
import * as Server from '../../server/PublicServer'
import { ExerciseWithMeta } from "../../server/PublicServer";
import ExerciseCrud, { IExerciseRecord } from '@crud/exc/ExerciseCrud'
import * as ExerciseContainer from '@src/component/exercise/ExerciseContainerComponent';
import ExerciseSeriesCrud, { IExerciseSeriesRecord } from '@src/framework/crud/exc/ExerciseSeriesCrud';
import ExerciseSeriesItemCrud, { IExerciseSeriesItemRecord } from '@src/framework/crud/exc/ExerciseSeriesItemCrud';
import { getCorrectSolution } from '@src/framework/api/exercise/getCorrectSolution';
import { startExerciseRun } from '@src/framework/api/exercise/startExerciseRun';
import { GetExerciseResult, getExercise } from '@src/framework/api/exercise/getExercise';
import { evaluate } from '@src/framework/api/exercise/evaluate';
import OoFolderCrud from '@src/framework/crud/media/OoFolderCrud';
import OoFileCrud, { IOoFileRecord } from '@src/framework/crud/media/OoFileCrud';
import { LIBRARY_PERSONAL_ID } from '@src/Const';
import { copyMeta } from '../meta/meta_copy';


const EXERCISE_ROOT_PARENT_FOLDER_OFI = 3;

const EXERCISE_ROOT_PARENT_FOLDER_PRIVATE = 7;

export function getExerciseRootParentFolderId(libraryId?: number) {
    if (libraryId == LIBRARY_PERSONAL_ID) {
        return EXERCISE_ROOT_PARENT_FOLDER_PRIVATE;
    } else {
        return EXERCISE_ROOT_PARENT_FOLDER_OFI;
    }
}


export async function getExerciseRecord(id: number): Promise<IExerciseRecord | undefined> {
    const ret = await ExerciseCrud.load(id);
    return Promise.resolve(ret.record);
}

export async function putExerciseRecord(exercise: Partial<IExerciseRecord>): Promise<IExerciseRecord> {
    const crud = new ExerciseCrud(exercise as any);
    const response = await crud.put();
    return response.record;
}

export async function putExerciseSeriesRecord(exerciseSeries: IExerciseSeriesRecord): Promise<IExerciseSeriesRecord> {
    const crud = new ExerciseSeriesCrud(exerciseSeries);
    const response = await crud.put();
    return response.record;
}

export async function putExerciseSeriesItemRecord(exerciseSeriesItem: IExerciseSeriesItemRecord): Promise<IExerciseSeriesItemRecord> {
    const crud = new ExerciseSeriesItemCrud(exerciseSeriesItem as any);
    const response = await crud.put();
    return response.record;
}

/**
 * This method copies all the files from the old exercise to the new. 
 * It creates a new ooFolder, and puts its id in the newExercise.
 * @param oldExercise From where to copy
 * @param newExercise Where to copy. This records oo_folder_id will be updated with the new folder id.
 */
export async function copyExerciseFiles(oldExercise:IExerciseRecord, newExercise:IExerciseRecord){
    const folders = await OoFolderCrud.list({ filter: { is_active: true, parent_id: oldExercise.oo_folder_id } });
    if (folders.length > 0) {
        throw new Error("A feladat mappájában vannak könyvtárak, nem lehet másolatot létrehozni!");
    }

    const files = await OoFileCrud.list({ filter: { is_active: true, oo_folder_id: oldExercise.oo_folder_id } });

    const newFolder = (await new OoFolderCrud({ parent_id: getExerciseRootParentFolderId(newExercise.library_id) }).put()).record;
    newExercise.oo_folder_id = newFolder.id;

    for (const file of files) {
        let copy: any = getFileCopy(file);
        copy.oo_folder_id = newExercise.oo_folder_id;
        copy = (await new OoFileCrud(copy).put()).record;
        copyMeta(OoFileCrud.TABLE_INFO_ID, file.id!, OoFileCrud.TABLE_INFO_ID, copy.id)
    }
}

export function getFileCopy(fileToCopy: IOoFileRecord) {
    // TODO FileActions.copy-t használni

    const newFile = {};
    // Csak ezeket a mezőket másoljuk át. A régi verzió az owner-t 
    // a creator session-t meg a relpath-ot is elküldte meg hasonlókat.
    const fnames: string[] = [
        'ext', 'has_thumb', 'is_active', 'keywords',
        'media_type_id', 'displayname', 'content_type', 
        'origin', 'origin_id', 'origin_url', 'origin_author', 'origin_display', 'suborigin_id',
        'size', 'title', 'width_px','height_px', 'sha1'
    ];
    fnames.forEach((fname: string) => { newFile[fname] = fileToCopy[fname]; });
    return newFile;
}


export class ExerciseServer implements ExerciseContainer.ExerciseServerInterface {


    private exerciseSeriesId?: number;

    private exerciseId?: number;

    private published: boolean;

    private exerciseRunState?: GetExerciseResult;

    private sharedContentRunId?: number;

    /**
     * @published: publikus feladatok adjona-e, vagy a szerkesztés alatt lévő feladatot.
     */
    constructor(published: boolean) {
        this.published = published;
    }

    /**
     * Konkrétan ezt a feladatot adja csak
     */
    setExerciseId(exerciseId: number) {
        this.sharedContentRunId = undefined;
        this.exerciseSeriesId = undefined;
        this.exerciseId = exerciseId;
        this.exerciseRunState = undefined;
    }

    /**
     * Ezt a feladatsort adja.
     * Az adaptív feladatsor is ide tartozik.
     */
    setExerciseSeriesId(exerciseSeriesId: number) {
        this.sharedContentRunId = undefined;
        this.exerciseSeriesId = exerciseSeriesId;
        this.exerciseId = undefined;
        this.exerciseRunState = undefined;
    }

    setShareContentRunId(sharedContentRunId: number) {
        this.sharedContentRunId = sharedContentRunId;
        this.exerciseSeriesId = undefined;
        this.exerciseId = undefined;
        this.exerciseRunState = undefined;
    }
    
    getSharedContentRunId() : number | undefined {
        if (this.sharedContentRunId != undefined)
            return this.sharedContentRunId 
        else
            return undefined;
    }
    
    async startSeries(): Promise<IExerciseSeriesRecord | undefined> {
        if (this.sharedContentRunId) {
            this.exerciseRunState = await getExercise({
                exerciseIndex: -1,
                shareContentRunId: this.sharedContentRunId
            });
            
        } else {
            this.exerciseRunState = await startExerciseRun({
                exerciseId: this.exerciseId,
                exerciseSeriesId: this.exerciseSeriesId,
                published: this.published,
            });
        }

        return this.exerciseRunState.exerciseSeriesRecord;
    }

    isPublished() {
        return this.published;
    }

    getSeriesInfo(): ExerciseContainer.ExerciseSeriesInfo {
        
        if (this.exerciseRunState) {
            return {
                currentExerciseCount: this.exerciseRunState.exerciseCount,
                earnedPoints: this.exerciseRunState.earnedPoints,
                isFinished: this.exerciseRunState.allExercisesCompleted,
                successCount: this.exerciseRunState.successCount,
                totalExerciseCount: this.exerciseRunState.totalExerciseCount || undefined,
                totalPoints: this.exerciseRunState.totalPoints
            }
        } else {
            return {
                currentExerciseCount: 0,
                earnedPoints: 0,
                isFinished: false,
                successCount: 0,
                totalExerciseCount: 0,
                totalPoints: 0
            };
        }
    }

    getCurrentExercise() {
        return this.getExercisebyIndex(-1);
    }

    skipCurrentExercise() {
        return this.getExercisebyIndex(-2);
    }

    async getExercisebyIndex(exerciseIndex: number): Promise<ExerciseWithMeta | null> {
        if (this.exerciseRunState && (this.exerciseRunState.runId || this.exerciseRunState.sharedContentRunId)) {
            this.exerciseRunState = await getExercise({
                exerciseIndex: exerciseIndex,
                runId: this.exerciseRunState.runId || undefined,
                shareContentRunId: this.sharedContentRunId
            });

            if (this.exerciseRunState.allExercisesCompleted && exerciseIndex < 0) {
                return null;
            }
            
            if (this.exerciseRunState.exerciseDetails) {
                return {
                    currentExerciseIndex: this.exerciseRunState.exerciseDetails.exerciseIndex,
                    exercise: this.exerciseRunState.exerciseDetails.exerciseRecord,
                    exerciseCount: this.exerciseRunState.exerciseCount,
                    exerciseEngineName: this.exerciseRunState.exerciseDetails.exerciseEngineName || "",
                    userSolution: this.exerciseRunState.exerciseDetails.userSolutionJSON,
                    totalPoints: this.exerciseRunState.exerciseDetails.totalPoints,
                    successPercent: this.exerciseRunState.exerciseDetails.successPercent !== null ? this.exerciseRunState.exerciseDetails.successPercent : undefined,                    
                    evaluationTimeStamp: this.exerciseRunState.exerciseDetails.evaluationTimeStamp || undefined
                }
            }
        }
        
        return null;
    }

    async evaluate( exercise: IExerciseRecord, dataToEvaluate: ExerciseContainer.DataToEvaluate): Promise<Evaluated> {
        
        if (this.exerciseRunState && this.exerciseRunState.exerciseDetails && (this.exerciseRunState.runId || this.exerciseRunState.sharedContentRunId)) {

            const evaluationResult = await evaluate({
                exerciseIndex: this.exerciseRunState.exerciseDetails.exerciseIndex,
                runId: this.exerciseRunState.runId || undefined,
                shareContentRunId: this.sharedContentRunId,
                solution: dataToEvaluate
            });

            if (evaluationResult.state) {
                this.exerciseRunState = evaluationResult.state;
            }

            var successPercent = evaluationResult.successPercent;
            if (successPercent == null) successPercent = evaluationResult.success ? 1 : 0;

            if(exercise && exercise.exercise.all_correct) {
                return {success: true, solution: evaluationResult.solution, successPercent: 0 }
            } 
            
            return {
                solution: evaluationResult.solution,
                success: evaluationResult.success,
                successPercent: successPercent
            }
        }
        
        return {
            solution: [],
            success: false
        }
        
    }

    async getCorrectSolution( exerciseId: number): Promise<any> {
        return await getCorrectSolution({
            id: exerciseId,
            published: this.published
        });
    }

    async searchExercise(exercise_id: number): Promise<any> {

        //TODO Ha könyvben van a feladat, csak az adott könyvben keressen
        return Server.elasticExerciseSearch(exercise_id, this.published);
    }    

}

