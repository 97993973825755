import { Server } from '../../server/Server';
import View , { registerViewClassProxy, ViewClassProxy } from '../View';
import { IListParams } from '@framework/crud/Crud';

export interface IViewOfficialKapDraftStatsRecord {
    /**
     * Azonosító
     *
     * Fake id
     */
    id?: number;
    /**
     * Táblázat infó
     *
     * Táblázat infó
     */
    table_info_id?: number|null;
    /**
     * Fajta
     *
     * DFHT-KIP / DFHT / Foglalkozásterv
     */
    kind?: string|null;
    /**
     * Évfolyam azonosító
     *
     * Évfolyam azonosító
     */
    grade_id?: number|null;
    /**
     * Évfolyam név
     *
     * Évfolyam név
     */
    grade_name?: string|null;
    /**
     * Tantárgy azonosító
     *
     * Tantárgy azonosító
     */
    subject_id?: number|null;
    /**
     * Tantárgy név
     *
     * Tantárgy név
     */
    subject_name?: string|null;
    /**
     * Folyamat állapot azonosító
     *
     * Folyamat állapot azonosító
     */
    wf_station_id?: number|null;
    /**
     * Állapot
     *
     * Folyamat állapot neve
     */
    wf_station_name?: string|null;
    /**
     * Darabszám
     *
     * Darabszám
     */
    cnt?: number|null;
}

/**
 *  kap.view_official_kap_draft_stats - Hivatalos KAP óravázlat statisztika
 */

export default class ViewOfficialKapDraftStats extends View<IViewOfficialKapDraftStatsRecord> {
    public static VIEW_INFO_ID = 2020040601;

    public static getSchemaNameForClass(): string { return 'kap'; }
    public static getViewNameForClass(): string { return 'view_official_kap_draft_stats'; }
    public static getTableNameForClass(): string|null { return 'null'; }

    public static load: (id: number, aServer ?: Server) => Promise<ViewOfficialKapDraftStats>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<IViewOfficialKapDraftStatsRecord[]>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class ViewOfficialKapDraftStatsClassProxy extends ViewClassProxy<IViewOfficialKapDraftStatsRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const viewOfficialKapDraftStatsClassProxy = new ViewOfficialKapDraftStatsClassProxy(ViewOfficialKapDraftStats);
registerViewClassProxy(viewOfficialKapDraftStatsClassProxy);

