import './style.css';
import { AnswerElement } from '@src/component/exercise/models/ExerciseBaseClass';
import { ExerciseEngineHelper } from '@src/component/exercise/engine/ExerciseEngineHelper';
import { ExerciseBaseClass } from '@src/component/exercise/models/ExerciseBaseClass';
import { AExerciseEngine } from '../../models/AExerciseEngine';
import polylabel from 'polylabel';
import { WMItemToImageServer } from './WMItemToImageServer';

export interface WMDndAreaData extends ExerciseBaseClass {
    areas: any[];
    area_threshold: string;
    options: AnswerElement[];
    keywords: string;
    show_radius: boolean;
    isFit: boolean;
    isScaled: boolean;
}

type WMD2ISolution = {
    key: number;
    value: boolean;
}

type WMDndToImageSolution = {
    fullmatch: boolean;
    answer: any[];
}

export class WMItemToImageDnDExerciseEngine extends AExerciseEngine {

    private allowPoints: any[] = [];
    private answerDivList: HTMLElement[] = [];
    private answerItemCircleRadius: number = 10;
    private answers: AnswerElement[] = [];
    private imageMaxDimension: number = 100;
    private userStarted: boolean = false;
    private polygonRatios: string[] = [];
    private radiusRatios: number[] = [];
    private wait: any;
    private mainImageLoad: any;
    private svgNS: string = "http://www.w3.org/2000/svg";
    private illNatW: number = 0;
    private illNatH: number = 0;
    private imageDropArea: HTMLImageElement;
    private sizeChangeTimer: NodeJS.Timer;
    private sizeChangeStore: any[] = [];
    private imagesStabilized: any;
    private exerciseLoader: any;

    initExercise(params: ExerciseParams): void {
        super.initExercise(params);
        let exercise: WMDndAreaData = params.exercise;
        this.answerDivList = [];

        if (!exercise || !exercise.areas || !exercise.options)
            return;

        this.answers = exercise.options.slice();
        this.root.classList.add("wm-dnd-image-area");

        if (this.exercise.isFit) {
            this.root.setAttribute('data-fitmode','true');
        }

        /*
        this.mainImageLoad = new Promise<number>((resolve) => {
            this.wait = resolve;
        });
        */

        this.root.setAttribute('data-loading','true');
        //this.imagesStabilized = this.isImagesStabilized();

        /* Exercise loader: Will be hide, when stabilizing function is done */
        this.exerciseLoader = this.root.appendChild(document.createElement("div"));
        this.exerciseLoader.classList.add('exercise-loader');

        let exerciseLoaderIcon: HTMLElement = this.exerciseLoader.appendChild(document.createElement("i"));

        let gridDiv: HTMLElement = this.root.appendChild(document.createElement("div"));
        gridDiv.classList.add("exercise-wrapper");

        let imageWrap: HTMLElement = gridDiv.appendChild(document.createElement("div"));
        imageWrap.classList.add("area-wrap");

        let imageContainer: HTMLElement = imageWrap.appendChild(document.createElement("div"));
        imageContainer.classList.add("image-container");

        let divDropArea: HTMLElement = imageContainer.appendChild(document.createElement("div"));
        divDropArea.classList.add("image-drop-area-div");
        this.imageDropArea = divDropArea.appendChild(document.createElement("img"));
        this.imageDropArea.classList.add("image-drop-area");

        /* Instead of later replace, we call max width img directly for correct calculation */
        let imageDropAreaSrc = exercise.imagebasepath + exercise.illustration;
        this.imageDropArea.setAttribute("src", imageDropAreaSrc);
        this.imageDropArea.classList.add("drop-div");
        this.imageDropArea.setAttribute("for", "img" + 1);
        this.imageDropArea.classList.add("exe-img-no-zoom");
        //window.onresize = this.resize.bind(this, this.imageDropArea);
        window.addEventListener('resize', this.resize.bind(this, this.imageDropArea));

        let answerWrap: HTMLElement = gridDiv.appendChild(document.createElement('div'));
        answerWrap.classList.add('answer-wrap');

        //Scrollozhatóság
        /*
        let answerController: HTMLElement = gridDiv.appendChild(document.createElement("div"));
        answerController.classList.add('answer-controller');
        */

        let answerControls = answerWrap.appendChild(document.createElement("div"));
        answerControls.classList.add("answer-controls");

        let answerLeftButton = answerControls.appendChild(document.createElement("button"));
        answerLeftButton.classList.add("control", "answer-control-left");

        let answerRightButton = answerControls.appendChild(document.createElement("button"));
        answerRightButton.classList.add("control", "answer-control-right");

        // .Scroll


        let answerContainer: HTMLElement = answerWrap.appendChild(document.createElement("div"));
        answerContainer.classList.add("answer-container");

        //Scroll button events
        answerRightButton.addEventListener("click", AExerciseEngine.xMove.bind(this, answerContainer, 'right'), false);
        answerLeftButton.addEventListener("click", AExerciseEngine.xMove.bind(this, answerContainer, 'left'), false);


        this.allowPoints = exercise.areas;
        // only for testing

        // convert radius percent to pixel
        let imgCont: HTMLDivElement = <HTMLDivElement>this.root.querySelector('.image-drop-area-div');
        let dimCont: DOMRect = <DOMRect>imgCont.getBoundingClientRect();

        this.imageDropArea.onload = this.displayAreasAndAnswers.bind(this, divDropArea, this.imageDropArea, exercise, answerContainer);

        //window.addEventListener('resize', this.setFitModes);
    }

    drop(ev: any) {
        this.answerDivList.forEach(element => {
            //element.style.opacity = "1";
            //element.style.zIndex = "1";
            element.style.opacity = '';
            element.style.zIndex = '';
        });

        let placeholderDivList = this.root.querySelectorAll('.placeholder-div');
        placeholderDivList.forEach((element:HTMLElement) => {
            //element.style.opacity = "1";
            //element.style.zIndex = "1";
            element.style.opacity = '';
            element.style.zIndex = '';
            element.removeAttribute('data-shrinkandgo');
            element.removeAttribute('data-shrinked');
        });

        AExerciseEngine.removeEvalStyle(ev.target);
        //ev.target.style.zIndex = 1;
        ev.target.style.zIndex = '';
        let answCont: HTMLDivElement = <HTMLDivElement>this.root.querySelector(".answer-container");
        let imageCont: HTMLDivElement = <HTMLDivElement>this.root.querySelector(".image-container");

        let hasImage: any = AExerciseEngine.hitTest(ev, [imageCont]);
        let hasAnswer: any = AExerciseEngine.hitTest(ev, [answCont]);
        if (ev.target.getAttribute("data-id").length != 0) {
            let circle: any = $(ev.target).find("circle")[0];
            circle.style.visibility = "hidden";
            if ((!hasImage.overlap && !hasAnswer.overlap) || hasAnswer.overlap) {
                // drop back to answer container
                let dDrop: string = ev.target.getAttribute('data-dropped');
                //if (dDrop == '1') {
                let dragIndex: number = Number(ev.target.getAttribute('data-id'));
                if (typeof dragIndex == 'number') {
                    ev.target.classList.remove('image-dropped');
                    ev.target.classList.remove('remove-background');
                    ev.target.style.position = 'relative';
                    ev.target.style.left = '';
                    ev.target.style.top = '';
                    ev.target.setAttribute('data-dropped', '0');
                    ev.target.removeAttribute('data-x');
                    ev.target.removeAttribute('data-y');
                    //answCont.appendChild(ev.target);
                    AExerciseEngine.moveElementBack(ev.target, answCont, this.root);
                }
                //}
            } else {
                // ev.preventDefault();
                /*
                set dropX and dropY according to their children. That is an svg which contains a circle, but the property getBoundingClientRect has to be shifted 
                by the value of width and height og the circle
                */
                let dropX: number = ev.target.children[0].getBoundingClientRect().left + ev.target.children[0].width.baseVal.value / 2;
                let dropY: number = ev.target.children[0].getBoundingClientRect().top + ev.target.children[0].width.baseVal.value / 2;
                ev.target.setAttribute('data-x', String(dropX));
                ev.target.setAttribute('data-y', String(dropY));
                ev.target.setAttribute('data-dropped', '1');
                this.afterDrop(ev.target);
                this.userStarted = true;
            }
        }

        answCont!.childNodes.forEach((element: HTMLElement) => {
            element.classList.remove("item-hidden");
        });
    }

    afterDrop(elem: HTMLElement) {
        let dimElem: DOMRect = <DOMRect>elem.getBoundingClientRect();
        let elemX: number = dimElem.left;
        let elemY: number = dimElem.top;
        let halfWidth: number = dimElem.width / 2;
        let halfHeight: number = dimElem.height / 2;
        let mrgnX: number = Number(elem.style.marginLeft);
        let mrgnY: number = Number(elem.style.marginTop);
        let imgCont: HTMLDivElement = <HTMLDivElement>this.root.querySelector('.image-drop-area-div');
        let dimCont: DOMRect = <DOMRect>imgCont.getBoundingClientRect();
        imgCont.appendChild(elem);
        let distX: string = "calc(" + ((elemX - dimCont.left - mrgnX + halfWidth) / dimCont.width * 100) + "% - " + halfWidth + "px)";
        let distY: string = "calc(" + ((elemY - dimCont.top - mrgnY + halfHeight) / dimCont.height * 100) + "% - " + halfHeight + "px)";

        elem.style.position = 'absolute';
        elem.style.left = distX;
        elem.style.top = distY;
        //elem.style.zIndex = '10';


        var x = getComputedStyle(elem).left;
        var elemWidthHalved = elem.getBoundingClientRect().width / 2;

        //the elem moved beyond the picture, maximize/minimize its position

        if (parseInt(x!, 10) > dimCont.width - elemWidthHalved)
            elem.style.left = dimCont.width - elemWidthHalved + "px";
        else if (parseInt(x!, 10) < (elemWidthHalved * -1))
            elem.style.left = (elemWidthHalved * -1) + "px";

        elem.classList.add('image-dropped');

        if (elem.getElementsByTagName('img').length > 0) {
            elem.classList.add('remove-background');
        }

        let areaCont = this.root.querySelector('.image-drop-area-div') as HTMLElement;

        // this.exercise.isFit = true; /* Test mode */
        /* FitMode Enabled */
        if (this.exercise.isFit) {
            //this.setFitMode(elem, areaCont);
            this.fitElement(elem, areaCont);

        }

        if (this.isSNIexc) this.SNIEvaluation(WMItemToImageServer);
    }

    drag(ev: any) {
        /* Remove FitMode properties on drag start */
        if (this.exercise.isFit) {
            this.removeFitMode(ev.target);
        }

        AExerciseEngine.removeEvalStyle(ev.target);

        if (!this.isSNIexc) {
            let circle: any = $(ev.target).find("circle")[0];
            circle.style.visibility = "visible";
        }

        this.answerDivList.forEach(element => {
            element.style.opacity = "0.3";
            //element.style.zIndex = "1";
            element.style.zIndex = '';
        });

        let placeholderDivList = this.root.querySelectorAll('.placeholder-div');
        placeholderDivList.forEach((element:HTMLElement) => {
            element.style.opacity = "0.3";
            //element.style.zIndex = "1";
            element.style.zIndex = '';
        });

        ev.target.style.zIndex = "10";
        ev.target.style.opacity = "0.6";
        if (ev.target.getElementsByTagName('img').length > 0) {
            ev.target.classList.add("remove-background");
        }

        let parentDiv: HTMLElement = (ev.target.parentElement as HTMLElement);
        if (parentDiv.classList.contains("answer-container") &&
            !ev.target.classList.contains("dropped") &&
            !ev.target.classList.contains("image-dropped")) {
            //if (this.simple_style || this.isSNIexc) {
                this.cloneAnswerElement(ev.target, parentDiv);
            //}
        }
    }

    /* Correct lines position on resize */
    setFitModes = () => {
        let droppedItems = this.root.querySelectorAll('.image-drop-area-div .image-dropped');
        //let areaCont = this.root.querySelector('.image-drop-area-div') as HTMLElement;
        for (let i = 0; i < droppedItems.length; i++) {
            this.fitInnerItem(droppedItems[i] as HTMLElement);
        }

    };

    /* Remove FitMode properties */
    removeFitMode(droppedItem: HTMLElement) {
        droppedItem.style.left = '';
        droppedItem.style.top = '';
        droppedItem.style.right = '';
        droppedItem.style.bottom = '';
        droppedItem.classList.remove('answer-div-fit');

        let evChild: HTMLElement | null;

        /*
        if (droppedItem.classList.contains('answer-img')) {
            evChild = droppedItem.querySelector('.exe-image-wrap');
        } else {
            evChild = droppedItem.querySelector('.answer-text-label');
        }
        */

        evChild = droppedItem.querySelector('.answer-text-label');

        if (evChild) {
            evChild.style.transform = '';
        }
    }

    /* Set element to fit the target area */
    setFitMode(droppedItem: HTMLElement, areaContainer: HTMLElement) {
        let targetNodes = areaContainer.querySelectorAll(".hitArea");
        let targetPolys = Array.from(targetNodes) as HTMLDivElement[];
        let dimCont: DOMRect = <DOMRect>areaContainer.getBoundingClientRect();
        let hitCheck: any = AExerciseEngine.hitTestOld(droppedItem as HTMLDivElement, targetPolys);
        if (hitCheck != false) {

            let evDim = droppedItem.getBoundingClientRect();
            //let hitParent = areaContainer.querySelector('.hitArea[data-index="' + hitCheck.dindex + '"]');
            let hitParent = targetPolys[hitCheck.id];


            if (hitParent) {
                let hitParentDim: DOMRect = <DOMRect>hitParent.getBoundingClientRect();

                let evL: number = hitParentDim.left - dimCont.left;
                let evT: number = hitParentDim.top - dimCont.top;
                let evR: number = dimCont.right - hitParentDim.right;
                let evB: number = dimCont.bottom - hitParentDim.bottom;

                evL = evL / dimCont.width * 100;
                evT = evT / dimCont.height * 100;
                evR = evR / dimCont.width * 100;
                evB = evB / dimCont.height * 100;

                droppedItem.style.left = evL + "%";
                droppedItem.style.top = evT + "%";
                droppedItem.style.right = evR + "%";
                droppedItem.style.bottom = evB + "%";
                droppedItem.classList.add('answer-div-fit');

                this.fitInnerItem(droppedItem);

            }
        }
    }

    /* Strech img element to founded target area */
    fitElement(droppedItem: HTMLElement, areaContainer: HTMLElement) {
        /* Point pairs */
        let ePoints = Array();

        /* Create extended polyareas from circle and rects */
        let polyAreas = this.createExtendedPolyAreas(areaContainer);
        let areaDim: DOMRect = <DOMRect>areaContainer.getBoundingClientRect();

        /* Dropped image */
        let eImg = droppedItem.querySelector('img');

        /* Prepare point pairs */
        if (eImg) {
            /* Create point pairs from element image */
            ePoints = this.createEPoints(eImg);
        } else {
            /* Create point pair from element center */
            let evDim = droppedItem.getBoundingClientRect();

            let pX = evDim.left - areaDim.left + evDim.width / 2;
            let pY = evDim.top - areaDim.top + evDim.height / 2;

            let pPair = {x: pX, y: pY};
            ePoints.push(pPair);
        }

        if (polyAreas && ePoints.length > 0) {
            /* Check hits */
            let foundId = this.checkPolyHits(ePoints, polyAreas);
            if (foundId > -1) {

                let targetNodes = areaContainer.querySelectorAll(".hitArea");
                let targetPolys = Array.from(targetNodes) as HTMLDivElement[];
                let targetPoly = targetPolys[foundId];


                if (targetPoly) {
                    let targetPolyDim: DOMRect = <DOMRect>targetPoly.getBoundingClientRect();

                    let evL: number = targetPolyDim.left - areaDim.left;
                    let evT: number = targetPolyDim.top - areaDim.top;
                    let evR: number = areaDim.right - targetPolyDim.right;
                    let evB: number = areaDim.bottom - targetPolyDim.bottom;

                    evL = evL / areaDim.width * 100;
                    evT = evT / areaDim.height * 100;
                    evR = evR / areaDim.width * 100;
                    evB = evB / areaDim.height * 100;

                    droppedItem.style.left = evL + "%";
                    droppedItem.style.top = evT + "%";
                    droppedItem.style.right = evR + "%";
                    droppedItem.style.bottom = evB + "%";
                    droppedItem.classList.add('answer-div-fit');

                    this.fitInnerItem(droppedItem);

                }
            }
        }
    }



    /* Fit inner element to the parent element */
    fitInnerItem(elem: HTMLElement) {
        let evInnerSizes = this.getInnerSize(elem);
        let evIW = evInnerSizes['width'];
        let evIH = evInnerSizes['height'];

        let evChild: HTMLElement | null;

        /*
         if (elem.classList.contains('answer-img')) {
         evChild = elem.querySelector('.exe-image-wrap');
         } else {
         evChild = elem.querySelector('.answer-text-label');
         }
         */

        evChild = elem.querySelector('.answer-text-label');

        if (evChild) {
            let labelW = evChild.offsetWidth;
            let labelH = evChild.offsetHeight;
            let evRatio = Math.min((evIW / labelW), (evIH / labelH));
            evChild.style.transform = 'scale(' + evRatio + ')';
        }
    }

    /* Get inner size of element and return size */
    getInnerSize(elem: HTMLElement): any {
        let sizes: any[] = [];
        let cs = window.getComputedStyle(elem);
        if (cs.paddingLeft && cs.paddingRight && cs.paddingTop && cs.paddingBottom && cs.borderLeftWidth && cs.borderRightWidth && cs.borderTopWidth && cs.borderBottomWidth) {
            let paddingX = parseFloat(cs.paddingLeft) + parseFloat(cs.paddingRight);
            let paddingY = parseFloat(cs.paddingTop) + parseFloat(cs.paddingBottom);

            let borderX = parseFloat(cs.borderLeftWidth) + parseFloat(cs.borderRightWidth);
            let borderY = parseFloat(cs.borderTopWidth) + parseFloat(cs.borderBottomWidth);

            let elemWidth = elem.offsetWidth - paddingX - borderX;
            let elemHeight = elem.offsetHeight - paddingY - borderY;

            sizes['width'] = elemWidth;
            sizes['height'] = elemHeight;

            return sizes;
        } else {
            return null;
        }
    }

    setAnswerImageSize(image: HTMLImageElement, wRatio: number, hRatio: number) {
        image.style.width = (image.naturalWidth * wRatio) + "px";
        image.style.height = (image.naturalHeight * hRatio) + "px";
        image.onload = null;
    }

    displayAreasAndAnswers(divDropArea: HTMLElement, imageDropArea: HTMLImageElement, exercise: WMDndAreaData, answerContainer: HTMLElement, params: ExerciseParams) {
        imageDropArea.onload = null;
        var polySVG = divDropArea.appendChild(document.createElementNS("http://www.w3.org/2000/svg", "svg"));
        polySVG.setAttribute("height", String(imageDropArea.clientHeight));
        polySVG.setAttribute("width", String(imageDropArea.clientWidth));
        polySVG.setAttribute("align", "left");
        polySVG.setAttribute("z-index", "10");
        polySVG.classList.add("polygon-area");

        if (this.exercise.show_radius) polySVG.setAttribute('data-show-radius', '');

        polySVG.style.position = "absolute";
        polySVG.style.left = String(imageDropArea.offsetLeft);
        polySVG.style.top = String(imageDropArea.offsetTop);

        for (let index = 0; index < this.allowPoints.length; index++) {
            //Circle
            if (!this.allowPoints[index].points) {
                if (this.allowPoints[index].x > this.imageMaxDimension || this.allowPoints[index].y > this.imageMaxDimension)  //greater pixel has been set than the picture itself
                    continue;

                let radius: number = this.allowPoints[index].radius;
                this.radiusRatios.push(radius);
                let dropdiv: HTMLElement = divDropArea.appendChild(document.createElement("div"));
                dropdiv.setAttribute("data-id", String(index));
                dropdiv.classList.add("drop-div-area");
                dropdiv.style.left = this.allowPoints[index].x + "%";
                dropdiv.style.top = this.allowPoints[index].y + "%";
                this.createCircleWithSVG(dropdiv, radius, "white", "http://www.w3.org/2000/svg", index, "dropDivCircle");
                if (!this.exercise.show_radius) {
                    // We don't need to display the red circles and the area in non display radius mode
                    dropdiv.style.visibility = "hidden";
                }
            }
            //Polygon
            else {
                this.polygonRatios.push(this.allowPoints[index].points);
                var polyArea = polySVG.appendChild(document.createElementNS("http://www.w3.org/2000/svg", "polygon"));
                polyArea.setAttribute("data-id", String(index));
                polyArea.setAttribute("points", ExerciseEngineHelper.getPolyCoordsImagePixels(this.allowPoints[index].points, imageDropArea.clientHeight, imageDropArea.clientWidth));
                polyArea.classList.add("poly", "hitArea");
            }
        }

        var originalOptions = exercise.options.slice(0);
        if (!exercise.ordered_answers) exercise.options = AExerciseEngine.shuffle(exercise.options.slice(0));

        this.illNatH = imageDropArea.naturalHeight;
        this.illNatW = imageDropArea.naturalWidth;

        let wRatio = imageDropArea.width / imageDropArea.naturalWidth;
        let hRatio = imageDropArea.height / imageDropArea.naturalHeight;

        //Answer elements
        for (let ndxAnswer = 0; ndxAnswer < exercise.options.length; ndxAnswer++) {
            let answerDiv: HTMLElement;
            answerDiv = answerContainer.appendChild(document.createElement("div"));
            answerDiv.classList.add("answer-div");
            if (exercise.options[ndxAnswer].type == "image") {
                this.createCircleWithSVG(answerDiv, this.answerItemCircleRadius, "green", this.svgNS, ndxAnswer, "answerCircle");
                answerDiv.classList.add("answer-img");
                answerDiv.title = answerDiv.title + exercise.options[ndxAnswer].text;
                answerDiv.setAttribute('data-type','image');

                AExerciseEngine.displayAnswer(answerDiv, exercise.options[ndxAnswer], this.is_accessible, [], this);
                if (exercise.isScaled) {
                    let img = answerDiv.querySelector("img");
                    if (img) {
                        img.classList.add('exe-img-no-zoom');
                        //img.onload = this.setAnswerImageSize.bind(this, img, wRatio, hRatio);
                        answerDiv.classList.add("answer-scaled");
                    }
                }
                //if (this.simple_style) answerDiv.classList.add("answer-div-simple");
            } else {
                if (exercise.options[ndxAnswer].type == "sound") {
                    answerDiv.setAttribute('data-type','sound');
                } else {
                    answerDiv.setAttribute('data-type','text');
                }
                this.createCircleWithSVG(answerDiv, this.answerItemCircleRadius, "green", this.svgNS, ndxAnswer, "answerCircle");
                let labelDiv: HTMLElement = answerDiv.appendChild(document.createElement("span"));
                AExerciseEngine.displayAnswer(labelDiv, exercise.options[ndxAnswer], this.is_accessible, ["answer-text-label"], this);
                AExerciseEngine.shrinkAndGrow(answerDiv);
            }
            answerDiv.classList.add("cell");
            answerDiv.setAttribute("data-id", String(originalOptions.indexOf(exercise.options[ndxAnswer])));
            answerDiv.setAttribute("data-index", "" + ndxAnswer);
            answerDiv.setAttribute("data-dropped", '0');

            this.answerDivList.push(answerDiv);
            answerDiv.onclick = this.click.bind(this);

            /* Create draggable elements */
            if (!this.isReplay) {
                ($(answerDiv) as any).draggable({
                    start: this.drag.bind(this),
                    stop: this.drop.bind(this),
                    containment: this.root,
                    scroll: true
                });
            }
        }

        /* Click-to-click simulation */
        if (!this.isReplay) {
            AExerciseEngine.simulateDrag({
                draggableItems: this.answerDivList,
                clickArea: this.root,
                excludedItemClasses: ['icon-shrinkgrow', 'exe-zoom-icon']
            });
        }

        //this.wait(0);
        if (this.reloadResources) this.reloadResources();

        /* Checking images until it's stable */
        this.imagesStabilized = this.isImagesStabilized();

        /*
        this.sizeChangeTimer = setInterval(() => {
            if (this.sizeChangeStore != this.imageDropArea.clientWidth) {
                this.sizeChangeStore = this.imageDropArea.clientWidth;
            } else {
                clearInterval(this.sizeChangeTimer);
                this.resize(this.imageDropArea);
            }
        }, 200);
        */
    }

    getUserSolution(): WMDndToImageSolution {
        //let result: any[] = this.checkUserAnswers();
        let result: any[] = this.checkUserAnswersCPS();

        let solution = { answer: result, fullmatch: true };
        return solution;
    }

    receiveEvaluation(evaluated: Evaluated): void {

        var correctIndexesJoined = []; //because correct solution is an array of arrays
        if (evaluated.success) {
            for (let index = 0; index < this.answerDivList.length; index++) {
                AExerciseEngine.removeEvalStyle(this.answerDivList[index]);
                this.answerDivList[index].classList.add("exe-engine-check-correct");
            }
        } else if (evaluated.solution != null) {
            let userSolution: any[] = this.getUserSolution().answer;
            let correctSolution = evaluated.solution;
            var droppedandEvaluated = [];

            for (let i = 0; i < userSolution.length; i++) {
                let solutionArray = [];
                var alreadyChecked: string[] = [];
                if (correctSolution[i] && correctSolution[i].length)
                    for (let index = 0; index < correctSolution[i].length; index++) {
                        solutionArray.push(this.answers[correctSolution[i][index]]);
                        correctIndexesJoined.push(correctSolution[i][index]);
                    }
                for (let j = 0; j < userSolution[i].length; j++) {
                    let answObj: HTMLElement = <HTMLElement>this.root.querySelector('.answer-div[data-id="' + userSolution[i][j] + '"]');
                    droppedandEvaluated.push(userSolution[i][j]);

                    AExerciseEngine.removeEvalStyle(answObj);

                    if (solutionArray.indexOf(this.answers[userSolution[i][j]]) > -1 && alreadyChecked.indexOf(this.answers[userSolution[i][j]].type == "image" ? this.answers[userSolution[i][j]].image : this.answers[userSolution[i][j]].text) == -1) {
                        if (!this.isSNIexc) answObj.classList.add("exe-engine-check-correct");
                    }
                    else if (!this.isReplay) {
                        this.moveItemToStart(answObj);
                        if (!this.isSNIexc) answObj.classList.add("exe-engine-check-wrong");
                    }
                    else {
                        if (!this.isSNIexc) answObj.classList.add("exe-engine-check-wrong");
                    }
                    alreadyChecked.push(this.answers[userSolution[i][j]].type == "image" ? this.answers[userSolution[i][j]].image : this.answers[userSolution[i][j]].text);
                }

            }

            //odd one out and undropped elements must be handled too
            for (let i = 0; i < this.answerDivList.length; i++) {

                if (droppedandEvaluated.indexOf(i) == -1) {                     //means this element has not been evaluated yet
                    let answObj: HTMLElement = <HTMLElement>this.root.querySelector('.answer-div[data-id="' + i + '"]');
                    AExerciseEngine.removeEvalStyle(answObj);

                        if (answObj.parentElement!.classList.contains('answer-container') && correctIndexesJoined.indexOf(i) == -1) { //if the element has not been moved and not the part of the correct solution then odd one out so correct
                            if (!this.isSNIexc) answObj.classList.add("exe-engine-check-correct");
                        }
                        else if (answObj.parentElement!.classList.contains('answer-container') && correctIndexesJoined.indexOf(i) != -1) {//if the element has not been moved and should be part of the correct solution then odd one out so uncorrect
                            if (!this.isSNIexc) answObj.classList.add("exe-engine-check-wrong");
                        }
                        else {
                            if (!this.isReplay) this.moveItemToStart(answObj);
                            if (!this.isSNIexc) answObj.classList.add("exe-engine-check-wrong");
                        }

                }
            }
        }
    }

    /* It's checking repeatedly width of image / div of exercise, and if it isn't changing anymore, give back true. If timeout, throw an Error  */
    /* It's necessary due to async image loading */
    /* At end, it call the resizing function, just in case */
    async isImagesStabilized(): Promise<any> {
        let repeated = 0;

        return new Promise(
            (resolve, reject) => {
                this.sizeChangeTimer = setInterval(() => {
                    let stabilizing = false;

                    /* Check if main element are already set */
                    if (this.answerDivList.length > 0 && this.imageDropArea !== undefined) {

                        /* Drop area image width compare */
                        if (this.sizeChangeStore[0] != this.imageDropArea.clientWidth || this.sizeChangeStore[0] === undefined) {
                            this.sizeChangeStore[0] = this.imageDropArea.clientWidth;
                            stabilizing = true;
                        }

                        /* Answer elements width compare */
                        for (let index = 0; index < this.answerDivList.length; index++) {
                            if (this.sizeChangeStore[index+1] != this.answerDivList[index].clientWidth || this.sizeChangeStore[index+1] === undefined) {
                                this.sizeChangeStore[index+1] = this.answerDivList[index].clientWidth;
                                stabilizing = true;
                                continue;
                            }
                        }
                    } else {
                        stabilizing = true;
                    }

                    if (!stabilizing) {
                        /* If stable yet */
                        clearInterval(this.sizeChangeTimer);
                        this.resize(this.imageDropArea);
                        //this.exerciseLoader.classList.remove('active');
                        this.root.setAttribute('data-loading','false');
                        resolve(true);
                    } else if (repeated > 50) {
                        /* If repeated already x times, timeout and error */
                        clearInterval(this.sizeChangeTimer);
                        this.resize(this.imageDropArea);
                        //this.exerciseLoader.classList.remove('active');
                        this.root.setAttribute('data-loading','false');
                        reject(new Error('Time out'));
                    }

                    repeated++;
                }, 500);

            }
        );
    }

    async showCorrectSolution(solution: any): void {
        //We need to wait for the loading of image
        const wImgStab = await this.imagesStabilized;
        //const wImgLoad = await this.mainImageLoad;
        this.removePlaceHolders(this.root);
        let imgCont = this.root.getElementsByClassName('image-drop-area-div')[0];
        let dimCont: DOMRect = <DOMRect>imgCont.getBoundingClientRect();
        let contWidth: number = dimCont.width;
        let contHeight: number = dimCont.height;
        let objAnsw: any[] = [];
        let notEmptyAreas: any[] = [];
        let elemsPlaced: any[] = [];

        if (!solution) {
            return;
        }
        for (let index = 0; index < solution.length; index++) {
            objAnsw[index] = {};
        }

        for (let i = 0; i < solution.length; i++) {
            var placedItems = [];

            for (let j = 0; j < solution[i].length; j++) {
                let data: string = solution[i][j];
                let placeAvailable = false;
                //let elem: HTMLElement | null = this.root.querySelector("#" + data);
                let elem: HTMLElement | null = this.root.querySelector('.answer-div[data-id="' + data + '"]');
                let elementData = this.answers[solution[i][j]].type == "image" ? this.answers[solution[i][j]].image : this.answers[solution[i][j]].text;  //the text or the img source of the actual element
                if (elem == null || elemsPlaced.includes(solution[i][j])) {
                    continue;
                }
                this.removeEvalStyle(elem);
                //here we check if answer with same text/image was placed and have other available place, to avoid same elements in one area bug
                if (notEmptyAreas.includes(i)) {
                    for (let k = 0; k < solution.length; k++) {
                        if (k != i && solution[k].includes(solution[i][j])) {
                            placeAvailable = true; //we indicate that element has another available place
                            break;
                        }
                    }
                }

                //if element has another available place then we dont place it at this area
                if (placeAvailable) {
                    continue;
                }

                notEmptyAreas.push(i);
                placedItems.push(elementData);          //added to palced items because from now it is a part of this area

                if (this.exercise.isFit) {
                    this.removeFitMode(elem);
                }

                AExerciseEngine.removeEvalStyle(elem);
                if (this.allowPoints[i].points) {
                    let polylabelCoords = ExerciseEngineHelper.getPolyCoordinatePairs(this.allowPoints[i].points);
                    var centerPoint = polylabel([polylabelCoords], 1.0);
                }

                let dropPercentX: number = this.allowPoints[i].x ? this.allowPoints[i].x : centerPoint![0];
                let dropPercentY: number = this.allowPoints[i].y ? this.allowPoints[i].y : centerPoint![1];
                let dropX: number = parseInt(String((contWidth) / 100 * dropPercentX));
                let dropY: number = parseInt(String(contHeight / 100 * dropPercentY));

                elem.setAttribute('data-x', String(dropX + dimCont.left));
                elem.setAttribute('data-y', String(dropY + dimCont.top));
                elem.setAttribute('data-dropped', '1');
                elem.style.left = 'calc(' + dropPercentX + '% - ' + elem.getBoundingClientRect().width / 2 + 'px)';
                elem.style.top = 'calc(' + dropPercentY + '% - ' + elem.getBoundingClientRect().height / 2 + 'px)';
                imgCont.appendChild(elem);
                elem.classList.add('image-dropped');
                elem.classList.add("exe-engine-correct-bg");
                elem.style.position = 'absolute';

                if (!elemsPlaced.includes(solution[i][j])) elemsPlaced.push(solution[i][j]);
                /* FitMode Enabled */
                if (this.exercise.isFit) {
                    let areaCont = this.root.querySelector('.image-drop-area-div') as HTMLElement;
                    //this.setFitMode(elem, areaCont);
                    this.fitElement(elem, areaCont);
                }
            }

        }

        for (let i = 0; i < this.answerDivList.length; i++) {
            //let answObj: HTMLElement = <HTMLElement>this.root.querySelector("#" + this.answerDivBase + i);
            let answObj: HTMLElement = <HTMLElement>this.root.querySelector('.answer-div[data-id="' + i + '"]');
            if (answObj.parentElement!.classList.contains('answer-container') && !answObj.classList.contains('exe-engine-correct-bg')) {
                AExerciseEngine.removeEvalStyle(answObj);
                answObj.classList.add("eke-engine-show-correct-highlight");
            }
        }

        imgCont.setAttribute("data-answers", JSON.stringify(objAnsw));
    }

    isUserReady(): boolean {
        return this.userStarted;
    }

    showHelp(solution: any): void {
        this.showCorrectSolution(solution);
    }

    checkUserAnswers(): any[] {
        let usrsol: any[] = [];

        let imgCont: HTMLDivElement = <HTMLDivElement>this.root.querySelector('.image-drop-area-div');
        let dimCont: DOMRect = <DOMRect>imgCont.getBoundingClientRect();
        let contWidth: number = dimCont.width;
        let ill_image = this.root.querySelector(".image-drop-area") as HTMLImageElement;


        // list answers and allowed points
        let answers: HTMLElement[] = ([].slice.call(this.root.querySelectorAll('.image-dropped'))) as HTMLElement[];
        let circleAreas: HTMLElement[] = ([].slice.call(this.root.querySelectorAll('.radius-circle'))) as HTMLElement[];
        let polyAreas: HTMLElement[] = ([].slice.call(this.root.querySelectorAll('.poly'))) as HTMLElement[];

        //Filling up the user solution array with empty arrays
        let totalAreaLenght = circleAreas.length + polyAreas.length;
        for (let i = 0; i < totalAreaLenght; i++) {
            usrsol[i] = [];
        }

        // Lopping through answer elements
        for (let ndx_answer = 0; ndx_answer < answers.length; ndx_answer++) {

            let answerElement: HTMLElement = answers[ndx_answer];

            let itemInCircle: boolean = false;
            let itemInPolygon: boolean = false;
            let choosenIndex: number = -1;

            //We get the position of the element
            //let x: number = Number(answerElement.getAttribute('data-x'));
            //let y: number = Number(answerElement.getAttribute('data-y'));
            let answerDivBound = answerElement.getBoundingClientRect();
            let x: number = answerDivBound.left + (answerDivBound.width / 2);
            let y: number = answerDivBound.top + (answerDivBound.height / 2);
            // Lopping through the circle areas
            for (let ndx_circle = 0; ndx_circle < circleAreas.length; ndx_circle++) {

                //Get the position of the circle area
                let circleAreaDataIndex: number = Number(circleAreas[ndx_circle].getAttribute('data-id'));
                let circle: any = {};
                let circleBoundaries = circleAreas[ndx_circle].getBoundingClientRect();
                let radius: number = this.radiusRatios[ndx_circle] / 100 * contWidth;
                circle.centerX = circleBoundaries.left + (circleBoundaries.width / 2);
                circle.centerY = circleBoundaries.top + (circleBoundaries.height / 2);
                circle.radius = radius;

                //Calculate the distance between the circle areas center and the answer item
                let distX: number = Math.abs(x - circle.centerX);
                let distY: number = Math.abs(y - circle.centerY);
                let dist: number = distX * distX + distY * distY;

                //Check it is inside radius or not
                itemInCircle = (dist <= radius * radius);
                if (itemInCircle) {
                    choosenIndex = Number(answers[ndx_answer].getAttribute('data-id'));
                    let temp_array: number[] = usrsol[circleAreaDataIndex];
                    temp_array.push(choosenIndex);
                    usrsol.splice(circleAreaDataIndex, 1, temp_array);
                    break;
                }
            }
            //We shouldn't check the point again, if its inside a circle
            if (itemInCircle) continue;
            // Looping through the poly areas
            for (let ndx_poly = 0; ndx_poly < polyAreas.length; ndx_poly++) {
                let polyAreaDataIndex: number = Number(polyAreas[ndx_poly].getAttribute('data-id'));
                itemInPolygon = ExerciseEngineHelper.isPointInsidePolygon([x - ill_image!.getBoundingClientRect().left, y - ill_image!.getBoundingClientRect().top], polyAreas[ndx_poly].getAttribute("points")!);
                if (itemInPolygon) {
                    choosenIndex = Number(answers[ndx_answer].getAttribute('data-id'));
                    let temp_array: number[] = usrsol[polyAreaDataIndex];
                    temp_array.push(choosenIndex);
                    usrsol.splice(polyAreaDataIndex, 1, temp_array);
                    break;
                }
            }

        }
        return usrsol;
    }

    /* check User Answers with Combined Polygon Solution */
    checkUserAnswersCPS(): any[] {
        let usrsol: any[] = [];

        let areaContainer: HTMLDivElement = <HTMLDivElement>this.root.querySelector('.image-drop-area-div');
        let answers: HTMLElement[] = ([].slice.call(this.root.querySelectorAll('.image-dropped'))) as HTMLElement[];
        let areas: HTMLElement[] = ([].slice.call(this.root.querySelectorAll('.radius-circle, .poly'))) as HTMLElement[];

        //Filling up the user solution array with empty arrays
        for (let i = 0; i < areas.length; i++) {
            usrsol[i] = [];
        }

        // Lopping through answer elements
        for (let ndx_answer = 0; ndx_answer < answers.length; ndx_answer++) {
            /* Create blank checking points */
            let ePoints = Array();

            /* Create extended polyareas from circle and rects */
            let polyAreas = this.createExtendedPolyAreas(areaContainer);

            let eImg = answers[ndx_answer].querySelector('img');
            if (eImg) {
                /* Create point pairs from element image */
                ePoints = this.createEPoints(eImg);
            } else {
                let areaDim = areaContainer.getBoundingClientRect();
                let answerElement: HTMLElement = answers[ndx_answer];
                let answerDivBound = answerElement.getBoundingClientRect();
                let pX: number = answerDivBound.left - areaDim.left + (answerDivBound.width / 2);
                let pY: number = answerDivBound.top - areaDim.top + (answerDivBound.height / 2);

                ePoints.push({x: pX, y: pY});
            }

            if (polyAreas && ePoints.length > 0) {
                /* Check hits */
                let foundId = this.checkPolyHits(ePoints, polyAreas);
                if (foundId > -1 ) {
                    let choosenIndex = Number(answers[ndx_answer].getAttribute('data-id'));
                    let temp_array: number[] = usrsol[foundId];
                    temp_array.push(choosenIndex);
                    usrsol.splice(foundId, 1, temp_array);
                }

            }
        }
        return usrsol;
    }


    createCircleWithSVG(parentDiv: HTMLElement, circleRadius: number, color: string, svgNS: string, index: number, idString: string) {
        let imgCont = this.root.getElementsByClassName('image-drop-area-div')[0];
        let dimCont: DOMRect = <DOMRect>imgCont.getBoundingClientRect();
        let contWidth: number = dimCont.width;

        var radius = circleRadius;
        var isAnswer = idString.includes('answer');

        if (!isAnswer)
            radius = radius / 100 * contWidth;

        var circleSVG = parentDiv.appendChild(document.createElementNS("http://www.w3.org/2000/svg", "svg")); //svg for wrapping the circle
        circleSVG.setAttribute("height", String(radius * 2));                                               //width and height is the diameter of the circle
        circleSVG.setAttribute("width", String(radius * 2));
        circleSVG.setAttribute("align", "left");
        circleSVG.setAttribute("z-index", "10");
        //in case of the dropdivs /which are not answer elements/ we have to correct the margin according to the proper placing of the circle
        if (!isAnswer) {
            circleSVG.style.marginTop = String((radius * -1) + 3) + "px";
            circleSVG.style.marginLeft = String((radius * -1) + 3) + "px";
        } else {
            circleSVG.style.position = "absolute";
            circleSVG.style.left = "50%";
            circleSVG.style.top = "50%";
            circleSVG.style.marginTop = String((radius * -1)) + "px";
            circleSVG.style.marginLeft = String(radius * -1) + "px";
            circleSVG.style.zIndex = "10";
        }
        var answerItemCircle = circleSVG.appendChild(document.createElementNS(svgNS, "circle")); //to create a circle. for rectangle use "rectangle"
        //answerItemCircle.setAttribute("id", idString + index);
        answerItemCircle.setAttribute("cx", String(radius));
        answerItemCircle.setAttribute("cy", String(radius));
        answerItemCircle.setAttribute("r", String(radius));
        answerItemCircle.setAttribute("position", "relative");
        answerItemCircle.setAttribute("fill", color);

        if (isAnswer) {
            answerItemCircle.setAttribute("fill-opacity", "0.8")
            answerItemCircle.setAttribute("stroke", "black");
            answerItemCircle.setAttribute("stroke-width", "2");
            answerItemCircle.setAttribute("visibility", "hidden");
        }
        else {
            answerItemCircle.setAttribute("fill-opacity", "0.2");
            answerItemCircle.setAttribute("stroke", "black");
            answerItemCircle.setAttribute("stroke-width", "1");
            answerItemCircle.setAttribute("stroke-dasharray", "10,10");
            answerItemCircle.classList.add("radius-circle");
            answerItemCircle.classList.add("hitArea");
            answerItemCircle.setAttribute("data-id", String(index));
        }
        answerItemCircle.setAttribute("align", "center");
    }

    removeEvalStyle(element: HTMLElement): void {
        element.classList.remove('exe-engine-correct-bg');
        element.classList.remove('exe-engine-wrong-bg');
        element.classList.remove('exe-engine-check-correct');
        element.classList.remove('exe-engine-check-wrong');
    }

    moveItemToStart(elem: HTMLElement): void {
        /* if FitMode, remove parameters, too */
        if (this.exercise.isFit) {
            this.removeFitMode(elem);
        }

        let vizsga: boolean = false; //Teszt
        if (vizsga) { //Ha nem éles teszt, csak gyakorlás
            return;
        }

        let dropContainer: HTMLElement | null = this.root.querySelector(".answer-container");
        let $dropContainer: any = dropContainer ? dropContainer : "";
        elem.classList.remove("image-dropped");
        elem.classList.remove("remove-background");
        //$dropContainer.appendChild(elem);
        AExerciseEngine.moveElementBack(elem, dropContainer!, this.root);
        AExerciseEngine.removeEvalStyle(elem);
        elem.style.top = '0';
        elem.style.left = '0';
        elem.style.position = 'relative';
    }

    click(ev: any) {
        let data = ev.target.getAttribute('data-id') != null ? Number(ev.target.getAttribute('data-id')) : Number(ev.target.closest('.answer-div').getAttribute('data-id'));

        for (let index = 0; index < this.answerDivList.length; index++) {
            if (Number(this.answerDivList[index].getAttribute('data-id')) == data)
                this.answerDivList[index].style.zIndex = "10";
            else
                //this.answerDivList[index].style.zIndex = "1";
                this.answerDivList[index].style.zIndex = '';
        }
    }

    containerResized() {
        this.resize(this.imageDropArea);
    }

    resize(illustrationImage: HTMLImageElement) {
        /* Clear change checker timer */
        clearInterval(this.sizeChangeTimer);

        //Changing the size of the polygon area svg dynamically according to the illustration image
        let imgWidth = illustrationImage.clientWidth;
        let imgHeight = illustrationImage.clientHeight;
        let polyArea: any = this.root.querySelector(".polygon-area");

        /* Correct dropped item */
        let droppedItems = this.root.querySelectorAll('.image-container .image-dropped');
        for (let i = 0; i < droppedItems.length; i++) {
            let droppedItemRect = droppedItems[i].getBoundingClientRect();
            let x = droppedItemRect.left + droppedItemRect.width / 2;
            let y = droppedItemRect.top + droppedItemRect.height / 2;

            droppedItems[i].setAttribute('data-x', x + '');
            droppedItems[i].setAttribute('data-y', y + '');
        }

        let scaledImages = this.root.querySelectorAll(".answer-scaled img");
        let wRatio = imgWidth / this.illNatW;
        let hRatio = imgHeight / this.illNatH;
        for (let i = 0; i < scaledImages.length; i++) {
            let img = scaledImages[i] as HTMLImageElement;
            img.style.width = img.naturalWidth * wRatio + "px";
            img.style.height = img.naturalHeight * hRatio + "px";
        }

        if (polyArea) {
            polyArea.setAttribute("height", String(imgHeight));
            polyArea.setAttribute("width", String(imgWidth));
            polyArea.style.left = String(illustrationImage.offsetLeft);
            polyArea.style.top = String(illustrationImage.offsetTop);
            let areas = this.root.querySelectorAll(".poly.hitArea");
            //Changing the points of polygons
            if (areas) {
                for (let i = 0; i < areas.length; i++) {
                    let polygon = areas[i] as HTMLElement;
                    polygon.setAttribute("points", ExerciseEngineHelper.getPolyCoordsImagePixels(this.polygonRatios[i], imgHeight, imgWidth));
                }
            }
        }
        //Changing the radiuses of the area circles
        if (this.exercise.show_radius) {
            let radiusCircles = this.root.getElementsByClassName("radius-circle");
            if (radiusCircles) {
                for (let i = 0; i < radiusCircles.length; i++) {
                    let newR = (this.radiusRatios[i] / 100) * imgWidth;
                    radiusCircles[i].parentElement!.style.marginLeft = String(newR * -1) + "px";
                    radiusCircles[i].parentElement!.style.marginTop = String(newR * -1) + "px";
                    radiusCircles[i].parentElement!.setAttribute("width", String(newR * 2));
                    radiusCircles[i].parentElement!.setAttribute("height", String(newR * 2));
                    radiusCircles[i].setAttribute("r", String(newR));
                    radiusCircles[i].setAttribute("cx", String(newR));
                    radiusCircles[i].setAttribute("cy", String(newR));
                }
            }
        }

        /* if FitMode Enabled, correct sizes */
        if (this.exercise.isFit) {
            this.setFitModes();
        }

    }


    xMove(div_container: HTMLDivElement, move_direction: string) {
        let container: HTMLDivElement = div_container!;
        let direction = move_direction;
        let childs = container.childElementCount;
        let iwidth = container.scrollWidth / childs / 2;

        let styleLeft = container.style.left;
        if (styleLeft == '') {
            styleLeft = '0px';
        }
        let maxLeft = (container.parentNode! as any).offsetWidth - container.scrollWidth;
        if (maxLeft > 0) {
            maxLeft = 0;
        }
        let mathLeft = parseInt(styleLeft + '');

        switch (direction) {
            case 'left':
                mathLeft = mathLeft + iwidth;
                if (mathLeft > 0) {
                    mathLeft = 0;
                }
                break;
            case 'right':
                mathLeft = mathLeft - iwidth;
                if (mathLeft < maxLeft) {
                    mathLeft = maxLeft;
                }
                break;
            default:
                break;
        }
        container.style.left = mathLeft + 'px';
    }

    /* Create canvas from image and return with */
    createCanvas(img: HTMLImageElement) {
        let clipRect = img.getBoundingClientRect();
        var canvas = document.createElement('canvas');
        canvas.setAttribute('width',img.width + '');
        canvas.setAttribute('height',img.height + '');
        canvas.setAttribute('style','display: none;');
        let imgParent = img.parentNode;
        if (imgParent) {
            imgParent.insertBefore(canvas, img.nextSibling );
        }


        var cx=canvas.getContext('2d');
        if (cx) {
            cx.drawImage(img,0,0,img.width,img.height);
            //img.remove();
        }

        return canvas;
    }

    /* Create point pairs from image and return an array of their pairs */
    createEPoints(img: HTMLImageElement) {
        let canvas = this.createCanvas(img);
        let ctx = canvas.getContext('2d');
        let points = new Array();

        if (ctx) {
            let imgCliprect = img.getBoundingClientRect();

            let imgWidth = imgCliprect.width;
            let imgHeight = imgCliprect.height;
            let imgLeft = imgCliprect.left;
            let imgRight = imgCliprect.right;
            let imgTop = imgCliprect.top;
            let imgBottom = imgCliprect.bottom;

            let illImage = this.root.querySelector(".image-drop-area") as HTMLImageElement;
            let illLeft = illImage!.getBoundingClientRect().left;
            let illTop = illImage!.getBoundingClientRect().top;

            if (imgWidth > 0 && imgHeight > 0) {

                /* Calc steps */
                let stepsW = Math.round(imgWidth / 15);
                let stepsH = Math.round(imgHeight / 15);
                let steps = (stepsW > stepsH) ? stepsW : stepsH;

                if (steps < 5) {
                    steps = 5;
                }

                //steps = Math.ceil(steps/5)*5;

                let pPair = {};

                //var leftR = Math.ceil(left/5)*5;
                //var topR = Math.ceil(top/5)*5;

                /* Search not transparent parts */
                for (let x = 0; x <= imgWidth; x += steps) {
                    for (let y = 0; y <= imgHeight; y += steps) {
                        if ((ctx.getImageData(x, y, 1, 1).data[3] != 0)) {
                            /*
                             var div = document.createElement('div');
                             //div.classList.add('star');
                             let nx = x + left;
                             let ny = y + top;
                             div.setAttribute('style','position: fixed; width: 2px; height: 2px; background: yellow;left: ' + nx + 'px; top: ' + ny + 'px;z-index: 100;');
                             document.body.appendChild(div);
                             */

                            let pX = x + (imgLeft - illLeft);
                            let pY = y + (imgTop - illTop);


                            pPair = {x: pX, y: pY};
                            points.push(pPair);
                        }
                    }
                }
            }
        }

        canvas.remove();
        return points;

        /*
         if (points.length > 0) {
         return points;
         } else {
         return false;
         }
         */


    }

    /* Extend normal polygon areas with circle to polygon convert */
    createExtendedPolyAreas(areaContainer: HTMLElement) {
        let iImage = areaContainer.querySelector(".image-drop-area") as HTMLImageElement;
        let iLeft = iImage!.getBoundingClientRect().left;
        let iTop = iImage!.getBoundingClientRect().top;

        let polyAreas: HTMLElement[] = ([].slice.call(areaContainer.querySelectorAll('.poly'))) as HTMLElement[];
        let circleAreas: HTMLElement[] = ([].slice.call(areaContainer.querySelectorAll('.radius-circle'))) as HTMLElement[];

        for (let ndx_circle = 0; ndx_circle < circleAreas.length; ndx_circle++) {
            let circleClip = circleAreas[ndx_circle].getBoundingClientRect();

            let cx = Number(circleAreas[ndx_circle].getAttribute('cx')) + circleClip.left - iLeft;
            let cy = Number(circleAreas[ndx_circle].getAttribute('cy')) + circleClip.top - iTop;
            let r = Number(circleAreas[ndx_circle].getAttribute('r'));


            let circlePolygonData = ExerciseEngineHelper.convertCircleToPolygon(cx, cy, r);

            let svg = areaContainer.querySelector('.polygon-area') as SVGSVGElement;
            let polygon = document.createElementNS("http://www.w3.org/2000/svg", "polygon");
            if (svg) {
                svg.appendChild(polygon);

                let circlePolygonPairs = circlePolygonData['pairs'];

                for (let pairIndex = 0; pairIndex < circlePolygonPairs.length; pairIndex++) {
                    //for (value of circlePolygonData['pairs']) {
                    let point = svg.createSVGPoint();
                    point.x = circlePolygonPairs[pairIndex][0];
                    point.y = circlePolygonPairs[pairIndex][1];
                    polygon.points.appendItem(point);
                    polygon.classList.add('vrPoly');
                    let dataId = circleAreas[ndx_circle].getAttribute('data-id');
                    if (dataId) {
                        polygon.setAttribute('data-id',dataId);
                    }

                }

                //this.root.appendChild(tmpElement);
                let HTMLPolygon:HTMLElement = svg.querySelector('.vrPoly') as HTMLElement;
                polyAreas.push(HTMLPolygon);
                polygon.remove();
            }

        }

        if (polyAreas.length > 0) {
            return polyAreas;
        } else {
            return false;
        }


    }

    /* Check polygon hits and return with ID */
    checkPolyHits(points:Number[], areas:HTMLElement[]) {
        if (areas.length == 0 || points.length == 0) {
            return -1;
        }

        let areasResult = new Array();

        for (let ndx_poly = 0; ndx_poly < areas.length; ndx_poly++) {
            let polyAreaDataIndex: number = Number(areas[ndx_poly].getAttribute('data-id'));
            let itemInPolygon = ExerciseEngineHelper.isPointsInsidePolygon(points, areas[ndx_poly].getAttribute("points")!);
            areasResult[polyAreaDataIndex] = itemInPolygon;
        }

        let found = !areasResult.every( (val, i, arr) => val === 0 );
        let foundID = -1;
        if (found) {
            let foundIndex = areasResult .indexOf(Math.max(...areasResult ));
            foundID = Number(areas[foundIndex].getAttribute('data-id'));
        }

        return foundID;

    }


}
