import * as React from 'react';

import { IViewInstituteSiteAdminRecord, ViewInstituteSiteAdminClassProxy, viewInstituteSiteAdminClassProxy } from '@src/framework/view/sys/ViewInstituteSiteAdmin';
import ListView from '@framework/forms/listview';
import { __ } from '@src/translation';


export class InstituteSiteAdminListViewInner extends ListView<IViewInstituteSiteAdminRecord> {

    public getViewClassProxy() : ViewInstituteSiteAdminClassProxy { return viewInstituteSiteAdminClassProxy; }
}

export default function InstituteSiteAdminListView(props: any) {
    return <InstituteSiteAdminListViewInner 
        defaultPageSize={10}
        match={props.match}        
        viewColumns={[
            {accessor: "admin_displayname"},
            {accessor: "link", Header:__("Műveletek"), maxWidth:200},
        ]}
    />
}
