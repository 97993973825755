import * as React from 'react';
import { Link } from 'react-router-dom';

import ViewWfType, { IViewWfTypeRecord, ViewWfTypeClassProxy, viewWfTypeClassProxy } from '@src/framework/view/wf/ViewWfType';

import { viewStationClassProxy  } from '@src/framework/view/wf/ViewStation';
import { viewTransitionTypeClassProxy  } from '@src/framework/view/wf/ViewTransitionType';
import { viewWfHeadTableClassProxy  } from '@src/framework/view/wf/ViewWfHeadTable';

import ListView from '@framework/forms/listview';
import { __ } from '@src/translation';


class WfTypeInner extends ListView<IViewWfTypeRecord> {
    //protected getHeader = () : JSX.Element|null => { return <WfMenu /> }
    public getViewClassProxy() : ViewWfTypeClassProxy { return viewWfTypeClassProxy; }

    protected getStationListButton(record:IViewWfTypeRecord) : JSX.Element {
        return (
            <Link 
                key="station-list"
                className="listview-button small" title={__("Állomások")} 
                to={ viewStationClassProxy.getListUrl({wf_type_id: record.id!, is_active: true})}>
            <i className="fa fa-tag"></i>
            </Link>);
    }    

    protected getTransitionTypeListButton(record:IViewWfTypeRecord) : JSX.Element {
        // Ez volt:  viewTransitionTypeClassProxy.getListUrl({type_id: record.id!})
        // de lecseréltük speciális editorra.
        return (
            <Link 
                key="transition-list"
                className="listview-button small" title={__("Átmenet típusok")} 
                to={`/wf/transition_matrix/${record.id!}`}>
                <i className="fa fa-arrow-circle-right"></i>
            </Link>);
    }       

    protected getTransitionGraphListButton(record:IViewWfTypeRecord) : JSX.Element {
        return (
            <Link 
                key="graph"
                className="listview-button small" title={__("Folyamatábra")} 
                to={`/wf/graph/${record.id}/`}>
                <i className="fa fa-project-diagram"></i>
            </Link>);
    }

    protected getHeadTableListButton(record:IViewWfTypeRecord) : JSX.Element {
        return (
            <Link 
                key="head-table-list"
                className="listview-button small" title={__("Fej táblák")} 
                to={ viewWfHeadTableClassProxy.getListUrl({wf_type_id: record.id!})}>
            <i className="fa fa-table"></i>
            </Link>);
    }       

    protected getRecordButtons(record: IViewWfTypeRecord) : JSX.Element[] {
        /* TODO: automatikus detail list menüt ide? */
        return super.getRecordButtons(record)
            .concat(this.getStationListButton(record))
            .concat(this.getTransitionTypeListButton(record))
            .concat(this.getTransitionGraphListButton(record))
            .concat(this.getHeadTableListButton(record))
        ;
    }

    protected getQuickFilterFieldNames(): string[] {
        return ['code', 'name'];
    }        

}

export default function WfTypeListView(props: any) {
    return <WfTypeInner 
        defaultPageSize={10}
        match={props.match}
        viewColumns={[
            {accessor: "code"},
            {accessor: "name"},
            {accessor: "link", Header:__("Műveletek")},
            {accessor: "description"},
            /*
            {accessor: "creation_time"},
            {accessor: "creator"},
            {accessor: "modification_time"},
            {accessor: "modifier"},
            */
        ]}
    />
}
