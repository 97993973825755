import * as React from 'react';
import { Link } from 'react-router-dom';

import WfTypeCrud, { IWfTypeRecord, WfTypeCrudClassProxy, wfTypeCrudClassProxy } from '@src/framework/crud/wf/WfTypeCrud';
import RecordEditor from '@framework/forms/recordeditor';


export default class WfTypeEditor extends RecordEditor<IWfTypeRecord> {
    public getCrudClassProxy() : WfTypeCrudClassProxy { return wfTypeCrudClassProxy;  }

}