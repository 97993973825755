export class EKEMultiTextAnswerServer{
    
    static evaluateOnServer(exercise: any, correctSolution: any, userSolution: any): Evaluated | boolean {
        var success = true;
        var correctAnsNum = 0;
        var successPercent = 0;
        //Make both the user-, and correct solution uppercase and string
        //Remove leading spaces from correctsolutions after the special character
        for (var i = 0; i < correctSolution.length; i++) {
            //Removing extra spaces before and after the ; character
            correctSolution[i] = correctSolution[i].toString().trim().replace(/\;;\;|\;\s|\s;/g, ";");
        }

        for (var i = 0; i < userSolution.length; i++) {
            userSolution[i] = userSolution[i].toString();
        }

        //if the correctsolution ith item doesn't contain ; then it's a single item, just check wheter they are equal
        for (var i = 0; i < correctSolution.length; i++) {
            var tempCorrectSol = exercise.exercise.caseSensitive ? correctSolution[i] : correctSolution[i].toUpperCase();
            if (correctSolution[i].search(/;/gi) == -1) {
                if (tempCorrectSol != userSolution[i])
                    success = false;
                else
                    correctAnsNum++;
            }
            else {
                //if it contains ; then it has multiple good choices, make an array of them, then check whetver the user hit one
                var sol_list = tempCorrectSol.split(";");
                if (sol_list.indexOf(userSolution[i]) == -1)
                    success = false;
                else
                    correctAnsNum++;
            }
        }

        if (correctAnsNum > 0 && correctSolution.length != 0)
            successPercent = Number((correctAnsNum / correctSolution.length).toFixed(2));
        return { "success": success, "solution": correctSolution, "successPercent": successPercent }
    }


}