import * as React from 'react';
import { app } from '@src/index';
import OoFileCrud, { IOoFileRecord } from '@src/framework/crud/media/OoFileCrud';
import DublinCoreEditorPlugin, { DublinCoreStore, saveDublinCoreStore } from '@src/component/meta/meta_dublin_core_plugin';
import { __ } from '@src/translation';

interface IFileDublinCoreEditorProps {
    file: IOoFileRecord;
    onCloseDialog?: () => void;
    onFileSaved: (file: IOoFileRecord) => void;
}

interface IFileDublinCoreEditorState {
    store: DublinCoreStore;
    changed: boolean;
}

export default class FileDublinCoreEditor extends React.Component<IFileDublinCoreEditorProps, IFileDublinCoreEditorState> {
    constructor(props: IFileDublinCoreEditorProps) {
        super(props);
        this.state = { store: {values:[], to_remove:[]}, changed:false }
    }

    async componentDidMount() {
        this.asnycLoad();
    }

    async asnycLoad() {
        try {
            ; // TODO
        } catch (error) {
            app.showErrorFromJsonResult(error);
        }
    }



    private onSave = async (data: any) => {
        try {
            await saveDublinCoreStore(
                OoFileCrud.TABLE_INFO_ID,
                this.props.file.id!,
                this.state.store
            );
            this.onFileSaved();
            this.onCloseDialog();
        } catch (error) {
            app.showErrorFromJsonResult(error);
        }
    }
    
    private onFileSaved = () => {
        if (this.props.onFileSaved)
            this.props.onFileSaved(this.props.file);
    }

    private onCloseDialog = () => {
        if (this.props.onCloseDialog)
            this.props.onCloseDialog();
    }


    render() {
        return <div>
            <br />
            <div className="row expanded">
                <div className="small-12 columns">
                    <DublinCoreEditorPlugin
                        tableInfoId={OoFileCrud.TABLE_INFO_ID}
                        recId={this.props.file.id! || null}
                        store={this.state.store}
                        onStoreLoaded={(store) => {
                            this.setState({store, changed: false})
                        }}
                        onStoreChanged={(store) => {
                            this.setState({store, changed: true})
                        }}
                    />
                </div>
            </div>
            <div className="row expanded">
                <div className="large-6 small-12 columns">
                    <a onClick={() => this.onCloseDialog()} className="button alert filemamanger-buttonPadder"><i className="fa fa-times" /> &nbsp;{__("Mégse")}</a>
                </div>
                <div className="large-6 small-12 columns text-right">
                    <button
                        onClick={this.onSave} 
                        disabled={!this.state.changed}
                        className="button success filemamanger-buttonPadder"
                    ><i className="fa fa-save" /> &nbsp;{__("Mentés")}</button>
                </div>
            </div>
        </div>;
    }
}