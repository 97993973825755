import * as React from 'react';

import { confirmDialog } from '../Dialog';
import { CalendarNavigator } from '../calendar/CalendarNavigator';
import { MonthCalendar } from '../calendar/MonthCalendar';
import { SysDayItem } from './sys_day_item';
import { appStore } from '@src/store/AppStore';
import { observer } from 'mobx-react';
import { observable, runInAction } from 'mobx';

@observer
export class SysDay extends React.PureComponent<{}> {
    private get calendar () {
        return appStore.dayTypesCalendar;
    }

    componentDidMount() {
        this.calendar.firstLoad();
    }

    render() {
        const ms = this.calendar;
        return <div className="row expanded">
                <div className="column small-12 large-12">
                    <CalendarNavigator calendarMonthSource={this.calendar} />
                </div>
                <div className="column small-12 medium-12 large-6">
                    <MonthCalendar dayItemRenderer={()=> null} calendarMonthSource={this.calendar}/>
                </div>
                <div className="column small-12 medium-12 large-6">
                    <SysDayItem 
                        calendarMonthSource={this.calendar}
                        onItemSaved={(item) => this.calendar.reload(/* TODO - optimize this! */)  }
                        onItemDeleted={(item) => this.calendar.reload(/* TODO - optimize this! */)  }
                    />
                </div>
            </div>;
    }
}

