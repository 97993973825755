import * as React from 'react';
import { Dialog } from '@src/component/Dialog';

import LessonEditor, { FileToUrlPath } from './LessonEditor';
import MediaFileChooser, { ALL_ACTIONS } from '@component/filemanager/MediaFileChooser';
import { Section } from '@src/server/EditorServer';
import OoFileCrud from '@src/framework/crud/media/OoFileCrud';
import { SectionOptionsEditor } from '@src/component/book/editor/SectionOptionsEditor';
import { __ } from '@src/translation';

type SectionIframeEditorProps = {
  lessonEditor: LessonEditor;
  section: Section;
  templateVariableId: string;
  rootFolderId: number;

  urlRoot: string;
  fileToUrlPath: FileToUrlPath;
}

type SectionIframeEditorState = {
  dialogVisible: boolean;
  currentFolderId: number;
}

export default class SectionIframeEditor extends React.Component<SectionIframeEditorProps, SectionIframeEditorState> {

  constructor(props: SectionIframeEditorProps) {
    super(props);
    this.state = { dialogVisible: false, currentFolderId: props.rootFolderId }
  }

  private onShowIframeSelectionDialog() {
    this.setState({
      dialogVisible: !this.state.dialogVisible
    })
  }

  async onIframeSelected(fileId: number) {

    const selectedFile = (await OoFileCrud.load(fileId)).record;

    const path = this.props.fileToUrlPath(selectedFile);


    const chapterEditor = this.props.lessonEditor;
    chapterEditor.handleEditorChange(this.props.section.id, this.props.templateVariableId, path);

    this.setState({ dialogVisible: false });
  }

  onUrlChange(event: React.FormEvent<HTMLInputElement>) {
    var value: string = event.currentTarget.value;

    const match1 = value.match("learningapps.org/view(.*)");
    const match2 = value.match("learningapps.org/(.*)");

    if (match1 && value.indexOf("watch") === -1) {
      value = "https://learningapps.org/watch?app=" + match1[1];
    } else if (match2 && value.indexOf("watch") === -1) {
      value = "https://learningapps.org/watch?app=" + match2[1];
    }

    this.props.lessonEditor.handleEditorChange(this.props.section.id, this.props.templateVariableId, value);
  }

  render() {
    var value: string | undefined = this.props.section.values[this.props.templateVariableId];

    if (value && !value.startsWith("http") && !value.startsWith("/")) {
      value = this.props.urlRoot + "/" + value;
    }

    return <div className="row expanded">
      {this.state.dialogVisible
        ?
        <Dialog title={__("Beágyazott tartalom kiválasztása")} onClose={() => this.setState({ dialogVisible: false })}>
          <MediaFileChooser
            key={this.state.currentFolderId || 0}
            rootFolderId={this.props.rootFolderId}
            currentFolderId={this.state.currentFolderId}
            selectableExtensions={["htm", "html"]}
            onFileSelected={this.onIframeSelected.bind(this)}
            onFolderSelected={(folderId: number) => { this.setState({ currentFolderId: folderId }) }}
            enabledActions={ALL_ACTIONS}
          />

        </Dialog>
        : ""
      }

      <div className="shrink column">
        <button className="button" onClick={this.onShowIframeSelectionDialog.bind(this)}><i className="fa fa-code" /> {__("Beágyazott tartalom kiválasztása")}</button>
      </div>
      <div className="shrink column">
        <label className="middle">{__("URL")}:</label>
      </div>
      <div className="column">
        <input
          type="text"
          value={this.props.section.values[this.props.templateVariableId]}
          onChange={this.onUrlChange.bind(this)}
        />
      </div>
      {value ?
        <div className="medium-12 column">
          <br />
          <iframe
            src={value}
            width="100%"
            height="500px"
          />
        </div>
        :
        ""
      }

      <SectionOptionsEditor
        lessonEditor={this.props.lessonEditor}
        section={this.props.section}
        templateVariableId={this.props.templateVariableId + "-options"}
        enableIcons={false}
        enableAspectRatio={true}
        enableExerciseCheckboxes={false}
      />
      
    </div>
      ;
  }
}
