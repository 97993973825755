import * as React from 'react';
import CollectionCrud, { ICollectionRecord } from '@src/framework/crud/usr/CollectionCrud';
import { me, Groups, hasAnyGroup } from '@src/framework/server/Auth';
import ViewCollectionItem, { IViewCollectionItemRecord } from '@src/framework/view/usr/ViewCollectionItem';
import CollectionItemCrud from '@src/framework/crud/usr/CollectionItemCrud';

import { app } from '@src/index';
import ExerciseCrud from '@src/framework/crud/exc/ExerciseCrud';
import ExercisePubCrud from '@src/framework/crud/exc_pub/ExerciseCrud';
import ExerciseSeriesCrud from '@src/framework/crud/exc/ExerciseSeriesCrud';
import ExerciseSeriesPubCrud from '@src/framework/crud/exc_pub/ExerciseSeriesCrud';
import OoFileCrud from '@src/framework/crud/media/OoFileCrud';
import OoFilePubCrud from '@src/framework/crud/media_pub/OoFileCrud';
import SectionCrud from '@src/framework/crud/doc/SectionCrud';
import SectionPubCrud from '@src/framework/crud/doc_pub/SectionCrud';
import { Popover } from '../ui/Popover';
import { __ } from '@src/translation';
import { AnySoaRecord } from 'dns';


type CollectionMenuProps = {
    tableInfoId: number;
    recId: number;
    isOnBook?: boolean;
}

type CollectionMenuState = {
    open: boolean;
    createCollection: boolean;
    collections: ICollectionRecord[];
    collectionItems: IViewCollectionItemRecord[];
    newCollectionTitle: string;
    srMessage: string;
}

export const ENABLED_COLLECTION_TABLE_INFO_IDS = [
    ExerciseCrud.TABLE_INFO_ID,
    ExercisePubCrud.TABLE_INFO_ID,

    ExerciseSeriesCrud.TABLE_INFO_ID,
    ExerciseSeriesPubCrud.TABLE_INFO_ID,

    OoFileCrud.TABLE_INFO_ID,
    OoFilePubCrud.TABLE_INFO_ID,

    SectionCrud.TABLE_INFO_ID,
    SectionPubCrud.TABLE_INFO_ID,
]

export class CollectionMenu extends React.Component<CollectionMenuProps, CollectionMenuState> {

    state: CollectionMenuState = {
        open: false,
        createCollection: false,
        collections: [],
        collectionItems: [],
        newCollectionTitle: "",
        srMessage: "",
    }

    componentDidMount()
    {
        window.addEventListener('keydown', this.keyDown.bind(this), true);
    }

    componentDidUpdate(prevProps: CollectionMenuProps, prevState: CollectionMenuState) {
        if ((!prevState.open && this.state.open && this.state.collections.length === 0)
            ||prevProps.recId!=this.props.recId) {
            this.reloadAsync();
        }

        if((this.refs.target as HTMLElement) && this.state.open === true && prevState.open === false)
        {
            (this.refs.target as HTMLElement).focus();
        }

       
    }

    componentWillUnmount()
    {
        window.removeEventListener('keydown', this.keyDown.bind(this), true);
    }

    private async reloadAsync() {
        if (!me) return;
        
        try {
            const collections = await CollectionCrud.list({filter: {is_active: true, owner_id: me.id}, order_by: "title"});
            const collectionItems = await ViewCollectionItem.list({filter: {is_active: true, owner_id: me.id, table_info_id: this.props.tableInfoId, rec_id: this.props.recId}});

            this.setState({
                collections,
                collectionItems
            });
        } catch(e) {
            app.showErrorFromJsonResult(e);
        }
    }

    private async toggleCollectionItem(collectionId?: number) {
        const collection = this.state.collections.find(c => c.id == collectionId);
        if (!collection) return;

        try {
            const item = this.isItemInCollection(collectionId);
            if (item) {
                await CollectionItemCrud.deleteById(item.id!);

                app.showSuccess(__(`Törölve a(z) {title_collection} gyűjteményből`, {title_collection:collection.title}), "");
                this.setState({srMessage :  __(`Törölve a(z) {title_collection} gyűjteményből`, {title_collection:collection.title})});

                setTimeout(() => {
                    this.setState({srMessage : ""});
                }, 1000);  
            } else {

                const existsIsActiveFalse = await CollectionItemCrud.list({filter: {table_info_id: this.props.tableInfoId, rec_id: this.props.recId, collection_id: collectionId}});
                if (existsIsActiveFalse.length === 0) {
                    
                    var no = 1;
                    const itemWithMaxNo = await CollectionItemCrud.list({filter: {collection_id: collectionId}, order_by: [{name: "no", desc: true}], limit: 1});
                    if (itemWithMaxNo.length > 0 && itemWithMaxNo[0].no) no = itemWithMaxNo[0].no! + 1;
                    
                    await new CollectionItemCrud({table_info_id: this.props.tableInfoId, rec_id: this.props.recId, collection_id: collectionId, no: no, is_active: true}).put();

                } else {
                    await new CollectionItemCrud({id: existsIsActiveFalse[0].id, is_active: true}).put();
                }

                app.showSuccess(__(`Hozzáadva a(z) {title} gyűjteményhez`,{ title:collection.title }), ""); 
                this.setState({srMessage :  __(`Hozzáadva a(z) {title} gyűjteményhez`,{ title:collection.title })});

                setTimeout(() => {
                    this.setState({srMessage : ""});
                }, 1000);          
            }           
        } catch(e) {
            app.showErrorFromJsonResult(e);
        }

        this.reloadAsync();
    }

    private async createCollection() {
        if (!me) return;
        
        try {
            await new CollectionCrud({title: this.state.newCollectionTitle}).put();
            this.setState({
                newCollectionTitle: "",
                createCollection: false,
            }, ()=>(this.refs.target as HTMLElement).focus())
            this.reloadAsync();
        } catch(e) {
            app.showErrorFromJsonResult(e);
        }
    }

    private isItemInCollection(collectionId?: number) {
        return this.state.collectionItems.find(item => item.collection_id === collectionId);
    }

    keyDown (e:any) {    
            if((e.key=='Escape'||e.key=='Esc'||e.keyCode==27)){
                e.preventDefault();
                this.setState({open: false});
            }
    }

    render() {
        return <div className="collection-menu" style={{position: "relative", display: "inline-block"}}>
            <button  title={__("Mentés gyűjteménybe")} ref="button" className="collection" onClick={() => this.setState({open: !this.state.open})}>
                <i className="fa fa-bars"/>
                {
                    this.props.isOnBook == true
                    ?
                    <span className="show-for-sr">&nbsp; {__("Mentés gyűjteménybe")} </span>
                    :
                    <>&nbsp; <span className="button-title">{__("Mentés")}</span> <span className="button-title hide-in-list">{__("gyűjteménybe")} </span></>
                  
                }
            </button>
            <Popover open={this.state.open} parentElement={() => this.refs.button as HTMLElement} onClose={() => this.setState({open: false})}>
                <div tabIndex={-1} ref="target"> 
                    <span role="alert" className="show-for-sr">{this.state.srMessage}</span>

                    <div style={{padding: "1em", borderBottom: "1px solid rgba(0, 0, 0, 0.1)"}}>
                    {__("Mentés helye")}:
                    </div>

                    <div style={{padding: "0.5em 1em"}}>
                    {this.state.collections && this.state.collections.length>0?
                        this.state.collections.map(c => 
                            <div key={c.id}>
                                <label style={{cursor: "pointer"}}>
                                    <input type="checkbox" checked={this.isItemInCollection(c.id) !== undefined} onChange={() => this.toggleCollectionItem(c.id)}/> {c.title}
                                </label>
                            </div>
                        )
                    :<div>
                        <label>
                        {__("Nincs még gyűjtemény")}
                        </label>
                    </div>
                    }
                    </div>

                    {
                        this.state.createCollection
                        ?
                        <div style={{padding: "0.5em 1em 1em", border: "1px solid rgba(0, 0, 0, 0.1)"}}>
                            <label>
                            {__("Név")}
                                <input ref="newCollectionInput" placeholder={__("az új gyűjtemény neve")} type="text" value={this.state.newCollectionTitle} onChange={(e) => this.setState({newCollectionTitle: e.currentTarget.value})}/>
                            </label>
                            <div style={{textAlign: "right"}}>
                                <button className="button success" style={{margin: 0}} disabled={this.state.newCollectionTitle === ""} onClick={this.createCollection.bind(this)}>
                                {__("Létrehozás")}
                                </button>
                            </div>
                        </div>
                        :
                        <button style={{padding: "1em", cursor: "pointer", borderTop: "1px solid rgba(0, 0, 0, 0.1)"}} onClick={() => this.setState({createCollection: true}, ()=> (this.refs.newCollectionInput as HTMLElement).focus())}>
                            <i className="fa fa-plus"/>&nbsp;{__("Új gyűjtemény létrehozása")} 
                        </button>
                    }
                </div>
            </Popover>
        </div>
        ;

    }

}
