import * as React from 'react';
import { app } from '@src/index';
import ViewDupPerson, { IViewDupPersonRecord } from '@src/framework/view/sys/ViewDupPerson';
import ViewPerson, { IViewPersonRecord } from '@src/framework/view/sys/ViewPerson';
import ReactTable, { Column, TableProps } from 'react-table';
import { getReactTableLabels } from '@src/framework/i18n';
import { BubbleLoader } from 'react-css-loaders';
import { Dialog } from '../Dialog';
import { formatDateWithoutTime, formatDate } from '@src/Util';
import ExamineUserLogAPI from '../usr/usr_log_examine_api';
import SecUserCrud from '@src/framework/crud/sys/SecUserCrud';
import { __ } from '@src/translation';
import obtainServer from '@src/framework/server/Server';

export const PATH_DUP_PERSON = "/sys/dup_person";

type DupPersonState = {
    dupPersons?: IViewDupPersonRecord[];
    
    selectedNormFullname?: string;
    persons?: IViewPersonRecord[];
    unify_order : number[];

    personLastLogin: {[persoId: number]: string};
}

export class DupPerson extends React.Component<any, DupPersonState> {

    constructor(props: any) {
        super(props);
        this.state = {
            personLastLogin: {},
            unify_order : []
        }
    }

    componentDidMount() {
        this.reloadAsync();
    }

    private async reloadAsync() {
        try {
            const dupPersons = await ViewDupPerson.list({order_by: "norm_fullname"});
            this.setState({
                dupPersons,
                selectedNormFullname:undefined,
                unify_order:[]
            });
        } catch(e) {
            app.showErrorFromJsonResult(e);
        }
    }

    private async onSelectPerson(selectedNormFullname: string) {
        this.setState({
            selectedNormFullname,
            persons: undefined,
            personLastLogin: {}
        });

        try {
            const personLastLogin = {};

            const persons = await ViewPerson.list({filter: {norm_fullname: selectedNormFullname}});
            for(const person of persons) {

                const users = (await SecUserCrud.list({filter: {person_id: person.id}}));
                if (users.length === 1) {
                    
                    const eventLog = await ExamineUserLogAPI.getLoginEventLog(users[0].id!);

                    if (eventLog.length > 0) {
                        const lastEvent = eventLog.sort((e1, e2) => e2.event_date.localeCompare(e1.event_date))[0];
                        personLastLogin[person.id!] = lastEvent.event_date;
                    }

                }
            }
            this.setState({
                persons,
                personLastLogin
            });

        } catch(e) {
            app.showErrorFromJsonResult(e);
        }
    }

    private joinFlag(data: any, colName: string): JSX.Element {
        const rowIdx = data.index;
        const unifyOrder = this.state.unify_order.indexOf(rowIdx);

        if (unifyOrder<0) {
            return <b style={{
                cursor:"pointer", 
                backgroundColor:"grey", 
                minWidth:"2em",
                minHeight:"2em",
                borderRadius: "1em"
            }}>N/A</b>
        } else {
            return <b style={{
                cursor:"pointer", 
                backgroundColor:"cyan", 
                minWidth:"2em",
                minHeight:"2em",
                borderRadius: "1em"
            }}>{""+(1+unifyOrder)}</b>
        }
    }

    private flagAccessor(colName: string, title: string) {
        return {
            accessor: colName, minWidth: 80,
            title: title,
            Header: title,
            style: { textAlign: "center" },
            Cell: (data: any, column: any) => {
                return this.joinFlag(data, colName)
            }
        };
    }    

    protected onCellClick(event: any, finalState: any, rowInfo: any, column?: Column, instance?: any) {
        if (column && column.id) {
            if (["sel"].includes(column.id)) {                
                const rowIdx = rowInfo.index;
                const row = this.state.persons![rowIdx];
                if (row.is_active) {
                    //const fname = column.id;                
                    //const personId = row.id!;
                    const unify_order = this.state.unify_order;
                    const unifyOrderIdx = unify_order.indexOf(rowIdx);
                    if (unifyOrderIdx>=0) {
                        unify_order.splice(unifyOrderIdx,1);
                    } else {
                        unify_order.push(rowIdx);
                    }
                    this.setState({ unify_order });
                }
            }
        }
    }    


    private getTdProps = (finalState: any, rowInfo: any, column?: Column, instance?: any) => {
        let props: Partial<TableProps> = {};
        if (column && rowInfo && rowInfo.row) {
            let row: any = rowInfo.row._original;
            if (row) {
                let style = props["style"] || {};
                if (row["is_active"] === false) {
                    style["textDecoration"] = "line-through";
                }
                props["style"] = style;
            }
        }
        props['onClick'] = (e: any, handleOriginal: any) => {
            this.onCellClick(e, finalState, rowInfo, column, instance);
        }
        return props;
    }    

    private unifyUsers = async () => {
        if (confirm(__("Biztosan egyesíteni akarja ezeket a személyeket?"))) {
            try {
                const person_ids : number[] = this.state.unify_order.map(rowidx =>
                    this.state.persons![rowidx].id!
                );
                const response = await obtainServer().post<boolean>(
                    'unify_users', {person_ids});
                console.log(response);
                this.reloadAsync();
            } catch (e) {
                app.showErrorFromJsonResult(e);
                Promise.reject(e);
            }
        }
    }

    render() {
        if (!this.state.dupPersons) {
            return <BubbleLoader />;
        }

        const excelUrl = ViewDupPerson.getExcelDownloadUrl({order_by: "norm_fullname"}, {filename: "Duplicate users.xlsx"});

        let canUnify : boolean = false;

        
        return <div>
            <h4>Lehetséges duplikált felhasználók: {this.state.dupPersons.length} db</h4>
            <ReactTable 
                columns={[
                    {
                        Header: "Név", accessor: "norm_fullname",
                        Cell: row => {
                            return <a href="#" onClick={() => this.onSelectPerson(row.value)}>{row.value}</a>
                        }
                    },
                ]}
                data={this.state.dupPersons}
                filterable
                sortable
                defaultPageSize={10}
                defaultFilterMethod={(filter, row, column) => row[filter.id] && row[filter.id].toLowerCase().indexOf(filter.value.toLowerCase()) != -1}
                {...getReactTableLabels()}
            />
            <a href={excelUrl} className="button"><i className="fa fa-file-excel" /> Excel</a>

            <Dialog open={this.state.selectedNormFullname !== undefined} 
                title={this.state.selectedNormFullname || ""} 
                width={1000}
                onClose={() => this.setState({selectedNormFullname: undefined})}>
                
                <ReactTable
                    columns={[
                        this.flagAccessor("sel", "Egyesítés"),
                        {Header: "Név", accessor: "fullname"},
                        {Header: "Email", accessor: "email"},
                        {Header: "Aktív", width: 100, accessor: "is_active", Cell: row => row.value ? "aktív" : "törölt"},
                        {Header: "Születési év", accessor: "birthday", Cell: row => row.value ? formatDateWithoutTime(row.value): ""},
                        {Header: "Születési város", accessor: "birth_city"},
                        {Header: "Anyja neve", accessor: "mothers_name"},
                        {Header: "Azonosító", accessor: "id_number"},
                        {Header: "Létrehozva", accessor: "creation_time", Cell: row => row.value ? formatDate(row.value): ""},
                        {Header: "Utolsó bejelentkezés (KAPU)", Cell: row => {
                            const lastLogin = this.state.personLastLogin[row.original.id];
                            return lastLogin ? formatDateWithoutTime(lastLogin) : "";
                        }},
                    ]}
                    data={this.state.persons}
                    filterable
                    sortable
                    loading={this.state.persons === undefined}
                    defaultPageSize={10}
                    defaultFilterMethod={(filter, row, column) => row[filter.id] && row[filter.id].toLowerCase().indexOf(filter.value.toLowerCase()) != -1}
                    getTdProps={this.getTdProps}
                />
                <button className="button primary" disabled={this.state.unify_order.length<=1}
                    onClick={this.unifyUsers}
                >
                    {__("Egyesít!")}
                </button>
            </Dialog>
        </div>;
    }
}
