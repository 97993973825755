import * as React from "react";
import SharedContentRunCrud, { ISharedContentRunRecord } from "@src/framework/crud/usr/SharedContentRunCrud";
import ViewSharedWithMe, { IViewSharedWithMeRecord } from "@src/framework/view/usr/ViewSharedWithMe";
import { ContentDetails } from "@src/component/filemanager/ContentUtils";
import OpenEndedQuestionCrud, { IOpenEndedQuestionRecord } from "@src/framework/crud/usr/OpenEndedQuestionCrud";
import SubmittedSolutionCrud, { ISubmittedSolutionRecord } from "@src/framework/crud/usr/SubmittedSolutionCrud";
import { me } from "@src/framework/server/Auth";
import { __ } from "@src/translation";
import MediaFileChooser, { ContentActionType, ALL_ACTIONS } from "@src/component/filemanager/MediaFileChooser";
import { app, history } from "@src/index";
import ViewSharedByMe, { IViewSharedByMeRecord } from "@src/framework/view/usr/ViewSharedByMe";
import { HTMLTextarea } from "@src/component/HtmlTextarea";
import { createFolderForSubmittedSolution } from "@src/framework/api/exercise/getExercise";
import { whiteSpaceChecker } from "@src/component/WhiteSpaceChecker";
import { Card } from "@src/component/ui/Panel";
import { ISharedContentRecord } from "@src/framework/crud/usr/SharedContentCrud";
import { Accordion, AccordionItem } from "@src/component/ui/Accordion";
import { ooFileCrudClassProxy } from "@src/framework/crud/media/OoFileCrud";
import { formatDate, formatDateTime, formatDateWithSeconds, roundToDecimals } from "@src/Util";

export type OpenEndedExerciseAnswerProps = {
    sharedContentRunId: number,
    recordId: number,
    isTeacher: boolean,
    returnUrl?: string,
    isEvaluated: boolean,
    sharedContentId: number
}

type OpenEndedExerciseAnswerState = {
    currAnswer: ISubmittedSolutionRecord,
    openEndedQuestion: IOpenEndedQuestionRecord,
    validationMessages: Map<string, string>,
    sharedContentRun: ISharedContentRunRecord,
    sharedContent?: IViewSharedWithMeRecord;
    earnedPoints?: number;
}

export class OpenEndedExerciseAnswer extends React.Component<OpenEndedExerciseAnswerProps, OpenEndedExerciseAnswerState> {

    constructor(props: OpenEndedExerciseAnswerProps) {
        super(props);

        let validationMap: Map<string, string> = new Map<string, string>();
        this.state = {
            currAnswer: {},
            openEndedQuestion: {},
            validationMessages: validationMap,
            sharedContentRun: {}
        }
        this.reloadAsync();
    }

    async reloadAsync() {
        if (!me || !this.props.recordId) return;

        try {
            let sharedContent = this.props.isTeacher ? (await ViewSharedByMe.load(this.props.sharedContentId)).record : (await ViewSharedWithMe.load(this.props.sharedContentId)).record;
            let sharedContentRun = (await SharedContentRunCrud.load(this.props.sharedContentRunId)).record;
            sharedContentRun.shared_by_success_percent = sharedContentRun.shared_by_success_percent ? sharedContentRun.shared_by_success_percent * 100 : 0;
            let openEndedQuestion = (await OpenEndedQuestionCrud.load(this.props.recordId)).record;
            let currAnswerL = (await SubmittedSolutionCrud.list({ filter: { shared_content_run_id: this.props.sharedContentRunId } }));
            let currAnswer = {};
            let earnedPoints = sharedContentRun.shared_by_success_percent && sharedContent ? roundToDecimals(sharedContent!.max_points! * sharedContentRun.shared_by_success_percent / 100, 2) : undefined;
            if (currAnswerL && currAnswerL.length > 0) currAnswer = currAnswerL[0];
            this.setState({ currAnswer: currAnswer, openEndedQuestion, sharedContentRun, sharedContent, earnedPoints });

        } catch (error) {
            app.showErrorFromJsonResult(error);
        }

    }

    async onSaveAnswer() {
        if (this.props.isTeacher) return false;
        let sc: ISubmittedSolutionRecord = this.state.currAnswer;

        try {
            //sc.shared_by_id = me!.id;
            sc.oo_folder_id = undefined;
            sc.shared_content_run_id = this.state.sharedContentRun.id;
            if (!sc.id) {
                sc.rec_id = this.state.openEndedQuestion.id;
                sc.table_info_id = OpenEndedQuestionCrud.TABLE_INFO_ID;
            }
            let record: ISubmittedSolutionRecord = (await new SubmittedSolutionCrud(sc).put()).record;
            app.showSuccess(__("Mentés"), __("Sikeres mentés"));
            this.setState({ currAnswer: record, validationMessages: new Map<string, string>() });

        } catch (error) {
            app.showErrorFromJsonResult(error);
            return false;
        }

        return true;
    }

    async onSubmit() {

        let sc: ISubmittedSolutionRecord = this.state.currAnswer;
        let errorMsg = "";
        let validationMessages = new Map<string, string>();
        let isAnyAnswer = false;
        if (sc.oo_folder_id) {
            let files = (await ooFileCrudClassProxy.list({ filter: { oo_folder_id: sc.oo_folder_id, is_active: true } }));
            isAnyAnswer = (files && files.length > 0);
        }
        if (!isAnyAnswer &&
            (!whiteSpaceChecker(sc.submitted_solution) || !whiteSpaceChecker(sc.submitted_solution.submitted_solution))) {
            errorMsg = __("Vagy a szöveges válasz, vagy legalább egy feltöltött fájl kötelező!");
            app.showError(__("Hiba"), errorMsg);
            validationMessages.set("body_html", errorMsg);
        }
        if (validationMessages.size > 0) {
            this.setState({ validationMessages });
        }
        if (await this.onSaveAnswer()) {

            //let sc: ISubmittedSolutionRecord = this.state.currAnswer;
            try {
                let scr: ISharedContentRunRecord = { id: this.state.sharedContentRun.id, finished: "now" };
                let nsr = await new SharedContentRunCrud(scr).put();

                app.showSuccess(__("Beküldés"), __("Sikeres feladat beadás"));
                if (this.props.returnUrl) history.push(this.props.returnUrl);

            } catch (error) {
                app.showErrorFromJsonResult(error);
            }

        }
    }

    async onSaveEvaluation() {

        let maxPoints = this.state.sharedContent!.max_points!;

        if (!this.state.earnedPoints) {
            app.showError(__("Hiba"), __("Az elért pont megadása kötelező!"));
            return;
        }
        if (this.state.earnedPoints && (this.state.earnedPoints < 0 || this.state.earnedPoints > maxPoints)) {
            app.showError(__("Hiba!"), __("Az elért pontok értékének 0 és {maxpoints} közé kell esnie!", { maxpoints: this.state.sharedContent!.max_points! }));
            return;
        }

        // temp.shared_by_success_percent = roundToDecimals(earnedPoints / maxPoints * 100, 2);

        let sc = {
            id: this.state.sharedContentRun.id,
            shared_by_comments_html: this.state.sharedContentRun.shared_by_comments_html,
            shared_by_success_percent: this.state.earnedPoints / maxPoints
        };
        const crud = new SharedContentRunCrud(sc as any);
        const response = await crud.put();
        app.showSuccess(__("Beküldés"), __("Sikeres értékelés"));
        if (this.props.returnUrl) history.push(this.props.returnUrl);
    }

    async addFolder() {
        if (!me) return;
        if (this.props.isTeacher) return;
        let saved = await this.onSaveAnswer();
        if (!saved || this.state.currAnswer.oo_folder_id) return;

        let oo_folder_id = (await createFolderForSubmittedSolution(this.state.currAnswer.id!));
        let sc: IOpenEndedQuestionRecord = this.state.currAnswer;
        sc.oo_folder_id = oo_folder_id;
        this.setState({ currAnswer: sc });
        this.reloadAsync();
    }

    handleDescriptionChange(value: any) {
        let tmp = this.state.currAnswer;
        tmp.submitted_solution = { submitted_solution: value };
        this.setState({ currAnswer: tmp });
    }

    handleEvaluationChange(value: any) {
        let tmp = this.state.sharedContentRun;
        tmp.shared_by_comments_html = value;
        this.setState({ sharedContentRun: tmp });
    }

    // handleEvaluationPercentChange(event: any) {
    //     let tmp = this.state.sharedContentRun;
    //     let newValue = Number(event.target.value);
    //     if (newValue < 0) {
    //         tmp.shared_by_success_percent = 0;
    //     }
    //     else if (newValue > 100) {
    //         tmp.shared_by_success_percent = 100;
    //     }
    //     else {
    //         tmp.shared_by_success_percent = newValue;
    //     }
    //     this.setState({ sharedContentRun: tmp });
    // }

    handleEvaluationPointsChange(ev: React.ChangeEvent<HTMLInputElement>) {
        let earnedPoints = Number(ev.target.value);
        this.setState({ earnedPoints: earnedPoints });
    }

    render() {

        if (!this.state.openEndedQuestion) return "";
        let filePermitsQuestion: number[] = [ContentActionType.FILE_DOWNLOAD];
        let filePermitsAnswer = (this.props.isTeacher || this.props.isEvaluated) ? [ContentActionType.FILE_DOWNLOAD] : ALL_ACTIONS;
        return <>
            <Accordion>
                <AccordionItem title={<span><img src={"/img/IKON_SET/FLAT/tag.svg"} className="exe-editor-icons" alt={__("Feladat adatai")} /> {__("Feladat adatai")}</span>}>
                    <div className="row expanded">
                        <div className="large-12 columns">
                            <label><strong>{__("Cím")}: </strong> <p>{this.state.openEndedQuestion.subject}</p></label>
                        </div>
                        <div className="large-12 columns">
                            <label><strong>{__("Kérdés")}: </strong> <p dangerouslySetInnerHTML={{ __html: this.state.openEndedQuestion.body_html || "" }}></p></label>
                        </div>
                        <div className="large-12 columns">
                            {this.state.openEndedQuestion.oo_folder_id &&
                                <MediaFileChooser
                                    rootFolderId={this.state.openEndedQuestion.oo_folder_id!}
                                    currentFolderId={this.state.openEndedQuestion.oo_folder_id!}
                                    showHoverPreview={false}
                                    showLocation={false}
                                    showFilter={false}
                                    enabledActions={filePermitsQuestion}
                                />
                            }
                        </div>
                    </div>
                </AccordionItem>
                <AccordionItem title={<span><img src={"/img/IKON_SET/FLAT/pencil.svg"} className="exe-editor-icons" alt={__("Válasz")} /> {__("Válasz")}</span>}>
                    <div className="row expanded">
                        {
                            !this.props.isTeacher && !this.props.isEvaluated ?
                                <div className="large-12 columns">
                                    <span className="exe-editor-validation-msg">{this.state.validationMessages.get("submitted_solution")}</span>
                                    <HTMLTextarea
                                        value={(this.state.currAnswer && this.state.currAnswer.submitted_solution ? this.state.currAnswer.submitted_solution.submitted_solution : "") || ""}
                                        onChange={(event) => this.handleDescriptionChange(event)}
                                    />
                                </div>
                                :
                                <div className="large-12 columns">
                                    <p dangerouslySetInnerHTML={{ __html: (this.state.currAnswer.submitted_solution ? this.state.currAnswer.submitted_solution.submitted_solution : "") || "" }}></p>
                                </div>
                        }
                        <div className="large-12 columns">
                            {!this.props.isTeacher && !this.props.isEvaluated &&
                                <>
                                    <button className="button small primary" onClick={(event) => { this.onSaveAnswer() }}><i className="fa fa-save" /> {__("Mentés")}</button>
                                    <button className="button small primary" onClick={(event) => { this.onSubmit() }}><i className="fas fa-paper-plane"></i>{__("Beküldés")}</button></>

                            }

                            {!this.state.currAnswer.oo_folder_id && !this.props.isTeacher && !this.props.isEvaluated &&

                                <button className="button small" onClick={(event) => { this.addFolder() }}><i className="fas fa-file-upload"></i>{__("Fájlok feltöltése")}</button>

                            }
                        </div>

                        {this.state.currAnswer.oo_folder_id && <div className="large-12 columns">
                            <MediaFileChooser
                                rootFolderId={this.state.currAnswer.oo_folder_id!}
                                currentFolderId={this.state.currAnswer.oo_folder_id!}
                                showHoverPreview={false}
                                showLocation={false}
                                showFilter={false}
                                enabledActions={filePermitsAnswer}
                            />
                        </div>
                        }


                    </div>
                </AccordionItem>
                {this.props.isEvaluated &&
                    <AccordionItem title={<span><img src={"/img/IKON_SET/FLAT/notepad.svg"} className="exe-editor-icons" alt={__("Értékelés")} /> {__("Értékelés")}</span>}>
                        <div className="row expanded">
                            {this.props.isTeacher ? <>
                                <div className="large-12 columns">
                                    <label>{__("Elérhető pontok")} </label>
                                    <label>{this.state.sharedContent && this.state.sharedContent!.max_points!}</label>
                                </div>
                                <div className="large-12 columns">
                                    <label>{__("Elért pontok")} </label>
                                    <input type="number" min="0" name="newPoints" value={this.state.earnedPoints || undefined} onChange={(event) => { this.handleEvaluationPointsChange(event) }}></input>
                                </div>
                                <div className="large-12 columns">
                                    <label>{__("Eredmény")} </label>
                                    <label>{this.state.earnedPoints && roundToDecimals(this.state.earnedPoints / this.state.sharedContent!.max_points! * 100, 1) + "%"}</label>
                                </div>

                                <div className="large-12 columns">
                                    <label>{__("Szöveges értékelés")} </label><span className="exe-editor-validation-msg">{this.state.validationMessages.get("shared_by_comments_html")}</span>
                                    <HTMLTextarea
                                        value={this.state.sharedContentRun.shared_by_comments_html || ""}
                                        onChange={(event) => this.handleEvaluationChange(event)}
                                    />
                                </div>

                                <div className="large-12 columns">
                                    <button className="button small primary float-right" onClick={(event) => { this.onSaveEvaluation() }}>{__("Mentés")}</button>
                                </div>
                            </>
                                : <>
                                    <div className="large-12 columns">
                                        <label><strong>{__("Elérhető pontok") + ": "}</strong> </label>
                                        {this.state.sharedContent && this.state.sharedContent.max_points}
                                    </div>
                                    <div className="large-12 columns">
                                        <label><strong>{__("Elért eredmény") + ": "}</strong> </label>
                                        {__("{point} pont ({percent}%)", { point: this.state.earnedPoints ? this.state.earnedPoints : "-", percent: roundToDecimals(this.state.sharedContentRun.shared_by_success_percent!, 1) })}
                                    </div>

                                    <div className="large-12 columns">
                                        <label><strong>{__("Szöveges értékelés") + ": "}</strong> </label>
                                        <p dangerouslySetInnerHTML={{ __html: this.state.sharedContentRun.shared_by_comments_html || "" }}></p>
                                    </div>
                                </>
                            }

                        </div>
                    </AccordionItem>
                }
            </Accordion>
        </>;
    }
}