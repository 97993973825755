
import * as React from 'react';

import { Link } from 'react-router-dom';
import { __ } from '@src/translation';

export default class Error404Page extends React.Component {
    render() {
        return <div className="row">
            <div className="column">
                <br/>
                <h2>{__("Hiba")}</h2>
                <h3>{__("A keresett lap nem található")}</h3>
                <p>
                    <Link to="/">{__("Vissza a főlapra")}</Link>
                </p>
            </div>
        </div>;
    }
}