import * as React from 'react'
import { match } from 'react-router';
import ViewInstituteGroup, { IViewInstituteGroupRecord } from '@src/framework/view/sys/ViewInstituteGroup';
import { app } from '@src/index';
import { __ } from '@src/translation';
import InstituteGroupCrud from '@src/framework/crud/sys/InstituteGroupCrud';
import ViewInstituteGroupMember from '@src/framework/view/sys/ViewInstituteGroupMember';
import { me } from '@src/framework/server/Auth';

type ClassRoomHeaderProps = {
    instituteGroupId: number;
}
type ClassRoomHeaderState = {
    group: IViewInstituteGroupRecord,
    edit: boolean,
    isAdmin: boolean,
}

export default class ClassRoomHeader extends React.Component<ClassRoomHeaderProps, ClassRoomHeaderState> {

    constructor(props: any) {
        super(props);

        this.state = { 
            group: {},
            edit: false,
            isAdmin: false,
         };
    }

    async componentDidMount()
    {
        const group = (await ViewInstituteGroup.load(this.props.instituteGroupId)).record;
        this.reloadIsAdmin();
        this.setState({group});
    }

    private async reloadIsAdmin() {
        let isAdmin : boolean = false;
        if(this.props.instituteGroupId)
        {
            if ((await ViewInstituteGroupMember.list({filter: {
                sec_user_id: me!.id,
                institute_group_id: this.props.instituteGroupId,
                is_active: true
            }}))[0].is_admin) {
                isAdmin = true;
            }
        }
        this.setState({isAdmin});
    }   

    async onGroupChangeEnd(e:any)
    {        
        if(e && e.target.value)
        {

            const value = e.target.value;
            this.state.group.name = value;

            await (new InstituteGroupCrud({ id: this.state.group.id, name: this.state.group.name}).put());
            this.setState({group: this.state.group, edit: false});

            app.showSuccess(__("Csoportnév módosítása"), __("Sikeres"));            
        }
        else{
            this.setState({edit: false});
        }


    }


    render()
    {
    return <div className="classroom-group-head">
                <h3 className={"group-name" + (this.state.isAdmin ? " isadmin" : "")}>
                    {
                        this.state.edit
                        ?
                        <input autoFocus type="text" defaultValue={this.state.group.name} onBlur={()=>this.onGroupChangeEnd(null)}
                        onKeyDown={event => {   
                            if (event.key === 'Enter') {                           
                                this.onGroupChangeEnd(event)
                            }
                            else if (event.keyCode === 27) {                                                    
                                this.onGroupChangeEnd(null)
                            }
                        }}></input>
                        :
                    <div onClick={()=>
                        {
                            this.state.isAdmin ?
                            this.setState({edit: true}) : null
                        }}>{this.state.group.name}</div>                                                        
                    }
                </h3>
                <span className="institute-name">{this.state.group.institute_name}</span>
            </div>
    }
}