import * as React from 'react';
import { Dialog, confirmDialog } from '@src/component/Dialog';
import { me, Groups, hasGroup } from '@src/framework/server/Auth';
import { __ } from '@src/translation';
import { Component } from '@fullcalendar/core';
import InstituteGroupNewsfeedCrud, { IInstituteGroupNewsfeedRecord } from '@src/framework/crud/usr/InstituteGroupNewsfeedCrud';
import { IViewUsrInstituteGroupNewsfeedRecord } from '@src/framework/view/usr/ViewUsrInstituteGroupNewsfeed';
import { FeedEdit } from './FeedEdit';
import { formatDate } from '@src/Util';
import { Popover } from '@src/component/ui/Popover';
import { app } from '@src/index';


type FeedProps = {
    feedRecord?: IViewUsrInstituteGroupNewsfeedRecord;  
    rowIndex : number;
    isAdmin: boolean; // A bejelentkezett felhasználó adminisztrátor-e
    sentByAdmin : boolean; // Az üzenet beküldője adminisztrátor-e
    onSave: (feedRecord: IInstituteGroupNewsfeedRecord, rowIndex: number) => void;  
    onDelete: (feedRecord: IInstituteGroupNewsfeedRecord, rowIndex: number) => void;  
   // editComponent?: JSX.Element; 
}

/*
    1 - kevés
    2 - több
    3 - eredeti
*/
type ShowAmount = 1 | 2 | 3;
const DEFAULT_SHOW_AMOUNT: ShowAmount = 1;

type FeedState = {
    isOpen: boolean;
    settingsIsVisible: boolean;
    // modifyPost: boolean;
    modify: boolean;
    showAmount: ShowAmount;
    showSettingsPopover: boolean;
}

interface ICuttedPost {
    html_body: string;
    moreButton: JSX.Element | null;

}

export default class Feed extends React.Component<FeedProps, FeedState>{

    constructor(props: any) {
        super(props);

        this.state = {
            isOpen: false,
            settingsIsVisible: false,
            modify: false,
            showSettingsPopover: false,
            showAmount: DEFAULT_SHOW_AMOUNT
        };
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps: FeedProps) {
        if (prevProps && prevProps.feedRecord && this.props.feedRecord && prevProps.feedRecord.id !== this.props.feedRecord.id) {
            this.setState({ showAmount: DEFAULT_SHOW_AMOUNT });
        }
    }

    settingsVisible() {
        this.setState({ settingsIsVisible: true });
    }

    settingsInvisible() {
        this.setState({ settingsIsVisible: false });

    }

    modify() {
        this.setState({ modify: true, showSettingsPopover: false });
    }

    update() {
        this.setState({ modify: false });
    }

    async delete() {
        this.setState({showSettingsPopover:false});
        if (!await confirmDialog(__("Törlés"), __("Biztosan törölni akarja a listából?"), __("Törlés"))) return;
        try {
            const feedRecord = await InstituteGroupNewsfeedCrud.deleteById(this.props.feedRecord!.id!);
            app.showSuccess(__("Bejegyzés törlése"), __("Sikeres"));
            this.props.onDelete(feedRecord, this.props.rowIndex);
        } catch (e) {
            app.showErrorFromJsonResult(e);
        }
    }

    private cutPost(text: string, showAmount: ShowAmount): ICuttedPost {
        if (text.length < 300 ) {
            return { html_body: text, moreButton: null };
        } else if (showAmount==1) {
            let cuttedPost = text.substring(0, 300);
            let regexp = new RegExp('[</>]$');
            while (regexp.test(cuttedPost)) {
                cuttedPost = cuttedPost.slice(0, -1);
            }
            return { html_body: cuttedPost + "...", moreButton: <><a onClick={() => this.setState({ showAmount: 2 })}>{__("Tovább")}</a></> };
        } else if (text.length < 1000 ) {
            return { html_body: text, moreButton: null };
        } else if (showAmount==2) {
            let cuttedPost = text.substring(0, 1000);
            let regexp = new RegExp('[</>]$');
            while (regexp.test(cuttedPost)) {
                cuttedPost = cuttedPost.slice(0, -1);
            }
            return { html_body: cuttedPost + "...", moreButton: <><a onClick={() => this.setState({ showAmount: 3 })}>{__("Tovább")}</a></> };
        } else if (text.length < 5000 ) {
            return { html_body: text, moreButton: <><a onClick={() => this.setState({ showAmount: 1 })}>{__("Összezár")}</a></> };
        } else {
            let cuttedPost = text.substring(0, 5000);
            let regexp = new RegExp('[</>]$');
            while (regexp.test(cuttedPost)) {
                cuttedPost = cuttedPost.slice(0, -1);
            }
            return { html_body: cuttedPost + "...", moreButton: <><a onClick={() => this.setState({ isOpen: true })}>{__("Tovább")}</a></> };
        }
    }

    private onSave(feedRecord: IInstituteGroupNewsfeedRecord) {
        this.setState({ modify: false }, () => {
            this.props.onSave(feedRecord, this.props.rowIndex);
        })
    }

    private onDelete(feedRecord: IInstituteGroupNewsfeedRecord) {
        this.setState({ modify: false }, () => {
            this.props.onDelete(feedRecord, this.props.rowIndex);
        })
    }

    render() {
        const feed = this.props.feedRecord;
        if(!feed) return null;

        let postDialog = <Dialog open={this.state.isOpen} title="" onClose={() => this.setState({ isOpen: false })}>
        <div className="modal-post">
            <div className="header">
               
                <div className="name-block">
                <h3>                 
                    {feed.sender_fullname || feed.sender_login_name }</h3>
                    <p className="date">
                        {feed.creation_time ?  formatDate(feed.creation_time) : formatDate(new Date().toString())}                        
                    </p>
                    <p className="date">{feed.modification_time ? (__("Módosítva: ")+ formatDate(feed.modification_time)) :  null} </p>
                </div>
                <div className="post-text" dangerouslySetInnerHTML={{ __html: feed.body_html! }}></div>
            </div>
        </div>
        </Dialog>

        const origBody = (this.props.feedRecord && this.props.feedRecord.body_html) || "";
        const { html_body, moreButton } = this.cutPost(origBody, this.state.showAmount);

        return <div className={"post" +(this.props.sentByAdmin ? " sent-by-admin" : "")} onMouseOver={this.settingsVisible.bind(this)} onMouseLeave={this.settingsInvisible.bind(this)}>
            <div className="header">
                <div className="name-block">
                    <h3>{this.props.sentByAdmin?<i className="fas fa-chalkboard-teacher" style={{marginRight: "6px"}}></i>:null}
                    {feed.sender_fullname || feed.sender_login_name}</h3>
                    <p className="date">
                        {feed.creation_time ? formatDate(feed.creation_time) : formatDate(new Date().toString())}                                               
                    </p>
                    <p className="date">{feed.modification_time ? (__("Módosítva: ")+ formatDate(feed.modification_time)) :  null} </p>
                </div>
                {(me && (me.id === feed.creation_user_id) || this.props.isAdmin) 
                ? 
                <>
                <div className="settings" style={{ visibility: this.state.settingsIsVisible && !this.state.modify ? "visible" : "hidden", cursor: "pointer" }} onClick={()=>this.setState({showSettingsPopover:true})}><i className="fas fa-cogs" ref="editBtn"></i></div>
                <Popover open={this.state.showSettingsPopover} parentElement={() => this.refs.editBtn as HTMLElement} onClose={() => this.setState({ showSettingsPopover: false })} >
                        <div className="content-item-icon__menu-item">
                            <div onClick={this.modify.bind(this)}><i className="fa fa-edit"></i> {__("Szerkesztés")} </div>
                            <div onClick={this.delete.bind(this)}><i className="fa fa-trash"></i> {__("Törlés")} </div>
                        </div>
                </Popover>
                </>
                : null
                }
            </div>
            {this.state.modify
                ?
                // React.cloneElement(this.props.editComponent!, { onSave: () => this.setState({ modify: false }), onRefresh: this.props.onRefresh, id:(this.props.id ? this.props.id : undefined)})
                <FeedEdit
                    instituteGroupId={feed.institute_group_id!}
                    onSave={this.onSave.bind(this)}
                    onDelete={this.onDelete.bind(this)}
                    id={feed.id}
                    rowIndex={-1}
                    onCancel={()=>this.setState({modify:false})}
                />
                :
                <><div className="post-text"><div dangerouslySetInnerHTML={
                    { __html: html_body }}></div>{moreButton}
                    {postDialog}
                </div></>}
        </div>;
    }


}