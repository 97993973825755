import * as React from 'react';
import './ExerciseShow.css';
import { match } from 'react-router';
import { Link } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { BubbleLoader } from 'react-css-loaders';
import * as queryString from 'query-string';
import * as ExerciseContainerComponent from '@src/component/exercise/ExerciseContainerComponent';
import * as ExerciseServer from '../ExerciseServer';
import { IViewExerciseSectionRecord } from '@framework/view/exc/ViewExerciseSection';
import * as PublicViewExerciseSection from '@framework/view/exc_pub/ViewExerciseSection';
import * as PrivateViewExerciseSection from '@framework/view/exc/ViewExerciseSection';
import { me, Groups, hasAnyGroup } from '@framework/server/Auth';
import * as PrivateViewExercise from '@src/framework/view/exc/ViewExcExercise';
import * as PublicViewExercise from '@src/framework/view/exc_pub/ViewExcExercise';
import { MetaKeywordImmediateAssignerPlugin, } from '@component/meta/meta_keyword_assigner_plugin';
import ExerciseCrud from '@src/framework/crud/exc/ExerciseCrud';
import { PATH_EXERCISE_VIEW, PATH_EXERCISE_EDIT, PATH_EXERCISE_PUB_VIEW, PATH_EXERCISE_SERIES_PUB_VIEW, PATH_EXERCISE_PUB_LIST, PATH_EXERCISE_LIST, PATH_PUBLISHED_BOOK, PATH_BOOK_VIEW_EDITING, getPath } from '@src/Routes';
import EngineCRUD from '@crud/exc/EngineCrud';
import { PermissionCache } from '@src/framework/security/PermissionCache';
import { WF_TYPE_OKOSFELADAT_ID, LIBRARY_OFI_OFFICIAL_ID, LIBRARY_PERSONAL_ID } from '@src/Const';
import { IViewExcExerciseRecord } from '@src/framework/view/exc/ViewExcExercise';
import * as PublicViewExerciseSeriesItem from '@framework/view/exc_pub/ViewExerciseSeriesItem';
import * as PrivateViewExerciseSeriesItem from '@framework/view/exc/ViewExerciseSeriesItem';
import { SearchResult } from '@src/server/PublicServer';
import * as Server from '@src/server/PublicServer';
import { ExerciseTile } from './ExerciseTileComponent';
import { app } from '../../..';
import { WorkflowDialog } from '@src/component/wf/WorkflowDialog';
import ExerciseSeriesCrud from '@src/framework/crud/exc/ExerciseSeriesCrud';
import { config } from '@src/framework/server/Server';
import { __ } from '@src/translation';
import { whiteSpaceChecker } from '@src/component/WhiteSpaceChecker';
import ViewMyExcExercise from '@src/framework/view/exc/ViewMyExcExercise';
import ViewEditorExcExercise from '@src/framework/view/exc/ViewEditorExcExercise';
import { IExerciseRecordView } from '../models/IExerciseRecordView';
import { exerciseModule } from '@src/module/exerciseModule/exerciseModule';


type IExerciseShowProps = {
    match: match<{ exerciseId: string }>
}

interface IExerciseShowState {
    inExerciseSeries: PrivateViewExerciseSeriesItem.IViewExerciseSeriesItemRecord[],
    // Szerencsére a nyilvánossal kompatibilis a nem nyilvános!
    // published_sections: PublicViewExerciseSection.IViewExerciseSectionRecord[] | null;
    sections: PrivateViewExerciseSection.IViewExerciseSectionRecord[];
    canEdit: boolean,
    filters: any,
    nextExerciseId?: number,
    prevExerciseId?: number,
    published: boolean,
    isFirst: boolean,
    searchResults: SearchResult[],
    engines: any[],

    workflowDialogOpen: boolean,
    workflowStateName?: string,
    loading: boolean,
}
export default class ExerciseShow extends React.Component<IExerciseShowProps, IExerciseShowState> {

    private exerciseServer: ExerciseServer.ExerciseServer;

    constructor(props: any) {
        super(props);
        const published = this.props.match && this.props.match.path.startsWith(PATH_EXERCISE_PUB_VIEW);

        this.state = {
            canEdit: false,
            inExerciseSeries: [],
            filters: {},
            published,
            isFirst: true,
            searchResults: [],
            engines: [],
            workflowDialogOpen: false,
            loading: true,
            sections: []
        };

        if (!me && !published) {
            app.showError(__("Hiba"), __("Nincs jogosultsága a tartalom megtekintésére!"));
            return;
        }
        this.exerciseServer = new ExerciseServer.ExerciseServer(published);
        this.exerciseServer.setExerciseId(Number(this.props.match.params.exerciseId));
        this.setNavigation();
    }

    async componentDidUpdate(prevProps: IExerciseShowProps) {
        if (prevProps.match.params.exerciseId != this.props.match.params.exerciseId) {
            this.exerciseServer.setExerciseId(Number(this.props.match.params.exerciseId));
            this.setNavigation();
        }
    }

    async getSeriesItems(exerciseId: number): Promise<PrivateViewExerciseSeriesItem.IViewExerciseSeriesItemRecord[]> {
        let items: PrivateViewExerciseSeriesItem.IViewExerciseSeriesItemRecord[] = [];
        try {
            items = this.state.published ? await PublicViewExerciseSeriesItem.default.list({ filter: { exercise_id: exerciseId } }) : await PrivateViewExerciseSeriesItem.default.list({ filter: { exercise_id: exerciseId } });
        } catch (e) {
            app.showErrorFromJsonResult(e);
            return [];
        }

        //if(this.state.published) return[]; // todo: have a public view
        //let exeSeriesItems:PrivateViewExerciseSeriesItem.IViewExerciseSeriesItemRecord[] = await PrivateViewExerciseSeriesItem.default.list({ filter: { exercise_id: exerciseId } });
        let uniqueExeSeriesIds = new Set(items.map(item => item.exercise_series_id!));
        let uniqueSeriesItems: PrivateViewExerciseSeriesItem.IViewExerciseSeriesItemRecord[] = [];

        let firstMatch: boolean;
        for (const x of Array.from(uniqueExeSeriesIds)) {
            firstMatch = true;
            for (var item of items) {

                if (!firstMatch) { break; }
                if (x === item.exercise_series_id && firstMatch) {
                    let isActiveSerie = (await ExerciseSeriesCrud.load(item.exercise_series_id)).record.is_active;
                    if (!isActiveSerie) { continue; }
                    uniqueSeriesItems.push(item);
                    firstMatch = false;
                }
            }
        }
        return uniqueSeriesItems;
    }


    async setNavigation() {
        this.setState({ loading: true });
        let exerciseRecords: IExerciseRecordView[] = []
        let currExId = parseInt(this.props.match.params.exerciseId);
        
        let published_exercise: PublicViewExercise.IViewExcExerciseRecord | null = null;
        let exercise: Partial<IExerciseRecordView> | null = null;

        // let published_sections: PublicViewExerciseSection.IViewExerciseSectionRecord[] | null = null;
        let sections: PrivateViewExerciseSection.IViewExerciseSectionRecord[];
        
        try {
            // A szerkesztőségi/privát verziót mindig megpróbáljuk betölteni.
            try {
                exercise = (await PrivateViewExercise.default.load(currExId)).record;
            } catch (e) {
                // De nem biztos hogy van rá jogunk. Ha nyilvános feladatot akartunk megnyitni,
                // akkor ez nem baj.
                if (this.state.published) {
                    exercise = null;
                } else {
                    // De ha nem nyilvánosat, akkor nem tudunk tovább menni.
                    app.showErrorFromJsonResult(e);                    
                    return;
                }

            }

            if (this.state.published) {
                // Ha publikált, akkor betöltjük a publikált és a szerkesztőségi verziót is.
                // Workflow állapota csak a szerkesztőséginek van...
                published_exercise = (await PublicViewExercise.default.load(currExId)).record;
                // A nyilvános sections szerencsére típus kompatibilis a simával.
                sections = await PublicViewExerciseSection.default.list({ filter: { id: currExId, library_id: LIBRARY_OFI_OFFICIAL_ID } });
            } else {
                sections = await PrivateViewExerciseSection.default.list({ filter: { id: currExId, library_id: LIBRARY_OFI_OFFICIAL_ID } });
            }

            // Ha nincs bejelentkezve akkor nem szerkeszthet.
            let canEdit = false;
            if (me && exercise) {
                // Hivatalos feladatnál akkor szerkeszthet, ha van joga szerkeszteni az aktuális állapotában.
                // Fontos, hogy ez a szerkesztés jog a szerkesztőségi verzióra vonatkozik. Akkor is,
                // ha most nem azt jelenítjük meg, hanem a publikáltat.
                //
                // Privát feladatnál akkor szerkeszthet, ha ő a tulajdonos.
                //
                // A többi library-hoz meg elvileg nincsenek feladatok.
                //
                if (exercise.library_id == LIBRARY_OFI_OFFICIAL_ID) {
                    canEdit = await PermissionCache.hasWfEditPermission(WF_TYPE_OKOSFELADAT_ID, exercise.wf_station_id, me, exercise);
                } else if (exercise.library_id == LIBRARY_PERSONAL_ID) {
                    canEdit = (exercise.owner_id == me.id);
                }
            }

            // setting the offered exercises
            // TODO: add limit argument to search API
            let searchResults: SearchResult[] = await Server.searchSimilar(currExId, this.state.published, 10, "exercise");

            let engines = await EngineCRUD.list({ filter: { is_active: true } });
            let uniqueSeriesItems = await this.getSeriesItems(currExId);

            let newState: Partial<IExerciseShowState> = {
                searchResults,
                sections,
                inExerciseSeries: uniqueSeriesItems,
                canEdit,
                engines,
                workflowStateName: (exercise && exercise.wf_station_name) || undefined,
                loading: false,
            };

            if (!whiteSpaceChecker(location.search)) {
                //canEdit = await PermissionCache.hasWfEditPermission(WF_TYPE_OKOSFELADAT_ID, curr_exercise.wf_station_id, me, curr_exercise);
                this.setState(newState as IExerciseShowState);
                return;
            }

            let filter = queryString.parse(location.search);
            // checking if it comes from a last page, then we need to create links for the previous and next exercises. 
            // Therefore, we need to know the library id, to use the apropriate view.
            const libId = filter.library_id?Number(filter.library_id):undefined;
            var listParameters = {
                filter: JSON.parse(filter.filter),
                order_by: filter.order_by ? JSON.parse(filter.order_by) : undefined,
                limit: Number(filter.limit),
                offset: Number(filter.offset),
                library_id:undefined
            };

            if (filter.offset == this.state.filters.offset && filter.limit == this.state.filters.limit) {
                this.setState(newState as IExerciseShowState);
                return;
            }

            let isFirst = false;

            // if it's the first element of the list

            if (listParameters.offset < 0) {
                isFirst = true;
                listParameters.offset = 0;
                listParameters.limit = 2;
            }

            if(libId == 0 || this.state.published){
                exerciseRecords = await PublicViewExercise.default.list(listParameters);               
            }else if(libId == LIBRARY_OFI_OFFICIAL_ID){
                exerciseRecords = await ViewEditorExcExercise.list(listParameters);
            }else{
                exerciseRecords = await ViewMyExcExercise.list(listParameters);
            }
            // we don't send the library id as filter to the server, but keep it, to pass it to the next page.
            listParameters.library_id = filter.library_id;

            let prevExId;
            let nextExId;

            if (exerciseRecords.length <= 1) {
                //do nothing in case of one element
                this.setState(newState as IExerciseShowState);
                return;
            }

            // the first or the last element
            else if (exerciseRecords.length == 2) {
                //if (this.state.isFirst) {
                if (isFirst) {
                    exercise = exerciseRecords[0];
                    nextExId = exerciseRecords[1].id;
                }
                else {
                    isFirst = false; // last element
                    prevExId = exerciseRecords[0].id;
                    exercise = exerciseRecords[1];
                }
            }
            else {
                prevExId = exerciseRecords[0].id;
                exercise = exerciseRecords[1];
                nextExId = exerciseRecords[2].id;
            }

            if ((this.state.nextExerciseId != nextExId || !this.state.nextExerciseId) && (this.state.prevExerciseId != prevExId || !this.state.prevExerciseId)) {
                newState = {
                    ...newState,
                    filters: listParameters,
                    nextExerciseId: nextExId,
                    prevExerciseId: prevExId,
                    isFirst,
                };
                this.setState(newState as IExerciseShowState);
                return;
            }

        } catch (e) {
            app.showErrorFromJsonResult(e);
        }
    }

    getNavigationFilter(isNext: boolean): any {
        let curr_offset = 0;
        if (!this.state.isFirst) {
            curr_offset = (isNext ? (this.state.filters.offset + 1) : (this.state.filters.offset - 1));
        }

        return {
            filter: JSON.stringify(this.state.filters.filter),
            order_by: JSON.stringify(this.state.filters.order_by),
            limit: 3,
            offset: curr_offset,
            library_id: this.state.filters.library_id
        };
    }
    private copyPublishedExercise = async (exerciseId: number) => {
        try {
            const newExercise = await Server.copyPublishedExercise(exerciseId, LIBRARY_PERSONAL_ID);
            // Itt nem kellene rá egy linket kiírni vagy valami?
            app.showSuccess(__("Feladat másolása"), __("Sikeres"))
        } catch (error) {
            app.showErrorFromJsonResult(error);
        }
    }

    render() {
        if (this.state.loading) {
            return <BubbleLoader />;
        }

        if (!this.state.published && !me) {
            app.showError(__("Hiba"), __("Nincs jogosultsága a tartalom megtekintésére!"));
            return "";
        }

        //this will not show the shared exercises
       /* if (!this.state.published && me && !hasAnyGroup(me, [Groups.Admin, Groups.OFIEditor, Groups.Developer]) && !this.state.canEdit) {
            app.showError(__("Hiba"), __("Nincs jogosultsága a tartalom megtekintésére!"));
            return "";
        }*/ 

        const sections = this.state.sections;

        return (
            <div className="row expanded exerciseList-maincomponent">
                <WorkflowDialog
                    open={this.state.workflowDialogOpen}
                    displayName={__("Feladat")}
                    tableInfoId={ExerciseCrud.TABLE_INFO_ID}
                    recId={Number(this.props.match.params.exerciseId)}
                    onClose={() => this.setState({ workflowDialogOpen: false })}
                    onTransition={this.setNavigation.bind(this)}
                    autoPublishOnClosedStation={true}
                />
                <div className="small-12 medium-8 medium-offset-2 column">

                    {
                        (this.state.prevExerciseId && !this.state.isFirst) ?
                            <Link className="button small eke-general-buttons float-left" to={getPath({PATH_EXERCISE_VIEW}, exerciseModule) + `/${this.state.prevExerciseId}/?${queryString.stringify(this.getNavigationFilter(false))}`}><i className="fa fa-chevron-left" /> {__("Előző feladat")}</Link>
                            : ""
                    }
                    {
                        (this.state.nextExerciseId) ?
                            <Link className="button small eke-general-buttons float-right" to={getPath({PATH_EXERCISE_VIEW}, exerciseModule) + `/${this.state.nextExerciseId}/?${queryString.stringify(this.getNavigationFilter(true))}`}><i className="fa fa-chevron-right" /> {__("Következő feladat")}</Link>
                            : ""
                    }
                </div>
                <div className="small-12 medium-8 medium-offset-2 column">

                    <ExerciseContainerComponent.default
                        ref="exercise_container"
                        key={this.props.match.params.exerciseId}
                        server={this.exerciseServer}
                    />
                    <div style={{ paddingBottom: "3rem" }}>
                        {
                            this.state.published ?
                                <Link className="button success" title={__("Vissza a feladattárhoz")} to={getPath({PATH_EXERCISE_PUB_LIST}, exerciseModule)}>
                                    <i className="fa fa-arrow-left"></i>&nbsp;{__("Vissza a feladattárhoz")}
                                </Link>
                                :
                                <Link className="button success" title={__("Vissza a feladatlistához")} to={getPath({PATH_EXERCISE_LIST}, exerciseModule)}>
                                    <i className="fa fa-arrow-left"></i>&nbsp;{__("Vissza a feladatlistához")}
                                </Link>
                        }
                        {this.state.canEdit &&
                            <Link className="button" title={__("Feladat szerkesztése")} to={getPath({PATH_EXERCISE_EDIT}, exerciseModule) + `/${this.props.match.params.exerciseId}`}>
                                <i className="fa fa-edit"></i>&nbsp;{__("Szerkesztés")}
                            </Link>
                        }
                        {
                            this.state.canEdit && !this.state.published && this.state.workflowStateName &&
                            <button className="button secondary" onClick={() => this.setState({ workflowDialogOpen: !this.state.workflowDialogOpen })}>
                                <i className="fa fa-project-diagram" />&nbsp;{__("Folyamat...")}
                            </button>
                        }

                        {(this.state.published && me && (hasAnyGroup(me, [Groups.Developer, Groups.Admin, Groups.Teacher, Groups.OFIEditor])) ?
                            <button key="new_copy" className="button success" onClick={this.copyPublishedExercise.bind(this, parseInt(this.props.match.params.exerciseId!))}>
                                <i className="fa fa-copy" />{__("Mentés másolatként")}
                            </button>
                            : ""
                        )}

                    </div>
                    <div>
                        {
                            (me && me.id && !this.state.published) ?
                                <MetaKeywordImmediateAssignerPlugin ref="keywordPlugin"
                                    tableInfoId={ExerciseCrud.TABLE_INFO_ID}
                                    recordId={Number(this.props.match.params.exerciseId)}
                                    key={"keywords_" + this.props.match.params.exerciseId}
                                />
                                : ""
                        }

                        {
                            this.state.inExerciseSeries && this.state.inExerciseSeries.length > 0 && this.state.inExerciseSeries[0].exercise_series_id != null
                                ? <div>
                                    <div className="eke-exerciseshow-tooltip">
                                        <br />
                                        <h4>{__("Ez a feladat az alábbi feladatsorokban szerepel:")}</h4>
                                        {this.state.inExerciseSeries.map((item: PrivateViewExerciseSeriesItem.IViewExerciseSeriesItemRecord) => {
                                            const url = getPath({PATH_EXERCISE_SERIES_PUB_VIEW}, exerciseModule) + "/" + item.exercise_series_id;
                                            return <Link to={url} className="eke-exerciseshow-graytitle">
                                                <span>{item.exercise_series_title} </span><br />
                                            </Link>
                                        })
                                        }
                                    </div>
                                    <br />
                                </div>
                                : ""
                        }


                        {
                            /* config.mainServer.showBooks && me && hasAnyGroup(me,[Groups.Admin, Groups.Developer, Groups.OFIEditor]) ?*/
                            sections && sections.length > 0 && sections[0].section_id != null
                                ? <div className="eke-exerciseshow-tooltip">
                                    <h4>{config.mainServer.showBooks && me ? __("Ehhez a leckéhez ajánljuk:") : __("Ez a feladat az alábbi tananyaghoz ajánlott:")}</h4>
                                    {sections.map((item: IViewExerciseSectionRecord) => {
                                        const url = this.state.published
                                            ? PATH_PUBLISHED_BOOK + "/" + item.book_uri_segment + "/" + item.lesson_uri_segment + "#section-" + item.section_id
                                            : PATH_BOOK_VIEW_EDITING + "/" + item.book_id + "/" + item.lesson_uri_segment + "#section-" + item.section_id;
                                        return <Link to={url} className="eke-exerciseshow-graytitle">
                                            <span>{item.book_name + " / " + item.chapter_name + " / " + item.lesson_name} </span><br />
                                        </Link>
                                    })
                                    }
                                </div>
                                : null
                        }
                        {this.state.searchResults.length > 0 &&
                            <div>
                                <h4>{__("Hasonló feladatok")}</h4>
                                <OwlCarousel
                                    ref="owlcarousel"
                                    key="owlcarousel"
                                    className="owl-theme eke-exerciseshow-similar"
                                    nav
                                    dots={false}
                                    responsive={{
                                        0: {
                                            items: 1,
                                            autoWidth: true,
                                            center: true
                                        },
                                        /*
                                        400: {
                                            items: 2
                                        },
                                        460: {
                                            items: 3
                                        },
                                        600: {
                                            items: 4
                                        },
                                        */
                                        640: {
                                            items: 2
                                        },
                                        900: {
                                            items: 3
                                        },
                                        1280: {
                                            items: 4
                                        },
                                        1600: {
                                            items: 5
                                        }
                                    }}
                                >
                                    {
                                        this.state.searchResults.map((r: Server.SearchResult) => {
                                            let engineName = undefined;
                                            const engine = this.state.engines.find((engine) => engine.id == r.engine_id);

                                            if (engine && engine.class_name) {
                                                engineName = engine.class_name;
                                            }
                                            return (<ExerciseTile className="item" key={"exetile" + r.id} ExerciseResult={r} published={this.state.published} engineName={engineName} />)
                                        })
                                    }
                                </OwlCarousel>
                            </div>
                        }

                    </div>

                </div>
            </div>
        );
    }
}
