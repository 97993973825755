import * as React from 'react';
import { __ } from '@src/translation';
import { action, computed } from 'mobx';
import { observer } from 'mobx-react';
import { forumStore, MessageListStore, TopicListStore, MemberListStore } from '../forumStore';
import { IForumMessage, IForumMessageModification } from '../forumAPI';
import { IForumMessageCardProps } from './ForumMessageCard';
import { AutoTextarea } from '@src/component/ui/AutoTextarea';

/**
 * Egy adott üzenet módosítását szerkeszteni, és a szerkesztéseket elküldeni tudó komponens.
 */
@observer
export class ForumMessageEditor extends React.Component<IForumMessageCardProps, {}> {

    @computed private get messageStore(): MessageListStore {
        return forumStore.getMessageListStore(this.props.forumId, this.props.topicId);
    }

    @computed private get messageModification(): IForumMessageModification | null {
        return this.messageStore.pendingModifications.get(this.props.messageId) || null;
    }

    private get isOperationPending() : boolean {
        return this.messageStore.isOperationPending(this.props.messageId);
    }

    private get isEditing() : boolean {
        return this.messageStore.isEditing(this.props.messageId);
    }    

    @action.bound private setModifiedMessage(message: string) {
        let modification = this.messageModification;
        if (modification === null) {
            this.messageStore.startEditing(this.props.messageId);
            modification = this.messageModification!;
        }
        modification.message = message;
        this.messageStore.pendingModifications.set(this.props.messageId, modification);
    }

    constructor(props: IForumMessageCardProps) {
        super(props);
        this.state = {};
    }

    private onSetModifiedMessage(e: React.ChangeEvent<HTMLTextAreaElement>) {
        this.setModifiedMessage(e.target.value);
    }

    @action.bound private sendModifications() {
        const modification = this.messageModification;
        if (modification) {
            this.messageStore.modifyMessage(modification);
        }
    }

    @action.bound private cancelModifications() {
        this.messageStore.cancelPending(this.props.messageId);
    }

    render() {
        //const message = this.message;
        const messageModification = this.messageModification;
        if (!messageModification) {
            return null;
        }
        const isOperationPending = this.isOperationPending;
        return <div className="messagebox-area">
            <b>Üzenet szerkesztése</b>
            <div className="message-edit">
                <AutoTextarea autoFocus={true}
                        text={messageModification.message || ""} onTextChange={(e) => this.onSetModifiedMessage(e)}>
                </AutoTextarea>                
                <button 
                    title={__("Módosít")} className="button primary"
                    onClick={() => this.sendModifications()}
                    disabled={isOperationPending}
                >
                    <i className="fas fa-paper-plane"></i> {__("Módosít!")}
                </button>
                <button
                    title={__("Mégse")} className="button secondary"
                    onClick={() => this.cancelModifications()}
                    disabled={isOperationPending}
                >
                    {__("Mégse")}
                </button>
            </div>
        </div>;
    }
}
