import * as React from 'react';
import * as _ from 'lodash';
import * as uuidv4 from 'uuid/v4';

import { IViewSecUserRecord, ViewSecUserClassProxy, viewSecUserClassProxy } from '@src/framework/view/sys/ViewSecUser';
import ListView, { ListViewProps } from '@framework/forms/listview';
import { app } from '@src/index';
import PersonCrud from '@src/framework/crud/sys/PersonCrud';
import SecUserCrud, { secUserCrudClassProxy } from '@src/framework/crud/sys/SecUserCrud';
import { Link } from 'react-router-dom';
import { PATH_SYS_USER_VIEW_SESSIONS } from '@src/component/sys/sys_routes';
import { me, hasGroup, Groups } from '@src/framework/server/Auth';
import { __ } from '@src/translation';

import { Dialog } from '@src/component/Dialog';
import { changePasswordOfUser } from '@src/server/PublicServer';


class SecUserInner extends ListView<IViewSecUserRecord> {
    private debouncedLookupExistingUser: any = undefined;

    constructor(props: ListViewProps) {
        super(props);
        let others: any = this.state.others || {};
        others.qa_email = "";
        others.qa_lastname = "";
        others.qa_firstname = "";
        others.qa_user_id = null;
        others.qa_loading = false;
        others.selectedItem = undefined;
        others.newPassword = "";
        others.confirmPassword = "";
        this.state = { ...this.state, others };
        this.debouncedLookupExistingUser = _.debounce(this.lookupExistingUser, 1000);
    }

    public getViewClassProxy(): ViewSecUserClassProxy { return viewSecUserClassProxy; }

    public getAllowInsertNew() : boolean {
        return false;
    }


    protected getRedirectUriListButton(record: IViewSecUserRecord): JSX.Element {
        return (
            <Link
                className="listview-button small" title={__("Bejelentkezések")}
                to={PATH_SYS_USER_VIEW_SESSIONS+"/"+record.id!}>
                <i className="fa fa-eye"></i>
            </Link>);
    }

    protected getRecordButtons(record: IViewSecUserRecord): JSX.Element[] {
        /* TODO: automatikus detail list menüt ide? */
        const result = super.getRecordButtons(record)
            .concat(this.getRedirectUriListButton(record));
            
            
        if (me && hasGroup(me, Groups.Admin)) {
            let passwordButton = this.getPasswordChangeButton(record);
            if(passwordButton) result.push(passwordButton);
        }

        return result;
    }

    protected getQuickFilterFieldNames(): string[] {
        return ['email', 'login_name', 'fullname'];
    }

    private lookupExistingUser = async (email: string) => {
        if (email) {
            let users = await SecUserCrud.list({ filter: { email } });
            if (users.length) {
                this.setState({ others: { ...this.state.others, qa_user_id: users[0].id!, qa_loading: false } })
                return;
            }
            users = await SecUserCrud.list({ filter: { login_name: email } });
            if (users.length) {
                this.setState({ others: { ...this.state.others, qa_user_id: users[0].id!, qa_loading: false } })
                return;
            }
        }
        this.setState({
            others: { ...this.state.others, qa_user_id: null, qa_loading: false }
        });
    }

    private setOther = (fname: string, value: string) => {
        let others = { ...this.state.others };
        others[fname] = value;
        if (fname == 'qa_email') {
            others.qa_loading = true;
        }
        this.setState({ others }, () => {
            if (fname == 'qa_email') {
                this.debouncedLookupExistingUser(value)
            }
        });
    }

    private onQuickAdd = async () => {
        let others = this.state.others;
        const email = others.qa_email.trim().toLowerCase();
        const firstname = others.qa_firstname.trim();
        const lastname = others.qa_lastname.trim();

        if (!email || !firstname || !lastname) {
            app.showError(__("Hiba"),__( 'Nem adott meg minden adatot.'));
            return;
        }
        var regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        if (!regex.test(email)) {
            app.showError(__("Hiba"),__( 'Ez nem tűnik érvényes e-mail címnek.'));
            return;
        }

        const fullname = lastname + ' ' + firstname;

        try {
            let users = await SecUserCrud.list({ filter: { email } });
            if (users.length) {
                app.showError(__("Hiba"),__( "Ilyen felhasználó már létezik:  email=" )+ email);
                this.setState({ others: { ...this.state.others, qa_user_id: users[0].id! } })
                return;
            }
            users = await SecUserCrud.list({
                filter: {
                    login_name: email
                }
            });
            if (users.length) {
                app.showError(__("Hiba"),__( "Ilyen felhasználó már létezik: login_name=" )+ email);
                this.setState({ others: { ...this.state.others, qa_user_id: users[0].id! } })
                return;
            }


            let persons = await PersonCrud.list({ filter: { email } });
            let person;
            if (persons.length) {
                app.showInfo(__("Figyelem!"),
                    __("Ilyen személy már létezik: email={email}, de a felhasználót létre tudom hozni.", {email}));
                person = persons[0];
            } else {
                person = (await (new PersonCrud(
                    { firstname, lastname, fullname, email, status_id: 1 })).put()).record;
            }

            let user = (await (new SecUserCrud({
                login_name: email,
                email,
                is_technical: false,
                person_id: person.id!,
                email_confirmed: 'now()',
                password_reset_code: uuidv4(),
                force_pwd_change: true
            })).put()).record;

            app.showSuccess(
                __("Sikeres fölvétel"),
                __("Felhasználó fölvéve, id=") + user.id!
            );
            this.setState({
                others: {
                    ...this.state.others,
                    qb_user_id: user.id!,
                    qb_loading: false
                }
            }, this.asyncReload);
        } catch (error) {
            app.showErrorFromJsonResult(error);
        }
    }

    protected getFooter(): JSX.Element[] {
        let result = super.getFooter();
        let others = this.state.others || {};
        result.push(
            <div key="quickAdd">
                <h4>{__("Új Felhasználó hozzáadása")}</h4>
                <fieldset className="large-12 row">                    
                    <div className="column small-12 large-12">
                        <p className="callout primary">
                            {__("Gyorsan létrehozhat egy új felhasználót.")}
                            {__("Kérem ellenőrizze az e-mail")}
                            {__("cím helyességét, mert a művelet nem vonható vissza!")}
                            {__("A létrehozás után a felhasználó kapni fog egy jelszó")}
                            {__("reset e-mailt.")}
                        </p>
                    </div>
                    <div className="column small-12 large-12">
                        <label htmlFor="qf_email">E-mail</label>
                        <input
                            id="qf_email"
                            type="text" value={others.qa_email || ""}
                            placeholder={__("Ezt duplán ellenőrizze le, mert nem javítható!")}
                            onChange={(e: any) => { this.setOther("qa_email", e.target.value) }}
                        />
                    </div>
                    <div className="column small-12 large-12">
                        {(this.state.others.qa_loading) ?
                            <label className="label warning">{__("Keresés, kérem várjon...")}</label>
                            : (this.state.others.qa_user_id
                                ? <Link to={secUserCrudClassProxy.getEditUrl(this.state.others.qa_user_id)}>
                                    <label className="label error">
                                        {__("Létező felhasználó") + " "}<b>{this.state.others.qa_email} </b>
                                    </label> {__("Kattintson ide a szerkesztéshez.")}
                            </Link>
                                : (this.state.others.qa_email ?
                                    <label className="label success">{__("Nem létezik felhasználó ezzel a címmel.")}</label>
                                    : null
                                )
                            )
                        }
                    </div>
                    <div className="column small-12 large-6">
                        <label htmlFor="qf_email">{__("Vezetéknév")}</label>
                        <input
                            id="qf_email"
                            type="text" value={others.qa_lastname || ""}
                            onChange={(e: any) => { this.setOther("qa_lastname", e.target.value) }}
                        />
                    </div>
                    <div className="column small-12 large-6">
                        <label htmlFor="qf_email">{__("Keresztnév")}</label>
                        <input
                            id="qf_email"
                            type="text" value={others.qa_firstname || ""}
                            onChange={(e: any) => { this.setOther("qa_firstname", e.target.value) }}
                        />
                    </div>
                    <div className="column small-12 large-12">
                        <button type="button" className="button primary"
                            onClick={this.onQuickAdd}
                            disabled={this.state.others.qa_loading || this.state.others.qa_user_id
                                || !this.state.others.qa_email || !this.state.others.qa_firstname || !this.state.others.qa_lastname}
                        >
                            <i className="fa fa-save" />{" " + __("Létrehoz!")}
                        </button>

                    </div>
                </fieldset>
            </div>
        )
        return result;
    }

    protected getPasswordChangeButton(record: IViewSecUserRecord): JSX.Element | null {
        let cls = "listview-button listview-button-success";
        let iconClass = "fa fa-key";
        return (
            <button
                key="showPwModal"
                title={__("Jelszó módosítása")}
                className={cls}
                onClick={() => {
                    let others = this.state.others;
                    others.selectedItem = record;
                    this.setState({ showPasswordModal: true, others})
                }}
            >
                <i className={iconClass} /></button>);
    }
    

    protected onNewPwChange(event: any) {
        let others = this.state.others;
        others.newPassword = event.target.value;
        this.setState({ others })
    }
    protected onConfirmPwChange(event: any) {
        let others = this.state.others;
        others.confirmPassword = event.target.value;
        this.setState({ others });
    }


    protected async onSaveButtonClick() {
        let others = this.state.others;
        try {
            if (others.confirmPassword != others.newPassword) app.showError(__("Hiba!"), __("A két jelszó nem egyezik!"))
            else {
               let response= await changePasswordOfUser(others.selectedItem!.id!,others.confirmPassword);
               if(response.successful)
               {
                others.selectedItem = undefined;
                others.newPassword = "";
                others.confirmPassword = "";
                this.setState({ showPasswordModal: false, others})
                app.showSuccess(__("A jelszó módosítása"), __("Sikeres"));
                }           
               else app.showError(__("Sikertelen jelszóváltoztatás"),response.error_message);           
            }
        }
        catch (error) {
            app.showErrorFromJsonResult(error);
        }
    }    
    
    protected getHeader () : JSX.Element | null {
        let result : JSX.Element | null = super.getHeader();
        let dlg : JSX.Element | null = null;        
        const others = this.state.others;        
        if (this.state.showPasswordModal) {
            dlg =  <Dialog title={__("Jelszó megváltoztatása")} width={1050} onClose={() => {
                    let others = this.state.others;
                    others.selectedItem = undefined;
                    others.newPassword = "";
                    others.confirmPassword = "";
                    this.setState({ showPasswordModal: false, others})}
                } >                
                <div className="small-12 column">
                <div className="small-12 medium-12 large-12 column">
                         <p>{__("Új jelszó beállítása")} {others.selectedItem?others.selectedItem.fullname:others.selectedItem.displayvalue} {__("számára...")}</p>
                    </div>
                    <div className="small-12 medium-12 large-12 column">
                        <label className="lms-pages-typeLabel">{__("Új Jelszó")}</label>
                        <input className="newsubmenu-url" type="password" value={others.newPassword || ""} onChange={this.onNewPwChange.bind(this)} />
                    </div>
                    <div className="small-12 medium-12 large-12 column">
                        <label className="lms-pages-typeLabel">{__("Új jelszó megerősítése")}</label>
                        <input className="newsubmenu-url" type="password" value={others.confirmPassword || ""} onChange={this.onConfirmPwChange.bind(this)} />
                    </div>
                    <div className="small-12 medium-12 column">
                        <button className="button small success eke-general-buttons" style={{ float: "right" }} onClick={this.onSaveButtonClick.bind(this)}>
                            <i className="fa fa-save" />   {__("Jelszó módosítása")}
                        </button>
                    </div>
                </div>
            </Dialog>
        }
        if (result && dlg) {
            return <div>
                {result}
                {dlg}
            </div>
        } else if (result) {
            return result
        } else {
            return dlg;
        }
    }
}


export default function SecUserListView(props: any) {
    // TODO: hozzáadni a person-os oszlopokat a view-hoz hogy látszódjon a 
    // rendes nevük!
    return <SecUserInner
        defaultPageSize={10}
        match={props.match}
        viewColumns={[
            { accessor: "id", maxWidth: 100 },
            { accessor: "login_name" },
            { accessor: "fullname" },
            /*
            {accessor: "creation_time"},
            {accessor: "creator"},
            {accessor: "modification_time"},
            {accessor: "modifier"},
            */
            { accessor: "link", Header: __("Műveletek"), maxWidth: 200 },
        ]}
    />
}
