import { Server } from '../../server/Server';
import View , { registerViewClassProxy, ViewClassProxy } from '../View';
import { IListParams } from '@framework/crud/Crud';

export interface IViewItemRecord {
    /**
     * Azonosító
     *
     * Azonosító (pótkulcs)
     */
    id?: number;
    /**
     * Rekord létrehozás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel létrehozásakor.
     */
    creation_time?: string /*timestamp?*/;
    /**
     * Ki hozta létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozó felhasználóra hivatkozik.
     */
    creation_user_id?: number;
    /**
     * Melyik session-ből jött létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozáshoz használt munkamenetre hivatkozik.
     */
    creation_session_id?: number|null;
    /**
     * Utolsó módosítás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel módosításakor.
     */
    modification_time?: string /*timestamp?*/|null;
    /**
     * Ki módosította utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosító felhasználóra hivatkozik.
     */
    modification_user_id?: number|null;
    /**
     * Melyik session-ből módosították utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosításhoz használt munkamenetre hivatkozik.
     */
    modification_session_id?: number|null;
    /**
     * Aktív
     *
     * Logikai törléshez használt mező.
     */
    is_active?: boolean;
    /**
     * Leírás
     */
    description?: string;
    /**
     * Tudásbázis bejegyzés típus
     */
    type_id?: number;
    /**
     * Tárgy
     */
    subject?: string;
    /**
     * Kulcsszavak
     */
    keywords?: string;
    /**
     * Tároló
     *
     * Az a mappa, ami a bejegyzéshez tartozó médiákat tárolja.
     */
    oo_folder_id?: number|null;
    /**
     * Kereshető szöveg
     *
     * Kereshető szöveg
     */
    search_field?: string|null;
    /**
     * Módosító
     *
     * Módosító
     */
    modifier?: string|null;
    /**
     * Létrehozó
     *
     * Létrehozó
     */
    creator?: string|null;
    /**
     * Típus
     *
     * Típus
     */
    item_type_title?: string|null;
}

/**
 *  kb.view_item - Tudásbázis bejegyzés
 *
 *  Idősoros táblázat, ami az összes tétel összes korábbi verzióját eltárolja.
 */

export default class ViewItem extends View<IViewItemRecord> {
    public static VIEW_INFO_ID = 2018102401;

    public static getSchemaNameForClass(): string { return 'kb'; }
    public static getViewNameForClass(): string { return 'view_item'; }
    public static getTableNameForClass(): string|null { return 'item'; }

    public static load: (id: number, aServer ?: Server) => Promise<ViewItem>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<IViewItemRecord[]>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class ViewItemClassProxy extends ViewClassProxy<IViewItemRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const viewItemClassProxy = new ViewItemClassProxy(ViewItem);
registerViewClassProxy(viewItemClassProxy);

