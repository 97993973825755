//Ha nincs kommentelve akkor exerciseEngineIsNotAConstructor hibát dob
// import {Evaluated} from "../../Framework/Server";

import './style.css';
import { ExerciseBaseClass, NavigationDirections, AnswerElement } from '../../models/ExerciseBaseClass';
import polylabel from 'polylabel';
import { ExerciseEngineHelper } from '../ExerciseEngineHelper';
import { AExerciseEngine, ExerciseEngineSubSeries } from '../../models/AExerciseEngine';
import { WMAssignPointToQuestionServer } from './WMAssignPointToQuestionServer';

export interface WMPairPointData extends ExerciseBaseClass {
    answers: WMPQAnswers[];
    image_dimensions: WMPQDimensions;
    ordered: boolean;
}
type WMPQDimensions = {
    width: number;
    height: number;
}
type WMPQAnswers = {
    answerElement: AnswerElement;
    pins: string;
}

type WMDPairPointSolution = {
    fullmatch: boolean;
    answer: any;
}

export class WMAssignPointToQuestionExerciseEngine extends ExerciseEngineSubSeries {
    private answerList: any[] = [];
    private interaction: boolean = false;
    private dimensions: WMPQDimensions = { width: 0, height: 0 };

    private droppedPins: HTMLElement[] = [];
    private questionCounter = 0;
    private userSolutions: any[] = [];
    private original_indexes: number[] = [];
    private wait: any;
    private mainImageLoad: any;
    private clearWrongAnsTimeout: any;

    click(ev: any): void {
        if (this.questionCounter >= this.answerList.length)
            return;

        let droppablePinNumber = this.answerList[this.questionCounter].pins;
        if (this.droppedPins.length < droppablePinNumber) {
            let left = ev.offsetX ? (ev.offsetX) : ev.pageX;
            //let top = ev.offsetY ? (ev.offsetY) : ev.pageY - ev.target.offsetTop;
            let top = ev.offsetY ? (ev.offsetY) : ev.pageY;
            //this.createPin(top, left - 6);
            this.createPin(top, left);
            this.changeInteraction(true);
            let cntr: HTMLElement | null = this.root.querySelector('#answer-counter');
            if (cntr) {
                cntr.innerHTML = this.droppedPins.length + "/" + droppablePinNumber;
            }
            if (this.isSNIexc) this.SNIEvaluation(WMAssignPointToQuestionServer);
        }

    }

    click_on_pin(ev: any) {
        let element = ev.target ? ev.target : ev;
        if (ev.target && ev.target.tagName != "svg") element = ev.target.parentElement;
        let droppablePinNumber = this.answerList[this.questionCounter].pins;
        let pin: HTMLElement = this.droppedPins.splice(this.droppedPins.indexOf(element), 1)[0];
        pin.parentElement!.removeChild(pin);
        let cntr: HTMLElement | null = this.root.querySelector('#answer-counter');
        if (cntr) {
            cntr.innerHTML = this.droppedPins.length + "/" + droppablePinNumber;
        }
    }

    initExercise(params: ExerciseParams): void {
        super.initExercise(params);
        let exercise: WMPairPointData = params.exercise;
        this.reloadResourceFunc = params.reloadResources;

        if (!exercise || !exercise.answers || exercise.answers.length == 0)
            return;

        this.exercise = params.exercise;
        this.userReadyWithSeriesFunc = params.userReadyWithSubSeries;

        /* Storing the indexes of the answer elements for further evaulation */
        exercise.answers.forEach((el, i) => {
            if (el.answerElement.type != "") this.original_indexes.push(i);
        });
        /*We don't need to handle the unfiltered answer elements*/
        this.answerList = exercise.answers.filter(x => x.answerElement.type != "");

        this.questionCounter = 0;
        this.droppedPins = [];
        // this.droppablePunNumber = [];
        this.changeInteraction(false);


        this.root.classList.add("wm-pair-pont-question-engine");
        this.root.classList.add("text-center");

        //let gridDiv = this.root.appendChild(document.createElement("div"));
        //gridDiv.classList.add("row");

        //let exerciseWrapperDiv = gridDiv.appendChild(document.createElement("div"));
        //exerciseWrapperDiv.classList.add("grid-x", "grid-padding-x", "align-middle", "row");
        //exerciseWrapperDiv.classList.add("columns", "text-center");

        this.mainImageLoad = new Promise<number>((resolve) => {
            this.wait = resolve;
        });

        let exerciseWrapper = this.root.appendChild(document.createElement('div'));
        exerciseWrapper.classList.add('exercise-wrapper');

        if (exercise.illustration && exercise.illustration != "" && exercise.illustration != null) {
            //Háttérkép
            let imageWrapper = exerciseWrapper.appendChild(document.createElement("div"));
            imageWrapper.classList.add("image-wrapper");

            let image = imageWrapper.appendChild(document.createElement("img"));
            image.onload = this.setDimensions.bind(this, image);

            /* If media type is image, limit max width */
            image.src = ExerciseEngineHelper.getMediaMaxWidthUrl(exercise.imagebasepath + exercise.illustration);
            image.classList.add("clicky-image");
            image.classList.add("exe-img-no-zoom");
            image.setAttribute("alt", "");

            if (!this.isReplay) {
                image.onclick = this.click.bind(this);
            }


        }

        let taskWrapperDiv = exerciseWrapper.appendChild(document.createElement("div"));
        taskWrapperDiv.classList.add("task-wrapper");

        let answerItem = taskWrapperDiv.appendChild(document.createElement("div"));
        let answerCounterWrapper = taskWrapperDiv.appendChild(document.createElement("div"));
        answerCounterWrapper.classList.add("answer-info");
        //answerCounterWrapper.innerHTML = 'Letett pontok: ';

        /* Create info ping */
        var parser = new DOMParser();
        var doc = parser.parseFromString('<svg height="16px" id="Layer_1" style="enable-background:new 0 0 16 16;" version="1.1" viewBox="0 0 16 16" width="16px" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><path d="M8,0C4.687,0,2,2.687,2,6c0,3.854,4.321,8.663,5,9.398C7.281,15.703,7.516,16,8,16s0.719-0.297,1-0.602  C9.679,14.663,14,9.854,14,6C14,2.687,11.313,0,8,0z M8,10c-2.209,0-4-1.791-4-4s1.791-4,4-4s4,1.791,4,4S10.209,10,8,10z M8,4  C6.896,4,6,4.896,6,6s0.896,2,2,2s2-0.896,2-2S9.104,4,8,4z"/></svg>', "image/svg+xml");
        let svgPin = doc.documentElement;
        svgPin.classList.add("pin-info");
        answerCounterWrapper.appendChild(svgPin!);

        let answerCounter = answerCounterWrapper.appendChild(document.createElement("div"));
        answerCounter.setAttribute('id', 'answer-counter');
        answerItem.style.minWidth = "150px";
        answerCounter.innerHTML = '0/' + this.answerList[0]['pins'];
        answerItem.setAttribute("id", "answer-item");
        answerItem.classList.add("answer-item");
        AExerciseEngine.displayAnswer(answerItem, this.answerList[0].answerElement, this.is_accessible, [], this);

        if (this.reloadResourceFunc) this.reloadResourceFunc();
    }

    public changeInteraction(bool: boolean) {
        this.interaction = bool;
    }

    getUserSolution(): WMDPairPointSolution {
        let answer: any = { answerindex: this.original_indexes[this.questionCounter], points: [] };
        for (let i = 0; i < this.droppedPins.length; i++) {
            let x = Math.round(Number(this.droppedPins[i].getAttribute("x")!));
            let y = Math.round(Number(this.droppedPins[i].getAttribute("y")!));
            let point: number[] = [x, y];
            answer.points.push(point);
        }
        this.userSolutions[this.original_indexes[this.questionCounter]] = answer;
        if (this.isExamMode && (this.questionCounter + 1) == this.answerList.length) {
            this.userReadyWithSeriesFunc(true);
        }
        return { answer: this.userSolutions, fullmatch: false };
    }

    receiveEvaluation(evaluated: Evaluated): void {
        /* delete Clear Wrong Answers timeout */
        clearTimeout(this.clearWrongAnsTimeout);

        if (evaluated.success) {
            this.droppedPins.map((x) => {
                x.classList.add('svg-pin-correct');
                x.classList.remove('svg-pin-wrong');
            });
        }
        else {
            let userSolution = this.getUserSolution().answer[this.original_indexes[this.questionCounter]];
            let correctSolution = evaluated.solution;
            var cur_solution = correctSolution.find((x: any) => {
                return x.answerindex == userSolution.answerindex
            });
            var copySolutionPoints = cur_solution.points.slice();

            if (cur_solution && cur_solution.points.length > 0) {
                let circle: any = {};
                for (var i = 0; i < userSolution.points.length; i++) {
                    circle.centerX = userSolution.points[i][0];
                    circle.centerY = userSolution.points[i][1];
                    for (var j = 0; j < copySolutionPoints.length; j++) {
                        //We need polygon evaluation
                        if (copySolutionPoints[j].points) {
                            let isInsidePoly = ExerciseEngineHelper.isPointInsidePolygon([circle.centerX, circle.centerY], copySolutionPoints[j].points)
                            if (isInsidePoly) {
                                this.droppedPins[i].classList.add('svg-pin-correct');
                                copySolutionPoints.splice(j, 1);
                            }
                            else {
                                this.droppedPins[i].classList.add('svg-pin-wrong');
                            }
                        }
                        else {
                            //We need circle evaluate
                            let x: number = copySolutionPoints[j].x;
                            let y: number = copySolutionPoints[j].y;
                            let radius: number = copySolutionPoints[j].radius;
                            let dist: number = Math.sqrt((x - circle.centerX) * (x - circle.centerX) + (y - circle.centerY) * (y - circle.centerY));
                            if (dist <= radius) {
                                this.droppedPins[i].classList.add('svg-pin-correct');
                                copySolutionPoints.splice(j, 1);
                            }
                            else {
                                this.droppedPins[i].classList.add('svg-pin-wrong');
                            }
                        }
                    }
                }
            }

        }
        if ((this.questionCounter + 1) == this.answerList.length) {
            this.userReadyWithSeriesFunc(evaluated.success);
        }

        /* IF SNI, clear all wrong answers with delay */
        if (this.isSNIexc) {
            this.clearWrongAnsTimeout = setTimeout(() => {
                for (var i = 0; i < this.droppedPins.length; i++) {
                    if (this.droppedPins[i].classList.contains("svg-pin-wrong") && !this.droppedPins[i].classList.contains("svg-pin-correct")) {
                        this.droppedPins[i].classList.remove('svg-pin-wrong');
                        this.click_on_pin(this.droppedPins[i]);
                    }

                }

            },
            2000);
        }
    }

    setDimensions(ill: any) {
        this.dimensions = { width: ill.width, height: ill.height }
        this.wait(0);
    }

    async showCorrectSolution(solution: any): Promise<any> {
        let image: HTMLElement | null = this.root.querySelector(".clicky-image");

        if (!image) return;

        //We need to wait for the loading of image to place the pins
        const wImgLoad = await this.mainImageLoad;

        for (let i = 0; i < this.droppedPins.length; i++) {
            $(this.droppedPins[i]).remove();
        }

        this.droppedPins = [];

        let assingment = solution[this.original_indexes[this.questionCounter]];

        for (let i = 0; i < assingment.points.length; i++) {
            let currX = this.isReplay ? assingment.points[i][0] : assingment.points[i].x;
            let currY = this.isReplay ? assingment.points[i][1] : assingment.points[i].y;
            if (!assingment.points[i].points) {
                //Circle solution
                let x = (currX / 100) * this.dimensions.width;
                let y = (currY / 100) * this.dimensions.height;
                this.createPin(y, x)
            }
            else {
                //Polygon solution
                let polylabelCoords = ExerciseEngineHelper.getPolyCoordinatePairs(assingment.points[i].points);
                var centerPoint = polylabel([polylabelCoords], 1.0);
                this.createPin((centerPoint[1] / 100) * this.dimensions.height, (centerPoint[0] / 100) * this.dimensions.width);
            }
        }

        for (let index = 0; index < this.droppedPins.length; index++) {
            if (!this.isReplay) this.droppedPins[index].classList.add('svg-pin-show-correct');
        }

        this.changeInteraction(true);

    }

    isUserReady(): boolean {
        return this.interaction
    }

    showHelp(solution: any): void {
        this.showCorrectSolution(solution);
    }


    private leftPercentageFromPosition(pos: number): any {
        let image: any = this.root.querySelector(".clicky-image");
        return (pos / image.offsetWidth * 100);
    }

    private topPercentageFromPosition(pos: number): any {
        let image: any = this.root.querySelector(".clicky-image");
        return (pos / image.offsetHeight * 100);
    }

    private createPin(top: any, left: any) {
        let gridDiv: any = this.root.querySelector(".image-wrapper");
        var parser = new DOMParser();
        //var doc = parser.parseFromString('<svg version="1.0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 830.000000 1280.000000" preserveAspectRatio="xMidYMid meet"><g transform="translate(0.000000,1280.000000) scale(0.100000,-0.100000)" fill="#FF8C00" stroke="black" stroke-width="5"><path d="M3855 12789 c-555 -44 -1043 -176 -1530 -414 -1457 -712 -2370 -2223 -2322 -3840 19 -605 152 -1155 406 -1680 109 -225 183 -353 331 -575 65 -96 856 -1369 1760 -2827 903 -1459 1646 -2653 1650 -2653 4 0 747 1194 1650 2652 904 1459 1695 2732 1760 2828 148 222 222 350 331 575 421 869 520 1869 279 2821 -244 958 -822 1795 -1640 2371 -696 491 -1551 759 -2404 752 -94 -1 -216 -5 -271 -10z m635 -1764 c440 -80 813 -271 1120 -575 769 -761 825 -1980 130 -2812 -335 -402 -817 -663 -1344 -728 -114 -14 -378 -14 -492 0 -853 105 -1550 715 -1764 1544 -141 545 -52 1136 243 1613 330 531 862 876 1497 968 130 19 481 13 610 -10z"/></g></svg>', "image/svg+xml");
        var doc = parser.parseFromString('<svg height="16px" id="Layer_1" style="enable-background:new 0 0 16 16;" version="1.1" viewBox="0 0 16 16" width="16px" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><path d="M8,0C4.687,0,2,2.687,2,6c0,3.854,4.321,8.663,5,9.398C7.281,15.703,7.516,16,8,16s0.719-0.297,1-0.602  C9.679,14.663,14,9.854,14,6C14,2.687,11.313,0,8,0z M8,10c-2.209,0-4-1.791-4-4s1.791-4,4-4s4,1.791,4,4S10.209,10,8,10z M8,4  C6.896,4,6,4.896,6,6s0.896,2,2,2s2-0.896,2-2S9.104,4,8,4z"/></svg>', "image/svg+xml");
        let svgPin = doc.documentElement;
        gridDiv.appendChild(svgPin!);

        svgPin.classList.add("pin", "svg-pin");
        svgPin.style.left = this.leftPercentageFromPosition(Math.floor(left)) + "%";
        svgPin.style.top = this.topPercentageFromPosition(Math.floor(top)) + "%";
        svgPin.setAttribute("id", "" + top + left);
        let newX = (Math.floor(left) / this.dimensions.width) * 100;
        let newY = (Math.floor(top) / this.dimensions.height) * 100;

        svgPin.setAttribute("x", newX.toString());
        svgPin.setAttribute("y", newY.toString());

        if (!this.isSNIexc) svgPin.onclick = this.click_on_pin.bind(this);
        this.droppedPins.push(svgPin);

    }

    public getNextSubQuestion(direction: NavigationDirections): SubSeriesQuestionData {
        if (this.questionCounter + direction == this.answerList.length
            || this.questionCounter + direction < 0)
            return { is_next_available: false, number_of_questions: this.answerList.length, current_question_index: this.questionCounter };

        this.nextQuestion(direction);
        return { is_next_available: true, number_of_questions: this.answerList.length, current_question_index: this.questionCounter };
    }

    private nextQuestion(direction: number) {
        if (direction > 0)
            this.questionCounter++;
        else if (this.questionCounter > 0)
            this.questionCounter--;


        for (let i = 0; i < this.droppedPins.length; i++) {
            $(this.droppedPins[i]).remove();
        }

        this.droppedPins = [];
        let cntr: HTMLElement | null;

        if (this.questionCounter + 1 > this.answerList.length)
            return;

        var cur_label = this.root.querySelector('[id=\"processLabel\"]');
        if (cur_label)
            cur_label.textContent = (this.questionCounter + 1).toString();


        cntr = this.root.querySelector('#answer-counter');
        if (cntr) {
            cntr.innerHTML = "0/" + this.answerList[this.questionCounter].pins;
        }

        cntr = this.root.querySelector('#answer-item');
        if (cntr && this.answerList[this.questionCounter].answerElement.type == "image") {
            if ($(cntr).has("img")) {
                cntr.innerHTML = '';
            }
        }
        else if (cntr && this.answerList[this.questionCounter].answerElement.type == "sound") {
            cntr.innerHTML = '';
        }
        if (cntr) {
            cntr.innerHTML = '';
            AExerciseEngine.displayAnswer(cntr, this.answerList[this.questionCounter].answerElement, this.is_accessible, [], this);
        }

        if (this.reloadResourceFunc) this.reloadResourceFunc();
    }


}