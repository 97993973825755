import { Server } from '../../server/Server';
import View , { registerViewClassProxy, ViewClassProxy } from '../View';
import { IListParams } from '@framework/crud/Crud';

export interface IViewSecUserRecord {
    /**
     * Azonosító
     *
     * Azonosító (pótkulcs)
     */
    id?: number;
    /**
     * Rekord létrehozás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel létrehozásakor.
     */
    creation_time?: string /*timestamp?*/;
    /**
     * Ki hozta létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozó felhasználóra hivatkozik.
     */
    creation_user_id?: number;
    /**
     * Melyik session-ből jött létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozáshoz használt munkamenetre hivatkozik.
     */
    creation_session_id?: number|null;
    /**
     * Utolsó módosítás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel módosításakor.
     */
    modification_time?: string /*timestamp?*/|null;
    /**
     * Ki módosította utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosító felhasználóra hivatkozik.
     */
    modification_user_id?: number|null;
    /**
     * Melyik session-ből módosították utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosításhoz használt munkamenetre hivatkozik.
     */
    modification_session_id?: number|null;
    /**
     * Aktív
     *
     * Logikai törléshez használt mező.
     */
    is_active?: boolean;
    /**
     * Bejelentkezési név
     */
    login_name?: string;
    /**
     * Jelszó
     *
     * Ha nincs kitöltve, akkor nem tud bejelentkezni helyi jelszavas azonosítással.
     */
    password_hash?: string|null;
    /**
     * Elsődleges e-mail
     */
    email?: string;
    /**
     * Másodlagos e-mail
     */
    email2?: string|null;
    /**
     * Felettes
     */
    reports_to_id?: number|null;
    /**
     * Személy
     *
     * Az a természetes személy, akihez a felhasználó tartozik
     */
    person_id?: number;
    /**
     * Bejelentkezési kísérletek száma
     */
    login_attempt?: number /*int4*/|null;
    /**
     * Mikortól kezdve tud bejelentkezni
     */
    valid_from?: string /*timestamp?*/|null;
    /**
     * Meddig tud bejelentkezni
     */
    valid_until?: string /*timestamp?*/|null;
    /**
     * Kötelező jelszót változtatnia
     *
     * A következő bejelentkezéskor kötelező megváltoztatni a jelszavát. Ha ez nem sikerül, akkor a bejelentkezése sem sikerül.
     */
    force_pwd_change?: boolean;
    /**
     * Bejelentkezési csoport
     */
    default_group_id?: number|null;
    /**
     * Technikai
     */
    is_technical?: boolean;
    /**
     * Kitiltás indoklása
     */
    login_disabled_reason?: string|null;
    /**
     * Munkamenet lejárati ideje.
     */
    session_timeout_minutes?: number /*int4*/|null;
    /**
     * Utoljára látva
     */
    ping_time?: string /*timestamp?*/|null;
    /**
     * Saját mappa
     *
     * A felhasználó saját személyes mappája
     */
    oo_folder_id?: number;
    /**
     * Azonosító NKP 1.0 rendszerben
     */
    nkp1_id?: number|null;
    /**
     * Jelszó igénylő kód
     */
    password_reset_code?: string|null;
    /**
     * Jelszó igénylő e-mail kiküldésének időpontja
     */
    password_reset_code_sent?: string /*timestamp?*/|null;
    /**
     * E-mail cím megerősítő kód
     */
    email_confirm_code?: string|null;
    /**
     * E-mail cím megerősítő kód kiküldésének időpontja
     */
    email_confirm_code_sent?: string /*timestamp?*/|null;
    /**
     * Másodlagos e-mail cím megerősítő kód
     */
    email2_confirm_code?: string|null;
    /**
     * Másodlagos e-mail cím megerősítő kód kiküldésének időpontja
     */
    email2_confirm_code_sent?: string /*timestamp?*/|null;
    /**
     * E-mail cím megerősítve
     */
    email_confirmed?: string /*timestamp?*/|null;
    /**
     * Másodlagos e-mail cím megerősítve
     */
    email2_confirmed?: string /*timestamp?*/|null;
    /**
     * E-mail cím megerősítő kiküldés kérelem időpontja
     */
    email_confirm_code_requested?: string /*timestamp?*/|null;
    /**
     * Másodlagos e-mail cím megerősítő kiküldés kérelem időpontja
     */
    email2_confirm_code_requested?: string /*timestamp?*/|null;
    /**
     * Felhasználó elérhetősége
     */
    user_availibility_id?: number;
    /**
     * Kvóta
     */
    soft_quota?: number|null;
    /**
     * Kvóta (hard limit)
     */
    hard_quota?: number|null;
    /**
     * Felhasznált hely
     */
    disk_space_used?: number;
    /**
     * TOTP kulcs
     */
    totp?: string|null;
    /**
     * TOTP megerősítve
     */
    totp_confirmed?: string /*timestamp?*/|null;
    /**
     * Szakmentor
     */
    prof_mentor_id?: number|null;
    /**
     * Neptun kód
     */
    neptun_code?: string|null;
    /**
     * Elsődleges ellátási hely
     *
     * A felhasználó elsődleges feladatellátási helye, tanárként.
     */
    primary_institute_site_id?: number|null;
    /**
     * Létrehozta
     *
     * Felhasználó
     */
    creator?: string|null;
    /**
     * Módosító
     *
     * Felhasználó, aki utoljára módosította
     */
    modifier?: string|null;
    /**
     * Teljes név
     *
     * Felhasználóhoz tartozó személy teljes neve
     */
    fullname?: string|null;
    /**
     * Kijelzett név
     *
     * Szöveges reprezentáció
     */
    displayvalue?: string|null;
    /**
     * Elsődleges ellátási hely OM azonosító
     *
     * Elsődleges ellátási hely OM azonosító
     */
    primary_om_identifier?: string|null;
    /**
     * Elsődleges ellátási hely ellátási hely azonosító
     *
     * Elsődleges ellátási hely ellátási hely azonosító
     */
    primary_site_identifier?: string|null;
    /**
     * Elsődleges ellátási hely név
     *
     * Elsődleges ellátási hely név
     */
    primary_institute_site_name?: string|null;
}

/**
 *  sys.view_sec_user - Alkalmazás szintű felhasználó
 *
 *  Az akalmazás szintű felhasználók az azonosítás eszközei.
 */

export default class ViewSecUser extends View<IViewSecUserRecord> {
    public static VIEW_INFO_ID = 201804171048;

    public static getSchemaNameForClass(): string { return 'sys'; }
    public static getViewNameForClass(): string { return 'view_sec_user'; }
    public static getTableNameForClass(): string|null { return 'sec_user'; }

    public static load: (id: number, aServer ?: Server) => Promise<ViewSecUser>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<IViewSecUserRecord[]>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class ViewSecUserClassProxy extends ViewClassProxy<IViewSecUserRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const viewSecUserClassProxy = new ViewSecUserClassProxy(ViewSecUser);
registerViewClassProxy(viewSecUserClassProxy);

