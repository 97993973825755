import { Server } from '../../server/Server';
import View , { registerViewClassProxy, ViewClassProxy } from '../View';
import { IListParams } from '@framework/crud/Crud';

export interface IViewMediaOoFileRecord {
    /**
     * Azonosító
     *
     * Azonosító (pótkulcs)
     */
    id?: number;
    /**
     * Rekord létrehozás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel létrehozásakor.
     */
    creation_time?: string /*timestamp?*/;
    /**
     * Ki hozta létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozó felhasználóra hivatkozik.
     */
    creation_user_id?: number;
    /**
     * Melyik session-ből jött létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozáshoz használt munkamenetre hivatkozik.
     */
    creation_session_id?: number|null;
    /**
     * Utolsó módosítás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel módosításakor.
     */
    modification_time?: string /*timestamp?*/|null;
    /**
     * Ki módosította utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosító felhasználóra hivatkozik.
     */
    modification_user_id?: number|null;
    /**
     * Melyik session-ből módosították utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosításhoz használt munkamenetre hivatkozik.
     */
    modification_session_id?: number|null;
    /**
     * Aktív
     *
     * Logikai törléshez használt mező.
     */
    is_active?: boolean;
    /**
     * OnlyOffice file azonosító.
     */
    oo_fileid?: string;
    /**
     * Név
     *
     * Az OnlyOffice rendszerében az állomány 'title' mezőjének értéke. Vagyis a file neve.
     */
    title?: string;
    /**
     * Kiterjesztés
     *
     * Az állományt kiterjesztése. Kisbetűs, ponttal kezdődik (vagy üres)
     */
    ext?: string|null;
    /**
     * Content-Type
     */
    content_type?: string|null;
    /**
     * Relatív útvonal
     *
     * A mappa szerkezetben a gyökérhez képest.
     */
    relpath?: string;
    /**
     * Relatív útvonal JSON objektumként
     *
     * Egy lista ami (folder_id, folder_title) elemekből áll.
     */
    relpath_items?: any;
    /**
     * Név
     *
     * Kulcsszavak
     */
    keywords?: string|null;
    /**
     * Méret
     *
     * A file teljes mérete byte-ban.
     */
    size?: number;
    /**
     * Szélesség pixelben
     *
     * Csak akkor, ha raszteres képről van szó.
     */
    width_px?: number|null;
    /**
     * Magasság pixelben
     *
     * Csak akkor, ha raszteres képről van szó.
     */
    height_px?: number|null;
    /**
     * SHA1 hash érték
     *
     * A file tartalmára számítva. Csak képeknél számítjuk!
     */
    sha1?: string;
    /**
     * OnlyOffice utolsó ismert file infó.
     *
     * A tartalmazó mappa listázó API hívásából az erre az állományra vonatkozó bejegyzés.
     */
    oo_info?: any;
    /**
     * OnlyOffice mappa
     */
    oo_folder_id?: number;
    /**
     * Van thumbnail
     *
     * Thumbnail-ek elérhetők hozzá MongoDb-ből
     */
    has_thumb?: boolean;
    /**
     * Utolsó találat időpontja
     */
    search_popularity_last_hit_date?: string /*timestamp?*/|null;
    /**
     * Utolsó népszerűségi index
     */
    search_popularity_last_score?: number /* float8 */|null;
    /**
     * Eredet leírás
     *
     * Az állomány származási helye (nyilvántartás és szerzői jog miatt), leírás
     */
    origin?: string|null;
    /**
     * Média típus
     */
    media_type_id?: number|null;
    /**
     * Publikált név
     *
     * Ahogyan a felületen megjelenik - ez nem a file neve.
     */
    displayname?: string|null;
    /**
     * Azonosító NKP 1.0 rendszerben
     *
     * Ez egy ContentVersionId
     */
    nkp1_id?: number|null;
    /**
     * Tulajdonos
     */
    owner_id?: number;
    /**
     * Jogok öröklése
     *
     * A mappa a jogokat a tartalmazó mappától örökli. Az explicit módon beállított jogok hatástalanok.
     */
    inherit_perms?: boolean;
    /**
     * Forrás (származási hely)
     */
    origin_id?: number|null;
    /**
     * Eredet URL
     *
     * Az állomány származási helye (nyilvántartás és szerzői jog miatt) URL-ként
     */
    origin_url?: string|null;
    /**
     * Származási szerző
     */
    origin_author?: string|null;
    /**
     * Megjelölendő forrás
     */
    origin_display?: string|null;
    /**
     * Al-forrás (származási hely)
     */
    suborigin_id?: number|null;
    /**
     * Kereshető
     *
     * A kereső szolgáltatás beindexeli, meg lehet találni a keresőben.
     */
    searchable?: boolean|null;
    /**
     * Egyéb adatok
     */
    other_json?: any|null;
    /**
     * Létrehozta
     *
     * Létrehozta
     */
    creator?: string|null;
    /**
     * Utoljára módosította
     *
     * Utoljára módosította
     */
    modifier?: string|null;
    /**
     * Tulajdonos
     *
     * Tulajdonos
     */
    owner?: string|null;
    /**
     * Média típus neve
     *
     * Média típus neve
     */
    media_type_title?: string|null;
    /**
     * Média típus leírása
     *
     * Média típus leírása
     */
    media_type_description?: string|null;
    /**
     * Származási hely neve
     *
     * Származási hely neve
     */
    origin_title?: string|null;
    /**
     * Származási al-hely neve
     *
     * Származási al-hely neve
     */
    suborigin_title?: string|null;
}

/**
 *  media.view_media_oo_file - OnlyOffice file
 *
 *  OnlyOffice-ba feltöltött file.
 */

export default class ViewMediaOoFile extends View<IViewMediaOoFileRecord> {
    public static VIEW_INFO_ID = 2018062801;

    public static getSchemaNameForClass(): string { return 'media'; }
    public static getViewNameForClass(): string { return 'view_media_oo_file'; }
    public static getTableNameForClass(): string|null { return 'oo_file'; }

    public static load: (id: number, aServer ?: Server) => Promise<ViewMediaOoFile>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<IViewMediaOoFileRecord[]>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class ViewMediaOoFileClassProxy extends ViewClassProxy<IViewMediaOoFileRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const viewMediaOoFileClassProxy = new ViewMediaOoFileClassProxy(ViewMediaOoFile);
registerViewClassProxy(viewMediaOoFileClassProxy);

