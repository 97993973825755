import * as React from 'react';

import { app } from '@src/index';

import { IRecord, CrudClassProxy, TFilterDict }  from '@framework/crud/Crud';
import { __ } from '@src/translation';

type CrudSelectEvent = (sender: CrudSelectComponent, newValue:number|null) => void;

interface CrudSelectComponentProps { 
    value: number|null;
    onSelect ?: CrudSelectEvent;
    emptyTitle ?: string;
    valueFieldName ?: string; // defaults to id
    displayFieldName: string;
    orderByFieldName?: string;
    crudClassProxy : CrudClassProxy<IRecord>;
    disabled ?: boolean;
    clearable ?: boolean;
    filter?: TFilterDict;
    limit?: number;
    autoSelectSingle ?: boolean;    
    onFilter ?: (record:any) => boolean;
    sortFunc?: (a: any, b: any) => number;
} 

interface CrudSelectComponentState {
    items ?: IRecord[];
}

export default class CrudSelectComponent  extends React.Component<CrudSelectComponentProps, CrudSelectComponentState> { 

    constructor(props:CrudSelectComponentProps) {
        super(props);
        this.state = {};        
    }

    componentDidMount() {
        this.asnycReload();
    }

    componentDidUpdate(prevProps: CrudSelectComponentProps) {
        let needRefresh:boolean = JSON.stringify(this.props.filter) != JSON.stringify(prevProps.filter);
        if (this.state.items===undefined || (
            this.props.crudClassProxy.getTableInfoIdForClass()
            !=
            prevProps.crudClassProxy.getTableInfoIdForClass()
        )|| needRefresh
        ) {
            this.asnycReload(needRefresh);
        }
    }

    private asnycReload = async (needRefresh?:boolean) => {
        if (this.state.items === undefined || needRefresh) {
            this.props.crudClassProxy.list({filter: this.props.filter, order_by: this.props.orderByFieldName || this.props.displayFieldName, limit:this.props.limit || 100})
                .then( (items) => { 
                    if (this.props.onFilter) items = items.filter(this.props.onFilter);
                    if (this.props.sortFunc) items = items.sort(this.props.sortFunc);                    
                    this.setState({items});
                    if (items.length==1 && this.props.autoSelectSingle && this.props.onSelect!==undefined && this.props.value===null) {
                        this.props.onSelect(this, items[0].id!);
                    }
                } )
                .catch( (error)  => { app.showErrorFromJsonResult(error) } );
        }
    }

    private _onSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
        if (this.props.onSelect!==undefined) {
            let newValue : number|null = null;
            if (event.target.value) {
                try {
                    newValue = Number(event.target.value);
                } catch (error) {

                }
            }
            this.props.onSelect(this, newValue)
        }
    }

    render() { 
        if (this.state.items===undefined) {
            return <select disabled><option>{__("Kérem várjon...")}</option></select>;
        } else {

            let items = this.state.items;
            
            if (this.props.onFilter) items = items.filter(this.props.onFilter);
            if (this.props.sortFunc) items = items.sort(this.props.sortFunc);

            const value = this.props.value? this.props.value.toString() : '' ;
            const emptyTitle = this.props.emptyTitle || __('Kérem válasszon...');
            const disabled = (this.props.disabled===undefined)?false:
                this.props.disabled?true:false;
            const clearable = (this.props.clearable===undefined) || this.props.clearable;
            const valueFieldName = this.props.valueFieldName===undefined?"id":this.props.valueFieldName;
            return (<select value={value} onChange={this._onSelect} disabled={disabled}>
                {clearable || !value ?
                <option key="_EMPTY_" value="">{emptyTitle}</option>
                :null}
                {
                    items.map((item) => {
                        return <option key={"opt_"+ (item[valueFieldName]||0).toString() } value={item[valueFieldName]}>{item[this.props.displayFieldName]}</option>
                    })
                }
            </select>)
        }
    }

}