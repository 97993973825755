import { Server } from '../../server/Server';
import Crud , { registerCrudClassProxy, IAsTextResult, IListParams, CrudClassProxy, ISearchTextParams, ISearchTextResult } from '../Crud';

export interface ISubsiteRecord {
    /**
     * Globálisan egyedi azonosító.
     * 
     * Ha új rekordot akarsz létrehozni put() metódussal, akkor hagyd üresen.
     * upsert() hívásnál figyelmen kívül van hagyva az értéke.
     *
     */
    id ?: number;
    /**
     * Rekord létrehozás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel létrehozásakor.
     */
    creation_time?: string /*timestamp?*/;
    /**
     * Ki hozta létre
     *
     * NOT NULL -> sys.sec_user ON DELETE noaction
     *
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozó felhasználóra hivatkozik.
     */
    creation_user_id?: number;
    /**
     * Melyik session-ből jött létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozáshoz használt munkamenetre hivatkozik.
     */
    creation_session_id?: number|null;
    /**
     * Utolsó módosítás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel módosításakor.
     */
    modification_time?: string /*timestamp?*/|null;
    /**
     * Ki módosította utoljára
     *
     * -> sys.sec_user ON DELETE noaction
     *
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosító felhasználóra hivatkozik.
     */
    modification_user_id?: number|null;
    /**
     * Melyik session-ből módosították utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosításhoz használt munkamenetre hivatkozik.
     */
    modification_session_id?: number|null;
    /**
     * Aktív
     *
     * Logikai törléshez használt mező.
     */
    is_active?: boolean;
    /**
     * Név
     */
    title?: string;
    /**
     * Leírás
     */
    description?: string|null;
    /**
     * Site
     *
     * NOT NULL -> cms.site ON DELETE cascade
     *
     */
    site_id?: number;
    /**
     * Subsite gyökér mappa
     *
     * NOT NULL -> media.oo_folder ON DELETE noaction
     *
     */
    oo_folder_id?: number;
    /**
     * URL rész
     *
     * URL path (útvonal) szegmense
     */
    uri_segment?: string|null;
    /**
     * Template alapnév
     *
     * A velocity template-ező rendszer ezen site-hoz használt alap template neve.
     */
    template_basename?: string|null;
    /**
     * Rövid név
     */
    shortname?: string|null;
    /**
     * Aloldal színe
     */
    color?: string|null;
    /**
     * Konfiguráció
     */
    config_jsonb?: any|null;
    /**
     * Al-site
     *
     * -> cms.subsite ON DELETE cascade
     *
     */
    parent_id?: number|null;
}

/**
 *  cms.subsite - Al-site
 *
 *  Idősoros táblázat, ami az összes tétel összes korábbi verzióját eltárolja.
 */

export default class SubsiteCrud extends Crud<ISubsiteRecord> {
    public static TABLE_INFO_ID = 2397103472;
    public static getSchemaNameForClass() { return 'cms'; }
    public static getTableNameForClass() { return 'subsite'; }

    public static load: (id: number, aServer ?: Server) => Promise<SubsiteCrud>;
    public static asText: (id: number, includeRecord : boolean, aServer ?: Server) => Promise<IAsTextResult<ISubsiteRecord>>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<ISubsiteRecord[]>;
    public static searchText: (params: ISearchTextParams, aServer ?: Server) => Promise<ISearchTextResult<ISubsiteRecord>[]>;
    public static deleteById: (id: number, aServer ?: Server) => Promise<ISubsiteRecord>;
    public static unDeleteById: (id: number, aServer ?: Server) => Promise<ISubsiteRecord>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class SubsiteCrudClassProxy extends CrudClassProxy<ISubsiteRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const subsiteCrudClassProxy = new SubsiteCrudClassProxy(SubsiteCrud);
registerCrudClassProxy(subsiteCrudClassProxy);
