import { Server } from '../../server/Server';
import View , { registerViewClassProxy, ViewClassProxy } from '../View';
import { IListParams } from '@framework/crud/Crud';

export interface IViewDupPersonRecord {
    /**
     * Személy min. azonosító
     *
     * Személy min. azonosító
     */
    id?: number;
    /**
     * Normalizált teljes név
     *
     * Normalizált teljes név
     */
    norm_fullname?: string|null;
}

/**
 *  sys.view_dup_person - Duplikált személyek
 *
 *  Valószínű személy duplikációk
 */

export default class ViewDupPerson extends View<IViewDupPersonRecord> {
    public static VIEW_INFO_ID = 2020052001;

    public static getSchemaNameForClass(): string { return 'sys'; }
    public static getViewNameForClass(): string { return 'view_dup_person'; }
    public static getTableNameForClass(): string|null { return 'null'; }

    public static load: (id: number, aServer ?: Server) => Promise<ViewDupPerson>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<IViewDupPersonRecord[]>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class ViewDupPersonClassProxy extends ViewClassProxy<IViewDupPersonRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const viewDupPersonClassProxy = new ViewDupPersonClassProxy(ViewDupPerson);
registerViewClassProxy(viewDupPersonClassProxy);

