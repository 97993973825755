import * as React from 'react';
import { Link } from 'react-router-dom';

import { IViewOauthClientRecord, ViewOauthClientClassProxy, viewOauthClientClassProxy } from '@src/framework/view/sys/ViewOauthClient';
import { viewOauthClientRedirectUriClassProxy } from '@src/framework/view/sys/ViewOauthClientRedirectUri';
import ListView from '@framework/forms/listview';
import { __ } from '@src/translation';


class OauthClientInner extends ListView<IViewOauthClientRecord> {

    public getViewClassProxy() : ViewOauthClientClassProxy { return viewOauthClientClassProxy; }


    protected getRedirectUriListButton(record:IViewOauthClientRecord) : JSX.Element {
        return (
            <Link 
                className="listview-button small" title={__("Átírányítás URL-ek")} 
                to={ viewOauthClientRedirectUriClassProxy.getListUrl(
                    {oauth_client_id: record.id!}
            )}>
                <i className="fa fa-link"></i>
            </Link>);
    }           

    protected getRecordButtons(record: IViewOauthClientRecord) : JSX.Element[] {
        /* TODO: automatikus detail list menüt ide? */
        return super.getRecordButtons(record)
            .concat(this.getRedirectUriListButton(record))
        ;
    }

    protected getQuickFilterFieldNames(): string[] {
        return ['client_id', 'name', 'description'];
    }

}

export default function OauthClientListView(props: any) {
    // TODO: hozzáadni a person-os oszlopokat a view-hoz hogy látszódjon a 
    // rendes nevük!
    return <OauthClientInner
        defaultPageSize={10}
        match={props.match}        
        viewColumns={[
            {accessor: "id", maxWidth: 100},
            {accessor: "client_id", maxWidth: 200},
            {accessor: "name", maxWidth: 250},
            {accessor: "description", maxWidth: 300},
            {accessor: "link", Header:__("Műveletek"), maxWidth:200},
        ]}
    />
}
