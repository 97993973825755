import { Module, ModuleMenu, BaseMenu, ModuleRoute } from "../Module";
import { __ } from "@src/translation";
import { BookSelectorPage, BookPage } from "@src/component/book/viewer/BookSelectorPage";
import { BookView } from "@src/component/book/viewer/BookView";
import { LowGradeBookPage } from "@src/component/book/viewer/LowGradeBook";


export let bookModule: BookModule<BaseMenu>;
export type BookModuleConfig = {
    paths?: any;
}

export class BookModule<T extends BaseMenu> extends Module<T> {

    private config: BookModuleConfig;

    //FELADATOK
    public PATH_PUBLISHED_BOOK_SELECT = "/tankonyvek";
    public PATH_PUBLISHED_BOOKS = "/okostankonyvek";
    public PATH_PUBLISHED_BOOK = "/tankonyv";
    public PATH_BOOK_VIEW_EDITING = "/tananyag";
    public PATH_PUBLISHED_BOOK_LOWGRADE = "/alsostankonyv";

    public getConfig()
    {
        return this.config;
    }

    constructor(config: BookModuleConfig) {
        super();

        this.config = config;

        this.PATH_PUBLISHED_BOOK_SELECT = (config.paths && config.paths["PATH_PUBLISHED_BOOK_SELECT"]) || this.PATH_PUBLISHED_BOOK_SELECT;
        this.PATH_PUBLISHED_BOOKS = (config.paths && config.paths["PATH_PUBLISHED_BOOKS"]) || this.PATH_PUBLISHED_BOOKS;
        this.PATH_PUBLISHED_BOOK = (config.paths && config.paths["PATH_PUBLISHED_BOOK"]) || this.PATH_PUBLISHED_BOOK;
        this.PATH_BOOK_VIEW_EDITING = (config.paths && config.paths["PATH_BOOK_VIEW_EDITING"]) || this.PATH_BOOK_VIEW_EDITING;
        this.PATH_PUBLISHED_BOOK_LOWGRADE = (config.paths && config.paths["PATH_PUBLISHED_BOOK_LOWGRADE"]) || this.PATH_PUBLISHED_BOOK_LOWGRADE;

      
        ///
    }

    public async getRoutesAsync(): Promise<Record<string, ModuleRoute<T>>> {
        return this.getRoutes();
    }

    public getRoutes(): Record<string, ModuleRoute<T>> {

        let ret: Record<string, ModuleRoute<T>> = {
            'ROUTE_PUBLISHED_BOOK_SELECT' : { path: this.PATH_PUBLISHED_BOOK_SELECT, component: BookSelectorPage, title:__("Tankönyvek"), layoutKey: "public"},
            'ROUTE_PUBLISHED_BOOKS' : { path: this.PATH_PUBLISHED_BOOKS + "/:subjectUriSegment?", component: BookPage, layoutKey: "public" },
            'ROUTE_PUBLISHED_BOOK' : { path: this.PATH_PUBLISHED_BOOK + "/:bookUriSegment/:lessonUriSegment?", component: BookView, exact:true, layoutKey: "book"},
            'ROUTE_BOOK_VIEW_EDITING' : { path: this.PATH_BOOK_VIEW_EDITING + "/:bookUriSegment/:lessonUriSegment?", component: BookView, exact:true, layoutKey: "book"},
            'ROUTE_PUBLISHED_BOOK_LOWGRADE' : { path: this.PATH_PUBLISHED_BOOK_LOWGRADE + "/:bookUriSegment", component: LowGradeBookPage, exact:true},                        
        };
            
        return ret;
    }

    public getMenus() {
        let ret: ModuleMenu<T>[] = [
            
            
        ];
        return ret;
    }

    public getRecordRoute(tableInfoId: number, recordId: number, edit?: boolean): string | null {
        return null;
    }
    public static initGlobal<T extends BaseMenu>(config: BookModuleConfig) {
        bookModule = new BookModule<T>(config);
    }

    
}
