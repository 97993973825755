import * as React from 'react'
import { History } from 'history';
import * as Server from '@src/server/PublicServer'
import SearchResultList from '@src/component/search/SearchResultList';
import CrudSelect from '@framework/forms//crudselect';
import { subjectCrudClassProxy, ISubjectRecord } from '@framework/crud/doc/SubjectCrud';
import { gradeCrudClassProxy, IGradeRecord } from '@framework/crud/doc/GradeCrud';
import { SearchResult } from '@src/server/PublicServer';
import { app } from '@src/index';
import { PATH_PUBLISHED_SEARCH, PATH_PUBLISHED_DETAILED_SEARCH, PATH_PUBLISHED_EXTERNAL_SEARCH } from '@src/Routes';
import AutoCompleteEdit from '@src/framework/forms/autocompleteedit';
import { SynonymResult } from '@src/server/PublicServer';
import { me } from '@src/framework/server/Auth';
import { __ } from '@src/translation';
import { cmsConfig } from '@src/component/cms/CmsModule';
import ViewBook, { IViewBookRecord } from '@src/framework/view/doc_pub/ViewBook';
import { instituteTypeCrudClassProxy, IInstituteTypeRecord } from '@src/framework/crud/sys/InstituteTypeCrud';
import { searchModule, SearchFilterType, SearchModule } from '../searchModule';

declare var MathJax: any;
const DICTIONARY_ID: number = 20981871903;

export enum ElasticContentType {
    Text = "book,chapter,lesson,section",
    Book = "book",
    Chapter = "chapter",
    Lesson = "lesson",
    Section = "section",
    Image = "image",
    Video = "video",
    Audio = "audio",
    Exercise = "exercise",
    DlMaterial = "dl_material",
    ExerciseSeries = "exercise_series",
    AllExercise = "exercise,exercise_series"
}

interface SearchPageProps {
    match: any;
    location: any;
    history: History;
}

type SearchPageState = {
    keywords: string;
    origkeywords: string;
    results: SearchResult[];
    resultNum: number;
    synonyms: SynonymResult[];
    searching: boolean;
    imgsrc: string;
    selectedSubject: number | null;
    selectedGrade: number | null;
    selectedInstituteTypeId: number | null;
    selectedElasticType?: string;
    pageIndex: number;
    exactMatchFound: boolean;
    radioMatchValue: string;
    gradeSubjectInstituteType: IViewBookRecord[];
    elementPerPage: number;

    filterParams: {name: string, value: any}[];
}

export function getTypeFromItemId(item: any): string | null {
    let types = Object.keys(ElasticContentType).map(key => ElasticContentType[key])
    let type = null;
    types.forEach(t => {
        if (item._id.toString().indexOf(t) > -1) {
            type = t;
        }
    });
    return type;
}

export default class SearchPage extends React.Component<SearchPageProps, SearchPageState> {

    constructor(props: SearchPageProps) {
        super(props);
        const keywords = this.props.match.params.keywords ? decodeURIComponent(this.props.match.params.keywords) : "";
        this.state = {
            keywords: keywords,
            origkeywords: keywords,
            results: [],
            resultNum: 0,
            synonyms: [],
            searching: true,
            imgsrc: "",
            pageIndex: 1,
            selectedSubject: null,
            selectedGrade: null,
            selectedInstituteTypeId: null,
            selectedElasticType: "",
            exactMatchFound: false,
            radioMatchValue: "exact",
            gradeSubjectInstituteType: [],
            elementPerPage: 10,
            filterParams: [],
        }

        if (this.state.keywords) {
            this.onSearch();
        }

        this.reloadGradeSubjectInstitute();
    }

    private async reloadGradeSubjectInstitute() {
        const gradeSubjectInstituteType = await ViewBook.list({ columns: ["subject_id", "grade_id", "institute_type_id"], distinct: true });
        this.setState({
            gradeSubjectInstituteType: gradeSubjectInstituteType
        })
    }

    private onItemSelected = (selectedItem: string) => {
        this.setState({ keywords: selectedItem || "" }, this.setCurrentSearch);
    }

    private onValueChange = (value: string) => {
        this.setState({ keywords: value });
    }

    setCurrentSearch() {
        // this.setPageIndex(1);
        this.props.history.push(searchModule.PATH_SEARCH_PAGE + "/" + (this.state.keywords != undefined ? encodeURIComponent(this.state.keywords) : ""));
    }

    setDetailedSearch() {
        this.props.history.push(searchModule.PATH_SEARCH_SPECIAL + "/");
    }

    setExternalSearch() {
        this.props.history.push(PATH_PUBLISHED_EXTERNAL_SEARCH + "/");
    }

    private onGradeSelect = (sender: CrudSelect, newGrade: number | null): void => {
        this.setState({ selectedGrade: newGrade });
        this.setCurrentSearch();
    }


    private onSubjectSelect = (sender: CrudSelect, newSubject: number | null): void => {
        this.setState({ selectedSubject: newSubject });
        this.setCurrentSearch();
    }

    private onInstituteTypeSelect = (sender: CrudSelect, newInstituteTypeId: number | null): void => {
        this.setState({ selectedInstituteTypeId: newInstituteTypeId });
        this.setCurrentSearch();
    }

    onSelectType(selectedElasticType: string) {

        this.setCurrentSearch();
        let elementPerPage = 10;
        if (selectedElasticType == ElasticContentType.Image) {
            elementPerPage = 12
        }
        this.setState({ selectedElasticType, elementPerPage }, this.onSearch);
    }

    private onSynonymClicked = (synonym: string) => {
        this.setState({ keywords: synonym }, this.setCurrentSearch);
    }
    private onKeyPress(e: any) {
        if (e.key === 'Enter') {
            this.setCurrentSearch();
        }
    }

    private onSwitchChange = (event: any) => {

        if (this.state.radioMatchValue === "exact") {
            this.setState({
                radioMatchValue: "fuzzy"
            });
        }
        else {
            this.setState({
                radioMatchValue: "exact"
            });
        }
    }

    private isValidGradeSubjectInstituteType(gradeId: number | null, subjectId: number | null, instituteTypeId: number | null) {
        if (this.state.gradeSubjectInstituteType.length === 0) return true;
        
        return this.state.gradeSubjectInstituteType.some(gsi => {
            if (gradeId !== null && gsi.grade_id !== gradeId) return false;
            if (subjectId !== null && gsi.subject_id !== subjectId) return false;
            if (instituteTypeId !== null && gsi.institute_type_id !== instituteTypeId) return false;
            return true;
        });
    }

    render() {

        const types: SearchFilterType[] = searchModule.getConfig().filterTypes || [
            { elasticType: "", name: __("Minden"), iconClassName: "nkp-icon-filter-all" },
            { elasticType: ElasticContentType.Text, name: __("Szöveg"), iconClassName: "nkp-icon-filter-text" },
            { elasticType: ElasticContentType.Image, name: __("Kép"), iconClassName: "nkp-icon-filter-photo" },
            { elasticType: ElasticContentType.Video, name: __("Videó"), iconClassName: "nkp-icon-filter-video" },
            { elasticType: ElasticContentType.Audio, name: __("Hang"), iconClassName: "nkp-icon-filter-audio" },
            { elasticType: ElasticContentType.AllExercise, name: __("Okosfeladat"), iconClassName: "nkp-icon-filter-lesson" },
        ];

        let autocomplete;
        autocomplete = <AutoCompleteEdit value={this.state.keywords ? this.state.keywords : ""} onItemSelected={this.onItemSelected} onValueChange={this.onValueChange} onKeyPress={this.onKeyPress.bind(this)} />

        return <div>

            <div className="secondary-bar">
                <div className="row align-center">
                    <div className="column small-12 medium-11 large-11">

                        <div className="input-group eke-search-field">
                            <div className="eke-search__input">{autocomplete}<div className="search-setting">
                                <div>
                                    <input className="switch-input" id="external-radio-switch" type="checkbox" value={this.state.radioMatchValue === "exact" ? "exact" : "fuzzy"}
                                        checked={this.state.radioMatchValue === "fuzzy"} onChange={this.onSwitchChange.bind(this)}
                                    />
                                    <button title={__("Pontos egyezés {setting}", { setting: (this.state.radioMatchValue === "exact" ? __("(bekapcsolva)") : __("(kikapcsolva)")) })} className={this.state.radioMatchValue === "exact" ? "active" : ""} onClick={this.onSwitchChange.bind(this)}>[Ab]</button>
                                </div>
                            </div></div>
                            <div className="input-group-button">
                                <div className="button-group shrink column eke-search__button">
                                    <button className="button" onClick={this.setCurrentSearch.bind(this)}>{__("Keresés")}</button>
                                    {
                                        searchModule.getConfig().enableSpecialSearch 
                                        ?
                                        <>
                                        <button className="button arrow-only" data-toggle="submenu" >
                                            <i className="fas fa-caret-down"></i>
                                            <span className="show-for-sr">{__("Egyéb")}</span>
                                        </button>
                                        <div className="dropdown-pane" data-position="bottom" data-alignment="right" id="submenu" data-dropdown data-auto-focus="true" data-close-on-click="true">
                                            <ul className="menu vertical">
                                                <li><a href="#" onClick={this.setDetailedSearch.bind(this)}>{__("Speciális keresés")}</a></li>

                                            </ul>
                                        </div>
                                        </>
                                        : null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

           
                <>
                     {
                        searchModule.getConfig().enableFilterButtons ?
                    <div className="row align-center">
                        <div className="column small-12 medium-11 large-11" style={{ position: "relative", paddingRight: "0" }}>
                            <div className="white back-glow"></div>
                            <div className="buttons-row" data-dropdown-menu ref="menu">
                                <div style={{ flex: "1 0 auto", width: "max-content", display: "flex" }}>
                                    <div style={{ flex: "1 0 auto" }}>
                                        {
                                            types.map(type => {

                                                let isActiveClass = "";
                                                if (type.elasticType == this.state.selectedElasticType) {
                                                    isActiveClass = "active";
                                                }

                                                return <button
                                                    key={type.elasticType}
                                                    onClick={this.onSelectType.bind(this, type.elasticType)}
                                                    className={"button primary hollow eke-search__filter-button " + isActiveClass}>
                                                    <i className={"icon " + type.iconClassName}></i> {type.name}
                                                </button>
                                            })

                                        }
                                    </div>
                                    {me
                                        ? <div className="column small-12 large-expand" style={{ flex: "0 0 auto", paddingRight: "0.9375rem" }}>
                                            <button onClick={this.setExternalSearch.bind(this)} className="button expanded secondary eke-search-button">{__("Keresés a külső tartalmakban")}</button>
                                        </div>
                                        : ""
                                    }

                                </div>
                            </div>

                        </div>
                    </div>
                    : null
                    }

                   

                    <div className="row">
                        <div className="small-12 column">
                        {
                        searchModule.getConfig().enableFilterSelects 
                        ?
                            <div className="row align-center">
                                <div className="search-filters column small-12 large-12">
                                    <CrudSelect
                                        value={this.state.selectedInstituteTypeId}
                                        onSelect={this.onInstituteTypeSelect}
                                        displayFieldName="title"
                                        emptyTitle={__("Minden iskolatípus")}
                                        crudClassProxy={instituteTypeCrudClassProxy}
                                        onFilter={(instituteType: IInstituteTypeRecord) => {
                                            return this.isValidGradeSubjectInstituteType(this.state.selectedGrade, this.state.selectedSubject, instituteType.id!);
                                        }}
                                    />

                                    <CrudSelect
                                        value={this.state.selectedGrade}
                                        onSelect={this.onGradeSelect}
                                        displayFieldName="name"
                                        orderByFieldName="id"
                                        emptyTitle={__("Minden osztály")}
                                        crudClassProxy={gradeCrudClassProxy}
                                        filter={{ is_active: true }}
                                        onFilter={(grade: IGradeRecord) => {
                                            return this.isValidGradeSubjectInstituteType(grade.id!, this.state.selectedSubject, this.state.selectedInstituteTypeId);
                                        }}
                                        sortFunc={(a, b) => a.name.localeCompare(b.name, undefined, { numeric: true, sensitivity: 'base' })}
                                    />

                                    <CrudSelect
                                        value={this.state.selectedSubject}
                                        onSelect={this.onSubjectSelect}
                                        displayFieldName="name"
                                        emptyTitle={__("Minden tantárgy")}
                                        crudClassProxy={subjectCrudClassProxy}
                                        onFilter={(subject: ISubjectRecord) => {
                                            return this.isValidGradeSubjectInstituteType(this.state.selectedGrade, subject.id!, this.state.selectedInstituteTypeId);
                                        }}
                                        filter={{ is_active: true }}
                                    />

                                    {/* {
                                         searchModule.getConfig().searchApi.filterParams
                                         ? searchModule.getConfig().searchApi.filterParams!.map(filter=>{
                                            return <CrudSelect
                                                        value={this.state.selectedSubject}
                                                        onSelect={this.onSubjectSelect}
                                                        displayFieldName={filter.displayFieldName}
                                                        emptyTitle={filter.emptyTitle}
                                                        crudClassProxy={filter.crudClassProxy}
                                                        // onFilter={(subject: ISubjectRecord) => {
                                                        //     return this.isValidGradeSubjectInstituteType(this.state.selectedGrade, subject.id!, this.state.selectedInstituteTypeId);
                                                        // }}
                                                        onFilter={(selected: any)=>{
                                                            return filter.onFilter!(selected, this.state.filterParams)
                                                        }}
                                                        filter={{ is_active: true }}
                                                    />
                                        })
                                        : null
                                    } */}
                                </div>
                            </div>
                            : null
                            }

                            <SearchResultList
                                results={this.state.results}
                                imagesOnly={this.state.selectedElasticType == ElasticContentType.Image}
                                synonyms={this.state.synonyms}
                                onSynonymClicked={this.onSynonymClicked}
                                searchType={this.state.selectedElasticType}
                                keywords={this.state.keywords}
                                origkeywords={this.state.origkeywords}
                                pageIndex={this.state.pageIndex}
                                onSetPageIndex={(i: number) => this.setState({ pageIndex: i })}
                                searching={this.state.searching}
                                exactMatchFound={this.state.exactMatchFound}
                                resultNum={this.state.resultNum}
                                elementPerPage={this.state.elementPerPage}
                                doSearch={this.onSearch.bind(this)}
                            />
                        </div>
                    </div>
                </>
              
        </div>
    }
    componentDidMount() {
        ($(document) as any).foundation();

    }

    componentDidUpdate(prevProps: any) {
        if (this.props.location !== prevProps.location) {

            this.setState({
                keywords: this.props.match.params.keywords ? decodeURIComponent(this.props.match.params.keywords) : '',
                results: [],
                searching: true
            });
            this.onSearch();
        }

        try {
            MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
        } catch (e) {
            console.log(e);
        }
    }

    async onSearch() {

        console.log("onsearch")
        this.setState({ searching: true })

        if (this.state.keywords)
            try {
                if (this.state.keywords.length > 500) {
                    this.setState({ searching: false });
                    app.showError(__("Túl hosszú a szöveg!"), __("Kérjük próbálja meg szűkíteni a keresett kifejezést."));
                    return;
                }
                let exactmatch = this.state.radioMatchValue == "exact" ? "true" : "false";


                let resultNumSearch = await (searchModule.getConfig().searchApi!.api({
                    keyword: this.state.keywords,
                    type: this.state.selectedElasticType,
                    subject_id: this.state.selectedSubject ? this.state.selectedSubject.toString() : undefined,
                    grade_id: this.state.selectedGrade ? this.state.selectedGrade.toString() : undefined,
                    institute_type_id: this.state.selectedInstituteTypeId ? this.state.selectedInstituteTypeId.toString() : undefined,
                    exactmatch: exactmatch,
                    countonly: true,
                    maxhits: "10000"
                }));

                let result = await searchModule.getConfig().searchApi!.api({
                    keyword: this.state.keywords,
                    type: this.state.selectedElasticType,
                    subject_id: this.state.selectedSubject ? this.state.selectedSubject.toString() : undefined,
                    grade_id: this.state.selectedGrade ? this.state.selectedGrade.toString() : undefined,
                    institute_type_id: this.state.selectedInstituteTypeId ? this.state.selectedInstituteTypeId.toString() : undefined,
                    exactmatch: exactmatch,
                    from: (this.state.pageIndex - 1) * this.state.elementPerPage,
                    maxhits: (this.state.pageIndex - 1) * this.state.elementPerPage + this.state.elementPerPage > 10000 ? (10000 - (this.state.pageIndex - 1) * this.state.elementPerPage).toString() : this.state.elementPerPage.toString()
                });

                if (result.length !== 0) {
                    this.setState({ exactMatchFound: true })
                } else {
                    resultNumSearch = await searchModule.getConfig().searchApi!.api({
                        keyword: this.state.keywords,
                        type: this.state.selectedElasticType,
                        subject_id: this.state.selectedSubject ? this.state.selectedSubject.toString() : undefined,
                        grade_id: this.state.selectedGrade ? this.state.selectedGrade.toString() : undefined,
                        institute_type_id: this.state.selectedInstituteTypeId ? this.state.selectedInstituteTypeId.toString() : undefined,
                        exactmatch: "false",
                        countonly: true,
                        maxhits: "10000"
                    });

                    this.setState({ exactMatchFound: false });
                    result = await searchModule.getConfig().searchApi!.api({
                        keyword: this.state.keywords,
                        type: this.state.selectedElasticType,
                        subject_id: this.state.selectedSubject ? this.state.selectedSubject.toString() : undefined,
                        grade_id: this.state.selectedGrade ? this.state.selectedGrade.toString() : undefined,
                        institute_type_id: this.state.selectedInstituteTypeId ? this.state.selectedInstituteTypeId.toString() : undefined,
                        exactmatch: "false",
                        from: (this.state.pageIndex - 1) * this.state.elementPerPage,
                        maxhits: (this.state.pageIndex - 1) * this.state.elementPerPage + this.state.elementPerPage > 10000 ? (10000 - (this.state.pageIndex - 1) * this.state.elementPerPage).toString() : this.state.elementPerPage.toString()
                    });
                }

                //const synonyms = await Server.getSynonyms(this.state.keywords, DICTIONARY_ID);
                let resultNum = resultNumSearch[0].count!;
                this.setState({
                    results: result,
                   // synonyms: synonyms,
                    searching: false,
                    origkeywords: this.state.keywords,
                    resultNum: resultNum
                });

            } catch (error) {
                app.showErrorFromJsonResult(error);
            }
    }
}

