import { Server } from '../../server/Server';
import View , { registerViewClassProxy, ViewClassProxy } from '../View';
import { IListParams } from '@framework/crud/Crud';

export interface IViewExerciseSeriesItemRecord {
    /**
     * Azonosító
     *
     * Azonosító (pótkulcs)
     */
    id?: number;
    /**
     * Sorrend
     */
    no?: number /*int4*/|null;
    /**
     * Rekord létrehozás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel létrehozásakor.
     */
    creation_time?: string /*timestamp?*/;
    /**
     * Ki hozta létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozó felhasználóra hivatkozik.
     */
    creation_user_id?: number;
    /**
     * Melyik session-ből jött létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozáshoz használt munkamenetre hivatkozik.
     */
    creation_session_id?: number|null;
    /**
     * Utolsó módosítás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel módosításakor.
     */
    modification_time?: string /*timestamp?*/|null;
    /**
     * Ki módosította utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosító felhasználóra hivatkozik.
     */
    modification_user_id?: number|null;
    /**
     * Melyik session-ből módosították utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosításhoz használt munkamenetre hivatkozik.
     */
    modification_session_id?: number|null;
    /**
     * Aktív
     *
     * Logikai törléshez használt mező.
     */
    is_active?: boolean;
    /**
     * Feladatlista
     */
    exercise_series_id?: number;
    /**
     * Feladat
     */
    exercise_id?: number;
    /**
     * Pontszám
     */
    points?: number /*int4*/;
    /**
     * Szint
     *
     * A feladatsor nehézségi foka, 1=könnyű 2=közepes 3=nehéz
     */
    level?: number /*int4*/|null;
    /**
     * Adaptív feladatsor témakör
     */
    exercise_series_topic_id?: number|null;
    /**
     * Létrehozó
     *
     * Aki a feladatsorba a feladatot hozzáadta
     */
    item_creator?: string|null;
    /**
     * Módosító
     *
     * Aki a feladatsor/feladat hozzárendelést módosította
     */
    item_modifier?: string|null;
    /**
     * Teljes név
     *
     * Egysoros szöveges reprezentáció
     */
    displayvalue?: string|null;
    /**
     * Feladatsor név
     *
     * Feladatsor név
     */
    exercise_series_title?: string|null;
    /**
     * Feladat név
     *
     * Feladat név
     */
    exercise_name?: string|null;
    /**
     * Feladat szint
     *
     * Feladat szint
     */
    exercise_level?: string|null;
    /**
     * Feladat létrehozó
     *
     * Aki a feladatot létrehozta
     */
    exercise_creator?: string|null;
    /**
     * Feladat módosító
     *
     * Aki a feladatot módosította
     */
    exercise_modifier?: string|null;
    /**
     * Folyamat azonosító
     *
     * Folyamat azonosító
     */
    wf_workflow_id?: number|null;
    /**
     * Folyamat állapot azonosító
     *
     * Folyamat állapot azonosító
     */
    wf_station_id?: number|null;
    /**
     * Állapot
     *
     * Folyamat állapot neve
     */
    wf_station_name?: string|null;
    /**
     * Folyamat állapot leírása
     *
     * Folyamat állapot leírása
     */
    wf_station_description?: string|null;
    /**
     * Állapot stílus
     *
     * Folyamat állapot stílusa, jsonb/css
     */
    wf_station_style?: any|null;
    /**
     * Folyamat al-státusz azonosító
     *
     * Folyamat al-státusz azonosító
     */
    wf_substation_id?: number|null;
    /**
     * Folyamat al-státusz
     *
     * Folyamat al-státusz
     */
    wf_substation_name?: string|null;
    /**
     * Folyamat al-státusz leírása
     *
     * Folyamat al-státusz leírása
     */
    wf_substation_description?: string|null;
    /**
     * Folyamat szöveges állapot kiegészítés
     *
     * Folyamat szöveges állapot kiegészítés
     */
    wf_status_text?: string|null;
}

/**
 *  exc.view_exercise_series_item - Feladatsor elem
 *
 *  Idősoros táblázat, ami az összes tétel összes korábbi verzióját eltárolja.
 */

export default class ViewExerciseSeriesItem extends View<IViewExerciseSeriesItemRecord> {
    public static VIEW_INFO_ID = 2018090601;

    public static getSchemaNameForClass(): string { return 'exc'; }
    public static getViewNameForClass(): string { return 'view_exercise_series_item'; }
    public static getTableNameForClass(): string|null { return 'exercise_series_item'; }

    public static load: (id: number, aServer ?: Server) => Promise<ViewExerciseSeriesItem>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<IViewExerciseSeriesItemRecord[]>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class ViewExerciseSeriesItemClassProxy extends ViewClassProxy<IViewExerciseSeriesItemRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const viewExerciseSeriesItemClassProxy = new ViewExerciseSeriesItemClassProxy(ViewExerciseSeriesItem);
registerViewClassProxy(viewExerciseSeriesItemClassProxy);

