/**
 * Returns false if the string is empty or only contains whitespace characters
 * @param StringToCheck 
 */
export function  whiteSpaceChecker (StringToCheck:string|null|undefined) {

    var regexp = new RegExp(/\S|^$/);
    if(!StringToCheck||(StringToCheck.length<1)||!regexp.test(StringToCheck))
    {     
        return false;
    }
    else return true;
}