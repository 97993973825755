import * as React from 'react';
import { ISupplementaryMaterialRecord } from '@src/framework/crud/doc/SupplementaryMaterialCrud';
import SearchInBookComponent, { SearchStatus } from '@src/component/book/viewer/SearchInBookComponent';
import { Link } from 'react-router-dom';
import { BookStructure } from '@src/server/PublicServer';
import Scrollbar from 'smooth-scrollbar';
import { SectionType, containsSection, SECTION_TYPES_TEXT, containsSectionType, SECTION_TYPES_EXERCISE_IFRAME, SECTION_TYPES_VIDEO } from '@src/component/book/viewer/BookSections';
import { ViewMode } from '@src/component/book/viewer/BookPage';
import { Toggle, ToggleControl, AnimationMode } from '@src/component/ui/Panel';
import { __ } from '@src/translation';

type BookMenuProps = {
    sections: { id: number, name: string, blockTemplateName: string }[],
    chapterLessons: any,
    supplementaryMaterials?: ISupplementaryMaterialRecord[],
    reveals: { id: string, name: string }[],
    book: BookStructure,
    bookPath: string;
    lessonUriSegment?: string,
    onSectionTypeChange?: (SectionType: SectionType[]) => void,
    viewMode?: ViewMode,
    sectionTypes?: SectionType[],
    onLessonChange?: () => void,
}

type BookMenuState = {
    scrollBar: any,
    isOpen: boolean
}

type SrMenuProps = {
    chapterLessons: any,
    book: BookStructure
}



export class ScreenReaderMenu extends React.Component<SrMenuProps, {}> {
    constructor(props: any) {
        super(props);

        this.state = {
        };
    }

    render() {
        return <nav className="show-for-sr" title={__("Tartalom")}>
            <ul>

                {
                    this.props.chapterLessons.map((chapter: any, index: number) => {

                        if (chapter.lessons.length == 0) return;

                        if (chapter.lessons.length == 1) {
                            const lesson = chapter.lessons[0];
                            return <li key={index}>
                                <Link to={"/tankonyv/" + this.props.book.uri_segment + "/" + lesson.uri_segment}>{lesson.name}</Link>

                            </li>
                        }

                        return <li key={index}>
                            <ul className="accordion" data-accordion data-multi-expand="true" data-allow-all-closed="true">
                                <li data-accordion-item>
                                    <Link to="#">
                                        <div className="chapter-link item-link"><h3 className="contents-list-item">{chapter.name}</h3></div>
                                    </Link>
                                    <ul className="accordion-content" data-tab-content>
                                        {
                                            chapter.lessons.map((lesson: any, index: number) => {
                                                return <li key={index}><Link to={"/tankonyv/" + this.props.book.uri_segment + "/" + lesson.uri_segment}><h3 className="contents-list-sub-item">{lesson.name}</h3></Link></li>;
                                            })
                                        }
                                    </ul>
                                </li>
                            </ul>
                        </li>
                    })
                }
            </ul>
        </nav>

    }
}

export default class BookMenu extends React.Component<BookMenuProps, BookMenuState> {

    constructor(props: any) {
        super(props);

        this.state = {
            scrollBar: null,
            isOpen: false
        };
        this.handleResize = this.handleResize.bind(this);
    }

    handleSectionTypeChange(sectionType: SectionType[]) {
        if (this.props.onSectionTypeChange) {
            this.props.onSectionTypeChange(sectionType);
        }
    }

    closeBookMenu() {
        //close accordion menu
        ($('#app-menu') as any).foundation('hideAll');
        //close toggle menu
        (this.refs.menuToggleElement as Toggle).close();
        $('.top-bar-right .nav-icon').removeClass('open');
    }

    setMobile() {
        if (screen.width < 640) {

            this.closeBookMenu();

            $('.title-bar').removeClass('sticky');

            let iScrollPos = 0;
            $(window).scroll(function (e) {

                if (screen.width < 640) {

                    if ($(".book-menu li").attr('aria-expanded') == 'true') {
                        e.preventDefault();
                        return false;
                    }

                    $('.book-menu').removeClass('show');
                    let iCurScrollPos = 0;
                    iCurScrollPos = $(this).scrollTop()!;
                    if (iCurScrollPos > iScrollPos) {
                        //Scrolling Down
                        $(".top-header").css('position', '').css('top', '');
                    } else {
                        //Scrolling Up
                        $(".top-header").css('position', 'fixed').css('top', '0');
                    }
                    iScrollPos = iCurScrollPos;
                }
                return;
            });

        }
        else {
            $('.title-bar').addClass('sticky');
            $(".top-header").css('position', '').css('top', '');
        }
    }

    onSearch() {

    }

    render() {
        const containsNonTextSection = containsSection(SECTION_TYPES_EXERCISE_IFRAME, this.props.sections) || containsSection(SECTION_TYPES_VIDEO, this.props.sections);

        // return <ul ref="menuElement" className="vertical menu docs-toc accordion-menu is-active book-menu" data-accordion-menu="" role="">
        //     <li className="menu-bar" role="">
        return <div ref="menuElement" className="menu docs-toc accordion-menu is-active book-menu menu-bar">
            <div role={__("Keresés a könyvben")} className="book-search">
                <SearchInBookComponent bookPath={this.props.bookPath} key="searchInBook" subject_id={this.props.book.subject_id} grade_id={this.props.book.grade_id} />
            </div>
            <div aria-hidden={true} className="menu-control" id="bookMenu" onClick={() => this.setState({ isOpen: !this.state.isOpen })}>
                <span className="nav-icon float-right"><span></span> <span></span> <span></span> <span></span> <span></span> <span></span></span>
            </div>
            <Toggle ariaHidden={true} ref="menuToggleElement" className="spymenu menuScroll" id="bookMenu" animationIn={AnimationMode.toggleIn} animationOut={AnimationMode.toggleOut} isOpen={this.state.isOpen}>
                <ul data-accordion-menu="" role="" aria-hidden="true" ref="menuElementToOpen" id="app-menu" className="is-active">


                    <li key="tartalomjegyzek" className="toc-container main-item" role=""> <Link to="#"><h2 className="main-menu-item">{__("A könyv tartalma")}</h2></Link>
                        <ul className="vertical nested menu">
                            {
                                this.props.chapterLessons.map((chapter: any, index: number) => {
                                    if (chapter.lessons.length == 0) return;

                                    let viewClass = "";

                                    if (chapter.lessons.length == 1) {
                                        const lesson = chapter.lessons[0];
                                        viewClass = this.props.book.lessons.find((el) => { return (el.lesson_id == lesson.id && el.viewed == true) }) ? "lesson-viewed " : "";
                                        return <li key={index} role="" className={lesson.uri_segment == this.props.lessonUriSegment ? "is-active" : undefined}>
                                            <Link onClick={this.props.onLessonChange} to={this.props.bookPath + lesson.uri_segment}><h3 className={viewClass + "menu-h3"}>{lesson.name}</h3></Link>
                                        </li>
                                    }

                                    const isChapterActive = chapter.lessons.filter((lesson: any) => lesson.uri_segment == this.props.lessonUriSegment).length > 0;

                                    return <li key={index}>
                                        <Link to="#" className={isChapterActive ? "book-menu-color-light" : undefined}><h3 className="menu-h3">{chapter.name}</h3></Link>
                                        <ul className="menu vertical nested">
                                            {
                                                chapter.lessons.map((lesson: any, index: number) => {
                                                    viewClass = this.props.book.lessons.find((el) => { return (el.lesson_id == lesson.id && el.viewed == true) }) ? "lesson-viewed " : "";
                                                    return <li role="" key={index} className={lesson.uri_segment == this.props.lessonUriSegment ? "is-active" : undefined}>
                                                        <Link onClick={this.props.onLessonChange} to={this.props.bookPath + lesson.uri_segment}><h3 className={viewClass + "menu-h3"}>{lesson.name}</h3></Link>
                                                    </li>;
                                                })
                                            }
                                        </ul>
                                    </li>
                                })
                            }
                        </ul>
                    </li>

                    <li key="lecke" className="main-item" role="" style={{ display: this.props.sections.filter(item => item.name != '').length < 1 ? "none" : '' }}>
                        <Link to="#"><h2 className="main-menu-item">{__("A lecke tartalma")}</h2></Link>
                        <ul id="magellanMenu" ref="subMenuElement1ToOpen" className="vertical nested menu is-active" data-magellan="" data-bar-offset="35">
                            {
                                this.props.sections.map((section, index) => {
                                    if (!section.name) return;

                                    return <li key={index}><a onClick={this.onScrollTo.bind(this, "section-" + section.id)} href={"#section-" + section.id}><h3 className="menu-h3">{section.name}</h3></a></li>
                                })
                            }
                        </ul>
                    </li>


                    <li key="keigeszito" className="main-item" style={{ display: ((this.props.supplementaryMaterials && this.props.supplementaryMaterials.length > 0) || this.props.reveals.length > 0) || ((this.props.viewMode == ViewMode.BOOK || this.props.viewMode == ViewMode.SIMPLE || this.props.viewMode == ViewMode.CONTENT) && containsNonTextSection) ? 'block' : 'none' }}>

                        <a href="#">{__("Kiegészítő anyagok")}</a>
                        <ul ref="subMenuElement2ToOpen" className="vertical nested menu is-active submenu is-accordion-submenu" data-magellan="" data-bar-offset="35">
                            {
                                this.props.supplementaryMaterials
                                    ?
                                    this.props.supplementaryMaterials.map((externalLink, index) => {
                                        if (!externalLink.name) return;

                                        return <li key={"link-" + index}><a href={externalLink.url!} target="_blank">{externalLink.name}</a></li>
                                    })
                                    :
                                    null
                            }

                            {
                                Array.from(this.props.reveals).map((reveal, index) => {
                                    return <li key={"reveal-" + index}>
                                        <a onClick={this.onReveal.bind(this, reveal.id)}>{reveal.name}</a>
                                    </li>;
                                })
                            }

                            {
                                this.props.sectionTypes && (this.props.viewMode == ViewMode.BOOK || this.props.viewMode == ViewMode.SIMPLE || this.props.viewMode == ViewMode.CONTENT) ?
                                    <>
                                        <li className={containsSectionType(this.props.sectionTypes, SECTION_TYPES_TEXT) ? "is-active" : ""}>
                                            <a onClick={() => this.handleSectionTypeChange(SECTION_TYPES_TEXT)}>{__("Szöveg")}</a>
                                        </li>
                                        {
                                            containsSection(SECTION_TYPES_EXERCISE_IFRAME, this.props.sections) &&
                                            <li className={containsSectionType(this.props.sectionTypes, SECTION_TYPES_EXERCISE_IFRAME) ? "is-active" : ""}>
                                                <a onClick={() => this.handleSectionTypeChange(SECTION_TYPES_EXERCISE_IFRAME)}>{__("Feladatok")}</a>
                                            </li>
                                        }
                                        {
                                            containsSection(SECTION_TYPES_VIDEO, this.props.sections) &&
                                            <li className={containsSectionType(this.props.sectionTypes, SECTION_TYPES_VIDEO) ? "is-active" : ""}>
                                                <a onClick={() => this.handleSectionTypeChange(SECTION_TYPES_VIDEO)}>{__("Videók")}</a>
                                            </li>
                                        }
                                    </>
                                    : null
                            }
                        </ul>

                    </li>

                </ul>
            </Toggle>
        </div>
    }

    onReveal(id: string) {
        let $ = (this.refs.menuElement as any).ownerDocument.defaultView["$"];
        ($("#" + id) as any).foundation('open');

        ($("#" + id) as any).find("img").each((i: number, e: HTMLElement) => {
            if (e.getAttribute("data-src")) e.setAttribute("src", e.getAttribute("data-src") || "");
            if (e.getAttribute("data-srcset")) e.setAttribute("srcset", e.getAttribute("data-srcset") || "");
        });
    }

    onScrollTo(sectionHtmlId: string, event: any) {
        const sectionToScrollTo = document.getElementById(sectionHtmlId);

        if (sectionToScrollTo && this.refs.menuElement) {
            const topMenuHeight = (this.refs.menuElement as HTMLElement).clientHeight;

            const offsetTop = $(sectionToScrollTo).offset()!.top - topMenuHeight - 5;

            $('html, body').stop().animate({
                scrollTop: offsetTop
            }, 600);

            event.preventDefault();
        }
    }

    handleResize() {
        this.setMobile();
    }

    componentDidUpdate(prevProps: BookMenuProps, prevState: BookMenuState) {
        this.setMobile();
        const menuToggleElement: Toggle = this.refs.menuToggleElement as Toggle;


        if (this.props.lessonUriSegment == "tartalomjegyzek" && menuToggleElement) {

            this.state.isOpen ? "" : $('.top-bar-right .nav-icon').removeClass('open'); // hamburger ikon zárva
            if (prevProps != this.props) {
                this.setState({ isOpen: false });
            }

        } else if (prevProps.lessonUriSegment == "tartalomjegyzek" && menuToggleElement && this.props.viewMode != ViewMode.SIMPLE && this.props.viewMode != ViewMode.PAGED && this.props.viewMode != ViewMode.PRESENTATION) {

            // Ha nem tartalomjegyzék lapra érkezünk, akkor nyíljon ki a tankönyv menü
            if (screen.width >= 800) { // de csak ha nem mobilon vagyunk TODO erre kell konstans vagy inkább globális metódus isMobileLayout()
                requestAnimationFrame(() => {
                    if (this.refs.menuElementToOpen) (menuToggleElement as any).open();//($(menuElement) as any).foundation('down', $(this.refs.menuElementToOpen))
                    if (this.refs.subMenuElement1ToOpen) (menuToggleElement as any).open();//($(menuElement) as any).foundation('down', $(this.refs.subMenuElement1ToOpen))
                    if (this.refs.subMenuElement2ToOpen) (menuToggleElement as any).open();//($(menuElement) as any).foundation('down', $(this.refs.subMenuElement2ToOpen))
                    $('.top-bar-right .nav-icon').addClass('open'); // hamburger ikon nyitva
                });
                if (prevProps != this.props) {
                    this.setState({ isOpen: true })
                }
            }

        }

        if (this.props.viewMode == ViewMode.SIMPLE || this.props.viewMode == ViewMode.PAGED || this.props.viewMode == ViewMode.PRESENTATION) {
            // this.closeBookMenu();
        }

        this.updateScrollActiveSetter();

    }

    componentDidMount() {

        const menuElement: HTMLElement = this.refs.menuElement as HTMLElement;

        window.addEventListener('resize', this.handleResize.bind(this));
        try {
            let $: any;
            $ = menuElement.ownerDocument!.defaultView!["$"];
            // This is $(document).foundation(), except work also if this element is in an other window (preview)
            menuElement.ownerDocument!.defaultView!["$"](menuElement.ownerDocument).foundation();

            $('.btn-main-menu').click(function () {
                $('.btn-main-menu.nav-icon').toggleClass('open');
                $('.main-menu').toggleClass('open');
            });

            $('.top-bar-right .menu-control').click(function () {
                $('.top-bar-right .nav-icon').toggleClass('open');
                ($('#app-menu') as any).foundation('hideAll');
                // if ($('.book-menu>li').attr('aria-expanded') == 'true') {
                //     $('.top-bar-right .nav-icon').addClass('open');
                // }
                // else {
                //     $('.top-bar-right .nav-icon').removeClass('open');
                // }
            });

            $('.btn-mobile-menu').click(function (e: any) {
                e.preventDefault();
                $('body').toggleClass('smenu');
                $('.btn-mobile-menu.nav-icon').toggleClass('open');
            });



            this.setMobile();
            //let thisResize = this.setMobile();
            //$(window).resize(function() {thisResize;});

            //menu scroll 
            let options = {
                alwaysShowTracks: true,
                continuousScrolling: false
            };
            let scrollBar = Scrollbar.init((document.querySelector('.menuScroll')! as HTMLElement), options);
            this.setState({ scrollBar: scrollBar });
            $(window).on({
                'down.zf.accordionMenu': function () {
                    scrollBar.update()
                },
                'up.zf.accordionMenu': function () {
                    scrollBar.update()
                }
            });

            if (screen.width < 1024) {
                //close accordion menu
                ($('#app-menu') as any).foundation('hideAll');
                //close toggle menu
                (this.refs.menuToggleElement as Toggle).close();
                $('.top-bar-right .nav-icon').toggleClass('open');
            }
            const menuToggleElement: Toggle = this.refs.menuToggleElement as Toggle;
            if (this.props.lessonUriSegment != "tartalomjegyzek" && !this.props.book.is_sni && menuToggleElement) {
                if (screen.width >= 800) {
                    requestAnimationFrame(() => {
                        if (this.refs.menuElementToOpen) menuToggleElement.open();
                        if (this.refs.subMenuElement1ToOpen) menuToggleElement.open();
                        if (this.refs.subMenuElement2ToOpen) menuToggleElement.open();
                        $('.top-bar-right .nav-icon').addClass('open');
                    });
                    this.setState({ isOpen: true });
                }

            }


        } catch (e) {
            // Jquery hiányzik
            console.log(e);
        }

        this.updateScrollActiveSetter();
    }

    componentWillUnmount() {
        this.removeLastScrollHandler();
    }

    private lastScrollHandler: any;

    updateScrollActiveSetter() {
        this.removeLastScrollHandler();

        //Cache selectors
        var lastId: any,
            topMenu = $(".top-bar"),
            topMenuHeight = topMenu.outerHeight();
        // All list items
        // Anchors corresponding to menu items

        var menuItems = topMenu.find('.spymenu a[href*="#section"]');

        var scrollItems = menuItems.map(function (index: number, menuitem: Element) {
            if (menuitem && menuitem.getAttribute("href") && menuitem.getAttribute("href")!.startsWith("#section")) {
                var item = $(menuitem.getAttribute("href") || "");
                if (item.length) { return item; }
            }
            return null;
        });

        // Bind to scroll
        this.lastScrollHandler = $(window).scroll(function (event) {
            var $this = $(event.target);
            var scrollTop = $this.scrollTop();

            if (!scrollTop || !topMenuHeight) {
                return;
            }

            // Get container scroll position
            var fromTop = scrollTop + topMenuHeight + 30 /* Ez nélkül ha rákattintasz a lecke tartalmában egy szekcióhoz, akkor a felette lévő szekció lesz a menüben is-active*/;

            // Get id of current scroll item

            var cur: any = scrollItems.map(function (index: number, $item: any) {
                if ($item.offset().top < fromTop) return $item;
            });
            // Get the id of the current element
            cur = cur[cur.length - 1];
            var id = cur && cur.length ? cur[0].id : "";

            if (lastId !== id) {
                lastId = id;
                // Set/remove active class
                menuItems
                    .parent().removeClass("is-active")
                    .end().filter("[href='#" + id + "']").parent().addClass("is-active");
            }
        });
    }

    removeLastScrollHandler() {
        if (this.lastScrollHandler) {
            $(window).off("scroll", this.lastScrollHandler);
            this.lastScrollHandler = null;
        }
    }

}
