import * as React from 'react';
import { getDetailForFile } from '@src/component/filemanager/ContentUtils';
export default class HoverImg extends React.Component<{id?: number, sha1?:string, alt?: string,title?: string, media_type_id?: number, ext?: string, width?: number, src?:string}, any> {
    constructor(props : any) {
        super(props);
        this.state = {
            
        };        
    }

    render() {

        const data = getDetailForFile(this.props.id, this.props.sha1 || "", this.props.media_type_id, this.props.ext, this.props.width);
        var objectFit: "contain" | "cover" = "cover";
        if (data.thumbnailUrl.endsWith(".svg")) {
            objectFit = "contain";
        }

        return <div className="img-wrapper">
            <a href={data.fileUrl} data-lightbox data-title={this.props.title?this.props.title:""}>
            <img
            className={data.thumbnailUrl.endsWith(".svg") ? "icon" : ""}
            src={data.thumbnailUrl?data.thumbnailUrl: this.props.src} 
            alt={this.props.alt} 
            title={this.props.title} 
            style={{objectFit: objectFit}}
            onError={(e) => {e.currentTarget.src = this.props.src?this.props.src:"/img/placeholder/play_200.png"}}
            width={this.props.width || undefined}
            />
            </a></div>

    }
}
