import './style.css';
import { ExerciseBaseClass } from '@src/component/exercise/models/ExerciseBaseClass';
import { AExerciseEngine } from '../../models/AExerciseEngine';
import { __ } from '@src/translation';
import { EKECrossWordServer } from './EKECrossWordServer';

export interface CrossWordData extends ExerciseBaseClass {
    words: Words[];
    tablecols: number;
    isHintTextVisible: boolean;
}

type Words = {
    description: string;
    startat: number;
    letters: number;
    join: number;
    join_letter: string;
    hint_index: any;
    hint_letter: any;
    sound_url?: string;
}

type CrossWordSolution = {
    answer: string[];
    fullmatch: boolean;
}

export class EKECrossWordExerciseEngine extends AExerciseEngine {
    private inputrows: any[] = [];
    private gotcorrectsolution: boolean = false;

    initExercise(params: ExerciseParams): void {
        super.initExercise(params);
        this.gotcorrectsolution = false;

        this.root.classList.add("eke-crossword");
        this.inputrows = [];
        let inputelemets: HTMLElement[];
        let exercise: CrossWordData = params.exercise;
        if (!exercise.words)
            return;

        this.setIllustration(exercise, this.root);
        let wordsNum = exercise.words.length;

        let accessHelper = document.createElement("p");
        accessHelper.classList.add("show-for-sr");
        accessHelper.innerText = __("Képernyőolvasóval való feladatmegoldáshoz a virtuális kurzort ki kell kapcsolni.");
        this.root.appendChild(accessHelper);
        /* TGY - Mobilon nagyitas */
        /* Kontenerek */
        let exerciseWrapper = this.root.appendChild(document.createElement("div"));
        exerciseWrapper.setAttribute("class", "exercise-wrapper");

        let exerciseControl = exerciseWrapper.appendChild(document.createElement("div"));
        exerciseControl.setAttribute("class", "exercise-control");

        let exerciseContainer = exerciseWrapper.appendChild(document.createElement("div"));
        exerciseContainer.setAttribute("data-zoom", "0");
        /* TGY */

        //let exerciseContainer = this.root.appendChild(document.createElement("div"));  - TGY
        exerciseContainer.setAttribute("id", "eke-crossword-exerciseContainer");
        let accesP = document.createElement('p');
        accesP.classList.add("show-for-sr");
        accesP.innerText = __("{wordsNum} darab szót keresünk.", { wordsNum: wordsNum });
        exerciseContainer.appendChild(accesP);

        let preWords = 0;
        let postwords = 0;
        for (let index = 0; index < wordsNum; index++) {
            if (preWords < exercise.words[index].join) {
                preWords = exercise.words[index].join;
            }
            if (postwords < (exercise.words[index].letters - exercise.words[index].join)) {
                postwords = (exercise.words[index].letters - exercise.words[index].join)
            }
        }
        let tablecols = preWords + postwords + 1;

        for (let index = 0; index < wordsNum; index++) {
            // this.descriptions.push(exercise.words[index].description);
            let hintRow = exerciseContainer.appendChild(document.createElement("div"));
            hintRow.setAttribute('id', index + '_hintRow');
            hintRow.classList.add("grid-x");
            hintRow.classList.add("hint-row", index + "hrI");
            hintRow.classList.add("row");
            hintRow.classList.add("align-center");
            hintRow.classList.add("callout", "warning");
            //Row with sound hit
            if (exercise.words[index].sound_url && exercise.words[index].sound_url != "") {
                let soundTag = hintRow.appendChild(document.createElement("AUDIO"));
                soundTag.setAttribute("src", exercise.imagebasepath + exercise.words[index].sound_url);
                let playIcon = hintRow.appendChild(document.createElement("i"));
                playIcon.classList.add("fa", "fa-play", "fa-2x", "crossword-play-sound");
                playIcon.addEventListener('click', AExerciseEngine.playAnswerSound.bind(this, hintRow, this, "crossword-play-sound"));
                let soundAltSpan = hintRow.appendChild(document.createElement("span"));
                soundAltSpan.innerText = exercise.words[index].description;
            }
            //There is no sound hint
            else hintRow.innerHTML = exercise.words[index].description;
            hintRow.setAttribute("aria-hidden", "true");

            let startat = (preWords - exercise.words[index].join);
            let answerRow = exerciseContainer.appendChild(document.createElement("div"));
            answerRow.classList.add("grid-x");
            answerRow.classList.add("row");
            answerRow.classList.add("align-center");
            answerRow.setAttribute("id", "" + index + "_answerRow");
            answerRow.addEventListener('click', this.onClicked.bind(this), false);
            inputelemets = [];
            for (let inner = 0; inner < tablecols; inner++) {
                let answerCol = answerRow.appendChild(document.createElement("div"));
                answerCol.setAttribute("id", inner + "_" + index);
                answerCol.classList.add("eke-crossword-particle");


                if (startat <= inner && inner <= exercise.words[index].letters + startat) {

                    answerCol.classList.add("eke-crossword-box-border");
                    answerCol.classList.add("white");

                    answerCol.classList.add("" + index + "_hasInputField");
                    answerCol.classList.add("crosswordInputContainer");
                    let answerinput = answerCol.appendChild(document.createElement("input"));
                    if (inner == startat) {
                        answerinput.setAttribute("aria-label", __("{description} {letters} betűs szót keresünk.", { description: exercise.words[index].description, letters: (exercise.words[index].letters + 1) }));
                    }
                    answerinput.setAttribute("type", "text");
                    answerinput.setAttribute("autocomplete", "off");
                    answerinput.setAttribute("maxlength", "2");

                    answerinput.setAttribute("id", inner + "_" + index); //Bad practice, nem kéne 2 elemnek ugyan olyan id-vel rendelkeznie!!!
                    answerinput.classList.add(inner + "_" + index);
                    answerinput.classList.add(index + "_letterInput");
                    answerinput.classList.add("crosswordinput");

                    if (this.isReplay) {
                        answerinput.setAttribute('disabled','disabled');
                    }

                    if (exercise.isHintTextVisible && (exercise.words[index].hint_index instanceof Array) == false && Number(exercise.words[index].hint_index) + startat == inner) {
                        answerinput.value = exercise.words[index].hint_letter;
                        answerinput.classList.add('eke-crossword-hintLetter');
                        //answerinput.readOnly = true;
                        answerinput.setAttribute("data-value",answerinput.value);
                        answerinput.oninput = this.onHintLetterInput.bind(this);
                    }
                    else if (exercise.isHintTextVisible && exercise.words[index].hint_index instanceof Array) {
                        for (let k = 0; k < exercise.words[index].hint_index.length; k++) {
                            if (Number(exercise.words[index].hint_index[k]) + startat == inner) {
                                answerinput.value = exercise.words[index].hint_letter[k];
                                answerinput.classList.add('eke-crossword-hintLetter');
                                answerinput.setAttribute("data-value",answerinput.value);
                                //answerinput.readOnly = true;
                                answerinput.oninput = this.onHintLetterInput.bind(this);
                            }
                        }
                    } else {
                        answerinput.oninput = this.onInput.bind(this);
                    }

                    if (exercise.words[index].join + startat == inner) {
                        // if (!exercise.isHintTextVisible) {
                        // answerinput.disabled = true;
                        answerinput.classList.add('eke-crossword-highlight');
                        if (inner == startat) {
                            answerinput.setAttribute('aria-label', __('A megfejtés egyik betűje.') + __("{description} {letters} betűs szót keresünk", { description: exercise.words[index].description, letters: (exercise.words[index].letters + 1) }));
                        } else {
                            answerinput.setAttribute('aria-label', __('A megfejtés egyik betűje.'));
                        }/* }
                         else
                             answerinput.value = exercise.words[index].join_letter;*/

                    }
                    $(answerinput).focus(this.onClicked.bind(this));
                    //if (!answerinput.classList.contains("eke-crossword-hintLetter")) { //Menjen rá a csak olvashatóakra is
                    answerinput.addEventListener("keyup", this.onKeyUp.bind(this));
                    answerinput.addEventListener('click', this.onClicked.bind(this), false);
                    inputelemets.push(answerinput);
                    //answerinput.onfocus = this.onHintLetterInput.bind(this);
                    //}
                }
            }
            let accessibilityHelperSpan = document.createElement("span");
            accessibilityHelperSpan.classList.add("show-for-sr");
            accessibilityHelperSpan.setAttribute("id", "accessibility-span-" + index);
            accessibilityHelperSpan.setAttribute("tabIndex", "0");

            answerRow.appendChild(accessibilityHelperSpan);
            this.inputrows.push(inputelemets);

        }
        /*
          <div class="callout warning EKECalcGenTextArea" id="EKECalcGenSolutionPanel" data-toggler="is-hidden" data-animate="hinge-in-from-top" data-closable>
            <button class="close-button" data-close>&times;</button>
            <div id="EKECalcGenSolutionmessageTitle">Helyes válasz:</div>
            <div id="EKECalcGenSolutionmessageContent"></div>
        </div>
        */
        let questionContainer = this.root.appendChild(document.createElement("div"));
        questionContainer.setAttribute("id", "eke-crossword-question-toogle");
        questionContainer.classList.add("callout", "warning");
        questionContainer.setAttribute("data-toggler", "is-hidden");
        questionContainer.setAttribute("data-animate", "hinge-in-from-top");
        questionContainer.setAttribute("data-closable", "");
        questionContainer.innerHTML = '<button class="close-button" data-close>&times;</button>';
        let questionTextContainer = questionContainer.appendChild(document.createElement("div"));
        questionTextContainer.setAttribute("id", "eke-crossword-questionContainer");

        ($(this.root) as any).foundation();

        /* TGY: Nagyitas */

        /* Arany szamolo fuggveny */
        function xGetFitScale(container: HTMLDivElement) {
            let scrollWidth = container.scrollWidth;
            let scrollHeight = container.scrollHeight;
            let offsetWidth = container.offsetWidth;
            let offsetHeight = container.offsetHeight;

            let scale = Math.min(
                offsetWidth / scrollWidth,
                offsetHeight / scrollHeight
            );

            return scale;
        }


        /* Alap kicsinyitesi arany */
        let exerciseScale = xGetFitScale(exerciseContainer);

        if (exerciseScale != 1) {
            exerciseContainer.style.transform = "scale(" + exerciseScale + ")";
            /* Nagyito gomb */
            let zoomButton = exerciseControl.appendChild(document.createElement("button"));
            zoomButton.classList.add("exercise-zoom");
            /* Gomb esemeny + nagyitas */
            zoomButton.addEventListener("click", function (event) {
                exerciseScale = xGetFitScale(exerciseContainer);

                let zoom = parseInt(exerciseContainer.getAttribute('data-zoom')!);
                zoom = (zoom < 2) ? zoom + 1 : 0;
                let zoomScale = 1;

                switch (zoom) {
                    case 0:
                        zoomScale = exerciseScale;
                        break;
                    case 1:
                        zoomScale = exerciseScale + (1 - exerciseScale) / 2;
                        break;
                    default:
                        zoomScale = 1;
                }

                exerciseContainer.style.transform = "scale(" + zoomScale + ")";
                //exerciseContainer.setAttribute('data-zoom', zoom)
                exerciseContainer.setAttribute('data-zoom', String(zoom));
            }, false);
        } else {
            exerciseControl.setAttribute("hidden", "true");
        }
        /* TGY */

        let accesSolutionDiv = document.createElement("div");
        accesSolutionDiv.classList.add("show-for-sr");
        accesSolutionDiv.setAttribute("id", "acces-solution-div");
        exerciseContainer.appendChild(accesSolutionDiv);

    }

    getUserSolution(): CrossWordSolution {

        let result = [];
        let rowLetter: string;
        let crossWordInputFields: any = this.root.getElementsByClassName("crosswordinput");
        //for (let index = 0; index < this.descriptions.length; index++) {
        for (let index = 0; index < this.inputrows.length; index++) {
            rowLetter = "";
            for (let inner = 0; inner < crossWordInputFields.length; inner++) {
                if (+crossWordInputFields[inner].id.split("_", 2)[1] == index) {
                    rowLetter += (crossWordInputFields[inner].value);
                }
            }
            result.push(rowLetter.toUpperCase());
        }
        let solution = { fullmatch: true, answer: result };
        return solution;
    }

    receiveEvaluation(evaluated: Evaluated): void {
        //Evaulation char by char
        $(".accessibility-p").remove();
        for (let i = 0; i < this.inputrows.length; i++) {
            //getting the input fields of thecurrent row
            let inputs: any = this.root.getElementsByClassName(i + "_letterInput");
            for (let j = 0; j < inputs.length; j++) {
                //first remove all of the styles from the input fields
                let accesElement = document.createElement("p");
                accesElement.classList.add("show-for-sr", "accessibility-p");
                this.removeStyles(inputs[j] as HTMLHtmlElement);
                if (evaluated.success) {
                    inputs[j].classList.add("exe-engine-check-correct");
                    if (!inputs[j].classList.contains("eke-crossword-hintLetter")) {
                        accesElement.innerText = __("Helyes megoldás");
                        inputs[j].parentElement.insertBefore(accesElement, inputs[j]);
                    }
                } else if (evaluated.solution == null) {
                    inputs[j].classList.add("exe-engine-check-wrong");
                } else {

                    if (inputs[j].value.toUpperCase() == evaluated.solution[i][j].toUpperCase()) {
                        //if the current char is correct
                        inputs[j].classList.add("exe-engine-check-correct");
                        if (!inputs[j].classList.contains("eke-crossword-hintLetter")) {
                            accesElement.innerText = __("Helyes megoldás");
                            inputs[j].parentElement.insertBefore(accesElement, inputs[j]);
                        }
                    } else if (!this.isSNIexc) {
                        inputs[j].classList.add("exe-engine-check-wrong");
                        if (!inputs[j].classList.contains("eke-crossword-hintLetter")) {
                            accesElement.innerText = __("Helytelen megoldás");
                            inputs[j].parentElement.insertBefore(accesElement, inputs[j]);
                        }
                    }
                }
            }
        }
    }


    showCorrectSolution(solution: any): void {
        this.gotcorrectsolution = true;

        for (let outerIndex = 0; outerIndex < solution.length; outerIndex++) {
            let crossWordInputFields: any = this.root.getElementsByClassName(outerIndex + "_letterInput");
            for (let innerIndex = 0; innerIndex < crossWordInputFields.length; innerIndex++) {
                this.removeStyles(crossWordInputFields[innerIndex] as HTMLHtmlElement);
                if (solution[outerIndex][innerIndex]) crossWordInputFields[innerIndex].value = solution[outerIndex][innerIndex];
                crossWordInputFields[innerIndex].parentElement.classList.add("eke-engine-show-correct-highlight");
            }
        }
    }

    isUserReady(): boolean {
        let prevActiveRow: any = this.root.getElementsByClassName("crosswordinput");
        for (let index = 0; index < prevActiveRow.length; index++) {
            if (prevActiveRow[index].value != "" && !this.gotcorrectsolution)
                return true
        }
        return false;
    }

    showHelp(solution: any): void {
        this.showCorrectSolution(solution);
    }

    onClicked(ev: any, curr_element: any) {
        if (curr_element == undefined)
            curr_element = ev.target as HTMLElement;
        let descriptionIndex = (curr_element.parentElement).parentElement.id.split("_", 1)[0];
        let inputContainer: any = this.root.getElementsByClassName(descriptionIndex + "_hasInputField");
        let hintContainer: any = this.root.getElementsByClassName("hint-row");
        for (let hintBox of hintContainer)
            hintBox.style.display = "none";
        let inputFields: any = this.root.getElementsByClassName("crosswordInputContainer");
        for (let index = 0; index < inputFields.length; index++) {
            inputFields[index].classList.remove("eke-crossword-activeInputBackground");
        }
        for (let inputItem of inputContainer) {
            inputItem.classList.add("eke-crossword-activeInputBackground");
        }
        let hintRow: any = this.root.getElementsByClassName(descriptionIndex + "hrI");
        $(hintRow[0]).css('display', 'block');

    }


    onKeyUp(ev: any) {

        let solutionLetters = document.getElementsByClassName("eke-crossword-highlight");
        let solutionElement = document.createElement("p");
        solutionElement.classList.add("show-for-sr");
        let solutionWord = "";
        let curr_keycode = ev.keyCode;

        for (let i = 0; i < solutionLetters.length; i++) {
            solutionWord += (solutionLetters[i] as any).value;
        }
        (document.getElementById("acces-solution-div") as any).innerText = __("A megfejtésed: {solutionWord}", { solutionWord: solutionWord });

        let currentRow = ev.target.id.split("_", 2)[1];
        let controlKeys = [KeyCodes.BACKSPACE, KeyCodes.ARROW_LEFT, KeyCodes.ARRROW_RIGHT, KeyCodes.ARROW_UP, KeyCodes.ARROW_DOWN, KeyCodes.ENTER, KeyCodes.DELETE, KeyCodes.TAB, KeyCodes.HOME, KeyCodes.END];

        for (let currentCol = 0; currentCol < this.inputrows[currentRow].length; currentCol++) {

            if (this.inputrows[currentRow][currentCol].id != ev.target.id) continue;

            if (this.inputrows[currentRow][this.inputrows[currentRow].length - 1].id != this.inputrows[currentRow][currentCol].id
                && !(controlKeys.indexOf(curr_keycode) > -1)) {
                continue;
            }

            try {
                let element: any;
                if (curr_keycode == KeyCodes.BACKSPACE || curr_keycode == KeyCodes.DELETE) { //Backspace, Delete
                    if (!this.inputrows[currentRow][currentCol].readOnly) {
                        if (this.inputrows[currentRow][currentCol].value === "")
                            this.jumpNext(currentRow, currentCol, -1);
                        else
                            this.inputrows[currentRow][currentCol].value = "";
                    }

                } else if (curr_keycode == KeyCodes.ARROW_LEFT) { //Bal
                    this.jumpNext(currentRow, currentCol, -1);
                } else if (curr_keycode == KeyCodes.ARROW_UP) { //Fel
                    this.jumpUpOrDown(currentRow, -1);
                }
                else if (curr_keycode == KeyCodes.ARRROW_RIGHT) //Jobbra
                    this.jumpNext(currentRow, currentCol, 1);
                else if (curr_keycode == KeyCodes.ARROW_DOWN) { //Le
                    this.jumpUpOrDown(currentRow, 1);
                }
                else if (curr_keycode == KeyCodes.HOME) { //jumps to actual row's first input
                    this.jumpToHome(currentRow);
                }
                else if (curr_keycode == KeyCodes.END) { //jumps to actual row's last input
                    this.jumpToEnd(currentRow);
                }
                element.focus();
                this.onClicked(null, element);
            } catch (e) {
                // throw e;
                //Nem tud tovább menni,mert kifutott a tömbből
            }
        }
    }

    onHintLetterInput(ev: any) {
        ev.preventDefault();
        let fixValue = ev.target.getAttribute("data-value");
        let newTarget = ev.target;
        newTarget.value=fixValue;
        this.onInput({target:newTarget});
    }

    onInput(ev: any) {
        this.gotcorrectsolution = false;
        let currentRow = ev.target.id.split("_", 2)[1];
        let str = "";
        let helperString = "";
        let inputs = document.getElementById(currentRow + "_answerRow")!.getElementsByClassName("crosswordinput");
        let readonlyInputs = document.getElementById(currentRow + "_answerRow")!.getElementsByClassName("eke-crossword-hintLetter");
        [].forEach.call(inputs, function (item: any) {
            str += item.value.toString();
        });
        let lastInput = this.inputrows[currentRow][this.inputrows[currentRow].length - 1];
        lastInput.removeAttribute("aria-label");
        for (let currentCol = 0; currentCol < this.inputrows[currentRow].length; currentCol++) {
            if (ev.target.value.toString().length > 1)
                ev.target.value = ev.target.value.toString().charAt(1);

            if (this.inputrows[currentRow][currentCol].id == ev.target.id &&
                lastInput.id != this.inputrows[currentRow][currentCol].id) {

                if (str.length >= this.inputrows[currentRow].length + readonlyInputs.length) {
                    helperString = __("A sorban az összes beviteli mező kitöltve. A(z) {currRow}. sorba beírt szó: {str}", { currRow: ((+currentRow) + (+1)), str: str });
                    lastInput.setAttribute("aria-label", helperString);
                    lastInput.focus();
                }
                else if (ev.target.value != '') {
                    this.jumpNext(currentRow, currentCol, +1);
                }
            }

            if (this.inputrows[currentRow][currentCol].id == ev.target.id && lastInput.id == ev.target.id) {
                if (str.length < this.inputrows[currentRow].length) {
                    helperString = __("A sorban nincs minden beviteli mező kitöltve. A(z) {currRow}. sorba beírt töredékszó: {str}", { currRow: ((+currentRow) + (+1)), str: str });
                    lastInput.setAttribute("aria-label", helperString);
                    lastInput.focus();
                } else {
                    helperString = __("A sorban az összes beviteli mező kitöltve. A(z) {currRow}. sorba beírt szó: {str}", { currRow: ((+currentRow) + (+1)), str: str });
                    lastInput.setAttribute("aria-label", helperString);
                    lastInput.focus();
                }

            }
        }
        if (this.isSNIexc) this.SNIEvaluation(EKECrossWordServer);

    }

    removeStyles(element: HTMLElement): void {
        element.classList.remove("exe-engine-correct-bg", "exe-engine-wrong-bg", "eke-engine-show-correct-bg", "eke-engine-checked-bg", "exe-engine-check-correct", "exe-engine-check-wrong");
    }

    private jumpNext(currentRow: number, currentCol: number, distance: number) {

        let bool = this.inputrows[currentRow][currentCol + distance].disabled === true;
        if (bool) {
            this.jumpNext(currentRow, currentCol + distance, distance);
        }
        this.inputrows[currentRow][currentCol + distance].focus(); //HA érvényes karaktert írt be
    }

    private jumpUpOrDown(currentRow: number, distance: number) {
        this.inputrows[Number(currentRow) + Number(distance)][0].focus();
    }

    private jumpToHome(currentRow: any) {
        this.inputrows[currentRow][0].focus();
    }

    private jumpToEnd(currentRow: any) {
        this.inputrows[currentRow][this.inputrows[currentRow].length - 1].focus();
    }
}

enum KeyCodes {
    BACKSPACE = 8,
    TAB = 9,
    ENTER = 13,
    SHIFT = 16,
    CTRL = 17,
    ALT = 18,
    PAUSE_BREAK = 19,
    CAPSLOCK = 20,
    ESCAPE = 27,
    BLANKSTEG = 32,
    PAGE_UP = 33,
    PAGE_DOWN = 34,
    END = 35,
    HOME = 36,
    ARROW_LEFT = 37,
    ARROW_UP = 38,
    ARRROW_RIGHT = 39,
    ARROW_DOWN = 40,
    INSERT = 45,
    DELETE = 46,
    WINDOWS = 91,
    SELECT_KEY = 93,
    NUM_MULTIPLY = 106,
    NUM_ADD = 107,
    NUM_SUBTRACT = 109,
    NUM_DECIMAL_POINT = 110,
    NUM_DIVIDE = 111,
    F1 = 112,
    F2 = 113,
    F3 = 114,
    F4 = 115,
    F5 = 116,
    F6 = 117,
    F7 = 118,
    F8 = 119,
    F9 = 120,
    F10 = 121,
    F11 = 122,
    F12 = 123,
    NUM_LOCK = 144,
    SCROLL_LOCK = 145,
    KEY_SLASH = 173,
    AUDIO_MUTE = 181,
    AUDIO_VOLUME_DOWN = 182,
    AUDIO_VOLUME_UP = 183,
    KEY_COMMA = 188,
    KEY_PERIOD = 190,
}
