import * as React from 'react';
import { Accordion, AccordionItem } from '@src/component/ui/Accordion';
import { BubbleLoader } from 'react-css-loaders';

import { app } from '@src/index';
import MediaFileChooser, { FILE_ACTIONS } from '@src/component/filemanager/MediaFileChooser';
import { match } from 'react-router';
import { ILessonplanBlockRecord } from '@src/framework/crud/doc/LessonplanBlockCrud';
import MetaCategoryAssignerPlugin from '@src/component/meta/meta_category_assigner_plugin';
import { MetaCategorization, setCategoryIds } from '@src/component/meta/meta_category_assigner_api';
import { IOoFolderRecord } from '@src/framework/crud/media/OoFolderCrud';
import ButtonGroup, { ButtonSize, ButtonAlign } from '@src/component/ui/ButtonGroup';
import { WorkflowDialog } from '../wf/WorkflowDialog';
import { __ } from '@src/translation';
import { IRecord } from '@src/framework/crud/Crud';
import OoFileCrud from '@src/framework/crud/media/OoFileCrud';
import { Dialog } from '../Dialog';
import ImageSettings from '../book/editor/ImageSettings';
import { MetaKeywordAssignerPlugin } from '../meta/meta_keyword_assigner_plugin';
import { Link } from 'react-router-dom';
import { PATH_LESSONPLAN_EDIT_LIST } from '@src/project/dpmk/lessonplan/lessonplan_routes';
import { IViewTransitionTypeRecordWithPerm } from '@src/framework/wf/WfAPI';
import { me, hasGroup, Groups } from '@src/framework/server/Auth';
import RenderIfHasPermission from '@src/framework/RenderIfHasPermission';
import { CrudPermissionType } from '@src/framework/security/PermissionCache';
//import { LIBRARY_JTR_BEADANDO_ID } from '@src/Const';

export const EMPTY_TECHICAL = {
    owner_id: undefined,
    is_active: undefined,
    creation_time: undefined,
    creation_user_id: undefined,
    creation_session_id: undefined,
    modification_time: undefined,
    modification_user_id: undefined,
    modification_session_id: undefined,
}

export enum AsyncStatus {
    LOADING, LOADED, SAVING
}

export type LessonPlanEditProps = {
    id?: number;
    onSave: (newId: number) => void;
    defaultLibraryId?: number;
    hideBackLink?: boolean;
    backLinkTo?: string;
}

export type LessonPlanEditState<PLAN extends IRecord, PLANBLOCK extends IRecord> = {
    // Type specific:
    lessonPlan: PLAN;
    lessonPlanBlocks: PLANBLOCK[];

    // All:
    folders: IOoFolderRecord[];
    ownerName: string;

    deletedBlocks: number[];

    status: AsyncStatus;

    isDataChanged?: boolean;

    //Meta kategorizálás
    categorization: MetaCategorization,
    subject_ids: { [category_type_id: string]: number | null }

    keywords: string[],

    //Dublin core
    //store: DublinCoreStore;
    workflowStateName?: string;
    workflowDialogOpen: boolean;

    // HTML Image selection
    showSelectDialog?: boolean;
    showImageDialog?: boolean;
    selectedImagePath?: string;
    tinyMCE?: any
}

export abstract class LessonPlanEdit<PROPS extends LessonPlanEditProps, STATE extends LessonPlanEditState<any, any>>
    extends React.Component<PROPS, STATE> {

    protected onChange(propName: string, value: any, callback?: () => void) {
        this.setState({ isDataChanged: true, lessonPlan: { ...this.state.lessonPlan, [propName]: value } }, callback);
    }

    protected onAddBlock() {
        this.state.lessonPlanBlocks.push({});

        this.setState({
            lessonPlanBlocks: this.state.lessonPlanBlocks, isDataChanged: true
        });
    }

    protected onRemoveBlock(index: number) {
        const deletedId = this.state.lessonPlanBlocks[index].id;
        if (deletedId) {
            this.state.deletedBlocks.push(deletedId);
        }

        this.state.lessonPlanBlocks.splice(index, 1);

        this.setState({
            lessonPlanBlocks: this.state.lessonPlanBlocks,
            deletedBlocks: this.state.deletedBlocks,
            isDataChanged: true
        });
    }

    protected moveExeUp(lpb: ILessonplanBlockRecord, e: React.FormEvent<HTMLButtonElement>) {

        e.stopPropagation();
        var newLessonPlanBlockList = (this.state.lessonPlanBlocks ? this.state.lessonPlanBlocks : []);
        var inxOfElement = newLessonPlanBlockList.indexOf(lpb);
        if (inxOfElement <= 0) return;
        newLessonPlanBlockList.splice(inxOfElement, 1);
        newLessonPlanBlockList.splice(inxOfElement - 1, 0, lpb);
        this.setState({
            lessonPlanBlocks: newLessonPlanBlockList, isDataChanged: true


        });
    }

    protected moveExeDown(lpb: ILessonplanBlockRecord, e: React.FormEvent<HTMLButtonElement>) {

        e.stopPropagation();
        var newLessonPlanBlockList = (this.state.lessonPlanBlocks ? this.state.lessonPlanBlocks : []);
        var inxOfElement = newLessonPlanBlockList.indexOf(lpb);
        if (inxOfElement >= newLessonPlanBlockList.length - 1) return;
        newLessonPlanBlockList.splice(inxOfElement, 1);
        newLessonPlanBlockList.splice(inxOfElement + 1, 0, lpb);
        this.setState({
            lessonPlanBlocks: newLessonPlanBlockList, isDataChanged: true
        });
    }

    protected abstract getTitles(): {
        mainTitle: string,
        mainPanelTitle: string,
        blockPanelTitle: string,
        blockTitle: string,
        secondPanelTitle: string,
        attachmentPanelTitle: string,
        disableBlockOrder?: boolean,
        tableInfoId: number,
        disableCheckforNodesWithLeaf?: boolean,
    };

    protected abstract getFormBeforeBlocks(): JSX.Element;

    protected abstract getFormAfterBlocks(): JSX.Element;

    protected abstract getConfirm(): JSX.Element;

    protected getSaveButton() {
        const config = this.getTitles();
        return <RenderIfHasPermission requirements={{ crud: { tableInfoId: config.tableInfoId, permissions: CrudPermissionType.U } }}>
            <button className="button success" onClick={() => this.setState({ isDataChanged: false }, this.onSave.bind(this))}>

                <i className="fa fa-save" /> &nbsp;{__("Mentés")}
            </button>
        </RenderIfHasPermission>;
    }

    protected abstract getExtraForm(): JSX.Element | null;

    protected abstract getBlockForm(block: any, index: number): JSX.Element;

    protected abstract reloadAsync(): void;

    protected abstract onSave(): void;

    protected getExtraButtons(): JSX.Element | null {
        return null;
    }

    protected onBeforeTransition(transition: IViewTransitionTypeRecordWithPerm) {
        return true;
    }

    render() {
        if (this.state.status == AsyncStatus.LOADING) {
            return <BubbleLoader />;
        }

        const titles = this.getTitles();

        const timeMinutes = this.state.lessonPlanBlocks.map(l => l.require_time_min).reduce((a, b) => (a || 0) + (b || 0), 0);

        return <div>
            <WorkflowDialog
                open={this.state.workflowDialogOpen}
                displayName={__("Óravázlat")}
                tableInfoId={titles.tableInfoId}
                recId={this.state.lessonPlan.id!}
                onClose={() => this.setState({ workflowDialogOpen: false })}
                onBeforeTransition={this.onBeforeTransition.bind(this)}
                onTransition={this.reloadAsync.bind(this)}
                autoPublishOnClosedStation={false}
                showHistory={true/* https://kb.itk.uni-eszterhazy.hu/project/38/task/2107 
                    this.state.lessonPlan["library_id"]!=LIBRARY_JTR_BEADANDO_ID
                    */}
            />
            <br />
            <div className="row" style={{ minWidth: "1024px" }}>
                <div className="column small-7">
                    <h4>
                        {!this.props.hideBackLink &&
                            <>
                                <Link to={this.props.backLinkTo ? this.props.backLinkTo as string : PATH_LESSONPLAN_EDIT_LIST}>{__("Óravázlatok")}</Link>
                                &nbsp;/&nbsp;
                            </>
                        }
                        {titles.mainTitle} {timeMinutes ? "(" + __(`{time} perc`, { time: timeMinutes }) + ")" : ""}
                    </h4>
                </div>
                {me ?
                    <div className="column small-5">
                        <ButtonGroup align={ButtonAlign.Right} size={ButtonSize.Normal}>
                            {
                                this.state.workflowStateName &&
                                <label className="label" style={{ alignSelf: "center", marginRight: "0.5rem" }}>{__("Állapot")}: {this.state.workflowStateName}</label>
                            }
                            {
                                this.state.lessonPlan.id && this.state.workflowStateName &&
                                <button disabled={(hasGroup(me, Groups.LessonPlanMentor)) || this.state.isDataChanged} className="button" onClick={() => this.setState({ workflowDialogOpen: !this.state.workflowDialogOpen })}>
                                    <i className="fa fa-project-diagram" />&nbsp;{__("Folyamat...")}
                                </button>
                            }
                            {
                                this.getSaveButton()
                            }
                        </ButtonGroup>
                    </div>
                    : ""
                }
            </div>

            <div className="row">

                <Accordion>
                    <AccordionItem title={titles.mainPanelTitle}>
                        {this.getFormBeforeBlocks()}
                    </AccordionItem>

                    {
                        this.getBlocks()
                    }

                    {this.getExtraForm()}

                    <AccordionItem title={titles.secondPanelTitle}>
                        {this.getFormAfterBlocks()}
                    </AccordionItem>

                    <AccordionItem title={titles.attachmentPanelTitle}>
                        <div className="row">
                            <div className="column small-12">
                                {
                                    this.state.lessonPlan.oo_folder_id
                                        ?
                                        <MediaFileChooser
                                            currentFolderId={this.state.lessonPlan.oo_folder_id}
                                            rootFolderId={this.state.lessonPlan.oo_folder_id}
                                            enabledActions={FILE_ACTIONS}
                                            showLocation={false}
                                            showFolders={false}

                                        />
                                        :
                                        <div className="column small-12 ">
                                            <p>{__("Csatolt állomány feltöltéséhez először le kell menteni az óravázlatot!")}</p>
                                        </div>
                                }
                            </div>
                        </div>
                    </AccordionItem>

                    <AccordionItem title={__("Kategorizálás")}>
                        {
                            <div>
                                {__("Kulcsszavak:")} *
                                <MetaKeywordAssignerPlugin
                                    keywords={this.state.keywords || []}
                                    onAppendKeywords={this.onAppendKeyword}
                                    onDeleteKeyword={this.onDeleteKeyword}
                                />
                            </div>
                        }
                        {__("Metaadatok:")}
                        <MetaCategoryAssignerPlugin
                            tableInfoId={titles.tableInfoId}
                            recId={this.state.lessonPlan.id || null}
                            categorization={this.state.categorization}
                            subject_ids={this.state.subject_ids}
                            onSubjectSelected={this.onSubjectSelected}
                            onCategoryIdsLoaded={this.onCategoryIdsLoaded}
                            onSetCategoryIds={this.onSetCategoryIds}
                            onRemoveCategoryIds={this.onRemoveCategoryIds}
                            defaultExpandAll={true}
                            disableCheckforNodesWithLeaf={this.getTitles().disableCheckforNodesWithLeaf}
                        />
                    </AccordionItem>
                </Accordion>
            </div>

            {me ?
                <>
                    {this.getConfirm()}
                    <div className="row">
                        <div className="column text-right">
                            <br />
                            {this.getSaveButton()}

                            {this.getExtraButtons()}
                        </div>
                    </div></>
                : ""
            }
            <br /><br /><br /><br /><br /><br /><br />

            {
                this.state.showSelectDialog
                    ?
                    <Dialog title={__("Kép választása")} width={1050} onClose={() => this.setState({ showSelectDialog: false })} >
                        <MediaFileChooser
                            rootFolderId={this.state.lessonPlan.oo_folder_id}
                            currentFolderId={this.state.lessonPlan.oo_folder_id}
                            selectableExtensions={["jpg", "jpeg", "png", "svg"]}
                            onFileSelected={(fileId) => { this.setState({ showSelectDialog: false }); this.onFileSelected(fileId); }}
                            enabledActions={FILE_ACTIONS}
                        />
                    </Dialog>
                    :
                    ""
            }
            {
                this.state.showImageDialog && this.state.selectedImagePath
                    ?
                    <Dialog title={__("Kép beillesztése")} width={1000} height={800} onClose={() => this.setState({ showImageDialog: false })}>
                        <ImageSettings filePath={this.state.selectedImagePath!} tinyMCE={this.state.tinyMCE} onClose={() => this.setState({ showImageDialog: false, isDataChanged: true })} />
                    </Dialog>
                    :
                    null
            }
        </div>
            ;
    }

    protected getBlocks() {
        const timeMinutes = this.state.lessonPlanBlocks.map(l => l.require_time_min).reduce((a, b) => (a || 0) + (b || 0), 0);

        const titles = this.getTitles();

        return <AccordionItem title={titles.blockPanelTitle + " " + (timeMinutes ? __(`(Összes idő: {time} perc)`, { time: timeMinutes }) : "")}>
            <div className="row">
                <div className="column small-12">
                    <Accordion>
                        {
                            this.state.lessonPlanBlocks.map((lpb, index) => {
                                const title = <div style={{ display: "flex", flex: 1, alignItems: "center" }}>
                                    <span>{`#${index + 1}. ${titles.blockTitle}`}</span>

                                    <span style={{ flex: 1, margin: "0 1em", display: "flex", alignItems: "center" }}>
                                        {__("Időkeret :")}
                                        <input
                                            type="number"
                                            style={{ margin: "0 2em", maxWidth: "4em", display: "inline-block" }}
                                            value={lpb.require_time_min || ""}
                                            onChange={(event) => {
                                                lpb.require_time_min = event.currentTarget.value ? Number(event.currentTarget.value) : null;
                                                this.setState({ lessonPlanBlocks: this.state.lessonPlanBlocks, isDataChanged: true });
                                            }}
                                        />
                                        {__("perc")}
                                    </span>
                                    {me ?
                                        titles.disableBlockOrder !== true &&
                                        <>
                                            <button disabled={(hasGroup(me, Groups.LessonPlanMentor))} className="button small primary exercise-series-small-btn" title="Fel" onClick={this.moveExeUp.bind(this, lpb)} ><i className="fa fa-arrow-up"></i></button>
                                            <button disabled={(hasGroup(me, Groups.LessonPlanMentor))} className="button small primary exercise-series-small-btn" title="Le" onClick={this.moveExeDown.bind(this, lpb)}><i className="fa fa-arrow-down"></i></button>
                                            <button disabled={(hasGroup(me, Groups.LessonPlanMentor))} className="button small alert" style={{ margin: "0" }} onClick={this.onRemoveBlock.bind(this, index)}><i className="fa fa-trash" /></button>
                                        </>
                                        : ""
                                    }

                                </div>;

                                return <AccordionItem key={index} title={title}>
                                    {this.getBlockForm(lpb, index)}
                                </AccordionItem>
                            })
                        }
                    </Accordion>
                    {
                        titles.disableBlockOrder !== true &&
                        <button className="button" onClick={this.onAddBlock.bind(this)} style={{ marginTop: "1em" }}>
                            <i className="fa fa-plus" /> &nbsp;{titles.blockTitle + " " + __("hozzáadása")}
                        </button>
                    }
                </div>
            </div>
        </AccordionItem>
    }

    // Meta kategorizálás

    private onAppendKeyword = async (keywords: string[]) => {
        this.setState({ isDataChanged: true, keywords: this.state.keywords!.concat(keywords) });
    }

    private onDeleteKeyword = async (index: number) => {
        this.state.keywords.splice(index, 1);
        this.setState({ isDataChanged: true, keywords: this.state.keywords });
    }

    private onSubjectSelected = (category_type_id: number, subject_id: number | null) => {
        this.setState({
            subject_ids: Object.assign(
                {},
                this.state.subject_ids,
                { [category_type_id.toString()]: subject_id }),
            isDataChanged: true
        });
    }

    private onCategoryIdsLoaded = (category_type_id: number, category_ids: number[]) => {
        this.onSetCategoryIds(category_type_id, category_ids, true);
    }

    private onSetCategoryIds = (category_type_id: number, category_ids: number[], loaded = false) => {
        let categorization = Object.assign(
            {},
            this.state.categorization,
            { [category_type_id.toString()]: category_ids }
        );
        this.setState({ categorization, isDataChanged: !loaded });
    }

    private onRemoveCategoryIds = (category_type_id: number, remove_category_ids: number[]) => {
        let category_ids = (this.state.categorization[category_type_id] || []).filter(
            (category_id: number) => { return remove_category_ids.indexOf(category_id) < 0 }
        );
        this.setState({
            categorization:
                Object.assign(
                    {},
                    this.state.categorization,
                    { [category_type_id]: category_ids }
                ), isDataChanged: true
        });
    }

    protected saveCategories = async (lessonPlanId: number) => {
        const titles = this.getTitles();

        try {
            for (let category_type_id in this.state.categorization) {
                const category_ids = this.state.categorization[category_type_id];

                await setCategoryIds(
                    titles.tableInfoId, lessonPlanId,
                    category_ids!,
                    parseFloat(category_type_id)
                );
            }
        } catch (error) {
            app.showErrorFromJsonResult(error);
        }
    }


    //TinyMCE    
    protected onAddImage(tinyMCE: any) {
        this.setState({
            showSelectDialog: true,
            tinyMCE,
            isDataChanged: true,
        });
    }

    protected async onFileSelected(fileId: number) {
        const file = (await OoFileCrud.load(fileId)).record;

        this.setState({
            showSelectDialog: false,
            showImageDialog: true,
            selectedImagePath: "/api/media/file/" + file.sha1,
            isDataChanged: true,
        })
    }

}
