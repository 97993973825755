import { Server } from '../../server/Server';
import View , { registerViewClassProxy, ViewClassProxy } from '../View';
import { IListParams } from '@framework/crud/Crud';

export interface IViewMemberNameRecord {
    /**
     * Akit néz (felhasználó)
     *
     * Akit néz (felhasználó)
     */
    id?: number;
    /**
     * Aki nézi (felhasználó)
     *
     * Aki nézi (felhasználó)
     */
    viewer_id?: number|null;
    /**
     * Aktív (akit néz)
     *
     * Aktív (akit néz)
     */
    member_is_active?: boolean|null;
    /**
     * Teljes név
     *
     * Teljes név
     */
    fullname?: string|null;
    /**
     * E-mail cím
     *
     * E-mail cím
     */
    email?: string|null;
    /**
     * Bejelentkezési név
     *
     * Bejelentkezési név
     */
    login_name?: string|null;
}

/**
 *  sys.view_member_name - Tag név
 *
 *  Tagok neve, ahogyan egymást látják
 */

export default class ViewMemberName extends View<IViewMemberNameRecord> {
    public static VIEW_INFO_ID = 2018121401;

    public static getSchemaNameForClass(): string { return 'sys'; }
    public static getViewNameForClass(): string { return 'view_member_name'; }
    public static getTableNameForClass(): string|null { return 'null'; }

    public static load: (id: number, aServer ?: Server) => Promise<ViewMemberName>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<IViewMemberNameRecord[]>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class ViewMemberNameClassProxy extends ViewClassProxy<IViewMemberNameRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const viewMemberNameClassProxy = new ViewMemberNameClassProxy(ViewMemberName);
registerViewClassProxy(viewMemberNameClassProxy);

