import { Server } from '../../server/Server';
import Crud , { registerCrudClassProxy, IAsTextResult, IListParams, CrudClassProxy, ISearchTextParams, ISearchTextResult } from '../Crud';

export interface ISharedContentMemberRecord {
    /**
     * Globálisan egyedi azonosító.
     * 
     * Ha új rekordot akarsz létrehozni put() metódussal, akkor hagyd üresen.
     * upsert() hívásnál figyelmen kívül van hagyva az értéke.
     *
     */
    id ?: number;
    /**
     * Megosztott tartalom
     *
     * NOT NULL -> usr.shared_content ON DELETE cascade
     *
     */
    shared_content_id?: number;
    /**
     * Alkalmazás szintű felhasználó
     *
     * NOT NULL -> sys.sec_user ON DELETE cascade
     *
     */
    sec_user_id?: number;
    /**
     * Létrehozva
     *
     * Az az időpont, amikortól kezdve a megosztás az adott felhasználó számára elérhető.
     */
    creation_time?: string /*timestamp?*/;
    /**
     * Nyugtázva
     *
     * Amikor a felhasználó nyugtázta az értesítést.
     */
    acknowledged?: string /*timestamp?*/|null;
    /**
     * Legjobb eredmény
     *
     * Ezt a gép automatikusan számítja, a shared_content_run-t összegzi.
     */
    success_percent?: number /* double precision */|null;
    /**
     * Legjobb eredmény időpontja
     */
    best_solution_time?: string /*timestamp?*/|null;
    /**
     * Legelső futtatás időpontja
     */
    first_run_time?: string /*timestamp?*/|null;
    /**
     * Legutolsó futtatás időpontja
     */
    last_run_time?: string /*timestamp?*/|null;
    /**
     * Futtatások száma
     */
    run_count?: number /*integer*/|null;
    /**
     * Legelső beküldés időpontja
     */
    first_submission_time?: string /*timestamp?*/|null;
    /**
     * Legutolsó beküldés időpontja
     */
    last_submission_time?: string /*timestamp?*/|null;
    /**
     * Beküldések száma száma
     */
    submission_count?: number /*integer*/|null;
}

/**
 *  usr.shared_content_member - Megosztott tartalom - felhasználó kapcsolat
 *
 *  Ez mondja meg, hogy melyik megosztás melyik felhasználókhoz tartozik. Trigger tölti ki.
 */

export default class SharedContentMemberCrud extends Crud<ISharedContentMemberRecord> {
    public static TABLE_INFO_ID = 1542049725;
    public static getSchemaNameForClass() { return 'usr'; }
    public static getTableNameForClass() { return 'shared_content_member'; }

    public static load: (id: number, aServer ?: Server) => Promise<SharedContentMemberCrud>;
    public static asText: (id: number, includeRecord : boolean, aServer ?: Server) => Promise<IAsTextResult<ISharedContentMemberRecord>>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<ISharedContentMemberRecord[]>;
    public static searchText: (params: ISearchTextParams, aServer ?: Server) => Promise<ISearchTextResult<ISharedContentMemberRecord>[]>;
    public static deleteById: (id: number, aServer ?: Server) => Promise<ISharedContentMemberRecord>;
    public static unDeleteById: (id: number, aServer ?: Server) => Promise<ISharedContentMemberRecord>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class SharedContentMemberCrudClassProxy extends CrudClassProxy<ISharedContentMemberRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const sharedContentMemberCrudClassProxy = new SharedContentMemberCrudClassProxy(SharedContentMemberCrud);
registerCrudClassProxy(sharedContentMemberCrudClassProxy);
