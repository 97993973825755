import * as React from 'react';
import { app } from '@src/index';
import { me } from '@framework/server/Auth';
import ViewPopularVideoRecord, { IViewPopularVideoRecord } from '@framework/view/media_pub/ViewPopularVideo';
import { VideoBlock } from '@src/component/media/VideoComponent';
import Swiper, {SwiperItem} from '@src/component/dashboard/Swiper';
import { shuffleArray } from '../media/Offers';
import { __ } from '@src/translation';
export interface PopularVideosTileProps {
    settings: any
}


export interface PopularVideosTileState {
    PopularVideos?: IViewPopularVideoRecord[];
    ItemWidth?:any;
}

export default class PopularVideosTile extends React.Component<PopularVideosTileProps, PopularVideosTileState> {

    constructor(props: PopularVideosTileProps) {
        super(props);
        this.state = {
            PopularVideos: []
        };
        this.asyncReload(this.props);

        this.handleResize = this.handleResize.bind(this);
    }

    private asyncReload = async (props:any) => {

        let newState: Partial<PopularVideosTileState> = {};
        if (me && me.id) {
            try {
                const limit = Number(props.settings.limit);
                let videos = await ViewPopularVideoRecord.list({limit: limit, order_by: [{name: "plays", desc: true}]});

                shuffleArray(videos);
                // videos = videos.filter(v => v.displayname).slice(0, limit);

                this.setState({PopularVideos:videos});

            } catch (error) {
                app.showErrorFromJsonResult(error);
            }
        } else {
            newState.PopularVideos = [];
        }
        this.setState(newState as PopularVideosTileState);
    }

    componentDidMount(){
        window.addEventListener("resize", this.handleResize);


    }
    componentDidUpdate(){
    } 

    componentWillReceiveProps(newProps: any) {

        this.asyncReload(newProps);
    }

    handleResize(event:any) {
        this.forceUpdate(); 
    }

    componentWillUnmount()
    {
        window.removeEventListener("resize", this.handleResize);
    }


    render() {

        if (!this.state.PopularVideos) {
                return <div/>;
        } else {

            return <div ref="popularVideosTile" className="popular-videos-tile tile-content">
            {    
                                (this.state.PopularVideos.length < 1) ?
                                <div>{__("Nincsenek ajánlott videók")}</div>
                                :   
                        <Swiper ItemCount={5} className="video-carousel-content">
                            
                               {
                                this.state.PopularVideos.map( (item: IViewPopularVideoRecord, i: number) => {
                                    return <SwiperItem key={i} title="" className="carousel-item">
                                    <VideoBlock 
                                        key={item.id} 
                                        fileId={item.id!}
                                        displayName={item.displayname || undefined}
                                        title={item.title_text!}
                                        sha1={item.sha1!}
                                        description = {item.alt_text!}                                      
                                    />  </SwiperItem> 
                                })                                                                                                                                         
                            } 
                        </Swiper>
            }
                        </div>
            
        }
    }
}