import { Server } from '../../server/Server';
import View , { registerViewClassProxy, ViewClassProxy } from '../View';
import { IListParams } from '@framework/crud/Crud';

export interface IViewWfHeadDetailTableRecord {
    /**
     * Azonosító
     *
     * Azonosító (pótkulcs)
     */
    id?: number;
    /**
     * Rekord létrehozás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel létrehozásakor.
     */
    creation_time?: string /*timestamp?*/;
    /**
     * Ki hozta létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozó felhasználóra hivatkozik.
     */
    creation_user_id?: number;
    /**
     * Melyik session-ből jött létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozáshoz használt munkamenetre hivatkozik.
     */
    creation_session_id?: number|null;
    /**
     * Utolsó módosítás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel módosításakor.
     */
    modification_time?: string /*timestamp?*/|null;
    /**
     * Ki módosította utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosító felhasználóra hivatkozik.
     */
    modification_user_id?: number|null;
    /**
     * Melyik session-ből módosították utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosításhoz használt munkamenetre hivatkozik.
     */
    modification_session_id?: number|null;
    /**
     * Aktív
     *
     * Logikai törléshez használt mező.
     */
    is_active?: boolean;
    /**
     * Folyamat fej táblázat
     */
    head_id?: number;
    /**
     * Tétel táblázat
     */
    table_id?: number;
    /**
     * A fejre mutató mező
     */
    ref_field_id?: number;
    /**
     * Létrehozó
     *
     * Létrehozó
     */
    creator?: string|null;
    /**
     * Módosító
     *
     * Módosító
     */
    modifier?: string|null;
    /**
     * Fejtáblázat azonosító (mindig töltve, not null)
     *
     * Fejtáblázat azonosító (mindig töltve, not null)
     */
    head_table_id?: number|null;
    /**
     * Mező infó id
     *
     * Mező infó id
     */
    key_field_id?: number|null;
    /**
     * Táblázat infó id
     *
     * Táblázat infó id
     */
    key_table_id?: number|null;
    /**
     * Folyamat típus azonosító
     *
     * Folyamat típus azonosító
     */
    wf_type_id?: number|null;
    /**
     * Folyamat típus kód
     *
     * Folyamat típus kód
     */
    wf_type_code?: string|null;
    /**
     * Folyamat típus név
     *
     * Folyamat típus név
     */
    wf_type_name?: string|null;
    /**
     * Séma név
     *
     * Séma név
     */
    schema_name?: string|null;
    /**
     * Táblázat név
     *
     * Táblázat név
     */
    table_name?: string|null;
    /**
     * Mező név
     *
     * Mező név
     */
    field_name?: string|null;
    /**
     * Külső kulcs tőrlési szabály
     *
     * Külső kulcs tőrlési szabály
     */
    fk_delete_rule?: string|null;
}

/**
 *  wf.view_wf_head_detail_table - Folyamat típus tétel táblázat
 *
 *  Ez a táblázat határozza meg, hogy a folyamat fej táblázata alá milyen tételek tartoznak. (Például: a könyv alá a fejezetek vagy a leckék.) Az ilyen master-detail kapcsolatok megadása azért fontos, mert a folyamat állapotától függően megszorításokat lehet megadni a tételekre is. (Pl. jóváhagyás alatti állapotban nem módosítható.)
 */

export default class ViewWfHeadDetailTable extends View<IViewWfHeadDetailTableRecord> {
    public static VIEW_INFO_ID = 2018092101;

    public static getSchemaNameForClass(): string { return 'wf'; }
    public static getViewNameForClass(): string { return 'view_wf_head_detail_table'; }
    public static getTableNameForClass(): string|null { return 'detail_table'; }

    public static load: (id: number, aServer ?: Server) => Promise<ViewWfHeadDetailTable>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<IViewWfHeadDetailTableRecord[]>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class ViewWfHeadDetailTableClassProxy extends ViewClassProxy<IViewWfHeadDetailTableRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const viewWfHeadDetailTableClassProxy = new ViewWfHeadDetailTableClassProxy(ViewWfHeadDetailTable);
registerViewClassProxy(viewWfHeadDetailTableClassProxy);

