import { Server } from '../../server/Server';
import Crud , { registerCrudClassProxy, IAsTextResult, IListParams, CrudClassProxy, ISearchTextParams, ISearchTextResult } from '../Crud';

export interface ILessonplanBlockRecord {
    /**
     * Globálisan egyedi azonosító.
     * 
     * Ha új rekordot akarsz létrehozni put() metódussal, akkor hagyd üresen.
     * upsert() hívásnál figyelmen kívül van hagyva az értéke.
     *
     */
    id ?: number;
    /**
     * Sorrend
     */
    no?: number /*integer*/|null;
    /**
     * Óravázlat
     *
     * NOT NULL -> doc_pub.lessonplan ON DELETE cascade
     *
     */
    lessonplan_id?: number;
    /**
     * Időkeret (perc)
     */
    require_time_min?: number /*integer*/|null;
    /**
     * Leírás
     */
    description?: string;
    /**
     * Módszerek/munkaformák
     */
    methods?: string;
    /**
     * Eszközök
     */
    tools?: string;
    /**
     * Értékelés
     */
    evaluation?: string;
    /**
     * Linkek
     */
    links?: any|null;
}

/**
 *  doc_pub.lessonplan_block - Óravázlat blokk
 */

export default class LessonplanBlockCrud extends Crud<ILessonplanBlockRecord> {
    public static TABLE_INFO_ID = 4210858992;
    public static getSchemaNameForClass() { return 'doc_pub'; }
    public static getTableNameForClass() { return 'lessonplan_block'; }

    public static canCache(): boolean {
        return true;
    }

    public static getDefaultMaxAge() : number|undefined {
        return 3600;
    }

    public static load: (id: number, aServer ?: Server) => Promise<LessonplanBlockCrud>;
    public static asText: (id: number, includeRecord : boolean, aServer ?: Server) => Promise<IAsTextResult<ILessonplanBlockRecord>>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<ILessonplanBlockRecord[]>;
    public static searchText: (params: ISearchTextParams, aServer ?: Server) => Promise<ISearchTextResult<ILessonplanBlockRecord>[]>;
    public static deleteById: (id: number, aServer ?: Server) => Promise<ILessonplanBlockRecord>;
    public static unDeleteById: (id: number, aServer ?: Server) => Promise<ILessonplanBlockRecord>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class LessonplanBlockCrudClassProxy extends CrudClassProxy<ILessonplanBlockRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const lessonplanBlockCrudClassProxy = new LessonplanBlockCrudClassProxy(LessonplanBlockCrud);
registerCrudClassProxy(lessonplanBlockCrudClassProxy);
