import { Server } from '../../server/Server';
import View , { registerViewClassProxy, ViewClassProxy } from '../View';
import { IListParams } from '@framework/crud/Crud';

export interface IViewExerciseSeriesSectionRecord {
    /**
     * Feladatsor azonosító
     *
     * Feladatsor azonosító
     */
    id?: number;
    /**
     * Feladatsor név
     *
     * Feladatsor név
     */
    exercise_series_title?: string|null;
    /**
     * Feladatsor létrehozás ideje
     *
     * Feladatsor létrehozás ideje
     */
    exercise_creation_time?: string /*timestamp?*/|null;
    /**
     * Feladatsor létrehozó felhasználó
     *
     * Feladatsor létrehozó felhasználó
     */
    exercise_creation_user_id?: number|null;
    /**
     * Feladatsor módosítás ideje
     *
     * Feladatsor módosítás ideje
     */
    exercise_modification_time?: string /*timestamp?*/|null;
    /**
     * Feladatsor módosító felhasználó
     *
     * Feladatsor módosító felhasználó
     */
    exercise_modification_user_id?: number|null;
    /**
     * Szeckió azonosító
     *
     * Szeckió azonosító
     */
    section_id?: number|null;
    /**
     * Szekció név
     *
     * Szekció név
     */
    section_name?: string|null;
    /**
     * Szekció létrehozó felhasználó
     *
     * Szekció létrehozó felhasználó
     */
    section_creation_user_id?: number|null;
    /**
     * Szekció létrehozás ideje
     *
     * Szekció létrehozás ideje
     */
    section_creation_time?: string /*timestamp?*/|null;
    /**
     * Szekció módosító felhasználó
     *
     * Szekció módosító felhasználó
     */
    section_modification_user_id?: number|null;
    /**
     * Szekció módosítás ideje
     *
     * Szekció módosítás ideje
     */
    section_modification_time?: string /*timestamp?*/|null;
    /**
     * Lecke azonosító
     *
     * Lecke azonosító
     */
    lesson_id?: number|null;
    /**
     * Lecke név
     *
     * Lecke név
     */
    lesson_name?: string|null;
    /**
     * Lecke URI szegmens
     *
     * Lecke URI szegmens
     */
    lesson_uri_segment?: string|null;
    /**
     * Fejezet azonosító
     *
     * Fejezet azonosító
     */
    chapter_id?: number|null;
    /**
     * Fejezet név
     *
     * Fejezet név
     */
    chapter_name?: string|null;
    /**
     * Könyv azonosító
     *
     * Könyv azonosító
     */
    book_id?: number|null;
    /**
     * Könyv név
     *
     * Könyv név
     */
    book_name?: string|null;
    /**
     * Könyv URI szegmens
     *
     * Könyv URI szegmens
     */
    book_uri_segment?: string|null;
    /**
     * Könyvtár azonosító
     *
     * Könyvtár azonosító
     */
    library_id?: number|null;
    /**
     * Könyvtár név
     *
     * Könyvtár név
     */
    library_name?: string|null;
    /**
     * Könyvtár URI szegmens
     *
     * Könyvtár URI szegmens
     */
    library_uri_segment?: string|null;
    /**
     * Feladatsor létrehozó
     */
    exercise_creator?: string|null;
    /**
     * Feladatsor módosító
     */
    exercise_modifier?: string|null;
    /**
     * Szekció létrehozó
     */
    section_creator?: string|null;
    /**
     * Szekció módosító
     */
    section_modifier?: string|null;
}

/**
 *  exc.view_exercise_series_section - Feladatsor-szekció
 *
 *  Melyik feladatsor melyik szekcióban szerepel
 */

export default class ViewExerciseSeriesSection extends View<IViewExerciseSeriesSectionRecord> {
    public static VIEW_INFO_ID = 2019010301;

    public static getSchemaNameForClass(): string { return 'exc'; }
    public static getViewNameForClass(): string { return 'view_exercise_series_section'; }
    public static getTableNameForClass(): string|null { return 'null'; }

    public static load: (id: number, aServer ?: Server) => Promise<ViewExerciseSeriesSection>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<IViewExerciseSeriesSectionRecord[]>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class ViewExerciseSeriesSectionClassProxy extends ViewClassProxy<IViewExerciseSeriesSectionRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const viewExerciseSeriesSectionClassProxy = new ViewExerciseSeriesSectionClassProxy(ViewExerciseSeriesSection);
registerViewClassProxy(viewExerciseSeriesSectionClassProxy);

