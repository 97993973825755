import { Server } from '../../server/Server';
import Crud , { registerCrudClassProxy, IAsTextResult, IListParams, CrudClassProxy, ISearchTextParams, ISearchTextResult } from '../Crud';

export interface IGoodWordGlobalRecord {
    /**
     * Globálisan egyedi azonosító.
     * 
     * Ha új rekordot akarsz létrehozni put() metódussal, akkor hagyd üresen.
     * upsert() hívásnál figyelmen kívül van hagyva az értéke.
     *
     */
    id ?: number;
    /**
     * Rekord létrehozás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel létrehozásakor.
     */
    creation_time?: string /*timestamp?*/;
    /**
     * Ki hozta létre
     *
     * NOT NULL -> sys.sec_user ON DELETE noaction
     *
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozó felhasználóra hivatkozik.
     */
    creation_user_id?: number;
    /**
     * Melyik session-ből jött létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozáshoz használt munkamenetre hivatkozik.
     */
    creation_session_id?: number|null;
    /**
     * Utolsó módosítás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel módosításakor.
     */
    modification_time?: string /*timestamp?*/|null;
    /**
     * Ki módosította utoljára
     *
     * -> sys.sec_user ON DELETE noaction
     *
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosító felhasználóra hivatkozik.
     */
    modification_user_id?: number|null;
    /**
     * Melyik session-ből módosították utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosításhoz használt munkamenetre hivatkozik.
     */
    modification_session_id?: number|null;
    /**
     * Aktív
     *
     * Logikai törléshez használt mező.
     */
    is_active?: boolean;
    /**
     * Szó
     */
    word?: string;
}

/**
 *  spell.good_word_global - Jó szavak
 *
 *  Idősoros táblázat, ami az összes tétel összes korábbi verzióját eltárolja.
 */

export default class GoodWordGlobalCrud extends Crud<IGoodWordGlobalRecord> {
    public static TABLE_INFO_ID = 813506376;
    public static getSchemaNameForClass() { return 'spell'; }
    public static getTableNameForClass() { return 'good_word_global'; }

    public static load: (id: number, aServer ?: Server) => Promise<GoodWordGlobalCrud>;
    public static asText: (id: number, includeRecord : boolean, aServer ?: Server) => Promise<IAsTextResult<IGoodWordGlobalRecord>>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<IGoodWordGlobalRecord[]>;
    public static searchText: (params: ISearchTextParams, aServer ?: Server) => Promise<ISearchTextResult<IGoodWordGlobalRecord>[]>;
    public static deleteById: (id: number, aServer ?: Server) => Promise<IGoodWordGlobalRecord>;
    public static unDeleteById: (id: number, aServer ?: Server) => Promise<IGoodWordGlobalRecord>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class GoodWordGlobalCrudClassProxy extends CrudClassProxy<IGoodWordGlobalRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const goodWordGlobalCrudClassProxy = new GoodWordGlobalCrudClassProxy(GoodWordGlobalCrud);
registerCrudClassProxy(goodWordGlobalCrudClassProxy);
