import * as React from 'react';
import { Dialog } from '@src/component/Dialog';
import './ExerciseImageCoordinatePick.css';
import { ExerciseEngineHelper } from '../engine/ExerciseEngineHelper';
import overlaps from 'polygon-overlap';
import { __ } from '@src/translation';
import { __promisify__ } from 'glob';
import { UniversalSelection, UniversalElement, CanvasInfo } from '../engine/UniversalEngine/UniversalEngineConverter';

export enum Shapes {
    Circle,
    Polygon,
    Rectangle
}

export type ExerciseImageCoordinateProps = {
    imagesrc: string
    x?: number,
    y?: number,
    radius?: number,
    points?: string | null,
    shape: Shapes,
    taskValues: any[],
    onChange: (newValues: any[], canvasInfo?: CanvasInfo) => void,
    otherCircles?: any[],
    otherPolygons?: any[],
    canvasInfo?: CanvasInfo,
    ill_bg?: string
}

export type ExerciseImageCoordinateState = {
    x?: number,
    y?: number,
    radius?: number,
    points: string | null,
    shape: Shapes
    show_image: boolean,
    isSavingProhibited: boolean,
    selection_type?: UniversalSelection;
}

export default class ExerciseImageCoordinatePick extends React.Component<ExerciseImageCoordinateProps, ExerciseImageCoordinateState> {

    private defaultX: number = 0;
    private defaultY: number = 0;
    private defaultRadius: number = 5;
    private coordinateSelected: boolean = false;
    private container: HTMLElement;
    private imgWidth: number = 0;
    private imgHeight: number = 0;
    private rad_sel_circle: number = 12;

    constructor(props: ExerciseImageCoordinateProps) {
        super(props);

        this.state = {
            show_image: false,
            x: props.x ? props.x : this.defaultX,
            y: props.y ? props.y : this.defaultY,
            radius: props.radius ? props.radius : this.defaultRadius,
            points: props.points ? props.points : null,
            shape: props.shape,
            isSavingProhibited: false,
            selection_type: UniversalSelection.Area
        }
    }

    componentDidUpdate(prevProps: any) {

        if (this.props.shape != prevProps.shape) {
            this.setState({
                shape: this.props.shape
            })
        }

        if (this.props.points != prevProps.points && this.props.x != prevProps.x && this.props.y != prevProps.y && this.props.radius != prevProps.radius)
            this.setState({
                x: this.props.x,
                y: this.props.y,
                radius: this.props.radius,
                points: this.props.points!
            })
    }

    handleRadiusChange(event: any) {
        const target = event.target;
        var value = target.value;

        if (target && target.name == "radius" && (target.value < 1 || target.value > 30))
            return;

        if (target && target.type === 'number') {
            value = Number(target.value);
            this.props.taskValues[2].value = value;
        }

        if (this.state.x && this.state.y && target.name == "radius") {

            if (this.state.x != 0 && this.state.y != 0)
                this.coordinateSelected = true;

            this.setState({
                radius: value,
                isSavingProhibited: this.isOverlap(ExerciseEngineHelper.convertCircleToPolygon((this.state.x / 100) * this.imgWidth, (this.state.y / 100) * this.imgHeight, (value / 100) * this.imgWidth).pairs)
            });
        }
    }

    getCoordinates(event: MouseEvent) {
        var element = event.target as HTMLElement;
        //clicks beyond the image are not captured
        if (element.nodeName == "DIV" || event.clientX > element.getBoundingClientRect().left + element.getBoundingClientRect().width || event.clientY > element.getBoundingClientRect().top + element.getBoundingClientRect().height)
            return;
        else if (element.nodeName == "circle" || "polygon")
            element = this.container.getElementsByTagName('img')[0];

        if (!this.coordinateSelected)
            this.coordinateSelected = true;

        var rateX = Math.round(((event.clientX - element.getBoundingClientRect().left) / element.getBoundingClientRect().width) * 100);
        var rateY = Math.round(((event.clientY - element.getBoundingClientRect().top) / element.getBoundingClientRect().height) * 100);

        var points: string = "";

        /* Circle */
        if (this.state.shape == Shapes.Circle) {
            this.props.taskValues[0].value = rateX;
            this.props.taskValues[1].value = rateY;
        }
        //Rectangle
        else if (this.state.shape == Shapes.Rectangle) {
            let pointsLength = this.state.points ? this.state.points.split(",").length - 1 : 0;

            let parent = element.closest('.dialog__content') as HTMLElement;
            let rectx1E = parent.querySelector('[name="rectx1"]') as HTMLInputElement;
            let recty1E = parent.querySelector('[name="recty1"]') as HTMLInputElement;
            let rectx2E = parent.querySelector('[name="rectx2"]') as HTMLInputElement;
            let recty2E = parent.querySelector('[name="recty2"]') as HTMLInputElement;

            //If this is not the first point and we have a rectangle shape
            switch(pointsLength) {
                case 1:
                    rectx2E.value = String(rateX);
                    recty2E.value = String(rateY);
                    points = ExerciseEngineHelper.getRectangleCoordinates(this.state.points + String(rateX) + "," + String(rateY) + " ");
                    break;
                case 4:
                    rectx1E.value = String(rateX);
                    recty1E.value = String(rateY);
                    rectx2E.value = '';
                    recty2E.value = '';
                    points = String(rateX) + "," + String(rateY) + " ";
                    break;
                default:
                    rectx1E.value = String(rateX);
                    recty1E.value = String(rateY);
                    rectx2E.value = '';
                    recty2E.value = '';
                    points = this.state.points + String(rateX) + "," + String(rateY) + " ";
                    break;
            }

            this.props.taskValues[0].value = points;
        }
        //Poly
        else {
            let pointsLenght = this.state.points ? this.state.points.split(",").length - 1 : 0;
            points = this.state.points + String(rateX) + "," + String(rateY) + " ";
            this.props.taskValues[0].value = points;
        }

        points = points.replace("null", "");

        //For the overlap check in case of circle, we need to convert the circle into polygon
        //And the isOverlap method works with pixel values, not with percentages, so we need to scale the circle or polygon coordinates
        let actualPoints = this.state.shape == Shapes.Circle ?
            ExerciseEngineHelper.convertCircleToPolygon((rateX / 100) * this.imgWidth, (rateY / 100) * this.imgHeight, (this.state.radius! / 100) * this.imgWidth).pairs :
            ExerciseEngineHelper.getPolyPercentageToPixel(ExerciseEngineHelper.getPolyCoordinatePairs(points), this.imgWidth, this.imgHeight)

        this.setState({
            x: rateX,
            y: rateY,
            points: points,
            isSavingProhibited: this.isOverlap(actualPoints)
        })
    }

    coordsChanged(event: any) {
        let element = event.target as HTMLElement;

        let parent = element.closest('.manualCoordinates') as HTMLElement;
        let rectx1E = parent.querySelector('[name="rectx1"]') as HTMLInputElement;
        let recty1E = parent.querySelector('[name="recty1"]') as HTMLInputElement;
        let rectx2E = parent.querySelector('[name="rectx2"]') as HTMLInputElement;
        let recty2E = parent.querySelector('[name="recty2"]') as HTMLInputElement;

        if (parent) {
            let rectx1 = (rectx1E) ? (rectx1E.value != '') ? (Number(rectx1E.value) < 0) ? rectx1E.value = "0" : (Number(rectx1E.value) > 100) ? rectx1E.value = "100" : rectx1E.value : '0' : undefined;
            let recty1 = (recty1E) ? (recty1E.value != '') ? (Number(recty1E.value) < 0) ? recty1E.value = "0" : (Number(recty1E.value) > 100) ? recty1E.value = "100" : recty1E.value : '0' : undefined;
            let rectx2 = (rectx2E) ? (rectx2E.value != '') ? (Number(rectx2E.value) < 0) ? rectx2E.value = "0" : (Number(rectx2E.value) > 100) ? rectx2E.value = "100" : rectx2E.value : '0' : undefined;
            let recty2 = (recty2E) ? (recty2E.value != '') ? (Number(recty2E.value) < 0) ? recty2E.value = "0" : (Number(recty2E.value) > 100) ? recty2E.value = "100" : recty2E.value : '0' : undefined;

            let points = "";
            let pointsString = rectx1 + "," + recty1 + " " + rectx2 + "," + recty2 + " ";

            points = ExerciseEngineHelper.getRectangleCoordinates(pointsString);
            if (!this.coordinateSelected) this.coordinateSelected = true;

            this.props.taskValues[0].value = points;

            let actualPoints = ExerciseEngineHelper.getPolyPercentageToPixel(ExerciseEngineHelper.getPolyCoordinatePairs(points), this.imgWidth, this.imgHeight);

            this.setState({
                points: points,
                isSavingProhibited: this.isOverlap(actualPoints)
            });
        }

    }

    //This method needs to get the points array in pixels!!
    isOverlap(p: number[]): boolean {
        var overlap: boolean = false;
        if (this.props.otherPolygons)
            for (let i = 0; i < this.props.otherPolygons.length; i++) {
                overlap = overlaps(p, ExerciseEngineHelper.getPolyPercentageToPixel(ExerciseEngineHelper.getPolyCoordinatePairs(this.props.otherPolygons[i].points), this.imgWidth, this.imgHeight));
                if (overlap) return true;
            }
        if (this.props.otherCircles)
            for (let i = 0; i < this.props.otherCircles.length; i++) {
                let circlepolypoints = ExerciseEngineHelper.convertCircleToPolygon((this.props.otherCircles[i].x / 100) * this.imgWidth, (this.props.otherCircles[i].y / 100) * this.imgHeight, (this.props.otherCircles[i].radius / 100) * this.imgWidth).pairs;
                overlap = overlaps(p, circlepolypoints);
                if (overlap) return true;
            }
        return false;
    }

    setDetails() {
        this.container = document.getElementById('imageContainer')!;

        if (!this.container)
            return;

        this.setState({});
    }

    resetDetails() {
        this.setState({
            x: this.props.x,
            y: this.props.y,
            radius: this.props.radius,
            points: null,
            show_image: false
        })
    }

    getSVGClassName(svg: any): string {
        if (svg.sel_type == UniversalSelection.Area) return "areas"
        else if (svg.sel_type == UniversalSelection.Draggable) return "draggables"
        else return ""
    }

    render() {

        var img = this.refs.image as HTMLImageElement;
        var left: number, top: number, radius: number, diameter: number, imgHeight: number, imgWidth: number;

        let points = this.state.points ? this.state.points.split(" ") : [];
        let pointPairs = [] as any[];
        for (let i = 0;i<points.length;i++) {
            pointPairs.push(points[i].split(","));
        }

        let r = this.rad_sel_circle;

        if (img) {
            if (this.state.radius) {
                radius = this.state.radius / 100 * img.getBoundingClientRect().width;
                diameter = radius * 2;
            }
            left = img.getBoundingClientRect().width / 100;
            top = img.getBoundingClientRect().height / 100;
            imgHeight = img.getBoundingClientRect().height;
            imgWidth = img.getBoundingClientRect().width;
            this.imgWidth = imgWidth;
            this.imgHeight = imgHeight;
        }

        return <div>

            <button className="button small" style={{ margin: "0.5em" }} onClick={() => this.setState({ show_image: true })}>
                <i className="fa fa" /> {__("Koordináták megadása")}
            </button>

            {
                this.state.show_image
                    ?
                    <Dialog title={this.state.shape == Shapes.Circle ? __("Kérem adja meg a kör középpontját!") : __("Kérem adja meg az alakzat pontjait!")}
                        onClose={this.resetDetails.bind(this)}>
                        <div className="imageContainer" id="imageContainer" onClick={this.getCoordinates.bind(this)}>
                            {this.props.imagesrc != "/" ? <img ref="image" className="coordimg" id="illustration" style={{ backgroundImage: `url(${this.props.ill_bg})` }} src={this.props.imagesrc} onLoad={this.setDetails.bind(this)}
                            /> : __("Kérem adja meg a feladat képét (illusztráció)!")}
                            {
                                /*Drawing the actual polygon and the other polygons*/
                                img ?
                                    <svg id="mainSVG" style={{
                                        position: "absolute",
                                        width: img.width,
                                        height: img.height,
                                        left: img.offsetLeft,
                                        top: img.offsetTop
                                    } as React.CSSProperties}>
                                        {this.state.shape != Shapes.Circle && this.state.points && this.state.points != "" ?
                                            <polygon id="mainPolygon" points={ExerciseEngineHelper.getPolyCoordsImagePixels(this.state.points, img.height, img.width)} />
                                            : ""}
                                        {
                                            this.props.otherPolygons ?
                                                this.props.otherPolygons.map((poly: any, i: number) => {
                                                    return (
                                                        <polygon id="otherPolygon" className={"otherPolygon " + this.getSVGClassName(poly)} key={"poly#" + i} points={ExerciseEngineHelper.getPolyCoordsImagePixels(poly.points, imgHeight, imgWidth)} />
                                                    )
                                                })
                                                : ""
                                        }
                                    </svg> : ""
                            }
                            {
                                /*Showing the helper small blue circles for selected points of rectangle and polygon*/
                                (this.state.shape != Shapes.Circle) &&
                                points.filter(function (p) {
                                    return (p !== "")
                                }).map((p: string) => {
                                    let point = p.split(",");
                                    return (<div>
                                        <svg className="currentCircle" height={String(r)} width={String(r)}
                                            style={{
                                                position: "absolute",
                                                left: String(Number(point[0]) * left!) + "px",
                                                top: String(Number(point[1]) * top! - imgHeight!) + "px",
                                                marginLeft: String((r / 2) * -1) + "px",
                                                marginTop: String((r / 2) * -1) + "px"
                                            }}
                                        >
                                            <circle cx={r / 2} cy={r / 2} r={r / 2} className="helperCircle" />
                                        </svg>
                                    </div>)
                                })
                            }
                            {
                                /*Drawing the actual circle*/
                                img && this.state.shape == Shapes.Circle && this.state.x && this.state.y ?

                                    <div id="currentCircDiv">
                                        <svg className="currentCircle" height={String(diameter!)} width={String(diameter!)}
                                            style={{
                                                left: String(this.state.x * left!),
                                                top: String(this.state.y * top! - imgHeight!),
                                                position: "absolute",
                                                marginLeft: String(radius! * -1),
                                                marginTop: String(radius! * -1)
                                            } as React.CSSProperties} >
                                            <circle className="answer-item-circle mainCircle" cx={radius!} cy={radius!} r={radius!} fill="red" />
                                        </svg>
                                    </div>
                                    : ""
                            }
                            {
                                img && this.props.otherCircles ?

                                    this.props.otherCircles.map((circ: any, i: number) => {
                                        return (
                                            <div className="otherCircle" key={"other_circle_" + i}>
                                                <svg className="currentCircle" height={String((circ.radius / 100) * imgWidth * 2)} width={String((circ.radius / 100) * imgWidth * 2)}
                                                    style={{
                                                        left: String(circ.x * left!),
                                                        top: String(circ.y * top! - imgHeight!),
                                                        position: "absolute",
                                                        marginLeft: String((circ.radius / 100) * imgWidth * -1),
                                                        marginTop: String((circ.radius / 100) * imgWidth * -1)
                                                    } as React.CSSProperties} >
                                                    <circle className={"answer-item-circle " + this.getSVGClassName(circ)} key={"circle#" + i} cx={(circ.radius / 100) * imgWidth!} cy={(circ.radius / 100) * imgWidth} r={(circ.radius / 100) * imgWidth} fill="grey" />
                                                </svg>
                                            </div>
                                        )
                                    })
                                    : ""
                            }
                        </div>

                        {
                            this.state.isSavingProhibited
                                ? <label style={{ textAlign: 'center' }}><strong>{__("Figyelem! Egy alakzat sem fedheti egymást!")}</strong></label>
                                : ""
                        }

                        <div className="row" style={{ display: "flex", paddingTop: "20px" }}>

                            {this.state.shape == Shapes.Circle ?
                                <div className="coordinates">
                                    <div className="ansitem small-3 columns">
                                        <label> X {__("koordináta")}
                                            <input name="x" type="number" value={this.state.x} disabled />
                                        </label>
                                    </div>
                                    <div className="ansitem small-3 columns">
                                        <label> Y {__("koordináta")}
                                            <input name="y" type="number" value={this.state.y} disabled />
                                        </label>
                                    </div>
                                    <div className="ansitem small-3 columns">
                                        <label> {__("Rádiusz")}
                                            <input type="number" name="radius" value={this.state.radius} onChange={this.handleRadiusChange.bind(this)} />
                                        </label>
                                    </div>
                                </div>
                                : this.state.shape == Shapes.Rectangle ? (
                                    <div className="manualCoordinates">
                                        {__("Téglalap megadása: Vegye fel a téglalap bal felső, majd a jobb alsó pontját. Ez a két pont meghatározza a téglalapot.")}
                                        <div style={{whiteSpace: "nowrap", marginTop: "5px"}}>
                                            1. sarok:
                                            X <input type="number" defaultValue={pointPairs[0] ? pointPairs[0][0] : undefined} name="rectx1" onChange={this.coordsChanged.bind(this)} style={{display: "inline-block", width: "auto"}}></input> (%)
                                                - Y <input type="number" defaultValue={pointPairs[0] ? pointPairs[0][1] : undefined} name="recty1" onChange={this.coordsChanged.bind(this)} style={{display: "inline-block", width: "auto"}}></input> (%)
                                        </div>
                                        <div style={{whiteSpace: "nowrap"}}>
                                            2. sarok:
                                            X <input type="number" defaultValue={pointPairs[2] ? pointPairs[2][0] : undefined} name="rectx2" onChange={this.coordsChanged.bind(this)} style={{display: "inline-block", width: "auto"}}></input> (%)
                                                - Y <input type="number" defaultValue={pointPairs[2] ? pointPairs[2][1] : undefined} name="recty2" onChange={this.coordsChanged.bind(this)} style={{display: "inline-block", width: "auto"}}></input> (%)
                                        </div>
                                    </div>
                                ) : ""
                            }

                            {
                                this.state.shape == Shapes.Polygon ?

                                    <div className="ansitem small-3 columns" style={{ textAlign: "center", alignSelf: "center" }}>
                                        <button className="button" style={{ alignSelf: "left" }} onClick={() => this.setState({ points: null, isSavingProhibited: false })}>
                                            <i className="fa fa" /> {__("Alakzat törlése")}
                                        </button>
                                    </div>
                                    : ""
                            }
                        </div>

                        <div className="ansitem columns" style={{ textAlign: "center", alignSelf: "center" }}>
                            <button className="button" disabled={!this.coordinateSelected || this.state.isSavingProhibited} onClick={() => this.setState({ show_image: false }, () =>
                                this.props.onChange(this.props.taskValues, this.props.canvasInfo))}>
                                <i className="fa fa" /> {__("Mentés")}
                            </button>

                        </div>


                    </Dialog>
                    :
                    null
            }

        </div>;
    }

}