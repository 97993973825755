import { Server } from '../../server/Server';
import View , { registerViewClassProxy, ViewClassProxy } from '../View';
import { IListParams } from '@framework/crud/Crud';

export interface IViewKapUserSubprogramRecord {
    /**
     * Azonosító
     *
     * Azonosító (pótkulcs)
     */
    id?: number;
    /**
     * Rekord létrehozás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel létrehozásakor.
     */
    creation_time?: string /*timestamp?*/;
    /**
     * Ki hozta létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozó felhasználóra hivatkozik.
     */
    creation_user_id?: number;
    /**
     * Melyik session-ből jött létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozáshoz használt munkamenetre hivatkozik.
     */
    creation_session_id?: number|null;
    /**
     * Utolsó módosítás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel módosításakor.
     */
    modification_time?: string /*timestamp?*/|null;
    /**
     * Ki módosította utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosító felhasználóra hivatkozik.
     */
    modification_user_id?: number|null;
    /**
     * Melyik session-ből módosították utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosításhoz használt munkamenetre hivatkozik.
     */
    modification_session_id?: number|null;
    /**
     * Aktív
     *
     * Logikai törléshez használt mező.
     */
    is_active?: boolean;
    /**
     * Alkalmazás szintű felhasználó
     */
    sec_user_id?: number;
    /**
     * KAP alprogram
     */
    subprogram_id?: number;
    /**
     * E-mail
     *
     * E-mail
     */
    email?: string|null;
    /**
     * Felhasználó aktív
     *
     * Felhasználó aktív
     */
    user_is_active?: boolean|null;
    /**
     * Személy azonosító
     *
     * Személy azonosító
     */
    person_id?: number|null;
    /**
     * Teljes név
     *
     * Teljes név
     */
    fullname?: string|null;
    /**
     * Alprogram kód
     *
     * Alprogram kód
     */
    subprogram_code?: string|null;
    /**
     * Alprogram cím
     *
     * Alprogram cím
     */
    subprogram_title?: string|null;
}

/**
 *  kap.view_kap_user_subprogram - Szakmai támogató alprogramja
 *
 *  Idősoros táblázat, ami az összes tétel összes korábbi verzióját eltárolja.
 */

export default class ViewKapUserSubprogram extends View<IViewKapUserSubprogramRecord> {
    public static VIEW_INFO_ID = 2019101001;

    public static getSchemaNameForClass(): string { return 'kap'; }
    public static getViewNameForClass(): string { return 'view_kap_user_subprogram'; }
    public static getTableNameForClass(): string|null { return 'user_subprogram'; }

    public static load: (id: number, aServer ?: Server) => Promise<ViewKapUserSubprogram>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<IViewKapUserSubprogramRecord[]>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class ViewKapUserSubprogramClassProxy extends ViewClassProxy<IViewKapUserSubprogramRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const viewKapUserSubprogramClassProxy = new ViewKapUserSubprogramClassProxy(ViewKapUserSubprogram);
registerViewClassProxy(viewKapUserSubprogramClassProxy);

