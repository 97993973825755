import * as React from 'react';
import { hasAnyGroup, me, Groups } from '@src/framework/server/Auth';
import ViewMediaOoFile, { IViewMediaOoFileRecord } from '@src/framework/view/media/ViewMediaOoFile';
import { app } from '@src/index';
import { formatDate } from '@src/Util';
import { TimedBubbleLoader } from '@src/component/ui/TimedBubbleLoader';

//Folder constants JTR Live
const CSOPORTSZINTU = 25377633161;
const DFHTKOMPLEX = 25377633162;
const INTEZMENYIKAPCSOLATTARTO =25377633163;
const INTEZMENYIWORKSHOP = 25377633165;
const INTEZMENYVEZETO=25377633166;
const PEDAGOGUS = 25377633167;

type AttendanceHelpFilesProps = {
}
interface AttendanceHelpFilesState {
    loading:boolean;
    groupLevelFiles:IViewMediaOoFileRecord[],
    dfhtComplex:IViewMediaOoFileRecord[],
    instituteConnector:IViewMediaOoFileRecord[],
    instituteWorkshop:IViewMediaOoFileRecord[],
    instituteLeader:IViewMediaOoFileRecord[],
    teacher:IViewMediaOoFileRecord[],
   
   
}

export class AttendanceHelpFilesPage extends React.Component<AttendanceHelpFilesProps, AttendanceHelpFilesState> {

    constructor(props: any) {
        super(props);

        this.state = {
            loading:false,
            groupLevelFiles:[],
            dfhtComplex:[],
            instituteConnector:[],
            instituteWorkshop:[],
            instituteLeader:[],
            teacher:[]
        };
    }

    componentDidMount() {
        this.reloadAsync();
        ($(document) as any).foundation();
    }
  private async reloadAsync() {
      try {
        this.setState({loading:true})
         let groupLevelFiles=await ViewMediaOoFile.list({filter:{is_active:true,oo_folder_id:CSOPORTSZINTU}});
         let dfhtComplex=await ViewMediaOoFile.list({filter:{is_active:true,oo_folder_id:DFHTKOMPLEX}});
         let instituteConnector= await ViewMediaOoFile.list({filter:{is_active:true,oo_folder_id:INTEZMENYIKAPCSOLATTARTO}});
         let instituteWorkshop= await ViewMediaOoFile.list({filter:{is_active:true,oo_folder_id:INTEZMENYIWORKSHOP}});
         let instituteLeader= await ViewMediaOoFile.list({filter:{is_active:true,oo_folder_id:INTEZMENYVEZETO}});
         let teacher= await ViewMediaOoFile.list({filter:{is_active:true,oo_folder_id:PEDAGOGUS}});

         this.setState({groupLevelFiles,dfhtComplex,instituteConnector,instituteLeader,instituteWorkshop,teacher,loading:false});
          
      } 
      catch (error) {
        app.showErrorFromJsonResult(error);
      }

    }

    render() {
        if (this.state.loading) {
            return <TimedBubbleLoader/>
        }
        return <div className="row" style={{minHeight: "70vh"}}>
            <div className="column small-12">
                <br />
                <h4>Segédanyagok</h4>

                {
                    (me && hasAnyGroup(me, [Groups.Admin, Groups.KapJtrSupport, Groups.KapJtrCoordinator]) &&(this.state.groupLevelFiles && this.state.groupLevelFiles.length > 0))
                    ? 
                    <div>
                        <h5>Csoportszintű alprogramok szakmai támogatóknak</h5>
                    {this.state.groupLevelFiles.map((file: IViewMediaOoFileRecord, index: number) => {
                        return <div key={index} className="small-12 large-12 column">
                        
                                    <a href={"/api/media/file/" + file.sha1} target="_blank" download={file.title}>
                                        <i className="fa fa-download" /> &nbsp;
                                        {file.title ? file.title : file.displayname} 
                                    </a>
                                    <small>{ " ("+formatDate(file.creation_time)+")"}</small>                 
                                </div>
                    }
                    )}
            
                </div>
                :""
                }
                <br/>
                {
                    (me && hasAnyGroup(me, [Groups.Admin, Groups.KapJtrSupport, Groups.KapJtrCoordinator]) &&(this.state.dfhtComplex && this.state.dfhtComplex.length > 0))
                    ? 
                    <div>
                        <h5>DFHT-KIP és Komplex óra szakmai támogatóknak</h5>
                    {this.state.dfhtComplex.map((file: IViewMediaOoFileRecord, index: number) => {
                        return <div key={index} className="small-12 large-12 column">
                        
                                    <a href={"/api/media/file/" + file.sha1} target="_blank" download={file.title}>
                                        <i className="fa fa-download" /> &nbsp;
                                        {file.title ? file.title : file.displayname}
                                    </a>
                                    <small>{ " ("+formatDate(file.creation_time)+")"}</small>                      
                                </div>
                    }
                    )}
            
                </div>
                :""
                }
                <br/>
                {   
                    (me && hasAnyGroup(me, [Groups.Admin, Groups.KapJtrInstituteContact, Groups.KapJtrCoordinator]) &&(this.state.instituteConnector && this.state.instituteConnector.length > 0))
                    ? 
                    <div>
                        <h5>Intézményi kapcsolattartóknak</h5>
                    {this.state.instituteConnector.map((file: IViewMediaOoFileRecord, index: number) => {
                        return <div key={index} className="small-12 large-12 column">
                        
                                    <a href={"/api/media/file/" + file.sha1} target="_blank" download={file.title}>
                                        <i className="fa fa-download" /> &nbsp;
                                    {file.title ? file.title : file.displayname} 
                                    </a>
                                    <small>{ " ("+formatDate(file.creation_time)+")"}</small>
                                </div>
                    }
                    )}
            
                </div>
                :""
                }
                   <br/>
                {/*
                    false &&
                    <>
                    <h5>Intézményi workshop</h5>
                    <ul>
                        
                    </ul>
                    </>
                */}

                {/*
                    false &&
                    <>
                    <h5>Intézmény vezetői támogatás</h5>
                    <ul>
                        
                    </ul>
                    </>
                */}

                {   
                    (me && hasAnyGroup(me, [Groups.Admin, Groups.KapKszrCertifiedTeacher, Groups.KapJtrCoordinator]) &&(this.state.teacher && this.state.teacher.length > 0))
                    ? 
                    <div>
                        <h5>Pedagógusoknak</h5>
                    {this.state.teacher.map((file: IViewMediaOoFileRecord, index: number) => {
                        return <div key={index} className="small-12 large-12 column">
                        
                                    <a href={"/api/media/file/" + file.sha1} target="_blank" download={file.title}>
                                        <i className="fa fa-download" /> &nbsp;
                                        {file.title ? file.title : file.displayname} 
                                    </a>
                                    <small>{ " ("+formatDate(file.creation_time)+")"}</small>
                                </div>
                    }
                    )}
            
                </div>
                :""
                }
            <br/>
            </div>
          
        </div>

    }

}
