import axios, { AxiosResponse, AxiosRequestConfig } from 'axios';
import Auth, { IAuthConfig } from './Auth';
import { GetConfigResult } from '../api/config/getConfig';

export let config: GetConfigResult;
export let authConfig: IAuthConfig;
export let auth: Auth | null;

export function onConfigResultArrived(configResult: GetConfigResult, authConfigOverride?: IAuthConfig) {
    config = configResult;
    authConfig = {
        // TODO ???
        client_id: configResult.authServer!.clientId || "default_www_client_id",
        // TODO ???
        redirect_uri: window.location.origin + "/",
        oauth_server_root_url: configResult.authServer!.baseUrl,
        // TODO: ??? retrieve all applicable scopes from server!
        scope: "email profile history forum personal"
    }
    if (authConfigOverride) {
        authConfig = authConfigOverride;
    }
    mainServer = new Server(config.mainServer.apiBaseUrl, config.mainServer.headers, auth || undefined);
}

export function onAuthArrived(aAuth: Auth | null) {
    auth = aAuth;
    mainServer = new Server(config.mainServer.apiBaseUrl, config.mainServer.headers, auth || undefined);
}


export class Server {
    auth?: Auth;
    apiBaseUrl: string;
    headers?: object;

    constructor(apiBaseUrl: string, headers?: object, auth?: Auth) {
        this.apiBaseUrl = apiBaseUrl;
        this.headers = headers;
        this.auth = auth;
    }

    protected getHeaders(headers?: object): Object {
        let result = Object.assign({});
        if (this.headers)
            result = Object.assign(result, this.headers);
        if (this.auth)
            result = Object.assign(result, this.auth.getAuthorizationHeader());
        if (headers)
            result = Object.assign(result, headers);
        return result;
    }

    protected getApiUrl(path: string): string {
        return this.apiBaseUrl + path;
    }

    /** Send GET request to server. */
    public get<T>(path: string, headers = {}): Promise<T> {
        let config: AxiosRequestConfig = {
            method: 'get',
            url: this.getApiUrl(path),
            headers: this.getHeaders(headers)
        }
        return axios(config).then(response => response.data);
    }

    /** Send PUT request to server. */
    public put<T>(path: string, data?: any, headers = {}): Promise<T> {
        let config: AxiosRequestConfig = {
            method: 'put',
            url: this.getApiUrl(path),
            headers: this.getHeaders(headers),
            data: data
        }
        return axios(config).then(response => response.data);
    }

    /** Send POST request to server. */
    public post<T>(path: string, data?: any, headers = {}): Promise<T> {
        let config: AxiosRequestConfig = {
            method: 'post',
            url: this.getApiUrl(path),
            headers: this.getHeaders(headers),
            data: data
        }
        return axios(config).then(response => response.data);
    }

    /** Send DELETE request to server. */
    public del<T>(path: string, headers = {}): Promise<T> {
        let config: AxiosRequestConfig = {
            method: 'delete',
            url: this.getApiUrl(path),
            headers: this.getHeaders(headers)
        }
        return axios(config).then(response => response.data);
    }


    /** Send POST request to server. */
    public patch<T>(path: string, data?: any, headers = {}): Promise<T> {
        let config: AxiosRequestConfig = {
            method: 'patch',
            url: this.getApiUrl(path),
            headers: this.getHeaders(headers),
            data: data
        }
        return axios(config).then(response => response.data);
    }
}

export let mainServer: Server | null = new Server("/api/");
// export const searchServer = new Server();
// export const authServer = new Server();

function obtainServer(aServer?: Server) {
    if (aServer === undefined) {
        if (mainServer === null) {
            throw new Error("createMainServer was not yet called.");
        } else {
            return mainServer;
        }
    } else {
        return aServer;
    }
}

export default obtainServer;
