import * as React from "react";
import { me } from "@src/framework/server/Auth";
import { match } from 'react-router';
import { messengerModule } from "../messengerModule";
import ReactTable, { Column, ReactTableFunction, FilterRender, RowInfo } from "react-table";
import { formatDate } from "@src/Util";
import { __ } from "@src/translation";
import { getReactTableLabels } from "@src/framework/i18n";
import { debounce } from "@fullcalendar/core";
import { TFilterDict } from "@src/framework/crud/Crud";
import ViewUsrMessage, { IViewUsrMessageRecord } from "@src/framework/view/usr/ViewUsrMessage";
import { history, app } from '@src/index';
import { cmsConfig } from "@src/component/cms/CmsModule";
import { HeaderNotificationStore, HEADER_NOTIFICATION_REFRESH_INTERVAL_MSEC } from "@src/component/HeaderNotification";
import { Module } from "@src/module/Module";
import CourseCrud from "@src/framework/crud/kap/CourseCrud";
import { NewMessageComponent } from "./NewMessagePopUp";
import { confirmDialog } from "@src/component/Dialog";
import ViewUsrIncomingMessage from "@src/framework/view/usr/ViewUsrIncomingMessage";
import ViewUsrTrashMessage from "@src/framework/view/usr/ViewUsrTrashMessage";

interface IMessageFilter {
    sender: string;
    //recipients: string;
    subject: string;
    body_html: string;
    displayvalue: string;
}
type FilterFieldName = "sender" | "recipients" | "subject" | "body_html" | "displayvalue";
const TEXT_FILTER_FIELD_NAMES = ["subject", "body_html", "displayvalue"]; // Azok a mezők amikre $text operátorral közvetlenül lehet keresni.
const SPECIAL_FILTER_FIELD_NAMES = ["sender"]; // Azok a mezők, amikre speciális szűrő kell.
const DISABLED_TABLE_IDS: number[] = [/*SharedContentCrud.TABLE_INFO_ID*/]; //TABLE_INFO_ID lista, amelyeket nem akarunk megjeleníteni ezen az oldalon - Például megosztott tartalmak

interface IMessageListPageProps {
    match: match<{}>;
}

interface IMessageListPageState {
    messages?: IViewUsrMessageRecord[];
    filters: any;
    message_filter: MessageMainFilter;
    loading: boolean;
    itemsPerPage: number;
    count: number;
    filter: IMessageFilter;
    selectedMessages: MessageOperationInfo[];
}

const EMPTY_FILTER: IMessageFilter = {
    sender: "",
    //recipients: "",
    subject: "",
    body_html: "",
    displayvalue: ""
};

enum MessageMainFilter {
    INCOMING,
    OUTGOING,
    TRASH
}

type MessageOperationInfo = {
    message_id: number;
    sender_operation: boolean;
}

export default class MessageListPage extends React.Component<IMessageListPageProps, IMessageListPageState> {
    private timer: any;
    private columnFilterRenderers: { [fieldName: string]: FilterRender } = {}; // Ez tartalmazza a header-ben levő input / filter komponenseket    
    /** Ez egy FilterRender függvényt készít egy megadott oszlophoz. */
    private createColumnFilterRenderer = (fieldName: FilterFieldName, placeholder: string): FilterRender => {
        return (params: { column: Column, filter: any, onChange: ReactTableFunction, key?: string }) => {
            return <input
                style={{ width: "100%" }}
                placeholder={placeholder}
                value={this.state.filters[fieldName]}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => { this.setFilter(fieldName, e.target.value) }}
                onKeyDown={e => {
                    if (e.key === "Enter")
                        this.asyncReload();
                }}
            />;
        }
    }

    constructor(props: IMessageListPageProps) {

        super(props);
        this.timer = null;
        this.state = {
            messages: [],
            filters: {},
            message_filter: this.getMainFilter(this.props.match.path),
            loading: true,
            itemsPerPage: 20,
            count: 0,
            filter: EMPTY_FILTER,
            selectedMessages: []
        }
        // Itt add hozzá az oszlop filter komponenseket. Csak azokra a mezőkre, amik ReactTable oszlopban szerepelnek.
        this.columnFilterRenderers = {
            "sender": this.createColumnFilterRenderer("sender", __("Feladó keresése")),
            //"recipients": this.createColumnFilterRenderer("recipients", __("Címzett keresése")),
            "subject": this.createColumnFilterRenderer("subject", __("Tárgy keresése")),
            "displayvalue": this.createColumnFilterRenderer("displayvalue", __("Címke keresése")),
        }
    }

    componentDidMount() {
        //this.asyncReload(); // milyen kár hogy nincs MobX adatbázisunk, akkor ez se kellene...
    }

    componentDidUpdate() {
        let mainFilter = this.getMainFilter(this.props.match.path);
        if (mainFilter !== this.state.message_filter) {
            this.setState({ message_filter: mainFilter }, this.debouncedAsyncReload);
        }
    }

    private onSelectAll() {
        if (!this.state.messages || !this.state.messages.length) return;
        const isOutGoing = this.state.message_filter == MessageMainFilter.OUTGOING;
        if (this.state.selectedMessages.length === this.state.messages.length) {
            this.setState({ selectedMessages: [] });
        }
        else {
            const all: MessageOperationInfo[] = this.state.messages.map(m => { return { message_id: m.id!, sender_operation: isOutGoing }; })
            this.setState({ selectedMessages: all });
        }
    }

    private toggleChecked(id: number, sender_operation: boolean, e: any) {
        e.stopPropagation();
        let selectedMessages = this.state.selectedMessages;
        let idx = selectedMessages.findIndex((el) => { return el.message_id == id })
        if (idx > -1) {
            selectedMessages.splice(idx, 1);
        } else {
            selectedMessages.push({ message_id: id, sender_operation });
        }
        this.setState({ selectedMessages });
    }

    // Path alapján visszaadja az aktuális filtert a listázáshoz
    // Hívni kell konstruktorban is, mert a didMount többször is fut
    private getMainFilter(path: string): MessageMainFilter {
        const isIncoming = path.startsWith(messengerModule.PATH_MESSAGES_IN);
        const isOutcoming = path.startsWith(messengerModule.PATH_MESSAGES_OUT);
        const isTrash = path.startsWith(messengerModule.PATH_MESSAGES_TRASH);
        const isStartPage = !isIncoming && !isOutcoming && !isTrash;
        let mainFilter;
        if (!isTrash) {
            mainFilter = (isStartPage || isIncoming) ? MessageMainFilter.INCOMING : MessageMainFilter.OUTGOING;
        } else {
            mainFilter = MessageMainFilter.TRASH;
        }
        return mainFilter;
    }

    private async asyncReload(tablestate?: { page: number, pageSize: number, sorted: any, filtered: any[] }) {
        if (this.timer) {
            clearTimeout(this.timer);
        }

        this.timer = setTimeout(this.debouncedAsyncReload.bind(this), HEADER_NOTIFICATION_REFRESH_INTERVAL_MSEC);
        if (!me) {
            this.setState({ messages: [], loading: false });
            return;
        }
        this.setState({ loading: true });

        let page = tablestate ? tablestate.page : 0;
        let pageSize = tablestate ? tablestate.pageSize : this.state.itemsPerPage;

        const specFilter: any = {};
        let messages: IViewUsrMessageRecord[] = [];

        let orderBy = [{ name: "creation_time", desc: true }];

        if (tablestate && tablestate.sorted.length > 0) {
            const userOrder = tablestate.sorted[0];
            if (userOrder.desc) {
                orderBy = [{ name: userOrder.id, desc: true }];
            } else {
                orderBy = userOrder.id
            }
        }

        //KÖZÖS FILTEREK
        let filter: TFilterDict = {
            is_active: true,
            site_id: cmsConfig.siteId,
            // Azért kell or, mert a null-os table_info_id rekordokat nem kapnánk vissza
            $or: {
                table_info_id: null,
                $not: { table_info_id: DISABLED_TABLE_IDS }
            }
        };

        let view = ViewUsrIncomingMessage;

        //KIMENŐ
        if (this.state.message_filter == MessageMainFilter.OUTGOING) {
            filter = { ...filter, sender_deleted: null, creation_user_id: me.id };
            view = ViewUsrMessage;
        }
        //BEJÖVŐ
        else if (this.state.message_filter == MessageMainFilter.INCOMING) {
            filter = { ...filter, deleted_by_me: null, purged_by_me: null };
            //specFilter.recipient_id = me.id;
        }
        //KUKA
        else {
            //filter.$not = {deleted_by_me: null, sender_deleted: null };
            //specFilter.my_trash = true;
            view = ViewUsrTrashMessage;
        }

        // Hozzáadjuk azokat a szűréseket, amik közvetlenül text -re kereshetők.
        const colFilters: TFilterDict[] = [];
        TEXT_FILTER_FIELD_NAMES.forEach((fieldName: string) => {
            const fieldValue = this.state.filter[fieldName].trim();
            if (fieldValue != "") {
                colFilters.push({ "$text": { fieldNames: [fieldName], expression: fieldValue } });
            }
        });

        if (colFilters.length) {
            filter.$and = colFilters;
        }

        SPECIAL_FILTER_FIELD_NAMES.forEach((fieldName: string) => {
            const fieldValue = this.state.filter[fieldName].trim();
            if (fieldValue != "") {
                specFilter[fieldName] = fieldValue; // A szerver ezt MÉG nem kezeli, de fogja!
            }
        });

        try {
            let count = 0;
            messages = await view.list({
                filter: filter,
                spec: specFilter,
                order_by: orderBy,
                offset: page * pageSize,
                limit: pageSize
            });

            count = await view.count({ filter, spec: specFilter });

            this.setState({
                loading: false,
                itemsPerPage: pageSize,
                messages,
                count: count,
                selectedMessages: []
            });

        } catch (e) {
            app.showErrorFromJsonResult(e);
        }
    }

    private debouncedAsyncReload = debounce((tablestate: any) => this.asyncReload(tablestate), 200);

    /** Üzenetek törlése kukába */
    async onDeleteMessages(message_info: MessageOperationInfo[], e: React.ChangeEvent) {
        e.stopPropagation();
        let sender_ids = message_info.filter((info) => info.sender_operation).map(info => info.message_id);
        let incoming_ids = message_info.filter((info) => !info.sender_operation).map(info => info.message_id);
        try {
            await messengerModule.setDeleted(incoming_ids);
            await messengerModule.setSenderDeleted(sender_ids);
        } catch (error) {
            app.showErrorFromJsonResult(e);
        }
        this.debouncedAsyncReload();
    }

    /** Üzenetek visszaállítása kukából */
    async onRestoreMessages(ids: number[], e: React.ChangeEvent) {
        e.stopPropagation();
        try {
            await messengerModule.clearDeleted(ids);
            await messengerModule.clearSenderDeleted(ids);
        } catch (error) {
            app.showErrorFromJsonResult(e);
        }
        this.debouncedAsyncReload();
    }

    /** Üzenetek végleges törlése */
    async onPurgeMessages(message_info: MessageOperationInfo[], e: React.ChangeEvent) {
        e.stopPropagation();
        let ids = message_info.map(info => info.message_id);

        let sender_ids = message_info.filter((info) => info.sender_operation).map(info => info.message_id);
        let incoming_ids = message_info.filter((info) => !info.sender_operation).map(info => info.message_id);
        if (!await confirmDialog(__("Megerősítés"), __(`Ez a művelet véglegesen töröl ${incoming_ids.length + sender_ids.length} darab üzenetet. Biztos benne?`), __("Rendben"))) return;
        try {
            await messengerModule.setPurged(incoming_ids);
            await messengerModule.setSenderPurged(sender_ids);
        } catch (error) {
            app.showErrorFromJsonResult(e);
        }
        this.debouncedAsyncReload();
    }

    /** Üzenetek olvasatlannak jelölése */
    async onClearSeen(ids: number[], e: React.ChangeEvent) {
        e.stopPropagation();
        try {
            await messengerModule.clearSeen(ids);
            HeaderNotificationStore.changed();
        } catch (e) {
            app.showErrorFromJsonResult(e);
        }
        this.debouncedAsyncReload();
    }

    /** Üzenetek olvasottnak jelölése */
    async onSetSeen(ids: number[], e: React.ChangeEvent) {
        e.stopPropagation();
        try {
            await messengerModule.setSeen(ids);
            HeaderNotificationStore.changed();
        } catch (e) {
            app.showErrorFromJsonResult(e);
        }
        this.debouncedAsyncReload();
    }

    onClickDisplayValue(message: IViewUsrMessageRecord, e: React.ChangeEvent) {
        e.stopPropagation();
        let displayRoute: string = "";
        if (message.rec_id && message.table_info_id) {
            displayRoute = Module.getDefaultRecordRoute(message.table_info_id, message.rec_id!)!.route;
        }
        if (displayRoute != "") history.push(displayRoute);
    }

    // State-ben eltárolja az új szűrési értéket, és ütemezi a következő adat újratöltést.
    private setFilter = (field: FilterFieldName, value: string) => {
        const filter = this.state.filter;
        filter[field] = value;
        this.setState({ filter /* ez jó lenne de sajnos ez a filter input mezőt is kiszürkíti, loading: true */ })
    }

    componentWillUnmount() {
        if (this.timer) {
            clearTimeout(this.timer);
        }
    }

    render() {
        let unreadStyle = { fontWeight: "bold" };
        const isTrash = this.state.message_filter == MessageMainFilter.TRASH;
        const isIncoming = this.state.message_filter == MessageMainFilter.INCOMING;
        const isOutGoing = this.state.message_filter == MessageMainFilter.OUTGOING;
        let selCount = this.state.selectedMessages.length;
        let checkedIndexes = this.state.selectedMessages.map(sel => sel.message_id);
        return <>
            <div className="expanded row message-list-header" style={{ position: "relative" }}>
                <div>
                    <NewMessageComponent></NewMessageComponent>
                </div>
                <div className="message-text-search">
                    <label>{__("Keresés az üzenet szövegében: ")}</label>
                    <input
                        value={this.state.filter.body_html}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => { this.setFilter("body_html", e.target.value) }}
                        onKeyDown={e => {
                            if (e.key === "Enter")
                                this.asyncReload();
                        }}
                    />
                    <button className="button small" title={__("Frissítés")} onClick={() => this.asyncReload()}>
                        <i className="fas fa-sync-alt"></i>
                    </button>
                </div>
                {selCount > 0 && <div className="trash-wrapper">
                    {isTrash &&
                        <div><button className="button alert" onClick={this.onPurgeMessages.bind(this, this.state.selectedMessages)}>
                            <i className="fa fa-trash"></i>{__("Kijelöltek végleges törlése") + ` (${selCount})`}
                        </button>
                            <button className="button success" onClick={this.onRestoreMessages.bind(this, this.state.selectedMessages.map(sm => sm.message_id))}>
                                <i className="fa fa-recycle"></i>{__("Kijelöltek visszaállítása") + ` (${selCount})`}
                            </button>
                        </div>
                    }
                    {!isTrash &&
                        <button className="button warning" onClick={this.onDeleteMessages.bind(this, this.state.selectedMessages)}>
                            <i className="fa fa-trash"></i>{__("Kijelöltek törlése") + ` (${selCount})`}
                        </button>}
                </div>}
            </div>

            <ReactTable className="message-list"
                columns={[
                    {
                        Header: <div>
                            <button
                                title={__("Mindet kijelöl")}
                                className="button secondary"
                                onClick={() => this.onSelectAll()}
                                disabled={this.state.count == 0}
                                style={{
                                    padding: 3,
                                    margin: 0
                                }}
                            >
                                <i className="fa fa-check-double" />
                            </button>
                        </div>,
                        accessor: "select",
                        filterable: false,
                        sortable: false,
                        maxWidth: 45,
                        Cell: (data: { original: IViewUsrMessageRecord }, column: any) => {
                            return <div style={{ textAlign: "center" }}>
                                <input type="checkbox" checked={checkedIndexes.includes(data.original.id!)} onClick={(e) => e.stopPropagation()} onChange={this.toggleChecked.bind(this, data.original.id!, isOutGoing || data.original.creation_user_id == me!.id)} />
                            </div>
                        }
                    },
                    {
                        Header: __("Tárgy"), accessor: "subject", sortable: true, filterable: true, className: "subject",
                        Cell: (data: { original: IViewUsrMessageRecord }, column: Column) => {
                            return <span>{data.original.my_first_reply ? <i className={"fas fa-reply"} title={__("Megválaszolva: ") + formatDate(data.original.my_first_reply)}></i> : ""} {data.original.subject} </span>;
                        },
                        Filter: this.columnFilterRenderers["subject"]
                    },
                    {
                        Header: __("Feladó"), accessor: "creation_user_id", sortable: false, show: this.state.message_filter != MessageMainFilter.OUTGOING, filterable: true, className: "default_agent", width: 200,
                        Cell: (data: { original: IViewUsrMessageRecord }, column: Column) => {
                            return <span title={data.original.sender_email || ""}>{data.original.sender_fullname}</span>
                        },
                        Filter: this.columnFilterRenderers["sender"]
                    },
                    {
                        Header: __("Címzett"), accessor: "recipients", sortable: false, show: this.state.message_filter != MessageMainFilter.INCOMING, filterable: false, className: "default_agent", width: 200,
                        Cell: (data: { original: IViewUsrMessageRecord }, column: Column) => {
                            let recipientStr = data.original.recipients[0].fullname;
                            if (data.original.recipients.length > 1) {
                                recipientStr = recipientStr + "...(" + (data.original.recipients.length - 1) + ")";
                            }
                            return recipientStr;
                        },
                        //Filter: this.columnFilterRenderers["recipients"]
                        //TODO: Spec filter
                    },
                    {
                        Header: __("Címke"), accessor: "displayvalue", sortable: true, filterable: true, className: "displayvalue", width: 200,
                        Cell: (data: { original: IViewUsrMessageRecord }, column: Column) => {
                            let dispVal = (data.original.table_info_id != CourseCrud.TABLE_INFO_ID) ? data.original.table_display_name : data.original.displayvalue;

                            if (!dispVal) dispVal = "";

                            let displayTitle = data.original.displayvalue ? data.original.displayvalue : "";
                            if (dispVal.length > 25) dispVal = dispVal.substring(0, 25) + "...";
                            return (dispVal != "") ? <span onClick={this.onClickDisplayValue.bind(this, data.original)} className="label" title={displayTitle}> {dispVal}</span> : "";
                        },
                        Filter: this.columnFilterRenderers["displayvalue"]
                    },
                    {
                        Header: __("Dátum"), accessor: "creation_time", sortable: true, filterable: false, className: "time", width: 150,
                        Cell: (data: { original: IViewUsrMessageRecord }, column: Column) => {
                            return formatDate(data.original.creation_time || "")
                        }
                    },
                    {
                        Header: __("Művelet"), accessor: "actions", sortable: false, filterable: false, className: "time", width: 120,
                        Cell: (data: { original: IViewUsrMessageRecord }, column: any) => {
                            const msg = data.original;
                            const meSender = me && me.id == msg.creation_user_id;
                            const isSeen = msg.seen_by_me != null;

                            const actionButtons = <>
                                {isTrash && <button className={"button small alert"} title={__("Végleges törlés")} onClick={this.onPurgeMessages.bind(this, [{ message_id: msg.id, sender_operation: meSender }])}><i className="fa fa-trash"></i></button>}
                                {isTrash && <button className={"button small success"} title={__("Visszaállítás")} onClick={this.onRestoreMessages.bind(this, [msg.id])}><i className={"fa fa-recycle"}></i></button>}
                                {!isTrash && <button className={"button small alert"} title={__("Törlés")} onClick={this.onDeleteMessages.bind(this, [{ message_id: msg.id, sender_operation: isOutGoing }])}><i className={"fa fa-trash"}></i></button>}
                                {isIncoming && isSeen && <button className="button small" title={__("Megjelölöm olvasatlannak")} onClick={this.onClearSeen.bind(this, [msg.id])}><i className={"fa fa-envelope"}></i></button>}
                                {isIncoming && !isSeen && <button className="button small" title={__("Megjelölöm olvasottnak")} onClick={this.onSetSeen.bind(this, [msg.id])}><i className={"fa fa-envelope-open"}></i></button>}
                            </>;
                            return actionButtons;
                        }
                    }

                ]}
                manual
                filterable
                sortable
                data={this.state.messages}
                onFetchData={this.debouncedAsyncReload.bind(this)}
                defaultSorted={[
                    {
                        id: "creation_time",
                        desc: true
                    }
                ]}
                defaultPageSize={this.state.itemsPerPage}
                pages={Math.ceil(this.state.count / this.state.itemsPerPage)}
                loading={this.state.loading}
                {...getReactTableLabels()}
                getTdProps={(state: any, rowInfo: any, column: any) => {
                    if (column.id == "select") {
                        return {
                            onClick: (e: any) => {
                                //e.stopPropagation();
                                this.toggleChecked(rowInfo.original.id, isOutGoing || rowInfo.original.creation_user_id == me!.id, e);
                            }
                        }
                    }
                    if (rowInfo && rowInfo.row) {
                        let unreaded = isIncoming && state.data && state.data[rowInfo.row._index].seen_by_me == null;
                        return {
                            onClick: (e: any) => {
                                history.push(messengerModule.PATH_MESSAGES_VIEW + "/" + rowInfo.original.id)
                            },
                            style: unreaded ? unreadStyle : { color: "#848484" }
                        };
                    }
                    return {};
                }}
            />
        </>;
    }

}

