import { Module, ModuleMenu, BaseMenu, ModuleRoute } from "../../../module/Module";
import { __ } from "@src/translation";
import { viewKapCourseClassProxy } from "@src/framework/view/kap/ViewKapCourse";
import { CourseOrganizer } from "./CourseOrganizer";
import CourseDetails from "./CourseDetails";
import TrainingList from "./adminPages/TrainingList";
import CoursePublicList from "./CoursePublicList";
import { CourseOrganizerDictionariesPage } from "./CourseOrganizerDictionaries";
import CourseInvitations from "./adminPages/CourseInvitations";
import { CourseExportPage } from "./CourseExportPage";
import CourseQuestionnarieIn from "./CourseQuestionnarieIn";
import CourseList from "./adminPages/CourseList";
import CourseEdit from "./adminPages/CourseEdit";
import CourseCrud from "@src/framework/crud/kap/CourseCrud";
import * as React from "react";
import { Link } from "react-router-dom";
import { CourseDetailsMain } from "./courseModule/details/CourseDetailsMain";
import { CourseMassDownload } from "./courseModule/CourseMassDownload";
import CourseTypeMilestoneEdit from "./adminPages/CourseTypeMilestoneEdit";
import TrainerActivityPage from "./adminPages/TrainerActivityPage";
import TrainingTypeEdit from "./adminPages/TrainingTypeEdit";
import CourseUserCertificateFinder from "./adminPages/CourseUserCertificateFinder";

export let courseModule: CourseModule<BaseMenu>;

type MenuZone = {
    code: string;
    name: string;
}
export type CourseModuleConfig = {
    enableCourse?: boolean | null,
    enableCoursePublic?: boolean | null,
    enableCourseTrainingAdmin?: boolean | null,
    enableCourseAdministration?: boolean | null,
    enableCourseMassDocumentDownload?: boolean | null,
    enableCourseInvitation?: boolean | null,
    enableCourseTaught?: boolean | null,
    enableCourseOwn?: boolean | null,
    enableCourseDocumentsDictionaries?: boolean | null,
    enableCourseExport?: boolean | null,
    siteId?: number,
}

export class CourseModule<T extends BaseMenu> extends Module<T> {

    public config: CourseModuleConfig;
    public PATH_COURSE_ORGANIZER = "/kszr";

    public PATH_COURSE_ORGANIZER_TRAINING = this.PATH_COURSE_ORGANIZER + "/kepzes";
    public PATH_COURSE_ORGANIZER_TRAINING_EDIT = this.PATH_COURSE_ORGANIZER + "/kepzes/szerkesztes";
    public PATH_COURSE_ORGANIZER_HANDBOOK = this.PATH_COURSE_ORGANIZER + "/kezikonyv";
    public PATH_COURSE_ORGANIZER_TRAINING_ADMIN = this.PATH_COURSE_ORGANIZER + "/kepzesadmin";
    public PATH_COURSE_ORGANIZER_COURSE = this.PATH_COURSE_ORGANIZER + "/kurzus";
    public PATH_COURSE_ORGANIZER_COURSE_PUBLIC = this.PATH_COURSE_ORGANIZER + "/kurzusok";
    public PATH_COURSE_ORGANIZER_COURSE_OWN = this.PATH_COURSE_ORGANIZER + "/kurzusaim";
    public PATH_COURSE_ORGANIZER_COURSE_INVITATION = this.PATH_COURSE_ORGANIZER + "/kurzusmeghivasok";
    public PATH_COURSE_ORGANIZER_COURSE_ADMIN = this.PATH_COURSE_ORGANIZER + "/kurzusadmin";
    public PATH_COURSE_ORGANIZER_COURSE_TAUGHT = this.PATH_COURSE_ORGANIZER + "/oktatottkurzus";
    public PATH_COURSE_ORGANIZER_COURSE_MASS_DOWNLOAD = this.PATH_COURSE_ORGANIZER + "/tomeges_dokumentum_letoltes";
    public PATH_COURSE_ORGANIZER_QUESTIONARIE_IN = this.PATH_COURSE_ORGANIZER + "/bemeneti-kerdoiv";
    public PATH_COURSE_ORGANIZER_TEACHER_DICTIONARIES = this.PATH_COURSE_ORGANIZER + "/kepzoikezikonyvek";
    public PATH_COURSE_ORGANIZER_DOCUMENTS_DICTIONARIES = this.PATH_COURSE_ORGANIZER + "/utmutatokeskezikonyvek";
    public PATH_COURSE_ORGANIZER_EXPORT = this.PATH_COURSE_ORGANIZER + "/export";
    public PATH_COURSE_ORGANIZER_COURSE_ADMIN_LIST = "/kap/course/list";
    public PATH_COURSE_ORGANIZER_MILESTONE_EDIT = this.PATH_COURSE_ORGANIZER + "/merfolko";
    public PATH_COURSE_ORGANIZER_TRAINER_MONITOR = "/kepzoaktivitas";
    public PATH_COURSE_USER_CERTIFICATE_FINDER = "/tanusitvany_kereso";

    private static EDIT_ROUTES = {
        [CourseCrud.TABLE_INFO_ID]: "/kap/course/edit/"
    }

    private static VIEW_ROUTES = {
        [CourseCrud.TABLE_INFO_ID]: "/kszr/kurzus/"
    }

    constructor(config: CourseModuleConfig) {
        super();
        this.config = config;
    }

    public static initGlobal<T extends BaseMenu>(config: CourseModuleConfig) {
        courseModule = new CourseModule<T>(config);

    }
    public async getRoutesAsync(): Promise<Record<string, ModuleRoute<T>>> {
        return this.getRoutes();
    }

    public getRoutes(): Record<string, ModuleRoute<T>> {
        let sidebarHeader: JSX.Element = this.headerKSZR();
        let courseEdit = viewKapCourseClassProxy.getDefaultEditRoute();
        let courseList = viewKapCourseClassProxy.getDefaultListRoute();
        var routes: Record<string, ModuleRoute<T>> = {
            'ROUTE_COURSE_ORGANIZER': { path: this.PATH_COURSE_ORGANIZER, component: CourseOrganizer, exact: true, hasSidebar: true, sidebarHeader, menuCode: "course-sidebar" },
            'ROUTE_COURSE_ORGANIZER_TRAINING_ADMIN': { path: this.PATH_COURSE_ORGANIZER_TRAINING_ADMIN, component: TrainingList, hasSidebar: true, sidebarHeader, menuCode: "course-sidebar" },
            'ROUTE_COURSE_ORGANIZER_TRAINING_EDIT': { path: this.PATH_COURSE_ORGANIZER_TRAINING_EDIT+ "/:recId?", component: TrainingTypeEdit, hasSidebar: true, sidebarHeader, menuCode: "course-sidebar" },
            'ROUTE_COURSE_ORGANIZER_MILESTONE_EDIT': { path: this.PATH_COURSE_ORGANIZER_MILESTONE_EDIT+ "/:recId?", component: CourseTypeMilestoneEdit, hasSidebar: true, sidebarHeader, menuCode: "course-sidebar" },
            'ROUTE_COURSE_ORGANIZER_COURSE_PUBLIC': { path: this.PATH_COURSE_ORGANIZER_COURSE_PUBLIC, component: CoursePublicList, hasSidebar: true, sidebarHeader, menuCode: "course-sidebar" },
            'ROUTE_COURSE_ORGANIZER_COURSE_OWN': { path: this.PATH_COURSE_ORGANIZER_COURSE_OWN, component: CoursePublicList, hasSidebar: true, sidebarHeader, menuCode: "course-sidebar" },
            'ROUTE_COURSE_ORGANIZER_COURSE_INVITATION': { path: this.PATH_COURSE_ORGANIZER_COURSE_INVITATION, component: CourseInvitations, hasSidebar: true, sidebarHeader, menuCode: "course-sidebar" },
            'ROUTE_COURSE_ORGANIZER_COURSE_TAUGHT': { path: this.PATH_COURSE_ORGANIZER_COURSE_TAUGHT, component: CoursePublicList, hasSidebar: true, sidebarHeader, menuCode: "course-sidebar" },
            'ROUTE_COURSE_ORGANIZER_QUESTIONARIE_IN': { path: this.PATH_COURSE_ORGANIZER_QUESTIONARIE_IN, component: CourseQuestionnarieIn, hasSidebar: true, sidebarHeader, menuCode: "course-sidebar" },
            'ROUTE_COURSE_ORGANIZER_DOCUMENTS_DICTIONARIES': { path: this.PATH_COURSE_ORGANIZER_DOCUMENTS_DICTIONARIES, component: CourseOrganizerDictionariesPage, hasSidebar: true, sidebarHeader, menuCode: "course-sidebar" },
            'ROUTE_COURSE_ORGANIZER_EXPORT': { path: this.PATH_COURSE_ORGANIZER_EXPORT, component: CourseExportPage, hasSidebar: true, sidebarHeader, menuCode: "course-sidebar" },
            'ROUTE_COURSE_ORGANIZER_TRAINER_MONITOR': { path: this.PATH_COURSE_ORGANIZER_TRAINER_MONITOR, component: TrainerActivityPage, hasSidebar: true, sidebarHeader, menuCode: "course-sidebar" },
            //PATH_COURSE_ORGANIZER_TRAINER_MONITOR
            'PATH_COURSE_USER_CERTIFICATE_FINDER': { path: this.PATH_COURSE_USER_CERTIFICATE_FINDER, component: CourseUserCertificateFinder, hasSidebar: true, sidebarHeader, menuCode: "course-sidebar" },
            
            'PATH_COURSE_ORGANIZER_COURSE_MASS_DOWNLOAD': { path: this.PATH_COURSE_ORGANIZER_COURSE_MASS_DOWNLOAD, component: CourseMassDownload, exact: true, hasSidebar: true, sidebarHeader, menuCode: "course-sidebar" },            
            courseList: { path: courseList, component: CourseList, hasSidebar: true, sidebarHeader, menuCode: "course-sidebar" },
            courseEdit: { path: courseEdit, component: CourseEdit, hasSidebar: true, sidebarHeader, menuCode: "course-sidebar" }
        };

        routes['ROUTE_COURSE_ORGANIZER_COURSE'] = { path: this.PATH_COURSE_ORGANIZER_COURSE + "/:courseId?", component: CourseDetailsMain };

        return routes;
    }

    public getRecordRoute(tableInfoId: number, recordId: number, edit?: boolean): string | null {
        let route = (edit ? CourseModule.EDIT_ROUTES : CourseModule.VIEW_ROUTES)[tableInfoId];
        if (route !== undefined) {
            return route + recordId;
        }
        return null;
    }

    public headerKSZR(): JSX.Element {
        return <Link to={this.PATH_COURSE_ORGANIZER}><h4>KSZR</h4></Link>
    }

    public getMenus() {
        let ret: ModuleMenu<T>[] = [];
        ret = ret.concat(
            [
                {
                    enabled: this.config.enableCoursePublic,
                    title: __("Kurzus jelentkezés"),
                    path: this.PATH_COURSE_ORGANIZER_COURSE_PUBLIC,
                    iconClass: "fa-fw fas fa-book-open",
                    code: "course-sidebar"
                },
                {
                    enabled: this.config.enableCourseTrainingAdmin,
                    title: __("Képzés adminisztráció"),
                    path: this.PATH_COURSE_ORGANIZER_TRAINING_ADMIN,
                    iconClass: "fa fa-book",
                    code: "course-sidebar"
                },
                {
                    enabled: this.config.enableCourseAdministration,
                    title: __("Kurzus adminisztráció"),
                    path: viewKapCourseClassProxy.getListUrl(),
                    iconClass: "fa-fw fas fa-school",
                    code: "course-sidebar"
                },
                {
                    enabled: this.config.enableCourseMassDocumentDownload,
                    title: __("Tömeges dokumentum letöltés"),
                    path: this.PATH_COURSE_ORGANIZER_COURSE_MASS_DOWNLOAD,
                    iconClass: "fa-fw fas fa-download",
                    code: "course-sidebar"
                },
                {
                    enabled: this.config.enableCourseInvitation,
                    title: __("Kurzus meghívások"),
                    path: this.PATH_COURSE_ORGANIZER_COURSE_INVITATION,
                    iconClass: "fa-fw fas fa-bullhorn",
                    code: "course-sidebar"
                },
                {
                    enabled: this.config.enableCourseTaught,
                    title: __("Általam tartott kurzusok"),
                    path: this.PATH_COURSE_ORGANIZER_COURSE_TAUGHT,
                    iconClass: "fa-fw fas fa-chalkboard-teacher",
                    code: "course-sidebar"
                },
                {
                    enabled: this.config.enableCourseOwn,
                    title: __("Felvett kurzusaim"),
                    path: this.PATH_COURSE_ORGANIZER_COURSE_OWN,
                    iconClass: "fa-fw fas fa-book-reader",
                    code: "course-sidebar"
                },
                {
                    enabled: this.config.enableCourseDocumentsDictionaries,
                    title: __("Útmutatók/Kézikönyvek"),
                    path: this.PATH_COURSE_ORGANIZER_DOCUMENTS_DICTIONARIES,
                    iconClass: "fa fa-book",
                    code: "course-sidebar"
                },
                {
                    enabled: this.config.enableCourseExport,
                    title: __("Résztvevők Export"),
                    path: this.PATH_COURSE_ORGANIZER_EXPORT,
                    iconClass: "fa fa-file-excel",
                    code: "course-sidebar"
                },
                {
                    enabled: this.config.enableCourseAdministration,
                    title: __("Képzői aktivitás"),
                    path: this.PATH_COURSE_ORGANIZER_TRAINER_MONITOR,
                    iconClass: "fa-fw fas fa-chalkboard-teacher",
                    code: "course-sidebar"
                },
                {
                    enabled: this.config.enableCourseAdministration,
                    title: __("Tanúsítvány kereső"),
                    path: this.PATH_COURSE_USER_CERTIFICATE_FINDER,
                    iconClass: "fa-fw fas fa-certificate",
                    code: "course-sidebar"
                }


            ]);

        return ret;
    }

}
