import * as React from 'react';
import PermissionPage from '@src/framework/PermissionPage';
import LessonplanCrud, { ILessonplanRecord } from '@src/framework/crud/doc/LessonplanCrud';
import CrudSelect from '@framework/forms//crudselect';
import LookupEdit from '@src/framework/forms/lookupedit';
import { dpmkProjectCrudClassProxy } from '@src/framework/crud/doc/DpmkProjectCrud';
import InstituteCrud, { instituteCrudClassProxy, IInstituteRecord } from '@src/framework/crud/sys/InstituteCrud';
import InstituteSiteCrud, { instituteSiteCrudClassProxy } from '@src/framework/crud/sys/InstituteSiteCrud';
import { literacyFieldCrudClassProxy } from '@src/framework/crud/doc/LiteracyFieldCrud';
import { gradeCrudClassProxy } from '@src/framework/crud/doc/GradeCrud';

import { HTMLTextarea } from '@src/component/HtmlTextarea';
import { dpmkMethodologyPackageCrudClassProxy } from '@src/framework/crud/doc/DpmkMethodologyPackageCrud';
import { me, hasAnyGroup, Groups } from '@src/framework/server/Auth';
import { CrudPermissionType } from '@src/framework/security/PermissionCache';
import { instituteTypeCrudClassProxy } from '@src/framework/crud/sys/InstituteTypeCrud';
import { subjectCrudClassProxy } from '@src/framework/crud/doc/SubjectCrud';
import { __ } from '@src/translation';
import { LessonPlanEdit, LessonPlanEditProps, LessonPlanEditState, AsyncStatus, EMPTY_TECHICAL } from '../../../component/lessonplan/LessonPlanEdit';
import LessonplanSubjectCrud, { ILessonplanSubjectRecord } from '@src/framework/crud/doc/LessonplanSubjectCrud';
import LessonplanGradeCrud, { ILessonplanGradeRecord } from '@src/framework/crud/doc/LessonplanGradeCrud';
import LessonplanBlockCrud, { ILessonplanBlockRecord } from '@src/framework/crud/doc/LessonplanBlockCrud';
import OoFolderCrud, { IOoFolderRecord } from '@src/framework/crud/media/OoFolderCrud';
import ViewLessonplan from '@src/framework/view/doc/ViewLessonplan';
import { app, history } from '@src/index';
import { getMissingFields } from '@src/Util';
import OoFileCrud from '@src/framework/crud/media/OoFileCrud';
import { Dialog } from '../../../component/Dialog';
import MediaFileChooser, { FILE_ACTIONS } from '../../../component/filemanager/MediaFileChooser';
import ImageSettings from '../../../component/book/editor/ImageSettings';
import { PATH_LESSONPLAN_EDIT } from './lessonplan_routes';
import { CrudMultiSelect } from '@src/framework/forms/crudmultiselect';
import LessonCrud from '@src/framework/crud/doc/LessonCrud';
import { fetchKeywords, setKeywords } from '@src/component/meta/meta_keyword_assigner_plugin';
import { match } from 'react-router';

export class DpmkLessonPlanEditPage extends React.Component<{match: match<{id?: string}>}, DpmkLessonPlanEditState> {

    render() {
        return (<PermissionPage requirements={{
                    crud: {tableInfoId: LessonplanCrud.TABLE_INFO_ID, permissions: CrudPermissionType.R }
                }}>
            <DpmkLessonPlanEdit 
                id={this.props.match.params.id ? Number(this.props.match.params.id) : undefined} 
                onSave={newId => history.push(PATH_LESSONPLAN_EDIT + "/" + newId)}
                />
        </PermissionPage>
        );
    }
}

type DpmkLessonPlanEditState = {
    
    // DPMK specific: 
    lessonPlanSubjects: ILessonplanSubjectRecord[];
    lessonPlanGrades: ILessonplanGradeRecord[];
    subjectIds: number[];
    gradeIds: number[];

    institute?: IInstituteRecord;
    grade_id:any;

} & LessonPlanEditState<ILessonplanRecord, ILessonplanBlockRecord>;

export class DpmkLessonPlanEdit extends LessonPlanEdit<LessonPlanEditProps, DpmkLessonPlanEditState> {

    constructor(props: LessonPlanEditProps) {
        super(props);

        this.state = {
            lessonPlan: {},
            lessonPlanBlocks: [{}],
            lessonPlanSubjects: [],
            lessonPlanGrades: [],
            folders: [],
            deletedBlocks: [],
            subjectIds: [],
            gradeIds: [],
            status: AsyncStatus.LOADING,
            ownerName: "",
            grade_id:undefined,

            categorization: {},
            subject_ids: {},

            workflowDialogOpen: false,

            keywords: [],
        }
    }

    async componentDidMount() {
        if (super.componentDidMount) super.componentDidMount();
        
        if (this.props.id) {
            this.reloadAsync();
        } else {
            this.setState({status: AsyncStatus.LOADED});
        }
    }

    componentDidUpdate(prevProps: LessonPlanEditProps) {
        if (this.props.id && this.props.id !== prevProps.id) {
            this.reloadAsync();
        }

        if ((!this.state.institute && this.state.lessonPlan.institute_id) 
                || ( this.state.institute && this.state.institute.id !== this.state.lessonPlan.institute_id)
            ) {
            this.reloadInstituteAsync(this.state.lessonPlan.institute_id);
        }
        
        $('.accordion-title input').click(function(e){
            e.stopPropagation();
        });
    }

    getTitles() {
        return {
            mainTitle: __("Óravázlat / foglalkozásvázlat"),
            mainPanelTitle: __("Adatlap"),
            blockPanelTitle: __("Az óra menete"),
            secondPanelTitle: __("Tapasztalatok"),
            attachmentPanelTitle: __("Mellékletek"),
            blockTitle: __("Feladat"),
            tableInfoId: LessonplanCrud.TABLE_INFO_ID
        }
    }

    async reloadAsync() {
        const id = this.props.id;
        if (!id) return;

        try {
            const lessonPlan = (await LessonplanCrud.load(id)).record;
            const lessonPlanBlocks = await LessonplanBlockCrud.list({
                filter: {
                    is_active: true,
                    lessonplan_id: lessonPlan.id,
                },
                order_by: "no"
            });
            const lessonPlanSubjects = await LessonplanSubjectCrud.list({
                filter: {
                    is_active: true,
                    lessonplan_id: lessonPlan.id,
                },
                order_by: "id"
            });

            const lessonPlanGrades = await LessonplanGradeCrud.list({
                filter: {
                    is_active: true,
                    lessonplan_id: lessonPlan.id,
                },
                order_by: "id"
            });

            const folders = await OoFolderCrud.list({
                filter: {is_active: true, parent_id: lessonPlan.oo_folder_id}
            });

            const viewLessonPlan = (await ViewLessonplan.load(lessonPlan.id!)).record;
            const ownerName = viewLessonPlan.owner_name || "";

            const keywords = await fetchKeywords(LessonCrud.TABLE_INFO_ID, lessonPlan.id!);

            this.setState({
                lessonPlan,
                lessonPlanBlocks,
                lessonPlanSubjects,
                lessonPlanGrades,
                folders,
                ownerName,
                deletedBlocks: [],
                subjectIds: lessonPlanSubjects.map(s => s.subject_id!),
                gradeIds: lessonPlanGrades.map(g => g.grade_id!),
                status: AsyncStatus.LOADED,
                workflowStateName: viewLessonPlan.wf_station_name || "",
                keywords
            });
        } catch(e) {
            app.showErrorFromJsonResult(e);
        }
    }

    protected async onSave() {
        try {
            let missingFields = await getMissingFields(LessonplanCrud as any, this.state.lessonPlan);

            if (this.state.gradeIds.length == 0) {
                app.showError(__("Hiba"),__("Legalább egy évfolyamot meg kell adni!"));
                return;
            }

            for(const lessonPlanBlock of this.state.lessonPlanBlocks) {
                missingFields = missingFields.concat(await getMissingFields(LessonplanBlockCrud as any, lessonPlanBlock, ["lessonplan_id"]));
                if(lessonPlanBlock.require_time_min && lessonPlanBlock.require_time_min<0)
                {
                    app.showError(__("Hiba"),__("Az időkeretnek nullánál nagyobb számnak kell lennie!"));
                    return;
                }
            }

            if (missingFields.length) {
                    app.showError(
                    __("Sikertelen mentés") ,
                    __("Töltse ki az összes kötelező mezőt: {details}", {details: missingFields.join(", ")})
                );
                return;
            }

            if (!this.state.lessonPlan.dpmk_methodology_package_id && !this.state.lessonPlan.dpmk_methodology_package_text) {
                app.showError(__("Hiba"),__("Digitális pedagógiai módszertani csomag megadása kötelező!"));
                return;
            }

            this.setState({status: AsyncStatus.SAVING});
            const lp = this.state.lessonPlan;
            let toSave = {...lp, ...EMPTY_TECHICAL };
            // Ezt nem lehet megváltoztatni, nem küldjük.
            if (lp && lp.id) {
                delete toSave["dpmk_project_id"];
            }

            const lessonPlan = (await new LessonplanCrud(toSave).put()).record;
            await setKeywords(
                LessonplanCrud.TABLE_INFO_ID, lessonPlan.id!,
                this.state.keywords
            );
            
            for(const subject of this.state.lessonPlanSubjects) {
                if (!this.state.subjectIds.includes(subject.subject_id!)) {
                    await new LessonplanSubjectCrud({
                            lessonplan_id: lessonPlan.id,
                            subject_id: subject.subject_id,
                            is_active:false
                        }).upsert(["lessonplan_id", "subject_id"]);
                }
            }
            for(const subjectId of this.state.subjectIds) {
                if (!this.state.lessonPlanSubjects.find(l => l.subject_id == subjectId)) {
                    await new LessonplanSubjectCrud({
                            lessonplan_id: lessonPlan.id,
                            subject_id: subjectId,
                            is_active:true
                        }).upsert(["lessonplan_id", "subject_id"]);
                }
            }

            for(const garde of this.state.lessonPlanGrades) {
                if (!this.state.gradeIds.includes(garde.grade_id!)) {
                    await new LessonplanGradeCrud({
                            lessonplan_id: lessonPlan.id,
                            grade_id: garde.grade_id,
                            is_active:false
                        }).upsert(["lessonplan_id", "grade_id"]);
                }
            }
            for(const gradeId of this.state.gradeIds) {
                if (!this.state.lessonPlanGrades.find(g => g.grade_id == gradeId)) {
                    await new LessonplanGradeCrud({
                            lessonplan_id: lessonPlan.id,
                            grade_id: gradeId,
                            is_active:true
                        }).upsert(["lessonplan_id", "grade_id"]);
                }
            }

            var index = 1;
            for(const lessonPlanBlock of this.state.lessonPlanBlocks) {
                  
                if (lessonPlanBlock.id) {
                    lessonPlanBlock.lessonplan_id = undefined;
                } else {
                    lessonPlanBlock.lessonplan_id = lessonPlan.id;
                }
                
                lessonPlanBlock.no = index;
                index++;
                await new LessonplanBlockCrud(lessonPlanBlock).put();
            }
            for(const deletedId of this.state.deletedBlocks) {
                await new LessonplanBlockCrud({id: deletedId}).del();
            }

            await this.saveCategories(lessonPlan.id!);
            app.showSuccess(__("Óravázlat lementve"),__("Óravázlat lementve"));
        
            
            if (lessonPlan.id != this.props.id) {
                this.props.onSave(lessonPlan.id!);
            } else if (lessonPlan.id) {
                this.reloadAsync();
            }
        } catch(e) {
            app.showErrorFromJsonResult(e);
            
            this.setState({
                status: AsyncStatus.LOADED
            })
        }
    }

    async reloadInstituteAsync(instituteId?: number) {
        if (instituteId) {
            const institute = (await InstituteCrud.load(instituteId)).record;
            this.setState({institute});
        } else {
            this.setState({institute: undefined});
        }
    }
    
    protected getFormBeforeBlocks() {
        return <div className="row">
            <div className="column small-12">
                <label>
                    {__("Projektazonosító* :")}
                    <CrudSelect
                        value={this.state.lessonPlan.dpmk_project_id || null}
                        onSelect={(_, id) => this.onChange("dpmk_project_id", id ? Number(id) : null)}
                        displayFieldName="title"
                        orderByFieldName="title"
                        emptyTitle={__("Válasszon...")}
                        crudClassProxy={dpmkProjectCrudClassProxy}
                        filter={{ is_active: true }}
                        autoSelectSingle={true}
                        clearable={false}
                    />
                </label>
            </div>
            
            <div className="column small-12">
                <label>
                    {__("Intézmény* :")}
                    { me && hasAnyGroup(me,[Groups.Admin, Groups.NkpAdmin])?

                    <LookupEdit
                        key={"institute_id " + (this.state.lessonPlan.institute_id || 'null')}
                        fk_table_info_id={InstituteCrud.TABLE_INFO_ID}
                        clearable={true}
                        value={this.state.lessonPlan.institute_id}
                        onChange={(id) => {this.onChange("institute_site_id", null), this.onChange("institute_id", id)}}
                    />
                    :
                    <CrudSelect
                        value={this.state.lessonPlan.institute_id || null}
                        onSelect={(_, id) => {this.onChange("institute_site_id", null), this.onChange("institute_id", id)}}
                        displayFieldName="name"
                        orderByFieldName="name"
                        emptyTitle={__("Válasszon...")}
                        crudClassProxy={instituteCrudClassProxy}
                        filter={{ is_active: true }}
                        autoSelectSingle={true}
                        clearable={false}
                    />                                    
                    }
                </label>
            </div>

            <div className="column small-12 large-6">
                <label>
                    {__("Iskolatípus* :")}
                    <CrudSelect
                        value={this.state.lessonPlan.institute_type_id || null}
                        onSelect={(_, id) => this.onChange("institute_type_id", id ? Number(id) : null)}
                        displayFieldName="title"
                        orderByFieldName="title"
                        emptyTitle={__("Válasszon...")}
                        crudClassProxy={instituteTypeCrudClassProxy}
                        filter={{}}
                    />
                </label>
            </div>

            <div className="column small-12 large-6">
                <label>
                    {__("OM azonosító:")}
                    <input type="text" disabled value={this.state.institute ? this.state.institute.om_identifier : ""} />
                </label>
            </div>

            <div className="column small-12">
                <label>
                {__("Feladatellátási hely*")}  :

                    { me && hasAnyGroup(me,[Groups.Admin, Groups.NkpAdmin])?

                        <LookupEdit
                        key={"institute_site_id " + (this.state.lessonPlan.institute_site_id || 'null')}
                        disabled={!this.state.institute}
                        filter={{is_active: true, institute_id: this.state.institute ? this.state.institute.id : null}}
                        fk_table_info_id={InstituteSiteCrud.TABLE_INFO_ID}
                        clearable={true}
                        value={this.state.lessonPlan.institute_site_id}
                        onChange={(id) => this.onChange("institute_site_id", id)}
                        />
                    :
                    <CrudSelect
                        value={this.state.lessonPlan.institute_site_id || null}
                        onSelect={(_, id) => {this.onChange("institute_site_id", id)}}
                        displayFieldName="name"
                        orderByFieldName="name"
                        emptyTitle={__("Válasszon...")}
                        crudClassProxy={instituteSiteCrudClassProxy}
                        filter={{is_active: true, institute_id: this.state.institute ? this.state.institute.id : null}}
                        autoSelectSingle={true}
                        clearable={false}
                    />                                    
                    }

                </label>
            </div>

            <div className="column small-12">
                <label>
                    {__("Pedagógus:")}
                    <input 
                        type="text" 
                        disabled 
                        value={
                            this.state.lessonPlan.owner_id ? this.state.ownerName : me!.person!.full_name
                        }
                        />
                </label>
            </div>

            <div className="column small-12">
                <label>
                    {__("Műveltségterület* :")}

                    <CrudSelect
                        value={this.state.lessonPlan.literacy_field_id || null}
                        onSelect={(_, id) => this.onChange("literacy_field_id", id ? Number(id) : null)}
                        displayFieldName="name"
                        orderByFieldName="name"
                        emptyTitle={__("Válasszon...")}
                        crudClassProxy={literacyFieldCrudClassProxy}
                        filter={{ is_active: true }}
                    />

                </label>
            </div>

            <div className="column small-12">
                    {__("Tantárgyak:")}
                            
                    <CrudMultiSelect
                        crudClassProxy={subjectCrudClassProxy}
                        displayFieldName="name"
                        orderByFieldName="name"
                        values={this.state.subjectIds}
                        filter={{is_active: true}}
                        onChange={values => this.setState({subjectIds: values})}
                    />
            </div>
            <div className="column small-12">
                    {__("Évfolyamok* :")}
                    <CrudMultiSelect
                        crudClassProxy={gradeCrudClassProxy}
                        displayFieldName="name"
                        orderByFieldName="name"
                        values={this.state.gradeIds}
                        filter={{is_active: true}}
                        onChange={values => this.setState({gradeIds: values})}
                    />
            </div>

            <div className="column small-12">
                <label>
                    {__("Téma, témakör* :")}
                    <HTMLTextarea 
                        value={this.state.lessonPlan.topic || ""}
                        onChange={(text) => this.onChange("topic", text)}
                        />
                </label>
            </div>

            <div className="column small-12">
                <label>
                    {__("Kereszttantervi kapcsolódás:")}
                    <HTMLTextarea 
                        value={this.state.lessonPlan.cross_curriculum_link || ""}
                        onChange={(text) => this.onChange("cross_curriculum_link", text)}
                        />
                </label>
            </div>

            <div className="column small-12">
                <label>
                    {__("A tanóra célja* :")}
                    <HTMLTextarea 
                        value={this.state.lessonPlan.goal || ""}
                        onChange={(text) => this.onChange("goal", text)}
                        />
                </label>
            </div>

            <div className="column small-12">
                <label>
                    {__("Fejlesztendő készség, képesség, kompetencia* :")}
                    <HTMLTextarea 
                        value={this.state.lessonPlan.facility_to_develop || ""}
                        onChange={(text) => this.onChange("facility_to_develop", text)}
                        />
                </label>
            </div>

            <div className="column small-12">
                <label>
                    {" " + __("Egyéb digitális pedagógiai módszertani csomag* :")}

                    <CrudSelect
                        value={this.state.lessonPlan.dpmk_methodology_package_id || null}
                        onSelect={(_, id) => {
                            this.onChange("dpmk_methodology_package_id", id ? Number(id) : null, () => {
                                if (id) this.onChange("dpmk_methodology_package_text", null);
                            });
                        }}
                        displayFieldName="title"
                        orderByFieldName="title"
                        emptyTitle={__("Egyéb")}
                        crudClassProxy={dpmkMethodologyPackageCrudClassProxy}
                        filter={{ is_active: true }}
                    />

                </label>

                {
                    this.state.lessonPlan.dpmk_methodology_package_id
                    ?
                    null
                    :
                    <label>
                    {" " + __("Egyéb digitális pedagógiai módszertani csomag* :")}
                        <input 
                            type="text" 
                            value={this.state.lessonPlan.dpmk_methodology_package_text || ""}
                            onChange={(text) => this.onChange("dpmk_methodology_package_text", text.currentTarget.value)}
                            />
                    </label>
                }

            </div>
            
            <div className="column small-12">
                <label>
                    {__("Keretek (tanulás szervezési feltételek)* :")}
                    <HTMLTextarea 
                        value={this.state.lessonPlan.frame_study_conditions || ""}
                        onChange={(text) => this.onChange("frame_study_conditions", text)}
                        />
                </label>
            </div>

            <div className="column small-12">
                <label>
                {__("Keretek (közreműködő szakemberek):")}
                    <HTMLTextarea 
                        value={this.state.lessonPlan.frame_contributor_experts || ""}
                        onChange={(text) => this.onChange("frame_contributor_experts", text)}
                        />
                </label>
            </div>

            <div className="column small-12">
                <label>
                    {__("Elsajátítandó fogalmak jegyzéke* :")}
                    <HTMLTextarea 
                        value={this.state.lessonPlan.concepts_to_pick || ""}
                        onChange={(text) => this.onChange("concepts_to_pick", text)}
                        />
                </label>
            </div>
        </div>
    }

    protected getFormAfterBlocks() {
        return <div className="row">
            <div className="column small-12">
                <label>
                    {__("A megvalósítás tapasztalatai :")} 
                    <HTMLTextarea 
                        value={this.state.lessonPlan.conclusions || ""}
                        onChange={(text) => this.onChange("conclusions", text)}
                        />
                </label>
            </div>

            <div className="column small-12">
                <label>
                    {" " + __("Javaslatok :")}
                    <HTMLTextarea 
                        value={this.state.lessonPlan.suggestions || ""}
                        onChange={(text) => this.onChange("suggestions", text)}
                        />
                </label>
            </div>
        </div>
    }

    protected getExtraForm() {
        return null;
    }

    protected getConfirm() {
        return <></>;
    }

    private onLessonPlanBlockChange(index: number, lessonPlanBlock: ILessonplanBlockRecord) {
        this.state.lessonPlanBlocks[index] = lessonPlanBlock;
        this.setState({
            lessonPlanBlocks: this.state.lessonPlanBlocks
        })
    }

    getBlockForm(lpb: any, index: number) {
        return <LessonPlanBlock 
            lessonPlanBlock={lpb} 
            onLessonPlanBlockChange={this.onLessonPlanBlockChange.bind(this, index)}
            folders={this.state.folders}
            />
    }

}


type LessonPlanBlockProps = {
    lessonPlanBlock: ILessonplanBlockRecord;
    onLessonPlanBlockChange: (newValue: ILessonplanBlockRecord) => void;
    folders: IOoFolderRecord[];
}

type LessonPlanBlockState = {
    showSelectDialog: boolean;
    showImageDialog: boolean;
    selectedImagePath: string;
    tinyMCE: any
}

class LessonPlanBlock extends React.Component<LessonPlanBlockProps, LessonPlanBlockState> {

    constructor(props: LessonPlanBlockProps) {
        super(props);

        this.state = {
            showSelectDialog: false,
            showImageDialog: false,
            selectedImagePath: "",
            tinyMCE: undefined
        }
    }

    private onChange(propName: string, value: any) {
        const lessonPlanBlock = this.props.lessonPlanBlock;
        
        lessonPlanBlock[propName] = value;

        this.props.onLessonPlanBlockChange(lessonPlanBlock);
    }

    private onLinkChange(index: number | null, event: any) {
        var links: string[];

        if (this.props.lessonPlanBlock.links && this.props.lessonPlanBlock.links.links) {
            links = this.props.lessonPlanBlock.links.links;
        } else {
            links = [];
        }

        if (index !== null) {
            if (event.currentTarget.value) {
                links[index] = event.currentTarget.value
            } else {
                links.splice(index, 1);
            }
        } else {
            links.push(event.currentTarget.value);
        }
        
        this.onChange("links", {links: links});
    }


    onAddImage(tinyMCE: any) {
        this.setState({
            showSelectDialog: true,
            tinyMCE
        });
    }

    async onFileSelected(fileId: number) {
        const file = (await OoFileCrud.load(fileId)).record;

        this.setState({
            showSelectDialog: false,
            showImageDialog: true,
            selectedImagePath : "/api/media/file/" + file.sha1
        })
    }

    render() {
        const folders = this.props.folders.filter(f => f.title === "" + this.props.lessonPlanBlock.id);

        var nextLinkIndex = 0;
        if (this.props.lessonPlanBlock.links && this.props.lessonPlanBlock.links.links) {
            nextLinkIndex = this.props.lessonPlanBlock.links.links.length;
        }

        var links: JSX.Element[] = [];
        if (this.props.lessonPlanBlock.links && this.props.lessonPlanBlock.links.links) {
            this.props.lessonPlanBlock.links.links.map((link: string, index: number) => {
                links.push(<input key={index} type="text" value={link} onChange={this.onLinkChange.bind(this, index)} />);
            })
        }
        links.push(<input key={nextLinkIndex} type="text" placeholder={__("Új link hozzáadása: http://...")} value="" onChange={this.onLinkChange.bind(this, null)} />);

        return <div className="row">
        
                {
                    this.state.showSelectDialog
                    ?
                    <Dialog title={__("Kép választása")} width={1050} onClose={() => this.setState({ showSelectDialog: false })} >
                    <MediaFileChooser
                        rootFolderId={folders[0].id!}
                        currentFolderId={folders[0].id!}
                        selectableExtensions={["jpg", "jpeg", "png", "svg"]}
                        onFileSelected={(fileId) => { this.setState({ showSelectDialog: false }); this.onFileSelected(fileId); }}
                        enabledActions={FILE_ACTIONS}
                    />
                    </Dialog>
                    :
                    ""
                }
                {
                    this.state.showImageDialog
                    ?
                    <Dialog title={__("Kép beillesztése")} width={1000} height={800} onClose={() => this.setState({ showImageDialog: false })}>
                        <ImageSettings filePath={this.state.selectedImagePath} tinyMCE={this.state.tinyMCE} onClose={() => this.setState({ showImageDialog: false })} />
                    </Dialog>
                    :
                    null
                }

                <div className="column small-12">
                    <label>
                        {__("Feladat leírása* :")}
                        <HTMLTextarea
                            value={this.props.lessonPlanBlock.description || ""}
                            onChange={(text) => this.onChange("description", text)}
                            onAddImage={this.onAddImage.bind(this)}
                            />
                    </label>
                </div>

                <div className="column small-12">
                    <label>
                        {__("Módszerek/Munkaformák* :")}
                        <HTMLTextarea 
                            value={this.props.lessonPlanBlock.methods || ""}
                            onChange={(text) => this.onChange("methods", text)}
                            />
                    </label>
                </div>
                
                <div className="column small-12">
                    <label>
                       {__("Eszközök* :")} 
                        <HTMLTextarea 
                            value={this.props.lessonPlanBlock.tools || ""}
                            onChange={(text) => this.onChange("tools", text)}
                            />
                    </label>
                </div>

                <div className="column small-12">
                    <label>
                       {__("Értékelés* :")} 
                        <HTMLTextarea 
                            value={this.props.lessonPlanBlock.evaluation || ""}
                            onChange={(text) => this.onChange("evaluation", text)}
                            />
                    </label>
                </div>
                
                {
                    links && links.length>0
                    ? 
                    <div className="column small-12">
                    <label>
                        {__("Linkek")}:
                        {links}
                    </label>
                </div>
                    :""
                }        
                
                <div className="column small-12">
                    <label>
                        {__("Mellékletek")}:
                    </label>
                        {
                            folders.length >= 1 && folders[0].id
                            ?
                            <MediaFileChooser
                                currentFolderId={folders[0].id!}
                                rootFolderId={folders[0].id!}
                                enabledActions={FILE_ACTIONS}
                                showLocation={false}
                                showFolders={false}
                                
                            />
                            :
                            <div key="other" className="column small-12 ">
                                <p>{__("Melléklet feltöltéséhez először le kell menteni az óravázlatot!")}</p>
                            </div>
                        }
                        
                </div>
                
            </div>;
    }
}
