export class WMFillTableTextServer{

    static evaluateSolution(correctSolution: any, userSolution: any, caseSensitive: boolean): Boolean {
        //if the correctsolution ith item doesn't contain ; then it's a single item, just check wheter they are equal
        // console.log(correctSolution);
        // console.log(userSolution);
        let solarray = [];
        let usersol = userSolution;
        if (caseSensitive) {
            solarray = correctSolution.text.split(';').map((x: any) => x.trim());
        } else {
            solarray = correctSolution.text.split(';').map((x: any) => x.toLowerCase().trim());
            usersol = userSolution.toLowerCase();
        }
        if (solarray.indexOf(usersol) == -1) {
            return false;
        }
        return true;
    }


    static evaluateOnServer(exercise: any, correctSolution: any, userSolution: any): Evaluated {
        var success = true;
        let wrongElementsCount = 0;
        if (userSolution.length != correctSolution.length) {
            wrongElementsCount++;
            success = false;
        }

        if (success) {
            for (let index = 0; index < userSolution.length; index++) {
                if (!WMFillTableTextServer.evaluateSolution(correctSolution[index], userSolution[index], exercise.caseSensitive)) {
                    success = false
                    wrongElementsCount++;
                }
            }
        }
        let successPercent = Number(((correctSolution.length - wrongElementsCount) / correctSolution.length).toFixed(2));
        return { "success": success, "solution": correctSolution, "successPercent": success ? 1 : successPercent };
    }
}