import { Server } from '../../server/Server';
import View , { registerViewClassProxy, ViewClassProxy } from '../View';
import { IListParams } from '@framework/crud/Crud';

export interface IViewSecUserViewAuditRecord {
    /**
     * Azonosító
     *
     * Azonosító (pótkulcs)
     */
    id?: number;
    /**
     * Rekord létrehozás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel létrehozásakor.
     */
    creation_time?: string /*timestamp?*/|null;
    /**
     * Ki hozta létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozó felhasználóra hivatkozik.
     */
    creation_user_id?: number|null;
    /**
     * Melyik session-ből jött létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozáshoz használt munkamenetre hivatkozik.
     */
    creation_session_id?: number|null;
    /**
     * Utolsó módosítás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel módosításakor.
     */
    modification_time?: string /*timestamp?*/|null;
    /**
     * Ki módosította utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosító felhasználóra hivatkozik.
     */
    modification_user_id?: number|null;
    /**
     * Melyik session-ből módosították utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosításhoz használt munkamenetre hivatkozik.
     */
    modification_session_id?: number|null;
    /**
     * Nézettábla információ
     */
    view_info_id?: number|null;
    /**
     * Engedély birtokos
     */
    sec_perm_owner_id?: number|null;
    /**
     * Olvasás
     *
     * Létező rekordok lekérdezése, szűrése
     */
    can_read?: boolean|null;
    /**
     * Séma név
     *
     * Séma név
     */
    schema_name?: string|null;
    /**
     * View név
     *
     * Nézet táblázat név
     */
    view_name?: string|null;
    /**
     * Táblázat név
     *
     * Táblázat név
     */
    table_name?: string|null;
    /**
     * Kijelzett név
     *
     * A nézettábla neve magyar nyelven
     */
    view_display_name?: string|null;
    /**
     * Leírás
     *
     * A nézettábla leírása magyar nyelven
     */
    view_description?: string|null;
    /**
     * Felhasználó azonosító
     *
     * Felhasználó azonosító
     */
    sec_user_id?: number|null;
    /**
     * Bejelentkezési név
     *
     * Bejelentkezési név
     */
    login_name?: string|null;
    /**
     * E-mail
     *
     * E-mail
     */
    email?: string|null;
    /**
     * Személy azonosító
     *
     * Személy azonosító
     */
    person_id?: number|null;
    /**
     * Teljes név
     *
     * Teljes név
     */
    fullname?: string|null;
    /**
     * Forrás csoport azonosító
     *
     * Amin keresztül a jogot kapta
     */
    src_group_id?: number|null;
    /**
     * Forrás csoport
     *
     * Annak a csoportnak a neve, amin keresztül a jogot kapta
     */
    src_group_title?: string|null;
    /**
     * Forrás csoport leírás
     *
     * Annak a csoportnak a leírása, amin keresztül a jogot kapta
     */
    src_group_description?: string|null;
    /**
     * Forrás csoport típusa
     *
     * Annak a csoportnak a típusa, amin keresztül a jogot kapta
     */
    src_group_type?: string|null;
    /**
     * Létrehozó
     */
    creator?: string|null;
    /**
     * Módosító
     */
    modifier?: string|null;
}

/**
 *  meta.view_sec_user_view_audit - Felhasználó View jog audit
 */

export default class ViewSecUserViewAudit extends View<IViewSecUserViewAuditRecord> {
    public static VIEW_INFO_ID = 2019011601;

    public static getSchemaNameForClass(): string { return 'meta'; }
    public static getViewNameForClass(): string { return 'view_sec_user_view_audit'; }
    public static getTableNameForClass(): string|null { return 'null'; }

    public static load: (id: number, aServer ?: Server) => Promise<ViewSecUserViewAudit>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<IViewSecUserViewAuditRecord[]>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class ViewSecUserViewAuditClassProxy extends ViewClassProxy<IViewSecUserViewAuditRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const viewSecUserViewAuditClassProxy = new ViewSecUserViewAuditClassProxy(ViewSecUserViewAudit);
registerViewClassProxy(viewSecUserViewAuditClassProxy);

