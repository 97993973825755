import * as React from 'react';
import { BubbleLoader } from 'react-css-loaders';

import AccessDeniedPage from '@framework/pages/AccessDeniedPage';
import { IPermissionRequirements, permissionCache } from '@framework/security/PermissionCache';

import { app } from '@src/index';

export interface PermissionPageProps {
    requirements: IPermissionRequirements;
    // ha ezt beállítod, akkor hiba helyett egyszerűen eltünteti
    // de még jobb, ha akkor inkább a RenderIfHasPermission -t használod.
    silent ?: boolean; 
}

export interface PermissionPageState {
    loading: boolean;
    hasPermission?: boolean;
}


/**
 * Ha a felhasználónak nincsen elegendő jogosultsága, akkor egy "hozzáférés megtagadva"
 * lapot jelenít meg. Egyébként meg azt, ami "benne van".
 * 
 */
export default class PermissionPage extends React.Component<PermissionPageProps, PermissionPageState> {
    constructor(props: PermissionPageProps) {
        super(props);
        this.state = { loading: true };
        this.asyncReload();
    }

    private asyncReload = async () => {
        permissionCache.hasPermission(this.props.requirements)
            .then((hasPermission) => this.setState({ hasPermission, loading: false }))
            .catch((error) => { app.showErrorFromJsonResult(error); this.setState({ hasPermission:false, loading: false })})
    }

    render() {
        if (this.state.loading) {
            return <BubbleLoader />;
        } else if (this.state.hasPermission) {
            return <>{this.props.children}</>;
        } else if (this.props.silent) {
            return null;
        } else {
            return <AccessDeniedPage />;
        }
    }

}