import { Server } from '../../server/Server';
import Crud , { registerCrudClassProxy, IAsTextResult, IListParams, CrudClassProxy, ISearchTextParams, ISearchTextResult } from '../Crud';

export interface IStationRecord {
    /**
     * Globálisan egyedi azonosító.
     * 
     * Ha új rekordot akarsz létrehozni put() metódussal, akkor hagyd üresen.
     * upsert() hívásnál figyelmen kívül van hagyva az értéke.
     *
     */
    id ?: number;
    /**
     * Rekord létrehozás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel létrehozásakor.
     */
    creation_time?: string /*timestamp?*/;
    /**
     * Ki hozta létre
     *
     * NOT NULL -> sys.sec_user ON DELETE noaction
     *
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozó felhasználóra hivatkozik.
     */
    creation_user_id?: number;
    /**
     * Melyik session-ből jött létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozáshoz használt munkamenetre hivatkozik.
     */
    creation_session_id?: number|null;
    /**
     * Utolsó módosítás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel módosításakor.
     */
    modification_time?: string /*timestamp?*/|null;
    /**
     * Ki módosította utoljára
     *
     * -> sys.sec_user ON DELETE noaction
     *
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosító felhasználóra hivatkozik.
     */
    modification_user_id?: number|null;
    /**
     * Melyik session-ből módosították utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosításhoz használt munkamenetre hivatkozik.
     */
    modification_session_id?: number|null;
    /**
     * Aktív
     *
     * Logikai törléshez használt mező.
     */
    is_active?: boolean;
    /**
     * Sorrend
     */
    no?: number /*integer*/|null;
    /**
     * Folyamat típus
     *
     * NOT NULL -> wf.wf_type ON DELETE noaction
     *
     */
    wf_type_id?: number;
    /**
     * Név
     */
    name?: string;
    /**
     * Leírás
     */
    description?: string;
    /**
     * Kezdeti állapot
     *
     * A folyamat elindulhat ezzel az állapottal.
     */
    is_start?: boolean;
    /**
     * Lezárt állapot
     *
     * A lezárt állapot azt jelenti, hogy a folyamattal nincs több tennivaló, nem várunk külső vagy belső eseményre.
     */
    is_closed?: boolean;
    /**
     * Elszámolható állapot
     *
     * Mint pl.: számlánál pénzügyileg, raktárnál készlet szempontjából stb.
     */
    is_eligible?: boolean;
    /**
     * Törölt állapot
     *
     * Olyan állapot, ami a logikai törléssel egyenértékű.
     */
    is_deleted?: boolean;
    /**
     * Fej módosítható
     */
    head_mod?: boolean;
    /**
     * Fej törölhető
     */
    head_del?: boolean;
    /**
     * Tétel hozzáadható
     */
    det_ins?: boolean;
    /**
     * Tétel módosítható
     */
    det_mod?: boolean;
    /**
     * Tétel törölhető
     */
    det_del?: boolean;
    /**
     * Értesít
     *
     * Értesíti a résztvevőket az állapotba való beérkezéskor.
     */
    notify?: boolean;
    /**
     * Saját magát értesíti
     *
     * Értesíti saját magát az állapotba való beérkezéskor.
     */
    notify_actor?: boolean;
    /**
     * Figyelmeztetés lejárat
     */
    warning_timeout?: any /* interval */|null;
    /**
     * Hiba lejárat
     */
    error_timeout?: any /* interval */|null;
    /**
     * Stílus
     *
     * Az állapot színe, betűtípusa stb.
     */
    style?: any|null;
    /**
     * Alapértelmezett al-státusz
     *
     * -> wf.substation ON DELETE cascade
     *
     */
    default_substation_id?: number|null;
    /**
     * Al-státusz megadása kötelező
     */
    substation_required?: boolean;
}

/**
 *  wf.station - Folyamat állapot
 *
 *  Idősoros táblázat, ami az összes tétel összes korábbi verzióját eltárolja.
 */

export default class StationCrud extends Crud<IStationRecord> {
    public static TABLE_INFO_ID = 919558418;
    public static getSchemaNameForClass() { return 'wf'; }
    public static getTableNameForClass() { return 'station'; }

    public static load: (id: number, aServer ?: Server) => Promise<StationCrud>;
    public static asText: (id: number, includeRecord : boolean, aServer ?: Server) => Promise<IAsTextResult<IStationRecord>>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<IStationRecord[]>;
    public static searchText: (params: ISearchTextParams, aServer ?: Server) => Promise<ISearchTextResult<IStationRecord>[]>;
    public static deleteById: (id: number, aServer ?: Server) => Promise<IStationRecord>;
    public static unDeleteById: (id: number, aServer ?: Server) => Promise<IStationRecord>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class StationCrudClassProxy extends CrudClassProxy<IStationRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const stationCrudClassProxy = new StationCrudClassProxy(StationCrud);
registerCrudClassProxy(stationCrudClassProxy);
