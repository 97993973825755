import * as ExerciseBaseTypes from "@src/component/exercise/models/ExerciseBaseClass";
import * as React from "react";
import { hasGroup, me, Groups } from "@src/framework/server/Auth";
"@src/component/exercise/";
import { __ } from '@src/translation';
import OoFileCrud from "@src/framework/crud/media/OoFileCrud";

export type ValidationResponse = {
    valid: boolean,
    message?: string
}

export type StatisticsResponse = {
    name: string,
    count?: Map<number, number>
}

export type ExerciseTypeConverterState = {
    exercise: any,
    imgSchema?: any,
    textSchema?: any,
    imgTextSchema?: any,
}

export type ExerciseTypeConverterProps = {
    exerciseDetails: any,
    onUpdateEvent: (exercise: any) => void,
    imagebasepath: string,
    exerciseEditor: any,  //ExerciseEditor,
    engineName: string,
    validationMessages: Map<string, string>
}

/**
 * Abstract class that is the parent of all exercise converter classes. 
 * It includes static methods, such as: exerciseToString 
 */
export abstract class AExerciseTypeConverter extends React.Component<ExerciseTypeConverterProps, ExerciseTypeConverterState> {
    hasTextAnswer: boolean;
    hasImageAnswer: boolean;
    hasTextImageAnswer: boolean;
    public isGame:boolean = false;
    protected static isOfiEditor: boolean = false;
    private _isMounted: boolean = false;
    static GLOBAL_QUESTION_TEXT_LENGHT: number = 200;
    static GLOBAL_TASK_MAX_LENGTH: number = 3000;
    static MAX_ALT_TEXT_LENGTH: number = 500;
    static TEXT_ANSWER_LENGTH: number = 500;
    static FILLGAP_GAP_TEXT_LENGTH: number = 2500;
    static FILLTABLE_MAX_ROWS_NUM: number = 15;
    static FILLTABLE_MIN_ROWS_NUM: number = 2;
    static FILLTABLE_MAX_COLUMN_NUM: number = 15;
    static FILLTABLE_MIN_COLUMN_NUM: number = 2;
    static PAIRING_MIN_ANSWER_NUMBER: number = 2;
    static GLOBAL_LIMITED_QUESTION_NUMBER: number = 10;
    static QUIZ_MIN_ANS_NUMBER: number = 2;

    public get is_mounted(): boolean {
        return this._isMounted;
    }
    public set is_mounted(value: boolean) {
        this._isMounted = value;
    }

    static depricatedExerciseFields: string[] = [
        "backgroundImage",
        "rows",
        "rows2",
        "elements",
        "pair1",
        "pair2",
        "headers"
    ];

    static fileBasePath = "/api/media/file/";

    constructor(props: any) {
        super(props);
        this.state = {
            exercise: this.props ? this.props.exerciseDetails : null,
        };

        AExerciseTypeConverter.isOfiEditor = hasGroup(me!, Groups.OFIEditor);
        //if (this.props) this.loadSchemas(this.props.engineName);
    }

    /* methods to implement */

    public abstract convertToEditorAnswer(exercise: any): any | undefined;

    public abstract convertToJson(answerList: any, baseData: ExerciseBaseTypes.ExerciseBaseClass, prevJSON?: any): any | undefined;

    public convertOldNKPToJson(data: any, folderId?: number): any | undefined { }

    /** This method is responsible for validating the new state before the setState is called on it. 
     * This is usefull when we need to check the previous state to see if the new state is valid. 
     * E.g. when we want radiobutton-like functionality, we need to uncheck the previous checkbox and check the new.
     */
    public makeValidBeforeSetState(oldState: any, newState: any): ValidationResponse | void { }


    render() {

        return <span> No editor is available yet. :(</span>


    }

    componentDidMount() {
        this.is_mounted = true;
    }

    componentWillUnmount() {
        this.is_mounted = false;
    }

    async componentWillMount() {
        //await this.loadSchemas(this.props.engineName);
    }

    /**
     * Method for validating the exercise data before saving. 
     * Child classes can override it for specific needs.
     * @param editorAnswer exercise describing data
     * @param baseData common fields in all exercises
     * @param validationMap a map structure that contains the names of the faulty fields as "key" 
     * and the error message as "value" in the map. The key contains the specific property name.
     * @param is_accessible a 3 valued bool variable that indicates the level of accessibility: 
     * "null" - all image alt's  are required
     * "true" - illustration alt not required, but it is required for all answer elements
     * "false" - alt not required at all
     */
    public validate(editorAnswer: any, baseData: any, validationMap?: Map<string, string>, is_accessible?: boolean | null): ValidationResponse {

        let coreRecordString = "exerciseBaseData."
        let editorAnswerJson = this.convertToJson(editorAnswer, baseData);
        let errormessage = "";
        if (validationMap != undefined) { // should be checked when called from an exercise editor and not from the statistics page

            let result: ValidationResponse = this.validateMultiAnswerTypes(editorAnswer, validationMap, "", is_accessible);
            if (!result.valid) return result;
        }

        if (!editorAnswerJson.title) {
            errormessage = __("Kérem adja meg a feladat címét!");
            if (validationMap) validationMap.set(coreRecordString + "title", errormessage);
            return { valid: false, message: errormessage };
        } else {
            if (baseData.title.length > AExerciseTypeConverter.GLOBAL_TASK_MAX_LENGTH) {
                errormessage = __("A feladat címe nem lehet hosszabb mint: {n} karakter!", { n: AExerciseTypeConverter.MAX_ALT_TEXT_LENGTH });
                if (validationMap) validationMap.set(coreRecordString + "title", errormessage);
                return { valid: false, message: errormessage };
            }
        }

        /*if (!editorAnswerJson.description) {
            errormessage = __("Kérem adja meg a feladat leírását!");
            if (validationMap) validationMap.set(coreRecordString + "description", errormessage);
            return { valid: false, message: errormessage };
        }*/
            if (baseData.description && baseData.description.length > AExerciseTypeConverter.GLOBAL_TASK_MAX_LENGTH) {
                errormessage = __("A feladat leírása nem lehet hosszabb mint: {n} karakter!", { n: AExerciseTypeConverter.GLOBAL_TASK_MAX_LENGTH });
                if (validationMap) validationMap.set(coreRecordString + "description", errormessage);
                return { valid: false, message: errormessage };
            }

        if (baseData.illustration && is_accessible == null && (!baseData.illustration_alt || baseData.illustration_alt == "undefined")) {
            errormessage = __("Kérem adja meg az illusztráció leírását!");
            if (validationMap) validationMap.set(coreRecordString + "illustration_alt", errormessage);
            return { valid: false, message: errormessage };
        }

        console.log(this.isSolutionAvailable(editorAnswerJson))
        console.log(editorAnswerJson)
        if (!this.isGame && !this.isSolutionAvailable(editorAnswerJson)) {
            return { valid: false, message: __("A feladat nem megoldható! Kérjük ellenőrizze, hogy adott-e meg feladatmegoldást!") };
        }

        return { valid: true };
    }

    /** This method validates the text, image and url fields of the answer elements.*/
    private validateMultiAnswerTypes(exerciseDetailes: any, validationMap: Map<string, string>, propertyName: string, is_accessible?: boolean | null): ValidationResponse {
        let exerciseJSON = exerciseDetailes;
        let result: ValidationResponse = { valid: true };
        let message: string = "";
        var BreakException = {};
        try {
            if (exerciseJSON != null || undefined) {
                let currentKey = "";
                Object.keys(exerciseJSON).forEach((key) => {
                    currentKey = (propertyName ? propertyName + "." : "") + key;
                    if (typeof (exerciseJSON[key]) === "number") { //&& exerciseJSON[key].length > 0) {
                        if (key == "type") {
                            if (exerciseJSON[key] != ExerciseBaseTypes.AnswerTypes.text && is_accessible != false && (!exerciseJSON["text"] || exerciseJSON["text"] == "")) message = __("Hiányzó alt leírás a válasz elemeknél!");
                            if (exerciseJSON["text"] && exerciseJSON["text"].length > AExerciseTypeConverter.MAX_ALT_TEXT_LENGTH) message = __("A válaszelemek karakterszáma maximum {n} lehet!", { n: AExerciseTypeConverter.MAX_ALT_TEXT_LENGTH });
                            if (exerciseJSON[key] == ExerciseBaseTypes.AnswerTypes.image) if ((!exerciseJSON["image"] || exerciseJSON["image"] == "") && (!exerciseJSON["url"] || exerciseJSON["url"] == "")) message = __("Kép típusú válasznál hiányzó kép!");
                            if (exerciseJSON[key] == ExerciseBaseTypes.AnswerTypes.sound) if (!exerciseJSON["url"] || exerciseJSON["url"] == "") message = __("Hang típusú válasznál hiányzó hangfájl!");
                            if (message != "") {
                                result = { valid: false, message };
                                validationMap.set(currentKey, message);
                            }
                        }
                    } else if (exerciseJSON[key] instanceof Array) {
                        let currStr = currentKey;
                        exerciseJSON[key].forEach((element: any, index: number) => {
                            currStr = currentKey + "[" + index + "]";
                            result = this.validateMultiAnswerTypes(element, validationMap, currStr, is_accessible);
                            if (!result.valid) throw BreakException;
                        });
                    } else if (typeof exerciseJSON[key] === 'object') {
                        result = this.validateMultiAnswerTypes(exerciseJSON[key], validationMap, currentKey, is_accessible);
                        if (!result.valid) throw BreakException;
                    }
                    if (!result.valid) throw BreakException;
                });
            }
        } catch (e) {
            console.log("Validate Exception", e);
        }
        return result;
    }

    /** This method checks if the current exercise can be solved at all. 
     * It has to be overwritten for those engines that have specific needs. Then the parameter "engineName can be removed." 
     */
    public isSolutionAvailable(exerciseJson: ExerciseBaseTypes.ExerciseBaseClass): boolean {
        var missingSolution = true;
        let curr_solution = exerciseJson.solution;

        if (!curr_solution)
            return false;

        // the solution is normally always an array
        if (Array.isArray(curr_solution)) {

            if (curr_solution.length == 0)
                return false;

            for (let index = 0; index < curr_solution.length; index++) {
                if (curr_solution[index] != undefined) {
                    if (typeof curr_solution[index] === 'object') {
                        Object.keys(curr_solution[index]).forEach(key => {
                            if (curr_solution[index][key] != undefined) {
                                missingSolution = false;
                                return; // todo: test this
                            }
                        });
                    } else {
                        missingSolution = false;
                        break;
                    }
                }
            }
        }
        return !missingSolution;
    }

    /** This method collects statistics about the existing exercises, like number of questions and answers and so on.
     * Child classes (converters) should override this method.
 */
    public getExerciseStatistics(exerciseList: any): StatisticsResponse[] {
        return [];
    }



    public async getFolderId(): Promise<number | undefined> {
        return await this.props.exerciseEditor.getFolderId();
    }


    reset(exerciseDetails: any) {
        this.setState({
            exercise: exerciseDetails
        });
    }

    /**
   * Moving the index-th element in the array, given by the property name.
   * @param propname Full name of the array that we want to change. Meets naming conventions, e.g. "properyA.propertyB#indexInpropertyBArray.propertyC"
   * @param index index of the element to be moved.
   */
    moveUp(propname: string, index: number) {
        let temp_exercise = this.state.exercise;
        this.changeArray(propname.split("."), temp_exercise, this.moveUpInArray, index);
        this.setState({ exercise: temp_exercise });
        this.onBlurEvent();
    }

    private moveUpInArray(arrayToChange: any[], index: number) {
        if (index <= 0) return;
        let temp_el = arrayToChange[index];
        arrayToChange.splice(index, 1);
        arrayToChange.splice(index - 1, 0, temp_el);
    }

    /**
     * Moving the index-th element in the array, given by the property name.
     * @param propname Full name of the array that we want to change. Meets naming conventions, e.g. "properyA.propertyB#indexInpropertyBArray.propertyC"
     * @param index index of the element to be moved.
     */
    moveDown(propname: string, index: number) {
        let temp_exercise = this.state.exercise;
        this.changeArray(propname.split("."), temp_exercise, this.moveDownInArray, index);
        this.setState({ exercise: temp_exercise });
        this.onBlurEvent();
    }

    private moveDownInArray(arrayToChange: any[], index: number) {
        if (index >= arrayToChange.length - 1) return;
        let temp_el = arrayToChange[index];
        arrayToChange.splice(index, 1);
        arrayToChange.splice(index + 1, 0, temp_el);
    }

    /**
     * Removing the index-th element of the array, given by the property name.
     * @param propname Full name of the array that we want to change. Meets naming conventions, e.g. "properyA.propertyB#indexInpropertyBArray.propertyC"
     * @param index index of the element to be removed.
     */
    removeElement(propname: string, index: number) {
        let temp_exercise = this.state.exercise;
        this.changeArray(propname.split("."), temp_exercise, this.removeElementInArray, index);
        this.setState({ exercise: temp_exercise });
        this.onBlurEvent();
    }

    private removeElementInArray(arrayToChange: any[], index: number) {
        //arrayToChange = arrayToChange.slice(index, 1);
        arrayToChange.splice(index, 1);
    }

    /** generic method to set the image source of a specific propety of the this.state.exercise.
     * @param propertyName meets naming conventions, e.g. "properyA.propertyB#indexInpropertyBArray.propertyC"
     * @param imgPath path of image to be set
     */
    async handleImageChange(propertyName: string, imgPath: string) {
        let new_event = { target: { name: propertyName, type: "image", value: imgPath } };
        let filenameSHA1 = imgPath.substring(imgPath.lastIndexOf('/') + 1);
        let list = await OoFileCrud.list({ filter: { is_active: true, sha1: filenameSHA1 } });
        this.handleInputChange(new_event);
        if (list && list.length > 0) {
            let altText = "No alt";
            if (list[0].displayname)
                altText = list[0].displayname;
            else if (list[0].ext && list[0].title)
                altText = list[0].title.replace(list[0].ext, '');

            this.handleAltText(propertyName, altText);
        }
        this.onBlurEvent();
    }

    async handleFileChanged(propertyName: string, filePath: string) {
        console.log(propertyName);
        let new_event = { target: { name: propertyName, type: "sound", value: filePath } };
        this.handleInputChange(new_event);
        this.onBlurEvent();
    }

    handleAltText(properts: string, value: string) {
        let newProps = properts.split(".");
        if (newProps.indexOf("image") != -1)
            newProps.splice(newProps.indexOf("image"), 1);
        else if (newProps.indexOf("url") != -1)
            newProps.splice(newProps.indexOf("url"), 1);
        newProps.push("text");
        let newExercise = JSON.parse(JSON.stringify(this.state.exercise));

        let oldAlt = this.getProperty(newProps, newExercise);
        if (!oldAlt || oldAlt == "") {
            this.setProperty(newProps, newExercise, value);
            this.makeValidBeforeSetState(this.state.exercise, newExercise);
            this.setState({ exercise: newExercise });
        }
    }

    /**
     * A generic inputChange handler which requires that the event.target.name meets naming conventions. 
     * ("properyA.propertyB#indexInpropertyBArray.propertyC"). This naming ensures that the correct property 
     * of this.state.exercise will be updated.
     * @param event onChange event, with target{name:string, value:any, type:string}
     */
    handleInputChange(event: any) {
        const target = event.target;
        var value = target.value;

        if (target.type === 'checkbox') {
            value = target.checked;
        } else if (target.type == 'select-one' || target.type == "number" || target.type == "coordinate") {
            value = Number(target.value);
            if (isNaN(value)) value = target.value;
        }


        let newExercise = JSON.parse(JSON.stringify(this.state.exercise));
        this.setProperty(target.name.split("."), newExercise, value);

        this.makeValidBeforeSetState(this.state.exercise, newExercise);

        this.setState({
            // exercise: { ...this.state.exercise, [target.name]: value }
            exercise: newExercise
        }, () => {
            if (target.type !== 'text' && target.type !== 'textarea') {
                this.onBlurEvent();
            }
        });

    }

    async handleInputChangeAdditionalArray(array: any[]) {
        let newExercise = JSON.parse(JSON.stringify(this.state.exercise));
        let target: any = null;
        for (let index = 0; index < array.length; index++) {
            let new_event = { target: { name: array[index].key, type: array.length == 1 ? "points" : "coordinate", value: array[index].value } }

            target = new_event.target;
            var value = target.value;

            if (target.type == 'select-one' || target.type == "number" || target.type == "coordinate") {
                value = Number(target.value);
            }


            this.setProperty(target.name.split("."), newExercise, value);
        }

        this.makeValidBeforeSetState(this.state.exercise, newExercise);

        this.setState({
            // exercise: { ...this.state.exercise, [target.name]: value }
            exercise: newExercise
        }, () => {
            if (target.type !== 'text') {
                this.onBlurEvent();
            }
        });

    }

    reduceArray(array: any[], index: number) {
        array.splice(index, 1);
    }

    onBlurEvent() {
        this.props.onUpdateEvent(this.state.exercise);
    }

    private getProperty(propertyNameList: string[], parentObject: any): any {
        let arrayParams = propertyNameList[0].split("#");
        if (propertyNameList.length == 1) {
            try {
                if (arrayParams.length == 2) {
                    return parentObject[arrayParams[0]][Number(arrayParams[1])];
                } else if (arrayParams.length == 3) {
                    return parentObject[arrayParams[0]][Number(arrayParams[1])][Number(arrayParams[2])];
                } else {
                    return parentObject[propertyNameList[0]];
                }
            } catch (exeption) {
                console.log("The property does not exist on the element.", exeption);
            }
            //return parentObject;
        } else {
            let newPropnameList = propertyNameList.slice(1, propertyNameList.length);

            if (arrayParams.length == 2) {
                return this.getProperty(newPropnameList, parentObject[arrayParams[0]][Number(arrayParams[1])]);
            } else if (arrayParams.length == 3) {
                return this.getProperty(newPropnameList, parentObject[arrayParams[0]][Number(arrayParams[1])][Number(arrayParams[2])]);
            } else {
                return this.getProperty(newPropnameList, parentObject[propertyNameList[0]]);
            }
        }

    }

    private setProperty(propertyNameList: string[], parentObject: any, value: any) {
        //let tempPropnameList = propertyName.split(".");
        //console.log("mi ír át ", propertyNameList, parentObject, value);
        let arrayParams = propertyNameList[0].split("#");
        if (propertyNameList.length == 1) {
            try {
                if (arrayParams.length == 2) {
                    parentObject[arrayParams[0]][Number(arrayParams[1])] = value;
                } else if (arrayParams.length == 3) {
                    parentObject[arrayParams[0]][Number(arrayParams[1])][Number(arrayParams[2])] = value;
                } else {
                    parentObject[propertyNameList[0]] = value;
                }
            } catch (exeption) {
                console.log("The property does not exist on the element.", exeption);
            }
            //return parentObject;
        } else {
            let newPropnameList = propertyNameList.slice(1, propertyNameList.length);

            if (arrayParams.length == 2) {
                this.setProperty(newPropnameList, parentObject[arrayParams[0]][Number(arrayParams[1])], value);
            } else if (arrayParams.length == 3) {
                this.setProperty(newPropnameList, parentObject[arrayParams[0]][Number(arrayParams[1])][Number(arrayParams[2])], value);
            } else {
                this.setProperty(newPropnameList, parentObject[propertyNameList[0]], value);
            }
        }
    }

    private changeArray(propertyNameList: string[], parentObject: any, methodOfChange: (arrayToChange: any[], index: number) => void, index: number) {
        //let tempPropnameList = propertyName.split(".");
        let arrayParams = propertyNameList[0].split("#");

        if (propertyNameList.length == 1) {
            try {
                if (arrayParams.length > 1) {
                    methodOfChange(parentObject[arrayParams[0]][Number(arrayParams[1])], index);
                } else methodOfChange(parentObject[propertyNameList[0]], index);
            } catch (exeption) {
                console.log("The property does not exist on the element.", exeption);
            }
            //return parentObject;
        } else {
            let newPropnameList = propertyNameList.slice(1, propertyNameList.length);

            if (arrayParams.length == 2) {
                this.changeArray(newPropnameList, parentObject[arrayParams[0]][Number(arrayParams[1])], methodOfChange, index);
            } else if (arrayParams.length == 3) {
                this.changeArray(newPropnameList, parentObject[arrayParams[0]][Number(arrayParams[1])][Number(arrayParams[2])], methodOfChange, index);
            } else {
                this.changeArray(newPropnameList, parentObject[propertyNameList[0]], methodOfChange, index);
            }
        }
    }

    public static exerciseToString(exerciseFullJSON: any, containsBase: boolean, additionalStrings: string[]): string {
        let exerciseJSON = exerciseFullJSON;
        let new_string = additionalStrings.toString();
        let stringToAdd = "";

        try {

            if (containsBase) {
                exerciseJSON = { ...exerciseFullJSON }; // making a copy of the original object, because we'll delete from it
                new_string += ", " + ExerciseBaseTypes.baseJSONToString(exerciseJSON);
                let baseData: ExerciseBaseTypes.ExerciseBaseClass = ExerciseBaseTypes.convertToBaseClass(exerciseJSON)!;
                // removing the properties that we don't want to convert anymore
                Object.keys(baseData).forEach(key => {
                    exerciseJSON[key] = undefined;
                });
                // removing depricated fields
                AExerciseTypeConverter.depricatedExerciseFields.forEach(key => {
                    if (exerciseJSON[key]) //delete exerciseJSON[key];
                        exerciseJSON[key] = undefined;
                });
            }

            if (typeof (exerciseJSON) == "string") {
                if (!exerciseJSON.includes(AExerciseTypeConverter.fileBasePath) && exerciseJSON.length > 0) stringToAdd = exerciseJSON;
            } else if (typeof exerciseJSON === 'object') {
                Object.keys(exerciseJSON).forEach(key => {
                    if (typeof (exerciseJSON[key]) == "string"
                        && exerciseJSON[key].length > 0 && !exerciseJSON[key].includes(AExerciseTypeConverter.fileBasePath)
                        && key != "image" && key != "type" && key != "url" && key != "question_illustration") {
                        stringToAdd = String(exerciseJSON[key]);
                    } else if (exerciseJSON[key] instanceof Array && key != "setbackgrounds") { // todo: remove these when they are deleted from preious exercises
                        exerciseJSON[key].forEach((element: any) => {
                            stringToAdd += String(this.exerciseToString(element, false, []));
                        });
                    } else if (typeof exerciseJSON[key] === 'object') {
                        stringToAdd += String(this.exerciseToString(exerciseJSON[key], false, []));
                    }
                });
            }
        } catch (e) {
            console.log("Exercise toString conversion failed: ", e);
        }
        //console.log("final_new_string", new_string);
        if (!stringToAdd || stringToAdd.length == 0) return new_string;

        return new_string + ", " + stringToAdd;
    }
}


export type ShuffleResult = {
    answers: any[],
    indexes: number[]
}

/**
 * Shuffles and merges the two input arrays and returns the shuffled list 
 * together with the list of original indexes. The list of indexes contains only the new indexes
 * of the answer array elements, the odd element indexes are not included.
 * @param a The answer array to shuffle
 * @param additionalElements If there are odd elements, they get mixed with the true answer elements
 */
export function shuffle(a: any[], additionalElements?: any[] | null): ShuffleResult {
    if (!a || a.length == 0) return { answers: [], indexes: [] };
    var j, x, i;
    var shuffledArray = [];
    var tempAnswers = a.slice();
    if (additionalElements) tempAnswers = tempAnswers.concat(additionalElements.slice());
    var indexes = [];
    var numOfElements = tempAnswers.length;

    //shuffling the elements together with the odd elements
    for (i = numOfElements - 1; i >= 0; i--) {
        j = Math.floor(Math.random() * (i + 1));
        shuffledArray.push(tempAnswers[j]);
        tempAnswers.splice(j, 1);
    }

    tempAnswers = shuffledArray.slice();
    // we give back the indexes of the true answer elements
    for (i = 0; i < a.length; i++) {
        if (!additionalElements || (additionalElements && additionalElements.indexOf(a[i]) == -1)) {
            let j = tempAnswers.indexOf(a[i]);
            indexes.push(j);
            // we need to handle duplicate elements
            tempAnswers[j] = "#undefined";
        }
    }

    var shuffleResult: ShuffleResult = { answers: shuffledArray, indexes: indexes };
    return shuffleResult;
}

export function GetAllIndexes(arr: any, val: any) {
    var indexes = [], i = -1;
    while ((i = arr.indexOf(val, i + 1)) != -1) {
        indexes.push(i);
    }
    return indexes;
}




