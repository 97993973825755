import * as React from 'react';
import { match } from 'react-router';
import * as queryString from 'query-string';
import { SurveySummaryComponent } from './SurveySummaryComponent';

interface SurveySummaryPageState
{

}
type SurveySummaryPageProps = {
    match: match<{ survey_id: string, recId: string, table_info_id: string }>;
    location: Location;
}
export class SurveySummaryPage extends React.Component<SurveySummaryPageProps, SurveySummaryPageState> {
    render() { 
        return <div className="row expanded">        
            <SurveySummaryComponent 
                survey_id={parseInt(this.props.match.params.survey_id)}
              />
        </div>;
    }
}

