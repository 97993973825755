import * as React from 'react';
import 'react-table/react-table.css'
import { Tabs, TabList, Tab, TabPanel } from 'react-tabs';
import PermissionAuditCrudFinder from './sys_perm_audit_crud_finder_raw';
import PermissionAuditViewFinder from './sys_perm_audit_view_finder_raw';
import { __ } from '@src/translation';

interface IPermissionAuditRawProps {

}

interface IPermissionAuditRawState {
}

export default class PermissionAuditRaw extends React.Component<IPermissionAuditRawProps, IPermissionAuditRawState> {
    constructor(props: IPermissionAuditRawProps) {
        super(props);
        this.state = {};
    }

    render() {
        return <div className="row expanded">
        <div className="column small-12 large-12">
            <p className="callout primary">
                {__("Az elemi jog kiosztásokat lehet itt megkeresni.")}
                {__("Az elemi kiosztott jogok azok amiket közvetlenül fölvett valaki az adatbázisba. Ezek nincsenek összevonva vagy csoportosítva (felhasználónként, csoportonként, táblázatonként vagy bármilyen más módon)")}
                </p>
        </div>
        <div className="column small-12 large-12">
            <Tabs>
                <TabList>
                    <Tab><i className="fa fa-table" />{__("Elemi CRUD")}</Tab>
                    <Tab><i className="fa fa-eye" />{__("Elemi View")}</Tab>
                </TabList>
                <TabPanel>
                    <PermissionAuditCrudFinder />
                </TabPanel>
                <TabPanel>
                    <PermissionAuditViewFinder />
                </TabPanel>
            </Tabs>
        </div>
    </div>
    }

}

