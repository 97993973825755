import * as React from 'react';
import { Link } from 'react-router-dom';
import { PATH_EXERCISE_LIST, PATH_EXERCISE_SERIES_LIST, PATH_EDIT_BOOK_LIST, PATH_LEARNING_PATH, getPath } from '@src/Routes';
import { __ } from '@src/translation';
import { exerciseModule } from '@src/module/exerciseModule/exerciseModule';

export type BreadcrumbsLink = { name: any; path?: string };

export type BreadcrumbsProps = { links: BreadcrumbsLink[] };

export class Breadcrumbs extends React.Component<BreadcrumbsProps, any> {
    render() {
        const items = this.props.links.map((link, index) => {
            if (link.path) {
                return <li key={index} style={{ fontSize: 'medium', textTransform: 'none'}}><Link to={link.path} >{link.name}</Link></li>;
            } else {
                return <li key={index} style={{ fontSize: 'medium', textTransform: 'none'}}>{link.name}</li>;
            }
        });

        return <ul className="breadcrumbs" style={{ margin: '1em' }}>{items} </ul>;
    }

}

export class BookBreadcrumbs extends React.Component<BreadcrumbsProps, any> {
    render() {
        const links = this.props.links;
        links.unshift({ name: <span><i className="fa fa-book" />{" " + __("Tankönyv lista")}</span>, path: PATH_EDIT_BOOK_LIST });
        return <Breadcrumbs links={links} />
    }

}

export class ExerciseBreadcrumbs extends React.Component<BreadcrumbsProps, any> {
    render() {
        const links = this.props.links;
        links.unshift({ name: <span><i className="fa fa-check-square-o" />{__("Feladatlista")}</span>, path:  getPath({PATH_EXERCISE_LIST}, exerciseModule)});
        return <Breadcrumbs links={links} />
    }
}

export class ExerciseSeriesBreadcrumbs extends React.Component<BreadcrumbsProps, any> {
    render() {
        const links = this.props.links;
        links.unshift({ name: <span><i className="fa fa-check-square-o" />{__("Feladatsorlista")}</span>, path: getPath({PATH_EXERCISE_SERIES_LIST}, exerciseModule) });
        return <Breadcrumbs links={links} />
    }
}

export class LearningPathBreadcrumbs extends React.Component<BreadcrumbsProps, any> {
    render() {
        const links = this.props.links;
        links.unshift({ name: <span><i className="fa fa-check-square-o" />{__("Tanulási útvonalaim")}</span>, path:getPath({PATH_LEARNING_PATH}, exerciseModule) });
        return <Breadcrumbs links={links} />
    }
}