import * as React from 'react';
import { DAY_TYPE_NAMES, DAY_TYPE_NON_WORKING_DAY_ID, DAY_TYPE_WORKDAY_ID, DAY_TYPE_RED_LETTER_DAY_ID, DAY_OF_WEEK_LONG_NAMES } from '../calendar//CalendarAPI';
import { app } from '@src/index';
import { debounce } from 'lodash';
import DayCrud, { IDayRecord } from '@src/framework/crud/sys/DayCrud';
import { BubbleLoader } from 'react-css-loaders';
import { confirmDialog } from '../Dialog';
import { CalendarSource } from '../../store/CalendarSource';
import { formatDateWithoutTime } from '@src/Util';
import { parse } from 'date-fns';
import { observer } from 'mobx-react';
import { observable, computed, reaction, IReactionDisposer, action, runInAction } from 'mobx';
import { __ } from '@src/translation';

export interface ISysDayItemProps {
    calendarMonthSource: CalendarSource;
    onItemSaved: (item: IDayRecord) => void;
    onItemDeleted: (item: IDayRecord) => void;
}


@observer
export class SysDayItem extends React.PureComponent<ISysDayItemProps> {
    private _debouncedAsyncReload: () => void;
    private reloadDisposer : IReactionDisposer | null = null;

    @observable private loading: boolean = false;
    @observable private saving: boolean = false;
    @observable private item: IDayRecord | null = null;

    @computed get calendarSource() { return this.props.calendarMonthSource; }
    @computed get selectedItem() { return this.calendarSource.selectedItem; }
    @computed private get hasChange () { return this.calendarSource.hasChange; }

    private set hasChange (value:boolean) {
        runInAction(() => { this.calendarSource.hasChange = value;} )        
    }


    @action.bound private setLoading() { this.loading = true; }
    @action.bound private clearLoading() { this.loading = false; }
    @action.bound private setSaving() { this.saving = true; }
    @action.bound private clearSaving() { this.saving = false; }


    constructor(props: ISysDayItemProps) {
        super(props);
        this._debouncedAsyncReload = debounce(this.asyncReload, 100);
    }    

    public componentDidMount() {
        this.asyncReload();
        // Reload when the selected item is changed.
        this.reloadDisposer = reaction(() => this.selectedItem, this.reload);
    }

    public componentWillMount() {
        if (this.reloadDisposer!==null) { this.reloadDisposer(); }
    }

    @action.bound public reload() {
        this.setLoading();
        this._debouncedAsyncReload();
    }

    private asyncReload = async () => {
        this.setLoading();
        try {
            let item: IDayRecord | null = null;
            const selectedItem = this.selectedItem;
            if (selectedItem) {
                const day = selectedItem.date;
                const defaultDayTypeId = (day.getDay() == 0)
                    ? DAY_TYPE_NON_WORKING_DAY_ID
                    : (day.getDay() == 6 ? DAY_TYPE_NON_WORKING_DAY_ID : DAY_TYPE_WORKDAY_ID);
                item = { day: selectedItem.date.getTime() as any, day_type_id: defaultDayTypeId };
                const dayItems = selectedItem ? await DayCrud.list({ filter: { day: selectedItem.date.getTime() } }) : [];
                if (dayItems.length > 0) {
                    item = dayItems[0];
                    if (!item.is_active) {
                        item.is_active = true;
                        item.day_type_id = defaultDayTypeId;
                        item.description = "";
                    }
                }
            }
            runInAction(() => {
                this.item = item;
                this.loading = false;
                this.hasChange = false;
            })
        } catch (error) {
            this.clearLoading();
            this.hasChange = false;
            app.showErrorFromJsonResult(error);
        }
    }

    @action.bound private onChangeDayType (dayTypeId: number) {
        const item = this.item!;
        item.day_type_id = dayTypeId;
        this.hasChange = true;
    }

    @action.bound onChangeDayDescription (description: string) {
        const item = this.item!;
        item.description = description;
        this.hasChange = true;
    }

    private saveItem = async () => {
        try {
            this.setSaving();
            const item = (await new DayCrud(this.item!).put()).record;
            runInAction(() => {
                this.saving = false;
                this.item = item;
            })
            this.hasChange = false;
            this.props.onItemSaved(item);
            app.showSuccess(__("Mentés sikeres"), null);
        } catch (error) {
            this.clearSaving();
            app.showErrorFromJsonResult(error);
        }
    }

    private deleteItem = async () => {
        if (await confirmDialog(__("Megerősítés"),__( "Biztos benne, hogy törli ezt a napot?"))) {
            try {
                this.setSaving();
                const item = this.item!;
                await DayCrud.deleteById(item.id!);
                this.clearSaving();
                this.props.onItemDeleted(item);
                this.asyncReload(); // TODO: do we need this? Really???
                app.showSuccess(__("Törlés sikeres"), null);
            } catch (error) {
                app.showErrorFromJsonResult(error);
            }
        }
    }


    private renderEditor = () => {
        const item = this.item!;
        const selector = <select
            value={item.day_type_id!}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                this.onChangeDayType(parseInt(e.target.value));
            }}
        >
            <option value={DAY_TYPE_WORKDAY_ID}>{DAY_TYPE_NAMES[DAY_TYPE_WORKDAY_ID]}</option>
            <option value={DAY_TYPE_NON_WORKING_DAY_ID}>{DAY_TYPE_NAMES[DAY_TYPE_NON_WORKING_DAY_ID]}</option>
            <option value={DAY_TYPE_RED_LETTER_DAY_ID}>{DAY_TYPE_NAMES[DAY_TYPE_RED_LETTER_DAY_ID]}</option>
        </select>


        const dow = parse(item.day!).getDay();

        return <div className="row expanded">
            <div className="column small-12"><h5>{formatDateWithoutTime(item.day)} {DAY_OF_WEEK_LONG_NAMES[dow]}</h5></div>
            <div className="column small-12">
                <label>{__("Típus:")}
                {selector}</label></div>
            <div className="column small-12">
                <label>{__("Leírás:")}
                <input type="text" value={item.description || ""}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            this.onChangeDayDescription(e.target.value)
                        }
                        } /></label>
            </div>


            <div className="column small-12">
                <button className="button primary" disabled={!this.hasChange}
                    onClick={this.saveItem}
                ><i className="fa fa-save" />{" " + __("Mentés")}</button>
                <button className="button alert" disabled={!item.id}
                    onClick={this.deleteItem}><i className="fa fa-delete" />{" " + __("Töröl")}</button>
            </div>
        </div>

    }

    public render() {
        const item = this.item;
        if (!item) { return null; }
        if (this.loading) {
            return <BubbleLoader />;
        } else {
            return this.renderEditor();
        }
    }
}
