import * as React from "react";
import { IOutgoingMessage } from "../messengerAPI";
import SecUserCrud from "@src/framework/crud/sys/SecUserCrud";
import { me } from "@src/framework/server/Auth";
import { __ } from "@src/translation";
import { Dialog, DialogContent } from "@src/component/Dialog";
import { HTMLTextarea } from "@src/component/HtmlTextarea";
import { history, app } from '@src/index';
import { whiteSpaceChecker } from "@src/component/WhiteSpaceChecker";
import { messengerModule } from "../messengerModule";
import SecUserSelector from "@src/framework/forms/SecUserSelector";
import { MemberCardFromId } from "@src/framework/forms/MemberCard";
import ViewKapCourseUser, { IViewKapCourseUserRecord } from "@src/framework/view/kap/ViewKapCourseUser";

interface NewMessagePopUpState {
    currentMessage?: IOutgoingMessage,
    sending: boolean,
    validationMessages: Map<string, string>,
    selectedUserId: number | null,
    recipients?: { fullName: string, loginName: string, id: number }[],
}
interface NewMessagePopUpProps {
    tableInfoId?: number,
    recordId?: number,
    recipientIds?: number[],
    isOpen: boolean,
    memberCard?: boolean,
    onClose: () => void
}

export default class NewMessagePopUp extends React.Component<NewMessagePopUpProps, NewMessagePopUpState> {
    constructor(props: any) {
        super(props);
        let validationMap: Map<string, string> = new Map<string, string>();
        let newMessage: IOutgoingMessage = { subject: "", body_html: "", reply_to_id: null, recipient_ids: [] }
        this.state = {
            currentMessage: newMessage,
            sending: false,
            validationMessages: validationMap,
            selectedUserId: null
        }
    }

    componentWillReceiveProps() {
        if (this.props.recipientIds) {
            let recipients: { fullName: string, loginName: string, id: number }[] = [];
            let members = this.props.recipientIds!.map(async r => {
                let member = await ViewKapCourseUser.list({ filter: { sec_user_id: r } });

                return member;
            });
            Promise.all(members).then(results => results.map((m) => {
                recipients.push({ fullName: m[0].participant_fullname!, loginName: m[0].participant_login_name!, id: m[0].sec_user_id! });
            })).then(r => this.setState({ recipients }));
            // this.setState({ recipients });
        }
    }

    async onSend() {
        this.setState({ sending: true });
        let validationMessages = new Map<string, string>();

        if (!this.state.currentMessage || !me) return;
        let error_message = "";

        if (this.props.recipientIds && this.props.recipientIds.length > 0) {
            this.state.currentMessage.recipient_ids = this.props.recipientIds;
        }
        if (this.state.currentMessage.recipient_ids.length < 1) {
            error_message = __("Legalább egy címzett kötelező!");
            app.showError(__("Hiba"), error_message);
            validationMessages.set("recipents", error_message);
        }

        if (!whiteSpaceChecker(this.state.currentMessage.subject)) {
            error_message = __("Az üzenet tárgya kötelező!");
            app.showError(__("Hiba"), error_message);
            validationMessages.set("subject", error_message);
        }

        if (!whiteSpaceChecker(this.state.currentMessage.body_html)) {
            error_message = __("Az üzenet szövege kötelező!");
            app.showError(__("Hiba"), error_message);
            validationMessages.set("body_html", error_message);
        }

        if (validationMessages.size > 0) {
            this.setState({ sending: false, validationMessages: validationMessages });
            return;
        }


        let t_id = this.props.tableInfoId || null;
        let r_id = this.props.recordId || null;
        await messengerModule.pushMessage(t_id, r_id, null, this.state.currentMessage!);

        app.showSuccess(__("Elküldve"), __("Az üzenet elküldve"));
        this.props.onClose();
        history.push(location.pathname);
        let emptyMsg = { subject: "", body_html: "", reply_to_id: null, recipient_ids: [] }
        this.setState({
            sending: false,
            validationMessages: new Map<string, string>(),
            currentMessage: emptyMsg
        });
    }

    onPropertyChanged(propertyName: string, event: any) {
        let newMsg = this.state.currentMessage;
        newMsg![propertyName] = event.target ? event.target.value : event;
        this.setState({ currentMessage: newMsg });
    }

    private removeRecipient(recipient_id: number) {
        let tmp_msg = this.state.currentMessage;
        const idx = tmp_msg!.recipient_ids.indexOf(recipient_id);
        if (idx >= 0) {
            tmp_msg!.recipient_ids.splice(idx, 1);
        }
        this.setState({ currentMessage: tmp_msg });
    }

    private addRecipient(selectedUserId: number | null) {
        if (!selectedUserId) return;
        let tmp_msg = this.state.currentMessage;
        if (tmp_msg!.recipient_ids.includes(selectedUserId)) {
            app.showError(__("Hiba"), __("Az adott személy már szerepel a címzettek között!"));
            return;
        }
        tmp_msg!.recipient_ids.push(selectedUserId);
        this.setState({ currentMessage: tmp_msg });
    }

    render() {
        let currMessage = this.state.currentMessage;
        let recipients = this.props.recipientIds && this.state.recipients ? this.state.recipients : undefined;
        if (!currMessage || !me) return "";
        return <Dialog hasFullScreenButton={true} outsideWindowClickClose={false} width={800} height={600} open={this.props.isOpen} title={__("Új üzenet")} onClose={this.props.onClose}>
            <DialogContent>
                <div>
                    <label>{__("Címzett")} <span style={{ color: "#b30000" }}>*</span><span className="exe-editor-validation-msg">{this.state.validationMessages.get("recipents")}</span>
                        {(this.props.recipientIds && this.props.recipientIds.length > 0) ?
                            <div>
                                <ul>
                                    {this.props.memberCard != false &&
                                        this.props.recipientIds.map((recipient_id) => {
                                            return <li key={"recipient_" + recipient_id} >
                                                <MemberCardFromId memberId={recipient_id} medium={true} />
                                            </li>
                                        })

                                    }
                                    {this.props.memberCard == false && this.state.recipients && this.state.recipients.length > 0 && this.state.recipients.map((r) => {
                                        return <li key={"recipient_" + r.id} >
                                            <span>{r.fullName + " <" + r.loginName + ">"}</span>
                                        </li>
                                    })}
                                </ul>
                            </div>
                            : <div>
                                <div style={{ display: "flex", flexDirection: "row" }}>
                                    <div style={{ width: "100%" }}>
                                        <SecUserSelector
                                            value={this.state.selectedUserId || null}
                                            clearable={true}
                                            placeholder={__("Címzett hozzáadása...")}
                                            onChange={(selectedUserId: number | null) => { this.addRecipient(selectedUserId) }}
                                            medium={true}
                                        />
                                    </div>
                                    {/* <div >
                                        <button className="button small"
                                            disabled={false}
                                            onClick={this.addRecipient.bind(this)}><i className="fa fa-save"></i></button>
                                    </div> */}
                                </div>
                                <div className="recipient-list-container">
                                    <ul>
                                        {
                                            currMessage.recipient_ids.map((recipient_id) => {
                                                return <li key={"recipient_" + recipient_id} >
                                                    <MemberCardFromId memberId={recipient_id} medium={true} />
                                                    <i className="fas fa-times" title={__("Eltávolít")} onClick={() => this.removeRecipient(recipient_id)} />
                                                </li>
                                            })
                                        }
                                    </ul>
                                </div>
                            </div>
                        }
                    </label>

                    <label>{__("Tárgy")} <span style={{ color: "#b30000" }}>*</span><span className="exe-editor-validation-msg">{this.state.validationMessages.get("subject")}</span>
                        <input type="text"
                            value={currMessage.subject}
                            onChange={this.onPropertyChanged.bind(this, "subject")} />
                    </label>
                    <label>{__("Üzenet")} <span style={{ color: "#b30000" }}>*</span><span className="exe-editor-validation-msg">{this.state.validationMessages.get("body_html")}</span>
                        <HTMLTextarea
                            value={currMessage.body_html || ""}
                            onChange={this.onPropertyChanged.bind(this, "body_html")}
                            tools={"full"}
                        />
                    </label>
                    <div>
                        <button className="button success eke-general-buttons"
                            onClick={this.onSend.bind(this)}>
                            <i className="fas fa-paper-plane"></i> {__("Elküldés")}
                        </button>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    }

}


export class NewMessageComponent extends React.Component<any, { isOpen: boolean }>{
    constructor(props: any) {
        super(props);
        this.state = {
            isOpen: false,
        }
    }

    public newMsgPopupClosed() {
        this.setState({ isOpen: false });
    }
    public newMsgPopUpOpen() {
        this.setState({ isOpen: true })
    }
    render() {
        return <div>
            <button className="button success eke-general-buttons" onClick={this.newMsgPopUpOpen.bind(this)} >
                <i className="fas fa-plus"></i>{__("Új üzenet")}
            </button>
            <NewMessagePopUp isOpen={this.state.isOpen} onClose={this.newMsgPopupClosed.bind(this)} />
        </div>
    }

}