import * as React from 'react';
import { IWfPluginProps } from './wf_plugin';
import { IViewWfHeadTableRecord } from '@framework/view/wf/ViewWfHeadTable';
import StationCrud, { IStationRecord } from '@framework/crud/wf/StationCrud';
import { IViewTransitionTypeRecord } from '@framework/view/wf/ViewTransitionType';
import ViewWfWorkflow, { IViewWfWorkflowRecord } from '@framework/view/wf/ViewWfWorkflow';
import { app } from '@src/index';
import { me, Groups, hasGroup } from '@src/framework/server/Auth';
import { BubbleLoader } from 'react-css-loaders';
import obtainServer, { Server } from '@framework/server/Server';
import WfAPI, { IViewTransitionTypeRecordWithPerm, SecStationPermissions, SecStationPerm, WfPublicationInfo} from '@framework/wf/WfAPI';
import ViewWfWorkflowAgent, { IViewWfWorkflowAgentRecord, viewWfWorkflowAgentClassProxy } from '@framework/view/wf/ViewWfWorkflowAgent';

import PermListEditor from '@framework/forms/permlisteditor';
import WfTransitionLog from './wf_transition_log';
import { workflowAgentCrudClassProxy } from '@src/framework/crud/wf/WorkflowAgentCrud';

import './wf_head_plugin.css';
import ViewWfTransitionLog, { IViewWfTransitionLogRecord } from '@src/framework/view/wf/ViewWfTransitionLog';
import TransitionTypeCrud, { ITransitionTypeRecord } from '@src/framework/crud/wf/TransitionTypeCrud';
import ViewSubstation from '@src/framework/view/wf/ViewSubstation';
import { ISubstationRecord } from '@src/framework/crud/wf/SubstationCrud';
import { Dialog } from '../Dialog';
import ReactTable from 'react-table';
import { getReactTableLabels } from '@src/framework/i18n';
import { __ } from '@src/translation';
import WfTypeGraph from '@src/component/wf/wf_type_graph';

export type WfHeadLoadedEvent = (sender: WfHeadPlugin) => void;
export type WfHeadAfterTransitionEvent = (
    sender: WfHeadPlugin,
    workflow: IViewWfWorkflowRecord,
    transitionType: IViewTransitionTypeRecord
) => void;

export enum DisplayMode{
    All,
    WorkflowChart,
    Details,
    History
}


export interface IWfHeadPluginProps extends IWfPluginProps {
    head: IViewWfHeadTableRecord;
    onLoaded?: WfHeadLoadedEvent;
    afterTransition?: WfHeadAfterTransitionEvent;
    server?: Server;
    wfApi?: WfAPI;
    displayMode: DisplayMode;
}


export interface IWfTimeLineItem {
    station: IStationRecord;
    who?: string | null;
    when?: string;
}


export interface IWfHeadPluginState {
    /*
     * Ennél a State-nél megkülönböztetjük az undefined-ot és a null-t.
     * Az undefined azt jelenti, hogy még nem tudjuk hogy van-e értéke (még nem töltöttük be).
     * A null azt jelenti, hogy megpróbáltuk betölteni az értékét, és kiderült hogy
     * nincs neki.
     * 
     * Ez a megkülönböztetés azért fontos mert pl. nem mindegy hogy "még nem töltöttük be a workflowt"
     * vagy hogy "nincs neki workflow-ja". Ugyan ez igaz a többi attribútumra is!
     * 
     */

    workflow?: IViewWfWorkflowRecord | null; /** Az aktuálisan beltöltött workflow */
    agents?: IViewWfWorkflowAgentRecord[]; /** A workflow felelősei */
    startStations?: IStationRecord[]; /** A lehetséges induló állomások. Ha a folyamat már el van indítva, akkor üres. */
    possibleTransitions?: IViewTransitionTypeRecordWithPerm[]; /** A lehetséges átmenet típusok. Ha a folyamat még nincs elindítva, akkor üres. */
    stationPermissions?: SecStationPermissions;
    justification: string;
    log_counter: number;
    log: IViewWfTransitionLogRecord[];

    timeLine: IWfTimeLineItem[]; // Idővonal: kiegyenesített" útvonal, ami felül jelenik meg
    timeLineIndex: number;      // Az éppen aktuális állapot indexe az idővonalon,

    subStationChanged: boolean;
    statusTextChanged: boolean;
    showPublishHistory: boolean;

    publishing: boolean;
    unpublishing: boolean;
    publicationInfo?: WfPublicationInfo;
}

const EMPTY_STATE: IWfHeadPluginState = {
    workflow: null, agents: [], justification: '',
    log: [], log_counter: 0,
    timeLine: [], timeLineIndex: -1,
    subStationChanged: false,
    statusTextChanged: false,
    showPublishHistory: false,
    publishing: false,
    unpublishing: false
}


/**
 * Megjeleníti és manipulálja egy konkrét rekordhoz tartozó konkrét típusú folyamatot.
 */
export default class WfHeadPlugin extends React.Component<IWfHeadPluginProps, IWfHeadPluginState> {
    private server: Server;
    private wfAPI: WfAPI;

    private stations?: IStationRecord[] = undefined;
    private activeStations?: IStationRecord[] = undefined;
    private substations?: ISubstationRecord[] = undefined;
    private activeTransitions?: ITransitionTypeRecord[] = undefined;
    private station_by_id: { [station_id: number]: IStationRecord } = {};
    private active_station_by_id: { [station_id: number]: IStationRecord } = {};
    private substation_by_id: { [substation_id: number]: ISubstationRecord } = {};
    private substations_by_station_id: { [station_id: number]: ISubstationRecord[] } = {};


    constructor(props: IWfHeadPluginProps) {
        super(props);
        this.server = props.server || obtainServer();
        this.wfAPI = props.wfApi || new WfAPI(this.server);

        this.state = { ...EMPTY_STATE };
    }

    componentWillMount() {
        this.reload();
    }

    private reload = async (): Promise<void> => {
        let newState: IWfHeadPluginState = { ...EMPTY_STATE, log_counter: this.state.log_counter + 1 };
        let timeLine: IWfTimeLineItem[] = [];
        let timeLineIndex = -1;

        try {
            if (this.props.recId) {
                const workflows = await ViewWfWorkflow.list({
                    filter: { head_table_id: this.props.head.id, rec_id: this.props.recId }
                });
                if (workflows.length) {
                    const workflow = workflows[0];

                    if (this.stations === undefined) {
                        this.stations = await StationCrud.list({
                            filter: {
                                wf_type_id: workflow.wf_type_id!
                                /*, is_active: true */
                            },
                            order_by: 'no'
                        });
                        this.activeStations = [];
                        this.stations.forEach((station) => {
                            this.station_by_id[station.id!] = station;
                            if (station.is_active) {
                                this.activeStations!.push(station);
                                this.active_station_by_id[station.id!] = station;
                            }
                        })
                    }
                    if (this.activeTransitions === undefined) {
                        this.activeTransitions = await TransitionTypeCrud.list({
                            filter: {
                                type_id: workflow.wf_type_id!, is_active: true
                            }
                        });
                    }
                    const agents = await ViewWfWorkflowAgent.list({
                        filter: { workflow_id: workflow.id! }
                    });
                    const log = await ViewWfTransitionLog.list({
                        filter: { workflow_id: workflow.id! },
                        order_by: [
                            { name: "creation_time", desc: true },
                            { name: "id", desc: true },
                        ],
                    });
                    const possibleTransitions = await this.wfAPI.listPossibleTransitions(
                        workflow!.id!);

                    if (this.substations === undefined) {
                        this.substations = await ViewSubstation.list({
                            filter: {
                                station_id:
                                    this.stations.map((station) => station.id!)
                            },
                            order_by: "name"
                        });
                        this.substation_by_id = {};
                        this.substations_by_station_id = {};
                        this.substations.forEach((substation) => {
                            this.substation_by_id[substation.id!] = substation;
                            let items = this.substations_by_station_id[substation.station_id!] || [];
                            items.push(substation);
                            this.substations_by_station_id[substation.station_id!] = items;
                        })
                    }

                    const stationPermissions = await this.wfAPI.getStationPermissions(workflow.wf_type_id!);

                    const publicationInfo = await this.wfAPI.getPublicationInfo(workflow.id!);


                    /*
                    
                    Melinda kéri: ez ne history legyen, hanem a normál ügymenet útvonala,
                    az aktuális állapoton keresztül.  Feltéve hogy van ilyen.

                    Ezt próbáljuk:  a kezdő állapotig megyünk visszafelé az olyan aktív
                    átmeneteken, amiknél nem kötelező indoklást megadni.
                    */

                    let dst_station_id = workflow.station_id!;
                    let added = 0;
                    const station = this.station_by_id[workflow.station_id!];
                    let reachedStart = station.is_start!;
                    timeLine.push({ station });
                    while (added < 5 && timeLine.length < 6 && !reachedStart) {
                        let found = false;
                        for (let i = 0; i < this.activeTransitions.length; i++) {
                            let transition = this.activeTransitions[i];
                            if (transition.dst_station_id! == dst_station_id && !transition.must_justify!) {
                                const src_station_id = transition.src_station_id!;
                                const srcStation = this.station_by_id[src_station_id];
                                if (srcStation && srcStation.is_start) { // ez csak akkor lehet undefined, ha közben az állapotot törölték
                                    timeLine.push({ station: srcStation });
                                    dst_station_id = src_station_id;
                                    found = true;
                                    if (srcStation.is_start) {
                                        reachedStart = true;
                                    }
                                    break;
                                }
                            }
                        }
                        // Ilyen nincs, de talán van olyan ami nem kezdő állapot
                        if (!found) {
                            for (let i = 0; i < this.activeTransitions.length; i++) {
                                let transition = this.activeTransitions[i];
                                if (transition.dst_station_id! == dst_station_id && !transition.must_justify!) {
                                    const src_station_id = transition.src_station_id!;
                                    const srcStation = this.station_by_id[src_station_id];
                                    if (srcStation) { // ez csak akkor lehet undefined, ha közben az állapotot törölték
                                        timeLine.push({ station: srcStation });
                                        dst_station_id = src_station_id;
                                        found = true;
                                        if (srcStation.is_start) {
                                            reachedStart = true;
                                        }
                                        break;
                                    }
                                }
                            }
                        }


                        // Ilyen nincs, de talán van olyan ahol kötelező indoklást megadni
                        if (!found) {
                            for (let i = 0; i < this.activeTransitions.length; i++) {
                                let transition = this.activeTransitions[i];
                                if (transition.dst_station_id! == dst_station_id) {
                                    const src_station_id = transition.src_station_id!;
                                    const srcStation = this.station_by_id[src_station_id];
                                    if (srcStation) { // ez csak akkor lehet undefined, ha közben az állapotot törölték
                                        timeLine.push({ station: srcStation });
                                        dst_station_id = src_station_id;
                                        found = true;
                                        if (srcStation.is_start) {
                                            reachedStart = true;
                                        }
                                        break;
                                    }
                                }
                            }
                        }
                        if (found) {
                            added += 1;
                        } else {
                            break;
                        }
                    }
                    /*                    
                    Ha nem sikerült elérni kezdő állapotot, akkor nem próbálkozunk tovább.
                    Helyette átváltunk a history kijelzésére.          
                    Végigmegyünk a naplón egészen addig, amíg elő nem áll legalább 3 "régi" állapot.
                    */
                    if (!reachedStart) {
                        timeLine = [];
                        for (let i = 0; i < log.length; i++) {
                            const station_id = log[i].dst_station_id;
                            if (station_id) {
                                timeLine.push({
                                    station: this.station_by_id[station_id],
                                    who: log[i].creator,
                                    when: log[i].creation_time
                                });
                                if (timeLine.length >= 3) {
                                    break;
                                }
                            }
                        }
                    }

                    timeLine.reverse();
                    timeLineIndex = timeLine.length - 1;


                    // Hozzáadjuk a következő jövőbelieket addig, amíg el nem érünk
                    // egy lezárt állapotot, vagy túl sok nem lesz belőle.
                    // A jövőbeli állapotoknál már csak az aktívakat nézzük.
                    // (A múltbelinél az inaktívakat is nézni kellett.)
                    let src_station_id = workflow.station_id!;
                    const currentStation = this.station_by_id[workflow.station_id!];
                    // Ez egy kis csalás - ha lezárt DE NEM AKTÍV állapotban vagyunk,
                    // akkor mutatjuk hogy hogyan lehet innen kimenekülni.
                    let reachedClosed = currentStation && currentStation.is_closed && currentStation.is_active;
                    while (added < 10 && timeLine.length < 6 && !reachedClosed) {
                        let found = false;
                        // Átvisszük a következő lezártba.
                        for (let i = 0; i < this.activeTransitions.length; i++) {
                            let transition = this.activeTransitions[i];
                            if (transition.src_station_id! == src_station_id && !transition.must_justify!) {
                                const dst_station_id = transition.dst_station_id!;
                                const dstStation = this.active_station_by_id[dst_station_id];
                                if (dstStation) {
                                    if (dstStation.is_closed) {
                                        timeLine.push({ station: dstStation });
                                        src_station_id = dst_station_id;
                                        found = true;
                                        if (dstStation.is_closed) {
                                            reachedClosed = true;
                                        }
                                        break;
                                    }
                                }
                            }
                        }
                        // Ilyen nincs, akkor egy következő akármilyenbe aminél nem kötelező indoklást megadni.
                        for (let i = 0; i < this.activeTransitions.length; i++) {
                            let transition = this.activeTransitions[i];
                            if (transition.src_station_id! == src_station_id && !transition.must_justify!) {
                                const dst_station_id = transition.dst_station_id!;
                                const dstStation = this.active_station_by_id[dst_station_id];
                                if (dstStation) {
                                    timeLine.push({ station: dstStation });
                                    src_station_id = dst_station_id;
                                    found = true;
                                    if (dstStation.is_closed) {
                                        reachedClosed = true;
                                    }
                                    break;
                                }
                            }
                        }

                        // Ilyen nincs, de talán van olyan ahol kötelező indoklást megadni
                        if (!found) {
                            for (let i = 0; i < this.activeTransitions.length; i++) {
                                let transition = this.activeTransitions[i];
                                if (transition.src_station_id! == src_station_id) {
                                    const dst_station_id = transition.dst_station_id!;
                                    const dstStation = this.active_station_by_id[dst_station_id];
                                    if (dstStation) {
                                        timeLine.push({ station: dstStation });
                                        src_station_id = dst_station_id;
                                        found = true;
                                        if (dstStation.is_closed) {
                                            reachedClosed = true;
                                        }
                                        break;
                                    }
                                }
                            }
                        }

                        if (found) {
                            added += 1;
                        } else {
                            break;
                        }
                    }
                    newState = {
                        ...newState, workflow, agents, log, justification: '',
                        possibleTransitions, stationPermissions, publicationInfo, timeLine, timeLineIndex,
                        publishing: false, unpublishing: false
                    };
                } else {
                    // Nincs workflow, új készítéséhez a kezdő állapotokat listázzuk.
                    newState.startStations = await StationCrud.list({
                        filter: {
                            is_active: true, wf_type_id: this.props.head.wf_type_id!,
                            is_start: true, publicationInfo: undefined
                        }
                    });
                }
            }

            this.setState(newState, this._onLoaded)


            return Promise.resolve();
        } catch (error) {
            app.showErrorFromJsonResult(error);
            return Promise.reject(error);
        };

    }

    /** Event emitter */
    private _onLoaded = () => {
        if (this.props.onLoaded) {
            this.props.onLoaded(this);
        }
    }

    protected onStartWorkflow(startStation: IStationRecord) {
        this.wfAPI.startWorkflowRaw(
            this.props.head.id!,
            this.props.recId!,
            startStation.id!
        ).then((workflowRecord) => { this.reload(); })
            .catch((error) => app.showErrorFromJsonResult(error));
    }

    private onClickCurrentStation = () => {

    }

    private onClickTransitionType = (transitionType: IViewTransitionTypeRecord) => {
        this.wfAPI.makeTransition(
            this.state.workflow!.id!,
            transitionType.id!,
            this.state.justification
        ).then((workflowRecord) => {
            this.reload().then(() => {
                this.afterTransition(transitionType);
            });
        })
            .catch((error) => app.showErrorFromJsonResult(error));
    }

    private afterTransition = (transitionType: IViewTransitionTypeRecord) => {
        if (this.props.afterTransition) {
            this.props.afterTransition(this, this.state.workflow!, transitionType);
        }

    }

    private onJustificationChange = (event: any) => {
        this.setState({ justification: event.target.value });
    }

    private onJustificationKeyPress = (e: any) => {
        if (e.key === 'Enter') {
            this.onSendComment();
        }
    }


    private onSendComment = () => {
        this.wfAPI.addComment(
            this.state.workflow!.id!,
            this.state.justification.trim()
        ).then((workflowRecord) => {
            app.showInfo(__("Sikeres művelet"), __("Megjegyzés hozzáadva."));
            this.reload();
        })
            .catch((error) => app.showErrorFromJsonResult(error));
    }

    private onPermOwnerAdded = (permOwnerId: number) => {
        this.setState({ log_counter: this.state.log_counter + 1 });
        WfAPI.notifyAgentAdded(this.state.workflow!, permOwnerId);
    }

    private onPermOwnerRemoved = (permOwnerId: number) => {
        this.setState({ log_counter: this.state.log_counter + 1 });
        WfAPI.notifyAgentRemoved(this.state.workflow!, permOwnerId);
    }

    private getProgressStepStyle = (index: number): string => {
        if (this.state.timeLineIndex >= 0) {
            if (index < this.state.timeLineIndex) {
                return "is-complete";
            } else if (index == this.state.timeLineIndex) {
                return "is-current";
            }
        }
        return "";
    }

    private getStation = (): IStationRecord | null => {
        if (this.state.workflow) {
            return this.station_by_id[this.state.workflow!.station_id!] || null;
        } else {
            return null;
        }
    }

    private onSaveStatusText = async () => {
        try {
            await this.wfAPI.updateStatusText(
                this.state.workflow!.id!,
                this.state.workflow!.status_text || null
            );
            const log_counter = this.state.log_counter + 1;
            const log = await ViewWfTransitionLog.list({
                filter: { workflow_id: this.state.workflow!.id! },
                order_by: [{ name: "creation_time", desc: true }],
            });
            this.setState({ log_counter, log, statusTextChanged: false });
            app.showSuccess(__("Sikeres módosítás"),
                __("A szöveges állapot kiegészítés beállítása sikeres volt."));
        } catch (error) {
            app.showErrorFromJsonResult(error);
        }
    }


    private onSaveSubstation = async () => {
        try {
            await this.wfAPI.updateSubstation(
                this.state.workflow!.id!,
                this.state.workflow!.substation_id || null
            );
            const log_counter = this.state.log_counter + 1;
            const log = await ViewWfTransitionLog.list({
                filter: { workflow_id: this.state.workflow!.id! },
                order_by: [{ name: "creation_time", desc: true }],
            });
            this.setState({ log_counter, log, subStationChanged: false });
            app.showSuccess(__("Sikeres módosítás"),
                __("Az al-státusz beállítása sikeres volt."));
        } catch (error) {
            app.showErrorFromJsonResult(error);
        }
    }

    private unPublish = async () => {
        if (!confirm(
            __("Publikálás visszavonása után a tartalom elérhetetlen lesz a nyilvános felületen.") + " " +
            __("A művelet teljes végrehajtásához és az összes nyilvános oldal frissítéséhez néhány percre van szükség.") + " " +
            __("Biztos benne, hogy törli a publikációt?")
        )) {
            return;
        }
        try {
            this.setState({ unpublishing: true });
            await this.wfAPI.unpublish(this.state.workflow!.id!);
            this.reload();
        } catch (error) {
            app.showErrorFromJsonResult(error);
        }
    }

    private publish = async () => {
        if (!confirm(
            __("Publikálás után a jelenlegi szerkesztőségi verzió másolata elérhető lesz a nyilvános felüelten.") + " " +
            __("A művelet teljes végrehajtásához és az összes nyilvános oldal frissítéséhez néhány percre van szükség.") + " " +
            (this.state.publicationInfo!.is_published ?
                __("Biztos benne, hogy újrapublikálja a tartalmat?") : __("Biztos benne, hogy publikálja a tartalmat?")
            )
        )) {
            return;
        }

        try {
            this.setState({ publishing: true });
            await this.wfAPI.publish(this.state.workflow!.id!);
            this.reload();
        } catch (error) {
            app.showErrorFromJsonResult(error);
        }
    }

    private showPublishHistory = () => {
        /*
        const history: WfPublicationHistoryRecord[] = this.state.publicationInfo!.history!;
        console.log('Kornél ezt jelenítsd meg egy dialógus ablakban!', history);
        */
        this.setState({ showPublishHistory: true })
    }

    render() {
        
        

        const key = "wf_head_" + this.props.head.id + "_ " +
            (this.props.recId || 'null');
        const station = this.getStation();
        if (this.state.workflow === undefined) {
            return <div key={key} className="callout secondary">{ __("Kérem várjon") }...  </div>;
        } else if (this.state.workflow === null) {
            const startStations = this.state.startStations;
            if (startStations === undefined) {
                return <div key={key}>{ __("Kezdőállapotok betöltése") }...  <div><BubbleLoader /></div></div>;
            }
            if (startStations.length == 0) {
                return <div key={key} className="callout alert">{ __("Nincs elérhető kezdőállapot, nem lehet folyamatot indítani.") }</div>;
            }
            return <div key={key} className="callout primary">
                { __("Ez a folyamat még nincs elindítva. Gombnyomással elindíthat egy új folyamatot.") }<br />
                {startStations.map((station: IStationRecord, index: number) => {
                    return <button key={station.id!} className="button wf-head-plugin-transition-button" style={station.style} title={station.description}
                        onClick={() => this.onStartWorkflow(station)}>
                        <i className="fa fa-forward"></i>
                        &nbsp;
                            {station.name}
                    </button>
                })}
            </div>;
        } else {
            const workflow: IViewWfWorkflowRecord = this.state.workflow;
            const possibleTransitions = this.state.possibleTransitions;
            const stationPermission: SecStationPerm | undefined = this.state.stationPermissions![workflow.station_id!];

            const creation = <span>
                &nbsp;<b>{ __("Indítva") }:</b> {(workflow.creation_time || '').slice(0, 16)} {workflow.creator}
            </span>;
            const lastModification = workflow.modification_time ?
                <span>
                    &nbsp;<b>{ __("Utolsó módosítás") }:</b> {(workflow.modification_time || '').slice(0, 16)} {workflow.modifier}
                </span>
                : null;

            let agents;
            if (this.state.agents === undefined) {
                agents = <span>{ __("Felelősök betöltése") }...</span>;
            } else {
                if (this.state.agents.length == 0) {
                    agents = __('Nincsenek felelősök.');
                } else {
                    agents = <ul>
                        {this.state.agents.map((agent, index) => {
                            return <li key={agent.id!}>{agent.perm_owner_label}</li>
                        })}
                    </ul>;
                }
            }

            const actualStation =
                <span
                    title={
                        __("Aktuális állapot: {state}", {state: station!.description})
                    }
                >
                    <span className="wf-head-plugin-actual-station"
                        style={station!.style}
                    >
                        {station!.name}
                    </span>
                </span>;

            if (possibleTransitions) {
                const topPart = <>
                    <div className="row expanded wf-head-plugin-row" key="zerothRow">
                        <div className="column small-12 large-12">
                            <ol className="progress-indicator">
                                {
                                    this.state.timeLine.map((item: IWfTimeLineItem, index: number) => {
                                        return <li
                                            className={this.getProgressStepStyle(index)} data-step="" key={"station_" + index}
                                            title={((item.who || "") + " " + (item.when || "")).trim()}
                                        >
                                            <span>{item.station.name!}</span>
                                        </li>
                                    })
                                }
                            </ol>
                        </div>
                    </div>
                    <div className="row expanded wf-head-plugin-row" key="zerothRow">
                        <div className="column small-12 large-12">
                            <WfTypeGraph wfTypeId={this.state.workflow.wf_type_id}></WfTypeGraph>
                        </div>
                    </div>
                    
                </>;

                const permState = <>
                <div className="row expanded wf-head-plugin-row" key="firstRow">
                        <div className="column small-12 large-6" key="actual">
                            <fieldset className="fieldset wf-head-plugin-fieldset">
                                <legend>
                                    {
                                        station!.is_active?__("Állapot"):
                                        <span className="label alert"
                                            title={__("Vigye át a folyamatot egy aktív állapotba!")}
                                        >{__("FIGYELEM! INAKTIVÁLT ÁLLAPOT!")}</span>      
                                    }                              
                                </legend>
                                <ul className="wf-head-plugin-actual">
                                    <li>{actualStation}</li>
                                    <li>{creation}</li>
                                    <li>{lastModification}</li>
                                </ul>
                            </fieldset>
                        </div>
                        <div className="column small-12 large-6" key="permList">
                            <fieldset className="fieldset wf-head-plugin-fieldset">
                                <legend>{ __("Felelősök beállítása (nem kötelező)") }</legend>
                                <PermListEditor
                                    connectorCrud={workflowAgentCrudClassProxy}
                                    connectorView={viewWfWorkflowAgentClassProxy}
                                    masterFieldName="workflow_id"
                                    masterRecordId={this.state.workflow.id!}
                                    onPermOwnerAdded={this.onPermOwnerAdded}
                                    onPermOwnerRemoved={this.onPermOwnerRemoved}
                                    title={__("Felelősök")}
                                    selectorPlaceholder={__("Válasszon felelőst...")}
                                />
                            </fieldset>
                        </div>                       
                    </div>
                </>;

                const substations = this.substations_by_station_id[workflow.station_id!]
                let activeSubStations = substations ? (
                    substations.filter((substation) => (
                        substation.is_active! ||
                        (substation.id && substation.id == workflow.substation_id))
                    )) : null;
                let subStationSelect = null;
                if (activeSubStations && activeSubStations.length > 0 && station) {
                    subStationSelect =
                        <div className="input-group">
                            <span className="input-group-label">{ __("Al-státusz") }</span>
                            <select value={workflow.substation_id || ""} className="input-group-field"
                                onChange={(e: any) => {
                                    let s = ("" + e.target.value).trim();
                                    const substation_id = s ? parseInt(s) : null;
                                    const workflow = { ...this.state.workflow, substation_id }
                                    this.setState({ workflow, subStationChanged: true });

                                }}
                            >
                                {(station!.substation_required && this.state.workflow!.substation_id != null) ? null : <option value="">(-- {__("Nincs al-státusz")} --)</option>}
                                {activeSubStations.map((substation) => <option value={substation.id!}>
                                    {substation.name}
                                </option>)}
                            </select>
                            <div className="input-group-button">
                                <button className="button"
                                    disabled={!this.state.subStationChanged}
                                    title={__("Al-státusz módosítás mentése")}
                                    onClick={this.onSaveSubstation}
                                >
                                    <i className="fa fa-save" />
                                </button>
                            </div>
                        </div>
                }
                const statusText =
                    <div className="input-group">
                        <span className="input-group-label">{__("Szöveges kiegészítés az állapothoz")}</span>
                        <input type="text" className="input-group-field" value={workflow.status_text || ""}
                            onChange={(e: any) => {
                                let workflow = this.state.workflow!;
                                workflow.status_text = e.target.value || null;
                                this.setState({ workflow, statusTextChanged: true });
                            }}
                        />
                        <div className="input-group-button">
                            <button className="button" disabled={!this.state.statusTextChanged}
                                title={__("Szöveges kiegészítés mentése")}
                                onClick={this.onSaveStatusText}
                            >
                                <i className="fa fa-save" />
                            </button>
                        </div>
                    </div>


                // let subStationPart = <div className="row expanded wf-head-plugin-row" key="secondRow">
                //     <div className="column small-12 large-12">
                //         <fieldset className="fieldset wf-head-plugin-fieldset">
                //             <legend>{__("Al-státusz és állapot megjegyzés")}</legend>
                //             {subStationSelect}
                //             {statusText}
                //         </fieldset>
                //     </div>

                // </div>;

                const justificationPart = <div className="row expanded wf-head-plugin-row" key="thirdRow">
                    <div className="column small-12 large-12" key="justification">
                        <input
                            type="text" placeholder={__("Üzenet a résztvevőknek / állapot váltás indoklása")} value={this.state.justification}
                            onChange={this.onJustificationChange}
                            onKeyPress={this.onJustificationKeyPress}
                        />
                    </div>
                    <div className="column shrink" key="sendjust">
                        <button
                            className="button text-right"
                            title={__("Üzenet a folyamatban résztvevőknek")}
                            disabled={this.state.justification.trim().length == 0}
                            onClick={() => this.onSendComment()}>
                            <i className="fa fa-comment"></i>
                            &nbsp;
                            {__("Üzenet a folyamatban résztvevőknek - beküldés")}
                        </button>
                    </div>
                </div>

                // const getTransitionbutton = (transitionType: IViewTransitionTypeRecordWithPerm) => {
                    // const iconCls = "fa " + (transitionType.must_justify ? "fa-backward" : "fa-forward");
                    // return <button
                    //     key={transitionType.id!}
                    //     className={"button wf-head-plugin-transition-button " + (transitionType.must_justify ? "float-left" : "float-right")}
                    //     style={transitionType.dst_station_style}
                       // title={`Következő állomás: ${transitionType.dst_station_name} - ${transitionType.dst_station_description}`}
                    //     title={ __(`Következő állomás: {station_name} - {station_description}`, {station_name:transitionType.dst_station_name, station_description:transitionType.dst_station_description} )}

                    //     disabled={!transitionType.has_perm}
                    //     onClick={
                    //         () => {
                    //             if (transitionType.has_perm) {
                    //                 this.onClickTransitionType(transitionType)
                    //             } else {
                    //                 alert(__('Nincs joga elvégezni ezt az átmenetet.'));
                    //             }
                    //         }
                    //     }>
                    //     <i className={iconCls}></i>
                    //     &nbsp;
                    //             {transitionType.description || transitionType.dst_station_name}
                    // </button>
                // }


                // const transitionButtonsPart = <div className="row expanded align-justify wf-head-plugin-row" key="fourthRow">
                    // <div className="column small-12 large-6">
                        {//possibleTransitions
                            // .filter((transitionType) => { return transitionType.must_justify && transitionType.is_active; })
                            // .map((transitionType: IViewTransitionTypeRecordWithPerm, index: number) => {
                            //     return getTransitionbutton(transitionType)
                            // })
                        }
                    // </div>
                    {/* <div className="column small-12 large-6"> */}
                        {/* {possibleTransitions
                            .filter((transitionType) => { return !transitionType.must_justify && transitionType.is_active; })
                            .map((transitionType: IViewTransitionTypeRecordWithPerm, index: number) => {
                                return getTransitionbutton(transitionType)
                            })
                        } */}
                    {/* </div> */}
                // </div>

                let publishPart = null;
                const publicationInfo = this.state.publicationInfo;

                //if (stationPermission && (stationPermission.can_publish || stationPermission.can_unpublish ))
                if (publicationInfo && publicationInfo.publication_supported) {
                    let currentPublicationInfo;
                    let lastPublicationDate;
                    let historyButton;
                    if (publicationInfo.history && publicationInfo.history.length) {
                        lastPublicationDate = <span>
                            <small>
                                {__("Utoljára publikálva:")} {
                                    publicationInfo.history[0].creation_time.substr(0, 16)
                                }
                                &nbsp;
                                {publicationInfo.history[0].fullname}
                                &nbsp;
                                ({publicationInfo.history[0].login_name})
                            </small>
                        </span>
                        historyButton = <button className="button primary"
                            title={__("Publikációs napló megtekintése")}
                            onClick={() => this.setState({ showPublishHistory: true })}
                        ><i className="fa fa-history" /> &nbsp;{__("Publikációs napló")}</button>;
                    } else {
                        lastPublicationDate = <span>{__("Még sosem volt publikálva.")}</span>
                        historyButton = null;
                    }
                    if (publicationInfo.is_published) {
                        currentPublicationInfo = <span>
                            <span className="label success">{__("Ez a tartalom publikálva van.")}</span>
                            <br /> {lastPublicationDate}
                        </span>
                    } else {
                        currentPublicationInfo = <span>
                            <span className="label secondary">{__("Ez a tartalom jelenleg nincsen publikálva.")}</span>
                            <br /> {lastPublicationDate}
                        </span>;
                    }

                    const isAdmin = hasGroup(me!, Groups.Admin);

                    const hasPublishPerm = isAdmin || (stationPermission && stationPermission.can_publish);
                    const hasUnpublishPerm = isAdmin || (stationPermission && stationPermission.can_unpublish);
                 
                    const canPublish =
                        !this.state.publishing
                        && !this.state.unpublishing
                        && hasPublishPerm;

                    const canUnpublish =
                        !this.state.publishing
                        && !this.state.unpublishing
                        && publicationInfo.is_published
                        && hasUnpublishPerm;

                    publishPart = <div className="row expanded wf-head-plugin-row" key="fifthRow">
                        <div className="column small-12 large-3">
                            <button key="unpublish"
                                className="button wf-head-plugin-transition-button wf-head-plugin-unpublish-button float-left"
                                title={
                                    __("Publikálás megszüntetése (visszavonás) - {details}{permissionCheck}", {
                                        details: (publicationInfo.is_published ? __("nyomja meg, hogy a tartalom elérhetetlen legyen a publikus felületen."): __("jelenleg nincs publikálva, a publikáció nem szüntethető meg.")),
                                        permissionCheck: (hasPublishPerm ? "" : " " + __("Nincsen jogosultsága törölni a publikációt"))
                                    })
                                }
                                onClick={this.unPublish}
                                disabled={!canUnpublish}
                            >
                                <i className="fa fa-eye-slash" />
                                {this.state.unpublishing ? <i className="fa fa-hourglass" /> : null}
                                &nbsp;{__("Publikálás visszavonása")}
                            </button>
                        </div>
                        <div className="column small-12 large-6" style={{ textAlign: "center" }}>
                            {currentPublicationInfo}
                            {/* {historyButton} */}
                        </div>
                        <div className="column small-12 large-3">
                            <button key="publish"
                                className="button wf-head-plugin-transition-button wf-head-plugin-publish-button float-right"
                                title={__("Publikálás - {publicationDetails}{permissionCheck}",{
                                    publicationDetails: (publicationInfo.is_published ? __("nyomja meg, hogy a tartalom elérhető legyen a publikus felületen.") : __("nyomja meg, hogy a jelenleg publikált tartalmat FELÜLÍRJA az aktuális szerkesztőségi verzióval.")),
                                    permissionCheck: (hasPublishPerm ? "" : " " + __("Nincsen jogosultsága publikálni a tartalmat."))
                                })}
                                disabled={!canPublish}
                                onClick={this.publish}
                            >
                                {/* disabled={!stationPermission || !stationPermission.can_publish} */}
                                <i className="fa fa-hand- fa-eye" />
                                {this.state.publishing ? <i className="fa fa-hourglass" /> : null}
                                &nbsp;{
                                    publicationInfo && publicationInfo.is_published?
                                    __("ÚJRA publikálás"):
                                    __("Publikálás")
                                }</button>
                        </div>
                        <div className="column small-12-large-12 medium-12" style={{ textAlign: "center" }}>{historyButton}</div>
                    </div>;
                }

                return (
                    <div className="callout" key={key}>
                        {this.state.showPublishHistory
                            ?
                            <Dialog title={__("A feladatra vonatkozó publikációs napló")} onClose={() => this.setState({ showPublishHistory: false })} width={1000} height={700}>
                                <ReactTable
                                    columns={[
                                        { Header: __("Létrehozva"), accessor: "creation_time",style: { justifyContent: "center" }},
                                        { Header: __("Archiválva"), accessor: "arch_date", filterable: false,style: { justifyContent: "center" }},
                                        { Header: __("Publikálta"), accessor: "fullname", filterable: false,style: { justifyContent: "center" } }                           
                                    ]}
                                    manual
                                    data={this.state.publicationInfo!.history!}                                
                                    filterable={true}
                                    className="-striped -highlight "
                                    {...getReactTableLabels()}
                              
                                />
                            </Dialog>
                            :
                            ""}
                        
                        {
                            this.props.displayMode == DisplayMode.All || this.props.displayMode == DisplayMode.WorkflowChart
                            ?
                            <>
                            {topPart}
                            </>
                            :
                            null                            
                        }

                        {
                            this.props.displayMode == DisplayMode.All || this.props.displayMode == DisplayMode.Details
                            ?
                            <>
                            {permState}
                            {/* {subStationPart} */}
                            {justificationPart}
                            {/* {transitionButtonsPart} */}
                            {publishPart}
                            </>
                            :
                            null                            
                        }

                        {
                            this.props.displayMode == DisplayMode.All || this.props.displayMode == DisplayMode.History
                            ?
                            <WfTransitionLog
                                key={this.state.log_counter}
                                workflow_id={this.state.workflow.id!}
                                items={this.state.log}
                            />
                            :
                            null                            
                        }
                        
                    </div>
                );
            } else {
                return <div key={key} className="callout secondary">{ __("Átmenet típusok töltése, kérem várjon") }...</div>;
            }
        }
    }
}