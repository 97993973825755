import { Server } from '../../server/Server';
import View , { registerViewClassProxy, ViewClassProxy } from '../View';
import { IListParams } from '@framework/crud/Crud';

export interface IViewUsrMessageRecord {
    /**
     * Azonosító
     *
     * Azonosító (pótkulcs)
     */
    id?: number;
    /**
     * Rekord létrehozás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel létrehozásakor.
     */
    creation_time?: string /*timestamp?*/;
    /**
     * Ki hozta létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozó felhasználóra hivatkozik.
     */
    creation_user_id?: number;
    /**
     * Melyik session-ből jött létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozáshoz használt munkamenetre hivatkozik.
     */
    creation_session_id?: number|null;
    /**
     * Utolsó módosítás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel módosításakor.
     */
    modification_time?: string /*timestamp?*/|null;
    /**
     * Ki módosította utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosító felhasználóra hivatkozik.
     */
    modification_user_id?: number|null;
    /**
     * Melyik session-ből módosították utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosításhoz használt munkamenetre hivatkozik.
     */
    modification_session_id?: number|null;
    /**
     * Aktív
     *
     * Logikai törléshez használt mező.
     */
    is_active?: boolean;
    /**
     * Amihez tartozik - Táblázat infó
     *
     * Ez mondja meg, hogy az üzenet melyik táblázat rekordjához tartozik.
     */
    table_info_id?: number|null;
    /**
     * Amihez tartozik - rekord azonosító
     *
     * Ez mondja meg, hogy az üzenet melyik rekordhoz tartozik.
     */
    rec_id?: number|null;
    /**
     * Tárgy
     */
    subject?: string;
    /**
     * Üzenet szövege
     */
    body_html?: string;
    /**
     * Feldolgozva
     */
    processed?: string /*timestamp?*/|null;
    /**
     * Válasz erre
     */
    reply_to_id?: number|null;
    /**
     * Site, ahol az üzenetet föladták
     */
    site_id?: number;
    /**
     * Látta
     */
    sender_seen?: string /*timestamp?*/|null;
    /**
     * Törölve
     */
    sender_deleted?: string /*timestamp?*/|null;
    /**
     * Véglegesen törölve
     */
    sender_purged?: string /*timestamp?*/|null;
    /**
     * Hivatkozott táblázat neve
     *
     * Hivatkozott táblázat neve
     */
    table_display_name?: string|null;
    /**
     * Hivatkozott rekord neve
     *
     * Hivatkozott rekord neve
     */
    displayvalue?: string|null;
    /**
     * Feladó bejelentkezési neve
     *
     * Feladó bejelentkezési neve
     */
    sender_login_name?: string|null;
    /**
     * Feladó e-mail címe
     *
     * Feladó e-mail címe
     */
    sender_email?: string|null;
    /**
     * Feladó teljes neve
     *
     * Feladó teljes neve
     */
    sender_fullname?: string|null;
    /**
     * Címzettek
     *
     * Címzettek
     */
    recipients?: any|null;
    /**
     * Mikor láttam
     *
     * Mikor láttam
     */
    seen_by_me?: string /*timestamp?*/|null;
    /**
     * Mikor töröltem
     *
     * Mikor töröltem
     */
    deleted_by_me?: string /*timestamp?*/|null;
    /**
     * Site alap URL
     *
     * Site alap URL
     */
    site_base_url?: string|null;
    /**
     * Site név
     *
     * Site név
     */
    site_title?: string|null;
    /**
     * Első válaszom
     *
     * Első válaszom
     */
    my_first_reply?: string /*timestamp?*/|null;
    /**
     * Utolsó válaszom
     *
     * Utolsó válaszom
     */
    my_last_reply?: string /*timestamp?*/|null;
}

/**
 *  usr.view_usr_message - Üzenet
 *
 *  Idősoros táblázat, ami az összes tétel összes korábbi verzióját eltárolja.
 */

export default class ViewUsrMessage extends View<IViewUsrMessageRecord> {
    public static VIEW_INFO_ID = 2020022801;

    public static getSchemaNameForClass(): string { return 'usr'; }
    public static getViewNameForClass(): string { return 'view_usr_message'; }
    public static getTableNameForClass(): string|null { return 'message'; }

    public static load: (id: number, aServer ?: Server) => Promise<ViewUsrMessage>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<IViewUsrMessageRecord[]>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class ViewUsrMessageClassProxy extends ViewClassProxy<IViewUsrMessageRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const viewUsrMessageClassProxy = new ViewUsrMessageClassProxy(ViewUsrMessage);
registerViewClassProxy(viewUsrMessageClassProxy);

