import { Server } from '../../server/Server';
import View , { registerViewClassProxy, ViewClassProxy } from '../View';
import { IListParams } from '@framework/crud/Crud';

export interface IViewKapCourseTrainerInvitationRecord {
    /**
     * Azonosító
     *
     * Azonosító (pótkulcs)
     */
    id?: number;
    /**
     * Rekord létrehozás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel létrehozásakor.
     */
    creation_time?: string /*timestamp?*/;
    /**
     * Ki hozta létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozó felhasználóra hivatkozik.
     */
    creation_user_id?: number;
    /**
     * Melyik session-ből jött létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozáshoz használt munkamenetre hivatkozik.
     */
    creation_session_id?: number|null;
    /**
     * Utolsó módosítás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel módosításakor.
     */
    modification_time?: string /*timestamp?*/|null;
    /**
     * Ki módosította utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosító felhasználóra hivatkozik.
     */
    modification_user_id?: number|null;
    /**
     * Melyik session-ből módosították utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosításhoz használt munkamenetre hivatkozik.
     */
    modification_session_id?: number|null;
    /**
     * Aktív
     *
     * Logikai törléshez használt mező.
     */
    is_active?: boolean;
    /**
     * Képzés
     */
    course_id?: number;
    /**
     * Válaszadási határidő
     */
    answer_deadline?: string /*timestamp?*/|null;
    /**
     * Elfogadók száma
     *
     * Elfogadók száma
     */
    accept_cnt?: number|null;
    /**
     * Elutasítók száma
     *
     * Elutasítók száma
     */
    deny_cnt?: number|null;
    /**
     * Nem válaszolók száma
     *
     * Nem válaszolók száma
     */
    undecided_cnt?: number|null;
    /**
     * Folyamat azonosító
     *
     * Folyamat azonosító
     */
    wf_workflow_id?: number|null;
    /**
     * Folyamat állapot azonosító
     *
     * Folyamat állapot azonosító
     */
    wf_station_id?: number|null;
    /**
     * Állapot
     *
     * Folyamat állapot neve
     */
    wf_station_name?: string|null;
    /**
     * Folyamat állapot leírása
     *
     * Folyamat állapot leírása
     */
    wf_station_description?: string|null;
    /**
     * Állapot stílus
     *
     * Folyamat állapot stílusa, jsonb/css
     */
    wf_station_style?: any|null;
    /**
     * Folyamat al-státusz azonosító
     *
     * Folyamat al-státusz azonosító
     */
    wf_substation_id?: number|null;
    /**
     * Folyamat al-státusz
     *
     * Folyamat al-státusz
     */
    wf_substation_name?: string|null;
    /**
     * Folyamat al-státusz leírása
     *
     * Folyamat al-státusz leírása
     */
    wf_substation_description?: string|null;
    /**
     * Folyamat szöveges állapot kiegészítés
     *
     * Folyamat szöveges állapot kiegészítés
     */
    wf_status_text?: string|null;
    /**
     * Utolsó átmenet
     *
     * Utolsó átmenet
     */
    last_transition?: string /*timestamp?*/|null;
}

/**
 *  kap.view_kap_course_trainer_invitation - KSZR képző felkérés
 *
 *  Idősoros táblázat, ami az összes tétel összes korábbi verzióját eltárolja.
 */

export default class ViewKapCourseTrainerInvitation extends View<IViewKapCourseTrainerInvitationRecord> {
    public static VIEW_INFO_ID = 2019120602;

    public static getSchemaNameForClass(): string { return 'kap'; }
    public static getViewNameForClass(): string { return 'view_kap_course_trainer_invitation'; }
    public static getTableNameForClass(): string|null { return 'course_trainer_invitation'; }

    public static load: (id: number, aServer ?: Server) => Promise<ViewKapCourseTrainerInvitation>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<IViewKapCourseTrainerInvitationRecord[]>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class ViewKapCourseTrainerInvitationClassProxy extends ViewClassProxy<IViewKapCourseTrainerInvitationRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const viewKapCourseTrainerInvitationClassProxy = new ViewKapCourseTrainerInvitationClassProxy(ViewKapCourseTrainerInvitation);
registerViewClassProxy(viewKapCourseTrainerInvitationClassProxy);

