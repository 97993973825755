import { Server } from '../../server/Server';
import Crud , { registerCrudClassProxy, IAsTextResult, IListParams, CrudClassProxy, ISearchTextParams, ISearchTextResult } from '../Crud';

export interface IOoFolderRecord {
    /**
     * Globálisan egyedi azonosító.
     * 
     * Ha új rekordot akarsz létrehozni put() metódussal, akkor hagyd üresen.
     * upsert() hívásnál figyelmen kívül van hagyva az értéke.
     *
     */
    id ?: number;
    /**
     * Rekord létrehozás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel létrehozásakor.
     */
    creation_time?: string /*timestamp?*/;
    /**
     * Ki hozta létre
     *
     * NOT NULL -> sys.sec_user ON DELETE noaction
     *
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozó felhasználóra hivatkozik.
     */
    creation_user_id?: number;
    /**
     * Melyik session-ből jött létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozáshoz használt munkamenetre hivatkozik.
     */
    creation_session_id?: number|null;
    /**
     * Utolsó módosítás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel módosításakor.
     */
    modification_time?: string /*timestamp?*/|null;
    /**
     * Ki módosította utoljára
     *
     * -> sys.sec_user ON DELETE noaction
     *
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosító felhasználóra hivatkozik.
     */
    modification_user_id?: number|null;
    /**
     * Melyik session-ből módosították utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosításhoz használt munkamenetre hivatkozik.
     */
    modification_session_id?: number|null;
    /**
     * Aktív
     *
     * Logikai törléshez használt mező.
     */
    is_active?: boolean;
    /**
     * Név
     *
     * Az OnlyOffice rendszerében a mappa'title' mezőjének értéke. Vagyis a mappa neve.
     */
    title?: string;
    /**
     * Ennek a könyvnek a gyökér könyvtára
     *
     * -> doc.book ON DELETE setnull
     *
     */
    book_id?: number|null;
    /**
     * Relatív útvonal
     *
     * A mappa szerkezetben a gyökérhez képest.
     */
    relpath?: string;
    /**
     * Relatív útvonal JSON objektumként
     *
     * Egy lista ami (folder_id, folder_title) elemekből áll.
     */
    relpath_items?: any;
    /**
     * OnlyOffice mappa azonosító.
     *
     * https://api.onlyoffice.com/portals/method/files/get/api/2.0/files/%7bfolderid%7d
     */
    oo_folderid?: string|null;
    /**
     * Szülő mappa
     *
     * -> media.oo_folder ON DELETE cascade
     *
     */
    parent_id?: number|null;
    /**
     * OnlyOffice listázó API hívás utolsó eredménye
     */
    oo_info?: any|null;
    /**
     * Azonosító NKP 1.0 rendszerben
     */
    nkp1_id?: number|null;
    /**
     * Tulajdonos
     *
     * NOT NULL -> sys.sec_user ON DELETE noaction
     *
     */
    owner_id?: number;
    /**
     * Jogok öröklése
     *
     * A mappa a jogokat a tartalmazó mappától örökli. Az explicit módon beállított jogok hatástalanok.
     */
    inherit_perms?: boolean;
    /**
     * Közös használatú
     *
     * A mappában létrehozott új bejegyzések tulajdonosa nem a létrehozó, hanem a mappa tulajdonosa lesz.
     */
    suid?: boolean;
}

/**
 *  media.oo_folder - OnlyOffice mappa
 *
 *  Idősoros táblázat, ami az összes tétel összes korábbi verzióját eltárolja.
 */

export default class OoFolderCrud extends Crud<IOoFolderRecord> {
    public static TABLE_INFO_ID = 3624830164;
    public static getSchemaNameForClass() { return 'media'; }
    public static getTableNameForClass() { return 'oo_folder'; }

    public static load: (id: number, aServer ?: Server) => Promise<OoFolderCrud>;
    public static asText: (id: number, includeRecord : boolean, aServer ?: Server) => Promise<IAsTextResult<IOoFolderRecord>>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<IOoFolderRecord[]>;
    public static searchText: (params: ISearchTextParams, aServer ?: Server) => Promise<ISearchTextResult<IOoFolderRecord>[]>;
    public static deleteById: (id: number, aServer ?: Server) => Promise<IOoFolderRecord>;
    public static unDeleteById: (id: number, aServer ?: Server) => Promise<IOoFolderRecord>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class OoFolderCrudClassProxy extends CrudClassProxy<IOoFolderRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const ooFolderCrudClassProxy = new OoFolderCrudClassProxy(OoFolderCrud);
registerCrudClassProxy(ooFolderCrudClassProxy);
