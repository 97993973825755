import * as React from 'react';
import { app } from '@src/index';
import { __ } from '@src/translation';
import OoFileCrud, { IOoFileRecord } from '@src/framework/crud/media/OoFileCrud';
import { MetaCategorization, setCategoryIds } from '@src/component/meta/meta_category_assigner_api';
import MetaCategoryAssignerPlugin from '@src/component/meta/meta_category_assigner_plugin';
import { CrudMultiSelect } from '@src/framework/forms/crudmultiselect';
import { gradeCrudClassProxy } from '@src/framework/crud/doc/GradeCrud';
import OoFileSubjectCrud, { IOoFileSubjectRecord, OoFileSubjectCrudClassProxy, ooFileSubjectCrudClassProxy } from '@src/framework/crud/media/OoFileSubjectCrud';
import OoFileGradeCrud, { IOoFileGradeRecord, ooFileGradeCrudClassProxy } from '@src/framework/crud/media/OoFileGradeCrud';
import OoFileSubprogramCrud, { IOoFileSubprogramRecord, ooFileSubprogramCrudClassProxy } from '@src/framework/crud/media/OoFileSubprogramCrud';
import { subjectCrudClassProxy } from '@src/framework/crud/doc/SubjectCrud';
import { subprogramCrudClassProxy } from '@src/framework/crud/kap/SubprogramCrud';
import { updateSubrecords } from '../UpdateSubrecords';

interface FileMetaCategorizationProps {
    file: IOoFileRecord;
    onCloseDialog?: () => void;
    onFileSaved: (file: IOoFileRecord, categorization: MetaCategorization) => void;
}

interface FileMetaCategorizationState {
    categorization: MetaCategorization;
    subject_ids: { [category_type_id: string]: number | null };

    subjectIds: number[];
    gradeIds: number[];
    subprogramIds: number[];

    subjects: IOoFileSubjectRecord[];
    grades: IOoFileGradeRecord[];
    subprograms: IOoFileSubprogramRecord[];

    schoolName?: string;
    teacherName?: string;
    lessonType?: string;
    mediaLength?: string;
    lessonPartType?: string;

}

export default class FileMetaCategorization extends React.Component<FileMetaCategorizationProps, FileMetaCategorizationState> {
    constructor(props: FileMetaCategorizationProps) {
        super(props);
        this.state = {
            categorization: {},
            subject_ids: {},

            subjectIds: [],
            gradeIds: [],
            subprogramIds: [],

            subjects: [],
            grades: [],
            subprograms: [],
        }
    }

    async componentDidMount() {
        this.asnycLoad();
    }

    async asnycLoad() {
        try {
            const listParams = {
                filter: {
                    is_active: true,
                    oo_file_id: this.props.file.id
                },
                order_by: "id"
            };
            const subjects = await OoFileSubjectCrud.list(listParams);
            const grades = await OoFileGradeCrud.list(listParams);
            const subprograms = await OoFileSubprogramCrud.list(listParams);
            const mediaMeta = await (await OoFileCrud.load(this.props.file.id!)).record.other_json;
            this.setState({
                grades,
                subjects,
                subprograms,
                subjectIds: subjects.map(s => s.subject_id!),
                gradeIds: grades.map(g => g.grade_id!),
                subprogramIds: subprograms.map(s => s.subprogram_id!),
                schoolName: mediaMeta ? mediaMeta.media_meta.school_name : null,
                teacherName: mediaMeta ? mediaMeta.media_meta.teacher_name : null,
                lessonType: mediaMeta ? mediaMeta.media_meta.lesson_type : null,
                lessonPartType: mediaMeta ? mediaMeta.media_meta.lesson_part_type : null,
                mediaLength: mediaMeta ? mediaMeta.media_meta.media_length : null
            });
            await this.reloadCategories();


        } catch (error) {
            app.showErrorFromJsonResult(error);
        }
    }


    private onSubjectSelected = (category_type_id: number, subject_id: number | null) => {
        this.setState({
            subject_ids: Object.assign(
                {},
                this.state.subject_ids,
                { [category_type_id.toString()]: subject_id })
        });
    }

    private onCategoryIdsLoaded = (category_type_id: number, category_ids: number[]) => {
        this.onSetCategoryIds(category_type_id, category_ids);
    }

    private onSetCategoryIds = (category_type_id: number, category_ids: number[]) => {
        let categorization = Object.assign(
            {},
            this.state.categorization,
            { [category_type_id.toString()]: category_ids }
        );
        this.setState({ categorization });
    }

    private onRemoveCategoryIds = (category_type_id: number, remove_category_ids: number[]) => {
        let category_ids = (this.state.categorization[category_type_id] || []).filter(
            (category_id: number) => { return remove_category_ids.indexOf(category_id) < 0 }
        );
        this.setState({
            categorization:
                Object.assign(
                    {},
                    this.state.categorization,
                    { [category_type_id]: category_ids }
                )
        });
    }

    private reloadCategories = () => {
        this.setState({ categorization: {} }, this.forceUpdate);
    }

    private saveCategories = async () => {
        try {
            for (let category_type_id in this.state.categorization) {
                const category_ids = this.state.categorization[category_type_id];

                await setCategoryIds(
                    OoFileCrud.TABLE_INFO_ID, this.props.file!.id!,
                    category_ids!,
                    parseFloat(category_type_id)
                );
            }
        } catch (error) {
            app.showErrorFromJsonResult(error);
        }
    }

    private onSave = async (data: any) => {
        this
        let mediaMeta = {
            media_meta: {
                school_name: (this.refs.meta_school_name_input as any).value ? (this.refs.meta_school_name_input as any).value : null,
                teacher_name: (this.refs.meta_teacher_name_input as any).value ? (this.refs.meta_teacher_name_input as any).value : null,
                lesson_type: this.state.lessonType ? this.state.lessonType : null,
                media_length: this.state.mediaLength ? this.state.mediaLength : null,
                lesson_part_type: this.state.lessonPartType ? this.state.lessonPartType : null
            }
        }
        if (Number(this.state.mediaLength) < 0) {
            app.showError("Hiba", "Média hossza nem lehet negatív szám!")
        } else {
            try {
                await this.saveCategories();
                await updateSubrecords<IOoFileSubjectRecord>(
                    this.state.subjects, this.state.subjectIds, ooFileSubjectCrudClassProxy, "oo_file_id", this.props.file.id!, "subject_id"
                );
                await updateSubrecords<IOoFileGradeRecord>(
                    this.state.grades, this.state.gradeIds, ooFileGradeCrudClassProxy, "oo_file_id", this.props.file.id!, "grade_id"
                );
                await updateSubrecords<IOoFileSubprogramRecord>(
                    this.state.subprograms, this.state.subprogramIds, ooFileSubprogramCrudClassProxy, "oo_file_id", this.props.file.id!, "subprogram_id"
                );
                await new OoFileCrud({ id: this.props.file.id, other_json: mediaMeta }).put()

                this.onFileSaved();
                this.onCloseDialog();
                app.showSuccess("Sikeres mentés", "Sikeresen mentette az adatokat!")
            } catch (error) {
                app.showErrorFromJsonResult(error);
                app.showError("Hiba", "Nem sikerült menteni az adatokat!")
            }
        }
    }

    private onFileSaved = () => {
        if (this.props.onFileSaved)
            this.props.onFileSaved(this.props.file, this.state.categorization!);
    }

    private onCloseDialog = () => {
        if (this.props.onCloseDialog)
            this.props.onCloseDialog();
    }

    render() {
        return <div>
            <br />
            <div className="row expanded">
                <div className="small-12 columns">
                    <label>
                        {__("Meta kategóriák")}
                        <MetaCategoryAssignerPlugin
                            tableInfoId={OoFileCrud.TABLE_INFO_ID}
                            recId={this.props.file.id! || null}
                            categorization={this.state.categorization}
                            subject_ids={this.state.subject_ids}
                            onSubjectSelected={this.onSubjectSelected}
                            onCategoryIdsLoaded={this.onCategoryIdsLoaded}
                            onSetCategoryIds={this.onSetCategoryIds}
                            onRemoveCategoryIds={this.onRemoveCategoryIds}
                            defaultExpandAll={true}
                        />
                    </label>
                </div>
                <div className="column small-12">
                    <label>
                        {__("Tantárgy")}
                </label>
                    <CrudMultiSelect
                        crudClassProxy={subjectCrudClassProxy}
                        displayFieldName="name"
                        orderByFieldName="name"
                        values={this.state.subjectIds}
                        filter={{ is_active: true }}
                        onChange={values => this.setState({ subjectIds: values })}
                    />
                </div>
                <div className="small-12 columns">

                    <label>
                        {__("Évfolyam(ok)")}
                </label>
                    <CrudMultiSelect
                        crudClassProxy={gradeCrudClassProxy}
                        displayFieldName="name"
                        orderByFieldName="id"
                        key="id"
                        values={this.state.gradeIds}
                        filter={{ is_active: true }}
                        onChange={values => this.setState({ gradeIds: values })}
                        sortFunc={(a, b) => a.name.localeCompare(b.name, undefined, { numeric: true, sensitivity: 'base' })}
                    />
                </div>
                <div className="column small-12">

                    <label>
                        {__("Alprogrami kapcsolat ")}
                        <small> ({__("A tanóra mely alprogramok elemeit integrálja")})</small>
                </label>
                    <CrudMultiSelect
                        crudClassProxy={subprogramCrudClassProxy}
                        displayFieldName="title"
                        orderByFieldName="title"
                        values={this.state.subprogramIds}
                        filter={{ is_active: true }}
                        onChange={values => this.setState({ subprogramIds: values })}
                    />
                </div>
                <div className="column small-12">
                    <label>
                        {__("Iskola neve")}
                        <small> ({__("A felvétel helyszíne")})</small>
                    </label>
                    <input ref="meta_school_name_input" type="text" defaultValue={this.state.schoolName ? this.state.schoolName : undefined} onChange={(e) => this.setState({ schoolName: e.target.value })}></input>
                </div>
                <div className="column small-12">
                    <label>
                        {__("Pedagógus")}
                        <small> ({__("A tanórát tartó pedagógus neve")})</small>
                    </label>
                    <input ref="meta_teacher_name_input" type="text" defaultValue={this.state.teacherName ? this.state.teacherName : undefined} onChange={(e) => this.setState({ teacherName: e.target.value })}></input>
                </div>
                <div className="column small-12">
                    <label>
                        {__("Tanóra típusa")}
                        <small> ({__("A tanóra típusa a KAP rendszerében")})</small>
                    </label>
                    <select value={this.state.lessonType} onChange={(e) => this.setState({ lessonType: e.target.value })}>
                        <option value={undefined}></option>
                        <option value="DFHT-KIP">DFHT-KIP</option>
                        <option value="DFHT">DFHT</option>
                        <option value="Komplex óra">Komplex óra</option>
                        <option value="Alprogrami foglalkozás">Alprogrami foglalkozás</option>
                        <option value="A Te órád">A Te órád</option>
                        <option value="Egyéb szakmai anyag">Egyéb szakmai anyag</option>
                    </select>
                </div>
                <div className="column small-12">
                    <label>
                        {__("Felvétel hossza")}
                        <small> ({__("A felvétel időbeni terjedelme")})</small>
                    </label>
                    <input type="number" min="0" style={{ width: "70px", display: "inline" }} defaultValue={this.state.mediaLength ? this.state.mediaLength : undefined} onChange={(e) => this.setState({ mediaLength: e.target.value })}></input> perc
            </div>
                <div className="column small-12">
                    <label>
                        {__("Órarészlet típusa")}
                        <small> ({__("A tanórafelvétel mely részét illusztrálja a tanórának")})</small>
                    </label>
                    <select value={this.state.lessonPartType} onChange={(e) => this.setState({ lessonPartType: e.target.value })}>
                        <option value={undefined}></option>
                        <option value="Ráhangolódás">Ráhangolódás</option>
                        <option value="Egyéni feladat">Egyéni feladat</option>
                        <option value="Csoportfeladat">Csoportfeladat</option>
                    </select>
                </div>
            </div>

            <div className="row expanded">
                <div className="large-6 small-12 columns">
                    <a onClick={() => this.onCloseDialog()} className="button alert filemamanger-buttonPadder"><i className="fa fa-times" /> &nbsp;{__("Mégse")}</a>
                </div>
                <div className="large-6 small-12 columns text-right">
                    <a onClick={this.onSave} className="button success filemamanger-buttonPadder"><i className="fa fa-save" /> &nbsp;{__("Mentés")}</a>
                </div>
            </div>
        </div>;
    }
}