import * as React from "react";
import { DateToString, DTSMode } from "@src/component/dashboard/eventcalendar/EventCalendarAPI";
import { addToDate, formatDateOrReturnOriginal, formatDateWithSeconds, formatDateTime, formatDate } from "@src/Util";
import { TFilterDict } from "@src/framework/crud/Crud";
import { __ } from "@src/translation";
import LookupEdit from "@src/framework/forms/lookupedit";
import CourseCrud from "@src/framework/crud/kap/CourseCrud";
import ViewKapCourse, { viewKapCourseClassProxy, IViewKapCourseRecord } from "@src/framework/view/kap/ViewKapCourse";
import SecUserCrud from "@src/framework/crud/sys/SecUserCrud";
import ViewKapKszrTrainer, { viewKapKszrTrainerClassProxy, IViewKapKszrTrainerRecord } from "@src/framework/view/kap/ViewKapKszrTrainer";
import { hasAnyGroup, Groups, me } from "@src/framework/server/Auth";
import AccessDeniedPage from "@src/framework/pages/AccessDeniedPage";
import { BubbleLoader } from 'react-css-loaders';
import UserViewHistoryAPI, { IUserViewEvent } from "@src/component/usr/usr_history_api";
import CourseUserCrud from "@src/framework/crud/kap/CourseUserCrud";
import { UserViewEvent, UserEventTargets, UserEventTypes, getUserEvents } from "@src/server/PublicServer";
import ViewKapCourseUser, { IViewKapCourseUserRecord } from "@src/framework/view/kap/ViewKapCourseUser";
import ViewKapCourseUserWfTransitionLog, { IViewKapCourseUserWfTransitionLogRecord } from "@src/framework/view/kap/ViewKapCourseUserWfTransitionLog";
import { messengerModule } from "@src/module/messengerModule/messengerModule";
import { ITrainerMessageEvents, ITrainerReceivedMessage, ITrainerSentMessage } from "@src/module/messengerModule/messengerAPI";
import { CSVLink } from "react-csv";

interface IEventLog {
    name?: string,
    time?: string,
    eventDescription?: string,
    courseName?: string | null
}

type TrainerActivityPageProps = {}

type TrainerActivityPageState = {
    loading: boolean,
    selectedTrainerId?: number,
    selectedTrainerName?: string,
    selectedCourseId?: number,
    fromDate?: string,
    toDate?: string,
    logs: IEventLog[],
    viewEvents: UserViewEvent[];
}

export default class TrainerActivityPage extends React.Component<TrainerActivityPageProps, TrainerActivityPageState> {

    constructor(props: any) {
        super(props);

        this.state = {
            loading: false,
            fromDate: DateToString(addToDate(new Date(), 0, -1, 0), DTSMode.dateFull, "-"), //By default we set fromDate last 30 days
            toDate: DateToString(addToDate(new Date(), 0, 0, 1), DTSMode.dateFull, "-"), //And one week extra
            logs: [],
            viewEvents: []
        }
    }

    async asyncReload() {
        let filter: TFilterDict = {};
        if (this.state.fromDate) {
            let from = new Date(this.state.fromDate);
            from.setHours(0, 0, 0, 0);
            filter[">="] = { start_date: from.getTime() }
        }
        if (this.state.toDate) {
            let to = new Date(this.state.toDate);
            to.setHours(23, 59, 59, 999);
            filter["<="] = { start_date: to.getTime() }
        }
        if (this.state.selectedTrainerId) {
            let trainer = await ViewKapKszrTrainer.list({ filter: { id: this.state.selectedTrainerId } });
            this.setState({ selectedTrainerName: trainer[0].fullname! });
        }
    }

    private onChangeDateFilter(propName: string, ev: any) {
        this.setState({ ...this.state, [propName]: ev.target.value }, this.asyncReload);
    }
    private onSetTrainerId(trainerId?: number) {
        this.setState({ selectedTrainerId: trainerId }, () => this.asyncReload());
    }
    private onSetTrainingTypeId(typeId?: number) {
        this.setState({ selectedCourseId: typeId }, () => this.asyncReload());
    }

    async getTrainerViewEvents() {
        if (!this.state.selectedTrainerId) return;
        this.setState({ loading: true });
        let trainerId: number = this.state.selectedTrainerId;
        let courseId: number | undefined = this.state.selectedCourseId;
        let viewEvents: UserViewEvent[] = [];
        let logs: IEventLog[] = [];
        let fromDate = addToDate(new Date(), 0, -1, 0); //By default we set fromDate last 30 days
        let toDate = new Date(); //And one week extra
        let trainerCourses: IViewKapCourseRecord[] = [];

        if (!courseId) trainerCourses = (await ViewKapCourse.list({ filter: { teacher_id: trainerId } }));
        if (this.state.fromDate && this.state.toDate) {
            let tmpF = new Date(this.state.fromDate);
            let tmpT = new Date(this.state.toDate);
            if (tmpF.getTime() <= Date.now()) {
                fromDate = tmpF;
            }
            if (tmpT.getTime() <= Date.now() && tmpT >= tmpF)
                toDate = tmpT;
        }
        /**
         * Getting the view events: checked the students progress, set the student milestone
         */
        let studentRelatedEvents: UserViewEvent[] = await getUserEvents(trainerId, CourseUserCrud.TABLE_INFO_ID, 1000, fromDate, toDate);
        viewEvents.concat(studentRelatedEvents);
        let filteredCourseUserIds: number[] = [];
        if (courseId) {
            filteredCourseUserIds = (await CourseUserCrud.list({ filter: { course_id: courseId } })).map(el => el.id!);
        }
        for (let index = 0; index < studentRelatedEvents.length; index++) {
            const element = studentRelatedEvents[index];
            //todo: uncomment, when server gives back good type
            if (!courseId || filteredCourseUserIds.includes(element.recordId)) {
                let log = this.getStudentRelatedEvent(element);
                if (!courseId && element.data) {
                    const tmp = trainerCourses.find(el => el.id == element.data!.courseId);
                    if (tmp) log.courseName = tmp.displayvalue;
                }
                logs.push(log);
                viewEvents.push(element);
            }
        }

        /**
          * Getting the workflow events: made a transition on the course user.
          */
        let wfEvents: IViewKapCourseUserWfTransitionLogRecord[] = (await ViewKapCourseUserWfTransitionLog.list({
            filter: {
                creation_user_id: trainerId, course_id: courseId,
                ">=": { creation_time: fromDate.getTime() },
                "<=": { creation_time: toDate.getTime() }
            },
            order_by: "creation_time"
        }))
        for (let index = 0; index < wfEvents.length; index++) {
            const element = wfEvents[index];
            let log: IEventLog = this.getTrainerAssignmentEvaluationEvents(element);
            if (!courseId && trainerCourses.length > 0) log.courseName = trainerCourses.find(el => el.id == element.course_id)!.displayvalue;
            logs.push(log);
        }
        let courseFilter = courseId ? [courseId] : undefined;

        /**
         * Getting the message related events: messages sent to or recieved from course participants 
         */
        let messageEvents: ITrainerMessageEvents = (await messengerModule.getTrainerMessageEvents(trainerId, courseFilter, fromDate, toDate));

        for (let index = 0; index < messageEvents.received.length; index++) {
            const element = messageEvents.received[index];
            let log: IEventLog = this.getTrainerReceivedMessageEvent(element);
            if (!courseId && trainerCourses.length > 0) log.courseName = trainerCourses.find(el => el.id == element.courseId)!.displayvalue;
            logs.push(log);
        }
        for (let index = 0; index < messageEvents.sent.length; index++) {
            const element = messageEvents.sent[index];
            let log: IEventLog = this.getTrainerSentMessageEvent(element);
            if (!courseId && trainerCourses.length > 0) log.courseName = trainerCourses.find(el => el.id == element.courseId)!.displayvalue;
            logs.push(log);
        }

        logs = logs.sort((a, b) => 0 - (a.time! > b.time! ? 1 : -1));


        this.setState({
            loading: false,
            viewEvents,
            logs
        })

    }

    getTrainerAssignmentEvaluationEvents(wfEvent: IViewKapCourseUserWfTransitionLogRecord): IEventLog {
        if (!this.state.selectedTrainerId || !wfEvent) return {};
        let desc = wfEvent.fullname + " résztvevő állapotát átállította a következőre: " + wfEvent.station_name + ". Indoklás: " + wfEvent.justification;
        let log: IEventLog = { time: formatDate(wfEvent.creation_time!), eventDescription: desc };
        return log;
    }

    getTrainerReceivedMessageEvent(messageEvent: ITrainerReceivedMessage): IEventLog {
        let desc = "Üzenetet kapott " + messageEvent.sender + " résztvevőtől.";
        let log: IEventLog = { time: formatDate(messageEvent.time!), eventDescription: desc };
        return log;
    }

    getTrainerSentMessageEvent(messageEvent: ITrainerSentMessage): IEventLog {
        let desc = "Üzenetet küldött " + messageEvent.recipients.toString() + " résztvevőnek.";
        let log: IEventLog = { time: formatDate(messageEvent.time!), eventDescription: desc };
        return log;
    }

    /**
     * Teacher actions that are sudent related
     */
    getStudentRelatedEvent(e: UserViewEvent): IEventLog {
        let log: IEventLog = { time: formatDate(e.eventDate) };
        let desc = "";
        if (e.data) {
            if (e.data.eventTarget == UserEventTargets.TEACHER) {

                const studentName = e.name;
                desc += studentName;
                desc += " résztvevő ";

                switch (e.data.eventType) {
                    case UserEventTypes.STUDENT_DATA_CHECK:
                        desc += "adatait megtekintette."
                        break;
                    case UserEventTypes.STUDENT_PROGRESS_CHECK:
                        desc += "tananyagi előrehaladását megtekintette."
                        break;
                    case UserEventTypes.MILESTONE_CHECK:
                        desc += "mérföldkövét állította."
                        break;
                    default:
                        break;
                }
            }
        }
        log.eventDescription = desc;

        return log;
    }

    render() {

        if (!me || !hasAnyGroup(me, [Groups.Admin, Groups.Developer, Groups.KapKszrCourseCoordinator]))
            return <AccessDeniedPage />

        let courseFilter: TFilterDict = {
            is_active: true
        };

        if (this.state.selectedTrainerId) {
            courseFilter["teacher_id"] = this.state.selectedTrainerId;
        }

        let csvHeader = [
            {
                label: "Idő",
                key: "time"
            },
            {
                label: "Esemény",
                key: "eventDescription"
            }
        ]

        return <div>
            <div className="row">
                <div className="large-12 small-12 columns">
                    <label>{__("Kiválasztott képző")}
                        <LookupEdit
                            key="trainer_id"
                            fk_table_info_id={SecUserCrud.TABLE_INFO_ID}
                            viewClassProxy={viewKapKszrTrainerClassProxy}
                            clearable={true}
                            valueColumn="id"
                            value={this.state.selectedTrainerId || null}
                            selectColumnNames={["id", "email", "fullname"]}
                            displayColumnNames={["fullname", "email"]}
                            searchColumnNames={["fullname", "email"]}
                            orderByColumnNames={"fullname"}
                            emptyLoad={true}
                            onChange={this.onSetTrainerId.bind(this)}
                        />

                    </label>
                </div>
                <div className="small-12 column">
                    <label>{__("Kiválasztott kurzus")}
                        <LookupEdit
                            fk_table_info_id={CourseCrud.TABLE_INFO_ID}
                            clearable={true}
                            emptyLoad={true}
                            selectColumnNames={["id", "displayvalue", "no"]}
                            searchColumnNames={["displayvalue"]}
                            displayColumnNames={["displayvalue"]}
                            orderByColumnNames={"displayvalue"}
                            valueColumn="id"
                            filter={courseFilter}
                            placeholder="Kérem válasszon típust..."
                            value={this.state.selectedCourseId}
                            onChange={id => this.onSetTrainingTypeId(id as number)}
                        />
                    </label>
                </div>

            </div>
            <div className="row">
                <div className="large-6 medium-6 small-12 columns">
                    <label>{__("Szűrés kezdete")}
                        <input type="date" value={this.state.fromDate ? this.state.fromDate.substr(0, 10) : ""}
                            onChange={this.onChangeDateFilter.bind(this, "fromDate")}
                        />
                    </label>
                </div>
                <div className="large-6 medium-6 small-12 columns">
                    <label>{__("Szűrés vége")}
                        <input type="date" min={this.state.fromDate ? this.state.fromDate : undefined} value={this.state.toDate ? this.state.toDate.substr(0, 10) : ""}
                            onChange={this.onChangeDateFilter.bind(this, "toDate")}
                        />
                    </label>
                </div>
                <div className="large-12 small-12 columns">
                    <button className="button primary" onClick={this.getTrainerViewEvents.bind(this)}>{__("Szűrés")}</button>
                </div>
            </div>
            {
                this.state.loading ?
                    <div>
                        <h5>Türelmet kérünk, a lekérdezés eltarhat pár másodpercig....</h5>
                        <BubbleLoader />
                    </div>
                    :
                    <div>
                        <table className="unstriped">
                            <thead>
                                <tr>
                                    <th style={{ minWidth: "10vw" }}>{__("Idő")}</th>
                                    {
                                        !this.state.selectedCourseId &&
                                        <th style={{ minWidth: "10vw" }}>{__("Kurzus")}</th>
                                    }
                                    <th>{__("Esemény")}</th>

                                </tr>
                            </thead>
                            <tbody>
                                {this.state.logs.map(s => {
                                    if (!s) return "";
                                    return <tr>
                                        <td>
                                            {s.time}
                                        </td>
                                        {
                                            !this.state.selectedCourseId &&
                                            <td>
                                                {s.courseName}
                                            </td>
                                        }
                                        <td>
                                            {s.eventDescription}
                                        </td>
                                    </tr>

                                })}
                            </tbody>
                        </table>
                        {this.state.selectedTrainerId && this.state.logs.length > 0 &&
                            <CSVLink data={this.state.logs}
                                filename={this.state.selectedTrainerName ? (this.state.selectedTrainerName.toLowerCase().split(' ').join('_').split('.').join('_') + "_trainer_stat.csv") : "trainer_stat.csv"}
                                className="button"
                                target="_blank"
                                headers={csvHeader}
                                separator={";"}>
                                {__("CSV letöltés")}
                            </CSVLink>
                        }
                    </div>
            }
        </div>;

    }
}