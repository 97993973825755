import { Server } from '../../server/Server';
import Crud , { registerCrudClassProxy, IAsTextResult, IListParams, CrudClassProxy, ISearchTextParams, ISearchTextResult } from '../Crud';

export interface IOoFileRecord {
    /**
     * Globálisan egyedi azonosító.
     * 
     * Ha új rekordot akarsz létrehozni put() metódussal, akkor hagyd üresen.
     * upsert() hívásnál figyelmen kívül van hagyva az értéke.
     *
     */
    id ?: number;
    /**
     * Név
     *
     * Az OnlyOffice rendszerében az állomány 'title' mezőjének értéke. Vagyis a file neve.
     */
    title?: string;
    /**
     * Kiterjesztés
     *
     * Az állományt kiterjesztése. Kisbetűs, ponttal kezdődik (vagy üres)
     */
    ext?: string|null;
    /**
     * Content-Type
     */
    content_type?: string|null;
    /**
     * Relatív útvonal
     *
     * A mappa szerkezetben a gyökérhez képest.
     */
    relpath?: string;
    /**
     * Relatív útvonal JSON objektumként
     *
     * Egy lista ami (folder_id, folder_title) elemekből áll.
     */
    relpath_items?: any;
    /**
     * Név
     *
     * Kulcsszavak
     */
    keywords?: string|null;
    /**
     * Méret
     *
     * A file teljes mérete byte-ban.
     */
    size?: number;
    /**
     * Szélesség pixelben
     *
     * Csak akkor, ha raszteres képről van szó.
     */
    width_px?: number|null;
    /**
     * Magasság pixelben
     *
     * Csak akkor, ha raszteres képről van szó.
     */
    height_px?: number|null;
    /**
     * SHA1 hash érték
     *
     * A file tartalmára számítva. Csak képeknél számítjuk!
     */
    sha1?: string;
    /**
     * Van thumbnail
     *
     * Thumbnail-ek elérhetők hozzá MongoDb-ből
     */
    has_thumb?: boolean;
    /**
     * OnlyOffice mappa
     *
     * NOT NULL -> media_pub.oo_folder ON DELETE cascade
     *
     */
    oo_folder_id?: number;
    /**
     * Eredet
     *
     * Az állomány származási helye (nyilvántartás és szerzői jog miatt)
     */
    origin?: string|null;
    /**
     * Média típus
     *
     * -> media.media_type ON DELETE noaction
     *
     */
    media_type_id?: number|null;
    /**
     * Publikált név
     *
     * Ahogyan a felületen megjelenik - ez nem a file neve.
     */
    displayname?: string|null;
    /**
     * Kereshető
     *
     * A kereső szolgáltatás beindexeli, meg lehet találni a keresőben.
     */
    searchable?: boolean|null;
    /**
     * Egyéb adatok
     */
    other_json?: any|null;
}

/**
 *  media_pub.oo_file - OnlyOffice file
 *
 *  OnlyOffice-ba feltöltött file.
 */

export default class OoFileCrud extends Crud<IOoFileRecord> {
    public static TABLE_INFO_ID = 2130720387;
    public static getSchemaNameForClass() { return 'media_pub'; }
    public static getTableNameForClass() { return 'oo_file'; }

    public static canCache(): boolean {
        return true;
    }

    public static getDefaultMaxAge() : number|undefined {
        return 3600;
    }

    public static load: (id: number, aServer ?: Server) => Promise<OoFileCrud>;
    public static asText: (id: number, includeRecord : boolean, aServer ?: Server) => Promise<IAsTextResult<IOoFileRecord>>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<IOoFileRecord[]>;
    public static searchText: (params: ISearchTextParams, aServer ?: Server) => Promise<ISearchTextResult<IOoFileRecord>[]>;
    public static deleteById: (id: number, aServer ?: Server) => Promise<IOoFileRecord>;
    public static unDeleteById: (id: number, aServer ?: Server) => Promise<IOoFileRecord>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class OoFileCrudClassProxy extends CrudClassProxy<IOoFileRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const ooFileCrudClassProxy = new OoFileCrudClassProxy(OoFileCrud);
registerCrudClassProxy(ooFileCrudClassProxy);
