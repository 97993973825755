import * as React from 'react';
import BookCrud, { IBookRecord } from '@src/framework/crud/doc/BookCrud';

import { app, history } from "@src/index";
import { PATH_EDIT_BOOK_TABLE_OF_CONTENTS } from '@src/Routes';
import CrudSelectComponent from '@src/framework/forms/crudselect';
import { LIBRARY_OFI_OFFICIAL_ID, LIBRARY_PERSONAL_ID, LANG_ID_EN } from '@src/Const';
import { libraryCrudClassProxy } from '@src/framework/crud/doc/LibraryCrud';
import { hasGroup, me, Groups, hasAnyGroup } from '@src/framework/server/Auth';
import { gradeCrudClassProxy } from '@src/framework/crud/doc/GradeCrud';
import { subjectCrudClassProxy } from '@src/framework/crud/doc/SubjectCrud';
import { styleCrudClassProxy } from '@src/framework/crud/doc/StyleCrud';
import OoFolderCrud from '@src/framework/crud/media/OoFolderCrud';
import { BookBreadcrumbs } from '@src/component/Breadcrumbs';
import { langCrudClassProxy } from '@src/framework/crud/sys/LangCrud';
import { LANG_ID_HU } from '@src/Const';
import { displayModeCrudClassProxy } from '@src/framework/crud/doc/DisplayModeCrud';
import { __ } from '@src/translation';
import { config } from '@src/framework/server/Server';
import { instituteTypeCrudClassProxy } from '@src/framework/crud/sys/InstituteTypeCrud';

const FOLDER_OFI_OFFICIAL_PARENT_ID = 318900;

const DISPLAY_ID_DEFAULT = 1;

type BookCreateState = {
    book: IBookRecord;
}

export class BookCreate extends React.Component<{}, BookCreateState> {

    constructor(props: any) {
        super(props);

        if (!me) throw __("Nincs hozzáférése");

        this.state = {
            book: {
                name: "",
                library_id: hasAnyGroup(me, [Groups.OFIEditor, Groups.KapELearningEditor]) ? LIBRARY_OFI_OFFICIAL_ID : LIBRARY_PERSONAL_ID,
                site_id: config.mainServer.siteId!,
                lang_id: LANG_ID_HU,
                display_mode_id: DISPLAY_ID_DEFAULT
            }
        }
    }

    private async onSave() {
        try {
            if (!me) throw __("Nincs hozzáférése");

            const required = { grade_id: __("Osztály"), subject_id: __("Tantárgy"), name: __("Könyv neve"), style_id: __("Stílus") };
            for (const key of Object.keys(required)) {
                if (!this.state.book[key]) {
                    app.showError(__("Hiányzó adat!"), required[key]);
                    return;
                }
            }

            const parentFolderId = this.state.book.library_id == LIBRARY_PERSONAL_ID ? me.oo_folder_id : FOLDER_OFI_OFFICIAL_PARENT_ID;
            const folderTitle = this.state.book.name;

            const folderRecord = (await new OoFolderCrud({
                title: folderTitle,
                parent_id: parentFolderId
            }).put()).record;

            this.state.book.oo_folder_id = folderRecord.id;

            this.state.book.owner_id = this.state.book.library_id == LIBRARY_PERSONAL_ID ? me.id : 1;

            const newBook = (await new BookCrud(this.state.book).put()).record;

            history.push(PATH_EDIT_BOOK_TABLE_OF_CONTENTS + "/" + newBook.id);
        } catch (e) {
            app.showErrorFromJsonResult(e);
        }
    }

    private onChange(name: keyof IBookRecord, value: any) {
        this.state.book[name] = value;

        this.setState({ book: this.state.book });
    }

    render() {
        return <div className="row">
            <div className="column small-12">
                <BookBreadcrumbs links={[]} />
            </div>

            <div className="column small-12">
                <h4>{__("Új könyv létrehozása")}</h4>
            </div>

            <div className="column small-6">
                <label>
                    {__("Osztály")}
                    <CrudSelectComponent
                        crudClassProxy={gradeCrudClassProxy}
                        displayFieldName="name"
                        orderByFieldName="name"
                        value={this.state.book.grade_id!}
                        onSelect={(sender, newValue) => this.onChange("grade_id", Number(newValue))}
                        sortFunc={(a, b) => a.name.localeCompare(b.name, undefined, { numeric: true, sensitivity: 'base' })}
                        filter={{ is_active: true }}
                    />
                </label>

            </div>

            <div className="column small-6">
                <label>
                    {__("Tantárgy")}
                    <CrudSelectComponent
                        crudClassProxy={subjectCrudClassProxy}
                        displayFieldName="name"
                        orderByFieldName="name"
                        value={this.state.book.subject_id!}
                        onSelect={(sender, newValue) => this.onChange("subject_id", Number(newValue))}
                        filter={{ is_active: true }}

                    />
                </label>
            </div>

            <div className="column small-12">

                <label>
                    {__("Iskola típus")}
                    <CrudSelectComponent
                        crudClassProxy={instituteTypeCrudClassProxy}
                        limit={200}
                        displayFieldName="title"
                        orderByFieldName="title"
                         value={this.state.book.institute_type_id!}
                         onSelect={(sender, newValue) => this.onChange("institute_type_id", Number(newValue))}                 
                    />
                </label>
                <label>
                    {__("Stílus")}
                    <CrudSelectComponent
                        crudClassProxy={styleCrudClassProxy}
                        limit={200}
                        displayFieldName="title"
                        orderByFieldName="title"
                        value={this.state.book.style_id!}
                        onSelect={(sender, newValue) => this.onChange("style_id", Number(newValue))}
                        filter={{ is_active: true }}

                    />
                </label>

                {
                    (me && hasAnyGroup(me, [Groups.OFIEditor, Groups.KapELearningEditor]))
                        ?
                        <label>
                            {__("Könyvtár")}
                            <CrudSelectComponent
                                crudClassProxy={libraryCrudClassProxy}
                                displayFieldName="name"
                                orderByFieldName="name"
                                value={this.state.book.library_id!}
                                onSelect={(sender, newValue) => this.onChange("library_id", Number(newValue))}
                                filter={{ is_active: true }}
                            />
                        </label>
                        :
                        null
                }

                <label>
                    {__("Új könyv neve")}
                    <input type="text" value={this.state.book.name || ""} onChange={(e) => this.onChange("name", e.currentTarget.value)} />
                </label>

                <label title={__("Sajátos nevelési igény")}>
                    <input type="checkbox" checked={this.state.book.is_sni || false} onChange={(e) => this.onChange("is_sni", e.currentTarget.checked)} />
                    {__("SNI")}
                </label>

                <label>
                    {__("Nyelv")}
                    <select value={this.state.book.lang_id} onChange={(e) => this.onChange("lang_id", Number(e.currentTarget.value))} >
                        <option value={LANG_ID_HU}>{__("Magyar")}</option>
                        <option value={LANG_ID_EN}>{__("Angol")}</option>
                    </select>
                </label>

                <label>
                    {__("Nézet")}
                    <CrudSelectComponent
                        crudClassProxy={displayModeCrudClassProxy}
                        displayFieldName="title"
                        orderByFieldName="id"
                        value={this.state.book.display_mode_id!}
                        onSelect={(sender, newValue) => this.onChange("display_mode_id", Number(newValue))}
                        filter={{ is_active: true }}
                    />
                </label>

                {
                    this.state.book.library_id === LIBRARY_PERSONAL_ID
                        ?
                        null
                        :
                        <label>
                            {__("Új könyv URL-je")}
                            <input type="text" value={this.state.book.uri_segment || ""} onChange={(e) => this.onChange("uri_segment", e.currentTarget.value)} />
                        </label>
                }

                <button className="button save success right" onClick={this.onSave.bind(this)}>
                    {__("Létrehozás")}
                </button>
            </div>
        </div>;
    }

}
