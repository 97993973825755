import * as React from 'react';
import { Tabs, TabList, Tab, TabPanel } from 'react-tabs';
import PermissionAuditByUser from './by_user/sys_perm_audit_by_user';
import PermissionAuditByTable from './by_table/sys_perm_audit_by_table';
import PermissionAuditRaw from './raw/sys_perm_audit_raw';
import PermissionAuditByView from './by_view/sys_perm_audit_by_view';
import { __ } from '@src/translation';

interface IPermissionAuditProps {

}

interface IPermissionAuditState {

}

export default class PermissionAudit extends React.Component<IPermissionAuditProps, IPermissionAuditState> {
    constructor(props: IPermissionAuditProps) {
        super(props);
        this.state = {};
    }

    render() {
        return <div className="row expanded">
            <Tabs
                defaultIndex={0}
                forceRenderTabPanel={true}>
                <TabList>
                    <Tab><i className="fa fa-user" /> &nbsp; {__("Felhasználó szerint")}</Tab>
                    <Tab><i className="fa fa-table" /> &nbsp; {__("Táblázat szerint")}</Tab>
                    <Tab><i className="fa fa-eye" /> &nbsp; {__("View szerint")}</Tab>
                    <Tab><i className="fa fa-tag" />&nbsp; {__("Elemi jog kereső")}</Tab>
                </TabList>
                <TabPanel>
                    <PermissionAuditByUser />
                </TabPanel>
                <TabPanel>
                    <PermissionAuditByTable />
                </TabPanel>
                <TabPanel>
                    <PermissionAuditByView />
                </TabPanel>
                <TabPanel>
                    <PermissionAuditRaw />
                </TabPanel>
            </Tabs>
        </div>
            }
        
        }
        
        
