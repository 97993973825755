import { EKEPairingDnDServer } from "../EKEPairingDnDExerciseEngine/EKEPairingDnDServer";
export class WMItemLinkLineServer {

    static evaluateOnServer(exercise: any, correctSolution: any, userSolution: any): Evaluated {
        return EKEPairingDnDServer.evaluateOnServer(exercise, correctSolution, userSolution);
    }


    static filterExerciseOnServer(exercise: any, solution: any): any {
        return EKEPairingDnDServer.filterExerciseOnServer(exercise, solution);
    }

}