import * as React from 'react';
import {NavLink } from 'react-router-dom';

import { viewMetaCategoryTypeClassProxy } from '@framework/view/meta/ViewMetaCategoryType';
import { viewTableInfoClassProxy } from '@framework/view/meta/ViewTableInfo';
import { viewMetaViewInfoClassProxy } from '@framework/view/meta/ViewMetaViewInfo';

import { me, hasGroup, Groups } from '@framework/server/Auth';
import { __ } from '@src/translation';

export default class MetaMenu extends React.Component<any, any> {
  
  componentDidMount()
  {

    if((this.refs.menu as HTMLUListElement).querySelector(".active"))
    {
      (this.refs.menu as HTMLUListElement).classList.add("is-active");
    }

  }

  public render() {
    if (me && hasGroup(me, Groups.Admin)) {
      return <ul className={"menu vertical nested"} ref="menu">
                  <li className="menu-item"><NavLink activeClassName="active" to={viewTableInfoClassProxy.getListUrl({})}>{ __("CRUD jogok") }</NavLink></li>
                  <li className="menu-item"><NavLink activeClassName="active" to={viewMetaViewInfoClassProxy.getListUrl({})}>{ __("View jogok") }</NavLink></li>
                  <li className="menu-item"><NavLink activeClassName="active" to={viewMetaCategoryTypeClassProxy.getListUrl({})}>{ __("Meta kategória típusok") }</NavLink></li>
                  <li className="menu-item"><NavLink activeClassName="active" to="/meta/teszt">{ __("Teszt beépülő") }</NavLink></li>
                </ul>;
             
    }
    else {
      return null;
    }
  }
}
