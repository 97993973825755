import * as React from 'react';
import ViewSectionComment, { IViewSectionCommentRecord } from '@src/framework/view/doc/ViewSectionComment';
import SectionCommentCrud from '@src/framework/crud/doc/SectionCommentCrud';
import { promptDialog, confirmDialog } from '@src/component/Dialog';
import { me } from '@src/framework/server/Auth';
import { app } from '@src/index';
import { SECTION_COMMENT_TYPE_LECTOR_ID } from '../viewer/page_addition/BookAnnotations';
import { timeoutAsync } from '@src/Util';
import { __ } from '@src/translation';

type SectionCommentsProps = {
    sectionIds: number[];
    isActive?: boolean;
}

type SectionCommentsState = {
    sectionComments: IViewSectionCommentRecord[];
}

export class SectionComments extends React.Component<SectionCommentsProps, SectionCommentsState> {

    constructor(props: SectionCommentsProps) {
        super(props);

        this.state = {
            sectionComments: []
        }
    }

    componentDidMount() {
      this.reloadAsync();
    }

    async reloadAsync() {
      const sectionComments = await ViewSectionComment.list({filter: {is_active: this.props.isActive, section_id: this.props.sectionIds, section_comment_type_id: SECTION_COMMENT_TYPE_LECTOR_ID}, order_by: "creation_time"});

      
      
      this.setState({sectionComments});
    }

    componentDidUpdate(prevProps: SectionCommentsProps) {
      const equal = this.props.sectionIds.length === prevProps.sectionIds.length && this.props.sectionIds.every((value, index) => value === prevProps.sectionIds[index]);

      if (!equal) {
        this.reloadAsync();
      }
    }
    
    async onSectionCommentAcknowledged(sectionComment: IViewSectionCommentRecord, event: React.FormEvent<HTMLInputElement>) {
      const checked = event.currentTarget.checked;

      if (!await confirmDialog(checked ? __("Javítva lett a megjegyzésben adott észrevétel?") : __("Még nincs javítva az észrevétel?"))) {
        return;
      }
        
      await new SectionCommentCrud({
        id: sectionComment.id,
        acknowledged_by_id: checked ? me!.id : null,
      }).put();
  
        app.showSuccess(__("Megjegyzés beállítva"), __("Megjegyzés beállítva"));
  
        this.reloadAsync();
      }
  
      async onSectionCommentUnsolvableClicked(sectionCommentId: number, event: React.FormEvent<HTMLInputElement>) {
        
        if (!event.currentTarget.checked) {
          
          if (!await confirmDialog(__("Mégis javítható?"), __("Erősítse meg, hogy mégis javítható!"),__("Igen, mégis javítható"))) {
            return;
          }
          
          await new SectionCommentCrud({
            id: sectionCommentId,
            unsolvable_by_id: null,
            unsolvable_reason: null
          }).put();
          
          this.reloadAsync();
        } else {
          const reason = await promptDialog(__("Megoldhatatlan"), __("Kérem írja be az okot, ami miatt a megjegyzésben adott észrevétel nem oldható meg:"), undefined, __("Megoldhatatlan"));
          if (reason) {
            await new SectionCommentCrud({
              id: sectionCommentId,
              unsolvable_by_id: me!.id,
              unsolvable_reason: reason
            }).put();
  
            app.showSuccess(__("Megjegyzés beállítva"), __("Megjegyzés beállítva"));
            this.reloadAsync();

          }
        }
      }
      async onSectionCommentCoordinationClicked(sectionCommentId: number, event: React.FormEvent<HTMLInputElement>) {
        
        if (!event.currentTarget.checked) {
          if (!await confirmDialog(__("Mégsem szükséges egyeztetés?"), __("Biztos, hogy nem szükséges egyeztetés?"),__("Igen"))) {
            return;
          } 
          await new SectionCommentCrud({
            id: sectionCommentId,
            coordination_required_by_id: null,
            coordination_required_reason: null
          }).put();
          
          this.reloadAsync();
        } else {
          const reason = await promptDialog(__("Egyeztetés szükséges"), __("Kérem írja be az okot, amiért egyeztetés szükségességét érzi:"), undefined, __("Egyeztetés szükséges"));
          if (reason) {
            await new SectionCommentCrud({
              id: sectionCommentId,
              coordination_required_by_id: me!.id,
              coordination_required_reason: reason
            }).put();
  
            app.showSuccess(__("Megjegyzés beállítva"), __("Megjegyzés beállítva"));
            this.reloadAsync();

          }
        }
      }
      
  
    render() {
        if (this.state.sectionComments.length === 0) {
            return null;
        }

        return <>
            <br />
            <table>
            <thead>
                <tr>
                <th style={{width: "20%"}}>{__("Létrehozó")}</th>
                <th style={{width: "30%"}}>{__("Szövegrészlet")}</th>
                <th style={{width: "30%"}}>{__("Megjegyzés")}</th>
                <th style={{width: "20%"}}>{__("Állapot")}</th>
                </tr>
            </thead>
            <tbody>
                {
                this.state.sectionComments.map((sectionComment, index) => {
                    const ignore = sectionComment.acknowledged_by_id || sectionComment.unsolvable_by_id;
                    return <tr key={index} style={{color: ignore ? undefined : "red", fontSize: ignore ? "small" : ""}}>
                        <td>
                        {sectionComment.creation_fullname}<br /><span style={{fontSize: "small"}}>{sectionComment.creation_time}</span>
                        </td>
                        <td>
                        {sectionComment.quote}
                        </td>
                        <td>
                        {sectionComment.comment}
                        </td>

                        <td>
                        {
                            !sectionComment.unsolvable_by_id && !sectionComment.coordination_required_by_id
                            ?
                            <>
                                <label><input type="checkbox" checked={sectionComment.acknowledged_by_id != null} onChange={this.onSectionCommentAcknowledged.bind(this, sectionComment)} />&nbsp;{__("Javítva")}</label>
                                <span style={{fontSize: "small"}}>{sectionComment.acknowledged_fullname}</span>
                            </>
                            :
                            null
                        }
                        
                        {
                            !sectionComment.acknowledged_by_id &&!sectionComment.coordination_required_by_id
                            ?
                            <>
                                <label><input type="checkbox" checked={sectionComment.unsolvable_by_id != null} onChange={this.onSectionCommentUnsolvableClicked.bind(this, sectionComment.id)} />&nbsp;{__("Megoldhatatlan")}</label>
                                {
                                sectionComment.coordination_required_by_id ? <span style={{fontSize: "small"}}>{sectionComment.coordination_required_fullname}<br />{sectionComment.coordination_required_reason}</span> : null
                                }
                                {
                                sectionComment.unsolvable_by_id ? <span style={{fontSize: "small"}}>{sectionComment.unsolvable_by_fullname}<br />{sectionComment.unsolvable_reason}</span> : null
                                }
                            </>
                            :
                            null
                        }
                        {
                            !sectionComment.unsolvable_by_id &&!sectionComment.acknowledged_by_id
                            ?
                            <>
                                <label><input type="checkbox" checked={sectionComment.coordination_required_by_id != null} onChange={this.onSectionCommentCoordinationClicked.bind(this, sectionComment.id)} />&nbsp;{__("Egyeztetés szükséges")}</label>
                                {
                                 sectionComment.acknowledged_by_id? <span style={{fontSize: "small"}}>{sectionComment.acknowledged_fullname}</span>:null
                                }
                                {
                                sectionComment.coordination_required_by_id ? <span style={{fontSize: "small"}}>{sectionComment.coordination_required_fullname}<br />{sectionComment.coordination_required_reason}</span> : null
                                }
                            </>
                            :
                            null
                        }

                        </td>
                    </tr>
                })
                }
            </tbody>
            </table>
        </>;

    }

}
