import * as React from 'react';
import ListView from '@framework/forms/listview';
import { IViewLessonplanRecord, ViewLessonplanClassProxy, viewLessonplanClassProxy } from '@src/framework/view/doc/ViewLessonplan';
import { __ } from '@src/translation';

class InstituteLessonPlan extends ListView<IViewLessonplanRecord> {

    protected getRecordButtons(record: IViewLessonplanRecord): JSX.Element[] {
        let result = [this.getEditorButton(record)];
        return result;
    }    

    public getViewClassProxy(): ViewLessonplanClassProxy { return viewLessonplanClassProxy; }

    protected getQuickFilterFieldNames(): string[] {
        return ['institute_name','wf_station_name','creator'];
    }
}

export default function InstituteLessonPlanListView(props: any) {

    return <InstituteLessonPlan
        defaultPageSize={10}
        match={props.match}
        viewColumns={[
            { accessor: "institute_name" },
            { accessor: "dpmk_project_id",style:{justifyContent: "center"} },       
            { accessor: "creator" },
            { accessor: "wf_station_name",style:{justifyContent: "center"}},
            { accessor: "link", Header: __("Műveletek"), maxWidth: 200 },
        ]}
    />
}
