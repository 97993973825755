import * as React from 'react';
import { SearchResult } from '@src/server/PublicServer';
import * as Server from '@src/server/PublicServer'
import { Link } from 'react-router-dom';
import { ExerciseTile } from '@src/component/exercise/Viewer/ExerciseTileComponent';
import { SearchResultComponent } from '../exercise/SearchResultComponent';
import { __ } from '@src/translation';
import { config } from '@src/framework/server/Server';
import { Pager } from '../Pager';
import { searchModule } from '@src/module/searchModule/searchModule';

type SetPageIndexEvent = (pageIndex: number) => void;

export type SynonymClickedEvent = (synonym: string) => void;

interface SearchResultListProps {
    results: SearchResult[];
    synonyms?: Server.SynonymResult[];
    imagesOnly: boolean;
    keywords: string;
    origkeywords: string;
    pageIndex: number;
    onSetPageIndex: SetPageIndexEvent;
    searching: boolean;
    searchType?: string;
    onSynonymClicked?: SynonymClickedEvent;
    exactMatchFound: boolean;
    resultNum: number;
    elementPerPage: number;
    doSearch: () => void;
}

interface SearchResultListState {
    pageIndex: number;
}

export default class SearchResultList extends React.Component<SearchResultListProps, SearchResultListState> {
    constructor(props: SearchResultListProps) {
        super(props);
        this.state = {
            pageIndex: 1,
        };
    }

    render() {


        const SearchModuleResultComponent = searchModule && searchModule.getConfig().searchResultComponent;

        let title: string | null = null;
        let titleElement: JSX.Element | null = null;

        if (this.props.results.length == 0 && this.props.origkeywords != undefined && this.props.origkeywords != "" && !this.props.searching) {
            titleElement = <div className="row">
                <div className="small-2 large-1 column"  style={{position: "relative"}}>
                    <img src={"/img/noResultSearch/thinking.png"} style={{ width: "13em", color: "black", position: "absolute", top: "35px", paddingRight: "15px" }} />
                </div>
                <br />
                <div className="small-10 large-11 column">
                    <br />
                    <h4>{__("A keresett kifejezés")} (<strong>{this.props.origkeywords}</strong>) {__("nem található meg.")}</h4>
                    <h6>{__("Javaslatok")}:</h6>
                    <ul className="eke-search-NoresultUli">
                        <li>{__("Győződjön meg arról, hogy valamennyi szót helyesen írta.")}</li>
                        <li>{__("Próbálkozzon más kulcsszavakkal.")}</li>
                        <li>{__("Próbálkozzon általánosabb kulcsszavakkal.")}</li>
                        <li>{__("Változtasson a szűrési feltételeken (pl. osztály).")}</li>
                    </ul>
                </div>
            </div>

        } else if (this.props.origkeywords != undefined && this.props.origkeywords != "" && !this.props.searching) {
            if (this.props.exactMatchFound) {
                title = this.props.resultNum + " " + __("találat a keresett szavakra");
            } else {
                title = __("A keresett kifejezésre nincs pontos találat. <br> <h5> A hangzáshoz hasonló találatok megjelenítése: </h5>"); // TO DO
            }
        }



        let curr_container = <div></div>;

        if (this.props.searchType == "image") {
            curr_container = <div className="row">
                {
                    this.props.results.map((r: Server.SearchResult) => {
                        return <SearchResultImageComponent result={r} key={r._id} />
                    })
                }
            </div>;

        } else if (this.props.searchType == "exercise") {
            curr_container = <div className="row">
                {
                    this.props.results.map((r: Server.SearchResult) => {
                        return (<div className="column medium-4 small-12">
                            <ExerciseTile ExerciseResult={r} key={r._id} published={true} /></div>);
                    })
                }
            </div>;

        } else {
            curr_container =
                <div>
                    {  
                    SearchModuleResultComponent 
                    ?
                        this.props.results.map((item: SearchResult) => {
                            return <SearchModuleResultComponent key={item._id} result={item} />
                        })
                    :

                        this.props.results.map((item: SearchResult) => {
                            return <SearchResultComponent key={item._id} result={item} />
                        })
                    }
                </div>;
        }

        let synonyms = null;
        let literals: string[] = [];
        let parent_literals: string[] = [];

        if (this.props.synonyms && this.props.synonyms.length > 0)
            this.props.synonyms.forEach(element => {
                if (literals.indexOf(element.literal) === -1)
                    literals.push(element.literal)

                if (element.parent_literal) {
                    if (parent_literals.indexOf(element.parent_literal) === -1)
                        parent_literals.push(element.parent_literal)
                }
            });

        if (literals.length > 0) {
            synonyms = <>

                <div className="row align-center">
                    <div className="column small-12 medium-11 large-11" key="synonyms">

                        <h6 className="">{__("Kereshetsz szinonimákra is")}:</h6>
                        <ul className="synonym-list" title={__("Szinonima lista")}>
                            {literals.slice(0, 10).map((synonym: string) =>
                                <li className="synonym"
                                    key={synonym}
                                ><a onClick={() => { if (this.props.onSynonymClicked) this.props.onSynonymClicked(synonym) }} >{synonym}</a></li>
                            )}
                            {parent_literals.slice(0, 10).map((synonym: string) =>
                                <li className="synonym synonym-parent"
                                    key={synonym}
                                    title={__("Általánosító kategória")}
                                ><a onClick={() => { if (this.props.onSynonymClicked) this.props.onSynonymClicked(synonym) }} >{synonym}</a></li>
                            )}
                        </ul>
                    </div>
                </div>
            </>
        }

        return <div className="search-result-list">
            {synonyms}
            <br />
            <h2><strong>{
                title
                    ? <div dangerouslySetInnerHTML={{ __html: title }}></div>
                    : <div>{titleElement}</div>
            }
            </strong></h2>
            {
                curr_container
            }

            <Pager resultNum={this.props.resultNum} elementPerPage={this.props.elementPerPage} pageIndex={this.state.pageIndex} paging={this.props.doSearch.bind(this)} setPageIndex={(i: number) => this.props.onSetPageIndex(i)}></Pager>
        </div>;
    }
}


class SearchResultImageComponent extends React.Component<{ result: SearchResult }, any> {

    onSearchHit(result: SearchResult) {
        Server.onSearchHit(result);
    }

    render() {
        var result: SearchResult = this.props.result;
        var imageSrc: string;
        var altText: string = "";

        if (result.path)
            imageSrc = "/tankonyv" + result.path;
        else
            imageSrc = "/api/media/file/" + result.sha1;

        if (result.alt_text) {
            altText = result.alt_text.substring(0, 65);

            if (result.alt_text.length > 65)
                altText += "...";
        }

        var title = "";
        if (result.grade_name) {
            title += result.grade_name + " / ";
        }
        if (result.subject_name) {
            title += result.subject_name + " / ";
        }
        if (result.chapter_name) {
            title += result.chapter_name + " / ";
        }
        if (result.lesson_name) {
            title += result.lesson_name;
        }

        if (result.exercise_title) {
            title += result.exercise_title + " / ";
        }
        if (result.exercise_name) {
            title += result.exercise_name;
        }
        if ((title == "") && (result.displayname)) {
            title += result.displayname;
        }

        if (title.length > 60)
            title = title.substring(0, 60) + "...";

        const content = <>
            <img src={imageSrc} style={{ height: "150px" }} title={result.alt_text} alt={result.alt_text} />
            <br />
            <small style={{ color: "black" }}>{title}</small>
            <br />
            {altText}
        </>;

        const target = config.mainServer.showBooks ? undefined : "_blank";

        return <div className="small-2 large-3 column">
            {
                result.lesson_uri_segment
                    ?
                    <Link to={"/tankonyv/" + result.book_uri_segment + "/" + result.lesson_uri_segment + "#section-" + result.section_id} target={target} onClick={this.onSearchHit.bind(this, this.props.result)}>
                        {content}
                    </Link>
                    :
                    <a href={"/api/media/file/" + result.sha1} target={target} onClick={this.onSearchHit.bind(this, this.props.result)}>
                        {content}
                    </a>
            }
        </div>
    }
}