import * as ExerciseBaseTypes from "@src/component/exercise/models/ExerciseBaseClass";
import { AExerciseTypeConverter, ValidationResponse, StatisticsResponse } from "@src/component/exercise/models/AExerciseTypeConverter";
import { MultiTextAnswerData } from "@src/component/exercise/engine/EKEMultiTextAnswerExerciseEngine/EKEMultiTextAnswerExerciseEngine";
import * as React from "react";
import { Accordion, ActionAccordionItem, AccordionActionType, IAccordionAction } from '@src/component/ui/Accordion';
import { AnswerTypes } from '@src/component/exercise/models/ExerciseBaseClass';
import ExerciseFileSelect, { ExerciseFileTypes } from "@src/component/exercise/Editor/ExerciseFileSelect";
import { Panel } from '@src/component/ui/Panel';
import { __ } from '@src/translation';
import { app } from '@src/index';

export type MultiTextAnswer = {
    text: string,
    type: AnswerTypes,
    image: string,
    url?: string
}

export type MultiTextAElement = {
    answer_item?: string,
    first_item: MultiTextAnswer,
    end_item: MultiTextAnswer
}

export type MultiTextAnswerElement = {
    answers?: any[],
    options: MultiTextAElement[],
    regular_format: boolean,
    caseSensitive: boolean,
    long_answer_mode: boolean
}

export class EKEMultiTextAnswerConverter extends AExerciseTypeConverter {

    public hasTextAnswer = true;
    public hasImageAnswer = false;
    public hasTextImageAnswer = false;
    static MAX_NUMBER_OF_OPTIONS: number = 25;
    static TEXT_ANSWER_LENGTH: number = 200;

    public convertToEditorAnswer(exercise: MultiTextAnswerData): MultiTextAnswerElement | undefined {
        if (exercise) {
            var answerList: MultiTextAElement[] = [];
            var cur_exercise = exercise;
            if (cur_exercise.options)
                for (var _i = 0; _i < cur_exercise.options.length; _i++) {
                    let curr_ans: MultiTextAElement;
                    let cur_first_item: any;
                    let cur_end_item: any;
                    let cur_answer_item = cur_exercise.solution[_i];

                    //first_item
                    if (cur_exercise.options[_i].first_item.type == "image") {
                        cur_first_item =
                            {
                                image: cur_exercise.options[_i].first_item.image,
                                text: cur_exercise.options[_i].first_item.text,
                                type: AnswerTypes.image
                            }
                    }
                    else if (cur_exercise.options[_i].first_item.type == "sound") {
                        cur_first_item =
                            {
                                image: cur_exercise.options[_i].first_item.image,
                                text: cur_exercise.options[_i].first_item.text,
                                type: AnswerTypes.sound,
                                url: cur_exercise.options[_i].first_item.url,
                            }
                    }
                    else {
                        cur_first_item =
                            {
                                image: cur_exercise.options[_i].first_item.image,
                                text: cur_exercise.options[_i].first_item.text,
                                type: AnswerTypes.text,
                            }
                    }
                    //end item
                    if (cur_exercise.options[_i].end_item.type == "image") {
                        cur_end_item =
                            {
                                image: cur_exercise.options[_i].end_item.image,
                                text: cur_exercise.options[_i].end_item.text,
                                type: AnswerTypes.image
                            }
                    }
                    else if (cur_exercise.options[_i].end_item.type == "sound") {
                        cur_end_item =
                            {
                                image: cur_exercise.options[_i].end_item.image,
                                text: cur_exercise.options[_i].end_item.text,
                                type: AnswerTypes.sound,
                                url: cur_exercise.options[_i].end_item.url,
                            }
                    }
                    else {
                        cur_end_item =
                            {
                                image: cur_exercise.options[_i].end_item.image,
                                text: cur_exercise.options[_i].end_item.text,
                                type: AnswerTypes.text,
                            }
                    }
                    curr_ans = {
                        first_item: cur_first_item,
                        answer_item: cur_answer_item,
                        end_item: cur_end_item
                    }
                    answerList.push(curr_ans)
                }
            var response: MultiTextAnswerElement = {
                options: answerList,
                regular_format: exercise.regular_format,
                caseSensitive: exercise.caseSensitive,
                long_answer_mode: exercise.long_answer_mode
            }
            return response;
        }
        else return undefined;
    }

    public convertToJson(answerList: MultiTextAnswerElement, baseData: ExerciseBaseTypes.ExerciseBaseClass, prevJSON?: MultiTextAnswerElement): MultiTextAnswerData | undefined {
        if (answerList) {
            var ans_list = [];
            var sol_list = [];
            if (answerList.options) {
                for (var _i = 0; _i < answerList.options.length; _i++) {
                    sol_list.push(answerList.options[_i].answer_item);
                    let cur_first_item: any;
                    let cur_end_item: any;
                    //first_item
                    if (answerList.options[_i].first_item.type == AnswerTypes.image) {
                        cur_first_item = {
                            image: answerList.options[_i].first_item.image,
                            text: answerList.options[_i].first_item.text,
                            type: "image"
                        }
                    }
                    else if (answerList.options[_i].first_item.type == AnswerTypes.sound) {
                        cur_first_item =
                            {
                                image: answerList.options[_i].first_item.image,
                                text: answerList.options[_i].first_item.text,
                                type: "sound",
                                url: answerList.options[_i].first_item.url,
                            }
                    }
                    else {
                        cur_first_item =
                            {
                                image: answerList.options[_i].first_item.image,
                                text: answerList.options[_i].first_item.text,
                                type: "text"
                            }
                    }
                    //end_item
                    if (answerList.options[_i].end_item.type == AnswerTypes.image) {
                        cur_end_item = {
                            image: answerList.options[_i].end_item.image,
                            text: answerList.options[_i].end_item.text,
                            type: "image"
                        }
                    }
                    else if (answerList.options[_i].end_item.type == AnswerTypes.sound) {
                        cur_end_item =
                            {
                                image: answerList.options[_i].end_item.image,
                                text: answerList.options[_i].end_item.text,
                                type: "sound",
                                url: answerList.options[_i].end_item.url,
                            }
                    }
                    else {
                        cur_end_item =
                            {
                                image: answerList.options[_i].end_item.image,
                                text: answerList.options[_i].end_item.text,
                                type: "text"
                            }
                    }
                    ans_list.push({ first_item: cur_first_item, end_item: cur_end_item });
                }
            }
            var cur_exercise = ExerciseBaseTypes.convertToBaseJson(baseData);
            cur_exercise = {
                ...cur_exercise,
                options: ans_list,
                solution: sol_list,
                regular_format: answerList.regular_format,
                caseSensitive: answerList.caseSensitive,
                long_answer_mode: answerList.long_answer_mode
            };
            return cur_exercise;
        }
        else return undefined;
    }

    public validate(editorAnswer: MultiTextAnswerElement, baseData: any, validationMap?: Map<string, string>, is_accessible?: boolean | null): ValidationResponse {
        let superAnswer: ValidationResponse = super.validate(editorAnswer, baseData, validationMap, is_accessible);
        let errorMsg = "";
        let rowsValid = this.makeValidBeforeSetState(null, editorAnswer);
        let OFIErrors: string[] = [];
        if (!rowsValid.valid) return rowsValid;

        let maxTextLength: number = EKEMultiTextAnswerConverter.TEXT_ANSWER_LENGTH;

        if (!superAnswer.valid) return superAnswer;

        let curr_options = editorAnswer.options;

        // KIFEJTŐS MÓD!
        if(editorAnswer.long_answer_mode && !baseData.all_correct) {
            errorMsg = "Kifejtős módban engedélyezni kell a \"Mindig helyes\" módot a Megjelenítési beállításoknál!";
            return { valid: false, message: errorMsg };
        }

        for (let i = 0; i < curr_options.length; i++) {
            let cur_option = curr_options[i];

            if ((cur_option.first_item.type != AnswerTypes.text || cur_option.end_item.type != AnswerTypes.text) && editorAnswer.regular_format) {
                if (!OFIErrors.includes(errorMsg, 0)) OFIErrors.push(errorMsg);
                return { valid: false, message: __("A \"folyószöveges megjelenítési\" beállítás csak szöveg elemeknél megengedett!") };
            }

            if (cur_option.first_item.type == AnswerTypes.text) {
                if (cur_option.first_item.text.length > maxTextLength) {
                    errorMsg = __("A szöveges elemek hossza max. {maxTextLength} karakter lehet!", { maxTextLength: maxTextLength });
                    validationMap!.set("options[" + i + "].first_item.text", errorMsg);
                    if (!OFIErrors.includes(errorMsg, 0)) OFIErrors.push(errorMsg);
                    if (!AExerciseTypeConverter.isOfiEditor) return { valid: false, message: errorMsg };
                }
            }

            if (cur_option.answer_item && (cur_option.answer_item.length > maxTextLength || cur_option.answer_item.length == 0)) {
                errorMsg = __("A szöveges elemek hossza max. {maxTextLength} karakter lehet!", { maxTextLength: maxTextLength });
                validationMap!.set("options[" + i + "].answer_item", errorMsg);
                if (!OFIErrors.includes(errorMsg, 0)) OFIErrors.push(errorMsg);
                if (!AExerciseTypeConverter.isOfiEditor) return { valid: false, message: errorMsg };
            }

            if (cur_option.end_item.type == AnswerTypes.text) {
                if (cur_option.end_item.text.length > maxTextLength) {
                    errorMsg = __("A szöveges elemek hossza max. {maxTextLength} karakter lehet!", { maxTextLength: maxTextLength });
                    validationMap!.set("options[" + i + "].end_item.text", errorMsg);
                    if (!OFIErrors.includes(errorMsg, 0)) OFIErrors.push(errorMsg);
                    if (!AExerciseTypeConverter.isOfiEditor) return { valid: false, message: __("A szöveges elemek hossza max. {maxTextLength} karakter lehet!", { maxTextLength: maxTextLength }) };
                }
            }
        }
        if (AExerciseTypeConverter.isOfiEditor) {
            return { valid: true, message: OFIErrors.join(' , ') }
        }

        return { valid: true }
    }

    public makeValidBeforeSetState(oldState: MultiTextAnswerElement | null, newState: MultiTextAnswerElement): ValidationResponse {
        if(newState.long_answer_mode) {
            newState.regular_format = false;
            newState.caseSensitive = false;
        }
        if (AExerciseTypeConverter.isOfiEditor) return { valid: true };
        if (newState.options && newState.options.length > EKEMultiTextAnswerConverter.MAX_NUMBER_OF_OPTIONS) {
            newState.options.splice(EKEMultiTextAnswerConverter.MAX_NUMBER_OF_OPTIONS, newState.options.length - EKEMultiTextAnswerConverter.MAX_NUMBER_OF_OPTIONS);
            return { valid: false, message: __("Maximum {MAX_NUMBER_OF_OPTIONS} sor vehető fel.", { MAX_NUMBER_OF_OPTIONS: EKEMultiTextAnswerConverter.MAX_NUMBER_OF_OPTIONS }) }
        }
        return { valid: true }
    }


    public isSolutionAvailable(exerciseJson: MultiTextAnswerData): boolean {
        if(exerciseJson.long_answer_mode) return true;
        if (!super.isSolutionAvailable(exerciseJson))
            return false;

        for (let i = 0; i < exerciseJson.solution.length; i++) {
            let curr_sol = exerciseJson.solution[i];
            if (curr_sol == "" || !curr_sol.replace(/\s/g, '').length) {
                return false;
            }
        }
        return true;
    }

    render() {
        let curr_ex: MultiTextAnswerElement = this.state.exercise as MultiTextAnswerElement;
        let isLong = curr_ex.long_answer_mode;
        return (
            <Panel header={__("Részletek")} headingLevel={4}>
                <div className="row">
                    <div className="large-12 columns">
                        <label ><input type="checkbox" name="caseSensitive" checked={curr_ex.caseSensitive || false}
                            onBlur={this.onBlurEvent.bind(this)}
                            onChange={this.handleInputChange.bind(this)} />
                            {__("Kis- és nagybetű figyelembe vétele")}</label>
                    </div>
                </div>
                <div className="row">
                    <div className="large-12 columns">
                        <label ><input type="checkbox" name="regular_format" checked={curr_ex.regular_format || false}
                            onBlur={this.onBlurEvent.bind(this)}
                            onChange={this.handleInputChange.bind(this)} />
                            {__("Folyószöveges megjelenítés")}</label>
                    </div>
                </div>
                <div className="row">
                    <div className="large-12 columns">
                        <label ><input type="checkbox" name="long_answer_mode" checked={curr_ex.long_answer_mode || false}
                            onBlur={this.onBlurEvent.bind(this)}
                            onChange={this.handleInputChange.bind(this)} />
                            {__("Kifejtős mód")}</label>
                            <span className="exe-editor-validation-msg">{isLong && __("Figyelem! Kifejtős módban minden választ elfogad a feladatmotor! (Mindig helyes mód) Továbbá nem alkalmazható folyószöveges megjelenítés!")}</span>
                    </div>
                </div>
                <div className="row">
                    <div className="large-12 columns">
                        <legend>
                            <h4>{!isLong ? __("Válasz mezők") : __("Kifejtős mód")}</h4>
                        </legend>
                    </div>
                    {!isLong && <div className="large-12 columns">
                        <label>{__("Több helyes válasz is megadható \";\"-vel elválasztva. Figyelem a szóközök is számítanak!")}</label>
                    </div>}
                    <div className="large-12 columns">
                        {
                            curr_ex.options ? curr_ex.options.map((curr_answer: any, i) => {

                                let actions: IAccordionAction[];
                                actions = [
                                    {
                                        title: __("Törlés"),
                                        type: AccordionActionType.Trash,
                                        action: () => this.removeElement("options", i)
                                    },
                                    {
                                        title: __("Fel"),
                                        type: AccordionActionType.Up,
                                        action: () => this.moveUp("options", i)
                                    },
                                    {
                                        title: __("Le"),
                                        type: AccordionActionType.Down,
                                        action: () => this.moveDown("options", i)
                                    }
                                ];
                                return (
                                    <Accordion key={"options_" + i}>
                                        <ActionAccordionItem defaultClosed key={"options_" + i} actions={actions} title={__("{n}. elem", { n: i + 1 })}>
                                            <Panel>
                                                <legend>
                                                    <h4>{isLong ? __("Kérdés") : __("Elem a válasz előtt")}</h4>
                                                </legend>                                               
                                                <div className="row">
                                                    <div className="large-4 small-12 columns">
                                                        <label> {__("Elem típusa")}
                                                            <select value={curr_answer.first_item.type} name={"options#" + i + ".first_item.type"} onChange={this.handleInputChange.bind(this)} onBlur={this.onBlurEvent.bind(this)}>
                                                                <option value={AnswerTypes.text}>{__("Szöveg")}</option>
                                                                <option value={AnswerTypes.image}>{__("Kép")}</option>
                                                                <option value={AnswerTypes.sound}>{__("Hang")}</option>
                                                            </select></label>
                                                    </div>
                                                    <div className="large-8 small-12 columns">
                                                        <label> {isLong ? __("Kérdés") : __("Elem szövege")} <span className="exe-editor-validation-msg">{this.props.validationMessages.get("options[" + i + "].first_item.text")}</span>
                                                            <input type="text" name={"options#" + i + ".first_item.text"} data-index={i} value={curr_answer.first_item.text}
                                                                onBlur={this.onBlurEvent.bind(this)}
                                                                onChange={this.handleInputChange.bind(this)} />
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    {curr_answer.first_item.type != AnswerTypes.text ? <div className="large-12 small-12 columns">
                                                        <label className="exe-image-select-label">{curr_answer.first_item.type == AnswerTypes.image ? __("Kép") : __("Hang")}
                                                            {<ExerciseFileSelect
                                                                imagebasepath={this.props.imagebasepath}
                                                                value={(curr_answer.first_item.type == AnswerTypes.image ? curr_answer.first_item.image : curr_answer.first_item.url) || ""}
                                                                onChange={this.handleImageChange.bind(this, "options#" + i + (curr_answer.first_item.type == AnswerTypes.image ? ".first_item.image" : ".first_item.url"))}
                                                                getFolderId={this.getFolderId.bind(this)}
                                                                fileType={curr_answer.first_item.type == AnswerTypes.image ? ExerciseFileTypes.Image : ExerciseFileTypes.Sound}
                                                            />}
                                                        </label>
                                                    </div> : ""}                                                    
                                                </div>
                                            </Panel>
                                            {!isLong && <Panel>
                                            <legend>
                                                    <h4>{__("Helyes válasz")}</h4>
                                                </legend>     
                                                <div className="row">                                                   
                                                    <div className="columns small-6 large-12">
                                                        <label> {__("Több helyes válasz esetén \";\"-vel felsorolva")}<span className="exe-editor-validation-msg">{this.props.validationMessages.get("options[" + i + "].answer_item")}</span>
                                                            <input type="text" name={"options#" + i + ".answer_item"} data-index={i} value={curr_answer.answer_item}
                                                                onBlur={this.onBlurEvent.bind(this)}
                                                                onChange={this.handleInputChange.bind(this)} />
                                                        </label>
                                                    </div>
                                                </div>
                                            </Panel>}
                                            {!isLong && <Panel>
                                            <legend>
                                                    <label>{__("Elem a válasz után")}</label>
                                                </legend>     
                                                <div className="row">
                                                    <div className="large-4 small-12 columns">
                                                        <label> {__("Elem típusa")}
                                                            <select value={curr_answer.end_item.type} name={"options#" + i + ".end_item.type"} onChange={this.handleInputChange.bind(this)} onBlur={this.onBlurEvent.bind(this)}>
                                                                <option value={AnswerTypes.text}>{__("Szöveg")}</option>
                                                                <option value={AnswerTypes.image}>{__("Kép")}</option>
                                                                <option value={AnswerTypes.sound}>{__("Hang")}</option>
                                                            </select></label>
                                                    </div>
                                                    <div className="large-8 small-12 columns">
                                                        <label>{__("Elem szövege")} <span className="exe-editor-validation-msg">{this.props.validationMessages.get("options[" + i + "].end_item.text")}</span>
                                                            <input type="text" name={"options#" + i + ".end_item.text"} data-index={i} value={curr_answer.end_item.text}
                                                                onBlur={this.onBlurEvent.bind(this)}
                                                                onChange={this.handleInputChange.bind(this)} />
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    {curr_answer.end_item.type != AnswerTypes.text ? <div className="large-12 small-12 columns">
                                                        <label className="exe-image-select-label">{curr_answer.end_item.type == AnswerTypes.image ? __("Kép") : __("Hang")}
                                                            {<ExerciseFileSelect
                                                                imagebasepath={this.props.imagebasepath}
                                                                value={(curr_answer.end_item.type == AnswerTypes.image ? curr_answer.end_item.image : curr_answer.end_item.url) || ""}
                                                                onChange={this.handleImageChange.bind(this, "options#" + i + (curr_answer.end_item.type == AnswerTypes.image ? ".end_item.image" : ".end_item.url"))}
                                                                getFolderId={this.getFolderId.bind(this)}
                                                                fileType={curr_answer.end_item.type == AnswerTypes.image ? ExerciseFileTypes.Image : ExerciseFileTypes.Sound}
                                                            />}
                                                        </label>
                                                    </div> : ""}                                                   
                                                </div>
                                            </Panel>}
                                        </ActionAccordionItem>
                                    </Accordion>
                                );
                            }) : ""
                        }
                        <button className="button small" onClick={this.onAddNewLine.bind(this)}><i className="fa fa-plus"></i> {__("Új sor")}</button>
                        <label className="exe-editor-label-description columns">{__("Maximum {MAX_NUMBER_OF_OPTIONS} sor vehető fel.", { MAX_NUMBER_OF_OPTIONS: EKEMultiTextAnswerConverter.MAX_NUMBER_OF_OPTIONS })}</label>
                    </div>
                </div>
            </Panel>
        )
    }

    onAddNewLine() {
        let temp_exercise = this.state.exercise as MultiTextAnswerElement;
        if (!temp_exercise.options)
            temp_exercise.options = [];
        temp_exercise.options.push(this.getNewLine());
        if (this.makeValidBeforeSetState(this.state.exercise, temp_exercise).valid)
            this.setState({ exercise: temp_exercise });
    }

    getNewLine(): MultiTextAElement {
        let newElement: MultiTextAElement = {
            answer_item: "",
            first_item: {
                type: AnswerTypes.text,
                text: "",
                image: "",
            },
            end_item: {
                type: AnswerTypes.text,
                text: "",
                image: "",
            },
        };
        return newElement;
    }

    public getExerciseStatistics(exerciseList: any): StatisticsResponse[] {
        if (!exerciseList || exerciseList.length == 0) return [];
        let statisticsResponses: StatisticsResponse[] = [];
        statisticsResponses.push({ name: __("limit - válaszelem hossza: {n}", { n: EKEMultiTextAnswerConverter.TEXT_ANSWER_LENGTH }), count: undefined });

        let optionsStat, firstItemStat, endItemStat, answerItemStat: StatisticsResponse;
        optionsStat = { name: __("Válaszok száma"), count: new Map() }
        firstItemStat = { name: __("Válasz előtti szavak száma"), count: new Map() }
        endItemStat = { name: __("Válasz utáni szavak száma"), count: new Map() }
        answerItemStat = { name: __("Válaszelem hossza"), count: new Map() }

        for (let i = 0; i < exerciseList.length; i++) {
            if (!exerciseList[i]) continue;
            const cur_exc = exerciseList[i];
            let options_count = optionsStat.count.has(cur_exc.options.length) ? optionsStat.count.get(cur_exc.options.length) : 0;
            optionsStat.count.set(cur_exc.options.length, options_count! + 1);
            for (let j = 0; j < cur_exc.options.length; j++) {
                if (!cur_exc.options[j]) continue;
                const curr_option = cur_exc.options[j];
                let first_item_count = firstItemStat.count.has(curr_option.first_item.text.length) ? firstItemStat.count.get(curr_option.first_item.text.length) : 0;
                firstItemStat.count.set(curr_option.first_item.text.length, first_item_count! + 1);
                let answer_item_count = answerItemStat.count!.has(curr_option.answer_item.length) ? answerItemStat.count!.get(curr_option.answer_item.length) : 0;
                answerItemStat.count!.set(curr_option.answer_item.length, answer_item_count! + 1);
                let end_item_count = endItemStat.count.has(curr_option.end_item.text.length) ? endItemStat.count.get(curr_option.end_item.text.length) : 0;
                endItemStat.count.set(curr_option.end_item.text.length, end_item_count! + 1);
            }
        }
        statisticsResponses.push(optionsStat, firstItemStat, endItemStat, answerItemStat);
        return statisticsResponses;

    }

}