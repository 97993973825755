import { Server } from '../../server/Server';
import View , { registerViewClassProxy, ViewClassProxy } from '../View';
import { IListParams } from '@framework/crud/Crud';

export interface IViewInstituteSiteAdminRecord {
    /**
     * Azonosító
     *
     * Azonosító (pótkulcs)
     */
    id?: number;
    /**
     * Rekord létrehozás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel létrehozásakor.
     */
    creation_time?: string /*timestamp?*/;
    /**
     * Ki hozta létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozó felhasználóra hivatkozik.
     */
    creation_user_id?: number;
    /**
     * Melyik session-ből jött létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozáshoz használt munkamenetre hivatkozik.
     */
    creation_session_id?: number|null;
    /**
     * Utolsó módosítás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel módosításakor.
     */
    modification_time?: string /*timestamp?*/|null;
    /**
     * Ki módosította utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosító felhasználóra hivatkozik.
     */
    modification_user_id?: number|null;
    /**
     * Melyik session-ből módosították utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosításhoz használt munkamenetre hivatkozik.
     */
    modification_session_id?: number|null;
    /**
     * Aktív
     *
     * Logikai törléshez használt mező.
     */
    is_active?: boolean;
    /**
     * Intézmény feladat ellátási hely
     */
    institute_site_id?: number;
    /**
     * Alkalmazás szintű felhasználó
     */
    sec_user_id?: number;
    /**
     * Módosító
     *
     * Módosító
     */
    modifier?: string|null;
    /**
     * Létrehozó
     *
     * Létrehozó
     */
    creator?: string|null;
    /**
     * Feladatellátási hely név
     *
     * Feladatellátási hely név
     */
    site_name?: string|null;
    /**
     * Feladatellátási hely azonosító
     *
     * Feladatellátási hely azonosító
     */
    site_identifier?: string|null;
    /**
     * Intézmény belső azonosító
     *
     * Intézmény belső azonosító
     */
    institute_id?: number|null;
    /**
     * Intézmény név
     *
     * Intézmény név
     */
    institute_name?: string|null;
    /**
     * Intézmény OM azonosító
     *
     * Intézmény OM azonosító
     */
    om_identifier?: string|null;
    /**
     * Adminisztrátor bejelentkezési név
     *
     * Adminisztrátor bejelentkezési név
     */
    login_name?: string|null;
    /**
     * Adminisztrátor kijelzett név
     *
     * Adminisztrátor kijelzett név
     */
    admin_displayname?: string|null;
}

/**
 *  sys.view_institute_site_admin - Feladat ellátási hely adminisztrátor
 *
 *  Idősoros táblázat, ami az összes tétel összes korábbi verzióját eltárolja.
 */

export default class ViewInstituteSiteAdmin extends View<IViewInstituteSiteAdminRecord> {
    public static VIEW_INFO_ID = 2018102501;

    public static getSchemaNameForClass(): string { return 'sys'; }
    public static getViewNameForClass(): string { return 'view_institute_site_admin'; }
    public static getTableNameForClass(): string|null { return 'institute_site_admin'; }

    public static load: (id: number, aServer ?: Server) => Promise<ViewInstituteSiteAdmin>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<IViewInstituteSiteAdminRecord[]>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class ViewInstituteSiteAdminClassProxy extends ViewClassProxy<IViewInstituteSiteAdminRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const viewInstituteSiteAdminClassProxy = new ViewInstituteSiteAdminClassProxy(ViewInstituteSiteAdmin);
registerViewClassProxy(viewInstituteSiteAdminClassProxy);

