import { Server } from '../../server/Server';
import Crud , { registerCrudClassProxy, IAsTextResult, IListParams, CrudClassProxy, ISearchTextParams, ISearchTextResult } from '../Crud';

export interface IPartnerRecord {
    /**
     * Globálisan egyedi azonosító.
     * 
     * Ha új rekordot akarsz létrehozni put() metódussal, akkor hagyd üresen.
     * upsert() hívásnál figyelmen kívül van hagyva az értéke.
     *
     */
    id ?: number;
    /**
     * Rekord létrehozás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel létrehozásakor.
     */
    creation_time?: string /*timestamp?*/;
    /**
     * Ki hozta létre
     *
     * NOT NULL -> sys.sec_user ON DELETE noaction
     *
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozó felhasználóra hivatkozik.
     */
    creation_user_id?: number;
    /**
     * Melyik session-ből jött létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozáshoz használt munkamenetre hivatkozik.
     */
    creation_session_id?: number|null;
    /**
     * Utolsó módosítás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel módosításakor.
     */
    modification_time?: string /*timestamp?*/|null;
    /**
     * Ki módosította utoljára
     *
     * -> sys.sec_user ON DELETE noaction
     *
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosító felhasználóra hivatkozik.
     */
    modification_user_id?: number|null;
    /**
     * Melyik session-ből módosították utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosításhoz használt munkamenetre hivatkozik.
     */
    modification_session_id?: number|null;
    /**
     * Aktív
     *
     * Logikai törléshez használt mező.
     */
    is_active?: boolean;
    /**
     * Név
     *
     * A rövidített elnevezése
     */
    name?: string;
    /**
     * Teljes név
     *
     * A partner teljes (hosszú) neve
     */
    fullname?: string;
    /**
     * Adószám
     */
    taxno?: string|null;
    /**
     * EU adószám
     */
    eutaxno?: string|null;
    /**
     * Cégjegyzékszám
     */
    compregno?: string|null;
    /**
     * Ország
     */
    address_country?: string|null;
    /**
     * Megye
     */
    address_county?: string|null;
    /**
     * Város
     */
    address_city?: string|null;
    /**
     * Irányítószám
     */
    address_zip?: string|null;
    /**
     * Cím
     */
    address_address?: string|null;
    /**
     * Ország hívószám
     */
    phone_country_code?: string|null;
    /**
     * Helyi szám
     */
    phone_number?: string|null;
    /**
     * Leírás
     */
    description?: string|null;
    /**
     * Állapot
     *
     * NOT NULL -> sys.partner_status ON DELETE noaction
     *
     */
    status_id?: number;
    /**
     * Partner típus
     *
     * NOT NULL -> sys.partner_type ON DELETE noaction
     *
     */
    partner_type_id?: number;
}

/**
 *  sys.partner - Partner/Szervezet/Cég
 *
 *  Idősoros táblázat, ami az összes tétel összes korábbi verzióját eltárolja.
 */

export default class PartnerCrud extends Crud<IPartnerRecord> {
    public static TABLE_INFO_ID = 2408465744;
    public static getSchemaNameForClass() { return 'sys'; }
    public static getTableNameForClass() { return 'partner'; }

    public static load: (id: number, aServer ?: Server) => Promise<PartnerCrud>;
    public static asText: (id: number, includeRecord : boolean, aServer ?: Server) => Promise<IAsTextResult<IPartnerRecord>>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<IPartnerRecord[]>;
    public static searchText: (params: ISearchTextParams, aServer ?: Server) => Promise<ISearchTextResult<IPartnerRecord>[]>;
    public static deleteById: (id: number, aServer ?: Server) => Promise<IPartnerRecord>;
    public static unDeleteById: (id: number, aServer ?: Server) => Promise<IPartnerRecord>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class PartnerCrudClassProxy extends CrudClassProxy<IPartnerRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const partnerCrudClassProxy = new PartnerCrudClassProxy(PartnerCrud);
registerCrudClassProxy(partnerCrudClassProxy);
