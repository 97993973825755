import * as React from 'react';
import { formatDate } from '@src/Util';
import KszrQuestionnarieInCrud, { IKszrQuestionnarieInRecord } from '@src/framework/crud/kap/KszrQuestionnarieInCrud';
import { app } from '@src/index';
import { __ } from '@src/translation';
import { string } from 'prop-types';
import { NavLink } from 'react-router-dom';
import { me } from '@src/framework/server/Auth';
import { courseModule } from './courseModule';

const validationRules = {
    gender_id: ["required", "number"],
    name: ["required"],
    current_address: ["required"],
    nationality: ["required"],
    notification_address: ["required"],
    tel: ["required"],
    email: ["required"],
    birth_name: ["required"],
    birth_place: ["required"],
    birth_date: ["required"],
    mother_birth_name: ["required"],
    taj_num: ["required"],
    permanent_address: ["required"],
    work_question: ["required"],
    is_public_employees: ["requiredif:work_question;Igen"],
    has_fixed_contract: ["requiredif:work_question;Igen"],
    is_full_time_school: ["required"],
    registered_unemployed: ["required"],
    unemployed_from: ["requiredif:registered_unemployed;Igen"],
    supply: ["required"],
    school_grade: ["required"],
    is_student: ["required"],
    nationality_feel: ["required"],
    homeless: ["required"],
    handicapped: ["required"],
    handicapped2: ["required"],
    consort_work: ["required"],
    has_dependent_child: ["requiredif:consort_work;Igen"],
    one_adult_and_has_dependent_child: ["required"],
}

export default class CourseQuestionnarieIn extends React.Component<{}, { form: IKszrQuestionnarieInRecord, validations: Map<string, string> }> {
    constructor(props: any) {
        super(props);
        this.state = {
            form: {
                sec_user_id: me!.id
            },
            validations: new Map<string, string>()
        };
    }

    // componentDidMount()
    // {
    //     localStorage.setItem("skipQuestionnarieIn", "false")
    // }

    showAndHide(e: React.ChangeEvent, targetsShow: string[], targetsHide: string[]) {
        targetsHide.forEach((className) => {
            (document.querySelector("." + className) as HTMLElement).classList.add("hide");
        })
        targetsShow.forEach((className) => {
            (document.querySelector("." + className) as HTMLElement).classList.remove("hide");
        })

    }

    validate() {
        var errorClassName = "editor-validation-msg";
        var state = this.state;

        var validations = new Map<string, string>();

        document.querySelectorAll("." + "editor-validation-msg").forEach(item => item.remove());

        Object.keys(validationRules).map(function (key) {

            if (validationRules[key]) {
                validationRules[key].forEach((rule: string) => {

                    const _rule = rule.split(":");
                    switch (_rule[0]) {
                        case "required":
                            {
                                if (!state.form[key]) {
                                    console.log(key);
                                    validations!.set(key, "Kötelező megadni!");
                                }
                                break;
                            }
                        case "requiredif":
                            {
                                const rule2 = _rule[1].split(";");
                                if (!state.form[key] && state.form[rule2[0]] == rule2[1]) {
                                    validations!.set(key, "Kötelező megadni!");
                                }
                                break;
                            }
                    }
                });
            }

        });

        validations.forEach((val, key, map) => {
            let element = document.querySelector('[name="' + key + '"]')!;
            if (element) {
                const message = document.createElement('span');
                message.className = errorClassName;
                message.innerHTML = validations.get(key)!;
                element.parentNode!.insertBefore(message, element);
            }

        });

        if (validations.size > 0 ) {
            const scrollTop = ((document.querySelector('.' + errorClassName) as HTMLElement).offsetTop - 5);
            window.scrollTo(0, scrollTop);
        }

        this.setState({ validations });

        return validations;
    }

    handleChange(propName: string, value: any) {
        var form = this.state.form;
        form[propName] = value;
        this.setState({ form: form });
    }
    
    //A nemzetiségi nyilatkozat mezők kezelése a formon
    handleNationality(target: any) {
        let form = this.state.form;
        form.nationality_feel = target.value;
        const inputs = document.querySelectorAll('input[name="nationality_feel"]');
        for (let i = 0; i < inputs.length; i++) {
            const el = inputs[i] as HTMLInputElement;
            //Ha megadják a nemzetiságet
            if (el != target && el.type == "radio") el.checked = false;
            //Ha nem tudják/nem akarnak nyilatkozni
            else if (el != target && el.type == "text") el.value = "";
        }
        this.setState({ form });
    }

    async submitForm() {
        var errors = this.validate();

        //console.log("errors", errors)

        if (errors.size > 0) {
            return false;
        }
        else {
            var form = this.state.form;
            const record = (await (new KszrQuestionnarieInCrud(this.state.form).put())).record;
            //console.log(record)
            app.showSuccess(__("Köszönjük,hogy kitöltötte!"), "");
            this.skipQuestionnarieIn();
            return true;
        }
    }

    skipQuestionnarieIn() {

        localStorage.setItem("skipQuestionnarieIn", "true");
        window.location.href = courseModule.PATH_COURSE_ORGANIZER;
    }

    render() {

        return <>

            <section className="row content-wrapper">
                <div className="column">

                    <div className="form">
                        <h2>Kérdőív</h2>

                        <section className="form padding-top-1">
                            <h3>Azonosító adatok</h3>
                            <h5 className="form_title">Az ön</h5>
                            <div className="row">
                                <div className="column medium-6">

                                    <label>Neme</label>
                                    <select name="gender_id" className="form-control" onChange={e => this.handleChange("gender_id", Number(e.target.value))}>
                                        <option value="">Válasszon!</option>
                                        <option value={2}>Nő</option>
                                        <option value={1}>Férfi</option>
                                    </select>

                                    <label>Családi és utóneve</label>
                                    <input type="text" name="name" className="form-control" onChange={e => this.handleChange("name", e.target.value)} />

                                    <label>Tartózkodási helye (ha nem azonos a lakóhellyel) </label>
                                    <input type="text" name="current_address" className="form-control" onChange={e => this.handleChange("current_address", e.target.value)} />

                                    <label>Állampolgársága</label>
                                    <select name="nationality" className="form-control" onChange={e => this.handleChange("nationality", e.target.value)}>
                                        <option value="">Válasszon!</option>
                                        <option value="magyar">Magyar állampolgár vagyok.</option>
                                        <optgroup label="Külföldi állampolgár vagyok. Tartózkodási helyem várhatóan legalább 12 hónapig Magyarországon van.">
                                            <option value="Külföldi állampolgár vagyok. Tartózkodási helyem várhatóan legalább 12 hónapig Magyarországon van.">Igen</option>
                                            <option value="Nem vagyok külföldi állampolgár.">Nem</option>
                                        </optgroup>
                                        <option value="Magyar és külföldi állampolgár vagyok.">Magyar és külföldi állampolgár vagyok.</option>
                                    </select>

                                    <label>Értesítési címe: (ha nem azonos a lakóhellyel, vagy a tartózkodási hellyel) </label>
                                    <input type="text" name="notification_address" className="form-control" onChange={e => this.handleChange("notification_address", e.target.value)} />

                                    <label>Telefon</label>
                                    <input type="text" name="tel" className="form-control" data-inputmask="'mask': '99-9999999'" onChange={e => this.handleChange("tel", e.target.value)} />

                                    <label>E-mail cím</label>
                                    <input type="email" name="email" className="form-control" onChange={e => this.handleChange("email", e.target.value)} />

                                </div>

                                <div className="column medium-6">

                                    <label>Születéskori családi és utóneve</label>
                                    <input type="text" name="birth_name" className="form-control" onChange={e => this.handleChange("birth_name", e.target.value)} />

                                    <label>Születési helye</label>
                                    <input type="text" name="birth_place" className="form-control" onChange={e => this.handleChange("birth_place", e.target.value)} />

                                    <label>Születési ideje</label>
                                    <input type="date" name="birth_date" className="bs-datepicker form-control" onChange={e => this.handleChange("birth_date", e.target.value)} />

                                    <label>Anyja születési családi és utóneve</label>
                                    <input type="text" name="mother_birth_name" className="form-control" onChange={e => this.handleChange("mother_birth_name", e.target.value)} />

                                    <label>Társadalombiztosítási azonosító jele (TAJ szám)</label>
                                    <input type="text" name="taj_num" className="form-control" onChange={e => this.handleChange("taj_num", e.target.value)} />

                                    <label>Lakóhelye (állandó lakcíme: irányítószám, település, utca. házszám)</label>
                                    <input type="text" name="permanent_address" className="form-control" onChange={e => this.handleChange("permanent_address", e.target.value)} />

                                </div>
                            </div>


                            <h3>Munkavégzéssel és iskolai végzettséggel kapcsolatos adatok</h3>
                            <div className="row">
                                <div className="column medium-12">
                                    <div className="form-group">
                                        <h5 className="form_title">Kérjük, hogy az alábbi állítások közül jelölje meg, hogy a projektbe történő belépés napján melyik vonatkozik Önre.</h5>

                                        <div className="radio">
                                            <label><input type="radio" onChange={(e) => { this.showAndHide(e, ["hideWork"], ["hideNoWork"]); this.handleChange("work_question", e.target.value) }} name="work_question" value="dolgozom" data-toggle="hide" data-target=".hideNoWork.show, .hideWork:not(.show)" />
                                                <span>A) Dolgozom, azaz rendelkezem olyan munkahellyel, ahonnan csak átmenetileg (betegség, szabadság miatt) vagyok távol. (A háztartási, ház körüli munkát ne számítsa ide! Ha szülési szabadságon / gyeden, gyesen vagy gyeten van, és mellette nem dolgozik, ne ezt a válaszlehetőséget jelölje be, hanem a B vagy a C válasz közül az egyiket.)</span>
                                            </label>
                                        </div>
                                        <div className="radio">
                                            <label><input type="radio" onChange={(e) => { this.showAndHide(e, ["hideNoWork"], ["hideWork"]); this.handleChange("work_question", e.target.value) }} name="work_question" value="Jelenleg nem dolgozom, de aktívan állást keresek és munkába is tudnék állni" data-toggle="hide" data-target=".hideNoWork:not(.show), .hideWork.show" />
                                                <span>B) Jelenleg nem dolgozom, de aktívan állást keresek és munkába is tudnék állni.</span>
                                            </label>
                                        </div>
                                        <div className="radio">
                                            <label><input type="radio" onChange={(e) => { this.showAndHide(e, [], ["hideNoWork", "hideWork"]); this.handleChange("work_question", e.target.value) }} name="work_question" value="Jelenleg nem dolgozom, nem keresek munkát és/vagy nem tudnék munkába állni." data-toggle="hide" data-target=".hideNoWork.show, .hideWork.show" />
                                                <span>C) Jelenleg nem dolgozom, nem keresek munkát és/vagy nem tudnék munkába állni.</span>
                                            </label>
                                        </div>
                                    </div>

                                    <div className="hideNoWork hide">
                                        <div className="form-group">
                                            <h5 className="form_title">Ha ezt a választ jelölte meg, akkor kérjük, adja meg, mióta nem dolgozik:</h5>

                                            <div className="radio">
                                                <label><input type="radio" name="work_question_no_work" value="Kevesebb, mint fél éve" onChange={(e) => this.handleChange("work_question_no_work", e.target.value)} />
                                                    <span>Kevesebb, mint fél éve</span>
                                                </label>
                                            </div>
                                            <div className="radio">
                                                <label><input type="radio" name="work_question_no_work" value="Több, mint fél éve, de kevesebb, mint egy éve" onChange={(e) => this.handleChange("work_question_no_work", e.target.value)} />
                                                    <span>Több, mint fél éve, de kevesebb, mint egy éve </span>
                                                </label>
                                            </div>
                                            <div className="radio">
                                                <label><input type="radio" name="work_question_no_work" value="Több, mint egy éve" onChange={(e) => this.handleChange("work_question_no_work", e.target.value)} />
                                                    <span>Több, mint egy éve</span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="hideWork hide">
                                        <div className="form-group ">
                                            <h5 className="form_title">Közfoglalkoztatott vagyok.</h5>

                                            <div className="radio">
                                                <label><input type="radio" name="is_public_employees" value="Igen" onChange={(e) => this.handleChange("is_public_employees", e.target.value)} />
                                                    <span>Igen</span>
                                                </label>
                                            </div>
                                            <div className="radio">
                                                <label><input type="radio" name="is_public_employees" value="Nem" onChange={(e) => this.handleChange("is_public_employees", e.target.value)} />
                                                    <span>Nem</span>
                                                </label>
                                            </div>
                                        </div>

                                        <div className="form-group ">
                                            <label>Határozott idejű munkaszerződéssel rendelkezem.</label>

                                            <div className="radio">
                                                <label><input type="radio" name="has_fixed_contract" value="Igen" onChange={(e) => this.handleChange("has_fixed_contract", e.target.value)} />
                                                    <span>Igen</span>
                                                </label>
                                            </div>
                                            <div className="radio">
                                                <label><input type="radio" name="has_fixed_contract" value="Nem" onChange={(e) => this.handleChange("has_fixed_contract", e.target.value)} />
                                                    <span>Nem</span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="form-group">
                                        <h5 className="form_title">Nappali tagozatos tanulói vagy hallgatói jogviszonnyal rendelkezem.</h5>

                                        <div className="radio">
                                            <label><input type="radio" name="is_full_time_school" value="Igen" onChange={(e) => this.handleChange("is_full_time_school", e.target.value)} />
                                                <span>Igen</span>
                                            </label>
                                        </div>
                                        <div className="radio">
                                            <label><input type="radio" name="is_full_time_school" value="Nem" onChange={(e) => this.handleChange("is_full_time_school", e.target.value)} />
                                                <span>Nem</span>
                                            </label>
                                        </div>
                                    </div>

                                    <div className="form-group">
                                        <h5 className="form_title">Regisztrált álláskereső vagyok.</h5>

                                        <div className="radio">
                                            <label><input type="radio" name="registered_unemployed" value="Igen" onChange={(e) => { this.showAndHide(e, ["hideUnemployedFrom"], []); this.handleChange("registered_unemployed", e.target.value) }} data-toggle="hide" data-target=".hideUnemployedFrom:not(.show)" />
                                                <span>Igen</span>
                                            </label>
                                        </div>

                                        <div className="radio">
                                            <label><input type="radio" name="registered_unemployed" value="Nem" onChange={(e) => { this.showAndHide(e, [], ["hideUnemployedFrom"]); this.handleChange("registered_unemployed", e.target.value) }} data-toggle="hide" data-target=".hideUnemployedFrom.show" />
                                                <span>Nem</span>
                                            </label>
                                        </div>
                                    </div>

                                    <div className="hideUnemployedFrom hide">
                                        <div className="form-group">
                                            <label>kérjük, adja meg, mióta regisztrált álláskereső (regisztráció időpontja): </label>
                                            <input type="text" name="unemployed_from" className="form-control" onChange={(e) => this.handleChange("unemployed_from", e.target.value)} />
                                        </div>
                                    </div>

                                    <div className="form-group">
                                        <h5 className="form_title">Kérjük, jelölje meg, hogy az alábbiak közül részesül-e jelenleg valamelyik ellátásban.</h5>

                                        <div className="radio">
                                            <label><input type="radio" name="supply" value="TGYÁS" onChange={(e) => this.handleChange("supply", e.target.value)} />
                                                <span>Csecsemőgondozási díj (korábbi elnevezése szerint terhességi gyermekágyi segély - TGYÁS)</span>
                                            </label>
                                        </div>
                                        <div className="radio">
                                            <label><input type="radio" name="supply" value="GYED" onChange={(e) => this.handleChange("supply", e.target.value)} />
                                                <span>Gyermekgondozási díj (GYED)</span>
                                            </label>
                                        </div>
                                        <div className="radio">
                                            <label><input type="radio" name="supply" value="GYES" onChange={(e) => this.handleChange("supply", e.target.value)} />
                                                <span>Gyermekgondozási segély (GYES)</span>
                                            </label>
                                        </div>
                                        <div className="radio">
                                            <label><input type="radio" name="supply" value="GYET" onChange={(e) => this.handleChange("supply", e.target.value)} />
                                                <span>Gyermeknevelési támogatás (GYET)</span>
                                            </label>
                                        </div>
                                        <div className="radio">
                                            <label><input type="radio" name="supply" value="NEM" onChange={(e) => this.handleChange("supply", e.target.value)} />
                                                <span>nem részesülök</span>
                                            </label>
                                        </div>
                                    </div>

                                    <div className="form-group">
                                        <h5 className="form_title">Kérjük, adja meg legmagasabb iskolai végzettségét.</h5>

                                        <div className="radio">
                                            <label><input type="radio" name="school_grade" value="Általános iskola 8 évfolyamánál kevesebb" onChange={(e) => this.handleChange("school_grade", e.target.value)} />
                                                <span>Általános iskola 8 évfolyamánál kevesebb</span>
                                            </label>
                                        </div>

                                        <div className="radio">
                                            <label><input type="radio" name="school_grade" value="Szakiskolai (szakmunkásképző iskolai) szakképesítést igazoló bizonyítvány" onChange={(e) => this.handleChange("school_grade", e.target.value)} />
                                                <span>Szakiskolai (szakmunkásképző iskolai) szakképesítést igazoló bizonyítvány</span>
                                            </label>
                                        </div>

                                        <div className="radio">
                                            <label><input type="radio" name="school_grade" value="Középiskolai - gimnáziumi vagy szakközépiskolai - érettségi, szakképesítés nélkül" onChange={(e) => this.handleChange("school_grade", e.target.value)} />
                                                <span>Középiskolai - gimnáziumi vagy szakközépiskolai - érettségi, szakképesítés nélkül</span>
                                            </label>
                                        </div>

                                        <div className="radio">
                                            <label><input type="radio" name="school_grade" value="Középiskolai szakképesítést igazoló érettségi, képesítő bizonyítvány" onChange={(e) => this.handleChange("school_grade", e.target.value)} />
                                                <span>Középiskolai szakképesítést igazoló érettségi, képesítő bizonyítvány</span>
                                            </label>
                                        </div>

                                        <div className="radio">
                                            <label><input type="radio" name="school_grade" value="Érettségit követő képzésben középfokú szakképesítést igazoló bizonyítvány)" onChange={(e) => this.handleChange("school_grade", e.target.value)} />
                                                <span>Érettségit követő képzésben középfokú szakképesítést igazoló bizonyítvány)</span>
                                            </label>
                                        </div>

                                        <div className="radio">
                                            <label><input type="radio" name="school_grade" value="Felsőoktatási képzésben szerzett szakképesítést igazoló bizonyítvány" onChange={(e) => this.handleChange("school_grade", e.target.value)} />
                                                <span>Felsőoktatási képzésben szerzett szakképesítést igazoló bizonyítvány</span>
                                            </label>
                                        </div>

                                        <div className="radio">
                                            <label><input type="radio" name="school_grade" value="Főiskolai, felsőfokú alapképzésben (BA/BSc) szerzett vagy azzal egyenértékű oklevél" onChange={(e) => this.handleChange("school_grade", e.target.value)} />
                                                <span>Főiskolai, felsőfokú alapképzésben (BA/BSc) szerzett vagy azzal egyenértékű oklevél</span>
                                            </label>
                                        </div>

                                        <div className="radio">
                                            <label><input type="radio" name="school_grade" value="Egyetemi, felsőfokú mesterképzésben (MA/MSc) szerzett vagy azzal egyenértékű oklevél" onChange={(e) => this.handleChange("school_grade", e.target.value)} />
                                                <span>Egyetemi, felsőfokú mesterképzésben (MA/MSc) szerzett vagy azzal egyenértékű oklevél</span>
                                            </label>
                                        </div>

                                        <div className="radio">
                                            <label><input type="radio" name="school_grade" value="Tudományos doktori (PhD vagy DLA) fokozatot igazoló oklevél" onChange={(e) => this.handleChange("school_grade", e.target.value)} />
                                                <span>Tudományos doktori (PhD vagy DLA) fokozatot igazoló oklevél</span>
                                            </label>
                                        </div>
                                    </div>

                                    <div className="form-group">
                                        <h5 className="form_title">Jelenleg oktatásban vagy képzésben (ide értve a formális iskolarendszerű oktatás vagy felnőttképzés bármilyen formáját) részt veszek.</h5>

                                        <div className="radio">
                                            <label><input type="radio" name="is_student" value="Igen" onChange={(e) => this.handleChange("is_student", e.target.value)} />
                                                <span>Igen</span>
                                            </label>
                                        </div>
                                        <div className="radio">
                                            <label><input type="radio" name="is_student" value="Nem" onChange={(e) => this.handleChange("is_student", e.target.value)} />
                                                <span>Nem</span>
                                            </label>
                                        </div>
                                    </div>

                                </div>

                            </div>


                            <h3>Nemzetiségi adatok</h3>
                            <p>Az alábbi adatok a 2011. évi CXII. törvény alapján különleges adatnak minősülnek. Ha kérdések valamelyikére nem kíván válaszolni, kérjük, jelölje meg a „nem kívánok nyilatkozni” választ.</p>
                            <div className="row">
                                <div className="column medium-12">
                                    <div className="form-group">
                                        <h5 className="form_title">Mely nemzetiséghez tartozónak érzi magát?</h5>
                                        <input type="text" name="nationality_feel" className="form-control" onChange={e => this.handleNationality(e.target)} />

                                        <div className="radio">
                                            <label><input type="radio" name="nationality_feel" value="nem tudom" onChange={(e) => this.handleNationality(e.target)} />
                                                <span>nem tudom</span>
                                            </label>
                                        </div>
                                        <div className="radio">
                                            <label><input type="radio" name="nationality_feel" value="nem kívánok nyilatkozni" onChange={(e) => this.handleNationality(e.target)} />
                                                <span>nem kívánok nyilatkozni</span>
                                            </label>
                                        </div>
                                    </div>

                                </div>

                            </div>


                            <h3>További személyes adatok</h3>
                            <p>Kérjük, jelölje meg, hogy következő állítások közül melyik vonatkozik Önre.
                                Az alábbi adatok a 2011. évi CXII. törvény alapján különleges adatnak minősülnek. Ha kérdések valamelyikére nem kíván válaszolni, kérjük, jelölje meg a „nem kívánok nyilatkozni” választ.</p>
                            <div className="row">
                                <div className="column medium-12">
                                    <div className="form-group">
                                        <h5 className="form_title">Megváltozott munkaképességű vagyok.
                                            <em className="small">(Megváltozott munkaképességű személy aki testi vagy értelmi fogyatékos, vagy akinek az orvosi rehabilitációt követően munkavállalási és munkahely-megtartási esélyei testi vagy szellemi károsodása következtében csökkennek.)</em>
                                        </h5>

                                        <div className="radio">
                                            <label><input type="radio" name="handicapped" value="Igen" onChange={(e) => this.handleChange("handicapped", e.target.value)} />
                                                <span>Igen</span>
                                            </label>
                                        </div>
                                        <div className="radio">
                                            <label><input type="radio" name="handicapped" value="Nem" onChange={(e) => this.handleChange("handicapped", e.target.value)} />
                                                <span>Nem</span>
                                            </label>
                                        </div>
                                        <div className="radio">
                                            <label><input type="radio" name="handicapped" value="Nem kívánok nyilatkozni" onChange={(e) => this.handleChange("handicapped", e.target.value)} />
                                                <span>Nem kívánok nyilatkozni</span>
                                            </label>
                                        </div>
                                    </div>

                                    <div className="form-group">
                                        <h5 className="form_title">Fogyatékossággal élő személy vagyok.
                                            <em className="small">(Fogyatékossággal élő személy, aki érzékszervi, így különösen látás-, hallásszervi, mozgásszervi, értelmi képességeit jelentős mértékben vagy egyáltalán nem birtokolja, illetőleg a kommunikációjában számottevően korlátozott, és ez számára tartós hátrányt jelent a társadalmi életben való aktív részvétel során.)</em>
                                        </h5>

                                        <div className="radio">
                                            <label><input type="radio" name="handicapped2" value="Igen" onChange={(e) => this.handleChange("handicapped2", e.target.value)} />
                                                <span>Igen</span>
                                            </label>
                                        </div>
                                        <div className="radio">
                                            <label><input type="radio" name="handicapped2" value="Nem" onChange={(e) => this.handleChange("handicapped2", e.target.value)} />
                                                <span>Nem</span>
                                            </label>
                                        </div>
                                        <div className="radio">
                                            <label><input type="radio" name="handicapped2" value="Nem kívánok nyilatkozni" onChange={(e) => this.handleChange("handicapped2", e.target.value)} />
                                                <span>Nem kívánok nyilatkozni</span>
                                            </label>
                                        </div>
                                    </div>

                                    <div className="form-group">
                                        <h5 className="form_title">Hajléktalan vagyok
                                            <em className="small">(nincs bejelentett lakcímem, vagy bejelentett lakóhelyem hajléktalan szállás, vagy az éjszakát közterületen vagy nem lakás céljára szolgáló helyiségben töltöm).</em>
                                        </h5>

                                        <div className="radio">
                                            <label><input type="radio" name="homeless" value="Igen" onChange={(e) => this.handleChange("homeless", e.target.value)} />
                                                <span>Igen</span>
                                            </label>
                                        </div>
                                        <div className="radio">
                                            <label><input type="radio" name="homeless" value="Nem" onChange={(e) => this.handleChange("homeless", e.target.value)} />
                                                <span>Nem</span>
                                            </label>
                                        </div>
                                        <div className="radio">
                                            <label><input type="radio" name="homeless" value="Nem kívánok nyilatkozni" onChange={(e) => this.handleChange("homeless", e.target.value)} />
                                                <span>Nem kívánok nyilatkozni</span>
                                            </label>
                                        </div>
                                    </div>

                                </div>

                            </div>

                            <h3>Háztartással kapcsolatos adatok</h3>
                            <p>Kérjük, jelölje meg, hogy igazak-e Önre az alábbi állítások.</p>
                            <div className="row">
                                <div className="column medium-12">
                                    <div className="form-group">
                                        <h5 className="form_title">Ön olyan háztartásban él, ahol a háztartás egyik tagja sem dolgozik?</h5>

                                        <div className="radio">
                                            <label><input type="radio" name="consort_work" value="Igen" onChange={(e) => { this.showAndHide(e, ["dependentChild"], []); this.handleChange("consort_work", e.target.value) }} data-toggle="hide" data-target=".dependentChild:not(.show)" />
                                                <span>Igen, a mi háztartásunkban senki nem dolgozik.</span>
                                            </label>
                                        </div>
                                        <div className="radio">
                                            <label><input type="radio" name="consort_work" value="Nem" onChange={(e) => { this.showAndHide(e, [], ["dependentChild"]); this.handleChange("consort_work", e.target.value) }} data-toggle="hide" data-target=".dependentChild.show" />
                                                <span>Nem, a mi háztartásunkban van olyan személy, aki dolgozik.</span>
                                            </label>
                                        </div>
                                    </div>

                                    <div className="hide dependentChild">
                                        <div className="form-group">
                                            <h5 className="form_title">van-e eltartott gyermek a háztartásban?</h5>

                                            <div className="radio">
                                                <label><input type="radio" name="has_dependent_child" value="Igen" onChange={(e) => this.handleChange("has_dependent_child", e.target.value)} />
                                                    <span>Igen</span>
                                                </label>
                                            </div>
                                            <div className="radio">
                                                <label><input type="radio" name="has_dependent_child" value="Nem" onChange={(e) => this.handleChange("has_dependent_child", e.target.value)} />
                                                    <span>Nem</span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="form-group">
                                        <h5 className="form_title">Ön olyan háztartásban él, amelynek csak egy felnőtt tagja van, és legalább egy eltartott gyermek is él a háztartásban?</h5>

                                        <div className="radio">
                                            <label><input type="radio" name="one_adult_and_has_dependent_child" value="Igen" onChange={(e) => this.handleChange("one_adult_and_has_dependent_child", e.target.value)} />
                                                <span>Igen, csak egy felnőtt van a háztartásban, és van eltartott gyermek is.</span>
                                            </label>
                                        </div>
                                        <div className="radio">
                                            <label><input type="radio" name="one_adult_and_has_dependent_child" value="Nem" onChange={(e) => this.handleChange("one_adult_and_has_dependent_child", e.target.value)} />
                                                <span>Nem (Pl. több felnőtt van; vagy egy felnőtt van, de nincs eltartott gyermek).</span>
                                            </label>
                                        </div>
                                    </div>

                                </div>

                            </div>

                            <div className="row">
                                <div className="column medium-12">
                                    <button onClick={this.submitForm.bind(this)} className="button">Mentés</button>
                                    {/* <button className="button" id="saveAndPrint" type="submit">Mentés és nyomtatás <i className="fa fa-print"></i></button> */}
                                </div>
                            </div>


                            <NavLink to={"/kszr"} onClick={this.skipQuestionnarieIn.bind(this)}>
                                Kihagyás <em>(Nem szeretném kitölteni a kérdőívet.)</em>
                            </NavLink>

                            <div className="padding-top-2">
                                {
                                    formatDate(new Date())
                                }
                            </div>
                        </section>
                    </div>
                </div>
            </section>

        </>
    }

}