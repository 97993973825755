import * as React from "react";
import { me } from "@src/framework/server/Auth";
import ViewUsrMessage, { IViewUsrMessageRecord } from "@src/framework/view/usr/ViewUsrMessage";
import { app } from "@src/index";
import { match } from "react-router";
import { BubbleLoader } from 'react-css-loaders';
import { __ } from "@src/translation";
import AccessDeniedPage from "@src/framework/pages/AccessDeniedPage";
import { Module, IRecordRoute } from "@src/module/Module";
import { Link } from "react-router-dom";
import MessageCards from "./MessageCards";
import { MemberCardFromId } from "@src/framework/forms/MemberCard";
import { Popover } from "@src/component/ui/Popover";

type MessageViewPageProps = {
    match?: match<{ messageId: string }>;
}

type MessageViewPageState = {
    loading: boolean;
    openedMessage: IViewUsrMessageRecord;
    showRecipients: boolean;
}

export default class MessageViewPage extends React.Component<MessageViewPageProps, MessageViewPageState> {

    constructor(props: MessageViewPageProps) {
        super(props);
        this.state = {
            loading: true,
            openedMessage: {},
            showRecipients: false
        }
    }

    componentDidMount() {
        this.asyncReload();
    }

    private async asyncReload() {

        if (!me || !this.props.match || !this.props.match.params.messageId) {
            this.setState({ loading: false });
            return;
        }

        this.setState({ loading: true });
        //A megnyitott üzenet betöltése, nem olvasásra, hanem a címzettek és tárgy megjelenítése végett
        //Az üzenetek megjelenítéséért a MessageCards komponens felelős
        try {

            let message_id = Number(this.props.match.params.messageId);
            const openedMessage = (await ViewUsrMessage.load(message_id)).record;
            this.setState({ openedMessage, loading: false });

        } catch (error) {
            app.showErrorFromJsonResult(error);
        }

    }

    render() {
        //TODO: Ha nem vagyunk jogosultak az üzenet megtekintésére
        if (!me || !me.id) {
            return <AccessDeniedPage />;
        }

        if (this.state.loading) {
            return <BubbleLoader />;
        }

        let startingMessage = this.state.openedMessage;

        //Címke előállítása
        let displayvalue = startingMessage.displayvalue;
        let displayRoute: string = "";
        if (startingMessage.rec_id && startingMessage.table_info_id) {
            let recRoute: IRecordRoute | null = Module.getDefaultRecordRoute(startingMessage.table_info_id, startingMessage.rec_id!);
            if (recRoute) displayRoute = recRoute.route;
        }

        return <div className="message-page">
            <div className="message-head"><h3 className="subject">{__("Tárgy: ")} {startingMessage.subject}</h3>
                {displayvalue && displayRoute != "" && <div className="column small-12"><h5 style={{ display: "inline" }}>{__("Címke: ")}</h5> <Link to={displayRoute}>{displayvalue}</Link></div>}
            
                {startingMessage && startingMessage.creation_user_id == me!.id ? 
                <div className="recipient-container">
                    {__("Címzettek:")}
                    <div ref="recipientList" className="recipient-list-container">
                        <ul>
                            {startingMessage.recipients.map((r: any, i: number) => {
                                return <li key={"recipient_" + r.sec_user_id} >
                                    <MemberCardFromId memberId={r.sec_user_id} short={true} />
                                </li>
                            }
                            )}
                        </ul>
                        
                    </div>
                    <button onClick={()=>this.setState({showRecipients:true})}><i className="fas fa-caret-down"></i></button>
                        <Popover parentAlignHorizontal="right" childAlignHorizontal="left" open={this.state.showRecipients} parentElement={() => this.refs.recipientList as HTMLElement} onClose={() => this.setState({showRecipients: false})} >
                            <div style={{padding:"0.5rem"}}>
                                {
                                startingMessage.recipients.map((r: any, i: number) => {
                                    return <span key={"recipient_" + r.sec_user_id} >
                                        <MemberCardFromId memberId={r.sec_user_id} medium={true} />
                                    </span>
                                }
                                )}
                            </div>
                        </Popover>
                </div>
                    : ""}
            </div>
            <MessageCards
                message_id={this.state.openedMessage.id!}
            />
        </div>
    }

}

