import { Server } from '../../server/Server';
import Crud , { registerCrudClassProxy, IAsTextResult, IListParams, CrudClassProxy, ISearchTextParams, ISearchTextResult } from '../Crud';

export interface IDfhtVisitRecord {
    /**
     * Globálisan egyedi azonosító.
     * 
     * Ha új rekordot akarsz létrehozni put() metódussal, akkor hagyd üresen.
     * upsert() hívásnál figyelmen kívül van hagyva az értéke.
     *
     */
    id ?: number;
    /**
     * Rekord létrehozás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel létrehozásakor.
     */
    creation_time?: string /*timestamp?*/;
    /**
     * Ki hozta létre
     *
     * NOT NULL -> sys.sec_user ON DELETE noaction
     *
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozó felhasználóra hivatkozik.
     */
    creation_user_id?: number;
    /**
     * Melyik session-ből jött létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozáshoz használt munkamenetre hivatkozik.
     */
    creation_session_id?: number|null;
    /**
     * Utolsó módosítás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel módosításakor.
     */
    modification_time?: string /*timestamp?*/|null;
    /**
     * Ki módosította utoljára
     *
     * -> sys.sec_user ON DELETE noaction
     *
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosító felhasználóra hivatkozik.
     */
    modification_user_id?: number|null;
    /**
     * Melyik session-ből módosították utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosításhoz használt munkamenetre hivatkozik.
     */
    modification_session_id?: number|null;
    /**
     * Aktív
     *
     * Logikai törléshez használt mező.
     */
    is_active?: boolean;
    /**
     * KAP szakmai támogató
     *
     * -> sys.sec_user ON DELETE noaction
     *
     */
    kap_supporter_id?: number|null;
    /**
     * Legkorábbi kezdő időpont
     *
     * Ezt a szakmai támogató adja meg. Ez a legkorábbi időpont ahonnan igénybe lehet venni.
     */
    min_start_date?: string /*timestamp?*/;
    /**
     * Legkésőbbi befejező időpont
     *
     * Ezt a szakmai támogató adja meg. Ez a legkésőbbi időpont ahonnan igénybe lehet venni.
     */
    max_end_date?: string /*timestamp?*/;
    /**
     * Intézmény
     *
     * -> sys.institute ON DELETE noaction
     *
     */
    institute_id?: number|null;
    /**
     * Támogató jóváhagyta
     *
     * Az az időpont, amikor a támogató jóváhagyta az időpontot és a feladatellátási helyet.
     */
    supporter_approved?: string /*timestamp?*/|null;
    /**
     * Kapcsolattartó jóváhagyta
     *
     * Az az időpont, amikor a feladatellátási hely kapcsolattartója jóváhagyta az időpontot és a szakmai támogatót.
     */
    site_approved?: string /*timestamp?*/|null;
    /**
     * Az intézmény látogatás gyökér könyvtára.
     *
     * NOT NULL -> media.oo_folder ON DELETE noaction
     *
     */
    oo_folder_id?: number;
}

/**
 *  kap.dfht_visit - KIP/Komplex intézmény látogatás
 *
 *  Idősoros táblázat, ami az összes tétel összes korábbi verzióját eltárolja.
 */

export default class DfhtVisitCrud extends Crud<IDfhtVisitRecord> {
    public static TABLE_INFO_ID = 2772655337;
    public static getSchemaNameForClass() { return 'kap'; }
    public static getTableNameForClass() { return 'dfht_visit'; }

    public static load: (id: number, aServer ?: Server) => Promise<DfhtVisitCrud>;
    public static asText: (id: number, includeRecord : boolean, aServer ?: Server) => Promise<IAsTextResult<IDfhtVisitRecord>>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<IDfhtVisitRecord[]>;
    public static searchText: (params: ISearchTextParams, aServer ?: Server) => Promise<ISearchTextResult<IDfhtVisitRecord>[]>;
    public static deleteById: (id: number, aServer ?: Server) => Promise<IDfhtVisitRecord>;
    public static unDeleteById: (id: number, aServer ?: Server) => Promise<IDfhtVisitRecord>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class DfhtVisitCrudClassProxy extends CrudClassProxy<IDfhtVisitRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const dfhtVisitCrudClassProxy = new DfhtVisitCrudClassProxy(DfhtVisitCrud);
registerCrudClassProxy(dfhtVisitCrudClassProxy);
