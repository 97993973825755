import { Server } from '../../server/Server';
import View , { registerViewClassProxy, ViewClassProxy } from '../View';
import { IListParams } from '@framework/crud/Crud';

export interface IViewDisplayModeRecord {
    /**
     * Azonosító
     *
     * Azonosító (pótkulcs)
     */
    id?: number;
    /**
     * Címke
     */
    label?: string;
    /**
     * Név
     */
    name?: string|null;
    /**
     * Leírás
     */
    description?: string|null;
}

/**
 *  exc.view_display_mode - Feladat üzemmód
 */

export default class ViewDisplayMode extends View<IViewDisplayModeRecord> {
    public static VIEW_INFO_ID = 2018040505;

    public static getSchemaNameForClass(): string { return 'exc'; }
    public static getViewNameForClass(): string { return 'view_display_mode'; }
    public static getTableNameForClass(): string|null { return 'exc_display_mode'; }

    public static load: (id: number, aServer ?: Server) => Promise<ViewDisplayMode>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<IViewDisplayModeRecord[]>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class ViewDisplayModeClassProxy extends ViewClassProxy<IViewDisplayModeRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const viewDisplayModeClassProxy = new ViewDisplayModeClassProxy(ViewDisplayMode);
registerViewClassProxy(viewDisplayModeClassProxy);

