import { Server } from '../../server/Server';
import Crud , { registerCrudClassProxy, IAsTextResult, IListParams, CrudClassProxy, ISearchTextParams, ISearchTextResult } from '../Crud';

export interface IHtrTicketUserReadRecord {
    /**
     * Globálisan egyedi azonosító.
     * 
     * Ha új rekordot akarsz létrehozni put() metódussal, akkor hagyd üresen.
     * upsert() hívásnál figyelmen kívül van hagyva az értéke.
     *
     */
    id ?: number;
    /**
     * Alkalmazás szintű felhasználó
     *
     * NOT NULL -> sys.sec_user ON DELETE cascade
     *
     */
    sec_user_id?: number;
    /**
     * HTR bejelentés
     *
     * NOT NULL -> ticket.htr_ticket ON DELETE cascade
     *
     */
    htr_ticket_id?: number;
    /**
     * Olvasás dátuma
     */
    read_date?: string /*timestamp?*/;
}

/**
 *  ticket.htr_ticket_user_read - A ticket olvasásának ideje
 *
 *  Azt mondja meg, hogy melyik felhasználó mikor olvasta az egyes ticket-eket
 */

export default class HtrTicketUserReadCrud extends Crud<IHtrTicketUserReadRecord> {
    public static TABLE_INFO_ID = 911144981;
    public static getSchemaNameForClass() { return 'ticket'; }
    public static getTableNameForClass() { return 'htr_ticket_user_read'; }

    public static load: (id: number, aServer ?: Server) => Promise<HtrTicketUserReadCrud>;
    public static asText: (id: number, includeRecord : boolean, aServer ?: Server) => Promise<IAsTextResult<IHtrTicketUserReadRecord>>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<IHtrTicketUserReadRecord[]>;
    public static searchText: (params: ISearchTextParams, aServer ?: Server) => Promise<ISearchTextResult<IHtrTicketUserReadRecord>[]>;
    public static deleteById: (id: number, aServer ?: Server) => Promise<IHtrTicketUserReadRecord>;
    public static unDeleteById: (id: number, aServer ?: Server) => Promise<IHtrTicketUserReadRecord>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class HtrTicketUserReadCrudClassProxy extends CrudClassProxy<IHtrTicketUserReadRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const htrTicketUserReadCrudClassProxy = new HtrTicketUserReadCrudClassProxy(HtrTicketUserReadCrud);
registerCrudClassProxy(htrTicketUserReadCrudClassProxy);
