import * as React from 'react';
import { __ } from '@src/translation';

type ProgressProps = {
    chapterLessons: any,
    lesson: { id: number, headno: number, lesson_uri_segment: string },
    chapter: { headno: number },
    book: { uri_segment: string }
}

type HeaderProgressBarState = {
    data: any,
    hasJSON: boolean
}

export default class HeaderProgressBar extends React.Component<ProgressProps, HeaderProgressBarState> {
    constructor(props: any) {
        super(props);
        this.state = {
            data: {},
            hasJSON: false
        };
    }

    componentDidMount() {
        this.reloadIdovonal();

        $(".ProgressBar .ProgressBar-icon").on("click", function (e) {
            e.preventDefault();
            var $bar = $(".ProgressBar");

            $bar.children(".ProgressBar-step").removeClass("is-current").removeClass("is-complete");
            $(this).parents(".ProgressBar-step").addClass("is-current").prevAll().addClass("is-complete").find(".is-current").nextAll().removeClass("is-complete");
            //$bar.find(".progress-line").css("width", $(this).data('progress') +'%');
        });
        ($(".ProgressBar") as any).foundation();
    }

    componentDidUpdate(prevProps: ProgressProps) {
        if (prevProps.book.uri_segment != this.props.book.uri_segment) {
            this.reloadIdovonal();
        }

        $(".ProgressBar .ProgressBar-icon").on("click", function (e) {
            e.preventDefault();
            var $bar = $(".ProgressBar");

            $bar.children(".ProgressBar-step").removeClass("is-current").removeClass("is-complete");
            $(this).parents(".ProgressBar-step").addClass("is-current").prevAll().addClass("is-complete").find(".is-current").nextAll().removeClass("is-complete");
            //$bar.find(".progress-line").css("width", $(this).data('progress') +'%');
        });
        ($(".ProgressBar") as any).foundation();

    }

    reloadIdovonal() {
        this.setState({ data: {} });

        $.ajax({
            type: 'GET',
            dataType: 'json',
            url: "/book/" + this.props.book.uri_segment + "/idovonal.json",
            success: (response) => {
                this.setState({ data: response, hasJSON: true });
            }

        });

    }

    render() {

        let progressBar: any;
        let progressList: any[] = [];
        let chapter = String(this.props.chapter.headno);

        let _chapter = this.props.chapterLessons.find((chapter: any) => chapter.lessons.some((_lesson: any) => _lesson.id == this.props.lesson.id));

        let lesson = this.props.lesson.headno ? String(this.props.lesson.headno) :
            this.props.lesson.lesson_uri_segment.includes(__('osszefoglalas')) ? "x" : "";

        if (this.state.data[chapter] && this.state.data[chapter][lesson]) {
  
            let rekordok = this.state.data[chapter][lesson].rekordok;
            if (rekordok.length) {
                let timelineColor = this.state.data[chapter][lesson].timelinecolor ? this.state.data[chapter][lesson].timelinecolor : "";
                if ($('#header').hasClass("large-header")) {
                    $('#header').removeClass("large-header");
                }
                let rekordokLastIndex = this.state.data[chapter][lesson].rekordok.length - 1;
                $.each(this.state.data[chapter][lesson].rekordok, function (i: number, item) {
                    let prevItem = i > 0 ? rekordok[i - 1] : item;
                    let tooltipPos = i == 0 ? "left" : rekordokLastIndex == i ? "right" : "center";
                    let tooltipHOffset = i == 0 ? -20 : rekordokLastIndex == i ? -20 : 0;
                    progressList.push(
                        // <li className="ProgressBar-step" style={{width : item[1] + "%"}}>
                        <li className="ProgressBar-step" style={{ left: prevItem[1] + "%", right: (100 - item[1]) + "%" }} key={"rekord" + i}>
                            {/* <div className="ProgressBar-progress"><span style={{ backgroundColor: item[3] ? item[3] : "#ffffff" }}></span></div> */}
                            <span data-click-open="true" data-disable-hover="false" data-position="top" data-alignment={tooltipPos} data-v-offset="15" data-h-offset={tooltipHOffset} tabIndex={1} data-tooltip title={item[2]} className="ProgressBar-icon" style={{ backgroundColor: timelineColor }}></span> {/* ennek kell szín */}
                            {/* <span data-progress={item[1]} className="ProgressBar-icon has-tip" data-disable-hover="false" tabIndex={1} aria-haspopup="true" data-click-open="true" data-tooltip title={item[2]} data-position="top" data-alignment="center" data-v-offset="15"></span> */}
                            <div className={"ProgressBar-Info " + (i % 2 == 0 ? "bottom" : "top")}>{item[0]}</div>
                        </li>)

                });

                progressBar = <ol className="ProgressBar">
                    {progressList}
                    <li className="progress-bar" ><span style={{ backgroundColor: timelineColor }}></span>{/* meg ennek a span-nek kell szín pl: style={{backgroundColor: "blue"}}*/}</li>
                </ol>;

                let backgroundColor = this.state.data[chapter][lesson].backgroundcolor ? this.state.data[chapter][lesson].backgroundcolor : "";

                return <div className="book-progress" style={{ backgroundColor: backgroundColor }}>
                    <div className="large-10 pc" id="mainProgress">
                        {progressBar}
                    </div>
                </div>
            } else if (rekordok.length == 0 || !rekordok) {
                $('#header').addClass("large-header");
            }

        }
        else if (this.state.hasJSON) {
            $('#header').addClass("large-header");
        }

        return "";

    }

}
