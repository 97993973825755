import * as React from 'react';
// import './RunSharedByMe.css';
import { match } from 'react-router';
import ExerciseContainerComponent, { ExerciseSeriesInfo } from '../exercise/ExerciseContainerComponent';
import { ExerciseServer } from '../exercise/ExerciseServer';

import { app, history } from '@src/index';
import { BubbleLoader } from 'react-css-loaders';
import { Breadcrumbs } from '../Breadcrumbs';
import { PATH_CONTENT_STORE, PATH_CONTENT_STORE_SHARED_BY_ME, PATH_SHARED_CONTENT_BY_ME, PATH_SHARED_CONTENT_RUN_CHECK_MINE, PATH_CONTENT_STORE_SHARED_WITH_ME, PATH_SHARED_CONTENT_WITH_ME } from '@src/Routes';
import SharedContentRunCrud, { ISharedContentRunRecord } from '@src/framework/crud/usr/SharedContentRunCrud';
import { formatDate, formatDateTime, roundToDecimals } from '@src/Util';
import { ContentDetails, getContentDetails } from '../filemanager/ContentUtils';
import ViewSharedByMe, { IViewSharedByMeRecord } from '@src/framework/view/usr/ViewSharedByMe';
import { HTMLTextarea } from '../HtmlTextarea';
import { Accordion, AccordionItem } from '@src/component/ui/Accordion';
import PermissionPage from '@src/framework/PermissionPage';
import { CrudPermissionType } from '@src/framework/security/PermissionCache';
import ViewSharedWithMe from '@src/framework/view/usr/ViewSharedWithMe';
import { __ } from '@src/translation';
import { OpenEndedExerciseAnswer } from '../exercise/Editor/OpenEndedExerciseAnswer';
import OpenEndedQuestionCrud from '@src/framework/crud/usr/OpenEndedQuestionCrud';
import { EditorPanel, EditorPage, EditorSplit } from '../ui/Panel';
import { ClassroomModule } from '@src/module/classroomModule/classroomModule';
import { MemberCardFromId } from '@src/framework/forms/MemberCard';
import SharedContentCrud from '@src/framework/crud/usr/SharedContentCrud';
import { distanceInWordsToNow } from 'date-fns';
import DiscussionCards from '@src/module/messengerModule/components/DiscussionCards';

const SHARE_MODE_EXAM_ID = 3;

type RunSharedByMeProps = {
    match: match<{ sharedContentRunId: string }>
}

type RunSharedByMeState = {
    exerciseServer?: ExerciseServer;
    sharedContentRun?: ISharedContentRunRecord;
    sharedContent?: IViewSharedByMeRecord;
    contentDetails?: ContentDetails;
    seriesInfo?: ExerciseSeriesInfo
    isDataChanged: boolean;
    newPoints: number,
    isReadOnly: boolean,
}

export class RunSharedByMe extends React.Component<RunSharedByMeProps, RunSharedByMeState> {

    constructor(props: RunSharedByMeProps) {
        super(props);
        let isReadOnly = this.props.match && this.props.match.path.startsWith(PATH_SHARED_CONTENT_RUN_CHECK_MINE);

        this.state = {
            isDataChanged: false,
            newPoints: 0,
            isReadOnly
        }
    }

    componentDidMount() {
        this.reloadAsync();
        this.doFoundation();
    }

    componentDidUpdate(prevProps: RunSharedByMeProps) {
        if (prevProps.match.params.sharedContentRunId !== this.props.match.params.sharedContentRunId) {
            this.reloadAsync();
        }
    }


    doFoundation() {
        if (this.refs && this.refs.offCanvas) {
            ($(this.refs.offCanvas) as any).foundation();
        }
        else {
            setTimeout(this.doFoundation.bind(this), 100);
        }
    }



    async reloadAsync() {
        const exerciseServer = new ExerciseServer(false);

        try {
            const sharedContentRunId = Number(this.props.match.params.sharedContentRunId);

            const sharedContentRun = (await SharedContentRunCrud.load(sharedContentRunId)).record;
            const sharedContent = this.state.isReadOnly ? (await ViewSharedWithMe.load(sharedContentRun.shared_content_id!)).record : (await ViewSharedByMe.load(sharedContentRun.shared_content_id!)).record;
            const contentDetails = await getContentDetails(sharedContent.table_info_id!, sharedContent.rec_id!);

            exerciseServer.setShareContentRunId(Number(this.props.match.params.sharedContentRunId));

            await exerciseServer.startSeries();
            let seriesInfo = exerciseServer.getSeriesInfo();
            let scale: number = sharedContent.max_points ? sharedContent.max_points! / seriesInfo.totalPoints : 1;
            let totalSucPercent: number = sharedContent.max_points ? (seriesInfo.earnedPoints * scale / sharedContent.max_points) : (seriesInfo.earnedPoints / seriesInfo.totalPoints);
            let newPercent = sharedContentRun.shared_by_success_percent != null ? sharedContentRun.shared_by_success_percent : totalSucPercent;

            let newPoints = sharedContent.max_points ? roundToDecimals(Number(sharedContent.max_points * newPercent), 2) : roundToDecimals(Number(seriesInfo.totalPoints * newPercent), 2);
            this.setState({
                exerciseServer,
                sharedContentRun,
                sharedContent,
                seriesInfo,
                contentDetails,
                newPoints
            });

        } catch (e) {
            app.showErrorFromJsonResult(e);
        }
    }

    handlePointChange(event: React.FormEvent<HTMLInputElement>) {
        if (this.state.sharedContentRun && this.state.seriesInfo) {
            let newPoint = roundToDecimals(Number(event.currentTarget.value), 2);

            this.setState({
                newPoints: newPoint,
                isDataChanged: true
            });
        }
    }

    handleDescriptionChange(text: any) {
        if (this.state.sharedContentRun) {
            this.setState({ sharedContentRun: { ...this.state.sharedContentRun, shared_by_comments_html: text }, isDataChanged: true });
        }
    }

    async onSave() {
        if (!this.state.sharedContentRun || !this.state.seriesInfo) return;

        try {
            let serInfo = this.state.seriesInfo;

            if (!this.state.newPoints) {
                app.showError(__("Hiányzó adat!"), __("Az elért pontszám nem lehet üres mező!"));
                return;
            }
            if (this.state.newPoints < 0) {
                app.showError(__("Hiba!"), __("Az elért pontszám nem lehet negatív szám!"));
                return;
            }
            if ((this.state.sharedContent!.max_points && this.state.newPoints > this.state.sharedContent!.max_points) || (!this.state.sharedContent!.max_points && this.state.newPoints > serInfo.totalPoints)) {
                app.showError(__("Hiba!"), __("A saját értékelésnél elért pont nem lehet több a maximálisan elérhető pontszámnál!"));
                return;
            }
            let newSucPercent: number = this.state.sharedContent!.max_points ? (this.state.newPoints / this.state.sharedContent!.max_points) : (this.state.newPoints / serInfo.totalPoints);
            let contentRunToSave: ISharedContentRunRecord = {
                id: this.state.sharedContentRun.id,
                shared_by_comments_html: this.state.sharedContentRun.shared_by_comments_html,
                shared_by_success_percent: newSucPercent
            }
            const crud = new SharedContentRunCrud(contentRunToSave as any);
            const response = await crud.put();
            this.setState({
                sharedContentRun: response.record,
                isDataChanged: false
            });
            app.showSuccess(__("Étékelés elmentve"), __("Az értékelés sikeresen elmentve"));

        } catch (ex) {
            app.showErrorFromJsonResult(ex);
        }
    }

    render() {
        const sharedContent = this.state.sharedContent;
        const sharedContentRun = this.state.sharedContentRun;

        if (this.state.exerciseServer && this.state.seriesInfo && sharedContent && this.state.sharedContentRun && this.state.contentDetails) {
            let runDataList: { totalPoints?: number, successPercent?: number, filteredExerciseJSON: any, evaluationTimeStamp?: any }[] = this.state.sharedContentRun.start_params.exercises;
            let seriesInfo: ExerciseSeriesInfo = this.state.seriesInfo;
            let maxPoints: number = sharedContent.max_points ? sharedContent.max_points : seriesInfo.totalPoints;
            let scale: number = sharedContent.max_points ? sharedContent.max_points! / seriesInfo.totalPoints : 1;

            let totalSucPercent: number = sharedContent.max_points ? roundToDecimals((seriesInfo.earnedPoints * scale / sharedContent.max_points * 100), 1) : (seriesInfo.earnedPoints / seriesInfo.totalPoints); //Math.round((Math.round(seriesInfo.earnedPoints) / seriesInfo.totalPoints) * 100);
            let newSucPercent: number = sharedContent.max_points ? roundToDecimals(((this.state.newPoints / sharedContent.max_points) * 100), 1) : roundToDecimals((this.state.newPoints / seriesInfo.totalPoints) * 100, 2);
            let earnedPoints: number = sharedContent.max_points ? roundToDecimals(seriesInfo.earnedPoints * scale, 2) : roundToDecimals(seriesInfo.earnedPoints, 2);

            let timeOfCurrentEval: Date = new Date(Date.parse(this.state.sharedContentRun.creation_time!));
            let returnURL = "";

            let links: any[] = [];
            //If the component received from links to the Breadcrumbs we use them
            if (history.location.state && history.location.state.links) {
                links = history.location.state.links;
            }
            else if (sharedContent.shared_from_institute_group_id) {
                links = ClassroomModule.getTaskBreadCrumbs(sharedContent.shared_from_institute_group_id, sharedContent.shared_from_institute_group_name || "");
                if (this.state.isReadOnly) {
                    //PATH_SHARED_CONTENT_BY_ME + "/" +
                    links.push({ name: sharedContent.title, path: PATH_SHARED_CONTENT_WITH_ME + "/" + sharedContent.id });
                } else {
                    links.push({ name: sharedContent.title, path: PATH_SHARED_CONTENT_BY_ME + "/" + sharedContent.id });
                }
            }
            else {
                if (this.state.isReadOnly) {
                    links = [
                        { name: __("Tartalomtár"), path: PATH_CONTENT_STORE },
                        { name: __("Nekem kiosztott feladatok"), path: PATH_CONTENT_STORE_SHARED_WITH_ME },
                        { name: sharedContent.title, path: PATH_SHARED_CONTENT_WITH_ME + "/" + sharedContent.id },
                    ];

                } else {
                    links = [
                        { name: __("Tartalomtár"), path: PATH_CONTENT_STORE + "/sajat" },
                        { name: __("Általam kiosztott feladatok"), path: PATH_CONTENT_STORE_SHARED_BY_ME },
                        { name: sharedContent.title, path: PATH_SHARED_CONTENT_BY_ME + "/" + sharedContent.id },
                    ];
                }
            }
            returnURL = links[links.length - 1].path;
            links.push({ name: formatDate(this.state.sharedContentRun.creation_time) });

            if (sharedContent.table_info_id == OpenEndedQuestionCrud.TABLE_INFO_ID) {
                return <div>
                    <div className="row column">
                        <PermissionPage requirements={
                            {
                                crud: { tableInfoId: SharedContentRunCrud.TABLE_INFO_ID, permissions: CrudPermissionType.U }
                            }}>
                            <Breadcrumbs links={links} />
                            <div>
                                <legend><h4>{__("Értékelés" + ": ")}</h4><MemberCardFromId memberId={this.state.sharedContentRun.run_by_id} /></legend>
                                {sharedContent.due_date && <p>{__("Leadás határideje")}: {formatDate(sharedContent.due_date)}</p>}

                                <OpenEndedExerciseAnswer sharedContentId={this.state.sharedContent!.id!} sharedContentRunId={this.state.sharedContentRun.id!}
                                    recordId={sharedContent.rec_id!}
                                    isTeacher={!this.state.isReadOnly}
                                    isEvaluated={true}
                                    returnUrl={returnURL}
                                />
                            </div>
                        </PermissionPage>
                    </div>
                </div>;
            }

            let lastEvalTime: Date = new Date(runDataList[runDataList.length - 1].evaluationTimeStamp!);
            let totalTime: Date = new Date(lastEvalTime.getTime() - timeOfCurrentEval.getTime());


            return <PermissionPage requirements={
                {
                    crud: { tableInfoId: SharedContentRunCrud.TABLE_INFO_ID, permissions: CrudPermissionType.U }
                }}>
                <EditorPage>
                    <Breadcrumbs links={links} />
                    <EditorSplit defaultSize={"50%"}>
                        <EditorPanel closable={true}>

                            <div className="shared-by-me-editor-wrapper">
                                <div>
                                    <legend><h4>{__("Értékelés") + ": "}</h4><MemberCardFromId memberId={this.state.sharedContentRun.run_by_id} /></legend>
                                    {sharedContent.due_date && <p>{__("Leadás határideje")}: {formatDate(sharedContent.due_date)}</p>}

                                    <Accordion>
                                        <AccordionItem key={"exe_list_accordion"} title={__("Részfeladatok")}>
                                            <div>
                                                <div className="row large-12 expanded  shared-by-me-undescore">
                                                    <div className="medium-4 column shared-by-me-editor-bf">{__("Feladat címe")}</div>
                                                    <div className="medium-2 column shared-by-me-editor-bf">{__("Max. pont")}</div>
                                                    <div className="medium-2 column shared-by-me-editor-bf">{__("Elért pont")}</div>
                                                    <div className="medium-2 column shared-by-me-editor-bf">{__("Eredmény")}(%)</div>
                                                    <div className="medium-2 column shared-by-me-editor-bf">{__("Idő")}</div>
                                                </div>
                                                {(runDataList) ?
                                                    runDataList.map((curr_ex: any, index_j: number) => {
                                                        let currTotal = roundToDecimals((curr_ex.totalPoints * scale), 2);
                                                        let currPoint = roundToDecimals(((curr_ex.totalPoints * curr_ex.successPercent) * scale), 2);
                                                        let spentTime: Date = new Date(new Date(curr_ex.evaluationTimeStamp)!.getTime() - timeOfCurrentEval.getTime());
                                                        timeOfCurrentEval = new Date(curr_ex.evaluationTimeStamp!);

                                                        return (<div className="row expanded">
                                                            <div className="medium-4 column">{curr_ex.filteredExerciseJSON.title}</div>
                                                            <div className="medium-2 column">{currTotal}</div>
                                                            <div className="medium-2 column">{currPoint}</div>
                                                            <div className="medium-2 column">{roundToDecimals(curr_ex.successPercent * 100, 1)}%</div>
                                                            {curr_ex.evaluationTimeStamp ?
                                                                <div className="medium-2 column">{formatDateTime(spentTime)}</div>
                                                                : ""}

                                                        </div>)
                                                    })
                                                    : ""
                                                }
                                                <div className="row large-12 expanded  shared-by-me-undescore"></div>

                                                <div className="row large-12 expanded ">
                                                    <div className="medium-4 column shared-by-me-editor-bf">{__("Összesítés")}</div>
                                                    <div className="medium-2 column shared-by-me-editor-bf">{maxPoints}</div>
                                                    <div className="medium-2 column shared-by-me-editor-bf">{earnedPoints}</div>
                                                    <div className="medium-2 column shared-by-me-editor-bf">{totalSucPercent}%</div>
                                                    {lastEvalTime ?
                                                        <div className="medium-2 column shared-by-me-editor-bf">{formatDateTime(totalTime)}</div>
                                                        : ""}
                                                </div>
                                                <div className="row large-12 expanded ">
                                                    <div className="medium-6 column shared-by-me-editor-bf">{this.state.isReadOnly ? __("Tanári értékelés") : __("Saját értékelés")}</div>
                                                    <div className="medium-2 column shared-by-me-editor-bf">
                                                        {this.state.isReadOnly ? this.state.newPoints :
                                                            <input type="number" min="0" name="newPercent"
                                                                value={this.state.newPoints || undefined}
                                                                onChange={this.handlePointChange.bind(this)} />
                                                        }
                                                    </div>
                                                    <div className="medium-2 column shared-by-me-editor-bf">{newSucPercent}%</div>
                                                </div>

                                            </div>
                                        </AccordionItem>
                                        <AccordionItem key={"runtime_accordion"} title={__("Idő")}>
                                            <div>
                                                <div className="row large-12 expanded ">
                                                    <div className="medium-3 column">{__("Kitöltés ideje")}:</div>
                                                    <div className="medium-3 column">{formatDate(this.state.sharedContentRun.run_time)}</div>
                                                    <div className="medium-3 column">{__("Kitöltésre adott idő")}:</div>
                                                    <div className="medium-3 column">-</div>
                                                </div>

                                            </div>
                                        </AccordionItem>
                                        <AccordionItem key={"exe_list_accordion"} title={__("Tanári értékelés")}>
                                            <div className="row expanded">
                                                <div className="large-12 columns">
                                                    <label className="shared-by-me-editor-bf">{__("Szöveges értékelés")}</label>
                                                    {this.state.isReadOnly ?
                                                        (
                                                            this.state.sharedContentRun!.shared_by_comments_html ? <div dangerouslySetInnerHTML={{ __html: this.state.sharedContentRun!.shared_by_comments_html || "" }}></div>
                                                                : <em>{__("Nincs szöveges értékelés..")}</em>
                                                        )

                                                        :
                                                        <HTMLTextarea
                                                            value={this.state.sharedContentRun.shared_by_comments_html || ""}
                                                            onChange={this.handleDescriptionChange.bind(this)}
                                                        />
                                                    }
                                                </div>
                                            </div>
                                        </AccordionItem>
                                    </Accordion>


                                    <div className="row">
                                        {!this.state.isReadOnly &&
                                            <div className="medium-4 column">
                                                <button className="button success expanded eke-general-buttons" disabled={!this.state.isDataChanged} onClick={this.onSave.bind(this, false)}>
                                                    <i className="fa fa-save" />  {__("Mentés")}
                                                </button>
                                            </div>
                                        }
                                        <div className="medium-4 column">
                                            <button className="button hollow" data-toggle="messagesOffCanvas">
                                                <i className="fa fa-envelope" />  {__("Megjegyzések")}
                                            </button>
                                        </div>
                                    </div>
                                    <div className="off-canvas-wrapper message-canvas">
                                        <div ref="offCanvas" className="off-canvas position-right is-closed" data-close-on-click={false} id="messagesOffCanvas" data-off-canvas>

                                            <div className="message-tools">
                                                <button onClick={() => (this.refs.messagePanel as DiscussionCards).asyncReload()}><i style={{ float: "right", padding: "10px" }} className="fas fa-sync-alt"></i></button>

                                                <button className="close-button" aria-label="Close menu" type="button" data-toggle="messagesOffCanvas">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div className="message-panel">
                                                {sharedContentRun && sharedContentRun.run_by_id && <DiscussionCards ref="messagePanel"
                                                    table_info_id={SharedContentCrud.TABLE_INFO_ID}
                                                    record_id={sharedContent.id!}
                                                    subject={sharedContent.title!}
                                                    user_id={this.state.isReadOnly ? sharedContent.shared_by_id! : sharedContentRun.run_by_id}
                                                />}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </EditorPanel>
                        <EditorPanel className="right" closable={true}>

                            <div className="shared-by-me-editor-wrapper">
                                <h4>{__("Visszajátszás")}</h4>
                                <ExerciseContainerComponent
                                    server={this.state.exerciseServer}
                                    examMode={sharedContent.share_mode_id === SHARE_MODE_EXAM_ID}
                                    replay={true}
                                />
                            </div>

                        </EditorPanel>
                    </EditorSplit>
                </EditorPage>

            </PermissionPage>
        } else {
            return <BubbleLoader />;
        }
    }

}
