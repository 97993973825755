import * as React from "react";
import { me } from "@src/framework/server/Auth";
import ViewUsrMessage, { IViewUsrMessageRecord } from "@src/framework/view/usr/ViewUsrMessage";
import { history, app } from "@src/index";
import { BubbleLoader } from 'react-css-loaders';
import { __ } from "@src/translation";
import { formatDate } from "@src/Util";
import { HTMLTextarea } from "@src/component/HtmlTextarea";
import { whiteSpaceChecker } from "@src/component/WhiteSpaceChecker";
import { messengerModule } from "../messengerModule";
import { IOutgoingMessage, IMessageTreeNode } from "../messengerAPI";
import { HeaderNotificationStore } from "@src/component/HeaderNotification";
import AccessDeniedPage from "@src/framework/pages/AccessDeniedPage";
import * as Scrollbar from "react-smooth-scrollbar";
import { AutoTextarea } from "@src/component/ui/AutoTextarea";
import { format, parse } from "date-fns";

type MessageCardsProps = {
    /** A megnyitott üzenet azonosítója. Ez alapján töltődnek a további üzenetek is a getMessageTree()-val. */
    message_id: number
}

type MessageCardsState = {
    loading: boolean;
    messages: IViewUsrMessageRecord[];
    openedMessage: IViewUsrMessageRecord;
    newMessage: IOutgoingMessage;
    validationMessages: Map<string, string>,
    HTMLEditor: boolean,
    replyToAll: boolean //Egyelőre BBC tervezés miatt, címzettek nélkül ezt nem tudom megvalósítani
}
export default class MessageCards extends React.Component<MessageCardsProps, MessageCardsState> {

    constructor(props: MessageCardsProps) {
        let validationMap: Map<string, string> = new Map<string, string>();
        let newMessage: IOutgoingMessage = { subject: "", body_html: "", reply_to_id: null, recipient_ids: [] }
        super(props);
        this.state = {
            loading: true,
            openedMessage: {},
            messages: [],
            newMessage,
            validationMessages: validationMap,
            HTMLEditor: true,
            replyToAll: false // Egyelőre ez fixen false
        }
    }

    componentDidMount() {
        this.asyncReload();
    }

    componentDidUpdate() {
        if (this.refs && this.refs.scrollbar) {
            const scrollbar = (this.refs.scrollbar as Scrollbar).scrollbar;
            scrollbar.scrollTo(undefined, scrollbar.size.content.height);
        }
    }

    public async asyncReload() {

        let emptyMsg = { subject: "", body_html: "", reply_to_id: null, recipient_ids: [] }

        if (!me) return;
        let message_id = this.state.openedMessage.id || this.props.message_id;

        this.setState({ loading: true });

        let convertedMessages: IViewUsrMessageRecord[] = [];

        try {
            let openedMessage;
            //Ha adtunk meg konkrét üzenet ID-t. Itt kérjük le az üzenetváltás további üzeneteit (korábbi üzenetek). API
            if (message_id) {
                openedMessage = (await ViewUsrMessage.load(message_id)).record;
                let meRecipient: boolean = openedMessage.recipients.some((r: any) => r.sec_user_id == me!.id);
                if (meRecipient) {
                    await messengerModule.setSeen([message_id]);
                    HeaderNotificationStore.changed();
                }
                const openedMessageTime = openedMessage.creation_time!;
                //Lekérjük az üzenet fát
                const actualTree = await messengerModule.getMessageTree(message_id, true);
                const messages = this.getMessagesFromTree(actualTree, [], 0);

                // Frontend time szűrés, a megnyitottnál korábbi üzeneteket kérjük csak le
                const openedTime = parse(openedMessageTime).getTime();
                const filteredMessages = messages.filter((m) => {return parse(m.creation_time).getTime() <= openedTime });
                convertedMessages = this.convertTreeNodes(filteredMessages);

            }

            if (!openedMessage) return;

            this.setState({ messages: convertedMessages, openedMessage, loading: false, newMessage: emptyMsg });

        } catch (error) {
            app.showErrorFromJsonResult(error);
        }

    }

    onNewMessageChanged(propertyName: string, event: any) {
        let newMessage = this.state.newMessage;
        newMessage[propertyName] = event.target ? event.target.value : event;
        this.setState({ newMessage });
    }

    getMessagesFromTree(tree: IMessageTreeNode, array: IMessageTreeNode[], level: number): IMessageTreeNode[] {
        //Belerakjuk a tömbbe az aktuálisat
        array.push({ ...tree, level: level });
        //Ha van replies, továbbjárjuk
        if (tree && tree.replies != []) {
            level++;
            tree.replies.forEach(rep => {
                return this.getMessagesFromTree(rep, array, level);
            });
        }
        //Egyébként visszaadjuk a kigyűjtötteket
        return array;
    }

    convertTreeNodes(treeNodes: IMessageTreeNode[]): IViewUsrMessageRecord[] {
        let messages: IViewUsrMessageRecord[] = [];
        treeNodes.map(tn => {
            messages.push({
                id: tn.id,
                is_active: tn.is_active,
                subject: tn.subject,
                creation_time: tn.creation_time,
                creation_user_id: Number(tn.creation_user_id),
                sender_email: tn.email,
                sender_login_name: tn.login_name,
                sender_fullname: tn.fullname,
                body_html: tn.body_html
            });
        })
        return messages;
    }

    async onSend() {

        let validationMessages = new Map<string, string>();
        let newMessage = this.state.newMessage;

        if (!this.props.message_id) return;

        try {

            const openedMessage = this.state.openedMessage;

            if (!newMessage || !me) return;
            let error_message = "";
            let resp;

            if (!whiteSpaceChecker(newMessage.body_html)) {
                error_message = __("Az üzenet szövege kötelező!");
                app.showError(__("Hiba"), error_message);
                validationMessages.set("body_html", error_message);
            }

            if (validationMessages.size > 0) {
                this.setState({ validationMessages });
                return;
            }
            newMessage.subject = "Re: " + openedMessage.subject!;
            let myMessage: boolean = me.id == openedMessage.creation_user_id;
            let recIds: number[] = [];
            //Ha enyém a megnyitott üzenet, akkor az összes címzettnek küldöm vissza az üzenetet
            if (myMessage) {
                for (const rec of openedMessage.recipients) {
                    recIds.push(rec.sec_user_id);
                }
            }
            //Egyelőre erre az ágra nem juthatunk, BCC tervezés miatt nem látjuk az összes címzettet
            else if (this.state.replyToAll) {
                for (const rec of openedMessage.recipients) {
                    if (rec.sec_user_id != me.id) recIds.push(rec.sec_user_id);
                }
                recIds.push(openedMessage.creation_user_id!);
            }
            //Ha nem az enyém, és nem reply_to_all akkor az eredeti üzenet feladójának válaszolok
            else {
                recIds = [openedMessage.creation_user_id!];
            }

            newMessage.recipient_ids = recIds;

            newMessage.reply_to_id = openedMessage.id!
            resp = await messengerModule.pushMessage(openedMessage.table_info_id || null, openedMessage.rec_id || null, openedMessage.id!, newMessage);
            history.push(messengerModule.PATH_MESSAGES_VIEW + "/" + resp.id);
            this.setState({ openedMessage: resp });

            app.showSuccess(__("Elküldve"), __("Az üzenet elküldve"));

            this.asyncReload();

        } catch (error) {
            app.showErrorFromJsonResult(error);
        }
    }

    render() {

        if (!me || !me.id) {
            return <AccessDeniedPage />;
        }

        if (this.state.loading) {
            return <BubbleLoader />;
        }

        let messages = this.state.messages;
        let newMessage = this.state.newMessage;

        return <div className="message-cards-wrapper">

            <Scrollbar alwaysShowTracks={true} ref="scrollbar" className="message-scroll-wrapper" style={{ flex: 1 }}>
                <div className="message-cards-area">

                    {messages && messages.length > 0
                        ?
                        messages.map((m: IViewUsrMessageRecord) => {
                            let myMessage = (me && me.id == m.creation_user_id);
                            let messageClass: string = myMessage ? "my-message" : "other-message";
                            return (<div className="message-cards" key={m.id}>
                                <div className="messages-show">
                                    <div className={"callout " + messageClass}>
                                        <div className="row expanded">
                                            <div className="column small-9 medium-9 large-9 text-left"><b>{`${m.sender_fullname} <${m.sender_email}>`}</b></div>
                                            <div className="column text-right">{formatDate(m.creation_time)}</div>
                                        </div>
                                        <div className="column" dangerouslySetInnerHTML={{ __html: m.body_html! }}></div>
                                    </div>
                                </div>
                            </div>)
                        })
                        : <div className="column">{__("Ebben az üzenetváltásban még nincs üzenet...")}</div>
                    }
                </div>
            </Scrollbar>

            <div className="messagebox-area">


                {
                    this.state.HTMLEditor
                        ?
                        <>
                            <div className="">
                                <label>{__("Új üzenet szövege")} <span style={{ color: "#b30000" }}>*</span>
                                    <span className="exe-editor-validation-msg">{this.state.validationMessages.get("body_html")}</span>
                                </label>

                                <HTMLTextarea
                                    value={newMessage.body_html || ""}
                                    onChange={this.onNewMessageChanged.bind(this, "body_html")}
                                    tools={"full"}
                                />
                            </div>
                            <div className="text-right">
                                <button className="button success eke-general-buttons"
                                    onClick={this.onSend.bind(this)}>
                                    <i className="fas fa-paper-plane"></i> {__("Elküldés")}
                                </button>
                                <button className={"button clear " + (this.state.HTMLEditor && "active")} title={__("Formázás kikapcsolása")} onClick={() => this.setState({ HTMLEditor: !this.state.HTMLEditor })}><i className="fas fa-font"></i></button>
                            </div>
                        </>
                        :
                        <>
                            <AutoTextarea buttons={<button title={__("Formázás")} onClick={() => this.setState({ HTMLEditor: !this.state.HTMLEditor })}><i className="fas fa-font"></i></button>} text={newMessage.body_html || ""} onSubmit={this.onSend.bind(this)} onTextChange={this.onNewMessageChanged.bind(this, "body_html")}></AutoTextarea>
                        </>
                }


            </div>
        </div>
    }

}

