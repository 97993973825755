import * as React from 'react';

import Tree, { TreeNode } from 'rc-tree';
import { me, hasGroup, Groups } from '@src/framework/server/Auth';
import OoFolderCrud from '@src/framework/crud/media/OoFolderCrud';
import { MAX_SYSTEM_FOLDER_ID } from '@src/component/filemanager/MediaFileChooser';
import { __ } from '@src/translation';
import { contentStoreModule } from '@src/module/contentStoreModule/contentStoreModule';

// Az rx-tree nem szereti a null id-ket
const COMMON_ROOT_FOLDER = -1;

type FolderTreeProps = {
    rootFolderId: number | null;
    onFolderSelected: (folderId: number) => void
}

type FolderItem = {
    id: number,
    name: string,
    children: FolderItem[]
}

type FolderTreeState = {
    roots: FolderItem[];
    folders: {[key: number]: FolderItem}
}

export default class FolderTree extends React.Component<FolderTreeProps, FolderTreeState> {

    constructor(props: FolderTreeProps) {
        super(props);

        const roots: FolderItem[] = [];

        if (me && 
                this.props.rootFolderId && 
                this.props.rootFolderId !== me.oo_folder_id &&
                this.props.rootFolderId !== COMMON_ROOT_FOLDER
                ) {
            roots.push({
                id: this.props.rootFolderId,
                name: __("Gyökér mappa"),
                children: []
            });
        }

        if (me && me.oo_folder_id) {
            roots.push({
                id: me.oo_folder_id,
                name: __("Saját tartalmaim"),
                children: []
            });
        }

        if (me && hasGroup(me, Groups.OFIEditor)) {
            roots.push({
                id: contentStoreModule.getCommonFolderId() || COMMON_ROOT_FOLDER,
                name: __("Közös tartalom"),
                children: []
            });
        }
        
        const folders = {};

        roots.map(folder => folders[folder.id] = folder);

        this.state = {
            roots,
            folders
        };
    }

    async onLoadNode(treeNode: any) {
        var id = treeNode.props.eventKey;
        
        const list = await OoFolderCrud.list({
            filter: {
                parent_id: id != COMMON_ROOT_FOLDER ? Number(id) : null, 
                is_active: true,
            },
            order_by: "title",
        });

        this.state.folders[id].children = list
                .filter(folder => folder.id! > MAX_SYSTEM_FOLDER_ID)
                .map(folder => {
            const node = {
                id: folder.id!,
                name: folder.title!,
                children: []
            }
            this.state.folders[folder.id!] = node;
            
            return node;
        });
        
        this.setState({
            roots: this.state.roots,
            folders: this.state.folders
        });
    }

    onSelect(selectedFolderIds: string[]) {
        this.props.onFolderSelected(Number(selectedFolderIds[0]));
    }

    render() {

        const loop = (data: FolderItem[]): any[] => {
            return data.map((item) => {
              if (item.children) {
                return <TreeNode title={item.name} key={item.id || COMMON_ROOT_FOLDER}>{loop(item.children)}</TreeNode>;
              }
              return (
                <TreeNode title={item.name} key={item.id}
                />
              );
            });
      };

        return <Tree 
            loadData={this.onLoadNode.bind(this)} 
            showIcon={false}
            onSelect={this.onSelect.bind(this)}
             >
                {loop(this.state.roots)}
            </Tree>
    }
}
