import { Server } from '../../server/Server';
import Crud , { registerCrudClassProxy, IAsTextResult, IListParams, CrudClassProxy, ISearchTextParams, ISearchTextResult } from '../Crud';

export interface ITableInfoRecord {
    /**
     * Globálisan egyedi azonosító.
     * 
     * Ha új rekordot akarsz létrehozni put() metódussal, akkor hagyd üresen.
     * upsert() hívásnál figyelmen kívül van hagyva az értéke.
     *
     */
    id ?: number;
    /**
     * Globálisan egyedi azonosító
     */
    table_guid?: string;
    /**
     * Név
     */
    schema_name?: string;
    /**
     * Táblázat név (adatbázisban)
     */
    table_name?: string;
    /**
     * Idősoros tárolás
     */
    is_journaled?: boolean;
    /**
     * Logikai törlés
     */
    is_logdel?: boolean;
    /**
     * Tulajdonságok
     */
    props?: any|null;
}

/**
 *  meta.table_info - Táblázat információ
 */

export default class TableInfoCrud extends Crud<ITableInfoRecord> {
    public static TABLE_INFO_ID = 504913410;
    public static getSchemaNameForClass() { return 'meta'; }
    public static getTableNameForClass() { return 'table_info'; }

    public static load: (id: number, aServer ?: Server) => Promise<TableInfoCrud>;
    public static asText: (id: number, includeRecord : boolean, aServer ?: Server) => Promise<IAsTextResult<ITableInfoRecord>>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<ITableInfoRecord[]>;
    public static searchText: (params: ISearchTextParams, aServer ?: Server) => Promise<ISearchTextResult<ITableInfoRecord>[]>;
    public static deleteById: (id: number, aServer ?: Server) => Promise<ITableInfoRecord>;
    public static unDeleteById: (id: number, aServer ?: Server) => Promise<ITableInfoRecord>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class TableInfoCrudClassProxy extends CrudClassProxy<ITableInfoRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const tableInfoCrudClassProxy = new TableInfoCrudClassProxy(TableInfoCrud);
registerCrudClassProxy(tableInfoCrudClassProxy);
