import { Server } from '../../server/Server';
import Crud , { registerCrudClassProxy, IAsTextResult, IListParams, CrudClassProxy, ISearchTextParams, ISearchTextResult } from '../Crud';

export interface IStationConstraintMatrixItemRecord {
    /**
     * Globálisan egyedi azonosító.
     * 
     * Ha új rekordot akarsz létrehozni put() metódussal, akkor hagyd üresen.
     * upsert() hívásnál figyelmen kívül van hagyva az értéke.
     *
     */
    id ?: number;
    /**
     * Rekord létrehozás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel létrehozásakor.
     */
    creation_time?: string /*timestamp?*/;
    /**
     * Ki hozta létre
     *
     * NOT NULL -> sys.sec_user ON DELETE noaction
     *
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozó felhasználóra hivatkozik.
     */
    creation_user_id?: number;
    /**
     * Melyik session-ből jött létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozáshoz használt munkamenetre hivatkozik.
     */
    creation_session_id?: number|null;
    /**
     * Utolsó módosítás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel módosításakor.
     */
    modification_time?: string /*timestamp?*/|null;
    /**
     * Ki módosította utoljára
     *
     * -> sys.sec_user ON DELETE noaction
     *
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosító felhasználóra hivatkozik.
     */
    modification_user_id?: number|null;
    /**
     * Melyik session-ből módosították utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosításhoz használt munkamenetre hivatkozik.
     */
    modification_session_id?: number|null;
    /**
     * Folyamat állapot megszorítás mátrix
     *
     * NOT NULL -> wf.station_constraint_matrix ON DELETE noaction
     *
     */
    station_constraint_matrix_id?: number;
    /**
     * Főfolyamat állapot
     *
     * -> wf.station ON DELETE cascade
     *
     */
    master_station_id?: number|null;
    /**
     * Alfolyamat állapot
     *
     * -> wf.station ON DELETE cascade
     *
     */
    detail_station_id?: number|null;
    /**
     * Engedélyezett
     */
    enabled?: boolean;
}

/**
 *  wf.station_constraint_matrix_item - Folyamat állapot megszorítás elem
 *
 *  Idősoros táblázat, ami az összes tétel összes korábbi verzióját eltárolja.
 */

export default class StationConstraintMatrixItemCrud extends Crud<IStationConstraintMatrixItemRecord> {
    public static TABLE_INFO_ID = 1431604755;
    public static getSchemaNameForClass() { return 'wf'; }
    public static getTableNameForClass() { return 'station_constraint_matrix_item'; }

    public static load: (id: number, aServer ?: Server) => Promise<StationConstraintMatrixItemCrud>;
    public static asText: (id: number, includeRecord : boolean, aServer ?: Server) => Promise<IAsTextResult<IStationConstraintMatrixItemRecord>>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<IStationConstraintMatrixItemRecord[]>;
    public static searchText: (params: ISearchTextParams, aServer ?: Server) => Promise<ISearchTextResult<IStationConstraintMatrixItemRecord>[]>;
    public static deleteById: (id: number, aServer ?: Server) => Promise<IStationConstraintMatrixItemRecord>;
    public static unDeleteById: (id: number, aServer ?: Server) => Promise<IStationConstraintMatrixItemRecord>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class StationConstraintMatrixItemCrudClassProxy extends CrudClassProxy<IStationConstraintMatrixItemRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const stationConstraintMatrixItemCrudClassProxy = new StationConstraintMatrixItemCrudClassProxy(StationConstraintMatrixItemCrud);
registerCrudClassProxy(stationConstraintMatrixItemCrudClassProxy);
