import { Server } from '../../server/Server';
import Crud , { registerCrudClassProxy, IAsTextResult, IListParams, CrudClassProxy, ISearchTextParams, ISearchTextResult } from '../Crud';

export interface IHtrTicketMessageRecord {
    /**
     * Globálisan egyedi azonosító.
     * 
     * Ha új rekordot akarsz létrehozni put() metódussal, akkor hagyd üresen.
     * upsert() hívásnál figyelmen kívül van hagyva az értéke.
     *
     */
    id ?: number;
    /**
     * Rekord létrehozás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel létrehozásakor.
     */
    creation_time?: string /*timestamp?*/;
    /**
     * Ki hozta létre
     *
     * NOT NULL -> sys.sec_user ON DELETE noaction
     *
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozó felhasználóra hivatkozik.
     */
    creation_user_id?: number;
    /**
     * Melyik session-ből jött létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozáshoz használt munkamenetre hivatkozik.
     */
    creation_session_id?: number|null;
    /**
     * Utolsó módosítás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel módosításakor.
     */
    modification_time?: string /*timestamp?*/|null;
    /**
     * Ki módosította utoljára
     *
     * -> sys.sec_user ON DELETE noaction
     *
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosító felhasználóra hivatkozik.
     */
    modification_user_id?: number|null;
    /**
     * Melyik session-ből módosították utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosításhoz használt munkamenetre hivatkozik.
     */
    modification_session_id?: number|null;
    /**
     * Aktív
     *
     * Logikai törléshez használt mező.
     */
    is_active?: boolean;
    /**
     * HTR bejelentés
     *
     * NOT NULL -> ticket.htr_ticket ON DELETE cascade
     *
     */
    htr_ticket_id?: number;
    /**
     * Üzenet
     *
     * Üzenet HTML formában. Képek használhatók a bejelentés mappjából.
     */
    message_html?: string;
}

/**
 *  ticket.htr_ticket_message - HTR bejelentés üzenet
 *
 *  Idősoros táblázat, ami az összes tétel összes korábbi verzióját eltárolja.
 */

export default class HtrTicketMessageCrud extends Crud<IHtrTicketMessageRecord> {
    public static TABLE_INFO_ID = 2860772488;
    public static getSchemaNameForClass() { return 'ticket'; }
    public static getTableNameForClass() { return 'htr_ticket_message'; }

    public static load: (id: number, aServer ?: Server) => Promise<HtrTicketMessageCrud>;
    public static asText: (id: number, includeRecord : boolean, aServer ?: Server) => Promise<IAsTextResult<IHtrTicketMessageRecord>>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<IHtrTicketMessageRecord[]>;
    public static searchText: (params: ISearchTextParams, aServer ?: Server) => Promise<ISearchTextResult<IHtrTicketMessageRecord>[]>;
    public static deleteById: (id: number, aServer ?: Server) => Promise<IHtrTicketMessageRecord>;
    public static unDeleteById: (id: number, aServer ?: Server) => Promise<IHtrTicketMessageRecord>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class HtrTicketMessageCrudClassProxy extends CrudClassProxy<IHtrTicketMessageRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const htrTicketMessageCrudClassProxy = new HtrTicketMessageCrudClassProxy(HtrTicketMessageCrud);
registerCrudClassProxy(htrTicketMessageCrudClassProxy);
