import * as React from 'react';

/*
  Azok a mezők, amik közösek a workflow-t megjelenítő View-kban.
*/  
export interface IWorkflowStateView {
    wf_workflow_id?: number|null;
    wf_station_id?: number|null;
    wf_station_name?: string|null;
    wf_station_description?: string|null;
    wf_station_style?: any|null;
    wf_substation_id?: number|null;
    wf_substation_name?: string|null;
    wf_substation_description?: string|null;
    wf_status_text?: string|null;    
}

/* Title attribútum előállítása */
export const getWfStateTitle = (workflowStateView: IWorkflowStateView) : string => {
    return "" + 
        (workflowStateView.wf_station_name || "") + " \n " 
        + (workflowStateView.wf_substation_name || "") + " \n " + 
        (workflowStateView.wf_status_text || "");
}

/* Szöveg előállítása (pl. gombra) */
export const getWfStateText = (workflowStateView: IWorkflowStateView) : JSX.Element => {
    return <>
        {workflowStateView.wf_station_name || ""} 
        {workflowStateView.wf_status_text? <>&nbsp; <i className="far fa-comment"/></>: ""}
        {workflowStateView.wf_substation_name? <small><br/> {workflowStateView.wf_substation_name}</small> : "" }
        </>;
}

export const getWfStateCell = ((data: any, column: any, extraContent?: any) => {
    return <span 
        title={getWfStateTitle(data.original)}
        style={data.original.wf_station_style || {}}
    >{getWfStateText(data.original)}
    {extraContent}
    </span>;

})
