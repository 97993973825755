import './style.css';
import { ExerciseEngineHelper } from '@src/component/exercise/engine/ExerciseEngineHelper';
import { ExerciseBaseClass, AnswerElement, NavigationDirections } from '@src/component/exercise/models/ExerciseBaseClass';
import { ExerciseEngineSubSeries, AExerciseEngine } from '../../models/AExerciseEngine';
import { EKEPairingServer } from './EKEPairingServer';

export interface PairingData extends ExerciseBaseClass {
    pair1: string[];
    pair2: string[];
    options: any;
    pair1images: boolean;
    pair2images: boolean;
    keywords: string;
    is_horizontal: boolean;
    num_of_questions: number;
    isExam: boolean;
}

type PairingSolution = {
    key: number;
    value: number;
}
type PairingUserSolution = {
    fullmatch: boolean;
    answer: PairingSolution[];
}


export class EKEPairingExerciseEngine extends ExerciseEngineSubSeries {

    private radiobuttonList: HTMLInputElement[] = [];
    private original_pair2: AnswerElement[] = [];
    private labels: HTMLElement[] = [];
    private originalIndex: number = 0;
    private actualQuestionIndex: number = 0;  // hanyadik elemnél tartunk
    private elementsToShow: any[] = [];  // megjelenítendő elemek eredeti indexekkel
    private elementsRandomized: any[] = []; // az előző tömb elemei random sorrendben, figyelembe véve a kérdések limitált számát
    private imgDiv: HTMLElement = document.createElement("div");
    private userSolutions: any[] = [];

    initExercise(params: ExerciseParams): void {
        super.initExercise(params);
        let exercise: PairingData = params.exercise;
        if (exercise && exercise.options)
            this.exercise = exercise;
        else return;

        this.elementsToShow = Object.assign([], exercise.options.pair1);
        this.elementsRandomized = [];

        this.reloadResourceFunc = params.reloadResources;

        this.userReadyWithSeriesFunc = params.userReadyWithSubSeries;

        this.radiobuttonList = [];
        this.labels = [];
        this.root.classList.add("eke-pairing");

        this.setIllustration(exercise, this.root);

        var gridDiv = this.root.appendChild(document.createElement("div"));
        gridDiv.classList.add("grid-x");
        gridDiv.classList.add("grid-padding-x");
        gridDiv.classList.add("align-middle");
        gridDiv.classList.add("row");

        this.imgDiv = gridDiv.appendChild(document.createElement("div"));
        this.imgDiv.classList.add("medium-6", "small-12");
        this.imgDiv.classList.add("columns");
        this.imgDiv.classList.add("exe-question-container");

        if (exercise.pair1images) {
            let pair1 = this.imgDiv.appendChild(document.createElement("img"));
            pair1.classList.add("pairing-img");
        } else {
            let pair1 = this.imgDiv.appendChild(document.createElement("div"));
            pair1.classList.add("pairing-text");
        }

        var answersdiv = gridDiv.appendChild(document.createElement("div"));
        answersdiv.classList.add("medium-6", "small-12");
        answersdiv.classList.add("columns");

        this.original_pair2 = exercise.options.pair2;

        //We make an array to push only the text/image string value of pair2 objects.
        //We need this array at setting the data-index of radio buttons

        var pair2Values: AnswerElement[] = [];
        for (let i = 0; i < exercise.options.pair2.length; i++) {
            let curr_val = exercise.options.pair2[i];

            let found = pair2Values.find(function (element) {
                return (element.type == curr_val.type && element.text == curr_val.text && element.image == curr_val.image && element.url == curr_val.url);
            });

            if (!found) {
                pair2Values.push(exercise.options.pair2[i]);
            }
        }

        var randomgroup = Math.floor(Math.random() * 1000);
        let i = 0;
        for (var curr_pair2 of pair2Values) {
            i++;
            if (curr_pair2.type == "") {
                continue;
            } // kérdések limitálása során üressé tett elemeket nem jelenítjük meg

            var answerdiv = answersdiv.appendChild(document.createElement("div"));

            let unique_id = "pair" + randomgroup + "_" + i;
            var radiobutton = answerdiv.appendChild(document.createElement("input"));
            radiobutton.setAttribute("type", "radio");
            radiobutton.setAttribute("name", "answer" + randomgroup);
            radiobutton.setAttribute("id", unique_id);
            radiobutton.setAttribute("data-index", String(this.original_pair2.indexOf(curr_pair2)));
            if (this.isSNIexc) radiobutton.addEventListener('change', this.onChange.bind(this), false);

            if (this.isReplay) {
                radiobutton.setAttribute('disabled','disabled');
            }

            var label = answerdiv.appendChild(document.createElement("label"));
            label.setAttribute("for", unique_id);
            label.classList.add("button");
            label.classList.add("clear");
            label.classList.add("answer-item");
            label.innerText = curr_pair2.text;
            this.radiobuttonList.push(radiobutton);
            this.labels.push(label);
            AExerciseEngine.shrinkAndGrow(label); /*  TGY */
        }

        for (var index = 0; index < this.elementsToShow.length; index++) {
            if ((exercise.options.pair1[index].type == "text" && exercise.options.pair1[index].text)
                || (exercise.options.pair1[index].type == "image" && exercise.options.pair1[index].image)
                || (exercise.options.pair1[index].type == "sound" && exercise.options.pair1[index].url)) // kérdések limitálása során üressé tett elemek itt sem kerülnek keverésre
                this.elementsRandomized.push(this.elementsToShow[index]);
        }
        if (!exercise.ordered_answers) this.elementsRandomized = AExerciseEngine.shuffle(this.elementsRandomized);

        this.actualQuestionIndex = -1;
        this.showNextQuestion(NavigationDirections.Next);
    }

    public getNextSubQuestion(direction: NavigationDirections): SubSeriesQuestionData {
        if (this.actualQuestionIndex + direction == this.elementsRandomized.length
            || this.actualQuestionIndex + direction < 0)
            return { is_next_available: false, number_of_questions: this.elementsRandomized.length, current_question_index: this.actualQuestionIndex };

        this.showNextQuestion(direction);
        return { is_next_available: true, number_of_questions: this.elementsRandomized.length, current_question_index: this.actualQuestionIndex };
    }

    onChange() {
        //We evaluate the exercise in SNI mode at every step
        this.SNIEvaluation(EKEPairingServer);
    }

    showNextQuestion(direction: NavigationDirections) {
        if (!this.exercise) return;

        this.actualQuestionIndex += direction;
        //if (this.actualQuestionIndex == this.elementsRandomized.length-1) { 
        //this.userReadyWithSeriesFunc(true); 
        //Hívjuk a konténer metódusát, ami megjeleníti a modalt a boolnak megfelelően           
        //  return;
        //}

        for (var index = 0; index < this.radiobuttonList.length; index++) {
            var radiobutton = this.radiobuttonList[index];
            radiobutton.checked = false;
            var label = this.labels[index];
            AExerciseEngine.removeEvalStyle(label);
        }

        var cur_label = this.root.querySelector('[id=\"processLabel\"]');
        if (cur_label)
            cur_label.textContent = " " + (this.actualQuestionIndex + 1);

        this.originalIndex = this.elementsToShow.indexOf(this.elementsRandomized[this.actualQuestionIndex]);

        this.imgDiv.innerText = "";
        let containerDiv = this.imgDiv.appendChild(document.createElement("div"));
        let classList: string[] = [];
        //Image pair1
        if (this.exercise && this.elementsRandomized[this.actualQuestionIndex].type == "image") {
            classList = ["pairing-img", "exe-large-img"];
            // AExerciseEngine.displayAnswer(containerDiv, this.elementsRandomized[this.actualQuestionIndex],this.is_accessible, ["pairing-img", "exe-large-img"], this);
        } else {
            classList = ["pairing-text-div", "pairing-div"];
        }

        AExerciseEngine.displayAnswer(containerDiv, this.elementsRandomized[this.actualQuestionIndex], this.is_accessible, classList, this);
        if (this.reloadResourceFunc) this.reloadResourceFunc();
    }


    getUserSolution(): PairingUserSolution {

        for (var index = 0; index < this.radiobuttonList.length; index++) {
            var radiobutton = this.radiobuttonList[index];

            if (radiobutton.checked) {
                var sol = {
                    key: this.originalIndex,
                    value: Number(radiobutton.getAttribute("data-index"))
                };
                if (this.userSolutions.length == this.actualQuestionIndex) {
                    this.userSolutions.push(sol);
                } else {
                    this.userSolutions[this.actualQuestionIndex] = sol;
                }
                break;
            }
        }

        if(this.isExamMode && (this.actualQuestionIndex + 1) == this.elementsRandomized.length) {
            this.userReadyWithSeriesFunc(true);
        }

        let solution = { answer: this.userSolutions, fullmatch: false };
        return solution;
    }

    receiveEvaluation(evaluated: Evaluated): void {
        let wrongIndex: number = -1;
        for (var index = 0; index < this.radiobuttonList.length; index++) {
            var radiobutton = this.radiobuttonList[index];
            var label = this.labels[index];
            AExerciseEngine.removeEvalStyle(label);
            if (radiobutton.checked) {
                if (evaluated.success) {
                    label.classList.add("exe-engine-check-correct");
                }
                else {
                    label.classList.add("exe-engine-check-wrong");
                    wrongIndex = index;
                }
            }
        }
        //If we have SNI mode and not the correct solution, we remove the wrong styling after 1 sec
        if (this.isSNIexc && !evaluated.success) {
            setTimeout(() => {
                AExerciseEngine.removeEvalStyle(this.labels[wrongIndex]);
                this.radiobuttonList[wrongIndex].checked = false;
            },
                1000);
        }

        if ((this.actualQuestionIndex + 1) == this.elementsRandomized.length) {
            this.userReadyWithSeriesFunc(evaluated.success);
        }
    }


    showCorrectSolution(solution: any[]): void {
        for (var index = 0; index < solution.length; index++) {
            if (solution[index].key == this.originalIndex) {
                let correctAnswer = this.original_pair2[solution[index].value].text;
                let currText = "";
                for (let i = 0; i < this.radiobuttonList.length; i++) {

                    AExerciseEngine.removeEvalStyle(this.labels[i]);

                    let dataIdx = Number(this.radiobuttonList[i].getAttribute("data-index"));
                    currText = this.original_pair2[dataIdx].text;
                    if (currText == correctAnswer) {
                        this.radiobuttonList[i].checked = true;
                        this.labels[i].classList.add("exe-engine-correct-bg");
                    }
                }
            }
        }
    }

    isUserReady(): boolean {
        for (var index = 0; index < this.radiobuttonList.length; index++) {
            var radiobutton = this.radiobuttonList[index];
            if (radiobutton.checked) {
                return true;
            }
        }
        return false;
    }

    showHelp(solution: any): void {
        this.showCorrectSolution(solution);
    }


}