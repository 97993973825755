import * as React from 'react';
import { observer } from 'mobx-react';

import { parse } from 'date-fns';
import CalendarAPI, { IDayItem, IActivitySummary, ActivitySummaryMap } from './CalendarAPI';
import { app } from '@src/index';
import DayActivity from './DayActivity';
import { IActivityLogRecord } from '@src/framework/crud/usr/ActivityLogCrud';
import { CalendarNavigator } from './CalendarNavigator';
import { MonthCalendar } from './MonthCalendar';
import { appStore } from '@src/store/AppStore';
import { observable, runInAction, reaction, IReactionDisposer, ObservableMap } from 'mobx';
import { me } from '@src/framework/server/Auth';


const dateKey = ( date: Date) : string => {
    return date.toISOString();
}

@observer
export class MyMunyiCalendarPage extends React.PureComponent<{}> {
    private loaderDisposer : IReactionDisposer|null = null;
    @observable private summary: ObservableMap<string, IActivitySummary>;

    constructor(props: {}) {
        super(props);
        this.summary = observable.map({});        
    }

    protected get userId() {
        return me!.id!;
    }

    private get calendarSource() {
        return appStore.myMunyiCalendar;
    }

    private set hasChange (value:boolean) {
        runInAction(() => { this.calendarSource.hasChange = value;} )        
    }

    componentDidMount() {
        this.calendarSource.firstLoad();
        this.loaderDisposer = reaction(
            () => [this.calendarSource.year, this.calendarSource.month],
            this.asyncReload
        );
        this.asyncReload();
    }

    componentWillUnmount() {
        if (this.loaderDisposer) { this.loaderDisposer(); this.loaderDisposer = null; }
    }

    /* Az egész hónap summary-ját újratölti. */
    private asyncReload = async () => {
        try {
            let summaryList = await CalendarAPI.getActivitySummary(
                this.calendarSource.startDate, this.calendarSource.endDate, this.userId);
            let summary: ActivitySummaryMap = {};
            summaryList.forEach((item: IActivitySummary) => {
                summary[dateKey(item.date)] = item
            });
            runInAction(() => {
                this.hasChange = false;
                this.summary.replace(summary);
            })            
        } catch (error) {
            runInAction(() => {
                this.hasChange = false;
            });
            app.showErrorFromJsonResult(error);
        }
    }

    /** Egyetlen nap summary-ját tölti újra. */
    private reloadSummaryForDay = async (day: string) => {
        const date = parse(day);
        try {
            let summaryList = await CalendarAPI.getActivitySummary(date, date, this.userId);
            runInAction(() => {
                this.summary.delete(dateKey(date));
                summaryList.forEach((item: IActivitySummary) => {
                    this.summary.set(dateKey(item.date), item);
                });
            })
        } catch (error) {
            app.showErrorFromJsonResult(error);
        }
    }

    private renderActivitySummary = (item: IDayItem) => {
        const key = dateKey(item.date);
        if (this.summary.has(key)) {
            const summary = this.summary.get(key)!;
            return <div className="eke-calendar-day-activity-summary">{summary.cnt}</div>;
        } else {
            return null;
        };
    }

    public render() {
        const ms = this.calendarSource;
        return <div className="row collapse expanded eke-calendar-page">
            <div className="column small-12 large-12 eke-calendar-navigator">
                <CalendarNavigator calendarMonthSource={this.calendarSource} />
            </div>
            <div className="row expanded eke-calendar">
                <div className="column small-12 medium-12 large-6 eke-calendar-grid">
                    <MonthCalendar calendarMonthSource={this.calendarSource} dayItemRenderer={this.renderActivitySummary} />
                </div>
                <div className="column small-12 medium-12 large-6 eke-calendar-activity">
                    <DayActivity
                        item={ms.selectedItem}
                        onItemSaved={(item: IActivityLogRecord) => this.reloadSummaryForDay(item.day!)}
                        onItemDeleted={(item: IActivityLogRecord) => this.reloadSummaryForDay(item.day!)}
                    />
                </div>
            </div>
        </div>;
    }
}

