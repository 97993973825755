import { Server } from '../../server/Server';
import Crud , { registerCrudClassProxy, IAsTextResult, IListParams, CrudClassProxy, ISearchTextParams, ISearchTextResult } from '../Crud';

export interface ISecTransitionPermRecord {
    /**
     * Globálisan egyedi azonosító.
     * 
     * Ha új rekordot akarsz létrehozni put() metódussal, akkor hagyd üresen.
     * upsert() hívásnál figyelmen kívül van hagyva az értéke.
     *
     */
    id ?: number;
    /**
     * Rekord létrehozás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel létrehozásakor.
     */
    creation_time?: string /*timestamp?*/;
    /**
     * Ki hozta létre
     *
     * NOT NULL -> sys.sec_user ON DELETE noaction
     *
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozó felhasználóra hivatkozik.
     */
    creation_user_id?: number;
    /**
     * Melyik session-ből jött létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozáshoz használt munkamenetre hivatkozik.
     */
    creation_session_id?: number|null;
    /**
     * Utolsó módosítás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel módosításakor.
     */
    modification_time?: string /*timestamp?*/|null;
    /**
     * Ki módosította utoljára
     *
     * -> sys.sec_user ON DELETE noaction
     *
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosító felhasználóra hivatkozik.
     */
    modification_user_id?: number|null;
    /**
     * Melyik session-ből módosították utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosításhoz használt munkamenetre hivatkozik.
     */
    modification_session_id?: number|null;
    /**
     * Folyamat átmenet típus
     *
     * NOT NULL -> wf.transition_type ON DELETE noaction
     *
     */
    transition_type_id?: number;
    /**
     * Engedély birtokos
     *
     * NOT NULL -> sys.sec_perm_owner ON DELETE cascade
     *
     */
    sec_perm_owner_id?: number;
    /**
     * Feladat
     *
     * Ha ez hamisra van állítva, akkor nem okozza a folyamat megjelenését a tennivalók listában.
     */
    is_task?: boolean;
}

/**
 *  wf.sec_transition_perm - Folyamat átmenet jog
 *
 *  Azt mondja meg, hogy kik végezhetnek el egy átmenet típust.
 */

export default class SecTransitionPermCrud extends Crud<ISecTransitionPermRecord> {
    public static TABLE_INFO_ID = 1295502475;
    public static getSchemaNameForClass() { return 'wf'; }
    public static getTableNameForClass() { return 'sec_transition_perm'; }

    public static load: (id: number, aServer ?: Server) => Promise<SecTransitionPermCrud>;
    public static asText: (id: number, includeRecord : boolean, aServer ?: Server) => Promise<IAsTextResult<ISecTransitionPermRecord>>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<ISecTransitionPermRecord[]>;
    public static searchText: (params: ISearchTextParams, aServer ?: Server) => Promise<ISearchTextResult<ISecTransitionPermRecord>[]>;
    public static deleteById: (id: number, aServer ?: Server) => Promise<ISecTransitionPermRecord>;
    public static unDeleteById: (id: number, aServer ?: Server) => Promise<ISecTransitionPermRecord>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class SecTransitionPermCrudClassProxy extends CrudClassProxy<ISecTransitionPermRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const secTransitionPermCrudClassProxy = new SecTransitionPermCrudClassProxy(SecTransitionPermCrud);
registerCrudClassProxy(secTransitionPermCrudClassProxy);
