import './style.css';
import { ExerciseBaseClass, AnswerElement } from '@src/component/exercise/models/ExerciseBaseClass';
import { ExerciseEngineHelper } from '../ExerciseEngineHelper';
import { AExerciseEngine, GET_EMPTY_IMAGE_ANSWER_ALT_TEXT } from '../../models/AExerciseEngine';
import { EKESudokuServer } from './EKESudokuServer';

export interface SudokuData extends ExerciseBaseClass {
    stronglinevertical: number;
    stronglinehorizontal: number;
    outersetheader: string;
    elements: string[][];
    fix_elements: AnswerElement[][];
    answers: string[];
    options: AnswerElement[];
    infinite_elements: boolean;
    sudoku_mode: any;
    show_drop_area: boolean;
    imageanswers:boolean;
}

type SudokuSolution = {
    answer: number[];
    fullmatch: boolean;
}

export class EKESudokuExerciseEngine extends AExerciseEngine {

    private droppabledivlist: HTMLDivElement[] = [];
    private divlist: HTMLElement[] = [];
    private gotcorrectsolution: boolean = false;
    private infinite_elements: boolean = false;
    private emptyColumnIndex: number = -1;
    private drag_clone_mode: boolean = true;
    

    initExercise(params: ExerciseParams): void {
        super.initExercise(params);
        let exercise:SudokuData = params.exercise;
        
        if (!exercise || !exercise.fix_elements)
            return;
        this.infinite_elements = exercise.infinite_elements;
        
        this.root.classList.add("eke-sudoku");

        let taskWrapper = this.root.appendChild(document.createElement("div"));
        taskWrapper.classList.add("task-wrapper");

        if (exercise.illustration != "" && exercise.illustration != null) {
            var imgWrapper = taskWrapper.appendChild(document.createElement("div"));
            imgWrapper.classList.add("wrapper-margin");
            this.setIllustration(exercise,imgWrapper);
        }

        this.droppabledivlist = [];
        this.divlist = [];
        this.gotcorrectsolution = false;

        let exerciseWrapper = taskWrapper.appendChild(document.createElement("div"));
        exerciseWrapper.classList.add("exercise-wrapper");

        let exerciseControl = exerciseWrapper.appendChild(document.createElement("div"));
        exerciseControl.setAttribute("class", "exercise-control");

        let exerciseContainer = exerciseWrapper.appendChild(document.createElement("div"));
        exerciseContainer.classList.add("exercise-container");
        this.root.setAttribute("data-zoom", "1");

        let tablediv = exerciseContainer.appendChild(document.createElement("div"));
        tablediv.classList.add("table-div");
        tablediv.classList.add("auto-cells");

        let table = tablediv.appendChild(document.createElement("table"));
        table.classList.add("sudoku-table");

        //Variable to store the maximum lenght of the rows (needed for coordinate or chess mode only)
        var maxRowLenght = 0;
        for (let i = 0; i < exercise.fix_elements.length; i++) {
            if (exercise.fix_elements[i].length > maxRowLenght) maxRowLenght = exercise.fix_elements[i].length;
            let tr = table.appendChild(document.createElement("tr"));
            tr.classList.add("sud-tr");
            //In case we have chess or coordinate mode
            if (exercise.sudoku_mode == 'chess' || exercise.sudoku_mode == 'coordinate') {
                //We make an extra column for displaying the number of line
                var specialModeTd = tr.appendChild(document.createElement("td"));
                specialModeTd.classList.add("sud-td", "special-td-left");
                specialModeTd.innerText = (exercise.fix_elements.length - i).toString();
                //We make an extra row, to display the number of columns (or ABC... at chess mode)
                if (i == exercise.fix_elements.length - 1) {
                    var specialExtraTr = table.appendChild(document.createElement("tr"));
                    specialExtraTr.classList.add("sud-tr");
                    let specialExtraTd = specialExtraTr.appendChild(document.createElement("td"));
                    specialExtraTd.classList.add("sud-td");
                    specialExtraTd.innerText = " ";
                    for (let i = 0; i < maxRowLenght; i++) {
                        let specialBottomColumn = specialExtraTr.appendChild(document.createElement("td"));
                        specialBottomColumn.classList.add("sud-td", "special-td-bottom");
                        //Coordinate mode: Numbers - Chess mode: ABC letters
                        if (exercise.sudoku_mode == 'coordinate') specialBottomColumn.innerText = (i + 1).toString();
                        else {
                            specialBottomColumn.innerText = (i + 10).toString(36).toUpperCase(); //ABC...
                        }
                    }
                }
            }

            for (let j = 0; j < exercise.fix_elements[i].length; j++) {
                let td = tr.appendChild(document.createElement("td"));
                td.classList.add("sud-td");
                if (((i + 1) % Math.round(exercise.stronglinehorizontal) == 0) && (i < exercise.fix_elements.length - 1)) td.classList.add("strong-tr");
                if (((j + 1) % Math.round(exercise.stronglinevertical) == 0) && (j < exercise.fix_elements[i].length - 1)) td.classList.add("strong-td");
                //We need to check the text/image property of the current AnswerElement depending on the value of exercise's imageanswers field
                let tempValue;
                exercise.imageanswers ? tempValue = exercise.fix_elements[i][j].image : tempValue = exercise.fix_elements[i][j].text.toString();
                if (tempValue == "") {
                    td.addEventListener('click', this.click.bind(this), false);
                    if (exercise.show_drop_area)
                        td.classList.add("droppable");
                    td.setAttribute('id', i + '_' + j);
                    this.droppabledivlist.push(td);
                } else {
                    if (!tempValue.replace(/\s/g, '').length) {
                        exercise.imageanswers ? exercise.fix_elements[i][j].image = "#ÜRES#" : exercise.fix_elements[i][j].text = "#ÜRES#";
                    }
                    if (exercise.imageanswers) {
                        let image = td.appendChild(document.createElement("img"));
                        /* If media type is image, limit max width */
                        let imageSrc = ExerciseEngineHelper.getMediaMaxWidthUrl(exercise.imagebasepath + exercise.fix_elements[i][j].image);
                        image.setAttribute("src", imageSrc);
                        for (const element of exercise.options) {
                            if (exercise.fix_elements[i][j].image == element.image) {
                                let altText = this.is_accessible != false ? element.text : GET_EMPTY_IMAGE_ANSWER_ALT_TEXT() + " - " + element.text;
                                image.setAttribute("alt", altText);
                                image.setAttribute("title", altText);
                                if (element.image == "#ÜRES#") image.style.display = 'none';
                                break;
                            }
                        }
                        image.classList.add("sudoku-image", "exe-img-no-zoom");
                    } 
                    //Text answers
                    else {
                        //If current element is empty, we should display " " inside DIV otherwise the value of the element 
                        td.innerText = exercise.fix_elements[i][j].text == "#ÜRES#" ? " " : exercise.fix_elements[i][j].text;
                    }
                }
            }
        }
        ($(this.droppabledivlist) as any).droppable({
            accept: ".show-droppable",
            classes: {
                "ui-droppable-active": "ui-state-active",
                "ui-droppable-hover": "ui-state-hover"
            },
            drop: function (event: any, ui: any) {
                $(this)
                    .addClass("ui-state-highlight")
            },
            tolerance: "pointer"
        });

        let answerWrapper = taskWrapper.appendChild(document.createElement("div"));
        answerWrapper.classList.add('answer-wrap');

        let answerContainer = answerWrapper.appendChild(document.createElement("div"));
        answerContainer.classList.add("answer-container");

        for (let index = 0; index < exercise.options.length; index++) {

            if (exercise.options[index]) {
                let tempValue;
                exercise.imageanswers ? tempValue = exercise.options[index].image : tempValue = exercise.options[index].text;

                if (!tempValue.replace(/\s/g, '').length) {
                    exercise.imageanswers ? exercise.options[index].image = "#ÜRES#" : exercise.options[index].text = "#ÜRES#";
                }
                
                let draggableitem = answerContainer.appendChild(document.createElement("div"));
                draggableitem.classList.add("sudoku-draggables");
                if (exercise.show_drop_area) {
                    draggableitem.classList.add("show-droppable");
                }
                if (exercise.imageanswers) {
                    let imageitem = draggableitem.appendChild(document.createElement("img"));
                    imageitem.classList.add("sudoku-image", "exe-img-no-zoom");
                    /* If media type is image, limit max width */
                    let imageItemSrc = ExerciseEngineHelper.getMediaMaxWidthUrl(exercise.imagebasepath + exercise.options[index].image);
                    imageitem.setAttribute("src", imageItemSrc);
                    let altText = this.is_accessible != false ? exercise.options[index].text : GET_EMPTY_IMAGE_ANSWER_ALT_TEXT() + " - " + exercise.options[index].text;
                    imageitem.setAttribute("alt", altText);
                    imageitem.setAttribute("title", altText);
                    //If there is an empty image, we shouldn't display it. And we should store the emptyColumnIndex for evaluation.
                    if (exercise.options[index].image == "#ÜRES#") {
                        draggableitem.style.display = 'none';
                        this.emptyColumnIndex = index;
                    }
                    //Text answers
                } else {
                    draggableitem.innerText = exercise.options[index].text;
                    if (exercise.options[index].text == "#ÜRES#") {
                        draggableitem.style.display = 'none';
                        this.emptyColumnIndex = index;
                    }
                }
                this.divlist.push(draggableitem);
                draggableitem.setAttribute("id", String(index));
                draggableitem.setAttribute("data-index", index + "");
                draggableitem.setAttribute("draggable", "true");

                /* Create draggable elements */
                if (!this.isReplay) {
                    ($(draggableitem) as any).draggable({
                        start: this.drag.bind(this),
                        stop: this.drop.bind(this),
                        drag: this.dragging.bind(this),
                        containment: this.root,
                        helper: 'clone',
                        appendTo: this.root,
                        scroll: true
                    });
                }
            }
        }

        /* Click-to-click simulation */
        if (!this.isReplay) {
            AExerciseEngine.simulateDrag({
                answerItemClass: 'sudoku-draggables',
                draggableItems: this.divlist,
                clickArea: this.root
            });
        }

        let temp: any[] = [];
        for (let i = 0; i < answerContainer.childNodes.length; i++) {
            temp.push(answerContainer.childNodes[i]);
        }
        if (!exercise.ordered_answers) AExerciseEngine.shuffle(temp);
        for (let i = answerContainer.childNodes.length - 1; i >= 0; i--) {
            answerContainer.removeChild(answerContainer.childNodes[i]);
        }
        temp.forEach(element => {
            answerContainer.appendChild(element);
        });
        this.droppabledivlist.push(answerContainer);

        let answerControls = answerWrapper.appendChild(document.createElement('div'));
        answerControls.classList.add("answer-controls");
        let answerLeftButton = answerControls.appendChild(document.createElement("button"));
        answerLeftButton.classList.add("control", "answer-control-left");
        let answerRightButton = answerControls.appendChild(document.createElement("button"));
        answerRightButton.classList.add("control", "answer-control-right");
        answerRightButton.addEventListener("click", AExerciseEngine.xMove.bind(this, answerContainer, 'right'), false);
        answerLeftButton.addEventListener("click", AExerciseEngine.xMove.bind(this, answerContainer, 'left'), false);

        /* Arany szamolo fuggveny */
        function xGetMaxScale(container: any) {
            let naturalWidth = 50;
            let cellWidth = 50;
            let cellImage = container.querySelector('img');

            if ( cellImage ) {
                naturalWidth = cellImage.naturalWidth;
            }

            let cell = container.querySelector('td');
            if ( cell ) {
                cellWidth = cell.offsetWidth;
            }

            let scale = naturalWidth / cellWidth;
            if (scale < 2) {scale = 2;}

            return scale;
        }

        /* Maximális nagyítási arány */
        //let exerciseScale = xGetMaxScale(exerciseContainer);
        let thisRoot = this.root;
        let exerciseScale = 2;
        if (exerciseScale != 1) {
            /* Nagyito gomb */
            let zoomButton = exerciseControl.appendChild(document.createElement("button"));
            zoomButton.classList.add("exercise-zoom");

            /* Gomb esemeny + nagyitas */
            zoomButton.addEventListener("click", function (event) {
                let zoom = Number(thisRoot.getAttribute('data-zoom'));
                zoom = (zoom < exerciseScale) ? zoom + (exerciseScale-1) / 2 : 1;
                exerciseContainer.style.transform = "scale(" + zoom + ")";
                thisRoot.setAttribute('data-zoom', String(zoom));
            }, false);
        } else {
            exerciseControl.setAttribute("hidden", "true");
        }

    }

    getUserSolution(): SudokuSolution {
        let result: any[] = [];
        for (let index = 0; index < this.droppabledivlist.length - 1; index++) {
            if (this.droppabledivlist[index].childNodes.length > 0) {
                result.push(Number((this.droppabledivlist[index].childNodes[0] as HTMLElement).getAttribute("id")));
            }
            else result.push(this.emptyColumnIndex != -1 ? this.emptyColumnIndex : null);
        }
        let solution = { answer: result, fullmatch: true };
        return solution;
    }


    receiveEvaluation(evaluated: Evaluated): void {
        if (evaluated.success) {
            for (let index = 0; index < this.droppabledivlist.length - 1; index++) {
                let htmlelement = this.droppabledivlist[index] as HTMLElement;
                htmlelement.classList.remove("exe-engine-check-wrong");
                htmlelement.classList.add("exe-engine-check-correct");
            }
        } else {
            if (evaluated.solution == null) {
                for (let index = 0; index < this.droppabledivlist.length - 1; index++) {
                    let htmlelement = this.droppabledivlist[index];
                    if (!this.infinite_elements) {
                        $(htmlelement.childNodes[0]).appendTo(this.droppabledivlist[this.droppabledivlist.length - 1]);
                    }
                    else if(this.isReplay) {
                        if(!this.isSNIexc) htmlelement.classList.add("exe-engine-check-wrong");
                    }
                    else {
                        htmlelement.innerText = "";
                    }
                }
            } else {
                for (let index = 0; index < this.droppabledivlist.length - 1; index++) {
                    let htmlelement = this.droppabledivlist[index];
                    if (this.droppabledivlist[index].childNodes.length > 0) {
                        if (evaluated.solution[index] == Number((this.droppabledivlist[index].childNodes[0] as HTMLElement).getAttribute("id"))) {
                            htmlelement.classList.add("exe-engine-check-correct");
                        }
                        else {
                            if (!this.infinite_elements) {
                                if (!this.isReplay) {
                                    //$(htmlelement.childNodes[0]).appendTo(this.droppabledivlist[this.droppabledivlist.length - 1]);
                                    let ansCont:HTMLElement|null = this.root.querySelector(".answer-container");
                                    if(ansCont) AExerciseEngine.moveElementBack(htmlelement.childNodes[0] as HTMLElement,ansCont, this.root);
                                }
                                else {
                                    if(!this.isSNIexc) htmlelement.classList.add("exe-engine-check-wrong");
                                }
                            } else {
                                if (!this.isReplay){
                                    htmlelement.innerText = "";
                                }
                                else {
                                    if(!this.isSNIexc) htmlelement.classList.add("exe-engine-check-wrong");
                                }
                            }
                        }
                    }
                    else {
                        if(this.isReplay) {
                            htmlelement.classList.add("exe-engine-check-wrong");
                        }
                    }
                }
            }
        }
    }

    showCorrectSolution(solution: any): void {
        this.removePlaceHolders(this.root);
        /* Removing checker classes */
        for (let index = 0; index < this.droppabledivlist.length - 1; index++) {
            let htmlelement = this.droppabledivlist[index] as HTMLElement;
            AExerciseEngine.removeEvalStyle(htmlelement);
        }
        for (let index = 0; index < solution.length; index++) {
            if(solution[index] == null && this.isReplay)
                continue;
            this.droppabledivlist[index].innerText = "";
            let correctdiv = this.divlist[solution[index]].cloneNode(true) as HTMLElement;
            correctdiv.classList.add("sudoku-dropped-div");
            correctdiv.classList.remove("sudoku-draggables");
            this.droppabledivlist[index].appendChild(correctdiv);
            if(!this.isReplay)
                this.droppabledivlist[index].classList.add("eke-engine-show-correct-highlight");
            this.gotcorrectsolution = true;
        }
    }

    isUserReady(): boolean {
        for (let index = 0; index < this.droppabledivlist.length - 1; index++) {
            if ((this.droppabledivlist[index].innerText != "" || this.droppabledivlist[index].childElementCount > 0) && !this.gotcorrectsolution) return true;
        }
        return false;
    }

    showHelp(solution: any): void {
        this.showCorrectSolution(solution);
    }

    drop(ev: any) {
        AExerciseEngine.drop(ev, this.droppabledivlist, this.infinite_elements, this);
        if(this.isSNIexc) this.SNIEvaluation( EKESudokuServer);
    }

    allowDrop(ev: any) {
        // ev.preventDefault();
    }

    drag(ev: any) {
        let parentDiv: HTMLElement = (ev.target.parentElement as HTMLElement);
            if (parentDiv.classList.contains("answer-container")) {
                if ((this.simple_style || this.isSNIexc) && !this.infinite_elements) {
                    this.cloneAnswerElement(ev.target, parentDiv);
                }
            }
    }

    dragging(ev: any, ui:any) {
        /* On the fly hit check and add extra class */
        let exerciseContainer: HTMLDivElement | null = this.root.querySelector(".exercise-container");
        let hasArea: any = (exerciseContainer ? AExerciseEngine.hitTest(ev, [exerciseContainer]) : null);
        if (hasArea && hasArea.overlap) {
            ui.helper[0].classList.add('drop-hit');
        } else {
            ui.helper[0].classList.remove('drop-hit');
        }

        if (ev.target.classList.contains("droppped")) {
            if (this.root.getAttribute('data-zoom')) {
                let scale = Number(this.root.getAttribute("data-zoom"));
                ui.position.left = ui.position.left / scale;
                ui.position.top = ui.position.top / scale;
            }
        }
    }

    click(ev: any) {
        if (ev.target.parentNode.classList.contains("sud-td")) {
            if (!this.infinite_elements) {
                $(ev.target).appendTo($(".answer-container"));

            } else {
                ev.target.parentNode.innerHTML = ""; //Pictures must vanish too.
            }
        }
    }

    shuffle(a: any[]) {
        for (let i = a.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [a[i], a[j]] = [a[j], a[i]];
        }
        return a;
    }

    /* Scrollozo fuggveny */
    xMove(div_container: HTMLDivElement, move_direction: string) {
        let container: HTMLDivElement = div_container!;
        let direction = move_direction;
        let childs = container.childElementCount;
        let iwidth = container.scrollWidth / childs / 2;

        let styleLeft = container.style.left;
        if (styleLeft == '') {
            styleLeft = '0px';
        }
        let maxLeft = (container.parentNode! as any).offsetWidth - container.scrollWidth;
        if (maxLeft > 0) {
            maxLeft = 0;
        }
        let mathLeft = parseInt(styleLeft + '');

        switch (direction) {
            case 'left':
                mathLeft = mathLeft + iwidth;
                if (mathLeft > 0) {
                    mathLeft = 0;
                }
                break;
            case 'right':
                mathLeft = mathLeft - iwidth;
                if (mathLeft < maxLeft) {
                    mathLeft = maxLeft;
                }
                break;
            default:
                break;
        }
        container.style.left = mathLeft + 'px';
    }
}