import { Module, BaseMenu, ModuleRoute } from "../Module";
import { __ } from "@src/translation";
import { config } from "@src/framework/server/Server";
import { me, api } from "@src/framework/server/Auth";
import { NotifierCluster } from "./NotifierCluster";

export let notifierModule: NotifierModule<BaseMenu>;

export type NotifierModuleConfig = {}



export class NotifierModule<T extends BaseMenu> extends Module<T> {

    private config: NotifierModuleConfig;
    private _cluster: NotifierCluster | null;

    constructor(config: NotifierModuleConfig) {
        super();
        this.config = config;
        if (this.shouldHaveConnection) {
            this._cluster = new NotifierCluster(this.notifierUrls!)
        } else {
            this._cluster = null;
        }
    }

    public static initGlobal<T extends BaseMenu>(config: NotifierModuleConfig) { notifierModule = new NotifierModule<T>(config); }
    public async getRoutesAsync(): Promise<Record<string, ModuleRoute<T>>> { return this.getRoutes(); }
    public getRoutes(): Record<string, ModuleRoute<T>> { return {}; }
    public getRecordRoute(tableInfoId: number, recordId: number, edit?: boolean): string | null { return null; }
    public getMenus() { return []; }


    /** A notifier szerver URL-jei. */
    public get notifierUrls(): string[] | null {
        return config.mainServer.notifierUrls;
    }

    /** Azt mondja meg, hogy kellene-e hogy legyen kiépítve kapcsolat? */
    public get shouldHaveConnection(): boolean {
        return !!(this.notifierUrls && this.notifierUrls.length > 0 && me && api && api.accessToken)
    }

    public get cluster(): NotifierCluster | null {
        return this._cluster;
    }


}

