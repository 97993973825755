import { __ } from "@src/translation";

// export const REACT_TABLE_LABELS = {
//     previousText: __("Előző"),
//     nextText: __("Következő"),
//     loadingText: __("Betöltés..."),
//     noDataText: __("Nincs adat"),
//     pageText: __("Lap"),
//     ofText: " / ",
//     rowsText: __("sor"),
// };

export function getReactTableLabels() {
    const labels = {
        previousText: __("Előző"),
        nextText: __("Következő"),
        loadingText: __("Betöltés..."),
        noDataText: __("Nincs adat"),
        pageText: __("Lap"),
        ofText: " / ",
        rowsText: __("sor"),
    }
    return labels;
}