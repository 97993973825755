import { Server } from '../../server/Server';
import View , { registerViewClassProxy, ViewClassProxy } from '../View';
import { IListParams } from '@framework/crud/Crud';

export interface IViewKapCourseInstituteSiteRecord {
    /**
     * Azonosító
     *
     * Azonosító (pótkulcs)
     */
    id?: number;
    /**
     * Rekord létrehozás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel létrehozásakor.
     */
    creation_time?: string /*timestamp?*/;
    /**
     * Ki hozta létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozó felhasználóra hivatkozik.
     */
    creation_user_id?: number;
    /**
     * Melyik session-ből jött létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozáshoz használt munkamenetre hivatkozik.
     */
    creation_session_id?: number|null;
    /**
     * Utolsó módosítás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel módosításakor.
     */
    modification_time?: string /*timestamp?*/|null;
    /**
     * Ki módosította utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosító felhasználóra hivatkozik.
     */
    modification_user_id?: number|null;
    /**
     * Melyik session-ből módosították utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosításhoz használt munkamenetre hivatkozik.
     */
    modification_session_id?: number|null;
    /**
     * Aktív
     *
     * Logikai törléshez használt mező.
     */
    is_active?: boolean;
    /**
     * Képzés
     */
    course_id?: number;
    /**
     * Intézmény feladat ellátási hely
     */
    institute_site_id?: number;
    /**
     * Belsős
     *
     * A belsős feladatellátási helyek jelentkezési ablaka eltérő lehet
     */
    is_internal?: boolean;
    /**
     * Ellátási hely azonosító
     *
     * Ellátási hely azonosító
     */
    site_identifier?: string|null;
    /**
     * Ellátási hely neve
     *
     * Ellátási hely neve
     */
    site_name?: string|null;
    /**
     * Ellátási hely címe
     *
     * Ellátási hely címe
     */
    site_address?: string|null;
    /**
     * Ellátási hely irányítószáma
     *
     * Ellátási hely irányítószáma
     */
    site_zipcode?: string|null;
    /**
     * Ellátási hely település
     *
     * Ellátási hely település
     */
    site_city?: string|null;
    /**
     * Intézmény belső azonosító
     *
     * Intézmény belső azonosító
     */
    institute_id?: number|null;
    /**
     * OM azonosító
     *
     * OM azonosító
     */
    om_identifier?: string|null;
    /**
     * Intézmény neve
     *
     * Intézmény neve
     */
    institute_name?: string|null;
}

/**
 *  kap.view_kap_course_institute_site - Képzés meghirdetési helye
 *
 *  Azt mondja meg, hogy mely feladatellátási helyekről vehetik föl a képzést a potenciális hallgatók.
 */

export default class ViewKapCourseInstituteSite extends View<IViewKapCourseInstituteSiteRecord> {
    public static VIEW_INFO_ID = 2020010803;

    public static getSchemaNameForClass(): string { return 'kap'; }
    public static getViewNameForClass(): string { return 'view_kap_course_institute_site'; }
    public static getTableNameForClass(): string|null { return 'course_institute_site'; }

    public static load: (id: number, aServer ?: Server) => Promise<ViewKapCourseInstituteSite>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<IViewKapCourseInstituteSiteRecord[]>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class ViewKapCourseInstituteSiteClassProxy extends ViewClassProxy<IViewKapCourseInstituteSiteRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const viewKapCourseInstituteSiteClassProxy = new ViewKapCourseInstituteSiteClassProxy(ViewKapCourseInstituteSite);
registerViewClassProxy(viewKapCourseInstituteSiteClassProxy);

