import * as React from 'react';
import { Link } from 'react-router-dom';
import { BookBreadcrumbs } from '@component/Breadcrumbs';
import ReactTable from 'react-table';
import { getReactTableLabels } from '@src/framework/i18n';
import 'react-table/react-table.css'
import { me, Groups, hasGroup, hasAnyGroup } from '@src/framework/server/Auth';
import { app } from '@src/index';
import { history } from '@src/index';
import { BubbleLoader } from 'react-css-loaders';
import PermissionPage from '@src/framework/PermissionPage';
import BookCrud, { IBookRecord } from '@src/framework/crud/doc/BookCrud';

import { CrudPermissionType } from '@src/framework/security/PermissionCache';
import { PATH_EDIT_BOOK_NEW, PATH_EDIT_BOOK_TABLE_OF_CONTENTS, PATH_BOOK_VIEW_EDITING, PATH_PUBLISHED_BOOK } from '@src/Routes';
import { TFilterDict } from '@src/framework/crud/Crud';
import { LIBRARY_OFI_OFFICIAL_ID, LIBRARY_PERSONAL_ID, LIBRARY_NKP_ID } from '@src/Const';
import LibraryCrud, { ILibraryRecord } from '@src/framework/crud/doc/LibraryCrud';
import ViewBook, { IViewBookRecord } from '@src/framework/view/doc/ViewBook';
import { copyPublishedBook } from '@src/server/PublicServer';
import { getWfStateCell } from '../wf/wf_util';
import { formatDate } from '@src/Util';
import WfAPI from '@src/framework/wf/WfAPI';
import obtainServer, { config } from '@src/framework/server/Server';
import ButtonGroup, { ButtonSize, ButtonAlign } from '@src/component/ui/ButtonGroup';
import { __ } from '@src/translation';
import { RadioTab, RadioTabs } from '@src/component/ui/RadioTab';
import { confirmDialog, Dialog, DialogContent } from '../Dialog';
import { WorkflowDialog } from '@src/component/wf/WorkflowDialog';
import { ToolTipDiv } from '@src/component/ToolTipDiv';
import ViewBookOfficial from '@src/framework/view/doc/ViewBookOfficial';

class BookListState {
  books?: IViewBookRecord[];
  libraries: ILibraryRecord[];
  selectedLibraryId: number;
  copyingBookId: number | null;
  publishingBookId: number | null;

  workflowDialogOpen: boolean;
  selectedId?: number;
}

export default class BookList extends React.Component<any, BookListState> {
  constructor(props: any) {
    super(props);

    this.state = {
      selectedLibraryId: (me && !hasAnyGroup(me, [Groups.OFIEditor, Groups.KapELearningEditor])) ? LIBRARY_PERSONAL_ID : LIBRARY_OFI_OFFICIAL_ID,
      libraries: [],
      copyingBookId: null,
      publishingBookId: null,
      workflowDialogOpen: false
    };
    this.asyncReload();
  }

  private asyncReload = async () => {
    try {
      var filter: TFilterDict = {
        is_active: true,
        site_id: config.mainServer.siteId
      };

      if (this.state.selectedLibraryId == LIBRARY_PERSONAL_ID) {
        if (!me) throw __("Nincs bejelentkezve!");

        filter.owner_id = me.id;
      } else if (this.state.selectedLibraryId == 0) {
        filter.library_id = LIBRARY_OFI_OFFICIAL_ID;
        filter.is_published = true;
      } else {
        filter.library_id = this.state.selectedLibraryId;
      }
      let libraries = [];
      if (me && hasAnyGroup(me, [Groups.OFIEditor, Groups.KapELearningEditor])) {
        libraries = await LibraryCrud.list({ filter: { is_active: true }, order_by: "id" });
      } else {
        libraries = [(await LibraryCrud.load(LIBRARY_PERSONAL_ID)).record];
      }

      let books : IViewBookRecord[];
      if (
        filter.library_id === LIBRARY_OFI_OFFICIAL_ID
        ||
        filter.library_id === LIBRARY_NKP_ID
      ) {
        books = await ViewBookOfficial.list({
          filter: filter,
          order_by: "name"
        }) as IViewBookRecord[];
      } else {
        books = await ViewBook.list({
          filter: filter,
          order_by: "name"
        });
      }
      this.setState({
        books,
        libraries
      });


    } catch (e) {
      app.showErrorFromJsonResult(e);
    }
  }

  componentDidUpdate(prevProps: any, prevState: BookListState) {
    if (this.state.selectedLibraryId != prevState.selectedLibraryId) {
      this.asyncReload();
    }
  }

  private async onCreateCopy(bookId: number) {
    const book = this.state.books!.find(book => book.id == bookId)!;

    if (!await confirmDialog(__("Tankönyv másolása"), __(`Biztos benne, hogy lemásolja a {name} könyvet a saját tartalmak közé?`, { name: book.name }), __("Lemásolom"))) {
      return;
    }

    this.setState({ copyingBookId: bookId }, async () => {
      try {
        const newBook = await copyPublishedBook(bookId, LIBRARY_PERSONAL_ID);
        this.setState({ copyingBookId: null });
      } catch (error) {
        app.showErrorFromJsonResult(error);
      }
      app.showSuccess(__("Sikeres másolás"), __("A könyv lemásolva a saját tartalmakhoz."));
    })
  }
  private async onDeleteOwnBook(bookId: number, bookName: string) {
    try {
      BookCrud.deleteById(bookId);
    }
    catch (error) {
      app.showErrorFromJsonResult(error);
    }
    if (!await confirmDialog(__("Megerősítés"), __("Biztos, hogy törölni szeretné ezt a könyvet: {book}?", { book: bookName }))) return;
    app.showSuccess(__("Könyv törlése"), __("Sikeres"));
    this.asyncReload();
  }

  private async onForcePublish(bookId: number, workflowId: number) {
    const book = this.state.books!.find(book => book.id == bookId)!;

    if (!await confirmDialog(__("Tankönyv publikálása"),
      __("Ez a művelet az összes folyamatkezelési szabály felülbírálásával publikálható állapotba hozza, és publikálja a {book} könyvet. Ez akár egy percig is eltarthat. Biztos benne, hogy ezt szeretné?", { book: book.name }), __("Publikálás"))) {
      return;
    }

    this.setState({ publishingBookId: bookId }, async () => {
      try {
        const wfAPI = new WfAPI(obtainServer());
        const newBook = await wfAPI.forcePublishBook(workflowId);
        this.setState({ publishingBookId: null }, () => {
          history.push(PATH_EDIT_BOOK_TABLE_OF_CONTENTS + `/${bookId}/`);
        });
      } catch (error) {
        app.showErrorFromJsonResult(error);
      }
    })
  }

  render() {
    if (this.state.books === undefined) {
      return <BubbleLoader />
    }

    if (this.state.publishingBookId) {
      return <Dialog title={__("Publikálás...")} width={800} closable={false} onClose={() => { }} >
        <DialogContent>
          <p>{__("Kis türelmet, publikálás folyamatban.")}</p>
        </DialogContent>
      </Dialog>
    }
    if (this.state.copyingBookId || this.state.publishingBookId) {
      return <Dialog title={__("Tankönyv másolása...")} width={800} closable={false} onClose={() => { }} >
        <DialogContent>
          <p>{__("Kis türelmet, a könyv másolása folyamatban van!")}</p>
        </DialogContent>
      </Dialog>
    }
    const books: IBookRecord[] = this.state.books;
    const ofiEditor = me && hasAnyGroup(me, [Groups.OFIEditor, Groups.KapELearningEditor]);
    let head: any = { Header: __("Állapot"), accessor: "wf_station_name", filterable: false, Cell: getWfStateCell, maxWidth: 150 }

    return (
      <PermissionPage requirements={{
        crud: { tableInfoId: BookCrud.TABLE_INFO_ID, permissions: CrudPermissionType.U }
      }}>
        <div className="row expanded">
          <WorkflowDialog
            open={this.state.workflowDialogOpen}
            displayName={__("Könyv")}
            tableInfoId={BookCrud.TABLE_INFO_ID}
            recId={this.state.selectedId!}
            onClose={() => this.setState({ workflowDialogOpen: false })}
            onTransition={this.asyncReload}
            autoPublishOnClosedStation={true}
          />
          <div className="small-12 column">
            <BookBreadcrumbs links={[]} />
          </div>

          <div className="small-12 column">
            <Link to={PATH_EDIT_BOOK_NEW} className="button">
              <i className="fa fa-plus" /> {__("Új könyv létrehozása")}
            </Link>
          </div>

          <div className="small-12 column">
            <RadioTabs tabGroup="bookTabs" onChange={(value: any) => this.setState({ selectedLibraryId: value })}>
              {

                this.state.libraries.map((l, i) => {
                  return <RadioTab key={l.id} value={l.id!} selected={l.id == this.state.selectedLibraryId}>

                    {/* <input id={"" + l.id} type="radio" name="books" checked={l.id == this.state.selectedLibraryId} /> */}

                    {l.name}
                  </RadioTab>
                })
              }
              {
                (me && !hasAnyGroup(me, [Groups.OFIEditor, Groups.KapELearningEditor])) ?
                  <RadioTab value={0}>
                    {/* <input type="radio" name="books" id="public-books" checked={this.state.selectedLibraryId == 0} /> */}
                    {__("Publikált könyvek")}
                  </RadioTab>
                  : ""
              }

            </RadioTabs>
          </div>

          <div className="small-12 column">
            <ReactTable
              columns={[
                {
                  Header: __("Tankönyv"), accessor: "name", filterable: true,
                  Cell: (data: any, column: any) => {
                    const row: IViewBookRecord = data.original;

                    var link: JSX.Element | string | undefined = <Link to={((ofiEditor || row.library_id === LIBRARY_PERSONAL_ID) ? PATH_BOOK_VIEW_EDITING + "/" + row.id + "/" : PATH_PUBLISHED_BOOK + "/" + row.uri_segment + "/")} target="_blank">
                      {row.name}
                    </Link>;
                    if (!ofiEditor && !row.last_published && row.library_id !== LIBRARY_PERSONAL_ID) {
                      link = row.name;
                    }

                    return <span>
                      {link}
                    </span>
                  }
                },
                {
                  Header: __("Állapot"), accessor: "wf_station_name", filterable: false,
                  Cell: (data: any, column: any) => {
                    const row: IViewBookRecord = data.original;
                    let bStatus: any = getWfStateCell(data, column);

                    return <div>
                      <div>{bStatus}</div>

                      {row.is_published && (me && hasAnyGroup(me, [Groups.OFIEditor, Groups.KapELearningEditor])) ?
                        <>
                          &nbsp;
                                <ToolTipDiv key={row.id} title={formatDate(row.last_published) + "<br>" + row.last_publisher} className="label primary">
                            {__("Publikálva")}
                          </ToolTipDiv>
                        </>
                        : null}
                    </div>
                  },
                  maxWidth: 150, show: (me && ofiEditor && this.state.selectedLibraryId != LIBRARY_PERSONAL_ID) || false
                },

                {
                  Header: __("Műveletek"), id: "link", filterable: false, style: { textAlign: "center" },

                  Cell: (data: any, column: any) => {
                    const row: IViewBookRecord = data.original;
                    if (!me) return null;
                    let ops = [];

                    if (me) {
                      if (!(row.library_id !== LIBRARY_PERSONAL_ID && !ofiEditor)) {
                        ops.push(<Link key="edit" style={{ marginRight: "0.5em" }} className="button" to={PATH_EDIT_BOOK_TABLE_OF_CONTENTS + "/" + row.id}><i className="fa fa-edit" /> &nbsp;{__("Szerkesztés")}</Link>);
                      }

                      if (row.wf_station_id && ofiEditor) {
                        ops.push(<button key="workflow" className="button" onClick={() => this.setState({ workflowDialogOpen: !this.state.workflowDialogOpen, selectedId: row.id })}>
                          <i className="fa fa-project-diagram" />&nbsp; {__("Folyamat...")}
                        </button>);
                      }
                    }

                    if (row.wf_station_id && hasAnyGroup(me, [Groups.ForcedPublisherOFI])) {
                      ops.push(<button
                        key="publish" className="button alert"
                        onClick={this.onForcePublish.bind(this, row.id, row.wf_workflow_id!)}
                        disabled={(this.state.copyingBookId || this.state.publishingBookId) ? true : false}
                      >
                        <i className="fa fa-eye" /> &nbsp;
                          {this.state.publishingBookId == row.id ? __("Kényszerített publikálás folyamatban...") : __("Kényszerített publikálás")}</button>);
                    }

                    if (row.is_published) {
                      ops.push(<button key="copy" className="button primary"
                        onClick={this.onCreateCopy.bind(this, row.id)}
                        disabled={(this.state.copyingBookId || this.state.publishingBookId) ? true : false}
                      >
                        <i className="fa fa-copy" /> &nbsp;{this.state.copyingBookId == row.id ? __("Másolás folyamatban...") : __("Lemásolom magamhoz")}</button>);
                    }
                    if (this.state.selectedLibraryId == LIBRARY_PERSONAL_ID) {
                      ops.push(<button key="copy" className="button alert"
                        onClick={this.onDeleteOwnBook.bind(this, row.id, row.name)}

                      >
                        <i className="fa fa-trash" /> &nbsp;{__("Könyv törlése")}</button>);
                    }

                    return <ButtonGroup align={ButtonAlign.Left} size={ButtonSize.Small}>
                      {ops.map((item, index) => {
                        return React.cloneElement(item, { ...item.props, key: index })
                      })}
                    </ButtonGroup>;
                  }
                },
              ]}
              data={books}
              filterable={true}
              className="-striped -highlight"
              {...getReactTableLabels()}

              /* Az alap filter csak kezdésre keres, és nem kis/nagybetű érzékeny. More info: https://react-table.js.org/#/story/custom-filtering */
              defaultFilterMethod={(filter, row, column) => row[filter.id].toLowerCase().indexOf(filter.value.toLowerCase()) != -1}
            />
          </div>

        </div>
      </PermissionPage>
    );

  }
};
