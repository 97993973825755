import { Module, ModuleMenu, BaseMenu, ModuleRoute } from "../Module";
import { __ } from "@src/translation";
import { ContentStoreExtraComponent, ContentStoreMyFiles, ContentStoreCommonFiles } from "@src/component/filemanager/ContentStore";

import SharedWithMePage from "@src/component/filemanager/SharedWithMePage";
import SharedByMePage from "@src/component/filemanager/SharedByMePage";
import { FavoriteList } from "@src/component/filemanager/FavouriteComponent";
import { Collection } from "@src/component/collection/Collection";
import { ContentHistoryPage } from "@src/component/filemanager/ContentHistoryPage";
import LearningPathList from "@src/component/learningpath/LerningPathList";

import ExerciseSeriesList from "@src/component/exercise/ExerciseSeries/ExerciseSeriesList";
import CollectionList from "@src/component/collection/CollectionList";
import ExerciseList from "@src/component/exercise/Viewer/ExerciseList";
import Error404Page from "@src/component/Error404Page";

export let contentStoreModule: ContentStoreModule<BaseMenu>;

export type ContentStoreModuleConfig = {

    enabledMainFiles?: boolean;

    enableFavourites?: boolean;
    enableExercises?: boolean;
    enableSharing?: boolean;
    enableCollections?: boolean;
    enableLearningPath?: boolean;
    enableHistory?: boolean;
    enableCommonFolder?: boolean;
    commonFolderId?: number | null;
    lessonPlanFolderId?: number | null;
    extraComponents?: ContentStoreExtraComponent[]

    
}

export class ContentStoreModule<T extends BaseMenu> extends Module<T> {

    private config: ContentStoreModuleConfig;
    
    public PATH_CONTENT_STORE = "/tartalomtar";
    public PATH_CONTENT_STORE_MAIN = this.PATH_CONTENT_STORE + "/sajat";
    public PATH_CONTENT_STORE_SHARED_WITH_ME = this.PATH_CONTENT_STORE + "/megosztas";
    public PATH_CONTENT_STORE_SHARED_BY_ME = this.PATH_CONTENT_STORE + "/megosztottam";
    public PATH_CONTENT_STORE_FAVORITES = this.PATH_CONTENT_STORE + "/kedvencek";
    public PATH_CONTENT_STORE_COLLECTION = this.PATH_CONTENT_STORE + "/gyujtemeny";
    public PATH_CONTENT_STORE_HISTORY = this.PATH_CONTENT_STORE + "/elozmenyek";
    public PATH_CONTENT_STORE_COMMON = this.PATH_CONTENT_STORE + "/kozos";
    //FELADATOK
    public PATH_EXERCISE = "/feladat";
    public PATH_EXERCISE_SERIES = "/feladatsor";
    public PATH_EXERCISE_LIST =  this.PATH_EXERCISE + "/szerkesztoiLista";
    public PATH_EXERCISE_SERIES_LIST =  this.PATH_EXERCISE_SERIES + "/szerkesztoiLista";
    public PATH_LEARNING_PATH = "/tanulasiut";
     

    constructor(config: ContentStoreModuleConfig) {
        super();

        this.config = config;

        ///
    }

    public static initGlobal<T extends BaseMenu>(config: ContentStoreModuleConfig) {
        contentStoreModule = new ContentStoreModule<T>(config);

    }

    public getCommonFolderId() {
        return this.config.commonFolderId;
    }
    
    public async getRoutesAsync(): Promise<Record<string, ModuleRoute<T>>>
    {
        return this.getRoutes();
    }
    
    public getRoutes(): Record<string, ModuleRoute<T>> {

        let ret: Record<string, ModuleRoute<T>> = {
            'ROUTE_CONTENT_STORE' : { path: this.PATH_CONTENT_STORE, component: ContentStoreMyFiles, exact: true, hasSidebar: true, menuCode: "contentstore-sidebar", sidebarHeader: __("Tartalomtár") },
            'ROUTE_CONTENT_STORE_MAIN' : { path: this.PATH_CONTENT_STORE_MAIN + "/:folderId?", component: ContentStoreMyFiles, hasSidebar: true, menuCode: "contentstore-sidebar", sidebarHeader: __("Tartalomtár") },
            'ROUTE_CONTENT_STORE_SHARED_WITH_ME' : { path: this.PATH_CONTENT_STORE_SHARED_WITH_ME, component: SharedWithMePage, hasSidebar: true, menuCode: "contentstore-sidebar", sidebarHeader: __("Tartalomtár") },
            'ROUTE_CONTENT_STORE_SHARED_BY_ME' : { path: this.PATH_CONTENT_STORE_SHARED_BY_ME, component: SharedByMePage, hasSidebar: true, menuCode: "contentstore-sidebar", sidebarHeader: __("Tartalomtár") },
            'ROUTE_CONTENT_STORE_FAVORITES' : { path: this.PATH_CONTENT_STORE_FAVORITES, component: FavoriteList, hasSidebar: true, menuCode: "contentstore-sidebar", sidebarHeader: __("Tartalomtár") },

            'ROUTE_CONTENT_STORE_COLLECTION' : { path: this.PATH_CONTENT_STORE_COLLECTION + "/:collectionId", component: Collection, hasSidebar: true, menuCode: "contentstore-sidebar", sidebarHeader: __("Tartalomtár") },
            'ROUTE_CONTENT_STORE_COLLECTION_LIST' : { path: this.PATH_CONTENT_STORE_COLLECTION, component: CollectionList, hasSidebar: true, menuCode: "contentstore-sidebar", sidebarHeader: __("Tartalomtár") },
            'ROUTE_CONTENT_STORE_HISTORY' : { path: this.PATH_CONTENT_STORE_HISTORY, component: ContentHistoryPage, hasSidebar: true, menuCode: "contentstore-sidebar", sidebarHeader: __("Tartalomtár") },           
            'ROUTE_LEARNING_PATH' : { path: this.PATH_LEARNING_PATH, component: LearningPathList, hasSidebar: true, menuCode: "contentstore-sidebar", sidebarHeader: __("Tartalomtár") },
            'ROUTE_CONTENT_STORE_COMMON_ROOT' : { path: this.PATH_CONTENT_STORE_COMMON, exact: true, component: ((this.config.commonFolderId || !this.config.enableCommonFolder) ? Error404Page : ContentStoreCommonFiles), hasSidebar: true, menuCode: "contentstore-sidebar", sidebarHeader: __("Tartalomtár") },  
            'ROUTE_CONTENT_STORE_COMMON' : { path: this.PATH_CONTENT_STORE_COMMON + "/:folderId?", component: ContentStoreCommonFiles, hasSidebar: true, menuCode: "contentstore-sidebar", sidebarHeader: __("Tartalomtár") },            

            //Külön exrcise modulba kell kerülnie
            'ROUTE_EXERCISE_LIST' : { path: this.PATH_EXERCISE_LIST, component: ExerciseList, hasSidebar: true, menuCode: "contentstore-sidebar", sidebarHeader: __("Tartalomtár") },
            'ROUTE_EXERCISE_SERIES_LIST' : { path: this.PATH_EXERCISE_SERIES_LIST, component: ExerciseSeriesList, hasSidebar: true, menuCode: "contentstore-sidebar", sidebarHeader: __("Tartalomtár") },
        };

        {
            this.config.extraComponents && this.config.extraComponents.forEach((c, index) => {

                ret[c.routeKey] = { path: c.routePath, component: c.component, hasSidebar: true, menuCode: "contentstore-sidebar", sidebarHeader: __("Tartalomtár") };
                //return <Route key={index} path={c.routePath} component={c.component} />;
            });
        }

        return ret;
    }

    public getRecordRoute(tableInfoId: number, recordId: number, edit?:boolean): string|null {
        // TODO!
        return null;
    }

    public getMenus() {
        let ret: ModuleMenu<T>[] = [];

        ret = ret.concat(
        [
            {
                enabled: this.config.enabledMainFiles,
                title: __("Tartalomtár"),
                path: this.PATH_CONTENT_STORE_MAIN,
                iconClass: "ico nkp-icon-file-explorer",
                code: "header-function"
            },

            {
                enabled: this.config.enabledMainFiles,
                title: __("Saját fájlok"),
                path: this.PATH_CONTENT_STORE_MAIN,
                iconClass: "fa fa-fw fa-file",
                code: "contentstore-sidebar",
                no: 1,
            },
            {
                separator:true,
                code: "contentstore-sidebar",
                no: 50,
            },

            // TODO exerciseModule-be
            {
                enabled: this.config.enableExercises,
                title: __("Feladatlista"),
                path: this.PATH_EXERCISE_LIST,
                iconClass: "fa fa-fw ico nkp-icon-my-lessons-repo",
                code: "contentstore-sidebar",
                no: 100,
            },   
            {
                enabled: this.config.enableExercises,
                title: __("Feladatsorlista"),
                path: this.PATH_EXERCISE_SERIES_LIST,
                iconClass: "fa fa-fw ico nkp-icon-excersises-repo",
                code: "contentstore-sidebar",
                no: 110,
            },
            {
                separator:true,
                code: "contentstore-sidebar",
                no: 150,
            },
            {
                enabled: this.config.enableSharing,
                title: __("Velem megosztott tartalmak"),
                path: this.PATH_CONTENT_STORE_SHARED_WITH_ME,
                iconClass: "fa fa-fw fa-share-alt",
                code: "contentstore-sidebar",
                no: 200
            }, 
            {
                enabled: this.config.enableSharing,
                title: __("Általam megosztott tartalmak"),
                path: this.PATH_CONTENT_STORE_SHARED_BY_ME,
                iconClass: "fa fa-fw fa-share-alt-square",
                code: "contentstore-sidebar",
                no: 210,
            }, 
            {
                enabled: this.config.enableFavourites,
                title: __("Kedvenceim"),
                path: this.PATH_CONTENT_STORE_FAVORITES,
                iconClass: "fas fa-fw fa-star",
                code: "contentstore-sidebar",
                no: 220,
            },
            {
                enabled: this.config.enableCollections,
                title: __("Gyűjteményeim"),
                path: this.PATH_CONTENT_STORE_COLLECTION,
                iconClass: "far fa-fw fa-folder",
                code: "contentstore-sidebar",
                no: 230,
            },
            {
                enabled: this.config.enableLearningPath,
                title: __("Tanulási útvonalaim"),
                path: this.PATH_LEARNING_PATH,
                iconClass: "fas fa-fw fa-bezier-curve",
                code: "contentstore-sidebar",
                no: 240,
            },
            {
                enabled: this.config.enableHistory,
                title: __("Előzmények"),
                path: this.PATH_CONTENT_STORE_HISTORY,
                iconClass: "fa fa-fw fa-arrow-circle-left",
                code: "contentstore-sidebar",
                no: 250,
            }, 
            {
                separator:true,
                code: "contentstore-sidebar",
                no: 270,
            },
            {
                enabled: this.config.enableCommonFolder,
                title: __("Közös fájlok"),
                path: this.PATH_CONTENT_STORE_COMMON +  (this.config.commonFolderId ? "/" + this.config.commonFolderId : ""),
                iconClass: "fa fa-fw fa-archive",
                code: "contentstore-sidebar",
                no: 300,
            }                
        ]); 
        
        {
            this.config.extraComponents && this.config.extraComponents.forEach((c, index) => {
                ret.push(
                    {
                        enabled: true,
                        title: c.name,
                        path: c.linkTo,
                        iconClass: c.iconClass,
                        code: "contentstore-sidebar",
                        no: 1000
                    }
                );                 
            })
        }

        return ret;
    }

}
