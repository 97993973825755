import * as React from 'react';
import Async from 'react-select/async';
import AwesomeDebouncePromise from 'awesome-debounce-promise';

import { getTableInfoById, ITableInfo, IFieldInfo } from '@src/framework/crud/Meta';
import { IRecord, CrudClassProxy, getCrudClassProxyById, TFilterDict, ISearchTextParams, TSpecFilterDict, IListParams, TMultiFieldTextSearch, StringSearchKind, IListOrderBy } from '@src/framework/crud/Crud';
import { app } from '@src/index';
import { REACT_SELECT_LABELS } from '@src/framework/i18n';

export type LookupFilterEvent = (sender: LookupEdit<IRecord>) => TFilterDict;
export type LookupSpecFilterEvent = (sender: LookupEdit<IRecord>) => TSpecFilterDict;

import '@src/framework/crud/sys/UserAvailibilityCrud';
import '@src/framework/crud/sys/PartnerStatusCrud';
import '@src/framework/crud/sys/PartnerTypeCrud';
import '@src/framework/crud/sys/SecGroupTypeCrud';
import '@src/framework/crud/sys/PersonStatusCrud';
import '@src/framework/crud/sys/LangCrud';
import '@src/framework/crud/sys/GenderCrud';
import { Props } from 'react-select/src/Select';
import { ActionMeta } from 'react-select/src/types';

import './lookupedit.css';
import { ViewClassProxy } from '../view/View';
import { parseDragMeta } from '@fullcalendar/core';

interface LookupEditProps extends Props<number> {
    /**
     * Annak a mezőnek a leírása, amit ez a komponens megjelenít.
     * A hivatkozott mező leírását magától betölti a háttrében, ha szükséges.
     */    
    fieldInfo ?: IFieldInfo;

    /**
     * Alternatív megadási mód: a hivatkozott táblázat info id-je,
     * plusz az hogy null-e vagy nem.
    */
   fk_table_info_id ?: number;
   /*

    Ha megadod a view nevét, akkor a találatokat a view-ból veszi és nem a táblázatból.
    De ilyenkor meg kell adni az oszlop nevét is, a találatok kijelzéséhez.
   */
   viewClassProxy ?: ViewClassProxy<IRecord>;
   valueColumn ?: string; // Annak az oszlopnak a neve, ami a kiválasztott értékhez tartozik. Ha nincs megadva, akkor "id"
   selectColumnNames ?: string[]; // Milyen mezőket adjon vissza a View API. Figyelem! Ha ezt megadod, akkor bele kell írnod az "id" mezőt is! (Vagy azt ami a valueColumn-ban van megadva)
   searchColumnNames ?: string[]; // Azok a szöveges oszlopok, amikben keres (ha a user beír valamit a keresőbe)
   displayColumnNames ?: string[]; // Milyen mezőket jelezzen ki a lenyíló listában.
   orderByColumnNames?: string | IListOrderBy[]; // melyik mező(k) szerint rendezze
   distinct ?: boolean; // select distinct


   /* Amikor egy konkrét id-hez akarja megjeleníteni a szöveget, akkor azt ebből a view-ból kéri le.
      Ha ez nincs megadva, akkor az fk_table_info_id alapján a Crud.asText -et használja helyette.
   */
   asTextViewClassProxy ?: ViewClassProxy<IRecord>;
   /* Melyik mező értékére szűrjön rá a value alapján. Ha nincs megadva, akkor 'id'-t használ.
      Ennek csak akkor van hatása, ha asTextViewClassProxy megvan adva.
   */
   asTextKeyColumn ?:string;
   /*
     Milyen mezőket jelezzen ki a szöveg betöltésekor. Csak akkor van használva ha
     asTextViewClassProxy is meg van adva.   
   */
   asTextColumnNames ?: string[]; 
   asTextFilter ?: TFilterDict;
   

   clearable ?: boolean;
   /** Ha a találatokat már a szerver oldalon szeretnéd szűrni valamilyen mezők szerint. */
   filter ?: TFilterDict;
   /** Ez hasonló, de dinamikus. Felülbírálja a filter property-t. */
   onFilter ?: LookupFilterEvent;
   /** Speciális szűrési feltételek. A backend értelmezi, csak bizonyos 
    *  táblázatok és view-k esetén van értelmezve. 
    */
   spec ?: TSpecFilterDict;
    /** Ez hasonló, de dinamikus. Felülbírálja a spec property-t. */
   onSpec ?: LookupSpecFilterEvent;
    /** Amennyiben az értéke igaz, akkor üres szűréskor is betölti a limitben megadott sort,
     *  ha van megadva viewClassProxy, vagy ha kevesebb van akkor annyit */
   emptyLoad?: boolean;
    /** A lenyiló menüben maximálisan megjelő sorok száma. Ha nincs megadva, akkor 50 */
   limit?: number;
    /** Azt mondja meg, hogy a kiválasztott értékhez betöltse e a központilag megadott labelt. Alapérték, hogy igen */
   labelReload?: boolean;

   /** Csak olvashatóként megjeleníteni. */
   isDisabled?:boolean;
}

interface LookupEditState {
    referencedTableInfo ?: ITableInfo;
    isLoading : boolean;
    value?: {value: number, label: string};
    emptyLoad: boolean;
    limit: number;
    labelReload: boolean;
    key: number;
}

const DEFAULT_SEARCH_AFTER_MSEC : number = 500;

export default class LookupEdit<TRecord extends IRecord> extends React.Component<LookupEditProps, LookupEditState> {
    private loadOptions : ((input: string) => Promise<any>) | null = null;;

    constructor(props: LookupEditProps) {
        super(props);
        if (props.fieldInfo && props.fieldInfo.fk_table_info_id) {
            // OK
        } else if (props.fk_table_info_id && props.clearable!==undefined) {
            // OK
        } else if ((props.fieldInfo && !props.fieldInfo.fk_table_info_id)) {
            throw new Error("Cannot create lookupedit for non-lookup field!");
        } else { // if (!props.fk_table_info_id) {
            throw new Error("Cannot create LookupEdit: you must give fieldInfo or fk_table_info_id AND clearable property!");
        }
        this.loadOptions = AwesomeDebouncePromise(this.doLoadOptions.bind(this), DEFAULT_SEARCH_AFTER_MSEC);
        this.state = {isLoading : false, 
            emptyLoad: props.emptyLoad!==undefined ? props.emptyLoad : false, 
            limit: props.limit!==undefined ? props.limit : 50,
            labelReload: props.labelReload!==undefined ? props.labelReload : true,
            key: new Date().getTime()
        }; // TODO: load initial value!
    }

    /**
     * Azt mondja meg, hogy ez a mező melyik táblázatra való hivatkozásokat
     * mutat/szerkeszt.
     */
    protected getFkTableInfoId() : number {
        if (this.props.fk_table_info_id) {
            return this.props.fk_table_info_id;
        } else {
            return this.props.fieldInfo!.fk_table_info_id!;
        }
    }

    /**
     * Azt mondja meg, hogy a mező értékét ki lehet-e törölni.
     */
    protected getClearable() : boolean {
        if (this.props.clearable!==undefined) {
            return this.props.clearable;
        } else if (this.props.fieldInfo) {
            return this.props.fieldInfo.not_null;
        } else {
            return true;
        }
        
    }

    public componentWillMount() {
        if (!this.state.referencedTableInfo) {
            getTableInfoById(this.getFkTableInfoId())
                .then( (tableInfo) => { this.setState({referencedTableInfo: tableInfo}) }
                    , this.forceUpdate
                 )
        }
    }

    componentDidMount() {
        this.reloadValue();
    }

    componentWillReceiveProps(nextProps: LookupEditProps){
        if (nextProps.filter!==this.props.filter) {
            this.setState({key: new Date().getTime()})              
        }
    }
    componentDidUpdate(prevProps: LookupEditProps) {
        if (prevProps.value != this.props.value) {
            this.reloadValue();
        }
    }

    private async reloadValue() {
        if (this.props.value) {
            let result: {id: number, text: string};
            const asTextProxy = this.props.asTextViewClassProxy || this.props.viewClassProxy;
            let keyFieldName = this.props.valueColumn || 'id';
            let columns = this.props.displayColumnNames;
            let filter : TFilterDict | undefined = this.props.filter;
            if (this.props.asTextViewClassProxy) {
                keyFieldName = this.props.asTextKeyColumn || 'id';
                if (this.props.asTextColumnNames) {
                    columns = this.props.asTextColumnNames;
                }
                if (this.props.asTextFilter) {
                    filter = this.props.asTextFilter;
                }
            }
            try {
                if (this.props.onValueReload) {
                    result = await this.props.onValueReload(this.props.value! as number);  
                } else if (asTextProxy) {
                    result = {id: this.props.value! as number, text: ""};
                    if (!filter) filter = {};
                    filter[keyFieldName] = this.props.value! as number;
                    let records = await asTextProxy.list({
                        filter,
                        columns,
                        distinct: true,
                        limit: 1
                    }
                    );
                    if (records && records.length) {
                        let parts : string[] = [];
                        columns!.forEach( (colname: string) => {
                            let colvalue = records[0][colname];
                            if (colvalue) {
                                parts.push(""+colvalue);
                            }
                        });
                        result.text = parts.join(" ");
                    }
                } else {
                    result = await this.getFkCrudClassProxy().asText(this.props.value! as number, false);
                }
            } catch (e) {
                console.log(e);
                app.showError("Hiba", "Nem sikerült betölteni a kiválaszott értéket.");
                this.setState({
                    value: {
                        value: this.props.value! as number,
                        label:"⚠ Nem betölthető érték ⚠"
                    }
                });
                return;
            }

            if (result) {
                this.setState({
                    value: {
                        value:result.id, 
                        label:result.text
                    }
                });
            }    
        } else {
            this.setState({
                value: undefined
            })
        }
    }

    private getFkCrudClassProxy() : CrudClassProxy<TRecord> {
        let result = getCrudClassProxyById(this.getFkTableInfoId())
        return result as CrudClassProxy<TRecord>;
    }

    private doLoadOptions(input: string) : Promise<any> {
        this.setState({isLoading: true});
        if (input || this.state.emptyLoad===true) {
            if (this.props.viewClassProxy) {
                if (!this.props.displayColumnNames || !this.props.searchColumnNames || this.props.searchColumnNames.length==0 || this.props.displayColumnNames.length==0) {
                    throw new Error("LookupEdit: when viewClassProxy is given, you must also give searchColumnNames and displayColumnName!");
                }

                let searchParams : IListParams = {
                    limit: this.state.limit,
                    //columns: ["id"].concat(this.props.displayColumnNames),
                    columns: this.props.selectColumnNames,
                    order_by: this.props.orderByColumnNames
                };
                if (this.props.distinct) {
                    searchParams.distinct = true;
                }

                /*
                    Add user defined filters with $and

                */
                if (this.props.onFilter!==undefined) {
                    searchParams["filter"] = this.props.onFilter(this);
                } else if (this.props.filter !== undefined) {
                    searchParams["filter"] = this.props.filter;
                }
                if (this.props.onSpec!==undefined) {
                    searchParams["spec"] = this.props.onSpec(this);
                } else if (this.props.spec !== undefined) {
                    searchParams["spec"] = this.props.spec;
                }
                if (input) {
                    const textSearch : TMultiFieldTextSearch = {
                        "$text": {
                            fieldNames: this.props.searchColumnNames,
                            expression: {
                                kind: StringSearchKind.WordSearch,
                                case_sensitive: false,
                                expr: input 
                            }                    
                        }
                    }
                    if (searchParams["filter"]) {
                        searchParams["filter"] = {
                            "$and": [ searchParams["filter"], textSearch, ]
                        }
                    } else {
                        searchParams["filter"] = textSearch;
                    }    
                }

                return this.props.viewClassProxy.list(searchParams).then( (items:IRecord[]) => {
                    let options: any[] = [];
                    for (let i=0; i<items.length; i++) {
                        const record : any = items[i] as any;
                        let label : string = '';
                        this.props.displayColumnNames!.forEach((fname) => {
                            let value = record[fname];
                            if (value) {
                                value = (""+value).trim();
                                if (value) {
                                    label += " " + value;
                                }

                            }
                        });

                        let idValue : any;
                        if (this.props.valueColumn) {
                            idValue = record[this.props.valueColumn];
                        } else {
                            idValue = record.id;
                        }

                        options.push({value: idValue, label: label.trim()});
                    }
                    return Promise.resolve(options);
                } ).catch( (error: any) => {
                        app.showErrorFromJsonResult(error);
                        return Promise.reject(error);
                    });
            } else {
                // Keresett valamire!    
                if (input || this.props.emptyLoad) {
                    let searchParams : ISearchTextParams = {
                        text: input,
                        case_sensitive: false,
                        include_record: false,
                        limit: this.state.limit
                    };
                    if (this.props.onFilter!==undefined) {
                        searchParams["filter"] = this.props.onFilter(this);
                    } else if (this.props.filter !== undefined) {
                        searchParams["filter"] = this.props.filter;
                    }
                    if (this.props.onSpec!==undefined) {
                        searchParams["spec"] = this.props.onSpec(this);
                    } else if (this.props.spec !== undefined) {
                        searchParams["spec"] = this.props.spec;
                    }
                    return this.getFkCrudClassProxy().searchText(searchParams)
                        .then( (items) => {
                            let options: any[] = [];
                            for (let i=0; i<items.length; i++) {
                                options.push({
                                    value: items[i].id,
                                    label: items[i].text
                                });
                            }
                            return Promise.resolve(options);
                        }).catch( (error: any) => {
                            app.showErrorFromJsonResult(error);
                            return Promise.reject(error);
                        });    
                } else {
                    return Promise.resolve([]);
                }       
            }
        } else {
            return Promise.resolve([]);
        }
    }

    private onChange = (newValue: {value: number | null}, actionMeta: ActionMeta) => {
        if (this.props.onChange) {
            this.props.onChange( (newValue && newValue.value) || null, actionMeta);
        }
    }

    // See "Advanced filters" section at https://github.com/JedWatson/react-select
    private filterOption = (option: any, filter: string) : boolean => {
        return true;
    }

    render() {
        const asyncProps : any = Object.assign({}, this.props);
        // Remove special/extra props
        delete asyncProps.fieldInfo;
        delete asyncProps.onChange;
        delete asyncProps.onValueChange;
        delete asyncProps.isLoading;
        return <Async 
            { ... REACT_SELECT_LABELS  }
            isDisabled={this.props.disabled}
            defaultOptions
            loadOptions={this.loadOptions!}
            isClearable={this.getClearable()}
            isLoading={this.state.isLoading}            
            onChange={this.onChange}
            cache={false}
            ignoreAccents={true}
            filterOption={this.filterOption}
            { ...asyncProps }
            className="eke-lookup-input-margin"
            value={this.state.value}
            key={this.state.key}            
        />
    }

}