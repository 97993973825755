import * as React from 'react';


type ChangeLimitEvent = (newLimit: number)=>void;
type ChangeOffsetEvent = (newOffset: number)=>void;

interface FileManagerPagerProps {
    disabled ?: boolean;
    limit: number;
    offset: number;
    onChangeLimit ?: ChangeLimitEvent;
    onChangeOffset ?: ChangeOffsetEvent;
    pageItemCount?: number;
} 

interface FileManagerPagerPropsState {
}

export default class FileManagerPager extends React.Component<FileManagerPagerProps, FileManagerPagerPropsState> {
    constructor(props: FileManagerPagerProps) {
        super(props);
        this.state = {};
    }

    private doChangeLimit = (event: any) => {
        if (this.props.onChangeLimit) {
            this.props.onChangeLimit(parseInt(event.target.value));
        }
    }

    private doChangeOffset = (event: any) => {
        if (this.props.onChangeOffset) {
            this.props.onChangeOffset(parseInt(event.target.value));
        }
    } 

    private doPrevPage = () => {
        if (this.props.onChangeOffset) {
            let newOffset = this.props.offset - this.props.limit;
            if (newOffset<0) newOffset = 0;
            this.props.onChangeOffset(newOffset);
        }
    }    

    private doNextPage = () => {
        if (this.props.onChangeOffset) {
            this.props.onChangeOffset(this.props.offset + this.props.limit);
        }
    }        

    render() {        
        const disabled : boolean = (this.props.disabled || false);
        const prevDisabled = disabled || this.props.offset == 0;

        const nextDisabled = disabled || (this.props.pageItemCount != undefined && (this.props.pageItemCount < this.props.limit));
        return <div className="row expanded">
            <div>
                <button disabled={prevDisabled} onClick={this.doPrevPage} 
                    className={this.props.offset == 0 ? "button small middle btn-right-pad-mfc disabled" : "button small middle btn-right-pad-mfc"}>
                    <i className="fa fa-arrow-left" />
                </button>
                &nbsp;
                {this.props.offset} - {this.props.offset + this.props.limit}
                &nbsp;            
                <button disabled={nextDisabled} onClick={this.doNextPage} 
                    className={this.props.offset == this.props.limit ? "button small middle btn-top-pad-mfc" : "button small middle btn-top-pad-mfc"}>
                    <i className="fa fa-arrow-right" />
                </button>     
                &nbsp;&nbsp;
                <select id="untaggedLimit" value={this.props.limit} className="eke-mediachooserPagination"
                    onChange={this.doChangeLimit} disabled={disabled}>
                    <option>10</option>
                    <option>25</option>
                    <option>50</option>
                    <option>100</option>
                </select> / oldal                
            </div>              
        </div>;
    }
}
