import * as React from 'react';
import './checkbox.css';

export interface CheckboxProps extends React.HTMLAttributes<HTMLElement> {
    value: boolean|null;
    disabled ?: boolean;
    name ?:string;
    label?: string;
    notNull?: boolean;
    onValueChange: (newValue: boolean|null) => void;
  }

export default class Checkbox extends React.Component<CheckboxProps> {

    private onClick = (event: any) => {
        if (this.props.value===false) {
            this.props.onValueChange(true)
        }
        else if (!this.props.notNull && this.props.value===true) {
            this.props.onValueChange(null)
        } else {
            this.props.onValueChange(false)
        }
    }

    render() {
        let props : any = Object.assign({}, this.props);
        delete props.onValueChange;
        delete props.onClick;
        delete props.disabled;
        let className = 'checkbox ';
        if (this.props.className)
            className += this.props.className + ' '; 

        if (this.props.value===null) {
            className += 'fas fa-question';
        } else if (this.props.value===true) {
            className += 'fas fa-check';
        } else {
            className += 'uncheck';
        }
        let onClick = this.onClick;
        if (this.props.disabled) {
            className += ' checkbox-disabled';
            onClick = () => {};
        } else {
            className += ' checkbox-enabled';
        }
        props.className = className;
            const checkbox = <i
            style={{display: "inline-block"}}
            { ...props } 
            onClick={onClick} 
        />;
        return this.props.label
        ? <label style={{display:"flex", alignItems: "center"}}>
            {checkbox}
            {this.props.label}
        </label>
        : checkbox
    }
}