import obtainServer, { Server } from '../server/Server';
import { IRecord } from './Crud';

/**
 * CRUD szerver - adatbázis rekordok manipulálása egyszerű módon.
 *
 * Ha nincs külön megadva, akkor a globális server -t használja.
 */
export default class CrudServer<R extends IRecord> {
    private server: Server;

    constructor(aServer?: Server) {
        this.server = obtainServer(aServer);
    }

    /**
     * Elmenti a megadott rekrodot az adatbázisba.
     *
     * Ha a rekord még nem létezik, akkor létre is hozza.
     *
     * @returns: A létrehozott rekord, visszaolvasva az adatbázisból.
     *           Ebben már látszódik a triggerek hatása is.
     */
    public put(path: string, record: R): Promise<R> {
        return this.server.put(path, record);
    }

    /**
     * Elmenti a megadott rekrodot az adatbázisba.
     *
     * Ha a rekord még nem létezik, akkor létre is hozza.
     * A létezést nem az id mező alapján ellenőrzi le, hanem
     * a keyFieldNames -ben megadott mező értékek alapján.
     *
     * @returns: A létrehozott rekord, visszaolvasva az adatbázisból.
     *           Ebben már látszódik a triggerek hatása is.
     */
    public upsert(path: string, record: R, keyFieldNames: string[]): Promise<R> {
        return this.server.post(path, {
            operation: "upsert", record: record, key_field_names: keyFieldNames,
        });        
    }    

    /**
     * Elküldi a megadott rekrodot az adatbázisba.
     *
     * A hatása a megadott művelettől függ.
     *
     * @param rec: A rekord amit el akarsz küldeni.
     * @param operation: A művelet amit el akarsz végezni.
     * @param params: Egyéb paraméterek, ha vannak.
     * @returns: A művelet hatása és a visszatérési érték művelet függő...
     *
     */
    public post(path: string, record: R, operation: string, params?: any): Promise<R> {
        return this.server.post(path, {
            ...{ operation, record }, params: (params === undefined ? null : params),
        });
    }

    /**
     * Törli a megadott rekrodot az adatbázisból.
     */
    public del(path: string): Promise<boolean> {
        return this.server.del(path);
    }

}
