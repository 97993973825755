import * as React from 'react';
import AutoCompleteEdit from '@src/framework/forms/autocompleteedit';
import { MetaKeywordAssignerPlugin } from '../../meta/meta_keyword_assigner_plugin';
import LookupEdit from '@src/framework/forms/lookupedit';
import CrudSelectComponent from '@src/framework/forms/crudselect';
import GradeCrud, { gradeCrudClassProxy } from '@src/framework/crud/doc/GradeCrud';
import SecUserSelector from '@src/framework/forms/SecUserSelector';
import Checkbox from '@src/framework/forms/checkbox';
import DatePicker from '@src/framework/forms/datetimepicker';
import ExerciseFileSelect, { ExerciseFileTypes } from '../../exercise/Editor/ExerciseFileSelect';
import { me } from '@src/framework/server/Auth';
import ButtonGroup, {ButtonSize, ButtonAlign} from '@src/component/ui/ButtonGroup';
import { __ } from '@src/translation';
import { CrudMultiSelect } from '@src/framework/forms/crudmultiselect';
import { subjectCrudClassProxy } from '@src/framework/crud/doc/SubjectCrud';

export class UIFormGuidelines extends React.Component<{}, {values: number[]}> {

    constructor(props: never) {
        super(props);

        this.state ={
            values: []
        }
    }

    render() {
        return <>
            
        <label>
            Input text
            <input type="text" />
        </label>

        <label>
            Input number
            <input type="number" />
        </label>

        <label>
            Input date
            <input type="date" />
        </label>

        <label>
            Input datetime
            <DatePicker onChange={() => {}} />
        </label>

        <label>
            Native Select
            <select><option>ABC</option></select>
        </label>

        <label>
            { __("Általános autocomplete") }
            <AutoCompleteEdit onValueChange={() => {}} value="x" />
        </label>

        <label>
            { __("Kulcsszó választó") }
            <MetaKeywordAssignerPlugin keywords={["abc"]} />
        </label>

        <label>
            { __("Adatbázis táblában kereső, be kell írni") }
            <LookupEdit fk_table_info_id={GradeCrud.TABLE_INFO_ID} clearable={true} />
        </label>

        <label>
            { __("Adatbázis táblából rekord előre betöltő") }
            <CrudSelectComponent crudClassProxy={gradeCrudClassProxy} value={1} displayFieldName="name" />
        </label>

        <label>
            { __("Felhasználó választó") }
            <SecUserSelector value={1} onChange={() => {}} clearable={true} />
        </label>

        <label>
            <input type="checkbox" />
            Checkbox
        </label>

        <label>
            <input type="radio" />
            Radio 1
            <input type="radio" />
            Radio 2
        </label>

        <label>
            Multi select
        </label>
            <CrudMultiSelect
                crudClassProxy={subjectCrudClassProxy}
                displayFieldName="name"
                values={this.state.values}
                onChange={values => this.setState({values})}

            />

        <label>{ __("Valami spéci checkbox") }
            <div className="switch">
                <input className="switch-input"
                    id="send_overdue" type="checkbox"
                />
                <label className="switch-paddle" htmlFor="send_overdue">
                    
                </label>
            </div>
        </label>

        <label>3 { __("állapotú checkbox") }
            <Checkbox value={null} onValueChange={() => {}} />
            <Checkbox value={false} onValueChange={() => {}} />
            <Checkbox value={true} onValueChange={() => {}} />
        </label>

        <label>{ __("Kép választó") }
            <ExerciseFileSelect onChange={() => {}} value={""} imagebasepath="" fileType={ExerciseFileTypes.Image} getFolderId={() => {return new Promise((r) => r(me!.oo_folder_id))}} />
        </label>

        <h4>ButtonGroup</h4>

        <b>ButtonSize.Small</b>
        <ButtonGroup align={ButtonAlign.Left} size={ButtonSize.Small}>
            <button className="button">{ __("Gomb") } 1</button>
            <button className="button">{ __("Gomb") } 2</button>
            <button className="button">{ __("Gomb") } 3</button>
        </ButtonGroup>

        <b>ButtonSize.Large</b>
        <ButtonGroup align={ButtonAlign.Left} size={ButtonSize.Large}>
            <button className="button">{ __("Gomb") } 1</button>
            <button className="button">{ __("Gomb") } 2</button>
            <button className="button">{ __("Gomb") } 3</button>
        </ButtonGroup>

        <b>ButtonSize.Large button + label</b>
        <ButtonGroup align={ButtonAlign.Right} size={ButtonSize.Large}>
            <button className="button">{ __("Gomb") } 1</button>
            <button className="button">{ __("Gomb") } 2</button>
            <button className="button">{ __("Gomb") } 3</button>
            <label className="label">{ __("Címke") }</label>
        </ButtonGroup>

        <b>ButtonSize.Normal</b>
        <ButtonGroup align={ButtonAlign.Expanded} size={ButtonSize.Normal}>
            <button className="button">{ __("Gomb") } 1</button>
            <button className="button">{ __("Gomb") } 2</button>
            <button className="button">{ __("Gomb") } 3</button>
        </ButtonGroup>

        </>;

    }

}
