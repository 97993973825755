import SharedContentRunCrud, { ISharedContentRunRecord } from "@src/framework/crud/usr/SharedContentRunCrud";
import { formatDate } from "@src/Util";
import { __ } from "@src/translation";
import { ISharedContentRecord } from "@src/framework/crud/usr/SharedContentCrud";
import { IViewInstituteGroupMemberRecord } from "@src/framework/view/sys/ViewInstituteGroupMember";
import { number } from "prop-types";
import SharedContentMemberCrud, { ISharedContentMemberRecord } from "@src/framework/crud/usr/SharedContentMemberCrud";
import { IViewSharedByMeRecord } from "@src/framework/view/usr/ViewSharedByMe";
import OpenEndedQuestionCrud from "@src/framework/crud/usr/OpenEndedQuestionCrud";
import { whiteSpaceChecker } from "../WhiteSpaceChecker";

export type SubmissionDetails = {
    shared_by_success_percent: number,
    run_time?: string,
    total_points?: number,
    earned_points?: number
}

export enum ExerciseSubmissionStates {
    NotStarted = -2,
    NotSubmitted = -3,
    NotEvaluated = -1
}
export class ExerciseEvaluationHelper {
    public static getRunResult(run: ISharedContentRunRecord) {
        const exercises: { totalPoints?: number, successPercent?: number }[] = run.start_params.exercises;

        var evaluatedExercises = 0;
        var sumPoints: number | null = 0;
        var sumTotalPoints: number = 0;
        var sumSuccessPercent: number | null = 0;
        if (run.shared_by_success_percent == null || run.shared_by_success_percent == undefined) {
            for (const exercise of exercises) {
                if (exercise.successPercent !== undefined && exercise.totalPoints !== undefined && sumPoints !== null && sumSuccessPercent !== null) {
                    sumPoints += (exercise.successPercent * exercise.totalPoints);
                    sumTotalPoints += exercise.totalPoints;
                    //sumSuccessPercent += exercise.successPercent * exercise.successPercent;
                    evaluatedExercises++;
                } else {
                    sumPoints = null;
                    sumSuccessPercent = exercise.successPercent || null;
                    break;
                }
            }
            if (sumSuccessPercent !== null && sumPoints !== null) {
                sumSuccessPercent = sumPoints / sumTotalPoints;
            }
        }
        else {
            sumSuccessPercent = run.shared_by_success_percent;
        }

        var notFinishedMessage: string | null = "";
        if (!whiteSpaceChecker(run.finished)) {
            if (evaluatedExercises > 0) {
                notFinishedMessage = __("{eva}. feladatnál tart", { eva: evaluatedExercises });
            } else if (exercises && exercises.length > 0) {
                notFinishedMessage = "Nem kezdett hozzá";
            } else {
                notFinishedMessage = "Még nem adta be";
            }
        }



        var ret = {
            formattedTime: formatDate(run.creation_time),
            exerciseCount: exercises.length,
            evaluatedExercises: evaluatedExercises,
            sumSuccessPercent: sumSuccessPercent,
            notFinishedMessage: notFinishedMessage,
            earnedPoints: sumPoints,
            total_points: sumTotalPoints
        }

        return ret;
    }

    public static getBestRunResult(runs: ISharedContentRunRecord[]): SubmissionDetails | null {
        if (runs.length === 0) return null;
        let bestResult: SubmissionDetails = { shared_by_success_percent: ExerciseSubmissionStates.NotStarted };
        let bestRunRes: any = {};
        const idx = runs.findIndex((el) => { return (el.shared_by_success_percent != null && el.shared_by_success_percent != undefined) });
        if (idx > -1) {
            let bestRes = runs[idx];
            bestRunRes = ExerciseEvaluationHelper.getRunResult(bestRes);
            bestResult.shared_by_success_percent = bestRes.shared_by_success_percent!;
        } else {
            bestRunRes = runs.map(ExerciseEvaluationHelper.getRunResult).reduce((a, b) => {
                return (a.sumSuccessPercent || 0) > (b.sumSuccessPercent || 0) ? a : b
            });
            if (bestRunRes && bestRunRes.sumSuccessPercent != null) {
                bestResult.shared_by_success_percent = bestRunRes.sumSuccessPercent;
            }
        }

        if (bestResult.shared_by_success_percent.toString() == "NaN" || bestResult.shared_by_success_percent == undefined || bestResult.shared_by_success_percent == NaN || bestResult.shared_by_success_percent == null) return null;

        bestResult.total_points = bestRunRes.total_points;
        bestResult.earned_points = bestRunRes.earnedPoints || undefined;
        bestResult.run_time = bestRunRes.formattedTime;
        return bestResult;
    }

    public static async getNumberOfSubmissions(members: IViewInstituteGroupMemberRecord[], sharedContent: IViewSharedByMeRecord): Promise<number> {
        let memberIds: number[] = members.map((m) => m.sec_user_id!);
        let count = 0;
        let memberResult: ISharedContentMemberRecord[] = (await SharedContentMemberCrud.list({ filter: { shared_content_id: sharedContent.id, sec_user_id: memberIds } }));
        //count = memberResult.filter((el) => (el.success_percent != null && el.success_percent != undefined)).length;
        count = memberResult.filter((el) => (el.run_count != null && el.run_count > 0)).length;

        return count;
    }

    /**
     * Tells the result of the users solution.  
     * returns: ExerciseSubmissionStates | number
     * -1: if the user started it, and submitted it, but didn't get a successpercent automatically (e.g. open ended exercise)
     * -2: if the user haven't started at all
     * -3: if started but not finished, not submitted
     * x >= 0: the user started to solve the exercise and this is the best result he/she got, or the teacher gave.
     */
    public static async getUserSuccessPercent(userId: number, sharedContentId: number): Promise<number> {
        try {
            let memberResult: ISharedContentMemberRecord[] = (await SharedContentMemberCrud.list({ filter: { shared_content_id: sharedContentId, sec_user_id: userId } }));
            if (memberResult && memberResult.length > 0) {
                //console.log("member res", memberResult[0]);
                if (memberResult[0].run_count == null || memberResult[0].run_count == 0) return ExerciseSubmissionStates.NotStarted;
                if (!whiteSpaceChecker(memberResult[0].last_submission_time)) return ExerciseSubmissionStates.NotSubmitted;
                return memberResult[0].success_percent != null ? memberResult[0].success_percent : ExerciseSubmissionStates.NotEvaluated;
            }
        } catch (error) { console.log(error) }
        return ExerciseSubmissionStates.NotStarted;
    }

    public static async getSubmissionData(userId: number, sharedContentId: number): Promise<SubmissionDetails> {
        const sharedContentRuns = await SharedContentRunCrud.list({ filter: { is_active: true, shared_content_id: sharedContentId, run_by_id: userId } });
        let item: SubmissionDetails = { shared_by_success_percent: -2 };
        if (!sharedContentRuns || sharedContentRuns.length == 0) {
            //item.shared_by_success_percent = -2;
            return item;
        }
        item = ExerciseEvaluationHelper.getBestRunResult(sharedContentRuns) || { shared_by_success_percent: -1 };
        return item;
    }
}