import * as React from 'react';
import { app } from '@src/index';
import { me, hasAnyGroup, Groups } from '@src/framework/server/Auth';
import ReactTable, { RowInfo, Column, TableProps } from 'react-table';
import { getReactTableLabels } from '@src/framework/i18n';
import { Link } from 'react-router-dom';
import { PATH_ATTENDANCE_DRAFT_UPLOAD_EDIT } from '../Attendance';
import ViewKapDraft, { IViewKapDraftRecord } from '@src/framework/view/kap/ViewKapDraft';
import { __ } from '@src/translation';
import { formatDate } from '@src/Util';
import StationCrud, { IStationRecord } from '@src/framework/crud/wf/StationCrud';
import { WF_TYPE_KAP_JTR_BEADANDO_ORAVAZLAT_ID, LIBRARY_OFI_OFFICIAL_ID, LIBRARY_JTR_BEADANDO_ID } from '@src/Const';
import { TFilterDict, StringSearchKind, IListOrderBy, IListParams } from '@src/framework/crud/Crud';
import { debounce } from 'lodash';
import obtainServer from '@src/framework/server/Server';
import { confirmDialog } from '@src/component/Dialog';
import KipDraftCrud from '@src/framework/crud/kap/KipDraftCrud';


const WF_DFHT_KIP_STATUS_ELFOGADVA_ID = 25408996783;

var saved = {

    filters: [],
    sorted: [],
    pageSize: 10,
    count: 0,
    page: 0,
}

type AttendanceDraftUploadState = {
    drafts: IViewKapDraftRecord[];
    stations: IStationRecord[];
    count: number;
    page: number,
    filters: any[],
    pageSize: number;
    loading: boolean;
    selected: any;
    sorted: any[];
}

export class AttendanceDraftUpload extends React.Component<any, AttendanceDraftUploadState> {

    constructor(props: any) {
        super(props);

        this.state = {
            drafts: [],
            count: saved.count,
            pageSize: saved.pageSize,
            stations: [],
            loading: false,
            selected: null,
            filters: saved.filters,
            page: saved.page,
            sorted: saved.sorted,
        }
    }

    componentDidMount() {
        this.reloadAsync();
    }

    private async reloadAsync(tablestate?: { page: number, pageSize: number, sorted: any, filtered: any[] }) {
        if (!me) return;

        try {
            this.setState({ loading: true });
            const filter: TFilterDict = {
                is_active: true,
                library_id: LIBRARY_JTR_BEADANDO_ID,
                table_info_id: KipDraftCrud.TABLE_INFO_ID
            };
            for (const userFilter of this.state.filters) {

                if (userFilter.id == "jtr_wf_station_name") {
                    if (userFilter.value == -1) { filter["jtr_wf_station_id"] = undefined; }
                    else { filter["jtr_wf_station_id"] = userFilter.value; }

                }
                else filter[userFilter.id] = { kind: StringSearchKind.Contains, case_sensitive: false, expr: userFilter.value }

            }
            let orderBy: undefined | string | IListOrderBy[] = undefined;
            if (this.state.sorted.length > 0) {
                const userOrder = this.state.sorted[0];

                if (userOrder.desc) {
                    orderBy = [{ name: userOrder.id, desc: true }];
                } else {
                    orderBy = userOrder.id
                }
            }
            if (!orderBy) { orderBy = [{ name: "creation_time", desc: true }]; }

            let listParameters: IListParams = {
                filter: filter,
                order_by: orderBy,
                limit: this.state.pageSize,
                offset: this.state.page * this.state.pageSize
            };

            let stations = await StationCrud.list({ filter: { is_active: true, wf_type_id: WF_TYPE_KAP_JTR_BEADANDO_ORAVAZLAT_ID }, order_by: "no" });
            const drafts = await ViewKapDraft.list(listParameters);
            const count = await ViewKapDraft.count(listParameters);
            saved.count = count
            this.setState({

                drafts,
                count: count,
                loading: false,
                stations
            })

        } catch (e) {
            app.showErrorFromJsonResult(e);
        }
    }
    onFetchData(tablestate: any) {
        if (this.state.filters != tablestate.filtered) {
            this.debouncedSetTableState(tablestate);
        } else {
            this.setTableState(tablestate);
        }
    };
    debouncedSetTableState = debounce((tablestate) => this.setTableState(tablestate), 300);


    private setTableState(tablestate: any) {

        saved.page = tablestate.page;
        saved.pageSize = tablestate.pageSize;
        saved.sorted = tablestate.sorted;
        saved.filters = tablestate.filtered;

        this.setState({
            page: tablestate.page,
            pageSize: tablestate.pageSize,
            sorted: tablestate.sorted,
            filters: tablestate.filtered,
        }, this.reloadAsync);
    };

    private async copyDraft(table_info_id: number, draft_id: number) {
        if (await confirmDialog(
            __("Megerősítés"),
            __("Biztos benne, hogy kiválónak jelöli ezt a munkát?"))) {
            try {
                let copyDraftId: number = await obtainServer().post<number>(
                    'kap/draft/copy',
                    { table_info_id, draft_id, library_id: LIBRARY_OFI_OFFICIAL_ID }
                );
                this.reloadAsync();
                alert('Másolat létrehozva.');
            } catch (e) {
                app.showErrorFromJsonResult(e);
            }
        }
    }

    render() {
        const wfFilter = (filter: any, onChange: any) => {
            return (
                <select
                    onChange={event => onChange(Number(event.target.value))}
                    style={{ width: "100%" }}
                    value={filter ? filter.value : -1}
                >
                    <option value={-1}>Mutasd mindet</option>
                    {this.state.stations.map((stationItem, index) => {
                        return (
                            <option style={stationItem.style} key={index} value={stationItem.id}>{stationItem.name || ""}</option>
                        );
                    })}
                </select>
            );
        };
        const getTdProps = (finalState: any, rowInfo: RowInfo, column?: Column, instance?: any) => {
            let props: Partial<TableProps> = {};
            if (column && rowInfo && rowInfo.row) {
                let row = rowInfo.row._original;
                if (row && column.id == "jtr_wf_station_name") {
                    props.style = row.wf_station_style || {};
                }
            }
            return props;
        }

        return <div className="row expanded" style={{ minHeight: "70vh" }}>
            <div className="column small-12">
                <br />
                <h4>DFHT-KIP óravázlatok feltöltése</h4>


                <Link className="button" to={PATH_ATTENDANCE_DRAFT_UPLOAD_EDIT + "?library_id=" + LIBRARY_JTR_BEADANDO_ID}>
                    <i className="fa fa-plus" /> Új óravázlat feltöltése *
                </Link>
                <p>
                    * Az "Új óravázlat feltöltése" gomb megnyomásával hozzájárulok, hogy az SZTR online felületére feltöltött, általam készített óraterveket az EFOP-3.1.2-16-2016-00001 azonosítószámú projekt keretében megbízott Szakmai Támogatás Munkacsoport szakemberei kezeljék, áttekintsék, javaslatokkal lássák el. A feltöltés a szakmai felkészülést segíti.
                </p>
                {/* 
                    Hozzájárulok, hogy az SZTR online felületére feltöltött, általam készített, az EFOP-3.1.216-2016-00001 azonosítószámú projekt keretében megbízott Szakmai Támogatás Munkacsoport szakemberei által kiválónak vélt óratervek a Komplex Alapprogram Tudástárába feltöltésre kerüljenek a nevem feltüntetése mellett. A feltöltés tényérol értesítést kapok.
                */}

                <label>
                    Az eddig felvett óravázlatok: {this.state.count ? this.state.count : ""}
                </label>
                <ReactTable
                    columns={[
                        {
                            Header: __("Tantárgy / Téma"), accessor: "topic", filterable: false, sortable: false, width: 180,
                            Cell: (data: { original: IViewKapDraftRecord }, column: any) => {
                                const row: IViewKapDraftRecord = data.original;
                                return <div>
                                    <span style={{ fontFamily: "monospace", color: "green" }}>{row.subject_name}</span>
                                    <br />
                                    <span>{row.topic}</span>
                                </div>;
                            }
                        },
                        {
                            Header: __("Ellátási hely"), accessor: "institute_site_supporter", filterable: true, sortable: false,
                            Cell: (data: { original: IViewKapDraftRecord }, column: any) => {
                                const row: IViewKapDraftRecord = data.original;
                                return <div>
                                    {row.institute_site_supporter
                                        ? <div>
                                            <span>{row.institute_site_supporter.split("|")[2]}</span><br/>
                                            <span style={{ fontFamily: "monospace", color: "green" }}>{row.institute_site_supporter.split("|")[3]+" "+row.institute_site_supporter.split("|")[4]}</span>
                                        </div>
                                        : ""
                                    }
                                </div>;
                            }
                        },
                        { Header: __("Létrehozó"), accessor: "creator", filterable: true, sortable: false },
                        /* Ideiglenesen eltávolítva https://kb.itk.uni-eszterhazy.hu/project/38/task/2107 */
                        {
                            Header: __("Létrehozva"), filterable: false, sortable: false, width: 180,
                            Cell: (data: { original: IViewKapDraftRecord }, column: any) => formatDate(data.original.creation_time)
                        },
                        {
                            Header: __("Státusz"), accessor: "jtr_wf_station_name", filterable: true, sortable: false, style: { justifyContent: "center" }, maxWidth: 200,

                            Cell: (data: any, column: any) => {

                                const row: IViewKapDraftRecord = data.original;
                                return <span style={row.jtr_wf_station_style}>{row.jtr_wf_station_name}
                                    {row.last_copy_id ?
                                        <label className="label success">Kiválónak jelölve</label> : null}
                                </span>;

                            },
                            filterMethod: (filter: any, row: RowInfo) => {
                                return row[filter.id] === filter.value;
                            },
                            Filter: params => wfFilter(params.filter, params.onChange),

                        },
                        {
                            Header: __("Műveletek"), filterable: false, sortable: false, className: "action-cell", width: 180,
                            Cell: (data: { original: IViewKapDraftRecord }, column: any) => {
                                let link = PATH_ATTENDANCE_DRAFT_UPLOAD_EDIT + "/" + data.original.id + "?library_id=" + LIBRARY_JTR_BEADANDO_ID;

                                const canCopyDraft = data.original.consent_publication 
                                    && me && hasAnyGroup(me, [Groups.Admin, Groups.Developer, Groups.KapJtrCoordinator, Groups.KapJtrSupport]);

                                return <>
                                    <Link className="button small" to={link}>
                                        <i className="fa fa-edit"></i>
                                    </Link>
                                    {(canCopyDraft
                                        && data.original.jtr_wf_station_id == WF_DFHT_KIP_STATUS_ELFOGADVA_ID
                                        && !data.original.last_copy_id
                                    ) ? <button className="button small secondary" title={__("Kiválónak jelöl")}
                                        onClick={(e) => this.copyDraft(data.original.table_info_id!, data.original.id!)}
                                    >
                                            <i className="fa fa-copy" />
                                        </button> : null}
                                </>
                            }
                        },
                    ]}
                    manual
                    filterable={true}
                    defaultFilterMethod={(filter, row) =>
                        String(row[filter.id]) === filter.value}
                    data={this.state.drafts}
                    defaultPageSize={this.state.pageSize}
                    defaultFiltered={this.state.filters}
                    defaultSorted={this.state.sorted}
                    pages={Math.ceil(this.state.count / this.state.pageSize)}
                    onFetchData={this.onFetchData.bind(this)}
                    loading={this.state.loading}
                    getTdProps={getTdProps}
                    {...getReactTableLabels()}
                />

            </div>
        </div>
    }

}
