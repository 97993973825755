import { Server } from '../../server/Server';
import View , { registerViewClassProxy, ViewClassProxy } from '../View';
import { IListParams } from '@framework/crud/Crud';

export interface IViewTicketClaimRecord {
    /**
     * Azonosító
     *
     * Azonosító (pótkulcs)
     */
    id?: number;
    /**
     * Rekord létrehozás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel létrehozásakor.
     */
    creation_time?: string /*timestamp?*/;
    /**
     * Ki hozta létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozó felhasználóra hivatkozik.
     */
    creation_user_id?: number;
    /**
     * Melyik session-ből jött létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozáshoz használt munkamenetre hivatkozik.
     */
    creation_session_id?: number|null;
    /**
     * Utolsó módosítás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel módosításakor.
     */
    modification_time?: string /*timestamp?*/|null;
    /**
     * Ki módosította utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosító felhasználóra hivatkozik.
     */
    modification_user_id?: number|null;
    /**
     * Melyik session-ből módosították utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosításhoz használt munkamenetre hivatkozik.
     */
    modification_session_id?: number|null;
    /**
     * Aktív
     *
     * Logikai törléshez használt mező.
     */
    is_active?: boolean;
    /**
     * Cím
     */
    title?: string;
    /**
     * Leírás
     */
    description_html?: string|null;
    /**
     * Az igény könyvtára
     *
     * Az igény saját virtuális könyvtára, ahová a résztvevők tudnak feltölteni állományokat
     */
    oo_folder_id?: number|null;
    /**
     * Igény típus
     */
    claim_type_id?: number;
    /**
     * Létrehozta
     *
     * Felhasználó, aki létrehozta
     */
    creator?: string|null;
    /**
     * Típus név
     *
     * Igény típus neve
     */
    claim_type_name?: string|null;
    /**
     * Kijelzett név
     *
     * Humánok számára, szöveges reprepezentáció
     */
    displayvalue?: string|null;
}

/**
 *  ticket.view_ticket_claim - Igény
 *
 *  Egy igény amihez tartozik egy workflow, linkek, állományok és van felelőse (vagy felelősei).
 */

export default class ViewTicketClaim extends View<IViewTicketClaimRecord> {
    public static VIEW_INFO_ID = 2018051401;

    public static getSchemaNameForClass(): string { return 'ticket'; }
    public static getViewNameForClass(): string { return 'view_ticket_claim'; }
    public static getTableNameForClass(): string|null { return 'claim'; }

    public static load: (id: number, aServer ?: Server) => Promise<ViewTicketClaim>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<IViewTicketClaimRecord[]>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class ViewTicketClaimClassProxy extends ViewClassProxy<IViewTicketClaimRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const viewTicketClaimClassProxy = new ViewTicketClaimClassProxy(ViewTicketClaim);
registerViewClassProxy(viewTicketClaimClassProxy);

