import * as React from "react";
import OpenEndedQuestionCrud, { IOpenEndedQuestionRecord } from "@src/framework/crud/usr/OpenEndedQuestionCrud";
import { __ } from "@src/translation";
import { HTMLTextarea } from "@src/component/HtmlTextarea";
import MediaFileChooser, { ContentActionType, ALL_ACTIONS } from "@src/component/filemanager/MediaFileChooser";
import { hasGroup, Groups, me } from "@src/framework/server/Auth";
import { app } from "@src/index";
import { whiteSpaceChecker } from "@src/component/WhiteSpaceChecker";
import OoFolderCrud, { IOoFolderRecord } from "@src/framework/crud/media/OoFolderCrud";
import { createFolderForOpenEndedExercise } from "@src/framework/api/exercise/getExercise";

type OpenEndedExerciseEditorProps = {
    onItemSaved?: (exerciseSeriesRecord: IOpenEndedQuestionRecord) => void,
    recorId?:number
}

type OpenEndedExerciseEditorState = {
    exerciseRecord: IOpenEndedQuestionRecord,
    validationMessages: Map<string, string>,
    loading: boolean
}
export default class OpenEndedExerciseEditor extends React.Component<OpenEndedExerciseEditorProps, OpenEndedExerciseEditorState> {

    constructor(props: OpenEndedExerciseEditorProps) {
        super(props);
        let exerciseRecord: IOpenEndedQuestionRecord = { subject: "" };
        let validationMap: Map<string, string> = new Map<string, string>();
        this.state = {
            exerciseRecord,
            validationMessages: validationMap,
            loading: false
        };
        this.reloadAsync();
    }

    async reloadAsync(){
        try{
            console.log("calling openended editor", this.props.recorId);
            if(!this.props.recorId) return;

            let record: IOpenEndedQuestionRecord  = (await OpenEndedQuestionCrud.load(this.props.recorId)).record;
            console.log("record", record);
            this.setState({exerciseRecord:record});
        }catch(error){
            app.showErrorFromJsonResult(error);
        }
    }

    async onSave() {
        this.setState({ loading: true });
        let errorMsg = "";
        let sc: IOpenEndedQuestionRecord = this.state.exerciseRecord;
        let validationMessages = new Map<string, string>();
        if (!whiteSpaceChecker(sc.subject)) {
            errorMsg = __("A cím kötelező!");
            app.showError(__("Hiba"), errorMsg);
            validationMessages.set("subject", errorMsg);
        }
        if (!whiteSpaceChecker(sc.body_html)) {
            errorMsg = __("A kérdés kötelező!");
            app.showError(__("Hiba"), errorMsg);
            validationMessages.set("body_html", errorMsg);
        }
        if (validationMessages.size > 0) {
            this.setState({ loading: false, validationMessages });
            return;
        }

        try {
            //sc.shared_by_id = me!.id;
            sc.oo_folder_id = undefined;
            let record: IOpenEndedQuestionRecord = (await new OpenEndedQuestionCrud(sc).put()).record;
            app.showSuccess(__("Mentés"), __("Sikeres mentés"));
            this.setState({ loading: false, exerciseRecord: record, validationMessages: new Map<string, string>() });

        } catch (error) {
            app.showErrorFromJsonResult(error);
            this.setState({ loading: false });
        }
    }

    async addFolder() {
        if (!me) return;
        await this.onSave();
        if (!this.state.exerciseRecord.id) {
            return;
        }
        let oo_folder_id = (await createFolderForOpenEndedExercise(this.state.exerciseRecord.id));
        let sc: IOpenEndedQuestionRecord = this.state.exerciseRecord;
        sc.oo_folder_id = oo_folder_id;
        this.setState({ exerciseRecord: sc });
    }

    async editingFinished() {
        if (!me) return;      
        await this.onSave();
        if (!this.state.exerciseRecord.id) {
            return;
        }
        if(this.props.onItemSaved) this.props.onItemSaved(this.state.exerciseRecord);
    }

    render() {
        if (!me) return "";
        let exerciseRecord: IOpenEndedQuestionRecord = this.state.exerciseRecord;
        let filePermits: number[] = [ContentActionType.FILE_DOWNLOAD];

        if(!exerciseRecord) return;
        // const wf_id = this.props.currentCourse.wf_station_id;
        // if (!wf_id || wf_id == ENROLL_PREPARATION_WF_TYPE_ID || wf_id == ENROLL_REFUSED_WF_TYPE_ID || wf_id == ENROLL_MISSING_DATA_WF_TYPE_ID) {
        filePermits = filePermits.concat([ContentActionType.FILE_MODIFY, ContentActionType.FILE_UPLOAD, ContentActionType.FILE_DELETE]);

        if (hasGroup(me!, Groups.Admin)) {
            filePermits = ALL_ACTIONS;
        }
        return <div className="row expanded">
            <div>
                <h5>{__("Új szöveges feladat")}</h5>
            </div>
            <div className="medium-12 columns">
                <label>{__("Cím")}<span className="exe-editor-validation-msg">{this.state.validationMessages.get("subject")}</span>
                    <input type="text"
                        value={exerciseRecord.subject || ""}
                        placeholder={__("Írja be a feladat címét")}
                        onChange={(event) => this.handleInputChange(event, "subject")}
                    />
                </label>
            </div>
            <div className="large-12 columns">
                <label>{__("Kérdés")} </label><span className="exe-editor-validation-msg">{this.state.validationMessages.get("body_html")}</span>
                <HTMLTextarea
                    value={exerciseRecord.body_html || ""}
                    onChange={(event) => this.handleDescriptionChange(event, "body_html")}
                />
            </div>
            {!exerciseRecord.oo_folder_id ?
                <div className="large-12 columns">
                    <button className="button small" onClick={(event) => { this.addFolder() }}>{__("Fájlok feltöltése")}</button>
                </div>
                :
                <div>
                    <MediaFileChooser
                        rootFolderId={exerciseRecord.oo_folder_id!}
                        currentFolderId={exerciseRecord.oo_folder_id!}
                        showHoverPreview={false}
                        showLocation={false}
                        showFilter={false}
                        showFolders={false}
                        enabledActions={filePermits}
                    />

                </div>
            }
            {this.props.onItemSaved &&
                <div className="large-12 columns">
                    <button className="button small primary float-right" onClick={(event) => { this.editingFinished() }}>{__("Tovább")}</button>
                </div>
            }
        </div>
    }

    handleInputChange(event: any, fieldName: string) {
        if (!event || !event.target) return;
        let tmp = this.state.exerciseRecord;
        tmp[fieldName] = event.target.value;
        this.setState({ exerciseRecord: tmp });
    }

    handleDescriptionChange(value: any, fieldName: string) {
        let tmp = this.state.exerciseRecord;
        tmp[fieldName] = value;
        this.setState({ exerciseRecord: tmp });
    }

}