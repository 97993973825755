import { Module, ModuleMenu, BaseMenu, ModuleRoute } from "../Module";
import { __ } from "@src/translation";
import { hasAnyGroup, me, Groups } from "@src/framework/server/Auth";
import { Translation } from "./Translation";

export let translationModule: TranslationModule<BaseMenu>;

export type TranslationModuleConfig = {

}

export class TranslationModule<T extends BaseMenu> extends Module<T> {
    
    private config: TranslationModuleConfig;

    public PATH_TRANSLATION_TRANSLATE = "/translation/translate";
    public PATH_TRANSLATION_UNTRANSLATED = "/translation/untranslated";

    constructor(config: TranslationModuleConfig) {
        super();

        this.config = config;
    }

    public static initGlobal<T extends BaseMenu>(config: TranslationModuleConfig) {
        translationModule = new TranslationModule<T>(config);
    }
    
    public async getRoutesAsync(): Promise<Record<string, ModuleRoute<T>>>
    {
        return this.getRoutes();
    }
    
    public getRoutes(): Record<string, ModuleRoute<T>> {

        let ret: Record<string, ModuleRoute<T>> = {
            'PATH_TRANSLATION_TRANSLATE' : { path: this.PATH_TRANSLATION_TRANSLATE, component: Translation},
        };

        return ret;
    }

    public getRecordRoute(tableInfoId: number, recordId: number, edit?:boolean): string|null {
        return null;
    }

    public getMenus() {
        let ret: ModuleMenu<T>[] = [];

        if (!me || !hasAnyGroup(me, [Groups.Admin, Groups.Developer])) return ret;

        ret = ret.concat(
        [
            {
                enabled: true,
                title: __("Fordítás"),
                path: this.PATH_TRANSLATION_TRANSLATE,
                iconClass: "fa fa-language",
                code: "header-function",
                no: 1000
            },
        ]);

        return ret;
    }

}
