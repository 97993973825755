
import { EKECrossWordServer } from '../engine/EKECrossWordExerciseEngine/EKECrossWordServer';
import { EKEFillGapDnDServer } from '../engine/EKEFillGapDnDExerciseEngine/EKEFillGapDnDServer';
import { EKEFillGapDropDownServer } from '../engine/EKEFillGapDropDownExerciseEngine/EKEFillGapDropDownServer';
import { EKEFillTableDnDServer } from '../engine/EKEFillTableDnDExerciseEngine/EKEFillTableDnDServer';
import { EKEMultiTextAnswerServer } from '../engine/EKEMultiTextAnswerExerciseEngine/EKEMultiTextAnswerServer';
import { EKEOddOneOutServer } from '../engine/EKEOddOneOutExerciseEngine/EKEOddOneOutServer';
import { EKEPairingServer } from '../engine/EKEPairingExerciseEngine/EKEPairingServer';
import { EKEQuizServer } from '../engine/EKEQuizExerciseEngine/EKEQuizServer';
import { EKEQuizSeriesServer } from '../engine/EKEQuizSeriesExerciseEngine/EKEQuizSeriesServer';
import { EKESortingServer } from '../engine/EKESortingExerciseEngine/EKESortingServer';
import { EKESudokuServer } from '../engine/EKESudokuExerciseEngine/EKESudokuServer';
import { WMAssignPointToQuestionServer } from '../engine/WMAssignPointToQuestionExerciseEngine/WMAssignPointToQuestionServer';
import { WMFillTableTextServer } from '../engine/WMFillTableTextExerciseEngine/WMFillTableTextServer';
import { WMItemToImageServer } from '../engine/WMItemToImageDnDExerciseEngine/WMItemToImageServer';
import { WMItemToSetServer } from '../engine/WMItemToSetDnDExerciseEngine/WMItemToSetServer';
import { WMNumberLineServer } from '../engine/WMNumberLineExerciseEngine/WMNumberLineServer';
import { WMNumberPyramidServer } from '../engine/WMNumberPyramidExerciseEngine/WMNumberPyramidServer';
import { EKEPairingDnDServer } from '../engine/EKEPairingDnDExerciseEngine/EKEPairingDnDServer';
import { WMItemLinkLineServer } from '../engine/WMItemLinkLineExerciseEngine/WMItemLinkLineServer';
import { PuzzleGameServer } from '../engine/PuzzleGame/PuzzleGameServer';
import { MemoryCardGameServer } from '../engine/MemoryCardGame/MemoryCardGameServer';
import { WordFinderGameServer } from '../engine/WordFinderGame/WordFinderGameServer';
import { ColoringGameServer } from '../engine/ColoringGame/ColoringGameServer';
import { UniversalEngineServer } from '../engine/UniversalEngine/UniversalEngineServer';
import { FillGapTextServer } from '../engine/FillGapTextExerciseEngine/FillGapTextServer';

export const exerciseServerClasses = {

    EKECrossWordExerciseEngine: EKECrossWordServer,
    EKEFillGapDnDExerciseEngine: EKEFillGapDnDServer,
    EKEFillGapDropDownExerciseEngine: EKEFillGapDropDownServer,
    EKEFillTableDnDExerciseEngine: EKEFillTableDnDServer,
    EKEMultiTextAnswerExerciseEngine: EKEMultiTextAnswerServer,
    EKEOddOneOutExerciseEngine: EKEOddOneOutServer,
    EKEPairingDnDExerciseEngine: EKEPairingDnDServer,
    EKEPairingExerciseEngine: EKEPairingServer,
    EKEQuizExerciseEngine: EKEQuizServer,
    EKEQuizSeriesExerciseEngine: EKEQuizSeriesServer,
    EKESortingExerciseEngine: EKESortingServer,
    EKESudokuExerciseEngine: EKESudokuServer,
    WMAssignPointToQuestionExerciseEngine: WMAssignPointToQuestionServer,
    WMFillTableTextExerciseEngine: WMFillTableTextServer,
    WMItemLinkLineExerciseEngine: WMItemLinkLineServer,
    WMItemToImageDnDExerciseEngine: WMItemToImageServer,
    WMItemToSetDnDExerciseEngine: WMItemToSetServer,
    WMNumberLineExerciseEngine: WMNumberLineServer,
    WMNumberPyramidExerciseEngine: WMNumberPyramidServer,
    UniversalExerciseEngine: UniversalEngineServer,
    PuzzleGame: PuzzleGameServer,
    MemoryCardGame: MemoryCardGameServer,
    WordFinderGame: WordFinderGameServer,
    ColoringGame: ColoringGameServer,
    FillGapTextExerciseEngine: FillGapTextServer
}