export const INDESIGN_FOLDER_NAME = "InDesign_Export";

export const SHARE_MEGOSZTAS_ID = 1;
export const SHARE_GYAKORLO_ID = 2;
export const SHARE_VIZSGA_ID = 3;

export const LANG_ID_HU = 3432150755;
export const LANG_ID_EN = 4082745666;
export const LANG_ID_SK = 2678816794;
export const LANG_ID_RO = 2178774338;
export const LANG_ID_EST = 238762040;

/*
select 'export const WF_TYPE_' || upper(code) || '_ID = ' || id || ';' as ts_code
from wf.wf_type order by 1 asc */
export const WF_TYPE_IGENY_ID = 109388574;
export const WF_TYPE_MEDIA_ID = 71058591;
export const WF_TYPE_OKOSFELADAT_ID = 378143017;
export const WF_TYPE_OKOSFELADATSOR_ID = 378143019;
export const WF_TYPE_OKOSTANKONYV_FEJEZET_ID = 378143037;
export const WF_TYPE_OKOSTANKONYV_ID = 378143035;
export const WF_TYPE_OKOSTANKONYV_LECKE_ID = 378143046;
export const WF_TYPE_ORAVAZLAT_ID = 16086523463;

export const WF_TYPE_KAP_ESZTR_ID = 25346145524;
export const WF_TYPE_KAP_ORAVAZLAT_ID = 25346112800;
export const WF_TYPE_KAP_JTR_BEADANDO_ORAVAZLAT_ID = 25408996780;

export const WF_TYPE_KAP_COURSE_USER_ID = 25406740433;

export const LIBRARY_OFI_OFFICIAL_ID = 6700;
export const LIBRARY_PERSONAL_ID = 6800;
export const LIBRARY_NKP_ID = 6900;
export const LIBRARY_JTR_BEADANDO_ID = 7000;
export const LIBRARY_JTR_ORALATOGATAS_ID = 7100;
export const LIBRARY_KSZR_BEADANDO_ID = 7200;

/* select 'export const FOLDER_XXX_ID = ' || id || '; // ' || title from media.oo_folder where id < 1000 order by id asc; */
export const FOLDER_USERS_ID = 1; // Felhasználók
export const FOLDER_CLAIMS_ID = 2; // Igények
export const FOLDER_EXERCISES_ID = 3; // Feladatok
export const FOLDER_LESSONPLANS_ID = 4; // Óravázlatok
export const FOLDER_KNOWLEDGEBASE_ID = 5; // Tudásbázis
export const FOLDER_SHUTTERSTOCK_ID = 6; // ShutterStock
export const FOLDER_PRIVATE_EXERCISES_ID = 7; // Privát feladatok
export const FOLDER_IMPORT_TASKS_ID = 8; // Importálási feladatok
export const FOLDER_DL_MATERIAL_ID = 9; // Letölthető anyagok
export const FOLDER_LEARNING_PATH_ID = 10; // Tanulási útvonalak
export const FOLDER_PRIVATE_LEARNING_PATH_ID = 11; // Privát tanulási útvonalak
export const FOLDER_PRIVATE_LESSONPLAN_ID = 12; // Privát óravázlatok
export const FOLDER_JTR_SUBPROGRAM_ID = 13; // Alprogrami szintű foglalkozás látogatás
export const FOLDER_JTR_DFHT_KIP_CLASS_ID = 14; // DFHT KIP és Komplex óra látogatás
export const FOLDER_CALENDAR_EVENTS_ID = 15; // Események
export const FOLDER_JTR_DFHT_KIP_INSTITUTE_ID = 16; // DFHT Intézmény látogatás
export const FOLDER_KSZR_TRAINING_TYPE_DOCS_ID = 17; // KSZR képzés típus dokumentációk
export const FOLDER_INSTITUTE_GROUP_ID = 18; // Tanulócsoportok
export const FOLDER_OPEN_ENDED_QUESTIONS_ID = 19; // Nyílt végű kérdések
export const FOLDER_SUBMITTED_ANSWERS_ID = 20; // Nyílt végű kérdésre beküldött válaszok
export const FOLDER_OLD_NKP_PUBLISHED_CONTENT_ID = 100; // Régi - Nyilvános tartalmak
export const FOLDER_NKP_COMMON_ID = 201; // NKP Közös fájlok
export const FOLDER_NKP_CMS_CONTENT_ID = 202; // NKP Cikkek
export const FOLDER_KAP_KSZR_TRAINING_PERMITS_ID = 204; // KAP KSZR Képzési engedélyek
export const FOLDER_KAP_KSZR_COURSES_ID = 205; // KAP KSZR Képzések
export const FOLDER_KAP_KSZR_TRAINER_GUIDES_ID = 206; // KSZR képzői kézikönyvek
export const FOLDER_KAP_KSZR_STUDENT_GUIDES_ID = 207; // KSZR résztvevői kézikönyvek
export const FOLDER_HTR_TICKETS_ID = 301; // HTR bejelentések

/*

select id, name from wf.station s where wf_type_id=25406740433 order by 2 asc

*/

export const WF_STATION_KSZR_COURSE_USER_STATION_BEADANDO_ELBIRALAS_ALATT_ID = 25406740439; // Beadandó feladat elbírálás alatt
export const WF_STATION_KSZR_COURSE_USER_STATION_SIKERESEN_LEZARVA_ID = 25406740440; // Beadandó feladat sikeresen lezárva
export const WF_STATION_KSZR_COURSE_USER_STATION_SIKERTELENUL_LEZARVA_ID = 25406740441; // Beadandó feladat sikertelenül lezárva
export const WF_STATION_KSZR_COURSE_USER_STATION_KEPZESBOL_TOROLVE_ID = 25406740442; // Képzésből törölve
export const WF_STATION_KSZR_COURSE_USER_STATION_KEPZES_ENGEDLYEZVE_ID = 25406740438; // Képzés engedélyezve
export const WF_STATION_KSZR_COURSE_USER_STATION_KERELEM_BEADVA_ID = 25406740434; // Kérelem beadva
export const WF_STATION_KSZR_COURSE_USER_STATION_KERELEM_VISSZAUTASITVA_ID = 25406740437; // Kérelem visszautasítva
export const WF_STATION_KSZR_COURSE_USER_STATION_NEM_TELJESITETTE_ID = 25408617064; // Nem teljesítette

export const WF_STATION_KSZR_TRAINER_INVITE_WORKFLOW_ID = 25408797512 ; // Nem teljesítette
/**
 * ==========================================================================
 * z-indexek, SZINKRONBAN KELL TARTANI .css-es IS!
 * Ha itt állítasz valamit, keresd meg .css-ben a konstans nevét, és fordítva
 * * ========================================================================
 */

/**
 * Fealadatmotorokon belül: 0 - 10
 */


/**
 * Feladat felugró szöveg mögötti elszürkítés
 */
export const Z_INDEX_EXERCISE_POPUP_BACKGROUND = 9;

/**
 * Feladat felugró szöveg)
 */
export const Z_INDEX_EXERCISE_POPUP = 10;

/**
 * Tankönyv rajzolás canvas
 */
export const Z_INDEX_BOOK_DRAW_CANVAS = 100;

/**
 * Tankönyv szerkesztő sticky "Előnézet" és "mentés" gombok
 */
export const Z_INDEX_EDITOR_STICKY = 200;


/** 
 * Sidebar
 * */
export const Z_INDEX_SIDEBAR = 500;


/**
 * Tankönyv sticky menü és eszköztár menü (az egész sáv és a lenyíló része)
 */
export const Z_INDEX_BOOK_STICKY = 800;


/** 
 * Fejléc
 * */
export const Z_INDEX_HEADER = 850;

/**
 * Fejléc menü lenyíló része
 */
export const Z_INDEX_HEADER_MENU_ITEM = 551;

/**
 * Foundation tankönyv felugró ablakok
 */
export const Z_INDEX_FOUNDATION_REVEAL_OVERLAY = 900;

/**
 * React dialógusablakok
 */
export const Z_INDEX_DIALOG_ROOT = 9900;

/**
 * app.showXXX értesítések
 */
export const Z_INDEX_NOTIFICATION = 9998;

/**
 * Fejléc jobboldali menüelem, saját menü (TODO: nem kell?)
 */
export const Z_INDEX_HEADER_MENU_DROPDOWN = 9999;

/**
 * Feladatmotor fullscreen
 */
export const Z_INDEX_EXERCISE_FULLSCREEN = 9999;
/**
 * LO fullscreen
 */
export const Z_INDEX_LO_FULLSCREEN = 9999;

/**
 * Lightbox elszürkítés overlay
 */
export const Z_INDEX_LIGHTBOX_BACKGROUND = 9999;

/**
 * Lightbox tartalom
 */
export const Z_INDEX_LIGHTBOX = 10000;

// gradeId-k 1-8. osztályig
export const PRIMARY_SCHOOL_GRADE_IDS = [6800, 6900, 7000, 7100, 7200, 7300, 7400, 7500];


// gradeId-k 9-12. osztályig
export const SECONDARY_SCHOOL_GRADE_IDS = [7600, 7700, 7800, 7900];