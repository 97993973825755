import { Server } from '../../server/Server';
import View , { registerViewClassProxy, ViewClassProxy } from '../View';
import { IListParams } from '@framework/crud/Crud';

export interface IViewSharedWithRecord {
    /**
     * Azonosító
     *
     * Azonosító (pótkulcs)
     */
    id?: number;
    /**
     * Rekord létrehozás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel létrehozásakor.
     */
    creation_time?: string /*timestamp?*/;
    /**
     * Ki hozta létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozó felhasználóra hivatkozik.
     */
    creation_user_id?: number;
    /**
     * Melyik session-ből jött létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozáshoz használt munkamenetre hivatkozik.
     */
    creation_session_id?: number|null;
    /**
     * Utolsó módosítás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel módosításakor.
     */
    modification_time?: string /*timestamp?*/|null;
    /**
     * Ki módosította utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosító felhasználóra hivatkozik.
     */
    modification_user_id?: number|null;
    /**
     * Melyik session-ből módosították utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosításhoz használt munkamenetre hivatkozik.
     */
    modification_session_id?: number|null;
    /**
     * Aktív
     *
     * Logikai törléshez használt mező.
     */
    is_active?: boolean;
    /**
     * Megosztott tartalom
     */
    shared_content_id?: number;
    /**
     * Kivel van megosztva
     *
     * Akkor kell tölteni, ha a megosztás célja egy felhasználó.
     */
    sec_user_id?: number|null;
    /**
     * Kivel van megosztva
     *
     * Akkor kell tölteni, ha a megosztás célja egy intézményi csoport.
     */
    institute_group_id?: number|null;
    /**
     * Megosztó
     *
     * Aki a megosztást végezte
     */
    shared_by_id?: number|null;
    /**
     * Megosztott tartalom (táblázat azonosító)
     *
     * Megosztott tartalom (táblázat azonosító)
     */
    table_info_id?: number|null;
    /**
     * Megosztott tartalom (táblázat név)
     *
     * Megosztott tartalom (táblázat név)
     */
    table_display_name?: string|null;
    /**
     * Megosztott tartalom (rekord azonosító)
     *
     * Megosztott tartalom (rekord azonosító)
     */
    rec_id?: number|null;
    /**
     * Megosztás címe
     *
     * Megosztás címe
     */
    title?: string|null;
    /**
     * Megosztás leírása
     *
     * Megosztás leírása
     */
    description?: string|null;
    /**
     * Aktív tag
     *
     * Aktív-e a felhasználó akivel megosztották
     */
    member_is_active?: boolean|null;
    /**
     * Teljes név
     *
     * A cél felhasználó teljes természetes neve
     */
    fullname?: string|null;
    /**
     * E-mail
     *
     * A cél felhasználó e-mail címe
     */
    email?: string|null;
    /**
     * Bejelentkezési név
     *
     * A cél felhasználó bejelentkezési neve
     */
    login_name?: string|null;
    /**
     * Csoport név
     *
     * A cél csoport neve
     */
    institute_group_name?: string|null;
    /**
     * Megosztó teljes név
     *
     * Annak a felhasználónak a teljes természetes neve, aki a megosztást végezte
     */
    shared_by_fullname?: string|null;
    /**
     * Megosztó e-mail
     *
     * Annak a felhasználónak az e-mail címe, aki a megosztást végezte
     */
    shared_by_email?: string|null;
    /**
     * Annak a felhasználónak a bejelentkezési neve, aki a megosztást végezte
     *
     * Annak a felhasználónak a bejelentkezési neve, aki a megosztást végezte
     */
    shared_by_login_name?: string|null;
}

/**
 *  usr.view_shared_with - Megosztás célja
 *
 *  Ez mondja meg, hogy a tartalom kivel vagy kikkel van megosztva.
 */

export default class ViewSharedWith extends View<IViewSharedWithRecord> {
    public static VIEW_INFO_ID = 2019010201;

    public static getSchemaNameForClass(): string { return 'usr'; }
    public static getViewNameForClass(): string { return 'view_shared_with'; }
    public static getTableNameForClass(): string|null { return 'shared_with'; }

    public static load: (id: number, aServer ?: Server) => Promise<ViewSharedWith>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<IViewSharedWithRecord[]>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class ViewSharedWithClassProxy extends ViewClassProxy<IViewSharedWithRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const viewSharedWithClassProxy = new ViewSharedWithClassProxy(ViewSharedWith);
registerViewClassProxy(viewSharedWithClassProxy);

