import * as React from 'react';
import { BubbleLoader } from 'react-css-loaders';

import { app, history } from '@src/index';
import CrudSelectComponent from '@src/framework/forms/crudselect';

import StationCrud, { IStationRecord } from '@src/framework/crud/wf/StationCrud';
import { IWfTypeRecord, wfTypeCrudClassProxy } from '@src/framework/crud/wf/WfTypeCrud';
import TransitionTypeCrud from '@src/framework/crud/wf/TransitionTypeCrud';
import ViewTransitionType, { IViewTransitionTypeRecord } from '@src/framework/view/wf/ViewTransitionType';


import './wf_transition_matrix_editor.css';
import ViewWfType from '@src/framework/view/wf/ViewWfType';
import { SecTransitionPermListViewForTransitionType } from '@src/component/wf/wf_sec_transition_perm_listview';
import { __ } from '@src/translation';

interface IWfTransitionMatrixEditorProps {
    wfTypeId?: number;
    match: any;
}

interface ICheckboxState {
    srcStationId: number;
    dstStationId: number;
    enabled: boolean;
    mustJustify: boolean;
    description: string | null;
    itemIndex?: number; // Ez csak azért nem kötelező, mert a changed-ben nincs ilyen.
}

type CheckboxStates = { [pairKey: string]: ICheckboxState };

interface IWfTransitionMatrixEditorState {
    loading: boolean;
    saving: boolean;
    wfTypeId: number | null;
    wfType: IWfTypeRecord | null;
    items: IViewTransitionTypeRecord[];
    stations: IStationRecord[];
    extraLegend: JSX.Element | null;

    orig: CheckboxStates;
    changed: CheckboxStates;

    editedSrcId: number | null;
    editedDstId: number | null;
}

const EMPTY_STATE: IWfTransitionMatrixEditorState = {
    loading: false,
    saving: false,
    wfTypeId: null,
    wfType: null,
    items: [],
    stations: [],
    extraLegend: null,
    orig: {},
    changed: {},
    editedSrcId: null,
    editedDstId: null,
}


export default class WfTransitionMatrixEditor
    extends React.Component<IWfTransitionMatrixEditorProps,
    IWfTransitionMatrixEditorState> {

    constructor(props: IWfTransitionMatrixEditorProps) {
        super(props);
        let wfTypeId = this.getMatchParam("recId");
        if (wfTypeId && wfTypeId.trim()) {
            wfTypeId = parseInt(wfTypeId) || null;
        } else {
            wfTypeId = null;
        }
        this.state = { ...EMPTY_STATE, loading: true, wfTypeId };
    }

    protected getMatchParam(name: string): any {
        const match: any = this.props.match;
        return (match && match.params) ? match.params[name] : null;
    }

    componentDidMount() {
        this.asyncReload();
    }

    private asyncReload = async () => {
        try {
            let wfTypeId = this.state.wfTypeId;
            if (wfTypeId) {
                wfTypeId = parseInt("" + wfTypeId);

                // A fő constraint rekord.
                const wfType = (await ViewWfType.load(wfTypeId)).record;
                // Lekérdezzük a tárolt item-eket.
                const items = await ViewTransitionType.list({
                    filter: { type_id: wfTypeId }
                });
                // Előállítjuk az eredeti elemeket tartalmazó dict-et.
                let orig: CheckboxStates = {};
                items.forEach((item, index) => {
                    const key = this.getKey(item.src_station_id!, item.dst_station_id!);
                    orig[key] = {
                        srcStationId: item.src_station_id!,
                        dstStationId: item.dst_station_id!,
                        itemIndex: index,
                        enabled: item.is_active!,
                        mustJustify: item.must_justify!,
                        description: item.description || null,
                    }
                })

                // Lekérdezzük az állapotokat
                const stations = await StationCrud.list({ filter: { wf_type_id: wfType.id!, is_active: true }, order_by: "no" });

                this.setState({
                    loading: false,
                    saving: false,
                    wfTypeId,
                    wfType,
                    items,
                    stations,
                    orig, changed: {}
                });
            } else {
                this.setState({ ...EMPTY_STATE, loading: false });
            }
        } catch (error) {
            app.showErrorFromJsonResult(error);
        }
    }

    private onSelectConstraint = (sender: CrudSelectComponent, wfTypeId: number | null) => {
        this.setState({ wfTypeId, loading: true }, () => {
            if (wfTypeId) {
                history.replace('/wf/transition_matrix/' + wfTypeId);
            }
            this.asyncReload();
        });
    }

    private getKey = (srcStationId: number, dstStationId: number): string => {
        return "" + srcStationId + "_" + dstStationId;
    }

    private getItem = (srcStationId: number, dstStationId: number): ICheckboxState => {
        const key = this.getKey(srcStationId, dstStationId);
        if (this.state.changed[key]) {
            return this.state.changed[key];
        } else if (this.state.orig[key]) {
            return this.state.orig[key];
        } else {
            return {
                srcStationId, dstStationId,
                enabled: false,
                mustJustify: false,
                description: null
            };
        }
    }

    private setItem = (item: ICheckboxState) => {
        const key = this.getKey(item.srcStationId, item.dstStationId);
        this.state.changed[key] = item;
        this.setState({ changed: this.state.changed });
    }


    private isAllowed = (srcStationId: number, dstStationId: number): boolean => {
        const item = this.getItem(srcStationId, dstStationId);
        return item.enabled;
    }

    private isChanged = (srcStationId: number, dstStationId: number): boolean => {
        const key = this.getKey(srcStationId, dstStationId);
        return this.state.changed[key] ? true : false;
    }

    private getAuditInfo = (srcStationId: number, dstStationId: number): string => {
        const key = this.getKey(srcStationId, dstStationId);
        const orig = this.state.orig[key];
        const changed = this.state.changed[key];
        if (orig) {
            const item = this.state.items[orig.itemIndex!];
            // TODO: itt user id helyett a neve kell, de ahhoz kell egy view...
            return "" + item.creation_time + " " + item.creation_user_id +
                " " + (changed ? __("MEGVÁLTOZTATVA") : "");
        } else if (changed) {
            return __("ÚJ");
        } else {
            return "";
        }
    }

    private toggleAllowed = (srcStation: IStationRecord, dstStation: IStationRecord): void => {
        const srcStationId = srcStation.id!;
        const dstStationId = dstStation.id!;
        const key = this.getKey(srcStationId, dstStationId);
        const orig = this.state.orig[key];
        const changed = this.state.changed[key];
        let oldEnabled: boolean;
        let mustJustify: boolean;
        let description: string | null;
        if (changed === undefined) {
            oldEnabled = orig ? orig.enabled : false;
            mustJustify = orig ? orig.mustJustify : false;
            description = orig ? orig.description : null;
        } else {
            oldEnabled = changed.enabled;
            mustJustify = changed.mustJustify;
            description = changed.description;
        }
        const newValue = !oldEnabled;
        this.state.changed[key] = {
            srcStationId, dstStationId, enabled: newValue,
            mustJustify, description,
            itemIndex: orig?orig.itemIndex:undefined
        }
        this.setState({
            changed: this.state.changed,
            editedSrcId: srcStationId,
            editedDstId: dstStationId
        }, () => {
            this.setExtraLegend(srcStation, dstStation);
        });
    }

    private setExtraLegend = (srcStation: IStationRecord, dstStation: IStationRecord): void => {
        if (this.state.wfType) {
            if (srcStation.id! == dstStation.id!) {
                this.setState({
                    extraLegend: <span>
                        <i className="fa fa-exclamation-triangle" style={{color:"brown", fontSize:"3em"}}/>
                        {__("Ez egy tiltott átmenet - az átmenet csak különböző állapotok között van értelmezve.")}
                    </span>
                });
                return;
            }

            const item = this.getItem(srcStation.id!, dstStation.id!);
            this.setState({
                extraLegend:
                    <span>
                        <i className="fa fa-info-circle" style={{color:"blue", fontSize:"3em"}}/>
                        <b>{this.state.wfType!.name}</b> &nbsp; {__("típusú folyamat")} &nbsp;
                        <span style={srcStation.style!}>{srcStation.name!}</span>
                        &nbsp; { __("állapotból") }  &nbsp;
                        {item.enabled
                            ? <span className="label success">{__("átvihető")}</span>
                            : <span className="label alert">{__("nem vihető át")}</span>} 
                            &nbsp;
                        <span style={dstStation.style!}>
                            {dstStation.name!}
                        </span> &nbsp; {__("állapotba.")}
                        &nbsp;
                        {item.mustJustify?
                            __("Kötelező megadni indoklást.") : __("Nem kötelező indokolni.")
                        }
                        &nbsp;
                        <b>{__("Leírás:")}</b> <span className="label secondary">{item.description}</span>
                </span>
            });
        } else {
            this.setState({ extraLegend: null });
        }
    }

    private clearExtraLegend = () => {
        this.setState({ extraLegend: null });
    }

    private onSave = async () => {
        this.setState({ saving: true });
        try {
            const changed = this.state.changed;
            for (var key in changed) {
                if (changed.hasOwnProperty(key)) {
                    const item = changed[key];
                    const crud = new TransitionTypeCrud({
                        type_id: this.state.wfTypeId!,
                        src_station_id: item.srcStationId,
                        dst_station_id: item.dstStationId,
                        is_active: item.enabled,
                        must_justify: item.mustJustify,
                        description: item.description
                    });
                    await crud.upsert([
                        'type_id',
                        'src_station_id', 'dst_station_id'
                    ]);
                }
            }
            await this.asyncReload();
        } catch (error) {
            app.showErrorFromJsonResult(error);
        }

    }

    private onChangeDescription = (srcStationId: number, dstStationId: number, newValue: string | null) => {
        let item = this.getItem(srcStationId, dstStationId);
        if (newValue && newValue.trim() != "") {
            item.description = newValue;
        } else {
            item.description = null;
        }
        this.setItem(item);
    }



    private onToggleMustJustify = (srcStationId: number, dstStationId: number) => {
        let item = this.getItem(srcStationId, dstStationId);
        item.mustJustify = !item.mustJustify;
        this.setItem(item);
    }

    render() {
        const constraintSelector = <div className="small-12 column">
            <CrudSelectComponent
                value={this.state.wfTypeId}
                onSelect={this.onSelectConstraint}
                displayFieldName="name"
                crudClassProxy={wfTypeCrudClassProxy}
                emptyTitle={__("Kérem válasszon egy folyamat típust...")}
            />
        </div>;
        let description = null;
        let editor;
        let buttons = null;
        let legend = null;


        if (this.state.loading) {
            editor = <BubbleLoader />;
        } else if (!this.state.wfTypeId) {
            editor = null;
        } else {
            if (this.state.wfType!.description) {
                description = <div className="small-12 column">
                    <p className="callout primary">
                        {this.state.wfType!.description}
                    </p>
                </div>;
            }

            const stations = this.state.stations;

            let inner = <table>
                <thead>
                    <tr className="wf-transition-matrix-table-head-row">
                        <td></td>
                        {stations!.map((srcStation) =>
                            <td key={srcStation.id!}
                                style={srcStation.style!}
                                className="wf-transition-matrix-table-src-station"
                                title={srcStation.description}
                            >
                                {srcStation.name}
                            </td>
                        )}
                    </tr>
                    {stations!.map((dstStation) =>
                        <tr key={dstStation.id}>
                            <td key={dstStation.id!}
                                style={dstStation.style!}
                                title={dstStation.description}
                                className="wf-transition-matrix-table-dst-station"
                            >
                                {dstStation.name}
                            </td>
                            {stations!.map((srcStation) =>
                                <td key={this.getKey(srcStation.id!, dstStation.id!)}
                                    className={
                                        "wf-transition-matrix-table-checkbox-cell"
                                        + (
                                            (
                                                (this.state.editedSrcId == srcStation.id!)
                                                &&
                                                (this.state.editedDstId == dstStation.id!)
                                            ) ?
                                                " wf-transition-matrix-table-checkbox-cell-edited"
                                                : (
                                                    this.isChanged(srcStation.id!, dstStation.id!)
                                                        ? " wf-transition-matrix-table-checkbox-cell-changed"
                                                        : "")
                                        )
                                        + ((srcStation.id! == dstStation.id!)
                                            ? " wf-transition-matrix-table-disabled-cell disabled " : " wf-transition-matrix-table-enabled-cell")}
                                    onMouseEnter={() => {
                                        this.setExtraLegend(srcStation, dstStation)
                                    }}
                                    onMouseLeave={this.clearExtraLegend}
                                    onClick={() => {
                                        this.setState({ editedSrcId: srcStation.id!, editedDstId: dstStation.id! })
                                    }
                                    }
                                >
                                    {(srcStation.id! != dstStation.id!) ?
                                        <input type="checkbox"
                                            disabled={this.state.saving}
                                            title={this.getAuditInfo(srcStation.id!, dstStation.id!)}
                                            checked={this.isAllowed(srcStation.id!, dstStation.id!)}
                                            onChange={() => { this.toggleAllowed(srcStation, dstStation) }}
                                        />
                                        : ""
                                    }
                                </td>
                            )}

                        </tr>
                    )}
                </thead>
            </table>

            editor =
                <div className="small-12 column">
                    <table>
                        <thead>
                            <tr>
                                <th></th>
                                <th
                                    className="wf-transition-matrix-table-src-head"
                                    colSpan={stations!.length}>
                                    {this.state.wfType!.name!} {__("Forrás állapot")}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th className="wf-transition-matrix-table-dst-head"
                                >
                                    {this.state.wfType!.name!} {__("Cél állapot")}
                                </th>
                                <td>
                                    {inner}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>;


            legend = <div className="small-12 columns">
                {this.state.extraLegend ?
                    <p className="callout primary">
                        {this.state.extraLegend}
                    </p> : ""}

                <p className="callout secondary">
                    <i className="fa fa-help" />
                    {__("Csak a pipált elemekhez tartozó átmenetek engedélyezettek.")}
                    &nbsp;
                    <span className="wf-transition-matrix-table-checkbox-cell-edited">{__("Az éppen szerkesztett átmenet típus piros.")}</span>
                    &nbsp;
                    <span className="wf-transition-matrix-table-checkbox-cell-changed">{__("A megváltoztatott átmenet típus sárga.")}</span>
                </p>
            </div>;

            const nChanges = Object.keys(this.state.changed).length;
            const unchanged = nChanges === 0;
            buttons = <div className="small-12 columns">
                <a
                    className={"button success " +
                        (unchanged || this.state.saving ? "disabled" : "")}
                    onClick={this.onSave}
                >
                    <i className={"fa " +
                        this.state.saving ? "fa-hourglass" : "fa-save"}
                    /> &nbsp;
                    {this.state.saving ? __("Mentés folyamatban, kérem várjon...") :
                        (unchanged ? __("Nincs változtatás") :
                            __(`Mentés ({nChanges} változtatás)`, {n_chnage: nChanges}))
                    }
                </a>
            </div>;
        }

        let detailEditor = null;
        if (this.state.editedSrcId && this.state.editedDstId) {
            const key = this.getKey(this.state.editedSrcId, this.state.editedDstId);
            let item: ICheckboxState = this.getItem(this.state.editedSrcId, this.state.editedDstId);
            let permEditor;
            if (item.itemIndex! >= 0) {
                permEditor =
                    <div className="small-12 columns">
                        <h4>{ __("Jogosultságok kezelése") }</h4>
                        <SecTransitionPermListViewForTransitionType
                            transition_type_id={this.state.items[item.itemIndex!].id!}
                            key={key+"-present"}
                        />
                    </div>
            } else {
                permEditor = <div className="small-12 columns">
                    <p className="callout alert" key={key+"-absent"}>
                      {__("Ez az átemenet típus még nincs mentve. Változtasson valamit, nyomja meg a mentés gombot, hogy meg tudja adni a jogosultságokat.")}  
                </p>
                </div>
            }

            detailEditor = <div className="small-12 columns">
                <div className="row expanded">
                    <div className="small-12 columns">
                        <input type="checkbox" checked={item.mustJustify}
                            onChange={() => {
                                this.onToggleMustJustify(item.srcStationId, item.dstStationId);

                            }}
                        />{__("Kötelező megadni indoklást")} 
                    </div>
                    <div className="small-12 columns">
                       {__("Leírás (ha meg van adva, az átmenet gombon megjelenik)")} 
                    </div>
                    <div className="small-12 columns">
                        <input type="text" value={item.description || ""}
                            onChange={(e: any) => {
                                this.onChangeDescription(item.srcStationId, item.dstStationId, e.target.value);
                            }}
                        />
                    </div>
                </div>
                <div className="row expanded">
                    {permEditor}
                </div>
            </div>
        }

        return <div className="row">
            {constraintSelector}
            {description}
            {editor}
            {legend}
            {buttons}
            {detailEditor}
        </div>;
    }
}
