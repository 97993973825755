import { Server } from '../../server/Server';
import View , { registerViewClassProxy, ViewClassProxy } from '../View';
import { IListParams } from '@framework/crud/Crud';

export interface IViewLessonRecord {
    /**
     * Azonosító
     *
     * Azonosító (pótkulcs)
     */
    id?: number;
    /**
     * Sorrend
     */
    no?: number /*int4*/|null;
    /**
     * Rekord létrehozás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel létrehozásakor.
     */
    creation_time?: string /*timestamp?*/;
    /**
     * Ki hozta létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozó felhasználóra hivatkozik.
     */
    creation_user_id?: number;
    /**
     * Melyik session-ből jött létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozáshoz használt munkamenetre hivatkozik.
     */
    creation_session_id?: number|null;
    /**
     * Utolsó módosítás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel módosításakor.
     */
    modification_time?: string /*timestamp?*/|null;
    /**
     * Ki módosította utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosító felhasználóra hivatkozik.
     */
    modification_user_id?: number|null;
    /**
     * Melyik session-ből módosították utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosításhoz használt munkamenetre hivatkozik.
     */
    modification_session_id?: number|null;
    /**
     * Aktív
     *
     * Logikai törléshez használt mező.
     */
    is_active?: boolean;
    /**
     * Név
     */
    name?: string;
    /**
     * Fejezet
     */
    chapter_id?: number;
    /**
     * URL rész
     *
     * URL path (útvonal) szegmense
     */
    uri_segment?: string;
    /**
     * Sorszám
     *
     * A lecke sorszáma, ahogy az a könyv lapok tetején megjelenik.
     */
    headno?: number /*int4*/|null;
    /**
     * Utolsó találat időpontja
     */
    search_popularity_last_hit_date?: string /*timestamp?*/|null;
    /**
     * Utolsó népszerűségi index
     */
    search_popularity_last_score?: number /* float8 */|null;
    /**
     * Azonosító NKP 1.0 rendszerben
     */
    nkp1_id?: number|null;
    /**
     * Létrehozó
     *
     * Aki a leckét létrehozta
     */
    creator?: string|null;
    /**
     * Módosító
     *
     * Aki a leckét módosította
     */
    modifier?: string|null;
    /**
     * Könyv azonosító
     *
     * A könyv azonosító amiben a fejezet van
     */
    book_id?: number|null;
    /**
     * Könyv név
     *
     * A könyv neve amiben a fejezet van
     */
    book_name?: string|null;
    /**
     * Teljes név
     *
     * Egysoros szöveges reprezentáció
     */
    displayvalue?: string|null;
    /**
     * Folyamat azonosító
     *
     * Folyamat azonosító
     */
    wf_workflow_id?: number|null;
    /**
     * Folyamat állapot azonosító
     *
     * Folyamat állapot azonosító
     */
    wf_station_id?: number|null;
    /**
     * Állapot
     *
     * Folyamat állapot neve
     */
    wf_station_name?: string|null;
    /**
     * Folyamat állapot leírása
     *
     * Folyamat állapot leírása
     */
    wf_station_description?: string|null;
    /**
     * Állapot stílus
     *
     * Folyamat állapot stílusa, jsonb/css
     */
    wf_station_style?: any|null;
    /**
     * Folyamat al-státusz azonosító
     *
     * Folyamat al-státusz azonosító
     */
    wf_substation_id?: number|null;
    /**
     * Folyamat al-státusz
     *
     * Folyamat al-státusz
     */
    wf_substation_name?: string|null;
    /**
     * Folyamat al-státusz leírása
     *
     * Folyamat al-státusz leírása
     */
    wf_substation_description?: string|null;
    /**
     * Folyamat szöveges állapot kiegészítés
     *
     * Folyamat szöveges állapot kiegészítés
     */
    wf_status_text?: string|null;
}

/**
 *  doc.view_lesson - Lecke
 *
 *  Idősoros táblázat, ami az összes tétel összes korábbi verzióját eltárolja.
 */

export default class ViewLesson extends View<IViewLessonRecord> {
    public static VIEW_INFO_ID = 2018082802;

    public static getSchemaNameForClass(): string { return 'doc'; }
    public static getViewNameForClass(): string { return 'view_lesson'; }
    public static getTableNameForClass(): string|null { return 'lesson'; }

    public static load: (id: number, aServer ?: Server) => Promise<ViewLesson>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<IViewLessonRecord[]>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class ViewLessonClassProxy extends ViewClassProxy<IViewLessonRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const viewLessonClassProxy = new ViewLessonClassProxy(ViewLesson);
registerViewClassProxy(viewLessonClassProxy);

