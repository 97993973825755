import { BubbleLoader } from 'react-css-loaders';
import * as React from 'react';
import { getContentDetails, ContentDetails, ContentType } from '@src/component/filemanager/ContentUtils';
import { Link } from 'react-router-dom';
import { formatDate } from '@src/Util';
import { __ } from '@src/translation';

/*

  Ez egy egyszerű wrapper ami tetszőleges table_info_id+rec_id -vel
  rendelkező listákat tud megjeleníteni.

*/


export interface IContentListProps {
    loading: boolean;
    items: IContentRowProps[];
}


export interface IContentRowProps {
    table_info_id: number;
    record_id: number;
    creation_time?: string | null;
    name?: string | null;
    description?: string | JSX.Element;
    url?: string;
    start_date : string;
    end_date ?: string|null;
    due_date ?: string|null;
    share_mode_title : string;
}

export class ContentList extends React.Component<IContentListProps, {}> {
    constructor(props: IContentListProps) {
        super(props);
        this.state = {};
    }

    render() {
        if (this.props.loading) {
            return <BubbleLoader />;
        }

        if (this.props.items.length==0) {
            return <div className="callout primary">{__("Ez a lista üres.")}</div>
        }

        return <div className="row expanded">
            <table>
                <thead>
                    <tr>
                        <td className="img"></td>
                        <td className="collapse"></td>
                        <td>
                            <small>
                                <div className="cell">
                                    <label>
                                    {__("Kezdete - vége")}
                                    </label>
                                </div>
                                <div className="cell">
                                    <label>
                                    {__("Határidő")}
                                    </label>
                                </div>
                                <div className="cell">
                                </div>
                            </small>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    {
                        this.props.items.map((item, index) => {
                            return <ContentRow key={index} {...item} />
                        })
                    }
                </tbody>
            </table>
        </div>
    }
}


export interface IContentRowState {
    details?: ContentDetails;
}
export class ContentRow extends React.Component<IContentRowProps, IContentRowState> {

    constructor(props: IContentRowProps) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.reloadAsync();
    }

    async reloadAsync() {
        const details = await getContentDetails(this.props.table_info_id, this.props.record_id);
        this.setState({details});
    }

    render() {
        const details = this.state.details;
        const dateTd = <td>
        <small className="date">
            <div className="cell">
                {/* <label>
                    Kezdete - vége
                </label> */}
                <span>                        
                    {formatDate(this.props.start_date)}                
                    {this.props.end_date?<> - {formatDate(this.props.end_date)}</>:null}
                </span>
            </div>
            
            <div className="cell">
            {this.props.due_date?
                <>
                {/* <label>
                    Határidő
                </label>                       */}
                <label className="warning">{formatDate(this.props.due_date)}</label>
                </>
                :null
            }                              
            </div>
             
            <div className="cell">
                <label className="label primary">{this.props.share_mode_title}</label>                                          
            </div> 
        </small>
        </td>;
        
        if (details) {
            var url = this.props.url;
            if (!url) {
                url = details.viewUrl;
            }
            return <tr>
                <td className="img">
                    <img className={[ContentType.FILE_IMAGE,ContentType.EXERCISE,ContentType.EXERCISE_SERIES,ContentType.EXERCISE_SERIES_ADAPTIVE,ContentType.OPEN_ENDED_EXERCISE].includes(details.type) ? "thumb" : "icon" } alt={details.type} title={details.type} src={details.thumbnailUrl} style={{ objectFit: "cover" }} />
                </td>
                <td className="collapse">
                    <Link to={url}>
                        {this.props.name || details.displayName}
                    </Link>
                    {
                        this.props.description ? 
                        <small className="desc">{this.props.description}</small> : null
                    }
                </td>
                {dateTd}
            </tr>
        } else {
            return <tr>
                <td></td>
                <td>{this.props.name}</td>
                {dateTd}
            </tr>
        }
    }
}

