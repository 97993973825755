import { Server } from '../../server/Server';
import Crud , { registerCrudClassProxy, IAsTextResult, IListParams, CrudClassProxy, ISearchTextParams, ISearchTextResult } from '../Crud';

export interface IOccupationDraftBlockEduWorkFormRecord {
    /**
     * Globálisan egyedi azonosító.
     * 
     * Ha új rekordot akarsz létrehozni put() metódussal, akkor hagyd üresen.
     * upsert() hívásnál figyelmen kívül van hagyva az értéke.
     *
     */
    id ?: number;
    /**
     * Rekord létrehozás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel létrehozásakor.
     */
    creation_time?: string /*timestamp?*/;
    /**
     * Ki hozta létre
     *
     * NOT NULL -> sys.sec_user ON DELETE noaction
     *
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozó felhasználóra hivatkozik.
     */
    creation_user_id?: number;
    /**
     * Melyik session-ből jött létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozáshoz használt munkamenetre hivatkozik.
     */
    creation_session_id?: number|null;
    /**
     * Utolsó módosítás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel módosításakor.
     */
    modification_time?: string /*timestamp?*/|null;
    /**
     * Ki módosította utoljára
     *
     * -> sys.sec_user ON DELETE noaction
     *
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosító felhasználóra hivatkozik.
     */
    modification_user_id?: number|null;
    /**
     * Melyik session-ből módosították utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosításhoz használt munkamenetre hivatkozik.
     */
    modification_session_id?: number|null;
    /**
     * Aktív
     *
     * Logikai törléshez használt mező.
     */
    is_active?: boolean;
    /**
     * KAP Foglalkozásterv blokk
     *
     * NOT NULL -> kap.occupation_draft_block ON DELETE cascade
     *
     */
    occupation_draft_block_id?: number;
    /**
     * Oktatási munka forma
     *
     * NOT NULL -> kap.edu_work_form ON DELETE noaction
     *
     */
    edu_work_form_id?: number;
}

/**
 *  kap.occupation_draft_block_edu_work_form - KAP foglalkozásterv blokk munka formája
 *
 *  Idősoros táblázat, ami az összes tétel összes korábbi verzióját eltárolja.
 */

export default class OccupationDraftBlockEduWorkFormCrud extends Crud<IOccupationDraftBlockEduWorkFormRecord> {
    public static TABLE_INFO_ID = 4024823770;
    public static getSchemaNameForClass() { return 'kap'; }
    public static getTableNameForClass() { return 'occupation_draft_block_edu_work_form'; }

    public static load: (id: number, aServer ?: Server) => Promise<OccupationDraftBlockEduWorkFormCrud>;
    public static asText: (id: number, includeRecord : boolean, aServer ?: Server) => Promise<IAsTextResult<IOccupationDraftBlockEduWorkFormRecord>>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<IOccupationDraftBlockEduWorkFormRecord[]>;
    public static searchText: (params: ISearchTextParams, aServer ?: Server) => Promise<ISearchTextResult<IOccupationDraftBlockEduWorkFormRecord>[]>;
    public static deleteById: (id: number, aServer ?: Server) => Promise<IOccupationDraftBlockEduWorkFormRecord>;
    public static unDeleteById: (id: number, aServer ?: Server) => Promise<IOccupationDraftBlockEduWorkFormRecord>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class OccupationDraftBlockEduWorkFormCrudClassProxy extends CrudClassProxy<IOccupationDraftBlockEduWorkFormRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const occupationDraftBlockEduWorkFormCrudClassProxy = new OccupationDraftBlockEduWorkFormCrudClassProxy(OccupationDraftBlockEduWorkFormCrud);
registerCrudClassProxy(occupationDraftBlockEduWorkFormCrudClassProxy);
