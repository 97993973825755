import * as React from 'react';
import * as moment from 'moment';

export type DateTimePickerChangeEvent = (date: string, time: string, changeType: number, event: React.FormEvent<HTMLInputElement>, id?: string) => void;
/*
changeType lehetséges értékei
    0 - nincs módosulás (nem használt, csak fenntartott, ha esetleg később szükségessé válna a callback függvényt a módosulástól függetlenül rendszeres időközönként hívni)
    1 - dátum módosult
    2 - időpont módosult
    3 - dátum és időpont módosult (nem használt, csak fantartott, ha esetleg később lenne valami menetközbeni betöltés, ahol mindkettő módosul)
*/
export enum DateTimeGeneralType {none=0, date=1, time=2, all=3};

type DateTimePickerProps = {
    className?: string;
    id?: string;
    label?: string;
    value?: string; // meg kell felelnie a dateFormat és a timeFormat értékének
    dateFormat?: string; //ha nincs megadva yyyy-mm-dd
    timeFormat?: string; //ha nincs megadva HH:MM:SS vagy HH:MM, attól függ van e másodperc
    seconds?: boolean;
    onChange: DateTimePickerChangeEvent;
    disabled?: DateTimeGeneralType;
    visibled?: DateTimeGeneralType;
    labelStyle?: React.CSSProperties;
    dateStyle?: React.CSSProperties;
    timeStyle?: React.CSSProperties;
    timeSpinButton?: boolean;
    timeClearButton?: boolean;
    dateSpinButton?: boolean;
    dateClearButton?: boolean;
    minDate?: string;
    maxDate?: string;
    minTime?: string;
    maxTime?: string;
}

type DateTimePickerState = {
    valueDate: string;
    valueTime: string;
    dateFormat: string;
    timeFormat: string;
    seconds: boolean;
    labelStyle: React.CSSProperties;
    dateStyle: React.CSSProperties;
    timeStyle: React.CSSProperties;
    timeSpinButton: boolean;
    timeClearButton: boolean;
    dateSpinButton: boolean;
    dateClearButton: boolean;
    minDate: string;
    maxDate: string;
    minTime: string;
    maxTime: string;
}

export class DateTimePicker extends React.Component<DateTimePickerProps, DateTimePickerState> {
    constructor(props: DateTimePickerProps) {
        super(props);
        const seconds: boolean = this.props.seconds!==undefined ? this.props.seconds : false;
        const dateFormat: string = this.props.dateFormat!==undefined ? this.props.dateFormat : "yyyy-mm-dd";
        const timeFormat: string = this.props.timeFormat!==undefined ? this.props.timeFormat : seconds ? "HH:mm:ss" : "HH:mm";
        var date: string = "", time: string = "";
        var minDate: string = this.props.minDate!==undefined ? this.props.minDate : "1000-01-01";
        var maxDate: string = this.props.maxDate!==undefined ? this.props.maxDate : "9999-12-31";
        var minTime: string = this.props.minTime!==undefined ? this.props.minTime : seconds ? "00.00.00" : "00:00";
        var maxTime: string = this.props.maxTime!==undefined ? this.props.maxTime : seconds ? "23:59:59" : "23:59";
        if (!moment(minDate, dateFormat).isValid()) minDate="1000-01-01";
        if (!moment(maxDate, dateFormat).isValid()) maxDate="9999-12-31";
        if (!moment(minTime, timeFormat).isValid()) minTime=seconds ? "00:00:00" : "00:00";
        if (!moment(maxTime, timeFormat).isValid()) maxTime=seconds ? "23:59:59" : "23:59";
        if (this.props.value!==undefined && this.props.value.trim().length>0){
            const i: number = this.props.value.indexOf(" ");
            if (i>-1){
                date=this.props.value.substring(0,i);
                time=this.props.value.substring(i+1);
            } else {
                if (this.props.value.indexOf(":")===-1) date=this.props.value;
                else time=this.props.value;
            }
            if (date.length>0) if (!moment(date, dateFormat).isValid()) date="";
            if (time.length>0) if (!moment(time, timeFormat).isValid()) time="";
        }
        this.state = {valueDate:date, valueTime:time,
            dateFormat: dateFormat,
            timeFormat: timeFormat,
            seconds: seconds,
            labelStyle: this.props.labelStyle!==undefined ? this.props.labelStyle : {},
            dateStyle: this.props.dateStyle!==undefined ? this.props.dateStyle : {},
            timeStyle: this.props.timeStyle!==undefined ? this.props.timeStyle : {},
            timeSpinButton: this.props.timeSpinButton!==undefined ? this.props.timeSpinButton : true,
            timeClearButton: this.props.timeClearButton!==undefined ? this.props.timeClearButton : true,
            dateSpinButton: this.props.dateSpinButton!==undefined ? this.props.dateSpinButton : true,
            dateClearButton: this.props.dateClearButton!==undefined ? this.props.dateClearButton : true,
            minDate: minDate, maxDate: maxDate, minTime: minTime, maxTime: maxTime   
        };
    }

    private onDateChange = (event: React.FormEvent<HTMLInputElement>) => {
        const dateValue = event.currentTarget.value;
        this.setState({valueDate:dateValue})
        this.props.onChange(dateValue, this.state.valueTime, 1, event, this.props.id);
    }

    private onTimeChange = (event: React.FormEvent<HTMLInputElement>) => {
        const timeValue = event.currentTarget.value;
        this.setState({valueTime:timeValue})
        this.props.onChange(this.state.valueDate, timeValue, 2, event, this.props.id);
    }

    render() {
        const disabled = this.props.disabled!==undefined ? this.props.disabled : DateTimeGeneralType.none;
        const visibled = this.props.visibled!==undefined ? this.props.visibled : DateTimeGeneralType.all;

        var addClassDate: string = disabled===DateTimeGeneralType.date || disabled===DateTimeGeneralType.all ? "DateDisabled_NKP " : ""
        var addClassTime: string = disabled===DateTimeGeneralType.time || disabled===DateTimeGeneralType.all ? "TimeDisabled_NKP " : "";
        if (!this.state.dateSpinButton) addClassDate+="DateNoSpin_NKP ";
        if (!this.state.dateClearButton) addClassDate+="DateNoClear_NKP ";
        if (!this.state.timeSpinButton) addClassTime+="TimeNoSpin_NKP ";
        if (!this.state.timeClearButton) addClassTime+="TimeNoClear_NKP ";
        return (
            <div className={"DateTime_NKP "+(this.props.className!==undefined ? "DateTime_"+this.props.className : "")} id={this.props.id} style={{marginBottom:"2px", marginTop:"3px", display:"flex"}}>
                {this.props.label ? <span className="DateTimeLabel_NKP" style={{...this.state.labelStyle}}>{this.props.label}</span> : null}
                {visibled===DateTimeGeneralType.date || visibled===DateTimeGeneralType.all ?
                    <input type="date" 
                        className={addClassDate+"Date_NKP "+(this.props.className!==undefined ? "Date_"+this.props.className : "")}
                        value={this.state.valueDate} min={this.state.minDate} max={this.state.maxDate}
                        onChange={this.onDateChange} style={{...this.state.dateStyle}}
                        disabled={disabled===DateTimeGeneralType.date || disabled===DateTimeGeneralType.all}
                    />
                : null }
                {visibled===DateTimeGeneralType.time || visibled===DateTimeGeneralType.all ?
                    <input type="time"
                    className={addClassTime+"Time_NKP "+(this.props.className!==undefined ? "Time_"+this.props.className : "")}
                        value={this.state.valueTime} min={this.state.minTime} max={this.state.maxTime}
                        onChange={this.onTimeChange} step={this.state.seconds ? 1 : 0} style={{...this.state.timeStyle}}
                        disabled={disabled===DateTimeGeneralType.time || disabled===DateTimeGeneralType.all}
                    />
                : null }
            </div>
        )
    }
}


