import { Server } from '../../server/Server';
import Crud , { registerCrudClassProxy, IAsTextResult, IListParams, CrudClassProxy, ISearchTextParams, ISearchTextResult } from '../Crud';

export interface ILangTranslationRecord {
    /**
     * Globálisan egyedi azonosító.
     * 
     * Ha új rekordot akarsz létrehozni put() metódussal, akkor hagyd üresen.
     * upsert() hívásnál figyelmen kívül van hagyva az értéke.
     *
     */
    id ?: number;
    /**
     * Nyelv, aminek a nevét lefordítjuk
     *
     * NOT NULL -> sys.lang ON DELETE cascade
     *
     */
    lang_id?: number;
    /**
     * Nyelv, amilyen nyelvre fordítjuk
     *
     * NOT NULL -> sys.lang ON DELETE cascade
     *
     */
    translated_to_id?: number;
    /**
     * Név (fordítás)
     */
    name?: string;
}

/**
 *  sys.lang_translation - Nyelv fordítás
 *
 *  A nyelvek nevei, különböző nyelvekre lefordítva.
 */

export default class LangTranslationCrud extends Crud<ILangTranslationRecord> {
    public static TABLE_INFO_ID = 1161545159;
    public static getSchemaNameForClass() { return 'sys'; }
    public static getTableNameForClass() { return 'lang_translation'; }

    public static load: (id: number, aServer ?: Server) => Promise<LangTranslationCrud>;
    public static asText: (id: number, includeRecord : boolean, aServer ?: Server) => Promise<IAsTextResult<ILangTranslationRecord>>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<ILangTranslationRecord[]>;
    public static searchText: (params: ISearchTextParams, aServer ?: Server) => Promise<ISearchTextResult<ILangTranslationRecord>[]>;
    public static deleteById: (id: number, aServer ?: Server) => Promise<ILangTranslationRecord>;
    public static unDeleteById: (id: number, aServer ?: Server) => Promise<ILangTranslationRecord>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class LangTranslationCrudClassProxy extends CrudClassProxy<ILangTranslationRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const langTranslationCrudClassProxy = new LangTranslationCrudClassProxy(LangTranslationCrud);
registerCrudClassProxy(langTranslationCrudClassProxy);
