import * as React from 'react';
declare var MathJax: any;
export class ToolTipDiv extends React.Component<{title: string, className: any}, {}> {

    private toolTipId: string | null;

    componentDidMount() {
        if (this.refs.tooltip) {
            setTimeout(() => {                
                this.tooltipInit();
            }, 500);

        }
    }

    tooltipInit()
    {
        ($(this.refs.tooltip) as any).foundation(); 
        this.toolTipId = (this.refs.tooltip as HTMLElement).getAttribute("data-toggle");
            
            try {
                document.getElementById(this.toolTipId!)!.classList.add('exercise-tip');
                document.getElementById(this.toolTipId!)!.setAttribute("aria-hidden", "true");
                
                //Matematikai képletek kiértékelése a találatokra
                 MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById(this.toolTipId!)]);
            } catch (e) {
                console.log(e);
            }
    }

    componentWillUnmount() {
        if (this.toolTipId) {
            const element = document.getElementById(this.toolTipId);
            if (element) {
                element.remove();
            }
        }
    }

    render() {
        return <div ref="tooltip" data-position="top" data-alignment="center"
                    className={this.props.className}
                    title={this.props.title} 
                    data-tooltip 
                    aria-haspopup="false" 
                    data-allow-html="true" 
                    style={{/*foundation has-tip felülírása*/border: "none"}}>
                    {this.props.children}
                </div>
            ;
    }
}
