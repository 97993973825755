import { ExerciseEngineHelper } from "../ExerciseEngineHelper";

export class WMAssignPointToQuestionServer{
    
    static evaluateOnServer(exercise: any, correctSolution: any, userSolution: any): Evaluated {

        let success = true;
        let correctAnswers = 0;
        let sol_index = userSolution.length - 1;
        let circle: any = {};
        let correctPointFound = false;
        let totalPins = 0;
        
        for (var k = 0; k < userSolution.length; k++) {
            if(!userSolution[k]) continue;
            let cur_solution = correctSolution.find((x: any) => {
                return x.answerindex == userSolution[k].answerindex
            });

            if (userSolution[sol_index].points.length != cur_solution.points.length && sol_index == k)
            success = false;

            var copySolutionPoints = cur_solution.points.slice();
            totalPins+=copySolutionPoints.length;
            
            for (var i = 0; i < userSolution[k].points.length; i++) {
                circle.centerX = userSolution[k].points[i][0];
                circle.centerY = userSolution[k].points[i][1];
                correctPointFound = false;

                for (var j = 0; j < copySolutionPoints.length; j++) {
                    //We need polygon evaluation
                    if (copySolutionPoints[j].points) {
                        let isInsidePoly = ExerciseEngineHelper.isPointInsidePolygon([circle.centerX, circle.centerY], copySolutionPoints[j].points)
                        if (isInsidePoly) {
                            correctPointFound = true;
                            correctAnswers++;
                            copySolutionPoints.splice(j, 1);
                        }
                    }
                    else {
                        //We need circle evaluate
                        let x: number = copySolutionPoints[j].x;
                        let y: number = copySolutionPoints[j].y;
                        let radius: number = copySolutionPoints[j].radius;
                        let dist: number = Math.sqrt((x - circle.centerX) * (x - circle.centerX) + (y - circle.centerY) * (y - circle.centerY));
                        if (dist <= radius) {
                            correctPointFound = true;
                            correctAnswers++;
                            copySolutionPoints.splice(j, 1);
                        }
                    }
                }

                if (!correctPointFound && sol_index == k) {
                    success = false;
                }
            }
        }
        let successPercent = Number((correctAnswers / totalPins).toFixed(2));
        return { "success": success, "solution": correctSolution, "successPercent": successPercent };
    }

    static filterExerciseOnServer(exercise: any, solution: any): any {
        if (!exercise.num_of_questions || exercise.num_of_questions < 1 || JSON.stringify(solution) === JSON.stringify({}) || solution.length == 0)
            return exercise;

        var delIdx: number[] = [];
        let elementsToRemove = solution.length - exercise.num_of_questions;

        while (delIdx.length != elementsToRemove) {
            let j = Math.floor(Math.random() * (exercise.answers.length));
            if (delIdx.find(x => x == j) == undefined)
                delIdx.push(j);
        }
        for (let index = 0; index < delIdx.length; index++) {
            exercise.answers[delIdx[index]].answerElement.type = "";
        }
        return exercise;
    }
}