import { Server } from '../../server/Server';
import View , { registerViewClassProxy, ViewClassProxy } from '../View';
import { IListParams } from '@framework/crud/Crud';

export interface IViewExerciseSectionRecord {
    /**
     * Feladat azonosító
     *
     * Feladat azonosító
     */
    id?: number;
    /**
     * Feladat név
     *
     * Feladat név
     */
    exercise_name?: string|null;
    /**
     * Szekció azonosító
     *
     * Szekció azonosító
     */
    section_id?: number|null;
    /**
     * Szekció név
     *
     * Szekció név
     */
    section_name?: string|null;
    /**
     * Lecke azonosító
     *
     * Lecke azonosító
     */
    lesson_id?: number|null;
    /**
     * Lecke név
     *
     * Lecke név
     */
    lesson_name?: string|null;
    /**
     * Lecke URI szegmens
     *
     * Lecke URI szegmens
     */
    lesson_uri_segment?: string|null;
    /**
     * Fejezet azonosító
     *
     * Fejezet azonosító
     */
    chapter_id?: number|null;
    /**
     * Fejezet név
     *
     * Fejezet név
     */
    chapter_name?: string|null;
    /**
     * Könyv azonosító
     *
     * Könyv azonosító
     */
    book_id?: number|null;
    /**
     * Könyv név
     *
     * Könyv név
     */
    book_name?: string|null;
    /**
     * Könyv URI szegmens
     *
     * Könyv URI szegmens
     */
    book_uri_segment?: string|null;
    /**
     * Könyvtár azonosító
     *
     * Könyvtár azonosító
     */
    library_id?: number|null;
    /**
     * Könyvtár név
     *
     * Könyvtár név
     */
    library_name?: string|null;
    /**
     * Könyvtár URI szegmens
     *
     * Könyvtár URI szegmens
     */
    library_uri_segment?: string|null;
}

/**
 *  exc_pub.view_exercise_section - Feladat-szekció összerendelés
 *
 *  Az egymással összerendelt feladatok és szekciók
 */

export default class ViewExerciseSection extends View<IViewExerciseSectionRecord> {
    public static VIEW_INFO_ID = 2019051501;

    public static getSchemaNameForClass(): string { return 'exc_pub'; }
    public static getViewNameForClass(): string { return 'view_exercise_section'; }
    public static getTableNameForClass(): string|null { return 'null'; }

    public static canCache(): boolean {
        return true;
    }

    public static load: (id: number, aServer ?: Server) => Promise<ViewExerciseSection>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<IViewExerciseSectionRecord[]>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class ViewExerciseSectionClassProxy extends ViewClassProxy<IViewExerciseSectionRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const viewExerciseSectionClassProxy = new ViewExerciseSectionClassProxy(ViewExerciseSection);
registerViewClassProxy(viewExerciseSectionClassProxy);

