import { Server } from '../../server/Server';
import Crud , { registerCrudClassProxy, IAsTextResult, IListParams, CrudClassProxy, ISearchTextParams, ISearchTextResult } from '../Crud';

export interface ICourseSchoolInvitationRecord {
    /**
     * Globálisan egyedi azonosító.
     * 
     * Ha új rekordot akarsz létrehozni put() metódussal, akkor hagyd üresen.
     * upsert() hívásnál figyelmen kívül van hagyva az értéke.
     *
     */
    id ?: number;
    /**
     * Rekord létrehozás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel létrehozásakor.
     */
    creation_time?: string /*timestamp?*/;
    /**
     * Ki hozta létre
     *
     * NOT NULL -> sys.sec_user ON DELETE noaction
     *
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozó felhasználóra hivatkozik.
     */
    creation_user_id?: number;
    /**
     * Melyik session-ből jött létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozáshoz használt munkamenetre hivatkozik.
     */
    creation_session_id?: number|null;
    /**
     * Utolsó módosítás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel módosításakor.
     */
    modification_time?: string /*timestamp?*/|null;
    /**
     * Ki módosította utoljára
     *
     * -> sys.sec_user ON DELETE noaction
     *
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosító felhasználóra hivatkozik.
     */
    modification_user_id?: number|null;
    /**
     * Melyik session-ből módosították utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosításhoz használt munkamenetre hivatkozik.
     */
    modification_session_id?: number|null;
    /**
     * Aktív
     *
     * Logikai törléshez használt mező.
     */
    is_active?: boolean;
    /**
     * Képzés
     *
     * NOT NULL -> kap.course ON DELETE noaction
     *
     */
    course_id?: number;
}

/**
 *  kap.course_school_invitation - Képzés meghívó
 *
 *  Idősoros táblázat, ami az összes tétel összes korábbi verzióját eltárolja.
 */

export default class CourseSchoolInvitationCrud extends Crud<ICourseSchoolInvitationRecord> {
    public static TABLE_INFO_ID = 4116064876;
    public static getSchemaNameForClass() { return 'kap'; }
    public static getTableNameForClass() { return 'course_school_invitation'; }

    public static load: (id: number, aServer ?: Server) => Promise<CourseSchoolInvitationCrud>;
    public static asText: (id: number, includeRecord : boolean, aServer ?: Server) => Promise<IAsTextResult<ICourseSchoolInvitationRecord>>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<ICourseSchoolInvitationRecord[]>;
    public static searchText: (params: ISearchTextParams, aServer ?: Server) => Promise<ISearchTextResult<ICourseSchoolInvitationRecord>[]>;
    public static deleteById: (id: number, aServer ?: Server) => Promise<ICourseSchoolInvitationRecord>;
    public static unDeleteById: (id: number, aServer ?: Server) => Promise<ICourseSchoolInvitationRecord>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class CourseSchoolInvitationCrudClassProxy extends CrudClassProxy<ICourseSchoolInvitationRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const courseSchoolInvitationCrudClassProxy = new CourseSchoolInvitationCrudClassProxy(CourseSchoolInvitationCrud);
registerCrudClassProxy(courseSchoolInvitationCrudClassProxy);
