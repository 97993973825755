import * as React from 'react';
import { CalendarSource } from '../../store/CalendarSource';
import { MONTH_LONG_NAMES } from './CalendarAPI';
import { observer } from 'mobx-react';
import { __ } from '@src/translation';

interface IConnectedCalendarNavigatorProps {
    calendarMonthSource: CalendarSource;
    showSelectedDay?: boolean;
}

@observer
export class CalendarNavigator extends React.PureComponent<IConnectedCalendarNavigatorProps> {


    private onYearSelected = async (e: React.ChangeEvent<HTMLSelectElement>) => {
        this.props.calendarMonthSource.selectYear(parseInt(e.target.value));
    }

    private onMonthSelected = async (e: React.ChangeEvent<HTMLSelectElement>) => {
        this.props.calendarMonthSource.selectMonth(parseInt(e.target.value) - 1);
    }

    public render() {
        const ms = this.props.calendarMonthSource;
        const years: number[] = [];
        let year = ms.year - 5;
        for (let y = year; y < ms.year + 5; y++) {
            years.push(y);
        }
        return <div className="calendar-navigator">
            <div className="calendar-nav">
                <button title={__("Előző")} className="button primary"
                    onClick={ms.goPrevMonth}>
                    <i className="fa fa-arrow-left"
                    />
                </button>

            </div>
            <div className="date-area">

            <label className="show-for-sr">{__("Év")}:</label>
            <select className="y-selector" value={ms.year.toString()}
                onChange={this.onYearSelected}
            >
                {years.map((year: number) =>
                    <option key={year.toString()} value={year.toString()}>{year}</option>
                )}
            </select>

            <label className="show-for-sr">{__("Hónap")}:</label>
            <select className="m-selector" value={(ms.month + 1).toString()}
                onChange={this.onMonthSelected}
            >

                {MONTH_LONG_NAMES.map((name: string, index: number) => {
                    return <option
                    key={(index + 1).toString()}
                    value={(index + 1).toString()}>{name}</option>
                })}
            </select>
            {
                this.props.showSelectedDay
                ?
                    <span className="selected-day">{(ms.selectedItem ? ms.selectedItem.date.getDate() : ms.today.getDate())}</span>
                : null
            }
            </div>

            <div className="calendar-nav">
                <button title={__("Következő")} className="button primary"
                    onClick={ms.goNextMonth}
                >
                    <i className="fa fa-arrow-right" />
                </button>
            </div>

        </div>;
    }
}
