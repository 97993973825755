import * as React from "react";
import { BubbleLoader } from 'react-css-loaders';
import { __ } from "@src/translation";
import { XAxis, YAxis, CartesianGrid, BarChart, Tooltip, Legend, Bar, ResponsiveContainer } from 'recharts';
import ViewLesson, { IViewLessonRecord } from "@src/framework/view/doc/ViewLesson";
import { IViewKapCourseRecord } from "@src/framework/view/kap/ViewKapCourse";
import { IViewKapTrainingTypeRecord } from "@src/framework/view/kap/ViewKapTrainingType";
import ViewKapTrainingType from "@src/framework/view/kap/ViewKapTrainingType";
import * as PublicServer from '@src/server/PublicServer';
import ViewKapCourseUser, { viewKapCourseUserClassProxy, IViewKapCourseUserRecord } from "@src/framework/view/kap/ViewKapCourseUser";
import { app } from "@src/index";
import LookupEdit from "@src/framework/forms/lookupedit";
import SecUserCrud from "@src/framework/crud/sys/SecUserCrud";
import { me } from "@src/framework/server/Auth";
import AccessDeniedPage from "@src/framework/pages/AccessDeniedPage";
import { DateToString, DTSMode } from "@src/component/dashboard/eventcalendar/EventCalendarAPI";
import { addToDate } from "@src/Util";
import CourseUserCrud from "@src/framework/crud/kap/CourseUserCrud";
import UserTracer from "@src/component/book/UserTracer";
import ReactTable, { Column } from "react-table";
import { IGetUserBooksExerciseSolutionsResult, IGetUserBooksExerciseSolutionsResultExerciseItem } from "@src/server/PublicServer";
import ViewExcExercise from "@src/framework/view/exc_pub/ViewExcExercise";
import ViewExerciseSection from "@src/framework/view/exc_pub/ViewExerciseSection";
import { CSVLink } from "react-csv";


type CourseStatsState = {
    loading: boolean;
    isBook:boolean;
    chartData: ChartDataValue[];
    studentChartData: ChartDataValue[];
    studentDataRefreshing: boolean;
    date_from?: string;
    date_to?: string;
    currentCourseUser?: IViewKapCourseUserRecord,
    selectedMemberId?: number | null,
    exeSolutionResults?: IGetUserBooksExerciseSolutionsResult;
    tableData?: TableData[];
}

type CourseStatsProps = {
    courseRec: IViewKapCourseRecord;
    isStudent: boolean;
}

type ChartDataValue = {
    name: string
    minutes: number,
    views: number
}

type TableData = {
    id: number,
    title: string,
    date?: string,
    succ_percent?: number,
    total_points?: number,
    earned_points?: number,
    url?: string,
    lesson_name?: string,
    chapter_name?: string,
}

export default class CourseStats extends React.Component<CourseStatsProps, CourseStatsState> {

    constructor(props: any) {
        super(props);

        this.state = {
            loading: false,
            chartData: [],
            studentChartData: [],
            studentDataRefreshing: false,
            selectedMemberId: this.props.isStudent ? me!.id : undefined,
            tableData: [],
            isBook:true
        }
    }

    private asyncReload = async (studentDataRefresh: boolean) => {
        if (!this.props.courseRec.training_type_id) return;
        let studentDataOnly = studentDataRefresh || this.props.isStudent;

        if (studentDataOnly) {
            this.setState({ studentDataRefreshing: true });
        } else {
            this.setState({ loading: true });
        }

        let chartDataMap: Map<number, ChartDataValue> = new Map();
        let ChartDataStudent: Map<number, ChartDataValue> = new Map();
        let chartDataArr: ChartDataValue[] = [];
        let chartStudentDataArr: ChartDataValue[] = [];

        try {
            const trainingType: IViewKapTrainingTypeRecord = (await ViewKapTrainingType.load(this.props.courseRec.training_type_id)).record;
            const bookId = trainingType.book_id;
            if(!bookId){
                this.setState({loading:false, isBook:false});
                return;
            } 
            const lessons: IViewLessonRecord[] = await ViewLesson.list({ filter: { is_active: true, book_id: bookId } });

            lessons.forEach(l => {
                chartDataMap.set(l.id!, { name: l.name!, views: 0, minutes: 0 });
                ChartDataStudent.set(l.id!, { name: l.name!, views: 0, minutes: 0 });
            });

            if (!studentDataOnly) {
                let courseUsers: IViewKapCourseUserRecord[] = (await ViewKapCourseUser.list({ filter: { course_id: this.props.courseRec.id, is_active: true } }));
                let userIds: number[] = courseUsers.map(item => item.sec_user_id!);
                const summaries: PublicServer.ILessonViewSummariesRecord[] = (await PublicServer.getLessonViewSummaries(userIds, [bookId!], undefined));
                for (let i = 0; i < userIds.length; i++) {
                    const userId: number = userIds[i];
                    const summary: PublicServer.ILessonViewSummariesRecord[] = summaries.filter(item => item._id.userId == userId)
                    for (let j = 0; j < summary.length; j++) {
                        const sum = summary[j];
                        let minutes = Math.round((sum.viewTime / 1000) / 60);
                        let entry = chartDataMap.get(sum._id.recordId);
                        //Update the current entry in the map (sum of views and minutes)
                        if (entry) {
                            entry.views += sum.viewCount;
                            entry.minutes += minutes;
                            chartDataMap.set(sum._id.recordId, entry);
                        }
                    }
                }
                chartDataArr = Array.from(chartDataMap.values());
                //Calculate avarage time of spent with the lesson ( total mins/total views)
                chartDataArr = chartDataArr.map(this.getAvgTime);
                this.setState({ chartData: chartDataArr, loading: false, studentDataRefreshing: false });



            }
            //Ha van kiválasztott diák, akkor csak az ő eredményeit kérjük le
            else if (this.state.selectedMemberId) {
                let userId = this.state.selectedMemberId;

                let exeSolutionResults: IGetUserBooksExerciseSolutionsResult | undefined;
                if (bookId) {
                    exeSolutionResults = await PublicServer.getUserBooksExerciseSolutions([bookId], userId);
                } else exeSolutionResults = undefined;

                let dataForTable: TableData[] = [];
                if (exeSolutionResults) {
                    exeSolutionResults.exercises.map(async (e: IGetUserBooksExerciseSolutionsResultExerciseItem) => {

                        let exe = await ViewExcExercise.list({ filter: { id: e.exercise_id } });
                        let exeSection = await ViewExerciseSection.list({ filter: { id: e.exercise_id, book_id: bookId } });
                        let title = exe[0].name!;
                        let url = exeSection ? "/tankonyv/" + exeSection[0].book_uri_segment + "/" + exeSection[0].lesson_uri_segment + "#section-" + exeSection[0].section_id : undefined;
                        let run = exeSolutionResults ? exeSolutionResults.runs[e.exercise_id] : undefined;
                        let exeData = run ? run[0].exercises[0] : undefined;
                        let date = exeData ? exeData.evaluationTimeStamp : "Nem oldotta meg a feladatot";
                        let succ_percent = exeData ? exeData.successPercent * 100 : 0;
                        let total_points = exeData ? exeData.totalPoints : 0;
                        let earned_points = exeData ? total_points * exeData.successPercent : 0;
                        let lesson_name = exeSection ? exeSection[0].lesson_name : undefined;
                        let chapter_name = exeSection ? exeSection[0].chapter_name : undefined;

                        dataForTable.push({
                            id: e.exercise_id,
                            title: title,
                            date: date,
                            succ_percent: succ_percent,
                            total_points: total_points,
                            earned_points: earned_points,
                            url: url,
                            lesson_name: lesson_name!,
                            chapter_name: chapter_name!
                        });
                    })
                }
                let pUsers: IViewKapCourseUserRecord[] = (await ViewKapCourseUser.list({ filter: { course_id: this.props.courseRec.id, sec_user_id: userId, is_active: true } }));
                if (pUsers.length > 0) {
                    let curr_member: IViewKapCourseUserRecord = pUsers[0];

                    let from = this.state.date_from ? new Date(this.state.date_from) : undefined;
                    if (from) from.setHours(0, 0, 0, 0);

                    let to = this.state.date_to ? new Date(this.state.date_to) : undefined;
                    if (to) to.setHours(23, 59, 59, 999);

                    const summary: PublicServer.ILessonViewSummariesRecord[] = (await PublicServer.getLessonViewSummaries([userId], [bookId!], undefined, from, to));

                    for (let j = 0; j < summary.length; j++) {
                        const sum = summary[j];
                        let minutes = Math.round((sum.viewTime / 1000) / 60);
                        let entry = ChartDataStudent.get(sum._id.recordId);
                        //Update the current entry in the map (sum of views and minutes)
                        if (entry) {
                            entry.minutes += minutes;
                            ChartDataStudent.set(sum._id.recordId, entry);
                        }
                    }
                    chartStudentDataArr = Array.from(ChartDataStudent.values());
                    this.setState({ tableData: dataForTable, studentChartData: chartStudentDataArr, currentCourseUser: curr_member, loading: false, studentDataRefreshing: false, exeSolutionResults });

                } else {
                    this.setState({ studentChartData: chartStudentDataArr, loading: false, studentDataRefreshing: false, exeSolutionResults })
                }

            }

        } catch (error) {
            app.showErrorFromJsonResult(error);
        }

    }

    getAvgTime(item: ChartDataValue) {
        return { name: item.name, views: item.views, minutes: Math.round(item.minutes / item.views) }
    }

    async userSelected(userId: number | null) {
        let currM: IViewKapCourseUserRecord | undefined = undefined;
        if (userId) {
            let pUsers: IViewKapCourseUserRecord[] = (await ViewKapCourseUser.list({ filter: { course_id: this.props.courseRec.id, sec_user_id: userId, is_active: true } }));
            if (pUsers.length > 0) {
                currM = pUsers[0];
            }
        }
        this.setState({ selectedMemberId: userId, currentCourseUser: currM }, () => this.asyncReload(true));
    }

    componentDidMount() {
        this.asyncReload(false);
    }

    render() {
        if (!me) {
            return AccessDeniedPage();
        }
        if (this.state.loading) {
            return <BubbleLoader />
        }
        if(!this.state.isBook){
            return <h4>{__("Csak az ebben a rendszer létrehozott tananyagokról van staisztika.")} </h4>
        }

        let columns: Column[] = [
            {
                Header: __("Lecke neve"), accessor: "lesson_name", filterable: false, sortable: true,
                Cell: (data: any, column: any) => {
                    return <div>{data.original.lesson_name}<br /><small>{data.original.chapter_name}</small></div>;

                }, style: { justifyContent: "left" }
            },
            {
                Header: __("Feladat címe"), accessor: "title", filterable: false, sortable: true,
                Cell: (data: any, column: any) => {
                    return <a target="_blank" href={data.original.url}>{data.original.title}</a>;

                }, style: { justifyContent: "left" }
            },
            {
                Header: __("Megoldás ideje"), accessor: "date", filterable: false, sortable: true,
                Cell: (data: any, column: any) => {
                    return data.original.date;
                }
                , style: { justifyContent: "left" }
            },
            {
                Header: __("Eredmény (%)"), accessor: "succ_percent", filterable: false, sortable: false,
                Cell: (data: any, column: any) => {
                    return data.original.succ_percent + " %";
                }
                , style: { justifyContent: "center" }
            },
            {
                Header: __("Össz pont"), accessor: "total_points", filterable: false, sortable: false,
                Cell: (data: any, column: any) => {
                    return data.original.total_points;
                }
                , style: { justifyContent: "center" }
            },
            {
                Header: __("Elért pont"), accessor: "earned_points", filterable: false, sortable: false,
                Cell: (data: any, column: any) => {
                    return data.original.earned_points;
                }
                , style: { justifyContent: "center" }
            },
        ];

        let csvExeHeaders = [
            {
                label: __("Feladat címe"),
                key: "title"
            },
            {
                label: __("Dátum"),
                key: "date"
            },
            {
                label: __("Eredmény (%)"),
                key: "succ_percent"
            },
            {
                label: __("Összes pont"),
                key: "total_points"
            },
            {
                label: __("Elért pont"),
                key: "earned_points"
            },
            {
                label: __("Link"),
                key: "url"
            },
            {
                label: __("Lecke neve"),
                key: "lesson_name"
            },
            {
                label: __("Fejezet neve"),
                key: "chapter_name"
            }
        ]

        let csvTimeStatHeaders = [
            {
                label: __("Lecke neve"),
                key: "name"
            },
            {
                label: __("Összes megtekintési idő (perc)"),
                key: "minutes"
            }
        ]


        return <>
            {!this.props.isStudent && <div>
                <div>
                    <h3>{__("Képzés megtekintési statisztika")}</h3>
                </div>
                <div>
                    {this.state.chartData.length > 0 ?
                        <ResponsiveContainer width="100%" height={400}>
                            <BarChart data={this.state.chartData}
                                margin={{ top: 10, right: 20, left: 20, bottom: 10 }}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="name" hide />
                                <YAxis yAxisId="left" orientation="left" stroke="#106291" />
                                <YAxis yAxisId="right" orientation="right" stroke="#16a7e0" />
                                <Tooltip />
                                <Legend />
                                <Bar unit={__("db")} name={__("Összes megtekintés száma")} yAxisId="left" dataKey="views" fill="#106291" />
                                <Bar unit={__("perc")} name={__("Átlagos megtekintési idő")} yAxisId="right" dataKey="minutes" fill="#16a7e0" />
                            </BarChart>
                        </ResponsiveContainer> : <h5>{__("Nem áll rendelkezésre adat!")}</h5>}

                </div>
            </div>
            }
            {!this.props.isStudent && <>
                <div>
                    <h3>{__("Résztvevő statisztikája")}</h3>
                </div>

                <div className="row expanded">
                    <div className="large-12 medium-12 small-12 columns">
                        <label>{__("Résztvevő") + ": "}
                            <LookupEdit
                                key={"member_name_selector"}
                                fk_table_info_id={SecUserCrud.TABLE_INFO_ID}
                                emptyLoad={true}
                                viewClassProxy={viewKapCourseUserClassProxy}
                                distinct={true}
                                searchColumnNames={["participant_fullname", "participant_email"]}
                                displayColumnNames={["participant_fullname", "participant_email"]}
                                orderByColumnNames={[{ name: "participant_fullname" }]}
                                valueColumn={"sec_user_id"}
                                filter={{
                                    is_active: true,
                                    course_id: this.props.courseRec.id,
                                }}
                                clearable={true}
                                value={this.state.selectedMemberId}
                                onChange={value => this.userSelected(value as number)}
                                placeholder={__("Kérem válasszon résztvevőt...")} />
                        </label>
                    </div>
                </div>
            </>
            }
            <div className="row expanded">
                <div className="large-6 medium-6 small-12 columns">
                    <label className="input-date-calendar-selector">{__("Szűrés kezdete")}
                        <input type="date" value={this.state.date_from ? this.state.date_from.substr(0, 10) : ""}
                            onChange={(e) => this.setState({ date_from: e.target.value }, () => this.asyncReload(true))}
                        />
                    </label>
                </div>
                <div className="large-6 medium-6 small-12 columns">
                    <label className="input-date-calendar-selector" >{__("Szűrés vége")}
                        <input type="date" value={this.state.date_to ? this.state.date_to.substr(0, 10) : ""}
                            onChange={(e) => this.setState({ date_to: e.target.value }, () => this.asyncReload(true))}
                        />
                    </label>
                </div>
            </div>
            <div>
                {this.state.studentChartData.length > 0 && this.state.selectedMemberId && this.state.currentCourseUser ?
                    <>
                        <ResponsiveContainer width="100%" height={400}>
                            <BarChart data={this.state.studentChartData}
                                margin={{ top: 10, right: 20, left: 20, bottom: 10 }}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="name" hide />
                                <YAxis yAxisId="left" orientation="left" stroke="#106291" />
                                <Tooltip />
                                <Legend />
                                <Bar unit={__("perc")} name={__("Összes megtekintési idő")} yAxisId="left" dataKey="minutes" fill="#16a7e0" />
                            </BarChart>
                        </ResponsiveContainer>
                        <div className="row expanded columns">
                            <CSVLink data={this.state.studentChartData}
                                filename={this.state.currentCourseUser.participant_fullname ? this.state.currentCourseUser.participant_fullname.toLowerCase().split(' ').join('_').split('.').join('_') + "_time_stat.csv" : "time_stat.csv"}
                                className="button"
                                target="_blank"
                                headers={csvTimeStatHeaders}
                                separator={";"}>
                                {__("CSV letöltés")}
                            </CSVLink>
                        </div>
                        {this.props.isStudent ?
                            <UserTracer
                                event={{
                                    tableInfoId: CourseUserCrud.TABLE_INFO_ID,
                                    recordId: this.state.currentCourseUser.id!,
                                    name: this.state.currentCourseUser.participant_fullname || "",
                                    data: {
                                        eventTarget: PublicServer.UserEventTargets.STUDENT,
                                        eventType: PublicServer.UserEventTypes.STUDENT_PROGRESS_CHECK,
                                        courseId: this.props.courseRec.id
                                    }
                                }}
                            />
                            : <UserTracer
                                event={{
                                    tableInfoId: CourseUserCrud.TABLE_INFO_ID,
                                    recordId: this.state.currentCourseUser!.id!,
                                    name: this.state.currentCourseUser.participant_fullname || "",
                                    data: {
                                        eventTarget: PublicServer.UserEventTargets.TEACHER,
                                        eventType: PublicServer.UserEventTypes.STUDENT_PROGRESS_CHECK,
                                        courseId: this.props.courseRec.id
                                    }
                                }}
                            />}
                    </> : <h5>{__("Nem áll rendelkezésre adat!")}</h5>}
            </div>

            {this.state.selectedMemberId && this.state.currentCourseUser && this.state.exeSolutionResults && this.state.tableData &&
                <div className="small-12 columns">
                    <ReactTable data={this.state.tableData}
                        columns={columns}
                        pageSize={10}
                        loading={this.state.loading}
                        className="-striped -highlight"
                        showPageSizeOptions={false} />
                    <CSVLink data={this.state.tableData}
                        filename={this.state.currentCourseUser.participant_fullname ? this.state.currentCourseUser.participant_fullname.toLowerCase().split(' ').join('_').split('.').join('_') + "_exe_stat.csv" : "exe_stat.csv"}
                        className="button"
                        target="_blank"
                        headers={csvExeHeaders}
                        separator={";"}>
                        {__("CSV letöltés")}
                    </CSVLink>
                </div>
            }

        </>
    }

}