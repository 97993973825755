import * as React from 'react';

import { IViewSecItemTypePermRecord, ViewSecItemTypePermClassProxy, viewSecItemTypePermClassProxy } from '@src/framework/view/kb/ViewSecItemTypePerm';
import ListView from '@framework/forms/listview';

import { app } from '@src/index';

import LookupEdit from '@framework/forms/lookupedit';
import SecPermOwnerCrud from '@src/framework/crud/sys/SecPermOwnerCrud';
import SecItemTypePermCrud from '@src/framework/crud/kb/SecItemTypePermCrud';
import { __ } from '@src/translation';
import './kb_sec_item_type_perm_listview.css';

class SecItemTypePermInner extends ListView<IViewSecItemTypePermRecord> {
    public getViewClassProxy(): ViewSecItemTypePermClassProxy { return viewSecItemTypePermClassProxy; }
    protected getFilterControls(): JSX.Element | null { return null; }


    private onSetFlag = async(recordId: number, fieldName: string, value: boolean) => {
        try {
            await new SecItemTypePermCrud({id:recordId, [fieldName]: value}).put();
            app.showInfo(__("Sikeres művelet"),__("A jogosultság beállítása sikeres volt."));
            this.asyncReload();
        } catch (error) {
            app.showErrorFromJsonResult(error);
        }

    }

    protected getToggleFlagButton(record: IViewSecItemTypePermRecord, fieldName: string, icon:string, description: string): JSX.Element  {
        const value: boolean = record[fieldName]!;
        let title = description;
        let cls = "kb-sec-station-perm-listview-button";
        let iconClass = "fa " + icon; 
        if (value) {
            title += ": " + __("A jog megvonásához kattintson az ikonra.");
            iconClass += "kb-sec-station-perm-listview-perm-active";
        } else {
            title += ": " + __("A jog megadásához kattintson az ikonra.");
            iconClass += "kb-sec-station-perm-listview-perm-inactive";
        }
        const rec_id = record.id!;
        return (
            <span
                className={cls} title={title}
                onClick={() => this.onSetFlag(rec_id, fieldName, !value) }
            ><i className={iconClass} />
            </span>);
    }



    protected getRecordButtons(record: IViewSecItemTypePermRecord): JSX.Element[] {
        return [
            this.getToggleFlagButton(record, "can_create",  "fa-plus-square", __("Új bejegyzés létrehozása")),
            this.getToggleFlagButton(record, "can_read",  "fa-tree", __("Bejegyzés olvasása")),
            this.getToggleFlagButton(record, "can_update",  "fa-edit", __("Bejegyzés módosítása")),
            this.getToggleFlagButton(record, "can_delete",  "fa-minus-square", __("Bejegyzés törlése")),

            this.getDeleteUndeleteButton(record)!
        ];
    }

    protected getHeader = (): JSX.Element | null => {
        if (this.state.struct) {
            const displayName = __("Bejegyzés típus függő jogosultságok kiosztása");
            return <div className="row expanded">            
                    <div id="listHeader" className="column small-12 large-12">
                        <h4>{displayName}</h4>
                    </div>
                </div>;
        } else {
            return null;
        }
    }

}

export interface ISecItemTypePermListViewForItemTypeProps {
    item_type_id: number;
}

export interface ISecItemTypePermListViewForItemTypeState {
    permOwnerIdToAdd: number | null;
    refreshKey: number
}

export class SecItemTypePermListViewForItemType extends React.Component<ISecItemTypePermListViewForItemTypeProps, ISecItemTypePermListViewForItemTypeState> {

    constructor(props: ISecItemTypePermListViewForItemTypeProps) {
        super(props);
        this.state = { permOwnerIdToAdd: null, refreshKey: 0 };
    }

    private onPermOwnerIdChange = (newValue: any) => {
        if ((typeof newValue === 'string'))
            if (newValue.trim() === '')
                newValue = '';
        if (newValue === '')
            newValue = null;
        let newState = {
            permOwnerIdToAdd: newValue
        };
        this.setState(newState);
    }

    private onAddPermOwner = () => {
        new SecItemTypePermCrud({
            item_type_id: this.props.item_type_id,
            sec_perm_owner_id: this.state.permOwnerIdToAdd!
        }).put()
            .then((crud) => {
                this.setState({
                    permOwnerIdToAdd: null, refreshKey: this.state.refreshKey + 1
                }, this.forceUpdate)
            })
            .catch((error) => { app.showErrorFromJsonResult(error) })
    }


    public render() {
        const value: any = this.state.permOwnerIdToAdd;
        return (
            <div>
                <div className="row">
                    <div className="column small-8 medium-8">
                        <LookupEdit
                            key={"permOwnerIdToAdd_" + (this.state.permOwnerIdToAdd || 'null')}
                            name="permOwnerIdToAdd"
                            fk_table_info_id={SecPermOwnerCrud.TABLE_INFO_ID}
                            clearable={true}
                            value={value}
                            onChange={(newValue) => this.onPermOwnerIdChange(newValue)}
                            placeholder={__("Jogosultság megadásához írjon be egy csoportot, szerepkört vagy felhasználót")}
                        />
                    </div>
                    <div className="column small-4 medium-4">
                        <button type="button" className="button primary"
                            disabled={!this.state.permOwnerIdToAdd}
                            onClick={this.onAddPermOwner}
                        >
                            <i className="fa fa-plus" />
                            {__("Hozzáad")}
                    </button>
                    </div>
                </div>
                <div className="row">
                    <div className="column small-12 medium-12">
                        <p className="callout warning">
                        {__("Figyelem! Alább minden kattintás azonnal menti a változtatásokat!")}
                        </p>
                        <SecItemTypePermInner
                            key={this.state.refreshKey}
                            allowInsertNew={false}
                            defaultPageSize={10}
                            filter={{ item_type_id: this.props.item_type_id }}
                            viewColumns={[
                                { accessor: "id", maxWidth: 100 },
                                { accessor: "perm_owner_label", maxWidth: 300 },                             
                                { accessor: "link", Header: __("Műveletek")},
                            ]} />
                    </div>
                </div>
            </div>);
    }

}

