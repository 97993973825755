import{ IPersonRecord, PersonCrudClassProxy, personCrudClassProxy } from '@src/framework/crud/sys/PersonCrud';
import RecordEditor, { IFieldEditorProp } from '@framework/forms/recordeditor';
import { ITableInfo } from '@src/framework/crud/Meta';

export default class PersonEditor extends RecordEditor<IPersonRecord> {

    public getCrudClassProxy() : PersonCrudClassProxy { return personCrudClassProxy;  }

    protected getNewRecordDefaults(): IPersonRecord {
        const result = super.getNewRecordDefaults();
        result.status_id = 1; // active
        return result;
    }

    protected getFieldEditor(fProps: IFieldEditorProp): JSX.Element | null {
        if (fProps.fieldName==="birthday") {
            return null;
        } else {
            return super.getFieldEditor(fProps);
        }
    }

}