import * as React from 'react';
import { app } from '@src/index';
import OoFileCrud, { IOoFileRecord } from '@src/framework/crud/media_pub/OoFileCrud';
import OoFolderCrud from '@src/framework/crud/media_pub/OoFolderCrud';
import { StringSearchKind } from '@src/framework/crud/Crud';
import obtainServer from '@src/framework/server/Server';
import ReactTable from 'react-table';
import { PATH_PUBLISHED_MEDIA, PATH_PUBLISHED_MEDIA_VIEW, PATH_CONTENT_STORE } from '@src/Routes';
import { objectOf } from 'prop-types';

type MediaListPageState = {
    files: {
        id: number,
        relpath: string,
        title: string,
        displayname: string,
        ext: string,
    }[];
}

export class MediaListPage extends React.Component<any, MediaListPageState> {

    constructor(props: any) {
        super(props);

        this.state = {
            files: []
        }
    }

    componentDidMount() {
        this.reloadAsync();
    }

    private async reloadAsync() {
        try {
            const response = await obtainServer().get<any>("media/list");

            this.setState({
                files: response.files
            });

        } catch(e) {
            app.showErrorFromJsonResult(e);
        }
    }

    render() {
        const exts = {};
        for(const file of this.state.files) {
            if (!exts[file.ext]) exts[file.ext] = 0;
            exts[file.ext]++;
        }

        if (this.state.files.length === 0) {
            return <div className="row" style={{maxWidth: "90%"}}>
                <div className="column small-12">
                    Betöltés...
                </div>
            </div>;
        }

        return <div className="row" style={{maxWidth: "90%"}}>
            <div className="column small-12">
                {
                    Object.entries(exts).map(e => e[0].substr(1) + ": " + e[1] + " db").join(", ")
                }
                , <strong>Összesen: {this.state.files.length}</strong>
                <ReactTable 
                    columns={[
                        {Header: "Filenév (megtekintés)", accessor: "title", width: 200, Cell: row => <a target="_new" href={PATH_PUBLISHED_MEDIA_VIEW + "/" + row.original.id}>{row.original.title}</a>},
                        {Header: "Név (displayname)", accessor: "displayname", width: 200},
                        {Header: "Típus", accessor: "ext", width: 100},
                        {Header: "Elérési út", accessor: "relpath", Cell: row => <a target="_new" href={PATH_CONTENT_STORE + "/kozos/" + row.original.oo_folder_id}>{row.original.relpath}</a>},
                    ]}
                    filterable
                    data={this.state.files}
                    defaultPageSize={10}
                    defaultFilterMethod={(filter, row, column) => row[filter.id] && row[filter.id].toLowerCase().indexOf(filter.value.toLowerCase()) != -1}
                />
            </div>
        </div>;
    }

}
