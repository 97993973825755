
import './style.css';
import { ExerciseEngineHelper } from '@src/component/exercise/engine/ExerciseEngineHelper';
import { ExerciseBaseClass } from '../../models/ExerciseBaseClass';
import { AExerciseEngine } from '../../models/AExerciseEngine';
import { WMItemToSetServer } from './WMItemToSetServer';
import { any } from 'prop-types';
import { __ } from '@src/translation';

export interface WMitemToSetData extends ExerciseBaseClass {
    title: string;
    sets: string[];
    setbackgrounds: string[];
    answers: string[];
    options: any[];
    ordered: boolean;
    set_bg_contain: boolean;
}

type WMD2ASolution = {
    key: number;
    value: number[];
}

type WMDndToAreaSolution = {
    fullmatch: boolean;
    answer: WMD2ASolution[];
}

export class WMItemToSetDnDExerciseEngine extends AExerciseEngine {

    private divList: HTMLElement[] = [];
    private answerDivBase: string = '';
    private dropDivList: HTMLDivElement[] = [];
    private ordered: boolean = false;
    private interaction: boolean = false;


    initExercise(params: ExerciseParams): void {
        super.initExercise(params);
        let exercise: WMitemToSetData = this.exercise;
        if (!this.exercise) return;
        this.ordered = exercise.ordered;
        this.divList = [];
        this.dropDivList = [];
        this.changeInteraction.bind(this, false);

        this.root.classList.add("wm-dnd-item-to-area");

        let gridDiv = this.root.appendChild(document.createElement("div"));
        //gridDiv.classList.add("row");
        gridDiv.classList.add("exercise-wrapper");

        this.setIllustration(exercise, gridDiv);

        let ariaLive = gridDiv.appendChild(document.createElement("div"));
        ariaLive.classList.add("aria-live");
        //ariaLive.setAttribute('aria-live','assertive');
        ariaLive.setAttribute('aria-live', 'polite');
        ariaLive.setAttribute('role', 'log');

        /* TGY: Mobilnezetben scrollozas */
        /* Kontenerek */
        let areaWrap = gridDiv.appendChild(document.createElement("div"));
        areaWrap.classList.add("area-wrap");

        let areaControls = areaWrap.appendChild(document.createElement("div"));
        areaControls.classList.add("area-controls");

        let answerWrap = gridDiv.appendChild(document.createElement("div"));
        answerWrap.classList.add("answer-wrap");

        let answerControls = answerWrap.appendChild(document.createElement("div"));
        answerControls.classList.add("answer-controls");

        let imageContainer = areaWrap.appendChild(document.createElement("div"));
        /* TGY */

        imageContainer.classList.add("align-middle");
        imageContainer.classList.add("area-container");

        if (!exercise.sets)
            return;

        for (let index = 0; index < exercise.sets.length; index++) {
            let dropdiv = imageContainer.appendChild(document.createElement("div"));
            dropdiv.classList.add("drop-div-box");
            dropdiv.classList.add("small-12");
            dropdiv.setAttribute("id", "drop-div-" + index.toString());

            let text_label = dropdiv.appendChild(document.createElement('div'));
            text_label.innerText = exercise.sets[index];
            text_label.classList.add('box-title');
            text_label.setAttribute('aria-hidden', 'true');

            let dropContainer = dropdiv.appendChild(document.createElement("div"));
            dropContainer.classList.add("drop-div");
            dropContainer.setAttribute("id", "solution-" + index.toString());
            dropContainer.tabIndex = 0;
            let capitalizedTitle = AExerciseEngine.stringCapitalize(exercise.sets[index]);

            /* This is for aria popup menu list */
            dropContainer.setAttribute('data-title', capitalizedTitle);
            dropContainer.setAttribute('aria-dropeffect', 'move');
            dropContainer.setAttribute('aria-role', 'list');

            let aria_label = dropContainer.appendChild(document.createElement('h4'));
            aria_label.innerText = __('{title} ejtési terület és elemei', {title: capitalizedTitle});
            aria_label.classList.add('aria-label');

            if (exercise.setbackgrounds && exercise.setbackgrounds[index] && exercise.setbackgrounds[index] !== '') {
                // let image = dropContainer.appendChild(document.createElement("img"));
                // image.classList.add('image');
                // image.setAttribute('src', exercise.imagebasepath + "" + exercise.sets[index]['img']);

                dropContainer.style.backgroundImage = "url(" + exercise.imagebasepath + "" + exercise.setbackgrounds[index] + ")";
                //dropContainer.style.backgroundSize = "contain";
                exercise.set_bg_contain ? dropContainer.style.backgroundSize = "contain" : dropContainer.style.backgroundSize = "cover";
            }
            this.dropDivList.push(dropContainer);
        }

        let answerContainer = answerWrap.appendChild(document.createElement("div"));
        /*  - TGY */
        answerContainer.classList.add("answer-container");
        // todo: give more specific id
        answerContainer.setAttribute("id", "answer-container");
        answerContainer.setAttribute('aria-role', 'list');
        answerContainer.tabIndex = 0;
        answerContainer.setAttribute('data-alabel', __('Válaszok ejtési terület és elemei'));

        // we put the answer container in the list,
        // so that the elements can be dragged back to their original  position
        this.dropDivList.push(answerContainer);

        if (!exercise.options) return;

        this.answerDivBase = "answer-div-";
        let answerList = exercise.ordered_answers ? exercise.options.slice() : AExerciseEngine.shuffle(exercise.options.slice());

        for (let ndxAnswer = 0; ndxAnswer < answerList.length; ndxAnswer++) {

            if (answerList[ndxAnswer].text == "" && answerList[ndxAnswer].image == "") continue;
            let elementClassList: string[] = [];
            // erre a szétválasztásra azért van szükség, hogy a kép válaszlehetőségeket automatikusan nagyíthatóvá tegyük  
            let answerdiv: HTMLElement = answerContainer.appendChild(document.createElement("div"));
            if (answerList[ndxAnswer].type == "image") {
                answerdiv.setAttribute('data-type','image');
                elementClassList = ["img-answer-div"];
                if (params.simple_style) answerdiv.classList.add("answer-div-simple");
            }
            else if (answerList[ndxAnswer].type == "sound") {
                answerdiv.setAttribute('data-type','sound');
                answerdiv.classList.add("string-answer-div", "answer-div");
            }
            else {
                answerdiv.setAttribute('data-type','text');
                if (answerList[ndxAnswer].text[0] == "$")
                    answerdiv.classList.add("latex-add");
            }

            AExerciseEngine.displayAnswer(answerdiv, answerList[ndxAnswer], this.is_accessible, elementClassList, this);

            answerdiv.classList.add("answer-div");
            answerdiv.setAttribute("draggable", "true");
            answerdiv.setAttribute("id", this.answerDivBase + String(exercise.options.indexOf(answerList[ndxAnswer])));
            answerdiv.setAttribute("data-originalindex", String(exercise.options.indexOf(answerList[ndxAnswer])));
            answerdiv.setAttribute("data-index", ndxAnswer + "");
            answerdiv.setAttribute('aria-grabbed', 'false');
            answerdiv.setAttribute('aria-role', 'button');
            answerdiv.setAttribute('aria-haspopup', 'true');
            answerdiv.setAttribute('aria-dropeffect', 'move');
            answerdiv.tabIndex = 0;

            /* Create draggable elements */
            if (!this.isReplay) {
                ($(answerdiv) as any).draggable({
                    start: this.dragstart.bind(this),
                    stop: this.drop.bind(this),
                    containment: this.root,
                    scroll: true,
                    helper: "clone"
                });
            }

            answerdiv.setAttribute("data-answers", String(exercise.options.indexOf(answerList[ndxAnswer])));
            if (answerList[ndxAnswer].type == "text") AExerciseEngine.shrinkAndGrow(answerdiv);
            this.divList.push(answerdiv);
            // this.answerList.push(exercise.answers[index]);   ???!!!
        }

        /* Click-to-click simulation */
        if (!this.isReplay) {
            AExerciseEngine.simulateDrag({
                draggableItems: this.divList,
                clickArea: this.root,
                dropAreas: this.dropDivList,
                excludedItemClasses: ['fa-play', 'fa-stop', 'exe-zoom-icon']
            });
        }

        /* TGY: Scrollozo */
        /* Gombok */
        let areaLeftButton = areaControls.appendChild(document.createElement("button"));
        areaLeftButton.classList.add("control", "area-control-left");

        let areaRightButton = areaControls.appendChild(document.createElement("button"));
        areaRightButton.classList.add("control", "area-control-right");

        let answerLeftButton = answerControls.appendChild(document.createElement("button"));
        answerLeftButton.classList.add("control", "answer-control-left");

        let answerRightButton = answerControls.appendChild(document.createElement("button"));
        answerRightButton.classList.add("control", "answer-control-right");

        /* Gomb esemenyek */
        areaRightButton.addEventListener("click", AExerciseEngine.xMove.bind(this, imageContainer, 'right'), false);

        areaLeftButton.addEventListener("click", AExerciseEngine.xMove.bind(this, imageContainer, 'left'), false);

        answerRightButton.addEventListener("click", AExerciseEngine.xMove.bind(this, answerContainer, 'right'), false);

        answerLeftButton.addEventListener("click", AExerciseEngine.xMove.bind(this, answerContainer, 'left'), false);
    }


    getUserSolution(): WMDndToAreaSolution {
        let result: any[] = [];
        for (let i = 0; i < this.dropDivList.length - 1; i++) {
            result[i] = [];
            if (this.dropDivList[i].children.length > 0) {
                for (let j = 0; j < this.dropDivList[i].children.length; j++) {
                    /* Check if not only an Aria label element*/
                    if (!this.dropDivList[i].children[j].classList.contains('aria-label')) {
                        if (!this.dropDivList[i].children[j].classList.contains('image')) {
                            let value: number = parseInt(String(this.dropDivList[i].children[j].getAttribute("data-answers")));
                            result[i].push(value);
                        }
                    }
                }
            }
            if (!this.ordered)
                result[i] = result[i].sort(this.compareNumbers);
        }
        return { answer: result, fullmatch: true };
    }

    receiveEvaluation(evaluated: Evaluated): void {
        let answCont:HTMLDivElement | null = this.root.querySelector(".answer-container");
        if (evaluated.success) {
            for (let index = 0; index < this.divList.length; index++) {
                let item: any = this.root.querySelector("#" + this.divList[index].id);
                AExerciseEngine.removeEvalStyle(item!);
                this.divList[index].classList.add("exe-engine-check-correct");
            }
        }
        if (!evaluated.success) {
            if (evaluated.solution != null) {
                let userSolution: any[] = this.getUserSolution().answer;
                let correctSolution = evaluated.solution;

                for (let i = 0; i < correctSolution.length; i++) {
                    let solutionArray = [];
                    for (let index = 0; index < correctSolution[i].length; index++) {       //push to solutionArray the string according to correctSolution array
                        let currCorrectElem = this.exercise.options[correctSolution[i][index]];
                        if (currCorrectElem) {
                            if (currCorrectElem.type == "text") {
                                solutionArray.push(currCorrectElem.text);
                            }
                            else if (currCorrectElem.type == "image") {
                                solutionArray.push(currCorrectElem.image);
                            }
                            else {
                                solutionArray.push(currCorrectElem.url);
                            }
                        }
                    }

                    for (let j = 0; j < userSolution[i].length; j++) {
                        let answer:HTMLElement | null = this.root.querySelector("#answer-div-" + userSolution[i][j]);
                        let currUserElem = this.exercise.options[userSolution[i][j]];
                        //let $answer: any = answer ? $(answer) : $("");
                        if(!answer) continue;
                        AExerciseEngine.removeEvalStyle(answer);
                        if (currUserElem.type == "text") {
                            if (solutionArray.indexOf(currUserElem.text) > -1) {     //check wether user user's guess in the soutionArray:string[]
                                answer.classList.add("exe-engine-check-correct");
                            }
                            else {
                                if (!this.isSNIexc)
                                    answer.classList.add("exe-engine-check-wrong");
                                if (!this.isReplay && !this.ordered)
                                    //this.moveItemToStart($answer);
                                    AExerciseEngine.moveElementBack(answer, answCont!, this.root);
                            }
                            if (solutionArray.indexOf(currUserElem.text) != -1) //remove checked elem from array
                                solutionArray.splice(solutionArray.indexOf(currUserElem.text), 1);
                        }
                        else if (currUserElem.type == "image") {
                            if (solutionArray.indexOf(currUserElem.image) > -1) {     //check wether user user's guess in the soutionArray:string[]
                            answer.classList.add("exe-engine-check-correct");
                            }
                            else {
                                if (!this.isSNIexc)
                                answer.classList.add("exe-engine-check-wrong");
                                if (!this.isReplay && !this.ordered)
                                    //this.moveItemToStart($answer);
                                    AExerciseEngine.moveElementBack(answer, answCont!, this.root);
                            }
                            if (solutionArray.indexOf(currUserElem.image) != -1) //remove checked elem from array
                                solutionArray.splice(solutionArray.indexOf(currUserElem.image), 1);
                        }
                        else {
                            if (solutionArray.indexOf(currUserElem.url) > -1) {     //check wether user user's guess in the soutionArray:string[]
                            answer.classList.add("exe-engine-check-correct");
                            }
                            else {
                                if (!this.isSNIexc)
                                    answer.classList.add("exe-engine-check-wrong");
                                if (!this.isReplay && !this.ordered)
                                    //this.moveItemToStart($answer);
                                    AExerciseEngine.moveElementBack(answer, answCont!, this.root);
                            }
                            if (solutionArray.indexOf(currUserElem.url) != -1) //remove checked elem from array
                                solutionArray.splice(solutionArray.indexOf(currUserElem.url), 1);
                        }
                    }
                }

            }
        }
    }

    showCorrectSolution(solution: any): void {
        this.removePlaceHolders(this.root);
        this.changeInteraction.bind(this, false);
        //var answer_list: String[] = [];
        let movedElements: HTMLElement[] = this.divList.slice();
        //let elements_already_in:string[] =[];
        for (let index = 0; index < solution.length; index++) {
            let dropContainer = this.root.querySelector("#" + this.dropDivList[index].id);
            if (!dropContainer) continue;
            //elements_already_in = [];

            for (let j = 0; j < solution[index].length; j++) {
                let element_to_add = movedElements.find(function (element: any) {
                    return Number(element.getAttribute("data-originalindex")) == solution[index][j];
                });
                if (element_to_add) {
                    dropContainer.appendChild(element_to_add);
                    AExerciseEngine.removeEvalStyle(element_to_add);
                    element_to_add.classList.remove("dropped");
                    element_to_add.classList.add("eke-engine-show-correct-bg");
                    let elementIdx = movedElements.indexOf(element_to_add);
                    movedElements.splice(elementIdx, 1);
                }
            }

            //coloring the odd elements
            let startContainer = this.dropDivList[this.dropDivList.length - 1];
            let oddElements: any = startContainer.querySelectorAll(".answer-div");
            if (oddElements && oddElements.length > 0) {
                for (let j = 0; j < oddElements.length; j++) {
                    AExerciseEngine.removeEvalStyle(oddElements[j]);
                    oddElements[j].classList.add("eke-engine-show-correct-bg");
                }
            }
        }
    }

    isUserReady(): boolean {
        return this.interaction
    }

    public changeInteraction(bool: boolean) {
        this.interaction = bool;
    }


    /* Scrollozo fuggveny */
    xMove(div_container: HTMLDivElement, move_direction: string) {

        let container = div_container!;
        let direction = move_direction;
        let childs = container.childElementCount;
        let iwidth = container.scrollWidth / childs / 2;
        let styleLeft = container.style.left;
        if (styleLeft == '') {
            styleLeft = '0px';
        }
        let maxLeft = (container.parentNode! as any).offsetWidth - container.scrollWidth;
        if (maxLeft > 0) {
            maxLeft = 0;
        }
        let mathLeft = parseInt(styleLeft!, 10);

        switch (direction) {
            case 'left':
                mathLeft = mathLeft + iwidth;
                if (mathLeft > 0) {
                    mathLeft = 0;
                }
                break;
            case 'right':
                mathLeft = mathLeft - iwidth;
                if (mathLeft < maxLeft) {
                    mathLeft = maxLeft;
                }
                break;
            default:
                break;
        }
        container.style.left = mathLeft + 'px';
    }


    drop(ev: any) {
        /* A lot of older positioning line was remove due to clone method */
        let moveElementBack = false;
        let overlap = AExerciseEngine.hitTest(ev, this.dropDivList);

        let answCont: HTMLDivElement | null = this.root.querySelector(".answer-container");
        //let alreadyInSet = this.elementAlreadyInSet(set[element], overlap.id);

        if (overlap.overlap && overlap.id != null) {
            let dropdiv = this.dropDivList[overlap.id];
            dropdiv.appendChild(ev.target);
            if (ev.target.parentElement.getAttribute('id') == 'answer-container') {
                ev.target.classList.remove('dropped');
                AExerciseEngine.moveElementBack(ev.target, answCont!, this.root);
                moveElementBack = true;
            } else {
                ev.target.classList.add('dropped');
            }
            this.alignIntoContainer((ev.target as HTMLDivElement), dropdiv);
            this.interaction = true;
        }
        else if (ev.target.parentElement.getAttribute('id') != 'answer-container') {
            //trgt.appendTo($(this.dropDivList.find(x => x.id.replace(/solution-/gi, "") == trgt.parent().attr('id')!.replace(/drop-div-/gi, "")) as any))
            //because of dragging we lost the parent element of trgt, It is deleted from drop-div-list. 
            //Now we compare drop-div-box and drop-div ids, because they numbered the same, and adding target to dropdivlist again

            /* if not starting container, neither drop area, put back to starting container */
            let evTarget = ev.target;
            if (answCont && evTarget.classList.contains('dropped')) {
                AExerciseEngine.removeEvalStyle(evTarget);
                evTarget.classList.remove('dropped');
                evTarget.classList.remove('ui-draggable-dragging');
                //answCont.appendChild(evTarget);
                AExerciseEngine.moveElementBack(evTarget, answCont, this.root);
                moveElementBack = true;
            }
        }
        else {
            AExerciseEngine.moveElementBack(ev.target, answCont!, this.root);
            moveElementBack = true;
        }

        answCont!.childNodes.forEach((element: HTMLElement) => {
            element.classList.remove("item-hidden");
        });

        let answerList = this.exercise.options.slice();//AExerciseEngine.shuffle(this.exercise.options.slice());
        let answerListIndex = Number(ev.target.getAttribute('data-originalindex'));
        if (answerList[answerListIndex].type == 'text') {
            AExerciseEngine.shrinkAndGrow(ev.target);
        }
        ev.target.classList.remove('draggable-original');
        if (this.isSNIexc && !moveElementBack) this.SNIEvaluation(WMItemToSetServer);
    }

    alignIntoContainer(mit: HTMLDivElement, mibe: HTMLDivElement) {
        let outerElementBoundaries: any = mibe.getBoundingClientRect();
        let innerElementBoundaries: any = mit.getBoundingClientRect();

        if (!mit.style || !mit.style.left || !mit.style.top) return;

        let offsetX: number = parseInt(mit.style.left);
        let offsetY: number = parseInt(mit.style.top);

        if (innerElementBoundaries.top <= outerElementBoundaries.top) {
            offsetY -= innerElementBoundaries.top - outerElementBoundaries.top;
        }
        else if (innerElementBoundaries.bottom >= outerElementBoundaries.bottom) {
            offsetY -= innerElementBoundaries.bottom - outerElementBoundaries.bottom;
        }
        if (outerElementBoundaries.left > innerElementBoundaries.left) {
            offsetX -= innerElementBoundaries.left - outerElementBoundaries.left;
        }
        else if (outerElementBoundaries.right < innerElementBoundaries.right) {
            offsetX += outerElementBoundaries.right - innerElementBoundaries.right;
        }

        mit.style.left = String(offsetX);
        mit.style.top = String(offsetY);
    }

    showHelp(solution: any): void {
        this.showCorrectSolution(solution);
    }

    compareNumbers(a: number, b: number): number {
        return a - b;
    }


    moveItemToStart(mit: JQuery<HTMLElement>): void {

        let dropContainer = this.dropDivList[this.dropDivList.length - 1];
        let $dropContainer = dropContainer ? $(dropContainer) : "";

        mit.closest(".answer-div").appendTo($dropContainer);
        mit.css("top", 0);
        mit.css("left", 0);
        mit.css("position", "relative");
        mit.removeClass('dropped');
    }

    dragstart(ev: any) {

        AExerciseEngine.removeEvalStyle(ev.target);
        if (ev.target.offsetParent.classList.contains('drop-div')) {
            $(ev.target).appendTo($(ev.target.offsetParent.offsetParent));
        }
        let parentDiv: HTMLElement = (ev.target.parentElement as HTMLElement);
        if (parentDiv.classList.contains("answer-container")) {
            if (this.simple_style || this.isSNIexc) {
                this.cloneAnswerElement(ev.target, parentDiv);
            }
        }
        let clientX = ev.clientX;
        let clientY = ev.clientY;
        ev.target.classList.add('draggable-original');
        let dimensions = ev.target.getBoundingClientRect();
    }

    elementAlreadyInSet(element: any, set_number: any): boolean {
        let currSet: any[] = this.getUserSolution().answer;
        let answerSet: any = [];

        if (set_number >= currSet.length) {
            return false;
        }
        if (typeof currSet[set_number] === 'undefined') {
            return false;
        }

        for (let j = 0; j < currSet[set_number].length; j++) {
            answerSet.push(this.exercise.options[currSet[set_number][j]])
        }

        let alreadyInSet = answerSet.find(function (elem: any) {
            if (JSON.stringify(elem) == JSON.stringify(element)) {
                return true;
            }
            else {
                return false;
            }
        });

        return alreadyInSet;
    }
}


