import * as React from "react";
import LpLearningPathCrud, { ILpLearningPathRecord } from "@src/framework/crud/exc/LpLearningPathCrud";
import { me } from "@src/framework/server/Auth";

import { BubbleLoader } from 'react-css-loaders';
import { app } from '@src/index';
import { __ } from "@src/translation";
import { Link } from 'react-router-dom';
import { ListItemImage, ListItem, ListItemText, ListItemMenu, List } from '../ui/List';
import { PATH_LEARNING_PATH_VIEW, PATH_LEARNING_PATH_CREATE, PATH_LEARNING_PATH_EDIT } from "@src/Routes";
import { formatDate } from "@src/Util";
import { lessonplanGradeCrudClassProxy } from "@src/framework/crud/doc/LessonplanGradeCrud";

type LearningPathListState={
    searchParams: any,
    learningPathRecords: ILpLearningPathRecord[],
    loading: boolean,
  
}

export default class LearningPathList extends React.Component<any, LearningPathListState> {

    constructor(props: any) {
        super(props);   
    
        this.state = {
            searchParams : {},
            learningPathRecords:[],
            loading:true
        }
    }

    componentDidMount() {
        this.asyncReload();
    }

    private asyncReload = async () => {
        this.setState({loading:true});
        try {
            let items :ILpLearningPathRecord[] = [];
            if (me && me.id) {
                items = await LpLearningPathCrud.list({
                    filter:{
                        is_active: true,
                        creation_user_id: me.id
                    },
                    order_by: "title"
                });
            }
            this.setState({loading:false, learningPathRecords: items});
        } catch (error) {
            this.setState({loading:false});
            app.showErrorFromJsonResult(error);
        }
    }

    async onDeleteLP(index:number){
        const crud = new LpLearningPathCrud(this.state.learningPathRecords![index] as any);
        const response = await crud.del();
        app.showSuccess(__("Törlés"), __("Az elem törlése sikeres!"));
        this.asyncReload();
    }

    onEditLP(index:number){
        let item = this.state.learningPathRecords[index];        
        this.props.history.push(PATH_LEARNING_PATH_EDIT +"/"+item.id);
    }

    render(){
        if (this.state.loading) {
            return <BubbleLoader />
        }
        return <><div className="column">
                <Link className="button small" to={PATH_LEARNING_PATH_CREATE}><i className="fa fa-plus" /> {__("Új útvonal hozzáadása")}</Link>
                <h4>{__("Tanulási útvonalaim")}</h4>
                {
                    this.state.learningPathRecords.length === 0 && 
                    <div className="callout">
                        <h5>{__("Jelenleg nincsenek útvonalaid.")}</h5>
                        <p>
                           {__("Tanulási útvonalat létrehozni a fentebb található \"Új útvonal létrehozása\" gombbal lehet.")} 
                        </p>
                    </div>
                }
                <List>
                    {
                        this.state.learningPathRecords.map((item, index) => 
                            <ListItem key={item.id}>
                                <Link to={PATH_LEARNING_PATH_VIEW +"/"+ item.id}>
                                    <ListItemImage src={"/img/IKON_SET/FILE/collection.svg"}/>
                                </Link>
                                <ListItemText>
                                    <Link to={PATH_LEARNING_PATH_VIEW +"/"+ item.id}>
                                        <strong>{item.title}</strong>
                                    </Link>
                                    <br />
                                    <span className="content-item-list__small">{__("Létrehozva")}: {formatDate(item.creation_time)}</span>
                                </ListItemText>

                                <ListItemMenu mainItems={[                                    
                                    {
                                        icon: <i className="fa fa-fw fa-edit" />,
                                        type: "secondary",
                                        name: __("Szerkesztés"),
                                        onClick: () => this.onEditLP(index)
                                    },
                                    {
                                        icon: <i className="fa fa-fw fa-trash" />,
                                        type: "alert",
                                        name: __("Törlés"),
                                        onClick: () => this.onDeleteLP(index)
                                    },
                                ]}
                                >
                                
                                </ListItemMenu>

                            </ListItem>
                        )
                    }
                </List>
                
            </div>

        </>
    }

}