import { MultiTextAElement } from "../engine/EKEMultiTextAnswerExerciseEngine/EKEMultiTextAnswerConverter";
import { AnswerElement } from "./ExerciseBaseClass";
import { __ } from '@src/translation';

export type ShuffleResult = {
    answers: any[],
    indexes: number[]
}


export type HitTestResult = {
    overlap: boolean;
    id?: number;
}

export function GET_EMPTY_IMAGE_ANSWER_ALT_TEXT() {
    return __("Kép, ami szükséges a feladat megoldásához, de szöveggel nem helyettesíthető.")
}

export abstract class AExerciseEngine implements ExerciseEngine10 {

    public apiVersion: string = "1.0";
    public isSNIexc: boolean = false;
    protected exercise: any = {};
    protected root: HTMLDivElement = document.createElement("div");
    protected isReplay: boolean = false;
    protected is_accessible: boolean | null = null;
    private SNIsolution: any = {};
    public simple_style: boolean = false;
    protected isExamMode:boolean = false;
    protected userReadyWithSNI: () => void = function () { };
    protected reloadResources:()=>void=function(){};

    public initExercise(params: ExerciseParams): void {
        if (!params.exercise) return;
        this.SNIsolution = params.SNISolution;
        this.userReadyWithSNI = params.SNIUserReady;
        this.isSNIexc = params.SNISolution && JSON.stringify(params.SNISolution) != JSON.stringify({});
        this.exercise = params.exercise;
        this.isReplay = params.isReplay;
        this.is_accessible = params.is_accessible;
        this.simple_style = params.simple_style;      
        this.isExamMode = params.isExamMode;  
        this.reloadResources = params.reloadResources;

        if (params.exercise.description && params.exercise.description != "") {
            let descriptionDiv = params.element.appendChild(document.createElement("nav"));
            descriptionDiv.classList.add("exe-description");
            descriptionDiv.innerHTML = params.exercise.description;
            descriptionDiv.title = __("Kérdés");
        }

        console.log(params.exercise)

        if (params.exercise.sound) {
            let soundTag = params.element.appendChild(document.createElement("AUDIO"));
            soundTag.setAttribute("src", "/" + params.exercise.sound);
            soundTag.setAttribute("controls", "");
            soundTag.classList.add("exe-main-audio");
        }

        this.root = params.element.appendChild(document.createElement("div"));
        this.root.classList.add("exe-content");
    }

    public setIllustration(exercise: any, illustrationContainer: HTMLElement) {
        if (exercise.illustration && exercise.illustration != "" && exercise.illustration != "undefined") {
            let img = illustrationContainer.appendChild(document.createElement("img"));
            img.setAttribute("src", exercise.imagebasepath + exercise.illustration);
            img.classList.add("exe-engine-illustration");
            if (exercise.illustration_alt && exercise.illustration_alt != "undefined") {
                img.setAttribute("alt", exercise.illustration_alt);
                img.setAttribute("title", exercise.illustration_alt);
            } else {
                img.setAttribute("alt", __("Illusztráció"));
                img.setAttribute("title", __("Illusztráció"));
            }
        }
    }

    public abstract getUserSolution(): UserSolution;

    public abstract receiveEvaluation(evaluated: Evaluated): void;

    public abstract showCorrectSolution(solution: any): void;

    public abstract isUserReady(): boolean; //visszaadja, hogy történt-e felhasználói interakció VAGY minden ki van-e töltve

    public containerResized() {
        // override in children if necessary!
    }

    /**
    * This method check that ver which div a drop event happened out of the arryOfElements list. 
    * The returned variable "overlap" tells if there was an overlap at all. 
    * If yes, the "id" tells the index of the div with which the coordinates overlaped. 
    * @param event Drop event with pageX, pageY parameters that tell where the mouse is at the event of drop
    * @param arryOfElements We search in this list to find the div to which the element was droped.
    */
    static hitTest(event: any, arryOfElements: HTMLDivElement[]): HitTestResult {
        let x = event.pageX;
        let y = event.pageY;

        for (let i = 0; i < arryOfElements.length; i++) {
            let rect2 = arryOfElements[i].getBoundingClientRect();
            let rect = arryOfElements[i];

            let offset = $(rect).offset();
            if ($(rect) && offset) {
                let overlap = (x > offset.left && y > offset.top
                    && x < offset.left + rect2.width && y < offset.top + rect2.height);

                if (overlap)
                    return { overlap: overlap, id: i };
            }
        }
        return { overlap: false };
    }

    static hitTestOld(element: HTMLDivElement, arryOfElements: HTMLDivElement[]): HitTestResult {
        let rect1 = element.getBoundingClientRect();
        let x = rect1.left + (rect1.width / 2);
        let y = rect1.top + (rect1.height / 2);

        for (let i = 0; i < arryOfElements.length; i++) {
            let rect2 = arryOfElements[i].getBoundingClientRect();
            let overlap = (x > rect2.left && y > rect2.top
                && x < rect2.right && y < rect2.bottom);

            if (overlap)
                return { overlap: overlap, id: i };
        }
        return { overlap: false };
    }

    /** 
     * This method does the SNIEvaluation for exercise engines.
    */
    public SNIEvaluation(engine: { evaluateOnServer: (exercise: any, correctSolution: any, userSolution: any) => Evaluated }) {
        let currSol = this.getUserSolution();
        let evalres: Evaluated = engine.evaluateOnServer({ exercise: this.exercise }, this.SNIsolution, currSol.answer);
        this.receiveEvaluation(evalres);
        if (evalres.success) this.userReadyWithSNI();
    }

    /**
   * This method displays answer elements at the engines, and sets the alt text of them. 
   * @param answerParent Parent div/element of the answer element
   * @param answerElement The answer element
   * @param is_accessible This boolean has 3 state: true/false/null
   * @param elementClassList The list of the classes that should be added on the answerElement
   * @param engine The current engine
   */
    public static displayAnswer(answerParent: HTMLElement, answerElement: AnswerElement, is_accessible: boolean | null, elementClassList: string[], engine: any): void {
        let altText = is_accessible != false || answerElement.type == "sound" ? answerElement.text : GET_EMPTY_IMAGE_ANSWER_ALT_TEXT() + " - " + answerElement.text;
        if (answerElement.type == "text") {
            let label = answerParent.appendChild(document.createElement("span"));
            label.tabIndex = 0;
            label.innerText = answerElement.text;
        }
        else if (answerElement.type == "image") {
            let answerimage = answerParent.appendChild(document.createElement("img"));
            answerimage.setAttribute("src", "/" + answerElement.image);
            answerimage.setAttribute("alt", altText);
            answerimage.setAttribute("title", altText);
            answerimage.setAttribute("draggable", 'false');
            answerimage.tabIndex = 0;
            //answerimage.classList.add(...elementClassList);
        }
        else if (answerElement.type == "sound") {
            if (answerElement.url != "") {
                let soundTag = answerParent.appendChild(document.createElement("AUDIO"));
                soundTag.setAttribute("src", "/" + answerElement.url);
                let playIcon = answerParent.appendChild(document.createElement("i"));
                playIcon.classList.add("fa", "fa-play", "fa-2x", "exe-engine-play-sound");
                playIcon.addEventListener('click', AExerciseEngine.playAnswerSound.bind(this, answerParent, engine, "exe-engine-play-sound"));
            }
            let label = answerParent.appendChild(document.createElement("span"));
            //label.classList.add(...elementClassList);
            label.tabIndex = 0;
            label.innerText = altText;
        }
        answerParent.classList.add(...elementClassList);
    }
    /** 
     * This method is responsible for managing sound answers/questions
    */
    public static playAnswerSound(element: HTMLElement, curr_engine: any, icon_className: string) {
        AExerciseEngine.resetIconsToPlay(curr_engine.root, icon_className);
        //We get the audio element and the icon from the clicked element
        let audio = element.getElementsByTagName("audio");
        let icon = element.getElementsByTagName("i");
        //If there is a sound running, we should first stop playing it
        if (curr_engine.curr_audio != undefined && !curr_engine.curr_audio.paused) {
            curr_engine.curr_audio.pause();
            curr_engine.curr_audio.currentTime = 0;
            //If it's the same, which is running, we should just change icon and return
            if (curr_engine.curr_audio == audio[0]) {
                icon[0].classList.add("fa-play");
                icon[0].classList.remove("fa-stop");
                return;
            }
        }
        //We should start the audio
        curr_engine.curr_audio = audio[0];
        curr_engine.curr_audio.play();
        //Change the icon to 'stop'
        icon[0].classList.remove("fa-play");
        icon[0].classList.add("fa-stop");
        //If the sound has ended, we should change the icon to 'play'
        $(audio[0]).bind('ended', function () {
            icon[0].classList.remove("fa-stop");
            icon[0].classList.add("fa-play");
        });
    }

    public static resetIconsToPlay(root: HTMLElement, icon_className: string): void {
        let icons = root.getElementsByClassName(icon_className);
        for (let i = 0; i < icons.length; i++) {
            icons[i].classList.remove("fa-stop");
            icons[i].classList.add("fa-play");
        }
    }

    /**
     * This method is responsible for handlig the drop event in those engines that only allow one item to be droped to the same area.
     * If required, it can handle that elements can be doped nfinitely many times.
     * The method requires that the starting container div has the class: 'answer-container'
     * and there is a style definition that describes the dropped element named 'dropped' class.
     * Returns true if the element is moved back to the answer container, false if not.
     */
    public static drop(ev: any, dropDivList: HTMLDivElement[], infinite_elements?: boolean, sender?: any):boolean {
        let trgt: HTMLElement = (ev.target as HTMLElement);
        let overlap = this.hitTest(ev, dropDivList);
        let answerContainer = sender.root.querySelector('.answer-container');

        if (answerContainer)
            answerContainer.childNodes.forEach((element: HTMLElement) => {
                element.classList.remove("item-hidden");
            });

        if (overlap.overlap && overlap.id != undefined) {
            // if its a div that already has elements in it, and it's not the starting container
            if ((dropDivList[overlap.id].childElementCount > 0 && !dropDivList[overlap.id].classList.contains('answer-container'))) {
                trgt.setAttribute("style", "position:relative; top:0; left:0");
                this.moveElementBack(ev.target, answerContainer, sender.root);
                AExerciseEngine.shrinkAndGrow(trgt);
                return true;
            }

            // if its the starting container, we remove the dropped style
            if (dropDivList[overlap.id].classList.contains('answer-container')) {
                if (infinite_elements && trgt.classList.contains('dropped')) {
                    $(ev.target).detach().remove();
                } else {
                    this.removeEvalStyle(ev.target);
                    trgt.classList.remove('dropped');
                    //dropDivList[overlap.id].appendChild(trgt);
                    this.moveElementBack(ev.target, answerContainer, sender.root);
                    AExerciseEngine.shrinkAndGrow(trgt);
                    return true;
                }

            } else {
                // if we deal with infinite elements, we need to make a copy of the original div
                // if it is freshly moved from the start position
                if (infinite_elements && !trgt.classList.contains('dropped')) {
                    trgt.setAttribute("style", "position:relative; top:0; left:0");
                    trgt = $(ev.target).clone().get(0);
                    ($(trgt) as any).draggable({
                        start: sender.drag.bind(sender),
                        stop: sender.drop.bind(sender),
                        drag: (typeof sender.dragging == 'function') ? sender.dragging.bind(sender) : null,
                        containment: sender.root,
                        scroll: true,
                        helper: (sender.drag_clone_mode) ? 'clone' : 'original',
                        appendTo: sender.root

                    });

                    /* Click-to-click simulation */
                    this.simulateDrag({
                        draggableItems: [trgt],
                        clickArea: sender.root,
                        clickAreaSet: false
                    });
                }
                trgt.classList.add('dropped');
                dropDivList[overlap.id].appendChild(trgt);
            }
        } else {
            /* if not starting container, neither drop area, put back to starting container */
            let answerContainer = sender.root.querySelector('.answer-container');
            if (answerContainer && trgt.classList.contains('dropped')) {
                if (infinite_elements) {
                    $(ev.target).detach().remove();
                } else {
                    this.removeEvalStyle(ev.target);
                    trgt.classList.remove('dropped');
                    trgt.classList.remove('ui-draggable-dragging');
                    this.moveElementBack(ev.target, answerContainer, sender.root);
                    AExerciseEngine.shrinkAndGrow(trgt);
                    return true;
                    //answerContainer.appendChild(trgt);
                }
            } else {
                let dataindex = trgt.getAttribute("data-index");
                let placeholder = answerContainer.querySelector('.placeholder-div[data-index="' + dataindex + '"]');
                if(placeholder) placeholder.parentElement!.removeChild(placeholder);
            }
        }
        trgt.setAttribute("style", "position:relative; top:0; left:0");
        //answerContainer.querySelector(".placeholder-div[");
        AExerciseEngine.shrinkAndGrow(trgt);
        return false;
    }

    public getChildPosition(parentDiv: HTMLElement, childeNode: HTMLElement): number {
        for (let index = 0; index < parentDiv.childNodes.length; index++) {
            if (childeNode == parentDiv.childNodes[index]) {
                return index;
            }
        }
        return -1;
    }

    /**
  * This method removes the placeholders of the answer elements.
  * @param root The root element of the exercise engine (this.root)
  */
    public removePlaceHolders(root: HTMLDivElement) {
        let placeHolders = root.querySelectorAll(".placeholder-div");
        placeHolders.forEach(pl => {
            pl.parentElement!.removeChild(pl);
        });
    }
    /**
      * This method makes a clone of the answer elements. This method is for the
      * DnD engines in simple_style or SNI. The method makes a clone at dragging,
      * and creates a placeholder div of the element, and inserts to the answer container.
      * @param target The answer div from the drag event (event.target)
      * @param parent The parent element of the target
      */
    public cloneAnswerElement(target: HTMLElement, parent: HTMLElement) {
        let childIdx = this.getChildPosition(parent, target);
        let cloneElem = ($(target).clone().get(0) as HTMLElement);
        cloneElem.classList.add("placeholder-div");
        cloneElem.style.top = "0";
        cloneElem.style.left = "0";
        cloneElem.classList.remove("ui-draggable-dragging");
        cloneElem.classList.add("item-hidden");
        cloneElem.style.opacity = '';
        cloneElem.style.zIndex = '';
        parent.insertBefore(cloneElem, parent.children[childIdx + 1]);
    }
    /**
      * This method moves back a dropped answer element to the answer-container. If the element has placeholder,
      * we remove that and put the element in that position. Otherwise we just put it back to the answer-container div.
      * @param answerElement The answer element div
      * @param parentDiv The parent element of the target
      * @param root The root element of the exercise engine. Usually this.root (from engines) or sender.root (from the abstract class drop method)
      */
    public static moveElementBack(answerElement: HTMLElement, answerC: HTMLElement, root: HTMLDivElement) {
        let dataindex = answerElement.getAttribute("data-index");
        let placeholder = answerC.querySelector('.placeholder-div[data-index="' + dataindex + '"]');
        answerElement.classList.remove("dropped");
        if (placeholder) {
            answerC.insertBefore(answerElement, placeholder);
            placeholder.remove();
            return;
        }
        answerC.appendChild(answerElement);
    }


    /**
     * Shuffles the array a in random order.
     * @param a an array to be shuffled
     */
    public static shuffle(a: any[]) {
        for (let i = a.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [a[i], a[j]] = [a[j], a[i]];
        }
        return a;
    }


    /**
     * Shuffles and merges the two input arrays and returns the shuffled list 
     * together with the list of original indexes
     * @param a The answer array to shuffle
     * @param additionalElements If there are odd elements, they get mixed with the true answer elements
     */
    public static shuffleWithIndexes(a: any[], additionalElements?: any[] | null): ShuffleResult {
        var j, x, i;
        var shuffledArray = [];
        var tempAnswers = a.slice();
        var indexes = [];
        var numOfElements = a.length;

        for (i = numOfElements - 1; i >= 0; i--) {
            j = Math.floor(Math.random() * (i + 1));
            shuffledArray.push(tempAnswers[j]);
            tempAnswers.splice(j, 1);
        }

        tempAnswers = shuffledArray.slice();
        for (i = 0; i < numOfElements; i++) {
            if (!additionalElements || (additionalElements && additionalElements.indexOf(a[i]) == -1)) {
                let j = tempAnswers.indexOf(a[i]);
                indexes.push(j);
                // we need to handle duplicate elements
                tempAnswers[j] = "#undefined";
            }
        }

        var shuffleResult: ShuffleResult = { answers: shuffledArray, indexes: indexes };
        return shuffleResult;
    }

    public static getRealIdx(shuffledIdx: number[]): number[] {
        let newArr: number[] = [];
        for (let index = 0; index < shuffledIdx.length; index++) {
            newArr[shuffledIdx[index]] = index;
        }
        return newArr;
    }

    /**
     * Removing the evaluation css classes from the html element
     * @param element element of which we change the class
     */
    public static removeEvalStyle(element: HTMLElement): void {
        if (element)
            element.classList.remove('exe-engine-correct-bg',
                'exe-engine-wrong-bg',
                'eke-engine-show-correct-bg',
                'exe-engine-check-wrong',
                'exe-engine-check-correct');
    }


    /**
     * This method requires a html element, of which it sets the expansion and shrink styles.
     * This method requires that the following css classes are defined:
     * answer-div-text-max-height,  .answer-div[data-shrinked="1"],  .answer-div[data-shrinked="0"]
     */
    public static shrinkAndGrow(element: HTMLElement) {
        if (element.classList.contains("answer-img")) return;
        if (!element.hasAttribute('data-shrinkandgo')) { // adding the functionality

            element.classList.add("answer-div-text-max-height");
            let iconButton = document.createElement("i");
            iconButton.classList.add("fa", "fa-chevron-right");
            iconButton.setAttribute('aria-hidden', 'true');
            iconButton.classList.add('icon-shrinkgrow');

            iconButton.addEventListener("click", function (event) {
                //let parent = this.closest('div');

                if (element.getAttribute('data-shrinked') == '1') {
                    element.setAttribute('data-shrinked', '0');
                    iconButton.classList.remove("fa", "fa-chevron-right");
                    iconButton.classList.add("fa", "fa-chevron-down");
                } else {
                    element.setAttribute('data-shrinked', '1');
                    iconButton.classList.remove("fa", "fa-chevron-down");
                    iconButton.classList.add("fa", "fa-chevron-right");
                }
            }, false);

            if (element.scrollHeight > element.offsetHeight) {
                element.appendChild(iconButton);
                element.setAttribute("data-shrinkandgo", "");
                element.setAttribute("data-shrinked", "1");
            }
        } else {
            /* Shrink back */
            if (element.getAttribute('data-shrinked') == '0') {
                let iconButton = element.querySelector('.icon-shrinkgrow');
                element.setAttribute('data-shrinked', '1');

                if (iconButton) {
                    iconButton.classList.remove("fa", "fa-chevron-down");
                    iconButton.classList.add("fa", "fa-chevron-right");
                }
            }
            /*
             // removing the functionality
            if (element.scrollHeight <= element.offsetHeight) {
                // if it was shrinked already
                let iconButton = element.querySelector(".icon-shrinkgrow");
                if (iconButton) element.removeChild(iconButton);
                //element.classList.remove("answer-div-text-max-height")
                element.removeAttribute("data-shrinkandgo");
                element.removeAttribute("data-shrinked");
            }
            */
        }
    }


    /**
     * This function is responsible for the pagination of elements in responsive, small screen mode. 
     * We can navigate in one div left/ight to see its elements. 
     * @param div_container The container in which we navigate
     * @param move_direction Move direction: "left" or "right"
     */
    public static xMove(div_container: HTMLDivElement, move_direction: string) {
        let container = div_container!;
        let direction = move_direction;
        let childs = container.childElementCount;
        let iwidth = container.scrollWidth / childs / 2;
        let styleLeft = container.style.left;
        if (styleLeft == '') {
            styleLeft = '0px';
        }
        let maxLeft = (container.parentNode! as any).offsetWidth - container.scrollWidth;

        if (maxLeft > 0) {
            maxLeft = 0;
        }
        let mathLeft = parseInt(styleLeft + '');
        switch (direction) {
            case 'left':
                mathLeft = mathLeft + iwidth;
                if (mathLeft > 0) {
                    mathLeft = 0;
                }
                break;
            case 'right':
                mathLeft = mathLeft - iwidth;
                if (mathLeft < maxLeft) {
                    mathLeft = maxLeft;
                }
                break;
            default:
                break;
        }
        container.style.left = mathLeft + 'px';
    }

    /**
     * This function is responsible for the simulate dragging of elements with click and click.
     * We can click on the selected element and click on the target.
     * @param data An object with list of parameters:
     * answerItemClass: answer element class - string
     * draggingClass: active element class - string
     * draggableItems: array of draggable elements - elements
     * clickArea: area where user can click - element
     * clickAreaSet: need to add click event to clickArea or not
     * excludedItemClasses: item classes which has to be skipped - array
     */
    public static simulateDrag(data: any = {}): void {
        let answerItemClass: string = (typeof data.answerItemClass !== "undefined") ? data.answerItemClass : 'answer-div';
        let draggingClass: string = (typeof data.draggingClass !== "undefined") ? data.draggingClass : 'ui-draggable-dragging';
        let draggableItems = (typeof data.draggableItems !== "undefined") ? data.draggableItems : null;
        let clickArea = (typeof data.clickArea !== "undefined") ? data.clickArea : null;
        //let clickAreaSet = (typeof data.clickAreaSet !== "undefined") ? data.clickAreaSet : true;
        let dropAreas = (typeof data.dropAreas !== "undefined") ? data.dropAreas : null;
        let excludedItemClasses: Array<string> = (typeof data.excludedItemClasses !== "undefined") ? data.excludedItemClasses : [];

        if (draggableItems && clickArea) {
            /* Clickarea event */
            //if (clickAreaSet) {
            if (!clickArea.onclick) {
                clickArea.onclick = function (e: any) {
                    simulateDragProcess(
                        {
                            method: 'mouse',
                            eventObject: e,
                        }
                    );

                    ariaDragProcess(
                        {
                            method: 'mouse',
                            eventObject: e,
                        }
                    );
                };
            }

            clickArea.keyup = function (e: any) {
                if (e.key == 'Enter') {
                    simulateDragProcess(
                        {
                            method: 'key',
                            eventObject: e,
                        }
                    );

                    ariaDragProcess(
                        {
                            method: 'key',
                            eventObject: e,
                        }
                    );
                }
            };
            //}

        }

        /* Process of simulate drag */
        function simulateDragProcess(options: any = {}) {
            let e = options.eventObject;
            let item = e.target as HTMLElement;
            let itemClassList = Array.from(item.classList);
            let itemClientRect = item.getBoundingClientRect();
            let ariaDragging = clickArea.getAttribute('data-aria-dragging');
            let answerElement = item.closest('.' + answerItemClass);

            /* Run only if the classList doesn't contains excluded list item */
            if (excludedItemClasses.some(r => itemClassList.indexOf(r) >= 0)) return;
            /* Run only if the classList doesn't contains excluded list item */
            if (answerElement) {
                /* Answer div click */
                /* Store mouse position due to overlaping click areas */
                let mx = 0;
                let my = 0;

                if (options.method == 'key') {
                    mx = itemClientRect.left + itemClientRect.width / 2;
                    my = itemClientRect.top + itemClientRect.height / 2;
                } else {
                    if (e.clientX != 0 && e.clientY != 0) {
                        mx = e.clientX;
                        my = e.clientY;
                    } else {
                        mx = itemClientRect.left + itemClientRect.width / 2;
                        my = itemClientRect.top + itemClientRect.height / 2;
                    }
                }

                let currentMousePos = mx + ',' + my;
                let mousePos = clickArea.getAttribute('data-mouse-position');

                if (mousePos != currentMousePos) {
                    clickArea.setAttribute('data-mouse-position', currentMousePos);
                }

                /* Add dragging class */
                if (!answerElement.classList.contains(draggingClass)) {
                    /* Remove all draggingClass */
                    let items = clickArea.querySelectorAll('.' + draggingClass);
                    items.forEach(function (item: HTMLElement) {
                        item.classList.remove(draggingClass);
                    });

                    answerElement.classList.add(draggingClass);
                    answerElement.setAttribute('aria-grabbed', 'true');
                } else {
                    answerElement.classList.remove(draggingClass);
                    answerElement.setAttribute('aria-grabbed', 'false');
                }


            } else {
                /* Clickarea other click */

                /* Run only if the classList doesn't contains excluded list item */
                if (!ariaDragging) {
                    let targetE: HTMLDivElement | null = clickArea.querySelector('.' + draggingClass);

                    /* Execute only if not on draggable item */

                    /* Mouse click */
                    let mx = 0;
                    let my = 0;
                    if (options.method == 'key') {
                        mx = itemClientRect.left + itemClientRect.width / 2;
                        my = itemClientRect.top + itemClientRect.height / 2;
                    } else {
                        if (e.clientX != 0 && e.clientY != 0) {
                            mx = e.clientX;
                            my = e.clientY;
                        } else {
                            mx = itemClientRect.left + itemClientRect.width / 2;
                            my = itemClientRect.top + itemClientRect.height / 2;
                        }
                    }

                    let currentMousePos = mx + ',' + my;
                    let mousePos = clickArea.getAttribute('data-mouse-position');

                    if (mousePos != currentMousePos && targetE) {

                        /* Calculated position */
                        let dimensions = targetE.getBoundingClientRect();
                        let px = dimensions.left + dimensions.width / 2;
                        let py = dimensions.top + dimensions.height / 2;

                        /* Difference of mouse - element position */
                        let diffx = mx - px;
                        let diffy = my - py;

                        ($(targetE) as any).simulate("drag", {
                            dx: diffx,
                            dy: diffy
                        });

                        /* Remove all draggingClass */
                        let items = clickArea.querySelectorAll('.' + draggingClass);
                        items.forEach(function (item: HTMLElement) {
                            item.classList.remove(draggingClass);
                            item.setAttribute('aria-grabbed', 'false');
                        });
                    }
                }
            }
        }

        function ariaDragProcess(data: any = {}): void {
            //let draggingClass: string = (typeof data.draggingClass !== "undefined") ? data.draggingClass : 'ui-draggable-dragging';
            //let draggableItems = (typeof data.draggableItems !== "undefined") ? data.draggableItems : null;
            //let dropAreas = (typeof data.dropAreas !== "undefined") ? data.dropAreas : null;
            //let clickArea = (typeof data.clickArea !== "undefined") ? data.clickArea : null;

            let e = data.eventObject;
            let item = e.target as HTMLElement;
            let itemClassList = Array.from(item.classList);
            let itemClientRect = item.getBoundingClientRect();
            //let ariaDragging = clickArea.getAttribute('data-aria-dragging');
            let answerElement = item.closest('.' + answerItemClass) as HTMLElement;
            let keyMode = false;

            if (data.method == 'mouse') {
                //if ((e.hasOwnProperty('sourceCapabilities') && e.sourceCapabilities == null) || e.mozInputSource == 0 || e.pointerType == "") {

                if ((typeof (e.sourceCapabilities) !== "undefined" && e.sourceCapabilities == null) || e.mozInputSource == 0 || e.pointerType == "") {
                    keyMode = true;
                }
            } else if (data.method == 'key') {
                keyMode = true;
            }

            if (answerElement && keyMode && dropAreas) {

                let ariaLive = clickArea.querySelector('.aria-live');
                let dropDowns = clickArea.parentNode.querySelectorAll('.droparea-dropdown');
                for (let i = 1; i < dropDowns.length; i++) {
                    dropDowns[i].remove();
                }

                let dropdown = clickArea.parentNode.appendChild(document.createElement("ul"));
                dropdown.classList.add("droparea-dropdown");
                dropdown.setAttribute('role', 'menu');


                let px = answerElement.offsetLeft;
                let py = answerElement.offsetTop;


                /* Get offset position to main exe engine if possible */
                let exeEngine = clickArea.closest('.exe-engine');
                if (exeEngine) {
                    let offsetPosition = AExerciseEngine.getRelativePosition({
                        masterElement: exeEngine,
                        childElement: answerElement
                    });

                    px = offsetPosition['x'] + answerElement.offsetWidth / 2;
                    py = offsetPosition['y'] + answerElement.offsetHeight / 2;

                }

                dropdown.style.left = px + 'px';
                dropdown.style.top = py + 'px';

                dropdown.addEventListener('keydown', function (ed: any) {
                    ed.preventDefault();

                    if (ed.key == 'ArrowDown' || ed.key == 'ArrowRight') {
                        let activeElement = document.activeElement;
                        if (activeElement && activeElement != dropdown.lastChild) {
                            let nextElement = activeElement.nextSibling as HTMLElement;
                            if (nextElement) {
                                nextElement.focus();
                            }
                        }

                    }
                    if (ed.key == 'ArrowUp' || ed.key == 'ArrowLeft') {
                        let activeElement = document.activeElement;
                        if (activeElement && activeElement != dropdown.firstChild) {
                            let nextElement = activeElement.previousSibling as HTMLElement;
                            if (nextElement) {
                                nextElement.focus();
                            }
                        }
                    }

                    if (ed.key == 'Enter' || ed.key == 'Space') {
                        let activeElement = document.activeElement;

                        if (activeElement) {
                            let targetId = activeElement.getAttribute('data-dropdiv');

                            if (targetId && dropAreas) {
                                dropAreas[targetId].click();
                            } else {
                                clickArea.click();
                            }

                            dropdown.remove();

                            AExerciseEngine.updateAriaLive({
                                ariaLive: ariaLive,
                                text: __('Elem áthelyezve. Mozgató menü bezárva.')
                            });

                            setTimeout(function () {
                                answerElement.focus();
                            }, 300);
                        }
                    }

                    if (ed.key == 'Escape' || ed.key == 'Tab') {
                        dropdown.remove();
                        answerElement.classList.remove(draggingClass);
                        answerElement.setAttribute('aria-grabbed', 'false');

                        setTimeout(function () {
                            answerElement.focus();
                        }, 300);

                        AExerciseEngine.updateAriaLive({
                            ariaLive: ariaLive,
                            text: __('Mozgató menü bezárva.')
                        });
                    }

                });

                dropdown.addEventListener('click', function (ed: any) {
                    let activeElement = document.activeElement;
                    if (activeElement) {
                        let targetId = activeElement.getAttribute('data-dropdiv');

                        if (targetId && dropAreas) {
                            dropAreas[targetId].click();
                        }
                        dropdown.remove();
                    }
                });

                for (let i = 0; i < dropAreas.length; i++) {
                    let name = dropAreas[i].getAttribute('data-title');
                    if (!name) {
                        name = __('Válaszok halmaza');
                    }

                    let li = document.createElement('li');
                    li.setAttribute('role', 'menuitem');
                    li.setAttribute('data-dropdiv', i + '');
                    li.tabIndex = -1;

                    li.appendChild(document.createTextNode(__('{name} területre', { name: name })));
                    dropdown.appendChild(li);
                }


                AExerciseEngine.updateAriaLive({
                    ariaLive: ariaLive,
                    text: __('Mozgató menü nyitva.')
                });

                setTimeout(function () {
                    dropdown.firstChild.focus();
                }, 1500);

            } else {
                let dropDowns = clickArea.parentNode.querySelectorAll('.droparea-dropdown');

                for (let i = 0; i < dropDowns.length; i++) {
                    dropDowns[i].remove();
                }
            }
        }

    }

    /**
     * This function is an extension for the aria dragging of elements with key and screen readers.
     * We can click on the selected element and click on the target.
     * @param data An object with list of parameters:
     * draggableItems: array of draggable elements - elements
     * clickArea: area where user can click - element
     */
    public static ariaDrag(data: any = {}): void {
        let draggingClass: string = (typeof data.draggingClass !== "undefined") ? data.draggingClass : 'ui-draggable-dragging';
        let draggableItems = (typeof data.draggableItems !== "undefined") ? data.draggableItems : null;
        let dropAreas = (typeof data.dropAreas !== "undefined") ? data.dropAreas : null;
        let clickArea = (typeof data.clickArea !== "undefined") ? data.clickArea : null;

        if (draggableItems && clickArea) {
            /* Add dropdown to every draggable items */
            for (let i = 0; i < draggableItems.length; i++) {

                let sourceElement = draggableItems[i];

                let ariaButton = document.createElement("button");
                ariaButton.classList.add('aria-drag-button');
                ariaButton.setAttribute('aria-haspopup', 'true');
                ariaButton.setAttribute('aria-label', __('Aktuális elem mozgatása.'));
                ariaButton.textContent = '&nbsp;';

                ariaButton.addEventListener('click', function (e) {
                    let ariaLive = clickArea.querySelector('.aria-live');
                    let dropDowns = clickArea.querySelectorAll('.droparea-dropdown');
                    for (let i = 1; i < dropDowns.length; i++) {
                        dropDowns[i].remove();
                    }

                    let dropdown = clickArea.parentNode.appendChild(document.createElement("ul"));
                    dropdown.classList.add("droparea-dropdown");
                    dropdown.setAttribute('role', 'menu');

                    let px = ariaButton.offsetLeft / 2 + sourceElement.offsetLeft;
                    let py = ariaButton.offsetTop / 2 + sourceElement.offsetTop;

                    /* Get offset position to main exe engine if possible */
                    let exeEngine = clickArea.closest('.exe-engine');
                    if (exeEngine) {
                        let offsetPosition = AExerciseEngine.getRelativePosition({
                            masterElement: exeEngine,
                            childElement: ariaButton
                        });

                        px = offsetPosition['x'] - ariaButton.offsetLeft / 2;
                        py = offsetPosition['y'] - ariaButton.offsetTop / 2;
                    }

                    dropdown.style.left = px + 'px';
                    dropdown.style.top = py + 'px';

                    dropdown.addEventListener('keydown', function (ed: any) {
                        ed.preventDefault();

                        if (ed.key == 'ArrowDown' || ed.key == 'ArrowRight') {
                            let activeElement = document.activeElement;
                            if (activeElement && activeElement != dropdown.lastChild) {
                                let nextElement = activeElement.nextSibling as HTMLElement;
                                if (nextElement) {
                                    nextElement.focus();
                                }
                            }

                        }
                        if (ed.key == 'ArrowUp' || ed.key == 'ArrowLeft') {
                            let activeElement = document.activeElement;
                            if (activeElement && activeElement != dropdown.firstChild) {
                                let nextElement = activeElement.previousSibling as HTMLElement;
                                if (nextElement) {
                                    nextElement.focus();
                                }
                            }
                        }

                        if (ed.key == 'Enter' || ed.key == 'Space') {
                            let activeElement = document.activeElement;

                            if (activeElement) {
                                let targetId = activeElement.getAttribute('data-dropdiv');

                                if (targetId) {
                                    dropAreas[targetId].click();
                                } else {
                                    clickArea.click();
                                }

                                sourceElement.focus();
                                dropdown.remove();

                                AExerciseEngine.updateAriaLive({
                                    ariaLive: ariaLive,
                                    text: __('Elem áthelyezve.')
                                });
                            }
                        }

                        if (ed.key == 'Escape' || ed.key == 'Tab') {
                            dropdown.remove();
                            sourceElement.focus();
                            draggableItems[i].classList.remove(draggingClass);
                            sourceElement.setAttribute('aria-grabbed', 'false');
                        }

                    });

                    dropdown.addEventListener('click', function (ed: any) {
                        let activeElement = document.activeElement;
                        if (activeElement) {
                            let targetId = activeElement.getAttribute('data-dropdiv');

                            if (targetId) {
                                dropAreas[targetId].click();
                            }
                            dropdown.remove();
                        }
                    });

                    for (let i = 0; i < dropAreas.length; i++) {
                        let name = dropAreas[i].getAttribute('data-title');
                        if (!name) {
                            name = __('Válaszok halmaza');
                        }

                        let li = document.createElement('li');
                        li.setAttribute('role', 'menuitem');
                        li.setAttribute('data-dropdiv', i + '');
                        li.tabIndex = -1;

                        li.appendChild(document.createTextNode(name));
                        dropdown.appendChild(li);
                    }


                    AExerciseEngine.updateAriaLive({
                        ariaLive: ariaLive,
                        text: 'Menü nyitva.'
                    });

                    setTimeout(function () {
                        dropdown.firstChild.focus();
                    }, 1000);


                });

                draggableItems[i].appendChild(ariaButton);


            }
        }

    }

    /**
     * This function is to capitalize text.
     * @param str: any text which should be transformed
     */
    public static stringCapitalize(str: any): any {
        return str.replace(/\w\S*/g, function (txt: any) { return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(); });
    }

    /**
     * This function is get offset pixel position of nested elements.
     * @param data An object with list of parameters:
     * masterElement: main element
     * childElement: nested element
     */
    public static getRelativePosition(data: any = {}): any {
        let masterElement = (typeof data.masterElement !== "undefined") ? data.masterElement : null;
        let childElement = (typeof data.childElement !== "undefined") ? data.childElement : null;

        let masterElementRect = masterElement.getBoundingClientRect();
        let childElementRect = childElement.getBoundingClientRect();
        let offsetX = childElementRect.left - masterElementRect.left;
        let offsetY = childElementRect.top - masterElementRect.top;

        let offset = {
            x: offsetX,
            y: offsetY
        };

        return offset;
    }

    /**
     * This function is to update Aria live text.
     * @param str: any text which should be logged
     */
    public static updateAriaLive(data: any = {}): any {
        let ariaLive = (typeof data.ariaLive !== "undefined") ? data.ariaLive : null;
        let text = (typeof data.text !== "undefined") ? data.text : null;

        if (ariaLive) {
            //ariaLive.textContent = ariaLive.textContent + text + ' ';
            ariaLive.textContent = text;
        }
    }

}

export abstract class ExerciseEngineSubSeries extends AExerciseEngine {
    abstract getNextSubQuestion(direction: number): SubSeriesQuestionData;

    protected reloadResourceFunc: () => void = function () { };
    protected userReadyWithSeriesFunc: (succes: boolean) => void = function (succes: boolean) { };

}