import * as React from 'react';
import { IOauthClientRecord, OauthClientCrudClassProxy, oauthClientCrudClassProxy } from '@src/framework/crud/sys/OauthClientCrud';
import RecordEditor from '@framework/forms/recordeditor';
import { config } from '@src/framework/server/Server';


export default class OauthClientEditor extends RecordEditor<IOauthClientRecord> {

    public getCrudClassProxy() : OauthClientCrudClassProxy { return oauthClientCrudClassProxy;  }

    protected getEditorForm(): JSX.Element {
        const authorizationUrl = config.authServer!.baseUrl + "/oauth/auth" ;
        const tokenUrl = config.authServer!.baseUrl + "/oauth/token" ;
        const revokeUrl = config.authServer!.baseUrl + "/oauth/revoke" ;
        const userInfoUrl = config.authServer!.baseUrl + "/oauth/me" ;

        return <>
            <div className="row" key="setup_help">
                <div className="small-12 medium-6 column">
                    <label>Authorization url:
                        <input type="text" value={authorizationUrl} readOnly />
                    </label>
                </div>
                <div className="small-12 medium-6 column">
                    <label>Token url:
                        <input type="text" value={tokenUrl} readOnly />
                    </label>
                    </div>
                <div className="small-12 medium-6 column">
                    <label>Revoke url:
                        <input type="text" value={revokeUrl} readOnly />
                    </label>
                    </div>
                <div className="small-12 medium-6 column">
                    <label>UserInfo url:
                        <input type="text" value={userInfoUrl} readOnly />
                    </label>
                </div>
            </div>
            {super.getEditorForm()}
        </>;
    }

}