import obtainServer, { Server } from '@framework/server/Server';

/** Egy nézettábla struktúráját írja le. */
export interface IViewInfo {
    id: number;
    schema_name : string;
    view_name : string;
    /** Csak akkor van töltve ha van hozzá egy "fő" táblázat */
    table_name ?: string; 
    columns: IViewColumnInfo[];
    translations: { [lang_id: number] : IViewInfoTranslation };
}

export interface IViewInfoTranslation {
    id: number;
    view_info_id: number;
    lang_id: number;
    display_name: string;
    description: string;
}

/** 
 * Egy nézettábla oszlopának stuktúráját írja le.
 * 
 * Ez nem egyezik meg az IViewColumnInfoCrud-ban levőkkel. Ez nem az amit
 * a CRUD API-val load() metódussal lehet kérdezni, hanem egy olyan 
 * változat, ami tartalmazza az oszlopok nevét és leírását különböző
 * nyelvekre lefordítva. (Jelenleg csak magyarra...)
 * 
 */
export interface IViewColumnInfo {
    id: number;
    column_name: string;
    colno: number;
    orig_type: string;
    size: number|null;
    fk_table_info_id: number|null;
    translations: { [lang_id: number] : IViewColumnInfoTranslation };
}

export interface IViewColumnInfoTranslation {
    id: number;
    view_column_info_id: number;
    lang_id: number;
    display_name: string;
    description: string;
}

const fetchViewInfoByName = async (schemaName:string, viewName:string, aServer?: Server): Promise<IViewInfo> => {
    return obtainServer(aServer).post<IViewInfo>(
        `view/${schemaName}/${viewName}`,
        {operation: 'getstruct'});
}

const fetchViewInfoById = async (viewInfoId: number, aServer?: Server): Promise<IViewInfo> => {
    return obtainServer(aServer).post<IViewInfo>(
        `view/_viewinfo/${viewInfoId}`,
        {operation: 'getstruct', view_info_id: viewInfoId});
}

const viewInfoByName : { [key: string] : IViewInfo } = {};
const viewInfoById : { [key: number] : IViewInfo } = {};

export const getViewInfoByName = async (schemaName:string, viewName:string, aServer?: Server): Promise<IViewInfo> => {
    const key : string = `${schemaName}.${viewName}`;
    if (!viewInfoByName[key]) {
        let vi : IViewInfo = await fetchViewInfoByName(schemaName, viewName);
        viewInfoByName[key] = vi;
        viewInfoById[vi.id] = vi;
    }
    return viewInfoByName[key];
}

export const getViewInfoById = async (viewInfoId:number, aServer?: Server): Promise<IViewInfo> => {
    if (!viewInfoById[viewInfoId]) {
        let vi = await fetchViewInfoById(viewInfoId);
        const key = vi.schema_name + '.' + vi.view_name;
        viewInfoByName[key] = vi;
        viewInfoById[viewInfoId] = vi;
    }
    return viewInfoById[viewInfoId];
}

