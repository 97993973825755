import { Server } from '../../server/Server';
import View , { registerViewClassProxy, ViewClassProxy } from '../View';
import { IListParams } from '@framework/crud/Crud';

export interface IViewInstituteSiteRecord {
    /**
     * Azonosító
     *
     * Azonosító (pótkulcs)
     */
    id?: number;
    /**
     * Rekord létrehozás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel létrehozásakor.
     */
    creation_time?: string /*timestamp?*/;
    /**
     * Ki hozta létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozó felhasználóra hivatkozik.
     */
    creation_user_id?: number;
    /**
     * Melyik session-ből jött létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozáshoz használt munkamenetre hivatkozik.
     */
    creation_session_id?: number|null;
    /**
     * Utolsó módosítás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel módosításakor.
     */
    modification_time?: string /*timestamp?*/|null;
    /**
     * Ki módosította utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosító felhasználóra hivatkozik.
     */
    modification_user_id?: number|null;
    /**
     * Melyik session-ből módosították utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosításhoz használt munkamenetre hivatkozik.
     */
    modification_session_id?: number|null;
    /**
     * Aktív
     *
     * Logikai törléshez használt mező.
     */
    is_active?: boolean;
    /**
     * Intézmény
     */
    institute_id?: number;
    /**
     * Név
     */
    name?: string;
    /**
     * Ellátási hely azonosító
     */
    site_identifier?: string;
    /**
     * Külföldi
     */
    is_foreign?: boolean;
    /**
     * Irányítószám
     */
    zipcode?: string|null;
    /**
     * Cím
     */
    address?: string|null;
    /**
     * Külföldi cím
     */
    foreign_address?: string|null;
    /**
     * Leírás
     */
    description?: string|null;
    /**
     * Település
     */
    city_id?: number|null;
    /**
     * KAP szakmai támogató
     */
    kap_supporter_id?: number|null;
    /**
     * KAP bevezetés dátuma
     */
    kap_introduction_date?: string /*date?*/|null;
    /**
     * Vezető neve
     */
    leader_name?: string|null;
    /**
     * Vezető telefon
     */
    leader_phone?: string|null;
    /**
     * Vezető e-mail
     */
    leader_email?: string|null;
    /**
     * Szerepel a KIR nyilvántartásban
     */
    in_kir?: boolean;
    /**
     * Kapcsolattartó neve neve
     */
    contact_name?: string|null;
    /**
     * Kapcsolattartó telefon
     */
    contact_phone?: string|null;
    /**
     * Kapcsolattartó e-mail
     */
    contact_email?: string|null;
    /**
     * Létrehozta
     *
     * Felhasználó, aki létrehozta
     */
    creator?: string|null;
    /**
     * Módosította
     *
     * Felhasználó, aki utoljára módosította
     */
    modifier?: string|null;
    /**
     * Intézet OM azonosító
     *
     * Intézet OM azonosító
     */
    om_identifier?: string|null;
    /**
     * Intézet neve
     *
     * Intézet neve
     */
    institute_name?: string|null;
    /**
     * Település
     *
     * Település
     */
    city?: string|null;
    /**
     * Kijelzett név
     *
     * Kijelzett név
     */
    displayvalue?: string|null;
    /**
     * DFHT támogató neve
     *
     * DFHT támogató neve
     */
    kap_supporter_name?: string|null;
}

/**
 *  sys.view_institute_site - Intézmény feladat ellátási hely
 *
 *  Idősoros táblázat, ami az összes tétel összes korábbi verzióját eltárolja.
 */

export default class ViewInstituteSite extends View<IViewInstituteSiteRecord> {
    public static VIEW_INFO_ID = 2018070502;

    public static getSchemaNameForClass(): string { return 'sys'; }
    public static getViewNameForClass(): string { return 'view_institute_site'; }
    public static getTableNameForClass(): string|null { return 'institute_site'; }

    public static load: (id: number, aServer ?: Server) => Promise<ViewInstituteSite>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<IViewInstituteSiteRecord[]>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class ViewInstituteSiteClassProxy extends ViewClassProxy<IViewInstituteSiteRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const viewInstituteSiteClassProxy = new ViewInstituteSiteClassProxy(ViewInstituteSite);
registerViewClassProxy(viewInstituteSiteClassProxy);

