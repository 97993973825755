import { Server } from '../../server/Server';
import Crud , { registerCrudClassProxy, IAsTextResult, IListParams, CrudClassProxy, ISearchTextParams, ISearchTextResult } from '../Crud';

export interface IStyleRecord {
    /**
     * Globálisan egyedi azonosító.
     * 
     * Ha új rekordot akarsz létrehozni put() metódussal, akkor hagyd üresen.
     * upsert() hívásnál figyelmen kívül van hagyva az értéke.
     *
     */
    id ?: number;
    /**
     * Rekord létrehozás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel létrehozásakor.
     */
    creation_time?: string /*timestamp?*/;
    /**
     * Ki hozta létre
     *
     * NOT NULL -> sys.sec_user ON DELETE noaction
     *
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozó felhasználóra hivatkozik.
     */
    creation_user_id?: number;
    /**
     * Melyik session-ből jött létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozáshoz használt munkamenetre hivatkozik.
     */
    creation_session_id?: number|null;
    /**
     * Utolsó módosítás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel módosításakor.
     */
    modification_time?: string /*timestamp?*/|null;
    /**
     * Ki módosította utoljára
     *
     * -> sys.sec_user ON DELETE noaction
     *
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosító felhasználóra hivatkozik.
     */
    modification_user_id?: number|null;
    /**
     * Melyik session-ből módosították utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosításhoz használt munkamenetre hivatkozik.
     */
    modification_session_id?: number|null;
    /**
     * Aktív
     *
     * Logikai törléshez használt mező.
     */
    is_active?: boolean;
    /**
     * Név
     */
    title?: string;
    /**
     * Leírás
     */
    description?: string|null;
    /**
     * CSS útvonal
     *
     * CSS állomány relatív útvonala a könyv gyökér könyvtárához képest.
     */
    css_path?: string;
}

/**
 *  doc.style - Stílus
 *
 *  Könyv blokk sablonok összessége.
 */

export default class StyleCrud extends Crud<IStyleRecord> {
    public static TABLE_INFO_ID = 2620001112;
    public static getSchemaNameForClass() { return 'doc'; }
    public static getTableNameForClass() { return 'style'; }

    public static load: (id: number, aServer ?: Server) => Promise<StyleCrud>;
    public static asText: (id: number, includeRecord : boolean, aServer ?: Server) => Promise<IAsTextResult<IStyleRecord>>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<IStyleRecord[]>;
    public static searchText: (params: ISearchTextParams, aServer ?: Server) => Promise<ISearchTextResult<IStyleRecord>[]>;
    public static deleteById: (id: number, aServer ?: Server) => Promise<IStyleRecord>;
    public static unDeleteById: (id: number, aServer ?: Server) => Promise<IStyleRecord>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class StyleCrudClassProxy extends CrudClassProxy<IStyleRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const styleCrudClassProxy = new StyleCrudClassProxy(StyleCrud);
registerCrudClassProxy(styleCrudClassProxy);
