import * as React from 'react';
import './BookPage.css';
import { BubbleLoader } from 'react-css-loaders';
import { Link } from 'react-router-dom';
import { BookStructure, ChapterJoinLesson, BookLessonContent, RenderedSection } from '@src/server/PublicServer';
import { scriptLoadAsync } from '@src/Util';
import { getClassesFromLessonUriSegment } from '@src/template/BookTemplate';

import { AnnotationMode } from '@src/component/book/viewer/page_addition/BookAnnotations';
import { getRevealIdAndName, isRevealSection } from '@src/template/SectionTemplate';

import { BookSections } from '@src/component/book/viewer/BookSections';
import { me, Groups, hasGroup } from '@src/framework/server/Auth';
import { BookToolBar, ViewMode, BookStickyMenu, BookHeader } from '@src/component/book/viewer/BookPage';
import { PATH_PUBLISHED_BOOK } from '@src/Routes';
import { history } from '@src/index';
import { __ } from '@src/translation';

declare var Foundation: any;

type BookPageProps = {
    onViewModeChange: (viewMode: ViewMode) => void;

    bookPath: string;

    lessonData?: BookLessonContent;
    lesson: ChapterJoinLesson | null;
    bookStructure?: BookStructure;
    viewMode: ViewMode;
}

type BookPageState = {
    cssLoaded: boolean;

    annotationMode: AnnotationMode;
    currentSectionIndex: number;
    viewMode: ViewMode;
    moveable: boolean;
}

const head = document.getElementsByTagName("head")[0];
const body = document.getElementsByTagName("body")[0];

export class BookPresentation extends React.Component<BookPageProps, BookPageState> {

    private bookCommonCss = document.createElement("link");
    private bookPresentationCss = document.createElement("link");

    private bookCustomCss = document.createElement("link");

    private hashChangeEventListener: any;

    private presentationContent: any;

    private zoomSliderElement?: HTMLElement;

    constructor(props: any) {
        super(props);

        this.bookCommonCss.setAttribute("rel", "stylesheet");
        this.bookCommonCss.setAttribute("href", `/css/book_flex.css`);

        this.bookPresentationCss.setAttribute("rel", "stylesheet");
        this.bookPresentationCss.setAttribute("href", `/css/book_presentation.css`);

        this.bookCustomCss.setAttribute("rel", "stylesheet");

        var annotationMode = AnnotationMode.NONE;
        if (me && hasGroup(me, Groups.Lector)) {
            annotationMode = AnnotationMode.LEKTOR_COMMENTING;
        }
        var viewMode = this.props.viewMode;

        this.state = {
            cssLoaded: false,
            annotationMode,
            currentSectionIndex: 0,
            viewMode,
            moveable: true
        };
    }

    async componentDidMount() {

        this.reloadCssAsync();

        if (this.props.lesson) body.className = getClassesFromLessonUriSegment(this.props.lesson.lesson_uri_segment).join(" ");

        this.initZoom();

        if (this.state.moveable) {
            this.contentMoveable();
        }

        this.hashChangeEventListener = window.addEventListener("hashchange", this.onHashChange.bind(this), false);
        document.addEventListener('exerciseOnFullscreen', this.exerciseOnFullscreen.bind(this));
    }

    private exerciseOnFullscreen() {
        let presContent = (this.refs.presentationContent as HTMLElement);
        presContent.style.transform = "none";
    }

    private onHashChange() {
        this.getSectionIndexById(window.location.hash);
    }

    private zoomInited = false;
    initZoom() {
        if (this.zoomInited) {
            return;
        }

        if (this.refs.zoomSlider == this.zoomSliderElement) {
            return;
        }
        this.zoomSliderElement = this.refs.zoomSlider as HTMLElement;
        ($(this.refs.zoomSlider) as any).foundation();

        var zoomSlider = $(this.refs.zoomSlider);
        var presentationContent = (this.refs.presentationContent as HTMLElement)


        zoomSlider.on('moved.zf.slider', function () {
            var zoom = (zoomSlider as any).children('.slider-handle').attr('aria-valuenow');
            var zoomRatio = zoom / 100;
            if (zoomRatio == 1) {
                presentationContent.style.transform = "none"
            } else
                presentationContent.style.transform = "scale(" + zoomRatio + ") ";//+ "translateY(" + ((offsetHeight)*zoomRatio-(offsetHeight))+"px)";

            // presentationWrapper.style.paddingLeft = paddingLeft;
        });

    }

    resetZoom() {
        var presentationContent = (this.refs.presentationContent as HTMLElement)
        var zoomSlider = new Foundation.Slider($(this.refs.zoomSlider), { initialStart: (100) });
        presentationContent.style.transform = "none";
    }

    defaultViewPortMetaContent: string;
    viewPortMeta: any;
    contentMoveable() {
        if (!this.viewPortMeta) {
            this.viewPortMeta = document.getElementsByName("viewport")[0]; //document.createElement('meta');
            this.defaultViewPortMetaContent = this.viewPortMeta.content;
            this.viewPortMeta.content = "width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no";
            //document.getElementsByTagName('head')[0].appendChild(this.meta);
        }


        if (this.refs.presentationContent) {
            this.presentationContent = (this.refs.presentationContent as HTMLElement);
            this.presentationContent.style.cursor = "move";
            this.presentationContent.addEventListener('mousedown', this.onMouseDown, true);
            this.presentationContent.addEventListener('mouseup', this.onMouseUp, true);
            this.presentationContent.addEventListener('mousemove', this.onMouseMove, true);

            this.presentationContent.addEventListener("touchstart", this.onTouchStart, false);
            this.presentationContent.addEventListener("touchmove", this.onTouchMove, false);
            this.presentationContent.addEventListener("touchend", this.onTouchEnd, false);

            if (this.props.viewMode == ViewMode.PRESENTATION) {
                window.addEventListener("touchstart", this.onBodyTouchStart, false);
                window.addEventListener("touchmove", this.onBodyTouchMove, false);
                window.addEventListener("touchend", this.onTouchEnd, false);
            }

        }
    }


    private mousePosition: any;
    private offset = [0, 0];
    private isDown = false;

    onMouseDown(e: any) {
        if (this) {

            var hasDraggable = false;
            var path = e.path || (e.composedPath && e.composedPath());
            if(path)
            {
                path.forEach((item:HTMLElement)=>
                {
                    if (item.hasAttribute && item.hasAttribute("draggable") && item.getAttribute("draggable") == "true") hasDraggable = true;
                });
            }
           
            if(hasDraggable)
            {
                this.isDown = false;
                return;
            }

            var content = this as any;
            content.style.position = "relative";

            this.isDown = true;
            this.offset = [
                content.offsetLeft - e.clientX,
                content.offsetTop - e.clientY
            ];
        }

    }

    onMouseUp(e: any) {
        this.isDown = false;
    }

    onMouseMove(event: any) {

        if (this) {
            var content = this as any;

            event.preventDefault();
            if (this.isDown) {
                // console.log((event.target as HTMLElement));
                // console.log((event.target as HTMLElement).hasAttribute("draggable"));
                

                this.mousePosition = {

                    x: event.clientX,
                    y: event.clientY

                };
                content.style.left = (this.mousePosition.x + this.offset[0]) + 'px';
                content.style.top = (this.mousePosition.y + this.offset[1]) + 'px';
            }
        }

    }

    scaling = false;
    //scale összeakad az eszközével
     onBodyTouchStart(event: any) {
    //     if (event.targetTouches.length == 2 && event.changedTouches.length == 2) {
    //         this.scaling = true;
    //     }
     }
    onTouchStart(event: any) {
        if (this) {
            var content = this as any;
            content.style.position = "relative";

            var hasDraggable = false;
            var path = event.path || (event.composedPath && event.composedPath());

            if(path)
            {
                path.forEach((item:HTMLElement)=>
                {
                    if (item.hasAttribute && item.hasAttribute("draggable") && item.getAttribute("draggable") == "true") hasDraggable = true;
                });
            }

            if(hasDraggable)
            {                
                this.isDown = false;
                return;
            }
            else
            {
                this.isDown = true;
            }

            //this.isDown = true;
            //if (event.targetTouches.length == 1){
            var touch = event.targetTouches[0];

            this.offset = [
                content.offsetLeft - touch.pageX,
                content.offsetTop - touch.pageY
            ];
            //}
        }

    }

    onTouchMove(event: any) {

        //if(this && !(event.touches.length == 2 && event.changedTouches.length == 2) )
        if (this && event.targetTouches.length == 1) {
            event.preventDefault();
            if (this.isDown) {
                var content = this as any;
                var touch = event.targetTouches[0];

                this.mousePosition = {

                    x: touch.pageX,
                    y: touch.pageY

                };
                content.style.left = (this.mousePosition.x + this.offset[0]) + 'px';
                content.style.top = (this.mousePosition.y + this.offset[1]) + 'px';
            }

        }

    }

    pinchDist?: number;
    onBodyTouchMove(event: any) {
        // if (event.touches.length == 2 && event.changedTouches.length == 2) {
        //     event.preventDefault();
        //     var dist = Math.hypot(
        //         event.touches[0].pageX - event.touches[1].pageX,
        //         event.touches[0].pageY - event.touches[1].pageY);

        //     if (this.pinchDist) {
        //         var scale = dist / this.pinchDist;

        //         var zoom = ($("#zoomSlider") as any).children('.slider-handle').attr('aria-valuenow');
        //         var zoomSlider = new Foundation.Slider($("#zoomSlider"), { initialStart: (zoom * scale) });

        //     }
        //     this.pinchDist = dist;

        // }

    }

    onTouchEnd(event: any) {
        if (this.scaling) {
            //pinchEnd(e);
            this.scaling = false;
        }
        this.pinchDist = undefined;
    }

    unbindMoveable() {

        this.viewPortMeta.content = this.defaultViewPortMetaContent;

        if (this.presentationContent) {
            this.presentationContent.style.cursor = "";
            this.presentationContent.removeEventListener('mousedown', this.onMouseDown, true);
            this.presentationContent.removeEventListener('mouseup', this.onMouseUp, true);
            this.presentationContent.removeEventListener('mousemove', this.onMouseMove, true);
            this.presentationContent.removeEventListener("touchstart", this.onTouchStart, false);
            this.presentationContent.removeEventListener("touchmove", this.onTouchMove, false);
            this.presentationContent.removeEventListener("touchend", this.onTouchEnd, false);

            if (this.props.viewMode == ViewMode.PRESENTATION) {
                window.removeEventListener("touchstart", this.onBodyTouchStart, false);
                window.removeEventListener("touchmove", this.onBodyTouchMove, false);
                window.removeEventListener("touchend", this.onTouchEnd, false);
            }
        }
    }

    componentWillUnmount() {
        this.removeBookCss();
        body.removeAttribute("class");

        if (this.hashChangeEventListener) window.removeEventListener("hashchange", this.hashChangeEventListener);
        this.unbindMoveable();

        document.removeEventListener("exerciseOnFullscreen", this.exerciseOnFullscreen);
        body.className = "";
        document.getElementById("mainMenuBar")!.classList.remove("slide");
    }


    async componentDidUpdate(prevProps: BookPageProps, prevState: BookPageState) {

        this.reloadCssAsync();

        if (this.props.bookPath != prevProps.bookPath
            || this.props.lesson != prevProps.lesson
        ) {

            this.setState({ currentSectionIndex: 0 });

            this.contentMoveable();
        }

        if (this.refs.zoomSlider) {
            this.initZoom();
        }

        if (this.state.moveable) {
            this.contentMoveable();
        }
        else {
            this.unbindMoveable();
        }


        const lesson = this.props.lesson;

        if (this.props.bookStructure && lesson) {
            document.title = this.props.bookStructure.name + " - " + lesson.chapter_name + " - " + lesson.lesson_name;
        }

        if (lesson) body.className = getClassesFromLessonUriSegment(lesson.lesson_uri_segment).join(" ");

        if (this.props.viewMode != ViewMode.NORMAL) {
            document.getElementById("mainMenuBar")!.classList.add("slide");
        }

        if (this.props.bookStructure!.is_sni) {
            $('[data-lightbox]').removeAttr("href");
            $('[data-lightbox]').removeAttr("data-lightbox");
        }

    }


    async reloadCssAsync() {
        if (!this.bookCommonCss.parentElement) {
            head.appendChild(this.bookCommonCss);
        }

        if (!this.bookPresentationCss.parentElement) {
            head.appendChild(this.bookPresentationCss);
        }

        if (!this.bookCustomCss.parentElement) {
            head.appendChild(this.bookCustomCss);
        }

        if (!this.props.bookStructure) {
            return;
        }

        const cssHref = this.props.bookStructure.css_path;

        if (this.bookCustomCss.getAttribute("href") != cssHref) {
            this.bookCustomCss.setAttribute("href", cssHref);

            this.setState({ cssLoaded: false });

            try {
                await scriptLoadAsync(this.bookCustomCss, 200);
            } catch (e) {/*css not yet loaded, render anyway*/ }

            this.setState({ cssLoaded: true });
        }

    }

    removeBookCss() {
        this.bookCustomCss.remove();
        this.bookCommonCss.remove();
        this.bookPresentationCss.remove();
    }

    getChapterLessonTree() {
        if (!this.props.bookStructure) {
            return [];
        }

        const chapterLessonTree: any[] = [];

        for (const lesson of this.props.bookStructure.lessons) {
            if (chapterLessonTree.length == 0 || lesson.chapter_id != chapterLessonTree[chapterLessonTree.length - 1].id) {
                chapterLessonTree.push({
                    id: lesson.chapter_id,
                    name: lesson.chapter_name,
                    chapter_headno: lesson.chapter_headno,
                    lessons: []
                });
            }

            chapterLessonTree[chapterLessonTree.length - 1].lessons.push({
                id: lesson.lesson_id,
                name: lesson.lesson_name,
                chapter_headno: lesson.chapter_headno,
                uri_segment: lesson.lesson_uri_segment
            });
        }

        return chapterLessonTree;
    }

    nextSection() {

        if (this.state.currentSectionIndex < this.getNonRevealSections().length - 1) {
            const renderedSections = this.getNonRevealSections()[this.state.currentSectionIndex + 1];

            history.push(window.location.pathname + "#section-" + renderedSections.id);
            this.setState({ currentSectionIndex: this.state.currentSectionIndex + 1 });
            this.resetZoom();
        }
    }
    prevSection() {
        if (this.state.currentSectionIndex != 0) {
            const renderedSections = this.getNonRevealSections()[this.state.currentSectionIndex - 1];

            history.push(window.location.pathname + "#section-" + renderedSections.id);
            this.setState({ currentSectionIndex: this.state.currentSectionIndex - 1 });
            this.resetZoom();
        }

    }

    getSectionIndexById(sectionId: string) {
        var sectionSplit = sectionId.split('-');

        var id: number = Number(sectionSplit[1]);
        var sectionIndex = 0;
        sectionIndex = this.getNonRevealSections().findIndex((section, index: number) => {

            return section.id == id;
        });

        this.setState({ currentSectionIndex: sectionIndex });

    }

    handleViewChange(viewMode: ViewMode) {
        //this.setState({viewMode})
        this.props.onViewModeChange(viewMode);
    }

    /**
     * Csak a nem modális szekciók, prezentációs nézetben az előbukkanó ablakot nem jelenítjuk meg mint egy dia
     */
    private getNonRevealSections(): RenderedSection[] {
        if (!this.props.lessonData) return [];

        return this.props.lessonData.renderedSections.filter(s => !isRevealSection(s.html));
    }

    render() {

        if (!this.state.cssLoaded) {
            return <BubbleLoader />;
        }

        var prevUrl = "";
        var nextUrl = "";

        const lesson = this.props.lesson;

        if (!this.props.bookStructure || !lesson) {
            return <BubbleLoader />;
        }

        const lessons = this.props.bookStructure.lessons;

        var index = lessons.findIndex(x => x.lesson_uri_segment == lesson.lesson_uri_segment);
        if (index == -1) index = 0;

        if (index > 0) {
            prevUrl = this.props.bookPath + lessons[index - 1].lesson_uri_segment;
        }

        if (index < lessons.length - 1) {
            nextUrl = this.props.bookPath + lessons[index + 1].lesson_uri_segment;
        }

        const chapterLessons = this.getChapterLessonTree();

        const reveals: { id: string, name: string }[] = [];

        if (this.props.lessonData) {
            for (const renderedSection of this.props.lessonData.renderedSections) {
                const reveal = getRevealIdAndName(renderedSection.html);
                if (reveal) {
                    reveals.push(reveal);
                }
            }
        }

        var renderedSections: RenderedSection[] = [];

        if (this.props.lessonData) {
            renderedSections = this.getNonRevealSections().slice(this.state.currentSectionIndex, this.state.currentSectionIndex + 1);

            renderedSections = renderedSections.concat(this.props.lessonData.renderedSections.filter(s => isRevealSection(s.html)))
        }

        const isPublished = this.props.bookPath.startsWith(PATH_PUBLISHED_BOOK);

        const arrowSVG = <svg aria-hidden="true" data-icon="triangle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" className="triangle-arrow">
            <defs>
                <filter id="drop-shadow" x="-100%" y="-100%" width="300%" height="300%">
                    <feGaussianBlur in="SourceAlpha" stdDeviation="15" />
                    <feOffset dx="10" dy="25" result="offsetblur" />
                    <feFlood floodOpacity="0.3" floodColor="#000000" />
                    <feComposite in2="offsetblur" operator="in" />
                    <feMerge>
                        <feMergeNode />
                        <feMergeNode in="SourceGraphic" />
                    </feMerge>
                </filter>
            </defs>
            <path filter="url(#drop-shadow)" stroke="white" strokeWidth="5" fill="currentColor" d="M329.6 24c-18.4-32-64.7-32-83.2 0L6.5 440c-18.4 31.9 4.6 72 41.6 72H528c36.9 0 60-40 41.6-72l-240-416z"></path>
        </svg>;

        return <div ref="bookPage" id="bookPage">

            <BookHeader
                bookName={this.props.bookStructure.name}
                bookSlogan={this.props.bookStructure.slogan}
                bookPath={this.props.bookPath}
                bookUriSegment={this.props.bookStructure.uri_segment}
                lesson={lesson}
                chapters={chapterLessons}
                headerFileNames={this.props.bookStructure.headerFileNames}
                viewMode={this.props.viewMode}
                libraryId={this.props.bookStructure.library_id}
                bookId={this.props.bookStructure.id}
            />

            <BookPresentationMenu key="bookStickyMenuPres"
                prevUrl={prevUrl}
                nextUrl={nextUrl}
                bookStructure={this.props.bookStructure}
                bookPath={this.props.bookPath}
                chapterLessons={chapterLessons}
                lessonName={lesson.lesson_name}
                sectionNames={this.props.lessonData ? this.props.lessonData.renderedSections : []}
                supplementaryMaterials={this.props.lessonData ? this.props.lessonData.supplementaryMaterials : []}
                reveals={reveals}
                lessonUriSegment={lesson.lesson_uri_segment}
                viewMode={this.props.viewMode}
            />


            {this.props.viewMode == ViewMode.PAGED ?
                <>
                    {
                        this.state.currentSectionIndex == 0 ?
                            prevUrl
                                ?
                                <Link className="pager-prev" to={prevUrl} title={__("Előző leckére ugrás")}>
                                    {arrowSVG}
                                </Link>
                                :
                                null
                            :
                            <a onClick={this.prevSection.bind(this)} className="pager-prev" title={__("Előző szekció")}>
                                {arrowSVG}
                            </a>
                    }
                    {
                        this.getNonRevealSections().length === 0 || this.state.currentSectionIndex == this.getNonRevealSections().length - 1 ?
                            nextUrl
                                ?
                                <Link className="pager-next" to={nextUrl} title={__("Következő leckére ugrás")}>
                                    {arrowSVG}
                                </Link>
                                :
                                null
                            :
                            <a onClick={this.nextSection.bind(this)} className="pager-next" title={__("Következő szekció")}>
                                {arrowSVG}
                            </a>
                    }
                </>
                : null}


            {
                this.props.lessonData
                    ?
                    <div ref="content">
                        <div ref="presentationWrapper" className="presentation-wrapper">
                            <div ref="presentationContent" className="presentation-content" >
                                <div ref="presentationSection">
                                    <BookSections
                                        key={lesson.lesson_id}
                                        renderedSections={renderedSections}
                                        chapterId={lesson.chapter_id}
                                        published={isPublished}
                                        showCollectionMenu={false}
                                        lessonName={lesson.lesson_name}
                                        isSNIview={false}
                                    />
                                </div>
                            </div>

                            {
                                this.props.viewMode == ViewMode.PRESENTATION
                                    ?
                                    <div className="presentation-toolbar">

                                        <div className="slider-wrapper">

                                            <i className="fas fa-minus"></i>
                                            <div ref="zoomSlider" id="zoomSlider" className="slider" data-slider data-initial-start="100" data-start="20" data-end="180">
                                                <span className="slider-handle" data-slider-handle role="slider" tabIndex={1}></span>
                                                <span className="slider-fill" data-slider-fill></span>
                                                <span className="slider-reset"></span>
                                                <input type="hidden" />
                                            </div>
                                            <i className="fas fa-plus"></i>
                                        </div>

                                        {this.props.viewMode == ViewMode.PRESENTATION ?
                                            <>
                                                <div onClick={this.prevSection.bind(this)} ref="prevSectionArrow" className="prev-section" style={{ display: this.state.currentSectionIndex == 0 ? "none" : "" }}>
                                                    <i className="fa fa-angle-left" />
                                                </div>
                                                <div onClick={this.nextSection.bind(this)} ref="nextSectionArrow" className="next-section" style={{ display: this.state.currentSectionIndex == this.getNonRevealSections().length - 1 ? "none" : "" }}>
                                                    <i className="fa fa-angle-right" />
                                                </div>
                                            </>
                                            : null
                                        }
                                    </div>
                                    :
                                    null
                            }


                        </div>

                        {/* <BookAnnotations lessonId={lesson.lesson_id} annotationMode={this.state.annotationMode} >
                            <BookSections
                                key={this.props.match.params.bookUriSegment + " " + this.props.match.params.lessonUriSegment}
                                renderedSections={this.state.lessonData.renderedSections}
                                bookUriSegment={this.props.match.params.bookUriSegment}
                                chapterId={lesson.chapter_id}
                            />
                        </BookAnnotations> */}
                    </div>
                    :
                    <div key="loader" style={{ minHeight: "100vh" }}>
                        <BubbleLoader />
                    </div>
            }

            {/* {lesson.lesson_uri_segment == "tartalomjegyzek" ? <ContentsList
                book={this.state.bookStructure}
                chapterLessons={chapterLessons}
            /> : ""} */}

            <BookToolBar
                annotationMode={this.state.annotationMode}
                viewMode={this.props.viewMode}
                onChange={(annotationMode, isMoveable) => this.setState({ annotationMode, moveable: isMoveable })}
                onViewModeChange={(viewMode) => this.handleViewChange(viewMode)}
                isMoveable={this.state.moveable}
                isSNI={this.props.bookStructure.is_sni}
            />
        </div>
    }
}

class BookPresentationMenu extends BookStickyMenu {

    componentDidMount() {
        this.doFoundation();
        ($('#app-menu') as any).foundation('hideAll');
        //($('#app-menu  [data-magellan]')as any).foundation('_destroy');
        //($('.vertical.menu.docs-toc.accordion-menu')as any).foundation('hideAll');
        //($('.vertical.menu.docs-toc.accordion-menu [data-magellan]')as any).foundation('_destroy');
    }


}
