import * as React from 'react';
import { BubbleLoader } from 'react-css-loaders';
import ReactTable from 'react-table'
import 'react-table/react-table.css'

import { app } from '@src/index';
import { __ } from '@src/translation';
import ViewOoFileHistoryRecord, { IViewOoFileHistoryRecord } from '@framework/view/media/ViewOoFileHistory';
import { Link } from 'react-router-dom';
import OoFileCrud from '@src/framework/crud/media/OoFileCrud';
import { IOoFileRecord } from '@src/framework/crud/media_pub/OoFileCrud';
import { permissionCache, ViewPermissionType } from '@src/framework/security/PermissionCache';

interface IFileVersionsProps  {
    ooFileId ?: number | null;
    allowRestoreVersion : boolean;
    onFileVersionRestored ?: (newFile: IOoFileRecord) => void;
}

interface IFileVersionsState  {
    loading : boolean;
    history ?: IViewOoFileHistoryRecord[];
    hasPerm : boolean;
}

export default class FileVersions extends React.Component<IFileVersionsProps, IFileVersionsState> {
    constructor (props: IFileVersionsProps) {
        super(props);
        if (props.allowRestoreVersion && !props.onFileVersionRestored) {
            throw new Error("FileVersions: failed assertion: props.allowRestoreVersion && !props.onFileVersionRestored ");
        }
        this.state = {loading: false, hasPerm: true}
    }

    componentDidMount() {
        this.asyncReload();
    }

    private asyncReload = async () => {
        this.setState({loading: true});
        try {
            const hasPerm = await permissionCache.hasPermission({
                view: {
                    viewInfoId: ViewOoFileHistoryRecord.VIEW_INFO_ID,
                    permissions: ViewPermissionType.R
                }
            })
            const history = hasPerm? await ViewOoFileHistoryRecord.list({
                    filter: { oo_file_id: this.props.ooFileId},
                    order_by: [ {name:'created', desc:true}  ]
                }) : undefined;

            this.setState({ history, hasPerm, loading: false});

        } catch (error) {
            app.showErrorFromJsonResult(error);
        }
    }

    private restoreVersion = async (sha1: string) => {
        if (confirm(__('Egész biztosan visszaállítja ezt a verziót?'))) {
            try {
                this.setState({loading: true});
                const crud = await new OoFileCrud({
                    id: this.props.ooFileId!, sha1
                }).put();
                if (this.props.onFileVersionRestored) {
                    this.props.onFileVersionRestored(crud.record);
                }
            } catch (error) {
                app.showErrorFromJsonResult(error);
            }
        }
    }

    render() {
        if (this.state.loading) {
            return <BubbleLoader />;
        } else if (!this.state.hasPerm) {
            return <p className="callout alert">
                {__("Nincs jogosultsága megnézni az állomány verziókat.")}
            </p>
        } else if (!this.props.ooFileId) {
            return <p className="callout warning">
                {__("Nincs kiválasztva állomány.")}
            </p>
        } else if (!this.state.history || this.state.history.length==0) {
            return <p className="callout secondary">
                {__("Nincs elérhető verzió történet. Lehetséges, hogy ez egy régi")}
                {__("állomány amit OnlyOffice-ból importált a rendszert. Ezeknél")}
                {__("az állományoknál előfordulhat, hogy nincs elérhető történet.")}
            </p>
        }

        let columns = [
            {Header: "Mikor", accessor: "created", style: {margin: "auto 0"}},
            {Header: __("Felhasználó"), accessor: "creator", style: {margin: "auto 0"}},
            {Header: __("Letöltés"), id: "link", filterable: false, style: {textAlign: "center"}, Cell: (props: any) => {
                return <Link style={{marginBottom: 0}} className="button expanded small" to={`/api/media/file/${props.original.sha1}/`}><i className="fa fa-cloud-download" /> {__("Letöltés")}</Link>;
            }},
            
        ];
        if (this.props.allowRestoreVersion)  {
            columns.push({Header: __("Visszaállítás"), id: "link", filterable: false, style: {textAlign: "center"}, Cell: props => {
                return <span style={{marginBottom: 0, cursor: "pointer"}} className="button expanded small alert" 
                    onClick={() => this.restoreVersion(props.original.sha1)}><i className="fa fa-recycle" /> {__("Visszaállítás")}</span>;
            }})
        }

        return  <ReactTable
            columns={columns}
            data={this.state.history}
            filterable={true}
            className="-striped -highlight"
            defaultFilterMethod={(filter, row, column) => row[filter.id].toLowerCase().indexOf(filter.value.toLowerCase()) != -1}
        />
    }
}