import { Server } from '../../server/Server';
import View , { registerViewClassProxy, ViewClassProxy } from '../View';
import { IListParams } from '@framework/crud/Crud';

export interface IViewUsrForumMemberRecord {
    /**
     * Belső Azonosító
     *
     * Belső Azonosító
     */
    id?: number;
    /**
     * Fórum azonosító
     *
     * Fórum azonosító
     */
    forum_id?: number|null;
    /**
     * Felhasználó azonosító
     *
     * Felhasználó azonosító
     */
    sec_user_id?: number|null;
    /**
     * Személy azonosító
     *
     * Személy azonosító
     */
    person_id?: number|null;
    /**
     * Teljes név
     *
     * A fórum tag teljes neve
     */
    fullname?: string|null;
    /**
     * Jogok
     *
     * A fórum tag jogai a fórumon belül
     */
    perms?: number[]|null;
}

/**
 *  usr.view_usr_forum_member - Fórum tagság
 */

export default class ViewUsrForumMember extends View<IViewUsrForumMemberRecord> {
    public static VIEW_INFO_ID = 2020050501;

    public static getSchemaNameForClass(): string { return 'usr'; }
    public static getViewNameForClass(): string { return 'view_usr_forum_member'; }
    public static getTableNameForClass(): string|null { return 'null'; }

    public static load: (id: number, aServer ?: Server) => Promise<ViewUsrForumMember>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<IViewUsrForumMemberRecord[]>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class ViewUsrForumMemberClassProxy extends ViewClassProxy<IViewUsrForumMemberRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const viewUsrForumMemberClassProxy = new ViewUsrForumMemberClassProxy(ViewUsrForumMember);
registerViewClassProxy(viewUsrForumMemberClassProxy);

