import * as React from 'react';

import{ IViewInfoRecord, ViewInfoCrudClassProxy, viewInfoCrudClassProxy } from '@src/framework/crud/meta/ViewInfoCrud';
import RecordEditor, { IFieldEditorProp } from '@framework/forms/recordeditor';
import { IFieldInfo } from '@src/framework/crud/Meta';
import { SecViewInfoPermListView } from '@src/component/meta/meta_sec_view_info_perm_listview';
import { __ } from '@src/translation';


export default class ViewMetaViewEditor extends RecordEditor<IViewInfoRecord> {
    public getCrudClassProxy() : ViewInfoCrudClassProxy { return viewInfoCrudClassProxy;  }

    /** Itt minden disabled, ez egy read-only táblázat. */
    protected getFieldEditorProps(fieldInfo: IFieldInfo) : IFieldEditorProp {
        return {fieldName: fieldInfo.field_name, disabled:true};
    }

    protected getBottomButtons(): JSX.Element[] {
        return [];
    }


    protected getDetailPanels() : any[] {
        let result = super.getDetailPanels();        
        if (this.state.rec_id) {
                result = result.concat(
                    <h4>{ __("View jogok") }</h4>,
                    <SecViewInfoPermListView
                        view_info_id={this.state.rec_id!}
                    />
                );  
        }
        return result;
    }
        

}