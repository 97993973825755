import './style.css';
import { AExerciseEngine } from '../../models/AExerciseEngine';
import { ExerciseBaseClass } from '@src/component/exercise/models/ExerciseBaseClass';
import { any } from 'prop-types';

export interface ColoringGameData extends ExerciseBaseClass {
    illustration: string,
    imageToFill: string,
    colorPalette: string[],
}

type childParent = {
    smallerLabel: Number;
    greaterLabel: Number;
}

type childParentOrgImg ={
    smallerLabel: Number;
    greaterLabel: Number;
}

export class ColoringGame extends AExerciseEngine {

    //private illustrationPath : string = "";
    private canvas: HTMLCanvasElement;
    private imagedata: any;

    initExercise(params: ExerciseParams): void {
        //setting up the game basics
        super.initExercise(params);
        //console.log(params);
        let exercise: ColoringGameData = params.exercise;
        console.log(params.exercise)
        // todo: do the visualization 
        this.root.classList.add("puzzle-image-area");

        if (!exercise || !exercise.imageToFill || exercise.imageToFill == "") return;
        this.setIllustration(params.exercise, this.root);

        let canvas = document.createElement("canvas");
        this.root.appendChild(canvas);
        this.canvas = canvas;

        let illImg: HTMLImageElement = this.root.appendChild(document.createElement("img"));
        illImg.classList.add("exe-img-no-zoom");
        illImg.onload = this.extractBinaryImage.bind(this);
        //illImg.onload = this.originalImage.bind(this);
        //illImg.src = "/" + exercise.illustration;
        illImg.src = "/" + exercise.imageToFill;


        console.log(exercise.imageToFill)
    }

    public getUserSolution(): UserSolution {
        throw new Error("Method not implemented.");
    }
    public receiveEvaluation(evaluated: Evaluated): void {
        throw new Error("Method not implemented.");
    }
    public showCorrectSolution(solution: any): void {
        throw new Error("Method not implemented.");
    }
    public isUserReady(): boolean {
        return true;
    }


    public extractBinaryImage(ev: any) {
        if (!this.canvas) return;
        let img = ev.target;
        this.canvas.width = img.width
        this.canvas.height = img.height
        let context = this.canvas.getContext('2d');
        let width = this.canvas.width;
        let height = this.canvas.height;

        if (!context) return;
        context.drawImage(img, 0, 0)

        let imagedata = context.getImageData(0, 0, width, height);
        //console.log("image  data - ", imagedata)

        for (let y = 0; y < height; y++) {

            let inpos = y * width * 4;
            let outpos = inpos;

            for (let x = 0; x < width; x++) {

                let r = imagedata.data[inpos++];
                let g = imagedata.data[inpos++];
                let b = imagedata.data[inpos++];
                let a = imagedata.data[inpos++];

                let grayScale = (0.30 * r + 0.59 * g + 0.11 * b);

                if (grayScale > 70) {
                    imagedata.data[outpos++] = 255;
                    imagedata.data[outpos++] = 255;
                    imagedata.data[outpos++] = 255;
                    imagedata.data[outpos++] = a;
                } else {
                    imagedata.data[outpos++] = 0;
                    imagedata.data[outpos++] = 0;
                    imagedata.data[outpos++] = 0;
                    imagedata.data[outpos++] = a;
                }
            }
        }
        context.putImageData(imagedata, 0, 0);
        this.imagedata = imagedata;
        let image = this.getConnectedComponents(imagedata);

    }
            
    public originalImage(ev: any){
        if (!this.canvas) return;
        let img = ev.target;
        this.canvas.width = img.width
        this.canvas.height = img.height
        let context = this.canvas.getContext('2d');
        let width = this.canvas.width;
        let height = this.canvas.height;

        if (!context) return;
        context.drawImage(img, 0, 0)

        let imagedata = context.getImageData(0, 0, width, height);
        console.log("original image  data - ", imagedata);
        //converting into 2d array for RGBA values:
        let pixels = imagedata.data;
        let w = imagedata.width;
        let h = imagedata.height;
        let l = w * h;
        let label = 0;
        let position = 0;
        let image: any[] = [];
        let row: any[] = [];
        let childParentOrgImg: childParentOrgImg[] = [];

        for (let i = 0; i < l; i++) {
            var r = pixels[i * 4];
            var g = pixels[i * 4 + 1];
            var b = pixels[i * 4 + 2];
            var a = pixels[i * 4 + 3];
            position++;
            label;
            //get the position of pixel
            let x = parseInt((i / w).toString(), 10);
            let y = i - x * w;

            if (y == 0 && i > 0) {
                let tmp = JSON.parse(JSON.stringify(row));
                image.push(tmp);
                row = [];
            }
            row.push({ r: r, g: g, b: b, a: a, label: label });
        }

        //again first pass labeling for ORIGINAL image. 
        //I checked all values r,g,b 
        //because it is colorful image and pixel to be a border r,g,b should all equal to 0 
        for (let i = 0; i < image.length; i++) {
            for (let j = 0; j < image[i].length; j++) {
                if (image[i][j].r == 0 && image[i][j].g == 0 && image[i][j].b == 0) {
                    continue;
                }
                if (image[i][j].r != 0 && image[i][j].g != 0 && image[i][j].b != 0 ) {//checks if it is border or not
                    if (j == 0 && i == 0) {
                        label++;
                        image[i][j].label = label;
                    } else if (j > 0 && image[i][j - 1].r == 0) {                       
                            if (i == 0 || image[i - 1][j].label == 0) {
                                label++;
                                image[i][j].label = label;
                            } else {
                                image[i][j].label = image[i - 1][j].label;
                            }
                    } else {
                        if (i > 0 && (image[i - 1][j].r != 0 && image[i - 1][j].g != 0 && image[i - 1][j].b != 0 )) {
                            if (j > 0 && image[i - 1][j].label > image[i][j - 1].label) {
                                image[i][j].label = image[i][j - 1].label;
                                childParentOrgImg.push({ smallerLabel: image[i][j - 1].label, greaterLabel: image[i - 1][j].label });
                            } else if (j == 0 || image[i - 1][j].label < image[i][j - 1].label) {
                                image[i][j].label = image[i - 1][j].label;
                                if(j>0)childParentOrgImg.push({ smallerLabel: image[i - 1][j].label, greaterLabel: image[i][j - 1].label });
                            } else if (image[i - 1][j].label == image[i][j - 1].label) {
                                image[i][j].label = image[i][j - 1].label;
                            }
                        } else {
                            image[i][j].label = image[i][j - 1].label;
                        }
                    }
                }
                //console.log("O.G.label value at ["+i+"]["+j+"] and RGB:  ", image[i][j].label, image[i][j].r,image[i][j].g,image[i][j].b)
            }
        }
        //console.log("original image in 2d image array: ",image);
        //console.log("this is row: ", row);
        let mapOfColorsOImg: Map<number, any[]> = new Map<number, any[]>();

        for(let i = 0; i < image.length; i++){
            for(let j = 0; j< image[i].length; j++){
                if(image[i][j].label == 0)
                    continue;

                image[i][j].label = this.getCLabelofOImg(image[i][j].label,childParentOrgImg);
                console.log("label at ["+ i +"]["+ j +"]: ",image[i][j].label)
            }
        }
    }

    getCLabelofOImg(currentLabelOImg: any, childParentOrgImg: any[]): number{
        for(let c = 0; c< childParentOrgImg.length; c++){
            if(childParentOrgImg[c].greaterLabel == currentLabelOImg){
                let newLabel = this.getCLabelofOImg(childParentOrgImg[c].smallerLabel, childParentOrgImg);
                childParentOrgImg[c].smallerLabel = newLabel;
                return newLabel;
            }
        }
        return currentLabelOImg;
    }

    /*todo: you have to call here the extraction of the regions:
      ColoringGame.extractBinaryImage() and ColoringGame.getConnectedComponents()
      based on this, the solution of the exercise will be a list of key-value pairs: [{key:1, value:[255,255,255]}, {key:2, value:[155,255,100]} ]
      here the key says which connected component, the value says what color*/
    public getConnectedComponents(imagedata: any): any {
        // todo: get the labeled image according to the description here: http://aishack.in/tutorials/labelling-connected-components-example/

        //console.log("get connected comp");
        let pixels = imagedata.data;
        let w = imagedata.width;
        let h = imagedata.height;
        let label = 0;
        let position = 0;
        let l = w * h;
        let arr: Array<number> = [];
        let image: any[] = [];
        let row: any[] = [];
        let label_replace: any[] = [];
        let smaller: Number;
        let greater: Number;
        let childParent: childParent[] = [];

        for (let i = 0; i < l; i++) {
            var r = pixels[i * 4];
            var g = pixels[i * 4 + 1];
            var b = pixels[i * 4 + 2];
            var a = pixels[i * 4 + 3];
            position++;
            label;
            //get the position of pixel
            let x = parseInt((i / w).toString(), 10);
            let y = i - x * w;

            if (y == 0 && i > 0) {
                let tmp = JSON.parse(JSON.stringify(row));
                image.push(tmp);
                row = [];
            }
            row.push({ r: r, g: g, b: b, a: a, position: position, label: label });
        }

        for (let i = 0; i < image.length; i++) {
            for (let j = 0; j < image[i].length; j++) {
                /*if(image[0][0].r != 0){
                    label++;
                    image[i][j].label = label;
                }*/
                if (image[i][j].r == 0) {
                    continue;
                }
                if (image[i][j].r == 255) {
                    /*
                    if(j == 0 && image[i][j].r == 255){
                        if(i == 0){
                            label++;
                            image[i][j].label = label;
                        }else{
                            image[i][j].label = image[i-1][j].label;
                        }
                    }
                    */
                    if (j == 0 && i == 0) {
                        label++;
                        image[i][j].label = label;
                    } else if (j > 0 && image[i][j - 1].r == 0) {                       
                            if (i == 0 || image[i - 1][j].label == 0) {
                                label++;
                                image[i][j].label = label;
                            } else {
                                image[i][j].label = image[i - 1][j].label;
                            }
                    } else {
                        if (i > 0 && image[i - 1][j].r == 255) {
                            if (j > 0 && image[i - 1][j].label > image[i][j - 1].label) {
                                image[i][j].label = image[i][j - 1].label;
                                childParent.push({ smallerLabel: image[i][j - 1].label, greaterLabel: image[i - 1][j].label });
                            } else if (j == 0 || image[i - 1][j].label < image[i][j - 1].label) {
                                image[i][j].label = image[i - 1][j].label;
                                if(j>0)childParent.push({ smallerLabel: image[i - 1][j].label, greaterLabel: image[i][j - 1].label });
                            } else if (image[i - 1][j].label == image[i][j - 1].label) {
                                image[i][j].label = image[i][j - 1].label;
                            }
                        } else {
                            image[i][j].label = image[i][j - 1].label;
                        }
                    }
                }
                //console.log("label value at ["+i+"]["+j+"] and Red:  ", image[i][j].label, image[i][j].r)
            }
        }
        let mapOfColors: Map<number, any[]> = new Map<number, any[]>();

        for (let i = 0; i < image.length; i++) {
            for (let j = 0; j < image[i].length; j++) {
                if (image[i][j].label == 0)
                    continue;

                image[i][j].label = this.getCLabel(image[i][j].label, childParent);
                console.log("label at [" + i + "][" + j + "]: ", image[i][j].label);
                let colorList: any[] = [];
                let tmp = mapOfColors.get(image[i][j].label);
                if (tmp) colorList = tmp;

                //todo: push the rgb color of the original image at the currect i,j coordinates:
                //colorList.push(originalimage[i][j])

                /* for(let c = 0; c < childParent.length; c++){
                     if(childParent[c].greaterLabel == image[i][j].label){
                         image[i][j].label = childParent[c].smallerLabel;
                     }
                 }*/
            }
        }

        return image;
    }

    getCLabel(currentLabel: any, childParent: any[]): number {
        for (let c = 0; c < childParent.length; c++) {
            if (childParent[c].greaterLabel == currentLabel) {
                let newLabel = this.getCLabel(childParent[c].smallerLabel, childParent);
                childParent[c].smallerLabel = newLabel;
                return newLabel;
            }
        }
        return currentLabel;
    }

    coloringRegion(coordinates: number[], color: string) {
        // todo: coloring a region with the given color according to  http://www.williammalone.com/articles/html5-canvas-javascript-paint-bucket-tool/
    }
}