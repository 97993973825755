import * as React from 'react';
import { Toggle, ToggleControl } from './Panel';
import { __ } from '@src/translation';

export type mediatype = {
    src: string,
    title: string
}

type mediaProps = {
    medias: mediatype[], 
    id: string
}

type mediaState = {
    title: string,
    isMuted: boolean,
    isPlaying: boolean,
    currTime: any,
    durTime: any,
    max: string,
    actMedia: number,
    intervalId: any,
    toggleIsOpened: boolean,
}

export class MediaPlayer extends React.Component<mediaProps, mediaState> {
    static defaultProps = {
        id: "tesztid"
    }

    constructor(props: any) {
        super(props);

        this.state = {
            title: "",
            isMuted: false,
            isPlaying: false,
            currTime: this.defaultTime,
            durTime: this.defaultTime,
            max: "0",
            actMedia: 0,
            intervalId: null,
            toggleIsOpened: false,
        }
    }

    defaultTime = "00:00";
    isAudio = false;
    audioElement: HTMLAudioElement;
    videoElement: HTMLVideoElement;

    componentDidMount() {
        this.audioElement = this.refs.audio as HTMLAudioElement;
        this.videoElement = this.refs.video as HTMLVideoElement;
        this.CheckIfAudio(this.state.actMedia);
        this.isAudio ? this.audioElement.src = this.props.medias[this.state.actMedia].src :  this.videoElement.src = this.props.medias[this.state.actMedia].src;
        this.setState({ title: this.props.medias[this.state.actMedia].title, actMedia: 0 });
    }

    CheckIfAudio(act : number){
        let oldAudio = this.isAudio;
        let sourceNameLength = this.props.medias[act].src.length;
        let extension = this.props.medias[act].src.substr(sourceNameLength-3,sourceNameLength);
        this.isAudio =(extension == "mp3");
        if(oldAudio !== this.isAudio){
            this.isAudio ? this.videoElement.pause() : this.audioElement.pause();
        }
    }

    componentWillUnmount() {
        clearInterval(this.state.intervalId);
    }

    LoadMedia(act: number) {
        this.CheckIfAudio(act);
        this.isAudio ? this.audioElement.src = this.props.medias[act].src : this.videoElement.src = this.props.medias[act].src;
        (this.refs.mediaslider as any).value = 0; 
    }

    Play() {
        if (this.state.isPlaying) {
            this.setState({ isPlaying: false });
            this.isAudio ? this.audioElement.pause() : this.videoElement.pause();
            clearInterval(this.state.intervalId);
        } else {
            this.isAudio ? this.audioElement.play() : this.videoElement.play();
            var interval = setInterval(this.UpdateMediaSlider.bind(this), 1000);
            this.setState({ isPlaying: true, intervalId: interval });
        }
    }

    Stop() {
        this.setState({ isPlaying: false, currTime: this.defaultTime });
        this.isAudio ? this.audioElement.pause() : this.videoElement.pause();
        this.isAudio ? this.audioElement.currentTime = 0 : this.videoElement.currentTime = 0;
        (this.refs.mediaslider as any).value = 0;
        clearInterval(this.state.intervalId);
    }

    SeekMedia() {
        let value = (this.refs.mediaslider as any).value;
        this.isAudio ? this.audioElement.currentTime = value : this.videoElement.currentTime = value;
        this.setState({ currTime: this.ConvertTime((this.refs.mediaslider as any).value) });
    }

    SetVolume() {
        let value = (this.refs.volumeslider! as any).value;
        this.audioElement.volume = value; this.videoElement.volume = value;
        this.audioElement.muted = false; this.videoElement.muted = false;
        this.setState({ isMuted: false });
    }

    Mute() {
        if (this.audioElement.muted) {
            this.audioElement.muted = false; this.videoElement.muted = false;
            this.setState({ isMuted: false });
        } else {
            this.audioElement.muted = true; this.videoElement.muted = true;
            this.setState({ isMuted: true });
        }
    }

    Previous() {
        let actNum = this.state.actMedia;
        if (actNum > 0) {
            this.LoadMedia(actNum - 1);
            this.setState({ actMedia: actNum - 1, title: this.props.medias[actNum - 1].title }); 
        }
        else if (actNum == 0) {
            this.LoadMedia(this.props.medias.length - 1);
            this.setState({ actMedia: (this.props.medias.length - 1), title: this.props.medias[this.props.medias.length - 1].title });
        }
        if (this.state.isPlaying) {
            this.isAudio ? this.audioElement.play() : this.videoElement.play();
        }
        this.setState({ currTime: this.defaultTime });
    }

    Next() {
        let actNum = this.state.actMedia;
        if (actNum < this.props.medias.length - 1) {
            this.LoadMedia(actNum + 1);
            this.setState({ actMedia: actNum + 1, title: this.props.medias[actNum + 1].title });
        }
        else if (actNum == this.props.medias.length - 1) {
            this.LoadMedia(0);
            this.setState({ actMedia: 0, title: this.props.medias[0].title });
        }
        if (this.state.isPlaying) {
            this.isAudio ? this.audioElement.play() : this.videoElement.play();
        }
        this.setState({ currTime: this.defaultTime });
    }

    UpdateMediaSlider() {
        let time = Math.round(this.isAudio ? this.audioElement.currentTime : this.videoElement.currentTime);
        (this.refs.mediaslider as any).value = time;
        this.setState({ currTime: this.ConvertTime(time)});
    }

    ConvertTime(secs: any) {
        let min = Math.floor(secs / 60);
        let sec = secs % 60;
        let mintext = min < 10 ? "0" + min : min;
        let sectext = sec < 10 ? "0" + sec : sec;
        return (mintext + ":" + sectext);
    }

    LoadedDataAudio() {
        this.setState({ durTime: this.ConvertTime(Math.round(this.audioElement.duration)), max: Math.round(this.audioElement.duration).toString() });
    }

    LoadedDataVideo() {
        this.setState({ durTime: this.ConvertTime(Math.round(this.videoElement.duration)), max: Math.round(this.videoElement.duration).toString() });
    }
    
    Fullscreen(){
        this.videoElement.requestFullscreen();
    }

    selectMedia(num: number) {
        this.LoadMedia(num);
        this.setState({ actMedia: num, title: this.props.medias[num].title, currTime: this.defaultTime });
        if (this.state.isPlaying) {
            this.isAudio ? this.audioElement.play() : this.videoElement.play();
        }
    }

    Toggle() {
        this.state.toggleIsOpened ? this.setState({ toggleIsOpened: false }) : this.setState({ toggleIsOpened: true });
    }

    render() {
        let multipleMedias = (this.props.medias.length > 1);
        return <div className="media-container">
            <span className="show-for-sr">{__("Média lejátszó")}</span>
            <div className="title">
                <i className={"title-icon " + (this.isAudio? "fas fa-music":"fas fa-video")}></i>
                <h3 className="media-title">{this.state.title}</h3>
                {multipleMedias ? <div className="nums">
                    <span>{this.state.actMedia + 1 + "/" + this.props.medias.length}</span>
                    <ToggleControl id={this.props.id}><i className="fas fa-list-ul" onClick={() => this.Toggle()}></i>{
                        this.state.toggleIsOpened ? <span className="show-for-sr">{__("Kattints a lejátszási lista összecsukásához!")}</span> :
                            <span className="show-for-sr">{__("Kattints a lejátszási lista lenyitásához!")}</span>
                    }</ToggleControl>
                </div> : ""}

            </div>

            {multipleMedias ? <Toggle id={this.props.id} className="media-list-toggle">
                <ol>
                    {
                        this.props.medias.map((item, num) => {
                            return <a onClick={() => this.selectMedia(num)}><li key={"i-" + num}>{item.title ? item.title : __("Nincs cím megadva")}</li></a>
                        })
                    }
                </ol>
            </Toggle> : ""}

            <div className={(!this.isAudio) ? "main-media" : "hidden"}>
                <button className={(!this.state.isPlaying) ? "hower-play" :"hidden"}>
                        <i className="fa fa-play fa-5x"></i>
                </button>
                <video ref="video" className={this.isAudio ? "hidden" : ""} onLoadedData ={() => this.LoadedDataVideo()} onClick = {() => this.Play()}></video> 
                <audio ref="audio" className={!this.isAudio ? "hidden" : ""} onLoadedData ={() => this.LoadedDataAudio()}></audio>
            </div>
            
            <div className="media-slider-container">
                <span className="current-time" title={__("mediaanyag aktuális ideje")}>{this.state.currTime}</span>
                <input ref="mediaslider" className="media-slider" type="range" min="0" max={this.state.max} step="1" defaultValue="0" title={__("mediaanyag")} onChange={() => this.SeekMedia()}></input>
                <span className="duration-time" title={__("mediaanyag hossza")}>{this.state.durTime}</span>
            </div>                  

            <div className="controller-panel">
                <div className="button-container">
                    {multipleMedias ? <button className="previous" onClick={() => this.Previous()}><i className="fas fa-backward" /><label className="show-for-sr">{__("Előző médiaanyag")}</label></button> : ""}
                    <button className="play" onClick={() => this.Play()}><i className={"fa " + (!this.state.isPlaying ? "fa-play" : "fa-pause")} />{!this.state.isPlaying ? <label className="show-for-sr">{__("Lejátszás")}</label> : <label className="show-for-sr">{__("Szüneteltetés")}</label>}</button>
                    <button className="stop" onClick={() => this.Stop()}><i className="fa fa-stop" /><label className="show-for-sr">{__("Megállítás")}</label></button>
                    {multipleMedias ? <button className="next" onClick={() => this.Next()}><i className="fas fa-forward" /><label className="show-for-sr">{__("Következő médiaanyag")}</label></button> : ""}
                </div>
                <div className="volume-slider-container">
                    <button className="audio-icon" onClick={() => this.Mute()}><i className={"fas " + (this.state.isMuted ? "fa-volume-mute" : "fa-volume-up")} />{this.state.isMuted ? <label className="show-for-sr">{__("Némítva. Hang bekapcsolása")}</label> : <label className="show-for-sr">{__("Némítás")}</label>}</button>
                    <input ref="volumeslider" className="media-volume-slider" type="range" min="0" max="1" step="0.01" defaultValue="0.5" onChange={this.SetVolume.bind(this)} title={__("Hangerő szabályzó")}></input>
                    <button className={(!this.isAudio ? "fullscreen-icon" : "hidden")} onClick={this.Fullscreen.bind(this)}><i className="fas fa-expand"/></button>
                </div>
            </div>
            <span className="show-for-sr">{__("Médialejátszó vége")}</span>
        </div>
    }
}