import { Server } from '../../server/Server';
import Crud , { registerCrudClassProxy, IAsTextResult, IListParams, CrudClassProxy, ISearchTextParams, ISearchTextResult } from '../Crud';

export interface IClaimLinkRecord {
    /**
     * Globálisan egyedi azonosító.
     * 
     * Ha új rekordot akarsz létrehozni put() metódussal, akkor hagyd üresen.
     * upsert() hívásnál figyelmen kívül van hagyva az értéke.
     *
     */
    id ?: number;
    /**
     * Rekord létrehozás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel létrehozásakor.
     */
    creation_time?: string /*timestamp?*/;
    /**
     * Ki hozta létre
     *
     * NOT NULL -> sys.sec_user ON DELETE noaction
     *
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozó felhasználóra hivatkozik.
     */
    creation_user_id?: number;
    /**
     * Melyik session-ből jött létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozáshoz használt munkamenetre hivatkozik.
     */
    creation_session_id?: number|null;
    /**
     * Utolsó módosítás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel módosításakor.
     */
    modification_time?: string /*timestamp?*/|null;
    /**
     * Ki módosította utoljára
     *
     * -> sys.sec_user ON DELETE noaction
     *
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosító felhasználóra hivatkozik.
     */
    modification_user_id?: number|null;
    /**
     * Melyik session-ből módosították utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosításhoz használt munkamenetre hivatkozik.
     */
    modification_session_id?: number|null;
    /**
     * Aktív
     *
     * Logikai törléshez használt mező.
     */
    is_active?: boolean;
    /**
     * Igény
     *
     * NOT NULL -> ticket.claim ON DELETE cascade
     *
     */
    claim_id?: number;
    /**
     * URL
     */
    url?: string;
    /**
     * Leírás
     */
    description?: string|null;
}

/**
 *  ticket.claim_link - Igény link
 *
 *  Az igényhez csatolt link/url
 */

export default class ClaimLinkCrud extends Crud<IClaimLinkRecord> {
    public static TABLE_INFO_ID = 3516666290;
    public static getSchemaNameForClass() { return 'ticket'; }
    public static getTableNameForClass() { return 'claim_link'; }

    public static load: (id: number, aServer ?: Server) => Promise<ClaimLinkCrud>;
    public static asText: (id: number, includeRecord : boolean, aServer ?: Server) => Promise<IAsTextResult<IClaimLinkRecord>>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<IClaimLinkRecord[]>;
    public static searchText: (params: ISearchTextParams, aServer ?: Server) => Promise<ISearchTextResult<IClaimLinkRecord>[]>;
    public static deleteById: (id: number, aServer ?: Server) => Promise<IClaimLinkRecord>;
    public static unDeleteById: (id: number, aServer ?: Server) => Promise<IClaimLinkRecord>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class ClaimLinkCrudClassProxy extends CrudClassProxy<IClaimLinkRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const claimLinkCrudClassProxy = new ClaimLinkCrudClassProxy(ClaimLinkCrud);
registerCrudClassProxy(claimLinkCrudClassProxy);
