import * as React from 'react';
import { Link, match } from 'react-router-dom';
import { ExerciseSeriesBreadcrumbs } from '@src/component/Breadcrumbs';
import { app } from '../../..';
import * as ExerciseServer from '@src/component/exercise/ExerciseServer';
import ExerciseSeriesCrud, { IExerciseSeriesRecord } from '@src/framework/crud/exc/ExerciseSeriesCrud';
import { me, hasGroup, Groups, hasAnyGroup } from '@framework/server/Auth';
import ExerciseSeriesItemCrud, { IExerciseSeriesItemRecord } from '@src/framework/crud/exc/ExerciseSeriesItemCrud';
import ViewSelectionMode, { IViewSelectionModeRecord } from '@src/framework/view/exc/ViewSelectionMode';
import ViewDisplayMode, { IViewDisplayModeRecord } from '@src/framework/view/exc/ViewDisplayMode';
import ExerciseSeriesQtyPerPointCrud, { IExerciseSeriesQtyPerPointRecord } from '@src/framework/crud/exc/ExerciseSeriesQtyPerPointCrud';
import ExerciseList from '@src/component/exercise/Viewer/ExerciseList';
import { Dialog, confirmDialog } from '@src/component/Dialog';
import { PATH_EXERCISE_VIEW, PATH_EXERCISE_SERIES_EDIT, PATH_ADAPTIVE_EXERCISE_SERIES_EDIT, getPath } from '@src/Routes';
import { fetchKeywords, setKeywords, MetaKeywordAssignerPlugin } from '@component/meta/meta_keyword_assigner_plugin';
import { history } from '@src/index';
import ViewExerciseSeriesItem from '@src/framework/view/exc/ViewExerciseSeriesItem';
import CrudSelect from '@framework/forms//crudselect';
import { subjectCrudClassProxy } from '@framework/crud/doc/SubjectCrud';
import { gradeCrudClassProxy } from '@framework/crud/doc/GradeCrud';
import LevelSelectComponent from '@src/framework/forms/level_select';
import PermissionPage from '@src/framework/PermissionPage';
import { CrudPermissionType, PermissionCache } from '@src/framework/security/PermissionCache';
import { LIBRARY_OFI_OFFICIAL_ID, LIBRARY_PERSONAL_ID, WF_TYPE_OKOSFELADATSOR_ID, LANG_ID_HU } from '@src/Const';
import ViewExcExercise, { IViewExcExerciseRecord } from '@src/framework/view/exc/ViewExcExercise';
import { getWfStateTitle, getWfStateText } from '@src/component/wf/wf_util';
import { HTMLTextarea } from '@src/component/HtmlTextarea';
import ViewExcSeries from '@src/framework/view/exc/ViewExcSeries';
import { getIconOfEngineByDisplayName } from '../models/ExerciseTypeConverter';
import ButtonGroup, { ButtonSize, ButtonAlign } from '@src/component/ui/ButtonGroup';
import { WorkflowDialog } from '@src/component/wf/WorkflowDialog';
import AccessDeniedPage from '@src/framework/pages/AccessDeniedPage';
import { __, getLanguageId } from '@src/translation';
import { config } from '@src/framework/server/Server';
import { exerciseModule } from '@src/module/exerciseModule/exerciseModule';
import ExcSelectionModeTranslationCrud, { IExcSelectionModeTranslationRecord } from '@src/framework/crud/exc/ExcSelectionModeTranslationCrud';
import ExcDisplayModeTranslationCrud, { IExcDisplayModeTranslationRecord, excDisplayModeTranslationCrudClassProxy } from '@src/framework/crud/exc/ExcDisplayModeTranslationCrud';
import { langTranslationCrudClassProxy } from '@src/framework/crud/sys/LangTranslationCrud';

export type ExerciseSeriesEditorState = {
    exerciseSeriesRecord: IExerciseSeriesRecord, // Exercise record in database    
    originalExerciseSeries?: any,          // original exercise, disable saving if nothing change  
    selectedExercises?: ExerciseItem[],
    selectedExercise?: IViewExcExerciseRecord,
    selectionModes: any[],
    oldlibId?: number,
    displayModes: any[],
    isDataChanged: boolean,
    pointCountPairList: PointCountPair[],
    dialogOpen: boolean,
    keywords: string[],
    minPoints: number,
    canEdit: boolean,
    workflowDialogOpen: boolean,
    workflowStateName?: string
}

export type ExerciseItem = {
    id: number | undefined,
    numberOfPoints: number,
    exerciseRecord: IViewExcExerciseRecord,
    engineImg: string,
    index: number,
    wf_station_name: string | null,
    wf_station_style: any,
    wf_substation_name: string | null,
    wf_status_text: any
}

export type PointCountPair = {
    id: number | undefined,
    points: number,
    count: number
}

export type ExerciseSeriesEditorProps = {
    match: match<{ exerciseSeriesId?: string }>
}
var OFIMEMBERSHIP: boolean;
export default class ExerciseSeriesEditor extends React.Component<ExerciseSeriesEditorProps, ExerciseSeriesEditorState> {

    constructor(props: any) {
        super(props);
        OFIMEMBERSHIP = hasGroup(me!, Groups.OFIEditor);
        this.state = {
            exerciseSeriesRecord: {},
            selectionModes: [],
            displayModes: [],
            isDataChanged: false,
            pointCountPairList: [],
            dialogOpen: false,
            keywords: [],
            minPoints: 1,
            canEdit: false,
            workflowDialogOpen: false
        };
        this.reloadAsync();
    }

    async reloadAsync() {
        try {
            const selectionModes = await ExcSelectionModeTranslationCrud.list({ filter: { lang_id: getLanguageId() } });
            const displayModes = await ExcDisplayModeTranslationCrud.list({ filter: { lang_id: getLanguageId() } });

            // TODO ezek lehetnek privált változó, mert szinte sosem változik
            this.setState({

                selectionModes: selectionModes,
                displayModes: displayModes
            });
            this.reloadExerciseSeriesAsync();
        } catch (error) {
            app.showErrorFromJsonResult(error);
        }
    }

    async reloadExerciseSeriesAsync() {
        var exerciseSeriesRecord: any = {};
        var selectedExercises: ExerciseItem[] = [];
        let keywords: string[] = [];

        try {

            let canEdit = false;
            // if there is an exercise to load
            if (this.props.match.params.exerciseSeriesId) {
                exerciseSeriesRecord = await ExerciseSeriesCrud.load((parseInt(this.props.match.params.exerciseSeriesId))).then((crud) => crud.record);;

                if (!exerciseSeriesRecord) {
                    app.showError(__("Nem sikerült betölteni a feladatot"), __("Hibás azonosító"));
                    return;
                }
                else if (exerciseSeriesRecord.is_adaptive) {
                    let tempurl = PATH_ADAPTIVE_EXERCISE_SERIES_EDIT + "/" + exerciseSeriesRecord.id + "/"
                    history.replace(tempurl)
                }
                else {
                    keywords = await fetchKeywords(ExerciseSeriesCrud.TABLE_INFO_ID, exerciseSeriesRecord.id);
                }
                // ExerciseSeriesItemCrud

                var exerciseView = (await ViewExcSeries.load(exerciseSeriesRecord.id!)).record;
                let curr_station_id = exerciseView.wf_station_id;
                let workflowStateName = exerciseView.wf_station_name || undefined;

                canEdit = await PermissionCache.hasWfEditPermission(WF_TYPE_OKOSFELADATSOR_ID, curr_station_id, me, exerciseSeriesRecord);


                var exerciseSeriesItems = await ViewExerciseSeriesItem.list({ filter: { exercise_series_id: exerciseSeriesRecord.id, is_active: true }, order_by: "no" });
                const exerciseIds = exerciseSeriesItems.map(i => i.exercise_id!);
                const exercises = await ViewExcExercise.list({ filter: { id: exerciseIds } });

                if (exerciseSeriesItems) {

                    for (var exItem of exerciseSeriesItems) {
                        if (!exItem.exercise_id) continue;
                        var curr_ex = exercises.find(e => e.id == exItem.exercise_id);
                        if (!curr_ex) continue;
                        let img = await getIconOfEngineByDisplayName(curr_ex.engine_name!, false, false)
                        selectedExercises.push({
                            id: exItem.id,
                            exerciseRecord: curr_ex,
                            engineImg: img,
                            numberOfPoints: exItem.points ? exItem.points : 0,
                            index: exItem.no ? exItem.no : 0,
                            wf_station_name: exItem.wf_station_name || null,
                            wf_station_style: exItem.wf_station_style || {},
                            wf_substation_name: exItem.wf_substation_name || null,
                            wf_status_text: exItem.wf_status_text || null
                        })
                    }
                }

                this.setState({
                    exerciseSeriesRecord,
                    selectedExercises,
                    keywords,
                    oldlibId: exerciseSeriesRecord.library_id,
                    canEdit: canEdit,
                    workflowStateName
                });

                // loading the pointCount pairs
                if (exerciseSeriesRecord.exc_selection_mode_id == 4) {
                    var pointCountPairList: PointCountPair[] = [];
                    var dbPointCountPairList = await ExerciseSeriesQtyPerPointCrud.list({ filter: { exercise_series_id: exerciseSeriesRecord.id, is_active: true }, order_by: "points" });
                    for (var pointItem of dbPointCountPairList) {
                        var newItem: PointCountPair = {
                            id: pointItem.id,
                            points: pointItem.points ? pointItem.points : 0,
                            count: pointItem.qty ? pointItem.qty : 0
                        }
                        pointCountPairList.push(newItem);
                    }
                    this.setState({ pointCountPairList });
                }

            } else {
                // if it is a new exercise and there are no examples              
                this.onStartNewExerciseSeries();
            }
        } catch (error) {
            app.showErrorFromJsonResult(error);
        }
    }

    async onSave(saveAsNew: boolean) {

        if (saveAsNew) {

            if (!await confirmDialog(__("Megerősítés"), __("Biztos, hogy másolatként szeretné menteni a feladatsort?"))) return;
        }

        try {
            if (!this.state.exerciseSeriesRecord.title) {
                app.showError(__("Hiányzó adat!"), __("Kérem adja meg a feladat címét!"));
                return;
            }
            if (this.state.exerciseSeriesRecord.success_limit_percent && (this.state.exerciseSeriesRecord.success_limit_percent > 100 || this.state.exerciseSeriesRecord.success_limit_percent < 0)) {
                app.showError(__("Hiba"), __("A sikeresség szintjét 0-100% ig lehet meghatározni!"));
                return;
            }
            if (!this.state.exerciseSeriesRecord.exc_selection_mode_id) {
                app.showError(__("Hiányzó adat!"), __("Kérem adja meg a feladatok kiválasztásának módját!"));
                return;
            }
            if (!this.state.selectedExercises || this.state.selectedExercises.length < 1) {
                app.showError(__("Hiányzó adat!"), __("Legalább egy feladat szükséges a mentéshez!"));
                return;
            }
            if ((this.state.exerciseSeriesRecord.exc_selection_mode_id == 2 && this.state.exerciseSeriesRecord.exc_count && this.state.exerciseSeriesRecord.exc_count > this.state.selectedExercises.length)) {
                app.showError(__("Hiba!"), __("Nincs ennyi feladat a listádban!"));
                return;
            }
            if (!this.state.keywords || this.state.keywords.length < 1) {
                app.showError(__("Hiányzó adat!"), __("Kérem adjon meg kulcsszavakat!"));
                return;
            }
            if ((this.state.exerciseSeriesRecord.exc_selection_mode_id == 4 && this.state.pointCountPairList.length < 1)
                || (this.state.exerciseSeriesRecord.exc_selection_mode_id == 2 && !this.state.exerciseSeriesRecord.exc_count)
                || (this.state.exerciseSeriesRecord.exc_selection_mode_id == 3 && !this.state.exerciseSeriesRecord.minpoints)) {
                app.showError(__("Hiányzó adat!"), __("A kiválasztás módjának megfelelő adatok megadása szükséges!"));
                return;
            }
            if (this.state.exerciseSeriesRecord.exc_selection_mode_id == 4 && this.state.pointCountPairList.length > 0) {

                for (var pointcountItem of this.state.pointCountPairList) {

                    let currentPoint = pointcountItem.points;
                    let pointCounter = 0;
                    for (var innerpointItem of this.state.pointCountPairList) {
                        if (currentPoint == innerpointItem.points) {
                            pointCounter++;
                        }
                    }
                    if (pointCounter > 1) {
                        app.showError(__("Hiba"), __("Egy súly csak egyszer adható meg a listában!"));
                        return;
                    }


                    let currentPointsCount = 0;
                    for (var selectedExeItem of this.state.selectedExercises) {
                        if (selectedExeItem.numberOfPoints == pointcountItem.points) {
                            currentPointsCount++;
                        }
                    }
                    if (currentPointsCount < pointcountItem.count) {
                        app.showError(__("Hiba"), __("Nincs ennyi súlyú feladat kiválogatva"));
                        return;
                    }
                }
            }
            if (this.state.exerciseSeriesRecord.exc_selection_mode_id == 3 && this.state.selectedExercises.length > 0 && this.state.minPoints) {
                let exercisesAboveMinpoints = 0;
                let sumExercisePoints = 0;
                for (var selectedExeItem of this.state.selectedExercises) {
                    if (selectedExeItem.numberOfPoints > this.state.minPoints) {
                        exercisesAboveMinpoints++;
                    }
                    sumExercisePoints += selectedExeItem.numberOfPoints
                }

                if (this.state.exerciseSeriesRecord.minpoints && this.state.exerciseSeriesRecord.minpoints > sumExercisePoints) {
                    app.showError(__("Hiba"), __("A megadott súly nagyobb mint amennyivel a feladatok rendelkeznek a listában"));
                    return;
                }
                if (exercisesAboveMinpoints == this.state.selectedExercises.length) {
                    app.showError(__("Hiba"), __("Minden feladat súlya nagyobb mint a megadott"));
                    return;
                }
            }

            /*const libraries = await LibraryCrud.list({});
            if (libraries.length != 1) {
                throw new Error("Cannot guess library_id");
            }*/

            var exerciseToSave: IExerciseSeriesRecord = {
                ...this.state.exerciseSeriesRecord,
                site_id: config.mainServer.siteId!,
                is_adaptive: false,
                library_id: this.state.exerciseSeriesRecord.library_id,
                level: (this.state.exerciseSeriesRecord.level ? Number(this.state.exerciseSeriesRecord.level) : undefined),
                exc_count: this.state.exerciseSeriesRecord.exc_selection_mode_id == 2 ? this.state.exerciseSeriesRecord.exc_count : null,
                minpoints: this.state.exerciseSeriesRecord.exc_selection_mode_id == 3 ? this.state.exerciseSeriesRecord.minpoints : null,
                grade_id: this.state.exerciseSeriesRecord.grade_id ? Number(this.state.exerciseSeriesRecord.grade_id) : null,
                subject_id: this.state.exerciseSeriesRecord.subject_id ? Number(this.state.exerciseSeriesRecord.subject_id) : null,
                lang_id: this.state.exerciseSeriesRecord.lang_id ? this.state.exerciseSeriesRecord.lang_id : LANG_ID_HU
            };

            delete exerciseToSave["is_active"];
            exerciseToSave.owner_id = undefined;

            if (saveAsNew) {

                exerciseToSave.id = undefined;
            }

            //-------- saving the exercise series --------
            const newExerciseSeriesOnServer = await ExerciseServer.putExerciseSeriesRecord(exerciseToSave);

            // ------ save the exercise_series_items --------
            // delete the missing items from the db
            var prevExerciseList: IExerciseSeriesItemRecord[] = await ExerciseSeriesItemCrud.list({ filter: { exercise_series_id: newExerciseSeriesOnServer.id, is_active: true } });

            for (var idx = 0; idx < prevExerciseList.length; idx++) {
                var existingElement = this.state.selectedExercises.filter((x: ExerciseItem) => x.id == prevExerciseList[idx].id);
                if (!existingElement || existingElement.length == 0) {
                    const crud = new ExerciseSeriesItemCrud(prevExerciseList[idx] as any);
                    const response = await crud.del();
                }
            }
            for (var i = 0; i < this.state.selectedExercises.length; i++) {
                // check if the item exists already
                var item: ExerciseItem = this.state.selectedExercises[i];
                var prevExItem = prevExerciseList.filter((exItem: IExerciseSeriesItemRecord) => exItem.id == item.id);

                const exercise_series_item_id = (!saveAsNew && prevExItem && prevExItem.length == 1 ? prevExItem[0].id : undefined);
                var newExerciseSeriesItem: IExerciseSeriesItemRecord = {
                    id: exercise_series_item_id,
                    exercise_series_id: exercise_series_item_id ? undefined : newExerciseSeriesOnServer.id,
                    exercise_id: item.exerciseRecord.id,
                    no: i,
                    points: item.numberOfPoints
                }
                await ExerciseServer.putExerciseSeriesItemRecord(newExerciseSeriesItem);
            }
            var curPointCountPairList: PointCountPair[] = [];

            // -------- saving the pointCount pairs --------
            if (this.state.exerciseSeriesRecord.exc_selection_mode_id == 4) {

                // get the original list from the db and delete the missing elements
                var oldPointCountPairList = await ExerciseSeriesQtyPerPointCrud.list({ filter: { exercise_series_id: newExerciseSeriesOnServer.id, is_active: true } });
                for (var idx = 0; idx < oldPointCountPairList.length; idx++) {
                    var existingPElement = this.state.pointCountPairList.filter((x: PointCountPair) => x.id == oldPointCountPairList[idx].id);

                    if (!existingPElement || existingPElement.length == 0) {
                        const crud = new ExerciseSeriesQtyPerPointCrud(oldPointCountPairList[idx] as any);
                        const response = await crud.del();
                    }
                }
                // todo: undelete newly created items, get their old id-s and use put

                for (var pointItem of this.state.pointCountPairList) {
                    var prev_pq_item_list = oldPointCountPairList.filter((curr_item: IExerciseSeriesQtyPerPointRecord) => curr_item.id == pointItem.id);
                    let prev_pq_item = (prev_pq_item_list && prev_pq_item_list.length > 0) ? prev_pq_item_list[0] : undefined;
                    let qty_per_point_id = (!saveAsNew && prev_pq_item) ? prev_pq_item.id : undefined;
                    // only save the element if something has changed
                    if (saveAsNew || !prev_pq_item || pointItem.points != prev_pq_item.points || pointItem.count != prev_pq_item.qty) {
                        var newItem: IExerciseSeriesQtyPerPointRecord = {
                            id: qty_per_point_id,
                            exercise_series_id: newExerciseSeriesOnServer.id,
                            points: pointItem.points,
                            qty: pointItem.count,
                            is_active: true
                        };
                        const crud = new ExerciseSeriesQtyPerPointCrud(newItem as any);
                        const response = await crud.upsert(["exercise_series_id", "points"]);
                        pointItem.id = newItem.id;
                    }
                    curPointCountPairList.push(pointItem);
                }
            } else {
                var oldPointCountPairList = await ExerciseSeriesQtyPerPointCrud.list({ filter: { exercise_series_id: newExerciseSeriesOnServer.id } });
                if (oldPointCountPairList) {
                    for (var idx = 0; idx < oldPointCountPairList.length; idx++) {
                        const crud = new ExerciseSeriesQtyPerPointCrud(oldPointCountPairList[idx] as any);
                        const response = await crud.del();
                    }
                }
            }

            await setKeywords(
                ExerciseSeriesCrud.TABLE_INFO_ID, newExerciseSeriesOnServer.id!,
                this.state.keywords
            );
            this.setState({
                exerciseSeriesRecord: {
                    ...this.state.exerciseSeriesRecord, id: newExerciseSeriesOnServer.id, creation_user_id: newExerciseSeriesOnServer.creation_user_id,
                    grade_id: Number(this.state.exerciseSeriesRecord.grade_id),
                    subject_id: Number(this.state.exerciseSeriesRecord.subject_id),
                },
                originalExerciseSeries: this.state.exerciseSeriesRecord,
                isDataChanged: false,
                pointCountPairList: curPointCountPairList
            });
            if (!saveAsNew) {
                let tempurl = getPath({PATH_EXERCISE_SERIES_EDIT}, exerciseModule) + "/" + newExerciseSeriesOnServer.id + "/"
                history.replace(tempurl)
                app.showSuccess(__("Feladatsor elmentve"), __("Feladatsor sikeresen elmentve"));
            } else {
                let tempurl = getPath({PATH_EXERCISE_SERIES_EDIT}, exerciseModule) + "/" + newExerciseSeriesOnServer.id + "/"
                history.replace(tempurl)
                app.showSuccess(__("Feladatsor másolatként elmentve"), __("Feladatsor sikeresen elmentve másolatként"));
            }
        } catch (e) {
            app.showErrorFromJsonResult(e);
            app.showError(__("Nem sikerült lementeni a Feladatsort"), e);
            console.log(e);
        }
    }

    async onStartNewExerciseSeries() {

        var newExSeries: IExerciseSeriesRecord = this.getEmptyExercise();
        newExSeries.success_limit_percent = 50;
        newExSeries.can_interrupt = true;
        this.setState({
            exerciseSeriesRecord: newExSeries,
            originalExerciseSeries: newExSeries,
            selectedExercises: [],
            oldlibId: undefined,
            canEdit: true,
        });
    }

    render() {
        const breadcrumbs = <ExerciseSeriesBreadcrumbs links={[
            { name: this.state.exerciseSeriesRecord ? this.state.exerciseSeriesRecord.title : <span style={{ color: "red" }}>{__("Betöltés...")}</span> }
        ]} />;

        if (!this.state.exerciseSeriesRecord || !me || (hasAnyGroup(me, [Groups.Student, Groups.TestStudent]) && this.state.exerciseSeriesRecord.owner_id && this.state.exerciseSeriesRecord.owner_id != me.id)) {
            return AccessDeniedPage();
        }

        const enableLang = exerciseModule.getConfig().enableLangSelector;

        return (<PermissionPage requirements={{
            crud: { tableInfoId: ExerciseSeriesCrud.TABLE_INFO_ID, permissions: CrudPermissionType.U }
        }}>
            <div className="row expanded">
                <WorkflowDialog
                    open={this.state.workflowDialogOpen}
                    displayName={__("Feladat")}
                    tableInfoId={ExerciseSeriesCrud.TABLE_INFO_ID}
                    recId={this.state.exerciseSeriesRecord.id!}
                    onClose={() => this.setState({ workflowDialogOpen: false })}
                    onTransition={this.reloadAsync.bind(this)}
                    autoPublishOnClosedStation={true}
                />
                <div className="column shrink">
                    {breadcrumbs}
                </div>

                <div className="small-12 column clearfix">
                    <div className="medium-12 column">
                        <div className="row">
                            <div className="small-12 column">
                                <h4>{__("Feladatsor szerkesztése")}</h4>
                            </div>
                            <div className="small-12 column">
                                <ButtonGroup size={ButtonSize.Normal} align={ButtonAlign.Expanded}>
                                    {
                                        this.state.exerciseSeriesRecord.id && this.state.workflowStateName &&
                                        <label className="label" style={{ alignSelf: "center", marginRight: "0.5rem" }}>{__("Állapot")}: {this.state.workflowStateName}</label>
                                    }

                                    <button key="new" className="button expanded eke-general-buttons" onClick={this.onStartNewExerciseSeries.bind(this)}>
                                        <i className="fa fa-plus" /> {__("Új létrehozása")}
                                    </button>

                                    {
                                        this.state.exerciseSeriesRecord.id && this.state.workflowStateName &&
                                        <button className="button" onClick={() => this.setState({ workflowDialogOpen: !this.state.workflowDialogOpen })}>
                                            <i className="fa fa-project-diagram" />&nbsp; {__("Folyamat...")}
                                        </button>
                                    }

                                    {(!this.state.canEdit || (this.state.oldlibId && this.state.oldlibId != this.state.exerciseSeriesRecord.library_id)) ?
                                        <button className="button success expanded eke-general-buttons" disabled={true}>
                                            <i className="fa fa-save" />  {__("Nincs joga menteni")}</button>
                                        :
                                        <button className="button success expanded eke-general-buttons" onClick={this.onSave.bind(this, false)} disabled={(!this.state.isDataChanged)}>
                                            <i className="fa fa-save" /> {this.state.exerciseSeriesRecord.id ? __("Feladatsor mentése") : __("Feladatsor mentése újként")}
                                        </button>
                                    }
                                    <button key="new_copy" className="button expanded eke-general-buttons success" onClick={this.onSave.bind(this, true)}>
                                        <i className="fa fa-copy" /> {__("Mentés másolatként")}
                                    </button>
                                </ButtonGroup>
                            </div>
                            <div className="medium-6 column">
                                <label className="lms-pages-typeLabel">{__("Osztály")}</label>
                                <CrudSelect
                                    value={this.state.exerciseSeriesRecord.grade_id ? Number(this.state.exerciseSeriesRecord.grade_id) : null}
                                    onSelect={this.onGradeSelect}
                                    displayFieldName="name"
                                    orderByFieldName="id"
                                    key="id"
                                    emptyTitle={__("Nincs megadva")}
                                    clearable={true}
                                    crudClassProxy={gradeCrudClassProxy}
                                    filter={{ is_active: true }}
                                    sortFunc={(a, b) => a.name.localeCompare(b.name, undefined, { numeric: true, sensitivity: 'base' })}
                                />
                            </div>
                            <div className="medium-6 column">
                                <label className="lms-pages-typeLabel">{__("Tantárgy")}</label>
                                <CrudSelect
                                    value={this.state.exerciseSeriesRecord.subject_id ? Number(this.state.exerciseSeriesRecord.subject_id) : null}
                                    onSelect={this.onSubjectSelect}
                                    displayFieldName="name"
                                    key="id"
                                    emptyTitle={__("Nincs megadva")}
                                    clearable={true}
                                    crudClassProxy={subjectCrudClassProxy}
                                    filter={{ is_active: true }}
                                />
                            </div>
                        </div>
                        {OFIMEMBERSHIP
                            ?
                            <div className="row">
                                <div className="small-12 medium-12 large-12 columns">
                                    <label>{__("Könyvtár")}
                                        <select name="exerciseSeriesRecord.library_id" id="library_selector_id" value={this.state.exerciseSeriesRecord.library_id}
                                            onChange={this.handleInputChange.bind(this)}
                                            disabled={this.state.selectedExercises && this.state.selectedExercises.length > 0}
                                        >

                                            {
                                                exerciseModule.getConfig().enableOfficialExercises
                                                    ?
                                                    <option value={LIBRARY_OFI_OFFICIAL_ID}>{__("Hivatalos")}</option>
                                                    : null
                                            }
                                            {
                                                exerciseModule.getConfig().enableOwnExercises
                                                    ?
                                                    <option value={LIBRARY_PERSONAL_ID}>{__("Saját")}</option>
                                                    : null
                                            }
                                        </select>
                                    </label>
                                </div>
                            </div>
                            : null
                        }
                        {
                             enableLang && <div className="row ">
                             <div className="large-12 columns">
                                 <label>{__("Feladatsor nyelve")}
                                 <CrudSelect
                                     value={this.state.exerciseSeriesRecord.lang_id ? Number(this.state.exerciseSeriesRecord.lang_id) : null}
                                     displayFieldName="name"
                                     onSelect={this.onSelectLang}
                                     valueFieldName="lang_id"
                                     emptyTitle={__("Nincs megadva")}
                                     clearable={true}
                                     filter={{ translated_to_id:getLanguageId() }}
                                     crudClassProxy={langTranslationCrudClassProxy}
                                 />
                                 </label>
                             </div>
                         </div>
                        }
                        <div className="row">
                            <div className="large-12 columns">
                                <label>{__("Cím")}
                                    <input type="text" name="exerciseSeriesRecord.title" value={this.state.exerciseSeriesRecord.title || ""} onChange={this.handleInputChange.bind(this)} />
                                </label>
                            </div>
                        </div>
                        <div className="row">
                            <div className="large-12 columns">
                                <label className="exerciseseries-editor-html-editor">
                                    {__("Bevezető szöveg")}
                                    <HTMLTextarea
                                        value={this.state.exerciseSeriesRecord.introduction || ""}
                                        onChange={this.handleIntroChange.bind(this)}
                                    />
                                </label>
                            </div>
                        </div>
                        <div className="row">
                            <div className="large-12 columns">
                                <label>{__("Kulcsszavak")}
                                    <MetaKeywordAssignerPlugin
                                        keywords={this.state.keywords}
                                        onAppendKeywords={this.onAppendKeyword}
                                        onDeleteKeyword={this.onDeleteKeyword}
                                    />
                                </label>
                            </div>
                        </div>
                        <div className="row ">
                            <div className="large-12 columns">
                                <label>{__("Nehézségi szint")}
                                    {<LevelSelectComponent
                                        value={this.state.exerciseSeriesRecord.level ? this.state.exerciseSeriesRecord.level : 1}
                                        allowEmpty={false}
                                        onSelect={this.onExeLevelSelect}
                                        name="exerciseSeriesRecord.level"
                                    />}
                                </label>
                            </div>
                        </div>
                        <div ref="exercise_list_div" className="row">
                            <table className="table table-bordered large-12 columns">
                                <thead>
                                    <tr>
                                        <th>{__("Feladat neve")}</th>
                                        <th>{__("Típus")}</th>
                                        <th>{__("Szint")}</th>
                                        <th>{__("Súly")}</th>
                                        {me && hasGroup(me, Groups.OFIEditor) && <th>{__("Állapot")}</th>}
                                        <th>{__("Művelet")}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.selectedExercises ?
                                        this.state.selectedExercises.map((curr_exercise) => {
                                            return <tr id={"exercise_" + curr_exercise.exerciseRecord.id} key={curr_exercise.exerciseRecord.id} style={{ borderBottom: "solid 2px rgba(0,0,0,0.05)" }}>
                                                <td> <label> {curr_exercise.exerciseRecord.name}</label></td>
                                                <td> <span><img src={curr_exercise.engineImg} className="eke-controls-image" alt={curr_exercise.exerciseRecord.engine_name!} /></span> </td>
                                                <td> <label className="text-center"> {curr_exercise.exerciseRecord.level}</label></td>
                                                <td> <input className="small-6 columns" type="number" value={curr_exercise.numberOfPoints ? curr_exercise.numberOfPoints : 1} name={"curr_exercise.numberOfPoints." + curr_exercise.index} onChange={this.handleInputChange.bind(this)} /></td>
                                                {me && hasGroup(me, Groups.OFIEditor) && <td style={curr_exercise.wf_station_style}><label className="small-6 column">
                                                    <span title={getWfStateTitle(curr_exercise)}>
                                                        {getWfStateText(curr_exercise)}
                                                    </span>
                                                </label>
                                                </td>}

                                                <td>
                                                    <button className="button small alert exercise-series-small-btn" title={__("Törlés")} onClick={this.onRemoveExerciseItem.bind(this, curr_exercise)}><i className="fa fa-trash"></i></button>
                                                    <Link className="button small exercise-series-small-btn" title={__("Megtekintés")} target="_blank" to={getPath({PATH_EXERCISE_VIEW}, exerciseModule) + `/${curr_exercise.exerciseRecord.id}/`}><i className="fa fa-eye"></i></Link>
                                                    <button className="button small secondary exercise-series-small-btn" title={__("Fel")} onClick={this.moveExeUp.bind(this, curr_exercise)} ><i className="fa fa-arrow-up"></i></button>
                                                    <button className="button small secondary exercise-series-small-btn" title={__("Le")} onClick={this.moveExeDown.bind(this, curr_exercise)}><i className="fa fa-arrow-down"></i></button>
                                                </td>
                                            </tr>
                                        })
                                        : null}
                                </tbody>
                            </table>
                        </div>
                        <div className="row">
                            <button className="button small" onClick={this.onOpenExerciseDialog.bind(this)}><i className="fa fa-plus"></i> {__("Feladat hozzáadása")}</button>
                            {this.state.dialogOpen ?
                                <Dialog title={__("Feladat kiválasztása")} onClose={this.onOpenExerciseDialog.bind(this)} width={1000} height={700}>
                                    <ExerciseList
                                        onExerciseSelected={this.checkIfExerciseInList.bind(this)}
                                        grade_id={this.state.exerciseSeriesRecord.grade_id}
                                        subject_id={this.state.exerciseSeriesRecord.subject_id}
                                        library_id={this.state.exerciseSeriesRecord.library_id}
                                        isPublicDisabled={true}
                                    />
                                </Dialog>
                                : ""
                            }
                        </div>
                        <div className="card row">
                            <div className="card-divider share-data-card-header columns large-12">{__("Megjelenés módja")}</div>
                            <div className="card-section columns large-12">
                                <div className="row">
                                    <div className="medium-12 column">
                                        <label>{__("Lejátszás módja")}</label>
                                        <select name="exerciseSeriesRecord.exc_display_mode_id" value={this.state.exerciseSeriesRecord.exc_display_mode_id} onChange={this.handleInputChange.bind(this)} style={{ lineHeight: "1.25" }}>
                                            <option value="">{__("Kérem válasszon...")}</option>
                                            {this.state.displayModes.map((curr_sel: IExcDisplayModeTranslationRecord) => {
                                                return <option key={curr_sel.name} value={curr_sel.exc_display_mode_id}>{curr_sel.name}</option>
                                            })
                                            }
                                        </select>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="large-12 columns">
                                        <label >
                                            <input type="checkbox" id="order_chbx" name="exerciseSeriesRecord.fix_order" checked={this.state.exerciseSeriesRecord.fix_order || false} onChange={this.handleInputChange.bind(this)} />
                                            {__("A kérdések sorrendje fix")}</label>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="medium-12 column">
                                        <label>{__("Kiválasztás módja")}</label>
                                        <select name="exerciseSeriesRecord.exc_selection_mode_id" value={this.state.exerciseSeriesRecord.exc_selection_mode_id} onChange={this.handleInputChange.bind(this)} style={{ lineHeight: "1.25" }}>
                                            <option value="">{__("Kérem válasszon...")}</option>
                                            {this.state.selectionModes.map((curr_sel: IExcSelectionModeTranslationRecord) => {
                                                return <option key={curr_sel.name} value={curr_sel.exc_selection_mode_id}>{curr_sel.name}</option>
                                            })
                                            }
                                        </select>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="medium-6 column">
                                        {
                                            this.getSelectionModeView()
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card row">
                            <div className="card-divider share-data-card-header columns large-12">{__("Kiértékelés módja")}</div>
                            <div className="card-section columns large-12">

                                <div className="row">
                                    <div className="large-4 small-12 columns">
                                        <label>{__("Sikeresség szintje")+"(%)"} 
                                            <input type="number" min="0" max="100" name="exerciseSeriesRecord.success_limit_percent" value={this.state.exerciseSeriesRecord.success_limit_percent || 0} onChange={this.handleInputChange.bind(this)} />
                                        </label>                                        
                                    </div>
                                    <div className="large-3 small-12 columns"> <label >
                                            <input type="checkbox" name="exerciseSeriesRecord.show_max_points" checked={this.state.exerciseSeriesRecord.show_max_points} onChange={this.handleInputChange.bind(this)} />
                                            {__("Pontszám kijelzése")}</label>
                                        </div>
                                </div>
                                <div className="row">
                                    <div className="large-4 small-12 columns">
                                        <label>{__("Időkorlát (perc)")}
                                            <input type="number" min="0" max="300" name="exerciseSeriesRecord.max_solution_time_min" value={this.state.exerciseSeriesRecord.max_solution_time_min || this.state.exerciseSeriesRecord.max_solution_time_min != undefined ? this.state.exerciseSeriesRecord.max_solution_time_min : 0} onChange={this.handleInputChange.bind(this)} />
                                        </label>                                        
                                    </div>
                                    <div className="large-3 small-12 columns"> <label >
                                            <input type="checkbox" name="exerciseSeriesRecord.can_interrupt" disabled={!this.state.exerciseSeriesRecord.max_solution_time_min || this.state.exerciseSeriesRecord.max_solution_time_min <=0 } checked={this.state.exerciseSeriesRecord.can_interrupt || false} onChange={this.handleInputChange.bind(this)} />
                                            {__("Megszakítható tesztsor")}</label>
                                        </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </PermissionPage>
        );
    }
    componentDidMount() {
    }

    componentDidUpdate() {
        ($(document) as any).foundation();
    }

    async checkIfExerciseInList(exerciseRecord: IViewExcExerciseRecord) {
        var newExlist = (this.state.selectedExercises ? this.state.selectedExercises : []);
        var itemExisting = newExlist.filter((item: ExerciseItem) => item.exerciseRecord.id == exerciseRecord.id);
        if (itemExisting && itemExisting.length > 0) {
            if (!await confirmDialog(__("Duplikált feladat"), __("Ez a fealadat már szerepel a feladatsorban. Biztos benne, hogy hozzá adja mégegyszer?"))) return;
        }
        this.onAddNewExercie(exerciseRecord);
    }

    async onAddNewExercie(exerciseRecord: IViewExcExerciseRecord) {
        // todo: generate input fields in this.refs.exercise_list_div
        try {
            if (!exerciseRecord) {
                return;
            }

            var newExlist = (this.state.selectedExercises ? this.state.selectedExercises : []);
            let img = await getIconOfEngineByDisplayName(exerciseRecord.engine_name!, false, false)
            var newExSeriesItem: ExerciseItem = {
                id: undefined,
                exerciseRecord: exerciseRecord,
                engineImg: img,
                numberOfPoints: 5,
                index: newExlist.length,
                /* ????? */
                wf_station_name: null,
                wf_station_style: {},
                wf_substation_name: null,
                wf_status_text: null
            };

            newExlist.push(newExSeriesItem);
            this.setState({
                selectedExercises: newExlist,
                isDataChanged: true,
            });

        }
        catch (e) {
            app.showError(__("Hiba történt"), e)
        }
    }

    onRemoveExerciseItem(exerciseItem: ExerciseItem) {
        if (!exerciseItem) {
            return;
        }
        var newExlist = (this.state.selectedExercises ? this.state.selectedExercises : []);
        var inxOfElement = newExlist.indexOf(exerciseItem);
        newExlist.splice(inxOfElement, 1);
        for (let i = 0; i < newExlist.length; i++) {
            newExlist[i].index = i;
        }
        this.setState({
            selectedExercises: newExlist,
            isDataChanged: true
        });

    }

    moveExeUp(exercise: ExerciseItem) {
        var newExlist = (this.state.selectedExercises ? this.state.selectedExercises : []);
        var inxOfElement = newExlist.indexOf(exercise);
        if (inxOfElement <= 0) return;
        newExlist.splice(inxOfElement, 1);
        newExlist.splice(inxOfElement - 1, 0, exercise);
        this.setState({
            selectedExercises: newExlist,
            isDataChanged: true
        });
    }

    moveExeDown(exercise: any) {
        var newExlist = (this.state.selectedExercises ? this.state.selectedExercises : []);
        var inxOfElement = newExlist.indexOf(exercise);
        if (inxOfElement >= newExlist.length - 1) return;
        newExlist.splice(inxOfElement, 1);
        newExlist.splice(inxOfElement + 1, 0, exercise);
        this.setState({
            selectedExercises: newExlist,
            isDataChanged: true
        });
    }

    onAddNewPointCountPair(event: any) {
        var pointCountPairList = this.state.pointCountPairList;
        var newItem: PointCountPair = {
            id: undefined,
            count: 0,
            points: 0
        }
        pointCountPairList.push(newItem);
        this.setState({
            pointCountPairList
        });
    }
    onRemovePointCountPair(item: PointCountPair) {
        var pointCountPairListTemp = this.state.pointCountPairList;
        var inxOfElement = pointCountPairListTemp.indexOf(item);
        pointCountPairListTemp.splice(inxOfElement, 1);
        this.setState({
            pointCountPairList: pointCountPairListTemp, isDataChanged: true
        });
    }

    private onAppendKeyword = async (keywords: string[]) => {
        this.setState({ isDataChanged: true, keywords: this.state.keywords!.concat(keywords) });
    }

    private onDeleteKeyword = async (index: number) => {
        this.state.keywords.splice(index, 1);
        this.setState({ isDataChanged: true, keywords: this.state.keywords });
    }
    // there
    getSelectionModeView(): any {
        if (this.state.exerciseSeriesRecord.exc_selection_mode_id == 3) {
            return <input className="small-6 columns" type="number" min="1" max="1000" value={this.state.exerciseSeriesRecord.minpoints ? this.state.exerciseSeriesRecord.minpoints : 0} name="exerciseSeriesRecord.minpoints" onChange={this.handleInputChange.bind(this)} />

        } else if (this.state.exerciseSeriesRecord.exc_selection_mode_id == 2) {
            return <input className="small-6 columns" type="number" min="0" max="1000" value={this.state.exerciseSeriesRecord.exc_count ? this.state.exerciseSeriesRecord.exc_count : 0} name="exerciseSeriesRecord.exc_count" onChange={this.handleInputChange.bind(this)} />

        } else if (this.state.exerciseSeriesRecord.exc_selection_mode_id == 4) {
            return <div> <table className="table table-bordered">
                <thead>
                    <tr>
                        <th>{__("Súly")}</th>
                        <th>{__("Darabszám")}</th>
                        <th>{__("Művelet")}</th>
                    </tr>
                </thead>
                <tbody>

                    {this.state.selectedExercises ?
                        this.state.pointCountPairList.map((curr_pair: PointCountPair, index) => {
                            return <tr id={"exercise_" + index} key={index}>
                                <td><input className="small-6 columns" type="number" min="0" max="100" value={curr_pair.points} name={"curr_pair.points." + index} onChange={this.handleInputChange.bind(this)} /></td>
                                <td><input className="small-6 columns" type="number" min="0" max="100" value={curr_pair.count} name={"curr_pair.count." + index} onChange={this.handleInputChange.bind(this)} /></td>
                                <td><button className="button small alert exercise-series-small-btn" title={__("Törlés")} onClick={this.onRemovePointCountPair.bind(this, curr_pair)}><i className="fa fa-trash"></i></button></td>
                            </tr>
                        })
                        : null}
                </tbody>
            </table>
                <button className="button small" onClick={this.onAddNewPointCountPair.bind(this)}><i className="fa fa-plus"></i></button>
            </div>
        }
        return "";
    }

    private getEmptyExercise(): IExerciseSeriesRecord {

        var newExSeries: IExerciseSeriesRecord = {
            title: "",
            id: undefined,
            // owner_id:(this.state.currentUser?this.state.currentUser.id:1),
            level: 1,
            exc_selection_mode_id: 1, // all
            exc_display_mode_id: 2, // practice
            library_id: OFIMEMBERSHIP ? LIBRARY_OFI_OFFICIAL_ID : LIBRARY_PERSONAL_ID,
            fix_order: false,
            show_max_points: false,
            can_interrupt: true,
            minpoints: 0,
            success_limit_percent: 0
        };

        return newExSeries;
    }


    onOpenExerciseDialog() {
        this.setState({ dialogOpen: !this.state.dialogOpen });
    }

    handleIntroChange(text: any) {

        this.setState({ isDataChanged: true, exerciseSeriesRecord: { ...this.state.exerciseSeriesRecord, introduction: text } });
    }

    private onGradeSelect = (sender: CrudSelect, newGradeId: number | null): void => {
        let tempgrade = newGradeId
        if (typeof (tempgrade) == "string" && tempgrade === "") {
            tempgrade = null
        }
        this.setState({
            exerciseSeriesRecord: { ...this.state.exerciseSeriesRecord, grade_id: tempgrade },
            isDataChanged: true
        }
        )
    };
    private onSubjectSelect = (sender: CrudSelect, newSubjectId: number | null): void => {
        let tempsubject = newSubjectId
        if (typeof (tempsubject) == "string" && tempsubject === "") {
            tempsubject = null
        }
        this.setState({
            exerciseSeriesRecord: { ...this.state.exerciseSeriesRecord, subject_id: tempsubject },
            isDataChanged: true
        }
        )
    };
    private onExeLevelSelect = (event: React.FormEvent<HTMLSelectElement>): void => {
        this.setState({
            exerciseSeriesRecord: { ...this.state.exerciseSeriesRecord, level: Number(event.currentTarget.value) },
            isDataChanged: true
        }
        )
    }

    private onSelectLang = (sender: CrudSelect, newLangId: number): void => {
        this.setState({
            exerciseSeriesRecord: { ...this.state.exerciseSeriesRecord, lang_id: newLangId },
            isDataChanged: true
        });
    }

    handleInputChange(event: any) {

        const target = event.target;
        var value = target.value;

        if (target.type === 'checkbox') {
            value = target.checked;
        } else if (target.type == 'select-one' || target.type == "number") {
            value = Number(target.value);
            value = value;
        }

        const name = target.name;
        var values = name.split(".");
        if (values.length > 1) {

            if (values[0] == "exerciseSeriesRecord") {
                this.setState({
                    exerciseSeriesRecord: { ...this.state.exerciseSeriesRecord, [values[1]]: value },
                    isDataChanged: true,
                });
            } else if (values[0] == "curr_exercise") {

                var exItemList = this.state.selectedExercises;
                if (exItemList) {
                    var cur_ex_item = exItemList.filter((item: ExerciseItem) => item.index == Number(values[2]));
                    exItemList[exItemList.indexOf(cur_ex_item[0])][values[1]] = value;
                    if (cur_ex_item) {
                        this.setState({
                            selectedExercises: exItemList,
                            isDataChanged: true,
                        });
                    }
                }
            } else if (values[0] == "curr_pair") {

                var pointCountPairList = this.state.pointCountPairList;
                if (pointCountPairList) {
                    pointCountPairList[values[2]][values[1]] = value;
                    this.setState({
                        pointCountPairList,
                        isDataChanged: true,
                    });
                }
            }
        } else {
            this.setState({
                [name]: value,
                isDataChanged: true
            } as any);
        }
    }
}
