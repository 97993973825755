import { Server } from '../../server/Server';
import Crud , { registerCrudClassProxy, IAsTextResult, IListParams, CrudClassProxy, ISearchTextParams, ISearchTextResult } from '../Crud';

export interface ILoggedTestRecord {
    /**
     * Globálisan egyedi azonosító.
     * 
     * Ha új rekordot akarsz létrehozni put() metódussal, akkor hagyd üresen.
     * upsert() hívásnál figyelmen kívül van hagyva az értéke.
     *
     */
    id ?: number;
    /**
     * Teszt létrehozás ideje
     */
    creation_time?: string /*timestamp?*/;
    /**
     * Teszt eredménye
     */
    result?: any;
}

/**
 *  log.logged_test - Teszt log
 */

export default class LoggedTestCrud extends Crud<ILoggedTestRecord> {
    public static TABLE_INFO_ID = 424462614;
    public static getSchemaNameForClass() { return 'log'; }
    public static getTableNameForClass() { return 'logged_test'; }

    public static load: (id: number, aServer ?: Server) => Promise<LoggedTestCrud>;
    public static asText: (id: number, includeRecord : boolean, aServer ?: Server) => Promise<IAsTextResult<ILoggedTestRecord>>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<ILoggedTestRecord[]>;
    public static searchText: (params: ISearchTextParams, aServer ?: Server) => Promise<ISearchTextResult<ILoggedTestRecord>[]>;
    public static deleteById: (id: number, aServer ?: Server) => Promise<ILoggedTestRecord>;
    public static unDeleteById: (id: number, aServer ?: Server) => Promise<ILoggedTestRecord>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class LoggedTestCrudClassProxy extends CrudClassProxy<ILoggedTestRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const loggedTestCrudClassProxy = new LoggedTestCrudClassProxy(LoggedTestCrud);
registerCrudClassProxy(loggedTestCrudClassProxy);
