import * as React from 'react';
import { BubbleLoader } from 'react-css-loaders';

import { app } from '@src/index';

import Tree, { TreeNode, CheckData } from 'rc-tree';

import 'rc-tree/dist/rc-tree.min.css';
import { subjectCrudClassProxy } from '@src/framework/crud/doc/SubjectCrud';
import CrudSelectComponent from '@src/framework/forms/crudselect';
import { MetaCategoryTreeItem, getMetaCategoryTree, fetchCategoryIds } from '@src/component/meta/meta_category_assigner_api';
import { MetaCategoryAssignerTypedEvent } from '@src/component/meta/meta_category_assigner_plugin';
import { __ } from '@src/translation';

interface IMetaCategoryAssignerHeadPluginProps {
    /** Adat szűrés */
    tableInfoId: number;
    recId: number | null;
    category_type_id: number;
    /** A kiválasztott kategória id-k. Ha még nincs betöltve, akkor undefined. */
    category_ids: number[] | undefined;
    /** A kiválasztott subject_id ehhez a fülhöz. */
    subject_id: number | null;
    onSubjectSelected ?: (category_type_id: number, subject_id: number) => void;

    /** Eseménykezelők, amikkel a category_ids -t lehet módosítani */
    onCategoryIdsLoaded?: MetaCategoryAssignerTypedEvent;
    onSetCategoryIds?: MetaCategoryAssignerTypedEvent;
    onRemoveCategoryIds?: MetaCategoryAssignerTypedEvent;
    defaultExpandAll?:boolean;
    disableCheckforNodesWithLeaf?:boolean;
    exercise_subject_id?: number | null
}

interface IMetaCategoryAssignerHeadPluginState {
    treeData?: MetaCategoryTreeItem[];
}

interface IAssignedCategoryProps {
    id: number;
    path: string[];
    in_current_subject: boolean;
    onClick: (props: IAssignedCategoryProps) => void;
}

const AssignedCategory = (props: IAssignedCategoryProps) => {
    let pathItems: JSX.Element[] = [];
    for (let i = 0; i < props.path.length; i++) {
        let part = props.path[i];
        pathItems.push(<span key={i}> {part} </span>);
        if (i < props.path.length - 1)
            pathItems.push(<i key={"sep_" + i} className="fa fa-arrow-right" />);
    }
    let classes= "label "+ 
        (props.in_current_subject?"primary":"success") +
        " meta-category-assigner-selected-category";
    return <span className={classes}>
        {pathItems}
        <i className="fa fa-times"
            style={{ cursor: "pointer" }}
            title={ __("Kattintson ide, hogy eltávolítsa ezt a kategóriát") }
            onClick={() => props.onClick(props)} />
    </span>
}

export default class MetaCategoryAssignerHeadPlugin extends React.Component<IMetaCategoryAssignerHeadPluginProps, IMetaCategoryAssignerHeadPluginState> {
    constructor(props: IMetaCategoryAssignerHeadPluginProps) {
        super(props);
        this.state = { };
    }

    componentDidMount() {
        this.asyncReload();
    }

    componentDidUpdate() {
        this.asyncReload();
    }

    private asyncReload = async (): Promise<void> => {
        this.fetchCategoryTree();
        this.fetchCategoryIds();
    }


    private fetchCategoryIds = async () => {
        if (this.props.category_ids === undefined) {
            try {
                let category_ids: number[] = [];
                if (this.props.recId) {
                    category_ids = await fetchCategoryIds(
                        this.props.tableInfoId,
                        this.props.recId!,
                        this.props.category_type_id
                    );
                }
                if (this.props.onCategoryIdsLoaded) {
                    this.props.onCategoryIdsLoaded(this.props.category_type_id, category_ids)
                }
            } catch (error) {
                app.showErrorFromJsonResult(error);
            }
        }
    }

    private fetchCategoryTree = async (): Promise<void> => {
        if (this.state.treeData === undefined) {
            try {
                const treeRoot = await getMetaCategoryTree(this.props.category_type_id);
                this.setState({ treeData: treeRoot.children });
            } catch (error) {
                app.showErrorFromJsonResult(error);
            }
        }
    }

    private onSubjectSelected = (sender: CrudSelectComponent, subject_id: number|null) => {
        if (this.props.onSubjectSelected) {
            this.props.onSubjectSelected(this.props.category_type_id, subject_id!);
        }
    }

    private onCheckCategory = (checkedKeys: any, e: CheckData) => {
        const category_ids = checkedKeys.checked.map((item: string) => {
            return parseFloat(item)
        });
        if (this.props.onSetCategoryIds) {
            this.props.onSetCategoryIds(
                this.props.category_type_id,
                category_ids
            )
        }
    }

    private onRemoveCategory = (assignedCategoryProps: IAssignedCategoryProps) => {
        if (this.props.onRemoveCategoryIds) {
            this.props.onRemoveCategoryIds(
                this.props.category_type_id,
                [assignedCategoryProps.id]
            );
        }
    }

    render() {
        if (
            this.props.tableInfoId === undefined
            || this.state.treeData === undefined
            || this.props.category_ids === undefined
        ) {
            return <div className="secondary"><BubbleLoader /></div>;
        } else {

            let assigned_paths: IAssignedCategoryProps[] = [];
            const selected_subject_id : number|null = this.props.subject_id;

            const loop = (data: MetaCategoryTreeItem[], parentPath: string[], add_nodes: boolean, disableCheckforNodesWithLeaf=this.props.disableCheckforNodesWithLeaf): any => {
                let result = [];
                for (let i = 0; i < data.length; i++) {
                    let item = data[i];
                    let path = parentPath.concat([item.title]);
                    const in_current_subject = item.subject_id == selected_subject_id;
                    if (this.props.category_ids!.includes(item.id)) {
                        assigned_paths.push({ id: item.id!, path, onClick: this.onRemoveCategory, in_current_subject: in_current_subject });
                    }
                    if (in_current_subject) {
                        const key = item.id!.toString();
                        if (item.children.length!=0) {
                            result.push(<TreeNode key={key} title={item.title} disableCheckbox={disableCheckforNodesWithLeaf}>
                                {loop(item.children, path, true, false)}
                            </TreeNode>);
                        } else {
                            result.push(<TreeNode key={key} title={item.title} disableCheckbox={false} />);
                        }
                    } else {
                        {loop(item.children, path, false)}
                    }
                }
                return result;
            };

            let treeNodes = loop(this.state.treeData, [], true);

            const checked: string[] = this.props.category_ids.map(
                (category_id) => category_id.toString());

            const categoryWithSubjectExists = this.state.treeData.map(t => t.subject_id).filter(t => t).length > 0;

            return (
                <div className="callout primary">
                    {
                        categoryWithSubjectExists
                        ?
                        <CrudSelectComponent
                            crudClassProxy={subjectCrudClassProxy}
                            displayFieldName="name"
                            key={"subject_" + (this.props.subject_id || "0")}
                            value={this.props.subject_id || this.props.exercise_subject_id || 0}
                            onSelect={this.onSubjectSelected}
                        />
                        :
                        null
                    }
                    {assigned_paths.map((props) =>
                        <AssignedCategory key={props.id} {...props} />)}
                    <div style={{ maxHeight: "500px", overflow: "auto" }}>
                        <Tree
                            key={"tree_" + (this.props.subject_id || "0")}
                            className="myCls" showLine checkable checkStrictly
                            checkedKeys={{ checked: checked, halfChecked: [] }}
                            onCheck={this.onCheckCategory}
                            defaultExpandAll={this.props.defaultExpandAll}
                        >
                            {treeNodes}
                        </Tree>
                    </div>
                </div>
            );
        }
    }
}