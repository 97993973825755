export class EKESortingServer{
    static evaluateOnServer(exercise: any, correctSolution: any[], userSolution: number[]): Evaluated {
        var success = true;
        let wrongElementsCount = 0;

        if (correctSolution.length == userSolution.length) {

            for (var i = 0; i <= correctSolution.length; i++) {

                if (correctSolution[i] !== userSolution[i]) {
                    wrongElementsCount++;
                    success = false;
                }
            }
        }
        else { success = false; }
        let successPercent = Number(((correctSolution.length - wrongElementsCount) / correctSolution.length).toFixed(2));
        return { "success": success, "solution": correctSolution, "successPercent": success ? 1 : successPercent };
    }

    static filterExerciseOnServer(exercise: any, solution: any): any {
        if(!exercise || !exercise.options || exercise.options.length < 2) return exercise;
        
        let indexes: any[] = [];
        let shuffled: boolean = false;
        let limit: number = 0;
        let tempOptions = [];
        let shuffledArray = [];
        let numOfElements = exercise.options.length;
        let realIdx = EKESortingServer.getRealIdx(solution);
        while (!shuffled && limit < 10) {

            limit++;
            shuffledArray = [];
            tempOptions = exercise.options.slice();
            if (exercise.ordered_answers) shuffledArray = tempOptions.slice();
            else
                for (let i = numOfElements - 1; i >= 0; i--) {
                    let j = Math.floor(Math.random() * (i + 1));
                    shuffledArray.push(tempOptions[j]);
                    tempOptions.splice(j, 1);
                }

            tempOptions = shuffledArray.slice();
            indexes=[];
            for (let i = 0; i < numOfElements; i++) {            
                    let j = tempOptions.indexOf(exercise.options[i]);
                    indexes.push(j);
                    if(realIdx[i] != j) shuffled = true;
                    // we need to handle duplicate elements
                    tempOptions[j] = "#undefined";            
            }
        }        

        exercise.options = shuffledArray;
        exercise.original_indexes = indexes;

        return exercise;
    }

    public static getRealIdx(shuffledIdx: number[]): number[] {
        let newArr: number[] = [];
        for (let index = 0; index < shuffledIdx.length; index++) {
            newArr[shuffledIdx[index]] = index;
        }
        return newArr;
    }

}