import * as React from 'react';
import * as ReactDOM from 'react-dom';

import { BlockTemplate, Section } from '@src/server/EditorServer';
import { IBookRecord } from '@src/framework/crud/doc/BookCrud';
import { IChapterRecord } from '@src/framework/crud/doc/ChapterCrud';
import { ILessonRecord } from '@src/framework/crud/doc/LessonCrud';
import { ISupplementaryMaterialRecord } from '@src/framework/crud/doc/SupplementaryMaterialCrud';
import { BookPage } from '@src/component/book/viewer/BookPage';
import Axios from 'axios';
import { BookLessonContent, ChapterJoinLesson, BookStructure, RenderedSection, getBookStructureForId } from '@src/server/PublicServer';
import * as _ from 'lodash';
import { ROOT_DIV_ID, PREVIEW_DISABLE_RENDER_NAME } from '../../..';
import { __ } from '@src/translation';

type LessonPreviewWindowProps = {
    onClose: () => void;
    templates: BlockTemplate[];
    sections: Section[];
    book: IBookRecord;
    chapter: IChapterRecord;
    lesson: ILessonRecord;
    supplementaryMaterials: ISupplementaryMaterialRecord[];
    bookPath: string;
}

type LessonPreviewWindowState = {
    window: Window;
    div?: HTMLElement;

    renderedSections: RenderedSection[],
    bookStructure?: BookStructure,
}


export class LessonPreviewWindow extends React.Component<LessonPreviewWindowProps, LessonPreviewWindowState> {

    constructor(props: LessonPreviewWindowProps) {
        super(props);

         var newWindow = window.open("", "_blank", 'height=800,width=1025');

         if (!newWindow)  return;

        this.state = {
            window: newWindow,
            renderedSections: []
        }
   
        
    }

    async componentDidMount() {

 
        this.setState({
            bookStructure: await getBookStructureForId(this.props.book.id!)
        });

        let htmlTemplate = (await Axios.get("/index.html")).data;

        htmlTemplate = htmlTemplate.replace('<head>', `<head><base href="${location.origin}${this.props.bookPath}/" />`);
        htmlTemplate = htmlTemplate.replace('src="/static/js', '');

        this.state.window[PREVIEW_DISABLE_RENDER_NAME] = true;

        this.state.window.document.open();

        this.state.window.addEventListener("load", () => {

            const root = this.state.window.document.getElementById(ROOT_DIV_ID);
            
            if (root) {
                this.setState({
                    div: root
                }, this.setRenderedSections);
            }
        });

        this.state.window.document.write(htmlTemplate);
        this.state.window.document.close();
    
        this.state.window.onunload = ()=> {this.props.onClose();}
 
    }

    componentDidUpdate() {
        this.setRenderedSections();
    }

    setRenderedSections = _.debounce(() => {
        const renderedSections: RenderedSection[] = [];

        for(let section of this.props.sections) {
    
            const template = this.props.templates.find((t) => {
                if (t.id == section.block_template_id) return true;
                return false;
            });
        
            if (template) {
                renderedSections.push({
                    id: section.id,
                    name: section.name,
                    html:template.template.fill(section),
                    blockTemplateName: template.name,
                    exerciseInPopup: false,
                    isTeachersGuide: false,
                });
            } else {
              console.error(__('Ismeretlen rész'), section.name, section.values);
            }
        }

        if (JSON.stringify(this.state.renderedSections) != JSON.stringify(renderedSections)) {
            this.setState({
                renderedSections
            })
        }

    }, 1000);

    render() {
        
        let lesson: ChapterJoinLesson | null = null;
        if (this.state.bookStructure) {
            const lessonFound = this.state.bookStructure!.lessons.find(l => l.lesson_id == this.props.lesson.id);
            if (lessonFound) {
                lesson = lessonFound;
            }
        }

        let lessonData: BookLessonContent = {
            lessonId: lesson ? lesson.lesson_id : 1,
            renderedSections: this.state.renderedSections,
            supplementaryMaterials: this.props.supplementaryMaterials,
            assignedCategories: [],
        };
        
        if (this.state.div) {

            return ReactDOM.createPortal(
                <BookPage 
                    bookPath=""
                    bookStructure={this.state.bookStructure}
                    lesson={lesson || null}
                    lessonData={lessonData}
                    onViewModeChange={() => {}}
                    disableScrollOnUpdate
                />
            , this.state.div);
        } else {
            return null;
        }
    }

}
