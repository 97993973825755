import * as React from 'react';

import { ITableInfoRecord, TableInfoCrudClassProxy, tableInfoCrudClassProxy } from '@src/framework/crud/meta/TableInfoCrud';
import RecordEditor, { IFieldEditorProp } from '@framework/forms/recordeditor';
import { IFieldInfo } from '@src/framework/crud/Meta';
import { SecTableInfoPermListView } from '@src/component/meta/meta_sec_table_info_perm_listview';
import { __ } from '@src/translation';


export default class TableInfoEditor extends RecordEditor<ITableInfoRecord> {
    public getCrudClassProxy() : TableInfoCrudClassProxy { return tableInfoCrudClassProxy;  }

    /** Itt minden disabled, ez egy read-only táblázat. */
    protected getFieldEditorProps(fieldInfo: IFieldInfo) : IFieldEditorProp {
        return {fieldName: fieldInfo.field_name, disabled:true};
    }

    protected getBottomButtons(): JSX.Element[] {
        return [];
    }


    protected getDetailPanels() : any[] {
        let result = super.getDetailPanels();        
        if (this.state.rec_id) {
                result = result.concat(
                    <h4>{ __("CRUD jogok") }</h4>,
                    <SecTableInfoPermListView
                        table_info_id={this.state.rec_id!}
                    />
                );  
        }
        return result;
    }
        

}