import * as React from 'react';

import { IViewGoodWordGlobalRecord, ViewGoodWordGlobalClassProxy, viewGoodWordGlobalClassProxy } from '@src/framework/view/spell/ViewGoodWordGlobal';

import ListView from '@src/framework/forms/listview';

import '@framework/crud/spell/GoodWordGlobalCrud'; // Ezt ne távolítsd el mert akkor a hülye webpack kioptimizálja!

import { __ } from '@src/translation';

class GoodWordGlobalListViewInner extends ListView<IViewGoodWordGlobalRecord> {
    //protected getHeader = () : JSX.Element|null => { return <SideMenu /> }
    public getViewClassProxy() : ViewGoodWordGlobalClassProxy { return viewGoodWordGlobalClassProxy; }
}

export default function GoodWordGlobalListView(props: any) {
    // TODO: hozzáadni a person-os oszlopokat a view-hoz hogy látszódjon a 
    // rendes nevük!
    return <GoodWordGlobalListViewInner 
        defaultPageSize={10}
        match={props.match}        
        viewColumns={[
            {accessor: "id", maxWidth: 100},
            {accessor: "word"},
            {accessor: "creation_time"},
            {accessor: "creator"},
            /*
            {accessor: "modification_time"},
            {accessor: "modifier"},
            */
            {accessor: "link", Header:__("Műveletek"), maxWidth:200},
        ]}
    />
}
