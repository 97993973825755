import * as React from 'react';
import { SearchResult } from '@src/server/PublicServer';
import { match } from 'react-router-dom';
import { ExerciseServerInterface } from './ExerciseContainerComponent';
import * as Server from '@src/server/PublicServer';
import { SearchResultComponent } from './SearchResultComponent';
import { PATH_EXERCISE_PUB_VIEW } from '@src/Routes';
import { MathJaxText } from '../MathJaxText';
import { __ } from '@src/translation';

interface ExerciseHelperLinksProps {
    results: SearchResult[];
    server?: ExerciseServerInterface; // not required
    match: match<{ exerciseId: string }>;
}

interface ExerciseHelperLinksState {
    results?:SearchResult[];
    published:boolean;
}

export default class ExerciseHelperLinks extends React.Component<ExerciseHelperLinksProps, ExerciseHelperLinksState> {

    constructor(props: ExerciseHelperLinksProps) {
      
        super(props);
        let isPublished = this.props.match && this.props.match.path.startsWith(PATH_EXERCISE_PUB_VIEW);
        this.state = {
            results:[],
            published:isPublished
         };
        
    }
    async componentDidMount()
    {
        var searchResults = await this.loadAllSearchResults();      
        this.setState({results:searchResults});
    }

    private async loadAllSearchResults(): Promise<SearchResult[] | undefined> {

        if (!parseInt(this.props.match.params.exerciseId)) {
            console.log("Error while loading search results: no server or exercise");
            return;
        }

        if (!parseInt(this.props.match.params.exerciseId))
            return;

        var result: SearchResult[] = await Server.elasticExerciseSearch(Number(this.props.match.params.exerciseId), this.state.published, 20);//
      
        if (result.length == 0) {
            return;
        }
       
        result=result.slice(0,20);
        return result;
    }

    render() {
        return <div>
        {
            this.state.results?         
                this.state.results.map((r: Server.SearchResult) => {
                    return <MathJaxText><SearchResultComponent result={r} key={r._id} /></MathJaxText>
                })
            : <p>{__("Hiba! Nem sikerült betölteni a linkeket!")}</p>
        }   
        </div>;
    }
}


