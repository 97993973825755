import { Server } from '../../server/Server';
import View , { registerViewClassProxy, ViewClassProxy } from '../View';
import { IListParams } from '@framework/crud/Crud';

export interface IViewSubstationRecord {
    /**
     * Azonosító
     *
     * Azonosító (pótkulcs)
     */
    id?: number;
    /**
     * Rekord létrehozás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel létrehozásakor.
     */
    creation_time?: string /*timestamp?*/;
    /**
     * Ki hozta létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozó felhasználóra hivatkozik.
     */
    creation_user_id?: number;
    /**
     * Melyik session-ből jött létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozáshoz használt munkamenetre hivatkozik.
     */
    creation_session_id?: number|null;
    /**
     * Utolsó módosítás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel módosításakor.
     */
    modification_time?: string /*timestamp?*/|null;
    /**
     * Ki módosította utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosító felhasználóra hivatkozik.
     */
    modification_user_id?: number|null;
    /**
     * Melyik session-ből módosították utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosításhoz használt munkamenetre hivatkozik.
     */
    modification_session_id?: number|null;
    /**
     * Aktív
     *
     * Logikai törléshez használt mező.
     */
    is_active?: boolean;
    /**
     * Folyamat állapot
     */
    station_id?: number;
    /**
     * Név
     */
    name?: string;
    /**
     * Leírás
     */
    description?: string;
    /**
     * Létrehozta
     *
     * Felhasználó
     */
    creator?: string|null;
    /**
     * Módosító
     *
     * Felhasználó, aki utoljára módosította
     */
    modifier?: string|null;
    /**
     * Állomás név
     *
     * Állomás név
     */
    station_name?: string|null;
    /**
     * Folyamat típus kód
     *
     * A folyamat típus kódja
     */
    wf_type_code?: string|null;
    /**
     * Folyamat típus név
     *
     * A folyamat típus neve
     */
    wf_type_name?: string|null;
}

/**
 *  wf.view_substation - Al-státusz
 *
 *  Idősoros táblázat, ami az összes tétel összes korábbi verzióját eltárolja.
 */

export default class ViewSubstation extends View<IViewSubstationRecord> {
    public static VIEW_INFO_ID = 2018110301;

    public static getSchemaNameForClass(): string { return 'wf'; }
    public static getViewNameForClass(): string { return 'view_substation'; }
    public static getTableNameForClass(): string|null { return 'substation'; }

    public static load: (id: number, aServer ?: Server) => Promise<ViewSubstation>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<IViewSubstationRecord[]>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class ViewSubstationClassProxy extends ViewClassProxy<IViewSubstationRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const viewSubstationClassProxy = new ViewSubstationClassProxy(ViewSubstation);
registerViewClassProxy(viewSubstationClassProxy);

