import * as React from "react";
import './LearningPath.css';
import { match } from "react-router";
import { me, Groups, hasAnyGroup } from '@framework/server/Auth';
import ExerciseCrud from "@src/framework/crud/exc/ExerciseCrud";
import ExerciseContainerComponent from "../exercise/ExerciseContainerComponent";
import ExerciseSeriesCrud from "@src/framework/crud/exc/ExerciseSeriesCrud";
import * as ExerciseServer from "../exercise/ExerciseServer";
import OoFileCrud from "@src/framework/crud/media/OoFileCrud";
import { MediaViewer } from "../media/MediaViewer";
import { __ } from "@src/translation";
import { LearningPathBreadcrumbs } from "../Breadcrumbs";
import { LpNodeCard } from "./LpNodeCard/LpNodeCard";
import { List } from "../ui/List";
import LearningPathPlayStateAPI from "./LearningPathPlayStateAPI";
import { app } from '@src/index';
import { ILpPlayStateRecord } from "@src/framework/crud/exc/LpPlayStateCrud";
import LpLearningPathCrud from "@src/framework/crud/exc/LpLearningPathCrud";


type LearningPathContainerProps = {
    match: match<{ learningPathId: string }>
}

type LearningPathContainerState = {
    lpId: number,
    LpPlayStateRecord?: ILpPlayStateRecord,
    LpPlayStateNode?: LpPlayStateNode,
    currStateRecordType?: RecordType,
    nextNodeSelecting?: boolean,
    availableNextNodes?: LpPlayStateNode[],
    nodeWasEvaluated?: boolean,
    secondCounter: number,
    time: TimeObject,
}

type TimeObject = {
    h: string,
    m: string,
    s: string
}

export enum RecordType {
    EXERCISE = 1,
    EXERCISESERIES,
    MEDIA,
    CHAPTER,
    LESSON
}

export type LpPlayStateNode = {
    title : string,
    table_info_id : number,
    record_id : number,
    node_id : number,
    is_available : boolean,
    next_nodes : LpPlayStateNode[]
}

export class LearningPathContainer extends React.Component<LearningPathContainerProps, LearningPathContainerState> {
    private exerciseServer: ExerciseServer.ExerciseServer;
    private counter: any;

    constructor(props: LearningPathContainerProps) {
        super(props);

        this.state = {
            lpId: (Number)(this.props.match.params.learningPathId),
            secondCounter: 0,
            nodeWasEvaluated: false,
            time: {
                h: "0",
                m: "0",
                s: "0",
            },
        }

        this.exerciseServer = new ExerciseServer.ExerciseServer(false); //this.state.published
    }

    async reloadAsync() {
        if (this.state.lpId && me && me.id) {
            try {
                let lpPlayState: LpPlayStateNode = await LearningPathPlayStateAPI.createOrload(this.state.lpId);

                if (!lpPlayState) {
                    app.showError(__("Nem sikerült betölteni az útvonalat"), __("Hibás azonosító"));
                    return;
                }

                let currStateRecordType = LearningPathContainer.getRecordType(lpPlayState.table_info_id);
                this.setExerciseId(currStateRecordType!, lpPlayState.record_id);

                this.setState({
                    LpPlayStateNode: lpPlayState
                });
            }
            catch(e) {
                app.showErrorFromJsonResult(e);
                app.showError(__("Nem sikerült betölteni a útvonalat!"), e);
                console.log(e);
            }
        }
    }

    static getRecordType(table_info_id: number | undefined): RecordType | undefined {
        if (!table_info_id) {
            return undefined;
        }
        switch (table_info_id) {
            case ExerciseCrud.TABLE_INFO_ID:
                return RecordType.EXERCISE;
            case ExerciseSeriesCrud.TABLE_INFO_ID:
                return RecordType.EXERCISESERIES;
            case OoFileCrud.TABLE_INFO_ID:
                return RecordType.MEDIA;
            default:
                return undefined;
            //to be xtended with chapter and lesson
        }
    }

    setExerciseId(recordType: RecordType, record_id: number) {
        if (recordType == RecordType.EXERCISE) {
            this.exerciseServer.setExerciseId(record_id);
        }
        else if (recordType == RecordType.EXERCISESERIES) {
            this.exerciseServer.setExerciseSeriesId(record_id!);
        }
    }

    componentDidMount() {
        this.reloadAsync();
        this.handleTimeCounterStart();
    }

    /*-------------------------------------------*/
    /*------------ Seconds counter --------------*/
    /*-------------------------------------------*/

    handleTimeCounterStart() {
        this.counter = 0;
        let time = this.secondsToTime(this.state.secondCounter);
        this.setState({
            time
        })
        this.startCounter();
    }

    startCounter() {
        if (this.counter == 0 && this.state.secondCounter >= 0) {
            this.counter = setInterval(() => this.countTime(), 1000);
        }
    }

    countTime() {
        let seconds = this.state.secondCounter ? this.state.secondCounter + 1 : 1;
        this.setState({
            time: this.secondsToTime(seconds),
            secondCounter: seconds
        });
    }

    secondsToTime(secs: number): TimeObject {
        let hours = Math.floor(secs / (60 * 60));

        let divisor_for_minutes = secs % (60 * 60);
        let minutes = Math.floor(Number(divisor_for_minutes) / 60);

        let divisor_for_seconds = divisor_for_minutes % 60;
        let seconds = Math.ceil(divisor_for_seconds);

        let time_obj: TimeObject = {
            h: ("0" + hours).slice(-2),
            m: ("0" + minutes).slice(-2),
            s: ("0" + seconds).slice(-2)
        };
        return time_obj;
    }

    async onLpGetSuccessPercentFromExercise(evaluation: Evaluated) {
        await this.evaluateNodeOnServer(evaluation);
    }

    async evaluateNodeOnServer(evaluation?: Evaluated) {
        let minutesSpent = 0;
        minutesSpent += Number(this.state.time.h) * 60;
        minutesSpent += Number(this.state.time.m);
        minutesSpent += Number(this.state.time.s) / 60;
        let userResult = {
            node_id: this.state.LpPlayStateNode!.node_id,
            points: evaluation ? Math.floor(evaluation.successPercent! * 100) : 0,
            minutes: minutesSpent
        }
        let LpPlayStateNode: LpPlayStateNode = await LearningPathPlayStateAPI.evaluateNode(this.state.lpId, userResult);
        this.setState({
            LpPlayStateNode,
            nodeWasEvaluated: true,
        });
    }

    /*-------------------------------------------*/
    /*-- Next node selection/transition methods -*/
    /*-------------------------------------------*/

    async nodeSelected(nodeIndex: any) {
        if (this.state.nextNodeSelecting && this.state.availableNextNodes) {
            await this.handleNodeTransition(this.state.availableNextNodes[nodeIndex]);
        }
    }

    private async goToNextNode() {
        clearInterval(this.counter);
        try {
            //check if exercise was solved
            if (!this.state.nodeWasEvaluated) {
                await this.evaluateNodeOnServer();
            }
            let nextAvailableNodes = this.state.LpPlayStateNode!.next_nodes;

            if (nextAvailableNodes.length == 1) {
                await this.handleNodeTransition(nextAvailableNodes[0]);
            }
            else if (nextAvailableNodes.length > 1) {
                this.setState({
                    availableNextNodes: nextAvailableNodes,
                    nextNodeSelecting: true,
                });
            }
        }
        catch(e) {
            app.showErrorFromJsonResult(e);
            app.showError(__("Nem sikerült betölteni a útvonalat!"), e);
            console.log(e);
        }
        //if returns one, automatically go to it, if not let user choose
    }

    private async handleNodeTransition(nextNode: LpPlayStateNode) {
        if (nextNode.node_id) {
            try {
                let nextPlayState: LpPlayStateNode  = await LearningPathPlayStateAPI.goToNextNode(this.state.lpId, nextNode.node_id);

                let currStateRecordType = LearningPathContainer.getRecordType(nextPlayState.table_info_id);
                this.setExerciseId(currStateRecordType!, nextPlayState.record_id);

                this.setState({
                    nextNodeSelecting: false,
                    nodeWasEvaluated: false,
                    availableNextNodes: [],
                    secondCounter: 0,
                    LpPlayStateNode: nextPlayState
                });
                //heh double setState?
                this.handleTimeCounterStart();
            }
            catch(e) {
                app.showErrorFromJsonResult(e);
                app.showError(__("Nem sikerült betölteni a útvonalat!"), e);
                console.log(e);
            }
        }
    }

    private async reloadFromBegining() {
        clearInterval(this.counter);
        let nextPlayState: LpPlayStateNode = await LearningPathPlayStateAPI.reloadFromBeginning(this.state.lpId);

            let currStateRecordType = LearningPathContainer.getRecordType(nextPlayState.table_info_id);
            this.setExerciseId(currStateRecordType!, nextPlayState.record_id);

            this.setState({
                nextNodeSelecting: false,
                availableNextNodes: [],
                secondCounter: 0,                
                LpPlayStateNode: nextPlayState
            });
            this.handleTimeCounterStart();
    }

    /*-------------------------------------------*/
    /* ------------ Render methods --------------*/
    /*-------------------------------------------*/

    getNodeRender(currPlayStateNode: LpPlayStateNode) {
        if (currPlayStateNode && currPlayStateNode.table_info_id) {
            switch (currPlayStateNode.table_info_id) {
                case ExerciseCrud.TABLE_INFO_ID:
                case ExerciseSeriesCrud.TABLE_INFO_ID:
                    return (
                        <ExerciseContainerComponent
                            ref="exercise_container"
                            key={currPlayStateNode.record_id}
                            server={this.exerciseServer}
                            learningPathPlayState={currPlayStateNode}
                            onLpGetSuccessPercent={this.onLpGetSuccessPercentFromExercise.bind(this)}
                        />
                    )
                case OoFileCrud.TABLE_INFO_ID:
                    return (
                        <MediaViewer
                            ooFileId={currPlayStateNode.record_id!}
                            isPublished={false}
                            notForWeak={false}
                            alt={"sometext" || undefined}
                        />
                    )
                default:
                    return <h3>{__("Node is not suported yet")}</h3>;
            }
        }
        return <h3>{__("Node not found")}</h3>;
    }

    getNodeSelectionRender() {
        if (this.state.availableNextNodes && this.state.availableNextNodes.length > 1) {
            return (
                <div>
                    <h4>{__("Available nodes")}:</h4>
                    <List>
                        {
                            this.state.availableNextNodes.map((node: any, index) => {
                                return (
                                    <LpNodeCard
                                        key={index}
                                        item={node}
                                        onNodeSelected={this.nodeSelected.bind(this, index)}
                                    />
                                )
                            })
                        }
                    </List>
                </div>
            )
        }
        else {
            return (
                <h3>{__("No next nodes available")}</h3>
            )
        }
    }

    render() {
        let currPlayStateNode: LpPlayStateNode = this.state.LpPlayStateNode!;
        return (
            <div className="lp-container-wrapper">
                <div className="row">
                    <div className="large-6 small-12 columns">
                        <LearningPathBreadcrumbs links={[
                            { name: __("Playing") },
                        ]} />
                    </div>
                    <div className="large-6 small-12 columns">
                        <ul className="lp-container-timer">
                            <li className="lp-container-timer-elem">{__("S:") + this.state.time.s}</li>
                            <li className="lp-container-timer-elem">{__("M:") + this.state.time.m}</li>
                            <li className="lp-container-timer-elem">{__("H:") + this.state.time.h}</li>
                        </ul>
                    </div>
                </div>
                <div>
                    {this.getNodeRender(currPlayStateNode)}
                </div>
                {this.state.nextNodeSelecting && this.getNodeSelectionRender()}
                <div className="expanded button-group lp-container-helper-div">
                    <div className="large-6 small-12 columns">
                        <button className="button success" onClick={this.reloadFromBegining.bind(this)}>
                            <i className="fas fa-redo-alt"></i>&nbsp;{__("Start over")}
                        </button>
                    </div>
                    <div className="large-6 small-12 columns">
                        {this.state.LpPlayStateNode && this.state.LpPlayStateNode.next_nodes && this.state.LpPlayStateNode.next_nodes.length > 0 ? 
                            <button className="button success" onClick={this.goToNextNode.bind(this)}>
                                {__("Go to next")}<i className="fa fa-arrow-right"></i>&nbsp;
                            </button>
                        :
                        <h5>{__("There are no next nodes available")}</h5>
                        }
                    </div>
                </div>
            </div>
        )
    }
}