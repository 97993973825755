import { Server } from '../../server/Server';
import Crud , { registerCrudClassProxy, IAsTextResult, IListParams, CrudClassProxy, ISearchTextParams, ISearchTextResult } from '../Crud';

export interface ISystemCheckLevelRecord {
    /**
     * Globálisan egyedi azonosító.
     * 
     * Ha új rekordot akarsz létrehozni put() metódussal, akkor hagyd üresen.
     * upsert() hívásnál figyelmen kívül van hagyva az értéke.
     *
     */
    id ?: number;
    /**
     * Név
     */
    title?: string;
    /**
     * Leírás
     */
    description?: string|null;
}

/**
 *  sys.system_check_level - Rendszer ellenőrzés szint
 */

export default class SystemCheckLevelCrud extends Crud<ISystemCheckLevelRecord> {
    public static TABLE_INFO_ID = 35326524;
    public static getSchemaNameForClass() { return 'sys'; }
    public static getTableNameForClass() { return 'system_check_level'; }

    public static canCache(): boolean {
        return true;
    }

    public static getDefaultMaxAge() : number|undefined {
        return 3600;
    }

    public static load: (id: number, aServer ?: Server) => Promise<SystemCheckLevelCrud>;
    public static asText: (id: number, includeRecord : boolean, aServer ?: Server) => Promise<IAsTextResult<ISystemCheckLevelRecord>>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<ISystemCheckLevelRecord[]>;
    public static searchText: (params: ISearchTextParams, aServer ?: Server) => Promise<ISearchTextResult<ISystemCheckLevelRecord>[]>;
    public static deleteById: (id: number, aServer ?: Server) => Promise<ISystemCheckLevelRecord>;
    public static unDeleteById: (id: number, aServer ?: Server) => Promise<ISystemCheckLevelRecord>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class SystemCheckLevelCrudClassProxy extends CrudClassProxy<ISystemCheckLevelRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const systemCheckLevelCrudClassProxy = new SystemCheckLevelCrudClassProxy(SystemCheckLevelCrud);
registerCrudClassProxy(systemCheckLevelCrudClassProxy);
