import { Server } from '../../server/Server';
import View , { registerViewClassProxy, ViewClassProxy } from '../View';
import { IListParams } from '@framework/crud/Crud';

export interface IViewMediaOoFilePermRecord {
    /**
     * Azonosító
     *
     * Azonosító (pótkulcs)
     */
    id?: number;
    /**
     * Rekord létrehozás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel létrehozásakor.
     */
    creation_time?: string /*timestamp?*/;
    /**
     * Ki hozta létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozó felhasználóra hivatkozik.
     */
    creation_user_id?: number;
    /**
     * Melyik session-ből jött létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozáshoz használt munkamenetre hivatkozik.
     */
    creation_session_id?: number|null;
    /**
     * Utolsó módosítás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel módosításakor.
     */
    modification_time?: string /*timestamp?*/|null;
    /**
     * Ki módosította utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosító felhasználóra hivatkozik.
     */
    modification_user_id?: number|null;
    /**
     * Melyik session-ből módosították utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosításhoz használt munkamenetre hivatkozik.
     */
    modification_session_id?: number|null;
    /**
     * OnlyOffice file
     */
    oo_file_id?: number;
    /**
     * Média jogosultság típus
     */
    oo_permtype_id?: number;
    /**
     * Engedély birtokos
     */
    sec_perm_owner_id?: number;
    /**
     * Létrehozó
     *
     * Aki a jogot kiosztotta
     */
    creator?: string|null;
    /**
     * Módosító
     *
     * Aki a jog kiosztását módosította
     */
    modifier?: string|null;
    /**
     * Jogosultság típus
     *
     * Jogosultság típus
     */
    permtype_title?: string|null;
    /**
     * Jogosultság csoport
     *
     * A jogot birtokló jogosultság csoport
     */
    group_title?: string|null;
    /**
     * Felhasználó
     *
     * A jogot birtokló felhasználó
     */
    user_title?: string|null;
}

/**
 *  media.view_media_oo_file_perm - Állomány kiosztott jog
 *
 *  Explicit módon kiosztott jogosultság állományra
 */

export default class ViewMediaOoFilePerm extends View<IViewMediaOoFilePermRecord> {
    public static VIEW_INFO_ID = 2018080704;

    public static getSchemaNameForClass(): string { return 'media'; }
    public static getViewNameForClass(): string { return 'view_media_oo_file_perm'; }
    public static getTableNameForClass(): string|null { return 'oo_file_perm'; }

    public static load: (id: number, aServer ?: Server) => Promise<ViewMediaOoFilePerm>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<IViewMediaOoFilePermRecord[]>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class ViewMediaOoFilePermClassProxy extends ViewClassProxy<IViewMediaOoFilePermRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const viewMediaOoFilePermClassProxy = new ViewMediaOoFilePermClassProxy(ViewMediaOoFilePerm);
registerViewClassProxy(viewMediaOoFilePermClassProxy);

