import * as React from 'react';
import OoFileCrud from '@src/framework/crud/media/OoFileCrud';
import { ExerciseEditor } from '@src/component/exercise/Editor/ExerciseEditor';
import { app } from '@src/index';
import { ExerciseFileSelectDialog } from './ExerciseFileSelectDialog';
import { __ } from '@src/translation';
import { IOoFileRecord } from '@src/framework/crud/media/OoFileCrud';
import * as  ExerciseServer from '../ExerciseServer';
import { copyMeta } from '@src/component/meta/meta_copy';

export enum ExerciseFileTypes{
    Image,
    Sound
}

export type ExerciseFileSelectProps = {
    imagebasepath: string,
    value: string,
    onChange: (newvalue: string, file?: IOoFileRecord) => void,
    getFolderId: () => Promise<number>,
    fileType:ExerciseFileTypes
}

export type ExerciseFileSelectState = {
    exerciseFolderId?: number,
    showFileChooser: boolean,
}

export default class ExerciseFileSelect extends React.Component<ExerciseFileSelectProps, ExerciseFileSelectState> {

    constructor(props: ExerciseFileSelectProps) {
        super(props);

        this.state = {
            showFileChooser: false
        }
    }

    async onShowFileChooserPopup() {
        const folderId = await this.props.getFolderId();

        if (folderId == null) {
            return;
        }

        this.setState({
            exerciseFolderId: folderId,
            showFileChooser: true
        });
    }

    onFileSelectedInPopup = async (fileId: number) => {
        try {
            var file = (await OoFileCrud.load(fileId)).record;

            if (file.oo_folder_id != this.state.exerciseFolderId && this.state.exerciseFolderId != null) {
                //Copy file to exercise folder             
                let orig_file_id = file.id;   
                const copy: any = ExerciseServer.getFileCopy(file);
                copy.oo_folder_id = this.state.exerciseFolderId;            
                file = (await new OoFileCrud(copy).put()).record;
                copyMeta(OoFileCrud.TABLE_INFO_ID, orig_file_id!, OoFileCrud.TABLE_INFO_ID, file.id!)
            }

            this.props.onChange("api/media/file/" + file.sha1, file);
            this.setState({showFileChooser: false});
            
        } catch(e) {
            app.showErrorFromJsonResult(e);
        }
    }

    onRemove() {
        this.props.onChange("");
    }

    render() {

        let thumbnaiImg =  this.props.value.replace("api/media/file/", "api/media/thumb/200/");

        return <div style={{display:"flex"}}>

                {
                    this.props.value && this.props.value != "" ?
                     this.props.fileType == ExerciseFileTypes.Sound?                    
                    <audio controls src={"/" + this.props.value} />
                    :
                    <img src={this.props.imagebasepath + thumbnaiImg} style={{margin: "0.5em", maxHeight: "50px"}}/> 
                    : 
                    null                
                }

{this.props.fileType == ExerciseFileTypes.Sound?
                <button className="button small" style={{margin: "0.5em"}} onClick={this.onShowFileChooserPopup.bind(this)}>
                    <i className="fa fa-volume-up" /> { __("Fájl kiválasztása") }
                </button>
                :
                <button className="button small" style={{margin: "0.5em"}} onClick={this.onShowFileChooserPopup.bind(this)}>
                <i className="fa fa-image" /> { __("Kép kiválasztása") }
            </button>
}
                <button className="button alert small" style={{margin: "0.5em"}} onClick={this.onRemove.bind(this)}>
                    <i className="fa fa-trash" />
                </button>

                <ExerciseFileSelectDialog 
                    open={this.state.showFileChooser}
                    onClose={() => this.setState({showFileChooser: false})}
                    exerciseFolderId={this.state.exerciseFolderId}
                    onFileSelected={this.onFileSelectedInPopup}
                />

            </div>;
    }
}
