import { Server } from '../../server/Server';
import View , { registerViewClassProxy, ViewClassProxy } from '../View';
import { IListParams } from '@framework/crud/Crud';

export interface IViewLessonplanRecord {
    /**
     * Azonosító
     *
     * Azonosító (pótkulcs)
     */
    id?: number;
    /**
     * Rekord létrehozás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel létrehozásakor.
     */
    creation_time?: string /*timestamp?*/;
    /**
     * Ki hozta létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozó felhasználóra hivatkozik.
     */
    creation_user_id?: number;
    /**
     * Melyik session-ből jött létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozáshoz használt munkamenetre hivatkozik.
     */
    creation_session_id?: number|null;
    /**
     * Utolsó módosítás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel módosításakor.
     */
    modification_time?: string /*timestamp?*/|null;
    /**
     * Ki módosította utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosító felhasználóra hivatkozik.
     */
    modification_user_id?: number|null;
    /**
     * Melyik session-ből módosították utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosításhoz használt munkamenetre hivatkozik.
     */
    modification_session_id?: number|null;
    /**
     * Aktív
     *
     * Logikai törléshez használt mező.
     */
    is_active?: boolean;
    /**
     * Utolsó találat időpontja
     */
    search_popularity_last_hit_date?: string /*timestamp?*/|null;
    /**
     * Utolsó népszerűségi index
     */
    search_popularity_last_score?: number /* float8 */|null;
    /**
     * DPMK projekt
     */
    dpmk_project_id?: number;
    /**
     * Intézmény
     */
    institute_id?: number;
    /**
     * Intézmény feladat ellátási hely
     */
    institute_site_id?: number;
    /**
     * Intézmény típus
     */
    institute_type_id?: number;
    /**
     * Műveltségi terület
     */
    literacy_field_id?: number|null;
    /**
     * Tulajdonos/felelős
     */
    owner_id?: number;
    /**
     * Témakör
     */
    topic?: string;
    /**
     * Kereszttantervi kapcsolódás
     */
    cross_curriculum_link?: string|null;
    /**
     * A tanóra célja
     */
    goal?: string;
    /**
     * Fejlesztendő készség
     */
    facility_to_develop?: string;
    /**
     * Pedagógiai módszertan csomag
     */
    dpmk_methodology_package_id?: number|null;
    /**
     * Pedagógiai módszertan csomag
     */
    dpmk_methodology_package_text?: string|null;
    /**
     * Keretek (tanulás szervezési feltételek)
     */
    frame_study_conditions?: string;
    /**
     * Keretek (közreműködő szakemberek)
     */
    frame_contributor_experts?: string|null;
    /**
     * Elsajátítandó fogalmak jegyzéke
     */
    concepts_to_pick?: string;
    /**
     * Megvalósítás tapasztalatai
     */
    conclusions?: string|null;
    /**
     * Javaslatok
     */
    suggestions?: string|null;
    /**
     * A könyv gyökér könyvtára.
     */
    oo_folder_id?: number|null;
    /**
     * Módosító
     *
     * Módosító
     */
    modifier?: string|null;
    /**
     * Létrehozó
     *
     * Létrehozó
     */
    creator?: string|null;
    /**
     * Tulajdonos
     *
     * Tulajdonos
     */
    owner_name?: string|null;
    /**
     * Intézmény OM azonosító
     *
     * Intézmény OM azonosító
     */
    institute_om_identifier?: string|null;
    /**
     * Intézmény név
     *
     * Intézmény név
     */
    institute_name?: string|null;
    /**
     * Feladatellátási hely azonosító
     *
     * Feladatellátási hely azonosító
     */
    institute_site_identifier?: string|null;
    /**
     * Feladatellátási hely neve
     *
     * Feladatellátási hely neve
     */
    institute_site_name?: string|null;
    /**
     * Intézmény típus név
     *
     * Intézmény típus név
     */
    institute_type_title?: string|null;
    /**
     * Intézmény típus leírás
     *
     * Intézmény típus leírás
     */
    institute_type_description?: string|null;
    /**
     * Projekt kód
     *
     * Projekt kód
     */
    project_code?: string|null;
    /**
     * Projekt azonoító
     *
     * Projekt azonoító
     */
    project_title?: string|null;
    /**
     * Műveltségi terület
     *
     * Műveltségi terület
     */
    literacy_field_name?: string|null;
    /**
     * Folyamat azonosító
     *
     * Folyamat azonosító
     */
    wf_workflow_id?: number|null;
    /**
     * Folyamat állapot azonosító
     *
     * Folyamat állapot azonosító
     */
    wf_station_id?: number|null;
    /**
     * Állapot
     *
     * Folyamat állapot neve
     */
    wf_station_name?: string|null;
    /**
     * Folyamat állapot leírása
     *
     * Folyamat állapot leírása
     */
    wf_station_description?: string|null;
    /**
     * Állapot stílus
     *
     * Folyamat állapot stílusa, jsonb/css
     */
    wf_station_style?: any|null;
    /**
     * Folyamat al-státusz azonosító
     *
     * Folyamat al-státusz azonosító
     */
    wf_substation_id?: number|null;
    /**
     * Folyamat al-státusz
     *
     * Folyamat al-státusz
     */
    wf_substation_name?: string|null;
    /**
     * Folyamat al-státusz leírása
     *
     * Folyamat al-státusz leírása
     */
    wf_substation_description?: string|null;
    /**
     * Folyamat szöveges állapot kiegészítés
     *
     * Folyamat szöveges állapot kiegészítés
     */
    wf_status_text?: string|null;
    /**
     * Évfolyamok
     *
     * Évfolyamok nevei, vesszővel elválasztva
     */
    grades?: string|null;
    /**
     * Tantárgyak
     *
     * Tantárgyak nevei, vesszővel elválasztva
     */
    subjects?: string|null;
}

/**
 *  doc.view_lessonplan - Óravázlat
 *
 *  Idősoros táblázat, ami az összes tétel összes korábbi verzióját eltárolja.
 */

export default class ViewLessonplan extends View<IViewLessonplanRecord> {
    public static VIEW_INFO_ID = 2018092601;

    public static getSchemaNameForClass(): string { return 'doc'; }
    public static getViewNameForClass(): string { return 'view_lessonplan'; }
    public static getTableNameForClass(): string|null { return 'lessonplan'; }

    public static load: (id: number, aServer ?: Server) => Promise<ViewLessonplan>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<IViewLessonplanRecord[]>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class ViewLessonplanClassProxy extends ViewClassProxy<IViewLessonplanRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const viewLessonplanClassProxy = new ViewLessonplanClassProxy(ViewLessonplan);
registerViewClassProxy(viewLessonplanClassProxy);

