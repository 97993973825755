import './style.css';
import * as ExerciseBaseTypes from "@src/component/exercise/models/ExerciseBaseClass";
import { AExerciseEngine } from '../../models/AExerciseEngine';
import { WordFinderGameServer } from './WordFinderGameServer';
import { __ } from '@src/translation';

export type WordFinderExerciseData = {
    illustration: string,
    show_possible_words: boolean,
    dimensions: { rows: number, columns: number },
    elements: ExerciseBaseTypes.AnswerElement[]
}

export type MatrixCell = {
    row: number,
    col: number
}

export enum MatrixDirection {
    Horizontal = 0,
    Vertical,
    Diagonal
}

export class WordFinderGame extends AExerciseEngine {

    private elements: ExerciseBaseTypes.AnswerElement[];
    private temp_matrix: any[];
    private answerDivBase: string = '';
    private firstClick: boolean = false;
    private firstCell: HTMLElement;
    private secondCell: HTMLElement;
    private foundWordsCounter: number;
    private foundWordsDiv: HTMLElement;
    private foundWordsList: any[];
    private table: HTMLTableElement;
    private answerContainer: HTMLElement;

    private allOrientations = ['horizontal','vertical','diagonal'];

    private orientations = {
        horizontal:     function(x: any,y: any,i: any) { return {x: x+i, y: y  }; },
        vertical:       function(x: any,y: any,i: any) { return {x: x,   y: y+i}; },
        diagonal:       function(x: any,y: any,i: any) { return {x: x+i, y: y+i}; },
    };

    private checkOrientations = {
        horizontal:     function(x: any,y: any,h: any,w: any,l: any) { return w >= x + l; },
        vertical:       function(x: any,y: any,h: any,w: any,l: any) { return h >= y + l; },
        diagonal:       function(x: any,y: any,h: any,w: any,l: any) { return (w >= x + l) && (h >= y + l); },
    };

    private skipOrientations = {
        horizontal:     function(x: any,y: any,l: any) { return {x: 0,   y: y+1  }; },
        vertical:       function(x: any,y: any,l: any) { return {x: 0,   y: y+100}; },
        diagonal:       function(x: any,y: any,l: any) { return {x: 0,   y: y+1  }; },
    };

    initExercise(params: ExerciseParams): void {
        if (!params.element || !params.exercise) return;
        super.initExercise(params);

        let exercise: WordFinderExerciseData = params.exercise;      
        this.elements = JSON.parse(JSON.stringify(exercise.elements));
        this.foundWordsList = [];
        this.foundWordsCounter = 0;
        let puzzle: any = this.fillPuzzle();
        if (puzzle == null) {
            return;
        }
        puzzle = this.fillBlanks(puzzle);
        this.temp_matrix = puzzle;

        this.root.classList.add("wordfinder");
        let exerciseWrapper = this.root.appendChild(document.createElement("div"));
        exerciseWrapper.classList.add("exercise-wrapper");
        this.setIllustration(exercise, exerciseWrapper);
        //console.log("exerice", exercise);

        let exerciseControl = exerciseWrapper.appendChild(document.createElement("div"));
        exerciseControl.setAttribute("class", "exercise-control");

        let exerciseContainer = exerciseWrapper.appendChild(document.createElement("div"));
        exerciseContainer.setAttribute("class", "exercise-container");

        let areaWrap = exerciseContainer.appendChild(document.createElement("div"));
        areaWrap.classList.add("area-wrap");

        let answerWrap = exerciseContainer.appendChild(document.createElement("div"));
        answerWrap.classList.add("answer-wrap");

        let answerControls = answerWrap.appendChild(document.createElement("div"));
        answerControls.classList.add("answer-controls");

        let answerContainer = answerWrap.appendChild(document.createElement("div"));
        answerContainer.classList.add("answer-container");

        answerContainer.setAttribute("id", "answer-container");
        answerContainer.setAttribute('aria-role', 'list');
        answerContainer.tabIndex = 0;
        answerContainer.setAttribute('data-alabel', __('Válaszok terület és elemei'));

        let foundWordsDiv = areaWrap.appendChild(document.createElement("div"));
        foundWordsDiv.classList.add("wordfinder-found-word-list");
        this.foundWordsDiv = foundWordsDiv;

        let wordfinderLetters = areaWrap.appendChild(document.createElement("div"));
        wordfinderLetters.classList.add("wordfinder-letters");
        //tablediv.classList.add("auto-cells");

        let tableContainer = wordfinderLetters.appendChild(document.createElement("div"));
        tableContainer.classList.add("wordfinder-table-container");

        let wordfinderTable = tableContainer.appendChild(document.createElement("table"));
        wordfinderTable.classList.add("wordfinder-table");
        wordfinderTable.setAttribute("data-zoom", "0");
        if (!this.temp_matrix) return;
        for (let i = 0; i < this.temp_matrix.length; i++) {
            let tr = wordfinderTable.appendChild(document.createElement("tr"));
            tr.classList.add("wordfinder-tr");
            for (let j = 0; j < this.temp_matrix[i].length; j++) {
                let td = tr.appendChild(document.createElement("td"));
                td.classList.add("wordfinder-td");
                td.innerText = this.temp_matrix[i][j];
                td.setAttribute("data-row-index", i.toString());
                td.setAttribute("data-col-index", j.toString());

                if (!this.isReplay) {
                    td.onclick = this.cellClick.bind(this, td);
                    td.onmouseover = this.cellHover.bind(this, td);
                }

            }
        }

        this.table = wordfinderTable;

        /* Nagyitas */
        /* Alap kicsinyitesi arany */
        let scaleContainer = tableContainer;
        let exerciseScale = this.xGetFitScale(scaleContainer);

        if (exerciseScale != 1) {
            let initExerciseThis = this;
            scaleContainer.style.transform = "scale(" + exerciseScale + ")";
            /* Nagyito gomb */
            this.root.classList.add("wordfinder-zoomable");
            let zoomButton = exerciseControl.appendChild(document.createElement("button"));
            zoomButton.classList.add("exercise-zoom");
            /* Gomb esemeny + nagyitas */
            zoomButton.addEventListener("click", function (event) {
                exerciseScale = initExerciseThis.xGetFitScale(scaleContainer);

                let zoom = parseInt(scaleContainer.getAttribute('data-zoom')!);
                zoom = (zoom < 2) ? zoom + 1 : 0;
                let zoomScale = 1;

                switch (zoom) {
                    case 0:
                        zoomScale = exerciseScale;
                        break;
                    case 1:
                        zoomScale = exerciseScale + (1 - exerciseScale) / 2;
                        break;
                    default:
                        zoomScale = 1;
                }

                scaleContainer.style.transform = "scale(" + zoomScale + ")";
                //exerciseContainer.setAttribute('data-zoom', zoom)
                scaleContainer.setAttribute('data-zoom', String(zoom));
            }, false);
        } else {
            exerciseControl.setAttribute("hidden", "true");
        }
        /* Nagyitas - End */

        if (!exercise.elements || !exercise.show_possible_words) return;

        this.answerDivBase = "answer-div-";
        let answerList = exercise.elements.slice();

        for (let ndxAnswer = 0; ndxAnswer < answerList.length; ndxAnswer++) {
            if (answerList[ndxAnswer].text == "" && answerList[ndxAnswer].image == "") continue;
            let elementClassList: string[] = [];
            let answerdiv: HTMLElement = answerContainer.appendChild(document.createElement("div"));
            if (answerList[ndxAnswer].type == "image") {
                elementClassList = ["img-answer-div"];
                if (params.simple_style) answerdiv.classList.add("answer-div-simple");
            }
            else if (answerList[ndxAnswer].type == "sound") {
                answerdiv.classList.add("string-answer-div", "answer-div");
            }
            else {
                if (answerList[ndxAnswer].text[0] == "$")
                    answerdiv.classList.add("latex-add");
            }

            AExerciseEngine.displayAnswer(answerdiv, answerList[ndxAnswer], this.is_accessible, elementClassList, this);

            answerdiv.classList.add("answer-div");
            answerdiv.setAttribute("id", this.answerDivBase + ndxAnswer);
            answerdiv.setAttribute("element-index", ndxAnswer.toString());
            answerdiv.setAttribute('aria-grabbed', 'false');
            answerdiv.setAttribute('aria-role', 'button');
            answerdiv.setAttribute('aria-haspopup', 'true');
            answerdiv.setAttribute('aria-dropeffect', 'move');
            answerdiv.tabIndex = 0;
            if (answerList[ndxAnswer].type == "text") AExerciseEngine.shrinkAndGrow(answerdiv);
        }

        let answerLeftButton = answerControls.appendChild(document.createElement("button"));
        answerLeftButton.classList.add("control", "answer-control-left");

        let answerRightButton = answerControls.appendChild(document.createElement("button"));
        answerRightButton.classList.add("control", "answer-control-right");

        answerRightButton.addEventListener("click", AExerciseEngine.xMove.bind(this, answerContainer, 'right'), false);
        answerLeftButton.addEventListener("click", AExerciseEngine.xMove.bind(this, answerContainer, 'left'), false);
        this.answerContainer = answerContainer;
    }

    /* Arany szamolo fuggveny */
    xGetFitScale(container: HTMLDivElement) {
        let scrollWidth = container.scrollWidth;
        let scrollHeight = container.scrollHeight;
        let offsetWidth = container.offsetWidth;
        let offsetHeight = container.offsetHeight;

        let scale = Math.min(
            offsetWidth / scrollWidth,
            offsetHeight / scrollHeight
        );

        return scale;
    }

    getUserSolution() {
        let solution = { answer: null, fullmatch: true };
        return solution;
    }

    receiveEvaluation(evaluated: Evaluated): void {
    }

    showCorrectSolution(solution: any) {

    }

    isUserReady(): boolean {
        return true;
    }

    showHelp(solution: any): void {
        this.showCorrectSolution(solution);
    }

    cellClick(td: HTMLElement) {
        this.firstClick = !this.firstClick;
        if (!td) return;
        if (this.firstClick) { 
            this.firstCell = td; 
            this.firstCell.classList.add("eke-engine-clicked")
        }

        if (!this.firstClick && this.firstCell) {
            this.secondCell = td;
            this.secondCell.classList.add("eke-engine-clicked");
            let scndRow = td.getAttribute("data-row-index");
            let scndCol = td.getAttribute("data-col-index");
            let firstRow = this.firstCell.getAttribute("data-row-index");
            let firstCol = this.firstCell.getAttribute("data-col-index");
            if (!scndRow && !scndCol && !firstRow && !firstCol) return;
            let firstCoords: MatrixCell = { row: Number(firstRow), col: Number(firstCol) };
            let secondCoords: MatrixCell = { row: Number(scndRow), col: Number(scndCol) };
            //Horizontal word
            if (firstCoords.row == secondCoords.row && firstCoords.col < secondCoords.col) {
                this.evaluateWord(firstCoords, secondCoords, MatrixDirection.Horizontal);
            }//Vertical word
            else if (firstCoords.col == secondCoords.col && firstCoords.row < secondCoords.row) {
                this.evaluateWord(firstCoords, secondCoords, MatrixDirection.Vertical);
            }//Diagonal
            else if(firstCoords.row < secondCoords.row && firstCoords.col < secondCoords.col && secondCoords.row - firstCoords.row == secondCoords.col - firstCoords.col) {
                this.evaluateWord(firstCoords, secondCoords, MatrixDirection.Diagonal);
            }
            else {
                this.firstCell.classList.remove("eke-engine-clicked");
                this.secondCell.classList.remove("eke-engine-clicked");
                let tempFirstCell = this.firstCell;
                let tempSecondCell = this.secondCell;
                tempFirstCell.classList.add("exe-engine-check-wrong");
                tempSecondCell.classList.add("exe-engine-check-wrong");
                setTimeout(() => {
                    tempFirstCell.classList.remove("exe-engine-check-wrong");
                    tempSecondCell.classList.remove("exe-engine-check-wrong");
                }, 1500);
            }
        }
    }

    cellHover(td: HTMLElement) {
        if (!td) return;

        if (this.firstClick && this.firstCell) {
            this.secondCell = td;
            let scndRow = td.getAttribute("data-row-index");
            let scndCol = td.getAttribute("data-col-index");
            let firstRow = this.firstCell.getAttribute("data-row-index");
            let firstCol = this.firstCell.getAttribute("data-col-index");
            if (!scndRow && !scndCol && !firstRow && !firstCol) return;

            let firstCoords: MatrixCell = { row: Number(firstRow), col: Number(firstCol) };
            let secondCoords: MatrixCell = { row: Number(scndRow), col: Number(scndCol) };

            // Remove hover classes
            this.root.querySelectorAll(".eke-engine-hover").forEach((e) => {
                e.classList.remove("eke-engine-hover");
            });

            //Horizontal word
            if (firstCoords.row == secondCoords.row && firstCoords.col < secondCoords.col) {
                this.setHoverRange(firstCoords, secondCoords, MatrixDirection.Horizontal);
            }//Vertical word
            else if (firstCoords.col == secondCoords.col && firstCoords.row < secondCoords.row) {
                this.setHoverRange(firstCoords, secondCoords, MatrixDirection.Vertical);
            }//Diagonal
            else if(firstCoords.row < secondCoords.row && firstCoords.col < secondCoords.col && secondCoords.row - firstCoords.row == secondCoords.col - firstCoords.col) {
                this.setHoverRange(firstCoords, secondCoords, MatrixDirection.Diagonal);
            }
        }
    }

    setHoverRange(firstCoords: MatrixCell, secondCoords: MatrixCell, direction: MatrixDirection) {
        let lastIndex = secondCoords.row;
        let startIndex = firstCoords.row;
        let colIndex = firstCoords.col;
        if (direction == MatrixDirection.Horizontal) {
            lastIndex = secondCoords.col;
            startIndex = firstCoords.col;
        }
        for (let i = startIndex; i <= lastIndex; i++) {
            if (direction == MatrixDirection.Horizontal)
                this.table.rows[firstCoords.row].cells[i].classList.add("eke-engine-hover");
            else if (direction == MatrixDirection.Vertical)
                this.table.rows[i].cells[firstCoords.col].classList.add("eke-engine-hover");
            else
                this.table.rows[i].cells[colIndex].classList.add("eke-engine-hover");
            colIndex++;
        }
    }

    evaluateWord(firstCoords: MatrixCell, secondCoords: MatrixCell, direction: MatrixDirection) {
        let tempWord: string = "";
        this.firstCell.classList.remove("eke-engine-clicked");
        this.secondCell.classList.remove("eke-engine-clicked");

        // Remove hover classes
        this.root.querySelectorAll(".eke-engine-hover").forEach((e) => {
            e.classList.remove("eke-engine-hover");
        });


        if (direction == MatrixDirection.Horizontal) {
            for (let i = firstCoords.col; i <= secondCoords.col; i++) {
                tempWord += this.temp_matrix[firstCoords.row][i];
            }
        }
        else if(direction == MatrixDirection.Vertical) {
            for (let i = firstCoords.row; i <= secondCoords.row; i++) {
                tempWord += this.temp_matrix[i][firstCoords.col];
            }
        }
        else if(direction == MatrixDirection.Diagonal) {
            let colIndex = firstCoords.col;
            for (let i = firstCoords.row; i <= secondCoords.row; i++) {
                tempWord += this.temp_matrix[i][colIndex];
                colIndex++;
            }
        }

        if (this.evaluatedAlready(firstCoords, secondCoords, direction)) return;

        let foundWordIndex = this.exercise.elements.findIndex((elem:any) => {
            return elem.text == tempWord;
        });
        
        if (foundWordIndex > -1 && this.exercise.elements[foundWordIndex] && !this.foundWordsList.includes(this.exercise.elements[foundWordIndex].text)) {
            let foundWord: string = this.exercise.elements[foundWordIndex].text;
            this.foundWordsList.push(foundWord);
            let newFoundWord = this.foundWordsDiv.appendChild(document.createElement("p"));
            newFoundWord.innerText = foundWord;
            this.foundWordsCounter++;
            if (this.table) {
                this.addEvalStyles(firstCoords, secondCoords, direction, true);
            }

            if (this.exercise.show_possible_words) {
                for (let i = 0; i < this.answerContainer.children.length; i++) {
                    let elemIndx = this.answerContainer.children[i].getAttribute("element-index");
                    if (Number(elemIndx) == foundWordIndex)
                        this.answerContainer.children[i].remove();
                }
            }

            if (this.foundWordsCounter == this.elements.length) this.SNIEvaluation(WordFinderGameServer);
        }
        else {
            if (this.table) {
                this.addEvalStyles(firstCoords, secondCoords, direction, false);

                setTimeout(() => {
                    let lastIndex = secondCoords.row;
                    let startIndex = firstCoords.row;
                    let colIndex = firstCoords.col;
                    if (direction == MatrixDirection.Horizontal) {
                        lastIndex = secondCoords.col;
                        startIndex = firstCoords.col;
                    }
                    for (let i = startIndex; i <= lastIndex; i++) {
                        if (direction == MatrixDirection.Horizontal)
                            this.table.rows[firstCoords.row].cells[i].classList.remove("exe-engine-check-wrong");
                        else if (direction == MatrixDirection.Vertical)
                            this.table.rows[i].cells[firstCoords.col].classList.remove("exe-engine-check-wrong");
                        else
                            this.table.rows[i].cells[colIndex].classList.remove("exe-engine-check-wrong");
                        colIndex++;
                    }
                }, 1500);
            }
        }
    }

    //correct set true when needs correct eval styles false when wrong needed
    addEvalStyles(firstCoords: MatrixCell, secondCoords: MatrixCell, direction: MatrixDirection, correct: boolean) {
        let lastIndex = secondCoords.row;
        let startIndex = firstCoords.row;
        let colIndex = firstCoords.col;
        let classToBeAdded: string = correct ? "exe-engine-check-correct" : "exe-engine-check-wrong";
        if (direction == MatrixDirection.Horizontal) {
            lastIndex = secondCoords.col;
            startIndex = firstCoords.col;
        }
        for (let i = startIndex; i <= lastIndex; i++) {
            if (direction == MatrixDirection.Horizontal) 
                this.table.rows[firstCoords.row].cells[i].classList.add(classToBeAdded);
            else if (direction == MatrixDirection.Vertical)
                this.table.rows[i].cells[firstCoords.col].classList.add(classToBeAdded);
            else
                this.table.rows[i].cells[colIndex].classList.add(classToBeAdded);
            colIndex++;
        }
    }

    //checks if word was evaluated already, for the words that might contain other words
    evaluatedAlready(firstCoords: MatrixCell, secondCoords: MatrixCell, direction: MatrixDirection) : boolean {
        let lastIndex = secondCoords.row;
        let startIndex = firstCoords.row;
        let colIndex = firstCoords.col;
        if (direction == MatrixDirection.Horizontal) {
            lastIndex = secondCoords.col;
            startIndex = firstCoords.col;
        }
        let lengthCount = 0;
        let correctCount = 0;
        for (let i = startIndex; i <= lastIndex; i++) {
            lengthCount++;
            if (direction == MatrixDirection.Horizontal && this.table.rows[firstCoords.row].cells[i].classList.contains("exe-engine-check-correct")){
                correctCount++
            }
            else if (direction == MatrixDirection.Vertical && this.table.rows[i].cells[firstCoords.col].classList.contains("exe-engine-check-correct")) {
                correctCount++
            }
            else if (direction == MatrixDirection.Diagonal && this.table.rows[i].cells[colIndex].classList.contains("exe-engine-check-correct")) {
                correctCount++
            }
            colIndex++;
        }

        return lengthCount == correctCount;
    }

    fillPuzzle(): any[] | null {
        let options = { 
            height: this.exercise.dimensions.rows, 
            width: this.exercise.dimensions.columns,
            orientations: this.allOrientations,
            preferOverlap: true,

        };
        let words: any[] = [];
        this.elements.forEach((elem) => {
            words.push(elem.text);
        });
        let puzzle: any[] = [];
        // initialize the puzzle with blanks
        let counter = 0;
        let maxTries = 20;
        while(counter < maxTries) {
            let tempPuzzle: any[] = [];
            let success = true;
            for (let i = 0; i < options.height; i++) {
                tempPuzzle.push([]);
                for (let j = 0; j < options.width; j++) {
                    tempPuzzle[i].push("");
                }
            }

            // add each word into the puzzle one at a time
            for (let i = 0, len = words.length; i < len; i++) {
                if (!this.placeWordInPuzzle(tempPuzzle, options, words[i])) {
                    success = false;
                    break;
                }
            }
            if (success) { 
                puzzle = tempPuzzle;
                break;
            }
            else {
                counter++;
            }
        }
        if (puzzle.length <= 0) return null;

        return puzzle;
    }

    placeWordInPuzzle(puzzle: any[], options: any, word: string) : boolean {
        // find all of the best locations where this word would fit
        let locations = this.findBestLocations(puzzle, options, word);
        if (locations.length === 0) {
            return false;
        }
        // select a location at random and place the word there
        let sel = locations[Math.floor(Math.random() * locations.length)];
        this.placeWord(puzzle, word, sel.x, sel.y, this.orientations[sel.orientation]);
        return true;
    }

    findBestLocations(puzzle:any[], options:any, word: string) {
        let locations = [];
        let height = options.height;
        let width = options.width;
        let wordLength = word.length;
        let maxOverlap = 0; // we'll start looking at overlap = 0
  
        // loop through all of the possible orientations at this position
        for (let k = 0, len = options.orientations.length; k < len; k++) {

            let orientation = options.orientations[k];
            let check = this.checkOrientations[orientation];
            let next = this.orientations[orientation];
            let skipTo = this.skipOrientations[orientation];
            let x = 0;
            let y = 0;
            // loop through every position on the board
            while( y < height ) {
            // see if this orientation is even possible at this location
                if (check(x, y, height, width, wordLength)) {
                    // determine if the word fits at the current position
                    let overlap = this.calcOverlap(word, puzzle, x, y, next);
                    // if the overlap was bigger than previous overlaps that we've seen
                    if (overlap >= maxOverlap || (!options.preferOverlap && overlap > -1)) {
                        maxOverlap = overlap;
                        locations.push({x: x, y: y, orientation: orientation, overlap: overlap});
                    } 
                    x++;
                    if (x >= width) {
                        x = 0;
                        y++;
                    }
                } else {
                    // if current cell is invalid, then skip to the next cell where
                    // this orientation is possible. this greatly reduces the number
                    // of checks that we have to do overall
                    let nextPossible = skipTo(x,y,wordLength);
                    x = nextPossible.x;
                    y = nextPossible.y;
                }
            }
        }
        // finally prune down all of the possible locations we found by
        // only using the ones with the maximum overlap that we calculated
        return options.preferOverlap ?
            this.pruneLocations(locations, maxOverlap) :
            locations;
    };

    calcOverlap(word: string, puzzle: any[], x: number, y: number, fnGetSquare: (x:any,y: any,i: any) => any) {
        let overlap = 0;
        // traverse the squares to determine if the word fits
        for (let i = 0, len = word.length; i < len; i++) {
            let next = fnGetSquare(x, y, i);
            let square = puzzle[next.y][next.x];
            // if the puzzle square already contains the letter we
            // are looking for, then count it as an overlap square
            if (square === word[i]) {
                overlap++;
            }
            // if it contains a different letter, than our word doesn't fit
            // here, return -1
            else if (square !== '' ) {
                return -1;
            }
        }
        // if the entire word is overlapping, skip it to ensure words aren't
        // hidden in other words
        return overlap;
    };

    pruneLocations(locations: any[], overlap: any) {
        let pruned = [];
        for(let i = 0, len = locations.length; i < len; i++) {
            if (locations[i].overlap >= overlap) {
                pruned.push(locations[i]);
            }
        }
        return pruned;
    };

    placeWord(puzzle: any[], word: string, x: number, y: number, fnGetSquare: (x:any,y: any,i: any) => any) {
        for (let i = 0, len = word.length; i < len; i++) {
            let next = fnGetSquare(x, y, i);
            puzzle[next.y][next.x] = word[i];
        }
    };

    fillBlanks(puzzle: any[]) {
        let characters = 'AÁBCDDDEÉFGHIÍJKLMNNOÓÖŐPQRSTUÚÜŰVWXYZ';
        for (let i = 0, height = puzzle.length; i < height; i++) {
            let row = puzzle[i];
            for (let j = 0, width = row.length; j < width; j++) {
                if (!puzzle[i][j]) {
                    let randChar = characters.charAt(Math.floor(Math.random() * characters.length));
                    puzzle[i][j] = randChar;
                }
            }
        }
        return puzzle;
    }
}
