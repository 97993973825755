import { Server } from '../../server/Server';
import View , { registerViewClassProxy, ViewClassProxy } from '../View';
import { IListParams } from '@framework/crud/Crud';

export interface IViewSecTableInfoPermRecord {
    /**
     * Azonosító
     *
     * Azonosító (pótkulcs)
     */
    id?: number;
    /**
     * Rekord létrehozás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel létrehozásakor.
     */
    creation_time?: string /*timestamp?*/;
    /**
     * Ki hozta létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozó felhasználóra hivatkozik.
     */
    creation_user_id?: number;
    /**
     * Melyik session-ből jött létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozáshoz használt munkamenetre hivatkozik.
     */
    creation_session_id?: number|null;
    /**
     * Utolsó módosítás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel módosításakor.
     */
    modification_time?: string /*timestamp?*/|null;
    /**
     * Ki módosította utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosító felhasználóra hivatkozik.
     */
    modification_user_id?: number|null;
    /**
     * Melyik session-ből módosították utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosításhoz használt munkamenetre hivatkozik.
     */
    modification_session_id?: number|null;
    /**
     * Táblázat információ
     */
    table_info_id?: number;
    /**
     * Engedély birtokos
     */
    sec_perm_owner_id?: number;
    /**
     * Létrehozás
     *
     * Új rekord létrehozása
     */
    can_create?: boolean;
    /**
     * Módosítás
     *
     * Létező rekord módosítása
     */
    can_update?: boolean;
    /**
     * Törlés
     *
     * Létező rekordok törlése
     */
    can_delete?: boolean;
    /**
     * Olvasás
     *
     * Létező rekordok lekérdezése, szűrése
     */
    can_read?: boolean;
    /**
     * Séma név
     *
     * Séma név
     */
    schema_name?: string|null;
    /**
     * Táblázat név
     *
     * Táblázat név
     */
    table_name?: string|null;
    /**
     * Kijelzett név
     *
     * A táblázat neve magyar nyelven
     */
    display_name?: string|null;
    /**
     * Leírás
     *
     * A táblázat leírása magyar nyelven
     */
    description?: string|null;
    /**
     * Felhasználó azonosító
     *
     * Felhasználó azonosító
     */
    user_id?: number|null;
    /**
     * Bejelentkezési név
     *
     * Bejelentkezési név
     */
    login_name?: string|null;
    /**
     * E-mail
     *
     * E-mail
     */
    email?: string|null;
    /**
     * Személy azonosító
     *
     * Személy azonosító
     */
    person_id?: number|null;
    /**
     * Teljes név
     *
     * Teljes név
     */
    fullname?: string|null;
    /**
     * Csoport azonosító
     *
     * Csoport azonosító
     */
    group_id?: number|null;
    /**
     * Csoport név
     *
     * A csoport neve
     */
    group_title?: string|null;
    /**
     * Csoport leírás
     *
     * Csoport leírás
     */
    group_description?: string|null;
    /**
     * Csoport típus
     *
     * A csoport típus azonosítója
     */
    sec_group_type_id?: number|null;
    /**
     * Csoport típus
     *
     * A csoport típus neve
     */
    sec_group_type_title?: string|null;
    /**
     * A jogosult neve
     *
     * A csoport vagy felhasználó humán azonosítására szolgáló szöveg
     */
    perm_owner_label?: string|null;
    /**
     * Létrehozó
     */
    creator?: string|null;
    /**
     * Módosító
     */
    modifier?: string|null;
}

/**
 *  meta.view_sec_table_info_perm - Táblázat jog
 *
 *  A CRUD alrendszer jogosultságait tárolja.
 */

export default class ViewSecTableInfoPerm extends View<IViewSecTableInfoPermRecord> {
    public static VIEW_INFO_ID = 2018050406;

    public static getSchemaNameForClass(): string { return 'meta'; }
    public static getViewNameForClass(): string { return 'view_sec_table_info_perm'; }
    public static getTableNameForClass(): string|null { return 'sec_table_info_perm'; }

    public static load: (id: number, aServer ?: Server) => Promise<ViewSecTableInfoPerm>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<IViewSecTableInfoPermRecord[]>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class ViewSecTableInfoPermClassProxy extends ViewClassProxy<IViewSecTableInfoPermRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const viewSecTableInfoPermClassProxy = new ViewSecTableInfoPermClassProxy(ViewSecTableInfoPerm);
registerViewClassProxy(viewSecTableInfoPermClassProxy);

