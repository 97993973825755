export class WMItemToImageServer{
    static evaluateOnServer(exercise: any, correctSolution: any, userSolution: any): Evaluated {
        let success: boolean = true;
        let goodAnswers = 0;

        var correctSolutionCopy = JSON.parse(JSON.stringify(correctSolution))
        var userSolutionCopy = JSON.parse(JSON.stringify(userSolution))

        let userIndexesJoined: any[] = [];
        let correctIndexesJoined: any = [];
        //merging the correct solution arrays without duplication because that is an array of arrays
        for (let i = 0; i < correctSolutionCopy.length; i++) {
            for (let j = 0; j < correctSolutionCopy[i].length; j++) {
                if (correctIndexesJoined.indexOf(correctSolutionCopy[i][j]) == -1)
                    correctIndexesJoined.push(correctSolutionCopy[i][j]);
            }
        }
        //merging the user solution arrays without duplication because that is an array of arrays
        for (let i = 0; i < userSolutionCopy.length; i++) {
            for (let j = 0; j < userSolutionCopy[i].length; j++) {
                if (userIndexesJoined.indexOf(userSolutionCopy[i][j]) == -1)
                    userIndexesJoined.push(userSolutionCopy[i][j]);
            }
        }

        userIndexesJoined = userIndexesJoined.sort();
        correctIndexesJoined = correctIndexesJoined.sort();

        //getting oddoneout elements indexes
        let oddOneOutIndexes: number[] = [];
        for (let i = 0; i < exercise.exercise.options.length; i++) {
            if (correctIndexesJoined.indexOf(i) == -1) oddOneOutIndexes.push(i);
        }

        //checking if the two index arrays have the same elements
        /*for (let index = 0; index < userIndexesJoined.length; index++) {
            if (userIndexesJoined[index] != correctIndexesJoined[index]) {
                success = false;
                break;
            } else {
                goodAnswers++;
            }

        }*/

        //Checking odd one outs
        for (let i = 0; i < oddOneOutIndexes.length; i++) {
            if(userIndexesJoined.indexOf(oddOneOutIndexes[i]) > -1) {
                success = false;
                goodAnswers--;
            }
        }

        if (userIndexesJoined.length != correctIndexesJoined.length) success = false;

        for (let i = 0; i < correctSolutionCopy.length; i++) {
            var alreadyChecked: string[] = [];
            for (let j = 0; j < userSolutionCopy[i].length; j++) {
                //either the user guess is not part of the correct solution or the part of it, but that area has already has an element with the same text or imgage, it must be declared as false
                if ((correctSolutionCopy[i].indexOf(userSolutionCopy[i][j]) == -1)
                    || (correctSolutionCopy[i].indexOf(userSolutionCopy[i][j]) > -1 && alreadyChecked.indexOf(exercise.exercise.options[userSolution[i][j]].type == "image" ? exercise.exercise.options[userSolution[i][j]].image : exercise.exercise.options[userSolution[i][j]].text) > -1)
                ) {
                    success = false;
                    break;
                } else {
                    goodAnswers++;
                }

                //collecting the user guesses into a string array
                alreadyChecked.push(exercise.exercise.options[userSolution[i][j]].type == "image" ? exercise.exercise.options[userSolution[i][j]].image : exercise.exercise.options[userSolution[i][j]].text)
            }
        }
        let successPercent = Number((goodAnswers/(exercise.exercise.options.length-oddOneOutIndexes.length)).toFixed(2));
        if(successPercent < 0) successPercent = 0;
        return { "success": success, "solution": correctSolution, "successPercent": successPercent };
    }
}