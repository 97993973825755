import * as React from 'react';

import OoFolderCrud from "@src/framework/crud/media/OoFolderCrud";
import OoFileCrud from "@src/framework/crud/media/OoFileCrud";
import BookCrud from "@src/framework/crud/doc/BookCrud";
import ViewOoFileInBook from "@src/framework/view/doc/ViewOoFileInBook";
import ViewDocSectionFileRef, { IViewDocSectionFileRefRecord } from "@src/framework/view/doc/ViewDocSectionFileRef";
import { showDialog } from "@src/component/Dialog";
import ViewMediaOoFolder, { IViewMediaOoFolderRecord } from '@src/framework/view/media/ViewMediaOoFolder';

export const MEDIA_TYPE_IMAGE = 1;
export const MEDIA_TYPE_VIDEO = 2;
export const MEDIA_TYPE_IFRAME_3D = 3;
export const MEDIA_TYPE_IFRAME_MAP = 4;
export const MEDIA_TYPE_IFRAME_SIMULATION = 5;
export const MEDIA_TYPE_SOUND = 6;
export const MEDIA_TYPE_IFRAME_EXERCISE = 7;
export const MEDIA_TYPE_IMAGE_GRAPHIC = 8;
export const MEDIA_TYPE_IMAGE_PHOTO = 9;
export const MEDIA_TYPE_IMAGE_MAP = 10;
export const MEDIA_TYPE_PDF = 11;

export const MEDIA_TYPE_IMAGES = [
    MEDIA_TYPE_IMAGE, MEDIA_TYPE_IMAGE_GRAPHIC, MEDIA_TYPE_IMAGE_PHOTO, MEDIA_TYPE_IMAGE_MAP
];

export const MEDIA_TYPE_IFRAMES = [
    MEDIA_TYPE_IFRAME_3D, MEDIA_TYPE_IFRAME_MAP, MEDIA_TYPE_IFRAME_SIMULATION, MEDIA_TYPE_IFRAME_EXERCISE
];


export type MakeFoldersResult = {
    record: IViewMediaOoFolderRecord;
    wasCreated: boolean;
    child: MakeFoldersResult | null;
}

export interface IMakeFolderTreeResultItem {
    record: IViewMediaOoFolderRecord;
    wasCreated: boolean;
}

export type MakeFolderTreeResult = {
    [relPath: string]: IMakeFolderTreeResultItem
}

/* A makeFolderTree hívja vissza az aktuális progress-el. */
export type MakeFolderTreeProgress = (total: number, current: number, item: IMakeFolderTreeResultItem) => void;


/**
 * Mappa útvonal létrehozása.
 * 
 * A megadott útvonalon levő elemeket létrehozza, ha még nem léteznek.
 * 
 * Ezt csak akkor hívd meg, ha egyetlen konkrét útvonal létezését akarod
 * biztosítani. Ha egyszerre sok útvonal halmazát (egy egész fát) akarsz
 * létrehozni, akkor arra használd a makeFolderTree-t.
 * 
 * TODO ellenőrizni, hogy van-e már ilyen nevű fájl a cél mappában
 * TODO ha van ilyen mappa, akkor azt használni
 * 
 * @param folderPath: Egy olyan relatív útvonal, ami könyvtár név elemekből áll.
 *      Tehát az elemek NEM tartalmaznak / jeleket.
 */
export async function makeFolders(folderPath: string[], parentFolderId: number | null): Promise<MakeFoldersResult | null> {
    if (folderPath.length == 0) {
        return Promise.resolve(null);
    }
    const folderToCreate = folderPath[0];
    const records = await ViewMediaOoFolder.list({ filter: { is_active: true, parent_id: parentFolderId, title: folderToCreate } });

    if (records.length > 0) {
        const ooFolderId = records[0].id!;
        return Promise.resolve({
            record: (await ViewMediaOoFolder.load(ooFolderId)).record,
            wasCreated: false,
            child: await makeFolders(folderPath.slice(1), ooFolderId)
        });
    } else {
        const newFolder = (await new OoFolderCrud({
            parent_id: parentFolderId,
            title: folderToCreate,
        }).put()).record;
        return Promise.resolve({
            record: (await ViewMediaOoFolder.load(newFolder.id!)).record,
            wasCreated: true,
            child: await makeFolders(folderPath.slice(1), newFolder.id!)
        });
    }
}


/*
 Hasonlít a makeFolders-re, de ez egy lépésben sok mappát hoz létre
 (egy egész mappa struktúrát).  Minden mappát legfeljebb egyszer kérdez le,
 ezért sokkal gyorsabb. A paramétere 

 * @param folderPaths: Egy olyan tömb, aminek az elemei komplett relatív útvonalak
 *  (tehát tartalmaznak / jeleket is). Ezek az elemek NEM tartalmazhatnak kezdő / jelet,
 *  és befejező / jelet sem!
 */

export async function makeFolderTree(folderPaths: string[], folderId: number | null, onProgress: MakeFolderTreeProgress): Promise<MakeFolderTreeResult> {
    // Létrehozunk egy olyan változatot, amiben minden szükséges könyvtár pontosan
    // egyszer benne van.
    let flattened: string[] = [];
    for (let i = 0; i < folderPaths.length; i++) {
        const relPath = folderPaths[i];
        const relPathItems = relPath.split("/");
        for (let i2 = 1; i2 <= relPathItems.length; i2++) {
            const container = relPathItems.slice(0, i2).join("/");
            if (flattened.lastIndexOf(container) < 0) {
                flattened.push(container)
            }
        }
    }
    // Rendezzük, így biztosan a szükséges létrehozás sorrendjében lesznek.
    flattened.sort();
    let result: MakeFolderTreeResult = {};
    for (let i = 0; i < flattened.length; i++) {
        const relPath = flattened[i];
        const relPathItems = relPath.split("/")
        const title = relPathItems[relPathItems.length - 1];
        let parentId: number | null;
        if (relPathItems.length > 1) {
            const parentRelPath = relPathItems.slice(0, -1).join("/");
            parentId = result[parentRelPath].record.id!;
        } else {
            parentId = folderId;
        }
        const records = await ViewMediaOoFolder.list({
            filter: { is_active: true, parent_id: parentId, title: title }
        });
        let record: IViewMediaOoFolderRecord;
        let wasCreated: boolean;
        if (records.length > 0) {
            const ooFolderId = records[0].id!;
            record = (await ViewMediaOoFolder.load(ooFolderId)).record;
            wasCreated = false;
        } else {
            const newFolder = (await new OoFolderCrud({
                parent_id: parentId, title: title
            }).put()).record;
            record = (await ViewMediaOoFolder.load(newFolder.id!)).record;
            wasCreated = true;
        }
        const item = { record, wasCreated };
        result[relPath] = item;
        onProgress(flattened.length, i + 1, item);
    }
    return Promise.resolve(result);
}



/**
 * Mappa átnevezése. Hiba esetén visszaadja a hiba okát.
 * TODO ellenőrizni, hogy van-e már ilyen nevű fájl vagy mappa a cél mappában
 */
export async function renameFolder(folderId: number, newTitle: string) {
    await new OoFolderCrud({ id: folderId, title: newTitle }).put();
}

/**
 * Mappa átnevezése. Hiba esetén visszaadja a hiba okát.
 * TODO ellenőrizni, hogy használja-e valami a fájlt (doc.section_file_ref)
 * TODO ellenőrizni, hogy van-e már ilyen nevű fájl vagy mappa a cél mappában
 */
export async function renameFile(fileId: number, newTitle: string) {
    await new OoFileCrud({ id: fileId, title: newTitle }).put();
}


/**
 * Mappák mozgatása új szülő mappába.
 * TODO ellenőrizni, hogy rekurzívan önmagába akarjuk-e mozgatni
 * TODO ellenőrizni, hogy van-e már ilyen nevű fájl vagy mappa a cél mappában
 * (TODO: ellenőrizni, hogy bármely al-fájl használva van-e)
 */
export async function moveFolders(folderIds: number[], targetFolderId: number) {
    for (const folderId of folderIds) {
        await new OoFolderCrud({ id: folderId, parent_id: targetFolderId }).put();
    }
}

/**
 * Fájl mozgatása új mappába
 * TODO ellenőrizni, hogy használja-e valami a fájlt (doc.section_file_ref)
 * TODO ellenőrizni, hogy van-e már ilyen nevű fájl vagy mappa
 */
export async function moveFiles(fileIds: number[], targetFolderId: number) {
    await ifFilesUsedShowErrorAndThrow(fileIds, "Nem lehet mozgatni, mert már használatban van!");

    for (const fileId of fileIds) {
        await new OoFileCrud({ id: fileId, oo_folder_id: targetFolderId }).put();
    }
}


/**
 * Mappák másolása új szülő mappába
 * TODO ellenőrizni, hogy van-e már ilyen nevű fájl a cél mappában
 * TODO ha van már ilyen mappa, akkor nem kell létrehozni, csak rekurzívan belemenni
 */
export async function copyFolders(folderIds: number[], targetFolderId: number) {

    for (const folderId of folderIds) {
        const folder = (await OoFolderCrud.load(folderId)).record;
        const subFiles = await OoFileCrud.list({ filter: { oo_folder_id: folderId, is_active: true } });
        const subFolders = await OoFolderCrud.list({ filter: { parent_id: folderId, is_active: true } });

        const newFolder = { parent_id: targetFolderId, title: folder.title }
        const createdFolder = (await new OoFolderCrud(newFolder).put()).record;
        
        await copyFiles(subFiles.map(x => x.id!), createdFolder.id!);
        await copyFolders(subFolders.map(x => x.id!), createdFolder.id!);
    }
}

/**
 * Fájlok másolása új mappába
 * TODO ellenőrizni, hogy van-e már ilyen nevű fájl vagy mappa a cél mappában
 */
export async function copyFiles(fileIds: number[], targetFolderId: number) {
    for (const fileId of fileIds) {

        var file = (await OoFileCrud.load(fileId)).record;

        const newFile = { oo_folder_id: targetFolderId }
        // Csak ezeket a mezőket másoljuk át. A régi verzió az owner-t 
        // a creator session-t meg a relpath-ot is elküldte meg hasonlókat.
        const fnames: string[] = [
            'ext', 'has_thumb', 'height_px', 'is_active', 'keywords',
            'media_type_id', 'origin', 'origin_id', 'origin_url',
            'size', 'title', 'width_px', 'sha1'
        ];
        fnames.forEach((fname: string) => { newFile[fname] = file[fname]; });
        await new OoFileCrud(newFile as any).put();
    }
}

/**
 * Mappa törlése
 * (TODO: ellenőrizni, hogy bármely al-fájl használva van-e)
 */
export async function deleteFolders(folderIds: number[]) {
    for (const folderId of folderIds) {
        await new OoFolderCrud({ id: folderId }).del();
    }
}

/**
 * Fájl törlése
 * TODO ellenőrizni, hogy bármi használja-e a fájlt (doc.section_file_ref)
 */
export async function deleteFiles(fileIds: number[]) {
    await ifFilesUsedShowErrorAndThrow(fileIds, "Nem lehet törölni, mert már használatban van!");

    for (const fileId of fileIds) {
        await new OoFileCrud({ id: fileId }).del();
    }
}

export async function ifFilesUsedShowErrorAndThrow(fileIds: number[], errorMessage: string) {
    var fileRefs: IViewDocSectionFileRefRecord[] = [];
    for (const fileId of fileIds) {
        fileRefs = fileRefs.concat(await ViewDocSectionFileRef.list({ filter: { oo_file_id: fileId } }));
    }
    if (fileRefs.length > 0) {
        await showDialog(errorMessage, <div style={{ padding: "1em" }}>
            <table>
                <thead>
                    <tr>
                        <th>Filenév</th>
                        <th>Könyv</th>
                        <th>Fejezet</th>
                        <th>Lecke</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        fileRefs.map(f => {
                            return <tr key={f.id}>
                                <td>{f.file_title}</td>
                                <td>{f.book_name}</td>
                                <td>{f.chapter_name}</td>
                                <td>{f.lesson_name}</td>
                            </tr>;
                        })
                    }
                </tbody>
            </table>
        </div>, { width: 800, height: 300 });
        throw "Már használatban van";
    }
}

export async function getFileUrlInBook(file: { id?: number, relpath?: string }) {
    if (!file.relpath || !file.id) return null;

    const fileInBook = (await ViewOoFileInBook.load(file.id)).record;
    if (!fileInBook) return null;

    const split = file.relpath.split("/");
    const bookRelpath = split[0] + "/" + split[1];

    const book = (await BookCrud.load(fileInBook.book_id!)).record;

    const path = "/tankonyv/" + book.uri_segment + file.relpath!.replace(bookRelpath, "");

    return path;
}
