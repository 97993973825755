import * as React from 'react';
import ReactTable, { TableProps, Column, RowInfo } from 'react-table';
import 'react-table/react-table.css';
import { getReactTableLabels } from '@src/framework/i18n';
import './ExerciseList.css';
import { Link, match } from 'react-router-dom';
import * as queryString from 'query-string';
import AccessibilitySelectComponent from '@src/framework/forms/accessibility_select.tsx';
import SubjectCrud, { ISubjectRecord } from '@crud/doc/SubjectCrud'
import EngineCrud, { IEngineRecord, engineCrudClassProxy } from '@src/framework/crud/exc/EngineCrud';
import * as PrivExerciseCrud from '@src/framework/crud/exc/ExerciseCrud';
import * as PubExerciseCrud from '@src/framework/crud/exc_pub/ExerciseCrud';
import * as PrivateViewExercise from '@src/framework/view/exc/ViewExcExercise';
import ViewExcExercise, * as PublicViewExercise from '@src/framework/view/exc_pub/ViewExcExercise';
import CrudSelect from '@framework/forms//crudselect';
import { subjectCrudClassProxy } from '@framework/crud/doc/SubjectCrud';
import { gradeCrudClassProxy } from '@framework/crud/doc/GradeCrud';
import * as ExerciseTypeConverter from "../models/ExerciseTypeConverter";
import { debounce } from 'lodash'
import { app } from '@src/index';
import { hasGroup, Groups, hasAnyGroup, me } from '@framework/server/Auth';
import {
  PATH_EXERCISE_ENGINE_SELECT,
  PATH_EXERCISE_EDIT,
  PATH_EXERCISE_VIEW,
  PATH_EXERCISE_PUB_VIEW,
  PATH_GUIDE_EDITOR,
  getPath
}
  from '@src/Routes';
import { getPlayData, copyPublishedExercise } from '@src/server/PublicServer';
import OoFileCrud from '@src/framework/crud/media/OoFileCrud';
import { stationCrudClassProxy } from '@src/framework/crud/wf/StationCrud';
import { WF_TYPE_OKOSFELADAT_ID } from '@src/Const';

/* Engine onverters */
import { AExerciseTypeConverter } from '@src/component/exercise/models/AExerciseTypeConverter';
import { IViewTransitionTypeRecord } from '@src/framework/view/wf/ViewTransitionType';
import WfAPI from '@src/framework/wf/WfAPI';
import obtainServer, { config } from '@src/framework/server/Server';
import ViewWfWorkflow, { IViewWfWorkflowRecord } from '@src/framework/view/wf/ViewWfWorkflow';
import LevelSelectComponent from '@src/framework/forms/level_select';
import { LIBRARY_OFI_OFFICIAL_ID, LIBRARY_PERSONAL_ID } from '@src/Const';
import { getWfStateCell } from '../../wf/wf_util';
import { PermissionCache, WfDeleteTransitionInfo, ViewPermissionType } from '@src/framework/security/PermissionCache';
import { TFilterDict, StringSearchKind } from '@src/framework/crud/Crud';
import UsrShareDialog from '../../usr/UsrShareDialog';
import { Accordion, AccordionItem } from '@src/component/ui/Accordion';
import { ISharedContentRecord } from '@src/framework/crud/usr/SharedContentCrud';
import { formatDate } from '@src/Util';
import { Dialog, confirmDialog, promptDialog } from '../../Dialog';

import { RadioTab, RadioTabs } from '@src/component/ui/RadioTab';
import * as ExerciseBaseTypes from "@src/component/exercise/models/ExerciseBaseClass";
import * as ExerciseServer from '@src/component/exercise/ExerciseServer';
import PermissionPage from '@src/framework/PermissionPage';
import { __, getLanguageId } from '@src/translation';
import ViewMyExcExercise, { IViewMyExcExerciseRecord } from '@src/framework/view/exc/ViewMyExcExercise';
import { IExerciseRecordView } from '../models/IExerciseRecordView';
import ViewEditorExcExercise from '@src/framework/view/exc/ViewEditorExcExercise';
import CollectionItemCrud from '@src/framework/crud/usr/CollectionItemCrud';
import FavouriteCrud from '@src/framework/crud/usr/FavouriteCrud';
import { exerciseModule } from '@src/module/exerciseModule/exerciseModule';
import EngineTranslationCrud, { engineTranslationCrudClassProxy, IEngineTranslationRecord } from '@src/framework/crud/exc/EngineTranslationCrud';
import { langTranslationCrudClassProxy } from '@src/framework/crud/sys/LangTranslationCrud';

type ExerciseSearchParams = {
  internal_code?: string,
  search?: string,
  nokeywords?: boolean,
  notinbook?: boolean,
  noresult?: boolean,
  keywords?: string,
  description?: string,
  subjectId?: number,
  gradeId?: number,
  engineId?: number,
  stationId?: number,
  selectedExeLevel?: number | null,
  filters: any[],
  sorted: any[],
  pageSize: number,
  page: number,
  libraryId?: number,
  isSNI?: boolean,
  accessibility?: number | null,
  origin?: ExerciseOriginTypes,
  lang_id?: number;
}


var saved: ExerciseSearchParams = {
  subjectId: undefined,
  gradeId: undefined,
  engineId: undefined,
  stationId: undefined,
  search: undefined,
  keywords: undefined,
  description: undefined,
  nokeywords: undefined,
  notinbook: undefined,
  internal_code: undefined,
  noresult: undefined,
  selectedExeLevel: undefined,
  libraryId: undefined,
  filters: [],
  sorted: [],
  pageSize: 10,
  page: 0,
  isSNI: undefined,
  accessibility: null,
  lang_id: undefined
}

type ExerciseListProps = {
  match?: match<any>,
  onExerciseSelected?: (exerciseRecord: PrivateViewExercise.IViewExcExerciseRecord, tableInfoId?: number) => void,
  grade_id?: any,
  subject_id?: any,
  library_id?: any,
  isPublicDisabled?: boolean
  openInNewTab?: boolean;
  onlyPublished?: boolean;
}

interface IExerciseListState {
  // Keresés
  searchParams: ExerciseSearchParams,
  exerciseRecords: IExerciseRecordView[],
  count: number,
  loading: boolean,
  exerciseEngines: IEngineRecord[],
  exerciseEngineTranslations: IEngineTranslationRecord[],
  shareExerciseId: number | null;
  shareExerciseName: string | undefined;
  sharedContentId: number | null | undefined;
  deleteTransitionInfo?: WfDeleteTransitionInfo;
  checked: Set<number>,
  showForcePublishingDialog: boolean
  publishPercent: number;
  showReopenDialog: boolean;
}

enum ExerciseOriginTypes {
  OWN = 1,
  OFFICIAL = 2,
  COLLECTION_PUBLISHED = 3,
  FAVORITS_PUBLISHED = 4,
  ALL_PUBLISHED = 5,
}
export default class ExerciseList extends React.Component<ExerciseListProps, IExerciseListState> {

  constructor(props: any) {
    super(props);

    let originValue = ExerciseOriginTypes.OWN;
    let libId = this.props.library_id || saved.libraryId;
    if (libId == LIBRARY_OFI_OFFICIAL_ID) {
      originValue = ExerciseOriginTypes.OFFICIAL;
    } else if (libId == 0) {
      originValue = ExerciseOriginTypes.ALL_PUBLISHED;
    }

    this.state = {
      searchParams: {
        search: saved.search || '',
        keywords: saved.keywords || '',
        description: saved.description || '',
        internal_code: saved.internal_code || '',
        subjectId: props.subject_id || saved.subjectId,
        gradeId: props.grade_id || saved.gradeId,
        engineId: saved.engineId,
        stationId: saved.stationId || props.stationId || undefined,
        nokeywords: saved.nokeywords || false,
        selectedExeLevel: props.selectedExeLevel || saved.selectedExeLevel,
        noresult: saved.noresult || false,
        notinbook: saved.notinbook || false,
        libraryId: this.props.library_id || saved.libraryId || LIBRARY_PERSONAL_ID,
        pageSize: saved.pageSize,
        page: saved.page,
        sorted: saved.sorted,
        filters: saved.filters,
        isSNI: saved.isSNI || false,
        accessibility: null,
        origin: originValue,
        lang_id: saved.lang_id
      },
      exerciseRecords: [],

      count: 0,
      loading: true,

      exerciseEngines: [],
      exerciseEngineTranslations: [],

      shareExerciseId: null, sharedContentId: undefined,
      shareExerciseName: "",

      checked: new Set([]),
      showForcePublishingDialog: false,
      publishPercent: 0,

      showReopenDialog: false
    };
  }

  componentDidMount() {
    this.reloadAsync();
  }

  componentDidUpdate(prevProps: ExerciseListProps) {
    if (prevProps.match != this.props.match) {
      this.reloadAsync();
    }
  }

  onExerciseSelect(exerciseRecord: PrivateViewExercise.IViewExcExerciseRecord) {
    if (this.props.onExerciseSelected) {
      if (this.props.isPublicDisabled && this.state.searchParams.libraryId == 0) return;
      if (this.state.searchParams.libraryId == LIBRARY_PERSONAL_ID) {
        this.props.onExerciseSelected(exerciseRecord, PrivExerciseCrud.default.TABLE_INFO_ID);
      } else {
        this.props.onExerciseSelected(exerciseRecord, PubExerciseCrud.default.TABLE_INFO_ID);
      }
    } else {
      app.showError(__("Hiba!"), __("Nem lehet feladatot választani!"));
    }
  }

  getIcon(curr_engine: any) {
    var engineIcon = (curr_engine ? curr_engine.name : "NA");
    return <div><img src={`/img/IKON_SET/FELADATMOTOR/Exercise_engine_icons/${curr_engine.class_name}.svg`} className="eke-header-icons" /> {curr_engine.name}</div>;
  }

  private copyPublishedExercise = async (exerciseId: number, exerciseName: string) => {
    try {
      //A másolni kívánt feladat szerepel -e már a saját feladataim között (NAME alapján, mert nem tároljuk az eredeti feladat id másolásnál)
      let count = await ViewMyExcExercise.count({ filter: { name: exerciseName } });
      if (count > 0) {
        if (!await confirmDialog(__(`Feladat másolása: ${exerciseName}`),
          __(`Ilyen című feladat már szerepel a Saját feladatok között. Biztosan kér újabb másolatot?`),
          __("Igen"))) return;
      }
      const newExercise = await copyPublishedExercise(exerciseId, LIBRARY_PERSONAL_ID);
      this.reloadAsync();
      app.showSuccess(__("Feladat másolása"), __("Sikeres"))
    } catch (error) {
      app.showErrorFromJsonResult(error);
    }
  }

  async reloadAsync() {

    try {
      this.setState({
        loading: true
      })

      let deleteTransitionInfo: WfDeleteTransitionInfo = await PermissionCache.loadWfDelTrans(WF_TYPE_OKOSFELADAT_ID);
      var subjects: ISubjectRecord[];
      try {
        subjects = await SubjectCrud.list({ filter: { is_active: true }, order_by: "name" });

      } catch (e) {
        console.log(e);
        return;
      }

      let exerciseEngines = await EngineCrud.list({ order_by: "name" });
      let exerciseEngineTranslations = await EngineTranslationCrud.list({ filter: { is_active: true, lang_id: getLanguageId() } });

      var filter: TFilterDict = {
        site_id: config.mainServer.siteId
      };
      let countCrudFilter = true;

      if (this.state.searchParams.libraryId !== 0 && this.state.searchParams.libraryId && (this.state.searchParams.libraryId == LIBRARY_OFI_OFFICIAL_ID || this.state.searchParams.libraryId == LIBRARY_PERSONAL_ID))
        filter.is_active = true;

      if (this.state.searchParams.gradeId) {
        filter.grade_id = Number(this.state.searchParams.gradeId);
      }
      if (this.state.searchParams.subjectId) {
        filter.subject_id = Number(this.state.searchParams.subjectId);
      }
      if (this.state.searchParams.engineId) {
        filter.engine_id = Number(this.state.searchParams.engineId);
      }
      if (this.state.searchParams.stationId) {
        filter.wf_station_id = Number(this.state.searchParams.stationId);
        countCrudFilter = false;
      }
      if (this.state.searchParams.selectedExeLevel) {
        filter.level = this.state.searchParams.selectedExeLevel;
        countCrudFilter = false;
      }
      if (this.state.searchParams.search) {
        filter.name = { kind: StringSearchKind.WordSearch, case_sensitive: false, expr: this.state.searchParams.search };
        countCrudFilter = false;
      }
      if (this.state.searchParams.accessibility) {
        if (this.state.searchParams.accessibility == -1)//false
          filter.is_accessible = false;
        else if (this.state.searchParams.accessibility == 1)//true or null
        {
          filter = {
            ...filter,
            $or: {
              is_accessible: null,
              $not: { is_accessible: true }
            },
            $not: { engine_id: ExerciseTypeConverter.ExerciseEngineTypeCheck.notAccessibleEnginesId }
          };
        }
      }
      if (this.state.searchParams.noresult) {
        filter.search_result_count = 0;
      }

      if (this.state.searchParams.nokeywords) {
        filter.keywords = null;
      }
      else {
        if (this.state.searchParams.keywords && this.state.searchParams.keywords.length > 0 && this.state.searchParams.keywords != "") {
          filter.search_text = { kind: StringSearchKind.WordSearch, case_sensitive: false, expr: this.state.searchParams.keywords };
          countCrudFilter = false;
        }
      }

      if (this.state.searchParams.description && this.state.searchParams.description.length > 0 && this.state.searchParams.description != "") {
        filter.description = { kind: StringSearchKind.WordSearch, case_sensitive: false, expr: this.state.searchParams.description };
        countCrudFilter = false;
      }

      if (this.state.searchParams.internal_code && this.state.searchParams.internal_code.length > 0 && this.state.searchParams.internal_code != "") {
        filter.internal_code = { kind: StringSearchKind.WordSearch, case_sensitive: false, expr: this.state.searchParams.internal_code };
        countCrudFilter = false;
      }

      if (this.state.searchParams.isSNI == true) {
        filter.is_sni = true;
      }

      if (this.state.searchParams.lang_id) {
        filter.lang_id = this.state.searchParams.lang_id;
      }

      /* if (this.props.isEmbedded) {
         filter.library_id = this.props.library_id;
       }*/

      for (const userFilter of this.state.searchParams.filters) {
        if (userFilter.id == "name") {
          filter.$text = {
            fieldNames: this.state.searchParams.libraryId == 0 ? ["name", "grade_name", "subject_name", "description"] : ["name", "grade_name", "subject_name", "internal_code", "description"],
            expression: {
              kind: StringSearchKind.WordSearch,
              case_sensitive: false,
              expr: userFilter.value
            }
          }
        } else {
          filter[userFilter.id] = { kind: StringSearchKind.WordSearch, case_sensitive: false, expr: userFilter.value }
        }
        countCrudFilter = false;
      }

      // filtering for collections
      if (me && this.state.searchParams.origin == ExerciseOriginTypes.COLLECTION_PUBLISHED) {
        let collectionItems = (await CollectionItemCrud.list({ filter: { table_info_id: PubExerciseCrud.default.TABLE_INFO_ID, creation_user_id: me.id, is_active: true } }));
        let recordIds = collectionItems.map(el => el.rec_id!);
        filter.id = recordIds;
      }

      //filtering for favourits
      if (me && this.state.searchParams.origin == ExerciseOriginTypes.FAVORITS_PUBLISHED) {
        let collectionItems = (await FavouriteCrud.list({ filter: { table_info_id: PubExerciseCrud.default.TABLE_INFO_ID, sec_user_id: me.id } }));
        let recordIds = collectionItems.map(el => el.rec_id!);
        filter.id = recordIds;
      }

      var orderBy: any = undefined;
      if (this.state.searchParams.sorted.length > 0) {
        const userOrder = this.state.searchParams.sorted[0];

        if (userOrder.desc) {
          orderBy = [{ name: userOrder.id, desc: true }];
        } else {
          orderBy = userOrder.id
        }
      }

      if (!orderBy && this.state.searchParams.libraryId != 0) { orderBy = [{ name: "creation_time", desc: true }]; }

      let spec = {}
      if (this.state.searchParams.notinbook) {
        spec["notinbook"] = true;
      }

      var listParameters = {
        filter, spec,
        order_by: orderBy,
        limit: this.state.searchParams.pageSize,
        offset: this.state.searchParams.page * this.state.searchParams.pageSize
      };

      var filterParams: Partial<TFilterDict> = {
        filter: JSON.stringify(filter),
        order_by: JSON.stringify(orderBy),
        limit: 3,
        offset: 0,
        library_id: this.state.searchParams.libraryId
      }
      let countParams = { filter, spec };


      var exerciseRecords: IExerciseRecordView[];
      var count: number;

      if (this.state.searchParams.libraryId == LIBRARY_PERSONAL_ID && me) {
        exerciseRecords = await ViewMyExcExercise.list(listParameters);
        count = await ViewMyExcExercise.count(countParams);
      } else if (this.state.searchParams.libraryId == LIBRARY_OFI_OFFICIAL_ID && me) {
        exerciseRecords = await ViewEditorExcExercise.list(listParameters);
        count = await ViewEditorExcExercise.count(countParams);
      } else {
        exerciseRecords = await PublicViewExercise.default.list(listParameters);
        if (countCrudFilter) {
          count = await PubExerciseCrud.default.count(countParams);
        }
        else {
          count = await PublicViewExercise.default.count(countParams);
        }
          
      }

      this.setState({ count: count });
      var subject = null;
      var gradeName = null;
      var station_name = null;
      let ex_index = listParameters.offset - 1;

      for (var exerciseRecord of exerciseRecords) {
        const engine = exerciseEngines.find(e => e.name == exerciseRecord.engine_name);
        const engTranslated = engine ? exerciseEngineTranslations.find(e => e.engine_id == engine.id) : null;
        const engine_name = engTranslated ? engTranslated.name : "Nan";

        const descriptionDiv = document.createElement("div");
        descriptionDiv.innerHTML = exerciseRecord.description || "";
        filterParams.offset = ex_index;
        exerciseRecord["filter_params"] = JSON.parse(JSON.stringify(filterParams));
        ex_index++;
        subject = exerciseRecord.subject_name;
        gradeName = exerciseRecord.grade_name;
        if (this.state.searchParams.libraryId != 0) {
          station_name = exerciseRecord.wf_station_name != null && exerciseRecord.wf_station_name.length > 0 ? exerciseRecord.wf_station_name : __("Saját feladat");
          exerciseRecord["internal_code"] = exerciseRecord.internal_code;
          exerciseRecord["level"] = exerciseRecord.level;
          exerciseRecord["wf_station_name"] = station_name;
        }
        exerciseRecord["name"] = exerciseRecord.name;
        exerciseRecord["grade_name"] = (gradeName ? String(gradeName).replace(__("osztály"), "") : "NA");
        exerciseRecord["subject_name"] = (subject ? subject : "NA");
        exerciseRecord["description"] = descriptionDiv.innerText;
        exerciseRecord["engine_name"] = engine_name;//exerciseRecord.engine_name;
        exerciseRecord["engine_img"] = engine && engine.class_name && ExerciseTypeConverter.getIconOfEngine(engine.class_name, false, false);
        //todo: new column in case of origin = ExerciseOriginTypes = collection

        let editBtn = null;
        let delBtn = null;
        let shareBtn = null;
        let copyBtn = null;
        let canEdit: boolean = false;
        let canDel: boolean = false;
        let canShare: boolean = false;
        let canCopy: boolean = false;
        if (this.props.onExerciseSelected) {
          editBtn = <button title="Kiválaszt" className="button small" onClick={this.onExerciseSelect.bind(this, exerciseRecord)}><i className="fa fa-plus"></i></button>;
        } else if (this.state.searchParams.libraryId == 0) {
          canCopy = true;
          //copyBtn = <button className="button small primary" title={__("Másolás")} onClick={this.copyPublishedExercise.bind(this, exerciseRecord.id!, exerciseRecord.name)}><i className="fa fa-clone"></i></button>;
        } else {
          canEdit = await PermissionCache.hasWfEditPermission(WF_TYPE_OKOSFELADAT_ID, exerciseRecord.wf_station_id, me, exerciseRecord);
          canDel = await PermissionCache.hasWfDeletePermission(deleteTransitionInfo.deleteFromStationIds, me, exerciseRecord);
          canShare = ((exerciseRecord.library_id == LIBRARY_PERSONAL_ID) && me && (exerciseRecord.owner_id == me.id)) || false;
          canCopy = this.state.searchParams.libraryId == 0 || (exerciseRecord.is_published != undefined && exerciseRecord.is_published);

          if (canEdit) {
            editBtn = <Link className="button small" title={__("Szerkesztés")} to={getPath({ PATH_EXERCISE_EDIT }, exerciseModule) + `/${exerciseRecord.id}/`}><i className="fa fa-edit"></i></Link>;
          }
        }

        if (canDel) {
          delBtn = <button className="button small alert" title={__("Törlés")} onClick={this.deleteExercise.bind(this, exerciseRecord)}><i className="fa fa-trash"></i></button>;
        }
        if (canCopy) {
          copyBtn = <button className="button small primary" title={__("Másolás")} onClick={this.copyPublishedExercise.bind(this, exerciseRecord.id!, exerciseRecord.name)}><i className="fa fa-clone"></i></button>;
        }
        if (canShare) {
          shareBtn = <button className="button small success" title={__("Megosztás")}
            onClick={this.showShareDialog.bind(this, exerciseRecord)}><i className="fa fa-share-alt"></i></button>;
        }
        exerciseRecord["link"] = <div className="exercise-list-page-button-container">{editBtn} {shareBtn} {delBtn} {copyBtn} </div>;
      }

      this.setState({
        exerciseRecords,
        exerciseEngines: exerciseEngines,
        loading: false,
        deleteTransitionInfo,
        checked: new Set([])
      });
    }
    catch (error) {
      app.showErrorFromJsonResult(error);
    }
  }

  onFetchData(tablestate: any) {
    if (this.state.searchParams.filters != tablestate.filtered) {
      this.debouncedSetTableState(tablestate);
    } else {
      this.setTableState(tablestate);
    }
  }

  debouncedSetTableState = debounce((tablestate) => this.setTableState(tablestate), 300);

  private setTableState(tablestate: any) {

    saved.page = tablestate.page;
    saved.pageSize = tablestate.pageSize;
    saved.sorted = tablestate.sorted;
    saved.filters = tablestate.filtered;

    this.setState({
      searchParams: {
        ...this.state.searchParams,
        page: tablestate.page,
        pageSize: tablestate.pageSize,
        sorted: tablestate.sorted,
        filters: tablestate.filtered
      }
    }, this.reloadAsync);
  }
  private onExeAccessSelect = (event: React.FormEvent<HTMLSelectElement>): void => {
    const tempaccess: any = event.currentTarget.value ? Number(event.currentTarget.value) : undefined;
    saved.accessibility = tempaccess;
    const newVal = event.currentTarget.value ? Number(event.currentTarget.value) : null;
    this.setState({ searchParams: { ...this.state.searchParams, accessibility: newVal } });
    this.reloadAsync();
  }

  private onExeLevelSelect = (event: React.FormEvent<HTMLSelectElement>): void => {
    const templev: any = event.currentTarget.value ? Number(event.currentTarget.value) : undefined;
    saved.selectedExeLevel = templev;
    const newVal = event.currentTarget.value ? Number(event.currentTarget.value) : null;
    this.setState({ searchParams: { ...this.state.searchParams, selectedExeLevel: newVal } });
    this.reloadAsync();
  }
  private onGradeSelect = (sender: CrudSelect, newGrade: number | null): void => {
    const gradeId: any = newGrade;
    saved.gradeId = gradeId;
    this.setState({ searchParams: { ...this.state.searchParams, gradeId: gradeId } });
    this.reloadAsync();
  }
  private onSubjectSelect = (sender: CrudSelect, newSubject: number | null): void => {
    const subjectId: any = newSubject;
    saved.subjectId = subjectId;
    this.setState({ searchParams: { ...this.state.searchParams, subjectId: subjectId } });
    this.reloadAsync();
  }
  private onEngineSelect = (sender: CrudSelect, newEngine: number | null): void => {
    const engine: any = newEngine;
    saved.engineId = engine;
    this.setState({ searchParams: { ...this.state.searchParams, engineId: engine } });
    this.reloadAsync();
  }
  private onStationSelect = (sender: CrudSelect, newStation: number | null): void => {
    const station: any = newStation;
    saved.stationId = station;
    this.setState({ searchParams: { ...this.state.searchParams, stationId: station } });
    this.reloadAsync();
  }

  private onSelectLang = (sender: CrudSelect, newLang: number): void => {
    saved.lang_id = newLang;
    this.setState({ searchParams: { ...this.state.searchParams, lang_id: newLang } });
    this.reloadAsync();
  }

  private onToggleLibraryId = (value: any) => {
    saved.libraryId = 0;
    saved.origin = value;
    // todo: use the enum

    if (value == ExerciseOriginTypes.OWN) {
      saved.libraryId = LIBRARY_PERSONAL_ID;
    } else if (value == ExerciseOriginTypes.OFFICIAL) {
      saved.libraryId = LIBRARY_OFI_OFFICIAL_ID;
    } else {
      saved.libraryId = 0;
    }


    if (this.state.searchParams.origin != saved.origin) {
      this.setState({
        searchParams:
        {
          ...this.state.searchParams,
          libraryId: saved.libraryId!,
          origin: saved.origin,
          stationId: saved.libraryId == 0 ? undefined : saved.stationId,
          sorted: [],
          internal_code: saved.libraryId == 0 ? undefined : saved.internal_code,
          noresult: saved.libraryId == 0 ? undefined : saved.noresult
        }
      }, () => { this.reloadAsync() });
    }
  }

  onHandleInputChange(event: any) {
    const target = event.target;

    var value = target.value;
    if (target && target.type === 'checkbox') {
      value = target.checked;
    } else if (target && (target.type == 'select-one' || target.getAttribute("data-type") == "number")) {
      value = Number(target.value);
    }
    let name = target.name;
    saved[name] = value;
    this.setState({ searchParams: { ...this.state.searchParams, [name]: value } });
    this.reloadAsync();
  }


  private getDeleteTransitionTypesForStation = (stationId: number): IViewTransitionTypeRecord[] => {
    let deleteTransitions = [];
    const tt = this.state.deleteTransitionInfo!.activeTransitionTypes;
    if (tt) {
      for (let i = 0; i < tt.length; i++) {
        let transitionType = tt[i];
        if (transitionType.src_station_id == stationId) {
          if (transitionType.dst_is_deleted!) {
            deleteTransitions.push(transitionType);
          }
        }
      }
    }
    return deleteTransitions;

  }

  async deleteExercise(selectedExercise: PrivateViewExercise.IViewExcExerciseRecord) {

    if (!selectedExercise) {
      alert(__("Nem található az adott elem."));
      return;
    }

    if (!await confirmDialog(__("Megerősítés"), __("Biztos, hogy törölni szeretné ezt a feladatot: {exercise}?", { exercise: selectedExercise.name }))) return;

    // Kikeressük a törlő transition type-okat.
    const stationId = selectedExercise.wf_station_id;
    if (stationId) {
      // Folyamatban részt vevő feladat, folyamat átmenettel töröljük.
      let possibleTransitions = this.getDeleteTransitionTypesForStation(stationId);
      if (possibleTransitions.length > 1) {
        // TODO: mi lenne ha itt egyből megnyitnánk ?
        app.showError(
          __("Hiba"),
          __('Egynél több célállapot elérhető.') + " " +
          __('A törlés végrehajtásához nyissa meg a folyamat dialógus ablakot.')
        );
        return;
      }
      const transitionType: IViewTransitionTypeRecord = possibleTransitions[0];

      let justification = prompt(__('Kérem írja be az indoklást'), '');
      this.setState({ loading: true });
      let wfApi = new WfAPI(obtainServer());
      try {
        let workflowAfter: IViewWfWorkflowRecord = await wfApi.makeTransition(
          selectedExercise!.wf_workflow_id!,
          transitionType.id!,
          justification
        );
        this.reloadAsync();
        app.showSuccess(__("Feladat törlése"), __("Sikeres"))

      } catch (error) {
        app.showErrorFromJsonResult(error);
        this.setState({ loading: false });
      };
    } else {
      try {
        await PrivExerciseCrud.default.deleteById(selectedExercise.id!);
        this.reloadAsync();
        app.showSuccess(__("Feladat törlése"), __("Sikeres"))
      } catch (error) {
        app.showErrorFromJsonResult(error);
        this.setState({ loading: false });
      }
    };
  }

  private showShareDialog = (exercise: PrivateViewExercise.IViewExcExerciseRecord) => {
    this.setState({ shareExerciseId: exercise.id!, sharedContentId: undefined, shareExerciseName: exercise.name })
  }


  async getTypeId() {

  }
  async tempMigrateOldNkp() {
    var exerciseEngineList = await EngineCrud.list({});
    var all_ex = await PrivExerciseCrud.default.list({ filter: { engine_id: 2, "$notnull": "nkp1_id" } });
    console.log("Konvertálandó feladatok száma: ", all_ex.length);
    var curr_ex_data;
    var curr_engine;
    var typeConverter;
    var new_contentVersion;

    for (var i = 0; i < all_ex.length; i++) {
      console.log("Konvertálás: " + i + "/" + all_ex.length);
      try {
        curr_ex_data = await getPlayData(String(all_ex[i].nkp1_id));
      }
      catch (e) {
        continue;
      }

      try {
        if (curr_ex_data.ContentVersionReferences) {
          let newUrl;
          for (let index = 0; index < curr_ex_data.ContentVersionReferences.length; index++) {
            new_contentVersion = await OoFileCrud.list({ filter: { nkp1_id: curr_ex_data.ContentVersionReferences[index].Id } })
            newUrl = AExerciseTypeConverter.fileBasePath + new_contentVersion[0].sha1;
            curr_ex_data.ContentVersionReferences[index].ThumbnailUrl = newUrl;
          }

        }
        curr_engine = exerciseEngineList.find(function (element: IEngineRecord) { return element.id == all_ex[i].engine_id });

        let engneName = curr_engine!.class_name;

        var curr_converter = ExerciseTypeConverter.TypeConverterAssinment.Converter.find((element: ExerciseTypeConverter.ExerciseEngineInfo) => element.EngineName == engneName);
        if (curr_converter) {
          typeConverter = curr_converter.TypeConverter;
          var new_ex_data = await typeConverter.convertOldNKPToJson(curr_ex_data, all_ex[i].oo_folder_id!);

          if (all_ex[i].is_active == false) {
            const wfApi = new WfAPI(obtainServer());
            const workflows = await ViewWfWorkflow.list({
              filter: { head_table_id: 381503895, rec_id: all_ex[i].id }
            });
            const workflow = workflows[0];
            await wfApi.makeTransition(workflow.id!, 16005777276, __("Automatikus régi NKP importálás"));
          }
          var response = await new PrivExerciseCrud.default({ id: all_ex[i].id, exercise: new_ex_data }).put();
        }
      }
      catch (e) {
        console.log("Something went wrong during parsing\n", e)
        continue;
      }
    }
    alert("Migration Finished!");
  }

  static async takeSolution(all_exercise: PrivExerciseCrud.IExerciseRecord[]) {

    for (let index = 0; index < all_exercise.length; index++) {
      console.log("Solution áthelyezés: " + index + "/" + all_exercise.length);
      try {
        if (!all_exercise[index].exercise.solution || JSON.stringify(all_exercise[index].exercise.solution) === JSON.stringify({})) // if the origin solution is empty
        {
          continue;
        }

        /*if ((all_exercise[index].solution.solution && JSON.stringify(all_exercise[index].solution.solution) !== JSON.stringify({}) && all_exercise[index].solution.solution.length > 0)) // if the destination solution is not empty
        {
          continue;
        }*/

        var sol = JSON.parse(JSON.stringify({ solution: all_exercise[index].exercise.solution }));

        let newExcJson = all_exercise[index].exercise;
        newExcJson.solution = {};

        var response = await new PrivExerciseCrud.default({ id: all_exercise[index].id, exercise: newExcJson, solution: sol }).put();
      }
      catch (e) {
        console.log(__("Valami probléma történt "), e)
      }
    }
  }

  static async tempReplaceSolutions() {

    var all_exercise = await PrivExerciseCrud.default.list({ limit: 10000, order_by: "id", offset: 0 });
    await ExerciseList.takeSolution(all_exercise);

    var all_exercise = await PrivExerciseCrud.default.list({ limit: 10000, order_by: "id", offset: 10000 });
    await ExerciseList.takeSolution(all_exercise);

    alert("Replacing Finished!");
  }

  static async tempReplaceSolOfItemToSet() {
    var all_ex = await PrivExerciseCrud.default.list({ filter: { engine_id: 2, "$notnull": "nkp1_id" } });
    await ExerciseList.takeSolution(all_ex);
    alert("Solutionök áthelyezése megtörtént!");
  }

  static async tempConvertMultiTextAnswer() {
    let exerciseEngineList = await EngineCrud.list({});

    let curr_engine = exerciseEngineList.find(function (element: IEngineRecord) {
      return element.class_name == ExerciseBaseTypes.ExerciseEngineTypes.MultiTextAnswer
    });

    let all_sorting: PrivExerciseCrud.IExerciseRecord[] = await PrivExerciseCrud.default.list({ filter: { engine_id: curr_engine!.id }, limit: 10000 });

    let curr_ex;

    for (let i = 0; i < all_sorting.length; i++) {
      curr_ex = all_sorting[i].exercise;
      let new_ansList = [];
      if (!curr_ex.answers || curr_ex.answers.length == 0) continue;

      for (let j = 0; j < curr_ex.answers.length; j++) {
        let new_first_item = {
          type: ExerciseBaseTypes.AnswerTypes.text,
          text: curr_ex.answers[j][0],
          image: "",
        }
        let new_end_item = {
          type: ExerciseBaseTypes.AnswerTypes.text,
          text: curr_ex.answers[j][1],
          image: "",
        }
        let new_ans = {
          first_item: new_first_item,
          end_item: new_end_item,
        }

        new_ansList.push(new_ans);
      }
      all_sorting[i].is_active = undefined;
      all_sorting[i].oo_folder_id = undefined;
      all_sorting[i].owner_id = undefined;
      all_sorting[i].exercise = { ...all_sorting[i].exercise, options: new_ansList };
      await ExerciseServer.putExerciseRecord(all_sorting[i]);
    }

    alert("Sorting Finished!");
  }

  static async tempConvertItemToImage() {
    var all_exercise = await PrivExerciseCrud.default.list({ filter: { engine_id: 1, }, limit: 10000, offset: 0 });

    for (let i = 0; i < all_exercise.length; i++) {
      if (JSON.stringify(all_exercise[i].exercise) == JSON.stringify({}) || JSON.stringify(all_exercise[i].solution) == JSON.stringify({}) || all_exercise[i].exercise.illustration == "" || all_exercise[i].solution.solution[0] instanceof Array)
        continue;

      var newSolution = [];

      let illustwait: any;
      var mainIllustrationwait = new Promise((resolve) => {
        illustwait = resolve;
      });

      var img = new Image();
      img.onload = () => illustwait(img.src);
      img.src = all_exercise[i].exercise.illustration[0] != "/" ? "/" + all_exercise[i].exercise.illustration : all_exercise[i].exercise.illustration;

      var response = await mainIllustrationwait;
      var newexercise = JSON.parse(JSON.stringify(all_exercise[i].exercise));

      for (let j = 0; j < all_exercise[i].exercise.areas.length; j++) {
        newexercise.areas[j].radius = Math.round(newexercise.areas[j].radius / img.width * 100);
      }

      for (let j = 0; j < all_exercise[i].solution.solution.length; j++) {
        var curr_sol = [];
        curr_sol.push(all_exercise[i].solution.solution[j]);
        newSolution.push(curr_sol);
      }

      var sol = { solution: newSolution };

      var upload = await new PrivExerciseCrud.default({ id: all_exercise[i].id, exercise: newexercise, solution: sol }).put();
    }

    alert("Converting Finished!");
  }

  private toggleChecked = (workflowId: number): void => {
    let checked = this.state.checked;
    if (checked.has(workflowId)) {
      checked.delete(workflowId);
    } else {
      checked.add(workflowId);
    }
    this.setState({ checked });
  }

  private checkAll = () => {
    if (this.state.checked.size < this.state.exerciseRecords!.length) {
      const allIds = this.state.exerciseRecords
        .filter(rec => (rec.wf_workflow_id ? true : false))
        .map(rec => rec.wf_workflow_id!);
      this.setState({ checked: new Set(allIds) });
    } else {
      this.setState({ checked: new Set([]) });
    }
  }

  private forcePublishChecked = () => {
    if (!confirm(
      __('Az összes kijelölt feladat publikálható állapotba kerül, a folyamat kezelési ') +
      __('szabályok megkerülésével, ezután publikálásra kerülnek. Biztos benne, hogy ezt akarja?'))) {
      return;
    }
    this.setState({ showForcePublishingDialog: true }, async () => {
      const wfApi: WfAPI = new WfAPI(obtainServer());
      try {
        const checked = this.state.checked;
        const it = checked.values();
        let item = it.next();
        let processed = 0;
        while (!item.done && this.state.showForcePublishingDialog) {
          const workflowId = item.value;
          const publishPercent = Math.round(1000.0 * processed / checked.size) / 10.0;
          this.setState({ publishPercent });
          await wfApi.forcePublishExercise(workflowId);
          processed += 1;
          item = it.next();
        }
        if (this.state.showForcePublishingDialog) {
          app.showSuccess(__("Sikeres műveletek"), __("A tömeges kényszerített publikálás sikeresen befejeződött."));
        } else {
          app.showWarning(__("Megszakítva"), __("A tömeges kényszerített publikálást a felhasználó megszakította."));
        }
        this.setState({ showForcePublishingDialog: false, checked: new Set([]) }, this.reloadAsync);
      } catch (error) {
        app.showErrorFromJsonResult(error);
        this.setState({ showForcePublishingDialog: false, checked: new Set([]) }, this.reloadAsync);
      }
    });
  }

  private async onTransitionToEdit() {
    const justification = await promptDialog(__("Visszanyitás szerkesztésre"), __("Kérem adja meg a visszanyitás okát!"), undefined, __("Visszanyitás"));
    if (!justification) {
      return;
    }

    let count = 0;

    this.setState({ showReopenDialog: true });
    try {
      const wfApi: WfAPI = new WfAPI(obtainServer());

      for (const workflowId of Array.from(this.state.checked)) {

        const transitions = await wfApi.listPossibleTransitions(workflowId);
        const transition = transitions.find(t => t.dst_is_start!);

        if (transition) {
          await wfApi.makeTransition(workflowId, transition.id!, justification!);
          count++;
        }

      }

      if (count > 0) app.showSuccess(__("Visszanyitás"), __(`{count} feladat visszanyitása sikeres.`, { _count: count }));

      this.reloadAsync();
    } catch (e) {
      app.showErrorFromJsonResult(e);
    }
    this.setState({ showReopenDialog: false, checked: new Set([]) });
  }

  render() {

    var filteredRecords = this.state.exerciseRecords;

    const enableLang = exerciseModule.getConfig().enableLangSelector;

    const getTdProps = (finalState: any, rowInfo: RowInfo, column?: Column, instance?: any) => {
      let props: Partial<TableProps> = {};

      if (column && rowInfo && rowInfo.row) {
        let row = rowInfo.row._original;
        if (row && column.id == "wf_station_name") {
          props.style = row.wf_station_style || {};
        }
      }
      return props;
    }

    const checkboxCount = this.state.exerciseRecords.filter(e => e.wf_workflow_id).length;

    let tableProps = {

      columns: [

        {
          Header: <div>
            <button
              title={__("Mindet kijelöl")}
              className="button secondary"
              onClick={this.checkAll}
              disabled={!this.state.exerciseRecords || !(this.state.exerciseRecords!.length)}
              style={{
                padding: 3,
                margin: 0
              }}
            >
              <i className="fa fa-check-double" />
            </button>
          </div>,
          accessor: "name",
          filterable: false,
          sortable: false,
          minWidth: 30,
          show: this.state.searchParams.libraryId != 0 && !this.props.onExerciseSelected,

          Cell: (data: any, column: any) => {
            const row: PrivateViewExercise.IViewExcExerciseRecord = data.original;
            return row.wf_workflow_id
              ? <input type="checkbox"
                value="1"
                checked={this.state.checked.has(row.wf_workflow_id!)}
                onChange={() => this.toggleChecked(row.wf_workflow_id!)}
              />
              : null;
          }
        },

        {
          Header: <div>
            <b>{__("Feladat címe")}</b><br />
            <small>{__("Évfolyam, tantárgy -")} <span style={{ fontFamily: "monospace", color: "green" }}>{__("Azonosító")}</span></small><br />
            <small style={{ color: "gray" }}>{__("Leírás")}</small>
          </div>,
          accessor: "name",
          minWidth: 200,

          Cell: (data: any, column: any) => {
            const row: PrivateViewExercise.IViewExcExerciseRecord = data.original;
            const baseUrl = this.state.searchParams.libraryId == 0 ? getPath({ PATH_EXERCISE_PUB_VIEW }, exerciseModule) : getPath({ PATH_EXERCISE_VIEW }, exerciseModule);
            return (this.props.openInNewTab ?
              <a target="_blank" href={baseUrl + `/${row.id}/?${queryString.stringify(data.original.filter_params)}`} style={{ width: "100%" }}>
                <b>{row.name}</b><br />
                <small>{row.grade_name} {row.subject_name}
                  {row.internal_code ? <span style={{ fontFamily: "monospace", color: "green" }}> - {row.internal_code}</span> : null}
                </small><br />
                <small style={{ color: "gray" }}>{row.description}</small>
              </a> :
              <Link to={baseUrl + `/${row.id}/?${queryString.stringify(data.original.filter_params)}`} style={{ width: "100%" }}>
                <b>{row.name}</b><br />
                <small>{row.grade_name} {row.subject_name}
                  {row.internal_code ? <span style={{ fontFamily: "monospace", color: "green" }}> - {row.internal_code}</span> : null}
                </small><br />
                <small style={{ color: "gray" }}>{row.description}</small>
              </Link>);
          }
        },
        { Header: <small title={__("Nehézségi szint")}>{__("Szint")}</small>, accessor: "level", maxWidth: 70, filterable: false, sortable: false, style: { justifyContent: "center" } },
        {
          Header: __("Típus"), accessor: "engine_name", filterable: false,
          Cell: (data: any, column: any) => {
            const row: any = data.original;
            return <div>
              <div><small><span><img src={row.engine_img} className="eke-controls-image" alt={row.engine_name!} /></span> {row.engine_name}</small></div>
              {row.in_series && <div><span className="label primary"
                  title={__("Feladatsorban szerepel")}
                >
                  <small>{__("Feladatsorban szerepel")}
                  </small>
                </span></div>}
            </div>;
          }
        },
        {
          Header: <small>{__("Tulajdonos")}<br />{__("Létrehozva")}</small>,
          accessor: "owner_person_fullname", filterable: true, maxWidth: 170,
          show: this.state.searchParams.libraryId != 0,
          Cell: (data: any, column: any) => {
            const row: PrivateViewExercise.IViewExcExerciseRecord = data.original;
            return row.owner_person_fullname ? <small><b>{row.owner_person_fullname}</b><br />{formatDate(row.creation_time)}</small> : ""
          }
        },
        {
          Header: __("Állapot"), accessor: "wf_station_name", filterable: false, show: this.state.searchParams.libraryId != 0,
          Cell: (data: any, column: any) => {
            const row: PrivateViewExercise.IViewExcExerciseRecord = data.original;
            return this.state.searchParams.libraryId != 0 ? getWfStateCell(data, column, row.is_published ?
              <>
                <br />
                <span className="label primary"
                  title={formatDate(row.last_published) + " " + row.last_publisher}
                >
                  <small>{__("Publikálva")}
                  </small>
                </span>
              </>
              : null
            ) : ""
          }
          , maxWidth: 100, style: { justifyContent: "left" }
        },
        { Header: __("Művelet"), accessor: "link", filterable: false, sortable: false, minWidth: 180, maxWidth: 180, className: "action-cell" },
      ],

      data: filteredRecords,
      defaultPageSize: this.state.searchParams.pageSize,
      defaultFiltered: this.state.searchParams.filters,
      defaultSorted: this.state.searchParams.sorted,
      pages: Math.ceil(this.state.count / this.state.searchParams.pageSize),
      filterable: true,
      className: "-striped -highlight",
      ...getReactTableLabels(),
      onFetchData: this.onFetchData.bind(this),
      loading: this.state.loading,
      getTdProps,
      manual: true
    };

    const radioTabs = [];

    if (!this.props.library_id || this.props.library_id === LIBRARY_PERSONAL_ID) {
      radioTabs.push(<RadioTab className={this.state.loading ? "disabled-input" : ""} selected={this.state.searchParams.origin == ExerciseOriginTypes.OWN} value={ExerciseOriginTypes.OWN}>
        {__("Saját feladataim")}
      </RadioTab>);
    }

    if (exerciseModule.getConfig().enableOfficialExercises && (me && hasGroup(me, Groups.OFIEditor) && (!this.props.library_id || this.props.library_id === LIBRARY_OFI_OFFICIAL_ID))) {
      radioTabs.push(<RadioTab className={this.state.loading ? "disabled-input" : ""} selected={this.state.searchParams.origin == ExerciseOriginTypes.OFFICIAL} value={ExerciseOriginTypes.OFFICIAL}>
        {__("Hivatalos feladatok")}
      </RadioTab>);
    }
    if (me && !this.props.isPublicDisabled && (!this.props.library_id)) {
      radioTabs.push(<RadioTab className={this.state.loading ? "disabled-input" : ""} selected={this.state.searchParams.origin == ExerciseOriginTypes.ALL_PUBLISHED} value={ExerciseOriginTypes.ALL_PUBLISHED}>
        {__("Akkreditált feladatok")}
      </RadioTab>);
    }
    if (exerciseModule.getConfig().enableExercisesColleciton && (me && !this.props.isPublicDisabled && (!this.props.library_id))) {
      radioTabs.push(<RadioTab className={this.state.loading ? "disabled-input" : ""} selected={this.state.searchParams.origin == ExerciseOriginTypes.COLLECTION_PUBLISHED} value={ExerciseOriginTypes.COLLECTION_PUBLISHED}>
        {__("Gyüjteményeim feladatai")}
      </RadioTab>);
    }
    if (exerciseModule.getConfig().enableFavouriteExercises && (me && !this.props.isPublicDisabled && (!this.props.library_id))) {
      radioTabs.push(<RadioTab className={this.state.loading ? "disabled-input" : ""} selected={this.state.searchParams.origin == ExerciseOriginTypes.FAVORITS_PUBLISHED} value={ExerciseOriginTypes.FAVORITS_PUBLISHED}>
        {__("Kedvenc feladataim")}
      </RadioTab>);
    }

    return (
      <PermissionPage
        requirements={{
          view: [{
            viewInfoId: ViewExcExercise.VIEW_INFO_ID,
            permissions: [ViewPermissionType.R]
          }]
        }}
      >
        <div className="exercise-list-page row expanded exerciseList-maincomponent">
          {this.state.showForcePublishingDialog ?
            <Dialog width={600} height={200}
              title={__("Kényszerített publikálás")}
              onClose={() => {
                this.setState({ showForcePublishingDialog: false, checked: new Set([]) });
              }}
            >
              <div className="progress" role="progressbar">
                <span className="progress-meter" style={{ width: "" + this.state.publishPercent + "%" }}>
                  <p className="progress-meter-text">{this.state.publishPercent}%</p>
                </span>
              </div>
              <br />
              <button className="button alert"
                onClick={() => this.setState({ showForcePublishingDialog: false, checked: new Set([]) })}
              >
                <i className="fa fa-abort" /> {__("Megszakít")}
              </button>
            </Dialog> : null
          }

          {
            this.state.shareExerciseId
              ?
              <Dialog title={__("Feladat kiosztása")} onClose={() => this.setState({ shareExerciseId: null, sharedContentId: undefined })} width={1000} height={700}>
                <UsrShareDialog
                  sharedContentId={this.state.sharedContentId}
                  fileName={this.state.shareExerciseName ? this.state.shareExerciseName : ""}
                  tableInfoId={PrivExerciseCrud.default.TABLE_INFO_ID}
                  recordId={this.state.shareExerciseId}
                  onCreated={(record: ISharedContentRecord) => {
                    this.setState({ shareExerciseId: null, sharedContentId: this.state.sharedContentId });
                    app.showSuccess(
                      __("Sikeres művelet"),
                      __("A tartalom megosztása sikeresen befejeződött."));
                  }}
                  onSaved={(record: ISharedContentRecord) => {
                    this.setState({ shareExerciseId: null, sharedContentId: this.state.sharedContentId });
                    app.showSuccess(
                      __("Sikeres művelet"),
                      __("Megosztás módosítva."));
                  }}
                  onSharedContentSelected={(sharedContentId: number | null) => {
                    this.setState({ sharedContentId });
                  }}
                  onSharedContentDeleted={(sharedContentId: number | null) => {
                    this.setState({ shareExerciseId: null });
                    app.showWarning(
                      __("Sikeres művelet"),
                      __("Megosztás törölve."));
                  }}
                  onCancel={() => this.setState({ shareExerciseId: null })}
                />
              </Dialog>
              : null
          }

          {
            this.state.showReopenDialog &&
            <Dialog title={__("Visszanyitás szerkesztésre")} closable={false} onClose={() => { }}>
              {__("Visszanyitás szerkesztésre folyamatban...")}
            </Dialog>
          }

          {
            this.props.onExerciseSelected ? ""
              :
              <div className="small-12 medium-12 column">
              </div>
          }

          <div className="small-12 medium-12 column clearfix">
            {!this.props.onExerciseSelected &&
              <Link className="button small" to={getPath({ PATH_EXERCISE_ENGINE_SELECT }, exerciseModule)}><i className="fa fa-plus" /> {__("Új feladat hozzáadása")}</Link>
            }
            {
              checkboxCount > 0 ?
                <button
                  className="button small"
                  onClick={this.onTransitionToEdit.bind(this)}
                  disabled={this.state.checked.size == 0}
                >
                  <i className="fa fa-edit" /> {__("Kijelöltek visszanyitása szerkesztésre")} ({this.state.checked.size})
                </button>
                : ""
            }
            {
              (me && this.state.checked.size && hasAnyGroup(me, [Groups.Admin, Groups.NkpAdmin, Groups.ForcedPublisherOFI])) ?
                <button
                  className="button small alert"
                  onClick={this.forcePublishChecked}
                >
                  <i className="fa fa-eye" /> {__("Kényszerített publikálás")} ({this.state.checked.size})
              </button>
                : ""
            }
            {!this.props.onExerciseSelected && <Link className="button small float-right" target="_blank" to={PATH_GUIDE_EDITOR}><i className="fa fa-question-circle" /> </Link>}
          </div>

          <RadioTabs onChange={this.onToggleLibraryId.bind(this)} tabGroup="exeTabs">
            {radioTabs}
          </RadioTabs>

          <div className="small-12 medium-12 filters">

            <Accordion>
              <AccordionItem defaultClosed key={"filter_accordion"} title={__("Szűrők")}>
                <div className="medium-12 row">
                  <div className="small-12 medium-6 large-4 column ">
                    <label className="lms-pages-typeLabel">{__("Évfolyam")}</label>
                    <CrudSelect
                      value={this.state.searchParams.gradeId || null}
                      onSelect={this.onGradeSelect}
                      displayFieldName="name"
                      orderByFieldName="id"
                      key="id"
                      emptyTitle={__("Minden osztály")}
                      clearable={true}
                      crudClassProxy={gradeCrudClassProxy}
                      filter={{ is_active: true }}
                      sortFunc={(a, b) => a.name.localeCompare(b.name, undefined, { numeric: true, sensitivity: 'base' })}
                    />
                  </div>
                  <div className="small-12 medium-6 large-4 column">
                    <label className="lms-pages-typeLabel">{__("Tantárgy")}</label>
                    <CrudSelect
                      value={this.state.searchParams.subjectId || null}
                      onSelect={this.onSubjectSelect}
                      displayFieldName="name"
                      key="id"
                      emptyTitle={__("Minden tantárgy")}
                      clearable={true}
                      crudClassProxy={subjectCrudClassProxy}
                      filter={{ is_active: true }}
                    />
                  </div>
                  <div className="small-12 medium-6 large-4 column">
                    <label className="lms-pages-typeLabel">{__("Feladattípus")}</label>
                    <CrudSelect
                      value={this.state.searchParams.engineId || null}
                      onSelect={this.onEngineSelect}
                      displayFieldName="name"
                      orderByFieldName="name"
                      key="id"
                      emptyTitle={__("Minden feladattípus")}
                      clearable={true}
                      valueFieldName="engine_id"
                      crudClassProxy={engineTranslationCrudClassProxy}
                      filter={{ is_active: true, lang_id: getLanguageId() }}
                      sortFunc={(a, b) => a.name.localeCompare(b.name, undefined, { numeric: true, sensitivity: 'base' })}
                    />
                  </div>
                  <div className="small-12 medium-6 large-4 column">
                    <label className="lms-pages-typeLabel">{__("Nehézségi szint")}</label>
                    <LevelSelectComponent
                      value={this.state.searchParams.selectedExeLevel || null}
                      allowEmpty={true}
                      onSelect={this.onExeLevelSelect}
                      name={""}
                    />
                  </div>
                  <div className="small-12 medium-6 large-4 column">
                    <label className="lms-pages-typeLabel">{__("Szabad szöveg, kulcsszó")}</label>
                    <input className="exerciseList-option" name="keywords" id="keywords" type="text" value={this.state.searchParams.keywords ? this.state.searchParams.keywords : ""} onChange={this.onHandleInputChange.bind(this)} />
                  </div>
                  <div className="small-12 medium-6 large-4 column">
                    <label className="lms-pages-typeLabel">{__("Leírás")}</label>
                    <input className="exerciseList-option" type="text" name="description" id="description" value={this.state.searchParams.description ? this.state.searchParams.description : ""} onChange={this.onHandleInputChange.bind(this)} />
                  </div>
                  {enableLang && <div className="small-12 medium-6 large-4 column">
                    <label className="lms-pages-typeLabel">{__("Feladat nyelve")}</label>
                    <CrudSelect
                      value={this.state.searchParams.lang_id || null}
                      displayFieldName="name"
                      onSelect={this.onSelectLang}
                      emptyTitle={__("Minden nyelv")}
                      clearable={true}
                      valueFieldName="lang_id"
                      filter={{ translated_to_id: getLanguageId() }}
                      crudClassProxy={langTranslationCrudClassProxy}
                    />
                  </div>}
                  <div className="small-12 medium-6 large-4 column">
                    <label className="lms-pages-typeLabel">{__("Akadálymentes")}</label>
                    <AccessibilitySelectComponent
                      value={this.state.searchParams.accessibility || null}
                      allowEmpty={true}
                      onSelect={this.onExeAccessSelect}
                      name={""}
                    />
                  </div>
                  {me && hasGroup(me, Groups.OFIEditor) ?
                    <>
                      {
                        this.state.searchParams.libraryId !== 0
                          ?
                          <>
                            <div className="small-12 medium-6 large-4 column">
                              <label className="lms-pages-typeLabel">{__("Állapot")}</label>
                              <CrudSelect
                                value={this.state.searchParams.stationId || null}
                                onSelect={this.onStationSelect}
                                displayFieldName="name"
                                orderByFieldName="name"
                                key="id"
                                emptyTitle={__("Minden állapot")}
                                clearable={true}
                                crudClassProxy={stationCrudClassProxy}
                                filter={{ is_active: true, wf_type_id: WF_TYPE_OKOSFELADAT_ID }}
                                sortFunc={(a, b) => a.name.localeCompare(b.name, undefined, { numeric: true, sensitivity: 'base' })}
                              />
                            </div>
                            <div className="small-12 medium-6 large-4 column">
                              <label className="lms-pages-typeLabel">{__("Azonosító")}</label>
                              <input className="exerciseList-option" type="text" name="internal_code" id="internal_code" value={this.state.searchParams.internal_code ? this.state.searchParams.internal_code : ""} onChange={this.onHandleInputChange.bind(this)} />
                            </div>
                          </>
                          : <div className="small-12 medium-6 large-8 column"></div>
                      }

                      <div className="small-12 medium-6 large-4 column">
                        <input type="checkbox" name="nokeywords" id="nokeywords" checked={this.state.searchParams.nokeywords} onChange={this.onHandleInputChange.bind(this)} />
                        <label htmlFor="nokeywords">{__("Kulcsszó nélküliek")}</label>
                      </div>

                      {
                        this.state.searchParams.libraryId !== 0
                          ?
                          <div className="small-12 medium-6 large-4 column">
                            <input type="checkbox" name="noresult" id="noresult" checked={this.state.searchParams.noresult} onChange={this.onHandleInputChange.bind(this)} />
                            <label htmlFor="noresult">{__("Nincs keresési eredmény")}</label>
                          </div>
                          : ""
                      }
                      {exerciseModule.getConfig().enableExerciseInBook && <div className="small-12 medium-6 large-4 column">
                        <input type="checkbox" name="notinbook" id="notinbook" checked={this.state.searchParams.notinbook} onChange={this.onHandleInputChange.bind(this)} />
                        <label htmlFor="notinbook">{__("Nem szerepel könyvben")}</label>
                      </div>}
                    </>
                    : ""
                  }

                  <div className="small-12 medium-6 large-4 column">
                    <input type="checkbox" name="isSNI" id="isSNI" checked={this.state.searchParams.isSNI} onChange={this.onHandleInputChange.bind(this)} />
                    <label htmlFor="isSNI">{__("SNI")}</label>
                  </div>
                </div>
              </AccordionItem>
            </Accordion>
          </div>

          <div className="small-12 medium-12">
            <label className="result-count">&nbsp;{this.state.count ? __("{count} találat", { count: this.state.count }) : "0"}</label>

            <ReactTable {...tableProps} />

          </div>
          <div className="small-12 medium-12 column clearfix">
            {
              (me && hasGroup(me, Groups.Developer)) ?
                <div className="row">
                  <button className="button secondary small eke-general-buttons" onClick={this.tempMigrateOldNkp} ><i className="fa fa-plus" /> {__("1. lépés Halmazok újrakonvertálása")} </button>
                  <button className="button secondary small eke-general-buttons" onClick={ExerciseList.tempReplaceSolOfItemToSet} ><i className="fa fa-plus" /> {__("2. lépés Halmaz solutionok áthelyezése")} </button>
                </div>
                : ""
            }
          </div>
        </div>
      </PermissionPage>
    );
  }
}
