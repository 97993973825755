import * as React from 'react';
import { debounce } from 'lodash';
import { app } from '@src/index';
import ReactTable from 'react-table'
import 'react-table/react-table.css'
import { getReactTableLabels } from '@src/framework/i18n';
import { IListOrderBy, TFilterDict } from '@src/framework/crud/Crud';
import DebouncedInput from '@src/framework/forms/debouncedinput';
import ViewSecUserViewAuditByUser, { IViewSecUserViewAuditByUserRecord } from '@src/framework/view/meta/ViewSecUserViewAuditByUser';
import { Dialog } from '@src/component/Dialog';
import ViewSecUserViewAudit, { IViewSecUserViewAuditRecord } from '@src/framework/view/meta/ViewSecUserViewAudit';
import { __ } from '@src/translation';

interface IPermissionAuditViewByUserProps {
    userId: number;
}

interface IPermissionAuditViewByUserState {
    loading: boolean,
    items: IViewSecUserViewAuditByUserRecord[];
    orderBy: IListOrderBy[];
    filter1: string;
    viewInfoId: number | null;
    viewInfoReport: IViewSecUserViewAuditRecord[] | null;
}

const ORDER_BY_1_ASC = [
    { name: "schema_name" },
    { name: "view_name" }
];
const ORDER_BY_1_DESC = [
    { name: "schema_name", desc: true },
    { name: "view_name", desc: true }
];


export default class PermissionAuditViewByUser extends React.Component<IPermissionAuditViewByUserProps, IPermissionAuditViewByUserState> {
    private debouncedAsyncReload: () => void;

    constructor(props: IPermissionAuditViewByUserProps) {
        super(props);
        this.state = {
            loading: true,
            items: [],
            orderBy: ORDER_BY_1_ASC,
            filter1: "",
            viewInfoId: null,
            viewInfoReport: null
        };
        this.debouncedAsyncReload = debounce(() => this.asyncReload(), 300);
    }

    componentDidMount() {
        this.debouncedAsyncReload();
    }

    private asyncReload = async () => {
        try {
            this.setState({ loading: true });
            let filters: TFilterDict[] = [
                // Azok a sorok nem jelennek meg, ahol egyik sem igaz.
                {
                    $or: {
                        can_read: true,
                    }
                }
            ];
            if (this.state.filter1) {
                filters.push({
                    $text: {
                        fieldNames: ["table_name", "schema_name", "table_display_name"],
                        expression: this.state.filter1
                    }
                });
            }
            const items = await ViewSecUserViewAuditByUser.list({
                filter: {
                    sec_user_id: this.props.userId,
                    $and: filters
                },
                order_by: this.state.orderBy
            });
            this.setState({ loading: false, items });
        } catch (error) {
            app.showErrorFromJsonResult(error);
        }
    }

    private setOrderBy = async (orderBy: IListOrderBy[]) => {
        console.log("setOrderBy", orderBy);
        this.setState({ orderBy, loading: true }, this.debouncedAsyncReload);
    }

    private filter1ValueChanged = (filter1: string) => {
        this.setState({ filter1, loading: true }, this.debouncedAsyncReload);
    }

    private analyzeTableInfo = async (viewInfoId: number) => {
        try {
            const viewInfoReport = await ViewSecUserViewAudit.list({
                filter: {
                    view_info_id: viewInfoId,
                    sec_user_id: this.props.userId
                }
            })

            this.setState({ viewInfoId, viewInfoReport });
        } catch (error) {
            app.showErrorFromJsonResult(error);
        }
    }

    private getViewInfoReport = () => {
        return <ReactTable
            columns={[
                {
                    Header: __("Jog forrása"),
                    Cell: (data: any, column: any) => {
                        const row: IViewSecUserViewAuditRecord = data.original;
                        if (row.src_group_id) {
                            return <p
                                title={row.src_group_description || ""}
                            ><small>
                                    <i className="fa fa-users" /> {row.src_group_title}
                                    <br />
                                    <label className="label success">{row.src_group_type}</label>
                                </small>
                            </p>
                        } else {
                            return <label className="label secondary">
                                &gt; {__("Saját felhasználónak közvetlenül kiosztva")} &lt;
                            </label>
                        }
                    }
                },
                {
                    Header: __("Jogok"),
                    filterable: false,
                    maxWidth: 100,
                    Cell: (data: any, column: any) => {
                        const row: IViewSecUserViewAuditRecord = data.original;
                        return <code style={{ width: "4em" }}>
                            {row.can_read ? "R" : ""}
                        </code>
                    }
                },
            ]}
            data={this.state.viewInfoReport!}
            filterable={false}
            className="-striped -highlight "
            {...getReactTableLabels()}
        />
    }

    render() {
        const orderBy = this.state.orderBy[0]!;

        let sortIcon1 = null;
        let desc1: boolean = true;
        if (orderBy.name == ORDER_BY_1_ASC[0].name) {
            desc1 = orderBy.desc ? true : false;
            sortIcon1 = <i className={"fa " +
                (desc1 ? "fa-sort-alpha-up" : "fa-sort-alpha-down")} />;
        }
        let onClick1 = () => { this.setOrderBy(desc1 ? ORDER_BY_1_ASC : ORDER_BY_1_DESC); }

        return <>
            {this.state.viewInfoId ?
                <Dialog width={800} height={600}
                    title={__("Jog források megjelenítése")}
                    onClose={() => { this.setState({ viewInfoId: null }) }}
                >
                    {this.state.viewInfoReport
                        ? this.getViewInfoReport()
                        : <p className="callout secondary">{__("Kérem várjon...")}</p>
                    }
                </Dialog>
                : null
            }

            <div className="row expanded">
                <div className="column small-12 large-12">
                    <p className="callout secondary">
                        <small>
                            {__("Az alábbi jogok össze vannak gyűjtve a felhasználó összes csoportjából, ")}
                            {__("itt már csak az jelenik meg, hogy a szerver milyen műveleteket engedélyez a ")}
                            {__("felhasználónak közvetlenül, vagy valamely csoportján keresztül. ")}
                            {__("Vegye figyelembe, hogy egy adott műveleti jog megléte nem jelenti azt, hogy ")}
                            {__("a műveletet bármely rekordra el lehet végezni. A szerver sok esetben ")}
                            {__("speciális szabályokkal tovább korlátozza a műveleteket.")}
                    </small>
                    </p>
                </div>
                <div className="column small-12 large-12">
                    <ReactTable
                        columns={[
                            {
                                Header: <div onClick={onClick1}>
                                    View &nbsp;{sortIcon1}
                                </div>,
                                Cell: (data: any, column: any) => {
                                    const row: IViewSecUserViewAuditByUserRecord = data.original;

                                    return <div style={{ display: "block" }}>


                                        <label className="label primary" style={{ fontFamily: "monospace" }}>
                                            <i className="fa fa-eye" /> &nbsp;
                                            {row.schema_name}.{row.view_name}
                                        </label><br />
                                        {row.table_name?
                                            <>
                                                <span style={{ fontFamily: "monospace" }}
                                                    title={__("Elsődleges táblázat")}
                                                >
                                                    <i className="fa fa-table" /> &nbsp;
                                                    {row.schema_name}.{row.table_name}
                                                </span><br />
                                            </>:null
                                        }
                                        <label className="label secondary"
                                            title={row.view_description || ""}
                                        >
                                            {row.view_display_name}
                                        </label><br />
                                    </div>;
                                },
                                Filter: () => {
                                    return <DebouncedInput
                                        type="text"
                                        placeholder={__("Séma/view/név")}
                                        value={this.state.filter1}
                                        onValueChanged={this.filter1ValueChanged}
                                    />
                                }
                            },
                            {
                                Header: __("Jogok"),
                                filterable: false,
                                maxWidth: 110,
                                Cell: (data: any, column: any) => {
                                    const row: IViewSecUserViewAuditByUserRecord = data.original;
                                    return <>                                    
                                            <code style={{ width: "4em" }}>
                                            {row.can_read ? "R" : ""}
                                            </code>
                                            <button className="button primary"
                                        title={__("A jogok származási helyének megjelenítése")}
                                        onClick={() => { this.analyzeTableInfo(row.id!) }}
                                    >
                                        <i className="fa fa-question-circle" />
                                    </button>
                                        </>
                                }
                            }
                        ]}
                        manual
                        data={this.state.items}
                        defaultPageSize={100}
                        pages={Math.ceil(this.state.items.length / 100)}
                        filterable={true}
                        className="-striped -highlight "
                        {...getReactTableLabels()}
                        onFetchData={(state: any, instance: any) => this.debouncedAsyncReload()}
                        loading={this.state.loading}
                    />
                </div>
            </div>
        </>
    }

}