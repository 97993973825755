import * as React from 'react'
import { History } from 'history';
import * as Server from '@src/server/PublicServer'
import { SearchResult } from '@src/server/PublicServer';
import CrudSelect from '@framework/forms//crudselect';
import { subjectCrudClassProxy } from '@framework/crud/doc/SubjectCrud';
import { gradeCrudClassProxy } from '@framework/crud/doc/GradeCrud';
import { app } from '@src/index';
import { ExerciseTile } from '@src/component/exercise/Viewer/ExerciseTileComponent';
import { PATH_EXERCISE_PUB_LIST } from '@src/Routes';
import { ExerciseEngineTypes } from '@src/component/exercise/models/ExerciseBaseClass';
import LevelSelectComponent from '@src/framework/forms/level_select';
import { __, getLanguageId } from '@src/translation';
import AccessibilitySelectComponent from '@src/framework/forms/accessibility_select';
import { Pager } from '@src/component/Pager';
import EngineTranslationCrud, { IEngineTranslationRecord, engineTranslationCrudClassProxy } from '@src/framework/crud/exc/EngineTranslationCrud';
import EngineCrud from '@crud/exc/EngineCrud';
import { langTranslationCrudClassProxy } from '@src/framework/crud/sys/LangTranslationCrud';
interface SearchComponentProps {
    match: any;
    location: any;
    history: History;
    filters?: EXE_FILTER_TYPES[] | null;
    basePath?: string;
}

type SearchComponentState = {
    keywords?: string;
    origkeywords?: string;
    results: SearchResult[];
    resultNum: number;
    searching?: boolean;
    imgsrc: string;
    selectedSubject: number | null;
    selectedGrade: number | null;
    selectedExeLevel: number | null;
    selectedEngine: number | null;
    selectedLevel: number | null;
    selectedElasticType?: string;
    pageIndex: number;
    exeOrSeries: string;
    exerciseSeriesType?: string;
    enginesInDatabase: IEngineTranslationRecord[];
    engineTranslations: IEngineTranslationRecord[];
    accessibility?: number;
    isSNI?: number;
    lang_id?: number;
}

export enum EXE_FILTER_TYPES {
    GRADE,
    SUBJECT,
    IS_EXERCISE,
    TYPE,
    LEVEL,
    ACCESSIBILITY_LEVEL,
    IS_SNI,
    LANG_ID
}

export default class ExerciseListPublicComponent extends React.Component<SearchComponentProps, SearchComponentState> {

    constructor(props: SearchComponentProps) {

        super(props);
        const keywords = this.props.match.params.keywords ? this.props.match.params.keywords : "";
        this.state = {
            keywords: keywords,
            origkeywords: keywords,
            results: [],
            resultNum: 0,
            searching: false,
            imgsrc: "",
            exeOrSeries: "all",
            exerciseSeriesType: '',
            pageIndex: 1,
            selectedSubject: null,
            selectedGrade: null,
            selectedExeLevel: null,
            selectedEngine: null,
            selectedLevel: null,
            selectedElasticType: "exercise,exercise_series",
            enginesInDatabase: [],
            engineTranslations:[],
            accessibility: 0,
            isSNI: 0
        }

        this.onSearch();
    }

    componentDidUpdate(prevProps: any) {

        if (this.props.location !== prevProps.location) {

            this.setState({
                keywords: this.props.match.params.keywords || '',
                results: [],
                searching: true
            });
            this.onSearch();
        }
    }

    async refreshEngineList() {
        let enginesInDatabase = await EngineCrud.list({filter:{is_active:true}});
        let engineTranslations = await EngineTranslationCrud.list({ filter: { is_active: true, lang_id:getLanguageId() } });
        this.setState({ enginesInDatabase, engineTranslations });
    }

    async onSearch() {
        this.refreshEngineList();

        const keywords = this.props.match.params.keywords ? this.props.match.params.keywords : undefined;
        const subjectId = this.state.selectedSubject;
        const gradeId = this.state.selectedGrade;
        let exeOrSeries = "";
        if (this.state.exeOrSeries == "all") exeOrSeries = "exercise,exercise_series";
        else if (this.state.exeOrSeries == "series") exeOrSeries = "exercise_series";
        else exeOrSeries = "exercise";

        let exeSeriesType = undefined;
        if (this.state.exerciseSeriesType == "simple") exeSeriesType = false;
        else if (this.state.exerciseSeriesType == "adaptive") exeSeriesType = true;
        try {
            let accessibility = undefined;

            if (this.state.accessibility == -1) {
                accessibility = false;
            }
            else if (this.state.accessibility == 1) {
                accessibility = true;
            }
            let is_sni = undefined;

            if (this.state.isSNI == -1) {
                is_sni = false;
            }
            else if (this.state.isSNI == 1) {
                is_sni = true;
            }
            const resultNumSearch = await Server.search({
                keyword: keywords,
                type: exeOrSeries,
                is_adaptive: exeSeriesType,
                subject_id: subjectId ? subjectId.toString() : undefined,
                grade_id: gradeId ? gradeId.toString() : undefined,
                engine_id: this.state.selectedEngine ? this.state.selectedEngine.toString() : undefined,
                exercise_level: this.state.selectedExeLevel ? this.state.selectedExeLevel : undefined,
                is_accessible: accessibility,
                is_sni: is_sni,
                countonly: true,
                maxhits: "10000",
                lang_id: this.state.lang_id ? this.state.lang_id.toString() : undefined
            });

            let resultNum = resultNumSearch[0].count!;

            const result = await Server.search({
                keyword: keywords,
                type: exeOrSeries,
                is_adaptive: exeSeriesType,
                subject_id: subjectId ? subjectId.toString() : undefined,
                grade_id: gradeId ? gradeId.toString() : undefined,
                engine_id: this.state.selectedEngine ? this.state.selectedEngine.toString() : undefined,
                exercise_level: this.state.selectedExeLevel ? this.state.selectedExeLevel : undefined,
                is_accessible: accessibility,
                is_sni: is_sni,
                from: (this.state.pageIndex - 1) * 12,
                maxhits: (this.state.pageIndex - 1) * 12 + 12 > 10000 ? (10000 - (this.state.pageIndex - 1) * 12).toString() : "12",
                lang_id: this.state.lang_id ? this.state.lang_id.toString() : undefined
            });

            this.setState({
                results: result,
                searching: false,
                origkeywords: keywords,
                resultNum: resultNum
            });

        } catch (error) {
            app.showErrorFromJsonResult(error);
        }
    }

    onInputChange(e: any) {
        this.setState(
            { keywords: e.target.value }
        );
    }

    setCurrentSearch() {
        this.props.history.push((this.props.basePath ? this.props.basePath : PATH_EXERCISE_PUB_LIST) + "/" + encodeURIComponent(this.state.keywords!));
    }

    onKeyPress(e: any) {
        if (e.key === 'Enter') {
            this.setCurrentSearch();
        }
    }
    private onSeriesOrExeChanged(event: any) {
        const exeOrSeries = event.target.value;
        if (exeOrSeries == "series") {
            this.setState({ selectedEngine: null, exeOrSeries })
        }
        else if (exeOrSeries == "all") {
            this.setState({ selectedEngine: null, exeOrSeries })
        }
        else {
            this.setState({ exerciseSeriesType: '', exeOrSeries });
        }
        this.setCurrentSearch();
    }
    private onExeSeriesTypechanged(event: any) {
        const exeSeriesType = event.target.value;
        this.setState({ exerciseSeriesType: exeSeriesType });
        this.setCurrentSearch();
    }
    private onGradeSelect = (sender: CrudSelect, newGrade: number | null): void => {
        this.setState({ selectedGrade: newGrade });
        this.setCurrentSearch();
    }

    private onExeLevelSelect = (event: React.FormEvent<HTMLSelectElement>): void => {
        let tempExeLevel = event.currentTarget.value ? Number(event.currentTarget.value) : null;
        this.setState({ selectedExeLevel: tempExeLevel });
        this.setCurrentSearch();
    }

    private onExeAccessSelect = (event: React.FormEvent<HTMLSelectElement>): void => {
        const tempaccess: any = event.currentTarget.value ? Number(event.currentTarget.value) : 0;
        const newVal = event.currentTarget.value ? Number(event.currentTarget.value) : 0;
        this.setState({ accessibility: newVal });
        this.setCurrentSearch();
    }

    private onExeSNISelect = (event: React.FormEvent<HTMLSelectElement>): void => {
        const tempaccess: any = event.currentTarget.value ? Number(event.currentTarget.value) : 0;
        const newVal = event.currentTarget.value ? Number(event.currentTarget.value) : 0;
        this.setState({ isSNI: newVal });
        this.setCurrentSearch();
    }

    private onSubjectSelect = (sender: CrudSelect, newSubject: number | null): void => {
        this.setState({ selectedSubject: newSubject });
        this.setCurrentSearch();
    }

    private onEngineSelect = (sender: CrudSelect, newEngine: number | null): void => {
        this.setState({ selectedEngine: newEngine });
        this.setCurrentSearch();
    }

    private onLangSelect = (sender: CrudSelect, newLang: number): void => {
        this.setState({ lang_id: newLang });
        this.setCurrentSearch();
    }

    render() {
        var grades = [];
        var faClass;
        var selectedOsztaly = __("Minden Osztály");
        let title;

        if (this.state.results.length == 0 && this.state.origkeywords != undefined && this.state.origkeywords != "" && !this.state.searching) {
            title = <div className="row">
                <div className="large-1 small-2 column" style={{ position: "relative" }}>
                    <img src={"/img/noResultSearch/thinking.png"} style={{ width: "13em", color: "black", position: "absolute", top: "50px", paddingRight: "15px" }} />
                </div>
                <br />
                <div className="large-11 small-10 column">
                    <br />
                    <h4>{title}{__("A keresett kifejezésre")} (<strong>{this.state.origkeywords}</strong>) {__("nincs találat.")}</h4>
                    <h6>{__("Javaslatok:")}</h6>
                    <ul className="eke-search-NoresultUli">
                        <li>{__("Győződjön meg arról, hogy valamennyi szót helyesen írta.")}</li>
                        <li>{__("Próbálkozzon más kulcsszavakkal.")}</li>
                        <li>{__("Próbálkozzon általánosabb kulcsszavakkal.")}</li>
                        <li>{__("Változtasson a szűrési feltételeken (pl. osztály).")}</li>
                    </ul>
                </div>
            </div>

        } else if ((this.state.origkeywords != undefined && this.state.origkeywords != "" && !this.state.searching)
            // || this.state.selectedSubject != null
            // || this.state.selectedGrade != null
            // || this.state.selectedExeLevel != null
            // || this.state.isSNI != 0
            // || this.state.accessibility != 0
            // || this.state.selectedElasticType != "exercise,exercise_series"
        ) {
            title = __("{n} találat:", { n: this.state.resultNum });
        }


        faClass = !this.state.selectedGrade ? "fa fa-check fa-fw" : "fa fa-blank fa-fw";
        grades.push(
            <li><a onClick={this.onGradeSelect.bind(this, false)}><i className={faClass} aria-hidden="true"></i>{__("Minden Osztály")} </a></li>
        );

        for (var i = 1; i < 12; i++) {
            if (this.state.selectedGrade == i) {
                selectedOsztaly = i + __(". osztály");
                faClass = "fa fa-check fa-fw";
            } else {
                faClass = "fa fa-blank fa-fw";
            }

            grades.push(
                <li><a onClick={this.onGradeSelect.bind(this, i)}><i className={faClass} aria-hidden="true"></i> {i}{__(". osztály")}</a></li>
            );
        }

        return <div className="exercise-list-public">

            <div className="secondary-bar">
                <div className="row">
                    <div className="column small-12">

                        <div className="input-group eke-search-field">
                            <div className="eke-search__input">
                                <input type="text" value={this.state.keywords} onKeyPress={this.onKeyPress.bind(this)} onChange={this.onInputChange.bind(this)} title={__("Keresőmező")} placeholder={__("Kezdjen el gépelni...")} />
                            </div>
                            <div className="input-group-button">
                                <div className="button-group shrink column eke-search__button">
                                    <button onClick={this.setCurrentSearch.bind(this)} className="button">{__("Keresés")}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div className="small-12 column">
                <div className="row align-center">
                    <div className="search-filters small-12 medium-12 large-12 column">
                        {(!this.props.filters || this.props.filters.includes(EXE_FILTER_TYPES.GRADE) || this.props.filters == null) && <CrudSelect
                            value={this.state.selectedGrade}
                            onSelect={this.onGradeSelect}
                            displayFieldName="name"
                            orderByFieldName="id"

                            emptyTitle={__("Minden osztály")}


                            crudClassProxy={gradeCrudClassProxy}
                            filter={{ is_active: true }}
                            sortFunc={(a, b) => a.name.localeCompare(b.name, undefined, { numeric: true, sensitivity: 'base' })}
                        />}
                        {(!this.props.filters || this.props.filters.includes(EXE_FILTER_TYPES.SUBJECT) || this.props.filters == null) && <CrudSelect
                            value={this.state.selectedSubject}
                            onSelect={this.onSubjectSelect}
                            displayFieldName="name"

                            emptyTitle={__("Minden tantárgy")}
                            crudClassProxy={subjectCrudClassProxy}
                            filter={{ is_active: true }}
                        />}
                        {(!this.props.filters || this.props.filters.includes(EXE_FILTER_TYPES.IS_EXERCISE) || this.props.filters == null) && 
                        <select name="ExerciseOrSeries" id="ExerciseOrSeries" onChange={this.onSeriesOrExeChanged.bind(this)} value={this.state.exeOrSeries}>
                            <option value="all">{__("Feladat és feladatsor")}</option>
                            <option value="exercise">{__("Feladat")}</option>
                            <option value="series">{__("Feladatsor")}</option>
                        </select>}
                        {/* if exercise selected before */}

                        {(!this.props.filters || this.props.filters.includes(EXE_FILTER_TYPES.TYPE) || this.props.filters == null) && this.state.exeOrSeries == "exercise"
                            ?
                            <CrudSelect
                                value={this.state.selectedEngine}
                                onSelect={this.onEngineSelect}
                                displayFieldName="name"
                                orderByFieldName="name"
                                key="id"
                                valueFieldName="engine_id"
                                clearable={true}
                                emptyTitle={__("Minden feladattípus")}
                                crudClassProxy={engineTranslationCrudClassProxy}
                                filter={{ is_active: true, lang_id:getLanguageId() }}
                                sortFunc={(a, b) => a.name.localeCompare(b.name, undefined, { numeric: true, sensitivity: 'base' })}
                            />
                            : null
                        }
                        {/* if exerciseSeries selected before */}
                        {(!this.props.filters || this.props.filters.includes(EXE_FILTER_TYPES.TYPE) || this.props.filters == null) && this.state.exeOrSeries == "series"
                            ?
                            <select name="exerciseSeriesType" id="exerciseSeriesType" onChange={this.onExeSeriesTypechanged.bind(this)} >
                                <option value="all">{__("Minden feladatsor típus")}</option>
                                <option value="simple">{__("Egyszerű")}</option>
                                <option value="adaptive">{__("Adaptív")} </option>
                            </select>
                            : null}

                        {(!this.props.filters || this.props.filters.includes(EXE_FILTER_TYPES.LEVEL) || this.props.filters == null) && <LevelSelectComponent
                            value={this.state.selectedExeLevel}
                            allowEmpty={true}
                            onSelect={this.onExeLevelSelect}
                            name=""

                        />}
                        {(!this.props.filters || this.props.filters.includes(EXE_FILTER_TYPES.ACCESSIBILITY_LEVEL) || this.props.filters == null) && <AccessibilitySelectComponent
                            value={this.state.accessibility || null}
                            allowEmpty={true}
                            onSelect={this.onExeAccessSelect}
                            name={""}
                            emptyTitle={__("Akadálymentesítési szint")}
                        />}
                        {(!this.props.filters || this.props.filters.includes(EXE_FILTER_TYPES.IS_SNI) || this.props.filters == null) && <select name="exerciseSNI" id="exerciseSNI" onChange={this.onExeSNISelect.bind(this)} >
                            <option value="0">{__("SNI és nem SNI")}</option>
                            <option value="-1">{__("nem SNI")}</option>
                            <option value="1">{__("SNI")} </option>
                        </select>}
                        { (this.props.filters && this.props.filters.includes(EXE_FILTER_TYPES.LANG_ID)) && <CrudSelect
                                value={this.state.lang_id ? this.state.lang_id : null}
                                onSelect={this.onLangSelect}
                                displayFieldName="name"
                                valueFieldName="lang_id"
                                clearable={true}
                                emptyTitle={__("Minden nyelv")}
                                crudClassProxy={langTranslationCrudClassProxy}
                                filter={{ translated_to_id:getLanguageId() }}
                            /> }
                    </div>
                </div>
                <h2 className="exe-result-count"><strong>{title}</strong></h2>
                <div ref="resultContainer" className="row exercise-list-tiles">
                    {
                        this.state.results.map((r: Server.SearchResult) => {
                            return (<ExerciseTile key={r.id} ExerciseResult={r} published={true} engineTranslatedName={this.getEngineTranslatedName(r.engine_id!)} engineName={this.getEngineName(r)} />)
                        })
                    }
                </div>
                <Pager resultNum={this.state.resultNum} elementPerPage={12} pageIndex={this.state.pageIndex} paging={this.onSearch.bind(this)} setPageIndex={(i: number) => this.setState({ pageIndex: i })}></Pager>
            </div>
        </div>
    }

    getEngineTranslatedName(engine_id:number){
        let engineName = "";
        const engine = this.state.engineTranslations.find((engine) => engine.engine_id == engine_id);
        if(engine) engineName = engine.name || "";
        return engineName;
    }

    getEngineName(exerciseRecord: SearchResult): string {

        let engineName = undefined;
        const engine = this.state.enginesInDatabase.find((engine) => engine.id == exerciseRecord.engine_id);

        if (engine && engine.name) {
            engineName = engine.name;
        }

        return engineName ? engineName : ExerciseEngineTypes.Series;
    }

}

