import * as React from 'react';
import { BubbleLoader } from 'react-css-loaders';
import { debounce } from 'lodash'
import { app } from '@src/index';
import { me } from '@framework/server/Auth';
import LookupEdit from '@src/framework/forms/lookupedit';
import CrudSelect from '@src/framework/forms/crudselect';
import DateTimePicker from '@src/framework/forms/datetimepicker';
import { shareModeCrudClassProxy } from '@src/framework/crud/usr/ShareModeCrud';
import SharedContentCrud, { ISharedContentRecord } from '@src/framework/crud/usr/SharedContentCrud';
import SecUserSelector from '@src/framework/forms/SecUserSelector';
import ViewSharedWith, { IViewSharedWithRecord } from '@src/framework/view/usr/ViewSharedWith';
import ViewMemberName, { IViewMemberNameRecord } from '@src/framework/view/sys/ViewMemberName';
import MemberCard, { memberToString } from '@src/framework/forms/MemberCard';

import './UsrShareDialog.css'
import InstituteGroupCrud, { instituteGroupCrudClassProxy } from '@src/framework/crud/sys/InstituteGroupCrud';
import SharedWithCrud from '@src/framework/crud/usr/SharedWithCrud';
import ViewSharedByMe, { IViewSharedByMeRecord } from '@src/framework/view/usr/ViewSharedByMe';
import { formatDate } from '@src/Util';

import '@framework/forms/forms.css';
import { getCrudClassProxyById } from '@src/framework/crud/Crud';
import * as ExerciseCrudPriv from '@src/framework/crud/exc/ExerciseCrud';
import * as ExerciseCrudPub from '@src/framework/crud/exc_pub/ExerciseCrud';
import * as ExerciseSeriesCrudPriv from '@src/framework/crud/exc/ExerciseSeriesCrud';
import * as ExerciseSeriesCrudPub from '@src/framework/crud/exc_pub/ExerciseSeriesCrud';
import OoFileCrud from '@src/framework/crud/media/OoFileCrud';
import OoFolderCrud from '@src/framework/crud/media/OoFolderCrud';
import { DialogContent, DialogActions, confirmDialog } from '../Dialog';

import { __ } from '@src/translation';
import { __promisify__ } from 'glob';
import OpenEndedQuestionCrud from '@src/framework/crud/usr/OpenEndedQuestionCrud';
import { Accordion, AccordionItem } from '../ui/Accordion';
import ViewInstituteGroupMember, { viewInstituteGroupMemberClassProxy } from '@src/framework/view/sys/ViewInstituteGroupMember';
import { viewInstituteGroupClassProxy } from '@src/framework/view/sys/ViewInstituteGroup';
import SharedContentRunCrud from '@src/framework/crud/usr/SharedContentRunCrud';
import { SHARE_VIZSGA_ID } from '@src/Const';
import SecUserCrud from '@src/framework/crud/sys/SecUserCrud';
import { InstituteGroupMemberStates } from '../institute/InstituteGroupMemberList';

export enum ShareMode {
    SHARE = 1,      //	Megosztás
    PRACTICE = 2,   //	Gyakorló kiosztás
    EXAM = 3       //	Vizsga kiosztás
}

export const SHARE_MODE_ALL: ShareMode[] = [
    ShareMode.SHARE, ShareMode.PRACTICE, ShareMode.EXAM
]

export const ALLOWED_SHARE_MODES: { [tableInfoId: number]: ShareMode[] } = {
    [ExerciseCrudPriv.default.TABLE_INFO_ID]: SHARE_MODE_ALL,
    [ExerciseCrudPub.default.TABLE_INFO_ID]: SHARE_MODE_ALL,
    [ExerciseSeriesCrudPriv.default.TABLE_INFO_ID]: SHARE_MODE_ALL,
    [ExerciseSeriesCrudPub.default.TABLE_INFO_ID]: SHARE_MODE_ALL,
    [OpenEndedQuestionCrud.TABLE_INFO_ID]: [ShareMode.PRACTICE],
    [OoFileCrud.TABLE_INFO_ID]: [ShareMode.SHARE],
    [OoFolderCrud.TABLE_INFO_ID]: [ShareMode.SHARE],
};


export type UsrShareDialogSharedContentEvent = (record: ISharedContentRecord) => void;
export type UsrShareDialogSharedContentSelectEvent = (sharedContentId: number | null) => void;
export type UsrShareDialogCancelEvent = () => void;

/**
 * Ezek a beállítások akkor vannak használva, ha valaki egy új megosztást
 * hoz létre. A létező megosztások szerkesztésekor nincsen hatásuk.
 */
export interface IUsrShareDefaultShareSettings {
    shareModeId?: number;
    maxRunCount?: number; // Lejátszások max. száma
    sendOverdue?: boolean; // Beküldhető a leadási határidő után
    groupSolution?: boolean; // Csoportos beküldés
}

export interface IUsrShareDialogProps {
    /*
        Ebből a háromból vagy az elsőt kell megadni, vagy a második kettőt.
        Íme a lehetőségeid:

        * sharedContentId -ben null-t adsz meg, ÉS megadod a 
          tableInfoId és recordId értékét is. Ez a beállítás
          úgy nyitja meg a komponenst, hogy egy új megosztás
          létrehozását indítja el.

        * sharedContentId -ben undefined-t adsz meg (vagy nem adod meg),
            ÉS megadod a  tableInfoId és recordId értékét is. Ez a beállítás
            úgy nyitja meg a komponenst, hogy a legutolsó megosztás szerkesztését
            indítja el, ha volt ilyen. Ezt úgy éri el, hogy betöltés közben
            meghívja az onSharedContentSelected eseménykezelőt. Ha nem volt 
            ilyen "utolsó megosztás, akkor egy új megosztás létrehozását indítja el. 
        
        * sharedContentId-ben egy számot adsz meg. Ilyenkor teljesen mindegy,
            hogy a tableInfoId-ben és a recordId-ben mi van, mert ilyenkor
            a megadott megosztást tölti be. Ha nincs ilyen megosztás, akkor
            nagy valószínűséggel hibás lesz.

    */
    sharedContentId?: number | null; // Figyelem! A null és az undefined mást csinál!!!
    tableInfoId?: number;
    recordId?: number;
    fileName: string | undefined | null;
    /** 
     * Ha ez meg van adva, akkor így jelenik meg a megosztott tartalom neve.
     * Ha nincs megadva, akkor a szervertől megpróbálja lekérni a megadott
     * tableInfoId + recordId alapján a megosztott tartalom nevét, és azt
     * jeleníti meg.
     */
    sharedItemTitle?: string;

    /**
     * Ha új megosztást hozol létre, akkor az itt megadott értékek lesznek
     * használva alapértelmezésnek. Létező megosztás szerkesztésekor ennek
     * nincsen hatása!
     */
    defaults?: IUsrShareDefaultShareSettings;

    /**
     * Ha ezt hamisra állítod, akkor a felületről nem fogja tudni megváltoztatni
     * a megadott megosztási módot.
     */
    canChangeShareMode?: boolean;
    /**
     * Ha ezt hamisra állítod, akkor a felületről nem fogja tudni megváltoztatni
     * a futtatások max. számát.
     */
    canChangemaxRunCount?: boolean;
    /**
     * Ha ezt hamisra állítod, akkor a felületről nem fogja tudni megváltoztatni
     * a "határidő lejárta után beküldhető" flag-et.
     */
    canChangeSendOverdue?: boolean;
    /**
     * Ha ezt hamisra állítod, akkor a felületről nem fogja tudni megváltoztatni
     * a "csoportos megoldás" flag-et.
     */
    canChangeGroupSolution?: boolean;

    /** Az onCreated akkor hívódik meg, ha egy új megosztás jött létre.
       Ha egy létezőt ment le valaki, akkor az onSaved hívódik meg. */
    onCreated: UsrShareDialogSharedContentEvent;
    /** Az onSaved akkor hívódik meg, ha egy létező megosztást módosít.
       Ha egy újat ment le valaki, akkor az onCreated hívódik meg. */
    onSaved: UsrShareDialogSharedContentEvent;
    /** Ez akkor hívódik meg, ha a felhasználó egy másik megosztást választ ki szerkesztésre. */
    onSharedContentSelected: UsrShareDialogSharedContentSelectEvent;
    /** Ez akkor hívódik meg, miután a felhasználó kitörli (inaktiválja) a kiválasztott megosztást. */
    onSharedContentDeleted: UsrShareDialogSharedContentSelectEvent;
    /** Ez akkor hívódik meg, ha a felhasználó elveti a módosításokat. */
    onCancel: UsrShareDialogCancelEvent;
}

export type IUsrShareDialogState = {
    loading: boolean;

    sharedContent: ISharedContentRecord;
    sharedWith: IViewSharedWithRecord[];
    toInactivate: Set<number>;
    toActivate: Set<number>;

    canChangeShareMode: boolean;
    canChangemaxRunCount: boolean;
    canChangeSendOverdue: boolean;
    canChangeGroupSolution: boolean;

    sharedByMe: IViewSharedByMeRecord[]; // Korábbi megosztások ugyan erre
    sharedByMeTableOpened: boolean;

    changed: boolean;
    lastAddedId: number | null;

    isSharedInClassRoom: boolean;
    isSharedWthAllInClassroom: boolean;
    selectedClassroomId?: number | null;
    isSubmission: boolean;
    isDateChanged: boolean;

    validationMessages: Map<string, string>;

    ownedGroupIds: number[];
}

const coalesce = (value: any, defval: any): any => {
    if (value === undefined) {
        return defval;
    } else {
        return value;
    }
}



let _id: number = -1000000;
const newId = (): number => {
    _id -= 1;
    return _id;
}

export default class UsrShareDialog extends React.Component<IUsrShareDialogProps, IUsrShareDialogState> {
    private debouncedAsyncReload: any;

    constructor(props: IUsrShareDialogProps) {
        super(props);
        if (!props.sharedContentId && !(props.tableInfoId && props.recordId)) {
            throw new Error(__("Hiba: a UsrShareDialog csak konkrét sharedContentId, vagy tableInfoId+recordId megadásával hívható meg!"));
        }
        let sharedTitle = this.props.sharedItemTitle ? this.props.sharedItemTitle : this.props.fileName;
        let sc: ISharedContentRecord = {
            id: props.sharedContentId || undefined,
            title: sharedTitle || undefined,
            table_info_id: props.tableInfoId,
            rec_id: props.recordId,
            max_run_count: 1,
            send_overdue: false,
            group_solution: false,
            max_points: 100
        };
        this.state = {
            loading: true,
            sharedContent: sc,
            canChangeShareMode: coalesce(this.props.canChangeShareMode, true),
            canChangemaxRunCount: coalesce(this.props.canChangemaxRunCount, true),
            canChangeGroupSolution: coalesce(this.props.canChangeGroupSolution, true),
            canChangeSendOverdue: coalesce(this.props.canChangeSendOverdue, true),
            sharedWith: [], toActivate: new Set([]), toInactivate: new Set([]),
            sharedByMe: [], sharedByMeTableOpened: false,
            changed: false,
            lastAddedId: null,
            isSharedInClassRoom: true,
            isSharedWthAllInClassroom: true,
            validationMessages: new Map<string, string>(),
            ownedGroupIds: [],
            isSubmission: false,
            isDateChanged: false
        };

        this.debouncedAsyncReload = debounce(() => this.asyncReload(), 300);
    }

    componentDidMount() {
        this.debouncedAsyncReload();

    }

    componentDidUpdate(prevProps: IUsrShareDialogProps, prevState: IUsrShareDialogState, snapshot: any) {
        if (
            (prevProps.sharedContentId != this.props.sharedContentId)
            || (prevProps.tableInfoId != this.props.tableInfoId)
            || (prevProps.recordId != this.props.recordId)
            || this.state.loading
        ) {
            this.debouncedAsyncReload();
        }
    }

    public static getAllowedShareModeIds = (sharedContent?: ISharedContentRecord | null): number[] => {
        let allowedShareModeIds: number[] = [];
        if (sharedContent) {
            if (sharedContent.table_info_id) {
                allowedShareModeIds = ALLOWED_SHARE_MODES[sharedContent.table_info_id] || [];
            }
            if (sharedContent.share_mode_id) {
                const smi = sharedContent.share_mode_id;
                if (!allowedShareModeIds.includes(smi)) {
                    allowedShareModeIds.push(smi);
                }
            }
        }
        return allowedShareModeIds;
    }

    public static async getOwnedGroupIds(userId: number): Promise<number[]> {

        const members = await ViewInstituteGroupMember.list({
            filter: {
                sec_user_id: userId,
                is_active: true,
                is_admin: true,
            }
        });
        let groupIds: number[] = members.map((element) => { return element.institute_group_id! });

        return groupIds;
    }

    asyncReload = async () => {
        try {
            //let sharedContentId: number | null = null;
            if (!me) return;
            let sharedContent: ISharedContentRecord | null;
            let sharedWith: IViewSharedWithRecord[];
            let selectedClassroomId: number | undefined | null = undefined;
            let isSharedInClassRoom = true;
            let ownedGroupIds = await UsrShareDialog.getOwnedGroupIds(me!.id);
            let isSubmission = false;

            if (this.props.sharedContentId) {
                let sharedContentId = this.props.sharedContentId;
                sharedContent = (await SharedContentCrud.load(sharedContentId)).record;

            } else {
                /* tableInfoId = this.props.tableInfoId!;
                 recordId = this.props.recordId!;*/
                sharedContent = this.state.sharedContent;

                let scList = (await SharedContentCrud.list({ filter: { table_info_id: this.props.tableInfoId!, rec_id: this.props.recordId! }, order_by: [{ name: "creation_time", desc: true }], limit: 1 }));
                if (scList.length != 0) {
                    sharedContent = scList[0];
                }
            }

            if (sharedContent.id) {
                sharedWith = await ViewSharedWith.list({
                    filter: { shared_content_id: sharedContent.id },
                    order_by: [
                        { name: "fullname" },
                        { name: "institute_group_name" }
                    ]
                });

                selectedClassroomId = sharedContent.shared_from_institute_group_id;

                const sharedContentRun = await SharedContentRunCrud.list({ filter: { is_active: true, shared_content_id: sharedContent.id } });
                isSubmission = sharedContentRun && sharedContentRun.length > 0;

                if ((this.state && !this.state.isSharedInClassRoom) || !selectedClassroomId) isSharedInClassRoom = false;

            } else {
                /* {
                    id: undefined,
                    table_info_id: tableInfoId,
                    rec_id: recordId,
                    max_run_count: 1,
                    send_overdue: false,
                    group_solution: false,
                    title: this.state.sharedItemTitle ? this.state.sharedItemTitle : ""
                }*/
                if (this.props.defaults) {
                    sharedContent.share_mode_id = coalesce(this.props.defaults.shareModeId, null);
                    sharedContent.max_run_count = coalesce(this.props.defaults.maxRunCount, 1);
                    sharedContent.send_overdue = coalesce(this.props.defaults.sendOverdue, false);
                    sharedContent.group_solution = coalesce(this.props.defaults.groupSolution, false);
                }
                sharedWith = [];
                let sharedItemTitle: string | null = this.props.sharedItemTitle || null;
                if (!sharedItemTitle) {
                    const crudClassProxy = getCrudClassProxyById(sharedContent.table_info_id!);
                    if (crudClassProxy) {
                        sharedItemTitle = (await crudClassProxy.asText(sharedContent.rec_id!, false)).text;
                    }
                }
                sharedContent.title = sharedItemTitle || "";
            }

            // Ha csak egy lehetséges van, akkor beállítjuk azt az egyet.
            if (!sharedContent.share_mode_id) {
                const allowedShareModeIds = UsrShareDialog.getAllowedShareModeIds(sharedContent);
                if (allowedShareModeIds.length == 1) {
                    sharedContent.share_mode_id = allowedShareModeIds[0];
                }
                /* else {
                    sharedContent.share_mode_id = ShareMode.PRACTICE;
                }*/
            }

            let sharedByMe = await ViewSharedByMe.list({
                filter: {
                    shared_by_id: me!.id,
                    table_info_id: sharedContent.table_info_id,
                    rec_id: sharedContent.rec_id,
                    is_active: true
                }, order_by: [{ name: "creation_time", desc: true }]
            });

            this.setState({
                loading: false, changed: false,
                sharedContent, sharedWith, toActivate: new Set([]), toInactivate: new Set([]),
                sharedByMe,
                selectedClassroomId,
                isSharedInClassRoom,
                ownedGroupIds,
                isSubmission
            });

            // Ha a user a sharedContentId-ben undefined-ot adott meg, akkor
            // megpróbáljuk megkérni hogy új megosztás helyett a legutolsó
            // szerkesztését indítsa el.

            if (sharedByMe.length > 0 && this.props.sharedContentId === undefined) {
                this.props.onSharedContentSelected(sharedByMe[0].id!);
            }

        } catch (error) {
            app.showErrorFromJsonResult(error);
        }
    }



    handleDateChange(value: string, fieldName: string) {
        const isSubmission = this.state.isSubmission;
        if (isSubmission) return;
        let sc = this.state.sharedContent;
        sc[fieldName] = value;

        this.setState({ sharedContent: sc, changed: true, isDateChanged: true });
    }

    handleInputChange(e: any, fieldName: string) {
        let sc = this.state.sharedContent;
        let value = e;
        if (e.target) {
            value = e.target.value;
            if (e.target && e.target.type === 'checkbox') {
                value = e.target.checked;
            } else if (e.target && (e.target.type == 'select-one' || e.target.type == 'number' || e.target.getAttribute("data-type") == "number")) {
                value = parseInt(e.target.value) || 0;//Number(e.target.value);
            }
        }

        sc[fieldName] = value;
        this.setState({ sharedContent: sc, changed: true });
    }

    private addUserToShare = async (userId: number | null) => {
        if (userId) {
            try {
                const alreadyAdded: IViewSharedWithRecord[] = this.state.sharedWith.filter((item) => item.sec_user_id === userId);
                if (alreadyAdded.length > 0) {
                    const sw = alreadyAdded[0];
                    if (sw.id! > 0) {
                        this.state.toActivate.add(sw.id!);
                        this.state.toInactivate.delete(sw.id!);
                    }
                    if (sw.is_active) {
                        app.showWarning(__("Figyelmeztetés"), __("Ez a felhasználó már hozzá van adva."));
                        this.setState({
                            toActivate: this.state.toActivate,
                            toInactivate: this.state.toInactivate,
                            lastAddedId: alreadyAdded[0].id!
                        });
                    } else {
                        sw.is_active = true;
                        this.setState({
                            sharedWith: this.state.sharedWith,
                            toActivate: this.state.toActivate,
                            toInactivate: this.state.toInactivate,
                            lastAddedId: alreadyAdded[0].id!,
                            changed: true
                        });
                    }
                } else {
                    const id = newId();
                    const members: IViewMemberNameRecord[] = await ViewMemberName.list({ filter: { id: userId } });
                    let member: IViewMemberNameRecord;
                    if (members.length) {
                        member = members[0];
                    } else {
                        member = { id: userId }
                    }
                    let record: IViewSharedWithRecord = {
                        id,
                        sec_user_id: userId,
                        is_active: true,
                        member_is_active: member.member_is_active,
                        fullname: member.fullname,
                        login_name: member.login_name,
                        email: member.email
                    }
                    const sharedWith = this.state.sharedWith;
                    sharedWith.push(record);
                    this.setState({ sharedWith, changed: true, lastAddedId: id });
                }
            } catch (error) {
                app.showErrorFromJsonResult(error);
            }
        }
    }


    private async classRoomChanged(instituteGroupId: any) {
        if ((typeof instituteGroupId === 'string'))
            if (instituteGroupId.trim() === '')
                instituteGroupId = '';
        if (instituteGroupId === '')
            instituteGroupId = null;

        if (instituteGroupId) {
            instituteGroupId = parseInt(instituteGroupId);
        }
        this.setState({ selectedClassroomId: instituteGroupId, changed: true });
    }

    private onAddGroupToShare = async (instituteGroupId: any) => {
        if ((typeof instituteGroupId === 'string'))
            if (instituteGroupId.trim() === '')
                instituteGroupId = '';
        if (instituteGroupId === '')
            instituteGroupId = null;

        if (instituteGroupId) {
            instituteGroupId = parseInt(instituteGroupId);
            try {
                const alreadyAdded: IViewSharedWithRecord[] = this.state.sharedWith.filter((item) => item.institute_group_id === instituteGroupId);
                if (alreadyAdded.length > 0) {
                    const sw = alreadyAdded[0];
                    if (sw.id! > 0) {
                        this.state.toActivate.add(sw.id!);
                        this.state.toInactivate.delete(sw.id!);
                    }
                    if (sw.is_active) {
                        app.showWarning(__("Figyelmeztetés"), __("Ez a csoport már hozzá van adva."));
                        this.setState({
                            toActivate: this.state.toActivate,
                            toInactivate: this.state.toInactivate,
                            lastAddedId: alreadyAdded[0].id!
                        });
                    } else {
                        sw.is_active = true;
                        this.setState({
                            sharedWith: this.state.sharedWith,
                            toActivate: this.state.toActivate,
                            toInactivate: this.state.toInactivate,
                            lastAddedId: alreadyAdded[0].id!,
                            changed: true
                        });
                    }
                } else {
                    const id = newId();
                    let institute_group_name: string = (await instituteGroupCrudClassProxy.asText(instituteGroupId, false)).text;
                    let record: IViewSharedWithRecord = {
                        id,
                        is_active: true,
                        institute_group_id: instituteGroupId,
                        institute_group_name
                    }
                    const sharedWith = this.state.sharedWith;
                    sharedWith.push(record);
                    this.setState({ sharedWith, changed: true, lastAddedId: id });
                }
            } catch (error) {
                app.showErrorFromJsonResult(error);
            }
        }
    }

    private onRemoveUser = (index: number) => {
        let sharedWith = this.state.sharedWith!;
        const sw = sharedWith[index];
        if (confirmDialog(__("Megerősítés"), __("Biztos benne, hogy eltávolítja ezt a felhasználót a megosztásból?"))) {
            if (sw.id! > 0) {
                sw.is_active = false;
                this.state.toInactivate.add(sw.id!);
                this.state.toActivate.delete(sw.id!);
            } else {
                sharedWith.splice(index, 1);
            }
            this.setState({
                sharedWith,
                toInactivate: this.state.toInactivate,
                toActivate: this.state.toActivate,
                changed: true
            });
        }
    }


    private onRemoveGroup = (index: number) => {
        let sharedWith = this.state.sharedWith!;
        const sw = sharedWith[index];
        if (confirm(sw.institute_group_name + "\n\n" + __("Biztos benne, hogy eltávolítja ezt a csoportot a megosztásból?"))) {
            if (sw.id! > 0) {
                sw.is_active = false;
                this.state.toInactivate.add(sw.id!);
                this.state.toActivate.delete(sw.id!);
            } else {
                sharedWith.splice(index, 1);
            }
            this.setState({
                sharedWith,
                toInactivate: this.state.toInactivate,
                toActivate: this.state.toActivate,
                changed: true
            });
        }
    }

    private onCancel = async () => {
        if (this.state.changed) {
            if (!await confirmDialog(__("Megszakítás"), __('Nem mentett módosításai vannak. Biztosan megszakítja?'), __("Megszakítás"))) {
                return;
            }
        }
        this.props.onCancel();
    }

    private onOpenSharedByMeTable = () => {
        this.setState({ sharedByMeTableOpened: true });
    }

    private onSave = async () => {
        // Megnézzük, hogy van-e a bárkivel megosztva.

        this.setState({ loading: true });

        let errorMsg = "";
        let sc = this.state.sharedContent;
        let isSubmission = false;
        let validationMessages = new Map<string, string>();

        if (!this.state.isSharedInClassRoom) {
            let actives = this.state.sharedWith.filter((item) => item.is_active);
            if (actives.length == 0) {
                errorMsg = __("Legalább egy személy megadása kötelező!");
                app.showError(__("Hiba"), errorMsg);
                validationMessages.set("selected_users", errorMsg);
            }
        } else {
            if (!this.state.selectedClassroomId) {
                errorMsg = __("A tanulócsoport megadása kötelező!");
                app.showError(__("Hiba"), errorMsg);
                validationMessages.set("selected_users", errorMsg);
            }
        }
        if (sc.id) {
            if (sc.shared_from_institute_group_id != this.state.selectedClassroomId) {
                errorMsg = __("Ez a módosítás nem megengedett! Új megosztást kell létrehozni!");
                app.showError(__("Hiba"), errorMsg);
                validationMessages.set("selected_users", errorMsg);
            }

            const sharedContentRun = await SharedContentRunCrud.list({ filter: { is_active: true, shared_content_id: sc.id } });
            isSubmission = sharedContentRun && sharedContentRun.length > 0;

            if (isSubmission) {
                const dbsc = (await SharedContentCrud.load(sc.id)).record;
                // Bármilyen módban, ha már van beküldött, akkor nem lehet dátumokat változtatni
                if (this.state.isDateChanged) {
                    errorMsg = __("Szerkesztés közben érkezett megoldás a megosztásra. A határidők nem változtathatóak!");
                    app.showError(__("Hiba"), errorMsg);
                    validationMessages.set("date_submission_error", errorMsg);
                }
                // Bármilyen módban, ha már van beküldött nem lehet a pontszámot módosítani
                if (sc.max_points != dbsc.max_points) {
                    errorMsg = __("Szerkesztés közben érkezett megoldás. A max. pontszám nem módosítható!");
                    app.showError(__("Hiba"), errorMsg);
                    validationMessages.set("maxp_submission_error", errorMsg);
                }
                // Vizsga módban, ha már van beküldött nem lehet lejátszások számát módosítani
                if (dbsc.share_mode_id == SHARE_VIZSGA_ID && sc.max_run_count != dbsc.max_run_count) {
                    errorMsg = __("Szerkesztés közben érkezett megoldás. A lejátszások száma nem módosítható!");
                    app.showError(__("Hiba"), errorMsg);
                    validationMessages.set("maxrunc_submission_error", errorMsg);
                }
            }
        }
        if (!sc.rec_id || !sc.table_info_id) {
            errorMsg = __("Adjon meg megosztandó feladatot!");
            app.showError(__("Hiba"), errorMsg);
            validationMessages.set("seletedItem", errorMsg);
        }
        if (!sc.share_mode_id) {
            errorMsg = __("A megosztási mód kötelező!");
            app.showError(__("Hiba"), errorMsg);
            validationMessages.set("share_mode_id", errorMsg);
        }
        if (!sc.max_points) sc.max_points = 100;
        if (sc.max_points < 0 || sc.max_points > 1000) {
            errorMsg = __("A pontszám 0-1000 közötti szám kell hogy legyen!");
            app.showError(__("Hiba"), errorMsg);
            validationMessages.set("max_points", errorMsg);
        }
        if (!sc.end_date && sc.due_date) {
            errorMsg = __("Ha van leadási határidő, kell kiosztás vége dátum is!");
            app.showError(__("Hiba"), errorMsg);
            validationMessages.set("end_date", errorMsg);
        }
        if (sc.end_date && sc.due_date) {
            if (sc.end_date < sc.due_date) {
                errorMsg = __("A leadási határidő nem lehet a láthatóság vége után!");
                app.showError(__("Hiba"), errorMsg);
                validationMessages.set("due_date", errorMsg);
            }
        }
        if (sc.send_overdue && !sc.due_date) {
            errorMsg = __("Határidő nincs megadva!");
            app.showError(__("Hiba"), errorMsg);
            validationMessages.set("due_date", errorMsg);
        }
        if (validationMessages.size > 0) {
            this.setState({ loading: false, validationMessages, isSubmission });
            return;
        }

        try {
            const sharedContent = this.state.sharedContent!;
            /* 
               Ezeket se beszúráskor, se módosításkor adjuk meg.
               Mindig trigger tölti őket.
            */
            sharedContent.creation_time = undefined;
            sharedContent.creation_user_id = undefined;
            sharedContent.creation_session_id = undefined;
            sharedContent.modification_time = undefined;
            sharedContent.modification_user_id = undefined;
            sharedContent.modification_session_id = undefined;
            sharedContent.is_active = undefined;
            let swList = this.state.sharedWith;
            if (sharedContent.id) {
                /* 
                   Ezeket módosításkor tilos megadni, nem módosíthatók.
                   Helyette legfeljebb egy új megosztást lehet létrehozni.
                */
                sharedContent.shared_by_id = undefined;
                sharedContent.table_info_id = undefined;
                sharedContent.rec_id = undefined;
            } else if (this.state.selectedClassroomId && swList.length == 0) {
                // when we just created a share in a classroom, we add the group for the first time
                // if no specific user is selected separately.
                sharedContent.shared_from_institute_group_id = this.state.selectedClassroomId;
                const id = newId();
                let institute_group_name: string = (await instituteGroupCrudClassProxy.asText(this.state.selectedClassroomId, false)).text;
                let record: IViewSharedWithRecord = {
                    id,
                    is_active: true,
                    institute_group_id: this.state.selectedClassroomId,
                    institute_group_name
                }
                swList.push(record);
            }
            let record: ISharedContentRecord = (await new SharedContentCrud(sharedContent).put()).record;
            // Töröljük a régieket

            for (let index: number = 0; index < swList.length; index++) {
                const sw = swList[index];
                if (sw.id! > 0) {
                    if (this.state.toActivate.has(sw.id!)) {
                        await (new SharedWithCrud({ id: sw.id!, is_active: true })).put();
                    }
                    else if (this.state.toInactivate.has(sw.id!)) {
                        await (new SharedWithCrud({ id: sw.id!, is_active: false })).put();
                    }
                } else {
                    await (new SharedWithCrud({
                        shared_content_id: record.id!,
                        sec_user_id: sw.sec_user_id,
                        institute_group_id: sw.institute_group_id
                    })).put()
                }
            }
            if (this.props.sharedContentId) {
                this.setState({ loading: false, validationMessages }, () => this.props.onSaved(record));
            } else {
                this.setState({ loading: false, validationMessages }, () => this.props.onCreated(record));
            }
        } catch (error) {
            app.showErrorFromJsonResult(error);
        }
    }

    private onSharedContentSelected = async (sharedContentId: number | null) => {
        if (this.state.changed) {
            if (!await confirmDialog(__("Kiosztás váltása"), __('Nem mentett módosításai vannak. Biztosan folytatja?'))) {
                return;
            }
        }
        this.setState({ sharedByMeTableOpened: false, loading: true },
            () => { this.props.onSharedContentSelected(sharedContentId); }
        );
    }

    private onSharedContentDelete = async (sharedContentId: number) => {
        if (!await confirmDialog(__("Törlés"),
            __("Ez a művelet törli a kiválasztott megosztást. A törlés után a tartalom elérhetetlen lesz a megosztáson keresztül. A művelet nem vonható vissza. Biztos benne, hogy törölni akarja?"),
            __("Törlés")
        )) {
            return;
        }
        try {
            await SharedContentCrud.deleteById(sharedContentId);
            this.props.onSharedContentDeleted(sharedContentId);
        } catch (error) {
            app.showErrorFromJsonResult(error);
        }
    }


    render() {
        if (this.state.loading) {
            return <BubbleLoader />;
        }

        const sc = this.state.sharedContent!;

        let isShare: boolean = sc.share_mode_id == ShareMode.SHARE;
        const isSubmission = this.state.isSubmission;
        let opName: string = isShare ? __("Megosztás") : __("Kiosztás");
        let canSave: boolean = (
            // this.state.changed
            sc
            && sc.share_mode_id
        ) ? true : false;



        const title = <div className="row expanded">
            <div className="column small-12 large-12">
                <h5>{opName} {(sc && sc.id) ? " " + __("módosítása") : " " + __("létrehozása")}: {sc.title}
                </h5>
            </div>
        </div>;

        if (this.state.sharedByMeTableOpened) {
            return <>
                <DialogContent>
                    {title}
                    <h4>{__("Melyik megosztást kívánja módosítani?")}</h4>
                    <table className="hover">
                        <thead>
                            <tr>
                                <th>{__("Név")}<br /><small>{__("Leírás")}</small></th>
                                <th>
                                    <small>
                                        {__("Létrehozva")}<br />
                                        {__("Kezdete")}
                                    </small>
                                </th>
                                <th>
                                    <small>
                                        {__("Határidő")}<br />
                                        {__("Vége")}
                                    </small>
                                </th>
                                <th><small>{__("Mód")}</small></th>
                                <th>{__("Művelet")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.sharedByMe.map((item: IViewSharedByMeRecord) => {
                                return <tr key={item.id}>
                                    <td>{item.title}<br /><small>{item.description}</small></td>
                                    <td>
                                        <small>
                                            {formatDate(item.creation_time)}<br />
                                            {formatDate(item.start_date)}
                                        </small>
                                    </td>
                                    <td>
                                        <small>
                                            {formatDate(item.due_date)}<br />
                                            {formatDate(item.end_date)}
                                        </small>
                                    </td>
                                    <td><small>{item.share_mode_title}</small></td>
                                    <td>
                                        <button className={"button " + (this.state.sharedContent!.id == item.id ? "secondary" : "primary")}
                                            onClick={() => {
                                                if (this.state.sharedContent!.id == item.id) {
                                                    this.setState({ sharedByMeTableOpened: false })
                                                } else {
                                                    this.onSharedContentSelected(item.id!)
                                                }
                                            }}
                                            title={__("Kattintson a megosztás szerkesztéséhez")}
                                            style={{ marginBottom: 0 }}
                                        >
                                            <i className="fa fa-edit" />
                                        </button>
                                        <button className="button alert"
                                            onClick={() => this.onSharedContentDelete(item.id!)}
                                            title={__("Kattintson a megosztás törléséhez")}
                                            style={{ marginBottom: 0 }}
                                        >
                                            <i className="fa fa-trash" />
                                        </button>
                                    </td>
                                </tr>
                            })}
                        </tbody>
                    </table>
                </DialogContent>
                <DialogActions>
                    <button className="button primary"
                        aria-label={__("Vissza")} type="button"
                        onClick={() => this.setState({ sharedByMeTableOpened: false })}>
                        <i className="fa fa-backward" />&nbsp; {__("Vissza")}
                    </button>
                </DialogActions>
            </>
        }

        const header =
            <div className="row expanded">
                <div className="medium-12 column text-right">

                    {
                        (!this.props.sharedContentId && this.state.sharedByMe.length > 0)
                            ||
                            (this.props.sharedContentId && this.state.sharedByMe.length > 1)
                            ?
                            <button className="button primary" onClick={this.onOpenSharedByMeTable}>
                                <i className="fa fa-edit" />&nbsp; {__("Korábbi")} {opName} {__("kiválasztása szerkesztésre...")} ({this.state.sharedByMe.length})
                        </button>
                            :
                            null
                    }
                    &nbsp;
                    {
                        this.props.sharedContentId
                            ?
                            <button className="button primary"
                                onClick={() => this.onSharedContentSelected(null)}
                                title={__("Kattintson az új megosztás létrehozásához")}
                            >
                                <i className="fa fa-file" />&nbsp; {__("Új")} {opName} {__("létrehozása")}
                            </button>
                            :
                            null
                    }
                    &nbsp;
                    {
                        this.props.sharedContentId
                            ?
                            <button className="button alert"
                                onClick={() => this.onSharedContentDelete(this.props.sharedContentId!)}
                                title={__("Kattintson a megosztás törléséhez")}
                            >
                                <i className="fa fa-trash" />&nbsp; {opName} {__("törlése")}
                            </button>
                            :
                            null
                    }
                </div>
            </div>
            ;

        let form: JSX.Element | null = null;
        let formDetails: JSX.Element | null = null;
        let sharewith: JSX.Element | null = null;
        let shareInClassroom: JSX.Element | null = null;

        const allowedShareModeIds = UsrShareDialog.getAllowedShareModeIds(this.state.sharedContent);
        if (allowedShareModeIds.length == 0) {
            form = <div className="row expanded">
                <div className="medium-12 column">
                    <p className="callout alert">
                        {__("Ezt a tartalomtípust nem lehet megosztani.")}
                    </p>
                </div>
            </div>
        } else if (!this.state.sharedByMeTableOpened) {
            form =
                <div className="row expanded">

                    <div className="medium-12 column">
                        <label>{__("Cím")}: <span className="exe-editor-validation-msg">{this.state.validationMessages.get("title")}</span>
                            <input
                                type="text"
                                value={sc.title || ""}
                                placeholder={__("Írja be a megosztás címét")}
                                onChange={(event) => this.handleInputChange(event, "title")}
                            />
                        </label>
                    </div>
                    <div className="medium-12 column">
                        <label>{__("Megosztás módja")}:<span className="exe-editor-validation-msg">{this.state.validationMessages.get("share_mode_id")}</span>
                            <CrudSelect
                                value={sc.share_mode_id! || null}
                                onSelect={(sender, value) => this.handleInputChange(value, "share_mode_id")}
                                emptyTitle={__(`-- Kérem adja meg a {name} módját --`, { name: opName })}
                                crudClassProxy={shareModeCrudClassProxy}
                                onFilter={(record: any) => allowedShareModeIds.includes(record.id)}
                                displayFieldName="title"
                                orderByFieldName="id"
                                disabled={(sc.id ? true : false) && this.state.canChangeShareMode}
                            />
                        </label>
                    </div>
                    {!isShare && <div className="medium-12 column">
                        <label>{__("Maximum pontszám")}<span className="exe-editor-validation-msg">{this.state.validationMessages.get("max_points")}</span>
                            <input type="number"
                                value={Number(sc.max_points || 0).toString()}
                                min={0} max={1000}
                                onChange={(event) => this.handleInputChange(event, "max_points")}
                                disabled={isSubmission}
                            />
                        </label>
                    </div>
                    }
                </div>;

            formDetails =
                <div className="row expanded">

                    <div className="medium-12 column">
                        <label>{__("Leírás")}:  <span className="exe-editor-validation-msg">{this.state.validationMessages.get("description")}</span>
                            <input
                                type="text"
                                value={sc.description || ""}
                                placeholder={__("Írja be a megosztás leírását")}
                                onChange={(event) => this.handleInputChange(event, "description")}
                            />
                        </label>
                    </div>

                    {isSubmission && <div className="medium-12 column">
                        <label className="label alert">{__("A kiosztásra már érkezett megoldás. A határidők nem módosíthatóak!")}</label>
                    </div>}

                    <div className="medium-6 column">
                        <label>{__("Látható ettől")}: <span className="exe-editor-validation-msg">{this.state.validationMessages.get("start_date")}</span>
                            <DateTimePicker
                                value={sc.start_date}
                                onChange={(date, time) => this.handleDateChange(date + " " + time, "start_date")}
                                disabled={isSubmission}
                            />
                        </label>
                    </div>

                    <div className="medium-6 column">
                        <label>{__("Látható eddig")}: <span className="exe-editor-validation-msg">{this.state.validationMessages.get("end_date")}</span>
                            <DateTimePicker
                                value={sc.end_date}
                                onChange={(date, time) => this.handleDateChange(date + " " + time, "end_date")}
                                disabled={isSubmission}
                            />
                        </label>
                    </div>

                    <div className="medium-6 column">
                        <label>{__("Leadás határideje")}:  <span className="exe-editor-validation-msg">{this.state.validationMessages.get("due_date")}</span>
                            <DateTimePicker
                                value={isShare ? null : sc.due_date}
                                onChange={(date, time) => this.handleDateChange(date + " " + time, "due_date")}
                                disabled={isShare || this.state.isSubmission}
                            />
                        </label>
                    </div>

                    {isShare ? null :
                        <div className="medium-6 column">
                            <label>{__("Lejátszások max. száma (0=végtelen)")}:<span className="exe-editor-validation-msg">{this.state.validationMessages.get("max_run_count")}</span>
                                <input
                                    value={sc.max_run_count || 0}
                                    disabled={!this.state.canChangemaxRunCount || isShare || (isSubmission && sc && sc.share_mode_id == SHARE_VIZSGA_ID)}
                                    type="number"
                                    min={0} max={1000}
                                    onChange={(event) => this.handleInputChange(event, "max_run_count")}
                                />
                            </label>
                        </div>
                    }

                    {/*isShare ? null :
                        <div className="medium-6 column">
                            <label>{__("Határidő után leadható")}
                                <div className="switch">
                                    <input className="switch-input"
                                        id="send_overdue" type="checkbox"
                                        checked={sc.send_overdue}
                                        onChange={(event) => this.handleInputChange(event, "send_overdue")}
                                        disabled={!this.state.canChangeSendOverdue || isShare}
                                    />
                                    <label className="switch-paddle" htmlFor="send_overdue">
                                        <span className="show-for-sr">{__("Határidő után leadható")}</span>
                                    </label>
                                </div>
                            </label>
                        </div>
                    */}

                    {/*isShare ? null :
                        <div className="medium-4 column">
                            <label >{__("Csoportos leadás")}
                                <div className="switch">
                                    <input className="switch-input"
                                        id="group_solution" type="checkbox"
                                        checked={sc.group_solution}
                                        onChange={(event) => this.handleInputChange(event, "group_solution")}
                                        disabled={!this.state.canChangeGroupSolution || isShare}
                                    />
                                    <label className="switch-paddle" htmlFor="group_solution">
                                        <span className="show-for-sr">{__("Csoportos leadás")}</span>
                                    </label>

                                </div>
                            </label>
                        </div>
                    */}
                </div>;


            sharewith = <div className="row expanded">
                <div className="column small-12 large-12">
                    <label>{__("Felhasználó hozzáadása")}:<span className="exe-editor-validation-msg">{this.state.validationMessages.get("selected_users")}</span>
                        <SecUserSelector clearable={false} onChange={this.addUserToShare} value={null} />
                    </label>
                </div>
                {/* <div className="column small-12 large-6">
                    <label>{__("Intézményi csoport hozzáadása")}:
                                <LookupEdit
                            value={undefined}
                            clearable={false}
                            onChange={this.onAddGroupToShare}
                            placeholder={__(`(-- Kérem adja meg a csoportot --)`)}
                            fk_table_info_id={InstituteGroupCrud.TABLE_INFO_ID}
                        />
                    </label>
                </div>*/}
                <div className="column small-12">
                    {
                        this.state.sharedWith.length > 0
                            ?
                            <>
                                <label>{__("Kiválasztott felhasználók")}:</label>
                                <table className="hover" style={{ width: "100%" }}>
                                    <thead>
                                        <tr>
                                            <th style={{ width: "80%" }}>{__("Név")}</th>
                                            <th style={{ textAlign: "right" }}>{__("Eltávolítás")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        {this.state.sharedWith.map((record: IViewSharedWithRecord, index: number) => {
                                            if (record.sec_user_id && record.is_active) {
                                                return <tr key={record.id} className={record.id == this.state.lastAddedId ? "usr-share-dialog-newly-added" : ""}>
                                                    <td><MemberCard {...record} id={record.sec_user_id} /></td>
                                                    <td style={{ textAlign: "right" }}>
                                                        <button className="button alert" onClick={() => this.onRemoveUser(index)} style={{ marginBottom: 0 }}>
                                                            <i className="fa fa-trash" />
                                                        </button>
                                                    </td>
                                                </tr>
                                            }
                                            return null;
                                        })}

                                        {this.state.sharedWith.map((record: IViewSharedWithRecord, index: number) => {
                                            if (record.institute_group_id && record.is_active) {
                                                return <tr key={record.id} className={record.id == this.state.lastAddedId ? "usr-share-dialog-newly-added" : ""}>
                                                    <td>{record.institute_group_name}</td>
                                                    <td><button className="button alert" onClick={() => this.onRemoveGroup(index)}>
                                                        <i className="fa fa-trash" />
                                                    </button></td>
                                                </tr>
                                            }
                                            return null;
                                        })}
                                    </tbody>
                                </table>
                            </>
                            :
                            null
                    }

                </div>
            </div>

            shareInClassroom = <div className="row expanded">
                <div className="column small-12 large-6">
                    <label>{__("Tanuló csoport kiválasztása")}:<span className="exe-editor-validation-msg">{this.state.validationMessages.get("selected_users")}</span>
                        <LookupEdit
                            value={this.state.selectedClassroomId}
                            clearable={false}
                            filter={{ id: this.state.ownedGroupIds }}
                            onChange={(ev) => this.classRoomChanged(ev)}
                            placeholder={__(`(-- Kérem adja meg a csoportot --)`)}
                            viewClassProxy={viewInstituteGroupClassProxy}
                            fk_table_info_id={InstituteGroupCrud.TABLE_INFO_ID}
                            valueColumn="id"
                            displayColumnNames={["name", "institute_site_name"]}
                            searchColumnNames={["name"]}
                        />
                    </label>
                </div>
                <div className="column small-12 large-6">
                    <label>{__("Csak adott felhasználó") + ": "}<span><small><em>{__("Ha nem ad meg külön címzettet, akkor az összes csoporttagnak szól.")}</em></small></span>
                        {/* <SecUserSelector clearable={false} groupId={this.state.selectedClassroomId || undefined} onChange={this.addUserToShare} value={null} /> */}
                        <LookupEdit
                            key={"member_name_selector"}
                            fk_table_info_id={SecUserCrud.TABLE_INFO_ID}
                            emptyLoad={true}
                            viewClassProxy={viewInstituteGroupMemberClassProxy}
                            distinct={true}
                            searchColumnNames={["member_name", "member_email"]}
                            displayColumnNames={["member_name", "member_email"]}
                            orderByColumnNames={[{ name: "member_name" }]}
                            valueColumn={"sec_user_id"}
                            filter={{
                                is_active: true,
                                institute_group_id: this.state.selectedClassroomId,
                                is_admin: false,
                                status_id: InstituteGroupMemberStates.ACTIVE_ID
                            }}
                            clearable={true}
                            // value={this.state.selectedMemberId}
                            onChange={value => this.addUserToShare(value as number)}
                            placeholder={__("Kérem válasszon diákot...")} />
                    </label>
                </div>
                <div className="column small-12 large-12">
                    {
                        this.state.sharedWith.length > 0
                            ?
                            <>
                                <label>{__("Kiválasztott felhasználók")}:</label>
                                <table className="hover" style={{ width: "100%" }}>
                                    <thead>
                                        <tr>
                                            <th style={{ width: "80%" }}>{__("Név")}</th>
                                            <th style={{ textAlign: "right" }}>{__("Eltávolítás")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        {this.state.sharedWith.map((record: IViewSharedWithRecord, index: number) => {
                                            if (record.sec_user_id && record.is_active) {
                                                return <tr key={record.id} className={record.id == this.state.lastAddedId ? "usr-share-dialog-newly-added" : ""}>
                                                    <td><MemberCard {...record} id={record.sec_user_id} /></td>
                                                    <td style={{ textAlign: "right" }}>
                                                        <button className="button alert" onClick={() => this.onRemoveUser(index)} style={{ marginBottom: 0 }}>
                                                            <i className="fa fa-trash" />
                                                        </button>
                                                    </td>
                                                </tr>
                                            }
                                            return null;
                                        })}

                                    </tbody>
                                </table>
                            </>
                            :
                            null
                    }
                </div>
            </div>
        }

        return <>
            <DialogContent>
                {title}
                {header}
                {form}
                <Accordion>
                    <AccordionItem title={<span><img src={"/img/IKON_SET/FLAT/deadline.svg"} className="share-page-icon" alt={__("Határidők/részletek")} />{__("Határidők/részletek")}</span>} defaultClosed key={"details_accordion"}>
                        {formDetails}
                    </AccordionItem>
                    <AccordionItem title={<span><img src={"/img/IKON_SET/FLAT/user.svg"} className="share-page-icon" alt={__("Címzettek")} />{__("Címzettek")}</span>} defaultClosed key={"recipients_accordion"} >
                        <label >{opName + " " + __("tanulócsoportban")}
                            <div className="switch">
                                <input className="switch-input"
                                    id="isSharedInClassRoom" type="checkbox"
                                    checked={this.state.isSharedInClassRoom}
                                    disabled={sc.id != undefined || sc.id != null}
                                    onChange={(event) => { this.setState({ isSharedInClassRoom: !this.state.isSharedInClassRoom }) }}
                                />
                                <label className="switch-paddle" htmlFor="isSharedInClassRoom">
                                    <span className="show-for-sr">{opName + " " + __("tanulócsoportban")}</span>
                                </label>
                            </div>
                        </label>

                        {this.state.isSharedInClassRoom ? shareInClassroom : sharewith}
                    </AccordionItem>
                </Accordion>
            </DialogContent>
            <DialogActions>
                <div>
                    {sc && sc.creation_time && <><strong>{__("Létrehozva")}:</strong> {formatDate(sc.creation_time)}<br /></>}
                    {sc && sc.modification_time && <><strong>{__("Utoljára módosítva")}:</strong> {formatDate(sc.modification_time)}<br /></>}
                </div>

                <div style={{ flex: 1 }} />

                <button className="button alert" data-close="" aria-label={__("Bezár")} type="button"
                    onClick={() => this.onCancel()}>
                    <i className="fa fa-times" />&nbsp; {__("Mégse")}
                </button>
                {
                    allowedShareModeIds.length > 0
                        ?
                        <button className="button success"
                            aria-label={opName} type="button"
                            disabled={!canSave}
                            onClick={this.onSave}
                        >
                            <i className="fa fa-save" />&nbsp; {__("Mentés")}
                        </button>
                        :
                        null
                }
            </DialogActions>
        </>;
    }

}