import { Module, ModuleMenu, BaseMenu, ModuleRoute } from "../Module";
import { me, hasGroup, Groups, hasAnyGroup } from "@src/framework/server/Auth";
import { __ } from "@src/translation";
import { ClassroomListPage } from "./ClassroomListPage";
import { ClassroomNewsPage } from "./ClassroomNewsPage";
import { ClassroomTasksPage } from "./ClassroomTasksPage";
import { ClassroomUsersPage } from "./ClassroomUsersPage";
import { ClassroomFolderPage } from "./ClassroomFolderPage";
import InstituteGroupCrud from "@src/framework/crud/sys/InstituteGroupCrud";
import * as React from "react";
import { Link } from "react-router-dom";
import ClassRoom from "./component/Classroom";
import { ClassroomResults } from "./ClassroomResults";

export let classroomModule: ClassroomModule<BaseMenu>;

export type ClassroomModuleConfig = {
    
}

export class ClassroomModule<T extends BaseMenu> extends Module<T> {

    private config: ClassroomModuleConfig;

    MATCH_INSTITUTE_GROUP_ID = "instituteGroupId";

    public PATH_CLASSROOM = "/tanulocsoport";
    public PATH_CLASSROOM_LIST = this.PATH_CLASSROOM + "/lista";

    public PATH_CLASSROOM_NEWS = this.PATH_CLASSROOM + "/hirfolyam";
    public PATH_CLASSROOM_TASKS = this.PATH_CLASSROOM + "/feladat";
    public PATH_CLASSROOM_USERS = this.PATH_CLASSROOM + "/csoporttagok";
    public PATH_CLASSROOM_FOLDER = this.PATH_CLASSROOM + "/mappak";
    public PATH_CLASSROOM_RESULTS = this.PATH_CLASSROOM + "/eredmenyek";

    public multipleTeachersEnabled = false;

    private static VIEW_ROUTES = {
        [InstituteGroupCrud.TABLE_INFO_ID]: "/tanulocsoport/hirfolyam/"
    }

    constructor(config: ClassroomModuleConfig) {
        super();
        this.config = config;
    }

    public static initGlobal<T extends BaseMenu>(config: ClassroomModuleConfig) {
        classroomModule = new ClassroomModule<T>(config);

    }

    public async getRoutesAsync(): Promise<Record<string, ModuleRoute<T>>> {
        return this.getRoutes();
    }

    public static getTaskBreadCrumbs(groupId: number, groupName: string): any[] {
        let name = (groupName && groupName != "") ? __("{groupname} feladatok", { groupname: groupName }) : __("Feladatok");
        let links: any[] = [
            { name: name, path: classroomModule.PATH_CLASSROOM_TASKS + "/" + groupId },
        ];
        return links;
    }

    public getRoutes(): Record<string, ModuleRoute<T>> {
        let currentGroupPathPart = this.getCurrentGroupPart();
        // const instituteGroupId = Number(currentGroupPathPart.replace('/', ''));

        let sidebarHeader = <div><Link to={this.PATH_CLASSROOM_LIST}><i className="fas fa-arrow-left" style={{ marginRight: "10px" }}></i>{__("Tanulócsoportok")}</Link></div>;
        return {
            'PATH_CLASSROOM_LIST': { path: this.PATH_CLASSROOM_LIST, component: ClassroomListPage, exact: true },

            'PATH_CLASSROOM_NEWS': { path: this.PATH_CLASSROOM_NEWS + "/:instituteGroupId", render: (props) => <ClassRoom key="cl" {...props}><ClassroomNewsPage {...props}></ClassroomNewsPage></ClassRoom>, exact: true, hasSidebar: true, menuCode: "classroom-sidebar", sidebarHeader: sidebarHeader, sideBarSize: 250 },
            'PATH_CLASSROOM_TASKS': { path: this.PATH_CLASSROOM_TASKS + "/:instituteGroupId", render: (props) => <ClassRoom key="cl" {...props}><ClassroomTasksPage {...props}></ClassroomTasksPage></ClassRoom>, exact: true, hasSidebar: true, menuCode: "classroom-sidebar", sidebarHeader: sidebarHeader, sideBarSize: 250 },
            'PATH_CLASSROOM_USERS': { path: this.PATH_CLASSROOM_USERS + "/:instituteGroupId", render: (props) => <ClassRoom key="cl" {...props}><ClassroomUsersPage {...props}></ClassroomUsersPage></ClassRoom>, exact: true, hasSidebar: true, menuCode: "classroom-sidebar", sidebarHeader: sidebarHeader, sideBarSize: 250 },
            'PATH_CLASSROOM_FOLDER': { path: this.PATH_CLASSROOM_FOLDER + "/:instituteGroupId/:folderId?", render: (props) => <ClassRoom key="cl" {...props}><ClassroomFolderPage {...props}></ClassroomFolderPage></ClassRoom>, exact: true, hasSidebar: true, menuCode: "classroom-sidebar", sidebarHeader: sidebarHeader, sideBarSize: 250 },
            'PATH_CLASSROOM_RESULTS': { path: this.PATH_CLASSROOM_RESULTS + "/:instituteGroupId", render: (props) => <ClassRoom key="cl" {...props}><ClassroomResults {...props}></ClassroomResults></ClassRoom>, exact: true, hasSidebar: true, menuCode: "classroom-sidebar", sidebarHeader: sidebarHeader, sideBarSize: 250 },
        };
    }

    public getRecordRoute(tableInfoId: number, recordId: number, edit?: boolean): string | null {
        let route = ClassroomModule.VIEW_ROUTES[tableInfoId];
        if (route !== undefined) {
            return route + recordId;
        }
        return null;
    }

    private getCurrentGroupPart() {
        let currentGroupPathPart = "";

        const currentPath = window.location.pathname;

        const routes = [
            this.PATH_CLASSROOM_NEWS,
            this.PATH_CLASSROOM_TASKS,
            this.PATH_CLASSROOM_USERS,
            this.PATH_CLASSROOM_FOLDER,
            this.PATH_CLASSROOM_RESULTS
        ];
        
        const route = routes.find(r => currentPath.startsWith(r));
        if (route) {
            const split = currentPath.substr(route.length).split("/");
            if (split.length > 1) currentGroupPathPart = "/" + split[1];
        }

        return currentGroupPathPart;
    }

    public getMenus() {
        let ret: ModuleMenu<T>[] = [];

        let currentGroupPathPart = this.getCurrentGroupPart();

        let showResults = (me && hasAnyGroup(me,[Groups.Developer,Groups.Admin]));
        /*if (me) {
            const admin: IInstituteGroupMemberRecord[] = await InstituteGroupMemberCrud.list({
                filter: {
                    institute_group_id: Number(currentGroupPathPart),
                    is_active: true,
                    is_admin: true,
                    sec_user_id: me.id
                }
            });
            if (admin.length > 0) showResults = true;
        }*/

        ret = ret.concat(
            [
                {
                    enabled: true,
                    title: __("Tanulócsoportok"),
                    path: this.PATH_CLASSROOM_LIST,
                    iconClass: "fa fa-user-friends",
                    no: 100,
                    code: "header-function"
                },
                /*{
                    enabled: true,
                    title: __("Tanulócsoportok"),
                    path: this.PATH_CLASSROOM_LIST,
                    iconClass: "fa fa-user-friends",
                    code: "contentstore-sidebar",
                    no: 120
                },*/

                {
                    enabled: true,
                    title: __("Üzenőfal"),
                    path: this.PATH_CLASSROOM_NEWS + currentGroupPathPart,
                    iconClass: "fa fa-newspaper",
                    code: "classroom-sidebar",
                    no: 10,
                    matchName: this.MATCH_INSTITUTE_GROUP_ID
                },
                {
                    enabled: true,
                    title: __("Feladatok"),
                    path: this.PATH_CLASSROOM_TASKS + currentGroupPathPart,
                    iconClass: "fa fa-tasks",
                    code: "classroom-sidebar",
                    no: 20,
                    matchName: this.MATCH_INSTITUTE_GROUP_ID
                },                
                {
                    enabled: true,
                    title: __("Eredmények"),
                    path: this.PATH_CLASSROOM_RESULTS + currentGroupPathPart,
                    iconClass: "far fa-chart-bar",
                    code: "classroom-sidebar",
                    no: 30,
                    matchName: this.MATCH_INSTITUTE_GROUP_ID
                },
                {
                    enabled: true,
                    title: __("Csoporttagok"),
                    path: this.PATH_CLASSROOM_USERS + currentGroupPathPart,
                    iconClass: "fa fa-users",
                    code: "classroom-sidebar",
                    no: 40,
                    matchName: this.MATCH_INSTITUTE_GROUP_ID
                },
                {
                    enabled: true,
                    title: __("Csoport mappák"),
                    path: this.PATH_CLASSROOM_FOLDER + currentGroupPathPart,
                    iconClass: "fa fa-folder",
                    code: "classroom-sidebar",
                    no: 50,
                    matchName: this.MATCH_INSTITUTE_GROUP_ID
                }
            ]);

       
        


        return ret;
    }

}


