import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { blockStatement } from '@babel/types';

export class SiteNameRibbon extends React.Component<any, {opacity: number}> {

    constructor(props: any) {
        super(props);

        this.state = {
            opacity: 1
        };
    }

    componentDidMount()
    {
        document.addEventListener("mousemove", this.onMouseMove.bind(this));
    }

    componentWillUnmount()
    {
        document.removeEventListener("mousemove", this.onMouseMove.bind(this));
    }

    onMouseMove(e: MouseEvent)
    {
        const rect = (this.refs.ribbon as HTMLElement) ? (this.refs.ribbon as HTMLElement).getBoundingClientRect() : undefined;
        if (rect && e.clientY <= rect.bottom && e.clientX >= rect.left ) {
            this.setState({
                opacity: 0,                
            });
        }
        else
        {
            this.setState({
                opacity: 1,
            });
        }
    }

    render()
    {
        return <span ref="ribbon" style={{color: this.props.siteNameColor, backgroundColor: this.props.backgroundColor, opacity: this.state.opacity}} className="show-for-large eke-top-bar__site-name">
                    <span>{this.props.siteName}</span>
                    <span>{this.props.siteUpdated}</span>
                </span>;
    }
}

