import './style.css';
import { ExerciseBaseClass } from '@src/component/exercise/models/ExerciseBaseClass';
import { AExerciseEngine, ShuffleResult } from '../../models/AExerciseEngine';
import { __ } from '@src/translation';
import { EKEFillGapDropDownServer } from './EKEFillGapDropDownServer';

export interface FillGapData extends ExerciseBaseClass {
    gap_text: string;
    answers: string[];
    infinite_elements: boolean;
    multiple_lists: boolean;
}

type FillGapUserSolution = {
    answer: number[];
}

export class EKEFillGapDropDownExerciseEngine extends AExerciseEngine {

    private multipleList: boolean = false;
    private selectorsList: HTMLElement[] = [];
    private selectedIndexList: number[] = [];
    private originalAnswers: any[] = [];
    private shuffledAnswers: any[] = [];
    private shuffledIndexes: any[] = [];

    apiVersion = "1.0";
    initExercise(params: ExerciseParams): void {
        super.initExercise(params);
        let exercise: FillGapData = params.exercise;

        if (!exercise.answers)
            return;

        this.shuffledAnswers = [];
        this.originalAnswers = [];
        this.selectedIndexList = [];
        this.multipleList = exercise.multiple_lists;
        
        this.originalAnswers = exercise.answers.slice();
        
        this.shuffledAnswers = [];
        if (!this.multipleList) {
            // shufle the one and only list
            if (exercise.ordered_answers) {
                this.shuffledAnswers = this.originalAnswers;
                for (let i = 0; i < this.originalAnswers.length; i++) this.shuffledIndexes.push(i);
            }
            else {
                let shuffleResult: ShuffleResult = AExerciseEngine.shuffleWithIndexes(this.originalAnswers, null);
                this.shuffledAnswers = shuffleResult.answers;
                this.shuffledIndexes = shuffleResult.indexes;
            }
        } else {
            //  shuffle the lists separately
            if (exercise.ordered_answers) {
                for (let index = 0; index < this.originalAnswers.length; index++) {
                    let currArray = this.originalAnswers[index].split(";");
                    this.shuffledAnswers.push(currArray);
                    let indexArray = [];
                    for (let i = 0; i < currArray.length; i++) indexArray.push(i);
                    this.shuffledIndexes.push(indexArray);
                }
            }
            else {
                for (let index = 0; index < this.originalAnswers.length; index++) {
                    let currArray = this.originalAnswers[index].split(";");
                    let shuffleResult: ShuffleResult = AExerciseEngine.shuffleWithIndexes(currArray, null);
                    this.shuffledAnswers.push(shuffleResult.answers);
                    this.shuffledIndexes.push(shuffleResult.indexes);
                }AExerciseEngine.shuffleWithIndexes
            }
        }

        this.selectorsList = [];
        this.selectedIndexList = [];

        
        this.root.classList.add("eke-fillgap-drop");
        this.root.classList.add("eke-fillgap-drop-bigp");
        //var random = Math.floor(Math.random() * 6) + 1;
        //this.root.style.backgroundImage = 'url("img/generic_bg/bg0' + random + '.jpg")';

        this.setIllustration(exercise,this.root); 
       
        if (!exercise.gap_text)
            return;

        var pattern = /#\d+#/g;
        var text = exercise.gap_text.replace(/\r/g, "");
        text = text.replace(/\n/g, "<br>");
        var modelIndex: number = 0;
        var result = null;

        while ((result = pattern.exec(text))) {
            var curr_selector;

            if (!this.multipleList)
                curr_selector = this.getDropdownlist(this.shuffledAnswers, modelIndex);
            else {
                curr_selector = this.getDropdownlist((this.shuffledAnswers[modelIndex]), modelIndex);
            }
            curr_selector.classList.add("eke-fillgap-selector");
            this.selectedIndexList.push(-1);
            text = this.replaceBetween(result.index, result.index + result[0].length, curr_selector, text);
            modelIndex += 1;
        }

        // append the text to a div eke-fillgap-drop-p
        var gap_text_container = document.createElement("nav");
        gap_text_container.classList.add("eke-fillgap-drop-p");
        gap_text_container.id = "eke-fillgap-drop-p";
        this.root.appendChild(gap_text_container).innerHTML = text;

        var cols = Array.from(this.root.querySelectorAll('#eke-fillgap-drop-p .eke-fillgap-selector'));
        cols.forEach((s: any) => this.selectorsList.push(s as HTMLElement));
        cols.forEach((s: any) => s.addEventListener('change', this.selectionChanged.bind(this), false));
        cols.forEach((s: any) => s.addEventListener('click', this.onClicked.bind(this), false));
    }

    getUserSolution() {
        let solution = { answer: this.selectedIndexList, fullmatch: true };
        return solution;
    }

   

    receiveEvaluation(evaluated: Evaluated): void {
        for (let index = 0; index < this.selectorsList.length; index++) {
            let currSelector = this.selectorsList[index] as HTMLSelectElement;
            AExerciseEngine.removeEvalStyle(currSelector);
            let currSelectorText = currSelector.options[currSelector.selectedIndex].text;
            let currOriginalAns;

            if (!this.multipleList) {
                currOriginalAns = this.originalAnswers[evaluated.solution[index]].replace(/ /g, '\u00a0');;
            }
            else {
                let tempArrayOfAnsw = this.originalAnswers[index].split(';');
                currOriginalAns = tempArrayOfAnsw[evaluated.solution[index]];
            }

            if (evaluated.success) {
                currSelector.classList.add("exe-engine-check-correct");
            } else {
                if (evaluated.solution == null && !this.isSNIexc) {
                    currSelector.classList.add("exe-engine-check-wrong");
                } else {
                    if (currOriginalAns.replace(/\s/g,'') == currSelectorText.replace(/\s/g,'')) {
                        currSelector.classList.add("exe-engine-check-correct");
                        this.accessibilityHelper(currSelector, true);
                    } else {
                        if (!this.isSNIexc) {
                            currSelector.classList.add("exe-engine-check-wrong");
                            this.accessibilityHelper(currSelector, false);
                        }
                    }
                }
            }
        }
    }

    accessibilityHelper(element: HTMLElement, correct: boolean) {
        let span = document.createElement("span");
        span.classList.add("show-for-sr");
        if (correct) {
            span.innerText = __("Helyes válasz");
        } else {
            span.innerText = __("Helytelen válasz");
        }
        let prevsib = element.previousSibling! as any;
        if (prevsib!=null &&  prevsib.classList) {
            if (!prevsib.classList.contains("show-for-sr"))
                (element.parentNode as any).insertBefore(span, element);
        } else
            (element.parentNode as any).insertBefore(span, element);
    }


    showCorrectSolution(solution: any): void {
        /*var shuffledSolution = [];

        for (let i = 0; i < solution.length; i++) {                 //get the proper indexes of answers, because of the shuffling in the INIT part
            shuffledSolution.push(this.shuffledAnswers.indexOf(this.originalAnswers[solution[i]]));
        }*/

        for (var index = 0; index < this.selectorsList.length; index++) {
            var curr_selector = this.selectorsList[index] as HTMLSelectElement;
            // removing the previously set styles (if any)
            AExerciseEngine.removeEvalStyle(curr_selector);
            if (!this.multipleList) {
                curr_selector.selectedIndex = this.shuffledIndexes[solution[index]] + 1;
            } else {
                curr_selector.selectedIndex = this.shuffledIndexes[index][solution[index]] + 1;
            }
            curr_selector.style.width = this.calcSelectedWidth(curr_selector) + 'px';
            // curr_selector.selectedIndex = shuffledSolution[index] + 1;
            if (!this.isReplay)
                curr_selector.classList.add("eke-engine-show-correct-bg");
        }
    }
    isUserReady(): boolean {
        return this.selectedIndexList.some(x => x > -1);
    }
    showHelp(solution: any): void {
        this.showCorrectSolution(solution);
    }

    getDropdownlist(values: Array<string>, indx: number): HTMLElement {
        // var unique = values.filter(function(item, i, ar){ return ar.indexOf(item) === i; });        
        var curr_select = document.createElement("select");
        curr_select.setAttribute("select-indx", String(indx));
        var default_op = document.createElement("option");
        default_op.value = "";
        default_op.selected = true;

        if (this.isReplay) {
            curr_select.setAttribute('disabled','disabled');
        }

        curr_select.appendChild(default_op);

        //var l = '<select><option value="" disabled selected="selected"> </option>';
        var alreadyInList = [];

        for (var i = 0; i < values.length; i++) {
            if (alreadyInList.indexOf(values[i]) != -1) continue;
            var curr_op = document.createElement("option");
            //curr_op.value= values[i].replace(/"/g, "&quot;");
            curr_op.value = "answ_" + i;
            curr_op.innerText = values[i].replace(/ /g, '\u00a0');
            curr_select.appendChild(curr_op);

            // l += '<option value="'+v+'" data-index="'+indx+'">'+values[i]+'</option>';
            alreadyInList.push(values[i]);
        }

        return curr_select;
    }

    replaceBetween(start: number, end: number, what: HTMLElement, str: string): string {
        return str.substring(0, start) + what.outerHTML + str.substring(end);
    }

    selectionChanged(ev: any) {
        var curr_selector = ev.target as HTMLSelectElement;
        var elidx = curr_selector.getAttribute("select-indx");
        if (elidx != null) {
            var elementIdx: number = +elidx;
            if (!this.multipleList) {
                this.selectedIndexList[elementIdx] = this.shuffledIndexes.indexOf(curr_selector.selectedIndex - 1);
            } else {
                this.selectedIndexList[elementIdx] = this.shuffledIndexes[elementIdx].indexOf(curr_selector.selectedIndex - 1);
            }
        }

        curr_selector.style.width = this.calcSelectedWidth(curr_selector) + 'px';
        if (this.isSNIexc) this.SNIEvaluation( EKEFillGapDropDownServer);
    }

    onClicked(ev: any) {
        var curr_selector = ev.target as HTMLSelectElement;
        AExerciseEngine.removeEvalStyle(curr_selector);
    }

    calcSelectedWidth(curr_e: HTMLSelectElement) {
        let dummy_text = curr_e.options[curr_e.selectedIndex].text;
        let dummy_span = document.createElement("span");
        dummy_span.classList.add('dummy');
        dummy_span.innerText = dummy_text;
        this.root.appendChild(dummy_span);
        let dummy_width = dummy_span.offsetWidth + 40;
        dummy_span.remove();
        return dummy_width;
    }
}