
import * as React from 'react';
import { SurveyInputType, Question } from './SurveyConstants';

/**
 * ===========================
 * DO NOT TRANSLATE THIS FILE!
 * ===========================
 */

export const NewQuestionsForTeachers: Question[] = [
    {
        id: 1,
        name: <>Neme?</>,
        type: SurveyInputType.RADIO,

        answers: [
            {
                id: 1,
                name: "Nő",
            },
            {
                id: 2,
                name: "Férfi",
            }
        ],
    },
    {
        id: 2,
        name: <>Kora? (év)</>,
        type: SurveyInputType.RADIO,

        answers: [
            {
                id: 1,
                name: "25 év alatt",
            },
            {
                id: 2,
                name: "26-35",
            },
            {
                id: 3,
                name: "36-45",
            },
            {
                id: 4,
                name: "46-55",
            },
            {
                id: 5,
                name: "56-65",
            },
            {
                id: 6,
                name: "65 év felett",
            }
        ],
    },
    {
        id: 3,
        name: <>Mely rendszereket használja az online oktatáshoz? (több is megjelölhető)</>,
        type: SurveyInputType.CHECKBOX,

        answers: [
            {
                id: 1,
                name: "Google Classroom",
            },
            {
                id: 2,
                name: "Learning Apps",
            },
            {
                id: 3,
                name: "Wordwall",
            },
            {
                id: 4,
                name: "Okosdoboz",
            },
            {
                id: 5,
                name: "Google űrlap",
            },
            {
                id: 6,
                name: "mozaWeb",
            },
            {
                id: 7,
                name: "BOOKR Suli",
            },
            {
                id: 8,
                name: "Redmenta",
            },
            {
                id: 9,
                name: "Facebook",
            }
        ],
    },
    {
        id: 4,
        name: <>Van olyan rendszer, ami nem volt felsorolva, de használja? Melyek ezek?</>,
        type: SurveyInputType.TEXT,
        optional: true,

        answers: [
            {
                id: 1,
                name: "Válasz",
            }
        ],
    },
    {
        id: 5,
        name: <>Mely modulokat használja leggyakrabban az NKP 2.0 portálon? (több is megjelölhető)</>,
        type: SurveyInputType.CHECKBOX,

        answers: [
            {
                id: 1,
                name: "Okostankönyv",
            },
            {
                id: 2,
                name: "Tartalomtár",
            },
            {
                id: 3,
                name: "Médiatár",
            },
            {
                id: 4,
                name: "Feladattár",
            },
            {
                id: 5,
                name: "Kereső",
            },
            {
                id: 6,
                name: "Tanulócsoport",
            }
        ],
    },
    {
        id: 6,
        name: <>Van olyan modul, ami nem volt felsorolva, de használja? Melyek ezek?</>,
        type: SurveyInputType.TEXT,
        optional: true,

        answers: [
            {
                id: 1,
                name: "Válasz",
            }
        ],
    },
    {
        id: 7,
        name: <>Milyen gyakran használt digitális oktatási eszközöket a COVID 19 vírushelyzet előtt?</>,
        type: SurveyInputType.RADIO,

        answers: [
            {
                id: 1,
                name: "Egyáltalán nem használtunk",
            },
            {
                id: 2,
                name: "Nagyon ritkán",
            },
            {
                id: 3,
                name: "Havonta egyszer-kétszer",
            },
            {
                id: 4,
                name: "Hetente többször",
            },
            {
                id: 5,
                name: "Szinte minden órán",
            }
        ],
    },
    {
        id: 8,
        name: <>Mit gondol, a vírushelyzet után gyakrabban fog digitális kiegészítőket használni, mint előtte?</>,
        type: SurveyInputType.RADIO,

        answers: [
            {
                id: 1,
                name: "Kevesebbet",
            },
            {
                id: 2,
                name: "Ugyan annyit",
            },
            {
                id: 3,
                name: "Többet",
            }
        ],
    },
    {
        id: 9,
        name: <>Jobbnak találta ezt a portált, mint az Ön által eddig ismert többi digitális eszközt?</>,
        type: SurveyInputType.RADIO,

        answers: [
            {
                id: 1,
                name: "Igen",
            },
            {
                id: 2,
                name: "Ugyanolyan",
            },
            {
                id: 3,
                name: "Nem",
            }
        ],
    },
    {
        id: 10,
        name: <>Az előző kérdésre adott választ indokolhatja!</>,
        type: SurveyInputType.TEXT,
        optional: true,

        answers: [
            {
                id: 1,
                name: "Válasz",
            }
        ],
    },
    {
        id: 11,
        name: <>Kérjük jelölje be, hogy az alábbi szópárok esetén melyik kifejezés a jellemzőbb és milyen mértékben az NKP portálra!</>,
        type: SurveyInputType.RANGE,
        rangeSize: 7,
        answers: [
            {
                id: 1,
                name: "akadályozó",
            },
            {
                id: 7,
                name: "támogató",
            }
        ],
    },
    {
        id: 12,
        type: SurveyInputType.RANGE,
        rangeSize: 7,
        answers: [
            {
                id: 1,
                name: "bonyolult",
            },
            {
                id: 7,
                name: "könnyű",
            }
        ],
    },
    {
        id: 13,
        type: SurveyInputType.RANGE,
        rangeSize: 7,
        answers: [
            {
                id: 1,
                name: "nem hatékony",
            },
            {
                id: 7,
                name: "hatékony",
            }
        ],
    },
    {
        id: 14,
        type: SurveyInputType.RANGE,
        rangeSize: 7,
        answers: [
            {
                id: 1,
                name: "átlátható",
            },
            {
                id: 7,
                name: "összezavaró",
            }
        ],
    },
    {
        id: 15,
        type: SurveyInputType.RANGE,
        rangeSize: 7,
        answers: [
            {
                id: 1,
                name: "unalmas",
            },
            {
                id: 7,
                name: "izgalmas",
            }
        ],
    },
    {
        id: 16,
        type: SurveyInputType.RANGE,
        rangeSize: 7,
        answers: [
            {
                id: 1,
                name: "nem érdekes",
            },
            {
                id: 7,
                name: "érdekes",
            }
        ],
    },
    {
        id: 17,
        type: SurveyInputType.RANGE,
        rangeSize: 7,
        answers: [
            {
                id: 1,
                name: "szokványos",
            },
            {
                id: 7,
                name: "formabontó",
            }
        ],
    },
    {
        id: 18,
        type: SurveyInputType.RANGE,
        rangeSize: 7,
        answers: [
            {
                id: 1,
                name: "újító",
            },
            {
                id: 7,
                name: "átlagos",
            }
        ],
    },
    {
        id: 19,
        type: SurveyInputType.RANGE,
        rangeSize: 7,
        answers: [
            {
                id: 1,
                name: "könnyen tanulható",
            },
            {
                id: 7,
                name: "nehezen tanulható",
            }
        ],
    },
    {
        id: 20,
        type: SurveyInputType.RANGE,
        rangeSize: 7,
        answers: [
            {
                id: 1,
                name: "motiváló",
            },
            {
                id: 7,
                name: "elkedvetlenítő",
            }
        ],
    },
    {
        id: 21,
        type: SurveyInputType.RANGE,
        rangeSize: 7,
        answers: [
            {
                id: 1,
                name: "barátságos",
            },
            {
                id: 7,
                name: "barátságtalan",
            }
        ],
    },
    {
        id: 22,
        type: SurveyInputType.RANGE,
        rangeSize: 7,
        answers: [
            {
                id: 1,
                name: "nem érthető",
            },
            {
                id: 7,
                name: "érthető",
            }
        ],
    },
]
