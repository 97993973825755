import * as React from 'react';
import UsrLikePlugin from './usr_like_plugin';
import BookCrud, { IBookRecord } from '@src/framework/crud/doc/BookCrud';
import { getTableInfoByName, ITableInfo } from '@src/framework/crud/Meta';
import UserViewHistoryAPI, { IUserViewEvent }  from '@src/component/usr/usr_history_api';
import BookCrudPub from '@src/framework/crud/doc_pub/BookCrud';
import { __ } from '@src/translation';

interface IWfTesztState {
    book ?: IBookRecord;
    tableInfo ?: ITableInfo;
    viewEvents ?: IUserViewEvent[];
}

export default class UsrLikeTeszt extends React.Component<any,any> {
    constructor(props: any) {
        super(props);
        this.state = {}
        getTableInfoByName("doc","book").then((tableInfo) => this.setState({tableInfo}));
        BookCrud.list({filter:{is_active: true}}).then(
            (books) => {this.setState({book: books[0]})}
        )
        UserViewHistoryAPI.getViewEventLog().then((viewEvents) => this.setState({viewEvents}));
    }

    render() {
        const book = this.state.book;
        const tableInfo = this.state.tableInfo;
        if (book && tableInfo) {
            return <div>
            <h3>{book.name} ,id={book.id}</h3>
            <UsrLikePlugin 
                tableInfoId={BookCrudPub.TABLE_INFO_ID}
                recId={book.id||null}
            />
        </div>
        } else {
            return <span>{__("kérem várjon...")}</span>;
        }
    }
}