import { NotifierConnection } from "./NotifierConnection";
import { NotifierTokenProvider, INotifierJWT, parseJwt, parseChannel, INotifierChannel } from "./NotifierJWT";
import { NotifierMessageHandler, NotifierEventHandler } from "./NotifierMessage";

interface ISubscriptionItem {
    channel: INotifierChannel;
    onMessage : NotifierMessageHandler;
    onSubscribed ?: NotifierEventHandler;
    onUnsubscribed ?: NotifierEventHandler;
    connection: NotifierConnection | null;
    subscribed : boolean;
}

/**
 * Notifier cluster
 */
export class NotifierCluster {
    private RECONNECT_INTERVAL : number = 1000;


    private initialUrls: string[]; // Ez csak arra kell, hogy a legelső kapcsolatot ki tudjuk építeni.    
    private providers: Map<string, NotifierTokenProvider>; // channel -> token callback-ek
    private rawTokens: Map<string, string>; // channel -> rawToken
    private parsedTokens: Map<string, INotifierJWT>; // channel -> parsedToken
    private connections: Map<string, NotifierConnection>; // url -> connection map
    /**
     * 
     * Ez mondja meg azt, hogy mire szeretnénk feliratkozva lenni.
     * 
     * A NotifierCluster magától csatlakozik a megfelelő notifier példányokhoz,
     * és feliratozik ezekre a csatornára. Ha megszakad a kapcsolat, akkor újra
     * próbálkozik. Ha van aktív kapcsolat és feliratkozás, akkor a map-ben
     * benne van, hogy melyik kapcsolat az. Ha nincs, akkor null.
     * 
     */
    private subscriptions: Map<string, ISubscriptionItem>;

    /**
     * 
     * @param initialUrls Néhány (legalább egy) URL amin keresztül a clusterhez kapcsolódni lehet.
     */
    constructor(initialUrls: string[]) {
        this.initialUrls = initialUrls;
        this.connections = new Map<string, NotifierConnection>();
        this.providers = new Map<string, NotifierTokenProvider>();
        this.rawTokens = new Map<string, string>();
        this.parsedTokens = new Map<string, INotifierJWT>();
        this.subscriptions = new Map<string, ISubscriptionItem>();
        setInterval(this._resubscribe, this.RECONNECT_INTERVAL);
    }

    /** Get random item from a non-empty array. */
    private getRandomItem<T>(items: T[]): T {
        return items[Math.floor(Math.random() * items.length)];
    }

    private getRandomInitialUrl(): string {
        return this.getRandomItem(this.initialUrls);
    }

    /** Obtain a token for a given channel. */
    public obtainToken = async (channel: string): Promise<string> => {
        try {
            // expired?
            if (this.parsedTokens.has(channel)) {
                const parsedToken = this.parsedTokens.get(channel)!;
                const now = Date.now() / 1000.0;
                if (parsedToken.exp > now) {
                    // not expired -> return
                    return this.rawTokens[channel]!;
                }
            }
            // expired or non-existent token.
            if (this.providers.has(channel)) {
                const provider = this.providers.get(channel)!;
                const rawToken = await provider(channel);
                const parsedToken = parseJwt(rawToken);
                if (!parsedToken) {
                    return Promise.reject("Invalid token received from provider: " + rawToken);
                }
                const now = Date.now();
                if (parsedToken.exp > now) {
                    return Promise.reject("Expired token received from provider: " + rawToken);
                }
                this.rawTokens.set(channel, rawToken);
                this.parsedTokens.set(channel, parsedToken);
                return Promise.resolve(rawToken);
            }
            return Promise.reject("No provider for channel " + channel);
        } catch (e) {
            return Promise.reject(e);
        }
    }

    /** Make sure that we have a connection for the given url. */
    private _ensureConnection = (url: string): NotifierConnection => {
        if (this.connections.has(url)) {
            return this.connections.get(url)!;
        } else {
            const connection = new NotifierConnection(this, url);
            this.connections.set(url, connection);
            return connection;
        }
    }

    /**
     * Bejövő üzenet törzs továbbítása a kijelölt kezelőnek.
     * 
     * Ezt meg lehet hívni frontend kódon belül is (bejövő üzenet szimulálása).
     * 
     */
    public _dispatchIncomingChannelMessage = (channel: string, messageBody: Object): boolean => {
        if (this.subscriptions.has(channel)) {
            this.subscriptions.get(channel)!.onMessage(messageBody);
            return true;
        } else {
            return false;
        }
    }

    /**
     * Kapcsolat inicializálás után hívódik meg.
     * 
     * Nem csak akkor amikor először kapcsolódunk egy URL-hez, hanem a
     * kapcsolat megszakadás hatására történt újrakapcsolódás után is.
     * 
     */
    public onConnectionInitialized = (connection: NotifierConnection) => {
        const url = connection.url;
        // Itt föl kellene iratkozni az összes csatornára az adott url -hez.
        // De ezt nem itt csináljuk (esemény alapon) hanem a _resubscribe -ból.
    }

    /**
     * 
     * Kapcsolat bezáródás után hívódik meg.
     * 
     * Ha például hálózati hiba miatt bezáródik a kapcsolat.
     * 
     */
    public onConnectionClosed = (connection: NotifierConnection) => {
        this.connections.delete(connection.url);
        // Megszűntek a feliratkozások is, az újrairatkozást a _resubscribe végzi.
        for (let channel in this.subscriptions.keys()) {
            let subscription : ISubscriptionItem = this.subscriptions.get(channel)!;
            if (subscription.connection === connection) {
                subscription.connection = null;
                subscription.subscribed = false;
            }
        }
    }

    /**
     * 
     * URL keresése egy megadott főcsatornához.
     * 
     * Egy véletlenszerű URL-t ad egy főcsatornához.
     * 
     */
    private async locate(channel: string): Promise<string> {
        // First, we find a usable connection.
        let connection: NotifierConnection | null = null;
        for (let entry of Array.from(this.connections.entries())) {
            if (entry[1]) {
                connection = entry[1];
            }
        }
        if (connection===null) {
            connection = this._ensureConnection(this.getRandomInitialUrl());
        }
        return this.getRandomItem(await connection.locate(channel));
    }

    private _resubscribe = async () => {
        let wasError = false;
        // Végigmegyünk azokon a csatornákon, amikre szeretnénk feliratkozva lenni.        
        for (let entry of Array.from(this.subscriptions.entries())) {
            const channel = entry[0];
            const subscription : ISubscriptionItem = entry[1];
            // Van-e hozzá kapcsolat?
            if (!subscription.connection) {
                // Nincs, akkor föl kell iratkozni.
                try {
                    // Először keresünk egy url-t ehhez a főcsatornához.
                    const url = await this.locate(subscription.channel.main+"//");
                    console.log("Located url="+url+" for channel="+subscription.channel.main+"//")
                    // Előkeressük VAGY létrehozzuk hozzá a kapcsolatot.
                    const connection = this._ensureConnection(url);
                    // Hozzárendeljük a kapcsolatot ehhez a csatornához.
                    subscription.connection = connection;                    
                } catch (e) {
                    wasError = true;
                    console.log(e);
                }
                        
            } else if (!subscription.connection.initialized) {
                console.log("Connection not initialized yet.");
            } else if (!subscription.subscribed) {
                try {
                    // Már van kapcsolat, de még nem vagyunk rá feliratkozva.
                    const rawToken = await this.obtainToken(channel);                
                    subscription.connection._subscribe(channel, rawToken);
                } catch (e) {
                    wasError = true;
                    console.log(e);
                }
            }            
        }
        if (wasError) {
            return Promise.reject("Nem sikerült minden csatornára feliratkozni.");
        } else {
            return Promise.resolve();
        }
    }

    /**
     * Feliratkozás csatornára. 
     * 
     * @param channel: A csatorna teljes neve (alcsatornával együtt, ha van)
     * @param provider: Ezt hívja vissza, amikor egy új, friss token-re van szüksége a feliratkozás fenntartásához.
     * @param onMessage: Ezt hívja vissza, amikor üzenet érkezik a csatornára.
     * @param onSubscribed: Akkor hívódik meg, amikor a feliratkozás sikerült a csatornára.
     *      Ha a kapcsolat megszakad és újracsatlakozik, akkor többször is meghívódhat.
     * @param onUnsubscribed: Akkor hívódik meg, amikor leiratkozás történt a csatornától.
     *      Pl. ha a kapcsolat megszakad.
     * 
     */
    public subscribe = (
            channel: string,
            provider: NotifierTokenProvider, 
            onMessage: NotifierMessageHandler, 
            onSubscribed ?: NotifierEventHandler,
            onUnsubscribed ?: NotifierEventHandler
        ) => {
        const parsedChannel = parseChannel(channel);
        if (!parsedChannel) {
            throw new Error("Érvénytelen csatorna név: " + channel);
        }
        if (this.subscriptions.has(channel)) {
            throw new Error("Már föl van iratkozva erre:" + channel)
        }
        this.providers.set(channel, provider);
        this.subscriptions.set(channel, {
            channel: parsedChannel, 
            onMessage, onSubscribed, onUnsubscribed,
            connection: null, 
            subscribed: false 
        });
    }

    public isSubscribed = (channel: string) : boolean => {
        return this.subscriptions.has(channel);
    }
}