import { me, GROUPS_KAP_WORKERS, GROUPS_KAP_EXTERNAL_UNIVERSITY_TEACHERS, Groups, hasGroup, hasAnyGroup } from "@src/framework/server/Auth";

export function isKSZREnabled() {
    return me && (
        hasAnyGroup(me, GROUPS_KAP_WORKERS) || 
        hasAnyGroup(me, GROUPS_KAP_EXTERNAL_UNIVERSITY_TEACHERS) || 
        hasAnyGroup(me, [Groups.Admin, Groups.Developer, 
            Groups.KapKszrCertifiedTeacher,
            Groups.KapKszrParticipantTeacher,
            Groups.KapKszrOhInspector,
            Groups.KapKszrCourseCoordinator,
            Groups.KapKszrCourseTeacher,
            Groups.KapKszrReporter,
        ])
    );
}

export function isElearningEnabled() {
    return true;
}

export function isESZTREnabled() {
    return me && (
        hasAnyGroup(me, GROUPS_KAP_WORKERS) || 
        hasAnyGroup(me, GROUPS_KAP_EXTERNAL_UNIVERSITY_TEACHERS) || 
        
        (hasGroup(me, Groups.KapKszrParticipantTeacher)) ||
        hasGroup(me, Groups.KapKszrCertifiedTeacher) ||

        hasAnyGroup(me, [Groups.Admin, Groups.Developer])
    );
}

export function isTudastarEnabled() {
    return me && (
        hasAnyGroup(me, [
            Groups.KapDraftReferens,
            Groups.KapDraftLanguageLector,
            Groups.KapEsztrAdmin,
            Groups.KapContentStoreKiadvany,
            Groups.KapContentStoreVideo,
            Groups.KapJtrCoordinator,
            Groups.KapJtrSupport,
            Groups.KapArticleEditor,
            Groups.KapNyiregyhaziAdmin,
            Groups.KapSzegediAdmin,
            Groups.KapResponsibleAssigner,
            Groups.KapKszrCourseCoordinator,
            Groups.KapKszrCourseTeacher,
            Groups.KapKszrOhInspector
        ]) || 
        
        (hasGroup(me, Groups.KapKszrCerttifiedKAK) && hasGroup(me, Groups.KapKszrCerttifiedDFHT)) ||
        hasGroup(me, Groups.KapKszrCertifiedTeacher) ||

        hasAnyGroup(me, [Groups.Admin, Groups.Developer])
    );
}

export function isJTREnabled() {
   
    return me && (
        (hasAnyGroup(me, [Groups.KapJtrCoordinator, Groups.KapJtrInstituteContact, Groups.KapJtrSupport])) ||
        (hasAnyGroup(me, [Groups.KapKszrCertifiedTeacher]) && me.teacher_has_kap_site) ||
        hasAnyGroup(me, [Groups.Admin, Groups.Developer])
    );
}

export function isKAPOCSEnabled() {
    // Jobbágy Zsuzsa: Azt szeretnénk még kérni, hogy a KAPU felületén egyelőre ne legyen elérhető a JTR és KAPOCS csempe. Mi csak az intézményvezetőknek küldjük ki a linket, ahol be tudnak regisztrálni, de ha a pedagógusok is hozzáférnek, akkor szabadon be tudják adni a regisztrációt és most van is egy intézmény ahol az igazgató nem tudja ki regisztrálta be az ő intézményéből, mert az OM azonosítónál nem az intézmény azonosítóját adta meg, hanem valószínűleg saját OM azonosítóját adta meg.
    return false;
}
