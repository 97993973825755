import * as React from 'react';
import { IViewInfo, IViewColumnInfo } from '@src/framework/view/Meta';
import LookupEdit from '@framework/forms/lookupedit';
import SecUserSelector from '@framework/forms/SecUserSelector';
import { StringSearchKind, IStringSearch, TFilterDict } from '@src/framework/crud/Crud';
import ThreeStateRadioGroup from './three_state_radio_group';
import './forms.css';
import SecUserCrud from '../crud/sys/SecUserCrud';
import { type } from 'os';

const LANG_ID_HU = 3432150755;

export type FilterByColumnChangeEvent = (columnInfo: IViewColumnInfo, newValue: any) => void;

export interface ViewFilterProps {
    viewInfo: IViewInfo;
    showInactiveByDefault?: boolean;

    filterByColumn: TFilterDict;
    onFilterByColumnChange?: FilterByColumnChangeEvent;
    onMinFilterByColumnChange?: FilterByColumnChangeEvent;
    onMaxFilterByColumnChange?: FilterByColumnChangeEvent;
    lang_id?: number;

    onShowColumn?: (column: IViewColumnInfo) => boolean;
}

export interface ViewFilterState {

}

/**
 * Ez egy olyan komponens, ami egy View lekérdezéshez megadható szűrési értékeket képes
 * vizuálisan beállítani.
 * 
 */
export default class ViewFilter extends React.Component<ViewFilterProps, ViewFilterState> {
    protected columnInfoByName: Map<String, IViewColumnInfo>;


    constructor(props: ViewFilterProps) {
        super(props);
        this.state = {};
        this.columnInfoByName = new Map<String, IViewColumnInfo>();
        let columns = props.viewInfo.columns;
        for (let i = 0; i < columns.length; i++) {
            let col = columns[i];
            this.columnInfoByName.set(col.column_name, col);
        }
    }


    public static constructBinOpValueFilter(binOp: string, viewInfo: IViewInfo, filterByColumn?: TFilterDict): TFilterDict {
        if (!filterByColumn) {
            return {};
        }

        const result: TFilterDict = {};
        let columns = viewInfo.columns;
        let hasCond: boolean = false;
        for (let i = 0; i < columns.length; i++) {
            let col = columns[i];
            let value = filterByColumn[col.column_name];
            if (value !== undefined && value !== null) {
                result[col.column_name] = value;
                hasCond = true;
            }
        }
        if (hasCond) {
            return { [binOp]: result };
        } else {
            return {};
        }

    }

    public static constructMinValueFilter(viewInfo: IViewInfo, filterByColumn?: TFilterDict): TFilterDict {
        return this.constructBinOpValueFilter(">=", viewInfo, filterByColumn);
    }

    public static constructMaxValueFilter(viewInfo: IViewInfo, filterByColumn?: TFilterDict): TFilterDict {
        return this.constructBinOpValueFilter("<=", viewInfo, filterByColumn);
    }

    public static constructNotNullValueFilter(viewInfo: IViewInfo, columNames: string[]): TFilterDict {
        if (columNames.length) {
            let result: TFilterDict = {};
            columNames.forEach(columName => {
                result[columName] = null;
            })
            return { "$not": result };
        } else {
            return {};
        }
    }

    public static constructNullValueFilter(viewInfo: IViewInfo, columNames: string[]): TFilterDict {
        if (columNames.length) {
            let result: TFilterDict = {};
            columNames.forEach(columName => {
                result[columName] = null;
            })
            return result;
        } else {
            return {};
        }
    }

    public static constructFilter = (viewInfo: IViewInfo, filterByColumn: TFilterDict, ...otherFiltersToMerge: TFilterDict[]): TFilterDict => {
        let result: TFilterDict = {};
        let columns = viewInfo.columns;
        for (let i = 0; i < columns.length; i++) {
            let col = columns[i];
            let value = filterByColumn[col.column_name];
            if (value !== undefined && value != null) {
                if (col.fk_table_info_id) {
                    result[col.column_name] = value;
                } else if (col.orig_type == 'text') {
                    if (value != null && value.toString().trim()) {
                        result[col.column_name] = {
                            kind: StringSearchKind.WordSearch,
                            case_sensitive: false,
                            expr: value.toString()
                        };
                    }
                } else if (col.orig_type == 'int8') {
                    result[col.column_name] = value;
                } else if (col.orig_type == 'bool') {
                    result[col.column_name] = value;
                }
            }
        }
        // TODO - ez nem igazi merge, felülírhatja a többit! Rendes merge-t kellene írni!
        otherFiltersToMerge.forEach(otherFilter => {
            result = { ...result, ...otherFilter };
        });
        return result;
    }

    protected getLangId(): number {
        return this.props.lang_id || LANG_ID_HU;
    }

    protected hasIsActive = () => {
        return this.columnInfoByName.has("is_active");
    }

    protected showInactive = (): boolean | undefined => {
        return this.props.filterByColumn["is_active"] as boolean | undefined;
    }

    private nextFlag = (flag: boolean | undefined): boolean | undefined => {
        if (flag === undefined) {
            return true;
        } else if (flag === true) {
            return false;
        } else {
            return undefined;
        }
    }

    private flagTitle = (flag: boolean | undefined): string => {
        if (flag === undefined) {
            return 'Mindent';
        } else if (flag === true) {
            return "Aktívakat";
        } else {
            return "Törölteket";
        }
    }

    private flagColorClass = (flag: boolean | undefined): string => {
        if (flag === undefined) {
            return 'secondary';
        } else if (flag === true) {
            return "success";
        } else {
            return "alert";
        }
    }

    private onToggleShowInactive = () => {
        this.onFilterChange(
            this.columnInfoByName.get("is_active")!,
            this.nextFlag(this.showInactive())
        );
    }

    private onFilterChange = (columnInfo: IViewColumnInfo, newValue: any) => {
        if (this.props.onFilterByColumnChange) {
            if ((typeof newValue === 'string')) {
                if (newValue.trim() === '')
                    newValue = '';
                if (newValue === '')
                    newValue = null;
            }
            this.props.onFilterByColumnChange(columnInfo, newValue);
        }
    }

    
    protected onFilterMinValueChange = (columnInfo: IViewColumnInfo, newValue: any) => {        

        if (this.props.onMinFilterByColumnChange) {           
            this.props.onMinFilterByColumnChange(columnInfo, newValue);
        }
    }

    protected onFilterMaxValueChange = (columnInfo: IViewColumnInfo, newValue: any) => {
        if (this.props.onMaxFilterByColumnChange) {           
            this.props.onMaxFilterByColumnChange(columnInfo, newValue);
        }
    }



    render() {
        let filters: any[] = [];
        let columns = this.props.viewInfo.columns;
        for (let i = 0; i < columns.length; i++) {
            const columnInfo = columns[i];
            let canFilter =
                columnInfo.fk_table_info_id ||
                (['text', 'bool', 'date', 'int8'].includes(columnInfo.orig_type) && columnInfo.column_name != "is_active");
            if (canFilter && this.props.onShowColumn) {
                canFilter = this.props.onShowColumn(columnInfo);
            }
            if (canFilter) {
                let inputElement = <div></div>;
                let filterValue: any = this.props.filterByColumn[columnInfo.column_name];
                const minValue = filterValue as any || undefined;
                const maxValue = filterValue as any || undefined;
                let inputClassName: string = (i % 2 == 0 ? " even" : " odd");
                if (filterValue === null || filterValue === undefined) {
                    inputClassName += " forminput forminput-empty";
                } else {
                    inputClassName += " forminput forminput-valid";
                }
                if (columnInfo.fk_table_info_id) {
                    if (!filterValue)
                        filterValue = null;

                    if (columnInfo.fk_table_info_id == SecUserCrud.TABLE_INFO_ID) {
                        inputElement = <div className={inputClassName}
                            key={`filter_editor_${columnInfo.column_name}`}>
                            <SecUserSelector
                                clearable={true}
                                value={filterValue}
                                onChange={(newValue) => this.onFilterChange(columnInfo, newValue)}
                            />
                        </div>;
                    } else {
                        inputElement = <div className={inputClassName}
                            key={`filter_editor_${columnInfo.column_name}`}>
                            <LookupEdit
                                key={columnInfo.column_name + "_" + filterValue}
                                name={`filter_${columnInfo.column_name}`}
                                fk_table_info_id={columnInfo.fk_table_info_id!}
                                clearable={true}
                                value={filterValue}
                                onChange={(newValue) => this.onFilterChange(columnInfo, newValue)}
                            />
                        </div>;
                    }
                }
                else if (columnInfo.orig_type == 'text') {
                    if (!filterValue)
                        filterValue = null;
                    inputElement = <div className={inputClassName}
                        key={`filter_editor_${columnInfo.column_name}`}>
                        <input
                            type="text"
                            name={`filter_${columnInfo.column_name}`}
                            value={filterValue || ''}
                            onChange={(event) => this.onFilterChange(columnInfo, event.target.value)}
                        />
                    </div>;
                }
                else if (columnInfo.orig_type == 'int8') {
                    if (!filterValue)
                        filterValue = null;
                    inputElement = <div className={inputClassName}
                        key={`filter_editor_${columnInfo.column_name}`}>
                        <input
                            type="number"
                            name={`filter_${columnInfo.column_name}`}
                            value={filterValue || ''}
                            onChange={(event) => this.onFilterChange(columnInfo, event.target.value ? Number(event.target.value) : null)}
                        />
                    </div>;
                }
                else if (columnInfo.orig_type == 'bool') {
                    inputElement = <div className={inputClassName}
                        key={`filter_editor_${columnInfo.column_name}`}>
                        <ThreeStateRadioGroup
                            name={`filter_${columnInfo.column_name}`}
                            value={filterValue}
                            thirdvalue={undefined}
                            thirdlabel="Mindet"
                            className="forminput-three-state-checkbox"
                            onValueChange={(newValue: boolean | null) =>
                                this.onFilterChange(columnInfo, newValue)}
                        />
                    </div>;
                } else if (columnInfo.orig_type == 'date') {
                    /*trExtra = __("Min/Max");*/
                    // TODO: ezek férjenek ki egymás mellé?
                    inputElement = <div>
                        <input type="date"
                            value={minValue}
                            onChange={(e: any) => { this.onFilterMinValueChange(columnInfo, e.target.value || undefined); }}
                        />
                        <input type="date"
                            value={maxValue}
                            onChange={(e: any) => {
                                this.onFilterMaxValueChange(columnInfo, e.target.value || undefined);
                            }}
                        />
                    </div>;
                }else{
                        throw new Error("Internal error in ViewFilter");
                    }

                    filters.push(
                        <div className="column small-12 medium-6" key={`filter_label_${columnInfo.column_name}`}>
                            <label htmlFor={`filter_editor_${columnInfo.column_name}`} className="form-label" >
                                {columnInfo.translations[this.getLangId()].display_name}
                            </label>
                            {inputElement}
                        </div>
                    );

                }
            }


            if (this.hasIsActive()) {
                filters.push(
                    <div className="column small-12 medium-6 " key={`filter_label_is_active`}>
                        <label htmlFor={`filter_editor_is_active`} >
                            Mutassa a logikailag törölt tételeket is.
                    </label>
                        <button
                            key={`filter_editor_is_active`}
                            type="button"
                            className={"button " + this.flagColorClass(this.showInactive())}
                            title={this.flagTitle(this.showInactive())}
                            onClick={this.onToggleShowInactive}
                        >
                            {this.flagTitle(this.showInactive())}
                        </button>
                    </div>
                );
                /* filters.push(
                     <div className="column small-12 medium-6 large-4"
                         key={`filter_editor_is_active`}>
                         <button
                             type="button"
                             className={"button " + this.flagColorClass(this.showInactive())}
                             title={this.flagTitle(this.showInactive())}
                             onClick={this.onToggleShowInactive}
                         >
                             {this.flagTitle(this.showInactive())}
                         </button>
                     </div>
                 );*/

            }
            return <div className="row form">{filters}</div>;
        }
    }