import { Server } from '../../server/Server';
import Crud , { registerCrudClassProxy, IAsTextResult, IListParams, CrudClassProxy, ISearchTextParams, ISearchTextResult } from '../Crud';

export interface IInstituteTypeRecord {
    /**
     * Globálisan egyedi azonosító.
     * 
     * Ha új rekordot akarsz létrehozni put() metódussal, akkor hagyd üresen.
     * upsert() hívásnál figyelmen kívül van hagyva az értéke.
     *
     */
    id ?: number;
    /**
     * Név
     */
    title?: string;
    /**
     * Leírás
     */
    description?: string|null;
}

/**
 *  sys.institute_type - Intézmény típus
 */

export default class InstituteTypeCrud extends Crud<IInstituteTypeRecord> {
    public static TABLE_INFO_ID = 714383698;
    public static getSchemaNameForClass() { return 'sys'; }
    public static getTableNameForClass() { return 'institute_type'; }

    public static load: (id: number, aServer ?: Server) => Promise<InstituteTypeCrud>;
    public static asText: (id: number, includeRecord : boolean, aServer ?: Server) => Promise<IAsTextResult<IInstituteTypeRecord>>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<IInstituteTypeRecord[]>;
    public static searchText: (params: ISearchTextParams, aServer ?: Server) => Promise<ISearchTextResult<IInstituteTypeRecord>[]>;
    public static deleteById: (id: number, aServer ?: Server) => Promise<IInstituteTypeRecord>;
    public static unDeleteById: (id: number, aServer ?: Server) => Promise<IInstituteTypeRecord>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class InstituteTypeCrudClassProxy extends CrudClassProxy<IInstituteTypeRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const instituteTypeCrudClassProxy = new InstituteTypeCrudClassProxy(InstituteTypeCrud);
registerCrudClassProxy(instituteTypeCrudClassProxy);
