import { Server } from '../../server/Server';
import Crud , { registerCrudClassProxy, IAsTextResult, IListParams, CrudClassProxy, ISearchTextParams, ISearchTextResult } from '../Crud';

export interface IExerciseSeriesItemRecord {
    /**
     * Globálisan egyedi azonosító.
     * 
     * Ha új rekordot akarsz létrehozni put() metódussal, akkor hagyd üresen.
     * upsert() hívásnál figyelmen kívül van hagyva az értéke.
     *
     */
    id ?: number;
    /**
     * Sorrend
     */
    no?: number /*integer*/|null;
    /**
     * Rekord létrehozás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel létrehozásakor.
     */
    creation_time?: string /*timestamp?*/;
    /**
     * Ki hozta létre
     *
     * NOT NULL -> sys.sec_user ON DELETE noaction
     *
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozó felhasználóra hivatkozik.
     */
    creation_user_id?: number;
    /**
     * Melyik session-ből jött létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozáshoz használt munkamenetre hivatkozik.
     */
    creation_session_id?: number|null;
    /**
     * Utolsó módosítás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel módosításakor.
     */
    modification_time?: string /*timestamp?*/|null;
    /**
     * Ki módosította utoljára
     *
     * -> sys.sec_user ON DELETE noaction
     *
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosító felhasználóra hivatkozik.
     */
    modification_user_id?: number|null;
    /**
     * Melyik session-ből módosították utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosításhoz használt munkamenetre hivatkozik.
     */
    modification_session_id?: number|null;
    /**
     * Aktív
     *
     * Logikai törléshez használt mező.
     */
    is_active?: boolean;
    /**
     * Feladatlista
     *
     * NOT NULL -> exc.exercise_series ON DELETE cascade
     *
     */
    exercise_series_id?: number;
    /**
     * Feladat
     *
     * NOT NULL -> exc.exercise ON DELETE cascade
     *
     */
    exercise_id?: number;
    /**
     * Pontszám
     */
    points?: number /*integer*/;
    /**
     * Szint
     *
     * A feladatsor nehézségi foka, 1=könnyű 2=közepes 3=nehéz
     */
    level?: number /*integer*/|null;
    /**
     * Adaptív feladatsor témakör
     *
     * -> exc.exercise_series_topic ON DELETE cascade
     *
     */
    exercise_series_topic_id?: number|null;
}

/**
 *  exc.exercise_series_item - Feladatsor elem
 *
 *  Idősoros táblázat, ami az összes tétel összes korábbi verzióját eltárolja.
 */

export default class ExerciseSeriesItemCrud extends Crud<IExerciseSeriesItemRecord> {
    public static TABLE_INFO_ID = 316276652;
    public static getSchemaNameForClass() { return 'exc'; }
    public static getTableNameForClass() { return 'exercise_series_item'; }

    public static load: (id: number, aServer ?: Server) => Promise<ExerciseSeriesItemCrud>;
    public static asText: (id: number, includeRecord : boolean, aServer ?: Server) => Promise<IAsTextResult<IExerciseSeriesItemRecord>>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<IExerciseSeriesItemRecord[]>;
    public static searchText: (params: ISearchTextParams, aServer ?: Server) => Promise<ISearchTextResult<IExerciseSeriesItemRecord>[]>;
    public static deleteById: (id: number, aServer ?: Server) => Promise<IExerciseSeriesItemRecord>;
    public static unDeleteById: (id: number, aServer ?: Server) => Promise<IExerciseSeriesItemRecord>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class ExerciseSeriesItemCrudClassProxy extends CrudClassProxy<IExerciseSeriesItemRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const exerciseSeriesItemCrudClassProxy = new ExerciseSeriesItemCrudClassProxy(ExerciseSeriesItemCrud);
registerCrudClassProxy(exerciseSeriesItemCrudClassProxy);
