import obtainServer from '@framework/server/Server';
import { parse } from 'date-fns';

const URL = 'calendar';

// TODO: a moment.js tudja ezt, a date-fns nem. Mit tegyünk? Jó ez így?
export const DAY_OF_WEEK_LONG_NAMES = [
    "Vasárnap", "Hétfő", "Kedd", "Szerda", "Csütörtök", "Péntek", "Szombat"
];
export const DAY_OF_WEEK_SHORT_NAMES = [
    "Vas", "Hé", "Ke", "Sze", "Csü", "Pé", "Szo"
];
export const DAY_OF_WEEK_MINIMAL_NAMES = [
    "V", "H", "K", "Sze", "Cs", "P", "Szo"
];

// 0=Vasárnap, sajnos.
export const DAY_OF_WEEK_ORDER = [1,2,3,4,5,6,0];

export const DAY_TYPE_WORKDAY_ID = 1;
export const DAY_TYPE_NON_WORKING_DAY_ID = 2;
export const DAY_TYPE_RED_LETTER_DAY_ID = 3;

export const DAY_TYPE_NAMES = {
    [DAY_TYPE_WORKDAY_ID]: "Munkanap",
    [DAY_TYPE_NON_WORKING_DAY_ID]: "Munkaszüneti nap",
    [DAY_TYPE_RED_LETTER_DAY_ID]: "Ünnepnap"
}

export const MONTH_LONG_NAMES = [
    "Január", "Február", "Március", "Április", "Május", "Június",
    "Július", "Augusztus", "Szeptember", "Október", "November", "December"
]

export interface IDay {
    day: string; /* timestamp */
    day_id: number | null; /* id of sys.day if any */
    day_type_id: number;
    description: string | null;
    dow: number; /* day of week, starts with Sunday=0 */
}


// Helper interface to connect dates with IDay records.
export interface IDayItem {
    date: Date;
    day: IDay
}

export interface IActivitySummary {
    date: Date;
    cnt: number;
}

export type ActivitySummaryMap = { [dateStr : string] : IActivitySummary };

interface IRawActivitySummary {
    day : string;
    cnt : number;
}


export default class CalendarAPI {

    /** Napok lekérdezése */
    public static getDays = (startDate: Date, endDate: Date): Promise<IDay[]> => {
        let url = URL + "?operation=get_days"
            + "&start_date=" + encodeURIComponent(""+startDate.getTime())
            + "&end_date=" + encodeURIComponent(""+endDate.getTime())
        return obtainServer().get<IDay[]>(url);
    }

    /** Aktuális user activity lista lekérése adott időszakra. */
    public static getActivitySummary = async (startDate: Date, endDate: Date, secUserId: number): Promise<IActivitySummary[]> => {
        let url = URL + "?operation=get_activity_summary"
            + "&start_date=" + encodeURIComponent(""+startDate.getTime())
            + "&end_date=" + encodeURIComponent(""+endDate.getTime())
            + "&sec_user_id=" + secUserId
        try {
            let items : IRawActivitySummary[] = await obtainServer().get<IRawActivitySummary[]>(url);
            return items.map(
                (item : IRawActivitySummary) : IActivitySummary => {
                    return {
                        date: parse(item.day),
                        cnt: item.cnt
                    }
                }
            );
        } catch (error) {
            return Promise.reject(error);
        }
    }

}
