import { ISubsiteRecord } from "@src/framework/crud/cms/SubsiteCrud";
import * as React from "react";
import HtrTicketCategoryCrud, { IHtrTicketCategoryRecord } from "@src/framework/crud/ticket/HtrTicketCategoryCrud";
import { Link } from "react-router-dom";
import { PATH_TICKETS, PATH_TICKETING_MESSAGE } from "@src/Routes";
import { app } from '@src/index';

type TicketCategoryListProps = {
    subsite?: ISubsiteRecord,
    title?: string,
    className?: string
}
type TicketCategoryListState = {
    ticket_categories: IHtrTicketCategoryRecord[];
}

export class TicketCategoryList extends React.Component<TicketCategoryListProps, TicketCategoryListState> {

    constructor(props: any) {
        super(props);
        this.state = { ticket_categories: [] };
    }

    componentDidMount() {
        this.reloadTicketCategories();
    }

    async reloadTicketCategories() {
        try {
            const ticket_categories = await HtrTicketCategoryCrud.list(
                {
                    filter: {
                        subsite_id: (this.props.subsite ? this.props.subsite.id : undefined),
                        is_active: true,
                        selectable: true
                    }
                });
            this.setState({ ticket_categories });
        } catch (e) {
            app.showErrorFromJsonResult(e);
        }
    }

    render() {
        return <div className={"tickets " + (this.props.className ? this.props.className : "")}><h3 className="title">{this.props.title ? this.props.title : "Üzenet kategóriák"}</h3>
            {
                this.state.ticket_categories.length > 0 ? this.state.ticket_categories.map((t_cat) => {
                    return <div className="ticket-item" key={t_cat.id}><Link to={PATH_TICKETING_MESSAGE + "/" + t_cat.id}><span>{t_cat.title}</span></Link></div>
                }) : "Nincs üzenet kategória felvéve!"
            }
        </div>
    }
}