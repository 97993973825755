import { Server } from '../../server/Server';
import Crud , { registerCrudClassProxy, IAsTextResult, IListParams, CrudClassProxy, ISearchTextParams, ISearchTextResult } from '../Crud';

export interface IDcFieldTypeRecord {
    /**
     * Globálisan egyedi azonosító.
     * 
     * Ha új rekordot akarsz létrehozni put() metódussal, akkor hagyd üresen.
     * upsert() hívásnál figyelmen kívül van hagyva az értéke.
     *
     */
    id ?: number;
    /**
     * Megnevezés
     */
    title?: string;
    /**
     * Angol megnevezés
     */
    title_en?: string;
    /**
     * Meghatározás
     */
    definition?: string;
    /**
     * Magyarázat
     */
    explanation?: string;
    /**
     * Kötelezőség
     */
    requirelevel?: string;
}

/**
 *  meta.dc_field_type - Dublin Core mező típus
 *
 *  http://mek.oszk.hu/html/irattar/dc.htm
 */

export default class DcFieldTypeCrud extends Crud<IDcFieldTypeRecord> {
    public static TABLE_INFO_ID = 67456654;
    public static getSchemaNameForClass() { return 'meta'; }
    public static getTableNameForClass() { return 'dc_field_type'; }

    public static load: (id: number, aServer ?: Server) => Promise<DcFieldTypeCrud>;
    public static asText: (id: number, includeRecord : boolean, aServer ?: Server) => Promise<IAsTextResult<IDcFieldTypeRecord>>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<IDcFieldTypeRecord[]>;
    public static searchText: (params: ISearchTextParams, aServer ?: Server) => Promise<ISearchTextResult<IDcFieldTypeRecord>[]>;
    public static deleteById: (id: number, aServer ?: Server) => Promise<IDcFieldTypeRecord>;
    public static unDeleteById: (id: number, aServer ?: Server) => Promise<IDcFieldTypeRecord>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class DcFieldTypeCrudClassProxy extends CrudClassProxy<IDcFieldTypeRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const dcFieldTypeCrudClassProxy = new DcFieldTypeCrudClassProxy(DcFieldTypeCrud);
registerCrudClassProxy(dcFieldTypeCrudClassProxy);
