import * as React from 'react';

import { IRecord, CrudClassProxy, TFilterDict }  from '@framework/crud/Crud';
import { __ } from '@src/translation';

interface CrudMultiSelectProps {
    values: number[];
    onChange: (values: number[]) => void;
    displayFieldName: string;
    orderByFieldName?: string;
    crudClassProxy : CrudClassProxy<IRecord>;
    disabled ?: boolean;
    filter?: TFilterDict;
    onFilter ?: (record:any) => boolean;
    sortFunc?: (a: any, b: any) => number;
    valueField?: string;
}

interface CrudSelectState {
    items ?: IRecord[];
}

export class CrudMultiSelect  extends React.Component<CrudMultiSelectProps, CrudSelectState> { 

    constructor(props:CrudMultiSelectProps) {
        super(props);
        this.state = {};        
    }

    componentDidMount() {
        this.asnycReload();
    }

    componentDidUpdate(prevProps: CrudMultiSelectProps) {
        let needRefresh:boolean = JSON.stringify(this.props.filter) != JSON.stringify(prevProps.filter);
        if ((this.state.items === undefined || this.props.crudClassProxy.getTableInfoIdForClass() != prevProps.crudClassProxy.getTableInfoIdForClass())||needRefresh) {
            this.asnycReload();
        }
    }

    private async asnycReload() {
        let items = await this.props.crudClassProxy.list({filter: this.props.filter, order_by: this.props.orderByFieldName || this.props.displayFieldName})

        if (this.props.onFilter) items = items.filter(this.props.onFilter);
        if (this.props.sortFunc) items = items.sort(this.props.sortFunc);

        this.setState({items});
    }

    private onChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        let values = [...this.props.values];
        values.push(parseInt(event.target.value));
        this.props.onChange(values);
    }

    private onValueDeleted(index: number) {
        let values = [...this.props.values];
        values.splice(index, 1);
        this.props.onChange(values);
    }

    render() { 
        if (this.state.items===undefined) {
            return <div>{__("Kérem várjon...")}</div>;
        } else {
            const emptyTitle = __("Kérem válasszon...");

            const disabled = (this.props.disabled===undefined)?false:
                this.props.disabled?true:false;
            
            return <>
                {
                    this.props.values.map((value, index) => {
                        if (!this.state.items) return;

                        const item = this.state.items.find(items => 
                            
                                (this.props.valueField ? (items[this.props.valueField] == value) 
                                : (items.id == value))
                                
                            );
                        if (!item) return;
                    
                        return <div key={index}>
                            <span className="label">
                                {item[this.props.displayFieldName]}
                            </span> <button onClick={this.onValueDeleted.bind(this, index)} className="button tiny alert" style={{marginBottom: "2px"}}><i className="fa fa-trash"/></button>
                        </div>
                    })
                }
                
                <select value={""} onChange={this.onChange} disabled={disabled}>
                    
                    <option key="_EMPTY_" value="">{emptyTitle}</option>

                    {
                        this.state.items.map((item) => {
                            if (item.id && this.props.values.includes(item.id)) return null;

                            return <option key={"opt_"+ (item.id||0).toString() } value={this.props.valueField ? item[this.props.valueField] : item.id}>{item[this.props.displayFieldName]}</option>
                        })
                    }
                </select>
            </>
        }
    }

}