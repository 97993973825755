import { Server } from '../../server/Server';
import View , { registerViewClassProxy, ViewClassProxy } from '../View';
import { IListParams } from '@framework/crud/Crud';

export interface IViewWfWorkflowAgentRecord {
    /**
     * Azonosító
     *
     * Azonosító (pótkulcs)
     */
    id?: number;
    /**
     * Rekord létrehozás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel létrehozásakor.
     */
    creation_time?: string /*timestamp?*/;
    /**
     * Ki hozta létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozó felhasználóra hivatkozik.
     */
    creation_user_id?: number;
    /**
     * Melyik session-ből jött létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozáshoz használt munkamenetre hivatkozik.
     */
    creation_session_id?: number|null;
    /**
     * Utolsó módosítás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel módosításakor.
     */
    modification_time?: string /*timestamp?*/|null;
    /**
     * Ki módosította utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosító felhasználóra hivatkozik.
     */
    modification_user_id?: number|null;
    /**
     * Melyik session-ből módosították utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosításhoz használt munkamenetre hivatkozik.
     */
    modification_session_id?: number|null;
    /**
     * Folyamat
     */
    workflow_id?: number;
    /**
     * Engedély birtokos
     */
    sec_perm_owner_id?: number;
    /**
     * Létrehozta
     *
     * Felhasználó, aki létrehozta
     */
    creator?: string|null;
    /**
     * Típus kód
     *
     * Folyamat típus kódja
     */
    wf_type_code?: string|null;
    /**
     * Típus név
     *
     * Folyamat típus neve
     */
    wf_type_name?: string|null;
    /**
     * Állapot
     *
     * Cél állapot neve
     */
    station_name?: string|null;
    /**
     * Állapot leírás
     *
     * Cél állapot leírása
     */
    station_description?: string|null;
    /**
     * Stílus
     *
     * Cél állapot stílusa (CSS)
     */
    station_style?: any|null;
    /**
     * Felhasználó
     *
     * Felhasználó, aki jogosult látni az ilyen állapotú folyamatokat
     */
    user_id?: number|null;
    /**
     * Felhasználó
     *
     * Felhasználó, aki jogosult látni az ilyen állapotú folyamatokat
     */
    login_name?: string|null;
    /**
     * Személy
     *
     * Aki jogosult látni az ilyen állapotú folyamatokat
     */
    person_id?: number|null;
    /**
     * Teljes név
     *
     * A jogosult természetes személy neve
     */
    fullname?: string|null;
    /**
     * Csoport név
     *
     * A csoport neve
     */
    group_title?: string|null;
    /**
     * Csoport típus
     *
     * A csoport típus azonosítója
     */
    sec_group_type_id?: number|null;
    /**
     * Csoport típus
     *
     * A csoport típus neve
     */
    sec_group_type_title?: string|null;
    /**
     * A jogosult neve
     *
     * A csoport vagy felhasználó humán azonosítására szolgáló szöveg
     */
    perm_owner_label?: string|null;
}

/**
 *  wf.view_wf_workflow_agent - Folyamat felelős
 *
 *  Azt mondja meg, hogy egy adott folyamatért kik a felelősök.
 */

export default class ViewWfWorkflowAgent extends View<IViewWfWorkflowAgentRecord> {
    public static VIEW_INFO_ID = 2018051001;

    public static getSchemaNameForClass(): string { return 'wf'; }
    public static getViewNameForClass(): string { return 'view_wf_workflow_agent'; }
    public static getTableNameForClass(): string|null { return 'workflow_agent'; }

    public static load: (id: number, aServer ?: Server) => Promise<ViewWfWorkflowAgent>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<IViewWfWorkflowAgentRecord[]>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class ViewWfWorkflowAgentClassProxy extends ViewClassProxy<IViewWfWorkflowAgentRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const viewWfWorkflowAgentClassProxy = new ViewWfWorkflowAgentClassProxy(ViewWfWorkflowAgent);
registerViewClassProxy(viewWfWorkflowAgentClassProxy);

