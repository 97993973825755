import { Server } from '../../server/Server';
import Crud , { registerCrudClassProxy, IAsTextResult, IListParams, CrudClassProxy, ISearchTextParams, ISearchTextResult } from '../Crud';

export interface ISurveyAnswerRecord {
    /**
     * Globálisan egyedi azonosító.
     * 
     * Ha új rekordot akarsz létrehozni put() metódussal, akkor hagyd üresen.
     * upsert() hívásnál figyelmen kívül van hagyva az értéke.
     *
     */
    id ?: number;
    /**
     * Rekord létrehozás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel létrehozásakor.
     */
    creation_time?: string /*timestamp?*/;
    /**
     * Ki hozta létre
     *
     * NOT NULL -> sys.sec_user ON DELETE noaction
     *
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozó felhasználóra hivatkozik.
     */
    creation_user_id?: number;
    /**
     * Melyik session-ből jött létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozáshoz használt munkamenetre hivatkozik.
     */
    creation_session_id?: number|null;
    /**
     * Utolsó módosítás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel módosításakor.
     */
    modification_time?: string /*timestamp?*/|null;
    /**
     * Ki módosította utoljára
     *
     * -> sys.sec_user ON DELETE noaction
     *
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosító felhasználóra hivatkozik.
     */
    modification_user_id?: number|null;
    /**
     * Melyik session-ből módosították utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosításhoz használt munkamenetre hivatkozik.
     */
    modification_session_id?: number|null;
    /**
     * Aktív
     *
     * Logikai törléshez használt mező.
     */
    is_active?: boolean;
    /**
     * Felmérés
     *
     * NOT NULL -> usr.survey ON DELETE cascade
     *
     */
    survey_id?: number;
    /**
     * Alkalmazás szintű felhasználó
     *
     * NOT NULL -> sys.sec_user ON DELETE cascade
     *
     *
     * Aki a kedveli
     */
    sec_user_id?: number;
    /**
     * Válaszok
     */
    answers?: any|null;
    /**
     * Amire fonatkozik a válasz - Táblázat infó
     *
     * -> meta.table_info ON DELETE noaction
     *
     */
    table_info_id?: number|null;
    /**
     * Amire fonatkozik a válasz - rekord azonosító
     */
    rec_id?: number|null;
}

/**
 *  usr.survey_answer - Felmérés eredmény
 *
 *  Idősoros táblázat, ami az összes tétel összes korábbi verzióját eltárolja.
 */

export default class SurveyAnswerCrud extends Crud<ISurveyAnswerRecord> {
    public static TABLE_INFO_ID = 4208547335;
    public static getSchemaNameForClass() { return 'usr'; }
    public static getTableNameForClass() { return 'survey_answer'; }

    public static load: (id: number, aServer ?: Server) => Promise<SurveyAnswerCrud>;
    public static asText: (id: number, includeRecord : boolean, aServer ?: Server) => Promise<IAsTextResult<ISurveyAnswerRecord>>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<ISurveyAnswerRecord[]>;
    public static searchText: (params: ISearchTextParams, aServer ?: Server) => Promise<ISearchTextResult<ISurveyAnswerRecord>[]>;
    public static deleteById: (id: number, aServer ?: Server) => Promise<ISurveyAnswerRecord>;
    public static unDeleteById: (id: number, aServer ?: Server) => Promise<ISurveyAnswerRecord>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class SurveyAnswerCrudClassProxy extends CrudClassProxy<ISurveyAnswerRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const surveyAnswerCrudClassProxy = new SurveyAnswerCrudClassProxy(SurveyAnswerCrud);
registerCrudClassProxy(surveyAnswerCrudClassProxy);
