import * as React from 'react';
import { match } from 'react-router';
import { app } from '@src/index';
import { BubbleLoader } from 'react-css-loaders';
import ReactTable, { Column, TableProps, RowInfo } from 'react-table'
import 'react-table/react-table.css'
import { me, hasAnyGroup, Groups } from '@src/framework/server/Auth';
import ViewInstituteGroupMember, { IViewInstituteGroupMemberRecord } from '@src/framework/view/sys/ViewInstituteGroupMember';
import ViewInstituteGroup, { IViewInstituteGroupRecord } from '@src/framework/view/sys/ViewInstituteGroup';
import SecUserSelector from '@src/framework/forms/SecUserSelector';
import InstituteGroupMemberCrud from '@src/framework/crud/sys/InstituteGroupMemberCrud';
import { __ } from '@src/translation';



export enum InstituteGroupMemberStates {
    INVITATION_SENT_ID = 1, // Meghívó kiküldve
    ACTIVE_ID = 2, //	Aktív
    LEFT_ID = 3,   // Önként távozott, visszatérhet
    TILTED_ID = 4, //	Kitiltva, nem térhet vissza
    INVITATION_REVOKED_ID = 5, //	Meghívó visszavonva
    INVITATION_REFUSED_ID = 6, //	Meghívó visszautasítva    
}

type InstituteGroupMemberListProps = {
    match: match<{ instituteGroupId: string }>
}
interface IInstituteGroupMemberListState {
    loading: boolean;
    saving: boolean;
    institutegroup?: IViewInstituteGroupRecord;
    intituteGroupMembers?: IViewInstituteGroupMemberRecord[];
    invite_user_id: number | null;
}

export default class InstituteGroupMemberList extends React.Component<InstituteGroupMemberListProps, IInstituteGroupMemberListState> {

    constructor(props: any) {
        super(props);
        this.state = {
            loading: true,
            saving: false,
            invite_user_id: null,
        };
    }

    private _getConfirmTitle = (row: IViewInstituteGroupMemberRecord): string => {
        return ( '' + (row.member_name||'')  + ' (id=' + row.sec_user_id! + ')\n\n');
    }

    private _onChangeMemberInvitationState = async (
        row: IViewInstituteGroupMemberRecord,
        newStateId: number,
        confirmMessage: string
    ) => {
        if (confirm(this._getConfirmTitle(row) + confirmMessage
            /* 'Elfogadja a meghívást ebbe az intézménye?' */
        )) {
            try {
                const rec = await new InstituteGroupMemberCrud({
                    id: row.id,
                    status_id: newStateId /* InstituteGroupMemberStates.ACTIVE_ID */
                }).put();
                this.reloadAsync();
            } catch (error) {
                app.showErrorFromJsonResult(error);
            }
        }
    }

    private onReinvite = (row: IViewInstituteGroupMemberRecord) => {
        this._onChangeMemberInvitationState(row, InstituteGroupMemberStates.INVITATION_SENT_ID,
            __('Újra meghívja a csoportba?')
        );
    }

    protected getReinviteButton(record: IViewInstituteGroupMemberRecord): JSX.Element {
        if (
            record.status_id == InstituteGroupMemberStates.LEFT_ID
            || record.status_id == InstituteGroupMemberStates.TILTED_ID
            || record.status_id == InstituteGroupMemberStates.INVITATION_REVOKED_ID
            || record.status_id == InstituteGroupMemberStates.INVITATION_REFUSED_ID
        ) {
            return (
                <button className="listview-button small"
                    title={__("Újra meghív")}
                    onClick={() => { this.onReinvite(record) }}
                    /* ez kellene de ugyan úgy néz ki mintha enabled lenne
                    disabled={record.sec_user_id==me!.id}
                    */
                   >
                    <i className="fa fa-user-plus" />
                </button>);
        } else {
            return <></>;
        }
    }

    private onRevoke = (row: IViewInstituteGroupMemberRecord) => {
        this._onChangeMemberInvitationState(row, InstituteGroupMemberStates.INVITATION_REVOKED_ID,
            __('Visszavonja a meghívást?')
        );
    }

    protected getRevokeButton(record: IViewInstituteGroupMemberRecord): JSX.Element {
        if (record.status_id == InstituteGroupMemberStates.INVITATION_SENT_ID) {
            return (
                <button className="listview-button listview-button-warning small"
                    title={__("Meghívó visszavonása")}
                    onClick={() => { this.onRevoke(record) }}
                    /* ez kellene de ugyan úgy néz ki mintha enabled lenne
                    disabled={record.sec_user_id==me!.id}
                    */
                   >
                    <i className="fa fa-user-minus" />
                </button>);
        } else {
            return <></>;
        }
    }

    private onTilt = (row: IViewInstituteGroupMemberRecord) => {
        this._onChangeMemberInvitationState(row, InstituteGroupMemberStates.TILTED_ID,
            'Kitiltja?'
        );
    }

    protected getTiltButton(record: IViewInstituteGroupMemberRecord): JSX.Element {
        if (record.status_id == InstituteGroupMemberStates.ACTIVE_ID) {
            return (
                <button className="listview-button listview-button-alert small"
                    title={__("Kitiltás")}
                    onClick={() => { this.onTilt(record) }}
                    /* ez kellene de ugyan úgy néz ki mintha enabled lenne
                    disabled={record.sec_user_id==me!.id}
                    */
                >
                    <i className="fa fa-user-times" />
                </button>);
        } else {
            return <></>;
        }
    }

    protected getRecordButtons(record: IViewInstituteGroupMemberRecord): JSX.Element[] {
        return /*super.getRecordButtons(record).concat(*/[
            this.getReinviteButton(record),
            this.getRevokeButton(record),
            this.getTiltButton(record),
        ];
    }

    componentDidMount() {
        this.reloadAsync();
    }

    componentDidUpdate() {
    }

    private reloadAsync = async () => {
        this.setState({ loading: true, saving: false });
        try {
            const institute_group_id = parseInt(this.props.match.params.instituteGroupId);
            const institutegroup = (await ViewInstituteGroup.load(institute_group_id)).record;
            const intituteGroupMembers = await ViewInstituteGroupMember.list({ filter: { institute_group_id, is_active: true }, 
                order_by: "member_name"  }
            );
            this.setState({
                loading: false, saving: false,
                institutegroup,
                intituteGroupMembers
            });
        } catch (error) { app.showErrorFromJsonResult(error) };
    }

    private onInviteUserIdChange = (invite_user_id: number | null) => {
        this.setState({ invite_user_id });
    }

    private doInvite = async () => {
        try {
            /* let member = */ await new InstituteGroupMemberCrud({
                institute_group_id: this.state.institutegroup!.id,
                sec_user_id: this.state.invite_user_id!,
                status_id: 1, // Meghívó kiküldve. Bocs hogy nem nevesített...
            }).put();
            app.showSuccess(
                __("Sikeres meghívás"),
                __("A meghívó kiküldése sikeres volt.")
            );
            this.setState({
                invite_user_id: null,
            }, this.reloadAsync);
        } catch (error) {
            app.showErrorFromJsonResult(error);
        }
    }

    private toggleAdmin = async (rowIdx: number) => {
        const rows : IViewInstituteGroupMemberRecord[] = this.state.intituteGroupMembers!;
        const row = rows[rowIdx];
        row.is_admin = !row.is_admin;
        try {
            await new InstituteGroupMemberCrud({
                id: row.id,
                is_admin: row.is_admin
            }).put();
            if (row.is_admin) {
                app.showSuccess(__("Sikeres művelet"), __("Adminisztrátori jogkör megadva."));
            } else {
                app.showSuccess(__("Sikeres művelet"), __("Adminisztrátori jogkör megvonva."));
            }
            this.reloadAsync();
        } catch (error) {
            app.showErrorFromJsonResult(error);
        }
    }

    render() {

        if (this.state.loading || !me || me == null) {
            return <BubbleLoader />
        }

        const intituteGroupMembers = this.state.intituteGroupMembers;
        const getTdProps = (finalState: any, rowInfo: RowInfo, column?: Column, instance?: any) => {
            let props: Partial<TableProps> = {};
            return props;
        }

        let isGroupAdmin: boolean = hasAnyGroup(me, [Groups.Admin, Groups.NkpAdmin, Groups.InstituteSiteAdmin]);
        if (!isGroupAdmin && intituteGroupMembers && me) {
            for (let i = 0; i < intituteGroupMembers.length ; i++) {
                const row = intituteGroupMembers[i];
                if (row.sec_user_id == me.id && row.is_admin) {
                    isGroupAdmin = true;
                    break;
                }
            }
        }
        let columns: any[] = [
            { Header: __("Név"), accessor: "member_name", style: { margin: "auto 0" } },
            { Header: __("Állapot"), accessor: "status_title", style: { margin: "auto 0" }, maxWidth: 220 ,
                Cell: (data: any, column: any) => {
                const row: IViewInstituteGroupMemberRecord = data.original;
                const log = __('Létrehozva: {creator} @ {creationTime}\n', {creator: row.creator, creationTime: row.creation_time}) +
                    __('Módosítva: {modificationTime}', {modificationTime: (row.modifier ? (row.modifier + " @ " + row.modification_time) : __("Még nem volt módosítva"))});

                return <span>
                        {row.status_title}
                        &nbsp;
                        <i className="fa fa-info-circle"
                            style={{ cursor: "pointer" }}
                            title={log}
                            onClick={() => { alert(log) }}
                        />
                    </span>
                }
            }
        ];
        if (isGroupAdmin) {
            columns.push({
                Header: __("Művelet"), accessor: "status_title", style: { margin: "auto 0" }, maxWidth: 220,
                Cell: (data: any, column: any) => {
                    return this.getRecordButtons(data.original as IViewInstituteGroupMemberRecord);
                }
            });
            columns.push(
                {
                    Header: "Admin", accessor: "is_admin", searchable: false,
                    Cell: (data: any, column: any) => {
                        const rowIdx = data.index;
                        const row: IViewInstituteGroupMemberRecord = data.original;
                        const id = "is_admin_sw_" + row.id;
                        return <div className="switch radius">
                            <input id={id} name={id}
                                className="switch-input" type="radio" checked={row.is_admin}
                                onClick={() => this.toggleAdmin(rowIdx)}
                                /* ez kellene de ugyan úgy néz ki
                                   mintha enabled lenne
                                   disabled={row.sec_user_id==me!.id}
                                */
                            />
                            <label className="switch-paddle" htmlFor={id}>
                                <span className="show-for-sr">
                                    Admin
                            </span></label>
                        </div>;
                    }
                })
        } else {
            columns.push(
                {
                    Header: "Admin", accessor: "is_admin", searchable: false,
                    Cell: (data: any, column: any) => {
                        const row: IViewInstituteGroupMemberRecord = data.original;
                        return <span>
                            {row.is_admin
                                ?<i className="fa fa-check" title="Igen" />
                                :<i className="fa fa-times" title="Nem" />
                            }
                        </span>;
                    }
                })

        }
        const tableProps = {
            columns,
            data: intituteGroupMembers,
            filterable: true,
            className: "-striped -highlight",
            getTdProps
        }

        const canInvite = this.state.invite_user_id;
            // && this.state.invite_user_name;


        return (
            <>
                <div className="row column">
                    <h2>{__("Intézményi csoport")}</h2>
                    <h3>{this.state.institutegroup!.displayvalue}</h3>
                </div>
                <div className="row column">
                    <ReactTable {...tableProps}
                    />
                </div>
                <div className="row column">
                    <fieldset className="fieldset wf-head-plugin-fieldset">
                        <legend >{__("Új tag meghívása")}</legend>

                        {/*
                        <label htmlFor="invite_user_name">Természetes név</label>
                        <input
                            id="invite_user_name"
                            key="invite_user_name"
                            type="text"
                            value={this.state.invite_user_name || ''}
                            onChange={this.onInviteUserNameChange}
                        />
                        */}


                        <label htmlFor="invite_user_input">{__("Felhasználó (e-mail cím vagy bejelentkezési név)")}</label>
                        <SecUserSelector
                            value={this.state.invite_user_id}
                            clearable={false}
                            onChange={this.onInviteUserIdChange}
                        />
                        <button type="button" className="button primary"
                            disabled={!canInvite}
                            onClick={this.doInvite}
                        >
                            <i className="far fa-plus-square" />
                            <i className="far fa-address-card" />
                            &nbsp;
                            {__("Meghívó elküldése")}
        
                </button>
                    </fieldset>

                </div>
            </>
        );
    }
}