import { Server } from '../../server/Server';
import View , { registerViewClassProxy, ViewClassProxy } from '../View';
import { IListParams } from '@framework/crud/Crud';

export interface IViewTaskRecord {
    /**
     * Azonosító
     *
     * Azonosító (pótkulcs)
     */
    id?: number;
    /**
     * Rekord létrehozás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel létrehozásakor.
     */
    creation_time?: string /*timestamp?*/;
    /**
     * Ki hozta létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozó felhasználóra hivatkozik.
     */
    creation_user_id?: number;
    /**
     * Melyik session-ből jött létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozáshoz használt munkamenetre hivatkozik.
     */
    creation_session_id?: number|null;
    /**
     * Utolsó módosítás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel módosításakor.
     */
    modification_time?: string /*timestamp?*/|null;
    /**
     * Ki módosította utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosító felhasználóra hivatkozik.
     */
    modification_user_id?: number|null;
    /**
     * Melyik session-ből módosították utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosításhoz használt munkamenetre hivatkozik.
     */
    modification_session_id?: number|null;
    /**
     * Aktív
     *
     * Logikai törléshez használt mező.
     */
    is_active?: boolean;
    /**
     * Importálási feladat típus
     */
    task_type_id?: number;
    /**
     * Importálási feladat állapota
     */
    task_status_id?: number;
    /**
     * Az importálási feladat gyökér könyvtára.
     */
    oo_folder_id?: number;
    /**
     * Cím
     */
    title?: string|null;
    /**
     * Elindítva
     *
     * Az importálási feladat ekkor lett elindítva
     */
    started?: string /*timestamp?*/|null;
    /**
     * Befejezve
     *
     * Az importálási feladat ekkor lett befejezve (csak ha sikeres volt).
     */
    completed?: string /*timestamp?*/|null;
    /**
     * Sikertelen
     *
     * Az importálási feladat ekkor futott hibára (csak ha sikertelen volt).
     */
    failed?: string /*timestamp?*/|null;
    /**
     * Típus
     *
     * Típus
     */
    task_type_title?: string|null;
    /**
     * Típus leírás
     *
     * Típus leírás
     */
    task_type_description?: string|null;
    /**
     * Állapot
     *
     * Állapot
     */
    task_status_title?: string|null;
    /**
     * Állapot leírás
     *
     * Állapot leírás
     */
    task_status_description?: string|null;
    /**
     * Létrehozta
     *
     * Felhasználó
     */
    creator?: string|null;
    /**
     * Módosító
     *
     * Felhasználó, aki utoljára módosította
     */
    modifier?: string|null;
}

/**
 *  data_imp.view_task - Importálási feladat
 *
 *  Idősoros táblázat, ami az összes tétel összes korábbi verzióját eltárolja.
 */

export default class ViewTask extends View<IViewTaskRecord> {
    public static VIEW_INFO_ID = 2019022001;

    public static getSchemaNameForClass(): string { return 'data_imp'; }
    public static getViewNameForClass(): string { return 'view_task'; }
    public static getTableNameForClass(): string|null { return 'task'; }

    public static load: (id: number, aServer ?: Server) => Promise<ViewTask>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<IViewTaskRecord[]>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class ViewTaskClassProxy extends ViewClassProxy<IViewTaskRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const viewTaskClassProxy = new ViewTaskClassProxy(ViewTask);
registerViewClassProxy(viewTaskClassProxy);

