import * as React from 'react';
import { Link } from 'react-router-dom';
import HoverImg from '@src/component/media/HoverImg';
import VimeoCrud from '@src/framework/crud/media/VimeoUploadCrud';
import { durationSecondsConverter } from '@src/component/media/DurationConverter';
import { PATH_PUBLISHED_MEDIA_VIEW } from '@src/Routes';
import { me } from '@src/framework/server/Auth';
import { CollectionMenu, ENABLED_COLLECTION_TABLE_INFO_IDS } from '../collection/CollectionMenu';
import { getTypes } from '../media/MediaSearchBar';
import { GetMediaTypeFromId } from './GetMediatypeFromID';
import { __ } from '@src/translation';

export enum VideoBlockDisplay {
    Block,
    List
}

type VideoBlockProps = {
    fileId: number,
    sha1: string,
    title: string,
    description: string,
    alt?: string,
    displayName?: string,
    media_type_id?: number,
    videoBlockDisplay?: VideoBlockDisplay,
    table_info_id?: number
}

export class VideoBlock extends React.Component<VideoBlockProps, { durationSeconds?: number, type?: string }> {

    constructor(props: any) {
        super(props);
        this.state = {

        };
    }

    async componentDidMount() {
        const response = await VimeoCrud.list({ filter: { sha1: this.props.sha1 }, limit: 1 });
        let type = GetMediaTypeFromId(this.props.media_type_id);
        this.setState({ type })
        if (response.length > 0 && response[0].vimeo_data != null) {
            const durationSeconds = response[0].vimeo_data.duration;
            this.setState({
                durationSeconds
            })
        }
    }

    render() {

        const type = getTypes().find(type => type.elasticType.includes(this.state.type!));
        return (


            <div className="eke-media-block">
                <Link className="eke-media-link" to={PATH_PUBLISHED_MEDIA_VIEW + "/" + this.props.fileId}>
                    <span className="eke-media-thumb">
                        <HoverImg id={this.props.fileId} sha1={this.props.sha1} alt={this.props.alt} title={this.props.title} media_type_id={this.props.media_type_id} />
                        <span className="eke-video-verticalDuration">
                            {durationSecondsConverter(this.state.durationSeconds + "")}
                        </span>
                    </span>

                    <div className="eke-media-details">

                        <div className="eke-media-name" dangerouslySetInnerHTML={{__html: this.props.displayName || this.props.title}}>
                            
                        </div>
                        <span className="eke-media-description">
                            {this.props.description}
                        </span>

                        <span className="eke-media-social">
                            {
                                type ?
                                    <span className="eke-media-type">
                                        <i className={"icon " + type!.iconClassName}></i>&nbsp;
                                            {type!.name}
                                    </span>
                                    :
                                    ""
                            }

                            {/* {

                                me && this.props.table_info_id && ENABLED_COLLECTION_TABLE_INFO_IDS.includes(this.props.table_info_id)
                                    ?
                                    <CollectionMenu tableInfoId={this.props.table_info_id} recId={this.props.fileId} />
                                    :
                                    null
                            } */}

                        </span>

                    </div>


                </Link>
            </div>


        );
    }
}

export class OfferBlock extends React.Component<{ item: any, durationSeconds?: string, mediaFavourite?: boolean, plays: number | undefined, mediaType: string }, { thumbnailUrl?: string }> {

    render() {
        let name="";
        if(this.props.item.displayname &&this.props.item.displayname.length>0)
        {
            name = this.props.item.displayname;//|| this.props.item.;
        }
        else
        {
            const title_text_paragraph = document.createElement("p");
            title_text_paragraph.innerHTML = this.props.item.title_text || "";
            name =title_text_paragraph.innerText;

        }
       
       
        if(this.props.mediaType == "map") name = this.props.item.lesson_name || "";
        let description = "";
        if (this.props.item.grade_name) {
            description += this.props.item.grade_name;
        }
        if (this.props.item.subject_name) {
            description += " " + this.props.item.subject_name;
        }


        let thumbnail = <HoverImg
            id={this.props.mediaFavourite ? this.props.item.oo_file_id : Number(this.props.item.id)}
            sha1={this.props.item.sha1} alt={this.props.item.text}
            media_type_id={this.props.item.media_type_id}
            title={name} />;

        if (this.props.mediaType == 'audio') {
            var audioEqualizerBars: JSX.Element[] = [];
            for (let i = 1; i <= 10; i++) {
                audioEqualizerBars.push(<i className="bar"></i>);
            }
            thumbnail = <span className="equalizer">
                {audioEqualizerBars}
            </span>;

        } else if (this.props.mediaType == 'map') {
            // "/img/IKON_SET/FLAT/image.svg",
            thumbnail = <HoverImg
            id={this.props.mediaFavourite ? this.props.item.oo_file_id : Number(this.props.item.id)}
            sha1={this.props.item.sha1} src={"/img/IKON_SET/FLAT/map1.png"} alt={this.props.item.text}
            media_type_id={this.props.item.media_type_id}
            title={name} />;
        }
        return (

            <div className="eke-media-offer">

                <Link className="eke-media-link" to={this.props.mediaFavourite ? PATH_PUBLISHED_MEDIA_VIEW + "/" + this.props.item.oo_file_id : PATH_PUBLISHED_MEDIA_VIEW + "/" + this.props.item.id}>
                    <span className="eke-media-thumb">
                        {thumbnail}
                        <span className="eke-media-duration">
                            {this.props.durationSeconds && durationSecondsConverter(this.props.durationSeconds)}
                        </span>
                    </span>
                    <span className={"eke-media-details"}>
                        {name ?
                            <span className={"eke-media-name"}>
                                {name}
                            </span>
                            : ""
                        }
                        {description ?
                            <span className={"eke-media-description"}>
                                {description}
                            </span>
                            : ""
                        }
                        <span className="eke-media-stat">
                            <span><i className="far fa-thumbs-up"></i> <span className="show-for-sr">{__("Lájkok: {n}", { n: this.props.item.likes || 0 })}</span> {this.props.item.likes}</span>
                            {this.props.item.type != 'image' ?
                                <span><i className="far fa-eye"></i> <span className="show-for-sr">{__("Megtekintések: {n}", { n: this.props.plays || 0 })}</span> {this.props.plays}</span>
                                : ""
                            }
                        </span>
                    </span>
                </Link>

            </div>

        );
    }
}
