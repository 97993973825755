import * as React from 'react';
import { Modal } from './Modal';
import { Backdrop } from './Backdrop';

type PopoverProps = {
    open: boolean;
    onClose: () => void;

    parentElement: () => HTMLElement;
    parentAlignHorizontal?: "left" | "center" | "right";
    parentAlignVertical?: "top" | "center" | "bottom";
    
    childAlignHorizontal?: "left" | "center" | "right";
    childAlignVertical?: "top" | "center" | "bottom";
}

export class Popover extends React.Component<PopoverProps> {

    static defaultProps: Partial<PopoverProps> = {
        parentAlignHorizontal: "center",
        parentAlignVertical: "bottom",
        childAlignHorizontal: "center",
        childAlignVertical: "top",
    }

    componentDidMount() {
        this.setPositioningStyles();

        window.addEventListener("resize", this.setPositioningStyles.bind(this));
        window.addEventListener("scroll", this.setPositioningStyles.bind(this));

        setTimeout(() => this.setPositioningStyles(), 100);
    }
    
    componentDidUpdate(prevProps: PopoverProps) {
        if (!prevProps.open && this.props.open) {
            this.setPositioningStyles();

            setTimeout(() => this.setPositioningStyles(), 100);
        }
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.setPositioningStyles.bind(this));
        window.removeEventListener("scroll", this.setPositioningStyles.bind(this));
    }

    private setPositioningStyles() {
        const element: HTMLElement = this.refs.root as HTMLElement;

        const positioning = this.getPositioningStyle();
        
        if (positioning) {
            if (positioning.top !== null) {
                element.style.top = positioning.top;
            }
            if (positioning.left !== null) {
                element.style.left = positioning.left;
            }
        }
    };

    private getPositioningStyle() {
        const element: HTMLElement = this.refs.root as HTMLElement;
        if (!element) return;

        const marginThreshold = 24;

        const elemRect = {
            width: element.offsetWidth,
            height: element.offsetHeight,
        };

        // Get the transform origin point on the element itself
        const transformOrigin = this.getTransformOrigin(elemRect);

        // Get the offset of of the anchoring element
        const anchorOffset = this.getAnchorOffset();

        // Calculate element positioning
        let top = anchorOffset.top - transformOrigin.vertical;
        let left = anchorOffset.left - transformOrigin.horizontal;
        const bottom = top + elemRect.height;
        const right = left + elemRect.width;

        // Use the parent window of the anchorEl if provided
        const containerWindow = window; //ownerWindow(getAnchorEl(anchorEl));

        // Window thresholds taking required margin into account
        const heightThreshold = containerWindow.innerHeight - marginThreshold;
        const widthThreshold = containerWindow.innerWidth - marginThreshold;

        // Check if the vertical axis needs shifting
        if (top < marginThreshold) {
            const diff = top - marginThreshold;
            top -= diff;
            transformOrigin.vertical += diff;
        } else if (bottom > heightThreshold) {
            const diff = bottom - heightThreshold;
            top -= diff;
            transformOrigin.vertical += diff;
        }

        // Check if the horizontal axis needs shifting
        if (left < marginThreshold) {
            const diff = left - marginThreshold;
            left -= diff;
            transformOrigin.horizontal += diff;
        } else if (right > widthThreshold) {
            const diff = right - widthThreshold;
            left -= diff;
            transformOrigin.horizontal += diff;
        }

        return {
            top: `${top}px`,
            left: `${left}px`,
        };
    };

    private getAnchorOffset() {
        const anchorElement = this.props.parentElement();
        const anchorRect = anchorElement.getBoundingClientRect();

        return {
            top: anchorRect.top + this.getOffset(anchorRect.height, this.props.parentAlignVertical),
            left: anchorRect.left + this.getOffset(anchorRect.width, this.props.parentAlignHorizontal),
        };
    }

    private getTransformOrigin(elemRect: {width: number, height: number}) {
        return {
            vertical: this.getOffset(elemRect.height, this.props.childAlignVertical),
            horizontal: this.getOffset(elemRect.width, this.props.childAlignHorizontal),
        };
    }

    private getOffset(num: number, align?: "top" | "center" | "bottom" | "left" | "right") {
        if (!align || align == "top" || align == "left") {
            return 0;
        } else if (align == "center") {
            return num / 2;
        } else {
            return num;
        }
    }

    render() {
        if (!this.props.open) return null;

        return <Modal>
            <Backdrop onClick={this.props.onClose} transparent />
            <div ref="root" style={{position: "absolute",
                    overflowY: 'auto',
                    overflowX: 'hidden',
                    minWidth: 16,
                    minHeight: 16,
                    maxWidth: 'calc(100% - 32px)',
                    maxHeight: 'calc(100% - 32px)',
                    background: "white",
                    boxShadow: "0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)",
                    borderRadius: "4px"}}>
                {this.props.children}
            </div>
        </Modal>
    }
}
