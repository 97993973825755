import './style.css';
import { ExerciseBaseClass, AnswerTypes } from '@src/component/exercise/models/ExerciseBaseClass';
import { AExerciseEngine } from '../../models/AExerciseEngine';
import { EKEMultiTextAnswerServer } from './EKEMultiTextAnswerServer';
import { ValidationResponse } from '../../models/AExerciseTypeConverter';

export interface MultiTextAnswerData extends ExerciseBaseClass {
    options: any[], //array of MultiTextAElem
    answers?: any[],
    keywords: string;
    regular_format: boolean;
    caseSensitive: boolean;
    long_answer_mode: boolean;
}

type MultiTextAnswerUserSolution = {
    fullmatch: boolean;
    answer: string[];
}

export type MultiTextAnswerJSON = {
    text: string,
    type: AnswerTypes,
    image: string,
    url?: string
}

export type MultiTextAElementJSON = {
    answer_item?: string,
    first_item: MultiTextAnswerJSON,
    end_item: MultiTextAnswerJSON
}

export type MultiTextAnswerElementJSON = {
    answers?: any[],
    options: MultiTextAElementJSON[],
    regular_format: boolean,
    caseSensitive: boolean
}

export class EKEMultiTextAnswerExerciseEngine extends AExerciseEngine {

    private textboxes: any[] = [];
    private gotcorrectsolution: boolean = false;
    private caseSensitive: boolean = false;
    private regularFormat: boolean = false;
    private longAnswerMode: boolean = false;

    initExercise(params: ExerciseParams): void {
        super.initExercise(params);
        let exercise: MultiTextAnswerData = params.exercise;

        if (!exercise.options)
            return;

        this.gotcorrectsolution = false;
        this.textboxes = [];
        this.caseSensitive = exercise.caseSensitive ? true : false;
        this.regularFormat = exercise.regular_format ? true : false;
        this.longAnswerMode = exercise.long_answer_mode ? true : false;

        this.root.classList.add("eke-MultiTextAnswer");

        var answerdiv = document.createElement("div");

        this.setIllustration(exercise, this.root);

        this.root.appendChild(answerdiv);
        answerdiv.classList.add("grid-x");
        answerdiv.classList.add("eke-MultiTextAnswer-bottom");


        for (var answer of exercise.options) {
            //Table layout
            if (!this.regularFormat) {

                var row = answerdiv.appendChild(document.createElement("div"));

                row.classList.add("row");
                row.classList.add("align-middle", "text-center");

                let parentNode = row.appendChild(document.createElement("div"));
                if (!this.longAnswerMode) parentNode.classList.add("small-5", "medium-4", "cells", "columns", "text-node");
                else parentNode.classList.add("small-12", "medium-12", "large-12");

                if (answer.first_item.type == "image") {
                    AExerciseEngine.displayAnswer(parentNode, answer.first_item, this.is_accessible, ["multitext-image-answer"], this);
                }
                else {
                    AExerciseEngine.displayAnswer(parentNode, answer.first_item, this.is_accessible, ["text-center"], this);
                }

                var textboxDiv = row.appendChild(document.createElement("div"));
                let textbox;
                //Kifejtős feladat
                if (this.longAnswerMode) {
                    textboxDiv.classList.add("small-12", "medium-12", "large-12")
                    textbox = textboxDiv.appendChild(document.createElement("textarea"));
                    textbox.setAttribute("rows", "5");
                }
                //Normál feladat 
                else {
                    textboxDiv.classList.add("small-2", "medium-4")
                    textbox = textboxDiv.appendChild(document.createElement("input"));
                    textbox.setAttribute("type", "text");
                }
                textboxDiv.classList.add("cells");
                textboxDiv.classList.add("columns", "texbox-div");

                if (this.isReplay) {
                    textbox.setAttribute('disabled','disabled');
                }

                if (this.isSNIexc) textbox.addEventListener('blur', this.SNIEvaluation.bind(this, EKEMultiTextAnswerServer));
                this.textboxes.push(textbox);

                if (!this.longAnswerMode && (answer.end_item.text.length >= 1 || answer.end_item.type == AnswerTypes.image || answer.end_item.type == AnswerTypes.sound)) {
                    let suffix = row.appendChild(document.createElement("div"));
                    suffix.classList.add("small-5", "medium-4", "cells", "columns", "text-node", "suffix");
                    if (answer.end_item.type == AnswerTypes.image) {
                        AExerciseEngine.displayAnswer(suffix, answer.end_item, this.is_accessible, ["multitext-image-answer"], this);
                    }
                    else {
                        AExerciseEngine.displayAnswer(suffix, answer.end_item, this.is_accessible, [], this);
                    }
                }
            } else {
                //Regular text format
                if (this.regularFormat) {
                    var regularTextContainer = answerdiv.appendChild(document.createElement("span"));
                    var beforeSpan = regularTextContainer.appendChild(document.createElement("span"));

                    if (answer.first_item.text.length >= 1 && answer.first_item.type == "text") {
                        beforeSpan.innerHTML = answer.first_item.text;
                    }

                    let textbox = regularTextContainer.appendChild(document.createElement("input"));
                    textbox.classList.add("input-regular");
                    textbox.setAttribute("type", "text");
                    if (this.isSNIexc) textbox.addEventListener('blur', this.SNIEvaluation.bind(this, this, EKEMultiTextAnswerExerciseEngine));
                    this.textboxes.push(textbox);

                    if (answer.end_item.text.length >= 1 && answer.end_item.type == "text") {
                        var afterSpan = regularTextContainer.appendChild(document.createElement("span"));
                        afterSpan.innerHTML = answer.end_item.text;
                    }
                    regularTextContainer.appendChild(document.createElement("br"));
                }

            }
        }
    }

    receiveEvaluation(evaluated: Evaluated): void {

        if (this.isReplay && this.longAnswerMode) {
            this.textboxes.forEach(tb => {
                this.removeStyles(tb);
            });
            return;
        }

        for (var index = 0; index < this.textboxes.length; index++) {

            var textbox = this.textboxes[index];
            this.removeStyles(textbox);

            if (evaluated.success == true) {
                textbox.classList.add("exe-engine-check-correct");
            }
            else {
                if (evaluated.solution == null) {
                    if (!this.isSNIexc) textbox.classList.add("exe-engine-check-wrong");
                }
                else {//if evaluation was unsuccesful, first compare the two items, else if check whether it has multiple good choices chech te correct answers lists, else it's wrong 

                    var tempSol = this.caseSensitive ? evaluated.solution[index] : evaluated.solution[index].toUpperCase();
                    var tempUserSol = this.caseSensitive ? textbox.value.trim() : textbox.value.toUpperCase().trim();

                    if (tempSol == tempUserSol) {
                        textbox.classList.add("exe-engine-check-correct");
                    } else if (evaluated.solution[index].search(/;/gi) != -1) {
                        var sol_list = this.caseSensitive ? evaluated.solution[index].split(";") : evaluated.solution[index].toUpperCase().split(";");
                        if (sol_list.includes(tempUserSol))
                            textbox.classList.add("exe-engine-check-correct");
                        else
                            if (!this.isSNIexc) textbox.classList.add("exe-engine-check-wrong");
                    }
                    else {
                        if (!this.isSNIexc) textbox.classList.add("exe-engine-check-wrong");
                    }
                }
            }
        }
    }

    showCorrectSolution(solution: any): void {
        this.gotcorrectsolution = true;

        for (var index = 0; index < this.textboxes.length; index++) {
            var textbox = this.textboxes[index];
            this.removeStyles(textbox);
            let value;
            if (this.isReplay && this.longAnswerMode) {
                value = solution[index];
            } else {
                value = solution[index].split(";");
                textbox.classList.add("exe-engine-correct-bg");
            }
            textbox.value = value;
        }
    }

    getUserSolution(): MultiTextAnswerUserSolution {
        var result = [];
        for (var index = 0; index < this.textboxes.length; index++) {
            var textbox = this.textboxes[index];
            if (textbox && textbox.value == "")
                result.push("");
            else if (textbox && textbox.value) {
                if (this.caseSensitive)
                    result.push(textbox.value.trim());
                else {
                    if (!this.longAnswerMode) {
                        result.push(textbox.value.toUpperCase().trim());
                    }
                    else result.push(textbox.value.trim());
                }

            }
        }
        let solution = { fullmatch: true, answer: result };
        return solution;

    }


    isUserReady(): boolean {
        for (var index = 0; index < this.textboxes.length; index++) {
            var textbox = this.textboxes[index];
            if (textbox && textbox.value && !this.gotcorrectsolution) {
                return true;
            }
        }
        return false;
    }

    showHelp(solution: any): void {
        this.showCorrectSolution(solution);
    }

    removeStyles(element: HTMLElement): void {
        element.classList.remove("exe-engine-correct-bg", "exe-engine-wrong-bg", "eke-engine-show-correct-bg", "exe-engine-check-correct", "exe-engine-check-wrong");
    }

}
