import { Server } from '../../server/Server';
import Crud , { registerCrudClassProxy, IAsTextResult, IListParams, CrudClassProxy, ISearchTextParams, ISearchTextResult } from '../Crud';

export interface ICourseRecord {
    /**
     * Globálisan egyedi azonosító.
     * 
     * Ha új rekordot akarsz létrehozni put() metódussal, akkor hagyd üresen.
     * upsert() hívásnál figyelmen kívül van hagyva az értéke.
     *
     */
    id ?: number;
    /**
     * Rekord létrehozás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel létrehozásakor.
     */
    creation_time?: string /*timestamp?*/;
    /**
     * Ki hozta létre
     *
     * NOT NULL -> sys.sec_user ON DELETE noaction
     *
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozó felhasználóra hivatkozik.
     */
    creation_user_id?: number;
    /**
     * Melyik session-ből jött létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozáshoz használt munkamenetre hivatkozik.
     */
    creation_session_id?: number|null;
    /**
     * Utolsó módosítás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel módosításakor.
     */
    modification_time?: string /*timestamp?*/|null;
    /**
     * Ki módosította utoljára
     *
     * -> sys.sec_user ON DELETE noaction
     *
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosító felhasználóra hivatkozik.
     */
    modification_user_id?: number|null;
    /**
     * Melyik session-ből módosították utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosításhoz használt munkamenetre hivatkozik.
     */
    modification_session_id?: number|null;
    /**
     * Aktív
     *
     * Logikai törléshez használt mező.
     */
    is_active?: boolean;
    /**
     * Képzés típus
     *
     * NOT NULL -> kap.training_type ON DELETE noaction
     *
     */
    training_type_id?: number;
    /**
     * Képző
     *
     * -> sys.sec_user ON DELETE noaction
     *
     */
    teacher_id?: number|null;
    /**
     * Képzés helye.
     *
     * -> sys.institute_site ON DELETE noaction
     *
     *
     *  Akkor érdemes megadni, ha a képzés egy feladatellátási helyen történik.
     */
    institute_site_id?: number|null;
    /**
     * Képzés helye szövegesen
     *
     * Ezt csak akkor kell megadni, ha a képzés helye nem egy feladatellátási hely, vagy kiegészítésre szorul (pl. emelet, terem száma stb.)
     */
    address?: string|null;
    /**
     * Első nap
     *
     * A teljes képzés kezdete. Magában foglalja a távoktatást és az összes kontakt napot is.
     */
    start_date?: string /*date?*/|null;
    /**
     * Utolsó nap
     *
     * A teljes képzés vége. Magában foglalja a távoktatást és az összes kontakt napot is.
     */
    end_date?: string /*date?*/|null;
    /**
     * Legkorábbi jelentkezés intézményen belülről
     */
    apply_after_internal?: string /*timestamp?*/;
    /**
     * Legkorábbi jelentkezés intézményen kívülről
     */
    apply_after_external?: string /*timestamp?*/;
    /**
     * Legkésőbbi jelentkezés
     */
    application_deadline?: string /*timestamp?*/;
    /**
     * Résztvevők max. száma
     */
    max_participants?: number /*integer*/;
    /**
     * Feladat beadási határidő
     */
    submission_deadline?: string /*timestamp?*/;
    /**
     * Tanúsítványok létrehozva
     */
    certs_created?: string /*timestamp?*/|null;
    /**
     * Távoktatás kezdete
     */
    distance_learning_start?: string /*date?*/|null;
    /**
     * Távoktatás vége
     */
    distance_learning_end?: string /*date?*/|null;
    /**
     * Megjegyzés résztvevőknek
     */
    participants_comment_html?: string|null;
    /**
     * Megjegyzés képzőnek
     */
    teacher_comment_html?: string|null;
    /**
     * A képzés gyökér könyvtára.
     *
     * NOT NULL -> media.oo_folder ON DELETE noaction
     *
     */
    oo_folder_id?: number;
    /**
     * Továbbképzés ideje (tól)
     */
    training_from?: string /*date?*/|null;
    /**
     * Továbbképzés ideje (ig)
     */
    training_to?: string /*date?*/|null;
    /**
     * Lezárhatóra állítva
     */
    closable_at?: string /*timestamp?*/|null;
    /**
     * Lezárva
     */
    closed_at?: string /*timestamp?*/|null;
    /**
     * Iktatószám
     */
    regno?: number;
    /**
     * OH ellenőr
     *
     * -> sys.sec_user ON DELETE cascade
     *
     */
    oh_inspector_id?: number|null;
    /**
     * Cím
     */
    title?: string;
    /**
     * Egyéb adatok
     */
    other_jsonb?: any|null;
}

/**
 *  kap.course - Képzés
 *
 *  Idősoros táblázat, ami az összes tétel összes korábbi verzióját eltárolja.
 */

export default class CourseCrud extends Crud<ICourseRecord> {
    public static TABLE_INFO_ID = 354099037;
    public static getSchemaNameForClass() { return 'kap'; }
    public static getTableNameForClass() { return 'course'; }

    public static load: (id: number, aServer ?: Server) => Promise<CourseCrud>;
    public static asText: (id: number, includeRecord : boolean, aServer ?: Server) => Promise<IAsTextResult<ICourseRecord>>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<ICourseRecord[]>;
    public static searchText: (params: ISearchTextParams, aServer ?: Server) => Promise<ISearchTextResult<ICourseRecord>[]>;
    public static deleteById: (id: number, aServer ?: Server) => Promise<ICourseRecord>;
    public static unDeleteById: (id: number, aServer ?: Server) => Promise<ICourseRecord>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class CourseCrudClassProxy extends CrudClassProxy<ICourseRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const courseCrudClassProxy = new CourseCrudClassProxy(CourseCrud);
registerCrudClassProxy(courseCrudClassProxy);
