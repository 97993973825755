import * as React from 'react';
import { match } from 'react-router';
import { app } from '@src/index';
import ViewSharedByMe, { IViewSharedByMeRecord } from '@src/framework/view/usr/ViewSharedByMe';
import SharedContentRunCrud, { ISharedContentRunRecord } from '@src/framework/crud/usr/SharedContentRunCrud';
import { SharedContent } from './SharedContent';
import { BubbleLoader } from 'react-css-loaders';
import { Breadcrumbs } from '../Breadcrumbs';
import { PATH_CONTENT_STORE, PATH_CONTENT_STORE_SHARED_BY_ME, PATH_SHARED_CONTENT_RUN_CHECK, PATH_SHARED_CONTENT_BY_ME } from '@src/Routes';
import { roundToDecimals } from '@src/Util';
import ViewSharedWith, { IViewSharedWithRecord } from '@src/framework/view/usr/ViewSharedWith';
import { Link } from 'react-router-dom';
import PermissionPage from '@src/framework/PermissionPage';
import { ViewPermissionType, CrudPermissionType } from '@src/framework/security/PermissionCache';
import { __ } from '@src/translation';
import ViewInstituteGroupMember from '@src/framework/view/sys/ViewInstituteGroupMember';
import { IViewMemberNameRecord } from '@src/framework/view/sys/ViewMemberName';
import { me } from '@src/framework/server/Auth';
import { ExerciseEvaluationHelper } from '../exercise/ExerciseEvaluationHelper';
import { ClassroomModule } from '@src/module/classroomModule/classroomModule';
import SharedContentCrud from '@src/framework/crud/usr/SharedContentCrud';
import { AccordionItem } from '../ui/Accordion';
import { InstituteGroupMemberStates } from '../institute/InstituteGroupMemberList';
import DiscussionCards from '@src/module/messengerModule/components/DiscussionCards';

type ViewSharedByMePageProps = {
    match: match<{ sharedContentId: string }>
}


type ViewSharedByMePageState = {
    sharedContent?: IViewSharedByMeRecord;
    sharedWiths: IViewSharedWithRecord[];
    sharedContentRuns: ISharedContentRunRecord[];
    members: IViewMemberNameRecord[],
    showUserSolutions: { [key: number]: boolean };
    selectedUserId?: number;
}

export class ViewSharedByMePage extends React.Component<ViewSharedByMePageProps, ViewSharedByMePageState> {

    constructor(props: ViewSharedByMePageProps) {
        super(props);

        this.state = {
            sharedWiths: [],
            sharedContentRuns: [],
            showUserSolutions: {},
            members: [],
        }
    }

    componentDidMount() {
        this.reloadAsync();
    }

    componentDidUpdate(prevProps: ViewSharedByMePageProps) {
        if (prevProps.match.params.sharedContentId !== this.props.match.params.sharedContentId) {
            this.reloadAsync();
        }
        this.doFoundation();
    }

    doFoundation() {
        if (this.refs.offCanvas) {
            ($(this.refs.offCanvas) as any).foundation();
        }
        else {
            setTimeout(this.doFoundation.bind(this), 100);
        }
    }

    private async reloadAsync() {
        try {
            if (!me) return;
            const sharedContentId = Number(this.props.match.params.sharedContentId);

            const sharedContent = (await ViewSharedByMe.load(sharedContentId)).record;
            const sharedContentRuns = await SharedContentRunCrud.list({ filter: { is_active: true, shared_content_id: sharedContentId } });

            const sharedWiths = await ViewSharedWith.list({ filter: { is_active: true, shared_content_id: sharedContentId } });
            let groupIds: number[] = [];
            let user_ids: number[] = [];
            let members: IViewMemberNameRecord[] = [];
            sharedWiths.forEach(element => {
                if (element.institute_group_id) groupIds.push(element.institute_group_id);
                if (element.sec_user_id && element.sec_user_id != me!.id && user_ids.findIndex((el) => { return el == element.sec_user_id }) == -1) {
                    user_ids.push(element.sec_user_id);
                    members.push({ id: element.sec_user_id, fullname: element.fullname, email: element.email });
                }
            });

            const groupMembers = await ViewInstituteGroupMember.list({
                filter: {
                    institute_group_id: groupIds,
                    is_active: true,
                    status_id: InstituteGroupMemberStates.ACTIVE_ID,
                    is_admin: false
                }
            });
            groupMembers.forEach(element => {
                if (element.sec_user_id && element.sec_user_id != me!.id && user_ids.findIndex((el) => { return el == element.sec_user_id }) == -1) {
                    user_ids.push(element.sec_user_id);
                    members.push({ id: element.sec_user_id, fullname: element.member_name, email: element.member_email });
                }
            });

            this.setState({
                sharedContent,
                sharedWiths,
                sharedContentRuns,
                members
            });

        } catch (e) {
            app.showErrorFromJsonResult(e);
        }

    }

    private onToggleSolution(sharedWithId: number) {
        this.state.showUserSolutions[sharedWithId] = !this.state.showUserSolutions[sharedWithId];
        this.setState({
            showUserSolutions: this.state.showUserSolutions
        });
    }


    render() {
        const sharedContent = this.state.sharedContent;

        if (!sharedContent) {
            return <BubbleLoader />
        }

        let links: any[] = [];
        //If the component received from links to the Breadcrumbs we use them
        if (sharedContent.shared_from_institute_group_id) {
            links = ClassroomModule.getTaskBreadCrumbs(sharedContent.shared_from_institute_group_id, sharedContent.shared_from_institute_group_name || "");
            links.push({ name: sharedContent.title, path: PATH_SHARED_CONTENT_BY_ME + "/" + sharedContent.id });
        }
        else {
            links = [
                { name: __("Tartalomtár"), path: PATH_CONTENT_STORE },
                { name: __("Általam kiosztott feladatok"), path: PATH_CONTENT_STORE_SHARED_BY_ME },
                { name: sharedContent.title, path: PATH_SHARED_CONTENT_BY_ME + "/" + sharedContent.id },
            ]
        }

        let evaluationAccordionItem = <AccordionItem key="reultsAccordionItem" defaultClosed={false} title={<span><img src={"/img/IKON_SET/FLAT/notepad.svg"} className="exe-editor-icons" alt={__("Ellenőrzés")} /> {__("Ellenőrzés")}</span>}>

            <div>
                <table className="unstriped">
                    <thead>
                        <tr>
                            <th>{__("Felhasználó")}</th>
                            <th>{__("Legjobb eredmény")}</th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.members.map(s => {

                            const runs = this.state.sharedContentRuns.filter(r => r.run_by_id == s.id);

                            const bestResult = ExerciseEvaluationHelper.getBestRunResult(runs);

                            return <React.Fragment key={s.id}>
                                <tr>
                                    <td>
                                        {s.fullname} <small>{s.email}</small>
                                    </td>
                                    <td>
                                        {bestResult != null && (bestResult.shared_by_success_percent > -1 ?
                                            __("{point} pont", { point: roundToDecimals(sharedContent.max_points! * bestResult.shared_by_success_percent!, 2) }) + " (" + roundToDecimals(bestResult.shared_by_success_percent! * 100, 1) + "%)" : "-")}
                                    </td>
                                    <td>
                                        {runs.length !== 0 && <button className="button hollow" onClick={this.onToggleSolution.bind(this, s.id)} style={{ margin: 0 }}>

                                            <i className="fa fa-tasks" />
                                            {runs.length}&nbsp;{__("megoldás")}
                                &nbsp;
                                {
                                                this.state.showUserSolutions[s.id!]
                                                    ? <i className="fas fa-caret-up"></i>
                                                    : <i className="fas fa-caret-down"></i>
                                            }
                                        </button>}
                                    </td>
                                    <td>
                                        <button className="button hollow" onClick={() => this.setState({ selectedUserId: s.id })} style={{ margin: 0 }} data-toggle="messagesOffCanvas"> <i className="fa fa-envelope" />{__("Megjegyzések")}</button>
                                    </td>
                                </tr>
                                {
                                    this.state.showUserSolutions[s.id!] ?
                                        <tr>
                                            <td colSpan={4} style={{ padding: "0" }}>
                                                <div className="exercise-results-subcomponent">
                                                    <table>
                                                        <thead>
                                                            <tr>
                                                                <th>{__("Kezdés időpontja")}</th>
                                                                <th>{__("Eredmény")}</th>
                                                                <th></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                runs.map(run => {
                                                                    const runResult = ExerciseEvaluationHelper.getRunResult(run);

                                                                    return <tr key={run.id}>
                                                                        <td>{runResult.formattedTime}</td>
                                                                        <td>
                                                                            {
                                                                                runResult.sumSuccessPercent && !runResult.notFinishedMessage
                                                                                    ?
                                                                                    (__("{point} pont ({percent}%)", { point: roundToDecimals(this.state.sharedContent!.max_points! * runResult.sumSuccessPercent, 2), percent: roundToDecimals(runResult.sumSuccessPercent * 100, 1) }))
                                                                                    :
                                                                                    runResult.notFinishedMessage
                                                                            }
                                                                        </td>
                                                                        {run.finished != null && run.finished != undefined && <td style={{ textAlign: "right" }}>
                                                                            <Link className="button" to={PATH_SHARED_CONTENT_RUN_CHECK + "/" + run.id}><i className="fa fa-edit" />{__("Megoldás ellenőrzése")}</Link>
                                                                        </td>
                                                                        }
                                                                    </tr>;
                                                                })
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </td>
                                        </tr>
                                        :
                                        null
                                }
                            </React.Fragment>
                        })}
                    </tbody>
                </table>
            </div>
        </AccordionItem>;

        return <PermissionPage
            requirements={{
                view: [{
                    viewInfoId: ViewSharedByMe.VIEW_INFO_ID,
                    permissions: [ViewPermissionType.R]
                }],
                crud: { tableInfoId: SharedContentRunCrud.TABLE_INFO_ID, permissions: CrudPermissionType.U }
            }}
        >

            <div className="row expanded">
                <div className="column small-12 large-offset-2 large-8">

                    <Breadcrumbs links={links} />

                    <SharedContent showSharedBy={false} sharedContent={sharedContent} evaluationAccordionItem={evaluationAccordionItem} updateCallback={this.reloadAsync.bind(this)} />

                </div>
                <div className="off-canvas-wrapper message-canvas">
                    <div ref="offCanvas" className="off-canvas position-right" id="messagesOffCanvas" data-close-on-click={false} data-off-canvas>

                        <div className="message-tools">
                            <button onClick={() => (this.refs.messagePanel as DiscussionCards).asyncReload()}><i style={{ float: "right", padding: "10px" }} className="fas fa-sync-alt"></i></button>

                            <button className="close-button" aria-label="Close menu" type="button" data-toggle="messagesOffCanvas">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>

                        <div className="message-panel">
                            {this.state.selectedUserId && <DiscussionCards ref="messagePanel"
                                table_info_id={SharedContentCrud.TABLE_INFO_ID}
                                record_id={sharedContent.id!}
                                subject={sharedContent.title!}
                                user_id={this.state.selectedUserId}
                            />}
                        </div>
                    </div>
                </div>
            </div>
        </PermissionPage>
    }
}
