import * as React from 'react';
import { Route, Switch } from 'react-router';
import SplitPane from 'react-split-pane';
import { Link, NavLink } from 'react-router-dom';
import { PATH_ATTENDANCE_REPORTS, PATH_TRACKING_DFHT_VIEWPOINT, PATH_TRACKING_COMPLEX_VIEWPOINT, PATH_TRACKING_OCCUPATION_VIEWPOINT, PATH_TRACKING_DFHT_STATISTICS, PATH_TRACKING_OCCUPATION_STATISTICS, PATH_TRACKING_JTR_BEADANDO_STATISTICS, PATH_TRACKING_JTR_BEADANDO_SUMMARY_STATISTICS, PATH_TRACKING_DFHT_KIP_COMPLEX_SUMMARY} from '../Attendance';
import {AttendanceReportData} from './AttendanceReportsData';

export class AttendanceReportsPage extends React.Component {

    render() {
       return <SplitPane  
                ref="splitPane" split="vertical" defaultSize={250} style={{position: "relative", minHeight: "100vh"}}>
            <div className="attendance-event-nav">
                <NavLink className="attendance-event-nav-1" isActive={(match, location) => {return location.pathname===PATH_TRACKING_DFHT_VIEWPOINT || location.pathname===PATH_ATTENDANCE_REPORTS}} to={PATH_TRACKING_DFHT_VIEWPOINT}>DFHT-KIP szempontsorok</NavLink>
                <NavLink className="attendance-event-nav-2" to={PATH_TRACKING_COMPLEX_VIEWPOINT}>Komplex szempontsorok</NavLink>
                <NavLink className="attendance-event-nav-3" to={PATH_TRACKING_OCCUPATION_VIEWPOINT}>Alprogrami szempontsorok</NavLink>
                <NavLink className="attendance-event-nav-4" to={PATH_TRACKING_DFHT_STATISTICS}>Alprogrami statisztika</NavLink>
                <NavLink className="attendance-event-nav-5" to={PATH_TRACKING_OCCUPATION_STATISTICS}>DFHT-KIP és Komplex statisztika</NavLink>
                <NavLink className="attendance-event-nav-6" to={PATH_TRACKING_JTR_BEADANDO_STATISTICS}>JTR beadandó statisztika</NavLink>
                <NavLink className="attendance-event-nav-7" to={PATH_TRACKING_JTR_BEADANDO_SUMMARY_STATISTICS}>JTR beadandó összesítő</NavLink>
                <NavLink className="attendance-event-nav-7" to={PATH_TRACKING_DFHT_KIP_COMPLEX_SUMMARY}>DFHT-KIP és Komplex összegzés</NavLink>
            </div>

            <Switch>
                <Route path={PATH_ATTENDANCE_REPORTS} exact component={TrackingDfhtViewpoint} />
                <Route path={PATH_TRACKING_DFHT_VIEWPOINT} component={TrackingDfhtViewpoint} />
                <Route path={PATH_TRACKING_COMPLEX_VIEWPOINT} component={TrackingComplexViewpoint} />
                <Route path={PATH_TRACKING_OCCUPATION_VIEWPOINT} component={TrackingOccupationViewpoint} />
                <Route path={PATH_TRACKING_DFHT_STATISTICS} component={TrackingDfhtStatistics} />
                <Route path={PATH_TRACKING_OCCUPATION_STATISTICS} component={TrackingOccupationStatistics} />
                <Route path={PATH_TRACKING_JTR_BEADANDO_STATISTICS} component={TrackingJTRBeadandoStatistics} />
                <Route path={PATH_TRACKING_JTR_BEADANDO_SUMMARY_STATISTICS} component={TrackingJTRBeadandoSummaryStatistics} />       
                <Route path={PATH_TRACKING_DFHT_KIP_COMPLEX_SUMMARY} component={TrackingDFHTKIPComplexSummary} />            
            </Switch>
        </SplitPane>
    }
}

function intezmeny() {
    return <img src="/img/esem/int.svg" style={{color: "white", height: "1.5em", verticalAlign: "bottom", paddingRight: "0.5em"}}/>;
}

export default function TrackingDfhtViewpoint() {
   let type: number = 1;
   return <AttendanceReportData reportType={type}/>
}

export function TrackingComplexViewpoint() {
   let type: number = 2;
   return <AttendanceReportData reportType={type}/>
}

export function TrackingOccupationViewpoint() {
   let type: number = 3;
   return <AttendanceReportData reportType={type}/>
}

export function TrackingDfhtStatistics() {
   let type: number = 4;
   return <AttendanceReportData reportType={type}/>
}

export function TrackingOccupationStatistics() {
   let type: number = 5;
   return <AttendanceReportData reportType={type}/>
}

export function TrackingJTRBeadandoStatistics() {
    let type: number = 6;
    return <AttendanceReportData reportType={type}/>
 }
 
 export function TrackingJTRBeadandoSummaryStatistics() {
    let type: number = 7;
    return <AttendanceReportData reportType={type}/>
 }
 export function TrackingDFHTKIPComplexSummary() {
   let type: number = 8;
   return <AttendanceReportData reportType={type}/>
}
 