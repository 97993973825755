import * as React from 'react';
import { Toggle, ToggleControl } from './Panel'
import { __ } from '@src/translation';

type audioState = {
    title: string,
    isMuted: boolean,
    isPlaying: boolean,
    isStopped: boolean,
    currTime: any,
    durTime: any,
    max: string,
    actAudio: number,
    intervalId: any,
    toggleIsOpened: boolean
}

type audioProps = {
    audios: audiotype[],
    id: string,
    audioPlayed?: () => void, 
    audioEnded?: () => void, 
}

export type audiotype = {
    src: string,
    title: string
}

export class AudioPlayer extends React.Component<audioProps, audioState> {
    static defaultProps = {
        audios: [{
            src: "/tankonyv/angol_nyelv_8_sni/MP_3/19 01 16 SNI 8 -L1 T1.mp3",
            title: "TEST 1"
        }, { src: "/tankonyv/angol_nyelv_8_sni/MP_3/19 01 16 SNI 8 -L1 T3.mp3", title: "TEST 2" }], id: "tesztid"
    }
    constructor(props: any) {
        super(props);

        this.state = {
            isMuted: false,
            title: __("Audio címe"),
            isPlaying: false,
            isStopped: true,
            currTime: "00:00",
            durTime: "00:00",
            max: "0",
            actAudio: 0,
            intervalId: null,
            toggleIsOpened: true
        }
    }

    audioElement: HTMLAudioElement;

    componentDidMount() {
        this.audioElement = this.refs.audio as HTMLAudioElement;
        let index = this.state.actAudio;
        this.audioElement.src = this.props.audios[index].src;
        this.setState({ title: this.props.audios[index].title, actAudio: 0 });
        this.audioElement.volume = 0.5;
    }

    componentWillUnmount() {
        clearInterval(this.state.intervalId);
    }

    LoadAudio(act: number) {
        this.audioElement.src = this.props.audios[act].src;
        (this.refs.audioslider as any).value = 0;
    }

    Play() {
        if (this.state.isPlaying) {
            this.audioElement.pause();
            this.setState({ isPlaying: false });
            clearInterval(this.state.intervalId);
        } else {
            this.audioElement.play();
            var interval = setInterval(this.UpdateSongSlider.bind(this), 1000);
            this.setState({ isPlaying: true, isStopped: false, intervalId: interval });
            this.props.audioPlayed;
        }
    }

    Stop() {
        this.setState({ isPlaying: false, isStopped: true, currTime: "00:00" });
        this.audioElement.pause();
        this.audioElement.currentTime = 0;
        (this.refs.audioslider as any).value = 0;

        clearInterval(this.state.intervalId);
    }

    SeekAudio() {
        this.audioElement.currentTime = (this.refs.audioslider as any).value;
        this.setState({ currTime: this.ConvertTime((this.refs.audioslider as any).value) });
    }

    SetVolume() {
        let value = (this.refs.volumeslider! as any).value;
        this.audioElement.volume = value;
    }

    Mute() {
        if (this.audioElement.muted) {
            this.audioElement.muted = false;
            this.setState({ isMuted: false });
        } else {
            this.audioElement.muted = true;
            this.setState({ isMuted: true });
        }
    }

    Previous() {
        let actNum = this.state.actAudio;
        if (actNum > 0) {
            this.setState({ actAudio: actNum - 1, title: this.props.audios[actNum - 1].title }); this.LoadAudio(actNum - 1)
        }
        else if (actNum == 0) {
            this.setState({ actAudio: (this.props.audios.length - 1), title: this.props.audios[this.props.audios.length - 1].title });
            this.LoadAudio(this.props.audios.length - 1);
        }

        if (this.state.isPlaying) {
            this.audioElement!.play();
        }
        this.setState({ currTime: "00:00" });
    }

    Next() {
        let actNum = this.state.actAudio
        if (actNum < this.props.audios.length - 1) {
            this.setState({ actAudio: actNum + 1, title: this.props.audios[actNum + 1].title });
            this.LoadAudio(actNum + 1);
        }
        else if (actNum == this.props.audios.length - 1) {
            this.setState({ actAudio: 0, title: this.props.audios[0].title });
            this.LoadAudio(0);
        }
        if (this.state.isPlaying) {
            this.audioElement!.play();
        }
        this.setState({ currTime: "00:00" });
    }

    UpdateSongSlider() {
        let time = Math.round(this.audioElement.currentTime);
        (this.refs.audioslider as any).value = time;
        this.setState({ currTime: this.ConvertTime(time) });
    }

    ConvertTime(secs: any) {
        let min = Math.floor(secs / 60);
        let sec = secs % 60;
        let mintext = min < 10 ? "0" + min : min;
        let sectext = sec < 10 ? "0" + sec : sec;
        return (mintext + ":" + sectext);
    }

    loadedData() {
        this.setState({ durTime: this.ConvertTime(Math.round(this.audioElement.duration)), max: Math.round(this.audioElement.duration).toString() });
    }

    SelectAudio(num: number) {
        this.LoadAudio(num);
        this.setState({ actAudio: num, title: this.props.audios[num].title, currTime: "00:00" });
        if (this.state.isPlaying) {
            this.audioElement.play();
        }
    }

    Toggle() {
        this.state.toggleIsOpened ? this.setState({ toggleIsOpened: false }) : this.setState({ toggleIsOpened: true });
    }

    private onEnded(event:any)
    {
        this.props.audioEnded!();
    }
    render() {
        let multipleAudios = (this.props.audios.length > 1);
        return <div className="audio-container">
            <span className="show-for-sr">{__("Hanglejátszó")}</span>
            <audio ref="audio" className="audio" onEnded={this.onEnded.bind(this)} onLoadedData={() => this.loadedData()}></audio>
            <div className="title-container">
                <h3 className="audio-title">{this.state.title}</h3>

                {multipleAudios ? <div className="nums">
                    <span>{this.state.actAudio + 1 + "/" + this.props.audios.length}</span>
                    <ToggleControl id={this.props.id}><i className="fas fa-list-ul" onClick={() => this.Toggle()}></i>{
                        this.state.toggleIsOpened ? <span className="show-for-sr">{__("Kattints a lejátszási lista összecsukásához!")}</span> :
                            <span className="show-for-sr">{__("Kattints a lejátszási lista lenyitásához!")}</span>
                    }</ToggleControl>
                </div> : ""}

            </div>

            {multipleAudios ? <Toggle id={this.props.id} className="audio-list-toggle">
                <ol>
                    {
                        this.props.audios.map((item, num) => {
                            return <a onClick={() => this.SelectAudio(num)}><li key={"i-" + num}>{item.title ? item.title : __("Nincs cím megadva")}</li></a>
                        })
                    }
                </ol>
            </Toggle> : ""}

            <div>
                <div className="audio-slider-container">
                    <span id="current-time" className="current-time" title={__("Hanganyag aktuális ideje")}>{this.state.currTime}</span>
                    <input ref="audioslider" id="audio-slider" className="audio-slider" type="range" min="0" max={this.state.max} step="1" defaultValue="0" title={__("Hanganyag")} onChange={() => this.SeekAudio()}></input>
                    <span id="duration-time" className="duration-time" title={__("Hanganyag hossza")}>{this.state.durTime}</span>
                </div>
                <div className="controller-panel">
                    <div className="button-container">
                        {multipleAudios ? <button className="previous" onClick={() => this.Previous()}><i className="fas fa-backward" /><label className="show-for-sr">{__("Előző hanganyag")}</label></button> : ""}

                        <button className="play" onClick={() => this.Play()}><i className={"fa " + (this.state.isStopped || !this.state.isPlaying ?
                            "fa-play" : "fa-pause")} />{this.state.isStopped || !this.state.isPlaying ? <label className="show-for-sr">{__("Lejátszás")}</label> : <label className="show-for-sr">{__("Szüneteltetés")}</label>}</button>
                        <button className="stop" onClick={() => this.Stop()}><i className="fa fa-stop" /><label className="show-for-sr">{__("Megállítás")}</label></button>
                        {multipleAudios ? <button className="next" onClick={() => this.Next()}><i className="fas fa-forward" /><label className="show-for-sr">{__("Következő hanganyag")}</label></button> : ""}
                    </div>
                    <div className="volume-slider-container">
                        <button className="audio-icon" onClick={() => this.Mute()}><i className={"fas " + (this.state.isMuted ?
                            "fa-volume-mute" : "fa-volume-up")} />{this.state.isMuted ? <label className="show-for-sr">{__("Némítva. Hang bekapcsolása")}</label> : <label className="show-for-sr">{__("Némítás")}</label>}</button>
                        <input ref="volumeslider" id="audio-volume-slider" className="audio-volume-slider" type="range"
                            min="0" max="1" step="0.01" defaultValue="0.5"
                            onChange={this.SetVolume.bind(this)} title={__("Hangerő szabályzó")}></input>
                    </div>
                </div>
            </div>
            <span className="show-for-sr">{__("Hanglejátszó vége")}</span>
        </div>
    }
}