import { Server } from '../../server/Server';
import Crud , { registerCrudClassProxy, IAsTextResult, IListParams, CrudClassProxy, ISearchTextParams, ISearchTextResult } from '../Crud';

export interface IOoFolderRecord {
    /**
     * Globálisan egyedi azonosító.
     * 
     * Ha új rekordot akarsz létrehozni put() metódussal, akkor hagyd üresen.
     * upsert() hívásnál figyelmen kívül van hagyva az értéke.
     *
     */
    id ?: number;
    /**
     * Név
     *
     * Az OnlyOffice rendszerében a mappa'title' mezőjének értéke. Vagyis a mappa neve.
     */
    title?: string;
    /**
     * Ennek a könyvnek a gyökér könyvtára
     *
     * -> doc_pub.book ON DELETE setnull
     *
     */
    book_id?: number|null;
    /**
     * Relatív útvonal
     *
     * A mappa szerkezetben a gyökérhez képest.
     */
    relpath?: string;
    /**
     * Relatív útvonal JSON objektumként
     *
     * Egy lista ami (folder_id, folder_title) elemekből áll.
     */
    relpath_items?: any;
    /**
     * Szülő mappa
     *
     * -> media_pub.oo_folder ON DELETE cascade
     *
     */
    parent_id?: number|null;
}

/**
 *  media_pub.oo_folder - OnlyOffice mappa
 */

export default class OoFolderCrud extends Crud<IOoFolderRecord> {
    public static TABLE_INFO_ID = 3066962827;
    public static getSchemaNameForClass() { return 'media_pub'; }
    public static getTableNameForClass() { return 'oo_folder'; }

    public static canCache(): boolean {
        return true;
    }

    public static getDefaultMaxAge() : number|undefined {
        return 3600;
    }

    public static load: (id: number, aServer ?: Server) => Promise<OoFolderCrud>;
    public static asText: (id: number, includeRecord : boolean, aServer ?: Server) => Promise<IAsTextResult<IOoFolderRecord>>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<IOoFolderRecord[]>;
    public static searchText: (params: ISearchTextParams, aServer ?: Server) => Promise<ISearchTextResult<IOoFolderRecord>[]>;
    public static deleteById: (id: number, aServer ?: Server) => Promise<IOoFolderRecord>;
    public static unDeleteById: (id: number, aServer ?: Server) => Promise<IOoFolderRecord>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class OoFolderCrudClassProxy extends CrudClassProxy<IOoFolderRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const ooFolderCrudClassProxy = new OoFolderCrudClassProxy(OoFolderCrud);
registerCrudClassProxy(ooFolderCrudClassProxy);
