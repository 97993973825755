import * as React from 'react';
import { me, Groups, hasGroup } from '@src/framework/server/Auth';
import { Dialog } from '@src/component/Dialog'
import { __ } from '@src/translation';

type DateInterval = {
    from?: string;
    to?: string;
}

export interface ContentResultProps  {
    subsiteName?: string;
    id?: number;
    title?: string;
    post: string;
    icon?: string;
    class?: string;
    date: string;
    dateInterval?: DateInterval;
    img?: string;
    publishDate?: string;
    subsiteId?: number;
    subsiteShortName?: string;
    subsiteColor?: string;
    contentType?: number; 
    onRefresh?: () => void;
    editComponent?: JSX.Element;
}

export interface ContentResultState {
    isOpen: boolean;
    settingsIsVisible: boolean;
    modify: boolean;
    moreButton?: any;
    cuttedPost?: string;
    dialog?: any;
}

export default abstract class ContentResult extends React.Component<ContentResultProps, ContentResultState> {

    constructor(props: any) {
        super(props);

        this.state = {
            isOpen: false,
            settingsIsVisible: false,
            modify: false,
            cuttedPost: "",
            moreButton: null,
            dialog: null
        };
    }

    abstract cutPost(text: string): any;

    componentDidMount() {
        this.cutPost(this.props.post);
    }

    settingsVisible() {
        this.setState({ settingsIsVisible: true });
    }

    settingsInvisible() {
        this.setState({ settingsIsVisible: false });

    }

    modify() {
        this.setState({ modify: true });
    }

    update() {
        this.setState({ modify: false });
    }

    render() {
        let postDialog = <Dialog open={this.state.isOpen} title={this.props.title!} onClose={() => this.setState({ isOpen: false })}>
        <div className="modal-post">
            <div className="header">
                <div className="icon" style={{ backgroundColor: this.props.subsiteColor }}>{this.props.subsiteShortName}</div>
                <div className="name-block">
                    <h3>{this.props.subsiteName}</h3>
                    <p className="date">{this.props.publishDate ? this.props.publishDate : this.props.date}</p>
                </div>
            </div>
            {this.props.dateInterval ? <div className="time-interval label"><i>{__("Érvényes")}: {this.props.dateInterval.from} {this.props.dateInterval.to ? " - " + this.props.dateInterval.to : ""} </i></div> : ""}
            <h5>{this.props.title}</h5>
            {this.props.img ? <div><img src={"/api/media/file/" + this.props.img}></img></div> : ""}<div className="post-text" dangerouslySetInnerHTML={{ __html: this.props.post }}></div>
        </div>
    </Dialog>

        return <div className="post" onMouseOver={this.settingsVisible.bind(this)} onMouseLeave={this.settingsInvisible.bind(this)}>
            <div className="header">
                <div className="icon" style={{ backgroundColor: this.props.subsiteColor }}>{this.props.subsiteShortName}</div>
                <div className="name-block">
                    <h3>{this.props.subsiteName}</h3>
                    <p className="date">{this.props.publishDate ? this.props.publishDate : this.props.date}</p>
                </div>
                {me && hasGroup(me, Groups.Developer) ? <div className="settings" style={{ visibility: this.state.settingsIsVisible && !this.state.modify ? "visible" : "hidden", cursor: "pointer" }} onClick={this.modify.bind(this)}><i className="fas fa-cogs"></i></div> : ""}
            </div>
            {this.props.dateInterval ? <div className="time-interval label"><i>{__("Érvényes")}: {this.props.dateInterval.from} {this.props.dateInterval.to ? " - " + this.props.dateInterval.to : ""} </i></div> : ""}
            {this.state.modify 
                ? 
                React.cloneElement(this.props.editComponent!, { onSave: () => this.setState({ modify: false }), onRefresh: this.props.onRefresh, id:(this.props.id ? this.props.id : undefined), subsiteId:(this.props.subsiteId)})
                // <PostEdit onSave={() => { this.setState({ modify: false }) }} onRefresh={this.props.onRefresh} id={this.props.id ? this.props.id : undefined} subsiteId={this.props.subsiteId}></PostEdit> 
                :
                <><h5>{this.props.title}</h5><div className="post-text">{this.props.img ? <img src={"/api/media/file/" + this.props.img}></img> : ""} <div dangerouslySetInnerHTML={{ __html: this.state.cuttedPost! }}></div>{this.state.moreButton} {postDialog}</div></>}
        </div>;
    }
}