import * as React from 'react';

import SecUserListView from './sys_sec_user_listview';
import SecUserEditor from './sys_sec_user_editor';
import { viewSecUserClassProxy } from '@src/framework/view/sys/ViewSecUser';

import SecGroupListView from './sys_sec_group_listview';
import SecGroupEditor from './sys_sec_group_editor';
import { viewSecGroupClassProxy } from '@src/framework/view/sys/ViewSecGroup';

import OauthClientListView from './sys_oauth_client_listview';
import OauthClientEditor from './sys_oauth_client_editor';
import { viewOauthClientClassProxy } from '@src/framework/view/sys/ViewOauthClient';

import OauthClientRedirectUriListView from './sys_oauth_client_redirect_uri_listview';
import OauthClientRedirectUriEditor from './sys_oauth_client_redirect_uri_editor';
import { viewOauthClientRedirectUriClassProxy } from '@src/framework/view/sys/ViewOauthClientRedirectUri';

import PersonListView from './sys_person_listview';
import PersonEditor from './sys_person_editor';
import { viewPersonClassProxy } from '@src/framework/view/sys/ViewPerson';

import PartnerListView from './sys_partner_listview';
import PartnerEditor from './sys_partner_editor';
import { viewPartnerClassProxy } from '@src/framework/view/sys/ViewPartner';

import InstituteListView from '../institute/sys_institute_listview';
import InstituteEditor from '../institute/sys_institute_editor';
import { viewInstituteClassProxy } from '@src/framework/view/sys/ViewInstitute';

import InstituteSiteListView from '../institute/sys_institute_site_listview';
import InstituteSiteEditor from '../institute/sys_institute_site_editor';
import { viewInstituteSiteClassProxy } from '@src/framework/view/sys/ViewInstituteSite';

import InstituteGroupListView from '../institute/sys_institute_group_listview';
import InstituteGroupEditor from '../institute/sys_institute_group_editor';
import { viewInstituteGroupClassProxy } from '@src/framework/view/sys/ViewInstituteGroup';

import InstituteSiteAdminListView from '../institute/sys_institute_site_admin_listview';
import InstituteSiteAdminEditor from '../institute/sys_institute_site_admin_editor';
import { viewInstituteSiteAdminClassProxy } from '@src/framework/view/sys/ViewInstituteSiteAdmin';

import InstituteSiteTeacherListView from '../institute/sys_institute_site_teacher_listview';
import InstituteSiteTeacherEditor from '../institute/sys_institute_site_teacher_editor';
import { viewInstituteSiteTeacherClassProxy } from '@src/framework/view/sys/ViewInstituteSiteTeacher';

import AdminRoute from '@src/Routes'
import SecUserViewSessions from '@src/component/sys/sys_sec_user_view_sessions';
import PermissionAudit from './audit/sys_perm_audit';
import InstituteLessonPlanListView from '../institute/instituteLessonPlanList';
import InstituteLessonPlanEditor from '../institute/instituteLessonPlanEditor';
import { viewLessonplanClassProxy } from '@src/framework/view/doc/ViewLessonplan';
import { viewKapTrainingPermitClassProxy } from '@src/framework/view/kap/ViewKapTrainingPermit';
import TrainingPermissionList from '@src/project/kap/module/adminPages/TrainingPermissionList';
import TrainingPermissionEdit from '@src/project/kap/module/adminPages/TrainingPermissionEdit';
import { DupPerson, PATH_DUP_PERSON } from './sys_dup_person';

export const PATH_SYS_USER_VIEW_SESSIONS = '/sys/sec_user/view_sessions';
export const PATH_PERM_AUDIT = '/sys/perm_audit';

export function SysRoutes() {
    /* Kötelezően: schema_name/table_name/:recId? */
    return [
        <AdminRoute path={viewSecUserClassProxy.getDefaultListRoute()} component={SecUserListView} key="SecUserListView"/>,
        <AdminRoute path={viewSecUserClassProxy.getDefaultEditRoute()} component={SecUserEditor}  key="SecUserEditor"/>,
        <AdminRoute path={PATH_SYS_USER_VIEW_SESSIONS+"/:userId?"} component={SecUserViewSessions} key="SecUserViewSessions"/>,
        
        <AdminRoute path={viewSecGroupClassProxy.getDefaultListRoute()} component={SecGroupListView}  key="SecGroupListView"/>,
        <AdminRoute path={viewSecGroupClassProxy.getDefaultEditRoute()} component={SecGroupEditor}  key="SecGroupEditor"/>,

        <AdminRoute path={viewOauthClientClassProxy.getDefaultListRoute()} component={OauthClientListView}  key="OauthClientListView"/>,
        <AdminRoute path={viewOauthClientClassProxy.getDefaultEditRoute()} component={OauthClientEditor}  key="OauthClientEditor"/>,

        <AdminRoute path={viewOauthClientRedirectUriClassProxy.getDefaultListRoute()} component={OauthClientRedirectUriListView}  key="OauthClientRedirectUriListView"/>,
        <AdminRoute path={viewOauthClientRedirectUriClassProxy.getDefaultEditRoute()} component={OauthClientRedirectUriEditor}  key="OauthClientRedirectUriEditor"/>,
 
        <AdminRoute path={viewPersonClassProxy.getDefaultListRoute()} component={PersonListView}  key="PersonListView"/>,
        <AdminRoute path={viewPersonClassProxy.getDefaultEditRoute()} component={PersonEditor}  key="PersonEditor"/>,

        <AdminRoute path={viewPartnerClassProxy.getDefaultListRoute()} component={PartnerListView}  key="PartnerListView"/>,
        <AdminRoute path={viewPartnerClassProxy.getDefaultEditRoute()} component={PartnerEditor}  key="PartnerEditor"/>,

        <AdminRoute path={viewInstituteClassProxy.getDefaultListRoute()} component={InstituteListView}  key="InstituteListView"/>,
        <AdminRoute path={viewInstituteClassProxy.getDefaultEditRoute()} component={InstituteEditor}  key="InstituteEditor"/>,

        <AdminRoute path={viewLessonplanClassProxy.getDefaultListRoute()} component={InstituteLessonPlanListView}  key="InstituteLessonPlanListView"/>,
        <AdminRoute path={viewLessonplanClassProxy.getDefaultEditRoute()} component={InstituteLessonPlanEditor}  key="InstituteLessonPlanEditor"/>,
 
        <AdminRoute path={viewInstituteSiteClassProxy.getDefaultListRoute()} component={InstituteSiteListView}  key="InstituteSiteListView"/>,
        <AdminRoute path={viewInstituteSiteClassProxy.getDefaultEditRoute()} component={InstituteSiteEditor}  key="InstituteSiteEditor"/>,
        
        <AdminRoute path={viewInstituteGroupClassProxy.getDefaultListRoute()} component={InstituteGroupListView}  key="InstituteSiteGroupView"/>,
        <AdminRoute path={viewInstituteGroupClassProxy.getDefaultEditRoute()} component={InstituteGroupEditor}  key="InstituteGroupEditor"/>,

        <AdminRoute path={viewInstituteSiteAdminClassProxy.getDefaultListRoute()} component={InstituteSiteAdminListView}  key="InstituteSiteSiteAdminView"/>,
        <AdminRoute path={viewInstituteSiteAdminClassProxy.getDefaultEditRoute()} component={InstituteSiteAdminEditor}  key="InstituteSiteAdminEditor"/>,

        <AdminRoute path={viewInstituteSiteTeacherClassProxy.getDefaultListRoute()} component={InstituteSiteTeacherListView}  key="InstituteSiteSiteTeacherView"/>,
        <AdminRoute path={viewInstituteSiteTeacherClassProxy.getDefaultEditRoute()} component={InstituteSiteTeacherEditor}  key="InstituteSiteTeacherEditor"/>,

        <AdminRoute path={PATH_PERM_AUDIT} component={PermissionAudit} key="PermissionAudit" />,

        <AdminRoute path={PATH_DUP_PERSON} component={DupPerson} key="PermissionAudit" />,

        <AdminRoute path={viewKapTrainingPermitClassProxy.getDefaultListRoute()} component={TrainingPermissionList}  key="TrainingPermissionList"/>,
        <AdminRoute path={viewKapTrainingPermitClassProxy.getDefaultEditRoute()} component={TrainingPermissionEdit}  key="TrainingPermissionEdit"/>,


    ];

}