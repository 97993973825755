import obtainServer from '@framework/server/Server';
import { ILpLearningPathRecord } from '@src/framework/crud/exc/LpLearningPathCrud';
import { ILpNodeRecord } from '@src/framework/crud/exc/LpNodeCrud';
import { ILpEdgeRecord } from '@src/framework/crud/exc/LpEdgeCrud';
import { ILpConditionRecord } from '@src/framework/crud/exc/LpConditionCrud';

const URL = 'learning_path_editor';

export interface ILearningPath extends ILpLearningPathRecord {
    nodes : ILpNodeRecord[];
    edges: ILearningPathEdge[];
    conditions: ILpConditionRecord[];
}

export interface ILearningPathEdge extends ILpEdgeRecord {
    conditions ?: ILpConditionRecord[];
}

export default class LearningPathAPI {

    /** Tanulási útvonal lekérdezése */
    public static load = (learningPathId: number): Promise<ILearningPath> => {
        return obtainServer().post<ILearningPath>(URL, {operation:"load", lp_learning_path_id: learningPathId });
    }

    /**
     * Tanulási útvonal mentése.
     * 
     * Az új rekordok azonosítójához negatív értéket kell megadni.
     * Sikeres mentés után a szerver visszaküldi az elmentett learning path-t, amiben
     * már benne vannak a "rendes" azonosítók.
     * 
    */
    public static save = (learningPath: ILearningPath): Promise<ILearningPath> => {
        return obtainServer().post<ILearningPath>(URL, {operation:"save", learning_path: learningPath });
    }

}

