import { utoa } from '@framework/util/StringUtils';

import obtainServer, { Server } from '@framework/server/Server';
import AbstractError from '@framework/util/AbstractError';
import CrudServer from './CrudServer';
import { ITableInfo, getTableInfoById } from './Meta';
import { objectToQueryJsonParams } from '../util/UrlUtils';

export interface IRecord {
    id?: number; // Globálisan egyedi rekord azonosító. Ha üres, akkor a rekord még sosem volt mentve.
}

export enum StringSearchKind {
    Equals = 'equals',
    StartsWith = 'starts_with',
    EndsWith = 'ends_with',
    Contains = 'contains',
    WordSearch = 'word_search',
}

/**
 * Szöveges keresést ír le.
 */
export interface IStringSearch {
    /**
     * A keresés fajtája (egyenlő, így kezdődik/végződik, tartalmazás, szavas keresés)
     */
    kind: StringSearchKind;
    /**
     * Kis/nagybetű érzékeny.
     * @default: false
     */
    case_sensitive?: boolean;
    /**
     * A kereső kifejezés
     */
    expr: string;
}

export interface IListOrderBy {
    name: string;
    /** @default: false */
    desc?: boolean;
}

export interface ISimpleFilterDict {
    [fieldName: string]:
    (IStringSearch
        | number | number[]
        | string // | string[] TODO!
        | boolean | null | undefined
        | IMultiFieldTextSearchSpec
        | TFilterDict
        | TFilterDict[]
    )
};

export interface IMultiFieldTextSearchSpec {
    fieldNames: string[];
    expression: IStringSearch | string;
};

export type TMultiFieldTextSearch = {
    "$text"?: IMultiFieldTextSearchSpec;
}

export type TOrFilter = {
    "$or"?: TFilterDict | TFilterDict[];
}

export type TAndFilter = {
    "$and"?: TFilterDict | TFilterDict[];
}

export type TNotFilter = {
    "$not"?: TFilterDict | TFilterDict[];
}

export type TNotNullFilter = {
    "$notnull"?: string;
}

export type TGreaterFilter = {
    ">" ?: TFilterDict | TFilterDict[];
}
export type TGreaterOrEqualsFilter = {
    ">=" ?: TFilterDict | TFilterDict[];
}
export type TLessFilter = {
    "<" ?: TFilterDict | TFilterDict[];
}
export type TLessOrEqualsFilter = {
    "<=" ?: TFilterDict | TFilterDict[];
}

export interface IFilterTree {
    [fieldName: string]: TFilterDict;
}

export type TFilterDict = ISimpleFilterDict & TMultiFieldTextSearch & TOrFilter & TAndFilter & TNotFilter & TNotNullFilter & TGreaterFilter & TGreaterOrEqualsFilter & TLessFilter & TLessOrEqualsFilter;

export type TSpecFilterDict = { [name: string]: any };

export interface IListParams {
    filter?: TFilterDict;
    spec?: TSpecFilterDict;
    order_by?: string | IListOrderBy[]; // melyik mező(k) szerint rendezze
    limit?: number;    // max. visszaadott sorok száma, max. értékét a szerver limitálja
    offset?: number;   // hányadik sortól kezdve adja vissza (a teljes lekérdezésből)
    columns ?: string[]; // milyen oszlopokat akarsz lekérni. Ha nincs megadva, akkor az összeset...
    distinct ?: boolean; // select distinct, csak list() műveletre használható és csak View API-val!
    max_age ?: number; // specify this value to accept cache/stale values
}

export interface ICountParams {
    filter?: TFilterDict;
    spec?: TSpecFilterDict;
    columns ?: string[]; // milyen oszlopokat akarsz lekérni. Ha nincs megadva, akkor az összeset...
    distinct ?: boolean; // select distinct, csak list() műveletre használható és csak View API-val!
    max_age ?: number; // specify this value to accept cache/stale values
}

export interface ICountResult {
    count: number;
}

export interface IExcelDownloadParams {
    filename ?: string;
}


/** 
 * Ez hasonló mint az IListParams, de ez név szerinti (szöveges)
 * keresést végez, és az eredményben csak a nevet adja vissza.
 * 
 */
export interface ISearchTextParams extends IListParams {
    /**
     * A szöveges kereséshez ez itt a szöveg. A megadása kötelező!
     */
    text: string;
    /**
     * Ha ezt igazta állítod, akkor azokat is megtalálja, amiknél is_active hamis.
     */
    ignore_is_active?: boolean;
    /**
     * Ha ezt igazra állítod, akkor a keresés case sensitive lesz.
     */
    case_sensitive?: boolean;
    /**
     * Ha ez igazra van állítva, akkor a válaszban visszajön az egész rekord,
     * tehát nem csak az id, is_active és text lesz kitöltve, hanem a record is.
     * 
     * @default: false
     * 
     */
    include_record?: boolean;
    /**
     * A visszaadott sorok max. száma. Legfeljebb ennyi sort ad vissza.
     * Ha nincs megadva, akkor a szerver dönti el, hogy max. hányat ad vissza.
     * 
     */
    limit?: number;
    /**
     * Ezzel lehet mező értékekre szűrni a megadott szöveges keresésen felül.
     * Nem minden táblázat esetén használható. A táblázatok nagy részénél
     * olyan mezőnevek használhatók, amik az adott táblázatban, vagy a neki
     * megfelelő view-ban szerepelnek.
     */
    filter?: TFilterDict;
    /**
     * Egyéb speciális szűrő értékek. Nem minden view-ra és táblázatra
     * használható. A backend egyedileg értelmezi és alkalmazza.
     */
    spec?: TSpecFilterDict;

}

/**
 * Az "asText" hívás eredménye. Ez egy rekord szöveges reprezentációját
 * adj meg.
 * 
 */
export interface IAsTextResult<TRecord extends IRecord> {
    id: number;
    is_active?: boolean;
    text: string;
    record?: TRecord;
}

export interface ISearchTextResult<TRecord extends IRecord> {
    id: number;
    text: string;
    is_active?: boolean;
    record?: TRecord;
}

/** Általános CRUD osztály ami egy adatbázis rekordot képes reprezentálni. */
export default abstract class Crud<TRecord extends IRecord = IRecord> {
    public record: TRecord;

    constructor(record: TRecord) { this.record = record; }


    // ABSTRACT, override in subclass
    public static TABLE_INFO_ID: number;

    // ABSTRACT, override in subclass
    public static getSchemaNameForClass(): string { throw new AbstractError(); }

    // ABSTRACT, override in subclass
    public static getTableNameForClass(): string { throw new AbstractError(); }

    /**
     * Lehet-e a list() és count() hívások eredményét cache-lni.
     * A publikus séma táblázataira ez true-t ad vissza, és ilyenkor
     * a list() és count() hívások GET kérést eredményeznek POST helyett.
     * 
     */
    public static canCache(): boolean {
        return false;
    }

    /**
     * Gyorsítótárazható GET list/count kérések alapértelmezett max-age paramétere.
     */
    public static getDefaultMaxAge(): number|undefined {
        return undefined;
    }

    // TODO lehet hogy nem használja semmi?
    public static getServerForClass(aServer?: Server): CrudServer<IRecord> {
        return new CrudServer<IRecord>(aServer);
    }

    /** CRUD API Endpoint path, ezen keresztül lehet elérni a tábla műveleteit
     */
    public static getAPIPathForClass(): string {
        return `crud/${this.getSchemaNameForClass()}/${this.getTableNameForClass()}/`;
    }

    // TODO lehet hogy nem használja semmi? helyette: AbcCrud.TABLE_INFO_ID
    public static getTableInfoIdForClass(): number {
        return this.TABLE_INFO_ID;
    }

    /** Adatszerkezet lekérdezése */
    public static getTableInfoForClass(aServer?: Server): Promise<ITableInfo> {
        return getTableInfoById(this.TABLE_INFO_ID, aServer);
    }

    /** Betölt egy rekordot egy azonosítóval
     * TODO: ha nincs ilyen rekord, akkor ...
     */
    public static load(id: number, aServer?: Server): Promise<Crud<IRecord>> {
        const constructor: any = this;
        return obtainServer(aServer)
            .get<IRecord>(this.getAPIPathForClass() + id.toString())
            .then((record) => new constructor(record));
    }

    /**
     *  Betölti a rekord szöveges reprezentációját, azonosító alapján. 
     *  Ez arra használható, hogy felhasználók számára értelmezhetően,
     *  egyszerű szövegként meg lehessen jeleníteni a rekordot. Pl.
     *  egy szöveges mezőben.
     * 
     * @param id: - a rekord azonosítója
     * @param includeRecord - ha ez igazra van állítva, akkor az eredményben
     *      a szövegen és az is_active flag-en kívül a teljes rekord is benne lesz.
     *  
     */
    public static asText(id: number, includeRecord: boolean, aServer?: Server): Promise<IAsTextResult<IRecord>> {
        const server = obtainServer(aServer);
        if (includeRecord) {
            const url = this.getAPIPathForClass() + id.toString();
            return server.post<IAsTextResult<IRecord>>(
                url, { operation: 'astext', include_record: true });
        } else {
            const url = this.getAPIPathForClass() + id.toString() + "/astext"
            return server.get<IAsTextResult<IRecord>>(url);
        }
    }

    /** Lekér egy rekord listát az adatbázisból. 
     * A szerver által visszadaott rekordok száma limitált 1000-re
     */
    public static list(params: IListParams, aServer?: Server): Promise<IRecord[]> {
        let canGet: boolean = (!!params.max_age) || this.canCache();
        if (canGet && params.max_age===undefined) {
            params.max_age = this.getDefaultMaxAge();
        }
        let qParams = "";
        if (canGet) {
            qParams = "operation=list&params=" + objectToQueryJsonParams(params);
            if (qParams.length > 250) {
                canGet = false;
            }
        }
        if (canGet) {
            return obtainServer(aServer)
                .get<IRecord[]>(this.getAPIPathForClass() + "?" + qParams);
        } else {
            const fullParams = { operation: 'list', ...{ params } };
            return obtainServer(aServer)
                .post<IRecord[]>(this.getAPIPathForClass(), fullParams);
        }
    }

    /** Lekéri a rekordok számát az adatbázisból. */
    public static count(params: ICountParams, aServer?: Server): Promise<number> {
        let canGet: boolean = (!!params.max_age) || this.canCache();        
        if (canGet && params.max_age===undefined) {
            params.max_age = this.getDefaultMaxAge();
        }
        let qParams = "";
        if (canGet) {
            qParams = "operation=count&params=" + objectToQueryJsonParams(params);
            if (qParams.length > 250) {
                canGet = false;
            }
        }
        if (canGet) {
            return obtainServer(aServer)
                .get<ICountResult>(this.getAPIPathForClass() + "?" + qParams)
                .then((result) => { return result.count; });
        } else {
            const fullParams = { operation: 'count', ...{ params } };
            return obtainServer(aServer)
                .post<ICountResult>(this.getAPIPathForClass(), fullParams)
                .then((result) => { return result.count; });
        }
    }

    /** Szöveges keresés. */
    public static searchText(params: ISearchTextParams, aServer?: Server): Promise<ISearchTextResult<IRecord>[]> {
        return obtainServer(aServer)
            .post<ISearchTextResult<IRecord>[]>(this.getAPIPathForClass(), { operation: 'search_text', ...{ params } });
    }

    /** Törli a rekordot az adatbázisból. (Logikai törlés) */
    public static deleteById(id: number, aServer?: Server): Promise<IRecord> {
        return obtainServer(aServer).del<IRecord>(this.getAPIPathForClass() + id);
    }

    /** Visszaállítja a rekordot az adatbázisból. (Logikai törlés) */
    public static unDeleteById(id: number, aServer?: Server): Promise<IRecord> {
        return obtainServer(aServer)
            .post<IRecord>(this.getAPIPathForClass() + id, { operation: 'undelete', ...{ id } });
    }

    private getClass() {
        return this.constructor as typeof Crud & (new (rec: TRecord) => Crud);
    }

    public getSchemaName() {
        return this.getClass().getSchemaNameForClass();
    }

    public getTableName() {
        return this.getClass().getTableNameForClass();
    }

    public getTableInfoId() {
        return this.getClass().TABLE_INFO_ID;
    }

    public getServer(aServer?: Server) {
        return new CrudServer<TRecord>(aServer);
    }

    public getAPIPath(): string {
        let url = this.getClass().getAPIPathForClass();
        if (this.record.id !== undefined && this.record.id !== null) {
            url += '/' + this.record.id;
        }
        return url;
    }

    public getTableInfo(aServer?: Server): Promise<ITableInfo> {
        return getTableInfoById(this.getTableInfoId(), aServer);
    }

    /** Rekord feltöltése. Ha a rekord még nem létezik, létrehozza.
     * @returns: A visszaadott érték az adatbázisból visszaolvasott rekord, amin már látszódik
     *           a triggerek hatása is.
     */
    public put(aServer?: Server): Promise<Crud> {
        const cls = this.getClass();
        return this.getServer(aServer)
            .put(this.getAPIPath(), this.record)
            .then((record) => new cls(record));
    }

    /**
     * Törli a rekordot az adatbázisból.
     * @returns: Igaz ha törölte, hamis ha nem törölte mert már törölve volt. (Minden más esetben kivételt dob.)
     */
    public del(aServer?: Server): Promise<boolean> {
        return this.getServer(aServer).del(this.getAPIPath());
    }

    /** Rekord feltöltése. Ha a rekord még nem létezik, létrehozza. A létezést nem az id mező
     *  alapján ellenőrzi le, hanem a keyFieldNames-ben megadott mezők alapján.
     * @returns: A visszaadott érték az adatbázisból visszaolvasott rekord, amin már látszódik
     *           a triggerek hatása is.
     */
    public upsert(keyFieldNames: string[], aServer?: Server): Promise<Crud<TRecord>> {
        const cls = this.getClass();
        return this.getServer(aServer)
            .upsert(this.getAPIPath(), this.record, keyFieldNames)
            .then((record) => new cls(record));
    }

    /**
     * Elküld egy műveletet a rekord adataival, plusz tetszőleges paraméterek is átadhatók.
     *
     * @returns: A visszaadott érték az adatbázisból visszaolvasott rekord, amin már látszódik
     *           a triggerek hatása is.
     */
    public post(operation: string, params?: any, aServer?: Server): Promise<Crud> {
        const cls = this.getClass();
        return this.getServer(aServer)
            .post(this.getAPIPath(), this.record, operation, params)
            .then((record) => new cls(record));
    }
}

export class CrudClassProxy<TRec extends IRecord> {
    private crudClass: typeof Crud;
    constructor(crudClass?: any) {
        this.crudClass = crudClass;

    }
    public getSchemaNameForClass(): string {
        return this.crudClass.getSchemaNameForClass();
    }
    public getTableNameForClass(): string {
        return this.crudClass.getTableNameForClass();
    }
    public getServerForClass(aServer?: Server): CrudServer<TRec> {
        return this.crudClass.getServerForClass(aServer) as CrudServer<TRec>;
    }
    public getAPIPathForClass(): string {
        return this.crudClass.getAPIPathForClass();
    }
    public getTableInfoForClass(): Promise<ITableInfo> {
        return this.crudClass.getTableInfoForClass();
    }
    public getTableInfoIdForClass(): number {
        return this.crudClass.getTableInfoIdForClass();
    }

    public load(id: number): Promise<TRec> {
        return this.crudClass.load(id).then((crud) => crud.record as TRec);
    }
    public asText(id: number, includeRecord: boolean, aServer?: Server): Promise<IAsTextResult<TRec>> {
        return this.crudClass.asText(id, includeRecord, aServer) as Promise<IAsTextResult<TRec>>;
    }
    public list(params: IListParams, aServer?: Server): Promise<TRec[]> {
        return this.crudClass.list(params, aServer) as Promise<TRec[]>;
    }
    public count(params: ICountParams, aServer?: Server): Promise<number> {
        return this.crudClass.count(params, aServer);
    }

    public searchText(params: ISearchTextParams, aServer?: Server): Promise<ISearchTextResult<TRec>[]> {
        return this.crudClass.searchText(params, aServer) as Promise<ISearchTextResult<TRec>[]>;
    }

    public deleteById(id: number, aServer?: Server): Promise<TRec> {
        return this.crudClass.deleteById(id, aServer) as Promise<TRec>;
    }
    public unDeleteById(id: number, aServer?: Server): Promise<TRec> {
        return this.crudClass.unDeleteById(id, aServer) as Promise<TRec>;
    }
    public getCrudInstance(record: Partial<TRec>): Crud<TRec> {
        return new (this.crudClass as any)(record);
    }
    public put(record: Partial<TRec>): Promise<TRec> {
        return this.getCrudInstance(record).put()
            .then((crud) => crud.record as TRec);
    }
    public del(record: TRec): Promise<boolean> {
        return this.getCrudInstance(record).del();
    }
    public upsert(record: Partial<TRec>, keyFieldNames: string[]): Promise<TRec> {
        return this.getCrudInstance(record).upsert(keyFieldNames).then((crud) => crud.record as TRec);
    }
    public post(record: Partial<TRec>, operation: string, params?: any): Promise<TRec> {
        return this.getCrudInstance(record).put()
            .then((crud) => crud.record as TRec);
    }


    /**
     * Konkrét listázó Url készítés a megadott szűrőkhöz.
     * 
     * @param filterValues: alapértelmezett szűrés a listázáshoz
     * 
     * */
    public getListUrl(filterValues?: any, spec?: any): string {
        let encodedParams: string = '';
        if (filterValues || spec) {
            let params = {};
            if (filterValues !== undefined)
                params["filter"] = filterValues;
            if (spec !== undefined)
                params["spec"] = spec;
            encodedParams = utoa(JSON.stringify(params));
        }
        return `/${this.getSchemaNameForClass()}/${this.getTableNameForClass()}/list/` +
            encodedParams;
    }
    /** 
     * Konkrét szerkesztő Url készítés a megadott rekord azonosítóhoz
     *
     * @param recId: A rekord azonosítója. Ha null, akkor új felvitel lesz belőle.
     * @param defaultValues: opcionálisan azokat alapértelmezett mező értékek
     *   tartalmazza, amiket új rekord létrehozásakor (az új gomb megnyomásakor)
     *   be kell írni.
     */
    public getEditUrl(recId: number | null, defaultValues?: any, spec?: any): string {
        let defaultValuesEncoded: string;
        if (defaultValues || spec) {
            let params = {}
            if (defaultValues) {
                params["defaultValues"] = defaultValues;
            }
            if (spec) {
                params["spec"] = spec;
            }
            defaultValuesEncoded = utoa(JSON.stringify(params));
        } else {
            defaultValuesEncoded = '';
        }
        return `/${this.getSchemaNameForClass()}/${this.getTableNameForClass()}/edit/` +
            (recId || 'null') +
            '/' + defaultValuesEncoded;
    }

}

type ICrudClassProxyCache = { [tableInfoId: number]: CrudClassProxy<IRecord> };
let crudClassProxyCache: ICrudClassProxyCache = {}

export const registerCrudClassProxy = (crudClassProxy: CrudClassProxy<IRecord>) => {
    crudClassProxyCache[crudClassProxy.getTableInfoIdForClass()] = crudClassProxy;
}

export const getCrudClassProxyById = (tableInfoId: number): CrudClassProxy<IRecord> => {
    return crudClassProxyCache[tableInfoId];
}