import { IRecord } from "@src/framework/view/View";
import { CrudClassProxy } from "@src/framework/crud/Crud";

export async function  updateSubrecords<T extends IRecord>(records: T[], selectedIds: number[], crudClassProxy: CrudClassProxy<T>, masterColumnName: string, masterValue: number, detailColumnName: string) {
    for(const record of records) {
        if (!selectedIds.includes(record[detailColumnName])) {
            await crudClassProxy.upsert({
                    [masterColumnName]: masterValue,
                    [detailColumnName]: record[detailColumnName],
                    is_active:false
                } as any, [masterColumnName, detailColumnName]);
        }
    }
    for(const selectedId of selectedIds) {
        if (!records.find(s => s[detailColumnName] == selectedId)) {
            await crudClassProxy.upsert({
                    [masterColumnName]: masterValue,
                    [detailColumnName]: selectedId,
                    is_active:true
                } as any, [masterColumnName, detailColumnName]);
        }
    }
}