import * as React from 'react';
import * as ReactDOM from 'react-dom';

import * as NotificationSystem from 'react-notification-system';
import { TermsOfUseAcceptPage, termsOfUseAcceptNeeded } from '@src/project/nkp/TermsOfUseAcceptPage';
import { Routes, PATH_PUBLISHED_MEDIA, PATH_PUBLISHED_SEARCH, PATH_EXERCISE_PUB_LIST, PATH_PUBLISHED_BOOK_SELECT, PATH_PUBLISHED_BOOKS, PATH_PUBLISHED_DOWNLOADABLE, PATH_EDIT_BOOK_TABLE_OF_CONTENTS, PATH_PUBLISHED_BOOK, PATH_EXERCISE, PATH_PUBLISHED_DETAILED_SEARCH, PATH_PUBLISHED_EXTERNAL_SEARCH, PATH_DASHBOARD, PATH_PROFILE, PATH_PROGRESSION_PAGE, PATH_CALENDAR_MUNYI_ACTIVITY, PATH_KNOWLEDGE_BASE_LIST, PATH_CONTENT_STORE, PATH_EDIT_BOOK_LIST, PATH_EXERCISE_LIST, PATH_EXERCISE_SERIES_LIST, ADMIN, PublicRoute, PATH_MAIN, PATH_CALENDAR_MUNYI_APPROVE, PATH_MENU_EDITOR_LIST } from '@src/Routes';
import { hasAnyGroup, me, Groups, hasGroup } from '@src/framework/server/Auth';
import { Switch, Route, Router } from 'react-router';
import Header, { MenuAction } from '@src/component/Header';
import KnowledgeBaseNotification from '@src/component/knowledgebase/KnowledgeBaseNotification';
import Axios from 'axios';

import { PREVIEW_DISABLE_RENDER_NAME, ROOT_DIV_ID, app, initSystem, history } from '@src/index';
import { __ } from '@src/translation';
import { viewWfWorkflowClassProxy } from '@src/framework/view/wf/ViewWfWorkflow';
import { permissionCache, CrudPermissionType } from '@src/framework/security/PermissionCache';
import ExerciseCrud from '@src/framework/crud/exc/ExerciseCrud';
import BookCrud from '@src/framework/crud/doc/BookCrud';
import ExerciseSeriesCrud from '@src/framework/crud/exc/ExerciseSeriesCrud';
import LessonplanCrud from '@src/framework/crud/doc/LessonplanCrud';
import LessonplanPubCrud from '@src/framework/crud/doc_pub/LessonplanCrud';

import { viewTicketClaimClassProxy } from '@src/framework/view/ticket/ViewTicketClaim';
import Dashboard from '@src/component/Dashboard';
import MainComponent from './MainComponent';
import { PATH_LESSONPLAN_EDIT, PATH_LESSONPLAN_VIEW, PATH_LESSONPLAN_PUB_VIEW, PATH_LESSONPLAN_PUB_EDIT_LIST, PATH_LESSONPLAN_EDIT_LIST } from '../dpmk/lessonplan/lessonplan_routes';
import { DpmkLessonPlanEditPage } from '../dpmk/lessonplan/DpmkLessonPlanEdit';
import { LessonPlanViewPage } from '../dpmk/lessonplan/LessonPlanView';
import LessonPlanEditList from '../dpmk/lessonplan/LessonPlanEditList';
import LessonPlanPubList from '../dpmk/lessonplan/LessonPlanPubList';
import { configureContentStore, ContentStoreExtraComponent } from '@src/component/filemanager/ContentStore';
import { OFIAltTaggerPage } from '@src/component/filemanager/ofi/OFIAltTaggerPage';
import OFIQuickTaggerPage from '@src/component/filemanager/ofi/OFIQuickTaggerPage';
import { OFIUnlinkedPicturesPage } from '@src/component/filemanager/ofi/OFIUnlinkedPicturesPage';
import { OFIConvertedLinksPage } from '@src/component/filemanager/ofi/OFIConvertedLinks';
import { OFIBookHeadersPage } from '@src/component/filemanager/ofi/OFIBookHeadersPage';
import OFIBrokenLinksPage from '@src/component/filemanager/ofi/OFIBrokenLinksPage';
import { OFIEmbeddedListPage } from '@src/component/filemanager/ofi/OFIEmbeddedListPage';
import { OFIInvalidExercisesList } from '@src/component/exercise/Viewer/ExerciseValidationResults';
import { OFISearchPage } from '@src/component/filemanager/ofi/OFISearchPage';
import { OFIOriginTablePage } from '@src/component/filemanager/ofi/OFIOriginTablePage';
import { config } from '@src/framework/server/Server';
import Footer from '@src/project/nkp/Footer';
import { configureBookPage } from '@src/component/book/viewer/BookPage';
import { configureCms } from '@src/component/cms/CmsModule';
import { DeveloperModule } from '@src/module/developerModule/developerModule';
import { ContentStoreModule } from '@src/module/contentStoreModule/contentStoreModule';
import OldNkpPlay, { PATH_PLAY } from './OldNkpPlay';
import { ClassroomModule } from '@src/module/classroomModule/classroomModule';
import { getMenus, BaseMenu } from '@src/module/Module';
import { configureProfile } from '@src/component/profile/ProfilePageComponent';
import { MessengerModule } from '@src/module/messengerModule/messengerModule';
import { NotifierModule  } from '@src/module/notifierModule/notifierModule';
import { OFIScormListPage } from '@src/component/filemanager/ofi/OFIScormListPage';
import { TranslationModule } from '@src/module/translationModule/translationModule';
import { SurveyStartComponent } from '@src/component/survey/SurveyStartComponent';
import { ExerciseModule } from '@src/module/exerciseModule/exerciseModule';
import { SearchModule } from '@src/module/searchModule/searchModule';
import { SearchResult } from '@src/server/PublicServer';
import * as Server from '@src/server/PublicServer';
import { ElasticContentType } from '@src/module/searchModule/component/SearchPage';

const rootElement = document.getElementById(ROOT_DIV_ID);

class OTK extends React.Component<{termsOfUseAcceptNeeded: boolean, showSurvey?:boolean}, {isWCAG: boolean, functionsMenuActions: MenuAction[]}> {

    private mainCss: HTMLLinkElement;

    private mainCssHref: string | null = null;

    constructor(props: any) {
        super(props);
        
        this.state = {
            isWCAG: false, 
            functionsMenuActions:[]
        };
    }

    componentDidMount() {
        app.setNotificationSystem(this.refs.notificationSystem as NotificationSystem.System);
        this.mainCss = (document.getElementById('mainCss') as HTMLLinkElement);
        this.mainCssHref = this.mainCss.getAttribute("href");

        this.reloadAsync();
    }

    private async reloadAsync() {
        const functionsMenuActions: MenuAction[] = [];
        
        functionsMenuActions.push({title: __("Tartalomtár"), linkTo: PATH_CONTENT_STORE + "/sajat", className: "ico nkp-icon-file-explorer"});

        if (await permissionCache.hasPermission({crud: {permissions: CrudPermissionType.R, tableInfoId: LessonplanPubCrud.TABLE_INFO_ID}})) {
            functionsMenuActions.push({title: __("Óravázlattár"), linkTo: PATH_LESSONPLAN_PUB_EDIT_LIST, className: "fa fa-clock"});
        }

        if (await permissionCache.hasPermission({crud: {permissions: CrudPermissionType.U, tableInfoId: BookCrud.TABLE_INFO_ID}})) {
            functionsMenuActions.push({title: __("Tankönyv szerkesztő"), linkTo: PATH_EDIT_BOOK_LIST, className: "ico nkp-icon-lesson-edit"});
        }
        
        if (await permissionCache.hasPermission({crud: {permissions: CrudPermissionType.U, tableInfoId: ExerciseCrud.TABLE_INFO_ID}})) {
            functionsMenuActions.push({title: __("Feladatlista"), linkTo: PATH_EXERCISE_LIST, className: "ico nkp-icon-my-lessons-repo"});
        }

        if (await permissionCache.hasPermission({crud: {permissions: CrudPermissionType.U, tableInfoId: ExerciseSeriesCrud.TABLE_INFO_ID}})) {
            functionsMenuActions.push({title: __("Feladatsorlista"), linkTo: PATH_EXERCISE_SERIES_LIST, className: "ico nkp-icon-excersises-repo"});
        }

        if ((await permissionCache.hasPermission({crud: {permissions: CrudPermissionType.U, tableInfoId: LessonplanCrud.TABLE_INFO_ID}})) || me &&hasGroup(me,Groups.LessonPlanMentor)) {
            functionsMenuActions.push({title: __("Óravázlat szerkesztő"), linkTo: PATH_LESSONPLAN_EDIT_LIST, className: "fa fa-clock"});
        }
        
        if (me && hasAnyGroup(me, [Groups.OFIEditor, Groups.Developer])) {
            functionsMenuActions.push({title: __("Szerkesztőségi folyamatok"), linkTo: viewTicketClaimClassProxy.getListUrl(), className: "ico nkp-icon-editorial-process"});
        }

        if (me && hasAnyGroup(me, [Groups.Admin, Groups.NkpAdmin, Groups.InstituteSiteAdmin])) {
            functionsMenuActions.push({title: __("Adminisztráció"), linkTo: ADMIN, className: "fa fa-wrench"});
        }
        if (me && hasAnyGroup(me, [Groups.Admin, Groups.NkpAdmin])) {
            functionsMenuActions.push({title: __("Menüszerkesztő"), linkTo: PATH_MENU_EDITOR_LIST, className: "fa fa-list"});
        }

        getMenus("header-function").forEach(m => {
            if (functionsMenuActions.some(f => f.linkTo === m.path)) return; // Ne rakjuk bele kétszer ugyanazt, de maradjon meg a sorrend

            functionsMenuActions.push({ title: m.title!, linkTo: m.path, className: m.iconClass });
        })

        this.setState({
            functionsMenuActions
        })
    }

    componentDidUpdate(prevProps:any, prevState:any)
    {
        if(prevState.isWCAG != this.state.isWCAG)
        {
            this.loadCss();
        }     
    }

    loadCss()
    {
        
        if(this.state.isWCAG)
        {

            this.mainCss.setAttribute("href", '/css/wcag.css');

        }
        else
        {
            this.mainCss.setAttribute("href", this.mainCssHref || "");
        }

    }

    moreContrast()
    {
        this.setState({isWCAG: !this.state.isWCAG});        
    }

    render() {
        const footer = <Footer />;
        
        let page;
        if (this.props.termsOfUseAcceptNeeded) {
            page = <TermsOfUseAcceptPage />;
        } else if (this.props.showSurvey) {
            page = <SurveyStartComponent />;
        } else {
            page = <Routes footer={footer}>
                <PublicRoute footer={footer} exact path={PATH_MAIN} component={MainComponent}/>
                {
                    me ?
                    <Route path={PATH_DASHBOARD} exact={true} render={() => <Dashboard functionsMenuActions={this.state.functionsMenuActions}/>} />
                    : null
                }

                {/* Bejelentkezés után - DPMK Óravázlat szerkesztőknek*/}
                <Route path={PATH_LESSONPLAN_EDIT + "/:id?"} exact={true} component={DpmkLessonPlanEditPage} />
                <Route path={PATH_LESSONPLAN_VIEW + "/:id"} exact={true} component={LessonPlanViewPage} />
                <Route path={PATH_LESSONPLAN_EDIT_LIST} exact={true} component={LessonPlanEditList} />

                {/* Bejelentkezés után - DPMK Óravázlat szerkesztőknek publikált tartalmakra*/}
                <Route path={PATH_LESSONPLAN_PUB_VIEW + "/:id"} exact={true} component={LessonPlanViewPage} />
                <PublicRoute footer={footer} path={PATH_LESSONPLAN_PUB_EDIT_LIST} exact={true} component={LessonPlanPubList} />

                <Route path={PATH_PLAY + "/:id?"} component={OldNkpPlay} />

            </Routes>;
        }
        
        var regexpforMedia = new RegExp(/^\/media/);
        var regexpforSearch = new RegExp(/^\/kereso/);
        var regexpforDashboard = new RegExp(/^\/iranyitopult/);

        let url = history && history.location ? history.location.pathname : "";
        let isBooksActive = !(url.indexOf(PATH_EDIT_BOOK_TABLE_OF_CONTENTS) == -1 && url.indexOf(PATH_PUBLISHED_BOOK) == -1);// ? "menu" : "menu eke-top-bar-active";
        let isMediaContainerActive = regexpforMedia.test(url);
        let isExerciseContainerActive = !(url.indexOf(PATH_EXERCISE) == -1);
        let isElasticsContainerActive = (regexpforSearch.test(url) ||
            (url.indexOf(PATH_PUBLISHED_DETAILED_SEARCH) > -1 || url.indexOf(PATH_PUBLISHED_EXTERNAL_SEARCH) > -1));
        let isDashboardContainerActive = regexpforDashboard.test(url);
        

        var topMenuActions= me ? [ {title: __("Irányítópult"), linkTo: PATH_DASHBOARD, className: "eke-header-dashboard-btn", imgSrc: isDashboardContainerActive ? "/img/IKON_SET/TOPBAR/new/dashboard_aktiv.svg" : "/img/IKON_SET/TOPBAR/new/dashboard.svg",}] : [];
        const _topMenuActions: any[]=[
            {
                title: __("Tananyagok"), 
                imgSrc: isBooksActive ? "/img/IKON_SET/TOPBAR/new/okostankonyv_aktiv.svg" : "/img/IKON_SET/TOPBAR/new/okostankonyv.svg", 
                linkTo: config.mainServer.showBooks ? PATH_PUBLISHED_BOOK_SELECT : PATH_PUBLISHED_DOWNLOADABLE, className: "eke-header-book-btn",
                subActions: config.mainServer.showBooks ? [                
                    {title: __("Okostankönyvek"), linkTo: PATH_PUBLISHED_BOOKS}, 
                    {title: __("Letölthető anyagok"), linkTo: "https://www.tankonyvkatalogus.hu/"}
                ]
                : undefined

            },
            {/*
            {title: __("Médiatár"), imgSrc: isMediaContainerActive ? "/img/IKON_SET/TOPBAR/new/mediatar_aktiv.svg" : "/img/IKON_SET/TOPBAR/new/mediatar.svg", linkTo: PATH_PUBLISHED_MEDIA, className: "eke-header-media-btn"},
            {title: __("Feladattár"), imgSrc: isExerciseContainerActive ? "/img/IKON_SET/TOPBAR/new/feladattar_aktiv.svg" : "/img/IKON_SET/TOPBAR/new/feladattar.svg", linkTo: PATH_EXERCISE_PUB_LIST, className: "eke-header-exercise-btn"},
            {
                title: __("Keresés"), imgSrc: isElasticsContainerActive ? "/img/IKON_SET/TOPBAR/new/kereso_aktiv.svg" : "/img/IKON_SET/TOPBAR/new/kereso.svg", linkTo: PATH_PUBLISHED_SEARCH, className: "eke-header-search-btn",
                subActions: me ? [
                    {title: __("Keresés a portálon"), linkTo: PATH_PUBLISHED_SEARCH}, 
                    {title: __("Keresés a külső tartalmakban"), linkTo: PATH_PUBLISHED_EXTERNAL_SEARCH}
                ] : undefined
            },
            */}
        ];

        topMenuActions = topMenuActions.concat(_topMenuActions);
        
        const profileMenuActions = [
            //{title: __("Irányítópult"), linkTo: PATH_DASHBOARD, className: "far fa-compass"},
            {title: __("Saját profilom"), linkTo: PATH_PROFILE, className: "ico nkp-icon-my-profile"},
            {title: __("Előrehaladás"), linkTo: PATH_PROGRESSION_PAGE, className: "fa fa-road"},
        ];

        if (me && hasAnyGroup(me,  [Groups.Developer, Groups.Admin, Groups.MunyiWorker])) {
            profileMenuActions.push({title: __("Naptár (MUNYI)"), linkTo: PATH_CALENDAR_MUNYI_ACTIVITY, className: "fa fa-calendar-alt"});
        }
        if (me && hasAnyGroup(me,  [Groups.Developer, Groups.Admin, Groups.MunyiValidator, Groups.MunyiApprover])) {
            profileMenuActions.push({title: __("Jóváhagyás/validálás (MUNYI)"), linkTo: PATH_CALENDAR_MUNYI_APPROVE, className: "fa fa-calendar-alt"});
        }
            
        if (me && hasAnyGroup(me, [Groups.OFIEditor, Groups.Developer])) {
            profileMenuActions.push({title: __("Tennivalóim (folyamatok)"), linkTo: viewWfWorkflowClassProxy.getListUrl({ "is_closed": false }, { "only_mine": true, "can_change_station": true }), className: "ico nkp-icon-my-tasks"});
            profileMenuActions.push({title: __("Szerkesztőségi folyamatok"), linkTo: viewTicketClaimClassProxy.getListUrl(), className: "ico nkp-icon-editorial-process"});
        }

        if (me && hasAnyGroup(me, [Groups.OFIEditor, Groups.Developer])) {
            profileMenuActions.push({title: __("Tudásbázis"), linkTo: PATH_KNOWLEDGE_BASE_LIST, className: "fa fa-book"});
        }

        return <div className="eke-main-wrapper">
                    <Switch >
                        <Route path="/alsostankonyv" />
                        <Header 
                            history={history} 
                            moreContrast={this.moreContrast.bind(this)}
                            logoUrl="/img/NKP_logo.svg"
                            logoHoverUrl="/img/NKP_logo.svg"
                            topMenuActions={topMenuActions}
                            functionMenuActions={me ? this.state.functionsMenuActions : []}
                            profileMenuActions={me ? profileMenuActions : []}     
                            forceUpdate={this.forceUpdate.bind(this)}
                            menuRight={
                                
                                    !me ?
                                    <Route exact path={PATH_MAIN} render={() =>
                                        <div className="szechenyi"></div>
                                    }></Route> : undefined
                                
                            }
                        />
                    </Switch>

                    {page}

                    <NotificationSystem ref="notificationSystem" />

                    <KnowledgeBaseNotification/> 
                </div>
    }
}

if (window.location.pathname.indexOf("/lo/") !== -1 || window.location.pathname.indexOf("/api/getPlayBlob") !== -1) {
    // React router hack.
    // Csak lokális tesztelés miatt kell.
    // A *.html fileokra is magát a react appot adja vissza a webpack local server, nem a tankönyv html fileját.

    Axios.get(window.location.pathname).then((response) => {
        // When locally testing, load local react bundle javascript that webpack created
        const data = response.data.replace(/static\/js\/main.*js/, 'static/js/bundle.js');      
        document.open();
        document.write(data);
        document.close();
    });

} else {
    
    if (!window[PREVIEW_DISABLE_RENDER_NAME] && rootElement) {
        init();
    }

}



async function init() {
    await initSystem();

    const acceptNeeded = await termsOfUseAcceptNeeded();
    const showSurvey = await SurveyStartComponent.showSurvey();

    // Tankönyv címének default beállítása. 
    // Ha egy komponens beállítja, de utána átmegyünk egy másik komponens-re, ami nem állítja be, akkor visszaállítjuk default-ra.
    history.listen(location => {
        document.title = "Okostankönyv";
    });
    document.title = "Okostankönyv";


    const isOfiEditor = me && hasAnyGroup(me, [Groups.OFIEditor, Groups.Developer]);
    let extraComponents: ContentStoreExtraComponent[] = [];
    if (isOfiEditor) {
        extraComponents = [
            {name: __("OH - multimédia adattár"), iconClass: "fa fa-fw fa-plus", linkTo: PATH_CONTENT_STORE + "/szarmazasi_hely", routeKey: 'ROUTE_CONTENT_STORE_SOURECE', routePath: PATH_CONTENT_STORE + "/szarmazasi_hely", component: OFIOriginTablePage},
            {name: __("OH - könyvbe nem bekötött fájlok"), iconClass: "fa fa-fw fa-image", linkTo: PATH_CONTENT_STORE + "/bekotetlen_kepek", routeKey: 'ROUTE_CONTENT_STORE_UNLINKED_IMAGES', routePath: PATH_CONTENT_STORE + "/bekotetlen_kepek", component: OFIUnlinkedPicturesPage},
            {name: __("OH - Konvertált linkek"), iconClass: "fa fa-fw fa-link", linkTo: PATH_CONTENT_STORE + "/konvertalt_linkek", routeKey: 'ROUTE_CONTENT_STORE_CONVERDED_LINKS', routePath: PATH_CONTENT_STORE + "/konvertalt_linkek", component: OFIConvertedLinksPage},
            {name: __("OH - alt tag nélküli tartalmak"), iconClass: "fa fa-fw fa-plus", linkTo: PATH_CONTENT_STORE + "/alt_tag", routeKey: 'ROUTE_CONTENT_STORE_ALT', routePath: PATH_CONTENT_STORE + "/alt_tag", component: OFIAltTaggerPage},
            {name: __("OH - gyors kulcsszó beállító"), iconClass: "fa fa-fw fa-plus", linkTo: PATH_CONTENT_STORE + "/gyors", routeKey: 'ROUTE_CONTENT_STORE_QUICK_TAGGER', routePath: PATH_CONTENT_STORE + "/gyors", component: OFIQuickTaggerPage},
            {name: __("OH - képkereső"), iconClass: "fa fa-fw fa-search", linkTo: PATH_CONTENT_STORE + "/kereso", routeKey: 'ROUTE_CONTENT_STORE_SEARCH', routePath: PATH_CONTENT_STORE + "/kereso", component: OFISearchPage},
            {name: __("OH - fejlécek"), iconClass: "fas fa-fw fa-heading", linkTo: PATH_CONTENT_STORE + "/fejlecek", routeKey: 'ROUTE_CONTENT_STORE_HEADERS', routePath: PATH_CONTENT_STORE + "/fejlecek/:bookId?", component: OFIBookHeadersPage},
            {name: __("OH - könyv linkek"), iconClass: "fa fa-fw fa-book", linkTo: PATH_CONTENT_STORE + "/konyvlinkek", routeKey: 'ROUTE_CONTENT_STORE_BOOKE_LINKS', routePath: PATH_CONTENT_STORE + "/konyvlinkek", component: OFIBrokenLinksPage},
            {name: __("OH - Könyv SCORM csomagok"), iconClass: "fa fa-fw fa-book", linkTo: PATH_CONTENT_STORE + "/beagyazott", routeKey: 'ROUTE_CONTENT_STORE_EMBED', routePath: PATH_CONTENT_STORE + "/beagyazott/:bookId?/:index?", component: OFIEmbeddedListPage},
            {name: __("OH - Különálló SCORM csomagok"), iconClass: "fa fa-fw fa-image", linkTo: PATH_CONTENT_STORE + "/scorm", routeKey: 'ROUTE_CONTENT_STORE_SCORM', routePath: PATH_CONTENT_STORE + "/scorm/:index?", component: OFIScormListPage},
            {name: __("OH - Hibás feladatok"), iconClass: "fa fa-fw fa-book", linkTo: PATH_CONTENT_STORE + "/hibasfeladatok", routeKey: 'ROUTE_CONTENT_STORE_INVALID_EXERCISE', routePath: PATH_CONTENT_STORE + "/hibasfeladatok", component: OFIInvalidExercisesList},
        ];
    }

    configureContentStore({
        enableExercises: true,
        enableSharing: true,
        enableFavourites: true,
        enableCollections: true,
        enableLearningPath: true,
        enableHistory: true,
        commonFolderId: isOfiEditor ? null : undefined,
        extraComponents
    });

    configureBookPage({
        footer: <Footer />
    });

    configureCms({
        siteId: 1
    });

    configureProfile({
        showCourseRegistrationData: false,
        showInstituteSiteEditor: false,
        showInstituteGroupInvitations: true,
        allowInstituteSiteTeacherSelfRegister: true,
    })

    ///Modules init
    ContentStoreModule.initGlobal({
        enabledMainFiles: true,
       
        enableExercises:false,
        //enableExercises: await permissionCache.hasPermission({crud: {permissions: CrudPermissionType.U, tableInfoId: ExerciseCrud.TABLE_INFO_ID}}),
        enableSharing: true,
        enableFavourites: true,
        enableCollections: true,
        enableLearningPath: true,//(me! && hasGroup(me!, Groups.Developer)),
        enableCommonFolder: isOfiEditor ? true : false,
        enableHistory: true,
        commonFolderId: isOfiEditor ? null : undefined,
        extraComponents
    });

    ClassroomModule.initGlobal({});
    NotifierModule.initGlobal({});
    MessengerModule.initGlobal({});
    
    DeveloperModule.initGlobal({});

    TranslationModule.initGlobal({});

    ExerciseModule.initGlobal({        
        enableExercises: await permissionCache.hasPermission({crud: {permissions: CrudPermissionType.U, tableInfoId: ExerciseCrud.TABLE_INFO_ID}}),     
        enableOfficialExercises: true,   
        enableLearningPath: true,
        enableExercisesColleciton: true,
        enableFavouriteExercises: true,
        enableExerciseInBook:true,
        enableOwnExercises: true
    });


    SearchModule.initGlobal<BaseMenu, SearchResult, Server.SearchParameters>({
        filterTypes: [
            { elasticType: "", name: __("Minden"), iconClassName: "nkp-icon-filter-all" },
            { elasticType: ElasticContentType.Text, name: __("Szöveg"), iconClassName: "nkp-icon-filter-text" },
            // { elasticType: ElasticContentType.Image, name: __("Kép"), iconClassName: "nkp-icon-filter-photo" },
            { elasticType: ElasticContentType.Video, name: __("Videó"), iconClassName: "nkp-icon-filter-video" },
            { elasticType: ElasticContentType.Audio, name: __("Hang"), iconClassName: "nkp-icon-filter-audio" },
            { elasticType: ElasticContentType.AllExercise, name: __("Okosfeladat"), iconClassName: "nkp-icon-filter-lesson" }
        ]
    });

    //

    ReactDOM.render(<Router history={history}><OTK termsOfUseAcceptNeeded={acceptNeeded} showSurvey={showSurvey}/></Router>, rootElement)
}

