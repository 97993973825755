import * as React from 'react';
import { observer } from 'mobx-react';
import { TopicListStore, ForumContainerStore, forumStore, MemberListStore } from '../forumStore';
import { __ } from '@src/translation';
import { computed } from 'mobx';


export interface IForumTopics {
    storeId: string;
    showTopics?: boolean; /* Az alapértelmezés hogy akkor látszódjon, ha nincs default! */
}

/**
 * Ez a komponens az aktuálisan kiválasztott fórum topic-jait jeleníti meg.
 */
@observer
export default class ForumTopics extends React.Component<IForumTopics, {}>{
    public get containerStore(): ForumContainerStore {
        return forumStore.getContainerStore(this.props.storeId);
    }

    public get forumId(): number | null {
        return this.containerStore.forumId;
    }


    public get topicStore(): TopicListStore | null {
        if (this.forumId === null) {
            return null;
        } else {
            return forumStore.getTopicListStore(this.forumId);
        }
    }


    private get memberStore(): MemberListStore | null {
        if (this.forumId === null) {
            return null
        } else {
            return forumStore.getMemberListStore(this.forumId);
        }
    }


    /** Tudom-e módosítani ezt az üzenetet? */
    @computed private get canModify() : boolean {
        const memberStore  = this.memberStore;
        return memberStore?memberStore.canModifyTopic:false;
    }

    /** Tudom-e törölni ezt az üzenetet? */
    @computed private get canDelete() : boolean {
        const memberStore  = this.memberStore;
        return memberStore?memberStore.canDeleteTopic:false;
    }

    private deleteTopic = () => {
        // this.containerStore.deleteTopic();???
        alert('TODO');
    }

    private editTopic = () => {
        alert('TODO');
    }    

    constructor(props: IForumTopics) {
        super(props);
        this.state = {};
    }

    public componentDidMount() {
        const topicStore = this.topicStore;
        if (topicStore != null) {
            if (!topicStore.loaded) {
                topicStore.reload();
            }
        }
    }

    render() {
        const forumId = this.forumId;
        if (forumId == null) {
            // Ilyen nem nagyon lesz...
            return <div>Nincs kiválasztva fórum.</div>;
        }
        const forum = this.containerStore.forum!;
        let showTopics: boolean;
        if (this.props.showTopics === undefined) {
            showTopics = !!forum.default_topic_id;
        } else {
            showTopics = this.props.showTopics;
        }
        if (!showTopics) {
            return <p className="callout secondary">{__("Ebben a fórumban nincsenek engedélyezve a topic-ok.")}</p>
        }
        const topicStore = this.topicStore!;
        return <div className="forum-topics">
            <div className="header">
                <h4>Téma lista</h4>
                <div className="actions">
                    <button className=""
                        onClick={() => topicStore.reload()}
                        disabled={topicStore.loading}
                        title={topicStore.loading ? __("Betöltés...") : ("Téma lista frissítése")}
                    >
                        <i className= {"fas fa-sync-alt post-refresh-btn" + (topicStore.loading?" sync":"")} />
                    </button>
                    <button className="" title={__("Új téma hozzáadása")}
                        disabled={topicStore.loading}>
                        <i className="fa fa-edit" />                             
                    </button>
                </div>
            </div>

            <ul className="list">
                {topicStore.topics.map((topic, index) =>
                    <li key={topic.id || index}>
                        <button className={(topic.id === this.containerStore.topicId ? "active" : "")}
                            onClick={() => this.containerStore.changeTopic(topic.id!)}
                        >
                            {topic.title}
                        </button>
                        {
                            this.canModify
                            ?
                            <button className="button tiny alert"
                                onClick={this.editTopic}
                                disabled={!this.canModify}
                            >
                                <i className="fa fa-edit" />
                            </button>
                            : null
                        }
                        {
                            this.canDelete
                            ?
                            <button className="button tiny alert"
                                onClick={this.deleteTopic}
                                disabled={!this.canDelete}
                            ><i className="fa fa-trash" />
                            </button>
                            : null
                        }

                    </li>
                )}
            </ul>
        </div>
    }

}