import * as React from "react";
import './LearningPath.css';
import { Tabs, TabList, Tab, TabPanel } from "react-tabs";
import SplitPane from "react-split-pane";
import { ILpLearningPathRecord } from "@src/framework/crud/exc/LpLearningPathCrud";
import { ILpNodeRecord, LpNodeCrudClassProxy } from "@src/framework/crud/exc/LpNodeCrud";
import { me, hasAnyGroup, Groups } from "@src/framework/server/Auth";
import CrudSelect from '@framework/forms//crudselect';
import { subjectCrudClassProxy } from '@framework/crud/doc/SubjectCrud';
import { gradeCrudClassProxy } from '@framework/crud/doc/GradeCrud';
import LevelSelectComponent from '@src/framework/forms/level_select';
import AccessDeniedPage from "@src/framework/pages/AccessDeniedPage";
import { EditorPage, EditorSidebar, EditorPanel, EditorSplit, Panel } from "../ui/Panel";
import ButtonGroup, { ButtonSize, ButtonAlign } from "../ui/ButtonGroup";
import ReactResizeDetector from "react-resize-detector";
import { Z_INDEX_EDITOR_STICKY, LIBRARY_OFI_OFFICIAL_ID, LIBRARY_PERSONAL_ID } from '@src/Const';
import StickyBox from "react-sticky-box";
import { __ } from "@src/translation";
import { Link } from "react-router-dom";
import { history } from '@src/index';
import { HTMLTextarea } from "../HtmlTextarea";
import { List } from "../ui/List";
import { IGeneralItem, CollectionItemComponent } from "../collection/CollectionItemComponent";
import { getContentDetails } from "../filemanager/ContentUtils";
import { app } from '@src/index';
import { Dialog, confirmDialog } from "../Dialog";
import ExerciseList from "../exercise/Viewer/ExerciseList";
import ExerciseSeriesList from "../exercise/ExerciseSeries/ExerciseSeriesList";
import ExerciseCrud from "@src/framework/crud/exc/ExerciseCrud";
import ExerciseSeriesCrud from "@src/framework/crud/exc/ExerciseSeriesCrud";
import MediaFileChooser, { ALL_ACTIONS } from "../filemanager/MediaFileChooser";
import OoFileCrud from "@src/framework/crud/media/OoFileCrud";
import LpConditionCrud, { ILpConditionRecord } from "@src/framework/crud/exc/LpConditionCrud";
import { element } from "prop-types";
import LearningPathAPI, { ILearningPath } from "./LearningPathAPI";
import { ILpEdgeRecord } from "@src/framework/crud/exc/LpEdgeCrud";
import { PATH_LEARNING_PATH_EDIT } from "@src/Routes";
import { excDisplayModeCrudClassProxy } from "@src/framework/crud/exc/ExcDisplayModeCrud";
import { lpForwardTypeCrudClassProxy } from "@src/framework/crud/exc/LpForwardTypeCrud";
import LpBookList from "../book/viewer/LpBookList";
import ChapterCrud from "@src/framework/crud/doc/ChapterCrud";
import LessonCrud from "@src/framework/crud/doc/LessonCrud";
import { LPConditionView, ConditionLogicalRelation, TempLpConditionRecord } from "./LpConditionView";
import { LearningPathBreadcrumbs } from "../Breadcrumbs";

/**
 * The item list contains the nodes as IGeneralItems. 
 * In the item.id, we store the id of the node if it has any from the db. 
 * the item.no is an index we create to subsitute the node id in the editing process.
 * This is an incremental number, this will be put in the this.state.nodeList, as the id of the node. 
 * This will be the from/to id in the edge recods as well.
 * It will be replaced by real id-s when these are saved in the db.
 */
export type LearningPathState = {
    learninPathRecord: ILpLearningPathRecord,
    nodeList: ILpNodeRecord[],
    edgeList: ILpEdgeRecord[],
    conditionList: ILpConditionRecord[],
    edgeConditions: TempLpConditionRecord[],

    itemList: IGeneralItem[],

    selectedNode?: ILpNodeRecord;
    pathsToSelectedNode?: ILpNodeRecord[];
    selectedEdges?: ILpEdgeRecord[];
    selectedConditions?: ILpConditionRecord[];

    actgraph?: any;
    physics?: boolean;
    nodeCoords?: any[];
    network?: any;

    isDataChanged: boolean,
    openedDialogs: any,
    currentFolderId: number
}


/*
play restrictions:
- egy nódra vonatkozik. 
- több megelőző (szülő) nódra vonatkoztatott feltételt lehet megadni
- szigorítani, hogy kitöltéskor csak szülő node-ot lehessen beállítani feltételbe.
*/


export class LearningPathEditorComponent extends React.Component<any, LearningPathState> {

    private Graph: any;
    private _isMounted: boolean = false;
    private _description: string = "";


    constructor(props: any) {
        super(props);

        this.state = {
            learninPathRecord: { title: "", description: "<p></p>" },
            isDataChanged: false,
            itemList: [],
            nodeList: [],
            edgeList: [],
            conditionList: [],
            edgeConditions: [],
            openedDialogs: {
                exerciseSelector: false,
                exerciseSeriesSelector: false,
                videoSelector: false,
                bookSelector: false,
                sectionSelector: false,
                nodeSettings: false
            },
            currentFolderId: 0
        };
        this.reloadAsync();
    }

    componentDidMount() {
        console.log("componentDidMount");
        this._isMounted = true;
    }

    componentWillUnmount() {
        console.log("componentWillUnmount");
        this._isMounted = false;
    }

    protected reloadGraph() {
        console.log("reloadGraph", this.state.nodeList, this.state.edgeList);
        //let coords = this.state.network ? this.getGraphCoords(this.state.network) : [];
        this.setState({ actgraph: this.calcGraph(this.state.nodeList!, this.state.edgeList!, []) });
        //this.reloadAsync();
    }

    async reload() {
        console.log("reload");
        this.Graph = (await import("react-graph-vis")).default;
        //this.reloadGraph();
    }

    async reloadAsync() {
        console.log("reloadAsync");
        //setTimeout(() => {
        this.reload();
        //}, 100000); 
        if (this.props.match.params.learningPathId) this.reloadLearningPathAsync();
    }

    protected getNetwork(network: any) {
        this.setState({ network: network });
    }

    componentDidUpdate(prevProps: any, prevState: LearningPathState) {
        ($("#lp_base_data_div") as any).foundation();
    }

    async onSave(saveAsNew: boolean) {
        if (saveAsNew) {
            if (!await confirmDialog(__("Megerősítés"), __("Biztos, hogy másolatként szeretné menteni az útvonalat?"))) return;
        }

        try {
            if (!this.state.learninPathRecord.title) {
                app.showError(__("Hiányzó adat!"), __("Kérem adja meg az útvonal címét!"));
                return;
            }
            if (!this.state.itemList || this.state.itemList.length < 2) {
                app.showError(__("Hiányzó adat!"), __("Kérem adjon meg legalább két elemet!"));
                return;
            }

            if (!this.state.learninPathRecord.start_node_id) {
                app.showError(__("Hiányzó adat!"), __("Select start node!"));
                return;
            }

            if (!this.isGraphConnected()) {
                app.showError(__("Hiányzó adat!"), __("Not all nodes are connected!"));
                return;
            }

            console.log("learningPathstate:", this.state);
            var exerciseToSave: ILearningPath = {
                ...this.state.learninPathRecord,
                nodes: [],
                edges: [],
                conditions: [],
                library_id: LIBRARY_PERSONAL_ID
            }

            console.log("item list", this.state.itemList, this.state.nodeList);
            let curr_item: IGeneralItem;
            for (let index = 0; index < this.state.itemList.length; index++) {
                curr_item = this.state.itemList[index];
                let curr_node: ILpNodeRecord | undefined = this.getNodeById(curr_item.no!);
                if (curr_node) {
                    curr_node.id = this.getRealIdONode(curr_item.no!, saveAsNew);
                    if (saveAsNew) {
                        curr_node.lp_learning_path_id = exerciseToSave.id;
                    }
                    /*if (this.getEdgesOfNode(curr_item.no!, false).length < 1) curr_node.is_terminal = true;
                    else curr_node.is_terminal = false;*/
                    //curr_node.exc_display_mode_id = 2;//todo: set it on the UI
                    //curr_node.lp_forward_type_id? = 2
                    if (exerciseToSave.start_node_id == curr_item.no) exerciseToSave.start_node_id = this.getRealIdONode(curr_item.no!, saveAsNew);
                    exerciseToSave.nodes.push(curr_node);
                    console.log("putting node", curr_node);
                }
            }

            console.log("newNode list", exerciseToSave.nodes);
            if (!exerciseToSave.start_node_id) {
                exerciseToSave.start_node_id = exerciseToSave.nodes[0].id; // todo: remove this if the correct selection is done
            }

            let from_id: number, to_id: number, new_from: number, new_to: number;
            for (let j = 0; j < this.state.edgeList.length; j++) {

                from_id = this.state.edgeList[j].from_node_id!;
                to_id = this.state.edgeList[j].to_node_id!;

                curr_item = this.state.itemList.find(item => { return item.no == from_id })!;
                new_from = this.getRealIdONode(curr_item.no!, saveAsNew);

                curr_item = this.state.itemList.find(item => { return item.no == to_id })!;
                new_to = this.getRealIdONode(curr_item.no!, saveAsNew);

                exerciseToSave.edges.push({ id: -(j + 1), from_node_id: new_from, to_node_id: new_to });

                let foundCondition: TempLpConditionRecord | undefined = this.state.edgeConditions.find((condtion:TempLpConditionRecord) => condtion.lp_edge.from_node_id == this.state.edgeList[j].from_node_id && condtion.lp_edge.to_node_id == this.state.edgeList[j].to_node_id);
                let conditionIndex = 0;
                if (foundCondition) {
                    exerciseToSave.conditions.push({ id: -(conditionIndex + 1), lp_edge_id: -(j + 1), condition: foundCondition.condition });
                    conditionIndex += 1;
                }
            }

            delete exerciseToSave["is_active"];
            exerciseToSave.creation_user_id = undefined;

            if (saveAsNew) {
                exerciseToSave.id = undefined;
            }


            const new_learningPath = await LearningPathAPI.save(exerciseToSave);
            let tempurl = PATH_LEARNING_PATH_EDIT + "/" + new_learningPath.id + "/";
            history.replace(tempurl);

            if (!saveAsNew) {
                app.showSuccess(__("Útvonal elmentve"), __("Útvonal sikeresen elmentve"));
            } else {

                app.showSuccess(__("Útvonal másolatként elmentve"), __("Útvonal sikeresen elmentve másolatként"));
            }

        } catch (e) {
            app.showErrorFromJsonResult(e);
            app.showError(__("Nem sikerült lementeni az útvonalat!"), e);
            console.log(e);
        }
    }

    async reloadLearningPathAsync() {
        console.log("reloadLearningPathAsync", this.props);
        try {
            let itemList: IGeneralItem[] = [];
            let nodeList: ILpNodeRecord[] = [];
            let edgeList: ILpEdgeRecord[] = [];
            let conditionList: ILpConditionRecord[] = [];
            let edgeConditions: TempLpConditionRecord[] = [];
            let learningPath: ILearningPath = { nodes: [], edges: [], conditions: [] };

            learningPath = await LearningPathAPI.load(this.props.match.params.learningPathId);

            if (!learningPath) {
                app.showError(__("Nem sikerült betölteni az útvonalat"), __("Hibás azonosító"));
                return;
            }

            // todo: condition list//
            let startNodeId = learningPath.start_node_id;

            let tmpId = 1;
            learningPath.nodes.forEach(curr_node => {
                let new_gi: IGeneralItem = { id: curr_node.id, no: tmpId, rec_id: curr_node.record_id, table_info_id: curr_node.table_info_id };
                itemList.push(new_gi);
                let newNode: ILpNodeRecord = { ...curr_node, id: tmpId };
                nodeList.push(newNode);
                if (startNodeId == curr_node.id) learningPath.start_node_id = tmpId;
                tmpId++;
            });

            let fromId = 0, toId = 0;
            learningPath.edges.forEach(curr_edge => {
                let tmp = itemList.find((element => { return element.id == curr_edge.from_node_id }));
                if (tmp) fromId = tmp.no!;
                tmp = itemList.find((element => { return element.id == curr_edge.to_node_id }));
                if (tmp) toId = tmp.no!;
                let new_edge: ILpEdgeRecord = { id: curr_edge.id, from_node_id: fromId, to_node_id: toId };

                /*let foundCondition: ILpConditionRecord | undefined = learningPath.conditions.find((curr_cond: ILpConditionRecord) => curr_cond.lp_edge_id == new_edge.id);
                if (foundCondition) {
                    let tempEdgeCondition: TempLpConditionRecord = { condition: foundCondition.condition, lp_edge: new_edge };
                    edgeConditions.push(tempEdgeCondition);
                }*/

                if (curr_edge.conditions && curr_edge.conditions.length > 0) {
                    let tempEdgeCondition: TempLpConditionRecord = { condition: JSON.stringify(curr_edge.conditions[0].condition), lp_edge: new_edge };
                    edgeConditions.push(tempEdgeCondition);
                }

                edgeList.push(new_edge);
                tmpId++;
            });


            if (this._isMounted) {
                this.setState({
                    learninPathRecord: learningPath,
                    edgeList,
                    conditionList,
                    edgeConditions,
                    itemList,
                    nodeList
                }, () => {
                    // this.state.itemList.forEach(item => this.loadDetailsOfItem(item, false));
                    this.reloadGraph();
                });
            }

        } catch (e) {
            app.showErrorFromJsonResult(e);
            app.showError(__("Nem sikerült betölteni a útvonalat!"), e);
            console.log(e);
        }
    }

    private addNewNode(rec_id: number, table_info_id: number) {
        if (!this.state.itemList.find((element) => { if (element.rec_id == rec_id) return true; else return false; })) {
            let tmpL = this.state.itemList;
            //let tmpNL = this.state.nodeList;
            let newNo: number = 1;
            if (tmpL.length > 0) newNo = (tmpL[tmpL.length - 1].no!) + 1;
            let newGI: IGeneralItem = { rec_id: rec_id, table_info_id: table_info_id, no: newNo };
            tmpL.push(newGI);

            /*let lpRec = this.state.learninPathRecord;
            if (tmpL.length == 1) lpRec.start_node_id = newGI.no;*/
            this.setState({ isDataChanged: true, itemList: tmpL }, () => this.loadDetailsOfItem(newGI));
        }
    }

    onDeleteNode(id: number) {
        let tempNodeList = this.state.nodeList;
        let tempItemList = this.state.itemList;
        let tempEdgeList = this.state.edgeList;

        let nodeIndexToDelete = tempNodeList.findIndex(item => item.id == id);
        tempNodeList.splice(nodeIndexToDelete, 1);

        let itemIndexToDelete = tempItemList.findIndex(item => item.no == id);
        tempItemList.splice(itemIndexToDelete, 1);

        for (let i = 0; i < tempEdgeList.length; i++) {
            let currEdge = tempEdgeList[i];
            if (currEdge.from_node_id == id || currEdge.to_node_id == id)
                tempEdgeList.splice(i, 1);
        }

        this.setState({
            nodeList: tempNodeList,
            itemList: tempItemList,
            edgeList: tempEdgeList
        }, () => this.reloadGraph());
    }

    onEditNode(id: number) {
        let nodeToEdit = this.getNodeById(id);
        console.log("nodeToEdit", nodeToEdit);
        if (!nodeToEdit) return;

        // get edges to edit
        let edgeList = this.state.edgeList.filter((element) => { return element.from_node_id == id });
        //let conditionList = this.state.conditionList.filter();

        let pathsToNode: ILpNodeRecord[] = this.getPathsToNode(id);

        this.setState({
            selectedNode: nodeToEdit,
            selectedEdges: edgeList,
            pathsToSelectedNode: pathsToNode,
            //selectedConditions: conditionList,
            openedDialogs: { ...this.state.openedDialogs, nodeSettings: true }
        });

    }

    private onNodeChanged() {
        let newNodeList: any[] = JSON.parse(JSON.stringify(this.state.nodeList)); 
        if (this.state.selectedNode && this.state.nodeList.length > 0) {
            let changeAtId = this.state.selectedNode.id;
            let toBeChangedIndex: any = this.state.nodeList.findIndex((el) => {
                return el.id == changeAtId;
            });
            newNodeList[toBeChangedIndex] = this.state.selectedNode;
        }
        this.setState({
            openedDialogs: { ...this.state.openedDialogs, nodeSettings: false },
            nodeList: newNodeList
        });

    }
    private onDeleteEdge(id: number) {
        let tempEdgeList = this.state.edgeList;
        let tempSelectedEdgeList = this.state.selectedEdges!;

        let curr_edge_idx = tempEdgeList.findIndex((item: { id: number; label: string; }) => item.id == id);
        if (curr_edge_idx > -1)
            tempEdgeList.splice(curr_edge_idx, 1);

        curr_edge_idx = tempSelectedEdgeList.findIndex((item: { id: number; label: string; }) => item.id == id);
        if (curr_edge_idx > -1)
            tempSelectedEdgeList.splice(curr_edge_idx, 1);

        this.setState({
            edgeList: tempEdgeList,
            selectedEdges: tempSelectedEdgeList
        }, () => this.reloadGraph());
    }

    private addNewEdge() {
        let fromNodeId = this.state.selectedNode!.id;
        let toNodeId = Number((this.refs.node_selector as HTMLSelectElement).value);
        console.log("adding new edge", fromNodeId, toNodeId);
        let newTmpId = -1;
        let newEdgeList = this.state.edgeList;
        if (newEdgeList.length > 0) newTmpId = -(Math.abs(newEdgeList[newEdgeList.length - 1].to_node_id! + 1));
        let newEdge = { id: newTmpId, from_node_id: fromNodeId, to_node_id: toNodeId };
        newEdgeList.push(newEdge);
        let newSelectedEdges = this.state.selectedEdges;
        if (newSelectedEdges) newSelectedEdges.push(newEdge);
        this.setState({
            isDataChanged: true,
            edgeList: newEdgeList,
            selectedEdges: newSelectedEdges
        }, () => this.reloadGraph());

    }

    private getPathsToNode(nodeId: number) : ILpNodeRecord[] {
        let pathList: any[] = [];

        let isVisitedMap = new Map();
        this.state.itemList.forEach((item: IGeneralItem) => {
            isVisitedMap.set(Math.abs(item.no!), false);
        });

        let edgeMap:Map<number, any[]> = new Map();
        this.state.edgeList.forEach((edge: ILpEdgeRecord) => {
            if (!edgeMap.has(edge.from_node_id!)) {
                let values: any[] = [];
                values.push(edge.to_node_id!);
                edgeMap.set(edge.from_node_id!, values);
            }
            else 
                edgeMap.get(edge.from_node_id!)!.push(edge.to_node_id!);
        });

        let startNodeId = this.state.learninPathRecord.start_node_id!;

        pathList.push(this.state.nodeList.find((node) => {
            return Math.abs(node.id!) == Math.abs(startNodeId);
        }));
        let newPathList: any[] = [];
        this.findPaths(Math.abs(nodeId), Math.abs(startNodeId), isVisitedMap, pathList, edgeMap, newPathList);
        return newPathList;
    }

    private findPaths(toNodeId: number ,fromNodeId: number, isVisitedMap: Map<number, boolean>, pathList: any[], edgeMap: Map<number, any[]>, allPaths: any[]) {
        isVisitedMap.set(fromNodeId, true);
        if (fromNodeId == toNodeId) {
            isVisitedMap.set(fromNodeId, false);
            allPaths.push(JSON.parse(JSON.stringify(pathList)));
            return;
        }
        let currEdgeList = edgeMap.get(fromNodeId);
        if (!currEdgeList) return;

        currEdgeList.forEach((elem: number) => {
            let currNodeId = Math.abs(elem);
            if (!isVisitedMap.get(currNodeId)) {
                let foundNodeAtIndex = this.state.nodeList.find((node) => {
                    return Math.abs(node.id!) == currNodeId;
                });
                pathList.push(foundNodeAtIndex);
                this.findPaths(toNodeId, currNodeId, isVisitedMap, pathList, edgeMap, allPaths);
                pathList.splice(pathList.indexOf(foundNodeAtIndex), 1);
            }
        }); 
        isVisitedMap.set(fromNodeId, false);
    }

    async loadDetailsOfItem(curr_item: IGeneralItem) {
        //let collectionItemList: IGeneralItem[] = [];
        let tmpNodeList: ILpNodeRecord[] = this.state.nodeList;
        let tmpEdgeList: ILpEdgeRecord[] = this.state.edgeList;
        //let curr_item: IGeneralItem = {};
        //  for (let index = 0; index < this.state.itemList.length; index++) {
        //curr_item = this.state.itemList[index];
        let details = await getContentDetails(curr_item.table_info_id!, curr_item.rec_id!);
        let title: string = "";

        if (details) {
            if (!details.displayName) {
                title = details.type + " " + curr_item.no!.toString();
            }
            else {
                title = details.displayName;
            }
        }


        let node: ILpNodeRecord = {
            record_id: curr_item.rec_id,
            table_info_id: curr_item.table_info_id,
            title: title,
            lp_forward_type_id: 2,
            exc_display_mode_id: 2,
            id: curr_item.no!
        };
        tmpNodeList.push(node);

        if (tmpNodeList.length > 1) {
            let edge: ILpEdgeRecord = { from_node_id: tmpNodeList[tmpNodeList.length - 2].id, to_node_id: node.id };
            tmpEdgeList.push(edge);
        }
        this.setState({
            nodeList: tmpNodeList,
            edgeList: tmpEdgeList
        }, () => this.reloadGraph());

    }

    private getNodeSelector(): any {
        let curr_edgeList = this.state.selectedEdges;
        let childNodes: ILpNodeRecord[] = [];
        console.log("get node list");
        this.state.nodeList.forEach(element => {
            if (curr_edgeList && this.state.selectedNode) {

                if (element.id != this.state.selectedNode.id) {
                    let edgeFound = curr_edgeList.find((el) => { return (el.to_node_id == element.id) })
                    if (edgeFound == undefined) childNodes.push(element);
                }
            }
        });
        console.log("childnode list", childNodes);
        return <select id="node_selector" ref="node_selector">
            {
                childNodes.map((element) => {
                    return <option value={element.id}>{element.title}</option>;
                })
            }
        </select>;
    }


    onCreateNewLP() {
        console.log("onCreateNewLP");
        this.setState({
            learninPathRecord: { title: "", description: "<p></p>" },
            itemList: [],
            nodeList: [],
            edgeList: [],
            isDataChanged: true
        }, () => this.reloadGraph());
    }


    handleInputChange(event: any) {
        const target = event.target;
        var value = target.value;
        if (target && target.type === 'checkbox') {
            value = target.checked;
        } else if (target && (target.type == 'select-one' || target.getAttribute("data-type") == "number")) {
            value = Number(target.value);
        }

        let name = target.name;
        let propertNames = name.split(".");
        if (propertNames.length > 1) {
            if (propertNames[0] == "learninPathRecord") {
                if (this.state.learninPathRecord[propertNames[1]] != value) {
                    this.setState({
                        learninPathRecord: { ...this.state.learninPathRecord, [propertNames[1]]: value },
                        isDataChanged: true,
                    });
                }
            }
        }
    }

    handleDescriptionChange(value?: string) {
        if (value) {
            this._description = value;
        } else if (this._description) {
            if (this._isMounted) this.setState({ learninPathRecord: { ...this.state.learninPathRecord, description: this._description }, isDataChanged: true });
        }
    }

    handleStartNodeChange(selectedNodeId: number) {
        let selectedNode = this.getNodeById(selectedNodeId);
        if (selectedNode) {
            this.setState({ 
                learninPathRecord: {...this.state.learninPathRecord, start_node_id:selectedNodeId}
            },() => this.reloadGraph());
        }
    }

    private onGradeSelect = (sender: CrudSelect, newGradeId: number | null): void => {
        if (newGradeId)
            this.setState({
                learninPathRecord: { ...this.state.learninPathRecord, grade_id: newGradeId },
                isDataChanged: true
            });
    }
    private onSubjectSelect = (sender: CrudSelect, newSubjectId: number | null): void => {
        this.setState({
            learninPathRecord: { ...this.state.learninPathRecord, subject_id: newSubjectId },
            isDataChanged: true
        });
    }

    private onForwardTypeSelect = (sender: CrudSelect, newId: number | null): void => {
        this.setState({
            selectedNode: { ...this.state.selectedNode, lp_forward_type_id: newId ? newId : undefined },
            isDataChanged: true
        });
    }


    onOpenExerciseDialog() {
        this.setState({ openedDialogs: { ...this.state.openedDialogs, exerciseSelector: !this.state.openedDialogs.exerciseSelector } });
    }

    private onExerciseSelected(exercise: any) {
        this.addNewNode(exercise.id, ExerciseCrud.TABLE_INFO_ID);
    }

    onOpenExerciseSeriesDialog() {
        this.setState({ openedDialogs: { ...this.state.openedDialogs, exerciseSeriesSelector: !this.state.openedDialogs.exerciseSeriesSelector } });
    }

    onOpenBookSelectorDialog() {
        this.setState({ openedDialogs: { ...this.state.openedDialogs, bookSelector: !this.state.openedDialogs.bookSelector } });
    }

    onBookSelected(book: any) {
        this.setState({ openedDialogs: { ...this.state.openedDialogs, selectedBook: book, sectionSelector: !this.state.openedDialogs.sectionSelector }})
    }

    onChapterSelected(chapter: any) {
        this.addNewNode(chapter.id, ChapterCrud.TABLE_INFO_ID);
    }

    onLessonSelected(lesson: any) {
        this.addNewNode(lesson.id, LessonCrud.TABLE_INFO_ID);
    }

    private onExerciseSeriesSelected(exSeries: any) {
        this.addNewNode(exSeries.id, ExerciseSeriesCrud.TABLE_INFO_ID);
    }

    onOpenVideoDialog() {
        this.setState({ openedDialogs: { ...this.state.openedDialogs, videoSelector: !this.state.openedDialogs.videoSelector } });
    }

    private onVideoSelected(item: any) {
        this.addNewNode(item, OoFileCrud.TABLE_INFO_ID);
    }



    render() {

        if (!this.state.learninPathRecord || !me ||
            (this.state.learninPathRecord.creation_user_id && this.state.learninPathRecord.creation_user_id != me.id)) {
            return AccessDeniedPage();
        }

        return (
            <EditorPage>
                <div className="editor-bar">
                    <LearningPathBreadcrumbs links={[
                        { name: __("Editing") },
                    ]} />
                </div>
                <EditorSplit>

                    <EditorSidebar width="50%">

                        <div className="exercise-editor-wrapper" ref="editor">
                            <h4>{__("Tanulási útvonal szerkesztése")}</h4>
                            <ReactResizeDetector handleWidth handleHeight>

                                {(width: any, height: any) =>

                                    <StickyBox offsetTop={0} style={{ zIndex: Z_INDEX_EDITOR_STICKY }}>
                                        <div className="columns large-12" style={{ alignSelf: "center", backgroundColor: "#fff" }}>
                                            <ButtonGroup size={ButtonSize.Normal} align={ButtonAlign.Expanded}>


                                                <button className="button" onClick={this.onCreateNewLP.bind(this)}>
                                                    <i className="fa fa-plus" /> {__("Új létrehozása")}
                                                </button>

                                                {
                                                    (this.state.learninPathRecord.id && this.state.learninPathRecord.owner_id != me!.id) ?
                                                        <button className="button success" disabled={true}>
                                                            <i className="fa fa-save" />  {__("Nincs joga menteni")}</button>
                                                        :
                                                        <button className="button success" onClick={this.onSave.bind(this, false)} disabled={(!this.state.isDataChanged)}>
                                                            <i className="fa fa-save" /> {this.state.learninPathRecord.id ? __("Mentés") : __("Mentés újként")}
                                                        </button>
                                                }
                                                <button key="new_copy" className="button success" onClick={this.onSave.bind(this, true)}>
                                                    <i className="fa fa-copy" /> {__("Mentés másolatként")}
                                                </button>
                                            </ButtonGroup>

                                        </div>
                                    </StickyBox>

                                }
                            </ReactResizeDetector>

                            <div className="columns" id="lp_base_data_div">
                                <ul className="accordion" data-accordion data-multi-expand="true">
                                    <li className="accordion-item is-active" data-accordion-item>
                                        <Link to="#" className="accordion-title lp-editor-accordion-title"><i className="accordion-icon"></i>{__("Alapadatok")}</Link>
                                        <div className="accordion-content" data-tab-content >
                                            <div className="row">
                                                <div className="medium-6 column">
                                                    <CrudSelect
                                                        value={this.state.learninPathRecord.grade_id ? Number(this.state.learninPathRecord.grade_id) : null}
                                                        onSelect={this.onGradeSelect}
                                                        displayFieldName="name"
                                                        orderByFieldName="id"
                                                        key="id"
                                                        emptyTitle={__("Osztály")}
                                                        clearable={true}
                                                        crudClassProxy={gradeCrudClassProxy}
                                                        filter={{ is_active: true }}
                                                        sortFunc={(a, b) => a.name.localeCompare(b.name, undefined, { numeric: true, sensitivity: 'base' })}
                                                    />
                                                </div>
                                                <div className="medium-6 column">
                                                    <CrudSelect
                                                        value={this.state.learninPathRecord.subject_id ? Number(this.state.learninPathRecord.subject_id) : null}
                                                        onSelect={this.onSubjectSelect}
                                                        displayFieldName="name"
                                                        key="id"
                                                        emptyTitle={__("Évfolyam")}
                                                        clearable={true}
                                                        crudClassProxy={subjectCrudClassProxy}
                                                        filter={{ is_active: true }}
                                                    />
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="large-12 columns exe-editor-inputgroup">
                                                    <label>{__("Útvonal címe")} * </label>
                                                    <input type="text" name="learninPathRecord.title"
                                                        value={this.state.learninPathRecord!.title || ""}
                                                        onChange={this.handleInputChange.bind(this)} />
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="large-12 columns exe-editor-inputgroup">
                                                    <label>{__("Útvonal leírása")}</label>
                                                    {this.state.learninPathRecord.description && this._isMounted ?
                                                        <HTMLTextarea
                                                            value={this.state.learninPathRecord.description ? this.state.learninPathRecord.description : "<p></p>"}
                                                            onChange={this.handleDescriptionChange.bind(this)}
                                                            onBlur={this.handleDescriptionChange.bind(this, null)}
                                                        />
                                                        :
                                                        ""
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </li>

                                    <li className="accordion-item" data-accordion-item>
                                        <Link to="#" className="accordion-title lp-editor-accordion-title"><i className="accordion-icon"></i>{__("Elemek")}</Link>
                                        <div className="accordion-content" data-tab-content >
                                            <div className="row">
                                                <div className="columns large-12">
                                                    <List >
                                                        {
                                                            this.state.itemList.map((item, index) => {
                                                                return <CollectionItemComponent dragIndex={undefined}
                                                                    key={item.id}
                                                                    item={item}
                                                                    showUserPlugin={false}
                                                                    onReload={this.reloadGraph.bind(this)}
                                                                    onDelete={this.onDeleteNode.bind(this, item.no)}
                                                                    onEdit={this.onEditNode.bind(this, item.no)} />;
                                                            }
                                                            )
                                                        }
                                                    </List>
                                                </div>

                                                {this.state.openedDialogs.nodeSettings && this.state.selectedNode
                                                    ?
                                                    <Dialog title={__("Csomópont szerkesztése")} onClose={this.onNodeChanged.bind(this)}>
                                                        <div className="row expanded">
                                                            <div className="columns large-12">

                                                                <div className="row">
                                                                    <label className="lp-field-label">{this.state.selectedNode.title}</label>
                                                                </div>

                                                                <div className="row">
                                                                    <div className="large-12 columns">
                                                                        <label>{__("Kezdő csomópont")}
                                                                            <input type="checkbox" name="startnodeId" id="startnodeId"
                                                                                checked={this.state.learninPathRecord.start_node_id == Math.abs(this.state.selectedNode.id!)}
                                                                                onChange={this.handleStartNodeChange.bind(this, this.state.selectedNode.id)} />
                                                                        </label>
                                                                    </div>

                                                                    <div className="large-12 columns">
                                                                        <label>{__("Lejátszás maximális száma:")}
                                                                            <input type="number" name="selectedNode.max_display_count" id="selectedNode.max_display_count"
                                                                                value={this.state.selectedNode.max_display_count || 1}
                                                                                onChange={this.handleInputChange.bind(this)} />
                                                                        </label>
                                                                    </div>


                                                                    <div className="large-12 columns">
                                                                        <label>{__("Lejátszás módja:")}
                                                                            <CrudSelect
                                                                                value={this.state.selectedNode.exc_display_mode_id ? Number(this.state.selectedNode.exc_display_mode_id) : null}
                                                                                onSelect={this.onGradeSelect.bind(this)}
                                                                                displayFieldName="label"
                                                                                orderByFieldName="id"
                                                                                key="id"
                                                                                clearable={false}
                                                                                crudClassProxy={excDisplayModeCrudClassProxy}
                                                                            />
                                                                        </label>
                                                                    </div>

                                                                    <div className="large-12 columns">
                                                                        <label>{__("Továbblépés módja:")}
                                                                            <CrudSelect
                                                                                value={this.state.selectedNode.lp_forward_type_id ? Number(this.state.selectedNode.lp_forward_type_id) : null}
                                                                                onSelect={this.onForwardTypeSelect.bind(this)}
                                                                                displayFieldName="title"
                                                                                orderByFieldName="id"
                                                                                key="id"
                                                                                clearable={false}
                                                                                crudClassProxy={lpForwardTypeCrudClassProxy}
                                                                            />
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>


                                                            <div className="columns large-12">

                                                                {
                                                                    !this.state.selectedEdges ? "" :
                                                                        this.state.selectedEdges.map((curr_edge: ILpEdgeRecord, index) => {
                                                                            let curr_node = this.getNodeById(curr_edge.to_node_id!);
                                                                            if (curr_node) {
                                                                                let nodeListToThisEdge: any = [];
                                                                                return <Panel>
                                                                                    <legend><label className="exe-editor-fieldlabel-1">{curr_node.title}</label>
                                                                                        <button className="button small alert exercise-series-small-btn" title={__("Törlés")} onClick={this.onDeleteEdge.bind(this, curr_edge.id)}><i className="fa fa-trash"></i></button>
                                                                                    </legend>
                                                                                    {
                                                                                        this.state.learninPathRecord.start_node_id && this.state.pathsToSelectedNode && this.state.selectedNode ?
                                                                                            <LPConditionView 
                                                                                                onSave={this.onSaveNewCondition.bind(this)} 
                                                                                                lpEdge={curr_edge} condition={this.state.edgeConditions.find((condition: TempLpConditionRecord) => (condition.lp_edge.id && curr_edge.id && condition.lp_edge.id == curr_edge.id) || (condition.lp_edge.from_node_id == curr_edge.from_node_id && condition.lp_edge.to_node_id == curr_edge.to_node_id))}
                                                                                                nodeList={JSON.parse(JSON.stringify(this.state.pathsToSelectedNode))}
                                                                                                nodeTableInfoId={this.state.selectedNode.table_info_id}
                                                                                                >
                                                                                            </LPConditionView>
                                                                                        : <h3>{__("Please select start node, in order to add conditions!")}</h3>
                                                                                    }
                                                                                </Panel>
                                                                            } else return "";
                                                                        })
                                                                }
                                                            </div>
                                                            <div className="columns large-12 lp-button-group">
                                                                <div className="row">
                                                                    <div className="columns large-6">

                                                                        {this.state.selectedNode ? this.getNodeSelector() : ""}
                                                                    </div>
                                                                    <div className="columns large-6">
                                                                        <button className="button small warning" onClick={this.addNewEdge.bind(this)}><i className="fa fa-plus"></i>{__("Él hozzáadása")}</button>
                                                                    </div>
                                                                </div>
                                                            </div>


                                                        </div>
                                                    </Dialog>
                                                    : ""
                                                }

                                            </div>
                                            <div className="row">
                                                <div className="columns large-12">
                                                    <button className="button expanded" onClick={this.onOpenExerciseDialog.bind(this)}><img aria-hidden="true" src="/img/IKON_SET/FELADATMOTOR/ex_small_icon.png" className="lp-button-icon" /> {__("Feladat hozzáadása")}</button>
                                                    {this.state.openedDialogs.exerciseSelector ?
                                                        <Dialog title={__("Feladat hozzáadása")} onClose={this.onOpenExerciseDialog.bind(this)}>
                                                            <ExerciseList
                                                                onExerciseSelected={this.onExerciseSelected.bind(this)}
                                                                grade_id={this.state.learninPathRecord.grade_id}
                                                                subject_id={this.state.learninPathRecord.subject_id}
                                                                library_id={LIBRARY_PERSONAL_ID}
                                                                isPublicDisabled={true}
                                                            />
                                                        </Dialog>
                                                        : ""
                                                    }
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="columns large-12">
                                                    <button className="button expanded" onClick={this.onOpenExerciseSeriesDialog.bind(this)}><img aria-hidden="true" src="/img/IKON_SET/FELADATMOTOR/ex_series_small_icon.png" className="lp-button-icon" /> {__("Feladatsor hozzáadása")}</button>
                                                    {this.state.openedDialogs.exerciseSeriesSelector ?
                                                        <Dialog title={__("Feladatsor hozzáadása")} onClose={this.onOpenExerciseSeriesDialog.bind(this)}>
                                                            <ExerciseSeriesList
                                                                onExerciseSeriesSelected={this.onExerciseSeriesSelected.bind(this)}
                                                                grade_id={this.state.learninPathRecord.grade_id}
                                                                subject_id={this.state.learninPathRecord.subject_id}
                                                            />
                                                        </Dialog>
                                                        : ""
                                                    }
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="columns large-12">
                                                    <button className="button expanded" onClick={this.onOpenBookSelectorDialog.bind(this)}><i className="fas fa-book"></i>{__("Tankönyv kiválasztása")}</button>
                                                    {this.state.openedDialogs.bookSelector ?
                                                        <Dialog title={__("Tankönyv kiválasztása")} onClose={this.onOpenBookSelectorDialog.bind(this)} width={1200} height={876}>
                                                            <LpBookList
                                                                onBookSelected={this.onBookSelected.bind(this)}
                                                                onChapterSelected={this.onChapterSelected.bind(this)}
                                                                onLessonSelected={this.onLessonSelected.bind(this)}
                                                            />
                                                        </Dialog>
                                                        : ""
                                                    }
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="columns large-12">
                                                    <button className="button expanded" onClick={this.onOpenVideoDialog.bind(this)}><i className="fa fa-play" /> {__("Videó/hang hozzáadása")}</button>
                                                    {this.state.openedDialogs.videoSelector
                                                        ?
                                                        <Dialog title={__("Videó/hang hozzáadása")} onClose={this.onOpenVideoDialog.bind(this)}>
                                                            <MediaFileChooser
                                                                key={this.state.currentFolderId || 0}
                                                                rootFolderId={this.props.rootFolderId}
                                                                currentFolderId={this.state.currentFolderId}
                                                                selectableExtensions={["mp4", "mp3"]}
                                                                onFileSelected={this.onVideoSelected.bind(this)}
                                                                onFolderSelected={(folderId: number) => { this.setState({ currentFolderId: folderId }) }}
                                                                enabledActions={ALL_ACTIONS}
                                                            />
                                                        </Dialog>
                                                        : ""
                                                    }

                                                </div>
                                            </div>

                                        </div>
                                    </li>
                                </ul>
                            </div>

                        </div>
                    </EditorSidebar>
                    <EditorPanel>

                        <div ref="visgraphdiv">
                            {this.Graph && this.state.actgraph ?/* Amíg nem is használja ezt senki, addig ne rakjuk be build-be, mert csak növeli a build időt és méretet*/
                                <this.Graph
                                    identifier="lp-graph"
                                    graph={this.state.actgraph}
                                    style={{ height: "800px" }}
                                    options={{
                                        physics: { enabled: true },
                                        layout: { hierarchical: { enabled: true, direction: 'UD' } },
                                        edges: {
                                            smooth: {
                                                type: "cubicBezier",
                                                forceDirection: "none"
                                            }
                                        }
                                    }}
                                    getNetwork={this.getNetwork.bind(this)}
                                />
                                : ""}
                        </div>

                    </EditorPanel>
                </EditorSplit>
            </EditorPage>

        );
    }

    /* --------------- Data helper methods ---------------------*/
    getNodeById(id: number): ILpNodeRecord | undefined {
        return this.state.nodeList.find((element) => { return element.id == id || element.id == id * -1});
    }

    getRealIdONode(tempId: number, saveAsNew: boolean): number {
        console.log("mp id", tempId);
        let curr_item = this.state.itemList.find(item => { return item.no == tempId })!;
        if (saveAsNew) {
            return curr_item.no! * -1; // we put negative ids, so we can distinguish them on serverside
        }
        return curr_item.id ? curr_item.id : curr_item.no! * -1; // we put negative ids, so we can distinguish them on serverside
    }

    /**
     * Getting the edges of a node
     * @param nodeId temp id of node
     * @param inComing is it an incoming edge or an out going?
     */
    getEdgesOfNode(nodeId: number, inComing: boolean): ILpEdgeRecord[] {
        let edgeList: ILpEdgeRecord[] = [];

        if (inComing) {
            edgeList = this.state.edgeList.filter(element => { return element.to_node_id == nodeId });
        } else {
            edgeList = this.state.edgeList.filter(element => { return element.from_node_id == nodeId });
        }
        return edgeList;
    }

    /**
     * Check if the graph is connected, ie. all the nodes are accessible. 
     */
    isGraphConnected(): boolean {
        // todo: do the check, mind that the edges are directed! 
        // You shall start the check from the startnode
        if (this.state.itemList && this.state.learninPathRecord.start_node_id && this.state.actgraph) {
            let tempActGraph = this.state.actgraph;
            let startNodeId = this.state.learninPathRecord.start_node_id;
            let startNode = tempActGraph.nodes.find(
                function (element: any) {
                    return element.id == startNodeId;
                }
            );
            if (!startNode) return false;
            let queue: any[] = [];
            let visitedList = new Array(tempActGraph.nodes.length).fill(true);
            queue.push(startNode);
            visitedList[tempActGraph.nodes.indexOf(startNode)] = false;

            let limitCounter = 0;
            let limit = 100;
            while (queue.length != 0 && limitCounter < limit) {
                limitCounter++;
                let currNode = queue.shift();
                let currEdgeList = tempActGraph.edges.filter(
                    function (edge: any) {
                        return edge.from == currNode.id;
                    }
                );
                currEdgeList.forEach(function (e: any) {
                    let foundNode = tempActGraph.nodes.find(function (n: any) {
                        return e.to == n.id;
                    })
                    if (foundNode) {
                        queue.push(foundNode);
                        visitedList[tempActGraph.nodes.indexOf(foundNode)] = false;
                    }
                });
            }
            return visitedList.indexOf(true) == -1;
        }
        return false;
    }

    /* ----------- Edge condition methods -----------------*/
    onSaveNewCondition(newCondition: TempLpConditionRecord)  {
        if (newCondition) {
            let edgeConditions = this.state.edgeConditions;
            let foundIndex = edgeConditions.findIndex((curr_con: TempLpConditionRecord) => curr_con.lp_edge.from_node_id == newCondition.lp_edge.from_node_id && curr_con.lp_edge.to_node_id == newCondition.lp_edge.to_node_id);
            if (foundIndex != -1)
                edgeConditions[foundIndex] = newCondition;
            else
                edgeConditions.push(newCondition);

            this.setState({
                edgeConditions,
                isDataChanged: true,
            })
        }
    }

    /* ----------- Grapth drawing methods -----------------*/

    public calcGraph(stations: ILpNodeRecord[], edges: ILpEdgeRecord[], coords: any[]): any {
        if (stations) {//&& transitions) {
            var nodes: any[] = [];
            var redEdges: any[] = [];
            var greenEdges: any[] = [];
            var newEdges: any[] = [];

            var i: number = 0;

            stations!.forEach((s) => {
                if (this.getEdgesOfNode(s.id!, false).length < 1) s.is_terminal = true;
                else s.is_terminal = false;

                if (this.state.learninPathRecord.start_node_id != s.id)
                    nodes.push(LearningPathEditorComponent.getVisNodeRandom(s));
                else
                    nodes.push(LearningPathEditorComponent.getVisNode(s, 0, 0));
                i++;
            });

            edges!.forEach((t) => {
                let foundConditionOnEdge: TempLpConditionRecord | undefined = this.state.edgeConditions.find((edgeCond: TempLpConditionRecord) => {
                    if (edgeCond.lp_edge.from_node_id == t.from_node_id && edgeCond.lp_edge.to_node_id == t.to_node_id)
                        return edgeCond;
                    else
                        return undefined;
                });
                let edgeCondition = foundConditionOnEdge ? foundConditionOnEdge.condition : undefined;
                newEdges.push(LearningPathEditorComponent.getVisEdge(t, edgeCondition));
            });


            /* redEdges.forEach((e) => {
                 edges.push(e);
             });
 
             greenEdges.forEach((e) => {
                 edges.push(e);
             });*/

            var visgraph = {
                nodes: nodes,
                edges: newEdges
            }

            return visgraph;
        } else {
            return null;
        }
    }

    protected getGraphCoords(network: any): any[] {
        var nodeCoords: any[] = [];
        var nodeList = network.body.nodes;
        this.state.nodeList!.forEach(s => {
            nodeCoords.push({ "x": nodeList[s.id!].x, "y": nodeList[s.id!].y });
        });
        this.setState({ nodeCoords: nodeCoords });

        return nodeCoords;
    }

    protected static getVisNode(station: ILpNodeRecord, x: number, y: number): any {
        const id: number = (station.id!);
        const label: string = (station.title!);
        const shape: string = (station.is_terminal ? "box" : "ellipse");
        const color: string = "#2aa8ce";
        /*const color: string = (station.style['backgroundColor']);
        const shape: string = (station.is_start || station.is_closed ? "ellipse" : "box");
        const size: number = 30;
        const fontColor: string = (station.style['color']);*/
        if (x != null || y != null) {
            const x_coord: number = x;
            const y_coord: number = y;
            return {
                id: id,
                label: label,
                shape: shape,
                color: color,
                font: { color: "white" },
                x: x_coord,
                y: y_coord
            }
        } else {
            return {
                id: id,
                label: label,
                color: color,
                shape: shape,
                font: { color: "white" }
                /*font: {size: size, color: fontColor }*/
            }
        }

    }


    protected static getVisNodeRandom(station: ILpNodeRecord): any {
        const id: number = (station.id!);
        const label: string = (station.title!);
        const shape: string = (station.lp_forward_type_id && station.lp_forward_type_id == 2 ? "box" : "ellipse");
        const color: string = (station.is_terminal  ? "rgb(49, 165, 55)":"rgb(49, 99, 165)");


        return {
            id: id,
            label: label,
            shape: shape,
            color: color,
            font: { color: "white" }
        }
    }

    protected static getVisEdge(transition: ILpEdgeRecord, edgeCondition: any): any {
        const from: number = (transition.from_node_id!);
        const to: number = (transition.to_node_id!);
        let label: string = "No condition";
        edgeCondition = edgeCondition ? JSON.parse(edgeCondition) : undefined;
        if (edgeCondition && edgeCondition.condition) {
            let parsedCondition = typeof edgeCondition.condition === 'object' ? edgeCondition.condition : JSON.parse(edgeCondition.condition);
            if (parsedCondition) {
                label = this.getConditionEdgeLabels(parsedCondition);
            }
        }
        //(transition.!);
        //const color: string = (transition.must_justify ? "#E7000C" : "#00A500");
        //const fontColor: string = (transition.must_justify ? "#E7000C" : "#00A500");
        //const width: number = (transition.must_justify ? 2 : 1);

        // http://visjs.org/docs/network/edges.html#
        let visEdge = {
                from: from,
                to: to,
                label: label,
                smooth: {
                    type: "curvedCW",
                    forceDirection: "none",
                    roundness: 0.5
                }
        }
        return visEdge;
        /*return {
            from: from,
            to: to,
            label: label,
            //font: {color: fontColor, opacity: 0.5},
            // length: 200,
            //width: width,
            //color: {color: color, opacity: 1, highlight: color },
            smooth: {
                    enabled: hasBack,
                type: (transition.must_justify ? 'curvedCW' : 'cubicBezier'), 
                // forceDirection: (transition.must_justify ? 'vertical' : 'horizontal'),
                roundness: 0.3,
            }
        }*/
    }

    protected static getConditionEdgeLabels(edgeConditionTree: any[]): string {
        let conditionString = "";
        if (edgeConditionTree) {
            edgeConditionTree = JSON.parse(JSON.stringify(edgeConditionTree));
            try {
                let hasOperator = false;
                let hasLeftSide = false;
                if (edgeConditionTree[1] && edgeConditionTree[1].condition) {
                    conditionString += edgeConditionTree[1].condition;
                }

                if (edgeConditionTree[0] && edgeConditionTree[0].operator) {
                    conditionString += " " + edgeConditionTree[0].operator + " ";
                    hasOperator = true;
                }
                //in case of 1 condition
                else if (edgeConditionTree[0] && edgeConditionTree[0].condition) {
                    conditionString += edgeConditionTree[0].condition;
                }

                if (edgeConditionTree[2] && edgeConditionTree[2].condition) {
                    conditionString += edgeConditionTree[2].condition;
                }    
    
                if (edgeConditionTree[2] && Array.isArray(edgeConditionTree[2])) {
                    conditionString += "(" + this.getConditionEdgeLabels(edgeConditionTree[2]) + ")";
                    hasLeftSide = true;
                }
                if (edgeConditionTree[1] && Array.isArray(edgeConditionTree[1])) {
                    let currLabel = "(" + this.getConditionEdgeLabels(edgeConditionTree[1]) + ")";
                    if (hasLeftSide && hasOperator) {
                        conditionString = currLabel + conditionString;
                    }
                    else {
                        conditionString += currLabel;
                    }
                }
            }
            catch(exception) {
                console.log("Some error occured:", exception);
            }
        }
        return conditionString;
    }

}