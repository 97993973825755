import { Server } from '../../server/Server';
import Crud , { registerCrudClassProxy, IAsTextResult, IListParams, CrudClassProxy, ISearchTextParams, ISearchTextResult } from '../Crud';

export interface IStationConstraintMatrixRecord {
    /**
     * Globálisan egyedi azonosító.
     * 
     * Ha új rekordot akarsz létrehozni put() metódussal, akkor hagyd üresen.
     * upsert() hívásnál figyelmen kívül van hagyva az értéke.
     *
     */
    id ?: number;
    /**
     * Rekord létrehozás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel létrehozásakor.
     */
    creation_time?: string /*timestamp?*/;
    /**
     * Ki hozta létre
     *
     * NOT NULL -> sys.sec_user ON DELETE noaction
     *
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozó felhasználóra hivatkozik.
     */
    creation_user_id?: number;
    /**
     * Melyik session-ből jött létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozáshoz használt munkamenetre hivatkozik.
     */
    creation_session_id?: number|null;
    /**
     * Utolsó módosítás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel módosításakor.
     */
    modification_time?: string /*timestamp?*/|null;
    /**
     * Ki módosította utoljára
     *
     * -> sys.sec_user ON DELETE noaction
     *
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosító felhasználóra hivatkozik.
     */
    modification_user_id?: number|null;
    /**
     * Melyik session-ből módosították utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosításhoz használt munkamenetre hivatkozik.
     */
    modification_session_id?: number|null;
    /**
     * Aktív
     *
     * Logikai törléshez használt mező.
     */
    is_active?: boolean;
    /**
     * Név
     */
    title?: string;
    /**
     * Leírás
     */
    description?: string|null;
    /**
     * Nézettábla neve
     *
     * Ez tartalmazza a megadott típusú folyamat összerendeléseket.
     */
    view_name?: string;
    /**
     * Főfolyamat típusa
     *
     * NOT NULL -> wf.wf_type ON DELETE cascade
     *
     */
    master_wf_type_id?: number;
    /**
     * Alfolyamat típusa
     *
     * NOT NULL -> wf.wf_type ON DELETE cascade
     *
     */
    detail_wf_type_id?: number;
}

/**
 *  wf.station_constraint_matrix - Folyamat állapot megszorítás mátrix
 *
 *  Idősoros táblázat, ami az összes tétel összes korábbi verzióját eltárolja.
 */

export default class StationConstraintMatrixCrud extends Crud<IStationConstraintMatrixRecord> {
    public static TABLE_INFO_ID = 2541551599;
    public static getSchemaNameForClass() { return 'wf'; }
    public static getTableNameForClass() { return 'station_constraint_matrix'; }

    public static load: (id: number, aServer ?: Server) => Promise<StationConstraintMatrixCrud>;
    public static asText: (id: number, includeRecord : boolean, aServer ?: Server) => Promise<IAsTextResult<IStationConstraintMatrixRecord>>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<IStationConstraintMatrixRecord[]>;
    public static searchText: (params: ISearchTextParams, aServer ?: Server) => Promise<ISearchTextResult<IStationConstraintMatrixRecord>[]>;
    public static deleteById: (id: number, aServer ?: Server) => Promise<IStationConstraintMatrixRecord>;
    public static unDeleteById: (id: number, aServer ?: Server) => Promise<IStationConstraintMatrixRecord>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class StationConstraintMatrixCrudClassProxy extends CrudClassProxy<IStationConstraintMatrixRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const stationConstraintMatrixCrudClassProxy = new StationConstraintMatrixCrudClassProxy(StationConstraintMatrixCrud);
registerCrudClassProxy(stationConstraintMatrixCrudClassProxy);
