
import * as React from 'react';

import ErrorReportCrud from '@src/framework/crud/ticket/ErrorReportCrud';
import { app } from "@src/index";
import { Dialog, DialogContent, DialogActions, alertDialog } from '../Dialog';
import { HTMLTextarea } from '../HtmlTextarea';
import { __ } from '@src/translation';

type ErrorReportProps ={
    onClosedInComponent: () => void,
    showErrorDialog:boolean 
}

export default class ErrorReport extends React.Component<ErrorReportProps , { errorReportSubject: string, errorReportDescription: string }> {

    constructor(props: any) {
        super(props);
        this.state = {
            errorReportSubject: "",
            errorReportDescription: "",
        }
    }
    handleErrorSubjectChange(e:any)
    {
        this.setState({errorReportSubject:e.target.value})
    
    }
    handleErrorDescriptionChange(value: string)
    {
        this.setState({errorReportDescription:value})
    }

    private async sendReport() {

        try {
            if(!this.state.errorReportSubject||this.state.errorReportSubject.length<1) {
                app.showError(__("Hiba"),__("A hiba beküldéséhez kötelező megadni tárgyat!"));
                return;
            }
            if(!this.state.errorReportDescription||this.state.errorReportDescription.length<1) {
                app.showError(__("Hiba"),__("A hiba beküldéséhez kötelező szöveget megadni!"));
                return;
            }

            await new ErrorReportCrud({
                subject:this.state.errorReportSubject,
                description:this.state.errorReportDescription,

            }).put();
            
            this.setState({errorReportDescription:"",errorReportSubject:""});
            this.props.onClosedInComponent();

            alertDialog(__("Köszönjük észrevételét!"));

        } catch(e) {
            app.showErrorFromJsonResult(e);
        }
    }
    render() {
        return (
            this.props.showErrorDialog?
            <Dialog title ={__("Hiba bejelentése")} onClose={()=>this.props.onClosedInComponent()} >
                        <DialogContent>
                            <div className="clearfix" style={{ border: "0px" }}>
                           <div className="row"> 
                               <p><strong>{__("Tárgy")}:</strong></p>
                               <input type="text" name="errorReport.subject" value={this.state.errorReportSubject||""}  onChange={this.handleErrorSubjectChange.bind(this)} />
                           </div>
                           <div className="row"> 
                               <p><strong>{__("Szöveg")}:</strong></p>
                                <HTMLTextarea                           
                                value={this.state.errorReportDescription || ""}
                                onChange={this.handleErrorDescriptionChange.bind(this)}
                                onBlur={()=>undefined}
                            />
                            </div>
                            {/* <div className="row">
                                <input type="file"
                                    id="avatar" name="avatar"
                                    accept="image/png, image/jpeg,xls/xlsx,doc/docx"
                                    data-max-size="2048">
                                </input>
                            </div> */}
                            </div>
                        </DialogContent>
                        <DialogActions> 
                                <button 
                                    onClick={()=>this.props.onClosedInComponent()}
                                    className="button alert"><i className="fa fa-times" />
                                     {__("Mégse")}
                                </button>
                                   
                                <button className="button success" onClick={this.sendReport.bind(this)}>
                                <i className="fa fa-envelope"/>{__("Hiba bejelentése")}
                                </button>
                        </DialogActions>
                    </Dialog>
                    :null
        )
    }
}
