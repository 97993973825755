import { Module, ModuleMenu, BaseMenu, ModuleRoute } from "../Module";
import { __ } from "@src/translation";
import * as React from "react";
import { ForumMessages } from "./component/ForumMessages";

export let forumModule: ForumModule<BaseMenu>;

export type ForumModuleConfig = {

}

export class ForumModule<T extends BaseMenu> extends Module<T> {
    private config: ForumModuleConfig;
    public static isInitialized : boolean = false;

    constructor(config: ForumModuleConfig) {
        super();
        this.config = config;
    }

    public PATH_FORUM = "/forumdefault";

    public static initGlobal<T extends BaseMenu>(config: ForumModuleConfig) {
        forumModule = new ForumModule<T>(config);
        ForumModule.isInitialized = true;
    }

    public async getRoutesAsync(): Promise<Record<string, ModuleRoute<T>>> {
        return this.getRoutes();
    }

    public static getTaskBreadCrumbs(groupId: number, groupName: string): any[] {
        return [];
    }

    public getRoutes(): Record<string, ModuleRoute<T>> {
        var routes : Record<string, ModuleRoute<T>> = {
            'ROUTE_FORUM': { path: this.PATH_FORUM, component: ForumMessages, exact: true, hasSidebar: false },
        };
       
        return routes;
    }

    public getRecordRoute(tableInfoId: number, recordId: number, edit?: boolean): string | null {
        return null;
    }

    public getMenus() {
        let ret: ModuleMenu<T>[] = [];
        return ret;
    }

}


