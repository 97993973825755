import * as React from "react";
import SharedContentCrud, { ISharedContentRecord } from "@src/framework/crud/usr/SharedContentCrud";
import UsrShareDialog, { ShareMode } from "./UsrShareDialog";
import { BubbleLoader } from 'react-css-loaders';
import { __ } from "@src/translation";
import { DialogContent, DialogActions, confirmDialog } from '../Dialog';
import DateTimePicker from '@src/framework/forms/datetimepicker';
import CrudSelect from '@src/framework/forms/crudselect';
import { shareModeCrudClassProxy } from "@src/framework/crud/usr/ShareModeCrud";
import { app } from "@src/index";
import SharedWithCrud from "@src/framework/crud/usr/SharedWithCrud";
import * as ExerciseCrudPriv from "@src/framework/crud/exc/ExerciseCrud";
import * as ExerciseSeriesCrudPriv from "@src/framework/crud/exc/ExerciseSeriesCrud";
import OpenEndedQuestionCrud from "@src/framework/crud/usr/OpenEndedQuestionCrud";
import OpenEndedExerciseEditor from "../exercise/Editor/OpenEndedExerciseEditor";
import ExerciseList from "../exercise/Viewer/ExerciseList";
import { LIBRARY_PERSONAL_ID, SHARE_VIZSGA_ID } from "@src/Const";
import ExerciseSeriesList from "../exercise/ExerciseSeries/ExerciseSeriesList";
import SecUserSelector from "@src/framework/forms/SecUserSelector";
import ViewSharedWith, { IViewSharedWithRecord } from "@src/framework/view/usr/ViewSharedWith";
import ViewMemberName, { IViewMemberNameRecord } from "@src/framework/view/sys/ViewMemberName";
import MemberCard from "@src/framework/forms/MemberCard";
import { Accordion, AccordionItem } from "../ui/Accordion";
import { me } from "@src/framework/server/Auth";
import SharedContentRunCrud from "@src/framework/crud/usr/SharedContentRunCrud";
import LookupEdit from "@src/framework/forms/lookupedit";
import SecUserCrud from "@src/framework/crud/sys/SecUserCrud";
import { viewInstituteGroupMemberClassProxy } from "@src/framework/view/sys/ViewInstituteGroupMember";
import { InstituteGroupMemberStates } from "../institute/InstituteGroupMemberList";

type IUsrShareInClassroomDialogProps = {
    instituteGroupId: number,
    onClose: () => void,
    table_info_id?: number,
    sharedContentId?: number,
    solutionCount?: number
}

type IUsrShareInClassroomDialogState = {
    sharedContent: ISharedContentRecord,
    allowedShareModeIds: number[],
    loading: boolean,
    showMenu: boolean,
    isShare: boolean,
    showDetails: boolean,
    validationMessages: Map<string, string>,
    record_id?: number,
    selectedTableInfoId?: number,
    openedDialogs: any,
    selectedItem?: any,
    selectedItemTitle: string,
    sharedWith: IViewSharedWithRecord[],
    lastAddedId?: number,
    isDateChanged: boolean,
    isSubmission: boolean
}

let _id: number = -1000000;
const newId = (): number => {
    _id -= 1;
    return _id;
}
export default class UsrShareInClassroomDialog extends React.Component<IUsrShareInClassroomDialogProps, IUsrShareInClassroomDialogState> {

    constructor(props: IUsrShareInClassroomDialogProps) {
        super(props);
        let sharedContent: ISharedContentRecord = {
            title: "",
            max_run_count: 1,
            shared_from_institute_group_id: props.instituteGroupId,
            max_points: 100
            /*rec_id: props.record_id,
            table_info_id: props.table_info_id*/
        };
        let validationMap: Map<string, string> = new Map<string, string>();

        this.state = {
            sharedContent: sharedContent,
            allowedShareModeIds: [],
            loading: false,
            showMenu: false,
            isShare: true,
            showDetails: false,
            validationMessages: validationMap,
            openedDialogs: {
                exerciseSelector: props.table_info_id == ExerciseCrudPriv.default.TABLE_INFO_ID,
                exerciseSeriesSelector: props.table_info_id == ExerciseSeriesCrudPriv.default.TABLE_INFO_ID,
                openEndedSelector: props.table_info_id == OpenEndedQuestionCrud.TABLE_INFO_ID,
                videoSelector: false,
                bookSelector: false,
                sectionSelector: false,
            },
            selectedItemTitle: "",
            sharedWith: [],
            isDateChanged: false,
            isSubmission: false
        };
        this.asyncReload();
    }

    // we only call this, when we want to open an existing one.
    async asyncReload() {
        try {
            //let sharedContentId: number | null = null;
            if (!me) return;

            if (this.props.sharedContentId) {
                let sharedContent: ISharedContentRecord | null;
                let sharedWith: IViewSharedWithRecord[];
                let selectedClassroomId: number | undefined | null = this.props.instituteGroupId;
                let sharedContentId = this.props.sharedContentId;
                sharedContent = (await SharedContentCrud.load(sharedContentId)).record;
                let allowedShareModeIds = UsrShareDialog.getAllowedShareModeIds(sharedContent);
                let selectedItem = { id: sharedContent.rec_id, name: "" };
                sharedWith = await ViewSharedWith.list({
                    filter: { shared_content_id: sharedContent.id },
                    order_by: [
                        { name: "fullname" },
                        { name: "institute_group_name" }
                    ]
                });
                selectedClassroomId = sharedContent.shared_from_institute_group_id;

                this.setState({
                    sharedContent,
                    sharedWith,
                    record_id: sharedContent.rec_id,
                    allowedShareModeIds,
                    openedDialogs: {
                        exerciseSelector: sharedContent.table_info_id == ExerciseCrudPriv.default.TABLE_INFO_ID,
                        exerciseSeriesSelector: sharedContent.table_info_id == ExerciseSeriesCrudPriv.default.TABLE_INFO_ID,
                        openEndedSelector: sharedContent.table_info_id == OpenEndedQuestionCrud.TABLE_INFO_ID,
                        videoSelector: false,
                        bookSelector: false,
                        sectionSelector: false,
                    },
                    selectedItem,
                    validationMessages: new Map<string, string>()
                })
            }
        }
        catch (error) {
            app.showErrorFromJsonResult(error);
        }
    }


    async onSave() {
        this.setState({ loading: true });
        let errorMsg = "";
        let isSubmission = this.state.isSubmission;
        let sc: ISharedContentRecord = this.state.sharedContent;
        let validationMessages = new Map<string, string>();
        if (!sc.rec_id || !sc.table_info_id) {
            errorMsg = __("Adjon meg megosztandó feladatot!");
            app.showError(__("Hiba"), errorMsg);
            validationMessages.set("seletedItem", errorMsg);
        }
        if (!sc.share_mode_id) {
            errorMsg = __("A megosztási mód kötelező!");
            app.showError(__("Hiba"), errorMsg);
            validationMessages.set("share_mode_id", errorMsg);
        }
        if (!sc.end_date && sc.due_date) {
            errorMsg = __("Ha van leadási határidő, kell kiosztás vége dátum is!");
            app.showError(__("Hiba"), errorMsg);
            validationMessages.set("end_date", errorMsg);
        }
        if (sc.end_date && sc.due_date) {
            if (sc.end_date < sc.due_date) {
                errorMsg = __("A leadási határidő nem lehet a láthatóság vége után!");
                app.showError(__("Hiba"), errorMsg);
                validationMessages.set("due_date", errorMsg);
            }
        }
        if (sc.send_overdue && !sc.due_date) {
            errorMsg = __("Határidő nincs megadva!");
            app.showError(__("Hiba"), errorMsg);
            validationMessages.set("due_date", errorMsg);
        }
        if (!sc.max_points) sc.max_points = 100;
        if (sc.max_points < 0 || sc.max_points > 1000) {
            errorMsg = __("A pontszám 0-1000 közötti szám kell hogy legyen!");
            app.showError(__("Hiba"), errorMsg);
            validationMessages.set("max_points", errorMsg);
        }

        if (sc.id) {
            //Ha meglévő megosztást módosítunk, akkor kérjük le a futtatásokat
            const sharedContentRun = await SharedContentRunCrud.list({ filter: { is_active: true, shared_content_id: sc.id } });
            isSubmission = sharedContentRun && sharedContentRun.length > 0;
            //Ha valaki már futtatta a kiosztott feladatot...
            if (isSubmission) {
                //Csak akkor kérjük le az adatbázisban lévőt, ha volt már leadás
                const dbsc = (await SharedContentCrud.load(sc.id)).record;
                // Bármilyen módban, ha már van beküldött, akkor nem lehet dátumokat változtatni
                if (this.state.isDateChanged) {
                    errorMsg = __("Szerkesztés közben érkezett megoldás. A határidők nem módosíthatóak!");
                    app.showError(__("Hiba"), errorMsg);
                    validationMessages.set("date_submission_error", errorMsg);
                }
                // Bármilyen módban, ha már van beküldött nem lehet a pontszámot módosítani
                if (sc.max_points != dbsc.max_points) {
                    errorMsg = __("Szerkesztés közben érkezett megoldás. A max. pontszám nem módosítható!");
                    app.showError(__("Hiba"), errorMsg);
                    validationMessages.set("maxp_submission_error", errorMsg);
                }
                // Vizsga módban, ha már van beküldött nem lehet lejátszások számát módosítani
                if (dbsc.share_mode_id == SHARE_VIZSGA_ID && sc.max_run_count != dbsc.max_run_count) {
                    errorMsg = __("Szerkesztés közben érkezett megoldás. A lejátszások száma nem módosítható!");
                    app.showError(__("Hiba"), errorMsg);
                    validationMessages.set("maxrunc_submission_error", errorMsg);
                }
            }
        }

        if (validationMessages.size > 0) {
            this.setState({ loading: false, validationMessages, isSubmission });
            return;
        }

        try {
            //sc.shared_by_id = me!.id;
            sc.shared_from_institute_group_id = this.props.instituteGroupId;
            if (sc.id) {
                sc.shared_by_id = undefined;
                sc.creation_time = undefined;
                sc.creation_user_id = undefined;
            }
            let record: ISharedContentRecord = (await new SharedContentCrud(sc).put()).record;
            /*await (new SharedWithCrud({
                shared_content_id: record.id!,
                institute_group_id: this.props.instituteGroupId
            })).put();*/

            let swList = this.state.sharedWith;
            //sharing with the whole group
            if (swList.length == 0) {
                let record: IViewSharedWithRecord = {
                    institute_group_id: this.props.instituteGroupId,
                }
                swList.push(record);
            }
            for (let index: number = 0; index < swList.length; index++) {
                const sw = swList[index];
                await (new SharedWithCrud({
                    shared_content_id: record.id!,
                    sec_user_id: sw.sec_user_id,
                    institute_group_id: sw.institute_group_id
                })).put();
            }

            console.log(record);
            app.showSuccess(__("Mentés"), __("Sikeres mentés"));
            this.setState({ loading: false }, this.props.onClose);

        } catch (error) {
            app.showErrorFromJsonResult(error);
            this.setState({ loading: false });
        }

    }

    onItemSelected(record: any, tableInfoId?: number) {
        if (record) {
            let title = record.title || record.name || record.subject;
            let sc = this.state.sharedContent;
            sc.title = title;
            sc.rec_id = record.id;
            sc.table_info_id = tableInfoId ? tableInfoId : this.props.table_info_id;
            let allowedShareModeIds = UsrShareDialog.getAllowedShareModeIds(sc);
            if (allowedShareModeIds.length == 1) sc.share_mode_id = allowedShareModeIds[0];
            this.setState({
                selectedItem: record,
                selectedTableInfoId: tableInfoId,
                record_id: record.id,
                sharedContent: sc,
                selectedItemTitle: title,
                allowedShareModeIds
            });

        }
        //newShareDialogOpen
    }


    private addUserToShare = async (userId: number | null) => {
        if (userId) {
            try {
                const alreadyAdded: IViewSharedWithRecord[] = this.state.sharedWith.filter((item) => item.sec_user_id === userId);
                if (alreadyAdded.length > 0) {
                    return;
                } else {
                    const id = newId();
                    const members: IViewMemberNameRecord[] = await ViewMemberName.list({ filter: { id: userId } });
                    let member: IViewMemberNameRecord;
                    if (members.length) {
                        member = members[0];
                    } else {
                        member = { id: userId }
                    }
                    let record: IViewSharedWithRecord = {
                        id,
                        sec_user_id: userId,
                        is_active: true,
                        member_is_active: member.member_is_active,
                        fullname: member.fullname,
                        login_name: member.login_name,
                        email: member.email
                    }
                    const sharedWith = this.state.sharedWith;
                    sharedWith.push(record);
                    this.setState({ sharedWith, lastAddedId: record.id });
                }
            } catch (error) {
                app.showErrorFromJsonResult(error);
            }
        }
    }
    private onRemoveUser = (index: number) => {
        let sharedWith = this.state.sharedWith!;
        const sw = sharedWith[index];
        if (confirmDialog(__("Megerősítés"), __("Biztos benne, hogy eltávolítja ezt a felhasználót a megosztásból?"))) {
            sharedWith.splice(index, 1);
            this.setState({
                sharedWith
            });
        }
    }


    async onCancel() {
        if (this.state.selectedItem && this.state.selectedTableInfoId == OpenEndedQuestionCrud.TABLE_INFO_ID) {
            try {
                if (!this.props.sharedContentId) {// when we cancel before saving the share for the first time.
                    OpenEndedQuestionCrud.deleteById(this.state.selectedItem.id);
                }
            } catch (error) {
                app.showErrorFromJsonResult(error);
            }
        }
        this.props.onClose();
    }

    moveBack() {
        if (this.state.record_id) {
            this.setState({ record_id: undefined });
        }
    }


    render() {
        if (this.state.loading) {
            return <BubbleLoader />;
        }
        let sc = this.state.sharedContent;
        let isShare: boolean = sc.share_mode_id == ShareMode.SHARE;
        let opName: string = isShare ? __("Megosztás") : __("Kiosztás");
        const isSubmission: boolean = this.state.isSubmission || (this.props.solutionCount != undefined && this.props.solutionCount > 0);
        return <>
            <DialogContent>
                <div>

                    {!this.state.record_id ?
                        <div>

                            {this.state.openedDialogs.openEndedSelector &&
                                <div>
                                    <OpenEndedExerciseEditor
                                        recorId={this.state.selectedItem ? this.state.selectedItem.id : undefined}
                                        onItemSaved={(record) => this.onItemSelected(record, OpenEndedQuestionCrud.TABLE_INFO_ID)}
                                    />
                                </div>
                            }
                            {this.state.openedDialogs.exerciseSelector &&
                                <div>
                                    <h5>{__("Feladat kiválasztása")}</h5>
                                    <ExerciseList
                                        onExerciseSelected={(record, tableInfoId) => this.onItemSelected(record, tableInfoId)}
                                        library_id={LIBRARY_PERSONAL_ID}
                                        openInNewTab={true}
                                    />
                                </div>
                            }

                            {this.state.openedDialogs.exerciseSeriesSelector &&
                                <div>
                                    <h5>{__("Feladatsor kiválasztása")}</h5>
                                    <ExerciseSeriesList
                                        onExerciseSeriesSelected={(record, tableInfoId) => this.onItemSelected(record, tableInfoId)}
                                        openInNewTab={true}
                                    />
                                </div>
                            }

                        </div>
                        :
                        <div className="row expanded">
                            <div className="medium-12 column">
                                <label>{__("Kiválasztott elem")}: <strong>{this.state.selectedItemTitle}</strong>
                                </label>
                            </div>

                            <div className="medium-12 column">
                                <label>{__("Cím")}<span className="exe-editor-validation-msg">{this.state.validationMessages.get("selectedItem")}</span>
                                    <input type="text"
                                        value={sc.title || ""}
                                        placeholder={__("Írja be a megosztás címét")}
                                        onChange={(event) => this.handleInputChange(event, "title")}
                                    />
                                </label>
                            </div>
                            <div className="medium-12 column">
                                <label>{__("Megosztás módja")}<span className="exe-editor-validation-msg">{this.state.validationMessages.get("share_mode_id")}</span>
                                    <CrudSelect value={sc.share_mode_id || null}
                                        onSelect={(sender, value) => this.handleInputChange(value, "share_mode_id")}
                                        emptyTitle={__(`-- Kérem adja meg a {name} módját --`, { name: opName })}
                                        crudClassProxy={shareModeCrudClassProxy}
                                        onFilter={(record: any) => this.state.allowedShareModeIds.includes(record.id)}
                                        displayFieldName="title"
                                        orderByFieldName="id"
                                        disabled={this.props.solutionCount ? this.props.solutionCount > 0 : false}
                                    />
                                </label>
                            </div>

                            {!isShare && <div className="medium-12 column">
                                <label>{__("Maximum pontszám")}<span className="exe-editor-validation-msg">{this.state.validationMessages.get("max_points")}</span>
                                    <input type="number"
                                        value={Number(sc.max_points || 0).toString()}
                                        min={0} max={1000}
                                        onChange={(event) => this.handleInputChange(event, "max_points")}
                                        disabled={isSubmission}
                                    />
                                </label>
                            </div>
                            }

                            <Accordion>
                                <AccordionItem title={<span><img src={"/img/IKON_SET/FLAT/deadline.svg"} className="share-page-icon" alt={__("Határidők/részletek")} />{__("Határidők/részletek")}</span>} defaultClosed key={"details_accordion"}>
                                    <div className="row expanded deadline-accordion-item">

                                        <div className="medium-12 column">
                                            <label>{__("Leírás")}
                                                <input type="text"
                                                    value={sc.description || ""}
                                                    placeholder={__("Írja be a megosztás leírását")}
                                                    onChange={(event) => this.handleInputChange(event, "description")}
                                                />
                                            </label>
                                        </div>

                                        {isSubmission && <div className="medium-12 column">
                                            <label className="label alert">{__("A kiosztásra már érkezett megoldás. Ezek az adatok nem módosíthatóak!")}</label>
                                        </div>}

                                        <div className="medium-6 column">
                                            <label>{("Látható ettől")}<span className="exe-editor-validation-msg">{this.state.validationMessages.get("start_date")}</span>
                                                <DateTimePicker value={sc.start_date}
                                                    onChange={(date, time) => this.handleDateChange(date + " " + time, "start_date")} disabled={isSubmission}
                                                />
                                            </label>
                                        </div>

                                        <div className="medium-6 column">
                                            <label>{("Látható eddig")}<span className="exe-editor-validation-msg">{this.state.validationMessages.get("end_date")}</span>
                                                <DateTimePicker value={sc.end_date || undefined}
                                                    onChange={(date, time) => this.handleDateChange(date + " " + time, "end_date")} disabled={isSubmission}
                                                />
                                            </label>
                                        </div>

                                        {!isShare && <div className="medium-6 column">
                                            <label>{__("Leadás határideje")}<span className="exe-editor-validation-msg">{this.state.validationMessages.get("due_date")}</span>
                                                <DateTimePicker value={sc.due_date}
                                                    onChange={(date, time) => this.handleDateChange(date + " " + time, "due_date")}
                                                    disabled={isShare || isSubmission}
                                                />
                                            </label>
                                        </div>
                                        }

                                        {!isShare &&
                                            <div className="medium-6 column">
                                                <label>{__("Lejátszások max. száma (0=végtelen)")}<span className="exe-editor-validation-msg">{this.state.validationMessages.get("max_run_count")}</span>
                                                    <input value={sc.max_run_count || undefined}
                                                        type="number"
                                                        min={0} max={1000}
                                                        onChange={(event) => this.handleInputChange(event, "max_run_count")}
                                                        disabled={isSubmission && sc.share_mode_id == SHARE_VIZSGA_ID}
                                                    />
                                                </label>
                                            </div>
                                        }

                                        {/*!isShare &&
                                            <div className="medium-6 column">
                                                <label>{__("Határidő után leadható")}<span className="exe-editor-validation-msg">{this.state.validationMessages.get("onSendOverdueToggle")}</span>
                                                    <div className="switch">
                                                        <input className="switch-input"
                                                            id="send_overdue" type="checkbox"
                                                            checked={sc.send_overdue}
                                                            onChange={(event) => this.handleInputChange(event, "onSendOverdueToggle")}
                                                            disabled={isShare}
                                                        />
                                                        <label className="switch-paddle" htmlFor="send_overdue">
                                                            <span className="show-for-sr">{__("Határidő után leadható")}</span>
                                                        </label>
                                                    </div>
                                                </label>
                                            </div>
                                        */}
                                    </div>
                                </AccordionItem>
                                <AccordionItem title={<span><img src={"/img/IKON_SET/FLAT/user.svg"} className="share-page-icon" alt={__("Címzettek")} />{__("Címzettek")}</span>} defaultClosed key={"recipients_accordion"} >
                                    <div className="row expanded">
                                        <div className="column small-12 large-12">
                                            <label>{__("Csak adott felhasználó") + ": "}<span><small><em>{__("Ha nem ad meg külön címzettet, akkor az összes csoporttagnak szól.")}</em></small></span>
                                                {/* <SecUserSelector clearable={false} groupId={this.props.instituteGroupId || undefined} onChange={this.addUserToShare} value={null} /> */}
                                                <LookupEdit
                                                    key={"member_name_selector"}
                                                    fk_table_info_id={SecUserCrud.TABLE_INFO_ID}
                                                    emptyLoad={true}
                                                    viewClassProxy={viewInstituteGroupMemberClassProxy}
                                                    distinct={true}
                                                    searchColumnNames={["member_name", "member_email"]}
                                                    displayColumnNames={["member_name", "member_email"]}
                                                    orderByColumnNames={[{ name: "member_name" }]}
                                                    valueColumn={"sec_user_id"}
                                                    filter={{
                                                        is_active: true,
                                                        institute_group_id: this.props.instituteGroupId,
                                                        is_admin: false,
                                                        status_id: InstituteGroupMemberStates.ACTIVE_ID
                                                    }}
                                                    clearable={true}
                                                    // value={this.state.selectedMemberId}
                                                    onChange={value => this.addUserToShare(value as number)}
                                                    placeholder={__("Kérem válasszon diákot...")} />
                                            </label>
                                        </div>
                                        <div className="column small-12 large-12">
                                            {
                                                this.state.sharedWith.length > 0
                                                    ?
                                                    <>
                                                        <label>{__("Kiválasztott felhasználók")}:</label>
                                                        <table className="hover" style={{ width: "100%" }}>
                                                            <thead>
                                                                <tr>
                                                                    <th style={{ width: "80%" }}>{__("Név")}</th>
                                                                    <th style={{ textAlign: "right" }}>{__("Eltávolítás")}</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>

                                                                {this.state.sharedWith.map((record: IViewSharedWithRecord, index: number) => {
                                                                    if (record.sec_user_id && record.is_active) {
                                                                        return <tr key={record.id} className={record.id == this.state.lastAddedId ? "usr-share-dialog-newly-added" : ""}>
                                                                            <td><MemberCard {...record} id={record.sec_user_id} /></td>
                                                                            <td style={{ textAlign: "right" }}>
                                                                                <button className="button alert" onClick={() => this.onRemoveUser(index)} style={{ marginBottom: 0 }}>
                                                                                    <i className="fa fa-trash" />
                                                                                </button>
                                                                            </td>
                                                                        </tr>
                                                                    }
                                                                    return null;
                                                                })}

                                                            </tbody>
                                                        </table>
                                                    </>
                                                    :
                                                    null
                                            }
                                        </div>
                                    </div>
                                </AccordionItem>
                            </Accordion>
                        </div>
                    }
                </div>
            </DialogContent>
            <DialogActions>

                <div style={{ flex: 1 }} />
                {this.state.record_id && (!sc.id || sc.table_info_id == OpenEndedQuestionCrud.TABLE_INFO_ID) &&
                    <button className="button alert float-left" aria-label={__("Vissza")} type="button"
                        onClick={() => this.moveBack()}>
                        <i className="fas fa-arrow-left"></i>&nbsp; {__("Vissza")}
                    </button>
                }

                <button className="button alert" data-close="" aria-label={__("Bezár")} type="button"
                    onClick={() => this.onCancel()}>
                    <i className="fa fa-times" />&nbsp; {__("Mégse")}
                </button>
                {
                    this.state.allowedShareModeIds.length > 0
                        ?
                        <button className="button success" type="button" onClick={this.onSave.bind(this)}>
                            <i className="fa fa-save" />&nbsp; {__("Mentés")}
                        </button>
                        :
                        null
                }
            </DialogActions>
        </>;
    }


    handleDateChange(value: string, fieldName: string) {
        let sc = this.state.sharedContent;
        const isSubmission: boolean = (this.props.solutionCount != undefined && this.props.solutionCount > 0);
        //Habár disabled, ha már jött beküldött megoldás, just in case...
        if (isSubmission) return;
        sc[fieldName] = value;
        this.setState({ sharedContent: sc, isDateChanged: true });
    }

    handleInputChange(e: any, fieldName: string) {
        let sc = this.state.sharedContent;
        let value = e;
        if (e.target) {
            value = e.target.value;
            if (e.target && e.target.type === 'checkbox') {
                value = e.target.checked;
            } else if (e.target && (e.target.type == 'select-one' || e.target.type == 'number' || e.target.getAttribute("data-type") == "number")) {
                value = Number(e.target.value);
            }
        }
        sc[fieldName] = value;
        this.setState({ sharedContent: sc });
    }


}