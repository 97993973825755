import * as React from 'react';

import ClaimTypeListView from './claim_type_listview';
import ClaimTypeEditor from './claim_type_editor';
import { viewTicketClaimTypeClassProxy } from '@src/framework/view/ticket/ViewTicketClaimType';

import ClaimListView from './claim_listview';
import ClaimEditor from './claim_editor';
import { viewTicketClaimClassProxy } from '@src/framework/view/ticket/ViewTicketClaim';
import AdminRoute from '@src/Routes'
import InquiryCategoryListView from './inquiry_category_listview';
import { viewTicketInquiryCategoryClassProxy } from '@src/framework/view/ticket/ViewTicketInquiryCategory';
import InquiryCategoryEditor from './inquiry_category_editor';


export function TicketRoutes() {
    return [
        <AdminRoute path={viewTicketClaimTypeClassProxy.getDefaultListRoute()} component={ClaimTypeListView} key="ClaimTypeListView"/>,
        <AdminRoute path={viewTicketClaimTypeClassProxy.getDefaultEditRoute()} component={ClaimTypeEditor} key="ClaimTypeEditor"/>,

        <AdminRoute path={viewTicketClaimClassProxy.getDefaultListRoute()} component={ClaimListView} key="ClaimListView"/>,
        <AdminRoute path={viewTicketClaimClassProxy.getDefaultEditRoute()} component={ClaimEditor} key="ClaimEditor"/>,

        <AdminRoute path={viewTicketInquiryCategoryClassProxy.getDefaultListRoute()} component={InquiryCategoryListView} key="InquiryCategoryListView"/>,
        <AdminRoute path={viewTicketInquiryCategoryClassProxy.getDefaultEditRoute()} component={InquiryCategoryEditor} key="InquiryCategoryEditor"/>,


        
    ];
}
