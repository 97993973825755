export class WMNumberLineServer{
    
    static evaluateOnServer(exercise: any, correctSolution: any, userSolution: any): Evaluated {
        var success = true;
        var goodAnswers = 0;
        for (let i = 0; i < userSolution.length; i++) {
            if(userSolution[i] == undefined) continue;
            if (userSolution[i] != correctSolution[i]) {
                success = false;
            } else {
                goodAnswers++;
            }
        }
        let successPercent = Number(((goodAnswers) / userSolution.length).toFixed(2));
        return { "success": success, "solution": correctSolution, "successPercent": success ? 1 : successPercent };
    }

    static filterExerciseOnServer(exercise: any, solution: any): any {
        if (!solution
            || !exercise.num_of_questions  || exercise.num_of_questions < 1 || exercise.num_of_questions  >= solution.length) {
            return exercise;
        }

        var delIdx: any[] = [];
        let elementsToRemove = solution.length - exercise.num_of_questions ;

        while (delIdx.length != elementsToRemove) {             //generating deletable indexes 
            let j = Math.floor(Math.random() * (exercise.cards.length));
            if (delIdx.indexOf(j) < 0)
                delIdx.push(j);
        }

        for (let index = 0; index < delIdx.length; index++) { //delete questions that are not needed because of limited question numbers
            exercise.cards[delIdx[index]].type = "";
        }
        return exercise;
    }

}