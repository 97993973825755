import * as React from 'react';
import { app }  from '@src/index';
import { me, hasGroup, Groups } from '@src/framework/server/Auth';
import { Survey } from '../../component/survey/Survey';
import { __ } from '@src/translation'; 

export const USER_CONFIG_TERMS_ACCEPTED_KEY = "terms_of_use_accepted";

export async function termsOfUseAcceptNeeded() {
    
    try {
        if (me && hasGroup(me, Groups.NeedToAcceptTermsOfUse)) {
            const accepted = sessionStorage.getItem(USER_CONFIG_TERMS_ACCEPTED_KEY + "/" + me.id);
            return accepted === null;
        }
    } catch(e) {
        return false; // sessionStorage does not exist
    }

    return false;
}

export function clearSessionTermsOfUseAccepted() {
    try {
        if (me) sessionStorage.removeItem(USER_CONFIG_TERMS_ACCEPTED_KEY + "/" + me.id);
    } catch(e) {
    }
}

export class TermsOfUseAcceptPage extends React.Component<{}, {acceptedCheckBox: boolean,showSurvey:boolean}> {

    state = {
        acceptedCheckBox: false,
        showSurvey:false
    }

    private async onAccept() {
        try {
            sessionStorage.setItem(USER_CONFIG_TERMS_ACCEPTED_KEY + "/" + me!.id, JSON.stringify({
                timestamp: new Date().getTime()
            }));

            this.setState({
                showSurvey: true
            });

        } catch(e) {
            app.showErrorFromJsonResult(e);
        }
    }
    
    private async onSurveyFinished() {

        location.reload();
    }

    render() {
        if (this.state.showSurvey) {
            return <div>
                    <Survey 
                        onSurveyFinished={this.onSurveyFinished.bind(this)}
                    />
                </div>;
        }

        return <div>
            <div className="row">
                <div className="column small-12" style={{paddingTop: "1em"}}>
                    
                    <h4>{__("Felhasználási nyilatkozat")}</h4>
                    
                    <h5>{__("Kérjük, figyelmesen olvassa át a nyilatkozatot, és jelölje be elfogadó válaszát a lap alján, hogy beléphessen a rendszerbe.")}</h5>

                    <ul>
                        <li>{__("Köszönjük, hogy részt vesz az Oktatási Hivatal (OH) által „A köznevelés tartalmi szabályozóinak megfelelő tankönyvek, taneszközök fejlesztése és digitális tartalomfejlesztése” című projekt keretében fejlesztett OKOSTANKÖNYV digitális tananyagcsomagok kipróbálási és tesztelési folyamatában.")}</li>
                    </ul>

                    <ul>
                        <li>{__("A portál okostankönyv-szolgáltatása nem publikus, kizárólag a tesztbe bevont tanárok és diákok használhatják.")}</li>
                        <li>{__("A rendszer kísérleti fázisban van, a tartalom és a funkciók változhatnak.")}</li>
                        <li>{__("Ne mentsen személyes adatokat a rendszerbe!")}</li>
                        <li>{__("A feltöltött adatokról a rendszer másolatot nem készít, azokat nem tartja meg. A következő rendszerfrissítésnél felülíródnak.")}</li>
                        <li>{__("A portál a jelen tesztfázisban személyes adatot nem kezel, a generált azonosítókat a személyekkel nem kapcsolja össze.")}</li>
                    </ul>

                    <ul>
                        <li>{__("Ön nem jogosult hozzáférését (felhasználónév/jelszó) továbbadni.")}</li>
                        <li>{__("Felhívjuk szíves figyelmét, hogy az okostankönyvekkel kapcsolatban bárminemű médianyilatkozatra kizárólag az OH munkatársai jogosultak.")}</li>
                    </ul>

                    <ul>
                        <li>
                            {__("A portál OKOSTANKÖNYV adatbázisában közzétett szerzői művek vagy más szerzői jogi védelem alá eső termékek (a továbbiakban: Művek) jogtulajdonosa az Oktatási Hivatal (a továbbiakban: Jogtulajdonos).")}<br/>
                            {__("A Jogtulajdonos engedélyezi a Művek köznevelési intézményben tesztelés céljából történő oktatási célú felhasználását. A Művek bármely egyéb célú, illetve eltérő módon történő felhasználása kifejezetten tilos, felhasználása kizárólag a Jogtulajdonos engedélyével lehetséges.")}
                        </li>
                    </ul>

                    <p>
                    </p>

                    <label>
                        <input type="checkbox" checked={this.state.acceptedCheckBox} onChange={e => this.setState({acceptedCheckBox: e.target.checked})}/> 
                        <strong>{__("A fenti tájékoztatót elolvastam, tartalmát megértettem.")}</strong>
                    </label>

                </div>
                <div className="column small-12">
                    <button className="button text-right" onClick={this.onAccept.bind(this)} disabled={!this.state.acceptedCheckBox}>
                        {__("Belépek")}
                    </button>
                </div>
            </div>
        </div>
        ;
    }

}
