import { Server } from '../../server/Server';
import Crud , { registerCrudClassProxy, IAsTextResult, IListParams, CrudClassProxy, ISearchTextParams, ISearchTextResult } from '../Crud';

export interface IOoFileRecord {
    /**
     * Globálisan egyedi azonosító.
     * 
     * Ha új rekordot akarsz létrehozni put() metódussal, akkor hagyd üresen.
     * upsert() hívásnál figyelmen kívül van hagyva az értéke.
     *
     */
    id ?: number;
    /**
     * Rekord létrehozás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel létrehozásakor.
     */
    creation_time?: string /*timestamp?*/;
    /**
     * Ki hozta létre
     *
     * NOT NULL -> sys.sec_user ON DELETE noaction
     *
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozó felhasználóra hivatkozik.
     */
    creation_user_id?: number;
    /**
     * Melyik session-ből jött létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozáshoz használt munkamenetre hivatkozik.
     */
    creation_session_id?: number|null;
    /**
     * Utolsó módosítás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel módosításakor.
     */
    modification_time?: string /*timestamp?*/|null;
    /**
     * Ki módosította utoljára
     *
     * -> sys.sec_user ON DELETE noaction
     *
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosító felhasználóra hivatkozik.
     */
    modification_user_id?: number|null;
    /**
     * Melyik session-ből módosították utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosításhoz használt munkamenetre hivatkozik.
     */
    modification_session_id?: number|null;
    /**
     * Aktív
     *
     * Logikai törléshez használt mező.
     */
    is_active?: boolean;
    /**
     * Utolsó találat időpontja
     */
    search_popularity_last_hit_date?: string /*timestamp?*/|null;
    /**
     * Utolsó népszerűségi index
     */
    search_popularity_last_score?: number /* double precision */|null;
    /**
     * OnlyOffice file azonosító.
     */
    oo_fileid?: string;
    /**
     * Név
     *
     * Az OnlyOffice rendszerében az állomány 'title' mezőjének értéke. Vagyis a file neve.
     */
    title?: string;
    /**
     * Kiterjesztés
     *
     * Az állományt kiterjesztése. Kisbetűs, ponttal kezdődik (vagy üres)
     */
    ext?: string|null;
    /**
     * Content-Type
     */
    content_type?: string|null;
    /**
     * Relatív útvonal
     *
     * A mappa szerkezetben a gyökérhez képest.
     */
    relpath?: string;
    /**
     * Relatív útvonal JSON objektumként
     *
     * Egy lista ami (folder_id, folder_title) elemekből áll.
     */
    relpath_items?: any;
    /**
     * Név
     *
     * Kulcsszavak
     */
    keywords?: string|null;
    /**
     * Méret
     *
     * A file teljes mérete byte-ban.
     */
    size?: number;
    /**
     * Szélesség pixelben
     *
     * Csak akkor, ha raszteres képről van szó.
     */
    width_px?: number|null;
    /**
     * Magasság pixelben
     *
     * Csak akkor, ha raszteres képről van szó.
     */
    height_px?: number|null;
    /**
     * SHA1 hash érték
     *
     * A file tartalmára számítva. Csak képeknél számítjuk!
     */
    sha1?: string;
    /**
     * Van thumbnail
     *
     * Thumbnail-ek elérhetők hozzá MongoDb-ből
     */
    has_thumb?: boolean;
    /**
     * OnlyOffice utolsó ismert file infó.
     *
     * A tartalmazó mappa listázó API hívásából az erre az állományra vonatkozó bejegyzés.
     */
    oo_info?: any;
    /**
     * OnlyOffice mappa
     *
     * NOT NULL -> media.oo_folder ON DELETE cascade
     *
     */
    oo_folder_id?: number;
    /**
     * Eredet leírás
     *
     * Az állomány származási helye (nyilvántartás és szerzői jog miatt), leírás
     */
    origin?: string|null;
    /**
     * Média típus
     *
     * -> media.media_type ON DELETE noaction
     *
     */
    media_type_id?: number|null;
    /**
     * Publikált név
     *
     * Ahogyan a felületen megjelenik - ez nem a file neve.
     */
    displayname?: string|null;
    /**
     * Azonosító NKP 1.0 rendszerben
     *
     * Ez egy ContentVersionId
     */
    nkp1_id?: number|null;
    /**
     * Tulajdonos
     *
     * NOT NULL -> sys.sec_user ON DELETE noaction
     *
     */
    owner_id?: number;
    /**
     * Jogok öröklése
     *
     * A mappa a jogokat a tartalmazó mappától örökli. Az explicit módon beállított jogok hatástalanok.
     */
    inherit_perms?: boolean;
    /**
     * Forrás (származási hely)
     *
     * -> media.origin ON DELETE noaction
     *
     */
    origin_id?: number|null;
    /**
     * Al-forrás (származási hely)
     *
     * -> media.suborigin ON DELETE noaction
     *
     */
    suborigin_id?: number|null;
    /**
     * Eredet URL
     *
     * Az állomány származási helye (nyilvántartás és szerzői jog miatt) URL-ként
     */
    origin_url?: string|null;
    /**
     * Származási szerző
     */
    origin_author?: string|null;
    /**
     * Megjelölendő forrás
     */
    origin_display?: string|null;
    /**
     * Kereshető
     *
     * A kereső szolgáltatás beindexeli, meg lehet találni a keresőben.
     */
    searchable?: boolean|null;
    /**
     * Egyéb adatok
     */
    other_json?: any|null;
}

/**
 *  media.oo_file - OnlyOffice file
 *
 *  OnlyOffice-ba feltöltött file.
 */

export default class OoFileCrud extends Crud<IOoFileRecord> {
    public static TABLE_INFO_ID = 260438188;
    public static getSchemaNameForClass() { return 'media'; }
    public static getTableNameForClass() { return 'oo_file'; }

    public static load: (id: number, aServer ?: Server) => Promise<OoFileCrud>;
    public static asText: (id: number, includeRecord : boolean, aServer ?: Server) => Promise<IAsTextResult<IOoFileRecord>>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<IOoFileRecord[]>;
    public static searchText: (params: ISearchTextParams, aServer ?: Server) => Promise<ISearchTextResult<IOoFileRecord>[]>;
    public static deleteById: (id: number, aServer ?: Server) => Promise<IOoFileRecord>;
    public static unDeleteById: (id: number, aServer ?: Server) => Promise<IOoFileRecord>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class OoFileCrudClassProxy extends CrudClassProxy<IOoFileRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const ooFileCrudClassProxy = new OoFileCrudClassProxy(OoFileCrud);
registerCrudClassProxy(ooFileCrudClassProxy);
