import * as React from 'react';
import { Link, match } from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { BubbleLoader } from 'react-css-loaders';
import { ExerciseServerSimulator } from '../ExerciseServerSimulator';
import { ExerciseBreadcrumbs } from '../../Breadcrumbs';
import * as ExerciseContainerComponent from '../ExerciseContainerComponent';
import { app } from '../../../index';
import * as ExerciseServer from '../ExerciseServer';
import * as ExerciseBaseTypes from "../models/ExerciseBaseClass";
import * as ExerciseTypeConverter from "../models/ExerciseTypeConverter";

import EngineCRUD, { IEngineRecord } from '@crud/exc/EngineCrud';
import ExerciseCrud, { IExerciseRecord } from '@src/framework/crud/exc/ExerciseCrud';
import { subjectCrudClassProxy } from '@framework/crud/doc/SubjectCrud';
import { gradeCrudClassProxy } from '@framework/crud/doc/GradeCrud';

import { me, hasGroup, Groups, hasAnyGroup } from '@framework/server/Auth';
import OoFolderCrud from '@src/framework/crud/media/OoFolderCrud';
import OoFileCrud from '@src/framework/crud/media/OoFileCrud';
import { Dialog, confirmDialog } from '@src/component/Dialog';
import MediaFileChooser, { ContentActionType } from '@component/filemanager/MediaFileChooser';
import ImageSettings from '@src/component/book/editor/ImageSettings';
import { fetchKeywords, setKeywords, MetaKeywordAssignerPlugin } from '@component/meta/meta_keyword_assigner_plugin';
import ExerciseFileSelect, { ExerciseFileTypes } from '@src/component/exercise/Editor/ExerciseFileSelect';
import { ExerciseServerInterface } from '@src/component/exercise/ExerciseContainerComponent';
import { PATH_EXERCISE_EDIT } from '@src/Routes';
import MetaCategoryAssignerPlugin from '@src/component/meta/meta_category_assigner_plugin';
import { setCategoryIds, MetaCategorization } from '@src/component/meta/meta_category_assigner_api';
import { MessageBoxContent } from "../models/ExerciseBaseClass";
import { history } from '@src/index';
import CrudSelect from '@framework/forms//crudselect';
import { AExerciseTypeConverter } from '@src/component/exercise/models/AExerciseTypeConverter';
import LevelSelectComponent from '@src/framework/forms/level_select';
import PermissionPage from '@src/framework/PermissionPage';
import { CrudPermissionType, PermissionCache } from '@src/framework/security/PermissionCache';
import { LIBRARY_OFI_OFFICIAL_ID, LIBRARY_PERSONAL_ID, WF_TYPE_OKOSFELADAT_ID, LANG_ID_HU } from '@src/Const';
import { SearchResult } from '@src/server/PublicServer';
import { HTMLTextarea } from '../../HtmlTextarea';
import * as ExePubView from '@src/framework/view/exc_pub/ViewExcExercise';
import * as ExePrivView from '@src/framework/view/exc/ViewExcExercise';
import { ExerciseEngineTypeCheck } from '../models/ExerciseTypeConverter';
import { WorkflowDialog } from '@src/component/wf/WorkflowDialog';
import ButtonGroup, { ButtonSize, ButtonAlign } from '@src/component/ui/ButtonGroup';
import { EditorPage, EditorPanel, EditorSidebar, EditorSplit } from '@src/component/ui/Panel';
import ReactResizeDetector from 'react-resize-detector';
import { Z_INDEX_EDITOR_STICKY } from '@src/Const';
import StickyBox from "react-sticky-box";
import AccessDeniedPage from '@src/framework/pages/AccessDeniedPage';
import { __, getLanguageId } from '@src/translation';
import { config } from '@src/framework/server/Server';
import { Accordion, AccordionItem, ActionAccordionItem } from '@src/component/ui/Accordion';
import { exerciseModule } from '@src/module/exerciseModule/exerciseModule';
import EngineTranslationCrud from '@src/framework/crud/exc/EngineTranslationCrud';
import { langTranslationCrudClassProxy } from '@src/framework/crud/sys/LangTranslationCrud';


export type ExerciseEditorState = {
    exerciseRecord: IExerciseRecord, // Exercise record in database    
    exerciseBaseData?: ExerciseBaseTypes.ExerciseBaseClass,
    exerciseDetails?: any,
    examples?: ExePubView.IViewExcExerciseRecord[],               // Exercise examples   
    engine?:IEngineRecord, 
    engineTranslation?:string,
    isExample: boolean,
    oldlibId?: number,
    //exerciseToLoad?: any,
    isDataChanged: boolean,
    keywords: string[],
    previousJSON?: any,
    showHelperLinks: boolean,
    messageBoxContent?: MessageBoxContent,
    searchResults?: SearchResult[],
    resultsLength?: number,
    subject_ids: { [category_type_id: string]: number | null },
    categorization: MetaCategorization,
    canEdit: boolean,

    // HTML szerkesztő kép kiválasztása
    tinyMCE?: any,
    showSelectFileDialog: boolean,
    showImageSettingsDialog: boolean,
    selectedImagePath?: string,

    workflowDialogOpen: boolean,
    workflowStateName?: string,

    loading: boolean;

    validationMessages: Map<string, string>
}
//const exerciseServer = new ExerciseServerSimulator("", "");

export class ExerciseEditorProtected extends React.Component<ExerciseEditorProps, ExerciseEditorState> {
    render() {
        return (<PermissionPage requirements={{ crud: { tableInfoId: ExerciseCrud.TABLE_INFO_ID, permissions: CrudPermissionType.U } }}>
            <ExerciseEditor {...this.props} />
        </PermissionPage>
        );

    }
}

type ExerciseEditorProps = {
    match: match<{ exerciseId?: string, exerciseEngineId?: string }>
    showHelperLinks: boolean,
}

export enum ModalTypes {
    NewEmptyTask,
    SaveAsCopy,
    LoadExampleExercise
};

var OFIMEMBERSHIP: boolean;
// declare global {
//     interface Error {
//         stack: any;
//     } 
// }
export class ExerciseEditor extends React.Component<ExerciseEditorProps, ExerciseEditorState> {

    // private container?: ExerciseContainerComponent.default;

    private enginesInDatabase: IEngineRecord[];
    private TypeConverter: AExerciseTypeConverter;
    private exerciseServer: ExerciseServerInterface = new ExerciseServerSimulator();

    constructor(props: any) {
        super(props);
        this.enginesInDatabase = [];

        OFIMEMBERSHIP = hasGroup(me!, Groups.OFIEditor);

        this.state = {
            exerciseRecord: {},
            isExample: false,
            isDataChanged: false,
            showSelectFileDialog: false,
            showImageSettingsDialog: false,
            showHelperLinks: false,
            messageBoxContent: undefined,
            searchResults: undefined,
            resultsLength: 0,
            keywords: [],
            previousJSON: {},
            categorization: {},
            subject_ids: {},
            canEdit: false,
            workflowDialogOpen: false,
            validationMessages: new Map(),
            loading: true,
        };
        this.reloadAsync();
    }


    private async loadSearchResults() {
        this.setState({ searchResults: [] });

        if (!this.exerciseServer || !this.state.exerciseRecord) {
            console.log("Error while loading search results: no server or exercise");
            return;
        }
        if (!this.state.exerciseRecord) {
            return;
        }
        if (!this.state.keywords || this.state.keywords == null || this.state.keywords.length < 1) {
            return;
        }

        try {
            if (!this.state.exerciseRecord.id) {
                return;
            }
            var result: SearchResult[] = await this.exerciseServer.searchExercise(this.state.exerciseRecord.id!);
            result = result.filter(x => !((x.book_uri_segment == null) || (x.lesson_uri_segment == null) || (x.section_id == null) || (x.subject_name == null) || (x.grade_name == null) || (x.text == null)));

            if (result.length == 0) {
                return;
            }
            var resLenght = result.length;
            result = result.slice(0, 5);
            this.setState({ searchResults: result, resultsLength: resLenght })

        } catch (e) {
            app.showErrorFromJsonResult(e);
        }
    }

    async reloadAsync() {
        try {
            this.enginesInDatabase = await EngineCRUD.list({filter:{is_active:true}});
            
            this.reloadExerciseAsync();

        } catch (error) {
            app.showErrorFromJsonResult(error);
        }
    }

    async reloadExerciseAsync() {
        try {

            let exerciseRecord: IExerciseRecord = {};
            let keywords: string[] = [];

            let canEdit = false;
            let workflowStateName: string | undefined = undefined;

            // if there is an exercise to load
            if (this.props.match.params.exerciseId) {
                exerciseRecord = (await ExerciseServer.getExerciseRecord(parseInt(this.props.match.params.exerciseId)) as IExerciseRecord);

                if (!exerciseRecord) {
                    app.showError(__("Nem sikerült betölteni a feladatot"), __("Hibás azonosító"));
                    return;
                }

                var exerciseView = (await ExePrivView.default.load(exerciseRecord.id!)).record;

                let curr_station_id = exerciseView.wf_station_id;
                workflowStateName = exerciseView.wf_station_name || undefined;

                canEdit = await PermissionCache.hasWfEditPermission(WF_TYPE_OKOSFELADAT_ID, curr_station_id, me, exerciseRecord);

            } /*else {
                // if there is no exercise to load, we load an example, if there are any
                if (this.state.examples && this.state.examples.length > 0) {
                    exerciseRecord.exercise = this.state.examples[0].;
                    //canEdit = true; No need for this, examples can only be saved as copies anyway
                    this.setState({ isExample: true });
                }
            }*/

            // if there is no exercise to load and no examples, we simply load the form with no data
            if (!exerciseRecord || !exerciseRecord.exercise) {
                // if it is a new exercise and there are no examples              
                exerciseRecord = await this.getEmptyExercise();
                canEdit = true;
            } else {
                keywords = await fetchKeywords(ExerciseCrud.TABLE_INFO_ID, exerciseRecord.id!);
            }

            /*const engine = this.enginesInDatabase.find((engine) => engine.id == exerciseRecord.engine_id);

            if (engine == null) {
                app.showError(__("Nem sikerült betölteni a feladatmotort"), __("Hibás azonosító"));
                return;
            }*/

            let engine:IEngineRecord|undefined = this.state.engine;
            
            if(!engine && exerciseRecord.engine_id){
                engine = this.enginesInDatabase.find((engine) => engine.id == exerciseRecord.engine_id);
            }else if (!engine && this.props.match.params.exerciseEngineId) {
                engine = this.enginesInDatabase.find((engine) => engine.id == this.props.match.params.exerciseEngineId);             
            } 
            if (!engine) {
                app.showError(__("Nem sikerült betölteni a feladatmotort"), __("Hibás azonosító"));
                return;
            }            

            await this.reloadExerciseEngineAsync(engine);

            var solution = this.getSolution(exerciseRecord);
            if (!solution) solution = { solution: [] };

            let exerciseJson = JSON.parse(JSON.stringify(exerciseRecord.exercise));
            var baseData = ExerciseBaseTypes.convertToBaseClass(exerciseJson);
            var detailedData = this.TypeConverter.convertToEditorAnswer({ ...exerciseJson, solution: solution.solution });

            if (detailedData == undefined)
                detailedData = {};

            this.setState({
                exerciseRecord: { ...exerciseRecord, solution: solution },
                oldlibId: exerciseRecord.library_id,
                exerciseBaseData: baseData,
                exerciseDetails: detailedData,
                keywords: keywords,
                canEdit: canEdit,
                workflowStateName,
                loading: false
            }, () => {
                Promise.resolve(() => { if (this.TypeConverter.refs.container) (this.refs.container as ExerciseContainerComponent.default).restartFromBeginning() })
            });
        } catch (error) {
            app.showErrorFromJsonResult(error);
            this.setState({ loading: false });
        }
    }

    reloadTypeConverter(engineName: string) {
        var curr_converter = ExerciseTypeConverter.TypeConverterAssinment.Converter.find((element: ExerciseTypeConverter.ExerciseEngineInfo) => element.EngineName == engineName);
        if (curr_converter) {
            this.TypeConverter = curr_converter.TypeConverter;
        }
    }

    async reloadExerciseEngineAsync(engine: IEngineRecord) {
        try {
            var examples = await ExePubView.default.list({ filter: { engine_id: engine.id }, limit: 20 });
            let etrans = await EngineTranslationCrud.list({filter:{engine_id:engine.id, lang_id:getLanguageId()}});
            let engineTranslation = etrans && etrans.length>0 ? etrans[0].name:"";

            var newExList = [];
            const exampleNum = Math.min(5, examples.length);
            if (examples && exampleNum) {
                var usedIndxs: number[] = [];
                var itemsSelected = false;
                while (!itemsSelected) {
                    var random = Math.floor(Math.random() * examples.length);
                    if (usedIndxs.indexOf(random) >= 0) continue;

                    newExList.push(examples[random]);
                    usedIndxs.push(random);
                    if (usedIndxs.length >= exampleNum) itemsSelected = true;
                }
                examples = newExList;
            }

            if (!engine.class_name) return;

            this.reloadTypeConverter(engine.class_name);

            this.setState({
                engine,
                engineTranslation,
                exerciseRecord: { ...this.state.exerciseRecord, engine_id: engine.id },
                examples: examples,
            });

        } catch (e) {
            app.showErrorFromJsonResult(e);
        }
    }

    async onSave(saveAsNew: boolean) {

        try {

            if (saveAsNew) {
                if (!await confirmDialog(__("Megerősítés"), __("Biztos, hogy másolatként szeretné menteni a feladatot?"))) {
                    return;
                }
            }

            if (!this.state.keywords || this.state.keywords.length < 1) {
                app.showError(__("Hiányzó adat!"), __("Kérem adjon meg kulcsszavakat!"));
                return;
            }

            if (this.state.exerciseBaseData && this.state.exerciseRecord.is_sni && this.state.exerciseBaseData.all_correct) {
                app.showError(__("Hiba!"), __("A rontani nem lehetséges és a mindig helyes mód egyidejűleg nem engedélyezett."));
                return;
            }

            if (this.state.exerciseRecord.library_id && this.state.exerciseRecord.library_id == LIBRARY_OFI_OFFICIAL_ID
                && this.state.exerciseRecord.internal_code && this.state.exerciseRecord.internal_code.length > 0) {
                const existingExe = await ExerciseCrud.list({ filter: { internal_code: this.state.exerciseRecord.internal_code } });
                if (existingExe && existingExe.length > 0 && existingExe[0].id != this.state.exerciseRecord.id) {
                    app.showError(__("Hibás azonosító"), __("Ez az azonosító már létezik, kérem adjon meg másikat!"));
                    return;
                }

                if (!this.state.exerciseRecord.internal_code || this.state.exerciseRecord.internal_code.length == 0) {
                    //ebben az esetben generálni
                }
            }

            this.setState({ loading: true });
            let isAccessible = (this.getEngineName() && !ExerciseEngineTypeCheck.isAccessibleEngine(this.getEngineName()!)) ? false : this.state.exerciseRecord.is_accessible;
            let valMsg = new Map<string, string>(); //JSON.parse(JSON.stringify( this.state.validationMessages));
            let validationRes = this.TypeConverter.validate(this.state.exerciseDetails, this.state.exerciseBaseData, valMsg, isAccessible);
            let isOfficialEditor: boolean = (me && hasAnyGroup(me, [Groups.Developer, Groups.OFIEditor, Groups.Admin])) || false;
            if (!validationRes.valid) {
                this.setState({ validationMessages: valMsg, loading: false });
                app.showError(__("Hiányzó adat!"), validationRes.message ? validationRes.message : "");
                return;
            }
            else if (validationRes.valid && validationRes.message && isOfficialEditor) { //in case of OFI editors (validate returns true and error message)
                this.setState({ validationMessages: valMsg, loading: false });
                if (!await confirmDialog(__("Megerősítés"), __("Biztosan lementi a feladatot az alábbi hibával?"), __(validationRes.message)))
                    return;
            }

            this.state.exerciseRecord.name = this.state.exerciseRecord.exercise.title;
            this.state.exerciseRecord.subject_id = (this.state.exerciseRecord.subject_id ? Number(this.state.exerciseRecord.subject_id) : null);
            this.state.exerciseRecord.grade_id = (this.state.exerciseRecord.grade_id ? Number(this.state.exerciseRecord.grade_id) : null);

            this.state.exerciseRecord.exercise.solution = {};
            //converting html entites to characters
            let tempjson = JSON.stringify(this.state.exerciseRecord.exercise);
            let tempStr = ExerciseTypeConverter.decodeHtmlEntity(tempjson);
            let cleanEx = JSON.parse(tempStr);
            let tempinternal = undefined;
            if (this.state.exerciseRecord.library_id == LIBRARY_OFI_OFFICIAL_ID && this.state.exerciseRecord.internal_code
                && this.state.exerciseRecord.internal_code.length > 0) {
                tempinternal = this.state.exerciseRecord.internal_code
            }
            var exerciseToSave: IExerciseRecord = {
                id: saveAsNew ? undefined : this.state.exerciseRecord.id,
                site_id: config.mainServer.siteId!,
                engine_id: this.state.exerciseRecord.engine_id,
                owner_id: undefined, //(saveAsNew || !this.state.exerciseRecord.id) ? (me ? me.id : 1) : undefined,
                library_id: (saveAsNew && !isOfficialEditor) ? LIBRARY_PERSONAL_ID : this.state.exerciseRecord.library_id,
                exercise: cleanEx,
                grade_id: this.state.exerciseRecord.grade_id,
                subject_id: this.state.exerciseRecord.subject_id,
                task_html: this.state.exerciseRecord.task_html,
                correct_answer_feedback_html: this.state.exerciseRecord.correct_answer_feedback_html,
                wrong_answer_feedback_html: this.state.exerciseRecord.wrong_answer_feedback_html,
                name: this.state.exerciseRecord.name,
                solution: this.state.exerciseRecord.solution,
                oo_folder_id: this.state.exerciseRecord.oo_folder_id,
                search_text: AExerciseTypeConverter.exerciseToString(this.state.exerciseRecord.exercise, true, this.state.keywords),
                internal_code: tempinternal,
                is_sni: this.state.exerciseRecord.is_sni,
                simple_style: this.state.exerciseRecord.simple_style,
                is_accessible: isAccessible,
                lang_id: this.state.exerciseRecord.lang_id ? this.state.exerciseRecord.lang_id : LANG_ID_HU
            };

            let newExerciseOnServer: IExerciseRecord = {};
            if (saveAsNew && this.state.exerciseRecord.oo_folder_id) {
                await ExerciseServer.copyExerciseFiles(this.state.exerciseRecord, exerciseToSave);
            }

            if (exerciseToSave.oo_folder_id) {
                const folder = (await OoFolderCrud.load(exerciseToSave.oo_folder_id)).record;

                if (folder && folder.parent_id !== ExerciseServer.getExerciseRootParentFolderId(exerciseToSave.library_id)) {
                    await new OoFolderCrud({ id: exerciseToSave.oo_folder_id, parent_id: ExerciseServer.getExerciseRootParentFolderId(exerciseToSave.library_id) }).put();
                }
            }

            newExerciseOnServer = await ExerciseServer.putExerciseRecord(exerciseToSave);
            await setKeywords(
                ExerciseCrud.TABLE_INFO_ID, newExerciseOnServer.id!,
                this.state.keywords
            );

            for (let category_type_id in this.state.categorization) {
                const category_ids = this.state.categorization[category_type_id];
                await setCategoryIds(
                    ExerciseCrud.TABLE_INFO_ID, newExerciseOnServer.id!,
                    category_ids!,
                    parseFloat(category_type_id)
                );
            }

            this.setState({
                exerciseRecord: { ...this.state.exerciseRecord, id: newExerciseOnServer.id, oo_folder_id: newExerciseOnServer.oo_folder_id, creation_user_id: newExerciseOnServer.creation_user_id },
                isDataChanged: false,
                isExample: false,
                loading: false
            });

            let tempurl = PATH_EXERCISE_EDIT + "/" + newExerciseOnServer.id + "/";
            history.replace(tempurl);


            if (!saveAsNew) {
                app.showSuccess(__("Feladat elmentve"), __("Feladat sikeresen elmentve"));
            } else {

                app.showSuccess(__("Feladat másolatként elmentve"), __("Feladat sikeresen elmentve másolatként"));
            }
            this.loadSearchResults();
        } catch (e) {
            app.showErrorFromJsonResult(e);
            this.setState({ loading: false });
        }
    }



    async onUpdateExercise(isBasicData: boolean, exercise: any) {

        if (!this.getEngineName()) return;
        if (isBasicData && exercise.imageanswers == null && this.state.exerciseBaseData) {
            exercise.imagequestion = this.state.exerciseBaseData.imagequestion;
        }

        var baseJson = this.state.exerciseBaseData;
        var detailsJson = this.state.exerciseDetails;
        var prevJSON = this.state.previousJSON;
        if (!isBasicData) {
            detailsJson = exercise;
        }

        var tempjson = this.TypeConverter.convertToJson(detailsJson, baseJson!, prevJSON);

        let solution: any = { solution: [] };
        if (tempjson && tempjson.solution) {
            solution = { solution: tempjson.solution };
        }

        let is_Sni = ExerciseEngineTypeCheck.isGame(this.getEngineName()!) ? true : this.state.exerciseRecord.is_sni;

        if (this.state.exerciseRecord.exercise && this.refs.container && tempjson) {
            this.setState({
                exerciseRecord: { ...this.state.exerciseRecord, exercise: tempjson, solution: solution, is_sni: is_Sni },
                exerciseBaseData: baseJson,
                exerciseDetails: detailsJson,
                previousJSON: detailsJson,
                isDataChanged: (tempjson != this.state.exerciseRecord.exercise)
            }, () => {
                if (this.refs.container) {
                    Promise.resolve((this.refs.container as ExerciseContainerComponent.default).restartFromBeginning());
                    (this.refs.exerciseForm as AExerciseTypeConverter).reset(detailsJson);
                }
            });
        }
    }

    async onLoadExampleExercise(exerciseToLoad: ExePubView.IViewExcExerciseRecord) {
        if (!await confirmDialog(__("Megerősítés"), __("Biztos, hogy betölti a példa feladatot? Ezzel a nem mentett módosításai elvesznek."))) return;
        var exBase = undefined;
        var exDetails: any = undefined;
        let keywords = await fetchKeywords(ExerciseCrud.TABLE_INFO_ID, exerciseToLoad.id!);

        var solution = this.getSolution(exerciseToLoad);


        let exerciseJson = JSON.parse(JSON.stringify(exerciseToLoad.exercise));
        exBase = ExerciseBaseTypes.convertToBaseClass(exerciseJson);
        exDetails = this.TypeConverter.convertToEditorAnswer({ ...exerciseJson, solution: solution.solution });

        this.setState({
            exerciseRecord: { ...exerciseToLoad, solution: solution },
            isExample: true,
            exerciseBaseData: exBase,
            exerciseDetails: exDetails,
            keywords,
            previousJSON: undefined
        }, () => {
            if (this.refs.container) {
                (this.refs.exerciseForm as AExerciseTypeConverter).reset(exDetails);
                Promise.resolve((this.refs.container as ExerciseContainerComponent.default).restartFromBeginning())
            }
        });
    }

    async onStartNewExercise() {

        if (!await confirmDialog(__("Megerősítés"), __("Biztos, hogy új feladatot szeretne kezdeni? Ezzel a nem mentett módsításai elvesznek."))) return;
        try {
            var exerciseRecord: any = await this.getEmptyExercise();
            var exBase = ExerciseBaseTypes.convertToBaseClass(exerciseRecord.exercise);
            this.setState({
                exerciseRecord,
                isExample: false,
                keywords: [],
                exerciseBaseData: exBase,
                exerciseDetails: {},
                oldlibId: undefined,
            }, () => (this.refs.exerciseForm as AExerciseTypeConverter).reset({}));

        } catch (error) {
            app.showErrorFromJsonResult(error);
        }
    }

    async getFolderId() {
        try {
            if (!this.state.exerciseRecord.oo_folder_id) {
                const folder = (await new OoFolderCrud({ parent_id: ExerciseServer.getExerciseRootParentFolderId(this.state.exerciseRecord.library_id) }).put()).record;

                this.setState({
                    exerciseRecord: { ...this.state.exerciseRecord, oo_folder_id: folder.id },
                });

                return folder.id;
            } else {
                return this.state.exerciseRecord.oo_folder_id;
            }
        } catch (e) {
            app.showErrorFromJsonResult(e);
            return null;
        }
    }
    

    render() {
        const breadcrumbs = <ExerciseBreadcrumbs links={[
            { name: this.state.engineTranslation},
        ]} />;

        const enableLang = exerciseModule.getConfig().enableLangSelector;

        if (!this.state.exerciseRecord || !me || (hasAnyGroup(me, [Groups.Student, Groups.TestStudent]) && this.state.exerciseRecord.owner_id && this.state.exerciseRecord.owner_id != me.id)) {
            return AccessDeniedPage();
        }
        var showLinksButtonClass: string = ""
        if (this.state.keywords.length <= 0) {
            showLinksButtonClass = "button small warning";
        }
        else if (this.state.keywords.length > 0 && this.state.searchResults && this.state.searchResults.length > 0) {
            showLinksButtonClass = "button small success";
        }
        else if (this.state.keywords.length > 0 && this.state.searchResults && this.state.searchResults.length <= 0) {
            showLinksButtonClass = "button small alert";
        }

        if (this.state.loading) {
            return <BubbleLoader />;
        }

        if (!this.state.exerciseRecord.exercise || !me || (hasAnyGroup(me, [Groups.Student, Groups.TestStudent]) && this.state.exerciseRecord.owner_id && this.state.exerciseRecord.owner_id != me.id)) {
            return <div className="row expanded">
                <div className="small-12 column">
                    {breadcrumbs}
                    {__("A feladat nem található...")}

                </div>
            </div>
        }

        let isOfficialEditor: boolean = (me && hasAnyGroup(me, [Groups.Developer, Groups.OFIEditor, Groups.Admin])) || false;

        var editor_form = (
            <EditorPage>

                <WorkflowDialog
                    open={this.state.workflowDialogOpen}
                    displayName="Feladat"
                    tableInfoId={ExerciseCrud.TABLE_INFO_ID}
                    recId={this.state.exerciseRecord.id!}
                    onClose={() => this.setState({ workflowDialogOpen: false })}
                    onTransition={this.reloadAsync.bind(this)}
                    autoPublishOnClosedStation={true}
                />

                {this.state.showSelectFileDialog
                    ?
                    <Dialog title={__("Kép választása")} width={1050} onClose={() => this.setState({ showSelectFileDialog: false })} >
                        <MediaFileChooser
                            key={this.state.exerciseRecord.oo_folder_id || 0}
                            rootFolderId={this.state.exerciseRecord.oo_folder_id!}
                            currentFolderId={this.state.exerciseRecord.oo_folder_id!}
                            selectableExtensions={["jpg", "jpeg", "png", "svg"]}
                            onFileSelected={(fileId) => { this.setState({ showSelectFileDialog: false }); this.onFileSelected(fileId); }}
                            onFolderSelected={(folderId: number) => { alert("Cannot change folder!") }}
                            enabledActions={[ContentActionType.FILE_UPLOAD]}
                        />
                    </Dialog>
                    :
                    ""
                }

                {
                    this.state.showImageSettingsDialog && this.state.selectedImagePath ?
                        <Dialog title={__("Kép beillesztése")} width={1000} height={800} onClose={() => this.setState({ showImageSettingsDialog: false })}>
                            <ImageSettings filePath={this.state.selectedImagePath} tinyMCE={this.state.tinyMCE} onClose={() => this.setState({ showImageSettingsDialog: false })} />
                        </Dialog>
                        :
                        ""
                }


                <div className="editor-bar">
                    {breadcrumbs}

                    <ButtonGroup size={ButtonSize.Normal} align={ButtonAlign.Left}>
                        {
                            isOfficialEditor && this.state.exerciseRecord.id && this.state.workflowStateName &&
                            <label className="label" style={{ alignSelf: "center", marginRight: "0.5rem" }}>{__("Állapot")}: {this.state.workflowStateName}</label>
                        }
                        {
                            isOfficialEditor && this.state.exerciseRecord.id && this.state.workflowStateName &&
                            <button className="button" onClick={() => this.setState({ workflowDialogOpen: !this.state.workflowDialogOpen })}>
                                <i className="fa fa-project-diagram" />&nbsp; {__("Folyamat")}...
                                </button>
                        }
                    </ButtonGroup>
                </div>
                <EditorSplit defaultSize="50%">
                    <EditorPanel closable={true}>
                        {/* <EditorSidebar width="50%"> */}
                            <div className="exercise-editor-wrapper" ref="editor">
                                <h4>{__("Feladat szerkesztése")}</h4>
                                <ReactResizeDetector handleWidth handleHeight>

                                    {(width: any, height: any) =>

                                        <StickyBox offsetTop={0} style={{ zIndex: Z_INDEX_EDITOR_STICKY }}>
                                            <div className="columns large-12" style={{ alignSelf: "center", backgroundColor: "#fff" }}>
                                                <ButtonGroup size={ButtonSize.Normal} align={ButtonAlign.Expanded}>


                                                    <button className="button" onClick={this.onStartNewExercise.bind(this)}>
                                                        <i className="fa fa-plus" /> {__("Új létrehozása")}
                                                    </button>

                                                    {
                                                        (!this.state.canEdit || this.state.isExample || (this.state.exerciseRecord.id && this.state.oldlibId && this.state.oldlibId != this.state.exerciseRecord.library_id)) ?
                                                            <button className="button success" disabled={true}>
                                                                <i className="fa fa-save" />  {__("Nincs joga menteni")}</button>
                                                            :
                                                            <button className="button success" onClick={this.onSave.bind(this, false)} disabled={(!this.state.isDataChanged)}>
                                                                <i className="fa fa-save" /> {this.state.exerciseRecord.id ? __("Mentés") : __("Mentés újként")}
                                                            </button>
                                                    }
                                                    <button key="new_copy" className="button success" onClick={this.onSave.bind(this, true)}>
                                                        <i className="fa fa-copy" /> {__("Mentés másolatként")}
                                                    </button>
                                                </ButtonGroup>
                                            </div>
                                        </StickyBox>
                                    }
                                </ReactResizeDetector>


                                <div className="columns" id="exe_base_data_div">
                                    <Accordion allowAllClosed={true}>
                                        <AccordionItem defaultClosed={false} title={<span><img src={"/img/IKON_SET/FLAT/notepad.svg"} className="exe-editor-icons" alt="Alapadatok" /> {__("Alapadatok")}</span>}>
                                            <div className="row" >
                                                <div className="medium-6 column">
                                                    <CrudSelect
                                                        value={this.state.exerciseRecord.grade_id ? Number(this.state.exerciseRecord.grade_id) : null}
                                                        onSelect={this.onGradeSelect}
                                                        displayFieldName="name"
                                                        orderByFieldName="id"
                                                        key="grade_id"
                                                        emptyTitle={__("Nincs megadva")}
                                                        clearable={true}
                                                        crudClassProxy={gradeCrudClassProxy}
                                                        filter={{ is_active: true }}
                                                        sortFunc={(a, b) => a.name.localeCompare(b.name, undefined, { numeric: true, sensitivity: 'base' })}
                                                    />
                                                </div>
                                                <div className="medium-6 column">
                                                    <CrudSelect
                                                        value={this.state.exerciseRecord.subject_id ? Number(this.state.exerciseRecord.subject_id) : null}
                                                        onSelect={this.onSubjectSelect}
                                                        displayFieldName="name"
                                                        key="id"
                                                        emptyTitle={__("Nincs megadva")}
                                                        clearable={true}
                                                        crudClassProxy={subjectCrudClassProxy}
                                                        filter={{ is_active: true }}
                                                    />
                                                </div>
                                            </div>
                                            {OFIMEMBERSHIP && exerciseModule.getConfig().enableOwnExercises                                                                
                                                ?
                                                <div className="row">
                                                    <div className="small-12 medium-12 large-12 columns">
                                                        <select name="exerciseRecord.library_id" id="library_selector_id" value={this.state.exerciseRecord.library_id} onChange={this.handleInputChange.bind(this)}>
                                                            
                                                            {
                                                                exerciseModule.getConfig().enableOfficialExercises
                                                                ?
                                                                <option value={LIBRARY_OFI_OFFICIAL_ID}>{__("Hivatalos")}</option>
                                                                : null
                                                            }                                                           
                                                                <option value={LIBRARY_PERSONAL_ID}>{__("Saját")}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                : null
                                            }
                                            {isOfficialEditor && this.state.exerciseRecord.library_id == LIBRARY_OFI_OFFICIAL_ID
                                                ?
                                                <div className="row">
                                                    <div className="large-12 columns">
                                                        <label> {__("Azonosító")}
                                                            <input type="text" name="exerciseRecord.internal_code" placeholder=""
                                                                value={this.state.exerciseRecord.internal_code || ""}
                                                                onChange={this.handleInputChange.bind(this)} />
                                                        </label>
                                                    </div>
                                                </div>
                                                : null
                                            }

                                            <div className="row ">
                                                <div className="large-12 columns">
                                                    <label>{__("Nehézségi szint")}
                                                        <LevelSelectComponent
                                                            value={this.state.exerciseBaseData ? this.state.exerciseBaseData.level : 1}
                                                            allowEmpty={false}
                                                            onSelect={this.handleInputChange.bind(this)}
                                                            onBlur={this.onUpdateExercise.bind(this, true, this.state.exerciseBaseData)}
                                                            name={"exerciseBaseData.level"}
                                                        />
                                                    </label>
                                                </div>
                                            </div>

                                            {
                                                enableLang && <div className="row ">
                                                <div className="large-12 columns">
                                                    <label>{__("Feladat nyelve")}
                                                    <CrudSelect
                                                        value={this.state.exerciseRecord.lang_id ? Number(this.state.exerciseRecord.lang_id) : null}
                                                        displayFieldName="name"
                                                        onSelect={this.onSelectLang}
                                                        valueFieldName="lang_id"
                                                        filter={{ translated_to_id:getLanguageId() }}
                                                        crudClassProxy={langTranslationCrudClassProxy}
                                                    />
                                                    </label>
                                                </div>
                                            </div>
                                            }

                                            <div className="row">
                                                <div className="large-12 columns exe-editor-inputgroup">
                                                    <label>{__("Feladat címe")} * </label> <span className="exe-editor-validation-msg">{this.state.validationMessages.get("exerciseBaseData.title")}</span>
                                                    <input type="text" name="exerciseBaseData.title"
                                                        value={this.state.exerciseBaseData!.title || ""}
                                                        onChange={this.handleInputChange.bind(this)}
                                                        onBlur={this.onUpdateExercise.bind(this, true, this.state.exerciseBaseData)} />
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="large-12 columns exe-editor-inputgroup">
                                                    <label>{__("Feladat leírása")} </label>
                                                    <HTMLTextarea
                                                        value={this.state.exerciseBaseData ? this.state.exerciseBaseData.description : "" || ""}
                                                        onChange={this.handleDescriptionChange.bind(this)}
                                                        onBlur={this.onUpdateExercise.bind(this, true, this.state.exerciseBaseData)}
                                                    />
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="large-12 columns  exe-editor-inputgroup">
                                                    <label>{__("Kulcsszavak")} *</label>
                                                    <MetaKeywordAssignerPlugin
                                                        keywords={this.state.keywords}
                                                        onAppendKeywords={this.onAppendKeyword}
                                                        onDeleteKeyword={this.onDeleteKeyword}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="large-12 columns">
                                                    {this.state.showHelperLinks
                                                        ?
                                                        <button disabled={!this.state.exerciseRecord.id} onClick={() => this.setState({ showHelperLinks: !this.state.showHelperLinks })} className={showLinksButtonClass} >

                                                            <i className="fa fa-chevron-down" aria-hidden="true"></i>
                                                        &nbsp;
                                                            {__("Találatok elrejtése")}
                                                        &nbsp;
                                                    {showLinksButtonClass == "button small alert" ? "0db" : ""}
                                                            {showLinksButtonClass == "button small success" ? this.state.resultsLength + "db" : ""}
                                                        </button>
                                                        :
                                                        <button disabled={!this.state.exerciseRecord.id} onClick={() => this.setState({ showHelperLinks: !this.state.showHelperLinks })} className={showLinksButtonClass}>
                                                            <i className="fa fa-chevron-left" aria-hidden="true"></i>
                                                        &nbsp;
                                                            {__("Találatok mutatása")}
                                                        &nbsp;
                                                    {showLinksButtonClass == "button small alert" ? "0db" : ""}
                                                            {showLinksButtonClass == "button small success" ? this.state.resultsLength + "db" : ""}
                                                        </button>
                                                    }
                                                    {(this.state.showHelperLinks && this.state.searchResults != undefined && this.state.keywords.length > 0)
                                                        ?
                                                        this.state.searchResults.map(function (x) {

                                                            return (
                                                                <Link target="_blank" className="exe-editor-result-link" to={"/tankonyv/" + x.book_uri_segment + "/" + x.lesson_uri_segment + "#section-" + x.section_id}>
                                                                    <i className="fa fa-arrow-circle-o-right" aria-hidden="true"></i>
                                                                    {x.subject_name + " / " + x.grade_name + " / " + x.text}
                                                                </Link>
                                                            );
                                                        })
                                                        : ""
                                                    }
                                                    {this.state.showHelperLinks && this.state.keywords.length <= 0
                                                        ? <p>{__("Adj meg kulcsszavakat amik alapján elkészül a találatok listája.")}</p>

                                                        : ""
                                                    }
                                                    {this.state.showHelperLinks && this.state.keywords.length > 0 && (this.state.searchResults == undefined || this.state.searchResults!.length <= 0)
                                                        ? <p>{__("A megadott kulcsszavakra nincs találat.")}</p>
                                                        : ""
                                                    }
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="small-12 columns">
                                                    <input type="checkbox" name="exerciseBaseData.show_search_results" id="exerciseBaseData.show_search_results"
                                                        checked={this.state.exerciseBaseData!.show_search_results === true || this.state.exerciseBaseData!.show_search_results === undefined}
                                                        onChange={this.handleInputChange.bind(this)}
                                                        onBlur={this.onUpdateExercise.bind(this, true, this.state.exerciseBaseData)} />
                                                    <label htmlFor="exerciseBaseData.show_search_results">{__("Hibás megoldás esetén segítő linkek mutatása")}</label>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="large-12 columns exe-editor-inputgroup">
                                                    <label className="exe-image-select-label"> {__("Illusztráció")} </label>
                                                    <ExerciseFileSelect
                                                        imagebasepath={this.state.exerciseBaseData!.imagebasepath}
                                                        value={this.state.exerciseBaseData!.illustration || ""}
                                                        onChange={this.handleFileChange.bind(this, "illustration")}
                                                        getFolderId={this.getFolderId.bind(this)}
                                                        fileType={ExerciseFileTypes.Image}
                                                    />
                                                    {(this.state.exerciseBaseData!.illustration != "") ?
                                                        <div className="large-12 columns">
                                                            <label style={{ fontSize: "1rem" }}>{__("Illusztráció képaláírása")} * </label>
                                                            <span className="exe-editor-validation-msg">{this.state.validationMessages.get("exerciseBaseData.illustration_alt")}</span>
                                                            <input type="text" name="exerciseBaseData.illustration_alt"
                                                                value={this.state.exerciseBaseData!.illustration_alt != "undefined" ? this.state.exerciseBaseData!.illustration_alt : ""}
                                                                onChange={this.handleInputChange.bind(this)}
                                                                onBlur={this.onUpdateExercise.bind(this, true, this.state.exerciseBaseData)} />
                                                        </div> : ""}
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="large-12 columns  exe-editor-inputgroup">
                                                    <label className="exe-image-select-label">{__("Hang fájl")} </label>
                                                    <ExerciseFileSelect
                                                        imagebasepath={this.state.exerciseBaseData!.imagebasepath}
                                                        value={this.state.exerciseBaseData!.sound || ""}
                                                        onChange={this.handleFileChange.bind(this, "sound")}
                                                        getFolderId={this.getFolderId.bind(this)}
                                                        fileType={ExerciseFileTypes.Sound}
                                                    />
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="large-12 columns exe-editor-inputgroup">
                                                    <label>{__("Megjegyzés")}</label>
                                                    <input type="text" name="exerciseBaseData.comment"
                                                        value={this.state.exerciseBaseData!.comment || ""}
                                                        onChange={this.handleInputChange.bind(this)}
                                                        onBlur={this.onUpdateExercise.bind(this, true, this.state.exerciseBaseData)} />
                                                </div>
                                            </div>
                                        </AccordionItem>

                                        {/*
                                        <AccordionItem style={{ display: "none" }} vi defaultClosed={false} title={<span><i className="accordion-icon"></i>{__("Háttér beállítások")}</span>}>
                                        <div className="row" >
                                            <div className="large-12 columns">
                                                <input type="checkbox" name="exerciseBaseData.backgroundStyle.is_custom_background"
                                                    checked={this.state.exerciseBaseData!.backgroundStyle.is_custom_background || false}
                                                    onChange={this.handleInputChange.bind(this)}
                                                    onBlur={this.onUpdateExercise.bind(this, true, this.state.exerciseBaseData)} />
                                                <label >{__("Egyéni háttér alkalmazása")} </label>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="large-12 columns">
                                                <label>{__("Háttérkép")}
                                                    <ExerciseFileSelect
                                                        imagebasepath={this.state.exerciseBaseData!.imagebasepath}
                                                        value={this.state.exerciseBaseData!.backgroundStyle.backgroundImage || ""}
                                                        onChange={this.handleFileChange.bind(this, "backgroundImage")}
                                                        getFolderId={this.getFolderId.bind(this)}
                                                        fileType={ExerciseFileTypes.Image}
                                                    />
                                                </label>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="large-12 columns">
                                                <label> {__("Háttérszín")}
                                                    <input type="color" name="exerciseBaseData.backgroundStyle.backgroundColor" value={this.state.exerciseBaseData!.backgroundStyle.backgroundColor || ""}
                                                        onChange={this.handleInputChange.bind(this)}
                                                        onBlur={this.onUpdateExercise.bind(this, true, this.state.exerciseBaseData)} />
                                                </label>
                                            </div>
                                        </div>
                                    </AccordionItem>
                                    */}

                                        <AccordionItem defaultClosed={true} title={<span><img src={"/img/IKON_SET/FLAT/tag.svg"} className="exe-editor-icons" alt={__("Meta kategóriák")} /> {__("Meta kategóriák")}</span>}>
                                            <div className="row">
                                                <div className="large-12 columns">
                                                    <label>{__("Meta kategóriák")}
                                                        <MetaCategoryAssignerPlugin
                                                            tableInfoId={ExerciseCrud.TABLE_INFO_ID}
                                                            recId={this.state.exerciseRecord.id || null}
                                                            categorization={this.state.categorization}
                                                            subject_ids={this.state.subject_ids}
                                                            onSubjectSelected={this.onSubjectSelected}
                                                            onCategoryIdsLoaded={this.onCategoryIdsLoaded}
                                                            onSetCategoryIds={this.onSetCategoryIds}
                                                            onRemoveCategoryIds={this.onRemoveCategoryIds}
                                                            exercise_subject_id={this.state.exerciseRecord.subject_id}
                                                        />
                                                    </label>
                                                </div>
                                            </div>
                                        </AccordionItem>

                                        <AccordionItem defaultClosed={true} title={<span><img src={"/img/IKON_SET/FLAT/messages.svg"} className="exe-editor-icons" alt={(__("Felugró ablakok"))} /> {__("Felugró ablakok")}</span>}>
                                            <Tabs>
                                                <TabList>
                                                    <Tab>{__("A feladat utasítása felugró ablakban")}</Tab>
                                                    <Tab>{__("Üzenet helyes válasz esetén")}</Tab>
                                                    <Tab>{__("Üzenet helytelen válasz esetén")}</Tab>
                                                </TabList>

                                                <TabPanel>
                                                    <HTMLTextarea
                                                        value={this.state.exerciseRecord.task_html || ""}
                                                        onChange={this.handleTaskChange.bind(this)}
                                                        onBlur={this.onUpdateExercise.bind(this, true, this.state.exerciseBaseData)}
                                                        onAddImage={this.onAddInlineImage.bind(this)}
                                                    />
                                                </TabPanel>

                                                <TabPanel>
                                                    <HTMLTextarea
                                                        value={this.state.exerciseRecord.correct_answer_feedback_html || ""}
                                                        onChange={this.handleCorrectChange.bind(this)}
                                                        onBlur={this.onUpdateExercise.bind(this, true, this.state.exerciseBaseData)}
                                                        onAddImage={this.onAddInlineImage.bind(this)}
                                                    />
                                                </TabPanel>

                                                <TabPanel>
                                                    <HTMLTextarea
                                                        value={this.state.exerciseRecord.wrong_answer_feedback_html || ""}
                                                        onChange={this.handleWrongChange.bind(this)}
                                                        onBlur={this.onUpdateExercise.bind(this, true, this.state.exerciseBaseData)}
                                                        onAddImage={this.onAddInlineImage.bind(this)}
                                                    />
                                                </TabPanel>
                                            </Tabs>
                                        </AccordionItem>


                                        {(this.getEngineName() && ExerciseEngineTypeCheck.isAccessibleEngine(this.getEngineName()!)) ?
                                            <AccordionItem defaultClosed={true} title={<span><img src={"/img/IKON_SET/FLAT/eye.svg"} className="exe-editor-icons" alt={(__("Akadálymentesítés"))} /> {__("Akadálymentesítés")}</span>}>
                                                <div className="row">
                                                    <div className="large-12 columns exe-editor-inputgroup">
                                                        <label>{__("Akadálymentesítés")} *</label> <span className="exe-editor-validation-msg">{this.state.validationMessages.get("exerciseBaseData.is_accessible")}</span>
                                                        {__("A feladatban levő képek, hangok")}
                                                        <div className="radio">
                                                            <label>
                                                                <input type="radio" value="accessible" name="exerciseBaseData.is_accessible" checked={this.state.exerciseRecord && this.state.exerciseRecord.is_accessible == true} onChange={this.handleAccessibleRadioChange.bind(this)} />
                                                                {__("csak illusztrációk (hangulatkeltő elemek).")}
                                                            </label>
                                                        </div>
                                                        <div className="radio">
                                                            <label>
                                                                <input type="radio" value="null" name="exerciseBaseData.is_accessible" checked={!this.state.exerciseRecord || this.state.exerciseRecord.is_accessible == null} onChange={this.handleAccessibleRadioChange.bind(this)} />
                                                                {__("szükségesek a feladat megoldásához és")} <strong>{__("szöveggel jól helyettesíthetők")}</strong>.
                                                            </label>
                                                        </div>
                                                        <div className="radio">
                                                            <label>
                                                                <input type="radio" value="notAccessible" name="exerciseBaseData.is_accessible" checked={this.state.exerciseRecord && this.state.exerciseRecord.is_accessible == false} onChange={this.handleAccessibleRadioChange.bind(this)} />
                                                                {__("szükségesek a feladat megoldásához, de")} <strong>{__("szöveggel nem helyettesíthetők")}</strong>.
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </AccordionItem>
                                            : ""
                                        }
                                        <AccordionItem defaultClosed={true} title={<span><img src={"/img/IKON_SET/FLAT/settings.svg"} className="exe-editor-icons" alt={(__("Megjelenési beállítások"))} /> {__("Megjelenési beállítások")}</span>}>
                                            {(this.getEngineName() && ExerciseEngineTypeCheck.isSNI(this.getEngineName()!)) && !ExerciseEngineTypeCheck.isGame(this.getEngineName()!) ?
                                                <div className="row ">
                                                    <div className="large-12 columns">
                                                        <label>
                                                            <input type="checkbox" name="exerciseRecord.is_sni" id="exerciseBaseData.is_sni"
                                                                checked={this.state.exerciseRecord!.is_sni === true || this.state.exerciseRecord!.is_sni === undefined}
                                                                onChange={this.handleInputChange.bind(this)}
                                                                onBlur={this.onUpdateExercise.bind(this, true, this.state.exerciseRecord)}
                                                            />
                                                            {__("Rontani nem lehetséges")}</label>
                                                    </div>
                                                </div>
                                                : ""
                                            }

                                            <div className="large-12 columns">
                                                <label>
                                                    <input type="checkbox" name="exerciseRecord.simple_style" id="exerciseBaseData.simple_style"
                                                        checked={this.state.exerciseRecord!.simple_style === true || this.state.exerciseRecord!.simple_style === undefined}
                                                        onChange={this.handleInputChange.bind(this)}
                                                        onBlur={this.onUpdateExercise.bind(this, true, this.state.exerciseRecord)} />
                                                    {__("Egyszerű stílus")}</label>
                                            </div>

                                            {(this.getEngineName() && !ExerciseEngineTypeCheck.isAlwaysCorrectDisabledEngine(this.getEngineName()!)) ? <div className="large-12 columns">
                                                <label>
                                                    <input type="checkbox" name="exerciseBaseData.all_correct" id="exerciseBaseData.all_correct"
                                                        checked={this.state.exerciseBaseData!.all_correct === true}
                                                        onChange={this.handleInputChange.bind(this)}
                                                        onBlur={this.onUpdateExercise.bind(this, true, this.state.exerciseBaseData)} />
                                                    {__("Mindig helyes")}</label>
                                            </div> : ""}

                                            {(this.getEngineName() && ExerciseEngineTypeCheck.isNoCommonShuffleEngine(this.getEngineName()!)) ?
                                                <div className="large-12 columns">
                                                    <label>
                                                        <input type="checkbox" name="exerciseBaseData.ordered_answers" id="exerciseBaseData.ordered_answers"
                                                            checked={this.state.exerciseBaseData!.ordered_answers === true}
                                                            onChange={this.handleInputChange.bind(this)}
                                                            onBlur={this.onUpdateExercise.bind(this, true, this.state.exerciseBaseData)} />
                                                        {__("A válaszok sorrendje fix")}</label>
                                                </div>
                                                : ""
                                            }
                                        </AccordionItem>
                                    </Accordion>
                                </div>
                                <div className="small-12 column">
                                    {
                                        this.getExerciseDetailsForm()
                                    }
                                </div>
                            </div>
                        {/* </EditorSidebar> */}
                    </EditorPanel>
                    <EditorPanel className="right" closable={true}>
                        <div className="exercise-editor-wrapper">
                            <h4>{__("Feladat tesztelése")}</h4>
                            <ExerciseContainerComponent.default ref="container"
                                exerciseRecord={this.state.exerciseRecord} engineName={this.getEngineName()} />

                            {me && !hasAnyGroup(me, [Groups.Student, Groups.TestStudent]) ?
                                <div className="row expanded">
                                    <h4>{__("Példa feladat betöltése")}:</h4>
                                    {
                                        this.state.examples ?
                                            this.state.examples.map((example, index) => {
                                                return <div key={index} className="columns small-12 large-12">
                                                    <button className="button small expanded" onClick={this.onLoadExampleExercise.bind(this, example)}>{example.name}</button>
                                                    <br />
                                                </div>
                                            })
                                            : ""
                                    }
                                </div>
                                : ""
                            }
                        </div>
                    </EditorPanel>
                </EditorSplit>
            </EditorPage>
        );
        return editor_form;
    }

    getExerciseDetailsForm(): any {

        var curr_converter = ExerciseTypeConverter.TypeConverterAssinment.Converter.find((element: ExerciseTypeConverter.ExerciseEngineInfo) => element.EngineName == this.getEngineName()!);
        if (!curr_converter) return "";

        const x = curr_converter.ExerciseConverterClass;
        return React.createElement(x, {
            ref: "exerciseForm",
            exerciseDetails: this.getEngineName() == ExerciseBaseTypes.ExerciseEngineTypes.ItemToImage || this.getEngineName() == ExerciseBaseTypes.ExerciseEngineTypes.AssignPointToQuestion ?
                {
                    ...this.state.exerciseDetails,
                    imageBasePath: this.state.exerciseBaseData!.imagebasepath + this.state.exerciseBaseData!.illustration
                } : this.state.exerciseDetails,
            imagebasepath: this.state.exerciseRecord.exercise.imagebasepath,
            onUpdateEvent: this.onUpdateExercise.bind(this, false),
            exerciseEditor: this,
            engineName: this.getEngineName()!,
            validationMessages: this.state.validationMessages
        });
    }

    setupEditor() {
        const editorComponent = this;

        return (tinyMCE: any) => {

            if (editorComponent.state.exerciseRecord.exercise.imagebasepath == "/") {
                tinyMCE.addButton("image-add", {
                    text: __("Kép beillesztése"),
                    tooltip: __("Kép beillesztése"),
                    onclick: async function () {
                        const folderId = await editorComponent.getFolderId();

                        editorComponent.setState({
                            tinyMCE: tinyMCE,
                            showSelectFileDialog: true,
                            exerciseRecord: { ...editorComponent.state.exerciseRecord, oo_folder_id: folderId }
                        });
                    }
                });
            }
        };
    }

    async onAddInlineImage(tinyMCE: any) {
        try {
            const folderId = await this.getFolderId();

            this.setState({
                tinyMCE: tinyMCE,
                showSelectFileDialog: true,
                exerciseRecord: { ...this.state.exerciseRecord, oo_folder_id: folderId }
            });
        } catch (error) {
            app.showErrorFromJsonResult(error);
        }
    }

    async onFileSelected(fileId: number) {
        try {
            const file = (await OoFileCrud.load(fileId)).record;

            const newImagePath = "/api/media/file/" + file.sha1;

            this.setState({
                showImageSettingsDialog: true,
                selectedImagePath: newImagePath,
            });
        } catch (error) {
            app.showErrorFromJsonResult(error);
        }
    }

    componentDidMount() {      
        let tempExe = this.state.exerciseRecord;
        tempExe.library_id = OFIMEMBERSHIP ? LIBRARY_OFI_OFFICIAL_ID : LIBRARY_PERSONAL_ID;
        this.setState({ exerciseRecord: tempExe });               
    }

    componentDidUpdate(prevProps: ExerciseEditorProps, prevState: ExerciseEditorState) {

        ($("#exe_base_data_div") as any).foundation();

        if (this.state.exerciseRecord &&
            (prevState.keywords != this.state.keywords
                || prevState.exerciseRecord.grade_id != this.state.exerciseRecord.grade_id
                || prevState.exerciseRecord.subject_id != this.state.exerciseRecord.subject_id
            )
        ) {
            this.loadSearchResults();
        }
        if (this.props.match.params.exerciseId != prevProps.match.params.exerciseId) { this.reloadAsync(); }
    }

    /*private getEngine() {
        if (!this.state.exerciseRecord || !this.state.exerciseRecord.engine_id) {
            return null;
        }
        const ret = this.state.engine; //this.enginesInDatabase.find((engine) => engine.id == this.state.exerciseRecord.engine_id);
        return ret;
    }*/


    private getEngineName() {
        const engine = this.state.engine;
        if (!engine) {
            return null;
        }
        return engine.class_name!;
    }

    private onAppendKeyword = async (keywords: string[]) => {
        this.setState({ isDataChanged: true, keywords: this.state.keywords!.concat(keywords) });
    }

    private onDeleteKeyword = async (index: number) => {
        this.state.keywords.splice(index, 1);
        this.setState({ isDataChanged: true, keywords: this.state.keywords });
    }
    setNewIllustartionAlt(newIlustrationAlt: string) {
        if (this.state.exerciseBaseData)
            this.state.exerciseBaseData.illustration_alt = newIlustrationAlt;
    }

    async handleFileChange(propertyName: string, filePath: string) {
        var exbase = this.state.exerciseBaseData;

        if (!exbase)
            return;

        if (propertyName == "backgroundImage") { // in case of bg style properties
            if (exbase.backgroundStyle.backgroundImage != filePath) {
                exbase.backgroundStyle.backgroundImage = filePath;
            }
        } else if (propertyName == "illustration") {
            if (exbase.illustration != filePath) {
                let filenameSHA1 = filePath.substring(filePath.lastIndexOf('/') + 1);
                let list = await OoFileCrud.list({ filter: { is_active: true, sha1: filenameSHA1 } });
                if (list && list.length > 0) {
                    let altText = "No alt";
                    if (list[0].displayname)
                        altText = list[0].displayname;
                    else if (list[0].ext && list[0].title)
                        altText = list[0].title.replace(list[0].ext, '');

                    if (!exbase.illustration_alt) this.setNewIllustartionAlt(altText);
                }
                exbase.illustration = filePath;
            }
            if (filePath == "")
                exbase.illustration_alt = "";
        } else if (propertyName == "sound") {
            if (exbase.sound != filePath) {
                exbase.sound = filePath;
            }
        }

        this.setState({
            exerciseBaseData: exbase,
            isDataChanged: true
        }, () => this.onUpdateExercise(true, this.state.exerciseBaseData));

    }

    handleInputChange(event: any) {

        const target = event.target;
        var value = target.value;
        if (target && target.type === 'checkbox') {
            value = target.checked;
        } else if (target && (target.type == 'select-one' || target.getAttribute("data-type") == "number")) {
            value = Number(target.value);
        }

        let name = target.name;
        let propertNames = name.split(".");
        if (propertNames.length > 1) {
            if (propertNames[0] == "exerciseRecord") {
                if (this.state.exerciseRecord[propertNames[1]] != value) {
                    this.setState({
                        exerciseRecord: { ...this.state.exerciseRecord, [propertNames[1]]: value },
                        isDataChanged: true,
                    });
                    Promise.resolve((this.refs.container as ExerciseContainerComponent.default).reset(false));
                }
            } else if (propertNames[0] == "exerciseBaseData") {
                var exbase = this.state.exerciseBaseData;
                if (!exbase)
                    return;
                if (propertNames.length > 2) { // in case of bg style properties
                    if (exbase.backgroundStyle[propertNames[2]] != value) {
                        exbase.backgroundStyle[propertNames[2]] = value;
                    }
                } else {
                    if (exbase[propertNames[1]] != value) {
                        exbase[propertNames[1]] = value;
                    }
                }

                this.setState({
                    exerciseBaseData: exbase,
                    isDataChanged: true
                });
            }
        }
    }

    private onSelectLang = (sender: CrudSelect, newLangId: number): void => {
        this.setState({
            exerciseRecord: { ...this.state.exerciseRecord, lang_id: newLangId },
            isDataChanged: true
        });
    }

    private onGradeSelect = (sender: CrudSelect, newGradeId: number | null): void => {
        /*let isLowGrade: boolean = false;
        if (newGradeId && newGradeId < 7200)
            isLowGrade = true;*/
        this.setState({
            exerciseRecord: { ...this.state.exerciseRecord, grade_id: newGradeId },
            isDataChanged: true
        }, () => (this.refs.container as ExerciseContainerComponent.default).reset(false));
    }
    private onSubjectSelect = (sender: CrudSelect, newSubjectId: number | null): void => {
        this.setState({
            exerciseRecord: { ...this.state.exerciseRecord, subject_id: newSubjectId },
            isDataChanged: true
        }, () => (this.refs.container as ExerciseContainerComponent.default).reset(false));
    }

    handleAccessibleRadioChange(event: any) {
        const target = event.target;
        var value = target.value;
        let newVal = null;

        if (value == "accessible") {
            newVal = true;
        } else if (value == "notAccessible") {
            newVal = false;
        }

        if (this.state.exerciseBaseData) {
            this.setState({ exerciseRecord: { ...this.state.exerciseRecord, is_accessible: newVal }, isDataChanged: true });
        }
    }

    handleDescriptionChange(text: any) {
        if (this.state.exerciseBaseData) {
            this.setState({ exerciseBaseData: { ...this.state.exerciseBaseData, description: text }, isDataChanged: true });
        }
    }
    handleTaskChange(text: any) {
        this.setState({ exerciseRecord: { ...this.state.exerciseRecord, task_html: text }, isDataChanged: true });
    }
    handleCorrectChange(text: any) {
        this.setState({ exerciseRecord: { ...this.state.exerciseRecord, correct_answer_feedback_html: text }, isDataChanged: true });
    }
    handleWrongChange(text: any) {
        this.setState({ exerciseRecord: { ...this.state.exerciseRecord, wrong_answer_feedback_html: text }, isDataChanged: true });
    }

    private onSubjectSelected = (category_type_id: number, subject_id: number | null) => {
        this.setState({
            subject_ids: Object.assign(
                {},
                this.state.subject_ids,
                { [category_type_id.toString()]: subject_id })
        });
    }

    private onCategoryIdsLoaded = (category_type_id: number, category_ids: number[]) => {
        this.onSetCategoryIds(category_type_id, category_ids);
    }

    private onSetCategoryIds = (category_type_id: number, category_ids: number[]) => {
        let categorization = Object.assign(
            {},
            this.state.categorization,
            { [category_type_id.toString()]: category_ids }
        );
        this.setState({ categorization });
    }

    private onRemoveCategoryIds = (category_type_id: number, remove_category_ids: number[]) => {
        let category_ids = (this.state.categorization[category_type_id] || []).filter(
            (category_id: number) => { return remove_category_ids.indexOf(category_id) < 0 }
        );
        this.setState({
            categorization:
                Object.assign(
                    {},
                    this.state.categorization,
                    { [category_type_id]: category_ids }
                )
        });
    }

    private async getEmptyExercise(): Promise<IExerciseRecord> {
        const newExercise = {
            title: "",
            internal_code: "",
            description: "",
            backgroundStyle: { is_custom_background: false, backgroundImage: "", backgroundColor: "" },
            illustration: "",
            illustration_alt: "",
            level: 1,
            demo_path: "",
            imageanswers: false,
            imagebasepath: "/",
            all_correct: false,
            ordered_answers: false
        };

        let libId = LIBRARY_PERSONAL_ID;
        if(!exerciseModule.getConfig().enableOwnExercises || (me && hasAnyGroup(me,[Groups.OFIEditor]))){
            libId = LIBRARY_OFI_OFFICIAL_ID;
        }

        var exerciseRecord: IExerciseRecord = {
            engine_id: Number((this.state.exerciseRecord && this.state.exerciseRecord.engine_id ? this.state.exerciseRecord.engine_id : this.props.match.params.exerciseEngineId)),
            library_id: this.state.exerciseRecord.library_id,
            owner_id: (me ? me.id : 1),
            exercise: newExercise,
            name: 'x',
            is_sni: false,
            simple_style: false,
        };
        return exerciseRecord;
    }

    private getSolution(exerciseRecord: IExerciseRecord): any {
        var solution = { solution: {} };
        if (exerciseRecord.solution && exerciseRecord.solution.solution && JSON.stringify(exerciseRecord.solution.solution) != '{}') {
            solution.solution = exerciseRecord.solution.solution;
        }

        return solution;
    }
}
