import * as React from 'react';
import { Popover } from '../ui/Popover';
import { __ } from '@src/translation';
const filesize = require('filesize');

export enum ItemStyle {
    SMALL_ICON,
    LARGE_ICON,
    LIST
}

type ContentListContainerProps = {
    style: ItemStyle
}

export class ContentListContainer extends React.Component<ContentListContainerProps, {}> {
    render() {
        return <div className={this.props.style != ItemStyle.LIST ? __("content-container-list") : ""}>
                {this.props.children}
            </div>;
    }
}

export type MenuItem = {
    name: string | JSX.Element,
    action: () => void
}

type ContentItemProps = {
    id: any,
    name: JSX.Element | string,

    mouseOverTitle?: string;

    creator ?: string;
    created ?: string;
    modifier ?: string|null;
    modified ?: string|null;
    size ?: number;

    icon: JSX.Element | string,
    style: ItemStyle,
    menuItems?: MenuItem[],


    /* Rákattintásra hívja meg az onItemClicked-et*/
    selectable: boolean,
    onItemClicked: (id: any) => void,

    /* Kiválasztva */
    checked?: boolean,
    onItemChecked?: (id: any) => void,
}

export class ContentItem extends React.Component<ContentItemProps, {showMenu: boolean}> {

    constructor(props: ContentItemProps) {
        super(props);

        this.state = {showMenu: false};
    }

    render() {
        if (this.props.style == ItemStyle.LIST) {
            return this.renderListItem();
        } else {
            return this.renderIconItemItem();
        }
    }

    private getMenu() {
        var menu: any[] = [];
        
        if (this.props.menuItems) {
            this.props.menuItems.map((item, index) => {
              
                menu.push(
                    <div key={index} onClick={(event) => {this.setState({showMenu: false}); item.action();event.stopPropagation();}}>
                        {item.name}
                    </div>
                );
            });
        }

        return menu;
    }

    private getDetails = () : string[] => {
        let parts : string[] = [];
        if (this.props.creator || this.props.created) {
            parts.push(
                __("Létrehozva: {creator}{created}", {creator: this.props.creator+" "||"", created: (this.props.created||"").substr(0,19)})
            );
        }
        if (this.props.modifier || this.props.modified) {
            parts.push(
                __("Módosítva: {modifier}{modified}", {modifier: (this.props.modifier+" "||""), modified: ((this.props.modified||"").substr(0,19))})
            );
        }
        if (this.props.size !== undefined)
            parts.push(__("Méret: {size}", {size: filesize(this.props.size)}));
        
        return parts;
    }

    renderListItem() {
        const details = this.getDetails();

        return <div className="content-item-list">
                    {
                        this.props.checked !== undefined
                        ?
                        <div className="content-item-list__checkbox" onChange={this.props.onItemChecked ? this.props.onItemChecked.bind(this) : undefined}>
                            <input type="checkbox" checked={this.props.checked}/>
                        </div>
                        :
                        null
                    }
                    <div className="content-item-list__icon"
                         onClick={this.onItemClicked.bind(this)}
                         style={{cursor: this.props.selectable ? __("pointer") : undefined}}
                         title={details.join("\n")}
                    >
                        {this.props.icon}
                    </div>
                <div className="content-item-list__text">
                    <span className="content-item-list__text-main" 
                        onClick={this.onItemClicked.bind(this)} 
                        style={{cursor: this.props.selectable ? __("pointer") : undefined}}
                        title={details.join("\n")}
                    >
                        {this.props.name}
                    </span> <br />
                    <span className="content-item-list__small">
                        { details.map( (value,index) => {
                            return <div key={"det_"+index}>{value}</div>
                        }) }
                    </span>
                </div>
                <div className="content-item-list__menu">
                    
                    {
                        this.props.menuItems && this.props.menuItems.length > 0
                        ?
                        <i ref="menuIcon" className="fa fa-fw fa-ellipsis-v fa-2x" onClick={() => this.setState({showMenu: !this.state.showMenu})}></i>
                        :
                        null
                    }
                    <Popover open={this.state.showMenu} parentElement={() => this.refs.menuIcon as HTMLElement} onClose={() => this.setState({showMenu: false})} >
                        <div className="content-item-icon__menu-item">
                            {this.getMenu()}
                        </div>
                    </Popover>
                    
                </div>
                {this.props.children}
            </div>
    }

    renderIconItemItem() {
        return <div className={"content-item-icon" + (this.props.style == ItemStyle.LARGE_ICON ? " content-item-icon__large" : "")}
                    style={{cursor: this.props.selectable ? "pointer" : undefined}}
                    title={this.props.mouseOverTitle}
                    >
                    
                <div className="content-item-icon__icon" onClick={this.onItemClicked.bind(this)}>
                    {
                        this.props.menuItems
                        ?
                            <i ref="menuIcon" className="fa fa-fw fa-ellipsis-v content-item-icon__menu" onClick={(e) => {this.setState({showMenu: !this.state.showMenu}); e.stopPropagation(); }}></i>
                        :
                        null
                    }
                    <Popover open={this.state.showMenu} parentElement={() => this.refs.menuIcon as HTMLElement} onClose={() => this.setState({showMenu: false})} >
                        <div className="content-item-icon__menu-item">
                            {this.getMenu()}
                        </div>
                    </Popover>
                    {this.props.icon}
                </div>
            <div className="content-item-icon__text" onClick={this.onItemClicked.bind(this)}>
                <span className="content-item-icon__text-main" onClick={this.onItemClicked.bind(this)} style={{cursor: this.props.selectable ? "pointer" : undefined}}>
                    {this.props.name}
                </span> 
                <br />
                <span className="content-item-icon__text-additional">{}</span>
            </div>
            {this.props.children}
      </div>;
    }

    onItemClicked() {
        if (!this.props.selectable) return;

        this.props.onItemClicked(this.props.id);
    }

}

function isDescendant(parent:any, child:any) {
    var node = child.parentNode;
    while (node != null) {
        if (node == parent) {
            return true;
        }
        node = node.parentNode;
    }
    return false;
}
