import obtainServer from '@framework/server/Server';
import { api, IUserInfo, hasGroup, Groups } from '@framework/server/Auth';
import WfAPI from '../wf/WfAPI';
import ViewTransitionType, { IViewTransitionTypeRecord } from '../view/wf/ViewTransitionType';

const PERMISSION_CACHE_TTL: number = 120 * 1000; // Ez 30 sec volt, de túl gyakran hívódott meg.

/**
 * Lehetséges CRUD jog típusok.
 */
export enum CrudPermissionType {
    C = 'can_create',
    R = 'can_read',
    U = 'can_update',
    D = 'can_delete',
}

/**
 * CRUD jog típus "halmaz" egyértelmű string reprezentációja.
 * Az azonos elemeket (bármilyen sorrendben) tartalmazó paraméterhez
 * azonos visszatérési érték tartozik. A különböző elemeket tartalmazó
 * paraméterhez különböző visszatérési érték tartozik.
 * 
 */
const hashCrudPermissions = (permissions: CrudPermissionType[]): string => {
    let res: string[] = [];
    if (permissions.includes(CrudPermissionType.C)) {
        res.push('C');
    }
    if (permissions.includes(CrudPermissionType.R)) {
        res.push('R');
    }
    if (permissions.includes(CrudPermissionType.U)) {
        res.push('U');
    }
    if (permissions.includes(CrudPermissionType.D)) {
        res.push('D');
    }
    return res.join("");
}

/**
 * Egy konkrét táblázatra megadott/elvárt jogokat reprezentál.
 * Az egyszerű használat érdekében a jogokat meg lehet adni tömbben,
 * vagy (egy elemű tömb helyett) egyetlen értékkel.
 */
export interface ICrudPermissionRequirement {
    tableInfoId: number;
    permissions: CrudPermissionType | CrudPermissionType[];
}

/**
 * Crud jogosultság elvárás egyértelmű reprezentációja string-ként.
 */
const hashCrudPermissionRequirement = (requirement: ICrudPermissionRequirement): string => {
    if (requirement.permissions instanceof Array) {
        return "T" + requirement.tableInfoId + hashCrudPermissions(requirement.permissions);
    } else {
        return "T" + requirement.tableInfoId + hashCrudPermissions([requirement.permissions]);
    }
}

/**
 * Lehetséges View jog típusok.
 */
export enum ViewPermissionType {
    R = 'can_read',
}


/**
 * View jog típus "halmaz" egyértelmű string reprezentációja.
 * Az azonos elemeket (bármilyen sorrendben) tartalmazó paraméterhez
 * azonos visszatérési érték tartozik. A különböző elemeket tartalmazó
 * paraméterhez különböző visszatérési érték tartozik.
 * 
 */
const hashViewPermissions = (permissions: ViewPermissionType[]): string => {
    let res: string[] = [];
    if (permissions.includes(ViewPermissionType.R)) {
        res.push('R');
    }
    return res.join("");
}


/**
 * Egy konkrét nézettáblára megadott/elvárt jogokat reprezentál.
 * Az egyszerű használat érdekében a jogokat meg lehet adni tömbben,
 * vagy (egy elemű tömb helyett) egyetlen értékkel.
 */
export interface IViewPermissionRequirement {
    viewInfoId: number;
    permissions: ViewPermissionType | ViewPermissionType[];
}


/**
 * View jogosultság elvárás egyértelmű reprezentációja string-ként.
 */
const hashViewPermissionsRequirement = (requirement: IViewPermissionRequirement): string => {
    if (requirement.permissions instanceof Array) {
        return "V" + requirement.viewInfoId + hashViewPermissions(requirement.permissions);
    } else {
        return "V" + requirement.viewInfoId + hashViewPermissions([requirement.permissions]);
    }
}


export interface IPermissionRequirements {
    /** Állítsd be ha azt akarod, hogy csak bejelentkezett user-nek legyen rá joga. 
     * 
     * @default: true
     * 
    */
    loggedIn?: boolean;
    /** Itt sorolhatod föl a szükséges általános CRUD jogokat. */
    crud?: ICrudPermissionRequirement | ICrudPermissionRequirement[];
    /** Itt sorolhatod föl a szükséges általános View jogokat. */
    view?: IViewPermissionRequirement | IViewPermissionRequirement[];
}


/**
 * Nem akarjuk állandóan lekérdezni a szervertől a jogokat minden renderelésnél.
 * De azt sem akarjuk, hogy egy jog változtatás után újra kelljen tölteni az egész
 * lapot a változások megjelenítéséhez. Ezért egy saját cache-t implementálunk,
 * ami egy bizonyos elvárt jogosultság halmaz meglétét egy ideig megjegyzi.
 * 
 */
export function hashPermissionPageProps(requirements: IPermissionRequirements): string {
    let items: string[] = [];
    if (requirements.crud !== undefined) {
        if (requirements.crud instanceof Array) {
            let sr = requirements.crud.sort((a, b) => { return b.tableInfoId - a.tableInfoId });
            for (let i = 0; i < sr.length; i++) {
                items.push(hashCrudPermissionRequirement(sr[i]));
            }
        } else {
            items.push(hashCrudPermissionRequirement(requirements.crud));
        }
    }
    if (requirements.view !== undefined) {
        if (requirements.view instanceof Array) {
            let sr = requirements.view.sort((a, b) => { return b.viewInfoId - a.viewInfoId });
            for (let i = 0; i < sr.length; i++)
                items.push(hashViewPermissionsRequirement(sr[i]));
        } else {
            items.push(hashViewPermissionsRequirement(requirements.view));
        }
    }
    return items.join(",");
}

export interface PermissionCacheItem {
    created: number;
    value: boolean;
}


export type TransitionTypeMap = { [stationId: number]: IViewTransitionTypeRecord[] };

export interface WfDeleteTransitionInfo {
    activeTransitionTypes: IViewTransitionTypeRecord[]; // Az összes átmenet
    deleteFromStationIds: Set<number>; // Azok az állapotok amikből lehet törölni
    undeleteFromStationIds: Set<number>; // Azok az állapotok amikből lehet visszaállítani.
}

export class PermissionCache {
    private _cache: { [key: string]: PermissionCacheItem } = {};
    private _ttl_msec: number;

    constructor(ttlMsec: number) {
        this._ttl_msec = ttlMsec;
    }

    private async _fetchPermission(requirements: IPermissionRequirements): Promise<boolean> {
        if (api == null) {
            return Promise.resolve(false);
        } else {
            const response = await obtainServer().post<boolean>(
                'crudPermission',
                { operation: "has_permission", requirements }
            );
            return response;
        }
    }

    public hasPermission = async (requirements: IPermissionRequirements): Promise<boolean> => {
        // A bejelentkezésre vonatkozó részt kliens oldalon ellenőrizzük.
        if (requirements.loggedIn === undefined || requirements.loggedIn) {
            if (!api) return false;
            const user = api.getCurrentUser();
            if (!user) return false;
        }

        try {
            const key = hashPermissionPageProps(requirements);
            // Empty permission requirements -> return true.
            if (!key) { return true; }
            let item = this._cache[key];
            if (item) {
                const now = (new Date).getTime();
                const expired = item.created + this._ttl_msec < now;
                if (!expired) {
                    return Promise.resolve(item.value);
                }
            }
            const value = await this._fetchPermission(requirements);
            const created = (new Date).getTime();
            this._cache[key] = { created, value };
            return Promise.resolve(value);
        } catch (error) {
            return Promise.reject(error);
        }
    }

    public static async hasWfEditPermission(wf_type_id: number, wf_station_id: number | null | undefined, user: IUserInfo | null, record: any): Promise<boolean> {

        let canEdit = false;
        let wfApi = new WfAPI(obtainServer());
        let stationPermissions = await wfApi.getStationPermissions(wf_type_id);

        if (user && hasGroup(user, Groups.Admin)) {
            canEdit = true;
        } else if (wf_station_id) {
            // Publikálható feladat, az állapot alapú jogtól függ, hogy szerkeszthető-e.
            let perm = stationPermissions[wf_station_id];
            canEdit = (perm && perm.can_update_master);
        } else {
            console.log("privát user", user, "creator ", record.creation_user_id)            
            // Privát feladat, csak a tulajdonosa szerkesztheti.
            canEdit = (user && (record.creation_user_id == user.id)) || false;
        }

        return canEdit;
    }


    public static async getWfDeletableStationIds(wf_type_id: number, transitionTypes?: IViewTransitionTypeRecord[] | undefined, activeDeleteTransitonTypes?: TransitionTypeMap | undefined): Promise<Set<number>> {

        let canDeleteFromStationIds: Set<number> = new Set([]);
        if (transitionTypes === undefined) {
            transitionTypes = await ViewTransitionType.list({
                filter: { type_id: wf_type_id, is_active: true }
            });
            activeDeleteTransitonTypes = {};
            transitionTypes.forEach((tt) => {
                if (tt.dst_is_deleted! && tt.is_active) {
                    const stationId = tt.src_station_id!;
                    let items = activeDeleteTransitonTypes![stationId] || [];
                    items.push(tt);
                    activeDeleteTransitonTypes![tt.src_station_id!] = items;
                    canDeleteFromStationIds.add(stationId);
                }
            });
        }

        return canDeleteFromStationIds;
    }

    /* Megadja egy adott folyamat típusra az összes átmenet típust, illetve leválogatja azokat az állapotokat amikből lehet törölni és visszaállítani. */
    public static loadWfDelTrans = async (wf_type_id: number) : Promise<WfDeleteTransitionInfo> => {
        const transitonTypes = await ViewTransitionType.list({
            filter: { type_id: wf_type_id, is_active: true }
        });
        // Azok a forrás állapotok, amikből lehetséges átmenni törölt állapotba.
        const deleteFromStationids: number[] = [];
        const unDeleteFromStationids: number[] = [];
        transitonTypes.forEach((transitionType) => {
            if (!transitionType.src_is_deleted! && transitionType.dst_is_deleted!) {
                deleteFromStationids.push(transitionType.src_station_id!);
            }
            if (transitionType.src_is_deleted! && !transitionType.dst_is_deleted) {
                unDeleteFromStationids.push(transitionType.src_station_id!);
            }
        });
        return { activeTransitionTypes: transitonTypes, deleteFromStationIds: new Set(deleteFromStationids), undeleteFromStationIds: new Set(unDeleteFromStationids) };
    }

    public static async hasWfDeletePermission(canDeleteFromStationIds: Set<number>, user: IUserInfo | null, record: any): Promise<boolean> {
        let canDel = false;

        if (user && hasGroup(user, Groups.Admin)) {
            canDel = true;
        } else if (record.wf_station_id) {
            canDel = canDeleteFromStationIds.has(record.wf_station_id);
        } else {
            canDel = true;
        }

        return canDel;
    }

}

export const permissionCache = new PermissionCache(PERMISSION_CACHE_TTL);
