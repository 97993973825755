import * as React from 'react';
import { BubbleLoader } from 'react-css-loaders';
import { app } from '@src/index';
import OoFileCrud from '@framework/crud/media//OoFileCrud';
import BookCrud, { IBookRecord } from '@src/framework/crud/doc/BookCrud';
import FileManagerPager from './FileManagerPager';
import ViewDocSectionFileRef, { IViewDocSectionFileRefRecord } from '@src/framework/view/doc/ViewDocSectionFileRef';
import {PATH_EDIT_BOOK_SECTIONS } from '@src/Routes';
import { StringSearchKind, IStringSearch, IListParams, TFilterDict, TSpecFilterDict } from '@src/framework/crud/Crud';
import { Dialog } from '@src/component/Dialog';
import { FileDataTabs } from '@src/component/filemanager/data/FileDataTabs';
import { Link } from 'react-router-dom';
import { IOoFileRecord } from '@src/framework/crud/media_pub/OoFileCrud';
import { LIBRARY_OFI_OFFICIAL_ID } from '@src/Const';
import { __ } from '@src/translation';

interface OFIAltTaggerPageProps {

}

interface OFIAltTaggerPageState {

    files?:IViewDocSectionFileRefRecord[];
    limit: number;
    offset: number;
    pageSize: number;
    filter: string;
    loading: boolean;
    books: IBookRecord[];
   
    selectedBookId?: number | null;
    selectedFilterId?:number
    originId?: number | null;

    withoutAltTag:boolean;
    count:number;
    type: "book" | "exercise" | "folder";
}

export class OFIAltTaggerPage extends React.Component<OFIAltTaggerPageProps, OFIAltTaggerPageState> {
    constructor(props: OFIAltTaggerPageProps) {
        super(props);
        this.state = {
            limit: 50,
            offset: 0,
            pageSize: 25,
            count:0,

            files: undefined,
            filter: '',
            loading: false,
            books: [],
            type: "book",
            withoutAltTag:false,
        };
    }

    async componentDidMount() {

        await this.reload();
        this.reloadUntaggedAsync();
    }

    async reload() {
        const books = await BookCrud.list({ filter: { is_active: true, library_id: LIBRARY_OFI_OFFICIAL_ID }, order_by: "name" });
        this.setState({
            books,
        });
    }

    async reloadUntaggedAsync() {
        try {
            this.setState({ files: undefined, loading: true });

          

            var titleFilter: IStringSearch | undefined = undefined;

            if (this.state.filter) {
                titleFilter = {
                    kind: StringSearchKind.Contains,
                    case_sensitive: false,
                    expr: this.state.filter
                }
            }
            
            let files;
            let count:number=0;
            var filter:any={} 
            
            filter={
                book_id: this.state.selectedBookId,
                file_title: titleFilter,
                alt: this.state.withoutAltTag?null:undefined,
            };
            
            files = await ViewDocSectionFileRef.list(this.getListParams(filter));
            count = await ViewDocSectionFileRef.count(this.getListParams(filter));
               
            this.setState({ files: files as any, loading: false,count:count });

        } catch (e) {
            app.showErrorFromJsonResult(e);
        }
    }

    
    private getListParams(filter:TFilterDict) : IListParams
    {
        let spec: TSpecFilterDict = {};
            if (this.state.selectedFilterId &&this.state.selectedFilterId>0) {
                spec.selectedFilterId = this.state.selectedFilterId;
            }
        return {
            filter: filter,            
            limit: this.state.limit,
            offset:this.state.offset,            
            spec:spec
          };
    }
    private onUntaggedFilterChange(e: any) {
        this.setState({ filter: e.target.value });
    }

    // Entert nyom a filter input-ban, feldolgozzuk
   
    private onUntaggedFilterKeyPress(e: any) {
        if (e.key === 'Enter') {
            this.reloadUntaggedAsync();
        }
    }

    private onChangeLimit = (limit: number) => {
        this.setState({ limit }, this.reloadUntaggedAsync);
    }

    private onChangeOffset = (offset: number) => {
        this.setState({ offset }, this.reloadUntaggedAsync);
    }

    private onBookChanged(event: any) {
        this.setState({ selectedBookId: event.target.value ? Number(event.target.value) : undefined }, this.reloadUntaggedAsync)
    }
    private OnFilterSelectChanged(event: any) {
        this.setState({ selectedFilterId: event.target.value}, this.reloadUntaggedAsync)
    }
    private onHasAltTagChanged(event:any)
    {
        this.setState({withoutAltTag:event.target.checked},this.reloadUntaggedAsync)
    }
 

    render() {
        const header = <div className="row">
            <div className="shrink column">
                <label className="middle" htmlFor="image-select-filter">{__("Fájlnév tartalmazza")}:</label>
            </div>
            <div className="column">
                <input value={this.state.filter} placeholder={__("Keresés fájlnévre")} type="text" id="untaggedImgSearch" onChange={this.onUntaggedFilterChange.bind(this)} onKeyPress={this.onUntaggedFilterKeyPress.bind(this)} />
            </div>              
            <div className="shrink column"><button className="button small middle" onClick={this.reloadUntaggedAsync.bind(this)}>
                <i className="fa fa-sync" /></button>
            </div>

        </div>;

        return <div>        

            <div className="row">
            <div className="columns large-6 small-12">
                    <label htmlFor="bookIdSelector">{__("Csak ebben a könyvben")}</label>
                    <select disabled={this.state.loading} name="bookIdSelector" onChange={this.onBookChanged.bind(this)}>
                        <option value="">{__("[ Összes ]")}</option>
                        {
                            this.state.books.map(book => {
                                if (!book.name) return;
                                return <option key={book.id} value={book.id}>{book.name}</option>
                            })
                        }
                    </select>
                </div>
                <div className="columns large-6 small-12">
                    <label htmlFor="filterSelect">{__("Képaláírás és az alt szöveg egyezés")}</label>
                    <select disabled={this.state.loading} name="filterSelect" onChange={this.OnFilterSelectChanged.bind(this)}>
                        <option key={0} value={0}>[ {__("Minden elem")} ]</option>
                        <option key={1} value={1}>[ {__("Ugyanaz a képaláírás és az alt szöveg")} ]</option>
                        <option key={2} value={2}>[ {__("Nem ugyanaz a képaláírás és az alt szöveg")} ]</option>
                    </select>
                </div>                
            </div>
            <div className="row">
            <div className="shrink column">
                <input type="checkbox" disabled={this.state.loading} id="withoutkeywords" name="withoutkeywords" onChange={this.onHasAltTagChanged.bind(this)}/><label htmlFor="withoutkeywords">{__("Alt tag nélküliek")}</label>
                </div>
            </div>
            {header}

            {this.state.files
            ?<span style={{ fontWeight: "bold", fontSize: "15px" }}>{__("Összesen")}{" "+this.state.count + " " +__("találat")}</span>
            :""
            }
            
            <FileManagerPager
                disabled={this.state.loading}
                limit={this.state.limit}
                offset={this.state.offset}
                onChangeLimit={this.onChangeLimit}
                onChangeOffset={this.onChangeOffset}
                pageItemCount={this.state.files && this.state.files!.length}
            />
            {
                !this.state.files
                    ? <div className="row"><BubbleLoader /></div>
                    : <table className="table striped">
                        <thead>
                            <tr>
                                <td>{__("Kép")}</td>
                                <td style={{width:"90%"}}>{__("Adatok")}</td>
                            </tr>
                        </thead>
                        {
                            this.state.files.map(f => {
                                return <AltTaggerTableRow key={f.id} section={f} onReload={this.reloadUntaggedAsync.bind(this)} />;
                            })
                        }
                    </table>
            }

            <FileManagerPager
                disabled={this.state.loading}
                limit={this.state.limit}
                offset={this.state.offset}
                onChangeLimit={this.onChangeLimit}
                onChangeOffset={this.onChangeOffset}
                pageItemCount={this.state.files && this.state.files!.length}
            />
            {
                !this.state.loading && this.state.files && this.state.files.length == 0
                    ?
                    __("Nincs találat")
                    :
                    null
            }
        </div>;
    }
}

type AltTaggerTableRowProps = {
    section:IViewDocSectionFileRefRecord
   
    onReload: () => void;
}

type AltTaggerTableRowState = {
    showSettingsDialog: boolean;
    file?: IOoFileRecord;
}
class AltTaggerTableRow extends React.Component<AltTaggerTableRowProps, AltTaggerTableRowState> {
    constructor(props: AltTaggerTableRowProps) {
        super(props);

        this.state = {
            showSettingsDialog: false
        }
    }

    async showDialog() {
        if(this.props.section.oo_file_id)
        {
        let file=(await OoFileCrud.load(this.props.section.oo_file_id)).record;
        this.setState({file, showSettingsDialog: true});
        }
    }

    render() {
        const f = this.props.section;

        const src = "/api/media/thumb/200/" + f.file_sha1;

        const thumbnail = <img draggable={false} src={src} onClick={this.showDialog.bind(this)} style={{ cursor: "pointer" }} />;  

        const link = PATH_EDIT_BOOK_SECTIONS + "/" + this.props.section.book_id + "/" + this.props.section.lesson_id;

        return <tbody>
            {
                this.state.showSettingsDialog && this.state.file
                    ?
                    <Dialog title={__("Állomány részletei - {details}", {details: this.props.section.title})} onClose={() => this.setState({ showSettingsDialog: false })} width={1000} height={700}>
                        <FileDataTabs
                            file={this.state.file}
                            allowRestoreVersion={true}
                            allowModifications={true}
                            onClose={() => this.setState({ showSettingsDialog: false }, this.props.onReload)}
                        />
                    </Dialog>
                    :
                    null
            }
            <tr>
                <td rowSpan={2} style={{ width: "210px" }}>
                    {thumbnail}
                </td>
                <td colSpan={2}>
                    <strong>{f.book_name} / {f.chapter_name} / {f.lesson_name}</strong> / {f.file_title} <br />
                    Alt: {f.alt ? <span >{f.alt}</span> : <span style={{color:"red"}}>{__("Nincs megadva")}</span>}<br />
                    Title: {f.title ? <span >{f.title}</span> : <span style={{color:"orange"}}>{__("Nincs megadva")}</span>}
                    <br />
                    <Link to={link} target="_blank">{__("Lecke szerkesztése")}</Link>
                </td>
            </tr>
        </tbody>
    }
}
