import * as React from 'react';
import { app } from '@src/index';
import { Resource, TRANSLATION_RESOURCE_FILE_PATH, getModules, MODULES } from '../translationModule/Translation';
import { BubbleLoader } from 'react-css-loaders';
import ReactTable from 'react-table';

type UntranslatedTextInCodeState = {
    resources?: Resource;
    selectedModule?: string;
}

export class UntranslatedTextInCode extends React.Component<any, UntranslatedTextInCodeState> {

    constructor(props: any) {
        super(props);

        this.state = {

        }
    }

    componentDidMount() {
        this.reloadAsync();
    }

    private async reloadAsync() {
        try {
            const response = await fetch(TRANSLATION_RESOURCE_FILE_PATH);
            const resources = await response.json() as Resource;

            for(const res of Object.values(resources.untranslatedTexts)) {
                res.modules = getModules(res.sources.map(s => s.path));
            }

            this.setState({resources});

        } catch(e) {
            app.showErrorDebounced(e);
        }
    }

    render() {
        if (!this.state.resources) {
            return <BubbleLoader/>;
        }

        let data: any[] = [];
        for(const untranslatedText in this.state.resources.untranslatedTexts) {
            const value = this.state.resources.untranslatedTexts[untranslatedText];
            
            const modules = getModules(value.sources.map(s => s.path));
            if (this.state.selectedModule && !modules.includes(this.state.selectedModule)) {
                continue;
            }

            let sources = value.sources.map(s => s.path);
            if (this.state.selectedModule) {
                sources = sources.filter(s => getModules([s]).includes(this.state.selectedModule!));
            }

            data.push({
                text: untranslatedText,
                sources: sources.join(", "),
                module: modules
            });
        }

        return <div>
            <h5>Az alábbi helyeken hiányzik a __("") függvényhívás:</h5>
            <select 
                value={this.state.selectedModule} 
                onChange={e => this.setState({selectedModule: e.target.value})}
                style={{width: "20em", margin: "0.5em 1em"}}
                >
                <option value="">All</option>
                {
                    Object.keys(MODULES).sort().map(m => {
                        const count = Object.values(this.state.resources!.untranslatedTexts).filter(r => r.modules.includes(m)).length;
                        return <option value={m}>{m} [{count}]</option>;
                    })
                }
            </select>
            
            <ReactTable 
                columns={
                    [
                        {accessor: "text", Header: "Text"},
                        {accessor: "sources", Header: "Forrás"},
                        {accessor: "module", Header: "Modul", width: 200},
                    ]
                }
                defaultPageSize={10}
                filterable
                defaultFilterMethod={(filter, row, column) => row[filter.id] && row[filter.id].toLowerCase().indexOf(filter.value.toLowerCase()) != -1}
                data={data}
            />
        </div>
    }

}
