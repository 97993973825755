import * as React from 'react'
import { Link, match } from 'react-router-dom';
import { IOoFileRecord } from '@src/framework/crud/media_pub/OoFileCrud';
import * as OoFileCrudPub from '@src/framework/crud/media_pub/OoFileCrud';
import * as OoFileCrud from '@src/framework/crud/media/OoFileCrud';
import VimeoCrud, { IVimeoUploadRecord } from '@src/framework/crud/media/VimeoUploadCrud';
import ViewDocSectionFileRef, { IViewDocSectionFileRefRecord } from '@src/framework/view/doc/ViewDocSectionFileRef';
import UsrLikePlugin from '@src/component/usr/usr_like_plugin';
import { History } from 'history';
import HoverImg from '@src/component/media/HoverImg';
import { durationSecondsConverter } from '@src/component/media/DurationConverter';
import { SearchResult } from '@src/server/PublicServer';
import * as Server from '@src/server/PublicServer';
import { PATH_PUBLISHED_MEDIA_VIEW } from '@src/Routes';
import { BubbleLoader } from 'react-css-loaders';
import { MediaViewer } from './MediaViewer';
import { ICON_NOT_FOR_WEAK_KEY } from '../book/editor/SectionOptionsEditor';
import { app } from '@src/index';
import { MEDIA_TYPE_SOUND, MEDIA_TYPE_VIDEO, MEDIA_TYPE_IMAGE } from '@src/framework/file/FileActions';
import { __ } from '@src/translation';
import { getDetailForFile, ContentType } from '../filemanager/ContentUtils';
import OoFolderCrud from '@src/framework/crud/media/OoFolderCrud';

const PUBLISHED_FOLDER_ID = 99;

type WatchComponentProps = {
    match: match<{ ooFileId: string }>
    history: History
}
type WatchComponentState = {
    file?: IOoFileRecord;
    sections: IViewDocSectionFileRefRecord[];
    similarMedia: SearchResult[];
    vimeo?: IVimeoUploadRecord;
    loading:boolean;
    durationAndSha1:Server.Sha1AndDurationElement[],
    published: boolean;
}
export class WatchComponent extends React.Component<WatchComponentProps, WatchComponentState> {

    constructor(prop: any) {
        super(prop);

        this.state = {
            sections: [],
            similarMedia: [],
            loading:false,
            durationAndSha1:[],
            published: true
        };
    }

    componentDidMount() {
        this.setState({loading:true});
        this.reloadAsync();
        window.scrollTo(0, 0);
    }

    componentDidUpdate(prevProps: WatchComponentProps) {
        if (prevProps.match.params.ooFileId != this.props.match.params.ooFileId) {
            this.reloadAsync();
            window.scrollTo(0, 0);
        }
    }

    async reloadAsync() {
        try
        {
        const tryPublishedFirst = this.props.history.location.pathname.startsWith(PATH_PUBLISHED_MEDIA_VIEW);

        const fileId = parseInt(this.props.match.params.ooFileId);
        
        var file: IOoFileRecord | undefined;
        let published: boolean = false;

        if (tryPublishedFirst) {
            try {
                file = (await OoFileCrudPub.default.load(fileId)).record;
                published = true;
            } catch(e) {/* We will try the unpublished */}
        }

        if (!file) {
            const publishedFolder = (await OoFolderCrud.load(PUBLISHED_FOLDER_ID)).record;
            file = (await OoFileCrud.default.load(fileId)).record;

            if (tryPublishedFirst && (!file.relpath || !file.relpath.startsWith(publishedFolder.relpath!))) {
                throw new Error("File not found");
            }
            published = false;
        }

        var vimeo: IVimeoUploadRecord | undefined;

        if (file) {
            const response = await VimeoCrud.list({ filter: { sha1: file.sha1 }, limit: 1 });
            if (response.length > 0) {
                vimeo = response[0];
            }
        }
      
        this.setState({
            file,
            vimeo,        
            sections: [],
            published
        });

        const sections: IViewDocSectionFileRefRecord[] = await ViewDocSectionFileRef.list({
            filter: { oo_file_id: Number(fileId) },
            limit: 1
        });
        this.setState({
            sections,
           loading:false
        });
        
        if (file.id)
            this.onSearchForOffers(file.id);
    }
    catch(e)
    {
        app.showErrorFromJsonResult(e);
        this.setState({loading:false})
    }
    }

    render() {
        if(this.state.loading)
        {
            return <div className="row"> <BubbleLoader/> </div>;
        }
        if (!this.state.file) {
            return <div className="row">
              <div className="small-2 large-1 column" style={{position: "relative"}}>
                    <img src={"/img/noResultSearch/thinking.png"}  style={{ width: "13em", color: "black", position: "absolute", top: "35px", paddingRight: "15px" }} />
                </div>
                <div className="small-10 large-11 column">
                <h4>{__("A kért tartalom jelenleg nem található.")}</h4>
                </div>
          
            </div>;
        }

        var notForWeak = false;
        for (const section of this.state.sections) {
            const sectionValues = section.section_values;

            if (this.refs.video && sectionValues["src-options"] && sectionValues["src-options"]['icons'] && sectionValues["src-options"]['icons'][ICON_NOT_FOR_WEAK_KEY]) {
                notForWeak = true;
                break;
            }
        }

        var alt = this.state.sections && this.state.sections.map(s => s.alt).find(a => a !== undefined && a !== null);
        var mediaType = null;
        switch(this.state.file.media_type_id) {
            case MEDIA_TYPE_VIDEO:
                mediaType = 'video';
                break;
            case MEDIA_TYPE_SOUND:
                mediaType = 'sound';
                break;
            case MEDIA_TYPE_IMAGE:
                mediaType = 'image';
                break;
            default:
            // code block
        }

        return <div className="row mainc">
           
            <div className={!this.state.similarMedia||this.state.similarMedia.length<1?"column small-12 large-12":"column small-12 large-9"}>
                <div className="eke-media-single" data-media-type={(mediaType) ? mediaType : ''}>
                {
                    this.state.file && this.state.file.displayname &&this.state.file.media_type_id!=MEDIA_TYPE_SOUND
                        ?
                        <h4 className="eke-media-title">{this.state.file.displayname}</h4>
                        :
                        null
                }
                    <div className="eke-media-viewer">
                        <MediaViewer
                        ooFileId={parseInt(this.props.match.params.ooFileId)}
                        isPublished={this.state.published}

                        notForWeak={notForWeak}
                        alt={alt || undefined}
                        />
                        {this.state.file.id && this.state.published ?
                            <div className="eke-media-socials">
                            <UsrLikePlugin
                                    tableInfoId={OoFileCrudPub.default.TABLE_INFO_ID}
                                    recId={this.state.file.id}
                                />
                            </div>
                            : ""

                        }
                    </div>
                    <div className="eke-media-details">

                        {this.state.sections ?
                            <div className="eke-media-tooltip">
                            {this.state.sections.length>0?<span className="eke-media-tooltiptext">{__("Megnézem a hozzátartozó leckét")}</span>:"" }
                                {
                                    this.state.sections.map(s => {
                                        const url = "/tankonyv/" + s.book_uri_segment + "/" + s.lesson_uri_segment + "#section-" + s.section_id;
                                        return <div key={s.id}>

                                            <Link className="eke-video-link" to={url} key={s.id}>
                                                {s.book_name + " / " + s.chapter_name + " / " + s.lesson_name}
                                            </Link>
                                        </div>
                                    })
                                }
                            </div>
                            : ""
                        }

                        {this.state.file.id && this.state.published ?
                            <div>
                                <span className="eke-media-title">{this.state.vimeo ? __("{n} Megtekintés", {n: this.state.vimeo.plays}) : ""} </span>
                            </div>
                            : ""
                        }
                    </div>
                </div>
            </div>

            <div className="cell column small-12 large-3">
                <div className="eke-media-single-similars" data-media-type={(mediaType) ? mediaType : ''}>
                    {this.state.similarMedia.map((item) => {
                       let shaDur = this.state.durationAndSha1.find( i=>i.sha1==item.sha1);                  
                        return <SimilarVideoBlock key={item.id} item={item} durationSeconds={shaDur && shaDur.duration} plays={shaDur && shaDur.plays} />
                    })
                    }
                </div>
            </div>
        </div>
    }

    async getSearchForOffers(id: number): Promise<SearchResult[]> {

        return await Server.searchSimilar(id, true, 6);
    }
    async onSearchForOffers(id: number) {
        if(this.state.published)
        {
            const hits = (await this.getSearchForOffers(id));
            
            this.setState({ 
                similarMedia: hits,
                durationAndSha1: await Server.getDurationAndSha1(hits.map(h => h.sha1!).filter(s => s)) 
            });

        }
    }
}
export class SimilarVideoBlock extends React.Component<{ item: SearchResult,durationSeconds?:string,plays:number|undefined }> {
    render() {
        const name = this.props.item.displayname || "";
        let details = getDetailForFile(this.props.item.id, this.props.item.sha1, this.props.item.media_type_id);
        return (
            <div className="eke-media-single-similar">
                <Link to={PATH_PUBLISHED_MEDIA_VIEW + "/" + this.props.item.id} className="eke-media-link">
                    <span className="eke-media-thumb">
                        <HoverImg id={Number(this.props.item.id)} sha1={this.props.item.sha1}
                            title={name}
                            alt={this.props.item.alt_text||this.props.item.displayname||this.props.item.title||""}
                            media_type_id={this.props.item.media_type_id}
                            src={details.contentType == ContentType.FILE_UNKNOWN ? "/img/IKON_SET/document_icon.png" : undefined}
                            width={details.contentType == ContentType.FILE_UNKNOWN ? 50 : undefined}
                        />
                        <span className="eke-media-duration">
                        {this.props.durationSeconds && durationSecondsConverter(this.props.durationSeconds)}
                        </span>
                    </span>
                    <span className="eke-media-details">
                        <span className="eke-media-name">
                            {name}
                        </span>
                        <span className="eke-media-subject">
                            {this.props.item.subject_name}
                        </span>
                        <span className="eke-media-grade">
                            {this.props.item.grade_name}
                        </span>
                        <span className="eke-media-stat">
                        <span><i className="far fa-thumbs-up"></i> <span className="show-for-sr">{__("Lájkok: {n}", {n: this.props.item.likes || 0})}</span> {this.props.item.likes}</span>
                        {this.props.item.type != 'image' ?
                        <span><i className="far fa-eye"></i> <span className="show-for-sr">{__("Megtekintések: {n}", {n: this.props.plays || 0})}</span> {this.props.plays}</span>
                            : ""
                        }
                        </span>
                    </span>
                </Link>
            </div>
        );
    }
}