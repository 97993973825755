import './style.css';
import { ExerciseBaseClass, AnswerElement } from '@src/component/exercise/models/ExerciseBaseClass';
import { AExerciseEngine } from '../../models/AExerciseEngine';
import { EKEOddOneOutServer } from './EKEOddOneOutServer';
import { __ } from '@src/translation';

type OddOne = {
    key: string;
    values: AnswerElement[];
}
export interface OddOneOutData extends ExerciseBaseClass {
    options: OddOne[];
    number: number;
    orientation: boolean;
    ordered_answers: boolean;
    ordered_questions: boolean;
}

type OOOSolution = {
    key: string;
    value: number;
}

type OddOneOutUserSolution = {
    fullmatch: boolean;
    answer: OOOSolution[];
}


export class EKEOddOneOutExerciseEngine extends AExerciseEngine {
    private labels: HTMLElement[] = [];
    private rows: number = 0;
    private checkableItemsList: HTMLInputElement[] = [];
    private fullanswerlist: any[] = [];
    private indexedlabelList: any[] = [];
    private shuffledQuestionIndexes: any[] = [];
    private clearWrongAnsTimeout: any;

    shuffleQuestions(array: OddOne[]) {
        array = array.filter(function (x) { return x.key != "" }); //here deleting all the elements that was set to "" in filterExerciseOnServer
        var currentIndex = array.length, temporaryValue, randomIndex;

        // While there remain elements to shuffle...
        while (0 !== currentIndex) {

            // Pick a remaining element...
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex -= 1;

            // And swap it with the current element.
            temporaryValue = array[currentIndex];
            array[currentIndex] = array[randomIndex];
            array[randomIndex] = temporaryValue;

            //storing the shuffled indexes as well, because of evaluation
            temporaryValue = this.shuffledQuestionIndexes[currentIndex];
            this.shuffledQuestionIndexes[currentIndex] = this.shuffledQuestionIndexes[randomIndex];
            this.shuffledQuestionIndexes[randomIndex] = temporaryValue;
        }
        return array;
    }

    shuffleAnswers(array: any[]) {
        var currentIndex = array.length, temporaryValue, randomIndex;

        // While there remain elements to shuffle...
        while (0 !== currentIndex) {

            // Pick a remaining element...
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex -= 1;
            // And swap it with the current element.
            temporaryValue = array[currentIndex];
            array[currentIndex] = array[randomIndex];
            array[randomIndex] = temporaryValue;
        }

        return array;
    }


    initExercise(params: ExerciseParams): void {

        if (!params.element) return;
        if (!params.exercise) return;

        super.initExercise(params);
        let exercise: OddOneOutData = params.exercise;

        this.fullanswerlist = [];
        this.indexedlabelList = [];
        this.rows = 0;
        
        this.checkableItemsList = [];
        this.labels = [];
        this.root.classList.add("eke-oddoneout");
      
        this.setIllustration(exercise,this.root); 
        
        var exerciseContainer = this.root.appendChild(document.createElement("div"));
        exerciseContainer.setAttribute("id", "exerciseContainer");
        exerciseContainer.classList.add('table-scroll');
        //exerciseContainer.style.overflowX = "auto";

        var answer_list: OddOne[] = [];

        if (!exercise.options)          //in case of new exercise avoid error message because of the following slice method
            return;

        answer_list = exercise.options.slice();

        //store the original indexes for further shuffling
        for (let index = 0; index < exercise.options.length; index++) {
            if (exercise.options[index].key != "")      //colleting only the answers that are available (needed because filtering removes some elements)
                this.shuffledQuestionIndexes.push(index);
        }

        //shuffle questions in case we need
        if (!exercise.ordered_questions) answer_list = this.shuffleQuestions(answer_list);


        var i = 0;
        for (var answer of answer_list) {
            if (answer.values.length < 1) continue;
            var innerlist: any[] = [];
            var labelHighlight: any[] = [];
            var answerdiv = exerciseContainer.appendChild(document.createElement("div"));
            answerdiv.classList.add("row");
            var paragraph = answerdiv.appendChild(document.createElement("label"));
            paragraph.innerText = answer.key;
            paragraph.classList.add("eke-oddoneout-keyparagraph");
            if (exercise.orientation) {
                paragraph.classList.add("keyparagraph-horizontal");
                answerdiv.style.flexFlow = "initial";
            }
            paragraph.classList.add("clear", "cell", "columns");
            this.rows++;

            var oldValues = answer.values.slice();

            if (!exercise.ordered_answers)
                answer.values = AExerciseEngine.shuffle(answer.values);

            let randomIdx = Math.floor(Math.random() * (1000));
            for (var index = 0; index < answer.values.length; index++) {
                var checkableItem = answerdiv.appendChild(document.createElement("input"));
                checkableItem.setAttribute("type", "radio");
                // let curr_name = exercise.imageanswers?answer.value[index].image:answer.value[index].text;

                checkableItem.setAttribute("id", index + "_" + this.rows + "_" + randomIdx);
                checkableItem.setAttribute("name", String(this.shuffledQuestionIndexes[i]));
                checkableItem.setAttribute("data-index", String(oldValues.indexOf(answer.values[index])));
                if (this.isSNIexc) checkableItem.addEventListener('click', this.SNIEvaluation.bind(this, EKEOddOneOutServer));

                if (this.isReplay) {
                    checkableItem.setAttribute('disabled','disabled');
                }

                var label = answerdiv.appendChild(document.createElement("label"));
                label.setAttribute("for", index + "_" + this.rows + "_" + randomIdx);
                this.labels.push(label);
                if (exercise.orientation) {
                    label.classList.add("cell", "columns");
                } else {
                    label.classList.add("row");
                }
                let elementClassList:string[] = [];
                if ((answer.values[index].type && answer.values[index].type == "image")) {
                    elementClassList.push("oddoneout-image-answer");
                    if (!exercise.orientation) {
                        elementClassList.push("exe-large-img");
                    }
                }
                AExerciseEngine.displayAnswer(label, answer.values[index], this.is_accessible, elementClassList, this); 
                this.checkableItemsList.push(checkableItem);
                innerlist.push(checkableItem);
                labelHighlight.push(label);
                label.addEventListener('click', this.onClicked.bind(this), false);
            }
            this.fullanswerlist.push(innerlist);
            this.indexedlabelList.push(labelHighlight);
            i++;
        }
    }

    getUserSolution(): OddOneOutUserSolution {
        var result: any[] = [];
        for (var index = 0; index < this.checkableItemsList.length; index++) {
            var radiobutton = this.checkableItemsList[index];
            if (radiobutton.checked) {
                var sol = {
                    key: radiobutton.name,
                    value: +radiobutton.getAttribute('data-index')!
                };
                result.push(sol);
            }
        }
        let solution = { fullmatch: true, answer: result };
        return solution;
    }

    receiveEvaluation(evaluated: Evaluated): void {
        /* delete Clear Wrong Answers timeout */
        clearTimeout(this.clearWrongAnsTimeout);

        //receiving the evaluation, then shuffling it as the the answers
        var templist: any[] = [];
        for (let index = 0; index < this.shuffledQuestionIndexes.length; index++) {
            templist.push(evaluated.solution[this.shuffledQuestionIndexes[index]]);
        }
        evaluated.solution = templist;
        for (var outerIndex = 0; outerIndex < this.fullanswerlist.length; outerIndex++) {
            for (var innerIndex = 0; innerIndex < this.fullanswerlist[outerIndex].length; innerIndex++) {
                var radiobutton = this.fullanswerlist[outerIndex][innerIndex];
                var label = this.indexedlabelList[outerIndex][innerIndex];
                this.removeStyles(label);
                var ans = label.innerHTML;
                if (radiobutton.checked) {
                    if (evaluated.success) {
                        label.classList.add("exe-engine-check-correct");
                        this.accessibilityHelper(label, ans, true);
                    }
                    else {
                        if (evaluated.solution == null) {
                            if (!this.isSNIexc) {
                                label.classList.add("exe-engine-check-wrong");
                                this.accessibilityHelper(label, ans, false);
                            }
                        }
                        else {
                            let sol_item = evaluated.solution[outerIndex];
                            if (sol_item.value == radiobutton.getAttribute('data-index')) {
                                label.classList.add("exe-engine-check-correct");
                                this.accessibilityHelper(label, ans, true);

                            } else {
                                //if (!this.isSNIexc) {
                                    label.classList.add("exe-engine-check-wrong");
                                    this.accessibilityHelper(label, ans, false);
                                //}
                            }
                        }
                    }
                }
            }
        }

        /* IF SNI, clear all wrong answers with delay */
        if (this.isSNIexc) {
            this.clearWrongAnsTimeout = setTimeout(() => {
                for (var outerIndex = 0; outerIndex < this.fullanswerlist.length; outerIndex++) {
                    for (var innerIndex = 0; innerIndex < this.fullanswerlist[outerIndex].length; innerIndex++) {
                        var radiobutton = this.fullanswerlist[outerIndex][innerIndex];
                        var label = this.indexedlabelList[outerIndex][innerIndex];

                        if (label.classList.contains("exe-engine-check-wrong")) {
                            this.removeStyles(label);
                            radiobutton.checked = false;
                        }


                    }
                }
            },
            2000);
        }
    }

    accessibilityHelper(element: HTMLElement, ans: any, correct: boolean) {
        if (correct) {
            if (!element.innerHTML.includes(__("Helyes válasz"))) {
                element.innerHTML = "<span class='show-for-sr'>" + __("Helyes válasz") +": " + "</span>" + ans;
            }
        } else {
            if (!element.innerHTML.includes(__("Helytelen válasz"))) {
                element.innerHTML = "<span class='show-for-sr'>" + __("Helytelen válasz")+ ": "  + "</span>" + ans;
            }
        }
    }

    showCorrectSolution(solution: any): void {
        /* Removing previous classes */
        for (var outerIndex = 0; outerIndex < this.fullanswerlist.length; outerIndex++) {
            for (var innerIndex = 0; innerIndex < this.fullanswerlist[outerIndex].length; innerIndex++) {
                var item = this.indexedlabelList[outerIndex][innerIndex];
                this.removeStyles(item);
            }
        }

        /* Reset radio buttons */
        for (var index = 0; index < this.checkableItemsList.length; index++) {
            var option = this.checkableItemsList[index];
            option.checked = false;
        }

        //receiving the evaluation, then shuffling it as the the answers
        var templist: any[] = [];
        for (let index = 0; index < this.shuffledQuestionIndexes.length; index++) {
            templist.push(solution[this.shuffledQuestionIndexes[index]])
        }
        solution = templist;
        for (var index = 0; index < solution.length; index++) {
            for (var inner = 0; inner < this.checkableItemsList.length; inner++) {
                let sol_item = solution[index];
                if (+this.checkableItemsList[inner].id.split("_", 3)[1] - 1 == index && sol_item.value == this.checkableItemsList[inner].getAttribute('data-index')) {
                    var label = this.labels[inner];
                    var ans = label.innerHTML;
                    this.checkableItemsList[inner].checked = true;
                    label.classList.add("eke-engine-show-correct-bg");
                    this.accessibilityHelper(label, ans, true);
                }
            }
        }
    }

    isUserReady(): boolean {

        for (var index = 0; index < this.checkableItemsList.length; index++) {
            var radiobutton = this.checkableItemsList[index];
            if (radiobutton.checked) {
                return true;
            }
        }
        return false;
    }
    showHelp(solution: any): void {
        this.showCorrectSolution(solution);
    }
    removeStyles(element: HTMLElement): void {
        element.classList.remove("exe-engine-correct-bg", "exe-engine-wrong-bg", "eke-engine-show-correct-bg", "eke-engine-checked-bg", "exe-engine-check-wrong", "exe-engine-check-correct");
    }
    onClicked(ev: any) {
        var curr_element = ev.target as HTMLElement;
        this.removeStyles(curr_element);
    }

}