import * as React from 'react';

declare var Foundation: any;
export class FAccordion extends React.Component<{}, {}> {


    componentDidMount() {
        ($(this.refs.accordion) as any).foundation();
    }

    componentDidUpdate(prevProps: any) {
        if (Array.isArray(this.props.children) && Array.isArray(prevProps.children) && this.props.children.length != prevProps.children.length) {
            try {
                (Foundation as any).reInit(($(this.refs.accordion) as any));
            } catch (e) {
                console.error(e);
            }
        }
    }

    render() {
        return <ul ref="accordion" className="accordion" data-accordion data-multi-expand="true" data-allow-all-closed="true" style={{ width: "100%" }}>
            {this.props.children}
        </ul>;
    }
}

const accContext: React.Context<{ accordion?: Accordion, openItem?: any }> = React.createContext({ accordion: undefined, openItem: undefined }) as any;
export class Accordion extends React.Component<{ allowAllClosed?: boolean, multiExpand?: boolean }, { openItem?: any }> {

    public static defaultProps = {
        allowAllClosed: true,
        multiExpand: true
    };

    public accordionElement: HTMLElement | null;

    constructor(props: any) {
        super(props);

        this.state = {
            openItem: null
        }
    }


    hideAllOther(element: any) {

        this.setState({ openItem: element })

    }

    render() {
        return <ul ref={(accordion) => { this.accordionElement = accordion }} className="accordion eke" style={{ width: "100%" }}>

            <accContext.Provider value={{ accordion: this, openItem: this.state.openItem }}>
                {this.props.children}
            </accContext.Provider>
        </ul>;
    }
}
export class FAccordionItem extends React.Component<{ title: string | JSX.Element, defaultClosed?: boolean }, {}> {

    render() {
        return <li className={"accordion-item " + (this.props.defaultClosed ? "" : "is-active")} data-accordion-item>
            <a className="accordion-title exercise-editor-accordion-title"><i className="accordion-icon"></i> {this.props.title}</a>
            <div className="accordion-content callout primary" data-tab-content>
                {this.props.children}
            </div>
        </li>
    }
}

interface IAccordionProps {
    title: string | JSX.Element;
    defaultClosed?: boolean;
    className?: string;
}

export class AccordionItem<P extends IAccordionProps, S = {}> extends React.Component<P, {}> {
    protected accordionContent: HTMLElement | null;
    protected accordionTitle: HTMLElement | null;
    protected accordionItem: HTMLElement | null;

    // Show an element
    protected show() {

        const itemContent = this.accordionContent!;
        const accordionTitle = this.accordionTitle!;
        const accordionItem = this.accordionItem!;

        var getHeight = function () {
            itemContent.style.display = 'block';
            var height = itemContent.scrollHeight + 'px';
            itemContent.style.display = '';
            return height;
        };

        var height = getHeight();
        itemContent.classList.add('is-visible');
        accordionItem.classList.add('is-active');
        itemContent.style.height = height;
        accordionTitle.setAttribute('aria-expanded', 'true');

        window.setTimeout(function () {
            itemContent.style.height = '';
            itemContent.style.overflow = 'visible';
        }, 350);

    };

    // Hide an element
    public hide() {
        const itemContent = this.accordionContent!;
        const accordionTitle = this.accordionTitle!;
        const accordionItem = this.accordionItem!;
        itemContent.style.height = itemContent.scrollHeight + 'px';

        window.setTimeout(function () {
            itemContent.style.height = '0';
            itemContent.style.overflow = 'hidden';
        }, 1);

        window.setTimeout(function () {
            itemContent.classList.remove('is-visible');
            accordionItem.classList.remove('is-active');
            accordionTitle.setAttribute('aria-expanded', 'false');
        }, 350);

    };

    // Toggle element visibility
    protected onToggle(accordion: Accordion) {
        const itemContent = this.accordionContent!;

        if (!accordion.props.multiExpand) {
            accordion.hideAllOther(this);
        }

        if (itemContent.classList.contains('is-visible')) {
            if (accordion.props.allowAllClosed || accordion.accordionElement!.querySelectorAll('is-visible').length > 1) {
                this.hide();
            }

            return;
        }

        this.show();

    };

    render() {
        return <accContext.Consumer>
            {
                ({ accordion, openItem }) => {

                    var isOpen = (openItem
                        ? openItem == this ? true : false
                        : this.props.defaultClosed ? false : true
                    );

                    return <li ref={(item) => { this.accordionItem = item; }} className={"accordion-item " + (this.props.className ? this.props.className : "") + (isOpen ? "is-active" : "")}>
                        <a href="javascript:void(0)" ref={(title) => { this.accordionTitle = title; }} onClick={this.onToggle.bind(this, accordion)} className="accordion-title" aria-expanded={isOpen ? true : false}><i className="accordion-icon"></i> {this.props.title}</a>
                        <div ref={(content) => { this.accordionContent = content; }} className={"accordion-content callout primary" +
                            (isOpen ? " is-visible" : "")} style={{overflow: (isOpen ? "visible" : "hidden")}}>
                            {this.props.children}
                        </div>
                    </li>
                }
            }
        </accContext.Consumer>;
    }
}

export enum AccordionActionType {
    Trash,
    Up,
    Down,
    Copy
}
export interface IAccordionAction {
    title: string;
    type: AccordionActionType;
    action: () => void;
}

export class FActionAccordionItem extends React.Component<{
    title: string | JSX.Element;
    actions: IAccordionAction[];
    defaultClosed?: boolean;
}, {}> {

    render() {
        return <li className={"accordion-item " + (this.props.defaultClosed ? "" : "is-active")} data-accordion-item>
            <a ref="accrodionTitle" className="accordion-title exercise-editor-accordion-title"><i className="accordion-icon"></i> {this.props.title}
                {
                    this.props.actions.map((item: IAccordionAction, i: number) => {

                        const button = item.type == AccordionActionType.Trash ?
                            <button className="button small alert exercise-series-small-btn" title={item.title} onClick={item.action.bind(this)}><i className="fa fa-trash"></i></button>
                            :
                            item.type == AccordionActionType.Copy ?
                                <button className="button small success exercise-series-small-btn" title={item.title} onClick={item.action.bind(this)}><i className="fa fa-copy"></i></button>
                                :
                                item.type == AccordionActionType.Up ?
                                    <button className="button small secondary exercise-series-small-btn" title={item.title} onClick={item.action.bind(this)}><i className="fa fa-arrow-up"></i></button>
                                    :
                                    item.type == AccordionActionType.Down ?
                                        <button className="button small secondary exercise-series-small-btn" title={item.title} onClick={item.action.bind(this)}><i className="fa fa-arrow-down"></i></button>
                                        :
                                        null
                        return <div key={i} className="float-right">{button}</div>;

                    })
                }
            </a>
            <div className="accordion-content callout primary" data-tab-content>
                {this.props.children}
            </div>

        </li>
    }
}

interface IActionAccordionProps extends IAccordionProps {

    actions: IAccordionAction[];
}

export class ActionAccordionItem extends AccordionItem<IActionAccordionProps, {}> {


    handleClick = (item: any, e: any) => {
        e.stopPropagation();
        item.action();
    }


    render() {
        return <accContext.Consumer>
            {
                ({ accordion, openItem }) => {

                    var isOpen = (openItem
                        ? openItem == this ? true : false
                        : this.props.defaultClosed ? false : true
                    );

                    return <li ref={(item) => { this.accordionItem = item; }} className={"accordion-item " + (this.props.className ? this.props.className : "") + (isOpen ? "is-active" : "")}>
                        <a href="javascript:void(0)" ref={(title) => { this.accordionTitle = title; }} onClick={this.onToggle.bind(this, accordion)} className="accordion-title" aria-expanded={isOpen ? true : false}>
                            <i className="accordion-icon"></i> {this.props.title}
                            <div className="accordion-actions">
                                {
                                    this.props.actions.map((item: IAccordionAction, i: number) => {

                                        const button = item.type == AccordionActionType.Trash ?
                                            <button key={i} className="button small alert exercise-series-small-btn" title={item.title} onClick={this.handleClick.bind(this, item)}><i className="fa fa-trash"></i></button>
                                            :
                                            item.type == AccordionActionType.Copy ?
                                                <button key={i} className="button small success exercise-series-small-btn" title={item.title} onClick={this.handleClick.bind(this, item)}><i className="fa fa-copy"></i></button>
                                                :
                                                item.type == AccordionActionType.Up ?
                                                    <button key={i} className="button small secondary exercise-series-small-btn" title={item.title} onClick={this.handleClick.bind(this, item)}><i className="fa fa-arrow-up"></i></button>
                                                    :
                                                    item.type == AccordionActionType.Down ?
                                                        <button key={i} className="button small secondary exercise-series-small-btn" title={item.title} onClick={this.handleClick.bind(this, item)}><i className="fa fa-arrow-down"></i></button>
                                                        :
                                                        null
                                        return button;

                                    })
                                }
                            </div>
                        </a>
                        <div ref={(content) => { this.accordionContent = content; }} className={"accordion-content callout primary" +
                            (isOpen ? " is-visible" : "")} style={{overflow: (isOpen ? "visible" : "hidden")}}>
                            {this.props.children}
                        </div>
                    </li>
                }
            }
        </accContext.Consumer>;

    }
}
