import * as ExerciseBaseTypes from "@src/component/exercise/models/ExerciseBaseClass";
import { AExerciseTypeConverter, ValidationResponse } from "@src/component/exercise/models/AExerciseTypeConverter";
import { FillGapData } from "@src/component/exercise/engine/EKEFillGapDropDownExerciseEngine/EKEFillGapDropDownExerciseEngine";
import * as React from "react";
import { Panel } from '@src/component/ui/Panel';
import { __ } from '@src/translation';
import { FillGapTextData } from "./FillGapTextExerciseEngine";

export type FillGapTextAnswerElement = {
    case_sensitive: boolean,
    gap_text: string,
    textbox_width: number
}

export class FillGapTextConverter extends AExerciseTypeConverter {

    public hasTextAnswer = true;
    public hasImageAnswer = false;
    public hasTextImageAnswer = false;
    static MAX_OPTIONS_NUMBER: number = 25;
    static MIN_TB_WIDTH: number = 1;
    static MAX_TB_WIDTH: number = 10;
    static DEF_TB_WIDTH: number = 5;
    static MAX_GAP_TEXT_LENGTH:number = 2500;
 
    render() {
        let curr_ex: FillGapTextAnswerElement = this.state.exercise as FillGapTextAnswerElement;
        return (
            <Panel header={__("Részletek")} headingLevel={4} >
                <div className="row">
                    <div className="large-12 columns">
                        <label ><input type="checkbox" name="case_sensitive" checked={curr_ex.case_sensitive || false}
                            onBlur={this.onBlurEvent.bind(this)}
                            onChange={this.handleInputChange.bind(this)} />
                            {__("Kis és nagybetű érzékeny")}</label>
                    </div>
                    <div className="large-12 columns">
                        <label >{__("Szövegdobozok szélessége")}</label><input type="number"
                            min={FillGapTextConverter.MIN_TB_WIDTH}
                            max={FillGapTextConverter.MAX_TB_WIDTH}
                            name="textbox_width"
                            value={curr_ex.textbox_width || FillGapTextConverter.DEF_TB_WIDTH}
                            onBlur={this.onBlurEvent.bind(this)}
                            onChange={this.handleInputChange.bind(this)} />
                            
                    </div>
                </div>
                <div className="row">
                    <div className="large-12 columns">
                        <label>{__("A kiegészítendő szöveg")}
                            <textarea name={"gap_text"} value={curr_ex.gap_text}
                                rows={8}
                                onBlur={this.onBlurEvent.bind(this)}
                                onChange={this.handleInputChange.bind(this)} />
                        </label>
                        <label className="exe-editor-label-description">{__("A hiányzó szöveget # # szimbólumok között kell megadni pl: \"A #víz# folyékony halmazállapotú, míg a #jég# szilárd. Továbbá a #víz# forráspontja 100°C. \" Több helyes megoldás esetén a megoldásokat \";\"-vel elválasztva kell megadni, pl: \"A labda #piros;vörös;pöttyös# volt.\"")}</label>
                    </div>
                </div>
            </Panel>
        );
    }

    public convertToEditorAnswer(exercise: FillGapTextData): FillGapTextAnswerElement | undefined {

        if (exercise) {

            const pattern = /#\d+#/g;
            let numOfGaps = 0;
            let result = null;
            let text = exercise.gap_text;

            while ((result = pattern.exec(exercise.gap_text))) {
                let gapAnswers: string[] = exercise.solution[numOfGaps];
                let fromIndex = text.indexOf(result.toString());
                text = text.substr(0, fromIndex) + '#' + gapAnswers.join(';') + '#' + (text.substr(fromIndex + result.toString().length, text.length));
                numOfGaps++;
            }
            const answerElement: FillGapTextAnswerElement = {
                gap_text: text,
                case_sensitive: exercise.case_sensitive,
                textbox_width: exercise.textbox_width
            }
            return answerElement;
        }

        else return undefined;
    }

    public convertToJson(exerciseDetails: FillGapTextAnswerElement, baseData: ExerciseBaseTypes.ExerciseBaseClass, prevJSON?: FillGapTextAnswerElement): FillGapData | undefined {
        if (exerciseDetails) {

            const pattern = /#.*?#/g;
            let numOfGaps = 0;
            let text = exerciseDetails.gap_text;
            let result = null;
            let solution: string[][] = [];
            // Bejárjuk a szöveget, amíg van #...# a szövegben és kiszedjük az értékeket és belerakjuk a solution tömbbe.
            while ((result = pattern.exec(exerciseDetails.gap_text))) {
                let currentList: string[] = result.toString().slice(1, result.length - 2).split(";");
                solution.push(currentList);
                text = text.replace(result.toString(), '#' + numOfGaps + '#');
                numOfGaps++;
            }

            let cur_exercise = ExerciseBaseTypes.convertToBaseJson(baseData);

            cur_exercise = {
                ...cur_exercise,
                solution: solution,
                gap_text: text,
                case_sensitive: exerciseDetails.case_sensitive,
                textbox_width: exerciseDetails.textbox_width
            };
            return cur_exercise;
        }
        else return undefined;
    }

    public validate(editorAnswer: FillGapTextAnswerElement, baseData: any, validationMap?: Map<string, string>, is_accessible?: boolean | null): ValidationResponse {
        let superAnswer: ValidationResponse = super.validate(editorAnswer, baseData, validationMap, is_accessible);
        if (!superAnswer.valid) return superAnswer;
        
        let errorMsg = "";
        let OFIErrors: string[] = [];
        let convertedExc = this.convertToJson(editorAnswer, baseData);
        
        if (convertedExc) {
            if (convertedExc.solution.length < 2)
                return { valid: false, message: __("Minimum {min} elem megadása kötelező!",{min:2}) }
            if (convertedExc.solution.length > FillGapTextConverter.MAX_OPTIONS_NUMBER) {
                errorMsg = __("Maximum {max} elem adható meg!", { max: FillGapTextConverter.MAX_OPTIONS_NUMBER });
                if (!OFIErrors.includes(errorMsg, 0)) OFIErrors.push(errorMsg);
                if (!AExerciseTypeConverter.isOfiEditor) return { valid: false, message: errorMsg }
            }
            if (convertedExc.gap_text.length > FillGapTextConverter.FILLGAP_GAP_TEXT_LENGTH) {
                errorMsg = __("A szöveg hossza maximum {FILLGAP_GAP_TEXT_LENGTH} karakter lehet.", { FILLGAP_GAP_TEXT_LENGTH: FillGapTextConverter.MAX_GAP_TEXT_LENGTH });
                if (!OFIErrors.includes(errorMsg, 0)) OFIErrors.push(errorMsg);
                if (!AExerciseTypeConverter.isOfiEditor) return { valid: false, message: errorMsg }
            }
        }

        if (AExerciseTypeConverter.isOfiEditor) return { valid: true, message: OFIErrors.join(' , ') }
        
        return { valid: true }
    }

}