import { Server } from '../../server/Server';
import Crud , { registerCrudClassProxy, IAsTextResult, IListParams, CrudClassProxy, ISearchTextParams, ISearchTextResult } from '../Crud';

export interface IClassVisitRecord {
    /**
     * Globálisan egyedi azonosító.
     * 
     * Ha új rekordot akarsz létrehozni put() metódussal, akkor hagyd üresen.
     * upsert() hívásnál figyelmen kívül van hagyva az értéke.
     *
     */
    id ?: number;
    /**
     * Rekord létrehozás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel létrehozásakor.
     */
    creation_time?: string /*timestamp?*/;
    /**
     * Ki hozta létre
     *
     * NOT NULL -> sys.sec_user ON DELETE noaction
     *
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozó felhasználóra hivatkozik.
     */
    creation_user_id?: number;
    /**
     * Melyik session-ből jött létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozáshoz használt munkamenetre hivatkozik.
     */
    creation_session_id?: number|null;
    /**
     * Utolsó módosítás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel módosításakor.
     */
    modification_time?: string /*timestamp?*/|null;
    /**
     * Ki módosította utoljára
     *
     * -> sys.sec_user ON DELETE noaction
     *
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosító felhasználóra hivatkozik.
     */
    modification_user_id?: number|null;
    /**
     * Melyik session-ből módosították utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosításhoz használt munkamenetre hivatkozik.
     */
    modification_session_id?: number|null;
    /**
     * Aktív
     *
     * Logikai törléshez használt mező.
     */
    is_active?: boolean;
    /**
     * KIP/Komplex intézmény látogatás
     *
     * NOT NULL -> kap.dfht_visit ON DELETE cascade
     *
     */
    dfht_visit_id?: number;
    /**
     * Óraszám
     */
    lesson_no?: number /*integer*/|null;
    /**
     * Kezdő időpont
     */
    start_date?: string /*timestamp?*/;
    /**
     * Befejező időpont
     */
    end_date?: string /*timestamp?*/;
    /**
     * Intézmény feladat ellátási hely
     *
     * -> sys.institute_site ON DELETE noaction
     *
     */
    institute_site_id?: number|null;
    /**
     * Terem szám
     */
    room_no?: string|null;
    /**
     * Óra látogatás típusa
     *
     * NOT NULL -> kap.class_visit_type ON DELETE noaction
     *
     */
    class_visit_type_id?: number;
    /**
     * Óratartó pedagógus
     *
     * NOT NULL -> sys.sec_user ON DELETE noaction
     *
     */
    teacher_id?: number;
    /**
     * KIP/Komplex óra látogatás gyökér könyvtára.
     *
     * NOT NULL -> media.oo_folder ON DELETE noaction
     *
     */
    oo_folder_id?: number;
    /**
     * Témakör
     */
    topic?: string|null;
    /**
     * Osztály
     *
     * Az osztály neve, szövegesen. Pl. '2/a'
     */
    class_name?: string|null;
    /**
     * Tantárgy
     *
     * -> doc.subject ON DELETE noaction
     *
     */
    subject_id?: number|null;
    /**
     * DFHT (alap és komplex) óraterv
     *
     * -> kap.lesson_draft ON DELETE noaction
     *
     */
    lesson_draft_id?: number|null;
    /**
     * DFHT KIP óraterv
     *
     * -> kap.kip_draft ON DELETE noaction
     *
     */
    kip_draft_id?: number|null;
}

/**
 *  kap.class_visit - KIP/Komplex óra látogatás
 *
 *  Idősoros táblázat, ami az összes tétel összes korábbi verzióját eltárolja.
 */

export default class ClassVisitCrud extends Crud<IClassVisitRecord> {
    public static TABLE_INFO_ID = 3742466852;
    public static getSchemaNameForClass() { return 'kap'; }
    public static getTableNameForClass() { return 'class_visit'; }

    public static load: (id: number, aServer ?: Server) => Promise<ClassVisitCrud>;
    public static asText: (id: number, includeRecord : boolean, aServer ?: Server) => Promise<IAsTextResult<IClassVisitRecord>>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<IClassVisitRecord[]>;
    public static searchText: (params: ISearchTextParams, aServer ?: Server) => Promise<ISearchTextResult<IClassVisitRecord>[]>;
    public static deleteById: (id: number, aServer ?: Server) => Promise<IClassVisitRecord>;
    public static unDeleteById: (id: number, aServer ?: Server) => Promise<IClassVisitRecord>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class ClassVisitCrudClassProxy extends CrudClassProxy<IClassVisitRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const classVisitCrudClassProxy = new ClassVisitCrudClassProxy(ClassVisitCrud);
registerCrudClassProxy(classVisitCrudClassProxy);
