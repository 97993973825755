import * as React from 'react';
import { BubbleLoader } from 'react-css-loaders';
import axios from 'axios';
import { app } from '@src/index';
import { me, hasGroup, Groups, api } from '@framework/server/Auth';
import { config } from '@framework/server/Server';
import { __ } from '@src/translation';

export type IWitnessActivitySearchResult = {
    ActivityID: number,
    Title: string,
    Description: string,
    Type: number,
    TypeLabel: string,
    Author: string,
    Grade: string,
    Standard: string,
    Subject: string,
    ImageURL: string,
    PageURL: string,
    SSOAccessToken: string;
    UserKey: string;
}

export type Topic = {
    PageURL: string,
    ID: number,
    Name: string
}

export type IWitnessClipSearchResult = {
    ClipID: number,
    Label: string,
    Description: string,
    Length: string,
    ThumbnailURL: string,
    IntCode: number,
    ExperienceGroup: string,
    DateOfBirth: Date,
    DateOfBirthText: string,
    CityOfBirth: string,
    CountryOfBirth: string,
    InterviewCity: string,
    InterviewState: string,
    InterviewCountry: string,
    InterviewDate: Date,
    InterviewDateText: string
    SSOAccessToken: string;    
    Topics: Topic[];
    UserKey: string;    
}
interface IWitnessSearchComponentProps {
    keyword: string;
    type: string;
}

type IWitnessSearchComponentState = {
    activityResults: IWitnessActivitySearchResult[] | null;
    clipResults: IWitnessClipSearchResult[] | null;
    loading: boolean;
}

export default class IWitnessSearchComponent extends React.Component<IWitnessSearchComponentProps, IWitnessSearchComponentState> {

    constructor(props: IWitnessSearchComponentProps) {
        super(props);
        this.state = { activityResults: null, clipResults: null, loading: true }

        this.onSearch();
    }
    
    componentDidUpdate(prevProps: IWitnessSearchComponentProps, prevState: IWitnessSearchComponentState) {
        if (prevProps.keyword != this.props.keyword) {            
            this.onSearch();
        }

        if (prevProps.type != this.props.type) {
            this.onSearch();
        }

    }

    async onSearch() {
        var keywordParam;

        if (this.props.keyword)
            keywordParam = "&keywords=" + this.props.keyword
        else 
            keywordParam = "";

        var url = config!.searchServer!.apiBaseUrl + "iwitness?type=" + this.props.type + keywordParam;

        this.setState({loading: true});
        try {

            const response = await axios.get(url, { headers: api ? api.getAuthorizationHeader() : null });

            if (this.props.type === "clip")
                this.setState({
                    clipResults: response.data
                });
            else
                this.setState({
                    activityResults: response.data
                });        
            
            this.setState({loading: false});
        } catch (error) {
            app.showErrorFromJsonResult(error);
        }
    }    

    render() {

        if (this.state.loading) {
            return <BubbleLoader />;
        }   

        const aResults = this.state.activityResults;
        const cResults = this.state.clipResults;

        if (me && me.id) {
            const userId = me.id;
            
            let role: string;

            if (hasGroup(me, Groups.Teacher))
                role = "teacher"
            else if (hasGroup(me, Groups.Student))
                role = "student";
            else 
                role = "";

            if (this.props.type === "activity") {
                if ((aResults !== null) && (aResults.length > 0)) {
                    return <div className="row eke-searchresult">
                        <h5>{__("IWitness találatok")}</h5>

                        <div className="columns small-12 medium-12 large-12">
                            <div className="row">
                                {aResults.map((item) => {                                                       
                                    return  <div key={item.ActivityID} className="columns small-12 medium-4 large-3">                                                                                                             
                                        <a href={                                            
                                            "https://iwitness.usc.edu/sfi/Account/OFI/OFIGateway.aspx?" +
                                            "token=" + item.SSOAccessToken +
                                            "&nexturl=" + item.PageURL +
                                            "&userid=" + userId +
                                            "&userkey=" + item.UserKey +
                                            "&role=" + role
                                            } target="_new" key={item.ActivityID}>

                                            <img src={item.ImageURL} title={item.Title} /> 
                                            <br/>
                                            <small>
                                            {item.Title}                                     
                                            </small>
                                        </a>
                                </div>
                                }
                                )}
                            </div>    
                        </div>
                    </div>
                } else {
                    return <div className="eke-searchresult">{__("Nincs IWitness találat a következő kifejezésre")}: <b>{this.props.keyword}</b></div>;
                }
            } else {
                if ((cResults !== null) && (cResults.length > 0)) {
                    return <div className="row eke-searchresult">
                        <h5>{__("IWitness találatok")}</h5>

                        <div className="columns small-12 medium-12 large-12">
                            <div className="row">
                                {cResults.map((item) => {                       
                                    return <div key={item.ClipID} className="columns small-12 medium-4 large-3"> 
                                        <a href={                                            
                                            "https://iwitness.usc.edu/sfi/Account/OFI/OFIGateway.aspx?" +
                                            "token=" + item.SSOAccessToken +
                                            "&nexturl=" + item.Topics[0].PageURL +
                                            "&userid=" + userId +
                                            "&userkey=" + item.UserKey +
                                            "&role=" + role
                                            } target="_new" key={item.ClipID}>
                                            <img src={item.ThumbnailURL} title={item.Label} /> 
                                            <br/>
                                            <small>
                                            {item.Label} 
                                            <br/> 
                                            {item.Topics[0].Name}
                                            <br/> 
                                            {__("Időtartam")}: {item.Length}
                                            </small>                                    
                                        </a>
                                </div>
                                }
                                )}
                            </div>    
                        </div>
                    </div>
                } else {
                    return <div className="eke-searchresult">{__("Nincs IWitness találat a következő kifejezésre")}: <b>{this.props.keyword}</b></div>;
                }
            }
        } else { return <div className="eke-searchresult">{__("Bejelentkezés szükséges az IWitness tartalom kereséséhez!")}</div>; }
    }    

}