
import * as React from 'react';
import { match } from 'react-router';
import BlockTemplateCrud, { IBlockTemplateRecord } from '@src/framework/crud/doc/BlockTemplateCrud';
import { app } from '@src/index';
import { Link } from 'react-router-dom';
import SectionCrud from '@src/framework/crud/doc/SectionCrud';
import StyleCrud, { IStyleRecord } from '@src/framework/crud/doc/StyleCrud';
import { TemplateStylesPage } from './TemplateStylesPage';
import BlockTemplateTypeCrud, { IBlockTemplateTypeRecord } from '@src/framework/crud/doc/BlockTemplateTypeCrud';
import { getSectionTemplateBody } from '@src/server/EditorServer';
import { __ } from '@src/translation';

const NEW_BODY = `<section name="" id="section-[[id]]" data-magellan-target="section-[[id]]">
    <div class="row column">
        <div class="callout" style="/*background-color: */">
            <x-html data-id="content" data-title="">Lorem, ipsum dolor sit amet consectetur adipisicing elit. Rem ipsum iste blanditiis, minima illo vero nam vitae nesciunt itaque consequuntur quas quaerat ratione commodi quia voluptatem maxime impedit eum sunt?</x-html>
        <div>
    </div> 
</section>`;

type TemplateUpdatePageProps = {
    match: match<{styleId: string}>,
    history: History
}

type TemplateUpdatePageState = {
    style?: IStyleRecord;
    templates: IBlockTemplateRecord[];
    templateTypes: IBlockTemplateTypeRecord[];
    error: boolean;
    loaded: boolean;
}


export default class TemplateUpdatePage extends React.Component<TemplateUpdatePageProps, TemplateUpdatePageState> {

    constructor(props: TemplateUpdatePageProps) {
        super(props);

        this.state = {
            templates: [],
            templateTypes: [],
            error: false,
            loaded: false
        };
    }

    componentDidMount() {
        this.reloadAsync();
    }

    componentDidUpdate(prevProps: TemplateUpdatePageProps) {
        if (this.props.match.params.styleId !== prevProps.match.params.styleId) {
            this.reloadAsync();
        }
    }

    async reloadAsync() {
        try {
            this.setState({
                error: false,
                loaded: false
            })

            const style = (await StyleCrud.load(Number(this.props.match.params.styleId))).record;
            if (!style) {
                throw new Error(__("Stílus nem található!"));
            }

            const templateTypes = await BlockTemplateTypeCrud.list({});

            const templates = await BlockTemplateCrud.list({filter: {is_active: true, style_id: style.id}, order_by: "name"});

            templates.push({
                name: "",
                body: NEW_BODY,
                style_id: style.id
            })

            this.setState({
                style,
                templates,
                templateTypes,
                loaded: true
            });

        } catch(e) {
            app.showErrorFromJsonResult( this.props.match.params.styleId + e);
            this.setState({
                error: true
            })
        }
    }

    onNameChanged(index: number, event: React.FormEvent<HTMLInputElement>) {
        this.state.templates[index].name = event.currentTarget.value;

        this.setState({templates: this.state.templates});
    }

    onBodyChanged(index: number, event: React.FormEvent<HTMLTextAreaElement>) {
        this.state.templates[index].body = event.currentTarget.value;

        this.setState({templates: this.state.templates});
    }
    
    onBlockTemplateTypeIdChange(index: number, event: React.FormEvent<HTMLSelectElement>) {
        this.state.templates[index].block_template_type_id = event.currentTarget.value ? Number(event.currentTarget.value) : null;

        this.setState({templates: this.state.templates});
    }

    onVariableChanged(index: number, variableName: string, event: React.FormEvent<HTMLInputElement>) {
        console.log(index, variableName, event);

        if (!this.state.templates[index].variables) {
            this.state.templates[index].variables = {};
        }
        this.state.templates[index].variables[variableName] = event.currentTarget.value;

        this.setState({templates: this.state.templates});
    }

    async onSave(index: number) {
        if (!confirm(__('Biztosan le akarja menteni?'))) return;

        try {
            await new BlockTemplateCrud(this.state.templates[index]).put();
            
            app.showSuccess(__("Sikeres mentés"), __("Szekció sablon sikeresen lementve"));
            
            await this.reloadAsync();
            
        } catch(e) {
            app.showErrorFromJsonResult(e);
        }
    }

    render() {
        if (this.state.error) {
            return <TemplateStylesPage />
        }

        return <div>
                <div className="row expanded">
                    <div className="small-12 column">
                        <br />
                        <Link to={"/templateUpdate"}><i className="fas fa-arrow-left"></i>{__("Vissza")}</Link>
                        <h4>{__("Sablon")}: {this.state.style ? this.state.style.title : ""}</h4>
                    </div>
                </div>
                <br />
                {
                    this.state.templates.map((template, index) => {
                        
                        var variables: string[] = [];

                        const body = getSectionTemplateBody(template, this.state.templateTypes);

                        if (template.block_template_type_id) {
                            const blockTemplateType = this.state.templateTypes.find(t => t.id == template.block_template_type_id);
                            if (blockTemplateType && blockTemplateType.body) {
                                
                                const variableNames = blockTemplateType.body.match(/\[\[(.*?)\]\]/g);
                                if (variableNames) {
                                    variables = Array.from(new Set(variableNames.map(n => n.substring(2, n.length - 2)).filter(v => v != "name" && v != "id")));
                                }
                            }
                        }

                        return <div key={template.id} className="row expanded">

                                <div className="small-3 column">
                                    <label>
                                    {__("Sablon típus")}
                                        <select value={template.block_template_type_id || ""} onChange={this.onBlockTemplateTypeIdChange.bind(this, index)}>
                                            <option value="">{__("[Egyedi sablon]")}</option>
                                            {
                                                this.state.templateTypes.map(type => <option value={type.id}>{type.name}</option>)
                                            }
                                        </select>
                                    </label>

                                    <label>
                                    {__("Név")}:
                                        <input type="text" value={template.name} onChange={this.onNameChanged.bind(this, index)}/>
                                    </label>
                                    {
                                        variables.map((variableName, i) => {
                                            return <label key={i}>
                                                {variableName}:
                                                <input type="text" value={(template.variables && template.variables[variableName]) || ""} onChange={this.onVariableChanged.bind(this, index, variableName)}/>
                                            </label>})
                                    }

                                    { template.id && <DeleteTemplate id={template.id} name={template.name!} onReload={this.reloadAsync.bind(this)} /> }

                                </div>

                                <div className="column">
                                    {
                                        template.block_template_type_id
                                        ?
                                        <textarea value={body} disabled rows={12} style={{fontFamily: "monospace", tabSize: "4", MozTabSize: "4"}}/>
                                        :
                                        <textarea value={template.body} onChange={this.onBodyChanged.bind(this, index)} rows={12} style={{fontFamily: "monospace", tabSize: "4", MozTabSize: "4"}}/>
                                    }
                                </div>
                                <div className="shrink column">
                                    <button className="button" onClick={this.onSave.bind(this, index)}><i className="fa fa-save"/> {__("mentés")}</button>
                                </div>
                                
                                <div className="small-12 column">
                                    <hr/>
                                </div>

                            </div>
                    })
                }

            </div>;
    }

}

class DeleteTemplate  extends React.Component<{id: number, name: string, onReload: () => void}, {count?: number}> {

    constructor(props: any) {
        super(props);

        this.state = {
            
        }
    }

    async componentDidMount() {
        const count = (await SectionCrud.list({ filter: {is_active: true, block_template_id: this.props.id} })).length;

        this.setState({
            count
        })
    }

    async onDelete() {
        if (confirm(`Biztos, hogy törölni akarja a ${this.props.name} sablont?`)) {
            await BlockTemplateCrud.deleteById(this.props.id);
            this.props.onReload();
        }
    }

    render() {
        if (this.state.count === undefined) return null;

        return <div>
            {__("Használva {count} szekcióban", {count: this.state.count})}
            <br />
            { this.state.count === 0 && <button className="button alert " onClick={this.onDelete.bind(this)}><i className="fa fa-trash" /></button>}
        </div>
    }

}
