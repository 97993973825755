import { Server } from '../../server/Server';
import View , { registerViewClassProxy, ViewClassProxy } from '../View';
import { IListParams } from '@framework/crud/Crud';

export interface IViewEditorExcSeriesRecord {
    /**
     * Azonosító
     *
     * Azonosító (pótkulcs)
     */
    id?: number;
    /**
     * Sorrend
     */
    no?: number /*int4*/|null;
    /**
     * Rekord létrehozás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel létrehozásakor.
     */
    creation_time?: string /*timestamp?*/;
    /**
     * Ki hozta létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozó felhasználóra hivatkozik.
     */
    creation_user_id?: number;
    /**
     * Melyik session-ből jött létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozáshoz használt munkamenetre hivatkozik.
     */
    creation_session_id?: number|null;
    /**
     * Utolsó módosítás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel módosításakor.
     */
    modification_time?: string /*timestamp?*/|null;
    /**
     * Ki módosította utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosító felhasználóra hivatkozik.
     */
    modification_user_id?: number|null;
    /**
     * Melyik session-ből módosították utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosításhoz használt munkamenetre hivatkozik.
     */
    modification_session_id?: number|null;
    /**
     * Aktív
     *
     * Logikai törléshez használt mező.
     */
    is_active?: boolean;
    /**
     * Cím
     */
    title?: string;
    /**
     * Bevezető szöveg
     */
    introduction?: string|null;
    /**
     * Évfolyam
     */
    grade_id?: number|null;
    /**
     * Tantárgy
     */
    subject_id?: number|null;
    /**
     * Könyvtár
     */
    library_id?: number;
    /**
     * Tulajdonos/felelős
     */
    owner_id?: number;
    /**
     * Szint
     *
     * A feladatsor nehézségi foka, 1=könnyű 2=közepes 3=nehéz
     */
    level?: number /*int4*/;
    /**
     * Fix sorrend
     *
     * A feladatok pontosan a meghatározott sorrendben követik egymást.
     */
    fix_order?: boolean;
    /**
     * Feladat kiválasztás módja
     */
    exc_selection_mode_id?: number;
    /**
     * Feladatok darabszáma
     *
     * Csak akkor, ha a kiválasztás módja: megadott darabszámú kérdés.
     */
    exc_count?: number /*int4*/|null;
    /**
     * Feladatok min. összpontszáma
     *
     * Csak akkor, ha a kiválasztás módja: fix összpontszámú feladatsor.
     */
    minpoints?: number /*int4*/|null;
    /**
     * Elérhető pontszám kijelzése
     */
    show_max_points?: boolean;
    /**
     * Feladat üzemmód
     */
    exc_display_mode_id?: number;
    /**
     * Megszakítható
     *
     * A feladatsor megoldása megszakítható.
     */
    can_interrupt?: boolean;
    /**
     * Sikeresség határa (százalék)
     */
    success_limit_percent?: number /* float8 */;
    /**
     * Max. megoldási idő
     *
     * A megoldásra használható maximális idő percben.
     */
    max_solution_time_min?: number /*int4*/|null;
    /**
     * Megjelenés helye
     *
     * Mappa, ahol a feladatsor a virtuális állományrendszerben megjelenik.
     */
    show_in_folder_id?: number|null;
    /**
     * Azonosító NKP 1.0 rendszerben
     */
    nkp1_id?: number|null;
    /**
     * Adaptív
     *
     * Az adaptív feladatsorok témakörökre és nehézségi kategóriákra osztják a feladatokat.
     */
    is_adaptive?: boolean;
    /**
     * Utolsó találat időpontja
     */
    search_popularity_last_hit_date?: string /*timestamp?*/|null;
    /**
     * Utolsó népszerűségi index
     */
    search_popularity_last_score?: number /* float8 */|null;
    /**
     * Név
     *
     * Kulcsszavak
     */
    keywords?: string|null;
    /**
     * Site
     */
    site_id?: number;
    /**
     * Nyelv
     */
    lang_id?: number;
    /**
     * Évfolyam neve
     *
     * A feladatsort milyen évfolyamnak szánták
     */
    grade_name?: string|null;
    /**
     * Tantárgy neve
     *
     * A feladatsort milyen tantárgyhoz tartozik
     */
    subject_name?: string|null;
    /**
     * Tulajdonos felhasználó bejelentkezési neve
     *
     * A feladatsor tulajdonosa (felhasználó)
     */
    owner_login_name?: string|null;
    /**
     * Tulajdonos személy azonosítója
     *
     * A feladatsor tulajdonosa (személy)
     */
    owner_person_id?: number|null;
    /**
     * Tulajdonos személy neve
     *
     * A feladatsor tulajdonosa (személy)
     */
    owner_person_fullname?: string|null;
    /**
     * Kiválasztás módja
     *
     * A feladatsoron belül a feladatok kiválasztása milyen elv alapján történik.
     */
    exc_selection_mode_name?: string|null;
    /**
     * Megjelenítés módja
     *
     * A feladatsor hogyan jelenik meg.
     */
    exc_display_mode_name?: string|null;
    /**
     * Folyamat azonosító
     *
     * Folyamat azonosító
     */
    wf_workflow_id?: number|null;
    /**
     * Folyamat állapot azonosító
     *
     * Folyamat állapot azonosító
     */
    wf_station_id?: number|null;
    /**
     * Állapot
     *
     * Folyamat állapot neve
     */
    wf_station_name?: string|null;
    /**
     * Folyamat állapot leírása
     *
     * Folyamat állapot leírása
     */
    wf_station_description?: string|null;
    /**
     * Állapot stílus
     *
     * Folyamat állapot stílusa, jsonb/css
     */
    wf_station_style?: any|null;
    /**
     * Folyamat al-státusz azonosító
     *
     * Folyamat al-státusz azonosító
     */
    wf_substation_id?: number|null;
    /**
     * Folyamat al-státusz
     *
     * Folyamat al-státusz
     */
    wf_substation_name?: string|null;
    /**
     * Folyamat al-státusz leírása
     *
     * Folyamat al-státusz leírása
     */
    wf_substation_description?: string|null;
    /**
     * Folyamat szöveges állapot kiegészítés
     *
     * Folyamat szöveges állapot kiegészítés
     */
    wf_status_text?: string|null;
    /**
     * Feladatok száma
     *
     * A feladatsorban található aktív feladatok száma.
     */
    item_count?: number|null;
    /**
     * Publikálva van
     *
     * Publikálva van
     */
    is_published?: boolean|null;
    /**
     * Utoljára publikálva
     *
     * Utoljára publikálva
     */
    last_published?: string /*timestamp?*/|null;
    /**
     * Utolsó publikáló
     *
     * Utolsó publikáló
     */
    last_publisher_id?: number|null;
    /**
     * Létrehozta
     *
     * Felhasználó
     */
    creator?: string|null;
    /**
     * Módosító
     *
     * Felhasználó, aki utoljára módosította
     */
    modifier?: string|null;
    /**
     * Utolsó publikáló
     */
    last_publisher?: string|null;
}

/**
 *  exc.view_editor_exc_series - Feladatlista
 *
 *  Feladványok listája
 */

export default class ViewEditorExcSeries extends View<IViewEditorExcSeriesRecord> {
    public static VIEW_INFO_ID = 2020040204;

    public static getSchemaNameForClass(): string { return 'exc'; }
    public static getViewNameForClass(): string { return 'view_editor_exc_series'; }
    public static getTableNameForClass(): string|null { return 'exercise_series'; }

    public static load: (id: number, aServer ?: Server) => Promise<ViewEditorExcSeries>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<IViewEditorExcSeriesRecord[]>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class ViewEditorExcSeriesClassProxy extends ViewClassProxy<IViewEditorExcSeriesRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const viewEditorExcSeriesClassProxy = new ViewEditorExcSeriesClassProxy(ViewEditorExcSeries);
registerViewClassProxy(viewEditorExcSeriesClassProxy);

