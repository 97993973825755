import { getTranslations } from './framework/api/translation/getTranslations';
import { me, Groups, hasGroup } from './framework/server/Auth';
import LangCrud, { ILangRecord } from '@src/framework/crud/sys/LangCrud';
import { LANG_ID_HU, LANG_ID_EN, LANG_ID_RO, LANG_ID_SK, LANG_ID_EST } from './Const';

const TRANSLATION_SOURCE_LANGUAGE_ID = LANG_ID_HU;

let loadedLangId = LANG_ID_HU;

let translations: {[key: string]: string} = {};

let translationsLoaded = false;

/**
 * Translate text to the currently selected language.
 * Example usage:
 *   __("Continue");
 *   __("Welcome {user}", {user: "Robert"});
 */
export function __(text: string, args?: object) {
    if (!translationsLoaded) {
        console.error("Translations not yet loaded: [" + text + "]. The translation function __ can only be used inside other functions and non static class methods!");
        return text;
    }

    if (translations[text]) {
        text = translations[text];
    } else if (me && hasGroup(me, Groups.Developer) && loadedLangId !== TRANSLATION_SOURCE_LANGUAGE_ID) {
        text = "🌍{" + text + "}🌍";
    }

    if (args) {
        for (const key in args) {
            text = text.replace(new RegExp("\\{" + key + "\\}", "gi"), args[key]);
        }
    }
    return text;
}

/**
 * Load the translation text for the selected language
 */
export async function loadTranslations(langId: number) {
    loadedLangId = langId;

    const translationResult = await getTranslations({langId: langId, details: false});
    translations = {};

    if (translationResult.translations) {
        for(const translation of translationResult.translations) {
            if (translation.value) {
                translations[translation.key] = translation.value;
            }
        }
    }
    
    translationsLoaded = true;
}

const LOCALSTORAGE_LANGUAGE_ID_KEY = "lang_id";

export async function setLanguage(language: "hu" | "en" | "est" | "sk" | "ro") {
    let langId = TRANSLATION_SOURCE_LANGUAGE_ID;

    if (language === "hu") langId = LANG_ID_HU;
    if (language === "en") langId = LANG_ID_EN;
    if (language === "ro") langId = LANG_ID_RO;
    if (language === "est") langId = LANG_ID_EST;
    if (language === "sk") langId = LANG_ID_SK;

    if (localStorage) {
        localStorage.setItem(LOCALSTORAGE_LANGUAGE_ID_KEY, "" + langId);
    }
    await loadTranslations(langId);
}

export function getLanguage() {
    const langId = getLanguageId();
    if (langId === LANG_ID_HU) return "hu";
    if (langId === LANG_ID_EN) return "en";
    if (langId === LANG_ID_RO) return "ro";
    if (langId === LANG_ID_SK) return "sk";
    if (langId === LANG_ID_EST) return "est";
    return "hu";
}

export function getLanguageId() {
    if (localStorage) {
        const language = localStorage.getItem(LOCALSTORAGE_LANGUAGE_ID_KEY);
        // console.log("language", language);
        if (language) return Number(language);
    }
    return TRANSLATION_SOURCE_LANGUAGE_ID;
}
