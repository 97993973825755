export type PairingSolutionAssignment = {
    key: string;
    value: string[];
}
export class EKEPairingDnDServer {


    static evaluateOnServer(exercise: any, correctSolution: any, userSolution: any): Evaluated {
        var success = true;
        let wrongAnswers = 0;
        let successPercent = 1;

        //removing empty objects from total answers like text: "", url: "", image: ""
        let total_questions = exercise.exercise.options.pair1.filter(function (element: any) {
            if (exercise.num_of_questions != 0) {
                return element.type != "";
            }
            else {
                if (element.type == "text")
                    return element.text != "";
                else if (element.type == "image")
                    return element.image != "";
                else
                    return element.url != "";
            }
        })
        // we need a separate structure to store the pair1 and pair2 assignments, 
        // becasue there could be duplicates on both sides
        let assignment: PairingSolutionAssignment[] = EKEPairingDnDServer.getPairAssignmentList(exercise.exercise, correctSolution);
        for (let i = 0; i < correctSolution.length; i++) {
            // handling odd one answer elements
            if (!exercise.exercise.options.pair1[correctSolution[i].key].text && !exercise.exercise.options.pair1[correctSolution[i].key].image && !exercise.exercise.options.pair1[correctSolution[i].key].url) { // || !exercise.exercise.pair2[correctSolution[i].value]) {
                continue;
            }
            // handling odd one box elements
            let corr_curr_pair2 = exercise.exercise.options.pair2[correctSolution[i].value];
            if ((corr_curr_pair2.type == "text" && corr_curr_pair2.text == "") ||
                (corr_curr_pair2.type == "image" && corr_curr_pair2.image == "") ||
                (corr_curr_pair2.type == "sound" && corr_curr_pair2.url == "")) { // || !exercise.exercise.pair2[correctSolution[i].value]) {
                let CurrUserElem = userSolution.find(function (element: any) {
                    return element.key == correctSolution[i].key;
                });
                if (CurrUserElem.value != null) {
                    wrongAnswers++;
                    success = false;
                }
                continue;
            }

            let curr_indx = correctSolution[i].key;
            let found = userSolution.find(function (element: any) {
                return element.key == curr_indx;
            });

            if (!found
                || ((found.value == null || found.value == undefined) && exercise.exercise.options.pair2[correctSolution[i].value])) {
                success = false;
                wrongAnswers++;
                continue;
            }

            let foundPair1: any;
            let foundPair2: any;

            if (exercise.exercise.options.pair1[curr_indx].type == "image") foundPair1 = exercise.exercise.options.pair1[curr_indx].image
            else if (exercise.exercise.options.pair1[curr_indx].type == "text") foundPair1 = exercise.exercise.options.pair1[curr_indx].text;
            else if (exercise.exercise.options.pair1[curr_indx].type == "sound") foundPair1 = exercise.exercise.options.pair1[curr_indx].url;

            if (exercise.exercise.options.pair2[found.value].type == "image") foundPair2 = exercise.exercise.options.pair2[found.value].image
            else if (exercise.exercise.options.pair2[found.value].type == "text") foundPair2 = exercise.exercise.options.pair2[found.value].text;
            else if (exercise.exercise.options.pair2[found.value].type == "sound") foundPair2 = exercise.exercise.options.pair2[found.value].url;
            if (!foundPair2) continue; // if the user correctly submitted an odd element

            let correctPair2Found = assignment.find(function (element: PairingSolutionAssignment) {
                return element.key == foundPair1 && element.value.indexOf(foundPair2) > -1
            });

            if (!correctPair2Found) {
                success = false;
                wrongAnswers++;
                continue;
            }
        }
        successPercent = Number(((total_questions.length - wrongAnswers) / total_questions.length).toFixed(2));
        return { "success": success, "solution": correctSolution, "successPercent": successPercent };
    }

    static getPairAssignmentList(exercise: any, correctSolution: any): PairingSolutionAssignment[] {
        let assignment: PairingSolutionAssignment[] = [];

        for (let i = 0; i < correctSolution.length; i++) {
            //handling odd elements
            if (!exercise.options.pair1[correctSolution[i].key]) { // || !exercise.exercise.pair2[correctSolution[i].value]) {
                continue;
            }

            let foundPair1: any;
            if (exercise.options.pair1[correctSolution[i].key].type == "image") foundPair1 = exercise.options.pair1[correctSolution[i].key].image;
            else if (exercise.options.pair1[correctSolution[i].key].type == "text") foundPair1 = exercise.options.pair1[correctSolution[i].key].text;
            else if (exercise.options.pair1[correctSolution[i].key].type == "sound") foundPair1 = exercise.options.pair1[correctSolution[i].key].url;

            let newPair2: any;
            if (exercise.options.pair2[correctSolution[i].value].type == "image") newPair2 = exercise.options.pair2[correctSolution[i].value].image;
            else if (exercise.options.pair2[correctSolution[i].value].type == "text") newPair2 = exercise.options.pair2[correctSolution[i].value].text;
            else if (exercise.options.pair2[correctSolution[i].value].type == "sound") newPair2 = exercise.options.pair2[correctSolution[i].value].url;

            let hasAssignment = assignment.find(function (element: any) {
                return element.key == foundPair1;
            });

            if (hasAssignment) {
                let idx = assignment.indexOf(hasAssignment);
                hasAssignment.value.push(newPair2);
                assignment[idx] = hasAssignment;
            } else {
                assignment.push({ key: foundPair1, value: [newPair2] });
            }
        }
        return assignment;
    }

    static filterExerciseOnServer(exercise: any, solution: any): any {
        if (!exercise.num_of_questions || exercise.num_of_questions < 1
            || !solution || solution.length <= exercise.num_of_questions)
            return exercise;

        let new_exercise = JSON.parse(JSON.stringify(exercise));
        let tempSolution = solution.slice();

        let elementsToRemove = tempSolution.length - new_exercise.num_of_questions;
        //randomly find static element that should stay in options in any case

        let staticj = Math.floor(Math.random() * (solution.length));
        let static1 = solution[staticj].key;
        let static2 = solution[staticj].value;

        //remove items from options
        let i = 0;
        let maxTry=0;
        while (i < elementsToRemove && maxTry < 100) {
            maxTry++;
            let j = Math.floor(Math.random() * (tempSolution.length));
            if (tempSolution[j].key == static1 && tempSolution[j].value == static2) {
                (tempSolution as any[]).splice(j, 1);
                continue;
            }

            //Handling pair1
            if (new_exercise.options.pair1[tempSolution[j].key].type == "sound") {
                new_exercise.options.pair1[tempSolution[j].key].text = "";
                new_exercise.options.pair1[tempSolution[j].key].url = "";
            }
            else if (new_exercise.options.pair1[tempSolution[j].key].type = "image") {
                new_exercise.options.pair1[tempSolution[j].key].text = "";
                new_exercise.options.pair1[tempSolution[j].key].image = "";
            }
            else {
                new_exercise.options.pair1[tempSolution[j].key].text = "";
            }
            new_exercise.options.pair1[tempSolution[j].key].type = "";

            //Handling pair2
            if (new_exercise.options.pair2[tempSolution[j].value].type == "sound") {
                new_exercise.options.pair2[tempSolution[j].value].url = "";
                new_exercise.options.pair2[tempSolution[j].value].text = "";
            }
            else if (new_exercise.options.pair2[tempSolution[j].value].type = "image") {
                new_exercise.options.pair2[tempSolution[j].value].image = "";
                new_exercise.options.pair2[tempSolution[j].value].text = "";
            }
            else {
                new_exercise.options.pair2[tempSolution[j].value].text = "";
            }
            new_exercise.options.pair2[tempSolution[j].value].type = "";

            i++;
            (tempSolution as any[]).splice(j, 1);
        }

        return new_exercise;
    }
}