import * as React from 'react';

import { observer } from 'mobx-react';
import { appStore } from '@src/store/AppStore';

import { MonthCalendar } from '@src/component/calendar/MonthCalendar';
import { IViewCmsContentRecord } from '@src/framework/view/cms/ViewCmsContent';
import { computed, runInAction, ObservableMap, observable, reaction, IReactionDisposer } from 'mobx';
import { CalendarSource } from '@src/store/CalendarSource';
import { app } from '@src/index';
//import { getLanguage } from '@src/translation';
import getLangId from '@src/framework/i18n/const';
import { parse } from 'date-fns';
import { IDayItem } from '@src/component/calendar/CalendarAPI';
import { CalendarNavigator } from '@src/component/calendar/CalendarNavigator';
import { IViewCmsContentPostRecord, viewCmsContentPostClassProxy } from '@src/framework/view/cms/ViewCmsContentPost';
import { Dialog } from '@src/component/Dialog';
import { formatDate } from '@src/Util';
import Post from './Post';


const dateKey = (date: Date): string => {
    return date.toISOString();
}


export interface IContentEventCalendarProps {
    isSmall?: boolean
}

type ContentEventCalendarState = {
    dialogShow: boolean;
    selectedEvent?: any;
}

@observer
export class ContentEventCalendar extends React.PureComponent<IContentEventCalendarProps, ContentEventCalendarState> {
    private loaderDisposer: IReactionDisposer | null = null;
    private eventsByDay: ObservableMap<string, IViewCmsContentPostRecord[]> = observable.map({});

    constructor(props: any) {
        super(props);

        this.state = {
            dialogShow: false,
        };
    }

    public componentDidMount() {
        this.calendarSource.firstLoad();
        this.asyncReload();
        this.loaderDisposer = reaction(
            () => [this.calendarSource.year, this.calendarSource.month],
            this.asyncReload
        );
        this.asyncReload();
    }

    componentWillUnmount() {
        if (this.loaderDisposer) { this.loaderDisposer(); this.loaderDisposer = null; }
    }

    @computed get calendarSource(): CalendarSource {
        return appStore.contentEventCalendar;
    }

    /* Az egész hónap summary-ját újratölti. */
    private asyncReload = async () => {
        try {
            let events = await viewCmsContentPostClassProxy.list({
                filter: {
                    // Van megadva időpontja
                    "$notnull": "start_date",
                    // A naptár jelenleg megjelenített napjain belül van
                    ">=": { "start_date": this.calendarSource.startDate.getTime() },
                    "<=": { "start_date": this.calendarSource.endDate.getTime() },
                    // Nincs törölve
                    is_active: true, content_is_active: true,
                    // Látható
                    is_visible: true,
                    // Nincs szabályozva hogy mikortól jelenik meg VAGY a megjelenési dátum eljött
                    "$or": [
                        { visible_from: null },
                        { "<=": { visible_from: "now" } }
                    ],


                    /* Viki TODO */


                    lang_id: getLangId(),
                    // Ide hozzá kell adni a site_id szűrést! De ez majd csak 2019-09-30 -tól lesz elérhető.
                    // site_id: config.mainServer.siteId,
                    // Ide hozzá kell adni a content_type_id szűrést (nem tudom mit szeretnétek?)
                    // content_type_id: ????,
                },
                //Kivettem a columns szűrőt, mert a Post komponenshez kell a többi mező is, az esemény tartalmának megjelenítésére. (Dialog)
                //columns: ["id", "html_body", "start_date", "end_date", "title", "uri_segment", "subsite_title", "subsite_color" /* teaser, creator etc. */]
            });

            /* 
                Csoportosítjuk őket napok szerint. 
                TODO: Azt nem tudom, hogy a több napon át tartókat hogy kellene, de amíg nem találjuk ki hogy
                jelenjen meg, addig ezt nem is lehet megmondani.
            */
            let eventsByDay = {};
            events.forEach((event: IViewCmsContentPostRecord) => {
                var date: Date = parse(event.start_date!);
                date = new Date(date.getFullYear(), date.getMonth(), date.getDate());
                const key = dateKey(date);
                if (!eventsByDay[key]) {
                    eventsByDay[key] = [event];
                } else {
                    eventsByDay[key]!.push(event);
                }
            });
            runInAction(() => {
                this.eventsByDay.replace(eventsByDay);
            })

        } catch (error) {
            app.showErrorFromJsonResult(error);
        }
    }

    private renderDaySummary = (item: IDayItem) => {
        const key = dateKey(item.date);
        if (this.eventsByDay.has(key)) {
            const events: IViewCmsContentRecord[] = this.eventsByDay.get(key)!;
            return <div className="eke-calendar-day-activity-summary">{events.length}</div>;
        } else {
            return null;
        };
    }

    private renderDayList = (item: IDayItem) => {
        const key = dateKey(item.date);
        if (this.eventsByDay.has(key)) {
            const events: IViewCmsContentPostRecord[] = this.eventsByDay.get(key)!;
            let eventlist: string[] = [];
            let eventlistWColor: JSX.Element[] = [];
            events.map((e) => {
                this.props.isSmall ? eventlist.push(e.subsite_title + ": " + e.title!) : 
                eventlistWColor.push(<div className="event" style={{ backgroundColor: e.subsite_color! }} onClick={() => this.setState({selectedEvent: e, dialogShow: true})}>{e.title!}</div>);
            })
            return this.props.isSmall ? eventlist : eventlistWColor;
        } else {
            return null;
        };
    }


    public render() {
        const e = this.state.selectedEvent;
        //Dialog létrehozása, amely megjeleníti a Post-ot ha rákattintunk az eseményre.
        const dialog = e ? <Dialog open={this.state.dialogShow} title={e.title!} onClose={() => { this.setState({ dialogShow: false }) }}>
            <Post
                key={e.id}
                id={e.id}
                subsiteId={e.subsite_id!}
                date={formatDate(new Date())}
                publishDate={formatDate(e.creation_time!)}
                img={e.sha1!}
                title={e.title!}
                post={e.html_body!}
                subsiteName={e.subsite_title!}
                subsiteColor={e.subsite_color!}
                subsiteShortName={e.subsite_shortname!}
                dateInterval={e.start_date && e.end_date ? { from: e.start_date!.slice(0, 16), to: e.end_date!.slice(0, 16) } : (e ? { from: e.start_date.slice(0, 16) } : undefined)}
            >
            </Post>
        </Dialog> : "";
        return <div>
            <CalendarNavigator calendarMonthSource={this.calendarSource} />
            <MonthCalendar
                calendarMonthSource={appStore.contentEventCalendar}
                dayItemRenderer={this.renderDayList}
                forceSize="2"
                isSmall={this.props.isSmall} />
            {dialog}
        </div>
    }

}