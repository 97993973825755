import { IUserLoginEvent } from '@src/component/usr/usr_profile_api';
import obtainServer from '@framework/server/Server';
import { __ } from '@src/translation';
const URL = 'examineUserLog';

export default class ExamineUserLogAPI {
    public static getLoginEventLog = (user_id: number): Promise<IUserLoginEvent[]> => {
        return obtainServer().post(URL, { operation: "get_login_history", user_id });
    }
}
