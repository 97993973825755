import * as React from 'react';
import { BubbleLoader } from 'react-css-loaders';
import axios from 'axios';
import { app } from '@src/index';
import { config } from '@src/framework/server/Server';
import { __ } from '@src/translation';

export type NavaSearchResultItem = {
    id: number,
    doc_title: string,
    nava_genre: string[],
    firstFrame_image: string[],
    scheduled_date: string, // timestamp!
    duration: string; // time length!
}

export type NavaSearchResult = {
    docs: NavaSearchResultItem[]
    numFound: number,
    start: number,
}

export type NavaHighlight = {
    id: number;
    contributor: string;
    index: number;
}

interface NavaSearchComponentProps {
    keyword: string;
}

type NavaSearchComponentState = {
    results: NavaSearchResult | null;
    highlightings: { [id: number]: NavaHighlight } | null;
    loading: boolean;

    selectedNavaId?: number;
}

export default class NavaSearchComponent extends React.Component<NavaSearchComponentProps, NavaSearchComponentState> {

    constructor(props: NavaSearchComponentProps) {
        super(props);
        this.state = { results: null, highlightings: null, loading: true }

        this.onSearch();
    }

    componentDidUpdate(prevProps: NavaSearchComponentProps, prevState: NavaSearchComponentState) {
        if (prevProps.keyword != this.props.keyword) {
            this.onSearch();
        }
    }

    async onSearch() {
        var url = config!.searchServer!.apiBaseUrl + "navaSearch?keyword=" + this.props.keyword;

        this.setState({loading: true});
        try {
            const response = await axios.get(url);
            this.setState({
                results: response.data.response,
                highlightings: response.data.highlighting,
                loading: false,
                selectedNavaId: undefined
            });
        } catch (error) {
            app.showErrorFromJsonResult(error);
        }
    }

    pad(num: number): string {
        var s = "00" + num;
        return s.substr(s.length-2);
    }

    getRandomImage(firstFrameImage: string, duration: string): string {

        let hrsStr = duration.substr(0,2);
        let minsStr = duration.substr(3,2);
        let hrsInt = parseInt(hrsStr, 10);
        let minsInt = parseInt(minsStr, 10);

        let randomHrs = 0;        
        let randomMins = 0;

        if (hrsInt > 0) 
            randomHrs = Math.floor(Math.random() * (hrsInt + 1));

        if (minsInt > 0) 
            randomMins = Math.floor(Math.random() * (minsInt + 1));

        hrsStr = this.pad(randomHrs);
        minsStr = this.pad(randomMins);

        let randomImg = firstFrameImage.replace('00000000', hrsStr + minsStr + '0000');

        return randomImg;
    }

    render() {

        if (!this.props.keyword) {
            return "";
        }

        if (this.state.loading) {
            return <BubbleLoader />;
        }   

        const results = this.state.results;
        const highlightings = this.state.highlightings;
        if (results && results.docs.length && highlightings) {
            return <div className="row eke-searchresult">
                        <div className="columns small-12">
                            <h5>{__("NAVA találatok")}</h5>
                        </div>

                        {
                            this.state.selectedNavaId
                            ?
                            <div className="columns small-12">
                                <div className="flex-video widescreen">
                                    <iframe src={"//nava.hu/embed/" + this.state.selectedNavaId} scrolling="no" frameBorder="0" allowFullScreen/>
                                </div>
                                <button className="button" onClick={() => this.setState({selectedNavaId: undefined})}>{__("Vissza a keresési találatokhoz")}</button>
                                <a className="button" href={"https://nava.hu/id/" + this.state.selectedNavaId}>{__("Megtekintés a Nava oldalán")}</a>
                            </div>    
                            :
                          results.docs.map((item) => {                       
                            let genre = null;
                            if (item.nava_genre && item.nava_genre.length) {
                                genre = item.nava_genre[0];
                            }
                            return <div key={item.id} className="columns small-12 medium-4 large-3">
                                <button onClick={() => this.setState({selectedNavaId: item.id})} >{/*href={ + item.id} target="_new" key={item.id}>*/}
                                    <img src={this.getRandomImage(item.firstFrame_image[0], item.duration)} title={item.doc_title} /> 
                                    <small>
                                    {item.doc_title} {genre} <br/> {__("Időtartam")}: {item.duration}
                                    <div dangerouslySetInnerHTML={{ __html: highlightings[item.id].contributor }} />                                
                                    </small>
                                </button>    
                            </div>
                        }
                        )}
            </div>;
        } else {
            return <div className="eke-searchresult">{__("Nincs NAVA találat a következő kifejezésre")}: <b>{this.props.keyword}</b></div>;
        }
    }

}