import * as React from 'react';
import LessonEditor, { FileToUrlPath } from './LessonEditor';
import { Section } from '@src/server/EditorServer';
import { Dialog } from '@src/component/Dialog';
import MediaFileChooser, { ALL_ACTIONS } from '@src/component/filemanager/MediaFileChooser';
import OoFileCrud from '@src/framework/crud/media/OoFileCrud';
import { __ } from '@src/translation';

const AUDIO_VARIABLE_NAME = "attached-audio";

type Audio = {
    src: string,
    title: string
}

type SectionAudioProps = {
    lessonEditor: LessonEditor;
    section: Section;
    rootFolderId: number;
    fileToUrlPath: FileToUrlPath;
    urlRoot: string;
}

type SectionAudioState = {
    dialogVisible: boolean;
    currentFolderId: number;
}

export class SectionAudio extends React.Component<SectionAudioProps, SectionAudioState> {

    constructor(props: SectionAudioProps) {
        super(props);

        this.state = {
            dialogVisible: false,
            currentFolderId: props.rootFolderId
        }
    }

    private onDelete(index: number) {
        const audio: Audio[] = this.props.section.values[AUDIO_VARIABLE_NAME] || [];

        audio.splice(index, 1);

        this.props.lessonEditor.handleEditorChange(this.props.section.id, AUDIO_VARIABLE_NAME, audio);
    }

    private onTitleChanged(index: number, event: React.FormEvent<HTMLInputElement>) {
        const audio: Audio[] = this.props.section.values[AUDIO_VARIABLE_NAME] || [];

        audio[index].title = event.currentTarget.value;

        this.props.lessonEditor.handleEditorChange(this.props.section.id, AUDIO_VARIABLE_NAME, audio);
    }

    private async onFileSelected(fileId: number) {
        const selectedFile = (await OoFileCrud.load(fileId)).record;

        const path = this.props.fileToUrlPath(selectedFile);

        const audio: Audio[] = this.props.section.values[AUDIO_VARIABLE_NAME] || [];

        audio.push({src: path, title: ""});

        this.props.lessonEditor.handleEditorChange(this.props.section.id, AUDIO_VARIABLE_NAME, audio);
        this.setState({dialogVisible: false});
    }

    render() {

        const audio: Audio[] = this.props.section.values[AUDIO_VARIABLE_NAME] || [];

        return <div>
            {
                this.state.dialogVisible &&
                <Dialog title={__("Hanganyag (mp3) kiválasztása")} onClose={() => this.setState({ dialogVisible: false })} width={800} height={600}>
                    <MediaFileChooser
                        key={this.state.currentFolderId || 0}
                        rootFolderId={this.props.rootFolderId}
                        currentFolderId={this.state.currentFolderId}
                        selectableExtensions={["mp3"]}
                        onFileSelected={this.onFileSelected.bind(this)}
                        onFolderSelected={(folderId: number) => { this.setState({ currentFolderId: folderId }) }}
                        enabledActions={ALL_ACTIONS}
                    />
                </Dialog>

            }
            <button className="button small" onClick={() => this.setState({dialogVisible: true})}><i className="fa fa-volume-up"/>&nbsp; {__("Hanganyag csatolása")}</button>

            {audio.length > 0 &&
            <table>
                <thead>
                    <tr>
                        <th>{__("MP3")}</th>
                        <th>{__("Név")}</th>
                        <th>{__("Lejátszás")}</th>
                        <th>{__("Törlés")}</th>
                    </tr>
                </thead>
                <tbody>
                    {audio.map((a, index) => {
                        return <tr key={a.src}>
                                <td>{a.src}</td>
                                <td><input type="text" value={a.title} onChange={this.onTitleChanged.bind(this, index)} style={{marginBottom: 0}}/></td>
                                <td><audio src={this.props.urlRoot + "/" + a.src} controls style={{width: "100%"}}/></td>
                                <td><button onClick={this.onDelete.bind(this, index)} className="button alert small" style={{margin: 0}}><i className="fa fa-trash"/></button></td>
                            </tr>
                    })}
                </tbody>
            </table>
            }
        </div>
    }

}
