import { Server } from '../../server/Server';
import Crud , { registerCrudClassProxy, IAsTextResult, IListParams, CrudClassProxy, ISearchTextParams, ISearchTextResult } from '../Crud';

export interface ILessonplanRecord {
    /**
     * Globálisan egyedi azonosító.
     * 
     * Ha új rekordot akarsz létrehozni put() metódussal, akkor hagyd üresen.
     * upsert() hívásnál figyelmen kívül van hagyva az értéke.
     *
     */
    id ?: number;
    /**
     * DPMK projekt
     *
     * NOT NULL -> doc.dpmk_project ON DELETE noaction
     *
     */
    dpmk_project_id?: number;
    /**
     * Intézmény
     *
     * NOT NULL -> sys.institute ON DELETE noaction
     *
     */
    institute_id?: number;
    /**
     * Intézmény feladat ellátási hely
     *
     * NOT NULL -> sys.institute_site ON DELETE noaction
     *
     */
    institute_site_id?: number;
    /**
     * Intézmény típus
     *
     * NOT NULL -> sys.institute_type ON DELETE noaction
     *
     */
    institute_type_id?: number;
    /**
     * Műveltségi terület
     *
     * -> doc.literacy_field ON DELETE noaction
     *
     */
    literacy_field_id?: number|null;
    /**
     * Tulajdonos/felelős
     *
     * NOT NULL -> sys.sec_user ON DELETE cascade
     *
     */
    owner_id?: number;
    /**
     * Témakör
     */
    topic?: string;
    /**
     * Kereszttantervi kapcsolódás
     */
    cross_curriculum_link?: string|null;
    /**
     * A tanóra célja
     */
    goal?: string;
    /**
     * Fejlesztendő készség
     */
    facility_to_develop?: string;
    /**
     * Pedagógiai módszertan csomag
     *
     * -> doc.dpmk_methodology_package ON DELETE noaction
     *
     */
    dpmk_methodology_package_id?: number|null;
    /**
     * Pedagógiai módszertan csomag
     */
    dpmk_methodology_package_text?: string|null;
    /**
     * Keretek (tanulás szervezési feltételek)
     */
    frame_study_conditions?: string;
    /**
     * Keretek (közreműködő szakemberek)
     */
    frame_contributor_experts?: string|null;
    /**
     * Elsajátítandó fogalmak jegyzéke
     */
    concepts_to_pick?: string;
    /**
     * Megvalósítás tapasztalatai
     */
    conclusions?: string|null;
    /**
     * Javaslatok
     */
    suggestions?: string|null;
    /**
     * A könyv gyökér könyvtára.
     *
     * -> media_pub.oo_folder ON DELETE noaction
     *
     */
    oo_folder_id?: number|null;
}

/**
 *  doc_pub.lessonplan - Óravázlat
 */

export default class LessonplanCrud extends Crud<ILessonplanRecord> {
    public static TABLE_INFO_ID = 2826833052;
    public static getSchemaNameForClass() { return 'doc_pub'; }
    public static getTableNameForClass() { return 'lessonplan'; }

    public static canCache(): boolean {
        return true;
    }

    public static getDefaultMaxAge() : number|undefined {
        return 3600;
    }

    public static load: (id: number, aServer ?: Server) => Promise<LessonplanCrud>;
    public static asText: (id: number, includeRecord : boolean, aServer ?: Server) => Promise<IAsTextResult<ILessonplanRecord>>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<ILessonplanRecord[]>;
    public static searchText: (params: ISearchTextParams, aServer ?: Server) => Promise<ISearchTextResult<ILessonplanRecord>[]>;
    public static deleteById: (id: number, aServer ?: Server) => Promise<ILessonplanRecord>;
    public static unDeleteById: (id: number, aServer ?: Server) => Promise<ILessonplanRecord>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class LessonplanCrudClassProxy extends CrudClassProxy<ILessonplanRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const lessonplanCrudClassProxy = new LessonplanCrudClassProxy(LessonplanCrud);
registerCrudClassProxy(lessonplanCrudClassProxy);
