import * as React from 'react';

type AttendanceWorkerCardProps = {
    img: string,
    name: string,
    title?: string,
    phone: string,
    email: string,
    otherInfo?: string[]
}

export class AttendanceWorkerCard extends React.Component<AttendanceWorkerCardProps> {

    render() {
        let infos: JSX.Element[] = [];
        {
            this.props.otherInfo ? this.props.otherInfo.forEach((i) => {
                infos.push(<div>{i}</div>);
            }) : ""
        }
        return <div className="worker-card row">
            <div className="large-2 large-offset-0 medium-3 medium-offset-0 small-8 small-offset-2 column img-container">
                <img src={this.props.img} />
            </div>
            <div className="large-10 medium-9 small-12 column text-container">
                <h2 className="name">{this.props.name}</h2>
                <h3 className="title">{this.props.title}</h3>
                {infos}
                <div className="contact-container row">
                    <div className="phone large-3 medium-5 small-12 column"><i title="Telefonszám" className="fas fa-phone"></i><a href={"tel:" + this.props.phone}>{this.props.phone}</a></div>
                    <div className="email large-9 medium-7 small-12 column"><i title="e-mail cím" className="fas fa-at"></i><a href={"mailto:" + this.props.email}>{this.props.email}</a></div>
                </div>
            </div>
        </div>;
    }
}