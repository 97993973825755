import * as React from 'react';
import { app } from '@src/index';
import { me } from '@framework/server/Auth';
import ViewUsrFavouriteFileRecord, { IViewUsrFavouriteFileRecord } from '@framework/view/usr/ViewUsrFavouriteFile';
import { VideoBlock } from '@src/component/media/VideoComponent';
import Swiper, {SwiperItem} from '@src/component/dashboard/Swiper';
import { __ } from '@src/translation';

export interface FavouriteVideosTileProps {
    settings: any
}

export interface FavouriteVideosTileState {
    favouriteVideos?: IViewUsrFavouriteFileRecord[];
}

export default class FavouriteVideosTile extends React.Component<FavouriteVideosTileProps, FavouriteVideosTileState> {

    constructor(props: FavouriteVideosTileProps) {
        super(props);
        this.state = {
            favouriteVideos: []
        };
        this.asyncReload(this.props);
        this.handleResize = this.handleResize.bind(this);
    }

    private asyncReload = async (props:any) => {

        let newState: Partial<FavouriteVideosTileState> = {};
        if (me && me.id) {
            try {

                let favVideos = await ViewUsrFavouriteFileRecord.list({filter: {sec_user_id:me.id,ext:".mp4"},order_by: [{name: "creation_time", desc: true}], limit: Number(props.settings.limit)});
                this.setState({favouriteVideos:favVideos});

            } catch (error) {
                app.showErrorFromJsonResult(error);
            }
        } else {
            newState.favouriteVideos = [];
        }
        this.setState(newState as FavouriteVideosTileState);
    }

    componentDidMount(){

        window.addEventListener("resize", this.handleResize);

    }

    componentDidUpdate(){

     } 

    componentWillReceiveProps(newProps: any) {

        this.asyncReload(newProps);
    }

    handleResize(event:any) {
        this.forceUpdate(); 
    }

    componentWillUnmount()
    {
        window.removeEventListener("resize", this.handleResize);
    }

    render() {

        if (!this.state.favouriteVideos) {
            return <div/>;
        }
        else {
            return <div ref="favoriteVideosTile" className="favorite-videos-tile tile-content">

            {(this.state.favouriteVideos.length < 1) ?
                                <div>{__("Nincsenek kedvelt videók")}</div>
                                : 
                        <Swiper ItemCount={5} className="video-carousel-content video-block-list">
                            {    
                                  
                                this.state.favouriteVideos.map( (item: IViewUsrFavouriteFileRecord, i: number) => {
                                    
                                    return <SwiperItem key={i} title="" className="carousel-item">
                                    <VideoBlock 
                                        key={item.id} 
                                        fileId={item.oo_file_id!}
                                        displayName={item.displayname || undefined}
                                        title={item.title!}
                                        sha1={item.sha1!}
                                        description = {item.relpath!.split("/")[0] + "/" + item.relpath!.split("/")[1]}                                      
                                    />  </SwiperItem> 
                                })                                                                   
                                                                          
                            }    

                        </Swiper>
            }
                        </div>
        }
    }
}