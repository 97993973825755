import * as React from 'react';

import { IViewSubstationRecord, ViewSubstationClassProxy, viewSubstationClassProxy } from '@src/framework/view/wf/ViewSubstation';
import ListView from '@framework/forms/listview';
import { viewStationClassProxy } from '@framework/view/wf/ViewStation';
import { Link } from 'react-router-dom';
import { __ } from '@src/translation';


class SubStationInner extends ListView<IViewSubstationRecord> {
    //protected getHeader = () : JSX.Element|null => { return <WfMenu /> }
    public getViewClassProxy(): ViewSubstationClassProxy { return viewSubstationClassProxy; }

    protected getQuickFilterFieldNames(): string[] {
        return ['name'];
    }

    protected getTopButtons(): JSX.Element[] {
        let result = super.getTopButtons();
        const wf_type_id = this.state.spec ? this.state.spec.wf_type_id : undefined;
        result.unshift(
            <div className="small-4 medium-4 column" key="back_list">
                <Link className="button" to={viewStationClassProxy.getListUrl({ wf_type_id, is_active: true })}>
                    <i className="fa fa-step-backward" />&nbsp;{__("Vissza az állapotokhoz")}
                </Link>
            </div>
        );
        return result;
    }
}


export default function SubStationListView(props: any) {
    return <SubStationInner
        defaultPageSize={10}
        match={props.match}
        viewColumns={[
            { accessor: "name", maxWidth: 150 },
            { accessor: "link", Header: __("Műveletek"), maxWidth: 200 },
        ]}
    />
}
