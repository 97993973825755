import * as React from 'react';

import { IViewSecGroupRecord, ViewSecGroupClassProxy, viewSecGroupClassProxy } from '@src/framework/view/sys/ViewSecGroup';
import ListView from '@framework/forms/listview';
import { __ } from '@src/translation';

import '@framework/crud/sys/SecUserGroupCrud'; // Ezt ne távolítsd el mert akkor a hülye webpack kioptimizálja!

class SecGroupInner extends ListView<IViewSecGroupRecord> {

    public getViewClassProxy(): ViewSecGroupClassProxy { return viewSecGroupClassProxy; }


    protected getQuickFilterFieldNames(): string[] {
        return ['title', 'group_type_title'];
    }



    protected getDeleteUndeleteButton(record: IViewSecGroupRecord): JSX.Element | null {
        if (record.id! > 1000) {
            return super.getDeleteUndeleteButton(record);
        } else {
            return null;
        }
    }

}


export default function SecGroupListView(props: any) {
    // TODO: hozzáadni a person-os oszlopokat a view-hoz hogy látszódjon a 
    // rendes nevük!
    return <SecGroupInner
        defaultPageSize={10}
        match={props.match}
        viewColumns={[
            { accessor: "id", maxWidth: 100 },
            { accessor: "title" },
            { accessor: "group_type_title" },
            /*
            {accessor: "creation_time"},
            {accessor: "creator"},
            {accessor: "modification_time"},
            {accessor: "modifier"},
            */
            { accessor: "link", Header: __("Műveletek"), maxWidth: 200 },
        ]}
    />
}
