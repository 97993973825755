import {EventStyleType, EventIconType, TooltipParametersType, 
    MenuParametersType, EventConflictVerifyType, EventConflictVerifyEnum as ECVEnum,
    EventConflictOperationTypeEnum as ECOTElem, EventCalendarPropsType} from '@src/component/dashboard/eventcalendar/EventCalendarAPI'
import { attendanceUserType as UT, attendanceCalendarType as CT } from './AttendanceAPI';
import { utc } from 'moment';


export const AttendanceEventData = {

}

export const DfhtSupportTime: number = 25346333726;       // Szakmai támogató időpont megjelölve
export const DfhtTimeBusy: number = 25346333727;          // Időpont lefoglalva, adatrögzítés folyamatban
export const DfhtLessonPlanUpload: number = 25346333728;  // Óravázlatok feltöltése
export const DfhtDocumentUpload: number = 25346333729;    // Dokumentáció feltöltése
export const DfhtWaitingAttendance: number = 25346333730; // Jelenléti ívre vár
export const DfhtClosed: number = 25346333731;            // Lezárt
export const DfhtDeleted: number = 25346333732;           // Törölt
export const DfhtFailed: number = 25346333733;

export const ApSupportTime: number = 25346333744;         // Szakmai támogató időpont megjelölve
export const ApInstituteTime: number = 25346333747;       // Intézmény időpont megjelölve
export const ApTimeBusy: number = 25346333748;            // Időpont lefoglalva, adatrögzítés folyamatban
//export const ApDataRecorded: number = 25346333749;        
export const ApAnnouncedWaitingApply: number = 25346333750; // Meghirdetve, jelentkezésre vár
export const ApApplyClosed: number = 25346333751;         // Jelentkezés lezárva
export const ApWaitingAttendance: number = 25346333752;   // Jelenléti ívre vár
export const ApClosed: number = 25346333753;              // Lezárt
export const ApDeleted: number = 25346333755;             // Törölt
export const ApFailed: number = 25346333754;              


export const EventStyles: EventStyleType = {
    eventStyle:{fontSize:13, eventHeight:24},
    eventTypeStyle: [
       {typeName:"type", typeValue:"0", backgroundColor:"#6f99bf", textColor:"#ffffff", textBold:true},

       {typeName:"type", typeValue:"1", backgroundColor:"#11a9e1", textColor:"black", textBold:true}, // DA
       {typeName:"type", typeValue:"2", backgroundColor:"#5cb957", textColor:"black", textBold:true}, // EA
       {typeName:"type", typeValue:"3", backgroundColor:"#f89833", textColor:"black", textBold:true}, // LA
       {typeName:"type", typeValue:"4", backgroundColor:"#ee2e3f", textColor:"black", textBold:true}, // MA
       {typeName:"type", typeValue:"5", backgroundColor:"#1271a8", textColor:"#ffffff", textBold:true}, // TA
       //{typeName:"type", typeValue:"6", backgroundColor:"#990000", textColor:"black", textBold:true}
   ]
}
export const EventIcons: EventIconType[] = [
    //DFNT
    {statusValue:25346333726, iconName:"calendar-alt"}, //'Szakmai támogató időpont megjelölve'},
    {statusValue:25346333727, iconName:"clock"}, //'Időpont lefoglalva, adatrögzítés folyamatban'},
    {statusValue:25346333728, iconName:"check"}, //'Óravázlatok feltöltése'},
    {statusValue:25346333729, iconName:"tasks"}, //'Dokumentáció feltöltése'},
    {statusValue:25346333730, iconName:"file-alt"}, //'Jelenléti ívre vár'},
    {statusValue:25346333731, iconName:"child"}, //'Lezárt'},
    {statusValue:25346333732, iconName:"times-circle"}, //'Törölt'},
    {statusValue:25346333733, iconName:"times-circle"}, //'Meghiúsult'},
    //Alprogrami
    {statusValue:25346333744, iconName:"calendar-alt"}, //'Szakmai támogató időpont megjelölve'},
    {statusValue:25346333747, iconName:"calendar-alt"}, //'Intézmény időpont megjelölve'},
    {statusValue:25346333748, iconName:"clock"}, //'Időpont lefoglalva, adatrögzítés folyamatban'},
    {statusValue:25346333749, iconName:"check"}, //'Adatok rögzítve, megerősítésre vár'},
    {statusValue:25346333750, iconName:"users"}, //'Meghirdetve, jelentkezésre vár'},
    {statusValue:25346333751, iconName:"tasks"}, //'Jelentkezés lezárva'},
    {statusValue:25346333752, iconName:"file-alt"}, //'Jelenléti ívre vár'},
    {statusValue:25346333753, iconName:"child"}, //'Lezárt'},
    {statusValue:25346333754, iconName:"times-circle"}, //'Meghiúsult'},
    {statusValue:25346333755, iconName:"times-circle"}, //'Törölt'}
]
/*export function getStatusIcon(statusId: number) => {
    const statusIcon = EventIcons.find(e => e.statusValue === statusId);

    for (const {item} of EventIcons.map((item: EventIconType) => ({item}))) {
        if (item.statusValue==status) result='fa fa-'+item.iconName;
    }
    return result
}*/

export const TooltipParameters: TooltipParametersType = {
    typeName: [
       {id:"0", name:"DFHT-KIP és Komplexóra"},
       {id:"4", name:"Művészetalapú alprogram"},
       {id:"5", name:"Testmozgásalapú alprogram"},
       {id:"2", name:"Életgyakorlat-alapú alprogram"},
       {id:"3", name:"Logikaalapú alprogram"},
       {id:"1", name:"Digitálisalapú alprogram"},
       {id:"6", name:"Hospitálásból kizárt időpont"}
   ],
    statusName: [
        //DFHT
        {id:"25346333726", name:"Szakmai támogató időpont megjelölve"},
        {id:"25346333727", name:"Időpont lefoglalva, adatrögzítés folyamatban"},
        {id:"25346333728", name:"Óravázlatok feltöltése"},
        {id:"25346333729", name:"Dokumentáció feltöltése"},
        {id:"25346333730", name:"Jelenléti ívre vár"},
        {id:"25346333731", name:"Lezárt"},
        {id:"25346333732", name:"Törölt"},
        {id:"25346333733", name:"Meghiúsult"},
        //Alprogrami
        {id:"25346333744", name:"Szakmai támogató időpont megjelölve"},
        {id:"25346333747", name:"Intézmény időpont megjelölve"},
        {id:"25346333748", name:"Időpont lefoglalva, adatrögzítés folyamatban"},
        {id:"25346333749", name:"Adatok rögzítve, megerősítésre vár"},
        {id:"25346333750", name:"Meghirdetve, jelentkezésre vár"},
        {id:"25346333751", name:"Jelentkezés lezárva"},
        {id:"25346333752", name:"Jelenléti ívre vár"},
        {id:"25346333753", name:"Lezárt"},
        {id:"25346333754", name:"Meghiúsult"},
        {id:"25346333755", name:"Törölt"}
    ],
    enabled: true, width: 350
}
export type AttandanceCalendarMenu = {
    menuElem: MenuParametersType,
    userTypesDFHT: UT[],
    userTypesAP: UT[]
}
/*
export const MenuArray: AttandanceCalendarMenu[] = [
    {menuElem: {id:"mFelvisz",name:"Új időpont megadása",icon:"fa fa-calendar-plus",propertyName:"status",propertyValue:["X"],dayClick:true,enabled:[true]}, 
        userTypesDFHT: [UT.koordinator,UT.szakmaitamogato], userTypesAP: [UT.koordinator,UT.szakmaitamogato,UT.kapcsolattarto]},
    {menuElem: {id:"mIntAdd",name:"Intézmény megadása",icon:"fa fa-school",propertyName:"status",propertyValue:["25346333726","25346333744"],dayClick:false,enabled:[true,true]}, 
        userTypesDFHT: [UT.koordinator,UT.kapcsolattarto], userTypesAP: [UT.koordinator, UT.kapcsolattarto]},
    {menuElem: {id:"mIntTorol",name:"Intézmény törlése",icon:"fa fa-angry",propertyName:"status",propertyValue:["25346333727","25346333748"],dayClick:false,enabled:[true,true,true]}, 
        userTypesDFHT: [UT.koordinator, UT.kapcsolattarto], userTypesAP: [UT.koordinator, UT.kapcsolattarto]},
    {menuElem: {id:"mSzTAdd",name:"Támogató megadása",icon:"fa fa-school",propertyName:"status",propertyValue:["25346333747",],dayClick:false,enabled:[true]}, 
        userTypesDFHT: [UT.koordinator,UT.kapcsolattarto], userTypesAP: [UT.koordinator, UT.kapcsolattarto]},
    {menuElem: {id:"mSzTTorol",name:"Támogató törlése",icon:"fa fa-angry",propertyName:"status",propertyValue:["25346333748"],dayClick:false,enabled:[true]}, 
        userTypesDFHT: [UT.koordinator, UT.szakmaitamogato], userTypesAP: [UT.koordinator, UT.szakmaitamogato]},

    {menuElem: {id:"mAdatRogzit",name:"Adatok rögzítése",icon:"fa fa-edit",propertyName:"status",propertyValue:["25346333727","25346333748"],dayClick:false,enabled:[true,true]}, 
        userTypesDFHT: [UT.koordinator,UT.pedagogus,UT.kapcsolattarto], userTypesAP: [UT.koordinator,UT.pedagogus,UT.kapcsolattarto]},

    {menuElem: {id:"mRogzitKesz",name:"Adatrögzítés kész",icon:"fa fa-edit",propertyName:"status",propertyValue:["25346333748"],dayClick:false,enabled:[true]}, 
        userTypesDFHT: [], userTypesAP: [UT.koordinator,UT.kapcsolattarto]},
    {menuElem: {id:"mMeghirdet",name:"Időpont meghirdetése",icon:"fa fa-calendar-check",propertyName:"status",propertyValue:["25346333749"],dayClick:false,enabled:[true]}, 
        userTypesDFHT: [], userTypesAP: [UT.koordinator, UT.kapcsolattarto]},
    {menuElem: {id:"mLezar",name:"Időpont lezárása",icon:"fa fa-calendar-check",propertyName:"status",propertyValue:["25346333730","25346333752"],dayClick:false,enabled:[true,true]}, 
        userTypesDFHT: [UT.koordinator, UT.kapcsolattarto], userTypesAP: [UT.koordinator, UT.kapcsolattarto]},

    {menuElem: {id:"mTorol",name:"Időpont törlése",icon:"fa fa-calendar-minus",propertyName:"status",propertyValue:["X"],dayClick:false,enabled:[true]}, 
        userTypesDFHT: [UT.koordinator], userTypesAP: [UT.koordinator]},
    {menuElem: {id:"mMeghiusul",name:"Időpont meghiúsulása",icon:"fa fa-calendar-minus",propertyName:"status",propertyValue:["X"],dayClick:false,enabled:[true]}, 
        userTypesDFHT: [UT.koordinator], userTypesAP: [UT.koordinator]},
        
    {menuElem: {id:"mEdit",name:"Megnyitás",icon:"fa fa-edit",propertyName:"status",propertyValue:["*"],dayClick:false,enabled:[true]}, userTypesDFHT: [UT.koordinator], userTypesAP: [UT.koordinator]}
];*/
export const EventConflictVerify: EventConflictVerifyType = {
    Elem: [
        {name:ECVEnum.personDay, type:ECOTElem.conflictError, message:"A szakmai támogatónak az adott napon már van hospitálása!"},
        {name:ECVEnum.placeTimePeriod, type:ECOTElem.conflictError, message:"Az adott helyszínen a megadott időszakban már van hospitálás!"},
        {name:ECVEnum.placeDay, type:ECOTElem.conflictConfirm, message:"Az adott helyszínen a megadott nap már van hospitálás. Biztos benne?"},
        {name:ECVEnum.noMoving, type:ECOTElem.conflictError, message:"Hospitálásból kizást időpont nem mozgatható?",propertyName:"status",propertyValue:"0"},
    ],
    personIdName: "personId",
    placeIdName: "placeId",
    placeIdEmptyValue: "-1"
}
export const EventCalendarProps: EventCalendarPropsType = {
    eventLimit: true,
    eventLimitText: "további hospitálás",
    defaultDate: new Date().toISOString().substring(0, 10)
}


