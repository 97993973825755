import {api } from '@framework/server/Auth';

import ViewMetaAssignedCategory from '@src/framework/view/meta/ViewMetaAssignedCategory';
import obtainServer from '@framework/server/Server';
import { __ } from '@src/translation';

export interface MetaCategoryTreeItem {
    id : number;
    title: string;
    description ?: string;
    category_type_id : number;
    min_grade_id : number;
    max_grade_id : number;
    subject_id : number;
    children : MetaCategoryTreeItem[];
}

export interface MetaCategoryTreeRoot {
    created : number;
    children: MetaCategoryTreeItem[];
}

/**
 * Ez reprezentálja egy adott rekordhoz rendelt
 * összes kategóriát, kategória típus szerint
 * csoportosítva.
 */
export type MetaCategorization = { [category_type_id: string]: number[] | undefined };


const fetchMetaCategoryTree = async (metaCategoryTypeId: number): Promise<MetaCategoryTreeRoot> => {
    const url = 'metacategories?operation=get_category_tree&category_type_id=' + metaCategoryTypeId.toString();
    return obtainServer().get<MetaCategoryTreeRoot>(url);
}
const metaCategoryTreeCache = {};

/**
 * Visszaadja egy adott meta kategória típushoz tartozó kategória fát
 */
export const getMetaCategoryTree = async  (metaCategoryTypeId: number): Promise<MetaCategoryTreeRoot> => {
    if (metaCategoryTreeCache[metaCategoryTypeId]===undefined) {
        metaCategoryTreeCache[metaCategoryTypeId] = await fetchMetaCategoryTree(metaCategoryTypeId);
    }
    return metaCategoryTreeCache[metaCategoryTypeId];
}



/**
 * Visszaadja egy rekordhoz hozzárendelt összes kategóriát.
 */
export const fetchCategoryIds = async (tableInfoId: number, recordId: number, categoryTypeId ?: number): Promise<number[]> => {
    try {
        let items = await ViewMetaAssignedCategory.list({
            filter: {
                table_info_id: tableInfoId,
                record_id: recordId,
                category_type_id: categoryTypeId,
                is_active: true,
            }
        });
        if (items && items.length) {
            return Promise.resolve(items.map((item) => { return item.category_id! }));
        } else {
            return Promise.resolve([]);
        }
    } catch (error) {
        return Promise.reject(error);
    }
}

/**
 * Beállítja (felülírja) egy rekordhoz hozzárendelt összes kategóriát.
 */
export const setCategoryIds = async (tableInfoId: number, recordId: number, category_ids: number[], category_type_id ?: number): Promise<void> => {
    return _callMetaCategories(tableInfoId, recordId, "set", category_ids, category_type_id);
}

/**
 * Hozzáad extra kategóriákat egy rekordhoz
 */
export const appendCategoryIds = async (tableInfoId: number, recordId: number, category_ids: number[]): Promise<void> => {
    return _callMetaCategories(tableInfoId, recordId, "append", category_ids);
}

/**
 * Töröl kategóriákat egy rekordból
 */
export const removeCategoryIds = async (tableInfoId: number, recordId: number, category_ids: number[]): Promise<void> => {
    return _callMetaCategories(tableInfoId, recordId, "remove", category_ids);
}

const _callMetaCategories = async (tableInfoId: number, recordId: number, 
        operation: "append" | "remove" | "set", category_ids: number[], category_type_id ?: number): Promise<void> => {
    if (api == null) {
        throw new Error(__("Nincs bejelentkezve?"));
    } else {
        const response = await obtainServer().post<boolean>(
            'metacategories',
            {
                operation,
                params: {
                    table_info_id: tableInfoId,
                    record_id: recordId,
                    category_ids,
                    category_type_id
                }
            }
        );
        return Promise.resolve();
    }
}