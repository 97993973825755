import { Server } from '../../server/Server';
import View , { registerViewClassProxy, ViewClassProxy } from '../View';
import { IListParams } from '@framework/crud/Crud';

export interface IViewInstituteGroupMemberRecord {
    /**
     * Azonosító
     *
     * Azonosító (pótkulcs)
     */
    id?: number;
    /**
     * Rekord létrehozás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel létrehozásakor.
     */
    creation_time?: string /*timestamp?*/;
    /**
     * Ki hozta létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozó felhasználóra hivatkozik.
     */
    creation_user_id?: number;
    /**
     * Melyik session-ből jött létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozáshoz használt munkamenetre hivatkozik.
     */
    creation_session_id?: number|null;
    /**
     * Utolsó módosítás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel módosításakor.
     */
    modification_time?: string /*timestamp?*/|null;
    /**
     * Ki módosította utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosító felhasználóra hivatkozik.
     */
    modification_user_id?: number|null;
    /**
     * Melyik session-ből módosították utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosításhoz használt munkamenetre hivatkozik.
     */
    modification_session_id?: number|null;
    /**
     * Aktív
     *
     * Logikai törléshez használt mező.
     */
    is_active?: boolean;
    /**
     * Intézményi csoport
     */
    institute_group_id?: number;
    /**
     * Alkalmazás szintű felhasználó
     */
    sec_user_id?: number;
    /**
     * Intézményi csoport tagság állapot
     */
    status_id?: number;
    /**
     * Azonosító NKP 1.0 rendszerben
     */
    nkp1_id?: number|null;
    /**
     * Adminisztrátor
     */
    is_admin?: boolean;
    /**
     * Tagság állapot
     *
     * Tagság állapot
     */
    status_title?: string|null;
    /**
     * Tagság állapot leírása
     *
     * Tagság állapot leírása
     */
    status_description?: string|null;
    /**
     * Feladatellátási hely azonosító
     *
     * Feladatellátási hely azonosító
     */
    institute_site_id?: number|null;
    /**
     * Intézmény azonosító
     *
     * Intézmény azonosító
     */
    institute_id?: number|null;
    /**
     * OM azonosító
     *
     * OM azonosító
     */
    om_identifier?: string|null;
    /**
     * Ellátási hely azonosító
     *
     * Ellátási hely azonosító
     */
    site_identifier?: string|null;
    /**
     * Ellátási hely neve
     *
     * Ellátási hely neve
     */
    institute_site_name?: string|null;
    /**
     * Csoport neve
     *
     * Csoport neve
     */
    group_name?: string|null;
    /**
     * Tag e-mail címe
     *
     * Tag e-mail címe
     */
    member_email?: string|null;
    /**
     * Tag aktív felhasználó
     *
     * Tag aktív felhasználó
     */
    member_is_active?: boolean|null;
    /**
     * Tag neve
     *
     * Tag neve
     */
    member_name?: string|null;
    /**
     * Létrehozta (meghívta)
     */
    creator?: string|null;
    /**
     * Utolsó módosító
     */
    modifier?: string|null;
}

/**
 *  sys.view_institute_group_member - Intézményi csoport tagság
 *
 *  Idősoros táblázat, ami az összes tétel összes korábbi verzióját eltárolja.
 */

export default class ViewInstituteGroupMember extends View<IViewInstituteGroupMemberRecord> {
    public static VIEW_INFO_ID = 2018082702;

    public static getSchemaNameForClass(): string { return 'sys'; }
    public static getViewNameForClass(): string { return 'view_institute_group_member'; }
    public static getTableNameForClass(): string|null { return 'institute_group_member'; }

    public static load: (id: number, aServer ?: Server) => Promise<ViewInstituteGroupMember>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<IViewInstituteGroupMemberRecord[]>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class ViewInstituteGroupMemberClassProxy extends ViewClassProxy<IViewInstituteGroupMemberRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const viewInstituteGroupMemberClassProxy = new ViewInstituteGroupMemberClassProxy(ViewInstituteGroupMember);
registerViewClassProxy(viewInstituteGroupMemberClassProxy);

