import * as React from 'react';
import ViewMetaTableCategoryType, { IViewMetaTableCategoryTypeRecord } from '@src/framework/view/meta/ViewMetaTableCategoryType';
import { app } from '../..';
import { Server } from '@src/framework/server/Server';

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

import MetaCategoryAssignerHeadPlugin from './meta_category_assigner_head_plugin';
import { MetaCategorization } from '@src/component/meta/meta_category_assigner_api';
import { __ } from '@src/translation';

export type MetaCategoryAssignerHeadLoadedEvent = (sender: MetaCategoryAssignerHeadPlugin) => void;
export type MetaCategoryAssignerTypedEvent = (category_type_id: number, category_ids: number[]) => void;
export type MetaCategoryAssignerSubjectSelectEvent = (category_type_id: number, subject_id: number) => void;

export interface IMetaCategoryAssignerPluginProps {
    /** Adat szűrés */
    tableInfoId: number;
    recId: number | null;
    /** A kiválasztott/hozzárendelt kategória id-k */
    categorization: MetaCategorization;
    subject_ids: { [category_type_id: string]: number | null };
    /**
     * Eseménykezelők, amikkel a category_ids -t lehet módosítani,
     * egy adott típuson belül (categorization -ban)
     */
    onSubjectSelected?: MetaCategoryAssignerSubjectSelectEvent;
    onCategoryIdsLoaded?: MetaCategoryAssignerTypedEvent;
    onSetCategoryIds?: MetaCategoryAssignerTypedEvent;
    onRemoveCategoryIds?: MetaCategoryAssignerTypedEvent;
    defaultExpandAll?: boolean;
    disableCheckforNodesWithLeaf?: boolean;
    /**
     * ExerciseEditor subject
     */
    exercise_subject_id?: number | null;
}

interface IMetaCategoryAssignerPluginState {
    categoryTypes?: IViewMetaTableCategoryTypeRecord[];
}

/**
 * Megjeleníti és manipulálja egy konkrét rekordhoz tartozó ÖSSZES folyamatot.
 * Minden típushoz egy külön WfHeadPlugin-t használ.
 */
export default class MetaCategoryAssignerPlugin extends React.Component<IMetaCategoryAssignerPluginProps, IMetaCategoryAssignerPluginState> {
    private server: Server;

    constructor(props: IMetaCategoryAssignerPluginProps) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.asyncReload();
    }

    private asyncReload = async () => {
        ViewMetaTableCategoryType.list({
            filter: {
                table_info_id: this.props.tableInfoId,
                is_active: true,
            },
            order_by: [ { name: "id", desc: true } ] // "Tantárgyi kulcsfogalmak" should come first
        })
            .then((categoryTypes) => this.setState({ categoryTypes: categoryTypes }))
            .catch((error) => app.showErrorFromJsonResult(error));
    }

    public getMyUniqueId(): string {
        return `wf_plugin_${this.props.tableInfoId}_${this.props.recId || 0}`;
    }

    render() {
        if (!this.props.recId) {
            return <div className="callout warning">
                {__("A metaadatok megjelenítéséhez először vegye fel a tételt!")}
            </div>;
        }

        if (this.state.categoryTypes === undefined) {
            return <div>{__("Kérem várjon...")}</div>;
        }
        let categoryTypes: IViewMetaTableCategoryTypeRecord[] = this.state.categoryTypes;
        const myId = this.getMyUniqueId();
        if (categoryTypes.length > 1) {
            return <Tabs>
                <TabList>
                    {
                        categoryTypes.map((item, index) => {
                            const category_type_id = item.category_type_id!;
                            const category_ids = this.props.categorization[category_type_id.toString()];
                            const count: string =
                                category_ids && category_ids.length ? " (" + category_ids.length + ")" : "";
                            return <Tab key={item.id!}>{item.category_type_title} {count}</Tab>
                        })
                    }
                </TabList>
                {
                    categoryTypes.map((item, index) => {
                        const category_type_id = item.category_type_id!;
                        const category_ids = this.props.categorization[category_type_id.toString()];
                        const subject_id = this.props.subject_ids[category_type_id] || null;
                        return (
                            <TabPanel key={item.id!}>
                                <MetaCategoryAssignerHeadPlugin
                                    tableInfoId={this.props.tableInfoId}
                                    recId={this.props.recId}
                                    category_type_id={category_type_id}
                                    category_ids={category_ids}
                                    subject_id={subject_id}
                                    exercise_subject_id={this.props.exercise_subject_id}
                                    onSubjectSelected={this.props.onSubjectSelected}
                                    onCategoryIdsLoaded={this.props.onCategoryIdsLoaded}
                                    onSetCategoryIds={this.props.onSetCategoryIds}
                                    onRemoveCategoryIds={this.props.onRemoveCategoryIds}
                                    defaultExpandAll={this.props.defaultExpandAll}
                                    disableCheckforNodesWithLeaf={this.props.disableCheckforNodesWithLeaf}
                                />
                            </TabPanel>);
                    })
                }
            </Tabs>;
        } else if (categoryTypes.length == 1) {
            const category_type_id = categoryTypes[0].category_type_id!;
            const subject_id = this.props.subject_ids[category_type_id.toString()] || null;

            return <MetaCategoryAssignerHeadPlugin
                tableInfoId={this.props.tableInfoId}
                recId={this.props.recId}
                category_type_id={category_type_id}
                category_ids={this.props.categorization[category_type_id.toString()]}
                subject_id={subject_id}

                onSubjectSelected={this.props.onSubjectSelected}
                onCategoryIdsLoaded={this.props.onCategoryIdsLoaded}
                onSetCategoryIds={this.props.onSetCategoryIds}
                onRemoveCategoryIds={this.props.onRemoveCategoryIds}
                defaultExpandAll={this.props.defaultExpandAll}
                disableCheckforNodesWithLeaf={this.props.disableCheckforNodesWithLeaf}
            />
        } else {
            return <div className="callout secondary">
                {__("Ehhez az adattípushoz nincsenek metaadat kategóriák rendelve.")}
            </div>
        }
    }
}