export class UniversalEngineServer {
    static evaluateOnServer(exercise: any, correctSolution: any, userSolution: any): Evaluated {
        let correctPoints = 0;
        let indexes = [];
        let odd_indexes = [];

        for (let k = 0; k < exercise.exercise.draggables.length; k++) {
            let found = false;
            for (let j = 0; j < correctSolution.length; j++) {
                if (correctSolution[j].indexOf(k) != -1) {
                    found = true;
                    break;
                }
            }
            if (!found) odd_indexes.push(k);
        }

        for (let i = 0; i < correctSolution.length; i++) {
            for (let j = 0; j < correctSolution[i].length; j++) {
                let curIndex = correctSolution[i][j];
                if (indexes.indexOf(curIndex) == -1) indexes.push(curIndex);
                if (userSolution[i].indexOf(curIndex) != -1) correctPoints += 1;
            }
        }

        for (let i = 0; i < odd_indexes.length; i++) {
            for (let j = 0; j < userSolution.length; j++) {
                if(userSolution[j].indexOf(odd_indexes[i]) != -1) correctPoints--;
            }
        }
        if(correctPoints < 0) correctPoints = 0;
        let successPercent = Number((correctPoints / indexes.length).toFixed(2));
        return { success: successPercent == 1, successPercent, solution: correctSolution }
    }

}