import './style.css';
import { ExerciseBaseClass, AnswerElement } from '@src/component/exercise/models/ExerciseBaseClass';
import { ExerciseEngineHelper } from '../ExerciseEngineHelper';
import { AExerciseEngine } from '../../models/AExerciseEngine';
import { EKEPairingServer } from '../EKEPairingExerciseEngine/EKEPairingServer';
import { WMItemLinkLineServer } from './WMItemLinkLineServer';

export interface WMItemLinkLineData extends ExerciseBaseClass {
    items: string[];
    answers: string[];
    keywords: string;
    pair1: AnswerElement[];
    pair2: AnswerElement[];
    options: any;
}

type WMD2ASolution = {
    key: number;
    value: number[];
}

type WMILLSolution = {
    key: number;
    value: number[];
}

type WMItemLinkLineSolution = {
    fullmatch: boolean;
    answer: WMD2ASolution[];
}

type WMDndToAreaSolution = {
    fullmatch: boolean;
    answer: WMD2ASolution[];
}

export class WMItemLinkLineExerciseEngine extends AExerciseEngine {

    private static line_colors: string[] = [
        "#16a085",
        "#2980b9",
        "#2c3e50",
        "#e67e22",
        "#d35400",
        "#27ae60",
        "#8e44ad",
        "#7f8c8d"];
    private curr_line_idx = 0;
    private interaction: boolean = false;
    private items: any;
    private answers: any;
    private clearWrongAnsTimeout: any;

    initExercise(params: ExerciseParams): void {
        super.initExercise(params);
        let exercise: WMItemLinkLineData = params.exercise;

        if (!exercise.options || exercise.options.pair1.length == 0)
            return;

        this.items = [];
        this.answers = [];
        
        this.root.classList.add("wm-item-link-line");

        let conetntWrapper = this.root.appendChild(document.createElement("div"));
        conetntWrapper.classList.add("exe-ll-content-wrapper");

        this.setIllustration(exercise,conetntWrapper);        

        let gridDiv = conetntWrapper.appendChild(document.createElement("div"));
        gridDiv.classList.add("row");
        gridDiv.classList.add("grid-div");

        let itemContainer = gridDiv.appendChild(document.createElement("div"));
        itemContainer.classList.add("small-6");
        itemContainer.classList.add("column");
        itemContainer.classList.add("align-middle");
        itemContainer.classList.add("item-container");

        for (let index = 0; index < exercise.options.pair1.length; index++) {
            if (exercise.options.pair1[index].type != "") {
                if((exercise.options.pair1[index].image == "" && exercise.options.pair1[index].text == "") ||
                exercise.options.pair1[index].url == "" && exercise.options.pair1[index].text == "") continue;
                let itemElem = (document.createElement("div"));
                itemElem.classList.add("item-div");
                itemElem.classList.add("elem-div");
                itemElem.setAttribute("id", "item-" + index.toString());
                itemElem.setAttribute("data-type", "item");
                itemElem.setAttribute("data-index", index.toString());
                itemElem.setAttribute("data-checked", "false");
                itemElem.setAttribute("data-linked", "false");

                if (!this.isReplay) {
                    itemElem.addEventListener('click', this.click.bind(this), false);
                }

                let itemData = itemElem.appendChild(document.createElement("div"));
                itemData.classList.add("item-card");
                if (exercise.options.pair1[index].type == "image") {
                    AExerciseEngine.displayAnswer(itemData, exercise.options.pair1[index], this.is_accessible, ["answer-img"], this);
                } else {
                    AExerciseEngine.displayAnswer(itemData, exercise.options.pair1[index], this.is_accessible, [], this);
                }
                let itemPoint = itemElem.appendChild(document.createElement("div"));
                itemPoint.classList.add("item-point");
                itemPoint.classList.add("elem-point");
                this.items.push(itemElem);
            }
        }
        if (!exercise.ordered_answers) this.items = AExerciseEngine.shuffle(this.items);

        for (let ndxI = 0; ndxI < this.items.length; ndxI++) {
            itemContainer.appendChild(this.items[ndxI]);
        }

        let answerContainer = gridDiv.appendChild(document.createElement("div"));
        answerContainer.classList.add("small-6");
        answerContainer.classList.add("column");
        answerContainer.classList.add("align-right");
        answerContainer.classList.add("answer-container");

        for (let index = 0; index < exercise.options.pair2.length; index++) {
            if (exercise.options.pair2[index].type == "" || 
            (exercise.options.pair2[index].image == "" && exercise.options.pair2[index].text == "") ||
            exercise.options.pair2[index].sound == "" && exercise.options.pair2[index].text == "")  continue;

            let answerElem = (document.createElement("div"));
            answerElem.classList.add("answer-div");
            answerElem.classList.add("elem-div");
            answerElem.setAttribute("id", "item-" + index.toString());
            answerElem.setAttribute("data-type", "answer");
            answerElem.setAttribute("data-index", index.toString());
            answerElem.setAttribute("data-checked", "false");
            answerElem.setAttribute("data-linked", "false");

            if (!this.isReplay) {
                answerElem.addEventListener('click', this.click.bind(this), false);
            }

            let answerData = answerElem.appendChild(document.createElement("div"));

            if (exercise.options.pair2[index].type == 'image') {
                AExerciseEngine.displayAnswer(answerData, exercise.options.pair2[index], this.is_accessible, ["answer-img"], this);
            }
            else {
                AExerciseEngine.displayAnswer(answerData, exercise.options.pair2[index], this.is_accessible, [], this);
            }

            answerData.classList.add("item-card");
            let answerPoint = answerElem.appendChild(document.createElement("div"));
            answerPoint.classList.add("answer-point");
            answerPoint.classList.add("elem-point");

            this.answers.push(answerElem);
        }
        if (!exercise.ordered_answers) this.answers = AExerciseEngine.shuffle(this.answers);
        for (let ndxA = 0; ndxA < this.answers.length; ndxA++) {
            answerContainer.appendChild(this.answers[ndxA]);
        }

        /* Line correction */
        window.addEventListener('resize', this.correctLinePositions);

    }

    public changeInteraction(bool: boolean) {
        this.interaction = bool;
    }

    getUserSolution(): WMDndToAreaSolution {
        let result: any[] = [];
        let item_container: HTMLCollection = this.root.getElementsByClassName("item-div");
        let svg_items: HTMLCollection =  this.root.getElementsByClassName("svg");
        for (let icnt = 0; icnt < svg_items.length; icnt++) {
            const curr_elem = svg_items[icnt];
            let itemIndex: number = parseInt(String(curr_elem.getAttribute('data-item')));
            let answerIndex: number = parseInt(String(curr_elem.getAttribute('data-answer')));
            let res = { key: itemIndex, value: answerIndex}
            result.push(res);
        }

        for (let i = 0; i < item_container.length; i++) {
            let curr_elem = item_container[i];
            let itemIndex: number = parseInt(String(curr_elem.getAttribute('data-index')));
            let res = { key: itemIndex, value: null }
            let found = result.find(function(element){
                return element.key == itemIndex;
            })
            if (!found)
                result.push(res);
        }
        //sorting by key, ascending order
        result.sort((a, b) => (a.key > b.key) ? 1 : -1)
        return { answer: result, fullmatch: true };
    }

    receiveEvaluation(evaluated: Evaluated): void {
        /* delete Clear Wrong Answers timeout */
        clearTimeout(this.clearWrongAnsTimeout);

        if (evaluated.success) {
            $(this.root).find('.elem-div').removeClass("exe-engine-wrong-bg");
            $(this.root).find('.elem-div').addClass("exe-engine-correct-bg");
            $(this.root).find('.elem-div').removeClass("exe-engine-check-wrong");
        }
        if (!evaluated.success) {
            if (evaluated.solution != null) {
                let correctSolution = evaluated.solution;
                let userSolution: any[] = this.getUserSolution().answer;
                for (let icnt = 0; icnt < correctSolution.length; icnt++) {
                    let userFound = userSolution.find(function(element: any) {
                        return element.key == correctSolution[icnt].key && element.value == correctSolution[icnt].value;
                    });
                    let item: HTMLElement|null = this.root.querySelector('.item-container .item-div[data-index="' + icnt + '"]');
                    let answer: HTMLElement|null = this.root.querySelector('.answer-container .answer-div[data-index="' + correctSolution[icnt].value + '"]');

                    //Odd one out pair 1:
                    if (item && !answer && !this.isSNIexc) {
                        AExerciseEngine.removeEvalStyle(item);
                        if (item.getAttribute("data-linked")=="true")
                            item.classList.add("exe-engine-check-wrong");
                        else
                            item.classList.add("exe-engine-check-correct");
                        continue;
                    }

                    //Odd one out pair 2:
                    if (!item && answer && !this.isSNIexc){
                        AExerciseEngine.removeEvalStyle(answer);
                        if (answer.getAttribute("data-linked")=="true")
                            answer.classList.add("exe-engine-check-wrong");
                        else
                            answer.classList.add("exe-engine-check-correct");
                    }
                    if (item && answer){
                        AExerciseEngine.removeEvalStyle(answer);
                        AExerciseEngine.removeEvalStyle(item);
                        if (!userFound || userFound === undefined) {
                            //if (!this.isSNIexc) {
                            if (item.getAttribute("data-linked")=="true") {
                                item.classList.add("exe-engine-check-wrong");
                            }

                            if (answer.getAttribute("data-linked")=="true") {
                                answer.classList.add("exe-engine-check-wrong");
                            }
                        } else {
                            item.classList.add("exe-engine-check-correct");
                            answer.classList.add("exe-engine-check-correct");
                        }
                    }
                }
            }

            /* IF SNI, clear all wrong answers with delay */
            if (this.isSNIexc) {
                this.clearWrongAnsTimeout = setTimeout(() => {
                    let wrongItems = this.root.querySelectorAll('.item-container .item-div[data-index].exe-engine-check-wrong');
                    for (let i = 0; i < wrongItems.length; i++) {
                        let svg = this.root.querySelector('svg[data-item="' + wrongItems[i].getAttribute('data-index') + '"]');
                        if (svg) {
                            let itemIndex = svg.getAttribute('data-item');
                            let answerIndex = svg.getAttribute('data-answer');
                            let itemElement = this.root.querySelector('.item-div[data-index="' + itemIndex + '"]');
                            let answerElement = this.root.querySelector('.answer-div[data-index="' + answerIndex + '"]');

                            if (itemElement) {
                                itemElement.setAttribute('data-linked', 'false');

                                let itemPoint = itemElement.querySelector('.point-linked');
                                if (itemPoint) {
                                    itemPoint.classList.remove('point-linked');
                                }
                            }

                            if (answerElement) {
                                let answerPoint = answerElement.querySelector('.point-linked');
                                if (answerPoint) {
                                    answerPoint.classList.remove('point-linked');
                                }

                                answerElement.setAttribute('data-linked', 'false');
                            }

                            svg.remove();
                        }

                    }

                    let wrongElements = this.root.querySelectorAll('.exe-engine-check-wrong');
                    for (let i = 0; i < wrongElements.length; i++) {
                        AExerciseEngine.removeEvalStyle(<HTMLElement>wrongElements[i]);
                    }

                    console.log(this.getUserSolution().answer);
                },
                2000);
            }


        }
    }


    showCorrectSolution(solution: any): void {
        let $area: JQuery<HTMLElement> = $(this.root).find('.grid-div');
        $area.find('.svg').remove();
        for (let icnt = 0; icnt < solution.length; icnt++) {
           
            let item: HTMLElement|null = this.root.querySelector('.item-container .item-div[data-index="' + icnt + '"]');
            let answer: HTMLElement|null = this.root.querySelector('.answer-container .answer-div[data-index="' + solution[icnt].value + '"]');
            if (item && !answer) {
                AExerciseEngine.removeEvalStyle(item);    
            }
            else if (!item && answer) {
                AExerciseEngine.removeEvalStyle(answer);
            }
            else if (item && answer) {
                AExerciseEngine.removeEvalStyle(item);
                AExerciseEngine.removeEvalStyle(answer);
                this.createLine($(item), $(answer));
            }
        }
        this.changeInteraction(false);
    }

    isUserReady(): boolean {
        return this.interaction;
    }

    showHelp(solution: any): void {
        this.showCorrectSolution(solution);
    }


    click(ev: any) {
        ev.preventDefault();
        let $elem: JQuery<HTMLElement> = $(ev.target);
        try {
            if (!$elem.hasClass('elem-div')) {
                $elem = $elem.closest('.elem-div');
            }
            if ($elem.length == 0) {
                throw new Error();
            }
        } catch (error) {
            return;
        }
        let type: string = String($elem.attr('data-type'));
        let index: string = String($elem.attr('data-index'));
        let $point: JQuery<HTMLElement> = $elem.find('.elem-point');
        let checked: string = String($elem.attr('data-checked'));
        let linked: string = String($elem.attr('data-linked'));
        if (linked == "true") {
            let $svg: JQuery<HTMLElement> = $('svg[data-' + type + '="' + index + '"]');
            let indexItem: string = String($svg.attr("data-item"));
            let indexAnswer: string = String($svg.attr("data-answer"));
            $svg.remove();
            $(this.root).find('.item-container .item-div[data-index="' + indexItem + '"]').removeClass('point-linked').attr('data-linked', 'false');
            $(this.root).find('.answer-container .answer-div[data-index="' + indexAnswer + '"]').removeClass('point-linked').attr('data-linked', 'false');
            return;
        }

        if (checked == "false") {
            $elem.parent().find('.elem-point').removeClass('point-checked');
            $elem.parent().find('div.elem-div').attr("data-checked", "false");
            $point.addClass('point-checked');
            $elem.attr("data-checked", "true");
        } else {
            $point.removeClass('point-checked');
            $elem.attr("data-checked", "false");
        }
        let $other: JQuery<HTMLElement>;
        if (type == 'item') {
            $other = $(this.root).find('.answer-container .answer-div[data-checked="true"]');
            //$other = $('#answer-container').find('.answer-div[data-checked="true"]');
            if ($other.length !== 0) {
                this.createLine($elem, $other);
            }
        } else {
            $other = $(this.root).find('.item-container .item-div[data-checked="true"]');
            //$other = $('#item-container').find('.item-div[data-checked="true"]');
            if ($other.length !== 0) {
                this.createLine($other, $elem);
            }
        }
        this.changeInteraction(true);
        if (this.isSNIexc) this.SNIEvaluation( WMItemLinkLineServer);
    }


    createLine($item: JQuery<HTMLElement>, $answer: JQuery<HTMLElement>): void {
        let $area: JQuery<HTMLElement> = $(this.root).find('.grid-div');

        let svg: SVGElement = document.createElementNS("http://www.w3.org/2000/svg", 'svg');
        let line: SVGLineElement = document.createElementNS("http://www.w3.org/2000/svg", 'line');
        let line_shadow: SVGLineElement = document.createElementNS("http://www.w3.org/2000/svg", 'line');
        $(line).addClass('line');
        $(line_shadow).addClass('line');
        $(svg).addClass('svg');
        let wdtArea: number = parseFloat(String($area.width()));
        let hgtArea: number = parseFloat(String($area.height()));
        let wdtPointRad: number = parseFloat(String($item.find('.elem-point').width())) / 2;
        let hgtPointRad: number = parseFloat(String($item.find('.elem-point').height())) / 2;

        let itemX: number = (($item.parent().position().left + $item.position().left + $item.find('.elem-point').position().left)) + wdtPointRad + 3; //+3px due to line width
        let itemY: number = (($item.parent().position().top + $item.position().top + $item.find('.elem-point').position().top)) + hgtPointRad;
        let answerX: number = (($answer.parent().position().top + $answer.position().left + $answer.find('.elem-point').position().left)) + wdtPointRad;
        let answerY: number = (($answer.parent().position().top + $answer.position().top + $answer.find('.elem-point').position().top)) + hgtPointRad;

        itemX = (itemX / wdtArea * 100);
        answerX = (answerX / wdtArea * 100);
        itemY = (itemY / hgtArea * 100);
        answerY = (answerY / hgtArea * 100);
        wdtPointRad = ((wdtPointRad / wdtArea) * 100);
        hgtPointRad = ((hgtPointRad / hgtArea) * 100);

        let wdt: number = answerX - itemX;
        let hgt: number = answerY - itemY;

        $(svg).attr('width', wdt + '%');
        let x1 = 0, x2 = 0, y1 = 0, y2 = 0;
        let svg_top = 0;
        if (hgt < 0) {
            hgt = Math.abs(hgt);
            svg_top = answerY;
            x1 = 100;
            y2 = 100;
        } else if (hgt > 0) {
            svg_top = itemY;
            x2 = 100;
            y2 = 100;
        } else if (hgt == 0) {
            hgt = 2;
            svg_top = itemY;
            x2 = 100;
            //y1='1';
            // y2 = '1';
        }

        $(svg).css('top', svg_top + '%');

        $(line).attr('x1', x1 + '%');
        $(line).attr('y1', y1 + '%');
        $(line).attr('x2', x2 + '%');
        $(line).attr('y2', y2 + '%');

        $(line_shadow).attr('x1', x1 + '%');
        $(line_shadow).attr('y1', y1 + '%');
        $(line_shadow).attr('x2', x2 + '%');
        $(line_shadow).attr('y2', y2 + '%');


        $(line).css('stroke', WMItemLinkLineExerciseEngine.line_colors[this.curr_line_idx]);
        $(line).css('stroke-width', 3);
        $(line_shadow).css('stroke', "#0000004f");
        $(line_shadow).css('stroke-width', 6);
        $(svg).attr('height', (hgt) + '%');
        $(svg).css('left', itemX + '%');
        $(svg).attr('data-item', String($item.attr('data-index')));
        $(svg).attr('data-answer', String($answer.attr('data-index')));
        $(svg).append(line_shadow);
        $(svg).append(line);
        $area.append(svg);
        $item.find('.elem-point').addClass('point-linked').removeClass('point-checked');
        $answer.find('.elem-point').addClass('point-linked').removeClass('point-checked');
        $item.attr('data-linked', "true").attr('data-checked', "false");
        $answer.attr('data-linked', "true").attr('data-checked', "false");
        if (this.curr_line_idx + 1 == WMItemLinkLineExerciseEngine.line_colors.length) {
            this.curr_line_idx = 0;
        } else {
            this.curr_line_idx++;
        }
    }

    
    /* Correct lines position on resize with debouncing */
    correctLinePositions = ExerciseEngineHelper.debounce(() => {
        let currentLines = this.root.querySelectorAll('svg');
        for (let i = 0; i < currentLines.length; i++) {
            let itemIndex = currentLines[i].getAttribute('data-item');
            let answerIndex = currentLines[i].getAttribute('data-answer');
            let currentLineE: SVGLineElement | null = currentLines[i].querySelector('line');
            let lineColor: string | null = '';
            if (currentLineE) {
                lineColor = currentLineE.style.stroke;
            }
            currentLines[i].remove();
            let item: HTMLElement | null = this.root.querySelector('[data-type="item"][data-index="' + itemIndex + '"]');
            let answer: HTMLElement | null = this.root.querySelector('[data-type="answer"][data-index="' + answerIndex + '"]');
            if (item && answer) {
                this.createLine($(item), $(answer));
                let newLine: SVGAElement | null = this.root.querySelector('svg[data-item="' + itemIndex + '"][data-answer="' + answerIndex + '"] line');
                if (newLine && lineColor) {
                    newLine.style.stroke = lineColor;
                }
            }

        }

    }, 250);

}


