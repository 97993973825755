import * as React from 'react'
import { Link, NavLink } from 'react-router-dom';
import { SearchResult } from "@src/server/PublicServer";
import * as Server from '@src/server/PublicServer'
import { PATH_EXERCISE_PUB_VIEW, PATH_EXERCISE_VIEW, PATH_EXERCISE_SERIES_PUB_VIEW, getPath } from "@src/Routes";
import { ExerciseBaseClass, ExerciseEngineTypes } from "@src/component/exercise/models/ExerciseBaseClass";
import './ExerciseTile.css';
import * as ExerciseTypeConverter from "../models/ExerciseTypeConverter";
import { ToolTipDiv } from '../../ToolTipDiv';
import { __ } from '@src/translation';
import { exerciseModule } from '@src/module/exerciseModule/exerciseModule';


type ExerciseTileProps = {
    ExerciseResult: SearchResult; // The search result containing data of the exercise    
    published: boolean;
    engineName?: string;
    className?: string;
    engineTranslatedName?:string;
    // todo: get engine name, so we can display specific icon for it
}

type ExerciseTileState = {};// todo

export class ExerciseTile extends React.Component<ExerciseTileProps, ExerciseTileState> {

    constructor(props: ExerciseTileProps) {
        super(props);
        this.state = {};
    }

    async componentDidMount() {

    }

    getExerciseImage(exerciseResult: SearchResult): string {

        if (this.props.engineName != ExerciseEngineTypes.Series) {

            if (!exerciseResult.exercise_json) return "";
            let exercise: ExerciseBaseClass = JSON.parse(exerciseResult.exercise_json);
            let img = "/img/IKON_SET/FELADATMOTOR/Lista/quiz.png";

            if (exercise.illustration) {
                img = exercise.imagebasepath + exercise.illustration;
            } else if (this.props.engineName) {
                img = ExerciseTypeConverter.getIconOfEngine(this.props.engineName, true);
            }
            return img;

        } else {
            return "/img/IKON_SET/FELADATMOTOR/Lista/quiz.png";
        }
    }

    async onSearchHit(result: SearchResult) {
        console.log("onSearchHit");
        await Server.onSearchHit(result);
    }

    render() {

        let exercise = { title: "", level: 1 };
        let img = this.getExerciseImage(this.props.ExerciseResult);
        let path = "";
        let title = "";
        let exe_name: string | undefined = "";

        if (this.props.engineName == ExerciseEngineTypes.Series) {
            exercise = { title: this.props.ExerciseResult.title!, level: this.props.ExerciseResult.exercise_level ? this.props.ExerciseResult.exercise_level : 2 };
            path = (getPath({PATH_EXERCISE_SERIES_PUB_VIEW}, exerciseModule)) + "/" + this.props.ExerciseResult.id;
            title = __("Feladatsor");
            exe_name = this.props.ExerciseResult.exercise_title;
        } else {
            if (!this.props.ExerciseResult.exercise_json) return "";
            exercise = JSON.parse(this.props.ExerciseResult.exercise_json);
            title = this.props.engineTranslatedName|| "";
            path = (this.props.published ? getPath({PATH_EXERCISE_PUB_VIEW}, exerciseModule) : getPath({PATH_EXERCISE_VIEW}, exerciseModule)) + "/" + this.props.ExerciseResult.id;
            exe_name = this.props.ExerciseResult.exercise_name;
        }

        title += "<br>" + (this.props.ExerciseResult.subject_name ? this.props.ExerciseResult.subject_name + "<br>" + this.props.ExerciseResult.grade_name : "" + "<br>" + this.props.ExerciseResult.grade_name);

        try {

            return (
                <ToolTipDiv key={this.props.ExerciseResult.id} className={this.props.className ? this.props.className : "column medium-4 small-12"} title={title}>
                    <NavLink to={path} onMouseDown={this.onSearchHit.bind(this, this.props.ExerciseResult)} className="eke-exercisetile-div" aria-haspopup="false">
                        <div className="eke-exercisetile-inner-div">
                            
                                <img src={img} alt={exe_name}
                                    title={this.props.ExerciseResult.title} className="eke-exercisetile-thumbnail eke-exercisetile-contentdiv" aria-hidden="true" />
                            
                            <div className="align-center">
                                <h3 className="eke-exercisetile-contentdiv">
                                    {exe_name}
                                </h3>
                                <span className="show-for-sr">{this.props.ExerciseResult.engine_name ? this.props.ExerciseResult.engine_name : ""}</span>
                                <span className="show-for-sr">{this.props.ExerciseResult.subject_name ? this.props.ExerciseResult.subject_name + "/" + this.props.ExerciseResult.grade_name : "" + "/" + this.props.ExerciseResult.grade_name}</span>
                                <span className="show-for-sr">{__("Szint:")} {exercise.level}</span>
                            </div> 
                            <span className="level">
                                <img src={"/img/IKON_SET/FELADATMOTOR/KONTENER_GOMBOK/vegleges/feladat_nehezseg0" + exercise.level + "_feladattar.svg"} alt={__("Nehézségi szint {level}", {level: exercise.level})} title={__("Nehézségi szint: {level}", {level: exercise.level})} /> 
                            </span>
                            
                                {/* <span className="badge" aria-hidden="true">                                
                                    {exercise.level} 
                                </span> */}

                        </div>
                    </NavLink>
                </ToolTipDiv>

            );

        } catch (e) {
            console.log("error: ", e);
            console.log("wrong json", this.props.ExerciseResult.exercise_json);
            return "";
        }

    }
}

