import * as React from 'react';

import { IViewTicketInquiryCategoryRecord, ViewTicketInquiryCategoryClassProxy, viewTicketInquiryCategoryClassProxy } from '@src/framework/view/ticket/ViewTicketInquiryCategory';
import ListView from '@framework/forms/listview';
import { __ } from '@src/translation';


class InquiryCategoryInner extends ListView<IViewTicketInquiryCategoryRecord> {

    public getViewClassProxy() : ViewTicketInquiryCategoryClassProxy { return viewTicketInquiryCategoryClassProxy; }

    protected getQuickFilterFieldNames(): string[] {
        return ['title'];
    }    

}

export default function InquiryCategoryListView(props: any) {
        return <InquiryCategoryInner 
            defaultPageSize={10}
            match={props.match}        
            viewColumns={[
                {accessor: "id", maxWidth: 100},
                {accessor: "title"},
                /*
                {accessor: "creation_time"},
                {accessor: "creator"},
                {accessor: "modification_time"},
                {accessor: "modifier"},
                */
                {accessor: "link", Header:"Műveletek", maxWidth:200},
            ]}
        />
    }