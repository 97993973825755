export class WMNumberPyramidServer{
    
    static evaluateOnServer(exercise: any, correctSolution: any[], userSolution: any[]): Evaluated | boolean {
        let success = true;
        let successPercent = 0;
        let correctAnsNum = 0;
        let NumOfQuestions = 0;
        for (let i = 0; i < userSolution.length; i++) {
            for (let j = 0; j < userSolution[i].length; j++) {
                NumOfQuestions++;
                if (correctSolution[i].indexOf(userSolution[i][j]) == -1) {
                    success = false;
                }
                else
                    correctAnsNum++;
            }
        }
        if (correctAnsNum > 0 && NumOfQuestions != 0)
            successPercent = Number((correctAnsNum / NumOfQuestions).toFixed(2));
        return { "success": success, "solution": correctSolution, "successPercent": successPercent };
    }
}