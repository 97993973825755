import { Server } from '../../server/Server';
import Crud , { registerCrudClassProxy, IAsTextResult, IListParams, CrudClassProxy, ISearchTextParams, ISearchTextResult } from '../Crud';

export interface ISecViewInfoPermRecord {
    /**
     * Globálisan egyedi azonosító.
     * 
     * Ha új rekordot akarsz létrehozni put() metódussal, akkor hagyd üresen.
     * upsert() hívásnál figyelmen kívül van hagyva az értéke.
     *
     */
    id ?: number;
    /**
     * Rekord létrehozás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel létrehozásakor.
     */
    creation_time?: string /*timestamp?*/;
    /**
     * Ki hozta létre
     *
     * NOT NULL -> sys.sec_user ON DELETE noaction
     *
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozó felhasználóra hivatkozik.
     */
    creation_user_id?: number;
    /**
     * Melyik session-ből jött létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozáshoz használt munkamenetre hivatkozik.
     */
    creation_session_id?: number|null;
    /**
     * Utolsó módosítás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel módosításakor.
     */
    modification_time?: string /*timestamp?*/|null;
    /**
     * Ki módosította utoljára
     *
     * -> sys.sec_user ON DELETE noaction
     *
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosító felhasználóra hivatkozik.
     */
    modification_user_id?: number|null;
    /**
     * Melyik session-ből módosították utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosításhoz használt munkamenetre hivatkozik.
     */
    modification_session_id?: number|null;
    /**
     * Nézettábla információ
     *
     * NOT NULL -> meta.view_info ON DELETE noaction
     *
     */
    view_info_id?: number;
    /**
     * Engedély birtokos
     *
     * NOT NULL -> sys.sec_perm_owner ON DELETE cascade
     *
     */
    sec_perm_owner_id?: number;
    /**
     * Olvasás
     *
     * Létező rekordok lekérdezése, szűrése
     */
    can_read?: boolean;
}

/**
 *  meta.sec_view_info_perm - Nézettábla jog
 *
 *  A View alrendszer jogosultságait tárolja.
 */

export default class SecViewInfoPermCrud extends Crud<ISecViewInfoPermRecord> {
    public static TABLE_INFO_ID = 1793153422;
    public static getSchemaNameForClass() { return 'meta'; }
    public static getTableNameForClass() { return 'sec_view_info_perm'; }

    public static load: (id: number, aServer ?: Server) => Promise<SecViewInfoPermCrud>;
    public static asText: (id: number, includeRecord : boolean, aServer ?: Server) => Promise<IAsTextResult<ISecViewInfoPermRecord>>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<ISecViewInfoPermRecord[]>;
    public static searchText: (params: ISearchTextParams, aServer ?: Server) => Promise<ISearchTextResult<ISecViewInfoPermRecord>[]>;
    public static deleteById: (id: number, aServer ?: Server) => Promise<ISecViewInfoPermRecord>;
    public static unDeleteById: (id: number, aServer ?: Server) => Promise<ISecViewInfoPermRecord>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class SecViewInfoPermCrudClassProxy extends CrudClassProxy<ISecViewInfoPermRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const secViewInfoPermCrudClassProxy = new SecViewInfoPermCrudClassProxy(SecViewInfoPermCrud);
registerCrudClassProxy(secViewInfoPermCrudClassProxy);
