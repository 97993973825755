import * as ExerciseBaseTypes from "@src/component/exercise/models/ExerciseBaseClass";
import { AExerciseTypeConverter, ValidationResponse, StatisticsResponse, ShuffleResult } from "@src/component/exercise/models/AExerciseTypeConverter";
import { SortingData } from "@src/component/exercise/engine/EKESortingExerciseEngine/EKESortingExerciseEngine";
import { AnswerTypes } from '@src/component/exercise/models/ExerciseBaseClass';
import * as React from "react";
import ExerciseFileSelect, { ExerciseFileTypes } from "@src/component/exercise/Editor/ExerciseFileSelect";
import { Panel } from '@src/component/ui/Panel';
import { __ } from '@src/translation';
import { AExerciseEngine } from "../../models/AExerciseEngine";

export type EKESortingAnswerElement = {
    answers: SortingMultyAnswerType[],
    horizontal: boolean
}

export type SortingMultyAnswerType = {
    type: AnswerTypes,
    url: string,
    text: string,
}

export class EKESortingConverter extends AExerciseTypeConverter {

    public hasTextAnswer = false;
    public hasImageAnswer = true;
    public hasTextImageAnswer = false;
    static TEXT_ANSWER_LENGTH: number = 250;
    static MAX_ELEMENT_NUM: number = 20;

    render() {
        let curr_ex: EKESortingAnswerElement = this.state.exercise as EKESortingAnswerElement;
        return (
            <Panel header={__("Részletek")} headingLevel={4}>
                <div className="row">
                    <div className="large-12 columns">
                        <label ><input type="checkbox" name="horizontal" checked={curr_ex.horizontal || false}
                            onBlur={this.onBlurEvent.bind(this)}
                            onChange={this.handleInputChange.bind(this)} />
                            {__("Horizontális megjelenítés")}</label>
                    </div>
                </div>

                <Panel header={__("Elemek")} headingLevel={5}>
                    {
                        curr_ex.answers ?
                            curr_ex.answers.map((curr_task: any, index) => {
                                return (
                                    <Panel>
                                    <legend>
                                        <label className="exe-editor-fieldlabel-1">{index + 1}. {__("Elem")}</label>
                                        <button className="button small alert exercise-series-small-btn" title={__("Törlés")} onClick={this.removeElement.bind(this, "answers", index)}><i className="fa fa-trash"></i></button>
                                        <button className="button small exercise-series-small-btn" title="Fel" onClick={this.moveUp.bind(this, "answers", index)} ><i className="fa fa-arrow-up"></i></button>
                                        <button className="button small exercise-series-small-btn" title="Le" onClick={this.moveDown.bind(this, "answers", index)}><i className="fa fa-arrow-down"></i></button>
                                    </legend>
                                    <span className="exe-editor-validation-msg">{this.props.validationMessages.get("answers[" + index + "].type")}</span>
                                    <div className="row">
                                        <div className="medium-4 small-12 columns">
                                            <label> {__("Válasz típusa")}
                                            <select value={curr_task.type} name={"answers#" + index + ".type"} onChange={this.handleInputChange.bind(this)} onBlur={this.onBlurEvent.bind(this)}>
                                                    <option value={AnswerTypes.text}>{__("Szöveg")}</option>
                                                    <option value={AnswerTypes.image}>{__("Kép")}</option>
                                                    <option value={AnswerTypes.sound}>{__("Hang")}</option>
                                                </select></label>
                                        </div>
                                        <div className="medium-8 small-12 columns">
                                            <label>{ __("Leírás")}
                                                <span className="exe-editor-validation-msg">{this.props.validationMessages.get("answers[" + index + "].text")}</span>
                                                <input type="text" name={"answers#" + index + ".text"} data-parent-index={index} data-index={index} value={curr_task.text}
                                                    onBlur={this.onBlurEvent.bind(this)}
                                                    onChange={this.handleInputChange.bind(this)} />
                                            </label>
                                        </div>
                                    </div>

                                    <div className="row">
                                    <div className="small-12 columns">
                                        {curr_task.type != AnswerTypes.text ?<label className="exe-image-select-label">{curr_task.type == AnswerTypes.image ? __("Kép") : __("Hang")}
                                                {<ExerciseFileSelect
                                                    imagebasepath={this.props.imagebasepath}
                                                    value={curr_task.url || ""}
                                                    onChange={this.handleImageChange.bind(this, "answers#" + index + ".url")}
                                                    getFolderId={this.getFolderId.bind(this)}
                                                    fileType={curr_task.type == AnswerTypes.image ? ExerciseFileTypes.Image : ExerciseFileTypes.Sound}
                                                />}
                                            </label> : ""}    
                                            </div>                                    
                                    </div>
                                </Panel>)
                            })
                            : ""
                    }
                    <div className="row">
                        <button className="button small columns" onClick={this.onAddNewAnswer.bind(this)}><i className="fa fa-plus"></i> {__("Elem hozzáadása")}</button>
                        <label className="exe-editor-label-description columns">{__("Az elemek maximális száma {n} lehet!", {n: EKESortingConverter.MAX_ELEMENT_NUM})}</label>
                    </div>
                </Panel>
            </Panel>
        );
    }

    onAddNewAnswer() {
        let temp_exercise = this.state.exercise as EKESortingAnswerElement;
        if (!temp_exercise.answers) temp_exercise.answers = [];
        temp_exercise.answers.push(this.getNewAnswer());
        if(this.makeValidBeforeSetState(this.state.exercise, temp_exercise).valid)
            this.setState({ exercise: temp_exercise });
    }

    getNewAnswer(): SortingMultyAnswerType {
        let newElement: SortingMultyAnswerType = {
            text: "",
            type: AnswerTypes.text,
            url: ""
        };
        return newElement;
    }

    public convertToEditorAnswer(exercise: SortingData): EKESortingAnswerElement | undefined {
        if (exercise) {
            var answerList = [];
            var cur_exercise = exercise;
            if (cur_exercise.options) {
                for (var _i = 0; _i < cur_exercise.solution.length; _i++) {
                    var realIndex = cur_exercise.solution[_i];
                    var curr_ans: any = {};

                    if (cur_exercise.options[realIndex].type == "image") {
                        curr_ans =
                            {
                                type: AnswerTypes.image,
                                url: cur_exercise.options[realIndex].image,
                                text: cur_exercise.options[realIndex].text,
                            };
                    }
                    else if (cur_exercise.options[realIndex].type == "sound") {
                        curr_ans =
                            {
                                type: AnswerTypes.sound,
                                url: cur_exercise.options[realIndex].url,
                                text: cur_exercise.options[realIndex].text,
                            };
                    }
                    else {
                        curr_ans =
                            {
                                type: AnswerTypes.text,
                                url: "",
                                text: cur_exercise.options[realIndex].text,
                            };
                    }
                    answerList.push(curr_ans);
                }
            }

            var answerElement: EKESortingAnswerElement = {
                answers: answerList,
                horizontal: cur_exercise.horizontal
            }
            return answerElement;
        }
        else return undefined;
    }

    public convertToJson(exerciseDetails: EKESortingAnswerElement, baseData: ExerciseBaseTypes.ExerciseBaseClass, prevJSON?: EKESortingAnswerElement): SortingData | undefined {

        var ans_list = [];
        var sol_list = [];

        var cur_exercise = ExerciseBaseTypes.convertToBaseJson(baseData);

        if (exerciseDetails && exerciseDetails.answers) {
            //var shuffleAnswers: ShuffleResult = shuffle(exerciseDetails.answers, null);
            for (var _i = 0; _i < exerciseDetails.answers.length; _i++) {
                if (exerciseDetails.answers[_i].type == AnswerTypes.image) {
                    let curr_ans = {
                        type: "image",
                        text: exerciseDetails.answers[_i].text,
                        image: exerciseDetails.answers[_i].url
                    }
                    ans_list.push(curr_ans);
                } else if ((exerciseDetails.answers[_i].type == AnswerTypes.sound)) {
                    let curr_ans = {
                        type: "sound",
                        text: exerciseDetails.answers[_i].text,
                        url: exerciseDetails.answers[_i].url
                    }
                    ans_list.push(curr_ans);
                }
                else {
                    let curr_ans = {
                        type: "text",
                        text: exerciseDetails.answers[_i].text,
                        image: ""
                    }
                    ans_list.push(curr_ans);
                }
                sol_list.push(_i);
            }

            let temp:ShuffleResult = AExerciseEngine.shuffleWithIndexes(ans_list);

            cur_exercise = {
                ...cur_exercise,
                options: temp.answers,
                solution: temp.indexes,
                horizontal: exerciseDetails.horizontal
            };

            return cur_exercise;
        }
        return cur_exercise;
    }
    public convertOldNKPToJson(data: any): any | undefined {


        var solution = [];
        var newOptionsList = [];
        var isImage = false;

        if (data && data.OrderAnswerItems) {

            for (let index = 0; index < data.OrderAnswerItems.length; index++) {
                if (data.OrderAnswerItems[index]["$id"]) {
                    let cur_ans = ExerciseBaseTypes.convertOldNKPToAnswerElement(data.OrderAnswerItems[index], data);
                    newOptionsList.push(cur_ans);
                    if (cur_ans.type == "image") isImage = true;

                    solution.push(data.OrderAnswerItems[index].Order);
                }
            }

            let newExercise = {
                title: data.Title,
                description: data.QuestionText,
                backgroundStyle: { is_custom_background: false, backgroundImage: "", backgroundColor: "" },
                illustration: (ExerciseBaseTypes.convertOldNKPToAnswerElement(data, data).image != "" && ExerciseBaseTypes.convertOldNKPToAnswerElement(data, data).image != undefined) ?
                    ExerciseBaseTypes.convertOldNKPToAnswerElement(data, data).image : null,
                level: data.QuestionDifficulty == 1 ? 1 : data.QuestionDifficulty == 2 ? 3 : 5,
                demo_path: "",
                imageanswers: isImage,
                imagebasepath: "", //await EditorServer.getExerciseNewImageBasePath()
                comment: data.Description,
                options: newOptionsList,
                solution: solution
            };

            return newExercise;

        }
        return null;

    }

    public isSolutionAvailable(exerciseJson: ExerciseBaseTypes.ExerciseBaseClass): boolean {
        if (!super.isSolutionAvailable(exerciseJson))
            return false;
        if (exerciseJson.solution.length < 2) return false;
        return true;
    }

    public validate(editorAnswer: EKESortingAnswerElement, baseData: any, validationMap?:Map<string,string>, is_accessible?:boolean|null): ValidationResponse {
        let superAnswer: ValidationResponse = super.validate(editorAnswer, baseData,validationMap, is_accessible);
        if (!superAnswer.valid) return superAnswer;
        let errorMsg="";
        let OFIErrors: string[] = [];
        if(editorAnswer.answers && (editorAnswer.answers.length < 2 || (editorAnswer.answers.length > EKESortingConverter.MAX_ELEMENT_NUM))){
            errorMsg = __("Az elemek száma minimum {min} és maximum {max} között lehet!", {min:2,max: EKESortingConverter.MAX_ELEMENT_NUM});
            if(!OFIErrors.includes(errorMsg, 0)) OFIErrors.push(errorMsg);
            if(!AExerciseTypeConverter.isOfiEditor ) return { valid: false, message: errorMsg}
        }

        for (let i = 0; i < editorAnswer.answers.length; i++) {
            if (editorAnswer.answers[i]){
                if (editorAnswer.answers[i] && editorAnswer.answers[i].text.length > EKESortingConverter.MAX_ALT_TEXT_LENGTH){
                    errorMsg = __("A válasz hossza nem lehet több mint {n} karakter!", {n: EKESortingConverter.MAX_ALT_TEXT_LENGTH});
                    if(!OFIErrors.includes(errorMsg, 0)) OFIErrors.push(errorMsg);
                    validationMap!.set("answers[" + i + "].text", errorMsg);
                    if(!AExerciseTypeConverter.isOfiEditor ) return { valid: false, message: errorMsg }
                }
            }
        }
        if (AExerciseTypeConverter.isOfiEditor){
            return { valid: true, message: OFIErrors.join(' , ') }
        }

        return { valid: true }
        
    }

    public makeValidBeforeSetState(oldState: EKESortingAnswerElement, newState: EKESortingAnswerElement): ValidationResponse {
        if(AExerciseTypeConverter.isOfiEditor ) return {valid:true};
        if (newState.answers){
            let maxNum = EKESortingConverter.MAX_ELEMENT_NUM;
            if (newState.answers.length > maxNum){
                newState.answers.splice(maxNum, newState.answers.length - maxNum);
                return { valid: false, message: __("Az elemek maximális száma {n} lehet!",{n:maxNum})};
            }
        }
        return { valid: true }
    }

    public getExerciseStatistics(exerciseList: any): StatisticsResponse[] {
        if (!exerciseList || exerciseList.length == 0) return [];
        let statisticsResponses: StatisticsResponse[] = [];
        let answersStat, answerTextStat: StatisticsResponse;
        statisticsResponses.push({name:__("limit - max. elemszám: {n}", {n: EKESortingConverter.MAX_ELEMENT_NUM}), count:undefined});
        statisticsResponses.push({name:__("limit - elem max. karakterszám: {n}", {n: AExerciseTypeConverter.TEXT_ANSWER_LENGTH}), count:undefined});
        answersStat = { name: __("Elemek száma"), count: new Map() }
        answerTextStat = { name: __("Válaszelem karakterszáma"), count: new Map() }
        for (let i = 0; i < exerciseList.length; i++) {
            if (!exerciseList[i]) continue;
            const currentExc = exerciseList[i];
            let task_count = answersStat.count.has(currentExc.answers.length) ? answersStat.count.get(currentExc.answers.length) : 0;
            answersStat.count.set(currentExc.answers.length, task_count! + 1);
            for (let j = 0; j < currentExc.answers.length; j++) {
                const currentAnswer = currentExc.answers[j];
                let answer_text_count = answerTextStat.count!.has(currentAnswer.text.length) ? answerTextStat.count!.get(currentAnswer.text.length) : 0;
                answerTextStat.count!.set(currentAnswer.text.length, answer_text_count! + 1);
            }
        }
        statisticsResponses.push(answersStat, answerTextStat);
        return statisticsResponses;
    }
}