import * as React from 'react';
import { Tabs, TabList, Tab, TabPanel } from 'react-tabs';
import { BubbleLoader } from 'react-css-loaders';

import ViewMediaOoFolder, { IViewMediaOoFolderRecord } from '@src/framework/view/media/ViewMediaOoFolder';
import { app } from '@src/index';
import { hasGroup, me, Groups } from '@src/framework/server/Auth';
import LookupEdit from '@src/framework/forms/lookupedit';
import SecUserCrud from '@src/framework/crud/sys/SecUserCrud';
import OoFolderCrud from '@src/framework/crud/media/OoFolderCrud';
import VfsPermissionAPI from '@src/component/vfs/vfs_permisson_api';
import OOFolderPermTable from '@src/component/filemanager/perm/OOFolderPermTable';
import OOFolderAuditPermTool from '@src/component/filemanager/perm/OOFolderAuditPermTool';
import { __ } from '@src/translation';



export interface IFolderPermsDialogProps {
    ooFolderId: number;
    onClose: () => void;
}
interface IFolderPermsDialogState {
    folder?: IViewMediaOoFolderRecord;
    set_suid : boolean;
    clear_suid : boolean;
    set_inherit: boolean;
    clear_inherit: boolean;
    loading: boolean;
    changed: boolean;
    new_owner_id : number | null;
}

export default class FolderPermsDialog extends React.Component<IFolderPermsDialogProps, IFolderPermsDialogState> {
    constructor(props: IFolderPermsDialogProps) {
        super(props);
        this.state = {
            loading: true, changed: false,
            set_suid : false,
            clear_suid : false,
            set_inherit: false,
            clear_inherit: false,
            new_owner_id: null,
        };
    }

    componentDidMount() {
        this.asyncReload();
    }

    private asyncReload = async () => {
        try {
            const folderCrud = await ViewMediaOoFolder.load(this.props.ooFolderId);
            this.setState({
                folder: folderCrud.record,
                loading: false, changed: false
            }
            );
        } catch (error) {
            app.showErrorFromJsonResult(error);
        }
    }

    private onOwnerIdChange = (newOwnerId: any) => {
        if ((typeof newOwnerId === 'string'))
            if (newOwnerId.trim() === '')
                newOwnerId = '';
        if (newOwnerId === '')
            newOwnerId = null;
        this.setState({ new_owner_id: newOwnerId });
    }

    private onSuidToggle = () => {
        let folder = this.state.folder!;
        folder.suid = folder.suid ? false : true;
        this.setState({ folder, changed: true });
    }

    private onInheritPermsToggle = () => {
        let folder = this.state.folder!;
        folder.inherit_perms = folder.inherit_perms ? false : true;
        this.setState({ folder, changed: true });
    }

    private onTakeOwnership = async () => {
        const recursive = confirm(__('Szeretné az aktuális beállítást az összes tartamazott elemre is alkalmazni?'));
        try {
            const folder = this.state.folder!;
            this.setState({ loading: true });
            await VfsPermissionAPI.takeFolderOwnership(
                folder.id!, recursive,
                folder.suid || false, !folder.suid,
                folder.inherit_perms || false, !folder.inherit_perms,
            );
            // Szépséghiba hogy az egyéb pending változásokat ez eltünteti...
            this.asyncReload();
            app.showInfo(__("Sikeres művelet"),
             __(`A(z) {folder_title} mappa saját tulajdonba vétele sikeres volt.`, {folder_title:folder.title} ));
        } catch (error) {
            app.showErrorFromJsonResult(error);
        }
    }

    private onCloseDialog = async () => {
        this.props.onClose();
    }

    private onSave = async () => {
        const folder = this.state.folder!;
        let rec = {
            id: folder.id!,
            inherit_perms: folder.inherit_perms ? true : false,
            suid: folder.suid ? true : false
        }
        try {
            await new OoFolderCrud(rec).put();
            app.showInfo(__("Sikeres művelet"), 
            __(`A(z) {folder_title} mappa jogosultságok beállítása sikeres volt.`, {folder_title:folder.title} ));
            this.props.onClose();
        } catch (error) {
            app.showErrorFromJsonResult(error);
            this.setState({ loading: true }, this.asyncReload);
        }

    }

    private isAdmin = (): boolean => {
        return hasGroup(me!, Groups.Admin);
    }

    private setSuidClicked = () => {
        if (this.state.set_suid) {
            this.setState({set_suid: false});
        } else {
            this.setState({clear_suid: false, set_suid:true});
        }
    }

    private clearSuidClicked = () => {
        if (this.state.clear_suid) {
            this.setState({clear_suid: false});
        } else {
            this.setState({set_suid: false, clear_suid:true});
        }
    }


    private setInheritClicked = () => {
        if (this.state.set_inherit) {
            this.setState({set_inherit: false});
        } else {
            this.setState({clear_inherit: false, set_inherit:true});
        }
    }

    private clearInheritClicked = () => {
        if (this.state.clear_inherit) {
            this.setState({clear_inherit: false});
        } else {
            this.setState({set_inherit: false, clear_inherit:true});
        }
    }

    private saveAdvanced = async (do_self: boolean, do_children: boolean) => {
        try {
            this.setState({ loading: true });
            await VfsPermissionAPI.advancedSetFolderPerms(
                this.props.ooFolderId,
                do_self,
                do_children,
                this.state.new_owner_id,
                this.state.set_suid,
                this.state.clear_suid,
                this.state.set_inherit,
                this.state.clear_inherit
            );
            this.asyncReload();
            app.showInfo(__("Sikeres művelet"), __(`A jogosultságok beállítása sikeres volt.`));
        } catch (error) {
            app.showErrorFromJsonResult(error);
        }
    }

    public render() {
        if (this.state.loading) {
            return <BubbleLoader />;
        }


        const folder = this.state.folder!;
        const oo_folder_id = parseInt(folder.id!.toString());
        return <Tabs>
            <TabList>
                <Tab>{__("Alapadatok")}</Tab>
                {this.isAdmin() ?
                    <Tab>{__("Haladó")} (admin)</Tab> : ""
                }
                {folder.inherit_perms ? "" :
                    <Tab>{__("Kiosztott jogok")}</Tab>
                }
                {this.isAdmin() ?
                    <Tab>Audit</Tab> : ""
                }
            </TabList>
            <TabPanel key="perms_basic">
                <div className="row expanded">
                    <div className="small-12 column">
                        <label>{__("Elérési út:")}</label>
                        {folder.relpath}
                    </div>
                    <div className="small-12 column">
                        <label>{__("Tulajdonos:")}</label>
                        {folder.owner}
                    </div>
                    <div className="small-12 column" />
                    <div className="small-2 column">
                        <div className="switch">
                            <input className="switch-input" id="suid" type="checkbox"
                                checked={folder.suid ? true : false} onChange={this.onSuidToggle}
                            />
                            <label className="switch-paddle" htmlFor="suid">
                                <span className="show-for-sr">{__("Közös használatú - a tartalmazott elemek tulajdonosa nem a létrehozó, hanem a mappa tulajdonosa lesz")}</span>
                            </label>
                        </div>
                    </div>
                    <div className="small-10 column">
                        {__("Közös használatú - a tartalmazott elemek tulajdonosa nem a létrehozó, hanem a mappa tulajdonosa lesz")}
                    </div>
                    <div className="small-12 column" />
                    <div className="small-2 column">
                        <div className="switch">
                            <input className="switch-input" id="inherit_perms" type="checkbox"
                                checked={folder.inherit_perms ? true : false} onChange={this.onInheritPermsToggle}
                            />
                            <label className="switch-paddle" htmlFor="inherit_perms">
                                <span className="show-for-sr">{__("Jogosultságok öröklése a tartalmazó mappától")}</span>
                            </label>
                        </div>
                    </div>
                    <div className="small-10 column">
                        {__("Jogosultságok öröklése a tartalmazó mappától")}
                    </div>

                    <div className="large-4 small-6 columns">
                        <a onClick={() => { if (this.state.changed) { this.onSave(); } }}
                            className={"button success " + (this.state.changed ? "" : "disabled")}>
                            <i className="fa fa-save" /> &nbsp;{__("Mentés")}</a>
                    </div>
                    <div className="large-4 small-6 columns">
                        <a onClick={() => this.onTakeOwnership()} className="button warning"><i className="fa fa-user-o" /> &nbsp;{__("Mentés és saját tulajdonba vétel")}</a>
                    </div>
                    <div className="large-4 small-6 columns  text-right">
                        <a onClick={() => this.onCloseDialog()} className="button alert"><i className="fa fa-times" /> &nbsp;{__("Mégse")}</a>
                    </div>
                </div>
            </TabPanel>
            {this.isAdmin() ?
                <TabPanel key="perms_advanced">
                    <div className="row expanded">
                        <div className="small-12 column">
                            <label>{__("Elérési út:")}</label>
                            {folder.relpath}
                        </div>
                        <div className="small-12 column">
                            <label>{__("Tulajdonos:")}
                            <LookupEdit
                                key={"ownerId" + (this.state.new_owner_id || 'null')}
                                name="ownerId"
                                fk_table_info_id={SecUserCrud.TABLE_INFO_ID}
                                clearable={false}
                                value={this.state.new_owner_id!}
                                onChange={(newValue) => this.onOwnerIdChange(newValue)}
                            />
                            </label>
                        </div>
                        <div className="small-12 column" />
                        <div className="small-12 column">
                            <table>
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>{__("Beállít")}</th>
                                        <th>{__("Töröl")}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{__("Közös használatú")}</td>
                                        <td>
                                            <input type="checkbox"
                                                checked={this.state.set_suid}
                                                onClick={this.setSuidClicked}
                                            />
                                        </td>
                                        <td>
                                            <input type="checkbox"
                                                checked={this.state.clear_suid}
                                                onClick={this.clearSuidClicked}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>{__("Jogosultságok öröklése")}</td>
                                        <td>
                                            <input type="checkbox"
                                                checked={this.state.set_inherit}
                                                onClick={this.setInheritClicked}
                                            />
                                        </td>
                                        <td>
                                            <input type="checkbox"
                                                checked={this.state.clear_inherit}
                                                onClick={this.clearInheritClicked}
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>    
                        <div className="large-4 small-6 columns">
                            <a onClick={() => { this.saveAdvanced(true, false) }}
                                className="button primary">
                                <i className="fa fa-save" /> &nbsp;{__("Alkalmazás erre a mappára")}</a>
                        </div>                                        
                        <div className="large-4 small-6 columns">
                            <a onClick={() => { this.saveAdvanced(true, true) }}
                                className="button primary">
                                <i className="fa fa-tree" /> &nbsp;{__("Erre és a tartalmazott elemekre")}</a>
                        </div>                                        
                        <div className="large-4 small-6 columns">
                            <a onClick={() => { this.saveAdvanced(false, true) }}
                                className="button primary">
                                <i className="fa fa-pagelines" /> &nbsp;{__("Csak a tartalmazott elemekre")}</a>
                        </div>                                        
                    </div>
                </TabPanel>
                : ""}
            {folder.inherit_perms ? "" :
                <TabPanel key="perms_table">
                    <OOFolderPermTable ooFolderId={oo_folder_id} onClose={this.onCloseDialog} />
                </TabPanel>
            }
            {this.isAdmin() ?
                <TabPanel key="perms_audit">
                    <OOFolderAuditPermTool ooFolderId={oo_folder_id} onClose={this.onCloseDialog} />
                </TabPanel>
            :""}
        </Tabs>;
    }
}
