import * as React from 'react';
import { IViewKapCourseRecord } from '@src/framework/view/kap/ViewKapCourse';
import { IViewKapTrainingTypeRecord } from '@src/framework/view/kap/ViewKapTrainingType';
import { formatDateWithoutTime } from '@src/Util';

type CoursePageHeaderProps = {
    courseRecord: IViewKapCourseRecord;
    trainingType: IViewKapTrainingTypeRecord;
}

export class CoursePageHeader extends React.Component<CoursePageHeaderProps> {

    render() {
        return <div className="course-header">
            <h5>
                {this.props.courseRecord.regno} - {this.props.courseRecord.training_type_code} - {this.props.courseRecord.institute_site_name}
            </h5>
            {formatDateWithoutTime(this.props.courseRecord.training_from)} - {formatDateWithoutTime(this.props.courseRecord.training_to)}
        </div>
    }

}
