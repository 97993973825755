import * as React from 'react';
import { Link } from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { ExerciseSeriesBreadcrumbs } from '@src/component/Breadcrumbs';
import { app } from '../../..';
import * as ExerciseServer from '@src/component/exercise/ExerciseServer';
import { subjectCrudClassProxy } from '@crud/doc/SubjectCrud'
import { gradeCrudClassProxy } from '@src/framework/crud/doc/GradeCrud';
import { IExerciseRecord } from '@src/framework/crud/exc/ExerciseCrud';
import ExerciseSeriesCrud, { IExerciseSeriesRecord } from '@src/framework/crud/exc/ExerciseSeriesCrud';
import { hasGroup, Groups, hasAnyGroup, me } from '@framework/server/Auth';
import ExerciseSeriesItemCrud, { IExerciseSeriesItemRecord } from '@src/framework/crud/exc/ExerciseSeriesItemCrud';
import ViewDisplayMode from '@src/framework/view/exc/ViewDisplayMode';
import { BubbleLoader } from 'react-css-loaders';
import ExerciseList from '@src/component/exercise/Viewer/ExerciseList';
import { Dialog, confirmDialog } from '@src/component/Dialog';
import { PATH_EXERCISE_VIEW, PATH_ADAPTIVE_EXERCISE_SERIES_EDIT, PATH_EXERCISE_SERIES_EDIT, getPath } from '@src/Routes';
import { fetchKeywords, setKeywords, MetaKeywordAssignerPlugin } from '@component/meta/meta_keyword_assigner_plugin';
import { history } from '@src/index';
import WfPlugin from '@src/component/wf/wf_plugin';
import CrudSelect from '@framework/forms/crudselect';
import ViewExerciseSeriesItem, { IViewExerciseSeriesItemRecord } from '@src/framework/view/exc/ViewExerciseSeriesItem';
import { ExerciseSeriesEditorProps, ExerciseItem } from '@src/component/exercise/ExerciseSeries/ExerciseSeriesEditor';
import ExerciseSeriesTopicCrud, { IExerciseSeriesTopicRecord } from '@src/framework/crud/exc/ExerciseSeriesTopicCrud';
import { Accordion, AccordionItem } from '@src/component/ui/Accordion';
import ExerciseSeriesLevelTransitionCrud, { IExerciseSeriesLevelTransitionRecord } from '@src/framework/crud/exc/ExerciseSeriesLevelTransitionCrud';
import PermissionPage from '@src/framework/PermissionPage';
import { CrudPermissionType, PermissionCache } from '@src/framework/security/PermissionCache';
import { LIBRARY_OFI_OFFICIAL_ID, LIBRARY_PERSONAL_ID, WF_TYPE_OKOSFELADATSOR_ID, LANG_ID_HU } from '@src/Const';
import ViewExcExercise, { IViewExcExerciseRecord } from '@src/framework/view/exc/ViewExcExercise';
import { HTMLTextarea } from '@src/component/HtmlTextarea';
import ViewExcSeries, { IViewExcSeriesRecord } from '@src/framework/view/exc/ViewExcSeries';
import { getIconOfEngineByDisplayName } from '../models/ExerciseTypeConverter';
import { __, getLanguageId } from '@src/translation';
import { exerciseModule } from '@src/module/exerciseModule/exerciseModule';
import { langTranslationCrudClassProxy } from '@src/framework/crud/sys/LangTranslationCrud';

export type AdaptiveSeriesEditorState = {
    exerciseSeriesRecord: IExerciseSeriesRecord, // Exercise record in database    
    originalExerciseSeries?: any,          // original exercise, disable saving if nothing change  
    selectedExercise?: IExerciseRecord,
    displayModes: any[],
    isDataChanged: boolean,
    oldlibId?: number,
    dialogOpen: boolean,
    duplicateDialogOpen: boolean,
    keywords: string[],
    minPoints: number,
    topics: TopicElement[],
    currentTopicIndex?: number,
    currentLevel?: number,
    canEdit: boolean
}

type TopicElement = {
    topic: IExerciseSeriesTopicRecord,
    levels: ExerciseItem[][]
}

var OFIMEMBERSHIP: boolean;
export default class AdaptiveSeriesEditor extends React.Component<ExerciseSeriesEditorProps, AdaptiveSeriesEditorState> {

    private isUpdating = false;
    constructor(props: any) {
        super(props);

        OFIMEMBERSHIP = hasGroup(me!, Groups.OFIEditor);

        this.state = {

            exerciseSeriesRecord: this.getEmptyExercise(),
            displayModes: [],
            isDataChanged: false,
            dialogOpen: false,
            duplicateDialogOpen: false,
            keywords: [],
            minPoints: 1,
            topics: [],
            canEdit: false
        };
        this.isUpdating = false;
        this.reloadAsync();
    }

    async reloadAsync() {
        const displayModes = await ViewDisplayMode.list({});
        // TODO ezek lehetnek privált változó, mert szinte sosem változik
        this.setState({
            displayModes: displayModes
        });
        this.reloadExerciseSeriesAsync();
    }

    async reloadExerciseSeriesAsync() {
        if (this.isUpdating) return;
        this.isUpdating = true;
        var exerciseSeriesRecord: any = {};
        let keywords: string[] = [];
        let topicList: TopicElement[] = [];

        // if there is an exercise to load
        if (this.props.match.params.exerciseSeriesId) {
            exerciseSeriesRecord = await ExerciseSeriesCrud.load((parseInt(this.props.match.params.exerciseSeriesId))).then((crud) => crud.record);;

            let canEdit = false;
            if (!exerciseSeriesRecord) {
                app.showError(__("Nem sikerült betölteni a feladatot"), __("Hibás azonosító"));
                return;
            }
            else if (!exerciseSeriesRecord.is_adaptive) {
                let tempurl = PATH_EXERCISE_SERIES_EDIT + "/" + exerciseSeriesRecord.id + "/"
                history.replace(tempurl)
            }
            else {
                keywords = await fetchKeywords(ExerciseSeriesCrud.TABLE_INFO_ID, exerciseSeriesRecord.id);
            }

            var exerciseView: IViewExcSeriesRecord[] = await ViewExcSeries.list({ filter: { id: exerciseSeriesRecord.id } });
            let curr_station_id = null;
            if (exerciseView && exerciseView.length > 0) {
                curr_station_id = (exerciseView[0] as IViewExcSeriesRecord).wf_station_id;
            }

            canEdit = await PermissionCache.hasWfEditPermission(WF_TYPE_OKOSFELADATSOR_ID, curr_station_id, me, exerciseSeriesRecord);

            // ExerciseSeriesItemCrud            

            let dbTopicList = await ExerciseSeriesTopicCrud.list({ filter: { exercise_series_id: exerciseSeriesRecord.id, is_active: true }, order_by: "no" });

            var exerciseSeriesItems: IViewExerciseSeriesItemRecord[] = await ViewExerciseSeriesItem.list({ filter: { exercise_series_id: exerciseSeriesRecord.id, is_active: true }, order_by: "no" });

            if (dbTopicList) {
                const exerciseIds = exerciseSeriesItems.map(i => i.exercise_id!);
                const exercises = await ViewExcExercise.list({ filter: { id: exerciseIds } });

                for (var topic of dbTopicList) {

                    let curr_levels: ExerciseItem[][] = [];

                    for (let level = 0; level < 5; level++) {

                        let exerciseList = exerciseSeriesItems.filter(function (element: IViewExerciseSeriesItemRecord) {
                            return ((element.points == (level + 1) || element.level == (level + 1)) && element.exercise_series_topic_id == topic.id)
                        });

                        let curr_no = 0;
                        let selectedExercises: ExerciseItem[] = [];

                        for (var exItem of exerciseList) {
                            if (!exItem.exercise_id) continue;
                            var curr_ex = exercises.find(e => e.id == exItem.exercise_id);
                            if (!curr_ex) continue;
                            let img = await getIconOfEngineByDisplayName(curr_ex.engine_name!, false, false)
                            selectedExercises.push({
                                id: exItem.id,
                                exerciseRecord: curr_ex,
                                engineImg: img,
                                numberOfPoints: exItem.points ? exItem.points : 0,
                                index: curr_no,//exItem.no ? exItem.no : 0,
                                wf_station_name: exItem.wf_station_name || null,
                                wf_station_style: exItem.wf_station_style || {},
                                wf_substation_name: exItem.wf_substation_name || null,
                                wf_status_text: exItem.wf_status_text || null,
                            });
                            curr_no++;
                        }
                        curr_levels.push(selectedExercises);
                    }

                    topicList.push({ topic: topic, levels: curr_levels });
                }
            }

            this.setState({
                exerciseSeriesRecord,
                topics: topicList,
                keywords,
                oldlibId: exerciseSeriesRecord.library_id,
                canEdit
            });


        } else {
            // if it is a new exercise and there are no examples              
            this.onStartNewExerciseSeries();
        }

        this.isUpdating = false;
    }


    async onSave(saveAsNew: boolean) {
        try {
            if (!this.state.exerciseSeriesRecord.title) {
                app.showError(__("Hiányzó adat!"), __("Kérem adja meg a feladat címét!"));
                return;
            }
            if (this.state.exerciseSeriesRecord.success_limit_percent && (this.state.exerciseSeriesRecord.success_limit_percent > 100 || this.state.exerciseSeriesRecord.success_limit_percent < 0)) {
                app.showError(__("Hiba"), __("A sikeresség szintjét 0-100% ig lehet meghatározni!"));
                return;
            }
            if (!this.state.keywords || this.state.keywords.length < 1) {
                app.showError(__("Hiányzó adat!"), __("Kérem adjon meg kulcsszavakat!"));
                return;
            }

            let newTopicList = this.state.topics;
            if (newTopicList && newTopicList.length < 1) {
                app.showError(__("Hiba"), __("Legalább egy témakört kötelező megadni!"))
                return;

            }
            let sumOfWeight = 0;

            for (let topicIndex = 0; topicIndex < newTopicList.length; topicIndex++) {
                let levelsWithExeInCurrentTopic = 0;
                const element = newTopicList[topicIndex].topic;
                if (element.max_questions !== undefined && element.max_questions < 1) {
                    app.showError(__("Hiba"), __("A témakörönkénti max. kérdés számnak nagyobbnak kell lennie nullánál!"));
                    return;
                }
                if (element.weight !== undefined) { sumOfWeight += element.weight }
                //at least 2 levels in each topic

                const levels = newTopicList[topicIndex].levels;

                for (let levelIndex = 0; levelIndex < levels.length; levelIndex++) {

                    if (levels[levelIndex].length > 0) {

                        if (element.max_questions != undefined && levels[levelIndex].length < element.max_questions) {

                            app.showError(__("Hiba"), __("Minden szintnek ami tartalmaz feladatot, legalább annyi darab feladatnak kell lennie, amennyi a témakör fejlécében meg van adva."));
                            return;
                        }
                        levelsWithExeInCurrentTopic++
                        let prevIndex = levelIndex;
                        if (prevIndex != levelIndex) {
                        }
                    }
                }

                if (levelsWithExeInCurrentTopic < 2) {
                    app.showError(__("Hiba"), __("Minden témakörben legalább 2 szintnek tartalmaznia kell minimum 1 feladatot!"))
                    return;
                }

            }

            if (sumOfWeight != 100) {
                app.showError(__("Hiba"), __("A témakörök súlyának összesen 100-nak kell lennie!"))
                return;
            }

            var exerciseToSave: IExerciseSeriesRecord = {
                ...this.state.exerciseSeriesRecord,
                library_id: this.state.exerciseSeriesRecord.library_id, // todo: take it from a selector,,
                level: (this.state.exerciseSeriesRecord.level ? Number(this.state.exerciseSeriesRecord.level) : undefined),
                is_adaptive: true,
                exc_display_mode_id: 1,
                minpoints: null,
                grade_id: this.state.exerciseSeriesRecord.grade_id ? Number(this.state.exerciseSeriesRecord.grade_id) : null,
                subject_id: this.state.exerciseSeriesRecord.subject_id ? Number(this.state.exerciseSeriesRecord.subject_id) : null,
                lang_id: this.state.exerciseSeriesRecord.lang_id ? this.state.exerciseSeriesRecord.lang_id : LANG_ID_HU
            };
            delete exerciseToSave["is_active"];
            exerciseToSave.owner_id = undefined;

            if (saveAsNew) {
                if (!await confirmDialog(__("Megerősítés"), __("Biztosan másolatként szeretné elmenteni az feladatot?"), __("Igen"))) { return; }
                exerciseToSave.id = undefined;
            }

            //-------- saving the exercise series --------
            const newExerciseSeriesOnServer = await ExerciseServer.putExerciseSeriesRecord(exerciseToSave);

            let dbTopicList = await ExerciseSeriesTopicCrud.list({ filter: { exercise_series_id: newExerciseSeriesOnServer.id, is_active: true }, order_by: "no" });

            var exerciseSeriesItems = await ViewExerciseSeriesItem.list({ filter: { exercise_series_id: newExerciseSeriesOnServer.id, is_active: true }, order_by: "no" });


            // ------- Saving the topics ---------

            // deleting deleted topics

            for (var idx = 0; idx < dbTopicList.length; idx++) {
                var existingElement = newTopicList.filter((x: TopicElement) => x.topic.id == dbTopicList[idx].id);
                if (!existingElement || existingElement.length == 0) {
                    // todo: deleting corresponding exerciseItems
                    let exerciseItemsToDelete: IExerciseSeriesItemRecord[] = await ExerciseSeriesItemCrud.list({ filter: { exercise_series_topic_id: dbTopicList[idx].id, is_active: true }, order_by: "no" });

                    if (exerciseItemsToDelete && exerciseItemsToDelete.length > 0)
                        for (let toDelIdx = 0; toDelIdx < exerciseItemsToDelete.length; toDelIdx++) {
                            const crud1 = new ExerciseSeriesItemCrud(exerciseItemsToDelete[toDelIdx] as any);
                            await crud1.del();
                        }

                    const crud2 = new ExerciseSeriesTopicCrud(dbTopicList[idx] as any);
                    await crud2.del();
                }
            }

            for (let index = 0; index < newTopicList.length; index++) {

                const element = newTopicList[index].topic;
                var prev_topic_item_list = dbTopicList.filter((curr_item: IExerciseSeriesTopicRecord) => curr_item.id == element.id);
                let prev_topic_item = (prev_topic_item_list && prev_topic_item_list.length > 0) ? prev_topic_item_list[0] : undefined;
                let topic_id = (!saveAsNew && prev_topic_item) ? prev_topic_item.id : undefined;

                var newItem: IExerciseSeriesTopicRecord = {
                    id: topic_id,
                    exercise_series_id: topic_id ? undefined : newExerciseSeriesOnServer.id,
                    title: element.title,
                    no: index,
                    weight: element.weight,
                    max_questions: element.max_questions,
                };

                const crud = new ExerciseSeriesTopicCrud(newItem as any);
                const response = await crud.put();
                topic_id = response.record.id;

                // ------ saving the execiseSeriesItems ---------- 

                let exerciseDbList = exerciseSeriesItems.filter(function (element: IViewExerciseSeriesItemRecord) {
                    return (element.exercise_series_topic_id == topic_id)
                });

                if (exerciseDbList) {
                    let newExercises: ExerciseItem[] = [];
                    for (let index_j = 0; index_j < newTopicList[index].levels.length; index_j++) {
                        newExercises = newExercises.concat(newTopicList[index].levels[index_j]);
                    }

                    // deleting deleted exerciseItems
                    for (var idx = 0; idx < exerciseDbList.length; idx++) {
                        let existingExerciseElement = newExercises.filter((x: ExerciseItem) => x.id == exerciseDbList[idx].id);
                        if (!existingExerciseElement || existingExerciseElement.length == 0) {
                            const crud = new ExerciseSeriesItemCrud(exerciseDbList[idx] as any);
                            const response = await crud.del();
                        }
                    }
                }

                // adding/modifying exerciseItems
                for (let index_j = 0; index_j < newTopicList[index].levels.length; index_j++) {
                    for (var idx = 0; idx < newTopicList[index].levels[index_j].length; idx++) {
                        const exerciseElement = newTopicList[index].levels[index_j][idx];
                        var prev_exe_item_list = exerciseDbList.filter((curr_item: IExerciseSeriesItemRecord) => curr_item.id == exerciseElement.id);
                        let prev_exe_item = (prev_exe_item_list && prev_exe_item_list.length > 0) ? prev_exe_item_list[0] : undefined;
                        let exe_id = (!saveAsNew && prev_exe_item) ? prev_exe_item.id : undefined;

                        let newExeItem: IExerciseSeriesItemRecord = {
                            id: exe_id,
                            exercise_series_id: exe_id ? undefined : newExerciseSeriesOnServer.id,
                            exercise_series_topic_id: exe_id ? undefined : topic_id, //prev_exe_item && topic_id==prev_exe_item.exercise_series_topic_id? undefined: topic_id,
                            no: idx,
                            exercise_id: exerciseElement.exerciseRecord.id,
                            points: index_j + 1 + 4,
                            level: index_j + 1,
                        };

                        await ExerciseServer.putExerciseSeriesItemRecord(newExeItem);
                    }
                }
            }

            // todo: válogatás módja táblázat
            let dbLevelList = await ExerciseSeriesLevelTransitionCrud.list({ filter: { exercise_series_id: newExerciseSeriesOnServer.id, is_active: true } });

            for (let level_idx = 1; level_idx <= 5; level_idx++) {
                var prev_level_item_list = dbLevelList.filter((curr_item: IExerciseSeriesLevelTransitionRecord) => curr_item.level == level_idx);
                let prev_level_item = (prev_level_item_list && prev_level_item_list.length > 0) ? prev_level_item_list[0] : undefined;
                let new_id = (!saveAsNew && prev_level_item) ? prev_level_item.id : undefined;;

                let levelTransitions: IExerciseSeriesLevelTransitionRecord = {
                    id: new_id,
                    exercise_series_id: new_id ? undefined : newExerciseSeriesOnServer.id,
                    level: level_idx,
                    min_correct: prev_level_item ? prev_level_item.min_correct : 1,
                    min_incorrect: prev_level_item ? prev_level_item.min_incorrect : 1,
                    backward_level: prev_level_item ? prev_level_item.backward_level : Math.max(1, level_idx - 1),
                    forward_level: prev_level_item ? prev_level_item.forward_level : Math.min(5, level_idx + 1)
                }

                const crud = new ExerciseSeriesLevelTransitionCrud(levelTransitions as any);
                await crud.put();
            }


            await setKeywords(
                ExerciseSeriesCrud.TABLE_INFO_ID, newExerciseSeriesOnServer.id!,
                this.state.keywords
            );

            this.setState({
                exerciseSeriesRecord: { ...this.state.exerciseSeriesRecord, id: newExerciseSeriesOnServer.id, creation_user_id: newExerciseSeriesOnServer.creation_user_id },
                originalExerciseSeries: this.state.exerciseSeriesRecord,
                isDataChanged: false,
            });
            if (!saveAsNew) {
                let tempurl = PATH_ADAPTIVE_EXERCISE_SERIES_EDIT + "/" + newExerciseSeriesOnServer.id + "/"
                history.replace(tempurl)
                app.showSuccess(__("Mentés"), __("Feladatsor sikeresen elmentve"));
            } else {
                let tempurl = PATH_ADAPTIVE_EXERCISE_SERIES_EDIT + "/" + newExerciseSeriesOnServer.id + "/"
                history.replace(tempurl)
                app.showSuccess(__("Mentés"), __("Feladatsor sikeresen elmentve másolatként"));
            }
        } catch (e) {
            app.showError(__("Nem sikerült lementeni a Feladatsort"), e);
            console.log(e);
        }
    }

    async onStartNewExerciseSeries() {

        var newExSeries: IExerciseSeriesRecord = this.getEmptyExercise();
        this.setState({
            exerciseSeriesRecord: newExSeries,
            originalExerciseSeries: newExSeries,
            oldlibId: undefined,
            topics: [],
            canEdit: true
        });
    }

    render() {
        const breadcrumbs = <ExerciseSeriesBreadcrumbs links={[
            { name: this.state.exerciseSeriesRecord ? this.state.exerciseSeriesRecord.title : <span style={{ color: "red" }}>{__("Betöltés...")}</span> }
        ]} />;

        const enableLang = exerciseModule.getConfig().enableLangSelector;

        if (!this.state.exerciseSeriesRecord || !me || (hasAnyGroup(me, [Groups.Student, Groups.TestStudent]) && this.state.exerciseSeriesRecord.owner_id && this.state.exerciseSeriesRecord.owner_id != me.id)) {
            return <div className="row expanded">
                <div className="small-12 column">
                    {breadcrumbs}
                    <BubbleLoader />
                </div>
            </div>
        }

        return (<PermissionPage requirements={{
            crud: { tableInfoId: ExerciseSeriesCrud.TABLE_INFO_ID, permissions: CrudPermissionType.U }
        }}>
            <div className="row expanded">
                <div className="small-12 column clearfix">
                    <div className="small-12 column float-left">
                        {breadcrumbs}
                    </div>
                    <a className="button small eke-general-buttons float-right" target="_blank" href="https://onlyoffice.itk.uni-eszterhazy.hu/products/files/doceditor.aspx?fileid=46409&action=view"><i className="fa fa-question-circle" /> </a>
                </div>
                <Tabs className="exe-editor-fullscreen-tab">
                    <TabList>
                        <Tab>{__("Feladatsor szerkesztése")}</Tab>
                        <Tab>{__("Folyamatok")}</Tab>
                    </TabList>
                    <TabPanel>
                        <div className="medium-12 column">
                            <div className="row">
                                <h4>{__("Feladatsor szerkesztése")}</h4>
                            </div>
                            <div className="row">
                                <div className="medium-4 column">
                                    <button key="new" className="button expanded eke-general-buttons" onClick={this.onStartNewExerciseSeries.bind(this)}>
                                        <i className="fa fa-plus" /> {__("Új létrehozása")}
                                    </button>
                                </div>
                                <div className="medium-4 column">
                                    {(!this.state.canEdit || (this.state.oldlibId && this.state.oldlibId != this.state.exerciseSeriesRecord.library_id)) ?
                                        <button className="button success expanded eke-general-buttons" disabled={true}>
                                            <i className="fa fa-save" />  {__("Nincs joga menteni")}</button>
                                        :
                                        <button className="button success expanded eke-general-buttons" onClick={this.onSave.bind(this, false)} disabled={(!this.state.isDataChanged)}>
                                            <i className="fa fa-save" /> {this.state.exerciseSeriesRecord.id ? __("Feladatsor mentése") : __("Feladatsor mentése újként")}
                                        </button>
                                    }
                                </div>
                                <div className="medium-4 column">
                                    <button key="new_copy" className="button expanded eke-general-buttons success" onClick={this.onSave.bind(this, true)}>
                                        <i className="fa fa-plus" /> {__("Mentés másolatként")}
                                    </button>
                                </div>
                            </div>
                            <br />
                            <div className="row">
                                <div className="medium-6 column">
                                    <label className="lms-pages-typeLabel">{__("Osztály")}</label>
                                    <CrudSelect
                                        value={this.state.exerciseSeriesRecord.grade_id ? Number(this.state.exerciseSeriesRecord.grade_id) : null}
                                        onSelect={this.onGradeSelect}
                                        displayFieldName="name"
                                        orderByFieldName="id"
                                        key="id"
                                        emptyTitle={__("Nincs megadva")}
                                        crudClassProxy={gradeCrudClassProxy}
                                        filter={{ is_active: true }}
                                        sortFunc={(a, b) => a.name.localeCompare(b.name, undefined, { numeric: true, sensitivity: 'base' })}
                                    />
                                </div>
                                <div className="medium-6 column">
                                    <label className="lms-pages-typeLabel">{__("Tantárgy")}</label>
                                    <CrudSelect
                                        value={this.state.exerciseSeriesRecord.subject_id ? Number(this.state.exerciseSeriesRecord.subject_id) : null}
                                        onSelect={this.onSubjectSelect}
                                        displayFieldName="name"
                                        key="id"
                                        emptyTitle={__("Nincs megadva")}
                                        crudClassProxy={subjectCrudClassProxy}
                                        filter={{ is_active: true }}
                                    />
                                </div>
                            </div>
                            {OFIMEMBERSHIP
                                ?
                                <div className="row">
                                    <div className="small-12 medium-12 large-12 columns">
                                        <label>{__("Könyvtár")}
                                            <select name="exerciseSeriesRecord.library_id" id="library_selector_id" value={this.state.exerciseSeriesRecord.library_id} onChange={this.handleInputChange.bind(this)}>

                                                {
                                                    exerciseModule.getConfig().enableOfficialExercises
                                                        ?
                                                        <option value={LIBRARY_OFI_OFFICIAL_ID}>{__("Hivatalos")}</option>
                                                        : null
                                                }
                                                {
                                                    exerciseModule.getConfig().enableOwnExercises
                                                        ?
                                                        <option value={LIBRARY_PERSONAL_ID}>{__("Saját")}</option>
                                                        : null
                                                }
                                            </select>
                                        </label>
                                    </div>
                                </div>
                                : null
                            }
                            {

                                enableLang && <div className="row ">
                                    <div className="large-12 columns">
                                        <label>{__("Feladatsor nyelve")}
                                            <CrudSelect
                                                value={this.state.exerciseSeriesRecord.lang_id ? Number(this.state.exerciseSeriesRecord.lang_id) : null}
                                                displayFieldName="name"
                                                onSelect={this.onSelectLang}
                                                valueFieldName="lang_id"
                                                emptyTitle={__("Nincs megadva")}
                                                clearable={true}
                                                filter={{ translated_to_id: getLanguageId() }}
                                                crudClassProxy={langTranslationCrudClassProxy}
                                            />
                                        </label>
                                    </div>
                                </div>

                            }
                            <div className="row">
                                <div className="large-12 columns">
                                    <label>{__("Cím")}
                                        <input type="text" name="exerciseSeriesRecord.title" value={this.state.exerciseSeriesRecord.title || ""} onChange={this.handleInputChange.bind(this)} />
                                    </label>
                                </div>
                            </div>
                            <div className="row">
                                <div className="large-12 columns">
                                    <label className="exerciseseries-editor-html-editor">
                                        {__("Bevezető szöveg")}
                                        <HTMLTextarea
                                            value={this.state.exerciseSeriesRecord.introduction || ""}
                                            onChange={this.handleIntroChange.bind(this)}
                                        />
                                    </label>
                                </div>
                            </div>
                            <div className="row">
                                <div className="large-12 columns">
                                    <label>{__("Kulcsszavak")}
                                        <MetaKeywordAssignerPlugin
                                            keywords={this.state.keywords}
                                            onAppendKeywords={this.onAppendKeyword}
                                            onDeleteKeyword={this.onDeleteKeyword}
                                        />
                                    </label>
                                </div>
                            </div>
                            <div className="row ">
                                <div className="large-12 columns">
                                    <label>{__("Nehézségi szint")}
                                        <select name="exerciseSeriesRecord.level" onChange={this.handleInputChange.bind(this)} value={this.state.exerciseSeriesRecord.level} >
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5">5</option>
                                        </select>
                                    </label>
                                </div>
                            </div>

                            <div className="row">
                                <Accordion>
                                    {
                                        !this.state.topics ? "" : this.state.topics.map((curr_topic: TopicElement, index) => {

                                            let accTitle = (<legend>
                                                <label className="exe-editor-fieldlabel-1">{curr_topic.topic.title ? curr_topic.topic.title : __("{n}. témakör", { n: (index + 1) })}</label>
                                                <button className="button small alert exercise-series-small-btn" title={__("Törlés")} onClick={this.removeElement.bind(this, "topics", index)}><i className="fa fa-trash"></i></button>
                                                <button className="button small secondary exercise-series-small-btn" title={__("Fel")} onClick={this.moveUp.bind(this, "topics", index)} ><i className="fa fa-arrow-up"></i></button>
                                                <button className="button small secondary exercise-series-small-btn" title={__("Le")} onClick={this.moveDown.bind(this, "topics", index)}><i className="fa fa-arrow-down"></i></button>
                                            </legend>);

                                            return (

                                                <AccordionItem key={"topic_" + index} title={accTitle}>

                                                    <div className="row">

                                                        <div className="large-12 small-12 columns">
                                                            <label>{__("Témakör címe")}
                                                                <input type="text" name={"topics#" + index + ".topic.title"} value={curr_topic.topic.title || ""} onChange={this.handleInputChange.bind(this)} />
                                                            </label>
                                                        </div>
                                                        <div className="large-6 small-12 columns">
                                                            <label>{__("Feladatok maximális száma")}
                                                                <input min="1" type="number" name={"topics#" + index + ".topic.max_questions"} value={curr_topic.topic.max_questions} onChange={this.handleInputChange.bind(this)} />
                                                            </label>
                                                        </div>
                                                        <div className="large-6 small-12 columns">
                                                            <label>{__("Súly")}
                                                                <input type="number" name={"topics#" + index + ".topic.weight"} value={curr_topic.topic.weight} onChange={this.handleInputChange.bind(this)} />
                                                            </label>
                                                        </div>

                                                        <Accordion>
                                                            {
                                                                curr_topic.levels.map((curr_level: ExerciseItem[], index_j) => {
                                                                    return (

                                                                        <AccordionItem key={"level_" + index + "_" + index_j} title={__("Szint") + (index_j + 1)} defaultClosed={true}>

                                                                            <div className="row">
                                                                                <button className="button small" onClick={this.onOpenExerciseDialog.bind(this, index, index_j)}><i className="fa fa-plus"></i> {__("Feladat hozzáadása")}</button>
                                                                            </div>

                                                                            <div className="row">
                                                                                {this.getExerciseTable(curr_level, index, index_j)}
                                                                            </div>
                                                                        </AccordionItem>
                                                                    )
                                                                })
                                                            }

                                                        </Accordion>

                                                    </div>
                                                </AccordionItem>
                                            )

                                        })}

                                </Accordion>

                                <button className="button small" onClick={this.onAddNewTopic.bind(this)}><i className="fa fa-plus"></i> {__("Témakör hozzáadása")}</button>
                            </div>

                            <div className="row">

                                {this.state.dialogOpen && !this.state.duplicateDialogOpen ?
                                    <Dialog title={__("Feladat kiválasztása")} onClose={this.onOpenExerciseDialog.bind(this)}>
                                        <ExerciseList
                                            onExerciseSelected={this.checkIfExerciseInList.bind(this)}
                                            grade_id={this.state.exerciseSeriesRecord.grade_id}
                                            subject_id={this.state.exerciseSeriesRecord.subject_id}
                                            library_id={this.state.exerciseSeriesRecord.library_id}
                                            isPublicDisabled={true}
                                        />
                                    </Dialog>
                                    : ""
                                }
                            </div>
                            <div className="card row">
                                <div className="card-divider share-data-card-header columns large-12">{__("Megjelenés módja")}</div>
                                <div className="card-section columns large-12">

                                    {/* <div className="row"> */}
                                    {/* <div className="medium-12 column"> */}
                                    {/* <label>Lejátszás módja</label>
                                        <select name="exerciseSeriesRecord.exc_display_mode_id" value={this.state.exerciseSeriesRecord.exc_display_mode_id} onChange={this.handleInputChange.bind(this)} style={{ lineHeight: "1.25" }}>
                                            <option value="">Kérem válasszon...</option>
                                            {this.state.displayModes.map((curr_sel: IViewDisplayModeRecord) => {
                                                return <option key={curr_sel.label} value={curr_sel.id}>{curr_sel.name}</option>
                                            })
                                            }
                                        </select> */}
                                    {/* </div> */}
                                    {/* </div> */}
                                    <div className="row">
                                        <div className="large-12 columns">
                                            <label >
                                                <input type="checkbox" id="order_chbx" name="exerciseSeriesRecord.fix_order" checked={this.state.exerciseSeriesRecord.fix_order || false} onChange={this.handleInputChange.bind(this)} />
                                                {__("A kérdések sorrendje fix")}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card row">
                                <div className="card-divider share-data-card-header columns large-12">{__("Kiértékelés módja")}</div>
                                <div className="card-section columns large-12">
                                    <div className="row">
                                        <div className="large-3 columns"> <label >
                                            <input type="checkbox" name="exerciseSeriesRecord.can_interrupt" checked={this.state.exerciseSeriesRecord.can_interrupt || false} onChange={this.handleInputChange.bind(this)} />
                                            {__("Megszakítható tesztsor")}</label>
                                        </div>
                                        <div className="large-3 columns"> <label >
                                            <input type="checkbox" name="exerciseSeriesRecord.show_max_points" checked={this.state.exerciseSeriesRecord.show_max_points} onChange={this.handleInputChange.bind(this)} />
                                            {__("Pontszám kijelzése")}</label>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="large-12 columns">
                                            <label>{__("Sikeresség szintje")} (%)
                                <input type="number" min="0" max="100" name="exerciseSeriesRecord.success_limit_percent" value={this.state.exerciseSeriesRecord.success_limit_percent || 0} onChange={this.handleInputChange.bind(this)} />
                                            </label>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="large-12 columns">
                                            <label>{__("Időkorlát")} (perc)
                                <input type="number" min="0" max="300" name="exerciseSeriesRecord.max_solution_time_min" value={this.state.exerciseSeriesRecord.max_solution_time_min || this.state.exerciseSeriesRecord.max_solution_time_min != undefined ? this.state.exerciseSeriesRecord.max_solution_time_min : 0} onChange={this.handleInputChange.bind(this)} />
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </TabPanel>
                    <TabPanel>
                        <div className="exe-editor-panel-padding">
                            <WfPlugin
                                tableInfoId={ExerciseSeriesCrud.TABLE_INFO_ID}
                                recId={this.state.exerciseSeriesRecord.id || null}
                            />
                        </div>
                    </TabPanel>
                </Tabs>


            </div>
        </PermissionPage>

        );
    }

    getExerciseTable(exeItemList: ExerciseItem[], topicIndex: number, levelIndex: number) {

        return (<table className="table table-bordered large-12 columns">
            <thead>
                <tr>
                    <th>{__("Feladat címe")}</th>
                    <th>{__("Típus")}</th>
                    <th className="text-center">{__("Eredeti szint")}</th>
                    <th>{__("Állapot")}</th>
                    <th>{__("Művelet")}</th>
                </tr>
            </thead>
            <tbody>
                {exeItemList.map((curr_exercise: ExerciseItem) => {
                    return <tr id={"exercise_" + curr_exercise.exerciseRecord.id} key={curr_exercise.exerciseRecord.id} style={{ borderBottom: "solid 2px rgba(0,0,0,0.05)" }}>
                        <td> <label> {curr_exercise.exerciseRecord.name}</label></td>
                        <td> <span><img src={curr_exercise.engineImg} className="eke-controls-image" alt={curr_exercise.exerciseRecord.engine_name!} /></span> </td>
                        <td> <label className="text-center"> {curr_exercise.exerciseRecord.level}</label></td>
                        <td style={curr_exercise.wf_station_style}><label className="small-6 column"></label>{curr_exercise.wf_station_name}</td>

                        <td>
                            <button className="button small alert exercise-series-small-btn" title={__("Törlés")} onClick={this.onRemoveExerciseItem.bind(this, curr_exercise, topicIndex, levelIndex)}><i className="fa fa-trash"></i></button>
                            <Link className="button small exercise-series-small-btn" title={__("Megtekintés")} target="_blank" to={getPath({ PATH_EXERCISE_VIEW }, exerciseModule) + `/${curr_exercise.exerciseRecord.id}/`}><i className="fa fa-eye"></i></Link>
                        </td>
                    </tr>
                })}
            </tbody>
        </table>);
    }

    componentDidMount() {
        let tempExe = this.state.exerciseSeriesRecord;
        tempExe.library_id = OFIMEMBERSHIP ? LIBRARY_OFI_OFFICIAL_ID : LIBRARY_PERSONAL_ID;
        this.setState({ exerciseSeriesRecord: tempExe });
    }

    componentDidUpdate() {
        ($(document) as any).foundation();
    }

    async checkIfExerciseInList(exerciseRecord: IViewExcExerciseRecord) {
        //var newExlist = ( this.state.topics[topicIndex].levels[levelIndex] ? this.state.selectedExercises : []);
        var itemExisting = this.state.topics[this.state.currentTopicIndex!].levels[this.state.currentLevel!].filter((item: ExerciseItem) => item.exerciseRecord.id == exerciseRecord.id);

        if (itemExisting && itemExisting.length > 0) {
            this.setState({ selectedExercise: exerciseRecord })
            this.onAddNewExercie(exerciseRecord, this.state.currentTopicIndex!, this.state.currentLevel!, true);
        } else {
            this.onAddNewExercie(exerciseRecord, this.state.currentTopicIndex!, this.state.currentLevel!, false);
        }
    }

    async onAddNewExercie(exerciseRecord: IViewExcExerciseRecord, topicIndex: number, levelIndex: number, duplicationCase: boolean) {
        // todo: generate input fields in this.refs.exercise_list_div

        try {
            if (!exerciseRecord) {
                return;
            }
            if (duplicationCase && !await confirmDialog(__("Megerősítés"), __("Ez a feladat már szerepel a listában. Biztosan beteszi újra?"), __("Igen"))) {
                return;
            }
            let img = await getIconOfEngineByDisplayName(exerciseRecord.engine_name!, false, false)
            var newExSeriesItem: ExerciseItem = {
                id: undefined,
                exerciseRecord: exerciseRecord,
                engineImg: img,
                numberOfPoints: levelIndex,
                index: this.state.topics[topicIndex].levels[levelIndex].length,
                wf_station_name: null,
                wf_station_style: {},
                wf_substation_name: null,
                wf_status_text: null
            };

            let tempTopics: TopicElement[] = JSON.parse(JSON.stringify(this.state.topics));

            tempTopics[topicIndex].levels[levelIndex].push(newExSeriesItem);

            this.setState({
                topics: tempTopics,
                isDataChanged: true,
                duplicateDialogOpen: false,
            });

        }
        catch (e) {
            app.showError(__("Hiba történt"), e)
        }
    }

    onRemoveExerciseItem(exerciseItem: ExerciseItem, topicIndex: number, levelIndex: number) {
        if (!exerciseItem) {
            return;
        }
        let newTopicList = this.state.topics;
        let newExlist = (newTopicList[topicIndex].levels[levelIndex] ? newTopicList[topicIndex].levels[levelIndex] : []);
        let inxOfElement = newExlist.indexOf(exerciseItem);
        newExlist.splice(inxOfElement, 1);
        for (let i = 0; i < newExlist.length; i++) {
            newExlist[i].index = i;
        }
        this.setState({
            topics: newTopicList,
            isDataChanged: true
        });
    }

    onAddNewTopic() {

        let currTopicList = this.state.topics;

        let newTopic: IExerciseSeriesTopicRecord = {
            no: currTopicList.length,
            title: __("{n}. témakör", { n: (currTopicList.length + 1) }),
            weight: 0,
            max_questions: 1
        };

        let currLevels: ExerciseItem[][] = [];

        for (let level = 0; level < 5; level++) {
            let selectedExercises: ExerciseItem[] = [];
            currLevels.push(selectedExercises);
        }
        currTopicList.push({ topic: newTopic, levels: currLevels });

        this.setState({ topics: currTopicList, isDataChanged: true });
    }

    private onAppendKeyword = async (keywords: string[]) => {
        this.setState({ isDataChanged: true, keywords: this.state.keywords!.concat(keywords) });
    }

    private onDeleteKeyword = async (index: number) => {
        this.state.keywords.splice(index, 1);
        this.setState({ keywords: this.state.keywords, isDataChanged: true });
    }

    private getEmptyExercise(): IExerciseSeriesRecord {

        var newExSeries: IExerciseSeriesRecord = {
            title: "",
            id: undefined,
            // owner_id:(this.state.currentUser?this.state.currentUser.id:1),
            level: 1,
            exc_selection_mode_id: 1, // all
            exc_display_mode_id: 1, // exam mode only
            library_id: OFIMEMBERSHIP ? LIBRARY_OFI_OFFICIAL_ID : LIBRARY_PERSONAL_ID,
            fix_order: false,
            show_max_points: false,
            can_interrupt: true,
            minpoints: 0,
            success_limit_percent: 0
        };

        return newExSeries;
    }

    onOpenExerciseDialog(currentTopicIndex: number, currentLevel: number) {
        this.setState({ dialogOpen: !this.state.dialogOpen, duplicateDialogOpen: false, currentTopicIndex: currentTopicIndex, currentLevel: currentLevel });
    }

    private onGradeSelect = (sender: CrudSelect, newGradeId: number | null): void => {
        this.setState({
            exerciseSeriesRecord: { ...this.state.exerciseSeriesRecord, grade_id: newGradeId },
            isDataChanged: true
        })
    };
    private onSubjectSelect = (sender: CrudSelect, newSubjectId: number | null): void => {
        this.setState({
            exerciseSeriesRecord: { ...this.state.exerciseSeriesRecord, subject_id: newSubjectId },
            isDataChanged: true
        })
    };

    private onSelectLang = (sender: CrudSelect, newLangId: number): void => {
        this.setState({
            exerciseSeriesRecord: { ...this.state.exerciseSeriesRecord, lang_id: newLangId },
            isDataChanged: true
        });
    }

    handleIntroChange(text: any) {

        this.setState({ isDataChanged: true, exerciseSeriesRecord: { ...this.state.exerciseSeriesRecord, introduction: text } });
    }

    handleInputChange(event: any) {

        const target = event.target;
        var value = target.value;

        if (target.type === 'checkbox') {
            value = target.checked;
        } else if (target.type == 'select-one' || target.type == "number" || target.type == "coordinate") {
            value = Number(target.value);
        }

        let targetList = target.name.split(".");
        let newExercise = JSON.parse(JSON.stringify(this.state));

        if (targetList[0] == "exerciseSeriesRecord") {
            this.setProperty(targetList, newExercise, value);
            this.setState({
                isDataChanged: true,
                // exercise: { ...this.state.exercise, [target.name]: value }
                exerciseSeriesRecord: newExercise.exerciseSeriesRecord
            });
        } else {//if (targetList[0] == "topics") {
            //let newTopicList = JSON.parse(JSON.stringify(this.state.topics));
            this.setProperty(targetList, newExercise, value);
            this.setState({
                isDataChanged: true,
                // exercise: { ...this.state.exercise, [target.name]: value }
                topics: newExercise.topics
            });
        }
    }

    /**
   * Moving the index-th element in the array, given by the property name.
   * @param propname Full name of the array that we want to change. Meets naming conventions, e.g. "properyA.propertyB#indexInpropertyBArray.propertyC"
   * @param index index of the element to be moved.
   */
    moveUp(propname: string, index: number) {
        let propArray = propname.split(".");
        let targetList = propname.split("#");

        if (targetList[0] == "topics") {
            let arrayToChange: TopicElement[] = this.state[propArray[0]];
            if (index <= 0) return;

            let temp_el = arrayToChange[index];
            arrayToChange.splice(index, 1);
            arrayToChange.splice(index - 1, 0, temp_el);
            this.setState({
                // exercise: { ...this.state.exercise, [target.name]: value }
                topics: arrayToChange,
                isDataChanged: true
            });
        }
    }

    /**
     * Moving the index-th element in the array, given by the property name.
     * @param propname Full name of the array that we want to change. Meets naming conventions, e.g. "properyA.propertyB#indexInpropertyBArray.propertyC"
     * @param index index of the element to be moved.
     */
    moveDown(propname: string, index: number) {
        let propArray = propname.split(".");
        let targetList = propname.split("#");

        if (targetList[0] == "topics") {
            let arrayToChange: TopicElement[] = this.state[propArray[0]];
            if (index >= arrayToChange.length - 1) return;
            let temp_el = arrayToChange[index];
            arrayToChange.splice(index, 1);
            arrayToChange.splice(index + 1, 0, temp_el);
            this.setState({
                // exercise: { ...this.state.exercise, [target.name]: value }
                topics: arrayToChange,
                isDataChanged: true
            });
        }
    }


    /**
     * Removing the index-th element of the array, given by the property name.
     * @param propname Full name of the array that we want to change. Meets naming conventions, e.g. "properyA.propertyB#indexInpropertyBArray.propertyC"
     * @param index index of the element to be removed.
     */
    removeElement(propname: string, index: number) {
        let propArray = propname.split(".");
        let targetList = propname.split("#");

        if (targetList[0] == "topics") {
            let arrayToChange: TopicElement[] = this.state.topics;
            if (index >= arrayToChange.length || index < 0) return;
            arrayToChange.splice(index, 1);
            this.setState({
                // exercise: { ...this.state.exercise, [target.name]: value }
                topics: arrayToChange,
                isDataChanged: true
            });
        }
    }


    private setProperty(propertyNameList: string[], parentObject: any, value: any) {

        let arrayParams = propertyNameList[0].split("#");
        if (propertyNameList.length == 1) {
            try {
                if (arrayParams.length == 2) {
                    parentObject[arrayParams[0]][Number(arrayParams[1])] = value;
                } else {
                    parentObject[propertyNameList[0]] = value;
                }
            } catch (exeption) {
                console.log("The property does not exist on the element.", exeption);
            }
            //return parentObject;
        } else {
            let newPropnameList = propertyNameList.slice(1, propertyNameList.length);

            if (arrayParams.length == 2) {
                this.setProperty(newPropnameList, parentObject[arrayParams[0]][Number(arrayParams[1])], value);
            } else {
                this.setProperty(newPropnameList, parentObject[propertyNameList[0]], value);
            }
        }
    }

}
