import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { ReactNode, ReactElement, HtmlHTMLAttributes } from 'react';

export enum ButtonSize {
    Normal = "",
    Tiny = "tiny",
    Small = "small",
    Large= "large"
}
export enum ButtonAlign {
    Left = "",
    Right = "align-right",
    Center = "align-center",
    Expanded = "expanded",
    Inline = "inline"
}

type BTNGroupProps = {
    size: ButtonSize,
    align: ButtonAlign,
    className?: string | ""
}

type BTNGroupState = {
}

export default class ButtonGroup extends React.Component<BTNGroupProps, BTNGroupState> {
 
    render() {
        return <div ref="btnGroup" className={"button-group stacked-for-small " + this.props.align + " " + this.props.size +(this.props.className ? " " + this.props.className : "")}>
                  
                    {this.props.children}
                    
                </div>;
    }
}
