import {htmlEscape, FgEventRenderer, Seg, isMultiDayRange, getAllDayHtml, EventRenderRange, EventDef, EventInstance, EventUi} from '@fullcalendar/core'
import {VisitListView} from './VisitListView'
import { WindowScrollGeomCache } from '@fullcalendar/interaction/scroll-geom-cache';

export default class VisitListEvent extends FgEventRenderer {

  visitListView: VisitListView;

  constructor(visitListView: VisitListView) {
    super(visitListView.context);
    this.visitListView = visitListView;
  }

  attachSegs(segs: Seg[]) {
    if (!segs.length) this.visitListView.renderEmptyMessage();
    else this.visitListView.renderSegList(segs);
  }

  detachSegs() {  }

  renderSegHtml(seg: Seg) {
    let {calendar, view, theme, options} = this.context;
    let eventRange: EventRenderRange | undefined = seg.eventRange;
    let eventDef: EventDef | null = (eventRange !== undefined ? eventRange.def : null);
    let eventInstance: EventInstance | null = (eventRange !== undefined ? eventRange.instance : null);
    let eventUi: EventUi | null = (eventRange !== undefined ? eventRange.ui : null);
    let url: string | null = (eventDef !== null ? eventDef.url : null);
    let classes: string[] = [ 'fc-visit-list-item' ].concat((eventUi!==null ? eventUi.classNames : ''));    
    if (url) {classes.push('fc-has-url');}
    let result = '<tr class="' + classes.join(' ') + '">';
    /*Egy lista szokott valamilyen jelölést tenni az elem elé, de ezt most kihagyjuk
      let bgColor: string | null = (eventUi !== null ? eventUi.backgroundColor : null);
      result += '<td class="fc-visit-list-item-marker ' + theme.getClass('widgetContent') + '">' + 
                    '<span class="fc-event-dot"' + (bgColor ? ' style="background-color:' + bgColor + '"' : '') + '></span>' +
                '</td>';
    */
    /* Amennyiben a "displayEventTime" igaz értékre van állítva, akkor az esemény időpontját szerepeltetni kell, hogy összhangban működjön a komponenssel*/
    let timeHtml: string;
    if (eventDef !== null && eventDef.allDay) {timeHtml = getAllDayHtml(view);} 
    else if (eventRange !== undefined && isMultiDayRange(eventRange.range)) {
        if (seg.isStart) {timeHtml = htmlEscape(this._getTimeText((eventInstance !== null ? eventInstance.range.start: seg.start), seg.end, false));} 
        else if (seg.isEnd) {timeHtml = htmlEscape(this._getTimeText(seg.start, (eventInstance !== null ? eventInstance.range.end : seg.end), false));} 
        else {timeHtml = getAllDayHtml(view);}} 
    else {timeHtml = (eventRange !== undefined ? htmlEscape(this.getTimeText(eventRange)) : '');}
    result += (this.displayEventTime ? '<td class="fc-visit-list-item-time ' + theme.getClass('widgetContent') + '">' + (timeHtml || '') + '</td>' : '');
    result += '<td colspan="'+(this.displayEventTime ? '2' : '3')+'" class="fc-visit-list-item-event ' + theme.getClass('widgetContent') + '"></td></tr>';
    


    /* A következő megoldás adatok beégetését igényelné, ezért nem így lesz megoldva
    let typeName = '';
    if (eventRange!==undefined){
      if (eventRange.def.extendedProps.type==='0') typeName='dfht';
      else if (eventRange.def.extendedProps.type===1) typeName='da';
      else if (eventRange.def.extendedProps.type===2) typeName='ea';
      else if (eventRange.def.extendedProps.type===3) typeName='la';
      else if (eventRange.def.extendedProps.type===4) typeName='ma';
      else if (eventRange.def.extendedProps.type===5) typeName='ta';
    }
    result += '<td colspan="3" class="fc-visit-list-item-title ' + theme.getClass('widgetContent') + '">' +
                '<div class="fc-visit-list-item-box">' +
                  '<div class="fc-visit-list-item-image image_'+typeName+'"></div>' +
                  '<div class="fc-visit-list-item-content content_'+typeName+'">' +
                    '<div class="fc-visit-list-item-content-left">' +
                      '<div class="fc-visit-list-item-content-left-1">' +
                        '<div class="fc-visit-list-item-content-inner fc-visit-list-item-content-left-1-inner">' + 
                          '14:00' + 
                        '</div>' +
                      '</div>' +
                      '<div class="fc-visit-list-item-content-left-2">' +
                        '<div class="fc-visit-list-item-content-inner fc-visit-list-item-content-left-2-inner">'+
                          'Eger' +
                        '</div>' +
                      '</div>' +
                    '</div>' +
                    '<div class="fc-visit-list-item-content-center">' +
                      '<div class="fc-visit-list-item-content-center-1">' +
                        '<div class="fc-visit-list-item-content-inner fc-visit-list-item-content-center-1-inner">' + 
                          'Az óra címe' + 
                        '</div>' +
                      '</div>' +
                      '<div class="fc-visit-list-item-content-center-2">' +
                        '<div class="fc-visit-list-item-content-inner fc-visit-list-item-content-center-2-inner">'+
                          'Szakmai támogatórendszer xy' +
                        '</div>' +
                      '</div>' +
                    '</div>' + 
                    '<div class="fc-visit-list-item-content-right">' +
                      '<div class="fc-visit-list-item-content-right-1">' +
                        '<div class="fc-visit-list-item-content-inner fc-visit-list-item-content-right-1-inner">' + 
                          '<button>Részletek</button>' + 
                        '</div>' +
                      '</div>' +
                      '<div class="fc-visit-list-item-content-right-2">' +
                        '<div class="fc-visit-list-item-content-inner fc-visit-list-item-content-right-2-inner">'+
                          (url ?  '<a href="' + htmlEscape(url) + '">' + htmlEscape((eventDef!==null ? eventDef.title : '')) : '[ide jönne az esemény web oldala]') +
                        '</div>' +
                      '</div>' +
                    '</div>' +
                  '</div>' +
                '</div>' +
              '</td>';
    result += '</tr>';
    */
    
    return result;
  }

  computeEventTimeFormat() {return {hour: 'numeric', minute: '2-digit', meridiem: 'short'}};

}
