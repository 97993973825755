import * as React from 'react';
import { getChapterLessons, ChapterLessons, getPDFPartSlide } from '@src/server/PublicServer';
import { match } from 'react-router';
import BookCrud, { IBookRecord } from '@src/framework/crud/doc/BookCrud';
import LessonCrud from '@src/framework/crud/doc/LessonCrud';
import { Link } from 'react-router-dom';
import { __ } from '@src/translation';

type BookLessonMatcherProps = {
    match: match<{bookId: string}>;
}

type BookLessonMatcherState = {
    book?: IBookRecord;
    chapterLessons: ChapterLessons[];
    contentVersions: any[];
    selectedLessonId?: number;
    hoverLessonId?: number;
}

export class BookLessonMatcher extends React.Component<BookLessonMatcherProps, BookLessonMatcherState> {

    constructor(props: any) {
        super(props);

        this.state = {
            chapterLessons: [],
            contentVersions: []
        };
    }

    componentDidMount() {
        this.reloadAsync();
    }

    async reloadAsync() {
        const bookId = Number(this.props.match.params.bookId);

        const book = (await BookCrud.load(bookId)).record;

        const chapterLessons = await getChapterLessons(bookId);

        const contentVersions = await getPDFPartSlide(book.nkp1_id!);

        console.log(contentVersions);

        this.setState({
            book,
            chapterLessons,
            contentVersions
        });


    }

    async onMatch(selectedNkp1Id: number) {
        if (!selectedNkp1Id) return;

        await new LessonCrud({
            id: this.state.selectedLessonId,
            nkp1_id: selectedNkp1Id
        }).put();
        
        this.setState({
            selectedLessonId: undefined
        });

        this.reloadAsync();
    }

    render() {
        if (!this.state.book) {
            return null;
        }

        var hoverLesson: any = null;
        if (this.state.hoverLessonId) {
            this.state.chapterLessons.forEach(c => {
                c.lessons.forEach(l => {
                    if (l.id == this.state.hoverLessonId) hoverLesson = l;
                })
            })
        }

        return <div className="row expanded">
                <div className="column small-12">
                    <h3>{this.state.book.name}</h3>
                </div>
                <div className="column small-6" style={{maxHeight: "90vh", overflow: "auto"}}>
                    {
                        this.state.chapterLessons.map(c => {
                            return <div key={c.id}>
                                <strong>{c.name}</strong>

                                <div style={{marginLeft: "2em"}}>
                                    {
                                        c.lessons.map(l => {
                                            var type = "warning";
                                            if (l.nkp1_id) {
                                                type = "success";
                                            }
                                            if (this.state.selectedLessonId == l.id) {
                                                type = "primary";
                                            }

                                            return <div>
                                                    <button 
                                                        className={"button small " + type}
                                                        style={{margin: "2px", minWidth: "30em"}}
                                                        onClick={() => this.setState({selectedLessonId: (this.state.selectedLessonId == l.id ? undefined: l.id)})}

                                                        onMouseOver={() => this.setState({hoverLessonId: l.id})}
                                                        onMouseOut={() => this.setState({hoverLessonId: undefined})}
                                                        >
                                                        {l.name}
                                                    </button>
                                                    &nbsp;&nbsp; <Link to={"/tankonyv/" + this.state.book!.uri_segment + "/" + l.uri_segment} target="_blank">
                                                        <i className="fa fa-link"/> &nbsp;{ __("Új tankönyv lap") }
                                                    </Link>
                                                </div>
                                        })
                                    }
                                </div>
                            </div>
                        })
                    }
                </div>
                <div className="column small-6" style={{maxHeight: "90vh", overflow: "auto"}}>
                    {
                        this.state.contentVersions.map(v => {
                            var type = "clear";
                            const linked = this.isChapterAlreadyLinked(v.Id);
                            
                            if (this.state.selectedLessonId) {
                                type = "primary";
                            }
                            if (linked) {
                                type = "success";
                            }
                            if (hoverLesson && hoverLesson.nkp1_id == v.Id) {
                                type = "primary";
                            }

                            return <div key={v.Id}>
                                <small style={{display: "inline-block", minWidth: "5em"}}>({v.StartPage1} - {v.EndPage})</small>

                                    <button 
                                        className={"button small " + type}
                                        style={{margin: "2px", minWidth: "30em"}}
                                        onClick={this.onMatch.bind(this, v.Id)}
                                        >
                                        {v.Title}
                                    </button>

                                    &nbsp;&nbsp; <a href={"https://player.nkp.hu/play/" + v.Id + "/false/undefined"} target="_blank">
                                        <i className="fa fa-link"/> &nbsp;{ __("Régi tankönyv PDF") }
                                    </a>
                            </div>
                        })
                    }
                </div>
            </div>
        ;
    }

    private isChapterAlreadyLinked(nkp1Id: number) {
        return this.state.chapterLessons.some(c => {
            return c.lessons.some(l => {
                return (l.nkp1_id == nkp1Id);
            });
        })
    }

}


export class BookLessonMatcherList extends React.Component<{}, {books: IBookRecord[]}> {
    constructor(props: any) {
        super(props);
        this.state = {
            books: []
        };
        this.reloadAsync();
    }
    async reloadAsync() {
        const books = await BookCrud.list({filter: {is_active: true}, order_by: "name"});

        this.setState({books});
    }

    render() {
        return <div className="row">
                <div className="column small-12">
                    <br/>
                    {
                        this.state.books.map(b => {
                            return <div>
                                    <Link className="button" to={"/meta/match/" + b.id}>
                                        {b.name}
                                    </Link>
                                </div>
                        })
                    }
                </div>
            </div>;
    }
}
