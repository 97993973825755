import * as React from 'react';
import { ForumMessages } from './ForumMessages';
import { ForumNewMessage } from './ForumNewMessage';
import ForumTopics from './ForumTopics';
import { forumStore, ForumContainerStore } from '../forumStore';
import { __ } from '@src/translation';
import { observer } from 'mobx-react';
import ForumMembers from './ForumMembers';
import { observable, action, runInAction } from 'mobx';
import { hasGroup, Groups, me } from '@src/framework/server/Auth';

interface IForumContainerProps {
    storeId : string; // Egyedi elérési út, ami azonosítja ezt a konkrét fórum megjelenítést.
    forumId : number; // A fórum azonosítója
    showTopics ?: boolean;
    showMembers ?: boolean;
}


/**
 * Fórum konténer, ami minden elemet tartalmaz, ami egy fórum kezeléséhez szükséges:
 * 
 * - fórum neve (ha van)
 * - topic lista
 * - tag lista
 * - üzenetek
 * - üzenet küldő doboz
 */
@observer
export default class ForumContainer extends React.Component<IForumContainerProps, {}>{
    public get store() : ForumContainerStore {
        return forumStore.getContainerStore(this.props.storeId);
    }

    constructor(props: any) {
        super(props);
        this.state = {};
        forumStore.getContainerStore(props.storeId).forumId = props.forumId;
    }

    public componentDidUpdate() {
        this.store.forumId = this.props.forumId;
    }

    @observable memberListOpen:boolean = false;
    @observable topicListOpen:boolean = false;

    @action.bound private toggleMemberList() {
        runInAction(() => { 
        this.memberListOpen = !this.memberListOpen;
        this.topicListOpen = false;

        });
    }
    @action.bound private toggleTopicList() {
        runInAction(() => { 
        this.topicListOpen = !this.topicListOpen;
        this.memberListOpen = false;
        });
    }

    render() {
        if (this.store.loading) {
            return <p className="secondary">{__("Betöltés...")}</p>;
        }
        if (!this.store.loaded) {
            return <p className="secondary">{__("A fórum nem tölthető be.")}</p>;
        }
        const forum = this.store.forum!;
        const showMembers = (this.props.showMembers===undefined) || (this.props.showMembers);
        let showTopics : boolean;
        if (this.props.showTopics===undefined) {
            showTopics = !!forum.default_topic_id;
        } else {
            showTopics = this.props.showTopics;
        }
        return <div className="forum-container">

            <div className="forum-header">
                
        {forum.title?<h4>{forum.title}</h4>:<h4>{__("Fórum")}</h4>}

                <div>
                    { me && hasGroup(me, Groups.Developer) &&
                    <button title="Témák" className={"button small"+(this.topicListOpen ? " primary" : " hollow")} onClick={()=>this.toggleTopicList()}>
                        <i className="fas fa-th-list"></i>
                    </button>
                    }
                    <button title="Tagok" className={"button small"+(this.memberListOpen ? " primary" : " hollow")} onClick={()=>this.toggleMemberList()}>
                        <i className="fas fa-users"></i>
                    </button>
                </div>
               
            </div>

            <div className="forum-body">

                {this.store.topicId?
                <>
                <div className="forum-messages-wrapper">               
                    <ForumNewMessage  storeId={this.props.storeId} />
                    <div className="forum-message-area">
                        <ForumMessages storeId={this.props.storeId}/>
                    </div>
                </div>
                </>:null}

                {
                    this.topicListOpen || this.memberListOpen
                    ?
                    <div className="forum-panel">
                        {showTopics && this.topicListOpen && <div className=""><ForumTopics storeId={this.props.storeId} showTopics={this.props.showTopics}/></div>}
                        {showMembers && this.memberListOpen && <div className=""><ForumMembers storeId={this.props.storeId} /></div>} 
                    </div>
                    : null
                }
                

            </div>

           

        </div>
       
    }

}



