

export type INotifierOp = "pub" | "sub";

export type NotifierTokenProvider = (channel: string) => Promise<string>; 

export interface INotifierJWT {
    aud : string;
    uid : number;
    ops ?: INotifierOp[];
    iss: string;
    exp: number;
    iat: number;
    jti: string;
    chs?: string[];
    flt?: Object;
}

/** Parse JWT string into JWT body */
export const parseJwt = (token:string) : INotifierJWT|null => {
    try {
      return JSON.parse(atob(token.split('.')[1]));
    } catch (e) {
      return null;
    }
  };

  /**
   * Notifier csatorna típus.
   * 
   * string-ből a parseChannel() függvénnyel lehet előállítani.
   */
export interface INotifierChannel {
  channel: string;
  main : string;
  sub: string;
}

const CHANNEL_PATTERN = new RegExp("^([a-zA-Z_0-9][a-zA-Z_0-9/]*)//([a-zA-Z_0-9/]*/{0,1})$")

export const parseChannel = (channel:string):INotifierChannel|null => {
  const parts = channel.match(CHANNEL_PATTERN);
  if (parts) {
    return { channel, main: parts[1], sub: parts[2]};
  } else {
    return null;
  } 
}