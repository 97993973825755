import ViewBook, { IViewBookRecord } from "@src/framework/view/doc/ViewBook";
import * as React from "react";
import { BubbleLoader } from 'react-css-loaders';
import { me, hasAnyGroup, Groups } from "@src/framework/server/Auth";
import { app } from '@src/index';
import { Link } from "react-router-dom";
import { List, ListItem, ListItemImage, ListItemText, ListItemMenu, ListItemMenuItem } from "@src/component/ui/List";
import { __ } from "@src/translation";
import { formatDateWithoutTime } from "@src/Util";
import TrainingTypeCrud, { ITrainingTypeRecord } from "@src/framework/crud/kap/TrainingTypeCrud";
import { PATH_BOOK_VIEW_EDITING, PATH_PUBLISHED_BOOK, PATH_EDIT_BOOK_TABLE_OF_CONTENTS, getPath } from "@src/Routes";
import { Breadcrumbs } from "@src/component/Breadcrumbs";
import { courseModule, CourseModule } from "../courseModule";
import { IViewKapTrainingTypeRecord } from "@src/framework/view/kap/ViewKapTrainingType";
import { cmsConfig } from "@src/component/cms/CmsModule";

type TrainingListState = {
    searchParams: any,
    trainingTypeRecords: ITrainingTypeRecord[],
    loading: boolean,
}

export default class TrainingList extends React.Component<any, TrainingListState> {

    constructor(props: any) {
        super(props);

        this.state = {
            searchParams: {},
            trainingTypeRecords: [],
            loading: true,
        }
    }

    componentDidMount() {
        this.asyncReload();
    }

    private asyncReload = async () => {
        this.setState({ loading: true });
        try {
            //let trainingItems: ITrainingTypeRecord[] = [];
            let trainingTypes: ITrainingTypeRecord[] = [];
            if (me && me.id) {
                let hasBookPermission: boolean = hasAnyGroup(me, [Groups.Developer, Groups.Admin, Groups.KapELearningEditor]);
                trainingTypes = await TrainingTypeCrud.list({
                    filter: {
                        is_active: true,
                        site_id: cmsConfig.siteId,
                    },
                    order_by: "no",
                });
            }
            this.setState({ loading: false, trainingTypeRecords: trainingTypes });
        } catch (error) {
            this.setState({ loading: false });
            app.showErrorFromJsonResult(error);
        }
    }

    async onDeleteLP(index: number) {
        /* const crud = new LpLearningPathCrud(this.state.learningPathRecords![index] as any);
         const response = await crud.del();
         app.showSuccess(__("Törlés"), __("Az elem törlése sikeres!"));
         this.asyncReload();*/
    }

    onEditLP(id: number) {        
        this.props.history.push(courseModule.PATH_COURSE_ORGANIZER_TRAINING_EDIT + "/" + id);
    }

    onEditMilestones(index: number) {
        let item = this.state.trainingTypeRecords[index];
        this.props.history.push(courseModule.PATH_COURSE_ORGANIZER_MILESTONE_EDIT + "/" + item.id);
    }

    render() {
        if (this.state.loading) {
            return <BubbleLoader />
        }
        let hasBookPermission: boolean = (me != null && hasAnyGroup(me, [Groups.Developer, Groups.Admin, Groups.KapELearningEditor]));
        return <><div className="">
            <Breadcrumbs links={[
                { name: __("KSZR"), path: courseModule.PATH_COURSE_ORGANIZER },
                { name: __("Tananyagok"), path: courseModule.PATH_COURSE_ORGANIZER_TRAINING_ADMIN },

            ]} />
            <h4>{__("Tananyagok")}</h4>
            {
                this.state.trainingTypeRecords.length === 0 &&
                <div className="callout">
                    <h5>{__("Jelenleg nincsenek tananyagok.")}</h5>
                </div>
            }
            <Link className="button small" to={courseModule.PATH_COURSE_ORGANIZER_TRAINING_EDIT}><i className="fa fa-plus" /> {__("Új képzés")}</Link>
            <List>
                {
                    this.state.trainingTypeRecords.map((item, index) => {
                        if (!item) return "";

                        //let bookItem = (this.state.trainingBookRecords && this.state.trainingBookRecords[index] != null) ? this.state.trainingBookRecords[index] : null;
                        let link = "";
                        if (hasBookPermission)
                            link = "";
                        let menuItems: ListItemMenuItem[] = [];

                        if (item.is_online) {
                            menuItems.push(
                                {
                                    icon: <i className="fas fa-fw fa-cogs" />,
                                    type: "secondary",
                                    name: __("Adatok"),
                                    onClick: () => this.onEditMilestones(index)
                                }
                            );
                        }
                        if (me && hasAnyGroup(me, [Groups.Admin, Groups.Developer, Groups.KapELearningEditor])) {
                            menuItems.push(
                                {
                                    icon: <i className="fa fa-fw fa-edit" />,
                                    type: "primary",
                                    name: __("Szerkesztés"),
                                    onClick: () => this.onEditLP(item.id!)
                                }
                            );
                        }

                        return <ListItem key={item.id}>
                            <Link to={link} title={item.title}>
                                <ListItemImage src={"/img/IKON_SET/FILE/text.svg"} />
                            </Link>
                            <ListItemText>                                
                                    <strong>{item.code}</strong>                               
                                <br />
                                <span className="content-item-list__small">{__("Létrehozva")}: {formatDateWithoutTime(item.creation_time)}</span>
                            </ListItemText>
                            <ListItemMenu mainItems={menuItems} >
                            </ListItemMenu>
                        </ListItem>

                    }

                    )
                }
            </List>

        </div>

        </>
    }

}