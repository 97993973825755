import * as ExerciseBaseTypes from "@src/component/exercise/models/ExerciseBaseClass";
import { AExerciseTypeConverter, StatisticsResponse, ValidationResponse } from "@src/component/exercise/models/AExerciseTypeConverter";
import { CrossWordData } from "@src/component/exercise/engine/EKECrossWordExerciseEngine/EKECrossWordExerciseEngine";
import * as React from "react";
import ExerciseFileSelect, { ExerciseFileTypes } from "@src/component/exercise/Editor/ExerciseFileSelect";
import { Accordion, ActionAccordionItem, AccordionActionType, IAccordionAction } from '@src/component/ui/Accordion';
import { Panel } from '@src/component/ui/Panel';
import { hasGroup, me, Groups } from "@src/framework/server/Auth";
import { __ } from '@src/translation';

export type CrossWordAElement = {
    image?: string,
    question_text: string,
    answer_text: string,
    char_index: any,
    hint_index: any,
    sound_url?: string
}

export type CrossWordAnswerElement = {
    answers: CrossWordAElement[],
    isHintTextVisible: boolean
}

export class EKECrossWordConverter extends AExerciseTypeConverter {
    static MAX_ROWS_NUMBER: number = 20;
    static ANSWER_TEXT_LENGTH = 20;

    public convertToEditorAnswer(exercise: CrossWordData): CrossWordAnswerElement | undefined {
        if (exercise) {
            var answerList: CrossWordAElement[] = [];
            var cur_exercise = exercise;
            if (cur_exercise.words) {
                for (var _i = 0; _i < cur_exercise.words.length; _i++) {
                    var curr_ans: CrossWordAElement = {
                        question_text: (cur_exercise.words[_i].description),
                        answer_text: cur_exercise.solution[_i],
                        char_index: (cur_exercise.words[_i].join + 1),
                        hint_index: cur_exercise.words[_i].hint_index instanceof Array ? cur_exercise.words[_i].hint_index.join(';') : cur_exercise.words[_i].hint_index,
                        sound_url: cur_exercise.words[_i].sound_url
                    }
                    answerList.push(curr_ans)
                }
            }
            var response = {
                answers: answerList,
                isHintTextVisible: exercise.isHintTextVisible
            }
            return response;
        }
        else return undefined;
    }

    public convertToJson(answerList: CrossWordAnswerElement, baseData: ExerciseBaseTypes.ExerciseBaseClass, prevJSON?: CrossWordAnswerElement): CrossWordData | undefined {
        if (answerList) {
            var ans_list = [];
            var sol_list = [];
            if (answerList.answers) {
                for (var _i = 0; _i < answerList.answers.length; _i++) {
                    var curr_element = answerList.answers[_i];
                    let curr_hint_index = "";

                    if (curr_element.hint_index && curr_element.hint_index != "")
                    curr_hint_index = curr_element.hint_index.toString().indexOf(';') == -1 ? curr_element.hint_index : curr_element.hint_index.split(';');

                    var curr_ans = {
                        description: curr_element.question_text,
                        letters: curr_element.answer_text.length - 1,
                        join: curr_element.char_index - 1,
                        join_letter: curr_element.answer_text[curr_element.char_index - 1],
                        hint_index: curr_hint_index,
                        sound_url: curr_element.sound_url
                    }

                    ans_list.push(curr_ans)
                    sol_list.push(curr_element.answer_text);
                }
            }
            var cur_exercise = ExerciseBaseTypes.convertToBaseJson(baseData);
            cur_exercise = {
                ...cur_exercise,
                words: ans_list,
                solution: sol_list,
                isHintTextVisible: answerList.isHintTextVisible
            };
            return cur_exercise;
        }
        else return undefined;
    }

    render() {
        let curr_ex: CrossWordAnswerElement = this.state.exercise as CrossWordAnswerElement;
        return (
            <Panel header={__("Részletek")} headingLevel={4}>
                <div>
                    <div className="row">
                        <div className="large-12 columns">
                            <label ><input type="checkbox" name="isHintTextVisible" checked={curr_ex.isHintTextVisible || false}
                                onBlur={this.onBlurEvent.bind(this)}
                                onChange={this.handleInputChange.bind(this)} />
                                { __("Segítség megjelenítése") }</label>
                        </div>
                    </div>
                    <Panel  header={__("Sorok")} headingLevel={5}>
                        <div>
                            {
                                curr_ex.answers ?
                                    curr_ex.answers.map((curr_row: CrossWordAElement, index) => {

                                        let actions: IAccordionAction[];
                                        actions = [
                                            {
                                                title: __("Törlés"),
                                                type: AccordionActionType.Trash,
                                                action: this.removeElement.bind(this, "answers", index)
                                            },
                                            {
                                                title: __("Fel"),
                                                type: AccordionActionType.Up,
                                                action: this.moveUp.bind(this, "answers", index)
                                            },
                                            {
                                                title: __("Le"),
                                                type: AccordionActionType.Down,
                                                action: this.moveDown.bind(this, "answers", index)
                                            }
                                        ];
                                        return (
                                            <Accordion key={"answers_" + index}>
                                                <ActionAccordionItem actions={actions} defaultClosed key={"answers_" + index} title={__("{index}. sor", {index:index + 1})}>
                                                    <Panel>
                                                        <div className="row">
                                                            <div className="large-12 columns">
                                                                <label>{ __("Leírás") } <span className="exe-editor-validation-msg">{this.props.validationMessages.get("answers[" + index + "].question_text")}</span>
                                                                <textarea name={"answers#" + index + ".question_text"} data-parent-index={index} value={curr_row.question_text}
                                                                        rows={3}
                                                                        onBlur={this.onBlurEvent.bind(this)}
                                                                        onChange={this.handleInputChange.bind(this)} />
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                        <div className="large-12 columns">
                                                            <label className="exe-image-select-label">{ __("Hangfájl (opcionális)") }
                                                                {<ExerciseFileSelect
                                                                    imagebasepath={this.props.imagebasepath}
                                                                    value={curr_row.sound_url || ""}
                                                                    onChange={this.handleImageChange.bind(this, "answers#" + index + ".sound_url")}
                                                                    getFolderId={this.getFolderId.bind(this)}
                                                                    fileType={ExerciseFileTypes.Sound}
                                                                />}
                                                            </label>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="large-4 small-12 medium-12 columns">
                                                                <label>{__("Szó")} <span className="exe-editor-validation-msg">{this.props.validationMessages.get("answers[" + index + "].answer_text")}</span>
                                                                     <input type="text" name={"answers#" + index + ".answer_text"} data-parent-index={index} value={curr_row.answer_text}
                                                                        onBlur={this.onBlurEvent.bind(this)}
                                                                        onChange={this.handleInputChange.bind(this)} />
                                                                </label>
                                                            </div>
                                                            <div className="large-4 small-12 medium-12 columns">
                                                                <label>{ __("Illesztés helye") }
                                                        <input type="number" name={"answers#" + index + ".char_index"} data-parent-index={index} value={curr_row.char_index}
                                                                        min={1} max={curr_row.answer_text.length}
                                                                        onBlur={this.onBlurEvent.bind(this)}
                                                                        onChange={this.handleInputChange.bind(this)} />
                                                                </label>
                                                            </div>
                                                            <div className="large-4 small-12 medium-12 columns">
                                                                <label>{ __("Segítség helye(i)") } (pl.: 2;3;6) 
                                                                     <input type="text" name={"answers#" + index + ".hint_index"} data-parent-index={index} value={curr_row.hint_index}
                                                                        onBlur={this.onBlurEvent.bind(this)}
                                                                        onChange={this.handleInputChange.bind(this)} />
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </Panel>
                                                </ActionAccordionItem>
                                            </Accordion>)
                                    })
                                    : ""
                            }
                        </div>
                        <div className="row">
                            <button className="button small" onClick={this.onAddNewLine.bind(this)}><i className="fa fa-plus"></i>{ __("Új sor") }</button>
                            <label className="exe-editor-label-description columns">{ __("Maximum {MAX_ROWS_NUMBER} sor vehető fel!", {MAX_ROWS_NUMBER:EKECrossWordConverter.MAX_ROWS_NUMBER}) }</label>
                        </div>
                    </Panel>

                </div>
            </Panel>
        );
    }

    onAddNewLine() {
        let temp_exercise = this.state.exercise as CrossWordAnswerElement;
        if (!temp_exercise.answers) temp_exercise.answers = [];
        temp_exercise.answers.push(this.getNewLine());
        if (this.makeValidBeforeSetState(this.state.exercise, temp_exercise).valid)
            this.setState({ exercise: temp_exercise });
    }

    getNewLine(): CrossWordAElement {
        let newElement: CrossWordAElement = {
            question_text: "",
            answer_text: "",
            char_index: "",
            hint_index: "",
        };
        return newElement;
    }

    public validate(editorAnswer: any, baseData: any, validationMap?:Map<string,string>, is_accessible?:boolean|null): ValidationResponse {
        let superAnswer: ValidationResponse = super.validate(editorAnswer, baseData,validationMap, is_accessible);
        if (!superAnswer.valid) return superAnswer;        

        let rowsValid = this.makeValidBeforeSetState(null, editorAnswer);
        if(!rowsValid.valid && !AExerciseTypeConverter.isOfiEditor ) return rowsValid;
        let errorMsg="";
        let OFIErrors: string[] = [];
       
        for (let i = 0; i < editorAnswer.answers.length; i++) {
            let curr_answer = editorAnswer.answers[i];
            if (curr_answer) {
                if (curr_answer.answer_text.length > EKECrossWordConverter.ANSWER_TEXT_LENGTH) {
                    errorMsg= __("Maximum  {ANSWER_TEXT_LENGTH}  karakteres szó vehető fel.", {ANSWER_TEXT_LENGTH:EKECrossWordConverter.ANSWER_TEXT_LENGTH});
                    validationMap!.set("answers["+i+"].answer_text",errorMsg);
                    if(!OFIErrors.includes(errorMsg, 0)) OFIErrors.push(errorMsg);
                    if(!AExerciseTypeConverter.isOfiEditor ) return { valid: false, message: errorMsg };
                }
                if (curr_answer.question_text.length > EKECrossWordConverter.TEXT_ANSWER_LENGTH) {
                    errorMsg= __("A szó leírása maximum {TEXT_ANSWER_LENGTH} karakter lehet.",{TEXT_ANSWER_LENGTH:EKECrossWordConverter.TEXT_ANSWER_LENGTH});
                    validationMap!.set("answers["+i+"].question_text",errorMsg);
                    if(!OFIErrors.includes(errorMsg, 0)) OFIErrors.push(errorMsg);
                    if(!AExerciseTypeConverter.isOfiEditor ) return { valid: false, message: errorMsg}
                }                
            }
        }
        if (AExerciseTypeConverter.isOfiEditor) return { valid: true, message: OFIErrors.join(' , ')}
        return { valid: true }
    }

    public makeValidBeforeSetState(oldState: CrossWordAnswerElement|null, newState: CrossWordAnswerElement): ValidationResponse {
        if (newState.answers.length > EKECrossWordConverter.MAX_ROWS_NUMBER) {
            newState.answers.splice(EKECrossWordConverter.MAX_ROWS_NUMBER, newState.answers.length - EKECrossWordConverter.MAX_ROWS_NUMBER);
            if(!AExerciseTypeConverter.isOfiEditor ) return { valid: false, message: __("Maximum {MAX_ROWS_NUMBER} sor vehető fel.", {MAX_ROWS_NUMBER:EKECrossWordConverter.MAX_ROWS_NUMBER}) }
        }
        return { valid: true }
    }

    public getExerciseStatistics(exerciseList: any): StatisticsResponse[] {
        if (!exerciseList || exerciseList.length == 0) return [];
        let statisticsResponses: StatisticsResponse[] = [];
        statisticsResponses.push({name:__("limit - kérdések száma: {n}", {n: EKECrossWordConverter.MAX_ROWS_NUMBER}), count:undefined});
        statisticsResponses.push({name:__("limit - szó karakterszáma: {n}", {n: EKECrossWordConverter.ANSWER_TEXT_LENGTH}), count:undefined});
        statisticsResponses.push({name:__("limit - leírás karakterszám: {n}", {n: EKECrossWordConverter.TEXT_ANSWER_LENGTH}), count:undefined});

        let answersStat, answerTextStat, questionTextStat: StatisticsResponse;
        answersStat = { name: __("Szavak száma") + " ", count: new Map() }
        answerTextStat = { name: __("Szavak hossza (karakter)"), count: new Map() }
        questionTextStat = { name: __("Szó leírás hossza"), count: new Map() }
        for (let i = 0; i < exerciseList.length; i++) {
            if (!exerciseList[i]) continue;
            const currentExc = exerciseList[i];
            let answer_count = answersStat.count.has(currentExc.answers.length) ? answersStat.count.get(currentExc.answers.length) : 0;
            answersStat.count.set(currentExc.answers.length, answer_count! + 1);
            for (let j = 0; j < currentExc.answers.length; j++) {
                const curr_ans = currentExc.answers[j];
                if (curr_ans) {
                    let answertext_counter = answerTextStat.count.has(curr_ans.answer_text.length) ? answerTextStat.count.get(curr_ans.answer_text.length) : 0;
                    answerTextStat.count.set(curr_ans.answer_text.length, answertext_counter! + 1);
                    let nearRounded = Math.ceil(curr_ans.question_text.length/10)*10;
                    let question_text_counter = questionTextStat.count!.has(nearRounded) ? questionTextStat.count!.get(nearRounded) : 0;
                    questionTextStat.count!.set(nearRounded, question_text_counter! + 1);
                }
            }
        }
        statisticsResponses.push(answersStat, answerTextStat, questionTextStat);
        return statisticsResponses;
    }

}