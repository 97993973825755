import { Server } from '../../server/Server';
import Crud , { registerCrudClassProxy, IAsTextResult, IListParams, CrudClassProxy, ISearchTextParams, ISearchTextResult } from '../Crud';

export interface IHeadTableRecord {
    /**
     * Globálisan egyedi azonosító.
     * 
     * Ha új rekordot akarsz létrehozni put() metódussal, akkor hagyd üresen.
     * upsert() hívásnál figyelmen kívül van hagyva az értéke.
     *
     */
    id ?: number;
    /**
     * Rekord létrehozás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel létrehozásakor.
     */
    creation_time?: string /*timestamp?*/;
    /**
     * Ki hozta létre
     *
     * NOT NULL -> sys.sec_user ON DELETE noaction
     *
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozó felhasználóra hivatkozik.
     */
    creation_user_id?: number;
    /**
     * Melyik session-ből jött létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozáshoz használt munkamenetre hivatkozik.
     */
    creation_session_id?: number|null;
    /**
     * Utolsó módosítás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel módosításakor.
     */
    modification_time?: string /*timestamp?*/|null;
    /**
     * Ki módosította utoljára
     *
     * -> sys.sec_user ON DELETE noaction
     *
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosító felhasználóra hivatkozik.
     */
    modification_user_id?: number|null;
    /**
     * Melyik session-ből módosították utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosításhoz használt munkamenetre hivatkozik.
     */
    modification_session_id?: number|null;
    /**
     * Aktív
     *
     * Logikai törléshez használt mező.
     */
    is_active?: boolean;
    /**
     * Folyamat típus
     *
     * NOT NULL -> wf.wf_type ON DELETE noaction
     *
     */
    wf_type_id?: number;
    /**
     * Fej táblázat
     *
     * NOT NULL -> meta.table_info ON DELETE noaction
     *
     */
    table_id?: number;
    /**
     * Automatikus indítás
     *
     * Ha ez be van álíltva, akkor a folyamat fej táblázatba való beszúráskor ez a típusú folyamat automatikusan elindul.
     */
    auto_create?: boolean;
}

/**
 *  wf.head_table - Folyamat fej táblázat
 *
 *  Ez a táblázat határroza meg, hogy melyik folyamat típus melyik (fej) táblázathoz tartozik. Például a könyv (publikálási) folyamat típus a könyv (book) táblázathoz. Megjegyzés: egy folyamat típust több fejhez is hozzá lehet kapcsolni.
 */

export default class HeadTableCrud extends Crud<IHeadTableRecord> {
    public static TABLE_INFO_ID = 2715409314;
    public static getSchemaNameForClass() { return 'wf'; }
    public static getTableNameForClass() { return 'head_table'; }

    public static load: (id: number, aServer ?: Server) => Promise<HeadTableCrud>;
    public static asText: (id: number, includeRecord : boolean, aServer ?: Server) => Promise<IAsTextResult<IHeadTableRecord>>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<IHeadTableRecord[]>;
    public static searchText: (params: ISearchTextParams, aServer ?: Server) => Promise<ISearchTextResult<IHeadTableRecord>[]>;
    public static deleteById: (id: number, aServer ?: Server) => Promise<IHeadTableRecord>;
    public static unDeleteById: (id: number, aServer ?: Server) => Promise<IHeadTableRecord>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class HeadTableCrudClassProxy extends CrudClassProxy<IHeadTableRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const headTableCrudClassProxy = new HeadTableCrudClassProxy(HeadTableCrud);
registerCrudClassProxy(headTableCrudClassProxy);
