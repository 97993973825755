export class EKEFillTableDnDServer{
    
    static evaluateOnServer(exercise: any, correctSolution: any, userSolution: any): Evaluated {
        let success = true;
        let wrongElementsCount = 0;
        for (let i = 0; i < correctSolution.length; i++) {
            if (userSolution[i] == null                                                                                                                                                        //there is no user solution
                || (exercise.exercise.options[userSolution[i]].type == "image" && exercise.exercise.options[userSolution[i]].image != exercise.exercise.options[correctSolution[i]].image)    //it is an image but doesn't match the correct
                || (exercise.exercise.options[userSolution[i]].type == "text" && exercise.exercise.options[userSolution[i]].text != exercise.exercise.options[correctSolution[i]].text)      //it is a text but doesn't match the correct
                || (exercise.exercise.options[userSolution[i]].type == "sound" && exercise.exercise.options[userSolution[i]].url != exercise.exercise.options[correctSolution[i]].url))     //it is a sound but doesn't match the correct  
            {
                success = false;
                wrongElementsCount++;
            }
        }

        let successPercent = Number(((correctSolution.length - wrongElementsCount) / correctSolution.length).toFixed(2));
        return { "success": success, "solution": correctSolution, "successPercent": success ? 1 : successPercent };
    }

}