import * as React from 'react';
import * as ReactDOM from 'react-dom';
import './BookPage.css';
import { Link } from 'react-router-dom';
import BookMenu, { ScreenReaderMenu } from '@src/component/book/viewer/BookMenu';
import { BubbleLoader } from 'react-css-loaders';
import { BookStructure, ChapterJoinLesson, BookLessonContent, RenderedSection, UserEventTargets, UserEventTypes } from '@src/server/PublicServer';
import { scriptLoadAsync, timeoutAsync } from '@src/Util';
import { ISupplementaryMaterialRecord } from '@src/framework/crud/doc/SupplementaryMaterialCrud';
import { getClassesFromLessonUriSegment } from '@src/template/BookTemplate';

import ChapterMenu from '@src/component/book/viewer/ChapterMenu';
import BookFooter from '@src/component/book/viewer/BookFooter';
import { BookAnnotations, AnnotationMode } from '@src/component/book/viewer/page_addition/BookAnnotations';
import { getRevealIdAndName, isRevealSection } from '@src/template/SectionTemplate';

import HeaderProgressBar from '@src/component/book/viewer/HeaderProgressBar';
import HeaderLegend from '@src/component/book/viewer/HeaderLegend';
import ContentsList from '@src/component/book/viewer/ContentsList';

import { PATH_PUBLISHED_BOOK, PATH_EDIT_BOOK_SECTIONS } from '@src/Routes';
import { BookSections, SectionType, SECTION_TYPES_ALL, SECTION_TYPES_TEXT, SECTION_TYPES_EXERCISE_IFRAME, SECTION_TYPES_VIDEO, SECTION_TYPES_EXTENSION, containsSection } from '@src/component/book/viewer/BookSections';
import { me, Groups, hasGroup } from '@src/framework/server/Auth';
import { LIBRARY_OFI_OFFICIAL_ID } from '@src/Const';
import { history } from '@src/index';
import { __ } from '@src/translation';
import { config } from '@src/framework/server/Server';
import { Toggle, AnimationMode } from '@src/component/ui/Panel';
import { BookCanvasToolBar } from '@src/component/book/viewer/page_addition/BookCanvas';
import { Dialog } from '@src/component/Dialog';
import { setTimeout } from 'timers';
import * as LessonCrud from '@src/framework/crud/doc/LessonCrud';
import * as LessonCrudPub from '@src/framework/crud/doc_pub/LessonCrud';
import UserTracer from '../UserTracer';

const HEADER_FILENAME_DEFAULT = "default.png";


type BookPageConfig = {
    footer?: JSX.Element
}

let pageConfig: BookPageConfig = {};

export function configureBookPage(newConfig: BookPageConfig) {
    pageConfig = newConfig;
}

export enum ViewMode {
    NORMAL = 1,
    PRESENTATION = 2,
    PAGED = 3,
    SIMPLE = 4,
    BOOK = 5,
    ORDERED = 6,
    CONTENT = 7

}

type BookPageProps = {
    onViewModeChange?: (viewMode: ViewMode) => void;

    bookPath: string;

    lessonData?: BookLessonContent;
    prevLessonData?: BookLessonContent;
    lesson: ChapterJoinLesson | null;
    bookStructure?: BookStructure;

    // Előnézetben ne scrollozzunk a lap tetejére, mert szerkesztés közben állandóan felscrollozna
    disableScrollOnUpdate?: boolean;
    viewMode?: ViewMode;
    sectionId?: string;
}

type BookPageState = {
    cssLoaded: boolean;
    annotationMode: AnnotationMode;
    sectionTypes: SectionType[];
    currentSectionIndex: number;
    showTeacherGuide: boolean;
    openGuide: boolean;
    guideSectionId?: number;
}

export class BookPage extends React.Component<BookPageProps, BookPageState> {

    private doc: HTMLDocument;

    private bookCommonCss: HTMLLinkElement;

    private bookCustomCss: HTMLLinkElement;

    private hashChangeEventListener: any;

    constructor(props: BookPageProps) {
        super(props);

        var annotationMode = AnnotationMode.NONE;
        this.state = {
            cssLoaded: false,
            annotationMode,
            sectionTypes: SECTION_TYPES_ALL,
            currentSectionIndex: 0,
            showTeacherGuide: false,
            openGuide: false
        };
    }

    async componentDidMount() {
        this.doc = ReactDOM.findDOMNode(this)!.ownerDocument!;

        this.bookCommonCss = this.doc.createElement("link");
        this.bookCustomCss = this.doc.createElement("link");

        this.bookCommonCss.setAttribute("rel", "stylesheet");
        this.bookCommonCss.setAttribute("href", `/css/book_flex.css`);

        this.bookCustomCss.setAttribute("rel", "stylesheet");

        this.reloadCssAsync();

        this.hashChangeEventListener = window.addEventListener("hashchange", this.onHashChange.bind(this), false);

        if (this.props.lesson) this.doc.body.className = getClassesFromLessonUriSegment(this.props.lesson.lesson_uri_segment).join(" ");

        this.setState({ currentSectionIndex: 0 });
        window.addEventListener("resize", this.removeWordMatch);
        window.addEventListener("click", this.removeWordMatch);
    }

    removeWordMatch() {
        document.querySelectorAll('match').forEach(el => {
            var docFrag = document.createDocumentFragment();
            while (el.firstChild) {
                var child = el.removeChild(el.firstChild);
                docFrag.appendChild(child);
            }

            el.parentNode!.replaceChild(docFrag, el);
        })
    }



    onResize() {
        if (this.props.viewMode == ViewMode.SIMPLE) {
            this.stretchSection();
        }
    }

    componentWillUnmount() {
        this.removeBookCss();
        this.doc.body.removeAttribute("class");

        if (this.hashChangeEventListener) window.removeEventListener("hashchange", this.hashChangeEventListener);


        this.doc.body.className = "";
        document.getElementById("mainMenuBar")!.classList.remove("slide");

        window.removeEventListener("resize", this.onResize.bind(this));

    }

    private onHashChange() {
        if (this.props.viewMode != ViewMode.SIMPLE) {
            this.scrollToId(window.location.hash.substr(1));
        } else {
            this.getSectionIndexById(window.location.hash);
        }

    }

    private onLessonChange() {
        this.setState({ currentSectionIndex: 0 });
    }

    componentDidUpdate(prevProps: BookPageProps, prevState: BookPageState) {
        // if(!window.location.hash && this.state.currentSectionIndex > 0 && this.state.currentSectionIndex == prevState.currentSectionIndex)
        // {
        //     this.setState({ currentSectionIndex: 0 });
        // }

        if (this.props.lessonData != prevProps.lessonData && location.hash) {
            this.getSectionIndexById(window.location.hash);
        }

        if (this.props.viewMode == ViewMode.SIMPLE) {
            this.stretchSection();
        }


        if (this.props.viewMode != undefined && (this.props.viewMode != this.props.viewMode)) {
            var sectionTypes = SECTION_TYPES_ALL;
            switch (this.props.viewMode) {
                case ViewMode.CONTENT:
                    {
                        sectionTypes = SECTION_TYPES_TEXT;
                        break;
                    }
                //case ViewMode.SIMPLE:
                case ViewMode.BOOK:
                    {
                        sectionTypes = SECTION_TYPES_TEXT;
                        break;
                    }
            }
            if (this.props.lessonData!.lessonId != prevProps.lessonData!.lessonId) {
                this.setState({ sectionTypes: sectionTypes, currentSectionIndex: 0 });
            }

        }

        this.reloadCssAsync();


        if (this.props.bookStructure != prevProps.bookStructure || this.props.lessonData != prevProps.lessonData || (this.state.cssLoaded && !prevState.cssLoaded)) {
            if (!this.props.disableScrollOnUpdate) {
                this.scrollToTop();
            }
        }

        const lesson = this.props.lesson;
        if (this.props.bookStructure && lesson) {
            document.title = this.props.bookStructure.name + " - " + lesson.chapter_name + " - " + lesson.lesson_name;
        }

        if (lesson) this.doc.body.className = getClassesFromLessonUriSegment(lesson.lesson_uri_segment).join(" ");

        // if (this.props.viewMode != ViewMode.NORMAL && this.props.viewMode != ViewMode.CONTENT) {
        //     document.getElementById("mainMenuBar")!.classList.add("slide");
        // }
        // else {
        //     document.getElementById("mainMenuBar")!.classList.remove("slide");
        // }

        // if (this.props.bookStructure && this.props.bookStructure!.is_sni) {
        //     $('[data-lightbox]').removeAttr("href");
        //     $('[data-lightbox]').removeAttr("data-lightbox");
        // }
    }

    private scrollToTop() {

        if (this.refs.stickyMenuElement && this.refs.content) {

            const topMenuHeight = (this.refs.stickyMenuElement as HTMLElement).clientHeight;
            const offsetTop = $(this.refs.content).offset()!.top - topMenuHeight;

            const currentTop = $(document).scrollTop();

            if (window.location.hash) {// Megváltozott a hash, scrollozzunk oda
                this.scrollToId(window.location.hash.substr(1));
            }
            // else if (currentTop && currentTop > offsetTop) { // Új lap töltődött be, scrollozzunk a header aljára
            //     $(document).scrollTop(offsetTop);
            // }
            else {
                $(document).scrollTop(0);
            }
        }
    }

    private async scrollToId(elementId: string) {
        const element = document.getElementById(elementId);
        if (element) {
            const topMenuHeight = (this.refs.stickyMenuElement as HTMLElement).clientHeight;

            for (var i = 0; i < 10; i++) {
                const offsetTop = Math.round($(element).offset()!.top - topMenuHeight);
                $(document).scrollTop(offsetTop);

                //Lazy loading miatt még egy másodpercig oda kell scrollozni, mert a betöltődő képek miatt elcsúszik a tartalom
                await timeoutAsync(100);

                if ($(document).scrollTop() != offsetTop) break; // A felhasználó már scrolloz
            }
        }
    }



    async reloadCssAsync() {
        if (!this.bookCommonCss.parentElement) {
            this.doc.head.appendChild(this.bookCommonCss);
        }

        if (!this.bookCustomCss.parentElement) {
            this.doc.head.appendChild(this.bookCustomCss);
        }

        if (!this.props.bookStructure) {
            return;
        }

        const cssHref = this.props.bookStructure.css_path;

        if (this.bookCustomCss.getAttribute("href") != cssHref) {
            this.bookCustomCss.setAttribute("href", cssHref);

            this.setState({ cssLoaded: false });

            try {
                await scriptLoadAsync(this.bookCustomCss, 200);
            } catch (e) {/*css not yet loaded, render anyway*/ }

            this.setState({ cssLoaded: true });
        }

    }

    removeBookCss() {
        this.bookCustomCss.remove();
        this.bookCommonCss.remove();
    }

    getChapterLessonTree() {
        if (!this.props.bookStructure) {
            return [];
        }

        const chapterLessonTree: any[] = [];

        for (const lesson of this.props.bookStructure.lessons) {
            if (chapterLessonTree.length == 0 || lesson.chapter_id != chapterLessonTree[chapterLessonTree.length - 1].id) {
                chapterLessonTree.push({
                    id: lesson.chapter_id,
                    name: lesson.chapter_name,
                    chapter_headno: lesson.chapter_headno,
                    lessons: []
                });
            }

            chapterLessonTree[chapterLessonTree.length - 1].lessons.push({
                id: lesson.lesson_id,
                name: lesson.lesson_name,
                chapter_headno: lesson.chapter_headno,
                uri_segment: lesson.lesson_uri_segment
            });
        }

        return chapterLessonTree;
    }

    handleViewChange(viewMode: ViewMode) {
        //this.setState({viewMode})
        if (this.props.onViewModeChange) {
            this.props.onViewModeChange(viewMode);
        }
    }

    handleSectionTypeChange(sectionTypes: SectionType[]) {
        this.setState({ sectionTypes: sectionTypes });
    }

    nextSection() {
        if (this.state.currentSectionIndex < this.getNonRevealSections().length - 1) {
            const renderedSections = this.getNonRevealSections()[this.state.currentSectionIndex + 1];
            history.push(window.location.pathname + "#section-" + renderedSections.id);
            this.setState({ currentSectionIndex: this.state.currentSectionIndex + 1 });
        }
    }
    prevSection() {
        if (this.state.currentSectionIndex > 0) {
            const renderedSections = this.getNonRevealSections()[this.state.currentSectionIndex - 1];

            history.push(window.location.pathname + "#section-" + renderedSections.id);
            this.setState({ currentSectionIndex: this.state.currentSectionIndex - 1 });
        }

    }

    getSectionIndexById(sectionId: string) {
        var sectionSplit = sectionId.split('-');

        var id: number = Number(sectionSplit[1]);
        var sectionIndex = 0;
        var temp = this.getNonRevealSections();
        sectionIndex = this.getNonRevealSections().findIndex((section, index: number) => {
            return section.id == id;
        });
        this.setState({ currentSectionIndex: sectionIndex });

    }

    stretchSection() {

        if (this.getNonRevealSections()[this.state.currentSectionIndex]) {
            var sectionId = (this.getNonRevealSections()[this.state.currentSectionIndex]).id;
            var section = document.getElementById('section-' + sectionId);
            var content = document.getElementById('content');

            if (section && content && !section.classList.contains('exercise')) {
                var cRatio = content.clientHeight / content.clientWidth;
                var sRatio = section.clientHeight / section.clientWidth;

                var ratio = 1;
                if (cRatio > sRatio) {
                    ratio = content.clientWidth / 1280;
                }
                else {
                    ratio = content.clientHeight / 640;
                }

                section.style.transform = "scale(" + ratio + ")";
            }
        }

    }

    /**
     * Csak a nem modális szekciók, SNI nézetben az előbukkanó ablakot nem jelenítjuk meg mint egy dia
     */
    private getNonRevealSections(): RenderedSection[] {
        if (!this.props.lessonData) return [];

        return this.props.lessonData.renderedSections.filter(s => !isRevealSection(s.html) && !s.isTeachersGuide);
    }
    private getPrevLessonNonRevealSections(): RenderedSection[] {
        if (!this.props.prevLessonData) return [];

        return this.props.prevLessonData.renderedSections.filter(s => !isRevealSection(s.html) && !s.isTeachersGuide);
    }

    private getBookNonRevealSections(): RenderedSection[] {
        if (!this.props.lessonData) return [];

        return this.props.lessonData.renderedSections.filter(s => !isRevealSection(s.html) && !s.isTeachersGuide);
    }


    render() {
        if (!this.state.cssLoaded) {
            return <BubbleLoader />;
        }

        var prevUrl = undefined;
        var nextUrl = undefined;

        const lesson = this.props.lesson;

        if (!this.props.bookStructure || !lesson) {
            return <BubbleLoader />;
        }

        const lessons = this.props.bookStructure.lessons;

        var index = lessons.findIndex(x => x.lesson_uri_segment == lesson.lesson_uri_segment);
        if (index == -1) index = 0;

        if (index > 0) {
            prevUrl = this.props.bookPath + lessons[index - 1].lesson_uri_segment;
        }

        if (index < lessons.length - 1) {
            nextUrl = this.props.bookPath + lessons[index + 1].lesson_uri_segment;
        }

        const chapterLessons = this.getChapterLessonTree();

        const reveals: { id: string, name: string }[] = [];

        if (this.props.lessonData) {
            for (const renderedSection of this.props.lessonData.renderedSections) {
                const reveal = getRevealIdAndName(renderedSection.html);
                if (reveal) {
                    reveals.push(reveal);
                }
            }
        }

        const contentClass = this.props.viewMode == ViewMode.SIMPLE ? "simple wide-display" : (this.props.viewMode == ViewMode.CONTENT ? "wide-display content-view" : "");

        const isPublished = this.props.bookPath.startsWith(PATH_PUBLISHED_BOOK);

        const showCollectionMenu = this.props.viewMode === ViewMode.NORMAL;

        const arrowSVG = <svg aria-hidden="true" data-icon="triangle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" className="triangle-arrow">
            <defs>
                <filter id="drop-shadow" x="-100%" y="-100%" width="300%" height="300%">
                    <feGaussianBlur in="SourceAlpha" stdDeviation="15" />
                    <feOffset dx="10" dy="25" result="offsetblur" />
                    <feFlood floodOpacity="0.3" floodColor="#000000" />
                    <feComposite in2="offsetblur" operator="in" />
                    <feMerge>
                        <feMergeNode />
                        <feMergeNode in="SourceGraphic" />
                    </feMerge>
                </filter>
            </defs>
            <path filter="url(#drop-shadow)" stroke="white" strokeWidth="5" fill="currentColor" d="M329.6 24c-18.4-32-64.7-32-83.2 0L6.5 440c-18.4 31.9 4.6 72 41.6 72H528c36.9 0 60-40 41.6-72l-240-416z"></path>
        </svg>;

        var renderedSections: RenderedSection[] = [];
        let teacherGuideSections: RenderedSection[] = [];
        if (this.props.viewMode == ViewMode.SIMPLE && this.props.lessonData) {

            renderedSections = this.getNonRevealSections().slice(this.state.currentSectionIndex, this.state.currentSectionIndex + 1);

            renderedSections = renderedSections.concat(this.props.lessonData.renderedSections.filter(s => (isRevealSection(s.html) && !s.isTeachersGuide)))
            teacherGuideSections = this.props.lessonData.renderedSections.filter(s => s.isTeachersGuide);
        }

        let footerSrc = "";

        if (this.props.lesson!.chapter_headno) {
            footerSrc = "footer_" + (this.props.lesson!.chapter_headno < 10 ? "0" : "") + this.props.lesson!.chapter_headno;

        } else {
            footerSrc = "footer_01";

        }


        const guideRenderedSection = teacherGuideSections.find((section) => { return section.id == this.state.guideSectionId });



        return <>

            <div className="book-top">
                {

                    this.props.viewMode != ViewMode.CONTENT ?
                        <BookHeader
                            bookName={this.props.bookStructure.name}
                            bookSlogan={this.props.bookStructure.slogan}
                            bookPath={this.props.bookPath}
                            bookUriSegment={this.props.bookStructure.uri_segment}
                            lesson={lesson}
                            chapters={chapterLessons}
                            headerFileNames={this.props.bookStructure.headerFileNames}
                            viewMode={this.props.viewMode}
                            libraryId={this.props.bookStructure.library_id}
                            bookId={this.props.bookStructure.id}
                        />
                        :
                        <header id="header" className="header lesson-header">
                            <div className="row">
                                <div className="top-header" style={{ position: "relative" }}>
                                    <div className="row align-middle">
                                        <div className="row align-middle title-column">

                                            <span className="book-logo">

                                                <img alt="" src={`/book/${this.props.bookStructure.uri_segment}/img/HEADER/logo.svg`} />
                                                <div>
                                                    <span>
                                                        {this.props.bookStructure.name}
                                                    </span>

                                                </div>
                                            </span>
                                            <div className="title-cell hide-for-small-only">
                                                <h1 className="book-title replace-chapter-name" data-template-replace="true">
                                                    {lesson.chapter_name}
                                                </h1>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </header>
                }
                {
                    this.props.viewMode == ViewMode.NORMAL ?
                        <>
                            <TimeLine bookUriSegment={this.props.bookStructure.uri_segment}
                                lesson={lesson}
                                chapters={chapterLessons} />
                        </>
                        : ""
                }


                {
                    this.props.viewMode != ViewMode.CONTENT ?

                        <div ref="stickyMenuElement">
                            <BookStickyMenu key="bookStickyMenuPage"
                                prevUrl={prevUrl}
                                nextUrl={nextUrl}
                                bookStructure={this.props.bookStructure}
                                bookPath={this.props.bookPath}
                                chapterLessons={chapterLessons}
                                lessonName={lesson.lesson_name}
                                sectionNames={this.props.lessonData ? this.props.lessonData.renderedSections : []}
                                supplementaryMaterials={this.props.lessonData ? this.props.lessonData.supplementaryMaterials : []}
                                reveals={reveals}
                                lessonUriSegment={lesson.lesson_uri_segment}
                                onSectionTypeChange={(sectionTypes) => this.handleSectionTypeChange(sectionTypes)}
                                viewMode={this.props.viewMode}
                                sectionTypes={this.state.sectionTypes}
                                onLessonChange={this.onLessonChange.bind(this)}
                                isSticky={this.props.viewMode != ViewMode.SIMPLE}
                                showTeacherGuide={() => this.setState({ showTeacherGuide: true })}
                                sectionIndex={this.state.currentSectionIndex}
                            />
                        </div>
                        : null
                }

            </div>
            {this.props.viewMode == ViewMode.SIMPLE ?
                <>
                    <div className="prev-side">
                        {
                            this.state.currentSectionIndex <= 0 ?
                                prevUrl
                                    ?
                                    <Link className="pager-prev" to={prevUrl} title={__("Előző leckére ugrás")} onClick={() => this.setState({ currentSectionIndex: this.getPrevLessonNonRevealSections().length - 1 })}>
                                        {/* {arrowSVG} */}
                                    </Link>
                                    :
                                    null
                                :
                                <a onClick={this.prevSection.bind(this)} className="pager-prev" title={__("Előző szekció")}>
                                    {/* {arrowSVG} */}
                                </a>
                        }
                    </div>
                    <div className="next-side">
                        {
                            this.getNonRevealSections().length === 0 || this.state.currentSectionIndex == this.getNonRevealSections().length - 1 ?
                                nextUrl
                                    ?
                                    <Link className="pager-next" to={nextUrl} title={__("Következő leckére ugrás")} onClick={() => { this.setState({ currentSectionIndex: 0 }) }}>
                                        {/* {arrowSVG} */}
                                    </Link>
                                    :
                                    null
                                :
                                <a onClick={this.nextSection.bind(this)} className="pager-next" title={__("Következő szekció")}>
                                    {/* {arrowSVG} */}
                                </a>
                        }
                    </div>
                </>
                : null}

            <div id="bookMain" style={{ fontSize: (this.props.bookStructure!.is_sni ? "120%" : "100%") }}>

                {
                    this.props.lessonData
                        ?
                        <div ref="content" className={contentClass}>
                            <UserTracer
                                event={{
                                    tableInfoId: isPublished ? LessonCrudPub.default.TABLE_INFO_ID : LessonCrud.default.TABLE_INFO_ID,
                                    recordId: this.props.lessonData.lessonId,
                                    name: this.props.bookStructure.name + "/" + this.props.lesson!.lesson_name,
                                    data: {
                                        eventTarget: UserEventTargets.LESSON,
                                        eventType: UserEventTypes.VIEWED,
                                        event: "lesson",
                                        lesson_event: "view"
                                    }
                                }}
                            />
                            <BookAnnotations lessonId={lesson.lesson_id} annotationMode={this.state.annotationMode} >


                                {
                                    this.props.viewMode == ViewMode.ORDERED || (this.props.viewMode == ViewMode.CONTENT && !config.mainServer.showBooks) ?
                                        <>
                                            <BookSections
                                                key={lesson.lesson_id}
                                                renderedSections={this.props.lessonData.renderedSections}
                                                chapterId={lesson.chapter_id}
                                                sectionTypes={SECTION_TYPES_TEXT}
                                                published={isPublished}
                                                showCollectionMenu={showCollectionMenu}
                                                lessonName={lesson.lesson_name}
                                                isSNIview={false}


                                            />

                                            {
                                                this.props.lessonData.renderedSections.filter(s => containsSection(SECTION_TYPES_EXTENSION, [s])).length > 0
                                                    ?

                                                    <div className="row">
                                                        <Toggle isOpen={false} header={__("Kiegészítő anyagok")} animationIn={AnimationMode.fadeIn} animationOut={AnimationMode.fadeOut}>

                                                            <BookSections
                                                                key={lesson.lesson_id}
                                                                renderedSections={this.props.lessonData.renderedSections}
                                                                chapterId={lesson.chapter_id}
                                                                sectionTypes={SECTION_TYPES_EXERCISE_IFRAME}
                                                                published={isPublished}
                                                                showCollectionMenu={showCollectionMenu}
                                                                lessonName={lesson.lesson_name}
                                                                isSNIview={false}
                                                            />
                                                            <BookSections
                                                                key={lesson.lesson_id}
                                                                renderedSections={this.props.lessonData.renderedSections}
                                                                chapterId={lesson.chapter_id}
                                                                sectionTypes={SECTION_TYPES_VIDEO}
                                                                published={isPublished}
                                                                showCollectionMenu={showCollectionMenu}
                                                                lessonName={lesson.lesson_name}
                                                                isSNIview={false}
                                                            />
                                                        </Toggle>
                                                    </div>

                                                    : null
                                            }


                                        </>
                                        :
                                        this.props.viewMode == ViewMode.SIMPLE
                                            ?
                                            <>
                                                <BookSections
                                                    key={lesson.lesson_id}
                                                    renderedSections={renderedSections}
                                                    chapterId={lesson.chapter_id}
                                                    published={isPublished}
                                                    showCollectionMenu={false}
                                                    lessonName={lesson.lesson_name}
                                                    isSNIview={true}
                                                />
                                                {/* <div className="section-slider" style={{display: "flex", position: "absolute", bottom: 0, left: 0, right: 0, zIndex: 1000}}>
                                                    <div className="prev-section"></div>
                                                    <div className="next-section"></div>  
                                                    <div className=""></div> 
                                                    <div className="thumb-container">
                                                    {
                                                        this.getNonRevealSections().map(s=>
                                                            <div className="section-thumb">
                                                            {s.name}
                                                            </div>
                                                        )                                                    
                                                    }
                                                    </div>                                                                                                   
                                                </div> */}
                                                <Dialog width={1024} open={this.state.showTeacherGuide} title={__("Tanári segédanyagok")} onClose={() => this.setState({ showTeacherGuide: false })}>
                                                    {
                                                        teacherGuideSections.length > 0
                                                            ?
                                                            <>
                                                                {

                                                                    teacherGuideSections.map((section) => {
                                                                        var myRegexp = /data-section-type=\"([\w.]+)\"/gm;
                                                                        var match = myRegexp.exec(section.html);

                                                                        var dataSectionType = match ? match[1] : "text";

                                                                        var fileSrc;
                                                                        var ext;
                                                                        if (match && match[1] == "file") {
                                                                            var fileRegexp = /data-file=\"([\w\W"][^\"]+)\"/gm;

                                                                            var fileMatch = fileRegexp.exec(section.html);
                                                                            fileSrc = fileMatch ? fileMatch[1]!.toLowerCase() : "";
                                                                            ext = fileSrc.split('.')[1];
                                                                        }
                                                                        if (ext == "pdf") {
                                                                            dataSectionType = "pdf";
                                                                        }
                                                                        else if (ext == "jpg" || ext == "png") {
                                                                            dataSectionType = "image";
                                                                        }
                                                                        else if (ext == "wav" || ext == "mp3") {
                                                                            dataSectionType = "audio";
                                                                        }
                                                                        else if (ext == "mp4" || ext == "avi" || ext == "wmv") {
                                                                            dataSectionType = "video";
                                                                        }
                                                                        else if (section.blockTemplateName == "Okosfeladat" || section.blockTemplateName == "Okosfeladatsor") {
                                                                            dataSectionType = "exercise";
                                                                        }

                                                                        return <a onClick={() => this.setState({ guideSectionId: section.id, openGuide: true })}>
                                                                            <h3 className={"section-type-" + dataSectionType}>
                                                                                {section.title}
                                                                            </h3>
                                                                        </a>
                                                                    })
                                                                }
                                                            </>
                                                            :
                                                            <p>{__("Nincs elérhető segédlet!")}</p>
                                                    }
                                                </Dialog>

                                                {/* {
                                                    console.log(this.state.openGuide)
                                                }
                                                {
                                                    console.log(guideRenderedSection)
                                                } */}

                                                {

                                                    this.state.openGuide && guideRenderedSection
                                                        ?
                                                        <Dialog width={1024} open={this.state.openGuide} title={guideRenderedSection!.blockTemplateName} onClose={() => this.setState({ openGuide: false })}>
                                                            <BookSections
                                                                key={lesson.lesson_id}
                                                                renderedSections={[guideRenderedSection!]}
                                                                chapterId={lesson.chapter_id}
                                                                published={isPublished}
                                                                showCollectionMenu={false}
                                                                lessonName={lesson.lesson_name}
                                                                isSNIview={false}
                                                            />
                                                        </Dialog>
                                                        :
                                                        null
                                                }

                                            </>
                                            :
                                            <BookSections
                                                key={lesson.lesson_id}
                                                renderedSections={this.props.lessonData.renderedSections}
                                                chapterId={lesson.chapter_id}
                                                sectionTypes={this.state.sectionTypes}
                                                published={isPublished}
                                                showCollectionMenu={showCollectionMenu}
                                                lessonName={lesson.lesson_name}
                                                isSNIview={false}
                                            />
                                }
                            </BookAnnotations>
                        </div>
                        :
                        <div key="loader" style={{ minHeight: "100vh" }}>
                            <BubbleLoader />
                        </div>
                }

                {(lesson.lesson_uri_segment == __("tartalomjegyzek") && (!isPublished || config.mainServer.showBooks)) ? <ContentsList
                    book={this.props.bookStructure}
                    bookPath={this.props.bookPath}
                    chapterLessons={chapterLessons}
                /> : ""}
            </div>
            {

                this.props.viewMode == ViewMode.NORMAL ?
                    <>
                        <BookFooter uriSegment={footerSrc}
                            bookpath={this.props.bookPath}
                            suggestBooksInGradeId={this.props.bookStructure.library_id === LIBRARY_OFI_OFFICIAL_ID ? this.props.bookStructure.grade_id : undefined}
                        />
                        {pageConfig.footer}
                    </>
                    : null
            }

            {
                (!isPublished || config.mainServer.showBooks)
                    ?
                    <BookToolBar
                        annotationMode={this.state.annotationMode}
                        viewMode={this.props.viewMode || ViewMode.NORMAL}
                        onChange={(annotationMode) => this.setState({ annotationMode })}
                        onViewModeChange={(viewMode) => this.handleViewChange(viewMode)}
                        isMoveable={false}
                        isSNI={this.props.bookStructure.is_sni}
                    />
                    : null
            }

            {
                this.props.viewMode != ViewMode.SIMPLE
                    ?
                    <ScrollTop viewMode={this.props.viewMode} />
                    : null

            }

        </>
    }
}


type BookHeaderProps = {
    bookPath: string,
    bookUriSegment: string,
    bookName: string,
    bookSlogan: string,
    lesson: ChapterJoinLesson,
    chapters: any,
    headerFileNames?: string[],
    viewMode?: ViewMode,
    libraryId: number,
    bookId: number,
}

type BookHeaderState = {
    backgroundStyle: any;
    chapterHoverId?: number;
}

export class BookHeader extends React.Component<BookHeaderProps, BookHeaderState> {

    constructor(props: BookHeaderProps) {
        super(props);

        this.state = {
            backgroundStyle: {}
        }
    }

    mobileMenuClick() {
        $('.main-menu-bar').toggleClass('open');
        $('.main-menu-wrapper').toggleClass('open');
        $('body').css('overflow', 'hidden');

        //SNI hamburger ikon
        if (this.props.viewMode == ViewMode.SIMPLE) {
            $('.main-menu-bar').toggleClass('show');
        }

    }

    mobileBookMenuClick() {
        $('.book-menu').toggleClass('show');
        ($('.menu.accordion-menu') as any).foundation('down', $('#app-menu'));
        $('.top-bar-right .nav-icon').addClass('open');
    }

    componentDidMount() {
        var startPosX = 0;
        var endPosX = 0;
        var moveLeft = true;

        document.addEventListener('touchstart', function (e) {
            var touch = e.touches[0];
            moveLeft = true;
            startPosX = touch.pageX;
            endPosX = touch.pageX;
        }, false);

        document.addEventListener('touchmove', function (e) {
            var touch = e.touches[0];
            endPosX = touch.pageX;
            if (startPosX <= touch.pageX) {
                moveLeft = false;
            }
        }, false);

        document.addEventListener('touchend', function (e) {
            var touch = e.touches[0];
            if (moveLeft && (document.body.clientWidth * 0.33) < startPosX - endPosX) {
                $('.main-menu-bar').removeClass('open');
                $('.main-menu-wrapper').removeClass('open');
                $('body').css('overflow', 'auto');
            }
        }, false);

        this.preloadBackground();
        this.setTitleSize();

    }

    private getImageUrl() {
        const headerFileNameJPG = this.props.lesson.lesson_uri_segment + ".jpg";
        const headerFileNamePNG = this.props.lesson.lesson_uri_segment + ".png";
        var url = undefined;
        if (this.props.headerFileNames && this.props.headerFileNames.includes(headerFileNamePNG)) {
            url = this.props.bookPath + 'img/HEADER/' + headerFileNamePNG;
        } else if (this.props.headerFileNames && this.props.headerFileNames.includes(headerFileNameJPG)) {
            url = this.props.bookPath + 'img/HEADER/' + headerFileNameJPG;
        } else if (this.props.headerFileNames && this.props.headerFileNames.includes(HEADER_FILENAME_DEFAULT)) {
            url = this.props.bookPath + 'img/HEADER/' + HEADER_FILENAME_DEFAULT;
        }
        return url;
    }

    setTitleSize() {
        $(".replace-lesson-name").css({ fontSize: "1.1em" });
        $(".book-title").css({ fontSize: "1.5em" });
        if ($(".book-title").text().length > 80) {
            (jQuery(".book-title") as any).fitText(2.8);
        } else if ($(".book-title").text().length > 65) {
            (jQuery(".book-title") as any).fitText(2.5);
        }
        else if ($(".book-title").text().length > 50) {
            (jQuery(".book-title") as any).fitText(2.3);
        }

        if (window.innerWidth > 1024 && this.props.viewMode != ViewMode.SIMPLE) {
            if ($(".replace-lesson-name").text().length > 80) {
                (jQuery(".replace-lesson-name") as any).fitText(3.7);
            } else if ($(".replace-lesson-name").text().length > 65) {
                (jQuery(".replace-lesson-name") as any).fitText(3.4);
            } else if ($(".replace-lesson-name").text().length > 50) {
                (jQuery(".replace-lesson-name") as any).fitText(3.7);
            }
        }
    }

    componentDidUpdate(prevProps: BookHeaderProps) {

        if (prevProps.lesson.lesson_uri_segment != this.props.lesson.lesson_uri_segment || prevProps.viewMode != this.props.viewMode) {
            this.preloadBackground();
            this.setTitleSize();
        }
    }

    /**
     * Háttérkép betöltése, először letöltjük, és miután lejött, kicseréljük a képet
     */
    private async preloadBackground() {

        if (this.props.viewMode != ViewMode.NORMAL) {

            this.setState({
                backgroundStyle: { height: "auto" }
            });
            return;
        }

        const uriToLoad = this.props.lesson.lesson_uri_segment;

        const url = this.getImageUrl();
        if (url) {
            const backgroundStyle = {
                backgroundImage: `url('${url}')`,
                backgroundPosition: 'top center',
                backgroundSize: 'cover',
                repeat: 'no-repeat'
            };

            var image = new Image();
            image.onload = async () => {
                if (uriToLoad == this.props.lesson.lesson_uri_segment) {
                    this.setState({ backgroundStyle });
                }
                image.remove();
            };
            image.src = url;

            if (!image.complete) {
                await timeoutAsync(300); // Egy kis idő után mindenképpen kicseréljük (még ha üres is), különben a felhasználó azt hiszi, hogy elromlott a fejléc
            }

            if (uriToLoad == this.props.lesson.lesson_uri_segment) {
                this.setState({ backgroundStyle });
            }

        } else {
            this.setState({
                backgroundStyle: { height: "auto" }
            });
        }
    }

    render() {

        const isPublished = this.props.bookPath.startsWith(PATH_PUBLISHED_BOOK);
        const ofiOfficial = this.props.libraryId == LIBRARY_OFI_OFFICIAL_ID;

        let chapterName = this.props.lesson.chapter_name;

        if (this.state.chapterHoverId !== undefined) {
            const chapter = this.props.chapters.find((c: any) => c.id == this.state.chapterHoverId);
            if (chapter) {
                chapterName = chapter.name;
            }
        }

        return <header id="header" className={"header " + ((this.props.viewMode != ViewMode.NORMAL) ? "simple" : "")} style={this.state.backgroundStyle}>
            <div className="row">
                <div className="top-header" style={{ position: ((this.props.viewMode != ViewMode.NORMAL) ? "relative" : "absolute") }}>
                    <div className="row align-middle">
                        <div className="row align-middle title-column">
                            <span className="mobile-menu-icon" onClick={this.mobileMenuClick.bind(this)}><i className="fa fa-bars"></i></span>

                            <Link to={this.props.bookPath} className="book-logo" title={__("Vissza a könyv kezdőoldalára")}>

                                <img alt="" className="replace-uri-segment" data-template-replace="true" src={`/book/${this.props.bookUriSegment}/img/HEADER/logo.svg`} />
                                <div>
                                    <span className="replace-book-name" data-template-replace="true">
                                        {this.props.bookName}
                                    </span>

                                </div>
                            </Link>
                            <div className="title-cell hide-for-small-only">
                                <h1 className="book-title replace-chapter-name" data-template-replace="true">
                                    {chapterName}
                                    {
                                        ofiOfficial && !isPublished
                                            ?
                                            <><br /><Link to={PATH_EDIT_BOOK_SECTIONS + "/" + this.props.bookId + "/" + this.props.lesson.lesson_id}><span style={{ backgroundColor: "black", color: "yellow" }}>{__("Szerkesztés alatti verzió")}</span></Link></>
                                            :
                                            null
                                    }
                                </h1>
                            </div>
                            <div className="chapter-menu" id="chapter-menu" aria-hidden="true">
                                {
                                    this.props.viewMode != ViewMode.SIMPLE && this.props.viewMode != ViewMode.PAGED ?
                                        <ChapterMenu
                                            bookPath={this.props.bookPath}
                                            chapter={{ id: this.props.lesson.chapter_id }}
                                            chapterLessons={this.props.chapters}
                                            onChapterHover={(chapterHoverIndex) => this.setState({ chapterHoverId: chapterHoverIndex })}
                                        />
                                        : null
                                }
                            </div>
                            <span className="mobile-book-menu-icon" onClick={this.mobileBookMenuClick}><i className="fa fa-book"></i></span>
                        </div>
                        {/* <span className="nav-icon btn-mobile-menu mobile"> <span></span> <span></span> <span></span> <span></span> <span></span> <span></span> </span> */}
                    </div>
                </div>
            </div>

            <HeaderLegend
                chapterLessons={this.props.chapters}
                lesson={{ id: this.props.lesson.lesson_id, headno: this.props.lesson.lesson_headno }}
                chapter={{ headno: this.props.lesson.chapter_headno }}
                book={{ uri_segment: this.props.bookUriSegment }}
            />
        </header>
    }
}

type TimeLineProps = {
    bookUriSegment: string,
    lesson: ChapterJoinLesson,
    chapters: any
}

class TimeLine extends React.Component<TimeLineProps, {}> {
    render() {
        return <HeaderProgressBar
            key={this.props.lesson.lesson_id}
            chapterLessons={this.props.chapters}
            lesson={{ id: this.props.lesson.lesson_id, headno: this.props.lesson.lesson_headno, lesson_uri_segment: this.props.lesson.lesson_uri_segment }}
            chapter={{ headno: this.props.lesson.chapter_headno }}
            book={{ uri_segment: this.props.bookUriSegment }}
        />
    }
}

type BookStickyMenuProps = {
    prevUrl?: string,
    nextUrl?: string,
    lessonName: string,
    bookStructure: BookStructure,
    bookPath: string,
    sectionNames: { id: number, name: string, blockTemplateName: string }[],
    chapterLessons: any,
    supplementaryMaterials: ISupplementaryMaterialRecord[],
    reveals: { id: string, name: string }[],
    lessonUriSegment: string,
    viewMode?: ViewMode,
    onSectionTypeChange?: (SectionType: SectionType[]) => void,
    sectionTypes?: SectionType[],
    onLessonChange?: () => void,
    isSticky?: boolean,
    showTeacherGuide?: () => void,
    sectionIndex?: number
}

export class BookStickyMenu extends React.Component<BookStickyMenuProps, { isHidden: boolean }> {

    constructor(props: BookStickyMenuProps) {
        super(props);

        this.state = {
            isHidden: false
        }
    }

    protected oldStickyElement?: HTMLElement;

    componentDidMount() {
        this.doFoundation();

        if (this.props.viewMode == ViewMode.SIMPLE) {
            setTimeout(() => {
                this.slideTopBar();
            }, 2000);
        }
    }

    componentDidUpdate(prevProps: BookStickyMenuProps) {
        this.doFoundation();

        /*lapozáskor a stickymenu ne mocorogjon*/
        // if (this.props.viewMode == ViewMode.SIMPLE && (this.props.lessonUriSegment != prevProps.lessonUriSegment || this.props.sectionIndex != prevProps.sectionIndex)) {
        //     if (this.state.isHidden) {
        //         this.slideTopBar(false);
        //         setTimeout(() => {
        //             this.slideTopBar();
        //         }, 2000);
        //     }
        // }
    }

    doFoundation() {
        if (this.refs.sticky && this.refs.sticky != this.oldStickyElement) {
            ($(this.refs.sticky) as any).foundation();

            // Ez nélkül az újrarenderelt sticky menü nem stickyzik
            // https://github.com/zurb/foundation-sites/issues?utf8=%E2%9C%93&q=sticky+onload
            // https://github.com/zurb/foundation-sites/issues/8394#issuecomment-197916114
            // https://github.com/zurb/foundation-sites/pull/11077
            //$(window).trigger('load');
            try {
                $(window).trigger('load');
                this.oldStickyElement = this.refs.sticky as HTMLElement;
            }
            catch{

            }

        }
        try {
            ($('[data-magellan]') as any).foundation('_destroy');
        } catch (e) {
            // no jquery, preview
        }
    }

    slideTopBar(e?: any, setHidden?: boolean) {
        this.setState({ isHidden: setHidden == undefined ? !this.state.isHidden : setHidden }, () => {
            if (this.state.isHidden) {
                document.getElementById("mainMenuBar")!.classList.add("slide");
                document.getElementById("content")!.classList.add("center");
            }
            else {
                document.getElementById("mainMenuBar")!.classList.remove("slide");
                document.getElementById("content")!.classList.remove("center");
            }
        });

    }

    render() {
        return <div ref="sticky" className="header-navigation hide-for-print" data-sticky-container="">
            <div className={"title-bar " + (this.state.isHidden ? "is-slided " : "") + (this.props.viewMode == ViewMode.SIMPLE ? "simple" : "")} data-margin-top="0" data-options="stickTo:top; stickyOn:small;" data-top-anchor="header:bottom" {...(this.props.isSticky == false ? {} : { 'data-sticky': '' })} >
                <div className="row ">
                    <div className="top-bar">
                        <div className="title-bar-left">
                            <div className="row align-middle">
                                {
                                    this.props.viewMode == ViewMode.SIMPLE
                                        ?
                                        <>
                                            <button className="show-guides" onClick={() => this.props.showTeacherGuide!()}></button>
                                            <div className="ksni-book-title">{this.props.bookStructure.name}</div>
                                        </>
                                        :
                                        null
                                }

                                <div className="columns auto">
                                    {this.props.viewMode != ViewMode.CONTENT ?
                                        <ScreenReaderMenu
                                            book={this.props.bookStructure}
                                            chapterLessons={this.props.chapterLessons}
                                        /> : null
                                    }
                                    <div className="title">
                                        {
                                            this.props.prevUrl && (this.props.viewMode != ViewMode.SIMPLE && this.props.viewMode != ViewMode.PAGED)
                                                ?
                                                <Link className="prev" to={this.props.prevUrl} title={__("Előző leckére ugrás")}></Link>
                                                :
                                                null
                                        }
                                        <h2 className="replace-lesson-name" data-template-replace="true" aria-hidden="true">
                                            {this.props.lessonName}
                                        </h2>

                                        {
                                            this.props.nextUrl && (this.props.viewMode != ViewMode.SIMPLE && this.props.viewMode != ViewMode.PAGED)
                                                ?
                                                <Link className="next" to={this.props.nextUrl} title={__("Következő leckére ugrás")}></Link>
                                                :
                                                null
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>

                        {
                            this.props.viewMode != ViewMode.CONTENT ?
                                <div>
                                    <nav id="book-menu" className="top-bar-right">

                                        <BookMenu
                                            book={this.props.bookStructure}
                                            sections={this.props.sectionNames}
                                            chapterLessons={this.props.chapterLessons}
                                            supplementaryMaterials={this.props.supplementaryMaterials}
                                            reveals={this.props.reveals}
                                            bookPath={this.props.bookPath}
                                            lessonUriSegment={this.props.lessonUriSegment}
                                            viewMode={this.props.viewMode}
                                            onSectionTypeChange={this.props.onSectionTypeChange}
                                            sectionTypes={this.props.sectionTypes}
                                            onLessonChange={this.props.onLessonChange}
                                        />
                                    </nav>
                                </div>
                                : null
                        }

                    </div>
                </div>
                {
                    this.props.viewMode == ViewMode.SIMPLE
                        ?
                        <button className="slide-top-bar" onClick={this.slideTopBar.bind(this)}>
                        </button>
                        :
                        null
                }
            </div>


        </div>
    }
}

const fontSizeDefault = 100;
let fontSize = fontSizeDefault;
export class BookToolBar extends React.Component<{ annotationMode: AnnotationMode, viewMode: ViewMode, onChange: (annotationMode: AnnotationMode, isMoveable: boolean) => void, onViewModeChange: (viewMode: ViewMode) => void, isMoveable: boolean, isSNI: boolean }, { open: boolean }> {

    private div: HTMLDivElement;

    constructor(props: any) {

        super(props);
        this.state = {
            open: true,

        };

        this.div = document.createElement("div");

        const body = document.getElementsByTagName("body")[0];
        body.appendChild(this.div);

    }

    handleOptionChange(annotationMode: AnnotationMode, isMoveable: boolean) {

        this.props.onChange(annotationMode, isMoveable);
        if (annotationMode == AnnotationMode.USER_MARKING) {
            document.body.style.cursor = "url('/img/mouse-highlighter.png'), auto";
        }
        else {
            document.body.style.cursor = "";
        }


    }

    handleViewChange(viewMode: ViewMode) {

        this.props.onViewModeChange(viewMode);
    }
    fontUp() {
        fontSize += 10;
        document.getElementById('bookMain')!.style.fontSize = fontSize + "%";
    }

    fontDown() {
        fontSize -= 10;
        document.getElementById('bookMain')!.style.fontSize = fontSize + "%";
    }
    fontOriginal() {
        this.props.isSNI ? fontSize = 120 : fontSize = 100;
        document.getElementById('bookMain')!.style.fontSize = fontSize + "%";
    }



    render() {
        return <div className="fixed-wrapper" aria-hidden="true">
            <div className="fixed toolbar" style={{ fontSize: "1rem" }}>
                <ul className="tools" data-accordion-menu="">
                    {this.state.open && me ?
                        <li>
                            <div className="tab-menu">
                                {
                                    this.props.viewMode == ViewMode.PRESENTATION ?
                                        <div className="tab" title={__("Mozgatás")}>
                                            <label style={{ color: "white", fontFamily: "Exo", fontWeight: 800 }}>
                                                <input value={"MOVE"} checked={this.props.annotationMode == AnnotationMode.NONE && this.props.isMoveable} type="radio" onChange={this.handleOptionChange.bind(this, AnnotationMode.NONE, true)} /><i className="fa fa-arrows-alt"></i></label>
                                        </div>
                                        : null
                                }

                                <div className="tab" title={__("A már meglévő jegyzetek látszódnak")}>
                                    <label style={{ color: "white", fontFamily: "Exo", fontWeight: 800 }}>
                                        <input value={AnnotationMode.NONE} checked={this.props.annotationMode == AnnotationMode.NONE && !this.props.isMoveable} type="radio" onChange={this.handleOptionChange.bind(this, AnnotationMode.NONE, false)} /><i className="fa fa-mouse-pointer"></i></label>
                                </div>
                                {
                                    hasGroup(me, Groups.Lector) || hasGroup(me, Groups.Developer)
                                        ?
                                        <div className="tab" title={__("A lektori jegyzetek látszódnak")}>
                                            <label style={{ color: "white", fontFamily: "Exo", fontWeight: 800 }}>
                                                <input value={AnnotationMode.LEKTOR_COMMENTING} checked={this.props.annotationMode == AnnotationMode.LEKTOR_COMMENTING} type="radio" onChange={this.handleOptionChange.bind(this, AnnotationMode.LEKTOR_COMMENTING, false)} /><i className="fa fa-eye"></i></label>
                                        </div>
                                        :
                                        null
                                }
                                <div className="tab" title={__("Szöveg kijelölésével jegyzet hozható létre")}>
                                    <label style={{ color: "white", fontFamily: "Exo", fontWeight: 800 }}>
                                        <input value={AnnotationMode.USER_COMMENTING} checked={this.props.annotationMode == AnnotationMode.USER_COMMENTING} type="radio" onChange={this.handleOptionChange.bind(this, AnnotationMode.USER_COMMENTING, false)} /><i className="fa fa-comment"></i></label>
                                </div>
                                <div className="tab" title={__("Szöveg kijelölése kiemeli azt")}><label style={{ color: "white", fontFamily: "Exo", fontWeight: 800 }}>
                                    <input value={AnnotationMode.USER_MARKING} checked={this.props.annotationMode == AnnotationMode.USER_MARKING} type="radio" onChange={this.handleOptionChange.bind(this, AnnotationMode.USER_MARKING, false)} /><i className="fa fa-underline"></i></label>
                                </div>
                            </div>
                        </li>
                        : null}
                    {this.state.open ?
                        <>
                            {
                                this.props.isSNI ? null
                                    :
                                    <li>
                                        <div className="tab-menu">

                                            {this.props.isSNI
                                                ?
                                                <>
                                                    <div className="tab" title={__("Lapozható nézet")}>
                                                        <label style={{ color: "white", fontFamily: "Exo", fontWeight: 800 }}>
                                                            <input checked={this.props.viewMode == ViewMode.PAGED} type="checkbox" onChange={this.handleViewChange.bind(this, ViewMode.PAGED)} /><i className="fas fa-book-open"></i></label>
                                                    </div>
                                                    <div className="tab" title={__("Egyszerű nézet")}>
                                                        <label style={{ color: "white", fontFamily: "Exo", fontWeight: 800 }}>
                                                            <input checked={this.props.viewMode == ViewMode.SIMPLE} type="checkbox" onChange={this.handleViewChange.bind(this, ViewMode.SIMPLE)} /><i className="fa fa-file-alt"></i></label>
                                                    </div>
                                                </>
                                                :
                                                <>
                                                    {
                                                        me && hasGroup(me, Groups.Developer)
                                                            ?
                                                            <div className="tab" title={__("Egyszerű nézet")}>
                                                                <label style={{ color: "white", fontFamily: "Exo", fontWeight: 800 }}>
                                                                    <input checked={this.props.viewMode == ViewMode.SIMPLE} type="checkbox" onChange={this.handleViewChange.bind(this, ViewMode.SIMPLE)} /><i className="fa fa-file-alt"></i></label>
                                                            </div>
                                                            : null
                                                    }

                                                    <div className="tab" title={__("Normál nézet")}>
                                                        <label style={{ color: "white", fontFamily: "Exo", fontWeight: 800 }}>
                                                            <input checked={this.props.viewMode == ViewMode.NORMAL} type="checkbox" onChange={this.handleViewChange.bind(this, ViewMode.NORMAL)} /><i className="fas fa-laptop"></i></label>
                                                    </div>
                                                    <div className="tab" title={__("Prezentációs nézet")}>
                                                        <label style={{ color: "white", fontFamily: "Exo", fontWeight: 800 }}>
                                                            <input checked={this.props.viewMode == ViewMode.PRESENTATION} type="checkbox" onChange={this.handleViewChange.bind(this, ViewMode.PRESENTATION)} /><i className="fa fa-tv"></i></label>
                                                    </div>

                                                    <div className="tab" title={__("Tankönyv nézet")}>
                                                        <label style={{ color: "white", fontFamily: "Exo", fontWeight: 800 }}>
                                                            <input checked={this.props.viewMode == ViewMode.BOOK} type="checkbox" onChange={this.handleViewChange.bind(this, ViewMode.BOOK)} /><i className="fas fa-book"></i></label>
                                                    </div>
                                                    {/*<div className="tab" title="Rendezett nézet">
                                        <label style={{color:"white",fontFamily:"Exo",fontWeight:800}}>
                                        <input checked={this.props.viewMode == ViewMode.ORDERED} type="checkbox" onChange={this.handleViewChange.bind(this, ViewMode.ORDERED )}/><i className="fas fa-sort-amount-down"></i></label>
                                    </div>*/}
                                                </>
                                            }


                                            {/* <div className="tab" title="Tartalom nézet">
                                        <label style={{color:"white",fontFamily:"Exo",fontWeight:800}}>
                                        <input checked={this.props.viewMode == ViewMode.CONTENT} type="checkbox" onChange={this.handleViewChange.bind(this, ViewMode.CONTENT )}/><i className="fas fa-align-justify"></i></label>
                                    </div> */}
                                        </div>
                                    </li>
                            }
                            <li>
                                <div className="tab-menu">
                                    <div className="tab" title={__("Betűméret visszaállítása")}>
                                        <i onClick={() => this.fontOriginal()} title={__("Betűméret visszaállítása")} className="fas fa-sync-alt"></i>
                                    </div>
                                    <div className="tab" title={__("Betűméret növelése")}>
                                        <span title={__("Betűméret csökkentése")} className="control" onClick={() => this.fontDown()}>A-</span>
                                    </div>
                                    <div className="tab" title={__("Betűméret csökkentése")}>
                                        <span title={__("Betűméret növelése")} className="control" onClick={() => this.fontUp()}>A+</span>
                                    </div>
                                </div>
                            </li>
                        </>
                        : null

                    }

                </ul>

                <div className="toolbar-handle"><i className="fa fa-angle-right"></i></div>
            </div>
            {/* { this.state.open ? <div ref="BookCanvasBar" className="toolbox"><BookCanvasToolBar /></div> : null } */}
        </div>;
        //    return <div className="fixed-wrapper">
        //             <div className="fixed toolbar" style={{fontSize: "1rem"}}>
        //                 <ul className="title-bar vertical menu accordion-menu" data-accordion-menu="">     
        //                   { this.state.open && me ?
        //                   <li>
        //                       <div className="controls">Szövegkijelölés:</div>
        //                       <div title="A már meglévő jegyzetek látszódnak">
        //                         <label style={{color:"white",fontFamily:"Exo",fontWeight:800}}>
        //                         <input value={ AnnotationMode.NONE} checked={this.props.annotationMode == AnnotationMode.NONE} type="radio" onChange={this.handleOptionChange.bind(this, AnnotationMode.NONE)}/> Nincs</label>
        //                       </div>
        //                       {
        //                             hasGroup(me, Groups.Lector) || hasGroup(me, Groups.Developer)
        //                             ?
        //                             <div title="A lektori jegyzetek látszódnak">
        //                                 <label style={{color:"white",fontFamily:"Exo",fontWeight:800}}>
        //                                 <input value={ AnnotationMode.LEKTOR_COMMENTING} checked={this.props.annotationMode == AnnotationMode.LEKTOR_COMMENTING} type="radio" onChange={this.handleOptionChange.bind(this, AnnotationMode.LEKTOR_COMMENTING)}/> Lektorálás</label>
        //                             </div>
        //                             :
        //                             null
        //                       }
        //                       <div title="Szöveg kijelölésével jegyzet hozható létre">
        //                         <label style={{color:"white",fontFamily:"Exo",fontWeight:800}}>
        //                         <input value={ AnnotationMode.USER_COMMENTING} checked={this.props.annotationMode == AnnotationMode.USER_COMMENTING} type="radio" onChange={this.handleOptionChange.bind(this, AnnotationMode.USER_COMMENTING)}/> Jegyzetelés</label>
        //                       </div>
        //                       <div title="Szöveg kijelölése kiemeli azt"><label style={{color:"white",fontFamily:"Exo",fontWeight:800}}>
        //                         <input value={ AnnotationMode.USER_MARKING} checked={this.props.annotationMode == AnnotationMode.USER_MARKING} type="radio" onChange={this.handleOptionChange.bind(this, AnnotationMode.USER_MARKING)}/> Aláhúzás</label>
        //                       </div>
        //                 </li>
        //                   : null }
        //                 {/* { this.state.open ? <BookCanvasToolBar /> : null } */}
        //                 { this.state.open ? 
        //                     <li>
        //                         <span title="Betűméret csökkentése" className="control" onClick={() => this.fontDown()}>A-</span>
        //                         <span title="Betűméret növelése" className="control" onClick={() => this.fontUp()}>A+</span>
        //                     </li> : null
        //                 }
        //                     <li onClick={() => this.setState({open:!this.state.open})}>
        //                         <a>Eszköztár<i className={`chevronpadder fa fa-chevron-${this.state.open?'up':'down'}`}></i></a>

        //                 </li>
        //             </ul>
        //         </div>
        //     </div>;

    }
}


class ScrollTop extends React.Component<{ viewMode?: ViewMode }, {}> {

    constructor(props: any) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        $(window).scroll(function () {
            if (($(window) as any).scrollTop() > 100) {
                $('.scroll-top').fadeIn();
            } else {
                $('.scroll-top').fadeOut();
            }
        });
    }

    scrollTop() {
        window.scrollTo(0, 0);
    }

    render() {
        return <div onClick={() => this.scrollTop()} aria-hidden="true" className={"scroll-top" + (this.props.viewMode == ViewMode.SIMPLE ? " simple" : "")}>
            <i className="fa fa-chevron-circle-up"></i>
        </div>;

    }
}


export class RenderSectionPage extends React.Component<BookPageProps, BookPageState> {

    private doc: HTMLDocument;

    private bookCommonCss: HTMLLinkElement;

    private bookCustomCss: HTMLLinkElement;
    constructor(props: BookPageProps) {
        super(props);

        var annotationMode = AnnotationMode.NONE;
        this.state = {
            cssLoaded: false,
            annotationMode,
            sectionTypes: SECTION_TYPES_ALL,
            currentSectionIndex: 0,
            showTeacherGuide: false,
            openGuide: false
        };
    }

    async componentDidMount() {
        this.doc = ReactDOM.findDOMNode(this)!.ownerDocument!;

        this.bookCommonCss = this.doc.createElement("link");
        this.bookCustomCss = this.doc.createElement("link");

        this.bookCommonCss.setAttribute("rel", "stylesheet");
        this.bookCommonCss.setAttribute("href", `/css/book_flex.css`);

        this.bookCustomCss.setAttribute("rel", "stylesheet");

        this.reloadCssAsync();

        if (this.props.lesson) this.doc.body.className = getClassesFromLessonUriSegment(this.props.lesson.lesson_uri_segment).join(" ");

        document.getElementById("mainMenuBar")!.style.display = "none";
    }


    componentWillUnmount() {
        this.removeBookCss();
        this.doc.body.removeAttribute("class");
        document.getElementById("mainMenuBar")!.style.display = "";
    }

    componentDidUpdate(prevProps: BookPageProps, prevState: BookPageState) {

        if (this.props.viewMode == ViewMode.SIMPLE) {
            this.stretchSection();
        }

        this.reloadCssAsync();

        const lesson = this.props.lesson;
        if (this.props.bookStructure && lesson) {
            document.title = this.props.bookStructure.name + " - " + lesson.chapter_name + " - " + lesson.lesson_name;
        }

        if (lesson) this.doc.body.className = getClassesFromLessonUriSegment(lesson.lesson_uri_segment).join(" ");
    }


    async reloadCssAsync() {
        if (!this.bookCommonCss.parentElement) {
            this.doc.head.appendChild(this.bookCommonCss);
        }

        if (!this.bookCustomCss.parentElement) {
            this.doc.head.appendChild(this.bookCustomCss);
        }

        if (!this.props.bookStructure) {
            return;
        }

        const cssHref = this.props.bookStructure.css_path;

        if (this.bookCustomCss.getAttribute("href") != cssHref) {
            this.bookCustomCss.setAttribute("href", cssHref);

            this.setState({ cssLoaded: false });

            try {
                await scriptLoadAsync(this.bookCustomCss, 200);
            } catch (e) {/*css not yet loaded, render anyway*/ }

            this.setState({ cssLoaded: true });
        }

    }

    removeBookCss() {
        this.bookCustomCss.remove();
        this.bookCommonCss.remove();
    }

    stretchSection() {

        var sectionId = this.props.sectionId;
        var section = document.getElementById('section-' + sectionId);
        var content = document.getElementById('content');

        if (section && content && !section.classList.contains('exercise')) {
            var cRatio = content.clientHeight / content.clientWidth;
            var sRatio = section.clientHeight / section.clientWidth;

            var ratio = 1;
            if (cRatio > sRatio) {
                ratio = content.clientWidth / 1280;
            }
            else {
                ratio = content.clientHeight / 640;
            }

            section.style.transform = "scale(" + ratio + ")";
        }
    }

    render() {
        if (!this.state.cssLoaded) {
            return <BubbleLoader />;
        }
        const lesson = this.props.lesson;
        if (!this.props.bookStructure || !lesson) {
            return <BubbleLoader />;
        }

        const contentClass = this.props.viewMode == ViewMode.SIMPLE ? "simple wide-display" : (this.props.viewMode == ViewMode.CONTENT ? "wide-display content-view" : "");

        const isPublished = this.props.bookPath.startsWith(PATH_PUBLISHED_BOOK);

        var renderedSections: RenderedSection[] = [];
        if (this.props.lessonData) {
            renderedSections = this.props.lessonData!.renderedSections.filter(s => s.id == Number(this.props.sectionId));
        }
        return <>

            <div id="bookMain" style={{ fontSize: (this.props.bookStructure!.is_sni ? "120%" : "100%") }}>

                {
                    this.props.lessonData
                        ?
                        <div ref="content" className={contentClass}>

                            <BookAnnotations lessonId={lesson.lesson_id} annotationMode={this.state.annotationMode} >

                                <BookSections
                                    key={lesson.lesson_id}
                                    renderedSections={renderedSections}
                                    chapterId={lesson.chapter_id}
                                    published={isPublished}
                                    showCollectionMenu={false}
                                    lessonName={lesson.lesson_name}
                                    isSNIview={true}
                                />

                            </BookAnnotations>
                        </div>
                        :
                        <div key="loader" style={{ minHeight: "100vh" }}>
                            <BubbleLoader />
                        </div>
                }
            </div>

        </>
    }
}
