import * as React from 'react';
import  { IViewMetaTableCategoryTypeRecord, ViewMetaTableCategoryTypeClassProxy, viewMetaTableCategoryTypeClassProxy } from '@src/framework/view/meta/ViewMetaTableCategoryType';
import ListView from '@framework/forms/listview';
import '@framework/crud/meta/TableCategoryTypeCrud'; // Ezt ne távolítsd el mert akkor a hülye webpack kioptimizálja!
import { __ } from '@src/translation';

class TableCategoryTypeListViewInner extends ListView<IViewMetaTableCategoryTypeRecord> {
    //protected getHeader = () : JSX.Element|null => { return <SideMenu /> }
    public getViewClassProxy() : ViewMetaTableCategoryTypeClassProxy { return viewMetaTableCategoryTypeClassProxy; }
}

export default function TableCategoryTypeListView(props: any) {
    // TODO: hozzáadni a person-os oszlopokat a view-hoz hogy látszódjon a 
    // rendes nevük!
    return <TableCategoryTypeListViewInner 
        defaultPageSize={10}
        match={props.match}        
        viewColumns={[
            {accessor: "id", maxWidth: 100},
            {accessor: "table_display_name"},
            {accessor: "category_type_title"},
            {accessor: "creation_time"},
            {accessor: "creator"},
            /*
            {accessor: "modification_time"},
            {accessor: "modifier"},
            */
            {accessor: "link", Header:__("Műveletek"), maxWidth:200},
        ]}
    />
}
