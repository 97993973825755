import * as React from 'react';
import ReactTable, { TableProps, Column, RowInfo } from 'react-table';
import 'react-table/react-table.css';
import { getReactTableLabels } from '@src/framework/i18n';
import { Link, match } from 'react-router-dom';
import { subjectCrudClassProxy } from '@crud/doc/SubjectCrud'
import CrudSelect from '@framework/forms//crudselect';
import { debounce } from 'lodash';
import { app } from '@src/index';
import { IUserInfo, hasGroup, Groups, me } from '@framework/server/Auth';
import ViewTransitionType, { IViewTransitionTypeRecord } from '@src/framework/view/wf/ViewTransitionType';
import ViewLessonplan, { IViewLessonplanRecord } from '@src/framework/view/doc/ViewLessonplan';
import { instituteTypeCrudClassProxy } from '@src/framework/crud/sys/InstituteTypeCrud';
import InstituteCrud from '@src/framework/crud/sys/InstituteCrud';
import LookupEdit from '@src/framework/forms/lookupedit';
import { dpmkProjectCrudClassProxy } from '@src/framework/crud/doc/DpmkProjectCrud';
import WfAPI from '@src/framework/wf/WfAPI';
import obtainServer from '@src/framework/server/Server';
import { IViewWfWorkflowRecord } from '@src/framework/view/wf/ViewWfWorkflow';
import { getWfStateCell } from '../../../component/wf/wf_util';
import SecUserSelector from '@src/framework/forms/SecUserSelector';
import {WF_TYPE_ORAVAZLAT_ID } from '@src/Const';
import { gradeCrudClassProxy } from '@src/framework/crud/doc/GradeCrud';
import { IListParams, TFilterDict, StringSearchKind, IListOrderBy, TSpecFilterDict } from '@src/framework/crud/Crud';
import { replaceAll } from '@src/framework/util/StringUtils';
import { confirmDialog } from '../../../component/Dialog';
import { __ } from '@src/translation';
import { PATH_LESSONPLAN_EDIT, PATH_LESSONPLAN_VIEW } from './lessonplan_routes';



var saved = {
    instituteTypeId: undefined,
    institute_om_id:undefined,
    owner_id: undefined,
    instituteId: undefined,
    grade_id: undefined,
    subject_id: undefined,
    engineId: undefined,
    stationId: undefined,
    dpmk_project_id: undefined,
    filters: [],
    sorted: [],
    pageSize: 10,
    page: 0,
}

type LessonPlanEditListProps = {
    match?: match<any>,
    onLessonPlanSelected?: (LessonPlanRecord: IViewLessonplanRecord) => void,
    instituteId?: any,
    institute_om_id?:string,
    institute_type_id?: any,
    owner_id?: any,
    dpmk_project_id?: any
}
interface ILessonPlanEditListState {

    search: string,
    keyword: string | null,
    institute_type_id: any,
    institute_om_id:"",
    instituteId: any,
    dpmk_project_id: any,
    grade_id: number | null;
    subject_id: number | null;
    owner_id: any,
    stationId: any,
    nokeywords: boolean,
    noresult: boolean,
    myLessonPlans: boolean,

    lesssonPlanRecords: IViewLessonplanRecord[],
    pageSize: number,
    page: number,
    count: number,
    sorted: any[],
    filters: any[],
    loading: boolean,

    currentUser?: IUserInfo | null,
    class?: any,
    lessonPlanTransitonTypes?: IViewTransitionTypeRecord[];
}

export default class LessonPlanEditList extends React.Component<LessonPlanEditListProps, ILessonPlanEditListState> {

    constructor(props: any) {
        super(props);

        this.state = {
            search: '',
            keyword: '',
            institute_type_id: props.instituteTypeId || saved.instituteTypeId,
            institute_om_id: props.institute_om_id ||saved.institute_om_id,
            instituteId: props.instituteId || saved.instituteId,
            grade_id: props.grade_id || saved.grade_id,
            subject_id: props.subject_id || saved.subject_id,
            owner_id: props.owner_id || saved.owner_id,
            stationId: props.stationId || saved.stationId,
            dpmk_project_id: props.dpmk_project_id || saved.dpmk_project_id,
            nokeywords: false,
            noresult: false,
            myLessonPlans: props.owner_id || saved.owner_id?true:false,

            lesssonPlanRecords: [],
            pageSize: saved.pageSize,
            page: saved.page,
            count: 0,
            sorted: saved.sorted,
            filters: saved.filters,
            loading: true,

            lessonPlanTransitonTypes: undefined
        };
    }

    componentDidMount() {
        this.reloadAsync();
        ($(document) as any).foundation();
    }

    componentDidUpdate(prevProps: LessonPlanEditListProps) {
        if (prevProps.match != this.props.match) {
            this.reloadAsync();
        }
    }

    componentWillUnmount() {
        try {
            // TODO az alábbi sor ezt a hibát dobja tankönyvben választáskor: ReferenceError: We're sorry, '_destroy' is not an available method for this element.
            ($(this.refs.deleteConfirmationModal) as any).foundation('_destroy');
            $(this.refs.deleteConfirmationModal).remove();
        } catch (e) {
            console.error(e);
        }
    }

    showOnlyMyLessonPlans() {
        return this.state.myLessonPlans;
    }

    onLessonPlanSelect(LessonPlanRecord: IViewLessonplanRecord) {
        if (this.props.onLessonPlanSelected) {
            this.props.onLessonPlanSelected(LessonPlanRecord);
        } else {
            app.showError(__("Hiba!"), __("Nem lehet óravázlatot választani!"));
        }
    }
    onMyExerciseListChanged(event: any) {
        const myLessonPlans = event.target.checked;
        this.setState({ myLessonPlans: myLessonPlans }, this.reloadAsync);
    }

    async reloadAsync() {
        try {
            this.setState({
                loading: true
            })

            let lessonPlanTransitonTypes: IViewTransitionTypeRecord[] | undefined = this.state.lessonPlanTransitonTypes;
            if (lessonPlanTransitonTypes === undefined) {
                lessonPlanTransitonTypes = await ViewTransitionType.list({
                    filter: { type_id: WF_TYPE_ORAVAZLAT_ID, is_active: true }
                });
            }

            var filter: TFilterDict = {is_active: true };

            if (this.state.institute_type_id) {
                filter.institute_type_id = Number(this.state.institute_type_id);
            }
            if(this.state.institute_om_id)
            {
                filter.institute_om_identifier = this.state.institute_om_id;
            }
            
            if (this.state.instituteId) {
                filter.institute_id = Number(this.state.instituteId);
            }
            if (this.state.myLessonPlans && me) {
                saved.owner_id=me.id as any;
                filter.owner_id = Number(me!.id);
            } else if (this.state.owner_id) {            
                filter.owner_id = Number(this.state.owner_id);
            }
            if (this.state.dpmk_project_id) {
                filter.dpmk_project_id = Number(this.state.dpmk_project_id);
            }
            if (this.state.search) {
                filter.name = { kind: StringSearchKind.Contains, case_sensitive: false, expr: this.state.search };
            }
            if (this.state.keyword && this.state.keyword.length > 0 && this.state.keyword != "") {
                filter.keywords = { kind: StringSearchKind.Contains, case_sensitive: false, expr: this.state.keyword };
            }
            if (this.showOnlyMyLessonPlans() && me) {
                filter.owner_id = Number(me.id);
            }

            for (const userFilter of this.state.filters) {
                filter[userFilter.id] = { kind: StringSearchKind.Contains, case_sensitive: false, expr: userFilter.value }
            }

            let orderBy: undefined | string | IListOrderBy[] = undefined;
            if (this.state.sorted.length > 0) {
                const userOrder = this.state.sorted[0];

                if (userOrder.desc) {
                    orderBy = [{ name: userOrder.id, desc: true }];
                } else {
                    orderBy = userOrder.id
                }
            }

            if (!orderBy) { orderBy = [{ name: "creation_time", desc: true }]; }

            let spec: TSpecFilterDict = {};
            if (this.state.grade_id) {
                spec.grade_id = this.state.grade_id;
            }
            if (this.state.subject_id) {
                spec.subject_id = this.state.subject_id;
            }


            let listParameters: IListParams = {
                filter: filter,
                spec: spec,
                order_by: orderBy,
                limit: this.state.pageSize,
                offset: this.state.page * this.state.pageSize
            };

            let lesssonPlanRecords = await ViewLessonplan.list(listParameters);

            for (var lessonPlanRecord of lesssonPlanRecords) {

                const topic = document.createElement("div");
                topic.innerHTML = lessonPlanRecord.topic || "";

                lessonPlanRecord["topic"] = topic.innerText;
                lessonPlanRecord["project_title"] = lessonPlanRecord.project_title;
                lessonPlanRecord["institute_name"] = lessonPlanRecord.institute_name;
                lessonPlanRecord["institute_site"] = lessonPlanRecord.institute_site_name;
                lessonPlanRecord["owner_name"] = lessonPlanRecord.owner_name;
                lessonPlanRecord["literacy_field_name"] = lessonPlanRecord.literacy_field_name;
                lessonPlanRecord["institute_type_title"] = lessonPlanRecord.institute_type_title;
                lessonPlanRecord["institute_om_identifier"] = lessonPlanRecord.institute_om_identifier;
                var editBtn = null;
                // var showBtn = null;
                var delBtn = null;

                if (this.props.onLessonPlanSelected) {
                    editBtn = <button className="button small" onClick={this.onLessonPlanSelect.bind(this, lessonPlanRecord)}><i className="fa fa-plus"></i></button>;
                }
                else {

                    if (!this.props.onLessonPlanSelected) {
                        editBtn = <Link className="button small" title={__("Szerkesztés")} to={PATH_LESSONPLAN_EDIT + `/${lessonPlanRecord.id}/`}><i className="fa fa-edit"></i></Link>;

                        if (this.canDelete(lessonPlanRecord)) {
                            delBtn = <button className="button small alert" title={__("Törlés")} onClick={this.deleteLessonPlan.bind(this, lessonPlanRecord)}><i className="fa fa-trash"></i></button>;
                        }
                    }
                }         
                lessonPlanRecord["link"] = <div>{editBtn} {delBtn} </div>;
            }

            this.setState({
                lesssonPlanRecords,
                loading: false,
                lessonPlanTransitonTypes
            });

            var count = await ViewLessonplan.count(listParameters);
            this.setState({ count: count });
        }
        catch (error) {
            app.showErrorFromJsonResult(error);
        }
    }

    onFetchData(tablestate: any) {
        if (this.state.filters != tablestate.filtered) {
            this.debouncedSetTableState(tablestate);
        } else {
            this.setTableState(tablestate);
        }
    }

    debouncedSetTableState = debounce((tablestate) => this.setTableState(tablestate), 300);

    private setTableState(tablestate: any) {

        saved.page = tablestate.page;
        saved.pageSize = tablestate.pageSize;
        saved.sorted = tablestate.sorted;
        saved.filters = tablestate.filtered;

        this.setState({
            page: tablestate.page,
            pageSize: tablestate.pageSize,
            sorted: tablestate.sorted,
            filters: tablestate.filtered,
        }, this.reloadAsync);
    }

    private onInstituteTypeSelect = (sender: CrudSelect, institute_type_id: number | null): void => {
        saved.instituteTypeId = institute_type_id as any;
        this.setState({ institute_type_id }, this.reloadAsync);
    }
 
    private onOwnerChange(value: any) {
        if (!value) {
            value = undefined;
        }
        let secuser_id = value;
        saved.owner_id = secuser_id;
        this.setState({ owner_id: secuser_id }, this.reloadAsync);
    }
    
    private onInstituteOmIdentifierValueChange(event: any) {
        let institute_om_id = event.target.value;
        saved.institute_om_id = institute_om_id;
        this.setState({ institute_om_id });

    }
    private onOM_IDKeyPress(e: any) {
        if (e.key === 'Enter') {
            this.reloadAsync();
        }
    }
    private onInstituteChange(value: any) {

        let institute_id = value;
        saved.instituteId = institute_id;
        this.setState({ instituteId: institute_id }, this.reloadAsync);

    }
    private onProjectIdChange(value: any) {

        let dpmk_project_id = value;
        saved.dpmk_project_id = dpmk_project_id;
        this.setState({ dpmk_project_id: dpmk_project_id }, this.reloadAsync);
    }

    private onGradeSelect = (sender: CrudSelect, grade_id: number | null): void => {
        if (grade_id) {
            grade_id = parseInt(grade_id.toString());
        }
        saved.grade_id = grade_id as any;
        this.setState({ grade_id }, this.reloadAsync);
    }

    private onSubjectSelect = (sender: CrudSelect, subject_id: number | null): void => {
        if (subject_id) {
            subject_id = parseInt(subject_id.toString());
        }
        saved.subject_id = subject_id as any;
        this.setState({ subject_id }, this.reloadAsync);
    }

    private canDelete(lessonPlan: IViewLessonplanRecord) {
        let canDelete = false;
        if (me && lessonPlan && lessonPlan.creation_user_id == me.id) { 
            canDelete = true;
        }
        return canDelete;
    }

    deleteLessonPlan = async (lessonPlan: IViewLessonplanRecord) => {
        if (!await confirmDialog(__("Törlés"), __("Biztosan törölni szeretné az alábbi kiválasztott elemet?"), __("Törlés"))) {
            return;
        }

        let canDelete = this.canDelete(lessonPlan);

        var selectedLessonPlan = await ViewLessonplan.load(lessonPlan.id!);

        // Kikeressük a törlő transition type-okat.
        let possibleTransitions = [];
        if (this.state.lessonPlanTransitonTypes) {
            let tt: IViewTransitionTypeRecord[] = this.state.lessonPlanTransitonTypes;

            for (let i = 0; i < tt.length; i++) {
                let transitionType = tt[i];

                if (transitionType.src_station_id == selectedLessonPlan!.record.wf_station_id) {
                    if (canDelete) {
                        if (transitionType.dst_is_deleted!) {
                            possibleTransitions.push(transitionType);
                        }
                    }
                }
            }
        }

        if (possibleTransitions.length > 1) {
            app.showError(
                __("Hiba"),
                __( 'Egynél több célállapot elérhető.') + " " +
                __('A törlés végrehajtásához nyissa meg a folyamat dialógus ablakot.')
            );
            return;
        }
        const transitionType: IViewTransitionTypeRecord = possibleTransitions[0];

        if (canDelete) {
            let justification = prompt(__('Kérem írja be az indoklást'), '');
            this.setState({ loading: true });
            let wfApi = new WfAPI(obtainServer());
            try {
                let workflowAfter: IViewWfWorkflowRecord = await wfApi.makeTransition(
                    selectedLessonPlan!.record.wf_workflow_id!,
                    transitionType.id!,
                    justification
                );
                this.reloadAsync();
                app.showSuccess(__("Feladat törlése"),__("Sikeres"))

            } catch (error) {
                app.showErrorFromJsonResult(error);
                this.setState({ loading: false });
            };
        }
    }

    render() {
        var filteredRecords = this.state.lesssonPlanRecords;
        const getTdProps = (finalState: any, rowInfo: RowInfo, column?: Column, instance?: any) => {
            let props: Partial<TableProps> = {};

            if (column && rowInfo && rowInfo.row) {
                let row = rowInfo.row._original;
                if (row && column.id == "wf_station_name") {
                    props.style = row.wf_station_style || {};               
                }
                if (row && column.id == "grades") {
                    let repstring:string=row.grades;
                    row.grades=replaceAll(repstring,",",", ");
                }
            }
            return props;
        }

        const tableProps = {
            columns: [
                { Header: __("Téma"), accessor: "topic", minWidth: 120, 
                 Cell: (data: any, column: any) => {
                    const row: IViewLessonplanRecord = data.original;
                    return <Link to={PATH_LESSONPLAN_VIEW + `/${row.id}`} style={{ width: "100%" }}>
                      <b>{row.topic}</b><br />
                    
                    </Link>;
                  }
                },
                { Header: __("Projekt azonosító"), accessor: "project_title", minWidth: 90 },
                { Header: __("Intézmény neve / azonosító"), accessor: "institute_name", minWidth: 220,
                Cell: (data: any, column: any) => {
                    const row: IViewLessonplanRecord = data.original;       
                    return<p style={{paddingTop:"2.5em"}} className="column small-12">{row.institute_name}<br/> 
                      <small style={{ color: "green" }}>{__("Azonosító: {identifier}", {identifier: row.institute_om_identifier+"-"+row.institute_site_identifier})}</small></p> 
                  } },

                { Header: __("Pedagógus"), accessor: "owner_name" },            
                { Header: __("Évfolyamok"), accessor: "grades", filterable: false,maxWidth:110},
                { Header: __("Tantárgyak"), accessor: "subjects", filterable: false,maxWidth:120 },
                { Header: __("Állapot"), accessor: "wf_station_name", filterable: true, Cell: getWfStateCell, style: { justifyContent: "center" },maxWidth:110 },
                { Header: __("Művelet"), accessor: "link", filterable: false, sortable: false, minWidth: 180, maxWidth: 180, className: "action-cell" },
            ],

            data: filteredRecords,
            defaultPageSize: this.state.pageSize,
            defaultFiltered: this.state.filters,
            defaultSorted: this.state.sorted,
            pages: Math.ceil(this.state.count / this.state.pageSize),
            filterable: true,
            className: "-striped -highlight",
            ...getReactTableLabels(),
            onFetchData: this.onFetchData.bind(this),
            loading: this.state.loading,
            getTdProps,
            manual: true
        };

        return (
            <div className="exercise-list-page row exerciseList-maincomponent">
                <br />              
                {
                    this.props.onLessonPlanSelected ? ""
                        :
                        <div className="small-12 medium-12 column">
                        </div>
                }

                <div className="small-12 medium-12 column clearfix">
                    {
                        (me && (!hasGroup(me, Groups.Student) || hasGroup(me, Groups.LessonplanEditor) || hasGroup(me, Groups.Teacher))) ?

                            <Link className="button small eke-general-buttons float-left" to={PATH_LESSONPLAN_EDIT}><i className="fa fa-plus" /> {__("Új óravázlat hozzáadása")}</Link>
                            : ""
                    }

                </div>
                <h4>{__("Szerkesztés alatti óravázlatok")}</h4><br />
                <div className="medium-12 columns row exerciselist-filterContainer">
                    <div className="small-12 medium-6 column">
                        <label className="lms-pages-typeLabel">{__("Projektazonosító")}</label>
                        <CrudSelect
                            value={this.state.dpmk_project_id}
                            onSelect={(_, id) => this.onProjectIdChange(id ? Number(id) : null)}
                            displayFieldName="title"
                            orderByFieldName="title"
                            emptyTitle={__("Minden projekt")}
                            crudClassProxy={dpmkProjectCrudClassProxy}
                            filter={{ is_active: true }}
                        />
                    </div>
                    <div className="small-12 medium-6 column">
                        <label className="lms-pages-typeLabel">{__("Intézmény neve")}</label>
                        <LookupEdit
                            key={"institute_id " + (this.state.instituteId || 'null')}
                            fk_table_info_id={InstituteCrud.TABLE_INFO_ID}
                            clearable={true}
                            value={this.state.instituteId}
                            onChange={(id) => this.onInstituteChange(id)}
                        />
                    </div>
                    <div className="small-12 medium-6 column">
                        <label className="lms-pages-typeLabel">{__("Évfolyam")}</label>
                        <CrudSelect
                            value={this.state.grade_id}
                            onSelect={this.onGradeSelect}
                            displayFieldName="name"
                            key="id"
                            emptyTitle={__("Minden évfolyam")}
                            crudClassProxy={gradeCrudClassProxy}
                            filter={{ is_active: true }}
                            sortFunc={(a, b) => a.name.localeCompare(b.name, undefined, { numeric: true, sensitivity: 'base' })}
                        />
                    </div>
                    <div className="small-12 medium-6 column">
                        <label className="lms-pages-typeLabel">{__("Tantárgy")}</label>
                        <CrudSelect
                            value={this.state.subject_id}
                            onSelect={this.onSubjectSelect}
                            displayFieldName="name"
                            key="id"
                            emptyTitle={__("Minden tantárgy")}
                            crudClassProxy={subjectCrudClassProxy}
                        />
                    </div>
                    <div className="small-12 medium-4 column">
                        <label className="lms-pages-typeLabel">{__("Pedagógus neve")}</label>
                        <SecUserSelector
                            key={"owner_id " + ((this.state.myLessonPlans ? me!.id : this.state.owner_id) || 'null')}
                            clearable={true}
                            value={this.state.myLessonPlans ? me!.id : this.state.owner_id}
                            onChange={(id) => this.onOwnerChange(id)}
                            disabled={this.state.myLessonPlans}
                        />
                    </div>
                    <div className="small-12 medium-4 column">
                        <label className="lms-pages-typeLabel">{__("Iskolatípus")}</label>
                        <CrudSelect
                            value={this.state.institute_type_id}
                            onSelect={this.onInstituteTypeSelect}
                            displayFieldName="title"
                            key="id"
                            emptyTitle={__("Minden iskola")}
                            crudClassProxy={instituteTypeCrudClassProxy}
                        />
                    </div>
                    <div className="small-12 medium-4 column">
                        <label className="lms-pages-typeLabel">{__("OM azonosító")}</label>
                      <input className="exerciseList-option" name="keywords" id="keywords" type="text" 
                        value={this.state.institute_om_id ? this.state.institute_om_id : ""}
                         onChange={this.onInstituteOmIdentifierValueChange.bind(this)} onKeyPress={this.onOM_IDKeyPress.bind(this)}/>
                       
                    </div>
                    <div className="small-12 medium-12 large-12 column">
                        <input type="checkbox" name="myexerciseLists" id="myexerciseLists" checked={this.state.myLessonPlans} onChange={this.onMyExerciseListChanged.bind(this)} />
                        <label htmlFor="myexerciseLists">{__("Saját óravázlataim")}</label>
                    </div>
                </div>
                <div className="small-12 medium-12">
                    <label className="result-count">&nbsp;{this.state.count ? __("{n} találat", {n: this.state.count}) : __("0 találat")}</label>

                    <ReactTable {...tableProps} />

                </div>
            </div>
        );
    }
}
