import { Server } from '../../server/Server';
import Crud , { registerCrudClassProxy, IAsTextResult, IListParams, CrudClassProxy, ISearchTextParams, ISearchTextResult } from '../Crud';

export interface ILessonRecord {
    /**
     * Globálisan egyedi azonosító.
     * 
     * Ha új rekordot akarsz létrehozni put() metódussal, akkor hagyd üresen.
     * upsert() hívásnál figyelmen kívül van hagyva az értéke.
     *
     */
    id ?: number;
    /**
     * Sorrend
     */
    no?: number /*integer*/|null;
    /**
     * Név
     */
    name?: string;
    /**
     * Fejezet
     *
     * NOT NULL -> doc_pub.chapter ON DELETE cascade
     *
     */
    chapter_id?: number;
    /**
     * URL rész
     *
     * URL path (útvonal) szegmense
     */
    uri_segment?: string;
    /**
     * Sorszám
     *
     * A lecke sorszáma, ahogy az a könyv lapok tetején megjelenik.
     */
    headno?: number /*integer*/|null;
}

/**
 *  doc_pub.lesson - Lecke
 */

export default class LessonCrud extends Crud<ILessonRecord> {
    public static TABLE_INFO_ID = 3510948561;
    public static getSchemaNameForClass() { return 'doc_pub'; }
    public static getTableNameForClass() { return 'lesson'; }

    public static canCache(): boolean {
        return true;
    }

    public static getDefaultMaxAge() : number|undefined {
        return 3600;
    }

    public static load: (id: number, aServer ?: Server) => Promise<LessonCrud>;
    public static asText: (id: number, includeRecord : boolean, aServer ?: Server) => Promise<IAsTextResult<ILessonRecord>>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<ILessonRecord[]>;
    public static searchText: (params: ISearchTextParams, aServer ?: Server) => Promise<ISearchTextResult<ILessonRecord>[]>;
    public static deleteById: (id: number, aServer ?: Server) => Promise<ILessonRecord>;
    public static unDeleteById: (id: number, aServer ?: Server) => Promise<ILessonRecord>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class LessonCrudClassProxy extends CrudClassProxy<ILessonRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const lessonCrudClassProxy = new LessonCrudClassProxy(LessonCrud);
registerCrudClassProxy(lessonCrudClassProxy);
