import { api } from '@framework/server/Auth';

import obtainServer from '@src/framework/server/Server';

import { __ } from '@src/translation';


/**
 * 
 * Copy metadata from a record to another record.
 * Metadata includes:
 * 
 * - assigned metacategories
 * - assigned keywords
 * - dublin core
 * 
 */
export const copyMeta = async (fromTableInfoId: number, fromRecordId: number, toTableInfoId: number, toRecordId: number): Promise<void> => {
    if (api == null) {
        throw new Error(__("Nincs bejelentkezve?"));
    } else {
        await obtainServer().post<boolean>(
            'metacopy',
            {
                params: {
                    from_table_info_id: fromTableInfoId,
                    from_record_id: fromRecordId,
                    to_table_info_id: toTableInfoId,
                    to_record_id: toRecordId,
                }
            }
        );
        return Promise.resolve();
    }
}
