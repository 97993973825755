import { Server } from '../../server/Server';
import View , { registerViewClassProxy, ViewClassProxy } from '../View';
import { IListParams } from '@framework/crud/Crud';

export interface IViewHtrTicketRecord {
    /**
     * Azonosító
     *
     * Azonosító (pótkulcs)
     */
    id?: number;
    /**
     * Rekord létrehozás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel létrehozásakor.
     */
    creation_time?: string /*timestamp?*/;
    /**
     * Ki hozta létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozó felhasználóra hivatkozik.
     */
    creation_user_id?: number;
    /**
     * Melyik session-ből jött létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozáshoz használt munkamenetre hivatkozik.
     */
    creation_session_id?: number|null;
    /**
     * Utolsó módosítás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel módosításakor.
     */
    modification_time?: string /*timestamp?*/|null;
    /**
     * Ki módosította utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosító felhasználóra hivatkozik.
     */
    modification_user_id?: number|null;
    /**
     * Melyik session-ből módosították utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosításhoz használt munkamenetre hivatkozik.
     */
    modification_session_id?: number|null;
    /**
     * Aktív
     *
     * Logikai törléshez használt mező.
     */
    is_active?: boolean;
    /**
     * Ügyiratszám
     */
    no?: string;
    /**
     * HTR bejelentés kategória
     */
    htr_ticket_category_id?: number;
    /**
     * Beküldő/Hallgató
     */
    sec_user_id?: number;
    /**
     * Tárgy
     */
    subject?: string;
    /**
     * Az bejelentés könyvtára
     *
     * A bejelentés saját virtuális könyvtára, ahová a résztvevők tudnak feltölteni állományokat
     */
    oo_folder_id?: number|null;
    /**
     * Kategória cím
     *
     * Kategória cím
     */
    title?: string|null;
    /**
     * Alapértelmezett felelős
     *
     * Alapértelmezett felelős
     */
    default_agent?: string|null;
    /**
     * Üzenetek száma
     *
     * Üzenetek száma
     */
    message_count?: number|null;
}

/**
 *  ticket.view_htr_ticket - HTR bejelentés
 *
 *  Idősoros táblázat, ami az összes tétel összes korábbi verzióját eltárolja.
 */

export default class ViewHtrTicket extends View<IViewHtrTicketRecord> {
    public static VIEW_INFO_ID = 2019101404;

    public static getSchemaNameForClass(): string { return 'ticket'; }
    public static getViewNameForClass(): string { return 'view_htr_ticket'; }
    public static getTableNameForClass(): string|null { return 'htr_ticket'; }

    public static load: (id: number, aServer ?: Server) => Promise<ViewHtrTicket>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<IViewHtrTicketRecord[]>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class ViewHtrTicketClassProxy extends ViewClassProxy<IViewHtrTicketRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const viewHtrTicketClassProxy = new ViewHtrTicketClassProxy(ViewHtrTicket);
registerViewClassProxy(viewHtrTicketClassProxy);

