import obtainServer from '@framework/server/Server';
import { objectToQueryParams } from '@src/framework/util/UrlUtils';
import { IViewEventData } from '@src/server/PublicServer';

const URL = 'viewEventHistory';

export interface IUserViewEvent {
    user_id : number;
    event_date: string; // timestamp
    table_info_id : number;
    record_id : number;
    name : string|null;
    data : IViewEventData;
}

export default class UserViewHistoryAPI
 {
    /** Profil adatok lekérdezése. */
    public static getViewEventLog = (tableInfoId?: number,limit?:number,offset?:number) : Promise <IUserViewEvent[]> => {
        return obtainServer().get<IUserViewEvent[]>(URL + "?"+objectToQueryParams({
            tableInfoId, limit, offset
        }));
       
    }
}