import * as React from 'react';
import './SharedContent.css';
import { IViewSharedWithMeRecord } from '@src/framework/view/usr/ViewSharedWithMe';
import { ContentDetails, getContentDetails } from '../filemanager/ContentUtils';
import MemberCard from '@src/framework/forms/MemberCard';
import { formatDate } from '@src/Util';
import { __ } from '@src/translation';
import { Link } from 'react-router-dom';
import { Accordion, AccordionItem } from '../ui/Accordion';
import CountDown from '../exercise/ExerciseSeries/CountDown';
import { Dialog } from '../Dialog';
import UsrShareDialog from '../usr/UsrShareDialog';
import { history } from '@src/index';
import { classroomModule } from '@src/module/classroomModule/classroomModule';
import { PATH_CONTENT_STORE_SHARED_BY_ME } from '@src/Routes';

type SharedContentProps = {
    sharedContent: IViewSharedWithMeRecord;
    showSharedBy: boolean;
    evaluationAccordionItem?: any;
    updateCallback?: () => void;
}

type SharedContentState = {
    contentDetails?: ContentDetails;
    shareDialogOpen: boolean;
}

export class SharedContent extends React.Component<SharedContentProps, SharedContentState> {

    constructor(props: SharedContentProps) {
        super(props);

        this.state = {
            shareDialogOpen: false
        }
    }

    componentDidMount() {
        this.reloadAsync();
    }

    componentDidUpdate(prevProps: SharedContentProps) {
        if (prevProps.sharedContent.id !== this.props.sharedContent.id) {
            this.reloadAsync();
        }
    }

    private async reloadAsync() {
        const sharedContent = this.props.sharedContent;
        const contentDetails = await getContentDetails(sharedContent.table_info_id!, sharedContent.rec_id!);
        this.setState({ contentDetails });
    }

    render() {
        const sharedContent = this.props.sharedContent;
        const returnPath = sharedContent.shared_from_institute_group_id ? classroomModule.PATH_CLASSROOM_TASKS + "/" + sharedContent.shared_from_institute_group_id : 
        PATH_CONTENT_STORE_SHARED_BY_ME;
        let starttime = new Date(sharedContent.start_date!);

        let remainingEndDate = undefined;
        let remainingDueDate = undefined;
        if (sharedContent.end_date) {
            let endtime = new Date(sharedContent.end_date!);
            remainingEndDate = Math.round((endtime.getTime() - new Date().getTime()) / 1000);
            if (remainingEndDate < 0) remainingEndDate = 0
        }
        if (sharedContent.due_date) {
            let duetime = new Date(sharedContent.due_date!);
            remainingDueDate = Math.round((duetime.getTime() - new Date().getTime()) / 1000);
            if (remainingDueDate < 0) remainingDueDate = 0
        }

        return <>

            <div className="share-data-title">
                {this.state.contentDetails && this.state.contentDetails.thumbnailUrl && <img src={this.state.contentDetails.thumbnailUrl} width="100px" />}&nbsp;
                {!this.props.showSharedBy ? <Link to={this.state.contentDetails ? this.state.contentDetails.viewUrl : ""}> <h4>{this.state.contentDetails && this.state.contentDetails.displayName}</h4></Link>
                    : <h4>{this.state.contentDetails && this.state.contentDetails.displayName}</h4>}
            </div>

            <Accordion allowAllClosed={true}>
                <AccordionItem key="shareDetailesAccordion" defaultClosed={false} title={<span><img src={"/img/IKON_SET/FLAT/tag.svg"} className="exe-editor-icons" alt="Alapadatok" /> {sharedContent.share_mode_title + " " + __("adatai")}</span>}>

                    <div>
                        {
                            this.state.shareDialogOpen &&
                            <Dialog title={__("Feladat kiosztása")} onClose={() => this.setState({ shareDialogOpen: false })} width={1000} height={700}>
                                <UsrShareDialog
                                    sharedContentId={sharedContent.id}
                                    tableInfoId={sharedContent.table_info_id}
                                    recordId={sharedContent.rec_id}
                                    fileName=""
                                    onCancel={() => this.setState({ shareDialogOpen: false })}
                                    onCreated={() => history.push(returnPath)}
                                    onSaved={() => this.setState({ shareDialogOpen: false }, this.props.updateCallback )}
                                    onSharedContentDeleted={() => history.push(returnPath)}
                                    onSharedContentSelected={() => null}
                                />
                            </Dialog>
                        }
                        <div className="row share-data-rows">
                            <div className="column small-10"><h5>{sharedContent.title}</h5></div>
                            {!this.props.showSharedBy && <div className="column small-2 text-right">
                                <button className="button success" title={__("Megosztás szerkesztése")} onClick={() => this.setState({ shareDialogOpen: true })}><i className="fas fa-fw fa-cogs"></i></button>
                            </div>}
                        </div>
                        <div className="row share-data-rows">
                            {sharedContent.description && <label><strong>{__("Leírás:")}</strong> <p>{sharedContent.description}</p></label>}
                        </div>
                        {
                            this.props.showSharedBy
                                ?
                                <div className="row share-data-rows">
                                    <div className="columns large-3 small-12"><label><strong>{__("Kiosztó")}:</strong></label></div>
                                    <div className="columns large-9 small-12"><MemberCard {...sharedContent} memberFieldNamePrefix="shared_by_" /></div>
                                </div> : ""
                        }

                        <div className="row share-data-rows">
                            <div className="columns large-3 small-12"><label><strong>{__("Kezdő időpont")}:</strong></label></div>
                            <div className="columns large-3 small-12">{sharedContent.start_date ? formatDate(sharedContent.start_date) : "-"}</div>
                            <div className="columns large-3 small-12"><label><strong>{__("Megtekinthető eddig")}:</strong></label></div>
                            <div className="columns large-3 small-12">{sharedContent.end_date ? formatDate(sharedContent.end_date) : "-"}</div>
                        </div>
                        <div className="row share-data-rows">
                            <div className="columns large-3 small-12"><label><strong>{__("Leadás határideje")}:</strong></label></div>
                            <div className="columns large-3 small-12">{sharedContent.due_date ? formatDate(sharedContent.due_date) : "-"}</div>
                        </div>
                        <div className="row share-data-rows">
                            <div className="columns large-3 small-12"><label><strong>{__("Elérhető pontszám")}:</strong></label></div>
                            <div className="columns large-3 small-12">{sharedContent.max_points ? sharedContent.max_points : "-"}</div>
                        </div>
                        <div className="row share-data-rows">
                            <div className="columns large-3 small-12"> {sharedContent.share_mode_title}</div>
                            <div className="columns large-7 small-12"><em>{sharedContent.max_run_count === 0 ? __("A feladat bármennyi alkalommal megoldható") : __(`A feladat legfeljebb {max_run_count} alkalommal oldható meg`, { max_run_count: sharedContent.max_run_count })}</em></div>
                        </div>
                    </div>
                </AccordionItem>
                {
                    remainingEndDate
                        ?
                        <AccordionItem key="shareDeadlinesAccItem" defaultClosed={false} title={<span><img src={"/img/IKON_SET/FLAT/deadline.svg"} className="exe-editor-icons" alt={__("Határidők")} /> {__("Határidők")}</span>}>

                            <div >
                                <table>
                                    <thead>
                                        <tr>
                                            <th>
                                                {__("Megtekinthető eddig")}
                                            </th>
                                            {remainingDueDate ?
                                                <th>
                                                    {__("Leadásig hátralévő idő")}
                                                </th>
                                                : null
                                            }
                                        </tr>
                                        <tr>
                                            <td>
                                                <CountDown startSeconds={remainingEndDate}
                                                    key={sharedContent.id}
                                                    isInSharedContent={true}
                                                    onComplete={() => { }}
                                                    timerIsPaused={() => { }}
                                                    ref="countDownChild"
                                                    canInterrupt={false}
                                                />
                                            </td>
                                            <td style={{ justifyContent: "center" }}>
                                                {remainingDueDate ?
                                                    <CountDown startSeconds={remainingDueDate}
                                                        key={sharedContent.id}
                                                        isInSharedContent={true}
                                                        onComplete={() => { }}
                                                        timerIsPaused={() => { }}
                                                        ref="countDownChild"
                                                        canInterrupt={false}
                                                    />
                                                    : null
                                                }
                                            </td>
                                        </tr>
                                    </thead>
                                </table>
                            </div>
                        </AccordionItem>

                        : null
                }
                {
                    this.props.evaluationAccordionItem ? this.props.evaluationAccordionItem : null
                }
            </Accordion>
        </>
    }
}
