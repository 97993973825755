import * as React from "react";
import { ListItem, ListItemImage, ListItemText, ListItemMenu } from "../ui/List";
import { Link } from "react-router-dom";
import { formatDate } from "@src/Util";
import UsrLikePlugin from "../usr/usr_like_plugin";
import { __ } from "@src/translation";
import { getContentDetails, ContentType } from "../filemanager/ContentUtils";
import { Dialog, DialogContent } from "../Dialog";
import TypeIcon from "@src/component/ui/TypeIcon";
import { PATH_PUBLISHED_BOOK } from "@src/Routes";
import * as PublicViewExerciseSection from '@framework/view/exc_pub/ViewExerciseSection';
import * as PublicViewExerciseSeriesItem from '@framework/view/exc_pub/ViewExerciseSeriesItem';
import ViewExerciseSeriesSection from '@src/framework/view/exc/ViewExerciseSeriesSection';
import ViewDocSectionFileRef, { IViewDocSectionFileRefRecord } from '@src/framework/view/doc/ViewDocSectionFileRef';
import { LIBRARY_OFI_OFFICIAL_ID } from '@src/Const';

export interface IGeneralItem {
    id?: number,
    table_info_id?: number,
    creation_time?: string,
    rec_id?: number,
    no?: number | null,
    details?: IGeneralItemDetails
}

export interface IGeneralItemDetails {
    id?: number,
    viewUrl?: string,
    type?: string,
    displayName?: string,
    thumbnailUrl?: string
}

type CollectionItemProps = {
    item: IGeneralItem;
    onReload: () => void;
    onDelete: (id: number) => void;
    onEdit?: (id: number) => void;
    dragIndex?: number;
    showUserPlugin: boolean;
}

type CollectionItemState = {
    details: IGeneralItemDetails;
    dialogOpen: boolean;
    dialogContent: JSX.Element[];
}

export class CollectionItemComponent extends React.Component<CollectionItemProps, CollectionItemState> {

    constructor(props: any) {
        super(props);
        this.state = {
            details: {},
            dialogOpen: false,
            dialogContent: []
        }
    }
    componentDidMount() {
        this.reloadAsync();
    }
    componentDidUpdate(prevProps: CollectionItemProps) {
        if (this.props.item.id != prevProps.item.id) {
            this.reloadAsync();
        }
    }

    setDialogContent(sections: any[], contentType: ContentType) {
        let dialogContent: JSX.Element[] = [];
        sections.length > 0 ? sections.map(s => {
            let url = PATH_PUBLISHED_BOOK + "/" + s.book_uri_segment + "/" + s.lesson_uri_segment + "#section-" + s.section_id;
            dialogContent.push(<div style={{ marginBottom: "-1px", paddingTop: "15px", paddingBottom: "15px", borderTop: "1px solid lightgray", borderBottom: "1px solid lightgray" }}>
                <a target="_blank" href={url}>{s.book_name + "/" + s.chapter_name + "/" + s.lesson_name}</a>
            </div>)
        }) : dialogContent.push(<>{"Ez a " + contentType.toLowerCase() + " nem található meg tankönyvben"}</>);

        this.setState({ dialogContent: dialogContent });
    }

    async getSections(id: number) {
        try {
            const details = this.state.details;
            let sections: any[] = []; //HA ez itt nem any, sajnos nem működik a map-elés és kódot kellene sokszorosítani.
            if (details.type == "Feladat") {
                sections = await PublicViewExerciseSection.default.list({ filter: { id: id, library_id: LIBRARY_OFI_OFFICIAL_ID } });
                let series: PublicViewExerciseSeriesItem.IViewExerciseSeriesItemRecord[] = await PublicViewExerciseSeriesItem.default.list({ filter: { exercise_id: id } });
                if (series.length > 0) {
                    series.map(async r => {
                        let seriessections = await ViewExerciseSeriesSection.list({ filter: { id: r.exercise_series_id, library_id: LIBRARY_OFI_OFFICIAL_ID } });
                        seriessections.map(e => {
                            sections.push(e);
                        })
                        this.setDialogContent(sections, this.state.details.type as ContentType);
                    });
                } else {
                    this.setDialogContent(sections, this.state.details.type as ContentType);
                }

            } else if (details.type == "Feladatsor") {
                sections = await ViewExerciseSeriesSection.list({ filter: { id: id, library_id: LIBRARY_OFI_OFFICIAL_ID } });
                this.setDialogContent(sections, this.state.details.type as ContentType);
            } else {
                sections = await ViewDocSectionFileRef.list({ filter: { oo_file_id: id } });
                this.setDialogContent(sections, this.state.details.type as ContentType);
            }
        } catch (e) {
            console.log(e);
        }
    }

    private async reloadAsync() {
        if (!this.props.item || !this.props.item.rec_id) return;
        const details = await getContentDetails(this.props.item.table_info_id!, this.props.item.rec_id!);
        if (details)
            this.setState({
                details
            }, () =>
                this.getSections(this.props.item.rec_id!))
    }


    render() {

        let mainItems: any[] = [];
        if (this.props.onDelete)
            mainItems.push({
                icon: <i className="fa fa-fw fa-trash" />,
                type: "alert",
                onClick: this.props.onDelete.bind(this, this.props.item.id)
            });

        if (this.props.onEdit)
            mainItems.push({
                icon: <i className="fa fa-fw fa-cog" />,
                type: "secondary",
                onClick: this.props.onEdit.bind(this, this.props.item.id)
            });



        return <>
            <ListItem dragIndex={this.props.dragIndex}>
                <ListItemImage src={this.state.details && this.state.details.thumbnailUrl} />
                <ListItemText>
                    <strong>
                        <Link to={this.state.details!.viewUrl || ""}>
                            <TypeIcon contentType={this.state.details.type as ContentType} />
                            {/* {this.state.details!.type ? "[" + this.state.details!.type + "] - " : ""} */}
                            {this.state.details!.displayName ? this.state.details!.displayName : ""}
                            <br />
                        </Link>
                    </strong>
                    <div><span className="content-item-list__small">{__("Hozzáadva")}: {formatDate(this.props.item.creation_time)}</span></div>
                    {this.state.details.type == "Lecke" ? "" :
                        <Link to="#" onClick={() => this.setState({ dialogOpen: true })}>Előfordulási helyek</Link>
                    }
                </ListItemText>

                <ListItemMenu
                    mainItems={mainItems}
                >
                    {this.props.showUserPlugin && this.props.item.rec_id ?
                        <UsrLikePlugin
                            tableInfoId={this.props.item.table_info_id!}
                            recId={this.props.item.rec_id!}
                        />
                        : ""}
                </ListItemMenu>
            </ListItem>
            <Dialog title="Előfordulási helyek" open={this.state.dialogOpen} onClose={() => this.setState({ dialogOpen: false })}>
                <DialogContent>
                    {this.state.dialogContent.map(c => {
                        return c;
                    })}
                </DialogContent>

            </Dialog>
        </>
    }

}
