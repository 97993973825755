import { Server } from '../../server/Server';
import View , { registerViewClassProxy, ViewClassProxy } from '../View';
import { IListParams } from '@framework/crud/Crud';

export interface IViewExcExerciseRecord {
    /**
     * Azonosító
     *
     * Azonosító (pótkulcs)
     */
    id?: number;
    /**
     * Név
     */
    name?: string;
    /**
     * Rekord létrehozás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel létrehozásakor.
     */
    creation_time?: string /*timestamp?*/;
    /**
     * Ki hozta létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozó felhasználóra hivatkozik.
     */
    creation_user_id?: number;
    /**
     * Melyik session-ből jött létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozáshoz használt munkamenetre hivatkozik.
     */
    creation_session_id?: number|null;
    /**
     * Utolsó módosítás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel módosításakor.
     */
    modification_time?: string /*timestamp?*/|null;
    /**
     * Ki módosította utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosító felhasználóra hivatkozik.
     */
    modification_user_id?: number|null;
    /**
     * Melyik session-ből módosították utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosításhoz használt munkamenetre hivatkozik.
     */
    modification_session_id?: number|null;
    /**
     * Aktív
     *
     * Logikai törléshez használt mező.
     */
    is_active?: boolean;
    /**
     * Feladat motor
     */
    engine_id?: number;
    /**
     * Évfolyam
     */
    grade_id?: number|null;
    /**
     * Tantárgy
     */
    subject_id?: number|null;
    /**
     * Könyvtár
     */
    library_id?: number;
    /**
     * Site
     */
    site_id?: number;
    /**
     * Tulajdonos/felelős
     */
    owner_id?: number;
    /**
     * Utasítás
     */
    task_html?: string|null;
    /**
     * Visszajelzés helyes válaszra
     */
    correct_answer_feedback_html?: string|null;
    /**
     * Visszajelzés helytelen válaszra
     */
    wrong_answer_feedback_html?: string|null;
    /**
     * Tároló
     *
     * Az a mappa, ami a feladathoz tartozó médiákat tárolja.
     */
    oo_folder_id?: number|null;
    /**
     * Megjelenés helye
     *
     * Mappa, ahol a feladat a virtuális állományrendszerben megjelenik.
     */
    show_in_folder_id?: number|null;
    /**
     * Azonosító NKP 1.0 rendszerben
     */
    nkp1_id?: number|null;
    /**
     * Utolsó találat időpontja
     */
    search_popularity_last_hit_date?: string /*timestamp?*/|null;
    /**
     * Utolsó népszerűségi index
     */
    search_popularity_last_score?: number /* float8 */|null;
    /**
     * Név
     *
     * Kulcsszavak
     */
    keywords?: string|null;
    /**
     * Kereső szöveg
     *
     * A feladat mentésekor ide bekerül az összes olyan szöveg, ami értelmes kereshető szöveget tartalmaz. Ez feladatmotoronként eltérő helyekről/mezőkből származik.
     */
    search_text?: string|null;
    /**
     * Hány találatot adott
     *
     * A search_text mezőre keresve hány találatot ad a keresőmotor.
     */
    search_result_count?: number /*int4*/|null;
    /**
     * Találatok száma ellenőrizve
     *
     * Mikor lett utoljára ellenőrizve a találatok száma
     */
    search_result_last_checked?: string /*timestamp?*/|null;
    /**
     * Belső azonosító
     */
    internal_code?: string|null;
    /**
     * A feladat szinte
     *
     * A feladat szinte
     */
    level?: number /* float8 */|null;
    /**
     * A feladat leírása
     *
     * A feladat leírása
     */
    description?: string|null;
    /**
     * SNI
     *
     * Sajátos nevelési igényűek számára készített feladat
     */
    is_sni?: boolean;
    /**
     * Akadálymentesített
     *
     * Látássérültek számára akadálymentesített feladat
     */
    is_accessible?: boolean|null;
    /**
     * Egyszerû megjelenítés
     *
     * Egyszerű stílusban megjelenítendő feladat
     */
    simple_style?: boolean|null;
    /**
     * Feladat motor
     *
     * Feladat motor név
     */
    engine_name?: string|null;
    /**
     * Évfolyam
     *
     * Évfolyam azonosító
     */
    grade_name?: string|null;
    /**
     * Tantárgy
     *
     * Tantárgy név
     */
    subject_name?: string|null;
    /**
     * Tulajdonos bejelentkezési név
     *
     * Tulajdonos bejelentkezési név
     */
    owner_login_name?: string|null;
    /**
     * Tulajdonos név
     *
     * Tulajdonos személy neve
     */
    owner_person_fullname?: string|null;
    /**
     * Folyamat azonosító
     *
     * Folyamat azonosító
     */
    wf_workflow_id?: number|null;
    /**
     * Folyamat állapot azonosító
     *
     * Folyamat állapot azonosító
     */
    wf_station_id?: number|null;
    /**
     * Állapot
     *
     * Folyamat állapot neve
     */
    wf_station_name?: string|null;
    /**
     * Folyamat állapot leírása
     *
     * Folyamat állapot leírása
     */
    wf_station_description?: string|null;
    /**
     * Állapot stílus
     *
     * Folyamat állapot stílusa, jsonb/css
     */
    wf_station_style?: any|null;
    /**
     * Folyamat al-státusz azonosító
     *
     * Folyamat al-státusz azonosító
     */
    wf_substation_id?: number|null;
    /**
     * Folyamat al-státusz
     *
     * Folyamat al-státusz
     */
    wf_substation_name?: string|null;
    /**
     * Folyamat al-státusz leírása
     *
     * Folyamat al-státusz leírása
     */
    wf_substation_description?: string|null;
    /**
     * Folyamat szöveges állapot kiegészítés
     *
     * Folyamat szöveges állapot kiegészítés
     */
    wf_status_text?: string|null;
    /**
     * Publikálva van
     *
     * Publikálva van
     */
    is_published?: boolean|null;
    /**
     * Utoljára publikálva
     *
     * Utoljára publikálva
     */
    last_published?: string /*timestamp?*/|null;
    /**
     * Utolsó publikáló
     *
     * Utolsó publikáló
     */
    last_publisher_id?: number|null;
    /**
     * Létrehozta
     *
     * Felhasználó
     */
    creator?: string|null;
    /**
     * Módosító
     *
     * Felhasználó, aki utoljára módosította
     */
    modifier?: string|null;
    /**
     * Utolsó publikáló
     */
    last_publisher?: string|null;
}

/**
 *  exc.view_exc_exercise - Okosfeladat
 */

export default class ViewExcExercise extends View<IViewExcExerciseRecord> {
    public static VIEW_INFO_ID = 2018040901;

    public static getSchemaNameForClass(): string { return 'exc'; }
    public static getViewNameForClass(): string { return 'view_exc_exercise'; }
    public static getTableNameForClass(): string|null { return 'exercise'; }

    public static load: (id: number, aServer ?: Server) => Promise<ViewExcExercise>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<IViewExcExerciseRecord[]>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class ViewExcExerciseClassProxy extends ViewClassProxy<IViewExcExerciseRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const viewExcExerciseClassProxy = new ViewExcExerciseClassProxy(ViewExcExercise);
registerViewClassProxy(viewExcExerciseClassProxy);

