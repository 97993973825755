import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Section } from '@src/server/EditorServer';
import ExerciseCrud from '@src/framework/crud/exc/ExerciseCrud';
import EngineCrud from '@src/framework/crud/exc/EngineCrud';
import * as ExerciseContainerComponent from '@src/component/exercise/ExerciseContainerComponent';
import { match } from 'react-router';
import BookCrud, { IBookRecord } from '@src/framework/crud/doc/BookCrud';
import * as PublicServer from '@src/server/PublicServer';
import * as EditorServer from '@src/server/EditorServer';
import './lowgrade_book.css';
import { BubbleLoader } from 'react-css-loaders';
import { Link } from 'react-router-dom';
import { setTimeout } from 'timers';
import { ExerciseServer } from '@src/component/exercise/ExerciseServer';
import { showUnpublished } from '@src/component/book/viewer/PublishedSelector';
import { __ } from '@src/translation';

type LowGradeBookPageProps = {
    match: match<{bookUriSegment: string}>,
}

type LowGradeBookPageState = {
    book?: IBookRecord;
    chapterLessons?: any;

    selectedChapterIndex: number | null;
    selectedLessonIndex: number | null;
    selectedSectionIndex: number | null;

    sections?: Section[];
    templates?: any[];
}

export class LowGradeBookPage extends React.Component<LowGradeBookPageProps, LowGradeBookPageState> {

    constructor(props: any) {
        super(props);

        this.state = {
            selectedChapterIndex: null,
            selectedLessonIndex: null,
            selectedSectionIndex: null,
        };
    }

    componentDidMount() {
        this.reloadAsync();
    }

    componentDidUpdate() {
        this.reloadAsync();
    }

    async reloadAsync() {
        if (!this.state.book || this.state.book.uri_segment != this.props.match.params.bookUriSegment) {
            const book = (await BookCrud.list({filter: {uri_segment: this.props.match.params.bookUriSegment}}))[0];
            
            if (!book || !book.id) {
                console.log(__("Ismeretlen tankönyv"));
                return;
            }

            const chapterLessons = await PublicServer.getChapterLessons(book.id);
            
            const templates = await EditorServer.getSectionTemplates(book.style_id!);

            this.setState({
                book: book,
                chapterLessons: chapterLessons,
                templates: templates
            });
        }

        if (this.state.book 
                && !this.state.sections 
                && this.state.selectedChapterIndex != null
                && this.state.selectedLessonIndex != null) {

            const lesson = this.state.chapterLessons[this.state.selectedChapterIndex].lessons[this.state.selectedLessonIndex];

            const sections = await EditorServer.getSections(lesson.id);

            this.setState({
                sections: sections
            });
        }
    }

    onItemSelected(selectedIndex: number) {
        if (this.state.selectedLessonIndex != null) {
            
            this.setState({selectedSectionIndex: selectedIndex});

        } else if (this.state.selectedChapterIndex != null) {

            this.setState({selectedLessonIndex: selectedIndex});
            
        } else {

            this.setState({selectedChapterIndex: selectedIndex});

        }
    }

    onBack() {
        if (this.state.selectedSectionIndex != null) {
            
            this.setState({selectedSectionIndex: null});

        } else if (this.state.selectedLessonIndex != null) {
            
            this.setState({selectedLessonIndex: null, sections: undefined});

        } else {

            this.setState({selectedChapterIndex: null, sections: undefined});

        }
    }

    render() {
        if (!this.state.book) {
            return <BubbleLoader />;
        }

        return <LowGradeBook
            chapterLessons={this.state.chapterLessons}
            sections={this.state.sections}
            templates={this.state.templates}

            selectedChapterIndex={this.state.selectedChapterIndex}
            selectedLessonIndex={this.state.selectedLessonIndex}
            selectedSectionIndex={this.state.selectedSectionIndex}

            onItemSelected={this.onItemSelected.bind(this)}
            onBack={this.onBack.bind(this)}
        />
        ;
    }
}



type LowGradeBookPreviewProps = {
    chapterLessons: any;
    sections: Section[];
    templates: any[];
}

type LowGradeBookPreviewState = {
    selectedSectionIndex: number | null;
}

export class LowGradeBookPreview extends React.Component<LowGradeBookPreviewProps, LowGradeBookPreviewState> {

    constructor(props: any) {
        super(props);

        this.state = {
            selectedSectionIndex: null
        };
    }

    onItemSelected(selectedSectionIndex: number) {
        this.setState({selectedSectionIndex});
    }

    onBack() {
        this.setState({selectedSectionIndex: null});
    }

    render() {
        return <LowGradeBook 
            chapterLessons={this.props.chapterLessons}
            sections={this.props.sections}
            templates={this.props.templates}

            selectedLessonIndex={1} /* TODO!*/
            selectedChapterIndex={1} /* TODO!*/
            selectedSectionIndex={this.state.selectedSectionIndex}

            onItemSelected={this.onItemSelected.bind(this)}
            onBack={this.onBack.bind(this)}
        />
    }

}


type LowGradeBookProps = {
    chapterLessons: any;
    sections?: Section[];
    templates?: any[];

    selectedLessonIndex: number | null;
    selectedChapterIndex: number | null;
    selectedSectionIndex: number | null;
    onItemSelected: (selectedSectionIndex: number) => void;
    onBack: () => void;
}

type LowGradeBookState = {
    lowGradeMapInit: LowGradeMapState;

}

class LowGradeBook extends React.Component<LowGradeBookProps, LowGradeBookState> {

    constructor(props: any) {
        super(props);
        this.state = { 
            lowGradeMapInit: { 
                gridX : 8,
                gridY : 13,
                slideCount : 2,
                activeSlide : 1,
                states : ['1/6/2', '1/9/1', '1/11/4', '1/8/6', '2/6/2', '2/9/1', '2/11/4', '2/8/6']
            }};
    }

    getStar()
    {
        setTimeout(function(){ 
            document.getElementById("star-five")!.classList.add('get-3-star');            
        }, 500)
        setTimeout(function(){ 
            document.getElementById("star-five")!.classList.remove('get-3-star');            
        }, 10)

    }

    componentDidMount(){
        
    }

    async componentDidUpdate() {
        if (this.refs.main) {
            const exerciseDivs = Array.from((this.refs.main as HTMLElement).querySelectorAll("div[data-exercise-id]"));
            
            for(const exerciseDiv of exerciseDivs) {
                const exerciseId = exerciseDiv.getAttribute("data-exercise-id") as string;

                const exerciseRecord = (await ExerciseCrud.load(parseInt(exerciseId))).record;
                
                if (exerciseRecord && exerciseRecord.engine_id) {
                    const engineInDatabase = (await EngineCrud.load(exerciseRecord.engine_id)).record;
                    if (engineInDatabase.class_name) {
                        var exContainer = ReactDOM.render(<ExerciseContainerComponent.default parentElement={exerciseDiv as HTMLElement} exerciseRecord={exerciseRecord} engineName={engineInDatabase.class_name} evaluationCallBack = {() => this.getStar()} />, exerciseDiv);
                        //(exContainer as ExerciseContainerComponent.default).reset(true);
                        document.getElementById("exeArea")!.classList.add('exe-show');
                        document.getElementById("exeArea")!.classList.remove('exe-hide');                        
                        
                    }                
                } else {
                    console.log("Engine not found!", exerciseId, exerciseRecord);
                }
                
            }

            const exerciseSeriesDivs = Array.from((this.refs.main as HTMLElement).querySelectorAll("div[data-exercise-series-id]"));

            for(const exerciseSeriesDiv of exerciseSeriesDivs) {
                const exerciseSeriesId = exerciseSeriesDiv.getAttribute("data-exercise-series-id") as string;
          
                const server = new ExerciseServer(!showUnpublished());
                server.setExerciseSeriesId(Number(exerciseSeriesId));
          
                ReactDOM.render(<ExerciseContainerComponent.default server={server} evaluationCallBack = {() => this.getStar()}/>, exerciseSeriesDiv);
                document.getElementById("exeArea")!.classList.add('exe-show');
                document.getElementById("exeArea")!.classList.remove('exe-hide');      
            }

        }
        
    }

    render() {
        if (this.props.selectedChapterIndex == null) {
            
            const chapterNames = this.props.chapterLessons.map((chapter: any) => chapter.name);

            return <div id="mapContent">
                <div className="p-background"><img className="map-img" src="/img/alsos_demo/felho2.png"/></div>
                <LowGradeNav lowGradeMapInit={this.state.lowGradeMapInit} onBack={this.props.onBack}/>
                <div id="mapArea" className="map-grid-container map-width">                     
                    <LowGradeMenu lowGradeMapInit={this.state.lowGradeMapInit} items={chapterNames} onItemSelected={this.props.onItemSelected} />
                </div>
            </div>
            
        } else if (this.props.selectedLessonIndex == null) {
            
            const lessonNames = this.props.chapterLessons[this.props.selectedChapterIndex].lessons.map((lesson: any) => lesson.name);

            return <div id="mapContent">
                <LowGradeNav lowGradeMapInit={this.state.lowGradeMapInit} onBack={this.props.onBack}/>
                <div id="mapArea" className="map-grid-container map-width">                    
                    <LowGradeMenu lowGradeMapInit={this.state.lowGradeMapInit} items={lessonNames} onItemSelected={this.props.onItemSelected} />
                </div>
            </div>
            
        } else if (this.props.selectedSectionIndex == null && this.props.sections) {
            
            const sectionNames = this.props.sections.map(s => s.name);

            return <div id="mapContent">
                <LowGradeNav lowGradeMapInit={this.state.lowGradeMapInit} onBack={this.props.onBack}/>
                <div id="mapArea" className="map-grid-container map-width">
                    <LowGradeMenu lowGradeMapInit={this.state.lowGradeMapInit} items={sectionNames} onItemSelected={this.props.onItemSelected} />
                </div>
            </div>
            
        } else if (this.props.selectedSectionIndex != null && this.props.sections && this.props.templates) {

            const sectionNames = this.props.sections.map(s => s.name);

            const selectedSection = this.props.sections[this.props.selectedSectionIndex];
    
            const template = this.props.templates.find(t => t.id == selectedSection.block_template_id);
    
            const html = template.template.fill(selectedSection);

            return <div id="mapContent">

                        <div id="mapArea" className="map-grid-container map-width">
                            <LowGradeMenu lowGradeMapInit={this.state.lowGradeMapInit} items={sectionNames} onItemSelected={this.props.onItemSelected} />
                        </div>
                        
                        <div ref="main" id="exeContent" key="exeArea" className="exe-area map-grid-container">
                            
                            <div  id="exeArea" className="exe-container map-width exe-hide exe-effect-1">                                                                                          
                                <LowGradeNav lowGradeMapInit={this.state.lowGradeMapInit} onBack={this.props.onBack}/>
                            
                                <div dangerouslySetInnerHTML={{__html: html}}>
                                </div>  
                            </div>

                            <span id="star-five" className="exe-star"><i className="fa fa-star"></i></span>

                            <div className="shadow-box"></div>
                                                                                                            
                        </div>                            
                </div>

        } else {
            return <BubbleLoader />;
        }
    }
}


class LowGradeNav extends React.Component<any, any> {

    constructor(props: any) {
        super(props);

        this.state = this.props.lowGradeMapInit;
    }

    componentDidMount() {
        this.setMapSize();       
    }

    componentDidUpdate() {  
        this.setMapSize(); 
    }

    setMapSize()
    {     
        var height : number;

        var gridX = this.state.gridX;
        var gridY = this.state.gridY;
        //var slideCount = this.state.slideCount;
        //var activeSlide = this.state.activeSlide;

        var ratio = gridX / gridY;
        height = Number($('.exe-area').height());

        $('.map-width').css('width', height * ratio + 'px');
        $('#exeArea').css('width', '800px'); // TODO ez nélkül használhatatlanok a feladatok, mert túl szűkek

        $(window).resize(function () { 
        
            var ratio = gridX / gridY;
            height = Number($('.map-grid').height());
           
            $('.map-width').css('width', height * ratio + 'px');           
            $('#exeArea').css('width', '800px'); // TODO ez nélkül használhatatlanok a feladatok, mert túl szűkek
            
          
        });

    }

    render() {
        return <div className="map-width map-menu-container">
                <div className="row lowgrade-menu">
                    <div className="column">
                        <Link to="/"><button><img src="/img/alsos_demo/home.png"/></button></Link>
                    </div>
                    <div className="column">
                        <button onClick={this.props.onBack}><img src="/img/alsos_demo/vissza.png"/></button>
                    </div>
                    <div className="column">
                        <button><img src="/img/alsos_demo/bevalt.png"/></button>
                    </div>
                    <div className="column">
                        <button><img src="/img/alsos_demo/hang.png"/></button>
                    </div>
                    <div className="column">
                        <button><img src="/img/alsos_demo/segito.png"/></button>
                    </div>
                </div>
            </div> 
    }

}

type LowGradeMenuProps = {
    items: string[];
    lowGradeMapInit: LowGradeMapState;
    onItemSelected: (selectedIndex: number) => void;
}


class LowGradeMenu extends React.Component<LowGradeMenuProps, any> {

    onItemSelected(index: number) {
        this.props.onItemSelected(index);
    }

    render() {
        return <Map items={this.props.items} lowGradeMapInit={this.props.lowGradeMapInit} onItemSelected={this.props.onItemSelected.bind(this)}/>    
    }

}


type LowGradeMapState = {
    gridX: number;
    gridY: number;
    slideCount : number,
    activeSlide : number,
    states : string[]
}

class Map extends React.Component<LowGradeMenuProps, LowGradeMapState> {

    constructor(props: any) {
        super(props);

        this.state = this.props.lowGradeMapInit;
        this.prevSlide = this.prevSlide.bind(this);
        this.nextSlide = this.nextSlide.bind(this);
    }

    onItemSelected(index: number) {
        console.log(index)
        this.props.onItemSelected(index);

    }

    prevSlide(e: any) {  

        if(this.state.activeSlide>1)
        {
            this.setState({activeSlide: this.state.activeSlide-1})
        }
    }

    nextSlide(e: any) {  
        if(this.state.activeSlide<this.state.slideCount)
        {
            this.setState({activeSlide: this.state.activeSlide+1})
        }          
    }


    componentDidMount() {
        this.setMapSize(false);       
    }

    componentDidUpdate() {  
        this.setMapSize(true); 
    }

    setMapSize(animate : boolean)
    {  
           
        var height : number;

        var gridX = this.state.gridX;
        var gridY = this.state.gridY;
        var slideCount = this.state.slideCount;
        var activeSlide = this.state.activeSlide;

        var ratio = gridX / gridY;
        height = Number($('.map-grid').height());

        $('.map-area').css('width', height * ratio * (slideCount+1) + 'px');
        // //$('#mapArea').css('width', height * ratio + 'px');
        if($('.map-width').length > 0)
        {
            $('.map-width').css('width', height * ratio + 'px');
        }
        
        if(animate)
        {
            $('.map-area').animate({'left': -(height * ratio * activeSlide) + "px"});
            $('.p-background').animate({'left': -((height/3) * ratio * activeSlide) + "px"});
        }
        else{
            $('.map-area').css('left', -(height * ratio * activeSlide));
            $('.p-background').css({'left': -((height/3) * ratio * activeSlide) + "px"});
        }
        
        


        $(window).resize(function () { 
        
            var ratio = gridX / gridY;
            height = Number($('.map-grid').height());

            $('.map-area').css('width', height * ratio * (slideCount+1) + 'px');
            //$('#mapArea').css('width', height * ratio + 'px');
            $('.map-width').css('width', height * ratio + 'px');
            //map left to active slide
            $('.map-area').css('left', -(height * ratio * activeSlide));
          
        });

    }

    render(){
      return(
        <MapGrid prevSlide={this.prevSlide} nextSlide={this.nextSlide} items={this.props.items} state={this.state} onItemSelected={this.props.onItemSelected.bind(this)} />
        
      )
    }
}

function MapGrid(props: any)
{

    console.log(props.items)

        var slideList = [];
        var slideCount = props.state.slideCount;

        for (var i = 1; i <= slideCount; i++)
        {
            slideList.push(CreateSlide(i, props.state, props.items, props.onItemSelected))
        } 

        return (
            <div className="map-grid-container">
                <div className="map-area">
                    <div className="map-slide" data-index="0">
                    </div>
                    {slideList}
                    <img className="map-img" src="/img/alsos_demo/utvonal3.png"/>  
                               
                </div>
                <div className="map-nav">
                        <button id="prevBtn" onClick={props.prevSlide}>{'{'}</button>
                        <button id="nextBtn" onClick={props.nextSlide}>{'}'}</button>
                    </div>   
            </div>
                        
        );
}

function CreateSlide(index: any, state: any, items: any, onItemSelected : any)
{

    var gridX = state.gridX;
    var gridY = state.gridY;

    
    var gridRows = [];        

    for (var y = 0; y < gridY; y++)
    {
        var gridColumns = [];

        for (var x = 0; x < gridX; x++)
        {            
            const i = state.states.indexOf(index + "/" + y + "/" + x);
            if(state.states.indexOf(index + "/" + y + "/" + x) > -1 && items.length > i)
            {
                

                gridColumns.push(<div className={"cell " + y + "/" + x}>
                    <div className="state"
                        onClick={() => onItemSelected(i)}
                    >
                        <span className="state-text">
                            {items[i]}
                        </span>
                    </div>
                </div>)
            }
            else
            {
                gridColumns.push(<div className={"cell " + y + "/" + x}></div>)
            }                            
        } 
        gridRows.push(
            <div className="grid-row">
                <div className="row">
                    {gridColumns}
                </div>
            </div>
        )   
    }

    return (
        <div className="map-slide" data-index={index}>
            <div className="map-grid">{gridRows}</div>
        </div>)
}

