import { IViewUsrFavouriteRecord } from "@src/framework/view/usr/ViewUsrFavourite";
import { PATH_EXERCISE_PUB_VIEW, PATH_EXERCISE_SERIES_PUB_VIEW, PATH_PUBLISHED_MEDIA_VIEW, PATH_EXERCISE_VIEW, PATH_PUBLISHED_BOOK, PATH_BOOK_VIEW_EDITING, PATH_EXERCISE_SERIES_VIEW, PATH_MEDIA_VIEW_EDITING, PATH_CONTENT_STORE, getPath } from "@src/Routes";

import OoFileCrud, { IOoFileRecord } from '@src/framework/crud/media/OoFileCrud';
import PubOoFileCrud from '@src/framework/crud/media_pub/OoFileCrud';

import OoFolderCrud, { IOoFolderRecord } from '@src/framework/crud/media/OoFolderCrud';
import PubOoFolderCrud from '@src/framework/crud/media_pub/OoFolderCrud';

import LessonCrud, { ILessonRecord } from '@src/framework/crud/doc/LessonCrud';
import PubLessonCrud from '@src/framework/crud/doc_pub/LessonCrud';

import ChapterCrud, { IChapterRecord } from "@src/framework/crud/doc/ChapterCrud";
import PubChapterCrud from "@src/framework/crud/doc_pub/ChapterCrud";

import BookCrud, { IBookRecord } from "@src/framework/crud/doc/BookCrud";
import PubBookCrud from "@src/framework/crud/doc_pub/BookCrud";

import ExerciseCrud, { IExerciseRecord } from '@src/framework/crud/exc/ExerciseCrud';
import PubExerciseCrud from '@src/framework/crud/exc_pub/ExerciseCrud';

import ExerciseSeriesCrud, { IExerciseSeriesRecord } from '@src/framework/crud/exc/ExerciseSeriesCrud';
import PubExerciseSeriesCrud from '@src/framework/crud/exc_pub/ExerciseSeriesCrud';

import { IUserViewEvent } from "@src/component/usr/usr_history_api";
import { MEDIA_TYPE_IMAGES, MEDIA_TYPE_VIDEO, MEDIA_TYPE_SOUND, MEDIA_TYPE_IFRAMES, MEDIA_TYPE_PDF } from "@src/framework/file/FileActions";
import SectionCrud, { ISectionRecord } from "@src/framework/crud/doc/SectionCrud";
import PubSectionCrud from "@src/framework/crud/doc_pub/SectionCrud";
import { getEnumKeyByValue } from "@src/Util";
import { __ } from '@src/translation';
import OpenEndedQuestionCrud, { IOpenEndedQuestionRecord } from "@src/framework/crud/usr/OpenEndedQuestionCrud";
import { UserEventTargets, UserEventTypes } from "@src/server/PublicServer";
import { exerciseModule } from "@src/module/exerciseModule/exerciseModule";

export enum ContentType {
    FILE_UNKNOWN = "Fájl",
    FILE_IMAGE = "Kép",
    FILE_VIDEO = "Videó",
    FILE_SOUND = "Hang",
    FILE_PDF = "PDF",
    FILE_INTERACTIVE = "Interaktív",
    FILE_WORD = "WORD",
    FILE_EXCEL = "EXCEL",

    FOLDER = "Mappa",

    EXERCISE = "Feladat",
    EXERCISE_SERIES = "Feladatsor",
    EXERCISE_SERIES_ADAPTIVE = "Adaptív feladatsor",    
    OPEN_ENDED_EXERCISE = "Nyílt végű feladat",
    LESSON = "Lecke",
    CHAPTER = "Fejezet",
}

export const FileTypeIcon = {
    FILE_UNKNOWN : "/img/IKON_SET/FLAT/file-empty.svg",
    FILE_IMAGE :  "/img/IKON_SET/FLAT/image.svg",
    FILE_VIDEO : "/img/IKON_SET/FLAT/movie.svg",
    FILE_SOUND : "/img/IKON_SET/FLAT/file-sound.svg",
    FILE_PDF : "/img/IKON_SET/FLAT/file-pdf.svg",
    FILE_ZIP : "/img/IKON_SET/FLAT/file-zip.svg",
    FILE_EXCEL : "/img/IKON_SET/FLAT/file-excel.svg",
    FILE_WORD : "/img/IKON_SET/FLAT/file-word.svg",
    FILE_PPT : "/img/IKON_SET/FLAT/file-powerpoint.svg",
    FILE_CODE : "/img/IKON_SET/FLAT/file-code.svg",
    FILE_TEXT : "/img/IKON_SET/FLAT/file-text.svg",
    FILE_INTERACTIVE : "/img/IKON_SET/FLAT/gamepad.svg",

    FOLDER : "/img/IKON_SET/FLAT/folder-open.svg",

     EXERCISE : "/img/IKON_SET/FLAT/exercise.svg",
     EXERCISE_SERIES : "/img/IKON_SET/FLAT/exercise.svg",
     EXERCISE_SERIES_ADAPTIVE : "/img/IKON_SET/FLAT/exercise.svg",
     LESSON : "/img/IKON_SET/FLAT/book.svg",
}

export function getContetnTypeIcon(contentType: ContentType)
{
    return FileTypeIcon[getEnumKeyByValue(ContentType, contentType)!]
}


export type ContentDetails = {

    /**
     * Tartalom típusa
     * */
    type: ContentType;

    /**
     * Felhasználó számára megjelenítendő név
     */
    displayName: string;

    /**
     * 
     */
    thumbnailUrl: string;

    /**
     * Létrehozás ideje
     */
    created: string;

    /**
     * URL, amin megtekinthető a rekord
     */
    viewUrl: string;

}

// TODO load multiple once, not one by one
export async function getContentDetailsList(records: {tableOrViewId: number, recordId: number}[]): Promise<(ContentDetails | undefined)[]>{
    const promises: Promise<(ContentDetails | undefined)>[] = [];
    const ret: (ContentDetails | undefined)[] = [];

    for(const record of records) {
        promises.push(getContentDetails(record.tableOrViewId, record.recordId));
    }
    
    for(const promise of promises) {
        try {
            ret.push(await promise);
        } catch(e) {/* ignore */}
    }

    return ret;
}

export async function getContentDetails(tableOrViewId: number, recordId: number): Promise<ContentDetails | undefined>{
    let record;
    
    switch(tableOrViewId) {

        case OoFileCrud.TABLE_INFO_ID:
            record = (await OoFileCrud.load(recordId)).record;
            return getFileDetails(record, false);

        case PubOoFileCrud.TABLE_INFO_ID:
            record = (await PubOoFileCrud.load(recordId)).record;
            return getFileDetails(record, true);

        case OoFolderCrud.TABLE_INFO_ID:
            record = (await OoFolderCrud.load(recordId)).record;
            return getFolderDetails(record, false);

        case PubOoFolderCrud.TABLE_INFO_ID:
            record = (await PubOoFolderCrud.load(recordId)).record;
            return getFolderDetails(record, true);

        case ExerciseCrud.TABLE_INFO_ID:
            let eRecord = (await ExerciseCrud.load(recordId)).record;
            return getExerciseDetails(eRecord, false);

        case PubExerciseCrud.TABLE_INFO_ID:
            eRecord = (await PubExerciseCrud.load(recordId)).record;
            return getExerciseDetails(eRecord, true);

        case ExerciseSeriesCrud.TABLE_INFO_ID:
            let esRecord = (await ExerciseSeriesCrud.load(recordId)).record;
            return getExerciseSeriesDetails(esRecord, false);

        case PubExerciseSeriesCrud.TABLE_INFO_ID:
            eRecord = (await PubExerciseSeriesCrud.load(recordId)).record;
            return getExerciseSeriesDetails(eRecord, true);
        case LessonCrud.TABLE_INFO_ID:
            let lRecord = (await LessonCrud.load(recordId)).record;
            let cRecord = (await ChapterCrud.load(lRecord.chapter_id || 0)).record;
            let bRecord = (await BookCrud.load(cRecord.book_id || 0)).record;
            return getLessonDetails(lRecord, bRecord, false);

        case PubLessonCrud.TABLE_INFO_ID:
            lRecord = (await PubLessonCrud.load(recordId)).record;
            cRecord = (await PubChapterCrud.load(lRecord.chapter_id || 0)).record;
            bRecord = (await PubBookCrud.load(cRecord.book_id || 0)).record;
            return getLessonDetails(lRecord, bRecord, true);

        case SectionCrud.TABLE_INFO_ID:
            let sRecord = (await SectionCrud.load(recordId)).record;
            lRecord = (await LessonCrud.load(sRecord.lesson_id || 0)).record;
            cRecord = (await ChapterCrud.load(lRecord.chapter_id || 0)).record;
            bRecord = (await BookCrud.load(cRecord.book_id || 0)).record;
            return getSectionDetails(sRecord, cRecord, lRecord, bRecord, false);

        case PubSectionCrud.TABLE_INFO_ID:
            sRecord = (await PubSectionCrud.load(recordId)).record;
            lRecord = (await PubLessonCrud.load(sRecord.lesson_id || 0)).record;
            cRecord = (await PubChapterCrud.load(lRecord.chapter_id || 0)).record;
            bRecord = (await PubBookCrud.load(cRecord.book_id || 0)).record;
            return getSectionDetails(sRecord, cRecord, lRecord, bRecord, true);
        
        case ChapterCrud.TABLE_INFO_ID:
            cRecord = (await ChapterCrud.load(recordId)).record;
            bRecord = (await PubBookCrud.load(cRecord.book_id || 0)).record;
            return getChapterDetails(cRecord, bRecord, true);

        case OpenEndedQuestionCrud.TABLE_INFO_ID:
            let oeRecord = (await OpenEndedQuestionCrud.load(recordId)).record;
            return getOpenEndedExeDetails(oeRecord);
    }

    return undefined;
}

export function getFileDetails(record: IOoFileRecord, published: boolean): ContentDetails {
    const d = getDetailForFile(record.id, record.sha1, record.media_type_id||undefined, record.ext || "");
    let contentType = d.contentType;
    let thumbnailUrl = d.thumbnailUrl;

    return {
        type: contentType,
        created: record.creation_time || "",
        displayName: record.displayname || "",
        thumbnailUrl: thumbnailUrl,
        viewUrl: (published ? PATH_PUBLISHED_MEDIA_VIEW : PATH_MEDIA_VIEW_EDITING ) + "/" + record.id
    }
}

export function getFolderDetails(record: IOoFolderRecord, published: boolean): ContentDetails {
    return {
        type: ContentType.FOLDER,
        created: record.creation_time || "",
        displayName: record.title || "",
        thumbnailUrl: FileTypeIcon.FOLDER,
        viewUrl: published ? "#" : PATH_CONTENT_STORE + "/sajat/" + record.id
    }
}


export function getExerciseDetails(record: IExerciseRecord, published: boolean): ContentDetails {
    let exercise = record.exercise;
    let thumbnailUrl = "/img/IKON_SET/FELADATMOTOR/Lista/exercise.png";

    if (exercise.illustration) {
        thumbnailUrl = exercise.imagebasepath + exercise.illustration;
        thumbnailUrl = thumbnailUrl.replace("/api/media/file/", "/api/media/thumb/500/")
    }/* else {
        var curr_converter = ExerciseTypeConverter.TypeConverterAssinment.Converter.find((element: ExerciseTypeConverter.ExerciseEngineInfo) => element. == this.props.engineName);

        if (curr_converter && curr_converter.Image) {
            thumbnailUrl = curr_converter.Image;
        }
    }*/
    
    return {
        type: ContentType.EXERCISE,
        created: record.creation_time || "",
        displayName: record.name || "",
        thumbnailUrl: thumbnailUrl,
        viewUrl: published ? (getPath({PATH_EXERCISE_PUB_VIEW}, exerciseModule) + "/" + record.id) : (getPath({PATH_EXERCISE_VIEW}, exerciseModule) + "/" + record.id)
    }
}

export function getExerciseSeriesDetails(record: IExerciseSeriesRecord, published: boolean): ContentDetails {
    let thumbnailUrl = "/img/IKON_SET/FELADATMOTOR/Lista/quiz.png";
    
    return {
        type: record.is_adaptive ? ContentType.EXERCISE_SERIES_ADAPTIVE : ContentType.EXERCISE_SERIES,
        created: record.creation_time || "",
        displayName: record.title || "",
        thumbnailUrl: thumbnailUrl,
        viewUrl: published ? (getPath({PATH_EXERCISE_SERIES_PUB_VIEW}, exerciseModule) + "/" + record.id) : (getPath({PATH_EXERCISE_SERIES_VIEW}, exerciseModule) + "/" + record.id)
    }    
}

export function getOpenEndedExeDetails(record: IOpenEndedQuestionRecord): ContentDetails {
    let thumbnailUrl = "/img/IKON_SET/FELADATMOTOR/Lista/openEndedQuestion.png";
    
    return {
        type: ContentType.OPEN_ENDED_EXERCISE,
        created: record.creation_time || "",
        displayName: record.subject || "",
        thumbnailUrl: thumbnailUrl,
        viewUrl:""// published ? (PATH_EXERCISE_SERIES_PUB_VIEW + "/" + record.id) : (PATH_EXERCISE_SERIES_VIEW + "/" + record.id)
    }
}

export function getLessonDetails(record: ILessonRecord, bookRecord: IBookRecord, published: boolean): ContentDetails {
    return {
        type: ContentType.LESSON,
        created: record.creation_time || "",
        displayName: record.name || "",
        viewUrl: published ? (PATH_PUBLISHED_BOOK + "/" + bookRecord.uri_segment + "/" + record.uri_segment) : (PATH_BOOK_VIEW_EDITING + "/" + bookRecord.id + "/" + record.uri_segment),
        thumbnailUrl: "/book/" + bookRecord.uri_segment + "/img/HEADER/logo.svg"
    }
}

export function getSectionDetails(record: ISectionRecord, cRecord: IChapterRecord, lRecord: ILessonRecord, bookRecord: IBookRecord, published: boolean): ContentDetails {
    return {
        type: ContentType.LESSON,
        created: record.creation_time || "",
        displayName: [bookRecord.name, cRecord.name, lRecord.name, record.name].filter(x => x).join(" / "),
        viewUrl: published 
            ? (PATH_PUBLISHED_BOOK + "/" + bookRecord.uri_segment + "/" + lRecord.uri_segment + "#section-" + record.id) 
            : (PATH_BOOK_VIEW_EDITING + "/" + bookRecord.id + "/" + lRecord.uri_segment + "#section-" + record.id),
        thumbnailUrl: "/book/" + bookRecord.uri_segment + "/img/HEADER/logo.svg"
    }
}

export function getChapterDetails(record: IChapterRecord, bookRecord: IBookRecord, published: boolean): ContentDetails {
    return {
        type: ContentType.CHAPTER,
        created: record.creation_time || "",
        displayName: [bookRecord.name, record.name].filter(x => x).join(" / "),
        viewUrl: published
            ? (PATH_PUBLISHED_BOOK + "/" +bookRecord.uri_segment) 
            : (PATH_BOOK_VIEW_EDITING) + "/" + bookRecord.id,
        thumbnailUrl: "/book/" + bookRecord.uri_segment + "/img/HEADER/logo.svg"
    }
}
 
export function getFavouriteDetails(record: IViewUsrFavouriteRecord): ContentDetails {
    let thumbnailUrl = "";
    let viewUrl = "";
    let contentType: ContentType = ContentType.FILE_UNKNOWN;
    let displayName = "";

    switch (record.table_name) {
        case 'exercise': {
            thumbnailUrl = "/img/IKON_SET/FELADATMOTOR/Lista/quiz.png";
            contentType = ContentType.EXERCISE;
            if (record.exc_illustration) {
                thumbnailUrl = record.exc_imagebasepath + record.exc_illustration;
            }

            displayName = record.object_name || "";
            viewUrl = (record.table_info_id === PubExerciseCrud.TABLE_INFO_ID ? getPath({PATH_EXERCISE_PUB_VIEW}, exerciseModule) : getPath({PATH_EXERCISE_VIEW}, exerciseModule)) + "/" + record.rec_id;
            break;
        }
        case 'exercise_series':
            displayName = record.object_name || "";
            contentType = ContentType.EXERCISE_SERIES;
            viewUrl = (record.table_info_id === PubExerciseSeriesCrud.TABLE_INFO_ID ? getPath({PATH_EXERCISE_SERIES_PUB_VIEW}, exerciseModule) : getPath({PATH_EXERCISE_SERIES_VIEW}, exerciseModule)) + "/" + record.rec_id;
            thumbnailUrl = "/img/IKON_SET/FELADATMOTOR/Lista/quiz.png";
            if (record.exc_illustration) {
                thumbnailUrl = record.exc_imagebasepath + record.exc_illustration;
            }

            break;

        case 'oo_file':
            
            const d = getDetailForFile(record.id, record.file_sha1 || "",record.file_media_type_id||undefined, record.file_ext || "");
            contentType = d.contentType;
            thumbnailUrl = d.thumbnailUrl;

            displayName = record.file_displayname || "";
            viewUrl = (record.table_info_id === PubOoFileCrud.TABLE_INFO_ID ? PATH_PUBLISHED_MEDIA_VIEW : PATH_MEDIA_VIEW_EDITING) + "/" + record.rec_id;
            break;
    }

    return {
        type: contentType,
        displayName: displayName,
        thumbnailUrl: thumbnailUrl,
        created: record.creation_time || "",
        viewUrl: viewUrl
    };
}

export function getDetailForFile(id?: number, sha1?: string, mediaTypeId?: number, extension?: string, width?: number) {

    width = width ? width : 200;

    let contentType = ContentType.FILE_UNKNOWN;
    let thumbnailUrl = "/api/media/thumb/"+ width + "/" + sha1;
    let filePath = "/api/media/file/" + sha1;

    /*

    TODO: lecserélni doc.generated_image-re? De csak cache-elt verziót használjon,
    on-the-fly ne generáljon!

    if(mediaTypeId && MEDIA_TYPE_IFRAMES.includes(mediaTypeId) || extension == ".html") {
        contentType = ContentType.FILE_INTERACTIVE;
        thumbnailUrl = "/api/media/screenshot/" +id;
    }
    */

    if ((mediaTypeId && MEDIA_TYPE_IMAGES.includes(mediaTypeId))
            || extension == ".png"
            || extension == ".jpg"
            || extension==  ".jpeg"
        ) {
        contentType = ContentType.FILE_IMAGE;
    }

    else if (mediaTypeId == MEDIA_TYPE_VIDEO || extension == ".mp4") {
        contentType = ContentType.FILE_VIDEO;
    }

    else if (mediaTypeId == MEDIA_TYPE_SOUND || extension == ".mp3") {
        contentType = ContentType.FILE_SOUND;
        thumbnailUrl = FileTypeIcon.FILE_SOUND;
    }
    
    else if(mediaTypeId && MEDIA_TYPE_PDF == mediaTypeId || extension == ".pdf") {
        contentType = ContentType.FILE_PDF;
        thumbnailUrl = FileTypeIcon.FILE_PDF;
    }

    else if(extension == ".doc" ||extension == ".docx") {
        contentType = ContentType.FILE_WORD;
        thumbnailUrl = FileTypeIcon.FILE_WORD;
    }
    else if(extension == ".xls" ||extension == ".xlsx") {
        contentType = ContentType.FILE_EXCEL;
        thumbnailUrl = FileTypeIcon.FILE_EXCEL;
    }

    else if (extension){
        thumbnailUrl = FileTypeIcon.FILE_UNKNOWN
    }
    
    return {
        contentType: contentType,
        thumbnailUrl: thumbnailUrl,
        fileUrl: filePath
    };

}

/**
 * Felhasználói eseményhez visszaadja az esemény nevét
 */
export function getUserEventName(e: IUserViewEvent) {

    let desc = "";
    switch(e.table_info_id) {

        case OoFileCrud.TABLE_INFO_ID:
        case PubOoFileCrud.TABLE_INFO_ID:
            if(e.data){
                if(e.data.eventTarget == UserEventTargets.VIMEO){
                    desc=__("Videó");
                }else if(e.data.eventTarget == UserEventTargets.IMAGE){
                    desc=__("Kép");
                }else if(e.data.eventTarget == UserEventTargets.SOUND){
                    desc=__("Hangfájl");
                }
                desc +=" ";

                if(e.data.eventType == UserEventTypes.START){
                    desc+=__("elindítva");
                }else if(e.data.eventType == UserEventTypes.ENDED){
                    desc+=__("lejátszva");
                }else if(e.data.eventType == UserEventTypes.VIEWED){
                    desc+=__("megtekintve");
                }

                desc+=".";
            }

            /*if (e.data && e.data.event == "vimeo" && e.data.vimeo_event == "play") {
                return __("Videó elindítva");
            }
            if (e.data && e.data.event == "vimeo" && e.data.vimeo_event == "ended") {
                return __("Videó végignézve");
            }
            if (e.data && e.data.event == "image" && e.data.vimeo_event == "opened") {
                return __("Kép megtekintve");
            }
            if (e.data && e.data.event == "sound" && e.data.sound_event == "play") {
                return __("Hanganyag elindítva");
            }
            if (e.data && e.data.event == "sound" && e.data.sound_event == "ended") {
                return __("Hanganyag végighallgatva");
            }
            if (e.data && e.data.event == "image" && e.data.image_event == "shown") {
                return __("Kép megtekintve");
            }*/
            break;
            // data.event = "sound";
            // data.sound_event = "play";
        case LessonCrud.TABLE_INFO_ID:
        case PubLessonCrud.TABLE_INFO_ID:
            return __("Lecke megtekintve");

        case ExerciseCrud.TABLE_INFO_ID:
        case PubExerciseCrud.TABLE_INFO_ID:

            if(e.data){                
                desc =__("Feladat") + " ";

                if(e.data.eventType == UserEventTypes.REQUESTED){
                    desc+=__("megnyitva");
                }else if(e.data.eventType == UserEventTypes.START){
                    desc+=__("elindítva");
                }else if(e.data.eventType == UserEventTypes.EVALUATE){
                    desc+=__("ellenőrizve");
                }else if(e.data.eventType == UserEventTypes.GET_CORRECT_SOLUTION){
                    desc+=__("helyes válasz lekérve");
                }

                desc+=".";
            }
       
            /*if (e.data && e.data.exercise_event == "get") {
                return __("Feladat megnyitva");
            }
            if (e.data && e.data.exercise_event == "start") {
                return __("Feladat elkezdve");
            }
            if (e.data && e.data.exercise_event == "evaluate") {
                return __("Feladat ellenőrizve");
            }
            if (e.data && e.data.exercise_event == "getcorrectsolution") {
                return __("Feladat helyes válasz kérés");
            }*/
            break;

        case ExerciseSeriesCrud.TABLE_INFO_ID:
        case PubExerciseSeriesCrud.TABLE_INFO_ID:

            if(e.data){                
                desc =__("Feladatsor") + " ";

                if(e.data.eventType == UserEventTypes.REQUESTED){
                    desc+=__("megnyitva");
                }else if(e.data.eventType == UserEventTypes.START){
                    desc+=__("elindítva");
                }

                desc+=".";
            }
            /*if(e.data && e.data.event == "get") {
                return __("Feladatsor megnyitva");
            }
            if(e.data && e.data.event == "start") {
                return __("Feladatsor elkezdve");
            }*/
            break;        
    }
    return null;
}



