import { Server } from '../../server/Server';
import View , { registerViewClassProxy, ViewClassProxy } from '../View';
import { IListParams } from '@framework/crud/Crud';

export interface IViewKapDraftRecord {
    /**
     * Táblázat infó
     *
     * Táblázat infó
     */
    table_info_id?: number|null;
    /**
     * Fajta
     *
     * DFHT-KIP / DFHT / Foglalkozásterv
     */
    kind?: string|null;
    /**
     * Azonosító
     *
     * Alap óravázlat, Komplex óravázlat, DFHT-KIP óravázlat vagy foglalkozásterv azonosítója.
     */
    id?: number;
    /**
     * types.journal.creation_time | Rekord létrehozás ideje
     *
     * types.journal.creation_time | Rekord létrehozás ideje
     */
    creation_time?: string /*timestamp?*/|null;
    /**
     * types.journal.creation_user_id -> sys.sec_user | Ki hozta létre
     *
     * types.journal.creation_user_id -> sys.sec_user | Ki hozta létre
     */
    creation_user_id?: number|null;
    /**
     * types.journal.creation_session_id | Melyik session-ből jött létre
     *
     * types.journal.creation_session_id | Melyik session-ből jött létre
     */
    creation_session_id?: number|null;
    /**
     * types.journal.modification_time | Utolsó módosítás ideje
     *
     * types.journal.modification_time | Utolsó módosítás ideje
     */
    modification_time?: string /*timestamp?*/|null;
    /**
     * types.journal.modification_user_id -> sys.sec_user | Ki módosította utoljára
     *
     * types.journal.modification_user_id -> sys.sec_user | Ki módosította utoljára
     */
    modification_user_id?: number|null;
    /**
     * types.journal.modification_session_id | Melyik session-ből módosították utoljára
     *
     * types.journal.modification_session_id | Melyik session-ből módosították utoljára
     */
    modification_session_id?: number|null;
    /**
     * types.logdel.is_active | Aktív
     *
     * types.logdel.is_active | Aktív
     */
    is_active?: boolean|null;
    /**
     * Szerzők
     *
     * Szerzők
     */
    authors?: string|null;
    /**
     * Téma
     *
     * Téma
     */
    topic?: string|null;
    /**
     * Cél
     *
     * Cél
     */
    goal?: string|null;
    /**
     * Csoport/Osztály típusa
     *
     * Csoport/Osztály típusa
     */
    group_type_id?: number|null;
    /**
     * Csoport/Osztály típusa
     *
     * Csoport/Osztály típusa
     */
    group_type_title?: string|null;
    /**
     * Csoport/Osztály típusa (egyéb)
     *
     * Csoport/Osztály típusa (egyéb)
     */
    group_type_other?: string|null;
    /**
     * Folyamat azonosító
     *
     * Folyamat azonosító
     */
    wf_workflow_id?: number|null;
    /**
     * Folyamat állapot azonosító
     *
     * Folyamat állapot azonosító
     */
    wf_station_id?: number|null;
    /**
     * Állapot
     *
     * Folyamat állapot neve
     */
    wf_station_name?: string|null;
    /**
     * Folyamat állapot leírása
     *
     * Folyamat állapot leírása
     */
    wf_station_description?: string|null;
    /**
     * Állapot stílus
     *
     * Folyamat állapot stílusa, jsonb/css
     */
    wf_station_style?: any|null;
    /**
     * Folyamat al-státusz azonosító
     *
     * Folyamat al-státusz azonosító
     */
    wf_substation_id?: number|null;
    /**
     * Folyamat al-státusz
     *
     * Folyamat al-státusz
     */
    wf_substation_name?: string|null;
    /**
     * Folyamat al-státusz leírása
     *
     * Folyamat al-státusz leírása
     */
    wf_substation_description?: string|null;
    /**
     * Folyamat szöveges állapot kiegészítés
     *
     * Folyamat szöveges állapot kiegészítés
     */
    wf_status_text?: string|null;
    /**
     * Publikálva van
     *
     * Publikálva van
     */
    is_published?: boolean|null;
    /**
     * Utoljára publikálva
     *
     * Utoljára publikálva
     */
    last_published?: string /*timestamp?*/|null;
    /**
     * Utolsó publikáló
     *
     * Utolsó publikáló
     */
    last_publisher_id?: number|null;
    /**
     * Létrehozta
     *
     * Létrehozta
     */
    creator?: string|null;
    /**
     * Módosító
     *
     * Módosító
     */
    modifier?: string|null;
    /**
     * Óraterv típus azonosító
     *
     * Óraterv típus azonosító
     */
    lesson_draft_type_id?: number|null;
    /**
     * Óraterv típus név
     *
     * Óraterv típus név
     */
    lesson_draft_type?: string|null;
    /**
     * Tantárgy azonosító
     *
     * Tantárgy azonosító
     */
    subject_id?: number|null;
    /**
     * Tantárgy név
     *
     * Tantárgy név
     */
    subject_name?: string|null;
    /**
     * Alprogram azonosító
     *
     * Alprogram azonosító
     */
    subprogram_id?: number|null;
    /**
     * Alprogram név
     *
     * Alprogram név
     */
    subprogram_title?: string|null;
    /**
     * Évfolyam azonosító
     *
     * Évfolyam azonosító
     */
    grade_id?: number|null;
    /**
     * Évfolyam név
     *
     * Évfolyam név
     */
    grade_name?: string|null;
    /**
     * Könyvtár azonosító
     *
     * Könyvtár azonosító
     */
    library_id?: number|null;
    /**
     * JTR beadandó folyamat azonosító
     *
     * JTR beadandó folyamat azonosító
     */
    jtr_workflow_id?: number|null;
    /**
     * JTR beadandó folyamat állapot azonosító
     *
     * JTR beadandó folyamat állapot azonosító
     */
    jtr_wf_station_id?: number|null;
    /**
     * JTR beadandó Állapot
     *
     * JTR beadandó folyamat állapot neve
     */
    jtr_wf_station_name?: string|null;
    /**
     * JTR beadandó Állapot stílus
     *
     * JTR beadandó folyamat állapot stílusa, jsonb/css
     */
    jtr_wf_station_style?: any|null;
    /**
     * JTR beadandó folyamat állapot szöveges kiegészítés
     *
     * JTR beadandó folyamat állapot szöveges kiegészítés
     */
    jtr_wf_status_text?: string|null;
    /**
     * Tulajdonos azonosítója
     *
     * Tulajdonos azonosítója
     */
    owner_id?: number|null;
    /**
     * Tulajdonos bejelentkezési neve
     *
     * Tulajdonos bejelentkezési neve
     */
    owner_login_name?: string|null;
    /**
     * Tulajdonos e-mail címe
     *
     * Tulajdonos e-mail címe
     */
    owner_email?: string|null;
    /**
     * Tulajdonos teljes neve
     *
     * Tulajdonos teljes neve
     */
    owner_fullname?: string|null;
    /**
     * Eredeti (amiből másolva lett)
     *
     * Eredeti (amiből másolva lett)
     */
    copied_from_id?: number|null;
    /**
     * Utolsó másolat
     *
     * Utolsó másolat
     */
    last_copy_id?: number|null;
    /**
     * Ellátási hely és támogatója
     *
     * Ellátási hely és támogatója
     */
    institute_site_supporter?: string|null;
    /**
     * Publikáláshoz hozzájárult
     *
     * Publikáláshoz hozzájárult
     */
    consent_publication?: string /*timestamp?*/|null;
    /**
     * Kiválónak jelölve
     *
     * Kiválónak jelölve
     */
    marked_excellent?: string /*timestamp?*/|null;
    /**
     * Utolsó publikáló
     */
    last_publisher?: string|null;
}

/**
 *  kap.view_kap_draft - KAP óravázlatok és foglalkozástervek
 *
 *  KAP óravázlatok és foglalkozástervek
 */

export default class ViewKapDraft extends View<IViewKapDraftRecord> {
    public static VIEW_INFO_ID = 20190718;

    public static getSchemaNameForClass(): string { return 'kap'; }
    public static getViewNameForClass(): string { return 'view_kap_draft'; }
    public static getTableNameForClass(): string|null { return 'null'; }

    public static load: (id: number, aServer ?: Server) => Promise<ViewKapDraft>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<IViewKapDraftRecord[]>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class ViewKapDraftClassProxy extends ViewClassProxy<IViewKapDraftRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const viewKapDraftClassProxy = new ViewKapDraftClassProxy(ViewKapDraft);
registerViewClassProxy(viewKapDraftClassProxy);

