import { callApiThrowOnError } from "@src/framework/api/Api";

export const TRANSLATION_STATUS_OK = 0;
export const TRANSLATION_STATUS_CHECK_TRANSLATION = 1;
export const TRANSLATION_STATUS_CHECK_CODE = 2;

export type GetTranslationsParams = {
    langId: number;
    details: boolean;
};

export type GetTranslationsResult = {
    translations: {
        key: string,
        value?: string,

        // Details:
        status?: number,
        history?: {
            created: string,
            userId: number,
            userName: string,
            value: string
        }[]
    }[],
};

export async function getTranslations(parameters: GetTranslationsParams): Promise<GetTranslationsResult> {
    return callApiThrowOnError<GetTranslationsParams, GetTranslationsResult>("/translation/getTranslations", parameters);
}
