import * as React from 'react';
import { IToggleState, IToggleProps, ToggleBase } from './Panel';

interface IToggleSelectState extends IToggleState
{
    selectedIndex?: any
}
interface IToggleSelectProps extends IToggleProps
{
    closeOnSelect?: boolean,
    selected: any,
    onChange?: (value:any)=>void
}
export class ToggleSelect
 extends ToggleBase<IToggleSelectProps, IToggleSelectState> {

    constructor(props: any) {
        super(props);

        this.state = {
            isOpen: false,
            isToggle: true,
            selectedIndex: this.getSelectedIndex()    
        }
    }

    getSelectedIndex()
    {
        var selectedIndex = React.Children.map(this.props.children, (child:any, index: number) =>
            child.type == SelectOption && child.props.value == this.props.selected
            ?
            index
            :
            null                    
        );
        return selectedIndex;
    }

    onSelect(index: number)
    {
        this.setState({selectedIndex: index, isOpen: (this.props.closeOnSelect != false ? false : true)}, ()=>{

            
            var selected = React.Children.map(this.props.children, (child:any, index: number) =>
                child.type == SelectOption && index == this.state.selectedIndex
                ?
                (child as SelectOption)
                :
                null                    
            );
           
         if(selected.length == 1 && selected[0] && this.props.onChange)
         {
            var selectedOption = selected[0]!;
            this.props.onChange(selectedOption.props.value);

            if(selectedOption.props.onSelect)
            {
                selectedOption.props.onSelect();
            }
         }


        });  
    }

    render() {

        const childrenWithProps = React.Children.map(this.props.children, (child:any, index: number) =>

            child.type == SelectOption && index != this.state.selectedIndex
            ?
            <li onClick={this.onSelect.bind(this, index)} className="select-item">{child}</li> 
            :
            null                     
        );


        return <>

            <div ref="togglePanel" id={this.props.id ? this.props.id : ""} className={"toggle-select " + (this.props.className ? this.props.className : "")}>
                {
                    this.state.selectedIndex != undefined
                    ? 
                    <div className={"select-header" + (this.state.isToggle ? " is-toggle" : "")} onClick={this.toggle.bind(this)}>
                        {
                            React.Children.map(this.props.children, (child:any, index: number) => 
                            index == this.state.selectedIndex 
                            ?           
                                child
                            : 
                                null                            
                            )
                        }
                    </div>
                    : null
                }
                
                <ul style={{display: this.state.isOpen ? "" : "none"}} ref="toggleContent" className={"select-menu"}>
                    {childrenWithProps}
                </ul>
            </div>

        </>
    }
}

export class SelectOption
 extends React.Component<{ value: any, className?: string, onSelect?: ()=>void }, {}> {
    render()
    {
        return this.props.children;
    };
 }