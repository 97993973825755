import * as React from 'react';
import Sidebar from '../../Sidebar';
import SplitPane from 'react-split-pane';
import { Link, Route } from 'react-router-dom';
import * as _ from 'lodash';
import { UIFormGuidelines } from './UIFormGuidelines';
import { Accordions } from '../Accordions';
import { Toggle, ToggleControl, AnimationMode, Card, Panel, EditorPage, EditorPanel, EditorSidebar, EditorSplit } from '../Panel';
import { ToggleSelect, SelectOption } from '../ToggleSelect';
import { AudioPlayer } from '../AudioPlayer';
import { VideoPlayer } from '../VideoPlayer';
import { MediaPlayer } from '../MediaPlayer';
import { VimeoControlPanel } from '../VimeoControlPanel';
import { UITabGuidelines } from './UITabGuidelines';
import { __ } from '@src/translation';
import { DialogsExample } from '../DialogExample';
import { Comment, CommentValue } from '../Comment';
import { EmojiPicker } from '../emojiPicker/EmojiPicker';


export class UIGuidelinesPage extends React.Component<{}, {}> {

    private elems = {};

    render() {
        return <div>
                <SplitPane split="vertical" minSize={150} maxSize={-150} defaultSize={200} style={{position: "relative", minHeight: "calc(100vh - 46px)", maxHeight: "calc(100vh - 46px)"}}>
                    <Sidebar>
                        <b>{__("Elemi komponensek")}</b>

                        <Link to="/test/ui/button">{ __("Gombok") }</Link>
                        <Link to="/test/ui/form">{ __("Form komponensek") }</Link>
                        <Link to="/test/ui/dialog">{ __("Dialogus ablak") }</Link>
                        <Link to="/test/ui/table">{ __("Táblázatok") }</Link>
                        <Link to="/test/ui/tab">{ __("Tabok")  }</Link>
                        <Link to="/test/ui/accordions">{ __("Accordion-ok") }</Link>
                        <Link to="/test/ui/panels">{ __("Panelek") }</Link>
                        <Link to="/test/ui/pages">{ __("Szerkesztői oldal") }</Link>
                        <Link to="/test/ui/player">{ __("Hanglejátszó") }</Link>
                        <Link to="/test/ui/mediaplayer">{ __("Médialejátszó") }</Link>
                        <Link to="/test/ui/vimeovideo">{ __("Vimeo Lejátszó Panel") }</Link>
                        <Link to="/test/ui/comment">{ __("Kommentezés tesztelés") }</Link>

                        <hr />
                        <Link to="/test/ui/css">{ __("CSS rule kereső") }</Link> 
                        <Link to="/test/ui/colors">{ __("Szín változók") }</Link>
                        <Link to="/test/ui/fonts">{ __("Szöveg stílus változók") }</Link>
                        <Link to="/test/ui/layout">{ __("Elrendezés változók") }</Link>

                    </Sidebar>
                        <div className="content-wrapper">
                            
                            <Route  exact path="/test/ui" component={UIGuideline}></Route>

                            <Route path="/test/ui/button" component={RenderButtons}></Route>
                            <Route path="/test/ui/form" component={UIFormGuidelines}></Route>
                            <Route path="/test/ui/dialog" component={DialogsExample}></Route>
                            <Route path="/test/ui/table" render={() => "TODO: " + __("irányelvek, példakódok a lapokon használt táblázatokhoz") }></Route>
                            <Route path="/test/ui/tab" component={UITabGuidelines}></Route>
                            <Route path="/test/ui/accordions" component={Accordions}></Route>
                            <Route path="/test/ui/panels" component={Panels}></Route>
                            <Route path="/test/ui/pages" component={Pages}></Route>
                            <Route path="/test/ui/player" component={AudioPlayer}></Route>
                            <Route path="/test/ui/video" component={VideoPlayer}></Route>
                            <Route path="/test/ui/mediaplayer" component={MediaPlayer}></Route>
                            <Route path="/test/ui/vimeovideo" component={VimeoControlPanel}></Route>
                            <Route path="/test/ui/comment" component={CommentTest}></Route>
                            <Route path="/test/ui/emoji" component={EmojiPicker}></Route>

                            <Route path="/test/ui/css" component={RenderCss}></Route>
                            <Route path="/test/ui/colors" component={RenderColors}></Route>
                            <Route path="/test/ui/fonts" component={RenderFontStyles}></Route>
                            <Route path="/test/ui/layout" component={RenderLayoutStyles}></Route>
                        </div>
                </SplitPane>
            </div>;
    }
}

function UIGuideline()
{
    return <>

    <h3>{__("Komponens struktúra")}</h3>

    <ul>
        <li>
            frontend - <b>{__("EKERNEL frontend forrás")}</b>
            <ul>
                <li>
                    src
                    <ul>
                        <li>
                            component - <b>{__("komponensek általános helye")}</b>
                            <ul>
                                <li>ui - <b>{__("elemi ui komponensek helye")}</b></li>
                            </ul>
                        </li>
                        <li>
                            project - <b>{__("projekt specifikus komponensek helye")}</b>
                            <ul>
                                <li>kap</li>
                                <li>nkp</li>
                                <li>...</li>
                            </ul>
                        </li>
                    </ul>
                </li>
            </ul>
        </li>       
    </ul>

    </>;
}

type FoundCSSRule = {
    file?: string,
    rule: string
};


class RenderCss extends React.Component<any, {filter: string, css: FoundCSSRule[], filtered?: FoundCSSRule[], filteredString?: string}> {

    constructor(props: any) {
        super(props);

        this.state = {
            filter: "",
            css: []
        }
    }

    componentDidMount() {
        const css: {
            file: string,
            rule: string,
        }[] = [];

        const styleSheets: any = document.styleSheets;
        for(const s of styleSheets) {
            var file = s.ownerNode.href;
            if (file) {
                if (file.includes("fontawesome.min.css")) continue;

                file = file.split("/").pop();
            }
            try {
                for(const r of s.cssRules) {
                    css.push({
                        file: file,
                        rule: r.cssText
                    })
                }
            } catch(e) {console.log(e); /* random error jön elő: InvalidAccessError: A parameter or an operation is not supported by the underlying object */}
        }

        this.setState({
            css
        });
    }

    componentDidUpdate() {
        this.doFilter();
    }

    doFilter = _.debounce(() => {
        if (this.state.filter) {
            const filtered = this.state.css.filter(c => 
                c.rule.toLowerCase().includes(this.state.filter.toLowerCase()) ||
                (c.file && c.file.toLowerCase().includes(this.state.filter.toLowerCase()))
            );
            this.setState({filtered, filteredString: this.state.filter})
        } else {
            this.setState({filtered: undefined, filteredString: undefined});
        }
    }, 500);

    render() {
        var css = this.state.css;
        if (this.state.filtered) {
            css = this.state.filtered;
        }

        return <>
            <br />
            
            <h4>{ __("CSS rule kereső") }</h4>

            <p>{ __("Ez a lap a css webdesignereknek készült, hogy megtalálják a duplikált / felesleges / hibás css-eket.") }</p>

            <label>
                { __("Szűrés") }
                <input type="text" value={this.state.filter} onChange={(e) => this.setState({filter: e.target.value})} />
            </label>
            <p>{css.length} { __("darab") }</p>
            <table>
                <tbody>
                    {
                        css.map(c => {
                            return <tr>
                                <td>{c.file && this.state.filteredString ? getHighlightedText(c.file, this.state.filteredString) : c.file}</td>
                                <td>{c.rule && this.state.filteredString ? getHighlightedText(c.rule, this.state.filteredString) : c.rule}</td>
                            </tr>
                        })
                    }
                </tbody>
            </table>

            </>;
    }
}

function findRules(match: RegExp) {

    const selectors: {[key: string]: string[]} = {};

    for(const stylesheet of Array.from(document.styleSheets)) {
        var file = stylesheet.ownerNode!["href"];
        if (file) {
            if (file.includes("fontawesome.min.css")) continue;
        }

        try {
            if (stylesheet instanceof CSSStyleSheet) {
                for(const rule of Array.from(stylesheet.cssRules)) {
                    matchSelectorText(rule, match, selectors);

                }
            }
        } catch(e) {console.log(e); /* random error jön elő: InvalidAccessError: A parameter or an operation is not supported by the underlying object */}
    }

    return selectors;
}

function matchSelectorText(rule: CSSRule, match: RegExp, selectors: {[key: string]: string[]}, prefix = "", postfix = "") {
    if (rule instanceof CSSMediaRule) {
                        
        for(const subRule of Array.from(rule.cssRules)) {
            matchSelectorText(subRule, match, selectors, rule.media + " { ", " } ");
        }

    } else if (rule instanceof CSSStyleRule) {

        const matches = rule.cssText.match(match);
        if (matches) {
            for(const match of matches) {
                if (!selectors[match]) selectors[match] = [];
                selectors[match].push(prefix + rule.selectorText + postfix);
            }
        }

    }
}

function RenderColors() {
    const colorSelectors = findRules(/rgb\(.*?\)|red|green|blue|brown|yellow|magenta|white|black|gray|lightgray|aqua|purple|cyan/g);
    
    const sortedColors = Object.entries(colorSelectors).sort((a, b) => b[1].length - a[1].length);
    
    return <>
    <br />

    <h5>{ __("A jelenlegi a rendszerben használt színek: {length} darab", {length: sortedColors.length}) }</h5>
    <table>
        <tbody>
            {
                sortedColors.map(c => {
                    return <tr key={c[0]}>
                        <td style={{width: "100px", backgroundColor: c[0]}}>&nbsp;</td>
                        <td style={{whiteSpace: "nowrap"}}>{c[0]}</td>
                        <td>{c[1].length}</td>
                        <td style={{fontSize: "10px", width: "70%"}}>{c[1].sort().join(", ")}</td>
                    </tr>
                })
            }
        </tbody>
    </table>
    </>;
}

function RenderFontStyles() {
    const fontSizeSelectors = findRules(/font-size: .*?[$;\}]/g);

    const sortedFontSizes = Object.entries(fontSizeSelectors).sort((a, b) => b[1].length - a[1].length);

    return <>
        <br />
        <h4>{ __("Betű stílusok") }</h4>

        <p>{ __("Ez a lap a webdesignereknek készült, a css-ben és komponensekben az alábbi betűstípusokat szabad használni.") }</p>

        <p>{sortedFontSizes.length} { __("darab") }</p>

        <table>
            <tbody>
                {
                    sortedFontSizes.map(f => {
                        return <tr key={f[0]}>
                            <td style={{fontSize: f[0].substr(11).replace(";", "")}}>ABC</td>
                            <td>{f[0]}</td>
                            <td>{f[1].length}</td>
                            <td>{f[1].join(", ")}</td>
                        </tr>
                    })
                }
            </tbody>
        </table>

        <table>
            <thead>
                <tr>
                    <th style={{width: "20%"}}>{ __("Név") }</th>
                    <th style={{width: "40%"}}>{ __("Kinézet") }</th>
                    <th style={{width: "20%"}}>{ __("Hol van használva?") }</th>
                    <th style={{width: "20%"}}>{ __("Számított CSS") }</th>
                </tr>
            </thead>
            <tbody>
                
                <tr>
                    <td>{ __("Címszöveg H1") }</td>
                    <td><h1 >{lorem()}</h1></td>
                    <td></td>
                    <td><PrevTDValues /></td>
                </tr>

                <tr>
                    <td>{ __("Címszöveg H2") }</td>
                    <td><h2 >{lorem()}</h2></td>
                    <td></td>
                    <td><PrevTDValues /></td>
                </tr>

                <tr>
                    <td>{ __("Címszöveg H3") }</td>
                    <td><h3 >{lorem()}</h3></td>
                    <td></td>
                    <td><PrevTDValues /></td>
                </tr>
                <tr>
                    <td>{ __("Címszöveg H4") }</td>
                    <td><h4 >{lorem()}</h4></td>
                    <td></td>
                    <td><PrevTDValues /></td>
                </tr>
                <tr>
                    <td>{ __("Címszöveg H5") }</td>
                    <td><h5 >{lorem()}</h5></td>
                    <td></td>
                    <td><PrevTDValues /></td>
                </tr>
                <tr>
                    <td>{ __("Címszöveg H6") }</td>
                    <td><h6 >{lorem()}</h6></td>
                    <td></td>
                    <td><PrevTDValues /></td>
                </tr>

                <tr>
                    <td>class=exe-editor-fieldlabel-1</td>
                    <td><span className="exe-editor-fieldlabel-1">{lorem()}</span></td>
                    <td></td>
                    <td><PrevTDValues /></td>
                </tr>

                <tr>
                    <td>{ __("Paragrafus") }</td>
                    <td><p >{lorem()}</p></td>
                    <td></td>
                    <td><PrevTDValues /></td>
                </tr>

                <tr>
                    <td>{ __("Normál szöveg") }</td>
                    <td><div>{lorem()}</div></td>
                    <td></td>
                    <td><PrevTDValues /></td>
                </tr>

                <tr>
                    <td>&lt;label&gt;</td>
                    <td><label >{lorem()}</label></td>
                    <td></td>
                    <td><PrevTDValues /></td>
                </tr>

                <tr>
                    <td>class=label</td>
                    <td><span className="label">{lorem()}</span></td>
                    <td>???</td>
                    <td><PrevTDValues /></td>
                </tr>

                <tr>
                    <td>class=form-label</td>
                    <td className="form"><label className="form-label">{lorem()}</label></td>
                    <td></td>
                    <td><PrevTDValues /></td>
                </tr>

                <tr>
                    <td>class=lms-pages-typeLabel</td>
                    <td><span className="lms-pages-typeLabel">{lorem()}</span></td>
                    <td></td>
                    <td><PrevTDValues /></td>
                </tr>

                <tr>
                    <td>class=badge</td>
                    <td><span className="badge">1</span></td>
                    <td>???</td>
                    <td><PrevTDValues /></td>
                </tr>

                <tr>
                    <td>class="tile-header"</td>
                    <td className="tile"><span className="tile-header">{lorem()}</span></td>
                    <td></td>
                    <td><PrevTDValues /></td>
                </tr>

                    <tr>
                        <td>React-table &lt;th&gt;</td>
                        <td><span className="rt-resizable-header" >{lorem()}</span></td>
                        <td></td>
                        <td><PrevTDValues /></td>
                    </tr>
                    <tr>
                        <td>React-table class="-btn"</td>
                        <td><span className="-btn" >{lorem()}</span></td>
                        <td></td>
                        <td><PrevTDValues /></td>
                    </tr>

                <tr>
                    <td>class="eke-home__title--light"</td>
                    <td className="tile"><span className="eke-home__title--light">{lorem()}</span></td>
                    <td></td>
                    <td><PrevTDValues /></td>
                </tr>
                
                <tr>
                    <td>React-table &lt;th&gt;</td>
                    <td><span className="rt-resizable-header" >{lorem()}</span></td>
                    <td></td>
                    <td><PrevTDValues /></td>
                </tr>
                <tr>
                    <td>React-table class="-btn"</td>
                    <td><span className="-btn" >{lorem()}</span></td>
                    <td></td>
                    <td><PrevTDValues /></td>
                </tr>

                <tr>
                    <td>&lt;table&gt; &lt;th&gt;</td>
                    <td><th>{lorem()}</th></td>
                    <td></td>
                    <td><PrevTDValues /></td>
                </tr>

                <tr>
                    <td>class="button"</td>
                    <td><span className="button" >{lorem()}</span></td>
                    <td></td>
                    <td><PrevTDValues /></td>
                </tr>

                <tr>
                    <td>class="button small"</td>
                    <td><span className="button small" >{lorem()}</span></td>
                    <td></td>
                    <td><PrevTDValues /></td>
                </tr>

                <tr>
                    <td>class="button tiny"</td>
                    <td><span className="button tiny" >{lorem()}</span></td>
                    <td>{ __("tartalomtár") }</td>
                    <td><PrevTDValues /></td>
                </tr>

                <tr>
                    <td>class="usr-like favourite"</td>
                    <td><span className="usr-like favourite" >{lorem()}</span></td>
                    <td></td>
                    <td><PrevTDValues /></td>
                </tr>

                <tr>
                    <td>{ __("Input mező") }</td>
                    <td><input type="text" defaultValue={lorem()} /></td>
                    <td></td>
                    <td><PrevTDValues /></td>
                </tr>

                <tr>
                    <td>class="eke-media-search__input"</td>
                    <td><input type="text" className="eke-media-search__input" defaultValue={lorem()} /></td>
                    <td></td>
                    <td><PrevTDValues /></td>
                </tr>

                <tr>
                    <td>class="eke-video-videoplayer-graytitle"</td>
                    <td><span className="eke-video-videoplayer-graytitle">{lorem()}</span></td>
                    <td></td>
                    <td><PrevTDValues /></td>
                </tr>

                <tr>
                    <td>class="container-exercise-title"</td>
                    <td><span className="exe-header container-exercise-title">{lorem()}</span></td>
                    <td></td>
                    <td><PrevTDValues /></td>
                </tr>

                <tr>
                    <td>class="eke-exerciseshow-graytitle"</td>
                    <td><span className="eke-exerciseshow-graytitle">{lorem()}</span></td>
                    <td></td>
                    <td><PrevTDValues /></td>
                </tr>

                <tr>
                    <td>class="wf-head-plugin-actual-station"</td>
                    <td><span className="wf-head-plugin-actual-station">{lorem()}</span></td>
                    <td></td>
                    <td><PrevTDValues /></td>
                </tr>

                <tr>
                    <td>class="content-item-list"</td>
                    <td><span className="content-item-list">{lorem()}</span></td>
                    <td>{ __("tartalomtár") }</td>
                    <td><PrevTDValues /></td>
                </tr>


                <tr>
                    <td>class="content-item-icon__text-main"</td>
                    <td><span className="content-item-icon__text-main">{lorem()}</span></td>
                    <td>{ __("tartalomtár") }</td>
                    <td><PrevTDValues /></td>
                </tr>

                <tr>
                    <td>class="image-select__breadcrumb-item"</td>
                    <td><span className="image-select__breadcrumb-item">{lorem()}</span></td>
                    <td>{ __("breadcrumb") }</td>
                    <td><PrevTDValues /></td>
                </tr>

                <tr>
                    <td>Link</td>
                    <td><a href="#">{lorem()}</a></td>
                    <td></td>
                    <td><PrevTDValues /></td>
                </tr>

                <tr>
                    <td>Link eke-sidebar</td>
                    <td className="eke-sidebar" style={{position: "static"}}><a href="#">{lorem()}</a></td>
                    <td>{ __("tartalomtár oldalt") }</td>
                    <td><PrevTDValues /></td>
                </tr>


                <tr>
                    <td>tabs-title Link</td>
                    <td className="tabs-title"><a href="#">{lorem()}</a></td>
                    <td>{ __("szerkesztő") }</td> 
                    <td><PrevTDValues /></td>
                </tr>


                <tr>
                    <td>&lt;small&gt;</td>
                    <td><small>{lorem()}</small></td>
                    <td></td>
                    <td><PrevTDValues /></td>
                </tr>
                
                <tr>
                    <td>&lt;b&gt;</td>
                    <td><b>{lorem()}</b></td>
                    <td></td>
                    <td><PrevTDValues /></td>
                </tr>
                
                {/*
                <tr>
                    <td>{ __("Tab címke) }</td>
                    <td><span>{lorem()}</span></td>
                    <td>???</td>
                    <td><PrevTDValues /></td>
                </tr>

                <tr>
                    <td>progress-indicator</td>
                    <td><span>{lorem()}</span></td>
                    <td>???</td>
                    <td><PrevTDValues /></td>
                </tr>

                <tr>
                    <td>{ __("Dialógusablak-cím") }</td>
                    <td><span>{lorem()}</span></td>
                    <td>???</td>
                    <td><PrevTDValues /></td>
                </tr>
                */}

            </tbody>
        </table>
        </>
}

function lorem () {
    return "Lorem ipsum dolor sit ame"; // Latin placeholder text, do not translate
}

class PrevTDValues extends React.Component {
    state: {element?: HTMLElement} = {element: undefined};

    componentDidMount() {
        const root = this.refs.root as HTMLDivElement;
        const element = root.parentElement!.previousElementSibling!.previousElementSibling!.children[0];
        this.setState({element});
    }

    render() {
        if (!this.state.element) {
            return <div ref="root"/>;
        } else {
            const computedStyle = window.getComputedStyle(this.state.element);
            return <small>
                <div style={{overflow: "hidden", whiteSpace: "nowrap"}}>
                    {computedStyle.fontFamily!.startsWith('"Open Sans') ? computedStyle.fontFamily : <b>{computedStyle.fontFamily}</b>}
                </div>
                font-size: <b>{computedStyle.fontSize}</b>
                  {computedStyle.textTransform != "none" ? ", " + computedStyle.textTransform : ""}
                  <b>{Number(computedStyle.fontWeight) > 400 ? ", bold" : ""}</b>
                , color: {computedStyle.color == "rgb(10, 10, 10)" ? computedStyle.color : <b>{computedStyle.color}</b>}
                {/*, line-height: {computedStyle.lineHeight}*/}
            </small>;
        }
    }
}

// https://stackoverflow.com/a/43235785/834966
function getHighlightedText(text: string, higlight: string) {
    // Split on higlight term and include term into parts, ignore case
    let parts = text.split(new RegExp(`(${higlight})`, 'gi'));
    return <span> { parts.map((part, i) => 
        <span key={i} style={part.toLowerCase() === higlight.toLowerCase() ? { fontWeight: 'bold' } : {} }>
            { part }
        </span>)
    } </span>;
}


function RenderLayoutStyles() {
    const margin = findRules(/margin.*?[$;\}]/g);
    const padding = findRules(/padding.*?[$;\}]/g);
    const zIndex = findRules(/z-index: .*?[$;\}]/g);

    return <>
        <br />
        <h4>Margin / padding / z-index</h4>

        <h5>Margin: {Object.keys(margin).length} { __("darab") }</h5>
        { ruleTable(margin) }

        <h5>Padding: {Object.keys(padding).length} { __("darab") }</h5>
        { ruleTable(padding) }

        <h5>Z-index: {Object.keys(zIndex).length} { __("darab") }</h5>
        { ruleTable(zIndex) }

    </>;
}

function ruleTable(margin: {[key: string]: string[]}) {
    return <>
        <table>
            <tbody>
                {Object.entries(margin).sort((a, b) => b[1].length - a[1].length).map(r => {
                    return <tr>
                        <td>{r[0]}</td>
                        <td>{r[1].length}</td>
                        <td style={{fontSize: "10px"}}>{r[1].join(", ")}</td>
                    </tr>
                })}
            </tbody>
        </table>
    </>
}


function RenderButtons() {

    return <>

        <h4>{ __("A jelenlegi rendszerben használt gombok") }:</h4>


        <h5>{ __("Alap random funkció gombok") }</h5>
        <button className="button">class=button</button> <button className="button hollow">class=button hollow</button> <br />

        <h5>{ __("Fő gombok") }</h5>
        <button className="button primary">class=button primary</button>  <button className="button primary hollow">class=button primary hollow</button> <br />

        <h5>{ __("Másodlagos random gombok, megnyitás/megtekintés") }</h5>
        <button className="button secondary">class=button secondary</button> <button className="button secondary hollow">class=button secondary hollow</button> <br />

        <h5>{ __("Mentés gomb") }</h5>
        <button className="button success">class=button success</button> <br />

        <h5>{ __("Törlések, néha mégse, bezár") }</h5>
        <button className="button alert">class=button alert</button> <br />
        
        <button className="button warning">class=button warning</button> <br />
        
        <h5>{ __("Gombok ikonal") }</h5>
        { __("Mentés") } <button className="button success save">class=button success save</button>  <br />
        { __("Megosztás") } <button className="button share">class=button share</button> <br />
        { __("Szerkesztés") } <button className="button edit">class=button edit</button> <br />
        { __("Vissza/Tovább") } <button className="button back">class=button back</button> <button className="button next">class=button back</button> <br />

        <h5>WF</h5>
        <button className="button wf-head-plugin-transition-button">WF-gombok</button> <br />

        <h5>{ __("Lapozás") }</h5>
        <div className="pagination"><a className="active">1</a><a className="">2</a><a className="">3</a><a className="">4</a><a className="">5</a></div> <br/>
        

    </>;

}

// Latin placeholder text, do not translate
const loremIpsum = "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc molestie vitae arcu nec cursus. Pellentesque interdum mi tortor, vel tincidunt orci venenatis vitae. Aliquam sodales congue tristique. Phasellus porta, lorem ac finibus consectetur, velit lectus maximus eros, quis sollicitudin justo metus eget purus. Sed a tincidunt est. Nam tincidunt leo consequat scelerisque molestie. Vestibulum tristique neque sit amet arcu mattis, non pulvinar felis efficitur. ";

export class Panels extends React.Component<{}, { openItem: any, animationIn :AnimationMode, animationOut :AnimationMode}> {

    toggleElement: Toggle;

    constructor(props: any) {
        super(props);

        this.state = {
            openItem: null,
            animationIn: AnimationMode.fadeIn,
            animationOut: AnimationMode.scaleOutDown
        }
    }
    componentDidMount()
    {
        this.toggleElement = this.refs.toggleElement as Toggle;
    }

    toggle()
    {
        this.toggleElement.toggle();
    }

    animationChange(event:any)
    {

        this.setState({animationIn:  event.target.value });
    }

    render()
    {

        return <>
    
        <h3>Toggle</h3>
        
        <Toggle id="toggle" ref="toggleElement" header="Toggle Header">
            {loremIpsum}
        </Toggle>
        <br/>
        <ToggleControl className="button" id="toggle">Toggle Control</ToggleControl>
        <br/>
        <button className="button" onClick={this.toggle.bind(this)}>Toggle by ref</button>


        <h3>{ __("Toggle animációval") }</h3>

        <label>Animate In</label>
        <select onChange={this.animationChange.bind(this)}>
        <optgroup label="Fade">
            <option value="fade-in">fade-in</option>
            <option value="fade-out">fade-out</option>
            </optgroup>
            <optgroup label="Toggle">
            <option value="toggle-in">toggle-in</option>
            <option value="toggle-out">toggle-out</option>
            </optgroup>
            <optgroup label="Slide">
            <option value="slide-in-down">slide-in-down</option>
            <option value="slide-in-left">slide-in-left</option>
            <option value="slide-in-up">slide-in-up</option>
            <option value="slide-in-right">slide-in-right</option>
            <option value="slide-out-down">slide-out-down</option>
            <option value="slide-out-left">slide-out-left</option>
            <option value="slide-out-up">slide-out-up</option>
            <option value="slide-out-right">slide-out-right</option>
            </optgroup>            
            <optgroup label="Hinge">
            <option value="hinge-in-from-top">hinge-in-from-top</option>
            <option value="hinge-in-from-right">hinge-in-from-right</option>
            <option value="hinge-in-from-bottom">hinge-in-from-bottom</option>
            <option value="hinge-in-from-left">hinge-in-from-left</option>
            <option value="hinge-in-from-middle-x">hinge-in-from-middle-x</option>
            <option value="hinge-in-from-middle-y">hinge-in-from-middle-y</option>
            <option value="hinge-out-from-top">hinge-out-from-top</option>
            <option value="hinge-out-from-right">hinge-out-from-right</option>
            <option value="hinge-out-from-bottom">hinge-out-from-bottom</option>
            <option value="hinge-out-from-left">hinge-out-from-left</option>
            <option value="hinge-out-from-middle-x">hinge-out-from-middle-x</option>
            <option value="hinge-out-from-middle-y">hinge-out-from-middle-y</option>
            </optgroup>
            <optgroup label="Scale">
            <option value="scale-in-up">scale-in-up</option>
            <option value="scale-in-down">scale-in-down</option>
            <option value="scale-out-up">scale-out-up</option>
            <option value="scale-out-down">scale-out-down</option>
            </optgroup>
            <optgroup label="Spin">
            <option value="spin-in">spin-in</option>
            <option value="spin-out">spin-out</option>
            <option value="spin-in-ccw">spin-in-ccw</option>
            <option value="spin-out-ccw">spin-out-ccw</option>
            </optgroup>
        </select>

        <Toggle id="toggle2" ref="toggleElement2" header="Toggle Header" animationIn={this.state.animationIn} animationOut={AnimationMode.toggleOut}>
            {loremIpsum}
        </Toggle>
        <br/>
        <ToggleControl className="button" id="toggle2">Toggle Control</ToggleControl>

        <h3>Card</h3>

        <Card header="Card title">
            {loremIpsum}
        </Card>

        <h3>Panel</h3>

        <Panel header="Panel title">
            {loremIpsum}
        </Panel>

        <h3>Toggle Panel isToggle=true</h3>

        <Panel isToggle={true} header="Panel title">
            {loremIpsum}
        </Panel>

        <h3>Toggle Select</h3>

        <ToggleSelect selected="1">
            <SelectOption onSelect={()=>null} value="1">Option 1</SelectOption>
            <SelectOption onSelect={()=>null}value="2">Option 2</SelectOption>
        </ToggleSelect>
        </>

    }
    
}

export class Pages extends React.Component<{}, {}> {


    render()
    {
        return <EditorPage>
                    <EditorSplit>           
                    <EditorSidebar>Sidebar</EditorSidebar>
                    <EditorPanel closable={true}>panel 2</EditorPanel>
                    </EditorSplit>
                </EditorPage>;
    }
}

type CommentTestState = {
    value?: CommentValue;
}

export class CommentTest extends React.Component<never, CommentTestState> {

    constructor(props: never) {
        super(props);

        this.state = {

        }
    }

    render() {
        return <Comment 
            value={this.state.value}
            onChange={value => this.setState({value})}
        />
    }
}
