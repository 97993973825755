import * as React from "react";
import CourseCrud, { ICourseRecord } from "@src/framework/crud/kap/CourseCrud";
import { __ } from "@src/translation";
import { app } from "@src/index";

enum FolderState {
    NotSent,
    SentToLocation,
    NotArrived,
    NotComplete,
    Ok
}

type ChecklistState = {
    folderState?: FolderState;
    folderSpine?: boolean;
    id?: boolean;
    dateOfCourse?: boolean;
    accNumber?: boolean;
    matchingDate?: boolean;
    spine?: boolean;
    attendenceList?: boolean;
    progressJournal?: boolean;
    contracts?: boolean;
    participantInfo?: boolean;
    report?: boolean;
    //certificateRecieve?:boolean;
    trainingJournal?: boolean;
    attendanceMatch?: boolean;
    attendanceList1?: boolean;
    attendanceList2?: boolean;
    applicationForm?: boolean;
    KAKDiplomCopy?: boolean;
    KAKStatement?: boolean;
    reasonForDifference?: boolean;
    certificateCopy?: boolean;
    certificateRecieve?: boolean;
    locationAnnouncement?: boolean;
    OSAP?: boolean;
    photos?: boolean;
}

type ChecklistProps = {
    courseRecord: ICourseRecord,
    isReadOnly: boolean
}

export class CourseChecklist extends React.Component<ChecklistProps, ChecklistState> {
    constructor(props: ChecklistProps) {
        super(props);
        let tmp: ChecklistState = { id: false };
        if (props.courseRecord.other_jsonb)
            tmp = JSON.parse(JSON.stringify(props.courseRecord.other_jsonb));

        this.state = tmp;
    }


    onCheckChange(ev: any, fieldName: string) {
        if (ev && ev.target) {
            this.setState({ ...this.state, [fieldName]: ev.target.checked });
        }
    }
    onSelectChange(ev: any) {
        if (ev && ev.target) {
            this.setState({ folderState: ev.target.value });
        }
    }

    async onSave() {
        try {
            if (!this.props.courseRecord) return;
            let tmp = this.props.courseRecord;
            let newRec: ICourseRecord = { id: tmp.id, other_jsonb: JSON.stringify(this.state as ChecklistState) };
            let crud = new CourseCrud(newRec);
            newRec = (await crud.put()).record;
            this.setState(JSON.parse(JSON.stringify(newRec.other_jsonb)));
            app.showSuccess("Mentés", "Sikeres mentés!")

        } catch (error) {
            app.showErrorFromJsonResult(error);
        }
    }

    render() {

        return <div>
            <div>
                <select id="folder_selector_id" disabled={this.props.isReadOnly} value={this.state.folderState} onChange={this.onSelectChange.bind(this)}>
                    <option value={FolderState.NotSent}>{__("Még nincs kiküldve")}</option>
                    <option value={FolderState.SentToLocation}>{__("Kiküldve a képzés helyszínére")}</option>
                    <option value={FolderState.NotArrived}>{__("Nem érkezett be")}</option>
                    <option value={FolderState.NotComplete}>{__("Hiányosan érkezett be")}</option>
                    <option value={FolderState.Ok}>{__("Rendben")}</option>
                </select>
            </div>
            {this.state.folderState == FolderState.NotComplete && <div>
                <h5>Hiányzó dokumentumok</h5>
                <label><input type="checkbox" disabled={this.props.isReadOnly} checked={this.state.folderSpine || false} onChange={(event)=>this.onCheckChange(event, "folderSpine")} />{__("Mappa gerinc")}</label>
                <label><input type="checkbox" disabled={this.props.isReadOnly} checked={this.state.id || false} onChange={(event) => this.onCheckChange( event, "id")} />{__("Id")}</label>
                <label><input type="checkbox" disabled={this.props.isReadOnly} checked={this.state.dateOfCourse || false} onChange={(event) => this.onCheckChange( event, "dateOfCourse")} />{__("Képzés dátuma")}</label>
                <label><input type="checkbox" disabled={this.props.isReadOnly} checked={this.state.accNumber || false} onChange={(event) => this.onCheckChange( event, "accNumber")} />{__("Akk. szám")}</label>
                <label><input type="checkbox" disabled={this.props.isReadOnly} checked={this.state.matchingDate || false} onChange={(event) => this.onCheckChange( event, "matchingDate")} />{__("Dátum egyezőség")}</label>
                <label><input type="checkbox" disabled={this.props.isReadOnly} checked={this.state.spine || false} onChange={(event) => this.onCheckChange( event, "spine")} />{__("Gerinc")}</label>
                <label><input type="checkbox" disabled={this.props.isReadOnly} checked={this.state.attendenceList || false} onChange={(event) => this.onCheckChange( event, "attendenceList")} />{__("Jelenléti ív")}</label>
                <label><input type="checkbox" disabled={this.props.isReadOnly} checked={this.state.progressJournal || false} onChange={(event) => this.onCheckChange( event, "progressJournal")} />{__("Haladási napló")}</label>
                <label><input type="checkbox" disabled={this.props.isReadOnly} checked={this.state.contracts || false} onChange={(event) => this.onCheckChange( event, "contracts")} />{__("Szerződések")}</label>
                <label><input type="checkbox" disabled={this.props.isReadOnly} checked={this.state.participantInfo || false} onChange={(event) => this.onCheckChange( event, "participantInfo")} />{__("Résztvevői tájékoztató")}</label>
                <label><input type="checkbox" disabled={this.props.isReadOnly} checked={this.state.report || false} onChange={(event) => this.onCheckChange( event, "report")} />{__("Jegyzőkönyv")}</label>
                <label><input type="checkbox" disabled={this.props.isReadOnly} checked={this.state.trainingJournal || false} onChange={(event) => this.onCheckChange( event, "trainingJournal")} />{__("Továbbképzési napló")}</label>
                <label><input type="checkbox" disabled={this.props.isReadOnly} checked={this.state.attendanceMatch || false} onChange={(event) => this.onCheckChange( event, "attendanceMatch")} />{__("Létszám egyezőség")}</label>
                <label><input type="checkbox" disabled={this.props.isReadOnly} checked={this.state.attendanceList1 || false} onChange={(event) => this.onCheckChange( event, "attendanceList1")} />{__("Jelenléti ív 1.nap")}</label>
                <label><input type="checkbox" disabled={this.props.isReadOnly} checked={this.state.attendanceList2 || false} onChange={(event) => this.onCheckChange( event, "attendanceList2")} />{__("Jelenléti ív 2.nap")}</label>
                <label><input type="checkbox" disabled={this.props.isReadOnly} checked={this.state.applicationForm || false} onChange={(event) => this.onCheckChange( event, "applicationForm")} />{__("Jelentkezési lap")}</label>
                <label><input type="checkbox" disabled={this.props.isReadOnly} checked={this.state.KAKDiplomCopy || false} onChange={(event) => this.onCheckChange( event, "KAKDiplomCopy")} />{__("Diploma másolat - KAK")}</label>
                <label><input type="checkbox" disabled={this.props.isReadOnly} checked={this.state.KAKStatement || false} onChange={(event) => this.onCheckChange( event, "KAKStatement")} />{__("Nyilatkozat tudástárhoz")}</label>
                <label><input type="checkbox" disabled={this.props.isReadOnly} checked={this.state.reasonForDifference || false} onChange={(event) => this.onCheckChange( event, "reasonForDifference")} />{__("Indok az eltérésről")}</label>
                <label><input type="checkbox" disabled={this.props.isReadOnly} checked={this.state.certificateCopy || false} onChange={(event) => this.onCheckChange( event, "certificateCopy")} />{__("Tanúsítvány másolat")}</label>
                <label><input type="checkbox" disabled={this.props.isReadOnly} checked={this.state.certificateRecieve || false} onChange={(event) => this.onCheckChange( event, "certificateRecieve")} />{__("Tanúsítvány átvételi")}</label>
                <label><input type="checkbox" disabled={this.props.isReadOnly} checked={this.state.locationAnnouncement || false} onChange={(event) => this.onCheckChange( event, "locationAnnouncement")} />{__("Helyszín bejelentő")}</label>
                <label><input type="checkbox" disabled={this.props.isReadOnly} checked={this.state.OSAP || false} onChange={(event) => this.onCheckChange( event, "OSAP")} />{__("OSAP")}</label>
                <label><input type="checkbox" disabled={this.props.isReadOnly} checked={this.state.photos || false} onChange={(event) => this.onCheckChange( event, "photos")} />{__("Fotódokumentáció")}</label>
            </div>}
            {!this.props.isReadOnly && <div>
                <button className="button success expanded eke-general-buttons" onClick={this.onSave.bind(this, false)}>
                    <i className="fa fa-save" /> {__("Mentés")}
                </button>
            </div>}
        </div>;
    }
}