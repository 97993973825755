import { Server } from '../../server/Server';
import Crud , { registerCrudClassProxy, IAsTextResult, IListParams, CrudClassProxy, ISearchTextParams, ISearchTextResult } from '../Crud';

export interface ICourseContactDayRecord {
    /**
     * Globálisan egyedi azonosító.
     * 
     * Ha új rekordot akarsz létrehozni put() metódussal, akkor hagyd üresen.
     * upsert() hívásnál figyelmen kívül van hagyva az értéke.
     *
     */
    id ?: number;
    /**
     * Rekord létrehozás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel létrehozásakor.
     */
    creation_time?: string /*timestamp?*/;
    /**
     * Ki hozta létre
     *
     * NOT NULL -> sys.sec_user ON DELETE noaction
     *
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozó felhasználóra hivatkozik.
     */
    creation_user_id?: number;
    /**
     * Melyik session-ből jött létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozáshoz használt munkamenetre hivatkozik.
     */
    creation_session_id?: number|null;
    /**
     * Utolsó módosítás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel módosításakor.
     */
    modification_time?: string /*timestamp?*/|null;
    /**
     * Ki módosította utoljára
     *
     * -> sys.sec_user ON DELETE noaction
     *
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosító felhasználóra hivatkozik.
     */
    modification_user_id?: number|null;
    /**
     * Melyik session-ből módosították utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosításhoz használt munkamenetre hivatkozik.
     */
    modification_session_id?: number|null;
    /**
     * Aktív
     *
     * Logikai törléshez használt mező.
     */
    is_active?: boolean;
    /**
     * Képzés
     *
     * NOT NULL -> kap.course ON DELETE cascade
     *
     */
    course_id?: number;
    /**
     * Sorrend
     */
    no?: number /*integer*/;
    /**
     * Képzés helye
     *
     * -> sys.institute_site ON DELETE noaction
     *
     *
     * Csak akkor kell megadni, ha eltér a képzés alapértemezett helyétől
     */
    institute_site_id?: number|null;
    /**
     * Képzés helye szövegesen
     *
     * Ezt csak akkor kell megadni, ha a képzés helye nem egy feladatellátási hely, vagy ha kiegészítésre szorul (pl. emelet, terem száma stb.)
     */
    address?: string|null;
    /**
     * A kontakt nap gyökér könyvtára.
     *
     * NOT NULL -> media.oo_folder ON DELETE noaction
     *
     */
    oo_folder_id?: number;
    /**
     * Hivatalos időpont kezdete
     */
    official_start?: string /*timestamp?*/;
    /**
     * Hivatalos időpont vége
     */
    official_end?: string /*timestamp?*/|null;
    /**
     * Valós időpont kezdete
     */
    real_start?: string /*timestamp?*/;
    /**
     * Valós időpont vége
     */
    real_end?: string /*timestamp?*/|null;
}

/**
 *  kap.course_contact_day - Képzés kontakt időpont
 *
 *  Idősoros táblázat, ami az összes tétel összes korábbi verzióját eltárolja.
 */

export default class CourseContactDayCrud extends Crud<ICourseContactDayRecord> {
    public static TABLE_INFO_ID = 3773099160;
    public static getSchemaNameForClass() { return 'kap'; }
    public static getTableNameForClass() { return 'course_contact_day'; }

    public static load: (id: number, aServer ?: Server) => Promise<CourseContactDayCrud>;
    public static asText: (id: number, includeRecord : boolean, aServer ?: Server) => Promise<IAsTextResult<ICourseContactDayRecord>>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<ICourseContactDayRecord[]>;
    public static searchText: (params: ISearchTextParams, aServer ?: Server) => Promise<ISearchTextResult<ICourseContactDayRecord>[]>;
    public static deleteById: (id: number, aServer ?: Server) => Promise<ICourseContactDayRecord>;
    public static unDeleteById: (id: number, aServer ?: Server) => Promise<ICourseContactDayRecord>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class CourseContactDayCrudClassProxy extends CrudClassProxy<ICourseContactDayRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const courseContactDayCrudClassProxy = new CourseContactDayCrudClassProxy(CourseContactDayCrud);
registerCrudClassProxy(courseContactDayCrudClassProxy);
