import * as React from "react";
import { ICourseRecord } from "@src/framework/crud/kap/CourseCrud";
import { ICourseContactDayRecord } from "@src/framework/crud/kap/CourseContactDayCrud";
import { __ } from "@src/translation";
import { formatDate } from "@src/Util";
import MediaFileChooser, { ContentActionType, ALL_ACTIONS } from "@src/component/filemanager/MediaFileChooser";
import { me, hasGroup, Groups, hasAnyGroup } from "@src/framework/server/Auth";
import { Accordion, AccordionItem, ActionAccordionItem } from '@src/component/ui/Accordion';
import { viewKapCourseOsapClassProxy } from "@src/framework/view/kap/ViewKapCourseOsap";

type CourseDocumentComponentProps = {
    currentCourse?: ICourseRecord,
    contactDates?: ICourseContactDayRecord[]
}

export default class CourseDocumentComponent extends React.Component<CourseDocumentComponentProps, any> {

    constructor(props: any) {
        super(props);

        this.state = {
        };
    }

    render() {
        if (!this.props.currentCourse) return "";

        let filePermits: number[] = [ContentActionType.FILE_DOWNLOAD];

        // const wf_id = this.props.currentCourse.wf_station_id;
        // if (!wf_id || wf_id == ENROLL_PREPARATION_WF_TYPE_ID || wf_id == ENROLL_REFUSED_WF_TYPE_ID || wf_id == ENROLL_MISSING_DATA_WF_TYPE_ID) {
        filePermits = filePermits.concat([ContentActionType.FILE_MODIFY, ContentActionType.FILE_UPLOAD, ContentActionType.FILE_DELETE]);

        if (hasGroup(me!, Groups.Admin)) {
            filePermits = ALL_ACTIONS;
        }

        // }


        /*
        user based: 
        - certificate, 
        - certificate_signed, 
        - pedag_jelentkezesi_lap

         type=kepzo_jelenletiiv, type=pedag_jelentkezesi_lap

         course based: 
         - kepzo_jegyzokonyv_zaroertekelesrol, 
         - kepzo_kepz_handout_atv (Résztvevői segédanyag átvételéről szóló dokumentum), 
         - kepzo_kepz_tajek_atv(Résztvevői tájékoztató a továbbképzés feltételeiről szóló átvételi dokumentum)
         - kepzo_tovabbkepzesi_naplo (Pedagógus képzési napló)
         - pedag_kepzesi_tajekoztato (Pedagógus képzési tájékoztató)
         - pedag_kerdoiv_ertekelo (Elégedettségi kérdőív)
         - pedag_tanusitvany_kiadas_nyilvantartas (TANÚSÍTVÁNYOK KIADÁSÁNAK ÉS ÁTVÉTELÉNEK NYILVÁNTARTÁSA)

*/
        const osapExcelUrl = viewKapCourseOsapClassProxy.getExcelDownloadUrl(
            {   filter: {course_id: this.props.currentCourse!.id!},
                columns: ['regno', 'course_name', 'zipcode', 'training_from', 'training_to', 'fullname', 'gender', 'birth_year','residence_zip'],
                order_by: "fullname"
            },
            {filename: 'osap_' + this.props.currentCourse!.id! + ".xlsx"}
        );

        return (
            <div className="columns">
                <div className="kap_course_download_div">
                    <a href={"/api/kap/course?type=pedag_kepzesi_tajekoztato&format=pdf&course_id=" + this.props.currentCourse.id} className="kap-course-downloadable">{__("Pedagógus képzési tájékoztató")}</a>
                    <a href={"/api/kap/course?type=kepzo_tovabbkepzesi_naplo&format=pdf&course_id=" + this.props.currentCourse.id} className="kap-course-downloadable">{__("Pedagógus képzési napló")}</a>
                    <a href={"/api/kap/course?type=pedag_kerdoiv_ertekelo&format=pdf&course_id=" + this.props.currentCourse.id} className="kap-course-downloadable">{__("Elégedettségi kérdőív")}</a>
                    <a href={"/api/kap/course?type=kepzo_jegyzokonyv_zaroertekelesrol&format=pdf&course_id=" + this.props.currentCourse.id} className="kap-course-downloadable">{__("Jegyzőkönyv záróértékelésről")}</a>

                    <a href={"/api/kap/course?type=pedag_jelentkezesi_lap&format=pdf&course_id=" + this.props.currentCourse.id} className="kap-course-downloadable">{__("Jelentkezési lap - összes")}</a>
                    <a href={"/api/kap/course?type=pedag_tovabbkepzesi_szerzodes&format=pdf&course_id=" + this.props.currentCourse.id} className="kap-course-downloadable">{__("Pedagógus-továbbképzési szerződés - összes")}</a>

                    {hasAnyGroup(me!, [Groups.Admin, Groups.Developer, Groups.KapKszrCourseCoordinator, Groups.KapKszrCourseTeacher ])
                        ?<a href={osapExcelUrl} className="kap-course-downloadable">{__("Résztvevői lista (OSAP jelentés) letöltése (Excel)")}</a>
                        :null
                    }
                </div>
                <div className="row">{this.props.contactDates ?
                    <Accordion allowAllClosed={true}>
                        {
                            this.props.contactDates.map((element, index) => {

                                const prefix = "contact_day_" + index;
                                return (
                                    <AccordionItem defaultClosed={true} key={prefix} title={__("{n}. Kontaktnap", { n: (index + 1) })}>
                                        <div className="row" >

                                            <div className="column small-12">
                                                <label>{__("Hivatalos időpont: ")}{element.official_start && element.official_start.length > 12 ?formatDate(element.official_start):""}</label>
                                                <label>{__("Valós időpont: ")}{element.real_start && element.real_start.length > 12?formatDate(element.real_start):""}</label>
                                                <label>{__("Helyszín: ")}{element.address}</label>
                                                <a href={"/api/kap/course?type=kepzo_jelenletiiv&format=pdf&course_contact_day_id=" + element.id} className="kap-course-downloadable">{__("Jelenléti ív")}</a>

                                            </div>
                                            {element.oo_folder_id &&
                                                <div className="column small-12">
                                                    <label className="profile_form_separator">{__("A kontaktnap fájljai")}</label>

                                                    <MediaFileChooser
                                                        rootFolderId={element.oo_folder_id!}
                                                        currentFolderId={element.oo_folder_id!}
                                                        showHoverPreview={false}
                                                        showLocation={false}
                                                        showFilter={false}
                                                        enabledActions={filePermits}
                                                    />
                                                </div>
                                            }
                                        </div>
                                    </AccordionItem>
                                );
                            })
                        }
                    </Accordion>
                    : ""
                }
                </div>
            </div>
        );
    }

}