import * as React from "react";
import { __ } from "@src/translation";
import ListView from "@src/framework/forms/listview";
import { IViewColumnInfo } from "@src/framework/view/Meta";
import { TableProps, Column, RowInfo } from "react-table";
import { IListOrderBy } from "@src/framework/crud/Crud";
import { IViewKapCourseRecord, ViewKapCourseClassProxy, viewKapCourseClassProxy } from "@src/framework/view/kap/ViewKapCourse";
import { formatDateWithoutTime } from "@src/Util";
import { Link } from "react-router-dom";
import { Breadcrumbs } from "@src/component/Breadcrumbs";
import { courseModule } from "../courseModule";
import { cmsConfig } from "@src/component/cms/CmsModule";


export class CourseListInner extends ListView<IViewKapCourseRecord> {
    getHeader() {
        return <Breadcrumbs links={[
            { name: __("KSZR"), path: courseModule.PATH_COURSE_ORGANIZER },
            { name: __("Kurzus adminisztráció") },
        ]} />
    };
    public getViewClassProxy(): ViewKapCourseClassProxy { return viewKapCourseClassProxy; }

    protected getFilter() {
        const filter = super.getFilter();
        filter.site_id = cmsConfig.siteId;
        return filter;
    }

    protected getQuickFilterFieldNames(): string[] {
        return ['regno', 'training_type_code', 'institute_site_name'];
    }

    protected onShowFilterForColumn(column: IViewColumnInfo): boolean {
        switch (column.column_name) {
            case "is_active":
                return true;
            case "training_type_code":
                return true;
            case "teacher_fullname":
                return true;
            case "training_from":
                return true;
            case "training_to":
                return true;
            case "wf_station_name":
                return true;
            case "regno":
                return true;
            case "institute_site_name":
                return true;
            default:
                return false;
        }
    }

    protected getExtraTableProps(): Partial<TableProps> {
        let oldProps = super.getExtraTableProps();
        const getTdProps = (finalState: any, rowInfo: RowInfo, column?: Column, instance?: any) => {
            let props: Partial<TableProps> = {};

            if (column && rowInfo && rowInfo.row) {
                let row = rowInfo.row._original;
                if (row && column.id == "wf_station_name") {
                    props.style = row.wf_station_style || {};
                }
            }
            return props;
        }
        return { ...oldProps, getTdProps };
    }

    /*protected getQuickFilterFieldNames(): string[] {
        return ['fullname'];
    }*/

    protected getRecordButtons(record: IViewKapCourseRecord): JSX.Element[] {
        /* TODO: automatikus detail list menüt ide? */
        return super.getRecordButtons(record);
    }

    protected getOrderBy(): string | IListOrderBy[] | undefined {
        // return [{ name: "training_from", desc: true }];
        return [{ name: "regno", desc: true }];
    }

}



export default function CourseList(props: any) {
    /*
 case "training_type_code":
                return true;
            case "subprogram_title":
                return true;
            case "teacher_login_name":
                return true;                
            case "training_from":
                return true;                
            case "training_to":
    */
    return <CourseListInner
        defaultPageSize={10}
        match={props.match}
        viewColumns={[

            {
                Header: <div>
                    <b>{__("Képzés típus - Intézmény")}</b><br />
                    <small style={{ fontFamily: "monospace", color: "green" }}>{__("Iktatószám") + "/" + __("OM azonosító")}</small>
                </div>,
                accessor: "training_type_code",
                minWidth: 60,
                Cell: (data: any, column: any) => {
                    const row: IViewKapCourseRecord = data.original;
                    return <Link to={courseModule.PATH_COURSE_ORGANIZER_COURSE + `/${row.id}/`}> <p>
                        <b>{row.training_type_code}</b> - {row.title} <br/>
                        <small>{row.institute_name}</small><br/>
                        <span style={{ fontFamily: "monospace", color: "green" }}>{row.regno}/{row.om_identifier}</span>
                        &nbsp; <small>{__("Létszám")}: {row.applied_participants}/{row.max_participants}</small>
                        {
                            row.not_judged_participants && row.not_judged_participants > 0 ? <div><span style={{ fontFamily: "monospace", color: "red" }}>{"Kérelem beadva:" + row.not_judged_participants + "db"}</span></div>
                                : <br />

                        }


                        <small>{row.address}</small>
                    </p></Link>;
                }
            },

            {
                Header: <div>
                    {__("Képzés időtartam")}
                </div>,
                accessor: "training_from",
                minWidth: 30,
                Cell: (data: any, column: any) => {
                    const row: IViewKapCourseRecord = data.original;
                    return <p>{formatDateWithoutTime(row.training_from)}-<br />{formatDateWithoutTime(row.training_to)}
                    </p>;
                }
            },
            /*{ accessor: "training_type_code" },
            { accessor: "subprogram_title" },*/
            { accessor: "teacher_fullname", minWidth: 50 },
            { accessor: "wf_station_name", minWidth: 30 },
            { accessor: "link", Header: __("Műveletek"), minWidth: 30, maxWidth: 150 },
        ]}
    />
}
