import * as React from 'react';
import { __ } from '@src/translation';

type LegendProps = {
    chapterLessons: any,
    lesson: { id: number, headno: number },
    chapter: { headno: number },
    book: { uri_segment: string }
}

type LegendState = {
    data: any
}

export default class HeaderLegend extends React.Component<LegendProps, LegendState> {

    constructor(props: any) {
        super(props);
        this.state = {
            data: {}
        };
    }

    componentDidMount() {
        this.reloadLegend();
    }

    componentDidUpdate(prevProps: LegendProps) {
        if (prevProps.book.uri_segment != this.props.book.uri_segment) {
            this.reloadLegend();
        }
    }

    reloadLegend() {
        this.setState({ data: {} });

        $.ajax({
            type: 'GET',
            dataType: 'json',
            url: "/book/" + this.props.book.uri_segment + "/idovonal.json",
            success: (response) => {
                this.setState({ data: response });
            }
        });
    }

    render() {

        let chapter = String(this.props.chapter.headno);

        let _chapter = this.props.chapterLessons.find((chapter: any) => chapter.lessons.some((_lesson: any) => _lesson.id == this.props.lesson.id));

        let lesson = this.props.lesson.headno ? String(this.props.lesson.headno) :
            this.props.lesson.id == _chapter.lessons[_chapter.lessons.length - 1].id ? "x" : "";
        let cim: any;
        let jelmagyarazat: any;
        let jelmagyarazatList: any[] = [];

        if (this.state.data[chapter] && this.state.data[chapter][lesson]) {
            if (this.state.data[chapter][lesson].jelmagyarazat) {
                let book_uri_segment = "/book/" + this.props.book.uri_segment;

                $.each(this.state.data[chapter][lesson].jelmagyarazat, function (i: string, item) {
                    if (item[1] instanceof Array) {
                        let colors: any[] = [];
                        item[1].forEach(function (g: any) {
                            if(g.includes('#')) {
                                colors.push(<span className="legend-color multiple" style={{ backgroundColor: g }}></span>);
                            } else {
                                colors.push(<span className="legend-icon multiple"><img src={book_uri_segment + g} /></span>);
                            }                            
                        });
                        jelmagyarazatList.push(
                            <li key={i}>
                                {colors}
                                <span className="legend-text">{item[0]}</span>
                            </li>)
                    } else {

                        jelmagyarazatList.push(
                            <li key={i}>
                                {(!item[1].includes('#')) ? <span className="legend-icon"><img src={book_uri_segment + item[1]} /></span> : <span className="legend-color" style={{ backgroundColor: item[1] }}></span>}
                                <span className="legend-text">{item[0]}</span>
                            </li>)
                    }
                });

                jelmagyarazat = <ol className="banner-legend">{jelmagyarazatList}</ol>;
            }
            cim = this.state.data[chapter][lesson].cim ? <div className="banner-title">{this.state.data[chapter][lesson].cim}</div> : "";

            return <div className="legend-div">
                <div className="banner-info large-10">
                    {cim}
                    {jelmagyarazat}
                </div>
            </div>
        }
        return "";

    }

}