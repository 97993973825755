import * as React from 'react';
import { BubbleLoader } from 'react-css-loaders';
import { app } from '@src/index';
import OoFileCrud from '@framework/crud/media//OoFileCrud';
import BookCrud, { IBookRecord } from '@src/framework/crud/doc/BookCrud';
import FileManagerPager from './FileManagerPager';
import { StringSearchKind, IStringSearch } from '@src/framework/crud/Crud';
import { Dialog } from '@src/component/Dialog';
import { FileDataTabs } from '@src/component/filemanager/data/FileDataTabs';
import { IOoFileRecord } from '@src/framework/crud/media_pub/OoFileCrud';
import { LIBRARY_OFI_OFFICIAL_ID } from '@src/Const';
import { __ } from '@src/translation';
import * as PublicServer from '@src/server/PublicServer';
import MediaTypeCrud, { IMediaTypeRecord } from '@src/framework/crud/media/MediaTypeCrud';
import { Link } from 'react-router-dom';
import { PATH_CONTENT_STORE } from '@src/Routes';

interface OFIUnlinkedPicturesPageProps {

}

interface OFIUnlinkedPicturesPageState {

    files?: PublicServer.FileUsage[];
    limit: number;
    offset: number;
    pageSize: number;
    searchText: string;
    loading: boolean;
    books: IBookRecord[];
    mediaTypes: IMediaTypeRecord[];
    selectedBookId?: number;
    selectecMediatypeId?: number | null;
    selectedFilterId?:number;
    withoutRefs?:boolean;
    count:number;
}

export class OFIUnlinkedPicturesPage extends React.Component<OFIUnlinkedPicturesPageProps, OFIUnlinkedPicturesPageState> {
    constructor(props: OFIUnlinkedPicturesPageProps) {
        super(props);
        this.state = {
            limit: 50,
            offset: 0,
            pageSize: 25,
            count:0,
            files: undefined,
            searchText: '',
            loading: false,
            books: [],
            mediaTypes:[],
            withoutRefs:false
        };
    }

    async componentDidMount() {

        await this.reload();
        this.reloadUntaggedAsync();
    }

    async reload() {
        const books = await BookCrud.list({ filter: { is_active: true, library_id: LIBRARY_OFI_OFFICIAL_ID }, order_by: "name" });
        const mediaTypes = await MediaTypeCrud.list({ order_by: "title" });
        this.setState({
            books,
            mediaTypes,
            selectedBookId:books[0].id
        });
    }

    async reloadUntaggedAsync() {
        try {
            
           
            this.setState({ files: undefined, loading: true });
            var titleFilter: IStringSearch | undefined = undefined;

            if (this.state.searchText) {
                titleFilter = {
                    kind: StringSearchKind.Contains,
                    case_sensitive: false,
                    expr: this.state.searchText
                }
            }
            let fileUsage: PublicServer.FileUsage[] = await PublicServer.getFileUsage(this.state.selectedBookId!,this.state.selectecMediatypeId,0,this.state.withoutRefs?0:10000000,this.state.limit,this.state.offset);
            let countgroup = await PublicServer.countFileUsage(this.state.selectedBookId!,this.state.selectecMediatypeId,0,this.state.withoutRefs?0:10000000);
            let totalcount=0;
            for (const count of countgroup) {
                totalcount+=count.count;
            }
            
            this.setState({ files: fileUsage as any, loading: false,count:totalcount });

        } catch (e) {
            app.showErrorFromJsonResult(e);
        }
    }

    private onMediatypeChanged(event: any) {
        var selectecMediatypeId: any = undefined;
        if (event.target.value == "null") {
            selectecMediatypeId = null;
        } else if (event.target.value) {
            selectecMediatypeId = Number(event.target.value);
        }
        this.setState({ selectecMediatypeId }, this.reloadPagination)
    }
    private onChangeLimit = (limit: number) => {
        this.setState({ limit }, this.reloadUntaggedAsync);
    }

    private onChangeOffset = (offset: number) => {
        this.setState({ offset }, this.reloadUntaggedAsync);
    }

    private onBookChanged(event: any) {
        this.setState({ selectedBookId: event.target.value ? Number(event.target.value) : undefined }, this.reloadPagination)
    }
    private hasRefsChanged(event:any)
    {
        this.setState({withoutRefs:event.target.checked},this.reloadPagination)
    }
    private async reloadPagination()
    {
        this.setState({limit:50,offset:0,pageSize:25},this.reloadUntaggedAsync);
    }

    render() {
        return <div>        

            <div className="row">
            <div className="columns large-6 small-12">
                    <label htmlFor="bookIdSelector">{__("Csak ebben a könyvben")}</label>
                    <select disabled={this.state.loading} name="bookIdSelector" onChange={this.onBookChanged.bind(this)}>              
                        {
                            this.state.books.map(book => {
                                if (!book.name) return;
                                return <option key={book.id} value={book.id}>{book.name}</option>
                            })
                        }
                    </select>
                </div>
                <div className="columns large-6 small-12">
                    <label htmlFor="mediaTypeIdSelector">{__("Csak ilyen típus")}</label>
                    <select disabled={this.state.loading} name="mediaTypeIdSelector" id="mediaTypeIdSelector" onChange={this.onMediatypeChanged.bind(this)}>
                        <option value="">[ {__("Összes")} ]</option>
                        <option value="null">[ {__("Típus nélküliek")} ]</option>
                        {
                            this.state.mediaTypes.map((mediaType: IMediaTypeRecord) => {
                                if (!mediaType.id) return;
                                return <option key={mediaType.id} value={mediaType.id}>{mediaType.title}</option>
                            })
                        }
                    </select>
                </div> 
                <div className="shrink column">
                <input type="checkbox" disabled={this.state.loading} id="hasRefs" name="hasRefs" checked={this.state.withoutRefs} onChange={this.hasRefsChanged.bind(this)}/><label htmlFor="hasRefs">{__("Csak a könyv mappájában lévő, de könyvbe nem bekötött fájlok.")}</label>
                
                </div>
            </div>
            {this.state.files
            ?<span style={{ fontWeight: "bold", fontSize: "15px" }}>{__("Összesen")}{" "+this.state.count + " " + __("találat")}</span>
            :""
            }
            
            <FileManagerPager
                disabled={this.state.loading}
                limit={this.state.limit}
                offset={this.state.offset}
                onChangeLimit={this.onChangeLimit}
                onChangeOffset={this.onChangeOffset}
            />
            {
                !this.state.files
                    ? <div className="row"><BubbleLoader /></div>
                    : <table className="table striped">
                        <thead>
                            <tr>
                                <td>{__("Kép")}</td>
                                <td style={{width:"80%"}}>{__("Adatok")}</td>
                                <td>{__("Hivatkozások száma")}</td>
                            </tr>
                        </thead>
                        {
                            this.state.files.map(f => {
                                return <UnLinkedPicturePageTableRow key={f.id} record={f} refCount={f.ref_count} onReload={this.reloadUntaggedAsync.bind(this)} />;
                            })
                        }
                    </table>
            }

            <FileManagerPager
                disabled={this.state.loading}
                limit={this.state.limit}
                offset={this.state.offset}
                onChangeLimit={this.onChangeLimit}
                onChangeOffset={this.onChangeOffset}
            />
            {
                !this.state.loading && this.state.files && this.state.files.length == 0
                    ?
                    __("Nincs találat")
                    :
                    null
            }
        </div>;
    }
}

type UnlinkedPicturesTablerowProps = {
    record:IOoFileRecord,
    refCount:number
   
    onReload: () => void;
}

type UnLinkedPicturePageState = {
    showSettingsDialog: boolean;
    file?: IOoFileRecord;
}
class UnLinkedPicturePageTableRow extends React.Component<UnlinkedPicturesTablerowProps, UnLinkedPicturePageState> {
    constructor(props: UnlinkedPicturesTablerowProps) {
        super(props);

        this.state = {
            showSettingsDialog: false,
        }
    }

    async showDialog() {
        if(this.props.record.id)
        {
        let file=(await OoFileCrud.load(this.props.record.id)).record;
        this.setState({file, showSettingsDialog: true});
        }
    }
   
    render() {
        const src = "/api/media/thumb/200/" + this.props.record.sha1;
        const thumbnail = <img draggable={false} src={src} onClick={this.showDialog.bind(this)} style={{ cursor: "pointer" }} />; 
        let link = PATH_CONTENT_STORE+"/kozos/"+this.props.record.oo_folder_id;
        return <tbody>
            {
                this.state.showSettingsDialog && this.state.file
                    ?
                    <Dialog title={__("Állomány részletei - {details}", {details: this.props.record.title})} onClose={() => this.setState({ showSettingsDialog: false })} width={1000} height={700}>
                        <FileDataTabs
                            file={this.state.file}
                            allowRestoreVersion={true}
                            allowModifications={true}
                            onClose={() => this.setState({ showSettingsDialog: false }, this.props.onReload)}
                        />
                    </Dialog>
                    :
                    null
            }
            <tr>
                <td rowSpan={2} style={{ width: "210px",cursor:"pointer" }}  onClick={this.showDialog.bind(this)}>
                {thumbnail}
                </td>
                <td colSpan={1}>
                    <strong>{__("Hely:")}</strong> <Link to={link} target="_blank">{link}</Link> <br />
                    <strong>relpath:</strong> {this.props.record.relpath ? <span >{this.props.record.relpath}</span> : <span style={{color:"red"}}>{__("Nincs megadva")}</span>}<br />
                    <strong>Title:</strong> {this.props.record.title ? <span >{this.props.record.title}</span> : <span style={{color:"orange"}}>{__("Nincs megadva")}</span>}
                    <br />             
                </td>
                <td colSpan={2}>
                    <strong>{this.props.refCount}</strong>
                </td>
            </tr>
        </tbody>
    }
}
