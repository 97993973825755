import './style.css';
import { ExerciseBaseClass, AnswerElement } from '@src/component/exercise/models/ExerciseBaseClass';
import { AExerciseEngine } from '../../models/AExerciseEngine';
import { WMFillTableTextServer } from './WMFillTableTextServer';

export interface FillTableData extends ExerciseBaseClass {
    answers: string[];
    options: AnswerElement[];
    headers: string[];
    rows: Row[];
    fix_elements: Row[];
    keywords: string;
    caseSensitive: boolean;
    first_row_header: boolean;
    first_column_header: boolean;
};

type Row = {
    data: any[];
};

type FillTableUserSolution = {
    answer: any[];
    fullmatch: boolean;
}

export class WMFillTableTextExerciseEngine extends AExerciseEngine {

    interaction: boolean = false;
    private dropDivList: HTMLElement[] = [];
    private caseSensitive: boolean = false;
    

    keyPressed(ev: any) {
        this.interaction = true;
    }

    initExercise(params: ExerciseParams): void {
        super.initExercise(params);       
        let exercise: FillTableData = params.exercise;
                this.interaction = true;
        this.caseSensitive = exercise.caseSensitive ? exercise.caseSensitive : false;            
        this.root.classList.add("wm-filltable-text");

        this.setIllustration(exercise,this.root);

        let tablediv = this.root.appendChild(document.createElement("div"));
        tablediv.classList.add("table-scroll");
        tablediv.classList.add('table-div');

        let table = tablediv.appendChild(document.createElement("table"));
        table.classList.add('table');

        if (!exercise || !exercise.fix_elements || !exercise.fix_elements[0])
            return;

        let columnCount = exercise.fix_elements[0].data.length;
        if (columnCount > 4) {
            tablediv.classList.add('mobile-table-wrapper');
            table.classList.add('mobile-table');
        }

        for (let indexO = 0; indexO < exercise.fix_elements.length; indexO++) {
            let tr = table.appendChild(document.createElement("tr"));

            for (let indexI = 0; indexI < exercise.fix_elements[indexO].data.length; indexI++) {
                let td = tr.appendChild(document.createElement("td"));

                if (exercise.first_row_header && indexO == 0 || exercise.first_column_header && indexI == 0) {
                    td.classList.add("table-header");
                    if (!exercise.fix_elements[indexO].data[indexI].answer) {
                        td.classList.add("input-area");
                    }
                }
                else {
                    td.classList.add("table-td");
                }
                
                if (exercise.fix_elements[indexO].data[indexI].is_answer) {
                    td.classList.add("input-area");
                    let textBox = td.appendChild(document.createElement('input'));
                    textBox.setAttribute('type', 'input');
                    textBox.classList.add('w-100');
                    textBox.addEventListener('keypress', this.keyPressed.bind(this), false);
                    if (this.isSNIexc) textBox.addEventListener('blur', this.SNIEvaluation.bind(this, WMFillTableTextServer));

                    if (this.isReplay) {
                        textBox.setAttribute('disabled', 'disabled');
                    }

                    this.dropDivList.push(td);
                } else {
                    td.innerText = exercise.fix_elements[indexO].data[indexI].answer.text;
                }
            }
        }
    }

    getUserSolution(): FillTableUserSolution {
        let result: any[] = [];
        for (let index = 0; index < this.dropDivList.length; index++) {
            if (this.dropDivList[index].childNodes[0] != null) {
                let element = this.dropDivList[index].childNodes[0] as HTMLInputElement;
                //console.log(element);
                let res = element.value;
                result.push(res);
            } else result.push(null);
        }
        return { answer: result, fullmatch: true };
    }

    receiveEvaluation(evaluated: Evaluated): void {
        if (evaluated.success) {
            for (let index = 0; index < this.dropDivList.length; index++) {
                let element = this.dropDivList[index].childNodes[0] as HTMLInputElement;
                AExerciseEngine.removeEvalStyle(element);
                element.classList.add("exe-engine-check-correct");
            }
        } else {
            if (evaluated.solution != null) {
                for (let index = 0; index < evaluated.solution.length; index++) {

                    if (this.dropDivList[index].childNodes.length > 0) {
                        let element = this.dropDivList[index].childNodes[0] as HTMLInputElement;
                        AExerciseEngine.removeEvalStyle(element);

                        //if (solarray.indexOf(val.toLowerCase()) > -1) {
                        if (WMFillTableTextServer.evaluateSolution(evaluated.solution[index], element.value, this.caseSensitive)) {
                            element.classList.add("exe-engine-check-correct");
                        }
                        else {
                            if (!this.isSNIexc) element.classList.add("exe-engine-check-wrong");
                        }
                    }
                }
            }
        }
    }

    showCorrectSolution(solution: any): void {
        for (let index = 0; index < this.dropDivList.length; index++) {
            let element = this.dropDivList[index].childNodes[0] as HTMLInputElement;
            if (!this.isReplay) {
                let solarray = solution[index].text.split(';').map((x: any) => x.trim());
                if (!element.classList.contains("exe-engine-correct-bg")) {
                    element.value = solarray[0];
                    AExerciseEngine.removeEvalStyle(element);
                    element.classList.add("exe-engine-correct-bg");
                }
            } else {
                element.value = solution[index];
            }
        }
    }

    isUserReady(): boolean {
        var result = this.getUserSolution();

        for (let index = 0; index < result.answer.length; index++) {
            if (result.answer[index].length > 0)
                return true;
        }

        return false;;
    }

    showHelp(solution: any): void {
        this.showCorrectSolution(solution);
    }
    
}