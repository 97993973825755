import * as React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { RadioTab, RadioTabs } from '../RadioTab';

export class UITabGuidelines extends React.Component {

    render() {
        return <>
            <h4>React tabs</h4>
            <Tabs>
                <TabList>
                    <Tab>Title 1</Tab>
                    <Tab>Title 2</Tab>
                </TabList>

                <TabPanel>
                    <p>Tab 1 content</p>
                </TabPanel>
                <TabPanel>
                    <p>Tab 2 content</p>
                </TabPanel>
            </Tabs>

            <h4>Radio tabs</h4>
           
            <RadioTabs tabGroup="tabgroup" onChange={(value:any)=>console.log("selected value", value)}>
                <RadioTab selected={true} value="1">Tab1</RadioTab>
                <RadioTab value="2">Tab2</RadioTab>
                <RadioTab value="3">Tab3</RadioTab>
            </RadioTabs>
        </>
    }

}
