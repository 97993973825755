import * as React from "react";
import {ExerciseServer} from '../ExerciseServer';
import * as ExerciseContainerComponent from '@src/component/exercise/ExerciseContainerComponent';

export class ExerciseTestPage extends React.Component<any, any> {

    private static examples : number[] = [978239880,989370430,1030623621, 1030623678,1030623697,1396313512,1396313527,1461668797,1477850618,1548801808,1548801829,1548801850, 1548801861,1548801883, 1710696608, 1710696617 ];
   
    
    constructor(props: any) {
        super(props);
    }

    render() {        
        return ( 
            <div className="row expanded exerciseList-maincomponent">
            <div id="testContainer" className="small-12 medium-8 medium-offset-2 column" ref="testContainer">      
            {
                ExerciseTestPage.examples.map(e =>{
                    let server = new ExerciseServer(false);
                    server.setExerciseSeriesId(e);
                    return <ExerciseContainerComponent.default
                        server={server}
                        key={e}
                    />
                })
            }
        </div>
        </div>
        );       
    }
}