import { Server } from '../../server/Server';
import Crud , { registerCrudClassProxy, IAsTextResult, IListParams, CrudClassProxy, ISearchTextParams, ISearchTextResult } from '../Crud';

export interface ISecTableInfoPermRecord {
    /**
     * Globálisan egyedi azonosító.
     * 
     * Ha új rekordot akarsz létrehozni put() metódussal, akkor hagyd üresen.
     * upsert() hívásnál figyelmen kívül van hagyva az értéke.
     *
     */
    id ?: number;
    /**
     * Rekord létrehozás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel létrehozásakor.
     */
    creation_time?: string /*timestamp?*/;
    /**
     * Ki hozta létre
     *
     * NOT NULL -> sys.sec_user ON DELETE noaction
     *
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozó felhasználóra hivatkozik.
     */
    creation_user_id?: number;
    /**
     * Melyik session-ből jött létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozáshoz használt munkamenetre hivatkozik.
     */
    creation_session_id?: number|null;
    /**
     * Utolsó módosítás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel módosításakor.
     */
    modification_time?: string /*timestamp?*/|null;
    /**
     * Ki módosította utoljára
     *
     * -> sys.sec_user ON DELETE noaction
     *
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosító felhasználóra hivatkozik.
     */
    modification_user_id?: number|null;
    /**
     * Melyik session-ből módosították utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosításhoz használt munkamenetre hivatkozik.
     */
    modification_session_id?: number|null;
    /**
     * Táblázat információ
     *
     * NOT NULL -> meta.table_info ON DELETE noaction
     *
     */
    table_info_id?: number;
    /**
     * Engedély birtokos
     *
     * NOT NULL -> sys.sec_perm_owner ON DELETE cascade
     *
     */
    sec_perm_owner_id?: number;
    /**
     * Létrehozás
     *
     * Új rekord létrehozása
     */
    can_create?: boolean;
    /**
     * Módosítás
     *
     * Létező rekord módosítása
     */
    can_update?: boolean;
    /**
     * Törlés
     *
     * Létező rekordok törlése
     */
    can_delete?: boolean;
    /**
     * Olvasás
     *
     * Létező rekordok lekérdezése, szűrése
     */
    can_read?: boolean;
}

/**
 *  meta.sec_table_info_perm - Táblázat jog
 *
 *  A CRUD alrendszer jogosultságait tárolja.
 */

export default class SecTableInfoPermCrud extends Crud<ISecTableInfoPermRecord> {
    public static TABLE_INFO_ID = 157691117;
    public static getSchemaNameForClass() { return 'meta'; }
    public static getTableNameForClass() { return 'sec_table_info_perm'; }

    public static load: (id: number, aServer ?: Server) => Promise<SecTableInfoPermCrud>;
    public static asText: (id: number, includeRecord : boolean, aServer ?: Server) => Promise<IAsTextResult<ISecTableInfoPermRecord>>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<ISecTableInfoPermRecord[]>;
    public static searchText: (params: ISearchTextParams, aServer ?: Server) => Promise<ISearchTextResult<ISecTableInfoPermRecord>[]>;
    public static deleteById: (id: number, aServer ?: Server) => Promise<ISecTableInfoPermRecord>;
    public static unDeleteById: (id: number, aServer ?: Server) => Promise<ISecTableInfoPermRecord>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class SecTableInfoPermCrudClassProxy extends CrudClassProxy<ISecTableInfoPermRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const secTableInfoPermCrudClassProxy = new SecTableInfoPermCrudClassProxy(SecTableInfoPermCrud);
registerCrudClassProxy(secTableInfoPermCrudClassProxy);
