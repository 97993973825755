import * as React from "react";
import { match } from "react-router";
import { app } from "@src/index";
import TrainingTypeMilestoneCrud, { ITrainingTypeMilestoneRecord } from "@src/framework/crud/kap/TrainingTypeMilestoneCrud";
import { hasAnyGroup, me, Groups } from "@src/framework/server/Auth";
import { __ } from "@src/translation";
import TrainingTypeCrud, { ITrainingTypeRecord } from "@src/framework/crud/kap/TrainingTypeCrud";
import { HTMLTextarea } from "@src/component/HtmlTextarea";
import { Accordion, AccordionItem } from "@src/component/ui/Accordion";
import { whiteSpaceChecker } from "@src/component/WhiteSpaceChecker";
import CourseCrud from "@src/framework/crud/kap/CourseCrud";

interface ICourseMilestoneProps {
    match: match<{ recId: string }>;
    history: History;
}

interface ICourseMilestoneState {
    trainingType?: ITrainingTypeRecord,
    milestones: ITrainingTypeMilestoneRecord[];
    loading: boolean;
    isDataChanged: boolean;
    validationMessages: Map<string, string>;
    isDeleteAvailable: boolean
}

export default class CourseTypeMilestoneEdit extends React.Component<ICourseMilestoneProps, ICourseMilestoneState> {

    constructor(props: ICourseMilestoneProps) {
        super(props);

        let validationMap: Map<string, string> = new Map<string, string>();
        this.state = {
            milestones: [],
            loading: false,
            isDataChanged: false,
            validationMessages: validationMap,
            isDeleteAvailable: false
        }
    }

    componentDidMount() {
        this.reloadAsync();
    }

    componentDidUpdate(prevProps: ICourseMilestoneProps) {
        if (this.props.match.params.recId != prevProps.match.params.recId) {
            this.reloadAsync();
        }
    }

    async reloadAsync() {
        try {
            this.setState({ loading: true });
            const match: any = this.props.match;
            let recId: number | null = (match && match.params) ? Number(match.params["recId"]) : null;
            if (recId == null) { app.showError(__("Hiba"), __("Adat nem található.")); return; }

            let trainingType = (await TrainingTypeCrud.load(recId!)).record;
            let milestones: ITrainingTypeMilestoneRecord[] = await TrainingTypeMilestoneCrud.list({ filter: { is_active: true, training_type_id: recId }, order_by: "no" });
            let countOfCourse: number = (await CourseCrud.count({ filter: { is_active: true, training_type_id: recId } }));

            this.setState({
                trainingType: trainingType,
                milestones,
                loading: false,
                isDeleteAvailable: countOfCourse == 0,
                isDataChanged: false
            })

        } catch (error) {
            app.showErrorFromJsonResult(error);
        }
    }

    async onSave() {
        if (!this.state.trainingType) return;
        this.setState({ loading: true });
        let validationMap:Map<string,string> = new Map<string,string>();
        let errorMsg = "";
        let newMl = this.state.milestones;
        for (let index = 0; index < newMl.length; index++) {
            const element:ITrainingTypeMilestoneRecord = newMl[index];
            if (!whiteSpaceChecker(element.title)) {
                errorMsg = __("A címet kötelező kitölteni!");
                app.showError(__("Hiba"), errorMsg);
                validationMap.set("["+index +"]title", errorMsg);
            }            

            if(element.deadline_days && element.deadline_days<0){
                errorMsg = __("A határidő napja nem lehet negatív szám!");
                app.showError(__("Hiba"), errorMsg);
                validationMap.set("["+index +"]deadline_days", errorMsg);
            }
        }

        if (validationMap.size > 0) {
            this.setState({ loading: false, validationMessages:validationMap });
            return;
        }
       
        try {
            let trainingType = this.state.trainingType;
            let oldMilestones: ITrainingTypeMilestoneRecord[] = await TrainingTypeMilestoneCrud.list({ filter: { is_active: true, training_type_id: trainingType.id }, order_by: "no" });
            
            if (this.state.isDeleteAvailable)
                for (let index = 0; index < oldMilestones.length; index++) {
                    const element = oldMilestones[index];
                    if (newMl.findIndex((el) => { return el.id == element.id }) == -1) await TrainingTypeMilestoneCrud.deleteById(element.id!);
                }

            for (let index = 0; index < newMl.length; index++) {
                const element = newMl[index];
                element.no = index;
                let savedEl = await (new TrainingTypeMilestoneCrud(element)).put();
            }
            app.showSuccess(__("Mentés"), __("Sikeres mentés!"));
            this.setState({loading:false, validationMessages:new Map<string,string>()});

        } catch (error) {
            app.showErrorFromJsonResult(error);
        }

        this.reloadAsync();
    }

    handleTitleChange(index: number, event: any) {
        if (!event || !event.target) return;
        let msL = this.state.milestones;
        msL[index].title = event.target.value;
        this.setState({ milestones: msL, isDataChanged: true });
    }

    handleDayChange(index: number, event: any) {
        if (!event || !event.target) return;
        let msL = this.state.milestones;
        msL[index].deadline_days = Number(event.target.value);
        this.setState({ milestones: msL, isDataChanged: true });
    }

    handleDescriptionChange(index: number, value: string) {
        let msL = this.state.milestones;
        msL[index].description_html = value;
        this.setState({ milestones: msL, isDataChanged: true });
    }

    addNewMilestone() {
        let msL = this.state.milestones;
        msL.push({ 
            no: this.state.milestones.length,
            training_type_id:this.state.trainingType?this.state.trainingType.id : undefined,
            title: (this.state.milestones.length + 1) + ". mérföldkő", 
            description_html: "" });
        this.setState({ milestones: msL, isDataChanged: true });
    }


    render() {

        if (!(me && hasAnyGroup(me, [Groups.Admin, Groups.Developer, Groups.KapELearningEditor, Groups.KapKszrCourseCoordinator]))) {
            app.showError(__("Hiba"), __("Nincs jogosultsága!"));
            return "";
        }
        if (!this.state.trainingType) return "";
        return <div>
            <div>
                <h5>{this.state.trainingType.title} </h5>
            </div>
            <div>
                <h3>
                    {__("Mérföldkövek")}
                </h3>
            </div>
            <div>
                <Accordion>
                    {
                        !this.state.milestones ? "" : this.state.milestones.map((curr_topic: ITrainingTypeMilestoneRecord, index) => {

                            let accTitle = (<legend>
                                <label className="exe-editor-fieldlabel-1">{curr_topic.title ? curr_topic.title : __("{n}. mérföldkő", { n: (index + 1) })}</label>
                                {this.state.isDeleteAvailable && <button className="button small alert exercise-series-small-btn" title={__("Törlés")} onClick={this.removeElement.bind(this, index)}><i className="fa fa-trash"></i></button>}
                                <button className="button small secondary exercise-series-small-btn" title={__("Fel")} onClick={this.moveUp.bind(this, index)} ><i className="fa fa-arrow-up"></i></button>
                                <button className="button small secondary exercise-series-small-btn" title={__("Le")} onClick={this.moveDown.bind(this, index)}><i className="fa fa-arrow-down"></i></button>
                            </legend>);

                            return (

                                <AccordionItem key={"topic_" + index} title={accTitle}>
                                    <div className="row expanded">
                                        <div className="column small-12 large-8">
                                            <label>{__("Cím")}<span style={{ color: "#b30000" }}></span>
                                                <span className="exe-editor-validation-msg">{this.state.validationMessages.get("["+index +"]title")}</span>
                                                <input type="text"
                                                    value={curr_topic.title || ""}
                                                    onChange={(e) => this.handleTitleChange(index, e)} />
                                            </label>
                                        </div>
                                        <div className="column small-12 large-4">
                                            <label>{__("Hanyadik nap")}<span style={{ color: "#b30000" }}></span>
                                                <span className="exe-editor-validation-msg">{this.state.validationMessages.get("["+index +"]deadline_days")}</span>
                                                <input type="number"
                                                min="0" max="100"
                                                    value={curr_topic.deadline_days || 0}
                                                    onChange={(e) => this.handleDayChange(index, e)} />
                                            </label>
                                        </div>
                                    </div>
                                    <div className="row expanded">
                                        <div className="column small-12 large-12">
                                        <label>{__("Leírás")} </label>
                                        <HTMLTextarea
                                            value={curr_topic.description_html || ""}
                                            onChange={this.handleDescriptionChange.bind(this, index)}
                                        />
                                        </div>
                                    </div>
                                </AccordionItem>
                            );
                        })
                    }
                </Accordion>


                <div>
                    <button
                        className="button primary"
                        title={__("Új mérföldkő")}
                        onClick={this.addNewMilestone.bind(this)}>
                        <i className="fa fa-plus" />
                        {__("Új mérföldkő")}
                    </button>
                </div>
            </div>
            <div>
                <button className="button success eke-general-buttons" onClick={this.onSave.bind(this, false)} disabled={(!this.state.isDataChanged)}>
                    <i className="fa fa-save" /> {__("Mentés")}
                </button>
            </div>
        </div>
    }

    /**
  * Moving the index-th element in the array, given by the property name.
  * @param index index of the element to be moved.
  */
    moveUp(index: number) {
        let arrayToChange: ITrainingTypeMilestoneRecord[] = this.state.milestones;
        if (index <= 0) return;
        let temp_el = arrayToChange[index];
        arrayToChange.splice(index, 1);
        arrayToChange.splice(index - 1, 0, temp_el);
        this.setState({
            // exercise: { ...this.state.exercise, [target.name]: value }
            milestones: arrayToChange,
            isDataChanged: true
        });
    }

    /**
     * Moving the index-th element in the array, given by the property name.
     * @param index index of the element to be moved.
     */
    moveDown(index: number) {
        let arrayToChange: ITrainingTypeMilestoneRecord[] = this.state.milestones;
        if (index >= arrayToChange.length - 1) return;
        let temp_el = arrayToChange[index];
        arrayToChange.splice(index, 1);
        arrayToChange.splice(index + 1, 0, temp_el);
        this.setState({
            // exercise: { ...this.state.exercise, [target.name]: value }
            milestones: arrayToChange,
            isDataChanged: true
        });
    }


    /**
     * Removing the index-th element of the array, given by the property name.
     * @param index index of the element to be removed.
     */
    removeElement(index: number) {
        if (!this.state.isDeleteAvailable) return;
        let arrayToChange: ITrainingTypeMilestoneRecord[] = this.state.milestones;
        if (index >= arrayToChange.length || index < 0) return;
        arrayToChange.splice(index, 1);
        this.setState({
            // exercise: { ...this.state.exercise, [target.name]: value }
            milestones: arrayToChange,
            isDataChanged: true
        });

    }
}