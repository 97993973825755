import { Server } from '../../server/Server';
import View , { registerViewClassProxy, ViewClassProxy } from '../View';
import { IListParams } from '@framework/crud/Crud';

export interface IViewInstituteSiteTeacherRecord {
    /**
     * Azonosító
     *
     * Azonosító (pótkulcs)
     */
    id?: number;
    /**
     * Rekord létrehozás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel létrehozásakor.
     */
    creation_time?: string /*timestamp?*/;
    /**
     * Ki hozta létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozó felhasználóra hivatkozik.
     */
    creation_user_id?: number;
    /**
     * Melyik session-ből jött létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozáshoz használt munkamenetre hivatkozik.
     */
    creation_session_id?: number|null;
    /**
     * Utolsó módosítás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel módosításakor.
     */
    modification_time?: string /*timestamp?*/|null;
    /**
     * Ki módosította utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosító felhasználóra hivatkozik.
     */
    modification_user_id?: number|null;
    /**
     * Melyik session-ből módosították utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosításhoz használt munkamenetre hivatkozik.
     */
    modification_session_id?: number|null;
    /**
     * Aktív
     *
     * Logikai törléshez használt mező.
     */
    is_active?: boolean;
    /**
     * Intézmény feladat ellátási hely
     */
    institute_site_id?: number;
    /**
     * Alkalmazás szintű felhasználó
     */
    sec_user_id?: number;
    /**
     * Tanárság állapot
     */
    status_id?: number;
    /**
     * Létrehozó
     *
     * Aki a jogot kiosztotta
     */
    creator?: string|null;
    /**
     * Módosító
     *
     * Aki a jog kiosztását módosította
     */
    modifier?: string|null;
    /**
     * Tagság állapot
     *
     * Tagság állapot
     */
    status_title?: string|null;
    /**
     * Tagság állapot leírása
     *
     * Tagság állapot leírása
     */
    status_description?: string|null;
    /**
     * Tanár neve
     *
     * Tanár neve
     */
    teacher_displayname?: string|null;
    /**
     * Feladatellátási hely név
     *
     * Feladatellátási hely név
     */
    institute_site_name?: string|null;
    /**
     * Feladatellátási hely azonosító
     *
     * Feladatellátási hely azonosító
     */
    site_identifier?: string|null;
    /**
     * Intézmény név
     *
     * Intézmény név
     */
    institute_name?: string|null;
    /**
     * Intézmény OM azonosító
     *
     * Intézmény OM azonosító
     */
    om_identifier?: string|null;
    /**
     * KAP bevezetés dátuma
     *
     * KAP bevezetés dátuma
     */
    kap_introduction_date?: string /*date?*/|null;
}

/**
 *  sys.view_institute_site_teacher - Feladat ellátási hely tanára
 *
 *  Idősoros táblázat, ami az összes tétel összes korábbi verzióját eltárolja.
 */

export default class ViewInstituteSiteTeacher extends View<IViewInstituteSiteTeacherRecord> {
    public static VIEW_INFO_ID = 2018112901;

    public static getSchemaNameForClass(): string { return 'sys'; }
    public static getViewNameForClass(): string { return 'view_institute_site_teacher'; }
    public static getTableNameForClass(): string|null { return 'institute_site_teacher'; }

    public static load: (id: number, aServer ?: Server) => Promise<ViewInstituteSiteTeacher>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<IViewInstituteSiteTeacherRecord[]>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class ViewInstituteSiteTeacherClassProxy extends ViewClassProxy<IViewInstituteSiteTeacherRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const viewInstituteSiteTeacherClassProxy = new ViewInstituteSiteTeacherClassProxy(ViewInstituteSiteTeacher);
registerViewClassProxy(viewInstituteSiteTeacherClassProxy);

