import * as React from 'react';
import { app } from '@src/index';
import { match } from 'react-router';
import CollectionItemCrud from '@src/framework/crud/usr/CollectionItemCrud';
import {List, } from '../ui/List';
import {  getContentDetails } from '../filemanager/ContentUtils';
import { Link } from 'react-router-dom';
import { PATH_CONTENT_STORE } from '@src/Routes';
import { confirmDialog } from '../Dialog';
import CollectionCrud, { ICollectionRecord } from '@src/framework/crud/usr/CollectionCrud';
import { BubbleLoader } from 'react-css-loaders';
import { __ } from '@src/translation';
import { IGeneralItem, CollectionItemComponent } from './CollectionItemComponent';

type CollectionProps = {
    match: match<{collectionId: string}>
}

type CollectionState = {
    collection?: ICollectionRecord;
    collectionItems: IGeneralItem[];
    saving: boolean;
}

export class Collection extends React.Component<CollectionProps, CollectionState> {

    state: CollectionState = {
        collectionItems: [],
        saving: false
    }

    componentDidMount() {
        this.reloadAsync();
    }

    private async reloadAsync() {
        try {
            const collectionId = Number(this.props.match.params.collectionId);

            const collection = (await CollectionCrud.load(collectionId)).record;
            const collectionItems = await CollectionItemCrud.list({filter: {is_active: true, collection_id: collectionId}, order_by: "no"});

            let collectionItemList:IGeneralItem[] = [];
            for (let index = 0; index < collectionItems.length; index++) {
                let details = await getContentDetails(collectionItems[index].table_info_id!, collectionItems[index].rec_id!);               
                let curr_item = {...collectionItems[index],  details}
                collectionItemList.push(curr_item);
            }               

            this.setState({
                collection,
                collectionItems:collectionItemList,
                saving: false
            })

        } catch(e) {
            app.showErrorFromJsonResult(e);
        }
    }

    private async onDelete(id:number) {
        if (!await confirmDialog(__("Törlés"), __("Biztosan törölni akarja a gyűjteményből?"), __("Törlés"))) return;

        try {
            await CollectionItemCrud.deleteById(id);
            this.reloadAsync();
        } catch(e) {
            app.showErrorFromJsonResult(e);
        }
    }


    private async onDrop(dragIndex: number, dropIndex: number) {
        const oldArray = this.state.collectionItems.slice();

        const removedItems = this.state.collectionItems.splice(dragIndex, 1);
        this.state.collectionItems.splice(dropIndex, 0, removedItems[0]);

        this.setState({
            collectionItems: this.state.collectionItems,
            saving: true
        })

        try {
            for(let i = dragIndex; dropIndex >= dragIndex ? i<= dropIndex : i>= dropIndex; dropIndex >= dragIndex ? i++ : i--) {
                await new CollectionItemCrud({
                    id: this.state.collectionItems[i].id, 
                    no: oldArray[i].no
                }).put();
            }
            this.reloadAsync();
        } catch(e) {
            app.showErrorFromJsonResult(e);
        }
    }

    render() {
        if (!this.state.collection) {
            return <BubbleLoader />
        }
        
        return <div>
            <h5><Link to={PATH_CONTENT_STORE + "/gyujtemeny/"}>{__("Gyűjteményeim")}</Link> / {this.state.collection.title}</h5>
            {
                this.state.collectionItems.length === 0 && 
                <div className="callout">
                    <h5>{__("A gyűjtemény jelenleg üres.")}</h5>
                    <p>
                      {__("Hozzáadni elemeket a (gyűjtemény) gombra kattintva lehet a könyvekben, feladattárban, médiatárban.")}  

                    </p>
                </div>
            }
            
            <List onDrop={this.onDrop.bind(this)}>
                {
                    this.state.collectionItems.map((item, index) => 
                        <CollectionItemComponent dragIndex={this.state.saving ? undefined : index} 
                        key={item.id} 
                        item={item} 
                        showUserPlugin={true}
                        onReload={this.reloadAsync.bind(this)} 
                        onDelete={this.onDelete.bind(this)} />
                    )
                }
            </List>
        </div>;
    }
}
/*
type CollectionItemProps = {
    item: ICollectionItemRecord;
    onReload: () => void;
    dragIndex?: number;
}

class CollectionItem extends React.Component<CollectionItemProps, {details?: ContentDetails}> {

    constructor(props: any) {
        super(props);
        this.state = {
            
        }
    }
    componentDidMount() {
        this.reloadAsync();
    }
    componentDidUpdate(prevProps: CollectionItemProps) {
        if (this.props.item.id != prevProps.item.id) {
            this.reloadAsync();
        }
    }

    private async reloadAsync() {
        const details = await getContentDetails(this.props.item.table_info_id!, this.props.item.rec_id!);
        this.setState({
            details
        })
    }

    private async onDelete() {
        if (!await confirmDialog(__("Törlés"), __("Biztosan törölni akarja a gyűjteményből?"), __("Törlés"))) return;

        try {
            await CollectionItemCrud.deleteById(this.props.item.id!);
            this.props.onReload();
        } catch(e) {
            app.showErrorFromJsonResult(e);
        }
    }

    render() {
        return <ListItem dragIndex={this.props.dragIndex}>
            <ListItemImage src={this.state.details && this.state.details.thumbnailUrl} />
            <ListItemText>
                <strong>
                    <Link to={this.state.details ? this.state.details.viewUrl : ""}>
                        {this.state.details &&this.state.details.type? "["+this.state.details.type+"] - " : ""}
                        {this.state.details &&this.state.details.displayName? this.state.details.displayName : ""}               
                         <br/>
                    </Link>
                </strong>
                <span className="content-item-list__small">{__("Hozzáadva")}: {formatDate(this.props.item.creation_time)}</span>
            </ListItemText>

            <ListItemMenu
                mainItems={[
                    {
                        icon: <i className="fa fa-fw fa-trash" />,
                        type: "alert",
                        onClick: this.onDelete.bind(this)
                    }
                ]}
            >
                <UsrLikePlugin 
                    tableInfoId={this.props.item.table_info_id!}
                    recId={this.props.item.rec_id!}
                />
            </ListItemMenu>
        </ListItem>
    }

}
*/