import * as React from 'react';
import { BubbleLoader } from 'react-css-loaders';
import { me } from '@src/framework/server/Auth';
import ReactTable, { Column } from 'react-table';
import 'react-table/react-table.css';
import { getReactTableLabels } from '@framework/i18n';
import { DialogContent, Dialog } from '@src/component/Dialog';
import ViewClassVisitDfhtAspectAnswer from '@src/framework/view/kap/ViewClassVisitDfhtAspectAnswer';
import ViewClassVisitComplexAspectAnswer from '@src/framework/view/kap/ViewClassVisitComplexAspectAnswer';
import ViewOccupationVisitAspectAnswer from '@src/framework/view/kap/ViewOccupationVisitAspectAnswer';
import ViewOccupationVisitSubprogramStats from '@src/framework/view/kap/ViewOccupationVisitSubprogramStats';
import ViewClassVisitSubprogramStats from '@src/framework/view/kap/ViewClassVisitSubprogramStats';
import { __ } from "@src/translation";
import { app } from '@src/index';
import './AttendanceReportsData.css';
import ViewKapBeadandoStats from '@src/framework/view/kap/ViewKapBeadandoStats';
import ViewKapBeadandoSummaryStats from '@src/framework/view/kap/ViewKapBeadandoSummaryStats';
import ViewOfficialKapDraftStats from '@src/framework/view/kap/ViewOfficialKapDraftStats';

type ReportViewDataItem = {
    label?: any;
    data?: string;
}

type AttendanceReportDataProps = {
    reportType: number;
}

type AttendanceReportDataState = {
    fullViewIsOpen: boolean;
    reportType: number;
    reportLoaded: boolean;
    data: any[];
    selected?: any;
    pageSize: number;
    rowData: ReportViewDataItem[];
}

export class AttendanceReportData extends React.Component<AttendanceReportDataProps, AttendanceReportDataState> {
    viewList: any[] = [ViewClassVisitDfhtAspectAnswer, ViewClassVisitComplexAspectAnswer, ViewOccupationVisitAspectAnswer,
        ViewOccupationVisitSubprogramStats, ViewClassVisitSubprogramStats, ViewKapBeadandoStats, ViewKapBeadandoSummaryStats,ViewOfficialKapDraftStats];
    reportTitle: string[] = ['DFHT-KIP szempontsorok', 'Komplex szempontsorok',
        'Alprogrami szempontsorok', 'Alprogrami statisztika', 'DFHT-KIP és Komplex statisztika','JTR beadandó statisztika','JTR beadandó összesítő','DFHT-KIP és Komplex összegzés'];
    reportColumns: Column[][] = [
        [
            { Header: "Sorszám", id: "row", Cell: (row) => { return <div>{row.index + 1}</div>; } },
            { Header: __("Intézmény látogatás azonosító"), accessor: "id", filterable: true, sortable: true, style: { justifyContent: "left" } },
            { Header: __("Látogatás kezdete"), accessor: "min_start_date", filterable: true, sortable: true, style: { justifyContent: "left" } },
            { Header: __("Feladat ellátási hely neve"), accessor: "institute_site_name", filterable: true, sortable: true, style: { justifyContent: "left" } },
            { Header: __("Ellátási hely azonosító"), accessor: "site_identifier", filterable: true, sortable: true, style: { justifyContent: "left" } },
            { Header: __("Ellátási hely irányítószám"), accessor: "institute_site_zipcode", filterable: true, sortable: true, style: { justifyContent: "left" } },
            { Header: __("Ellátási hely címsor"), accessor: "institute_site_address", filterable: true, sortable: true, style: { justifyContent: "left" } },
            { Header: __("Ellátási hely település azonosító"), accessor: "city_id", filterable: true, sortable: true, style: { justifyContent: "left" } },
            { Header: __("Ellátási hely település név"), accessor: "city_name", filterable: true, sortable: true, style: { justifyContent: "left" } },
            { Header: __("Intézmény azonosító"), accessor: "institute_id", filterable: true, sortable: true, style: { justifyContent: "left" } },
            { Header: __("Intézmény OM azonosító"), accessor: "om_identifier", filterable: true, sortable: true, style: { justifyContent: "left" } },
            { Header: __("Intézmény neve"), accessor: "institute_name", filterable: true, sortable: true, style: { justifyContent: "left" } },
            { Header: __("Tanár felhasználó azonosító"), accessor: "teacher_id", filterable: true, sortable: true, style: { justifyContent: "left" } },
            { Header: __("Tanár személy azonosító"), accessor: "teacher_person_id", filterable: true, sortable: true, style: { justifyContent: "left" } },
            { Header: __("Tanár e-mail cím"), accessor: "teacher_email", filterable: true, sortable: true, style: { justifyContent: "left" } },
            { Header: __("Tanár teljes neve"), accessor: "teacher_fullname", filterable: true, sortable: true, style: { justifyContent: "left" } },
            { Header: __("Támogató felhasználó azonosító"), accessor: "kap_supporter_id", filterable: true, sortable: true, style: { justifyContent: "left" } },
            { Header: __("Támogató személy azonosító"), accessor: "supporter_person_id", filterable: true, sortable: true, style: { justifyContent: "left" } },
            { Header: __("Támogató e-mail cím"), accessor: "supporter_email", filterable: true, sortable: true, style: { justifyContent: "left" } },
            { Header: __("Támogató teljes neve"), accessor: "supporter_fullname", filterable: true, sortable: true, style: { justifyContent: "left" } },
            { Header: __("Tantárgy azonosító"), accessor: "subject_id", filterable: true, sortable: true, style: { justifyContent: "left" } },
            { Header: __("Tantárgy név"), accessor: "subject_name", filterable: true, sortable: true, style: { justifyContent: "left" } },
            { Header: __("Óralátogatás azonosító"), accessor: "class_visit_id", filterable: true, sortable: true, style: { justifyContent: "left" } },
            { Header: __("Óralátogatás osztály neve"), accessor: "class_visit_class_name", filterable: true, sortable: true, style: { justifyContent: "left" } },
            { Header: __("Óralátogatás téma"), accessor: "class_visit_topic", filterable: true, sortable: true, style: { justifyContent: "left" } },
            { Header: __("Sablon azonosítója"), accessor: "kip_aspect_id", filterable: true, sortable: true, style: { justifyContent: "left" } },
            { Header: __("Sablon neve"), accessor: "kip_aspect_title", filterable: true, sortable: true, style: { justifyContent: "left" } },
            { Header: __("Szempont típus azonosító"), accessor: "kip_aspect_type_id", filterable: true, sortable: true, style: { justifyContent: "left" } },
            { Header: __("Válasz azonosító"), accessor: "kip_aspect_answer_id", filterable: true, sortable: true, style: { justifyContent: "left" } },
            { Header: __("Szöveges válasz"), accessor: "text_value", filterable: true, sortable: true, style: { justifyContent: "left" } },
            { Header: __("Logikai válasz"), accessor: "boolean_value", filterable: true, sortable: true, style: { justifyContent: "left" } },
            { Header: __("Válasz"), accessor: "kat_value", filterable: true, sortable: true, style: { justifyContent: "left" } },
            { Header: __("Folyamat azonosító"), accessor: "workflow_id", filterable: true, sortable: true, style: { justifyContent: "left" } },
            { Header: __("Folyamat állapot azonosító"), accessor: "wf_station_id", filterable: true, sortable: true, style: { justifyContent: "left" } },
            { Header: __("Folyamat állapot név"), accessor: "wf_station_name", filterable: true, sortable: true, style: { justifyContent: "left" } },
        ],
        [
            { Header: "Sorszám", id: "row", Cell: (row) => { return <div>{row.index + 1}</div>; } },
            { Header: __("Intézmény látogatás azonosító"), accessor: "id", filterable: true, sortable: true, style: { justifyContent: "left" } },
            { Header: __("Látogatás kezdete"), accessor: "min_start_date", filterable: true, sortable: true, style: { justifyContent: "left" } },
            { Header: __("Ellátási hely azonosító"), accessor: "institute_site_id", filterable: true, sortable: true, style: { justifyContent: "left" } },
            { Header: __("Feladat ellátási hely neve"), accessor: "institute_site_name", filterable: true, sortable: true, style: { justifyContent: "left" } },
            { Header: __("Ellátási hely azonosító"), accessor: "site_identifier", filterable: true, sortable: true, style: { justifyContent: "left" } },
            { Header: __("Ellátási hely irányítószám"), accessor: "institute_site_zipcode", filterable: true, sortable: true, style: { justifyContent: "left" } },
            { Header: __("Ellátási hely címsor"), accessor: "institute_site_address", filterable: true, sortable: true, style: { justifyContent: "left" } },
            { Header: __("Ellátási hely település azonosító"), accessor: "city_id", filterable: true, sortable: true, style: { justifyContent: "left" } },
            { Header: __("Ellátási hely település név"), accessor: "city_name", filterable: true, sortable: true, style: { justifyContent: "left" } },
            { Header: __("Intézmény azonosító"), accessor: "institute_id", filterable: true, sortable: true, style: { justifyContent: "left" } },
            { Header: __("Intézmény OM azonosító"), accessor: "om_identifier", filterable: true, sortable: true, style: { justifyContent: "left" } },
            { Header: __("Intézmény neve"), accessor: "institute_name", filterable: true, sortable: true, style: { justifyContent: "left" } },
            { Header: __("Tanár felhasználó azonosító"), accessor: "teacher_id", filterable: true, sortable: true, style: { justifyContent: "left" } },
            { Header: __("Tanár személy azonosító"), accessor: "teacher_person_id", filterable: true, sortable: true, style: { justifyContent: "left" } },
            { Header: __("Tanár e-mail cím"), accessor: "teacher_email", filterable: true, sortable: true, style: { justifyContent: "left" } },
            { Header: __("Tanár teljes neve"), accessor: "teacher_fullname", filterable: true, sortable: true, style: { justifyContent: "left" } },
            { Header: __("Támogató felhasználó azonosító"), accessor: "kap_supporter_id", filterable: true, sortable: true, style: { justifyContent: "left" } },
            { Header: __("Támogató személy azonosító"), accessor: "supporter_person_id", filterable: true, sortable: true, style: { justifyContent: "left" } },
            { Header: __("Támogató e-mail cím"), accessor: "supporter_email", filterable: true, sortable: true, style: { justifyContent: "left" } },
            { Header: __("Támogató teljes neve"), accessor: "supporter_fullname", filterable: true, sortable: true, style: { justifyContent: "left" } },
            { Header: __("Tantárgy azonosító"), accessor: "subject_id", filterable: true, sortable: true, style: { justifyContent: "left" } },
            { Header: __("Tantárgy név"), accessor: "subject_name", filterable: true, sortable: true, style: { justifyContent: "left" } },
            { Header: __("Óralátogatás azonosító"), accessor: "class_visit_id", filterable: true, sortable: true, style: { justifyContent: "left" } },
            { Header: __("Óralátogatás osztály neve"), accessor: "class_visit_class_name", filterable: true, sortable: true, style: { justifyContent: "left" } },
            { Header: __("Óralátogatás téma"), accessor: "class_visit_topic", filterable: true, sortable: true, style: { justifyContent: "left" } },
            { Header: __("Sablon azonosító"), accessor: "complex_aspect_id", filterable: true, sortable: true, style: { justifyContent: "left" } },
            { Header: __("Sablon név"), accessor: "complex_aspect_title", filterable: true, sortable: true, style: { justifyContent: "left" } },
            { Header: __("Alprogram azonosító"), accessor: "subprogram_id", filterable: true, sortable: true, style: { justifyContent: "left" } },
            { Header: __("Alprogram kód"), accessor: "subprogram_code", filterable: true, sortable: true, style: { justifyContent: "left" } },
            { Header: __("Alprogram név"), accessor: "subprogram_title", filterable: true, sortable: true, style: { justifyContent: "left" } },
            { Header: __("Sablon típus azonosító"), accessor: "complex_aspect_type_id", filterable: true, sortable: true, style: { justifyContent: "left" } },
            { Header: __("Sablon típus név"), accessor: "complex_aspect_type_title", filterable: true, sortable: true, style: { justifyContent: "left" } },
            { Header: __("Válasz azonosító"), accessor: "complex_aspect_answer_id", filterable: true, sortable: true, style: { justifyContent: "left" } },
            { Header: __("Válasz érték azonosító"), accessor: "complex_aspect_value_id", filterable: true, sortable: true, style: { justifyContent: "left" } },
            { Header: __("Válasz"), accessor: "complex_aspect_value_title", filterable: true, sortable: true, style: { justifyContent: "left" } },
            { Header: __("Folyamat azonosító"), accessor: "workflow_id", filterable: true, sortable: true, style: { justifyContent: "left" } },
            { Header: __("Folyamat állapot azonosító"), accessor: "wf_station_id", filterable: true, sortable: true, style: { justifyContent: "left" } },
            { Header: __("Folyamat állapot név"), accessor: "wf_station_name", filterable: true, sortable: true, style: { justifyContent: "left" } },
        ],
        [
            { Header: "Sorszám", id: "row", Cell: (row) => { return <div>{row.index + 1}</div>; } },
            { Header: __("Foglalkozás látogatás azonosító"), accessor: "id", filterable: true, sortable: true, style: { justifyContent: "left" } },
            { Header: __("Látogatás kezdete"), accessor: "min_start_date", filterable: true, sortable: true, style: { justifyContent: "left" } },
            { Header: __("Feladat ellátási hely neve"), accessor: "institute_site_name", filterable: true, sortable: true, style: { justifyContent: "left" } },
            { Header: __("Ellátási hely azonosító"), accessor: "site_identifier", filterable: true, sortable: true, style: { justifyContent: "left" } },
            { Header: __("Intézmény azonosító"), accessor: "institute_id", filterable: true, sortable: true, style: { justifyContent: "left" } },
            { Header: __("Intézmény OM azonosító"), accessor: "om_identifier", filterable: true, sortable: true, style: { justifyContent: "left" } },
            { Header: __("Intézmény neve"), accessor: "institute_name", filterable: true, sortable: true, style: { justifyContent: "left" } },
            { Header: __("Tanár felhasználó azonosító"), accessor: "teacher_id", filterable: true, sortable: true, style: { justifyContent: "left" } },
            { Header: __("Tanár személy azonosító"), accessor: "teacher_person_id", filterable: true, sortable: true, style: { justifyContent: "left" } },
            { Header: __("Tanár teljes neve"), accessor: "teacher_fullname", filterable: true, sortable: true, style: { justifyContent: "left" } },
            { Header: __("Támogató felhasználó azonosító"), accessor: "kap_supporter_id", filterable: true, sortable: true, style: { justifyContent: "left" } },
            { Header: __("Támogató személy azonosító"), accessor: "supporter_person_id", filterable: true, sortable: true, style: { justifyContent: "left" } },
            { Header: __("Támogató teljes neve"), accessor: "supporter_fullname", filterable: true, sortable: true, style: { justifyContent: "left" } },
            { Header: __("Sablon azonosítója"), accessor: "subprogram_aspect_id", filterable: true, sortable: true, style: { justifyContent: "left" } },
            { Header: __("Sablon megnevezése"), accessor: "subprogram_aspect_title", filterable: true, sortable: true, style: { justifyContent: "left" } },
            { Header: __("Alprogram azonosítója"), accessor: "subprogram_id", filterable: true, sortable: true, style: { justifyContent: "left" } },
            { Header: __("Alprogram kódja"), accessor: "subprogram_code", filterable: true, sortable: true, style: { justifyContent: "left" } },
            { Header: __("Alprogram neve"), accessor: "subprogram_title", filterable: true, sortable: true, style: { justifyContent: "left" } },
            { Header: __("Válasz azonoítója"), accessor: "subprogram_aspect_answer_id", filterable: true, sortable: true, style: { justifyContent: "left" } },
            { Header: __("Válasz érték azonosítója"), accessor: "subprogram_aspect_value_id", filterable: true, sortable: true, style: { justifyContent: "left" } },
            { Header: __("Válasz érték neve"), accessor: "subprogram_aspect_value_title", filterable: true, sortable: true, style: { justifyContent: "left" } },
            { Header: __("Válaszhoz tartozó megjegyzés"), accessor: "subprogram_aspect_answer_comments", filterable: true, sortable: true, style: { justifyContent: "left" } },
            { Header: __("Folyamat azonosító"), accessor: "workflow_id", filterable: true, sortable: true, style: { justifyContent: "left" } },
            { Header: __("Folyamat állapot azonosító"), accessor: "wf_station_id", filterable: true, sortable: true, style: { justifyContent: "left" } },
            { Header: __("Folyamat állapot név"), accessor: "wf_station_name", filterable: true, sortable: true, style: { justifyContent: "left" } },
        ],
        [
            { Header: "Sorszám", id: "row", Cell: (row) => { return <div>{row.index + 1}</div>; } },
            { Header: __("Foglalkozás látogatás azonosító"), accessor: "id", filterable: true, sortable: true, style: { justifyContent: "left" } },
            { Header: __("Látogatás kezdete"), accessor: "min_start_date", filterable: true, sortable: true, style: { justifyContent: "left" } },
            { Header: __("Feladat ellátási hely neve"), accessor: "institute_site_name", filterable: true, sortable: true, style: { justifyContent: "left" } },
            { Header: __("Ellátási hely azonosító"), accessor: "site_identifier", filterable: true, sortable: true, style: { justifyContent: "left" } },
            { Header: __("Ellátási hely irányítószám"), accessor: "institute_site_zipcode", filterable: true, sortable: true, style: { justifyContent: "left" } },
            { Header: __("Ellátási hely címsor"), accessor: "institute_site_address", filterable: true, sortable: true, style: { justifyContent: "left" } },
            { Header: __("Ellátási hely település azonosító"), accessor: "city_id", filterable: true, sortable: true, style: { justifyContent: "left" } },
            { Header: __("Ellátási hely település név"), accessor: "city_name", filterable: true, sortable: true, style: { justifyContent: "left" } },
            { Header: __("Intézmény azonosító"), accessor: "institute_id", filterable: true, sortable: true, style: { justifyContent: "left" } },
            { Header: __("Intézmény OM azonosító"), accessor: "om_identifier", filterable: true, sortable: true, style: { justifyContent: "left" } },
            { Header: __("Intézmény neve"), accessor: "institute_name", filterable: true, sortable: true, style: { justifyContent: "left" } },
            { Header: __("Tanár felhasználó azonosító"), accessor: "teacher_id", filterable: true, sortable: true, style: { justifyContent: "left" } },
            { Header: __("Tanár személy azonosító"), accessor: "teacher_person_id", filterable: true, sortable: true, style: { justifyContent: "left" } },
            { Header: __("Tanár e-mail cím"), accessor: "teacher_email", filterable: true, sortable: true, style: { justifyContent: "left" } },
            { Header: __("Tanár teljes neve"), accessor: "teacher_fullname", filterable: true, sortable: true, style: { justifyContent: "left" } },
            { Header: __("Támogató felhasználó azonosító"), accessor: "kap_supporter_id", filterable: true, sortable: true, style: { justifyContent: "left" } },
            { Header: __("Támogató személy azonosító"), accessor: "supporter_person_id", filterable: true, sortable: true, style: { justifyContent: "left" } },
            { Header: __("Támogató e-mail cím"), accessor: "supporter_email", filterable: true, sortable: true, style: { justifyContent: "left" } },
            { Header: __("Támogató teljes neve"), accessor: "supporter_fullname", filterable: true, sortable: true, style: { justifyContent: "left" } },
            { Header: __("Min. létszám"), accessor: "min_visitors", filterable: true, sortable: true, style: { justifyContent: "left" } },
            { Header: __("Max. létszám"), accessor: "max_visitors", filterable: true, sortable: true, style: { justifyContent: "left" } },
            { Header: __("Jelentkezések száma"), accessor: "applied_visitors", filterable: true, sortable: true, style: { justifyContent: "left" } },
            { Header: __("Feltöltött állományok száma"), accessor: "file_count", filterable: true, sortable: true, style: { justifyContent: "left" } },
            { Header: __("Szempontsor kitöltve"), accessor: "has_subprogram_aspect_answer", filterable: true, sortable: true, style: { justifyContent: "left" } },
            { Header: __("Folyamat azonosító"), accessor: "workflow_id", filterable: true, sortable: true, style: { justifyContent: "left" } },
            { Header: __("Folyamat állapot azonosító"), accessor: "wf_station_id", filterable: true, sortable: true, style: { justifyContent: "left" } },
            { Header: __("Folyamat állapot név"), accessor: "wf_station_name", filterable: true, sortable: true, style: { justifyContent: "left" } },
        ],
        [
            { Header: "Sorszám", id: "row", Cell: (row) => { return <div>{row.index + 1}</div>; } },
            { Header: __("Intézmény látogatás azonosító"), accessor: "id", filterable: true, sortable: true, style: { justifyContent: "left" } },
            { Header: __("Látogatás kezdete"), accessor: "min_start_date", filterable: true, sortable: true, style: { justifyContent: "left" } },
            { Header: __("Ellátási hely azonosító"), accessor: "institute_site_id", filterable: true, sortable: true, style: { justifyContent: "left" } },
            { Header: __("Feladat ellátási hely neve"), accessor: "institute_site_name", filterable: true, sortable: true, style: { justifyContent: "left" } },
            { Header: __("Ellátási hely azonosító"), accessor: "site_identifier", filterable: true, sortable: true, style: { justifyContent: "left" } },
            { Header: __("Ellátási hely irányítószám"), accessor: "institute_site_zipcode", filterable: true, sortable: true, style: { justifyContent: "left" } },
            { Header: __("Ellátási hely címsor"), accessor: "institute_site_address", filterable: true, sortable: true, style: { justifyContent: "left" } },
            { Header: __("Ellátási hely település azonosító"), accessor: "city_id", filterable: true, sortable: true, style: { justifyContent: "left" } },
            { Header: __("Ellátási hely település név"), accessor: "city_name", filterable: true, sortable: true, style: { justifyContent: "left" } },
            { Header: __("Intézmény azonosító"), accessor: "institute_id", filterable: true, sortable: true, style: { justifyContent: "left" } },
            { Header: __("Intézmény OM azonosító"), accessor: "om_identifier", filterable: true, sortable: true, style: { justifyContent: "left" } },
            { Header: __("Intézmény neve"), accessor: "institute_name", filterable: true, sortable: true, style: { justifyContent: "left" } },
            { Header: __("Tanár felhasználó azonosító"), accessor: "teacher_id", filterable: true, sortable: true, style: { justifyContent: "left" } },
            { Header: __("Tanár személy azonosító"), accessor: "teacher_person_id", filterable: true, sortable: true, style: { justifyContent: "left" } },
            { Header: __("Tanár e-mail cím"), accessor: "teacher_email", filterable: true, sortable: true, style: { justifyContent: "left" } },
            { Header: __("Tanár teljes neve"), accessor: "teacher_fullname", filterable: true, sortable: true, style: { justifyContent: "left" } },
            { Header: __("Támogató felhasználó azonosító"), accessor: "kap_supporter_id", filterable: true, sortable: true, style: { justifyContent: "left" } },
            { Header: __("Támogató személy azonosító"), accessor: "supporter_person_id", filterable: true, sortable: true, style: { justifyContent: "left" } },
            { Header: __("Támogató e-mail cím"), accessor: "supporter_email", filterable: true, sortable: true, style: { justifyContent: "left" } },
            { Header: __("Támogató teljes neve"), accessor: "supporter_fullname", filterable: true, sortable: true, style: { justifyContent: "left" } },
            { Header: __("Óralátogatás azonosító"), accessor: "class_visit_id", filterable: true, sortable: true, style: { justifyContent: "left" } },
            { Header: __("Óra sorszám"), accessor: "lesson_no", filterable: true, sortable: true, style: { justifyContent: "left" } },
            { Header: __("Óra kezdete"), accessor: "start_date", filterable: true, sortable: true, style: { justifyContent: "left" } },
            { Header: __("Óra vége"), accessor: "end_date", filterable: true, sortable: true, style: { justifyContent: "left" } },
            { Header: __("Óralátogatás típus azonosító"), accessor: "class_visit_type_id", filterable: true, sortable: true, style: { justifyContent: "left" } },
            { Header: __("Óralátogatás típus név"), accessor: "class_visit_type_title", filterable: true, sortable: true, style: { justifyContent: "left" } },
            { Header: __("Tárgy azonosító"), accessor: "subject_id", filterable: true, sortable: true, style: { justifyContent: "left" } },
            { Header: __("Tárgy név"), accessor: "subject_name", filterable: true, sortable: true, style: { justifyContent: "left" } },
            { Header: __("Osztály neve"), accessor: "class_visit_class_name", filterable: true, sortable: true, style: { justifyContent: "left" } },
            { Header: __("Állományok száma"), accessor: "file_count", filterable: true, sortable: true, style: { justifyContent: "left" } },
            { Header: __("Szempontsor kitöltve"), accessor: "has_aspect_answer", filterable: true, sortable: true, style: { justifyContent: "left" } },
            { Header: __("Folyamat azonosító"), accessor: "workflow_id", filterable: true, sortable: true, style: { justifyContent: "left" } },
            { Header: __("Folyamat állapot azonosító"), accessor: "wf_station_id", filterable: true, sortable: true, style: { justifyContent: "left" } },
            { Header: __("Folyamat állapot név"), accessor: "wf_station_name", filterable: true, sortable: true, style: { justifyContent: "left" } },
        ],
        [
            { Header: "Sorszám", id: "row", Cell: (row) => { return <div>{row.index + 1}</div>; } },
            { Header: __("Azonosító"), accessor: "id", filterable: true, sortable: true, style: { justifyContent: "left" } },
            { Header: __("Feltöltve"), accessor: "feltoltve", filterable: true, sortable: true, style: { justifyContent: "left" } },
            { Header: __("Feltöltve (év)"), accessor: "feltoltve_ev", filterable: true, sortable: true, style: { justifyContent: "left" } },
            { Header: __("Feltöltve (hó)"), accessor: "feltoltve_ho", filterable: true, sortable: true, style: { justifyContent: "left" } },
            { Header: __("Tantárgy"), accessor: "tantargy", filterable: true, sortable: true, style: { justifyContent: "left" } },
            { Header: __("Osztály"), accessor: "osztaly", filterable: true, sortable: true, style: { justifyContent: "left" } },
            { Header: __("Tematikus egység"), accessor: "thematic_unit", filterable: true, sortable: true, style: { justifyContent: "left" } },
            { Header: __("Szerzők"), accessor: "szerzok", filterable: true, sortable: true, style: { justifyContent: "left" } },
            
            { Header: __("Téma"), accessor: "topic", filterable: true, sortable: true, style: { justifyContent: "left" } },
            { Header: __("Cél"), accessor: "goal", filterable: true, sortable: true, style: { justifyContent: "left" } },
            { Header: __("Feltöltő e-mail"), accessor: "feltolto_email", filterable: true, sortable: true, style: { justifyContent: "left" } },
            { Header: __("Feltöltő teljes név"), accessor: "feltolto_teljes_nev", filterable: true, sortable: true, style: { justifyContent: "left" } },
            { Header: __("OM azonosító"), accessor: "om_azonosit", filterable: true, sortable: true, style: { justifyContent: "left" } },
            { Header: __("Intézmény név"), accessor: "intezmeny_nev", filterable: true, sortable: true, style: { justifyContent: "left" } },
            { Header: __("Ellátási hely azonosító"), accessor: "ellatasi_hely_azonosito", filterable: true, sortable: true, style: { justifyContent: "left" } },
            { Header: __("Ellátási hely név"), accessor: "ellatasi_hely_nev", filterable: true, sortable: true, style: { justifyContent: "left" } },
            { Header: __("Állapot"), accessor: "allapot", filterable: true, sortable: true, style: { justifyContent: "left" } },
            { Header: __("Lezárva"), accessor: "lezarva", filterable: true, sortable: true, style: { justifyContent: "center" }, Cell: (row) => { return <div>{row.original.lezarva?__("Igen"):__("Nem")}</div>; } },
            { Header: __("Szakmai támogató"), accessor: "szakmai_tamogato", filterable: true, sortable: true, style: { justifyContent: "center" } },
            { Header: __("Hozzájárult"), accessor: "hozzajarult", filterable: true, sortable: true, style: { justifyContent: "center" } },
            { Header: __("Kiválónak jelölt"), accessor: "kivalonak_jelolt", filterable: true, sortable: true, style: { justifyContent: "center" } },         
        ],
        [
            { Header: "Sorszám", id: "row", Cell: (row) => { return <div>{row.index + 1}</div>; } },
            { Header: __("Feltöltve (év)"), accessor: "feltoltve_ev", filterable: true, sortable: true, style: { justifyContent: "left" } },
            { Header: __("Feltöltve (hó)"), accessor: "feltoltve_ho", filterable: true, sortable: true, style: { justifyContent: "left" } },
            { Header: __("OM azonosító"), accessor: "om_azonosit", filterable: true, sortable: true, style: { justifyContent: "left" } },
            { Header: __("Intézmény név"), accessor: "intezmeny_nev", filterable: true, sortable: true, style: { justifyContent: "left" } },
            { Header: __("Ellátási hely azonosító"), accessor: "ellatasi_hely_azonosito", filterable: true, sortable: true, style: { justifyContent: "left" } },
            { Header: __("Ellátási hely név"), accessor: "ellatasi_hely_nev", filterable: true, sortable: true, style: { justifyContent: "left" } },
            { Header: __("Állapot"), accessor: "allapot", filterable: true, sortable: true, style: { justifyContent: "left" } },
            { Header: __("Lezárva"), accessor: "lezarva", filterable: true, sortable: true, style: { justifyContent: "center" }, Cell: (row) => { return <div>{row.original.lezarva?__("Igen"):__("Nem")}</div>; } },
            { Header: __("Darabszám"), accessor: "darabszam", filterable: true, sortable: true, style: { justifyContent: "right" } },
        ],
        [
            { Header: "Sorszám", id: "row", Cell: (row) => { return <div>{row.index + 1}</div>; } },
            { Header: __("Fajta"), accessor: "kind", filterable: true, sortable: true, style: { justifyContent: "left" } },
            { Header: __("Évfolyam"), accessor: "grade_name", filterable: true, sortable: true, style: { justifyContent: "left" } },
            { Header: __("Tantárgy"), accessor: "subject_name", filterable: true, sortable: true, style: { justifyContent: "left" } },
            { Header: __("Folyamat"), accessor: "wf_station_name", filterable: true, sortable: true, style: { justifyContent: "left" } },
            { Header: __("Darabszám"), accessor: "cnt", filterable: true, sortable: true, style: { justifyContent: "left" } },
          
        ]

    ];

    constructor(props: AttendanceReportDataProps) {
        super(props);
        this.state = {
            fullViewIsOpen: false,
            reportType: props.reportType - 1,
            reportLoaded: false,
            data: [],
            selected: 0,
            pageSize: 20,
            rowData: [],
        }
        this.reloadAsync();
    }

    componentDidMount() {
        this.reloadAsync();
    }

    private async reloadAsync() {
        this.state = {
            fullViewIsOpen: false,
            reportType: this.state.reportType,
            reportLoaded: false,
            data: [],
            selected: 0,
            pageSize: 20,
            rowData: [],
        }
        let data: any[] = [];
        try {
            data = (await this.viewList[this.state.reportType].list({
                limit: 10000
            }));
            this.setState({ reportLoaded: true, data, selected: 0 });
        } catch (error) {
            app.showErrorFromJsonResult(error);
        };
    }

    private reportDataView = () => {
        let reportViewData: ReportViewDataItem[] = [];
        var fieldName: string;
        for (let i = 1; i < this.reportColumns[this.state.reportType].length; i++) {
            fieldName = String(this.reportColumns[this.state.reportType][i].accessor);
            let dataItem: ReportViewDataItem = { label: "", data: "" };
            dataItem.label = this.reportColumns[this.state.reportType][i].Header;
            dataItem.data = this.state.data[this.state.selected][fieldName];
            reportViewData.push(dataItem);
        }
        this.setState({ fullViewIsOpen: true, rowData: reportViewData });
    }

    private reportDataExport = () => {
        var location = this.viewList[this.state.reportType].getExcelDownloadUrl({
            limit: 10000
        }, { filename: this.reportTitle[this.state.reportType] + ".xlsx" });
        window.location.replace(location);
    }
    render() {
        if (!me) return null;
        if (!this.state.reportLoaded) { return <BubbleLoader />; }
        const getColumnWidth = (rows: any[], accessor: any, headerText: any) => {
            const maxWidth = 400
            const magicSpacing = 10
            const cellLength = Math.max(
                ...rows.map(row => (`${row[accessor]}` || '').length),
                headerText.length,
            )
            return Math.min(maxWidth, cellLength * magicSpacing)
        }
        for (let i = 0; i < this.reportColumns.length; i++) {
            for (let j = 0; j < this.reportColumns[i].length; j++) {
                this.reportColumns[i][j].width = getColumnWidth(this.state.data, this.reportColumns[i][j].accessor, this.reportColumns[i][j].Header);
                this.reportColumns[i][j].className = "report-view-column";
            }
        }
        return <>
            <div className="report-box">
                <div className="report-title">
                    <h4>{this.reportTitle[this.state.reportType]}</h4>
                </div>
                <div className="report-view-row">
                    <button className="button warning" title="Adott sor megtekintése" onClick={this.reportDataView}>Adott sor megtekintése</button>
                </div>
                <div className="report-view-export">
                    <button className="button success" title="Adatok exportálása" onClick={this.reportDataExport}>Adatok exportálása</button>
                </div>
                <div className="report-active-rowtitle">
                    Az aktív sor száma: <b>{this.state.selected + 1}</b>
                </div>
            </div>
            <ReactTable data={this.state.data}
                columns={this.reportColumns[this.state.reportType]}
                resizable={false}
                pageSize={this.state.pageSize}
                onPageChange={page => this.setState({ selected: page * this.state.pageSize })}
                className="-striped -highlight report-view-table"
                showPageSizeOptions={true}
                {...getReactTableLabels()}
                getTrProps={(state: any, rowInfo: any) => {
                    if (rowInfo && rowInfo.row) {
                        return {
                            onClick: (e: any) => {
                                if (this.state.selected !== null && this.state.selected === rowInfo.index)
                                    this.setState({ selected: null })
                                else this.setState({ selected: rowInfo.index })
                            },
                            style: {
                                background: rowInfo.index === this.state.selected ? '#00afec' : 'white',
                                color: rowInfo.index === this.state.selected ? 'white' : 'black'
                            }
                        }
                    } else { return {} }
                }}
            />
            {this.state.fullViewIsOpen &&
                <Dialog title={this.reportTitle[this.state.reportType] + " - aktuális sor megtekintése ( sor száma: " + eval(this.state.selected + 1) + " )"} width={1200} outsideWindowClickClose={false} onClose={() => { this.setState({ fullViewIsOpen: false }) }} className="reportDataRowDialog">
                    <DialogContent>
                        <div className="reportDataViewFrame">
                            {this.state.rowData.map((data, i) => (
                                <div className="reportDataViewBox" key={"Box_" + i}>
                                    <div className="reportDataViewLabel" key={"Label_" + i}>
                                        {data.label}:
                                    </div>
                                    <div className="reportDataViewData" key={"Data_" + i}>
                                        {data.data}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </DialogContent>
                </Dialog>
            }
        </>
    }

}