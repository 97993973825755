import { Server } from '../../server/Server';
import View , { registerViewClassProxy, ViewClassProxy } from '../View';
import { IListParams } from '@framework/crud/Crud';

export interface IViewKapCourseRecord {
    /**
     * Azonosító
     *
     * Azonosító (pótkulcs)
     */
    id?: number;
    /**
     * Rekord létrehozás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel létrehozásakor.
     */
    creation_time?: string /*timestamp?*/;
    /**
     * Ki hozta létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozó felhasználóra hivatkozik.
     */
    creation_user_id?: number;
    /**
     * Melyik session-ből jött létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozáshoz használt munkamenetre hivatkozik.
     */
    creation_session_id?: number|null;
    /**
     * Utolsó módosítás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel módosításakor.
     */
    modification_time?: string /*timestamp?*/|null;
    /**
     * Ki módosította utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosító felhasználóra hivatkozik.
     */
    modification_user_id?: number|null;
    /**
     * Melyik session-ből módosították utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosításhoz használt munkamenetre hivatkozik.
     */
    modification_session_id?: number|null;
    /**
     * Aktív
     *
     * Logikai törléshez használt mező.
     */
    is_active?: boolean;
    /**
     * Képzés típus
     */
    training_type_id?: number;
    /**
     * Képző
     */
    teacher_id?: number|null;
    /**
     * Képzés helye.
     *
     *  Akkor érdemes megadni, ha a képzés egy feladatellátási helyen történik.
     */
    institute_site_id?: number|null;
    /**
     * Első nap
     *
     * A teljes képzés kezdete. Magában foglalja a távoktatást és az összes kontakt napot is.
     */
    start_date?: string /*date?*/|null;
    /**
     * Utolsó nap
     *
     * A teljes képzés vége. Magában foglalja a távoktatást és az összes kontakt napot is.
     */
    end_date?: string /*date?*/|null;
    /**
     * Legkorábbi jelentkezés intézményen belülről
     */
    apply_after_internal?: string /*timestamp?*/;
    /**
     * Legkorábbi jelentkezés intézményen kívülről
     */
    apply_after_external?: string /*timestamp?*/;
    /**
     * Legkésőbbi jelentkezés
     */
    application_deadline?: string /*timestamp?*/;
    /**
     * Résztvevők max. száma
     */
    max_participants?: number /*int4*/;
    /**
     * Feladat beadási határidő
     */
    submission_deadline?: string /*timestamp?*/;
    /**
     * Tanúsítványok létrehozva
     */
    certs_created?: string /*timestamp?*/|null;
    /**
     * Távoktatás kezdete
     */
    distance_learning_start?: string /*date?*/|null;
    /**
     * Távoktatás vége
     */
    distance_learning_end?: string /*date?*/|null;
    /**
     * Megjegyzés résztvevőknek
     */
    participants_comment_html?: string|null;
    /**
     * Megjegyzés képzőnek
     */
    teacher_comment_html?: string|null;
    /**
     * Képzés helye szövegesen
     *
     * Ezt csak akkor kell megadni, ha a képzés helye nem egy feladatellátási hely, vagy kiegészítésre szorul (pl. emelet, terem száma stb.)
     */
    address?: string|null;
    /**
     * A képzés gyökér könyvtára.
     */
    oo_folder_id?: number;
    /**
     * Továbbképzés ideje (tól)
     */
    training_from?: string /*date?*/|null;
    /**
     * Továbbképzés ideje (ig)
     */
    training_to?: string /*date?*/|null;
    /**
     * Lezárhatóra állítva
     */
    closable_at?: string /*timestamp?*/|null;
    /**
     * Lezárva
     */
    closed_at?: string /*timestamp?*/|null;
    /**
     * Iktatószám
     */
    regno?: number;
    /**
     * OH ellenőr
     */
    oh_inspector_id?: number|null;
    /**
     * Cím
     */
    title?: string;
    /**
     * Egyéb adatok
     */
    other_jsonb?: any|null;
    /**
     * Képzés típus kódja
     *
     * Képzés típus kódja
     */
    training_type_code?: string|null;
    /**
     * Engedélyszám
     *
     * Engedélyszám
     */
    license_number?: string|null;
    /**
     * Alprogram azonosító
     *
     * Alprogram azonosító
     */
    subprogram_id?: number|null;
    /**
     * Alprogram kód
     *
     * Alprogram kód
     */
    subprogram_code?: string|null;
    /**
     * Alprogram név
     *
     * Alprogram név
     */
    subprogram_title?: string|null;
    /**
     * Képző bejelentkezési név
     *
     * Képző bejelentkezési név
     */
    teacher_login_name?: string|null;
    /**
     * Képző e-mail cím
     *
     * Képző e-mail cím
     */
    teacher_email?: string|null;
    /**
     * Képző teljes név
     *
     * Képző teljes név
     */
    teacher_fullname?: string|null;
    /**
     * OM azonosító
     *
     * OM azonosító
     */
    om_identifier?: string|null;
    /**
     * Intézmény név
     *
     * Intézmény név
     */
    institute_name?: string|null;
    /**
     * Feladatellátási hely név
     *
     * Feladatellátási hely név
     */
    institute_site_name?: string|null;
    /**
     * Feladatellátási hely száma
     *
     * Feladatellátási hely száma
     */
    site_identifier?: string|null;
    /**
     * Könyv URI szegmens
     *
     * Könyv URI szegmens
     */
    book_uri_segment?: string|null;
    /**
     * Folyamat azonosító
     *
     * Folyamat azonosító
     */
    wf_workflow_id?: number|null;
    /**
     * Folyamat állapot azonosító
     *
     * Folyamat állapot azonosító
     */
    wf_station_id?: number|null;
    /**
     * Állapot
     *
     * Folyamat állapot neve
     */
    wf_station_name?: string|null;
    /**
     * Folyamat állapot leírása
     *
     * Folyamat állapot leírása
     */
    wf_station_description?: string|null;
    /**
     * Állapot stílus
     *
     * Folyamat állapot stílusa, jsonb/css
     */
    wf_station_style?: any|null;
    /**
     * Folyamat al-státusz azonosító
     *
     * Folyamat al-státusz azonosító
     */
    wf_substation_id?: number|null;
    /**
     * Folyamat al-státusz
     *
     * Folyamat al-státusz
     */
    wf_substation_name?: string|null;
    /**
     * Folyamat al-státusz leírása
     *
     * Folyamat al-státusz leírása
     */
    wf_substation_description?: string|null;
    /**
     * Folyamat szöveges állapot kiegészítés
     *
     * Folyamat szöveges állapot kiegészítés
     */
    wf_status_text?: string|null;
    /**
     * Utolsó átmenet
     *
     * Utolsó átmenet
     */
    last_transition?: string /*timestamp?*/|null;
    /**
     * Résztvevők száma
     *
     * Résztvevők száma
     */
    applied_participants?: number|null;
    /**
     * Nem értékeltek
     *
     * Nem értékeltek
     */
    not_judged_participants?: number|null;
    /**
     * Jelentkezhet
     *
     * Jelentkezhet
     */
    can_apply?: boolean|null;
    /**
     * Kijelzett név
     *
     * Kijelzett név
     */
    displayvalue?: string|null;
    /**
     * Online
     *
     * Online
     */
    is_online?: boolean|null;
    /**
     * Site id
     *
     * Site id
     */
    site_id?: number|null;
}

/**
 *  kap.view_kap_course - Képzés
 *
 *  Idősoros táblázat, ami az összes tétel összes korábbi verzióját eltárolja.
 */

export default class ViewKapCourse extends View<IViewKapCourseRecord> {
    public static VIEW_INFO_ID = 2019111901;

    public static getSchemaNameForClass(): string { return 'kap'; }
    public static getViewNameForClass(): string { return 'view_kap_course'; }
    public static getTableNameForClass(): string|null { return 'course'; }

    public static load: (id: number, aServer ?: Server) => Promise<ViewKapCourse>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<IViewKapCourseRecord[]>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class ViewKapCourseClassProxy extends ViewClassProxy<IViewKapCourseRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const viewKapCourseClassProxy = new ViewKapCourseClassProxy(ViewKapCourse);
registerViewClassProxy(viewKapCourseClassProxy);

