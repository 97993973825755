import * as React from 'react';
import { BubbleLoader } from 'react-css-loaders';
import ReactTable from 'react-table'
import 'react-table/react-table.css'

import { app } from '@src/index';
import { Tabs, TabList, Tab, TabPanel } from 'react-tabs';
import LookupEdit from '@src/framework/forms/lookupedit';
import SecUserCrud from '@src/framework/crud/sys/SecUserCrud';
import { ooPermtypeCrudClassProxy } from '@src/framework/crud/media/OoPermtypeCrud';
import CrudSelectComponent from '@src/framework/forms/crudselect';
import VfsPermissionAPI, { IPermTypeItem, IAuditByUserResult } from '@src/component/vfs/vfs_permisson_api';
import { Link } from 'react-router-dom';
import { __ } from '@src/translation';



export interface IOOFileAuditPermToolProps {
    ooFileId: number;
    onClose: () => void;
}

interface IOOFileAuditPermToolState {
    audit_user_id: number | null;
    oo_permtype_id: number | null;
    permtypes: IPermTypeItem[];
    ar_users?: IAuditByUserResult; // audit result / users
    loading: boolean;
}

export default class OOFileAuditPermTool extends React.Component<IOOFileAuditPermToolProps, IOOFileAuditPermToolState> {
    constructor(props: IOOFileAuditPermToolProps) {
        super(props);
        this.state = { audit_user_id: null, oo_permtype_id: null, permtypes: [], loading: false };
    }


    private onAuditUserIdChange = async (newAuditUserId: any) => {
        if ((typeof newAuditUserId === 'string'))
            if (newAuditUserId.trim() === '')
                newAuditUserId = '';
        if (newAuditUserId === '')
            newAuditUserId = null;
        if (newAuditUserId) {
            this.setState({ audit_user_id: newAuditUserId, loading: true });
            try {
                const permtypes = await VfsPermissionAPI.getPermsForFileAndUser(this.props.ooFileId, newAuditUserId);
                this.setState({ permtypes, loading: false });
            } catch (error) {
                app.showErrorFromJsonResult(error);
            }
        } else {
            this.setState({ audit_user_id: newAuditUserId, permtypes: [], loading: false });
        }
    }

    private onSelectPermTypeId = async (sender: CrudSelectComponent, oo_permtype_id: string | number | null) => {
        if ((typeof oo_permtype_id === 'string')) {
            oo_permtype_id = Number(oo_permtype_id); // Should not happen
        }
        this.setState({ oo_permtype_id: oo_permtype_id });
        if (oo_permtype_id) {
            this.setState({ oo_permtype_id, loading: true });
            try {
                const ar_users = await VfsPermissionAPI.getUsersForFileAndPerm(this.props.ooFileId, oo_permtype_id);
                this.setState({ ar_users, loading: false });
            } catch (error) {
                app.showErrorFromJsonResult(error);
            }
        } else {
            this.setState({ oo_permtype_id, ar_users: undefined, loading: false });
        }
    }



    render() {
        let ar_res;
        const au = this.state.ar_users;
        if (au) {
            if (au.guest) {
                ar_res = <p className="callout primary">
                    {__("Erre az állományra és jogosultság típusra a")} <b>{__("Vendég")}</b>  
                    {__("csoportnak van joga, ezért mindenki hozzáférést kap (a nem regisztrált felhasználók is)")} 
                </p>;
            } else if (au.everybody) {
                ar_res = <p className="callout primary">
                    {__("Erre az állományra és jogosultság típusra a")} <b>{__("Mindenki")}</b> 
                    {__("csoportnak van joga, ezért minden regisztrált felhasználó hozzáférést kap.")}
                </p>;
            } else {
                ar_res = <ReactTable
                    columns={[
                        { Header: __("Felhasználó"), accessor: "title", style: { margin: "auto 0" } },
                        {
                            Header: __("Szerkesztés"), id: "link", filterable: false, style: { textAlign: "center", width: "120px" }, Cell: props => {
                                return <Link style={{ marginBottom: 0 }} className="button expanded small" to={`/sys/sec_user/edit/${props.original.id}/`}><i className="fa fa-edit" />{__("Szerkesztés")} </Link>;
                            }
                        }

                    ]}
                    data={au.users}
                    filterable={true}
                    className="-striped -highlight"
                    /* Az alap filter csak kezdésre keres, és nem kis/nagybetű érzékeny. More info: https://react-table.js.org/#/story/custom-filtering */
                    defaultFilterMethod={(filter, row, column) => row[filter.id].toLowerCase().indexOf(filter.value.toLowerCase()) != -1}
                />
            }
        } else {
            ar_res = <p className="callout alert">{__("Kérem válasszon ki egy jogosultság típust.")}</p>;
        }


        return <Tabs>
            <TabList>
                <Tab>{__("Adott felhasználóra")}</Tab>
                <Tab>{__("Adott jogosultság típusra")}</Tab>
            </TabList>
            <TabPanel>
                {(this.state.loading) ? <BubbleLoader /> :
                    <div className="row expanded">
                        <div className="small-12 column">
                            <label>{__("Melyik felhasználóra kíváncsi:")}
                            <LookupEdit
                                key={"ownerId" + (this.state.audit_user_id || 'null')}
                                name="ownerId"
                                fk_table_info_id={SecUserCrud.TABLE_INFO_ID}
                                clearable={false}
                                value={this.state.audit_user_id!}
                                onChange={(newValue) => this.onAuditUserIdChange(newValue)}
                            />
                            </label>
                        </div>
                        <div className="small-12 column" />
                        <div className="small-12 column">
                            {(this.state.audit_user_id) ?
                                <ReactTable
                                    columns={[
                                        { Header: __("Típus"), accessor: "title", style: { margin: "auto 0" } },
                                    ]}
                                    data={this.state.permtypes}
                                    filterable={false}
                                    showPagination={false}
                                    defaultPageSize={13}
                                    className="-striped -highlight"
                                />
                                : <p>{__("Kérem válasszon egy felhasználót.")}</p>}
                        </div>
                    </div>
                }
            </TabPanel>
            <TabPanel>
                {(this.state.loading) ? <BubbleLoader /> :
                    <div className="row expanded">
                        <div className="small-12 column">
                            <label>{__("Melyik jogosultság típusra kíváncsi:")}</label>
                            <CrudSelectComponent
                                value={this.state.oo_permtype_id}
                                onSelect={this.onSelectPermTypeId}
                                displayFieldName="title"
                                crudClassProxy={ooPermtypeCrudClassProxy}
                                onFilter={(record) => { return record.id < 200 || record.id >= 300 }}
                            />
                        </div>
                        <div className="small-12 column" />
                        <div className="small-12 column">
                            {ar_res}
                        </div>
                    </div>
                }
            </TabPanel>
        </Tabs>;
    }
}