import * as React from 'react';
import { SearchStatus } from '@src/component/book/viewer/SearchInBookComponent';
import { Link } from 'react-router-dom';
import { BookStructure } from '@src/server/PublicServer';
import * as queryString from 'query-string';
import { __ } from '@src/translation';

type ContentsListProps = {
    chapterLessons: any,
    book: BookStructure,
    bookPath: string;
}

type ContentsListState = {
}

export default class ContentsList extends React.Component<ContentsListProps, ContentsListState> {

    constructor(props: any) {
        super(props);

        this.state = {
        };

    }

    onResultCallback(searchStatus: SearchStatus) {
        this.setState({ searchStatus });
    }

    private getChapterHeaderImgUrl(chapter: any) {

        const paddedChapter = zeroPad(String(chapter.chapter_headno), 2);
        const headerFileNameJPG = "fejezet_" + paddedChapter + "_fejezetnyito.jpg";
        const headerFileNamePNG = "fejezet_" + paddedChapter + "_fejezetnyito.png";
        var url = undefined;

        if (this.props.book.headerFileNames && this.props.book.headerFileNames.includes(headerFileNamePNG)) {
            url = this.props.bookPath + "/img/HEADER/" + headerFileNamePNG;
        } else if (this.props.book.headerFileNames && this.props.book.headerFileNames.includes(headerFileNameJPG)) {
            url = this.props.bookPath + "/img/HEADER/" + headerFileNameJPG;
        }
        return url;
    }

    render() {
        return <section className="contentslist" area-label={__("Tartalomjegyzék")}><div className="row column">
            <h3 className="text-center">{__("Tartalomjegyzék")}</h3>
            <ul className="contents-list">

                {
                    this.props.chapterLessons.map((chapter: any, index: number) => {
                        let className = "";
                        if (chapter.lessons.length == 0) return;

                        if (chapter.lessons.length == 1) {
                            const lesson = chapter.lessons[0];
                            className += "row " + lesson.uri_segment;

                            if (lesson.uri_segment != __("tartalomjegyzek")) {
                                return <li key={index}>
                                    <div className={className}>
                                        <div className="small-12">
                                            <Link to={this.props.bookPath + lesson.uri_segment}><div className="item-link"><h3 className="contents-list-only-item">{lesson.name}</h3></div></Link>
                                        </div>
                                    </div>
                                </li>
                            }
                            else return;
                        }

                        const paddedChapter = zeroPad(String(chapter.chapter_headno), 2);
                        let normalizedName = chapter.name.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase();

                        if (this.props.book.uri_segment == "irodalom_5") {
                            chapter.chapter_headno ?
                                className = "small-12 fejezet_" + paddedChapter : className = "small-12 raadas"
                        } else {
                            chapter.chapter_headno ?
                                className = "small-12 fejezet_" + paddedChapter : className = "small-12 " + normalizedName;
                        }

                        const headerUrl = this.getChapterHeaderImgUrl(chapter) + "?max_width=1024";

                        return <li key={index}>

                            <div className="row">


                                <div className={className}>
                                    <ul className="accordion" data-accordion data-multi-expand="true" data-allow-all-closed="true">
                                        <li data-accordion-item>
                                            <Link to="#">
                                                <div className="small-12">

                                                    {
                                                        chapter.chapter_headno
                                                            ? <img onError={(e) => { e.currentTarget.style.display = "none" }} src={headerUrl} />
                                                            : ""
                                                    }
                                                </div>
                                                <div className="chapter-link item-link"><h3 className="contents-list-item">{chapter.name}</h3></div>
                                            </Link>
                                            <ul className="accordion-content" data-tab-content>
                                                {
                                                    chapter.lessons.map((lesson: any, index: number) => {
                                                        return <li key={index}><Link to={this.props.bookPath + lesson.uri_segment}><h3 className="contents-list-sub-item">{lesson.name}</h3></Link></li>;
                                                    })
                                                }
                                            </ul>
                                        </li>
                                    </ul>
                                </div>

                            </div>
                        </li>
                    })
                }

            </ul>
            
            {/* {
                this.props.book.downloadAsZipFolderId &&
                <a className="button" target="_blank" download="Segedanyagok.zip" href={"/api/media/zip?fileIds=%5B%5D&folderIds=%5B" + this.props.book.downloadAsZipFolderId + "%5D"}>
                    <i className="fa fa-download" />&nbsp; {__("Segédanyagok letöltése")} (zip)
                </a>
            } */}

        </div></section>
    }


    componentDidUpdate() {

        try {
            ($('.contents-list') as any).foundation();
        } catch (e) {
            // Jquery hiányzik
            console.log(e);
        }
    }
    componentDidMount() {

        try {
            ($('.contents-list') as any).foundation();

        } catch (e) {
            // Jquery hiányzik
            console.log(e);
        }
    }

}

function zeroPad(number: string, length: number): string {

    var str = Number(number) ? "" + Number(number) : number;
    while (str.length < length) {
        str = '0' + str;
    }

    return str;

}
