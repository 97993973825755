import * as React from "react";
import { IOoFolderRecord } from "@src/framework/crud/media/OoFolderCrud";
import OoFileCrud, { IOoFileRecord } from "@src/framework/crud/media/OoFileCrud";

type FileLinkListComponentProps = {
    oo_folder_id: number
};

type FileLinkListComponentState = {
    files: IOoFileRecord[];
};

export class FileLinkListComponent extends React.Component<FileLinkListComponentProps, FileLinkListComponentState> {
    constructor(props: FileLinkListComponentProps) {
        super(props);
        this.state = {
            files: []
        };
    }
    componentDidMount() {
        this.reloadAsync();
    }

    private reloadAsync = async () => {
        let files: IOoFileRecord[] = []
        if (this.props.oo_folder_id) {
                files = await OoFileCrud.list({ filter: { is_active: true, oo_folder_id: this.props.oo_folder_id } });
        }
        this.setState({ files });
    }

    render() {

        return (
            this.state.files && this.state.files.length > 0 ?              
                    <div className="large-12 columns kap_course_download_div">
                        {this.state.files.map((file: IOoFileRecord) => {
                            return <a key={"link_"+file.sha1} download={file.title} href={"/api/media/file/" + file.sha1} className="kap-course-downloadable">{file.title}</a>
                        })
                        }
                    </div>
                : null
        )
    }
}
