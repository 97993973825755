import * as React from 'react';
import { Switch, Route } from 'react-router';
import { me, hasAnyGroup, Groups } from '@src/framework/server/Auth';
import { customDialog, DialogType } from '@src/component/Dialog';

import { AttendanceCalendar } from './AttendanceCalendar'
import { NavLink } from 'react-router-dom';
import { AttendanceAboutUsPage } from './description/AttendanceAboutUsPage';
import InstituteLeaderCourse, { AttendanceEventTypesPage, InstituteWorkshop, PersonalConsultationAferInstatiate, PersonalConsultationBeforeInstatiate, DFHTComplexAndKIP, SubProgramOccupationPersonalLevel, SubProgramOccupationGroupLevel, MidYearInterview, ClosingWorkshop } from './description/AttendanceEventtTypesPage';
import { AttendanceWorkerListPage } from './description/AttendanceWorkerListPage';
import { AttendanceHelpFilesPage } from './description/AttendanceHelpFiles';
import { AttendanceDraftUpload } from './teacher/AttendanceDraftUpload';
import { AttendanceReportsPage } from './coordinator/AttendanceReportsPage';
import { KipDraftEditPage } from '../draft/KipDraft';

export const PATH_ATTENDANCE = "/jelenleti";
export const PATH_ATTENDANCE_ABOUT_US = PATH_ATTENDANCE + "/rolunk";
export const PATH_ATTENDANCE_EVENT_TYPES = PATH_ATTENDANCE + "/esemeny_tipusok";
export const PATH_INSTITUTE_LEADER_COURSE = PATH_ATTENDANCE_EVENT_TYPES + "/intezmeny_vezetoi_kepzes";
export const PATH_INSTITUTE_WORKSHOP = PATH_ATTENDANCE_EVENT_TYPES + "/intezmenyi_workshop";
export const PATH_PERSONALCOLNSULTING_AFTER = PATH_ATTENDANCE_EVENT_TYPES + "/szemelyes_tanacsadas";
export const PATH_DFHT = PATH_ATTENDANCE_EVENT_TYPES + "/dfht";
export const PATH_SUBPROGRAM_OCCUPATION_PERSONAL = PATH_ATTENDANCE_EVENT_TYPES + "/alapprogrami_foglalkozas_egyeni";
export const PATH_SUBPROGRAM_OCCUPATION_GROUP = PATH_ATTENDANCE_EVENT_TYPES + "/alapprogrami_foglalkozas_csoportos";
export const PATH_MIDYEAR_INTERVIEW = PATH_ATTENDANCE_EVENT_TYPES + "/evkozi_interju";
export const PATH_PERSONALCOLNSULTING_BEFORE = PATH_ATTENDANCE_EVENT_TYPES + "/szemelyes_tanacsadas2";
export const PATH_CLOSING_WORKSHOP = PATH_ATTENDANCE_EVENT_TYPES + "/zaro_workshop";

export const PATH_ATTENDANCE_WORKER_LIST = PATH_ATTENDANCE + "/munkatarsak";
export const PATH_ATTENDANCE_HELP_FILES = PATH_ATTENDANCE + "/segedanyagok";

export const PATH_ATTENDANCE_DRAFT_UPLOAD = PATH_ATTENDANCE + "/dfht-kip_feltoltes/lista";
export const PATH_ATTENDANCE_DRAFT_UPLOAD_EDIT = PATH_ATTENDANCE + "/dfht-kip_feltoltes/szerkeszt";

export const PATH_ATTENDANCE_REPORTS = PATH_ATTENDANCE + "/riportok";
export const PATH_TRACKING_DFHT_VIEWPOINT = PATH_ATTENDANCE_REPORTS + "/dfht_szempontsorok";
export const PATH_TRACKING_COMPLEX_VIEWPOINT = PATH_ATTENDANCE_REPORTS + "/komplex_szempontsorok";
export const PATH_TRACKING_OCCUPATION_VIEWPOINT = PATH_ATTENDANCE_REPORTS + "/alprogrami_szempontsorok";
export const PATH_TRACKING_DFHT_STATISTICS = PATH_ATTENDANCE_REPORTS + "/dfht_statisztika";
export const PATH_TRACKING_OCCUPATION_STATISTICS = PATH_ATTENDANCE_REPORTS + "/alprogrami_statisztika";
export const PATH_TRACKING_JTR_BEADANDO_STATISTICS = PATH_ATTENDANCE_REPORTS + "/jtr_beadando_statisztika";
export const PATH_TRACKING_JTR_BEADANDO_SUMMARY_STATISTICS = PATH_ATTENDANCE_REPORTS + "/jtr_beadando_osszesitett_statisztika";
export const PATH_TRACKING_DFHT_KIP_COMPLEX_SUMMARY = PATH_ATTENDANCE_REPORTS + "/dht_kip_komplex_osszegzes";

export const PATH_ATTENDANCE_CALENDAR_ALL = "/jelenleti/Esemenyek";

type AttendanceProps = {};

type AttendanceState = {
    error: boolean;
    errorMessage: string;
};



export class Attendance extends React.Component<AttendanceProps, AttendanceState> {
    constructor(props: AttendanceProps) {
        super(props);
        this.state = {
            error: false,
            errorMessage: ""
        };
    }
    componentWillMount() {
        var i: number = 0;
        if (me && hasAnyGroup(me, [Groups.Developer, Groups.KapJtrCoordinator])) i++;
        if (me && hasAnyGroup(me, [Groups.KapJtrInstituteContact])) i++;
        if (me && hasAnyGroup(me, [Groups.KapJtrSupport])) i++;
        if (me && hasAnyGroup(me, [Groups.KapKszrCertifiedTeacher])) i++;
        if (i > 0) {
            this.setState({ error: false, errorMessage: "" })
        } else {
            var message: string = "";
            if (!me) message = "A jelenléti támogatási rendszer használatához, először be kell jelentkeznie!"
            else message = "Önnek nincs jogosultsága a Jelenléti támogatási rendszerhez!"
            this.setState({ error: true, errorMessage: message });
            customDialog("FIGYELEM", message, DialogType.Error, false, true, "300px");
        }
    }

    render() {

        const isCoordinator = me && hasAnyGroup(me, [Groups.Admin, Groups.Developer, Groups.KapJtrCoordinator]);
        const isSupporter = me && hasAnyGroup(me, [Groups.Developer, Groups.KapJtrSupport]);
        const isTeacher = me && hasAnyGroup(me, [Groups.Developer, Groups.KapKszrCertifiedTeacher]);

        return (
            !this.state.error ?
                <div className="attendancePage">
                    <div style={{ backgroundColor: "#33aee0" }}>
                        <nav title="JTR menü">
                            <div className="row expanded align-center" /*style={{paddingTop: "1em", paddingBottom: "1em"}}*/>

                                <div className="column shrink">
                                    <NavLink className="attendance-top-button" to={PATH_ATTENDANCE_CALENDAR_ALL}>Események</NavLink>
                                </div>

                                <div className="column shrink">
                                    <NavLink className="attendance-top-button" to={PATH_ATTENDANCE_EVENT_TYPES}>Esemény típusok</NavLink>
                                </div>

                                <div className="column shrink">
                                    <NavLink className="attendance-top-button" to={PATH_ATTENDANCE_ABOUT_US}>Rólunk</NavLink>
                                </div>
                                <div className="column shrink">
                                    <NavLink className="attendance-top-button" to={PATH_ATTENDANCE_WORKER_LIST}>Munkatársaink</NavLink>
                                </div>
                                {
                                    (isTeacher || isSupporter || isCoordinator) &&
                                    <div className="column shrink">
                                        <NavLink className="attendance-top-button" to={PATH_ATTENDANCE_DRAFT_UPLOAD}>DFHT-KIP óravázlatok</NavLink>
                                    </div>
                                }
                                <div className="column shrink">
                                    <NavLink className="attendance-top-button" to={PATH_ATTENDANCE_HELP_FILES}>Segédanyagok</NavLink>
                                </div>

                                {
                                    isCoordinator &&
                                    <div className="column shrink">
                                        <NavLink className="attendance-top-button" to={PATH_ATTENDANCE_REPORTS}>Adatkérés, kimutatások</NavLink>
                                    </div>
                                }
                            </div>
                        </nav>
                    </div>

                    <Switch>
                        <Route path={PATH_ATTENDANCE_ABOUT_US} component={AttendanceAboutUsPage} />

                        <Route path={PATH_ATTENDANCE_EVENT_TYPES} component={AttendanceEventTypesPage} />


                        <Route path={PATH_ATTENDANCE_WORKER_LIST} component={AttendanceWorkerListPage} />
                        <Route path={PATH_ATTENDANCE_HELP_FILES} component={AttendanceHelpFilesPage} />

                        <Route path={PATH_ATTENDANCE_DRAFT_UPLOAD} component={AttendanceDraftUpload} />
                        <Route path={PATH_ATTENDANCE_DRAFT_UPLOAD_EDIT + "/:id?"} component={KipDraftEditPage} />


                        <Route path={PATH_ATTENDANCE_REPORTS} component={AttendanceReportsPage} />

                        <Route path={PATH_ATTENDANCE_CALENDAR_ALL} component={AttendanceCalendar} />

                    </Switch>



                </div>
                : null
        )
    }
}
