import * as React from 'react';
import { IOoFileRecord } from '@src/framework/crud/media/OoFileCrud';
import ViewDocSectionFileRef, { IViewDocSectionFileRefRecord } from '@src/framework/view/doc/ViewDocSectionFileRef';
import { __ } from '@src/translation';

type FileBookReferencesProps = {
    file: IOoFileRecord;
}

type FileBookReferencesState = {
    fileRefs: IViewDocSectionFileRefRecord[];
}

export class FileBookReferences extends React.Component<FileBookReferencesProps, FileBookReferencesState> {

    constructor(props: FileBookReferencesProps) {
        super(props);

        this.state = {
            fileRefs: []
        }
    }

    componentDidMount() {
        this.reloadAsync();
    }

    async reloadAsync() {
        const fileRefs = await ViewDocSectionFileRef.list({filter: {oo_file_id: this.props.file.id}});
        
        this.setState({fileRefs});
    }

    render() {
        return <div style={{padding: "1em"}}>
                <table>
                    <thead>
                        <tr>
                            <th>{__("Könyv")}</th>
                            <th>{__("Fejezet")}</th>
                            <th>{__("Lecke")}</th>
                        </tr>
                    </thead>
                    <tbody>
                    {
                        this.state.fileRefs.map(f => {
                            return <tr key={f.id}>
                                    <td>{f.book_name}</td>
                                    <td>{f.chapter_name}</td>
                                    <td>{f.lesson_name}</td>
                                </tr>;
                        })
                    }
                    </tbody>
                </table>
            </div>;
    }
}
