export function getClassesFromLessonUriSegment(lessonUriSegment: string) {
  var classList = []

  let chapter_segment = (lessonUriSegment.indexOf('fejezet') > -1 || lessonUriSegment.indexOf('lecke') > -1) && lessonUriSegment.split('_').length > 1 ? "fejezet_" + zeroPad(lessonUriSegment.split('_')[1], 2) : lessonUriSegment 
  classList.push(chapter_segment);
  if(classList.indexOf(lessonUriSegment) === -1) {
    classList.push(lessonUriSegment);
  }

  if(lessonUriSegment.indexOf('lecke') > -1) {
    let lesson_segment = lessonUriSegment.split('_').length > 2 ? "lecke_" + zeroPad(lessonUriSegment.split('_')[2], 3) : lessonUriSegment 
    if(lesson_segment != chapter_segment) {
      classList.push(lesson_segment);
    }
  }

  return classList;
}

function zeroPad (number : string, length : number) : string {
   
  var str = Number(number) ? "" + Number(number) : number;
  //var str = '' + number;
  while (str.length < length) {
      str = '0' + str;
  }
 
  return str;

}
