import { Server } from '../../server/Server';
import Crud , { registerCrudClassProxy, IAsTextResult, IListParams, CrudClassProxy, ISearchTextParams, ISearchTextResult } from '../Crud';

export interface IPersonStatusRecord {
    /**
     * Globálisan egyedi azonosító.
     * 
     * Ha új rekordot akarsz létrehozni put() metódussal, akkor hagyd üresen.
     * upsert() hívásnál figyelmen kívül van hagyva az értéke.
     *
     */
    id ?: number;
    /**
     * Címke
     */
    label?: string;
}

/**
 *  sys.person_status - Állapot (személy)
 */

export default class PersonStatusCrud extends Crud<IPersonStatusRecord> {
    public static TABLE_INFO_ID = 97736488;
    public static getSchemaNameForClass() { return 'sys'; }
    public static getTableNameForClass() { return 'person_status'; }

    public static canCache(): boolean {
        return true;
    }

    public static getDefaultMaxAge() : number|undefined {
        return 3600;
    }

    public static load: (id: number, aServer ?: Server) => Promise<PersonStatusCrud>;
    public static asText: (id: number, includeRecord : boolean, aServer ?: Server) => Promise<IAsTextResult<IPersonStatusRecord>>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<IPersonStatusRecord[]>;
    public static searchText: (params: ISearchTextParams, aServer ?: Server) => Promise<ISearchTextResult<IPersonStatusRecord>[]>;
    public static deleteById: (id: number, aServer ?: Server) => Promise<IPersonStatusRecord>;
    public static unDeleteById: (id: number, aServer ?: Server) => Promise<IPersonStatusRecord>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class PersonStatusCrudClassProxy extends CrudClassProxy<IPersonStatusRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const personStatusCrudClassProxy = new PersonStatusCrudClassProxy(PersonStatusCrud);
registerCrudClassProxy(personStatusCrudClassProxy);
