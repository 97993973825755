import * as React from 'react'
import { BubbleLoader } from 'react-css-loaders';
import { VideoBlock, VideoBlockDisplay } from '@src/component/media/VideoComponent';
import UserViewHistoryAPI, { IUserViewEvent } from '@src/component/usr/usr_history_api';
import { me } from '@src/framework/server/Auth';
import { app } from '../..';
import OoFileCrud from '@src/framework/crud/media_pub/OoFileCrud';
import { __ } from '@src/translation';
import { GetMediaTypeFromId } from './GetMediatypeFromID';
import { getOfferDivs } from './GetOffers';
import * as queryString from 'query-string';
import { MEDIA_TYPE_IMAGE, MEDIA_TYPE_SOUND, MEDIA_TYPE_VIDEO, MEDIA_TYPE_IMAGE_MAP } from "@src/framework/file/FileActions"
import { History, Location } from 'history';
import ViewMediaOoFile from '@src/framework/view/media/ViewMediaOoFile';

type MediaHistoryProps = {
    location: Location;
    history: History;
}

type MediaHistoryState = {
    loading: boolean;
    media: IUserViewEvent[];
    pageIndex: number;
    mediaCount: number;
    extendedList: boolean;
    videoBlock: JSX.Element | null;
    audioBlock: JSX.Element | null;
    mapBlock: JSX.Element | null;
    imageBlock: JSX.Element | null;
    videoCount: number,
    imageCount: number,
    mapCount: number,
    audioCount: number
}
const ITEMSPERPAGE = 8;
export class MediaHistory extends React.Component<MediaHistoryProps, MediaHistoryState> {

    constructor(prop: any) {
        super(prop);

        this.state = {
            loading: false,
            media: [],
            pageIndex: 1,
            mediaCount: 0,
            extendedList: false,
            videoBlock: <></>,
            audioBlock: <></>,
            mapBlock: <></>,
            imageBlock: <></>,
            videoCount: 0,
            imageCount: 0,
            mapCount: 0,
            audioCount: 0
        };
    }

    componentDidMount() {
        this.reloadOffersAsync();
    }

    componentDidUpdate(prevprops: MediaHistoryProps) {
        if (prevprops.location.search != this.props.location.search) {
            const params = queryString.parse(this.props.location.search);
            this.setState({ loading: true, extendedList: params.extendedList && params.extendedList == "true" ? true : false }, this.reloadOffersAsync.bind(this));
        }
    }

    async reloadOffersAsync() {
        if (me) {
            try {
                this.setState({ loading: true })

                const viewEvents = await UserViewHistoryAPI.getViewEventLog(OoFileCrud.TABLE_INFO_ID);
                let mediaViewEvents = viewEvents.filter(v => v.table_info_id == OoFileCrud.TABLE_INFO_ID);

                let videos = [];
                let maps = [];
                let images = [];
                let audios = [];
                let medias = await ViewMediaOoFile.list({filter: {id: mediaViewEvents.map(m=>m.record_id)}});
                for (let media of medias) {
                    if (media.media_type_id == MEDIA_TYPE_VIDEO) videos.push(media);
                    if (media.media_type_id == MEDIA_TYPE_IMAGE) images.push(media);
                    if (media.media_type_id == MEDIA_TYPE_IMAGE_MAP) maps.push(media);
                    if (media.media_type_id == MEDIA_TYPE_SOUND) audios.push(media);
                }
                let videoBlock = await getOfferDivs(this.props.history, this.props.location, __("Videók"), "video", videos, !this.state.extendedList, "video");
                let imageBlock = await getOfferDivs(this.props.history, this.props.location, __("Képek"), "image", images, !this.state.extendedList, "image");
                let mapBlock = await getOfferDivs(this.props.history, this.props.location, __("Térképek"), "video", maps, !this.state.extendedList, "map")
                let audioBlock = await getOfferDivs(this.props.history, this.props.location, __("Hanganyagok"), "audio", audios, !this.state.extendedList, "audio");

                this.setState({
                    loading: false,
                    mediaCount: mediaViewEvents.length,
                    media: mediaViewEvents,
                    videoBlock: videoBlock,
                    imageBlock: imageBlock,
                    mapBlock: mapBlock,
                    audioBlock: audioBlock,
                    videoCount: videos.length,
                    imageCount: images.length,
                    mapCount: maps.length,
                    audioCount: audios.length
                });

            } catch (e) {
                app.showErrorFromJsonResult(e);
            }
        }
    }
    private setPageIndex = (pageIndex: number) => {
        if (pageIndex) {
            this.setState({ pageIndex: pageIndex }, this.reloadOffersAsync.bind(this));
        }
    }

    render() {
        const params = queryString.parse(location.search);
        if (this.state.loading) {
            return <div className="row"><BubbleLoader /></div>;
        }

        let count = 0;
        switch (params.type) {
            case "video": count = this.state.videoCount; break;
            case "image": count = this.state.imageCount; break;
            case "map": count = this.state.mapCount; break;
            case "audio": count = this.state.audioCount; break;
            case "media": count = this.state.mediaCount; break;
            default: count = this.state.mediaCount; break;
        }

        let pageCount: number = Math.ceil(count / ITEMSPERPAGE);
        let pageIndex = this.state.pageIndex;
        var pageIndents: JSX.Element[] = [];

        for (var i = 1; i <= pageCount; i++) {
            pageIndents.push(<h2 key={i}>
                <button className={pageIndex == i ? "active" : undefined} onClick={this.setPageIndex.bind(null, i)} >
                    {i}
                </button>
            </h2>);
        }
        return <div>
            <div className="row">
                <h2 className="eke-media-title"><strong>{__("Megtekintett tartalmak")}</strong></h2>
            </div>
            <div className="column small-12">
                {(!params.type || params.type == "media" || params.type == "video") && this.state.videoBlock}
                {(!params.type || params.type == "media" || params.type == "image") && this.state.imageBlock}
                {(!params.type || params.type == "media" || params.type == "map") && this.state.mapBlock}
                {(!params.type || params.type == "media" || params.type == "audio") && this.state.audioBlock}
            </div>

            <div className="search-result-list">
                <div className="pagination columns text-center">
                    {count > ITEMSPERPAGE && this.state.extendedList && pageIndents}
                </div>
            </div>
        </div>
    }
}
