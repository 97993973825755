import * as React from 'react';
import { app } from '@src/index';
import { BubbleLoader } from 'react-css-loaders';
import { Dialog, confirmDialog, alertDialog, customDialog, DialogType } from '@src/component/Dialog';
import ReactTable, { Column, TableProps, RowInfo } from 'react-table'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-table/react-table.css'
import { me, hasAnyGroup, Groups } from '@src/framework/server/Auth';
import { changePassword } from '@src/server/PublicServer';
import MyProfileAPI, { IGetMyProfileResult, IChangeMyProfileParams, IUserLoginEvent } from '@src/component/usr/usr_profile_api';
import ViewInstituteSiteTeacher, { IViewInstituteSiteTeacherRecord, viewInstituteSiteTeacherClassProxy } from '@src/framework/view/sys/ViewInstituteSiteTeacher';
import { IViewInstituteGroupMemberRecord } from '@src/framework/view/sys/ViewInstituteGroupMember';
import MyInstituteTeacherInvitations from './MyInstituteTeacherInvitations';
import { formatDate, phoneNumberCheck, HUN_COUNTRY_CODE } from '@src/Util';
import { whiteSpaceChecker } from '../WhiteSpaceChecker';
import { getReactTableLabels } from '@src/framework/i18n';
import { __, getLanguageId } from '@src/translation';
import PersonCrud, { IPersonRecord, personCrudClassProxy } from '@src/framework/crud/sys/PersonCrud';
import CityCrud from '@src/framework/crud/sys/CityCrud';
import MediaFileChooser, { ContentActionType, FolderContentChangedEvent } from '../filemanager/MediaFileChooser';
import TrainingPermitCrud, { trainingPermitCrudClassProxy } from '@src/framework/crud/kap/TrainingPermitCrud';
import WfAPI from '@src/framework/wf/WfAPI';
import obtainServer from '@src/framework/server/Server';
import { WorkflowDialog } from '../wf/WorkflowDialog';
import { PATH_PROFILE_TRAINING } from '@src/Routes';
import ViewKapTrainingPermit, { IViewKapTrainingPermitRecord, viewKapTrainingPermitClassProxy } from '@src/framework/view/kap/ViewKapTrainingPermit';
import { ooFileCrudClassProxy, IOoFileRecord } from '@src/framework/crud/media/OoFileCrud';
import CountyCrud from '@src/framework/crud/sys/CountyCrud';
import LookupEdit from '@src/framework/forms/lookupedit';
import InstituteSiteTeacherCrud, { IInstituteSiteTeacherRecord, instituteSiteTeacherCrudClassProxy } from '@src/framework/crud/sys/InstituteSiteTeacherCrud';
import { viewInstituteSiteClassProxy } from '@src/framework/view/sys/ViewInstituteSite';
import InstituteSiteCrud from '@src/framework/crud/sys/InstituteSiteCrud';
import { HeaderNotificationStore } from '../HeaderNotification';
import { UserInfoTableComponent } from '@src/project/kap/module/CourseStudentList';
import AutoCompleteEdit from '@src/framework/forms/autocompleteedit';
import CountryTranslationCrud from '@src/framework/crud/sys/CountryTranslationCrud';
import { classroomModule } from '@src/module/classroomModule/classroomModule';
import { messengerModule } from '@src/module/messengerModule/messengerModule';

const filesize = require('filesize');
export const ENROLL_REQUEST_WF_TYPE_ID = 25374585325;
export const ENROLL_PREPARATION_WF_TYPE_ID = 25374585319;
export const ENROLL_SUBMITTED_WF_TYPE_ID = 25374585320;
export const ENROLL_PROOF_ALLOWED_WF_TYPE_ID = 25374585328;
export const ENROLL_CERTIFICATE_ALLOWED_WF_TYPE_ID = 25374585327;
export const ENROLL_MISSING_DATA_WF_TYPE_ID = 25374585324;//25374585330;
export const ENROLL_REFUSED_WF_TYPE_ID = 25374585322;//25374585329;
export const INSTITUTE_SITE_TEACHER_STATUS_ACTIVE_ID = 2;

type ProfileConfig = {
    showCourseRegistrationData: boolean;
    showInstituteSiteEditor: boolean;
    showInstituteGroupInvitations: boolean;
    allowInstituteSiteTeacherSelfRegister: boolean;
}

let profileConfig: ProfileConfig = {
    showCourseRegistrationData: false,
    showInstituteSiteEditor: false,
    showInstituteGroupInvitations: true,
    allowInstituteSiteTeacherSelfRegister: false,
}

export function configureProfile(config: ProfileConfig) {
    profileConfig = config;
}

interface IProfilePageComponentState {
    loading: boolean;
    saving: boolean;
    showPasswordDialog: boolean;
    showTOTPDialog: boolean;
    showloginEventListDialog: boolean;
    origProfile?: IGetMyProfileResult; // Ez a szerveren tárolt változat ami a módosítások előtt volt.
    profile?: IGetMyProfileResult;
    person?: IPersonRecord;
    currentTrainingPermit?: IViewKapTrainingPermitRecord;
    profileChanged: boolean;
    personChanged: boolean;
    oldPassword: string;
    newPassword: string;
    confirmationPassword: string;
    last_alerts: string | null;
    loginEvents?: IUserLoginEvent[];
    invitations: IViewInstituteGroupMemberRecord[];
    loadId: number;
    teacherinvitations: IViewInstituteSiteTeacherRecord[];
    email2changed: boolean;
    validationMessages: Map<string, string>

    TOTPcode: string;
    TOTPSeq: number;
    institute_site_id?: number;
    workflowDialogOpen: boolean;
    hasEnrollPermission: boolean;
    trainingPermitFiles?: IOoFileRecord[];

    countryList?: string[];
    countyList?: string[];

}

export default class ProfilePageComponent extends React.Component<any, IProfilePageComponentState> {

    constructor(props: any) {
        super(props);
        let validationMap: Map<string, string> = new Map<string, string>();
        this.state = {
            loading: true,
            saving: false,
            profileChanged: false,
            personChanged: false,
            showPasswordDialog: false,
            showTOTPDialog: false,
            showloginEventListDialog: false,
            origProfile: undefined,
            profile: undefined,
            person: undefined,
            institute_site_id: undefined,
            currentTrainingPermit: undefined,
            oldPassword: "",
            newPassword: "",
            confirmationPassword: "",
            last_alerts: null,
            invitations: [],
            loadId: 0,
            teacherinvitations: [],
            validationMessages: validationMap,

            TOTPcode: "",
            TOTPSeq: 0,
            email2changed: false,
            workflowDialogOpen: false,
            hasEnrollPermission: false,
            countryList: [],
            countyList: [],
        };
    }

    async componentDidMount() {
        this.asyncReload().then(() => {
            let isTraining = window.location.href.endsWith(PATH_PROFILE_TRAINING);
            if (isTraining && this.refs.training_data_card) {
                (this.refs.training_data_card as HTMLElement).scrollIntoView({
                    behavior: 'smooth',
                    block: 'end',
                });
                setTimeout(() => {
                    window.scrollBy(0, -150)
                }, 500);
            }
        });

    }

    private asyncReload = async () => {
        this.setState({ loading: true, saving: false, profile: undefined, person: undefined });
        try {
            const profile: IGetMyProfileResult = await MyProfileAPI.get();
            const origProfile: IGetMyProfileResult = { ...profile };
            const loginEvents: IUserLoginEvent[] = await MyProfileAPI.getLoginEventLog();
            const countryList = (await CountryTranslationCrud.list({ limit: 1000, filter:{lang_id:getLanguageId()} })).map(el => { return el.name || "" });
            const countyList = (await CountyCrud.list({ limit: 1000 })).map(el => { return (el.name || "") });

            let person = (await PersonCrud.load(profile.person_id)).record;
            let currentTrainingPermit = undefined;
            let hasPermit = true;
            let trainingPermitFiles = undefined;
            if (me && profileConfig.showCourseRegistrationData) {
                let trainingPermits = (await ViewKapTrainingPermit.list({ filter: { sec_user_id: profile.id } }));
                currentTrainingPermit = trainingPermits[trainingPermits.length - 1];
                if (currentTrainingPermit) {
                    hasPermit = (await this.hasPermissionToEnroll(currentTrainingPermit));

                    trainingPermitFiles = (await ooFileCrudClassProxy.list({ filter: { oo_folder_id: currentTrainingPermit.oo_folder_id, is_active: true } }));
                }
            }

            this.setState({
                loading: false, saving: false, profileChanged: false,
                profile, origProfile, loginEvents, email2changed: false,
                person, personChanged: false,
                currentTrainingPermit, hasEnrollPermission: hasPermit,
                trainingPermitFiles: trainingPermitFiles,
                countryList: countryList ? countryList : [],
                countyList: countyList ? countyList : []
            });
        } catch (error) {
            app.showErrorFromJsonResult(error);
        };
    }

    private onInvitationsLoaded = (invitations: IViewInstituteGroupMemberRecord[]) => {
        this.setState({ invitations });
    }

    private onTeacherInvitationsLoaded = (teacherinvitations: IViewInstituteSiteTeacherRecord[]) => {
        this.setState({ teacherinvitations });
    }

    private closeModal = () => {
        this.setState({ showPasswordDialog: false, showTOTPDialog: false })
    }

    private onStateFieldChange(e: any, fieldName: string) {
        this.setState({ [fieldName]: e.target.value } as IProfilePageComponentState);
    }

    private onProfileFieldChange(e: any, fieldName: string) {
        let profile = Object.assign({}, this.state.profile);
        profile[fieldName] = e.target.value;
        this.setState({ profile, profileChanged: true });
        if (fieldName == "email2") {
            this.setState({ email2changed: true });
        }
    }

    private onProfileFieldChange2(value: any, fieldName: string) {
        let profile = Object.assign({}, this.state.profile);
        profile[fieldName] = value;
        this.setState({ profile, profileChanged: true });
    }

    private onPersonFieldChange(e: any, fieldName: string) {
        let person = Object.assign({}, this.state.person);
        person[fieldName] = e.target.value;
        this.setState({ person, personChanged: true });
    }

    private onPersonFieldChangeWithValue(value: any, fieldName: string) {
        let person = Object.assign({}, this.state.person);
        person[fieldName] = value;
        this.setState({ person, personChanged: true });
    }
    private onInstituteValueChanged(siteId: any, ) {
        this.setState({ institute_site_id: siteId });
    }

    async filterCountry(inputValue: string): Promise<string[]> {
        if (!this.state.countryList) return [];
        return this.state.countryList.filter(element => { return element.toLowerCase().includes(inputValue.toLowerCase()) });
    }

    async filterCounty(inputValue: string): Promise<string[]> {
        if (!this.state.countyList) return [];
        return this.state.countyList.filter(element => { return element.toLowerCase().includes(inputValue.toLowerCase()) });
    }

    async filterCity(inputValue: string): Promise<string[]> {
        let townList = (await CityCrud.searchText({ text: inputValue, include_record: true }));
        return townList.map(el => { return el.record!.name || "" });
    }

    filterCityzenship(inputValue: string): string[] {
        let list = [__("magyar"), __("szlovák"), __("román"), __("szerb"), __("szlovén"), __("horvát")]
        let st = inputValue.toLowerCase();
        return list.filter(el => { return el.includes(st) });
    }


    private onOpenEventLogsButtonClick() {
        this.setState({ showloginEventListDialog: true })
    }

    private async onCreateTrainingPermit() {
        try {
            if (this.state.currentTrainingPermit) return;
            let result = (await trainingPermitCrudClassProxy.put({ sec_user_id: this.state.profile!.id }));
            let res = (await viewKapTrainingPermitClassProxy.load(result.id!));
            let hasPermission = (await this.hasPermissionToEnroll(result));
            this.setState({ currentTrainingPermit: res, hasEnrollPermission: hasPermission });
        } catch (ex) {
            app.showErrorFromJsonResult(ex.message);
        }
    }

    private async onSaveButtonClick(e: any) {

        this.setState({ saving: true });

        try {
            const profile = this.state.profile!;

            if (!whiteSpaceChecker(profile.firstname)) {
                app.showError(__("Hiba"), __("A keresztnevet kötelező kitölteni!"));

                this.setState({ saving: false });
                return;
            }
            if (!whiteSpaceChecker(profile.lastname)) {
                app.showError(__("Hiba"), __("A vezetéknevet kötelező kitölteni!"));
                this.setState({ saving: false });
                return;
            }
            if (!whiteSpaceChecker(profile.fullname)) {
                app.showError(__("Hiba"), __("A teljes nevet kötelező kitölteni!"));
                this.setState({ saving: false });
                return;
            }

            const changes: IChangeMyProfileParams = {
                firstname: profile.firstname,
                lastname: profile.lastname,
                name_prefix: profile.name_prefix,
                fullname: profile.fullname,
                nickname: profile.nickname,
                user_availibility_id: profile.user_availibility_id,
                birthday: profile.birthday,
                email2: profile.email2 || null
            };

            const result: IGetMyProfileResult = await MyProfileAPI.put(changes);
            const origProfile: IGetMyProfileResult = { ...result };

            this.setState({ profile: result, origProfile, profileChanged: false, saving: false });
            app.showSuccess(__("Adatok módosítása"), __("Sikeres"));


        } catch (e) {
            app.showError(__("Nem sikerült módosítani az adataidat"), e);
        }
    }
    private async onSavePasswordButtonClick(e: any) {
        this.setState({ saving: true });
        try {
            const result = await changePassword(this.state.oldPassword, this.state.newPassword);
            if (result.successful) {
                app.showSuccess(__("Sikeres"), __("A jelszavad megváltoztatása sikeres"));
                this.setState({ showPasswordDialog: false, saving: false });
            } else {
                this.setState({ saving: false, last_alerts: result.error_message });
                app.showError(
                    result.error_code,
                    result.error_message
                );
            }
        } catch (error) {
            app.showErrorFromJsonResult(error);
        }
    }

    validation(validationMessages: Map<string, string>): boolean {
        let person = this.state.person!;
        let errorMsg = "";
        if (!whiteSpaceChecker(person.birthname)) {
            errorMsg = __("A születési nevet kötelező kitölteni!");
            app.showError(__("Hiba"), errorMsg);
            validationMessages.set("birthname", errorMsg);
        }
        if (!whiteSpaceChecker(person.mothers_name)) {
            errorMsg = __("Az anyja nevét kötelező kitölteni!");
            app.showError(__("Hiba"), errorMsg);
            validationMessages.set("mothers_name", errorMsg);
        }
        if (!whiteSpaceChecker(person.id_number)) {
            errorMsg = __("A személyi igazolvány számot kötelező kitölteni!");
            app.showError(__("Hiba"), errorMsg);
            validationMessages.set("id_number", errorMsg);
        }
        if (!whiteSpaceChecker(person.taxid)) {
            errorMsg = __("Az adóazonosító jelet kötelező kitölteni!");
            app.showError(__("Hiba"), errorMsg);
            validationMessages.set("taxid", errorMsg);
        }
        if (!whiteSpaceChecker(person.taj)) {
            errorMsg = __("A társadalombiztosítási azonosító jelet kötelező kitölteni!");
            app.showError(__("Hiba"), errorMsg);
            validationMessages.set("taj", errorMsg);
        }
        if (!whiteSpaceChecker(person.citizenship)) {
            errorMsg = __("Az állampolgárságot kötelező kitölteni!");
            app.showError(__("Hiba"), errorMsg);
            validationMessages.set("citizenship", errorMsg);
        }
        if (!person.birthday) {
            errorMsg = __("A születési dátumot kötelező kitölteni!");
            app.showError(__("Hiba"), errorMsg);
            validationMessages.set("birthday", errorMsg);
        }
        else if (person.birthday && (new Date().getFullYear() - parseInt(person.birthday.substr(0, 4)) >= 100 || new Date().getFullYear() - parseInt(person.birthday.substr(0, 4)) <= 1)) {
            errorMsg = __("Kérjük valós születési dátumot adjon meg!");
            app.showError(__("Hiba"), errorMsg);
            validationMessages.set("birthday", errorMsg);
        }

        if (!whiteSpaceChecker(person.birth_country)) {
            errorMsg = __("A születési országot kötelező kitölteni!");
            app.showError(__("Hiba"), errorMsg);
            validationMessages.set("birth_country", errorMsg);
        }
        if (!whiteSpaceChecker(person.birth_city)) {
            errorMsg = __("A születési várost kötelező kitölteni!");
            app.showError(__("Hiba"), errorMsg);
            validationMessages.set("birth_city", errorMsg);
        }
        if (!whiteSpaceChecker(person.residence_country)) {
            errorMsg = __("Az országot kötelező kitölteni!");
            app.showError(__("Hiba"), errorMsg);
            validationMessages.set("residence_country", errorMsg);
        }
        if (!whiteSpaceChecker(person.residence_county)) {
            errorMsg = __("A megyét kötelező kitölteni!");
            app.showError(__("Hiba"), errorMsg);
            validationMessages.set("residence_county", errorMsg);
        }
        if (!whiteSpaceChecker(person.residence_city)) {
            errorMsg = __("A települést kötelező kitölteni!");
            app.showError(__("Hiba"), errorMsg);
            validationMessages.set("residence_city", errorMsg);
        }
        if (!whiteSpaceChecker(person.residence_address)) {
            errorMsg = __("A lakcímet kötelező kitölteni!");
            app.showError(__("Hiba"), errorMsg);
            validationMessages.set("residence_address", errorMsg);
        }
        if (!whiteSpaceChecker(person.residence_zip)) {
            errorMsg = __("Az irányítószámot kötelező kitölteni!");
            app.showError(__("Hiba"), errorMsg);
            validationMessages.set("residence_zip", errorMsg);
        }
        if (!whiteSpaceChecker(person.diploma_qualification)) {
            errorMsg = __("A legmagasabb képesítést kötelező kitölteni!");
            app.showError(__("Hiba"), errorMsg);
            validationMessages.set("diploma_qualification", errorMsg);
        }
        if (!whiteSpaceChecker(person.diploma_serial)) {
            errorMsg = __("A diploma számot kötelező kitölteni!");
            app.showError(__("Hiba"), errorMsg);
            validationMessages.set("diploma_serial", errorMsg);
        }
        if (!person.diploma_created) {
            errorMsg = __("A kiállítás dátumát kötelező kitölteni!");
            app.showError(__("Hiba"), errorMsg);
            validationMessages.set("diploma_created", errorMsg);
        }
        if (person.diploma_created && (new Date().getFullYear() - parseInt(person.diploma_created.substr(0, 4)) >= 100)) {
            errorMsg = __("Kérjük valós kiállítás dátumot adjon meg!");
            app.showError(__("Hiba"), errorMsg);
            validationMessages.set("diploma_created", errorMsg);
        }
        if (!person.diploma_inst || !whiteSpaceChecker(person.diploma_inst)) {
            errorMsg = __("A kiállító intézményt kötelező kitölteni!");
            app.showError(__("Hiba"), errorMsg);
            validationMessages.set("diploma_inst", errorMsg);
        }

        if (person.landline_number && !phoneNumberCheck(person.landline_number, false)) {
            errorMsg = __("Hibás vezetékes telefonszám!");
            app.showError(__("Hiba"), errorMsg);
            validationMessages.set("landline_number", errorMsg);
        }

        if (person.landline_number && !person.landline_country_code) {
            person.landline_country_code = HUN_COUNTRY_CODE;
        }

        if (person.mobile_number && !phoneNumberCheck(person.mobile_number, true)) {
            errorMsg = __("Hibás mobiltelefonszám!");
            app.showError(__("Hiba"), errorMsg);
            validationMessages.set("mobile_number", errorMsg);
        }

        if (person.mobile_number && !person.mobile_country_code) {
            person.mobile_country_code = HUN_COUNTRY_CODE;
        }
        if (validationMessages.size > 0) {
            return false;
        }
        return true;
    }

    private async onSavePersonalDataClick(e: any): Promise<boolean> {
        this.setState({ saving: true });

        try {
            let validationMessages = new Map<string, string>();
            if (!this.validation(validationMessages)) {
                this.setState({ loading: false, validationMessages });
                return false;
            }
            let person = this.state.person!;
            let result = await personCrudClassProxy.put(person);

            this.setState({
                person: result, personChanged: false, saving: false
            });

            app.showSuccess(__("Adatok módosítása"), __("Sikeres"));

            if (!this.state.currentTrainingPermit || (this.state.hasEnrollPermission && (!this.state.trainingPermitFiles || this.state.trainingPermitFiles.length < 1))) {
                await alertDialog("Figyelem!", "A képzési engedély megszerzéséhez föl kell töltenie a képzésre jogosító oklevél vagy diploma másolatát. Amíg ezt nem tölti föl, addig a jelentkezését nem bíráljuk el!");
            }

            return true;

        } catch (e) {
            app.showError(__("Nem sikerült módosítani az adataidat"), e);
            return false;
        }

    }

    private async hasPermissionToEnroll(trainingPermit: IViewKapTrainingPermitRecord): Promise<boolean> {
        if (trainingPermit && trainingPermit.wf_workflow_id) {
            const wfApi: WfAPI = new WfAPI(obtainServer());
            const transitions = await wfApi.listPossibleTransitions(trainingPermit.wf_workflow_id!);
            if (!transitions || transitions.length < 1) {
                return false;
            }
            // if not in enrollment prepare state
            const transition = transitions.find(t => t.id == ENROLL_REQUEST_WF_TYPE_ID);
            if (!transition) return false;
        }

        return true;
    }


    private onWFButtonClick() {
        this.setState({ workflowDialogOpen: true });
    }

    private async onEnrollButtonClick() {
        if (!this.state.hasEnrollPermission) {
            app.showError(__("Hiba"), __("Nincs joga jelentkezni!"));
            return;
        }
        const wfApi: WfAPI = new WfAPI(obtainServer());
        if (!(await this.onSavePersonalDataClick({}))) {
            return;
        }
        if (!this.state.currentTrainingPermit) {
            app.showError("Hiányzó adatok", "A képzettséget igazoló dolumentumok feltöltése kötelező!");
            return;
        }

        let oofiles = (await ooFileCrudClassProxy.list({ filter: { oo_folder_id: this.state.currentTrainingPermit.oo_folder_id, is_active: true } }));
        if (!oofiles || oofiles.length < 1) {
            app.showError("Hiányzó adatok", "A képzettséget igazoló dolumentumok feltöltése kötelező!");
            return;
        }

        if (!this.state.person!.diploma_serial || !this.state.person!.diploma_qualification || !this.state.person!.diploma_created || !this.state.person!.diploma_inst) {
            app.showError("Hiányzó adatok", "A képzettséget igazoló dolumentum adatainak megadása kötelező!");
            return;
        }

        if (!this.state.teacherinvitations || this.state.teacherinvitations.length < 1) {
            app.showError("Hiányzó adatok", "Legalább egy intézmény megadása kötelező!");
            return;
        }

        try {
            (await (wfApi.makeTransition(this.state.currentTrainingPermit.wf_workflow_id!, ENROLL_REQUEST_WF_TYPE_ID)).then(
                () => {
                    this.asyncReload().then(() => { app.showSuccess(__("Sikeres művelet"), __("Sikeresen benyújtotta a jelentkezési kérelmét.")) });
                }));
        } catch (ex) {
            app.showErrorFromJsonResult(ex);
        }
    }


    private onConfirmTOTPButtonClick = async () => {
        this.setState({ saving: true });
        try {
            const result = await MyProfileAPI.confirmTOTP(this.state.TOTPcode);
            const profile = this.state.profile!;
            profile.has_totp = true;
            profile.totp_confirmed = result.totp_confirmed;
            this.setState({ saving: false, profile, showTOTPDialog: false });
            app.showSuccess(__("Sikeres művelet"), __("A két faktoros azonosítás be lett kapcsolva."));
        } catch (error) {
            app.showErrorFromJsonResult(error);
        }
    }

    private turnOffTOTP = async () => {
        if (await confirmDialog(__("Megerősítés"), __("Biztos benne, hogy ki akarja kapcsolni a két faktoros azonosítást?"),
            __("Igen"), __("Nem"))) {
            this.setState({ saving: true });
            try {
                await MyProfileAPI.turOffTOTP();
                const profile = this.state.profile!;
                profile.has_totp = false;
                profile.totp_confirmed = "";
                this.setState({ profile, saving: false });
                app.showSuccess(__("Sikeres művelet"), __("A két faktoros azonosítás ki lett kapcsolva."));
            } catch (error) {
                app.showErrorFromJsonResult(error);
            }
        }
    }

    private onRequestConfirmEmail = async () => {
        this.setState({ saving: true });
        try {
            let result: IGetMyProfileResult = await MyProfileAPI.requestEmailConfirmation();
            this.setState({ profile: result, profileChanged: false, saving: false });
            app.showSuccess(__("Sikeres művelet"), __("Ellenőrizze a postafiókját ehhez: {details}", { details: result.email }));
        } catch (error) {
            app.showErrorFromJsonResult(error);
        }
    }

    private onRequestConfirmEmail2 = async () => {
        this.setState({ saving: true });
        try {
            let result: IGetMyProfileResult = await MyProfileAPI.requestEmail2Confirmation();
            this.setState({ profile: result, profileChanged: false, saving: false });
            app.showSuccess(__("Sikeres művelet"), __("Ellenőrizze a postafiókját ehhez: {details}", { details: result.email2 }));
        } catch (error) {
            app.showErrorFromJsonResult(error);
        }
    }

    private swapEmails = async () => {
        if (await confirmDialog(
            __("E-mail csere"),
            __("Biztos benne, hogy megcseréli az elsődleges és másodlagos e-mail címeket?"),
            __("Megcserélem"))) {
            this.setState({ saving: true });
            try {
                await MyProfileAPI.swapEmails();
                this.setState({ profileChanged: false, saving: false });
                app.showSuccess(__("Sikeres művelet"), __("Az e-mail címek megcserélése sikeres volt."));
                this.asyncReload();
            } catch (error) {
                app.showErrorFromJsonResult(error);
            }
        }
    }

    async onFolderChanged(action: "upload" | "del") {

        if (action == "upload" && (!this.state.trainingPermitFiles || this.state.trainingPermitFiles.length < 1)) {
            /*const buttons = [
                { className: "", text: __("Előtte további fotókat szeretnék feltölteni"), value: 1 },
                { className: "", text: __("Igen, végeztem az adatfeltöltéssel"), value: 2 },
                { className: "", text: __("Későbbre halasztom"), value: 3 }

            ];
            customDialog("", "Szeretné beküldeni a képzési engedély kérelmet elbírálásra?", DialogType.Info, false, false, undefined, undefined, undefined, undefined, undefined, buttons).then((ret) => {
                switch (ret) {
                    case 1:
                        {
                            break;
                        }
                    case 2:
                        {
                            this.onEnrollButtonClick();
                            break;
                        }
                    case 3:
                        {
                            alertDialog(__("Figyelem!"), __("A képzési engedély megszerzéséhez szükséges adatokat nem küldte be elbírálásra. Elbírálás nélkül nem fog tudni fölvenni képzéseket!"))
                            break;
                        }
                    default:
                        {
                            break;
                        }

                }
            });*/
            customDialog(__("Információ"), __("Ha végzett az összes adat kitöltésével, nyomja meg a Jelentkezés gombot!"), DialogType.Info, false, false);
        }

        let oofiles = (await ooFileCrudClassProxy.list({ filter: { oo_folder_id: this.state.currentTrainingPermit!.oo_folder_id, is_active: true } }));
        this.setState({ trainingPermitFiles: oofiles });

    }

    private renderPersonalData() {
        const profile = this.state.profile!;
        const personalEnabled = !hasAnyGroup(me!, [Groups.TestStudent, Groups.TestTeacher]);
        if (personalEnabled) {
            return <>
                <div className="row">
                    <div className="large-6 medium-6 small-12 columns">
                        <label>{__("Vezetéknév")} <span style={{ color: "#b30000" }}>*</span>
                            <input type="text"
                                value={profile.lastname}
                                onChange={(e) => this.onProfileFieldChange(e, "lastname")} />

                        </label>
                    </div>
                    <div className="large-6 medium-6 small-12 columns">
                        <label>{__("Keresztnév")}<span style={{ color: "#b30000" }}>*</span>
                            <input type="text"
                                value={profile.firstname}
                                onChange={(e) => this.onProfileFieldChange(e, "firstname")} />
                        </label>
                    </div>
                </div>
                <div className="row">
                    <div className="large-6 medium-6 small-12 columns">
                        <label>{__("Becenév")}
                            <input type="text"
                                value={profile.nickname || ""}
                                onChange={(e) => this.onProfileFieldChange(e, "nickname")} />
                        </label>
                    </div>
                    <div className="large-6 medium-6 small-12 columns">
                        <label>{__("Előnév")}
                            <input type="text"
                                value={profile.name_prefix || ""}
                                onChange={(e) => this.onProfileFieldChange(e, "name_prefix")} />
                        </label>
                    </div>
                </div>
                <div className="row">
                    <div className="large-12 medium-12 small-12 columns">
                        <label>{__("Teljes név")}<span style={{ color: "#b30000" }}>*</span>
                            <input type="text"
                                value={profile.fullname || ""}
                                onChange={(e) => this.onProfileFieldChange(e, "fullname")} />
                        </label>
                    </div>
                </div>
            </>
        } else {
            return null;
        }
    }

    private renderPrimaryEmail() {
        const profile = this.state.profile!;
        return <div className="row">
            <div className="large-6 medium-6 small-12 columns">
                <label>{__("Elsődleges e-mail cím")}
                    <input type="text" value={profile.email} disabled />
                </label>
            </div>
            <a id="email_confim" />
            <div className="large-6 medium-6 small-12 columns">
                {profile.email_confirmed
                    ?
                    <label>{__("Megerősítve")}
                &nbsp;
            <i style={{ color: "green" }} className="fa fa-check" />
                &nbsp;
                {profile.email_confirmed}
                    </label>
                    :
                    <>
                        <label>{__("Elsődleges e-mail cím nincs megerősítve!")}</label>
                        <div>
                            {profile.email_confirm_code_requested ? __("megerősítő kérelmezve") + ": " + formatDate(profile.email_confirm_code_requested) : ""}
                        </div>
                        <div>
                            {profile.email_confirm_code_sent ? __("megerősítő üzenet kiküldve") + ": " + formatDate(profile.email_confirm_code_sent) : ""}
                        </div>
                        <button style={{ marginTop: "0.55rem" }}
                            className="button small alert eke-general-buttons"
                            onClick={this.onRequestConfirmEmail}
                        >
                            <i className="fa fa-key" />{__("Új megerősítő email kiküldése")}
                        </button>
                    </>
                }

            </div>
        </div>;
    }

    private renderSecondaryEmail() {
        const profile = this.state.profile!;
        const origProfile = this.state.origProfile!;
        let confirmBlock = null;
        // Ha nincs lementve az email2 akkor az egészből semmi ne látszódjon.
        // https://kb.itk.uni-eszterhazy.hu/project/14/task/1634
        if (origProfile.email2) {
            if (profile.email2_confirmed) {
                confirmBlock = <>
                    <label>{__("Megerősítve")}
                        <div>
                            <i style={{ color: "green" }} className="fa fa-check" /> {profile.email2_confirmed}
                        </div>
                    </label>

                    <button style={{ marginTop: "0.55rem" }}
                        className="button small warning eke-general-buttons"
                        title={__("Elsődleges és másodlagos cím megcserélése")}
                        disabled={this.state.profileChanged}
                        onClick={this.swapEmails}
                    >
                        <i className="fa fa-exchange-alt" />{__("Cserél")}
                    </button>
                </>
            } else {
                confirmBlock = <>
                    <label>{__("Másodlagos e-mail cím nincs megerősítve!")}</label>
                    <div>
                        {profile.email2_confirm_code_requested ? __("megerősítő kérelmezve") + " :" + formatDate(profile.email2_confirm_code_requested) : ""}
                    </div>
                    <div>
                        {profile.email2_confirm_code_sent ? __("megerősítő üzenet kiküldve") + " :" + formatDate(profile.email2_confirm_code_sent) : ""}
                    </div>
                    <button style={{ marginTop: "0.55rem" }} className="button small alert eke-general-buttons"
                        onClick={this.onRequestConfirmEmail2}
                        disabled={!this.state.profile!.email2}
                    >
                        <i className="fa fa-key" />{__("Új megerősítő email kiküldése")}
                    </button>
                </>
            }
        }

        return <div className="row">
            <div className="large-6 medium-6 small-12 columns">
                <label>{__("Másodlagos e-mail cím")}
                    <input type="text" value={profile.email2 || ""} onChange={(e) => this.onProfileFieldChange(e, "email2")} />
                </label>
            </div>
            <div className="large-6 medium-6 small-12 columns">{confirmBlock}</div>
        </div>;
    }

    private renderTOTPButtons() {
        const profile = this.state.profile!;
        let dlg = null;
        if (this.state.showTOTPDialog) {

            let warnings: string[] = [];

            if (!this.state.TOTPcode) {
                warnings.push(__("Írja be a megerősítő kódot."));
            }
            if (this.state.TOTPcode.length != 6) {
                warnings.push(__("A megerősítő kód pontosan 6 karakterből áll."));
            }


            let warning = null;
            if (warnings.length) {
                warning = <div className="callout warning">
                    {warnings.join("\n")}
                </div>
            }
            let alerts = null;
            if (this.state.last_alerts) {
                alerts = <div className="callout warning">
                    <b>{__("Utolsó kísérlet eredménye:")}</b>
                    {this.state.last_alerts}
                </div>
            }


            dlg = <Dialog
                key="totpdialog"
                title={__("Két faktoros azonosítás beállítása")} onClose={this.closeModal} >
                <div className="large-12 small-12 column" key="totpdialog_content">
                    <div><p> {/*TODO Translation */}
                        {__("A kód beolvasásához és a második faktor aktiválásához töltsön le egy megfelelő TOTP alkalmazást mobil eszközére:")}
                        <a href="https://play.google.com/store/search?q=TOTP&c=apps&hl=hu" target="_blank"> android </a>
                        <a href="https://www.apple.com/hu/search/TOTP-authenticator?src=serp" target="_blank"> apple </a>
                    </p></div>
                    <div key="totpdialog_qrcode" className="text-center">
                        <img src={MyProfileAPI.getInitTOTPUrl(this.state.TOTPSeq)} />
                    </div>
                    <div key="totpdialog_confirmcode">
                        <label>{__("Megerősítő kód")}
                            <input
                                key="totpdialog_confirmcode_input"
                                autoFocus
                                type="password" value={this.state.TOTPcode || ""} onChange={(e) => this.setState({ TOTPcode: e.target.value })}
                            />
                        </label>
                    </div>
                    {warning}
                    {alerts}
                    <div className="callout clearfix" style={{ border: "0px" }}>
                        <button
                            onClick={this.closeModal}
                            className="button alert float-left"><i className="fa fa-times" />{__("Mégse")}</button>
                        <button onClick={this.onConfirmTOTPButtonClick.bind(this)}
                            className="button success float-right"
                            disabled={!this.state.TOTPcode || this.state.TOTPcode.length != 6}
                        > <i className="fa fa-save" />{__("Kód megerősítése")}</button>
                    </div>
                </div>
            </Dialog>;
        }

        return <>
            {dlg}
            <div className="row">
                <div className="large-12 medium-12 small-12 columns">
                    <button className={"button small eke-general-buttons"}
                        onClick={() => this.setState({ showPasswordDialog: true })} >
                        <i className="fa fa-lock-open" />{__("Jelszó megváltoztatása")}
                    </button>
                    {profile.has_totp && profile.totp_confirmed ?
                        <button className={"button small eke-general-buttons"}
                            onClick={this.turnOffTOTP}
                        >
                            <i className="fa fa-lock-open" />{__("Két faktoros azonosítás aktív")} ({formatDate(profile.totp_confirmed)})
            </button>
                        :
                        <button className={"button small eke-general-buttons"}
                            onClick={() => this.setState({ showTOTPDialog: true, TOTPSeq: this.state.TOTPSeq + 1 })}
                        >
                            <i className="fa fa-lock-open" />{__("Két faktoros azonosítás bekapcsolása")}
                        </button>
                    }
                </div>
            </div>
        </>;
    }

    private renderAvailibility() {
        const profile = this.state.profile!

        const moduleNames = [];
        if (classroomModule) moduleNames.push(__("tanulócsoport meghívásnál"));
        
        // TODO check exercise module
        moduleNames.push(__("feladat kiosztásánál"));
        
        if (messengerModule) moduleNames.push(__("üzenetküldésnél"));
        
        return <div className="row">
            <fieldset className="large-12 cell">
                <legend>Az e-mail címem alapján...</legend>
                <div className="large-12 medium-12 small-12 columns">

                    <input type="radio" name="authcheckbox" value={__("el tudnak érni a rendszerben")} id="with-Email"
                        checked={profile.user_availibility_id == 1}
                        onChange={() => this.onProfileFieldChange2(1, "user_availibility_id")}
                    />
                    <label>{__("el tudnak érni a rendszerben")}
                    </label>
                </div>
                {/*
            <div className="large-12 medium-12 small-12 columns">
                <input type="radio" name="authcheckbox" value={__("Az általam kiválasztott, egyedi felhasználónévvel tud elérni")} id="withcustomName"
                    checked={profile.user_availibility_id == 2}
                    onChange={() => this.onProfileFieldChange2(2, "user_availibility_id")}
                />
                <label>{__("Az általam kiválasztott, egyedi felhasználónévvel tud elérni")}
                </label>
            </div>
            */}
                <div className="large-12 medium-12 small-12 columns">
                    <input type="radio" name="authcheckbox" value={__("nem tudnak elérni a rendszerben")} id="no-connection"
                        checked={profile.user_availibility_id == 3}
                        onChange={() => this.onProfileFieldChange2(3, "user_availibility_id")}
                    />
                    <label>{__("nem tudnak elérni a rendszerben")}
                    </label>
                </div>
            ...{moduleNames.join(", ")}.
            </fieldset>
        </div>
    }

    private renderSaveButton() {
        const profile = this.state.profile!
        const emailConflicts: string[] = [];
        var regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

        if (profile.email == profile.email2) { emailConflicts.push(__("A két e-mail cím nem lehet azonos.")) }

        if (profile.email2 && !regex.test(profile.email2!)) {
            emailConflicts.push(__("A megadott másodlagos e-mail cím érvénytelen."))
        }

        return <>
            {emailConflicts.length ? <div className="callout alert">{emailConflicts.join("\n")}</div> : null}
            <div className="large-12 small-12 columns row">
                {/* Ezt max ha magyarul ír ki mindent
        <button className={"button small warning eke-general-buttons"}
            onClick={this.onOpenEventLogsButtonClick.bind(this)} >
            <i className="fa fa-calendar" /> {__("Bejelentkezési napló")}
        </button>
        */}
                <div style={{ float: "right" }}>
                    <button
                        className={"button small success eke-general-buttons"}
                        onClick={this.onSaveButtonClick.bind(this)}
                        disabled={!this.state.profileChanged && !emailConflicts.length}
                    >
                        <i className="fa fa-save" />   {__("Módosítások mentése")}
                    </button>
                </div>
            </div>
        </>;
    }

    private renderCourseRegistrationData() {
        const person = this.state.person;

        if (!profileConfig.showCourseRegistrationData || !person) {
            return null;
        }

        let filePermits: number[] = [ContentActionType.FILE_DOWNLOAD];

        if (this.state.currentTrainingPermit) {
            const wf_id = this.state.currentTrainingPermit.wf_station_id;
            if (!wf_id || wf_id == ENROLL_PREPARATION_WF_TYPE_ID || wf_id == ENROLL_REFUSED_WF_TYPE_ID || wf_id == ENROLL_MISSING_DATA_WF_TYPE_ID) {
                filePermits = filePermits.concat([ContentActionType.FILE_MODIFY, ContentActionType.FILE_UPLOAD, ContentActionType.FILE_DELETE]);
            }
        }

        return <>
            <div className="row" ref="training_data_card">
                <div className="column large-12 medium-12 small-12">
                    <div className="card">
                        <div className="card-divider">
                            <p><i className="fa fa-graduation-cap" /> {__("Kurzusra való jelentkezéshez szükséges adatok")}</p>
                        </div>
                        <div className="card-section">
                            <div className="large-12 small-12 columns">
                                <div className="row">

                                    <div className="large-6 medium-6 small-12 columns">
                                        <label>{__("Anyja neve")} <span style={{ color: "#b30000" }}>*</span>
                                            <span className="exe-editor-validation-msg">{this.state.validationMessages.get("mothers_name")}</span>
                                            <input type="text"
                                                value={person.mothers_name || ""}
                                                onChange={(e) => this.onPersonFieldChange(e, "mothers_name")} />
                                        </label>
                                    </div>
                                    <div className="large-6 medium-6 small-12 columns">
                                        <label>{__("Személyi igazolvány száma")}<span style={{ color: "#b30000" }}>*</span>
                                            <span className="exe-editor-validation-msg">{this.state.validationMessages.get("id_number")}</span>
                                            <input type="text"
                                                value={person.id_number || ""}
                                                onChange={(e) => this.onPersonFieldChange(e, "id_number")} />
                                        </label>
                                    </div>
                                    <div className="large-6 medium-6 small-12 columns">
                                        <label>{__("Társadalombiztosítási azonosító jele (TAJ)")}<span style={{ color: "#b30000" }}>*</span>
                                            <span className="exe-editor-validation-msg">{this.state.validationMessages.get("taj")}</span>
                                            <input type="text"
                                                value={person.taj || ""}
                                                onChange={(e) => this.onPersonFieldChange(e, "taj")} />
                                        </label>
                                    </div>
                                    <div className="large-6 medium-6 small-12 columns">
                                        <label>{__("Adóazonosító jele")}<span style={{ color: "#b30000" }}>*</span>
                                            <span className="exe-editor-validation-msg">{this.state.validationMessages.get("taxid")}</span>
                                            <input type="text"
                                                value={person.taxid || ""}
                                                onChange={(e) => this.onPersonFieldChange(e, "taxid")} />
                                        </label>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="large-12 columns">
                                        <label className="profile_form_separator">{__("Születési adatok")}</label>
                                    </div>
                                </div>


                                <div className="row">
                                    <div className="large-6 medium-6 small-12 columns">
                                        <label>{__("Születési név")}<span style={{ color: "#b30000" }}>*</span>
                                            <span className="exe-editor-validation-msg">{this.state.validationMessages.get("birthname")}</span>
                                            <input type="text"
                                                value={person.birthname || ""}
                                                onChange={(e) => this.onPersonFieldChange(e, "birthname")} />
                                        </label>
                                    </div>
                                    <div className="large-6 medium-6 small-12 columns">
                                        <label>{__("Születési dátum")}<span style={{ color: "#b30000" }}>*</span>
                                            <span className="exe-editor-validation-msg">{this.state.validationMessages.get("birthday")}</span>
                                            <input type="date" value={person.birthday ? person.birthday.substr(0, 10) : ""}
                                                onChange={(e) => this.onPersonFieldChange(e, "birthday")}
                                            />
                                        </label>
                                    </div>

                                </div>

                                <div className="row">
                                    <div className="large-6 medium-6 small-12 columns">
                                        <label>{__("Születési ország")} <span style={{ color: "#b30000" }}>*</span>
                                            <span className="exe-editor-validation-msg">{this.state.validationMessages.get("birth_country")}</span>
                                            <AutoCompleteEdit placeholder={__("Ország")} value={person.birth_country || ""}
                                                onValueChange={(newValue) => this.onPersonFieldChangeWithValue(newValue, "birth_country")}
                                                onItemSelected={(newValue) => this.onPersonFieldChangeWithValue(newValue, "birth_country")}
                                                filterFunction={this.filterCountry.bind(this)} />
                                        </label>
                                    </div>
                                    <div className="large-6 medium-6 small-12 columns">
                                        <label>{__("Születési település")}<span style={{ color: "#b30000" }}>*</span>
                                            <span className="exe-editor-validation-msg">{this.state.validationMessages.get("birth_city")}</span>
                                            <AutoCompleteEdit placeholder={__("Település")} value={person.birth_city || ""}
                                                onValueChange={(newValue) => this.onPersonFieldChangeWithValue(newValue, "birth_city")}
                                                onItemSelected={(newValue) => this.onPersonFieldChangeWithValue(newValue, "birth_city")}
                                                filterFunction={this.filterCity.bind(this)} />
                                        </label>
                                    </div>
                                    <div className="large-6 medium-6 small-12 columns">
                                        <label>{__("Állampolgárság")} <span style={{ color: "#b30000" }}>*</span>
                                            <span className="exe-editor-validation-msg">{this.state.validationMessages.get("citizenship")}</span>
                                            <AutoCompleteEdit placeholder={__("Állampolgárság")} value={person.citizenship || ""}
                                                onValueChange={(newValue) => this.onPersonFieldChangeWithValue(newValue, "citizenship")}
                                                onItemSelected={(newValue) => this.onPersonFieldChangeWithValue(newValue, "citizenship")}
                                                filterFunction={this.filterCityzenship.bind(this)} />
                                        </label>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="large-12 columns">
                                        <label className="profile_form_separator">{__("Állandó lakcím")}</label>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="large-4 medium-6 small-12 columns">
                                        <label>{__("Ország")} <span style={{ color: "#b30000" }}>*</span>
                                            <span className="exe-editor-validation-msg">{this.state.validationMessages.get("residence_country")}</span>
                                            <AutoCompleteEdit placeholder={__("Ország")} value={person.residence_country || ""}
                                                onValueChange={(newValue) => this.onPersonFieldChangeWithValue(newValue, "residence_country")}
                                                onItemSelected={(newValue) => this.onPersonFieldChangeWithValue(newValue, "residence_country")}
                                                filterFunction={this.filterCountry.bind(this)} />
                                        </label>
                                    </div>
                                    <div className="large-4 medium-6 small-12 columns">
                                        <label>{__("Megye")} <span style={{ color: "#b30000" }}>*</span>
                                            <span className="exe-editor-validation-msg">{this.state.validationMessages.get("residence_county")}</span>
                                            <AutoCompleteEdit placeholder={__("Település")} value={person.residence_county || ""}
                                                onValueChange={(newValue) => this.onPersonFieldChangeWithValue(newValue, "residence_county")}
                                                onItemSelected={(newValue) => this.onPersonFieldChangeWithValue(newValue, "residence_county")}
                                                filterFunction={this.filterCounty.bind(this)} />
                                        </label>
                                    </div>
                                    <div className="large-4 medium-6 small-12 columns">
                                        <label>{__("Település")}<span style={{ color: "#b30000" }}>*</span>
                                            <span className="exe-editor-validation-msg">{this.state.validationMessages.get("residence_city")}</span>
                                            <AutoCompleteEdit placeholder={__("Település")} value={person.residence_city || ""}
                                                onValueChange={(newValue) => this.onPersonFieldChangeWithValue(newValue, "residence_city")}
                                                onItemSelected={(newValue) => this.onPersonFieldChangeWithValue(newValue, "residence_city")}
                                                filterFunction={this.filterCity.bind(this)} />
                                        </label>
                                    </div>

                                </div>

                                <div className="row">
                                    <div className="large-2 medium-6 small-12 columns">
                                        <label>{__("Irányítószám")}<span style={{ color: "#b30000" }}>*</span>
                                            <span className="exe-editor-validation-msg">{this.state.validationMessages.get("residence_zip")}</span>
                                            <input type="text"
                                                value={person.residence_zip || ""}
                                                onChange={(e) => this.onPersonFieldChange(e, "residence_zip")} />
                                        </label>
                                    </div>
                                    <div className="large-10 columns">
                                        <label>{__("Utca, házszám, emelet, ajtó")} <span style={{ color: "#b30000" }}>*</span>
                                            <span className="exe-editor-validation-msg">{this.state.validationMessages.get("residence_address")}</span>
                                            <input type="text"
                                                value={person.residence_address || ""}
                                                onChange={(e) => this.onPersonFieldChange(e, "residence_address")} />
                                        </label>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="large-12 columns">
                                        <label className="profile_form_separator">{__("Levelezési cím")}</label>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="large-4 medium-6 small-12 columns">
                                        <label>{__("Ország")}
                                            <AutoCompleteEdit placeholder={__("Ország")} value={person.postal_country || ""}
                                                onValueChange={(newValue) => this.onPersonFieldChangeWithValue(newValue, "postal_country")}
                                                onItemSelected={(newValue) => this.onPersonFieldChangeWithValue(newValue, "postal_country")}
                                                filterFunction={this.filterCountry.bind(this)} />
                                            <span className="exe-editor-validation-msg">{this.state.validationMessages.get("postal_country")}</span>
                                        </label>
                                    </div>
                                    <div className="large-4 medium-6 small-12 columns">
                                        <label>{__("Megye")}
                                            <AutoCompleteEdit placeholder={__("Település")} value={person.postal_county || ""}
                                                onValueChange={(newValue) => this.onPersonFieldChangeWithValue(newValue, "postal_county")}
                                                onItemSelected={(newValue) => this.onPersonFieldChangeWithValue(newValue, "postal_county")}
                                                filterFunction={this.filterCounty.bind(this)} />
                                            <span className="exe-editor-validation-msg">{this.state.validationMessages.get("postal_county")}</span>
                                        </label>
                                    </div>
                                    <div className="large-4 medium-6 small-12 columns">
                                        <label>{__("Település")}
                                            <AutoCompleteEdit placeholder={__("Település")} value={person.postal_city || ""}
                                                onValueChange={(newValue) => this.onPersonFieldChangeWithValue(newValue, "postal_city")}
                                                onItemSelected={(newValue) => this.onPersonFieldChangeWithValue(newValue, "postal_city")}
                                                filterFunction={this.filterCity.bind(this)} />
                                            <span className="exe-editor-validation-msg">{this.state.validationMessages.get("postal_city")}</span>
                                        </label>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="large-2 medium-6 small-12 columns">
                                        <label>{__("Irányítószám")}
                                            <input type="text"
                                                value={person.postal_zip || ""}
                                                onChange={(e) => this.onPersonFieldChange(e, "postal_zip")} />
                                            <span className="exe-editor-validation-msg">{this.state.validationMessages.get("postal_zip")}</span>
                                        </label>
                                    </div>
                                    <div className="large-10 columns">
                                        <label>{__("Utca, házszám, emelet, ajtó")}
                                            <input type="text"
                                                value={person.postal_address || ""}
                                                onChange={(e) => this.onPersonFieldChange(e, "postal_address")} />
                                            <span className="exe-editor-validation-msg">{this.state.validationMessages.get("postal_address")}</span>
                                        </label>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="large-12 columns">
                                        <label className="profile_form_separator">{__("Elérhetőségek")}</label>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="large-6 columns">
                                        <label>{__("Vezetékes telefonszám")}
                                            <span className="exe-editor-validation-msg">{this.state.validationMessages.get("landline_country_code")}</span><br />
                                            <span className="exe-editor-validation-msg">{this.state.validationMessages.get("landline_number")}</span>
                                            <div className="row" style={{ margin: "0" }}>
                                                <input type="text" className="large-2 columns"
                                                    value={person.landline_country_code || "+36"}
                                                    onChange={(e) => this.onPersonFieldChange(e, "landline_country_code")} />
                                                <input type="text" className="large-9 columns"
                                                    value={person.landline_number || ""}
                                                    onChange={(e) => this.onPersonFieldChange(e, "landline_number")} />
                                            </div>
                                        </label>
                                    </div>

                                    <div className="large-6 columns">
                                        <label>{__("Mobilszám")}
                                            <span className="exe-editor-validation-msg">{this.state.validationMessages.get("mobile_country_code")}</span><br />
                                            <span className="exe-editor-validation-msg">{this.state.validationMessages.get("mobile_number")}</span>
                                            <div className="row" style={{ margin: "0" }}>
                                                <input type="text" className="large-2 columns"
                                                    value={person.mobile_country_code || "+36"}
                                                    onChange={(e) => this.onPersonFieldChange(e, "mobile_country_code")} />
                                                <input type="text" className="large-9 columns"
                                                    value={person.mobile_number || ""}
                                                    onChange={(e) => this.onPersonFieldChange(e, "mobile_number")} />
                                            </div>
                                        </label>
                                    </div>

                                    <div className="large-6 small-12 columns">
                                        <label>{__("Munkahelyi telefonszám")}
                                            <input type="text"
                                                value={person.workplace_tel || ""}
                                                onChange={(e) => this.onPersonFieldChange(e, "workplace_tel")} />
                                            <span className="exe-editor-validation-msg">{this.state.validationMessages.get("workplace_tel")}</span>
                                        </label>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="large-12 columns">
                                        <label className="profile_form_separator">{__("Képzettségi adatok")}</label>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="large-6 medium-6 small-12 columns">
                                        <label>{__("Legmagasabb képesítés")} <span style={{ color: "#b30000" }}>*</span>
                                            <span className="exe-editor-validation-msg">{this.state.validationMessages.get("diploma_qualification")}</span>
                                            <input type="text"
                                                value={person.diploma_qualification || ""}
                                                onChange={(e) => this.onPersonFieldChange(e, "diploma_qualification")} />
                                        </label>
                                    </div>
                                    <div className="large-6 medium-6 small-12 columns">
                                        <label>{__("Diploma száma")}<span style={{ color: "#b30000" }}>*</span>
                                            <span className="exe-editor-validation-msg">{this.state.validationMessages.get("diploma_serial")}</span>
                                            <input type="text"
                                                value={person.diploma_serial || ""}
                                                onChange={(e) => this.onPersonFieldChange(e, "diploma_serial")} />
                                        </label>
                                    </div>
                                    <div className="large-6 medium-6 small-12 columns">
                                        <label>{__("Kiállítás dátuma")}<span style={{ color: "#b30000" }}>*</span>
                                            <span className="exe-editor-validation-msg">{this.state.validationMessages.get("diploma_created")}</span>
                                            <input type="date" value={person.diploma_created ? person.diploma_created.substr(0, 10) : ""}
                                                onChange={(e) => this.onPersonFieldChange(e, "diploma_created")}
                                            />
                                        </label>
                                    </div>
                                    <div className="large-6 medium-6 small-12 columns">
                                        <label>{__("Kiállító intézmény")}<span style={{ color: "#b30000" }}>*</span>
                                            <span className="exe-editor-validation-msg">{this.state.validationMessages.get("diploma_inst")}</span>
                                            <input type="text"
                                                value={person.diploma_inst || ""}
                                                onChange={(e) => this.onPersonFieldChange(e, "diploma_inst")} />
                                        </label>
                                    </div>
                                </div>
                                {!this.state.currentTrainingPermit ?
                                    <div className="row columns">
                                        <button className={"button small warning eke-general-buttons"}
                                            onClick={this.onCreateTrainingPermit.bind(this)} >
                                            <i className="fa fa-upload" /> {__("Igazoló dokumentumok feltöltése")}
                                        </button>
                                    </div>
                                    :
                                    <div className="row">
                                        <label>{__("Igazoló dokumentumok feltöltése")}</label>
                                        <MediaFileChooser
                                            rootFolderId={this.state.currentTrainingPermit!.oo_folder_id!}
                                            currentFolderId={this.state.currentTrainingPermit!.oo_folder_id!}
                                            showHoverPreview={false}
                                            showLocation={false}
                                            showFilter={false}
                                            enabledActions={filePermits}
                                            onFolderContentChanged={this.onFolderChanged.bind(this)}
                                        />
                                    </div>
                                }

                                <div className="row columns large-12">
                                    {this.state.hasEnrollPermission && (this.state.trainingPermitFiles && this.state.trainingPermitFiles!.length > 0) ?
                                        <button className={"button small warning eke-general-buttons"}
                                            onClick={this.onEnrollButtonClick.bind(this)} >
                                            <i className="fa fa-graduation-cap" /> {__("Jelentkezés")}
                                        </button>
                                        :
                                        this.state.hasEnrollPermission && this.state.currentTrainingPermit
                                            ?
                                            <div className="callout warning">{__("Figyelem! A képzési engedély megszerzéséhez föl kell töltenie a képzésre jogosító oklevél vagy diploma másolatát. Amíg ezt nem tölti föl, addig a jelentkezését nem bíráljuk el!")}</div>
                                            : null
                                    }

                                    {this.state.currentTrainingPermit && (this.state.currentTrainingPermit.wf_station_id == ENROLL_MISSING_DATA_WF_TYPE_ID || this.state.currentTrainingPermit.wf_station_id == ENROLL_REFUSED_WF_TYPE_ID) ?
                                        <div> <label>{__("Kérjük frissítse az adatait az újbóli jelentkezéshez!")}</label>
                                            <button className={"button small warning eke-general-buttons"}
                                                onClick={this.onWFButtonClick.bind(this)} >
                                                <i className="fa fa-graduation-cap" /> {__("Újra jelentkezés")}
                                            </button></div> : ""
                                    }
                                    {
                                        this.state.currentTrainingPermit ?
                                            <label className="label" style={{ alignSelf: "center", marginRight: "0.5rem" }}>{__("Állapot")}: {this.state.currentTrainingPermit!.wf_station_name}</label>
                                            : ""
                                    }
                                    <div style={{ float: "right" }}>
                                        <button
                                            className={"button small success eke-general-buttons"}
                                            onClick={this.onSavePersonalDataClick.bind(this)}
                                            disabled={!this.state.personChanged}
                                        >
                                            <i className="fa fa-save" />   {__("Módosítások mentése")}
                                        </button>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {me && hasAnyGroup(me, [Groups.KapKszrCourseTeacher, Groups.KapKszrParticipantTeacher, Groups.KapKszrCertifiedTeacher]) &&
                <div className="row">
                    <div className="column large-12 medium-12 small-12">
                        <div className="card">
                            <div className="card-divider">
                                <p><i className="fa fa-graduation-cap" /> {__("Képzettség adatok")}</p>
                            </div>
                            <div className="card-section">
                                <div className="large-12 small-12 columns">
                                    <UserInfoTableComponent selectedSecUserId={this.state.profile!.id} showAsPopup={false} onClose={() => { }} />

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>;
    }

    private renderTeacherInvitations() {
        return <div className="row">
            <div className="card">
                <div className="card-divider">
                    <p><i className="fa fa-university" /> {__("Saját intézményeim, ahol tanítok")}</p>
                </div>
                <div className="card-section row">

                    {
                        profileConfig.allowInstituteSiteTeacherSelfRegister &&
                        <>
                            <div className="column small-12">{__("Intézmény")}</div>
                            <div className="large-10 medium-10 small-12 column" style={{ marginBottom: "10px" }}>

                                <LookupEdit
                                    key={"institute_site_id_" + (this.state.institute_site_id || 'null')}
                                    fk_table_info_id={InstituteSiteCrud.TABLE_INFO_ID}
                                    viewClassProxy={viewInstituteSiteClassProxy}
                                    clearable={true}
                                    emptyLoad={true}
                                    valueColumn="id"
                                    value={this.state.institute_site_id}
                                    selectColumnNames={["id", "name"]}
                                    displayColumnNames={["name", "om_identifier", "site_identifier"]}
                                    searchColumnNames={["name", "om_identifier", "site_identifier"]}
                                    distinct={true}
                                    onChange={(newValue) => this.onInstituteValueChanged(Number(newValue))}
                                    filter={{ is_active: true }}
                                />
                            </div>
                            <div className="large-2 medium-2 small-12 column" style={{ display: "flex", alignContent: "center", marginBottom: "10px" }}>
                                <button onClick={this.OnAddInstitute.bind(this)} className="button" style={{ marginTop: "auto", marginBottom: "auto" }}>
                                    {__("Kiválaszt")}
                                </button>
                            </div>
                        </>
                    }
                    <div className="column small-12">
                        <MyInstituteTeacherInvitations
                            invitations={this.state.teacherinvitations}
                            onTeacherInvitationsLoaded={this.onTeacherInvitationsLoaded}
                            allowSelfAssign={profileConfig.allowInstituteSiteTeacherSelfRegister}
                        />
                    </div>
                </div>
            </div>
        </div>
    }

    private renderDiskQuota() {
        const profile = this.state.profile!;
        let storagePercent = (profile.disk_space_used / profile.hard_quota) * 100;
        storagePercent = storagePercent > 100 ? 100 : storagePercent;
        let progressBg = "#2ba6cb";
        if (profile.disk_space_used < profile.soft_quota && profile.disk_space_used < profile.hard_quota) {
            progressBg = "#2ba6cb";
        }
        else if (profile.disk_space_used > profile.soft_quota && profile.disk_space_used < profile.hard_quota) {
            progressBg = "#f4b342";
        }
        else {
            progressBg = "#7a031b";
        }

        return <div className="row">
            <div className="column small-12">
                <strong>{__("Felhasznált tárterület a megadott {quota}-ból: {used}", { quota: filesize(profile.hard_quota), used: filesize(profile.disk_space_used) })}</strong>
                <br />
                <div style={{ width: '100%' }} className="progress" role="progressbar" tabIndex={1} aria-valuenow={profile.disk_space_used} aria-valuemin={0} aria-valuemax={profile.hard_quota}>
                    <span className="progress-meter" style={{ width: storagePercent + "%", backgroundColor: progressBg }}>
                        <p className="progress-meter-text"></p>
                    </span>
                </div>
            </div>
        </div>

    }

    private renderPasswordDialog() {
        if (!this.state.showPasswordDialog) {
            return null;
        }
        const canChangePassword =
            (
                this.state.newPassword
                && this.state.confirmationPassword
                && (this.state.newPassword == this.state.confirmationPassword)
                && (!this.state.oldPassword || (this.state.oldPassword != this.state.newPassword))
            ) && (!this.state.saving);

        let warnings: string[] = [];

        if (this.state.showPasswordDialog) {
            if (!this.state.newPassword) {
                warnings.push(__("Írja be az új jelszót."));
            }
            if (!this.state.confirmationPassword) {
                warnings.push(__("Írja be a jelszó megerősítését."));
            }
            if (warnings.length == 0) {
                if (this.state.oldPassword.toLowerCase() == this.state.newPassword.toLowerCase()) {
                    warnings.push(__("A régi és az új jelszavak nem lehetnek azonosak."));
                }
                if (this.state.newPassword != this.state.confirmationPassword) {
                    warnings.push(__("A jelszavak nem egyeznek."));
                }
            }
        }

        let warning = null;
        if (warnings.length) {
            warning = <div className="callout warning">
                {warnings.join("\n")}
            </div>
        }
        let alerts = null;
        if (this.state.last_alerts) {
            alerts = <div className="callout warning">
                <b>{__("Utolsó kísérlet eredménye:")}</b>
                {this.state.last_alerts}
            </div>
        }



        return <Dialog title={__("Jelszó megváltoztatása")} onClose={this.closeModal} >
            <div className="large-12 small-12 columns">
                <div className="row">
                    <label>{__("Jelszó")}
                        <input type="password" value={this.state.oldPassword || ""} onChange={(e) => this.onStateFieldChange(e, "oldPassword")} />
                    </label>
                </div>
                <div className="row">
                    <label>{__("Új jelszó")}
                        <input type="password" value={this.state.newPassword || ""} onChange={(e) => this.onStateFieldChange(e, "newPassword")} />
                    </label>
                </div>
                <div className="row">
                    <label>{__("Jelszó megerősítése")}
                        <input type="password" value={this.state.confirmationPassword || ""} onChange={(e) => this.onStateFieldChange(e, "confirmationPassword")} />
                    </label>
                </div>
                {warning}
                {alerts}
                <div className="callout clearfix" style={{ border: "0px" }}>
                    <button
                        onClick={this.closeModal}
                        className="button alert float-left"><i className="fa fa-times" /> {__("Mégse")}</button>
                    <button onClick={this.onSavePasswordButtonClick.bind(this)}
                        className="button success float-right"
                        disabled={!canChangePassword}
                    > <i className="fa fa-save" /> {__("Jelszó módosítása")}</button>
                </div>
            </div>
        </Dialog>;

    }

    private renderLoginEventsDialog() {
        if (!this.state.showloginEventListDialog) {
            return null;
        }


        let loginEvents = this.state.loginEvents;

        let loginEventList: JSX.Element[] = [];
        if (this.state.loginEvents) {
            this.state.loginEvents.map((event) => {
                loginEventList.push(
                    <li key={event.event_date}>
                        {event.event_date} {event.action} {event.message}
                        <br />
                        {event.client_ip} {event.user_agent}
                    </li>
                )
            })
        }

        const getTdProps = (finalState: any, rowInfo: RowInfo, column?: Column, instance?: any) => {
            let props: Partial<TableProps> = {};
            if (column && rowInfo && rowInfo.row) {
                let row: any = rowInfo.row._original;
                if (row) {
                    if (row["action"] != "login_success" && row["action"] != "logout") {
                        props["style"] = { backgroundColor: "rgba(179, 0, 0,0.55)" };
                    } else {
                        props["style"] = { backgroundColor: "rgba(36, 153, 0,0.55)" };
                    }
                }
            }
            return props;
        }

        const tableProps = {
            columns: [
                { Header: __("Dátum"), accessor: "event_date", style: { margin: "auto 0" } },
                { Header: __("Esemény"), accessor: "action", style: { textAlign: "center" } },
                { Header: __("Szöveg"), accessor: "message", style: { margin: "auto 0" } }
            ],
            data: loginEvents,
            filterable: true,
            className: "-striped -highlight",
            getTdProps
        }

        return <Dialog title={__("Bejelentkezési napló")} width={1050} onClose={() => this.setState({ showloginEventListDialog: false })}>
            {this.state.loginEvents
                ?
                <div>
                    <p style={{ color: "#b34700", textAlign: "center" }}>{__("Figyelem: Csak az utolsó 100 látszódik")} </p>
                    <ReactTable {...tableProps} {...getReactTableLabels()}
                    />
                </div>
                : <p style={{ textAlign: "center" }}>{__("Jelenleg nem találhatóak logok")} </p>
            }
        </Dialog>;

    }

    render() {
        if (this.state.loading || !this.state.profile) {
            return <BubbleLoader />
        }
        if (!me) {
            return <div className="callout alert">{__("Ez a funkció csak bejelentkezett felhasználók számára érhető el.")}</div>;
        }

        const profile = this.state.profile!;


        // Ez sajnos így nem megy, mert akkor kérdezi le őket amikor már render-elni. Így a length>0
        // itt nem vizsgálható.
        // && (profileConfig.allowInstituteSiteTeacherSelfRegister || this.state.teacherinvitations.length>0);
        // Dettó
        // const showInstituteGroupInvitations = profileConfig.showInstituteGroupInvitations;

        return <div key="profile_page" className="profil-page">
            {this.renderPasswordDialog()}

            {this.state.currentTrainingPermit ?
                <WorkflowDialog
                    open={this.state.workflowDialogOpen}
                    displayName="Jelentkezés"
                    tableInfoId={TrainingPermitCrud.TABLE_INFO_ID}
                    recId={this.state.currentTrainingPermit!.id!}
                    onClose={() => this.setState({ workflowDialogOpen: false })}
                    onTransition={this.asyncReload.bind(this)}
                    autoPublishOnClosedStation={true}
                    showHistory={true}
                /> : ""
            }
            {this.renderLoginEventsDialog()}

            <div className="row">
                <div className="column large-12 medium-12 small-12">
                    <h4>{__("Profilkezelés")}</h4>
                </div>
            </div>

            <div className="row">
                <div className="column large-12 medium-12 small-12">
                    <div className="card">
                        <div className="card-divider">
                            <p><i className="fa fa-address-card" /> {__("Személyes adatok")}</p>
                        </div>
                        <div className="card-section">
                            <div className="large-12 small-12 columns">
                                <div className="row">
                                    <div className="small-12 columns">
                                        <label>{__("Bejelentkezési név")}
                                            <input type="text" value={profile.login_name || ""} disabled />
                                        </label>
                                    </div>
                                </div>

                                {this.renderPersonalData()}
                                {this.renderPrimaryEmail()}
                                {this.renderSecondaryEmail()}
                                {this.renderTOTPButtons()}
                                {this.renderAvailibility()}


                                {this.renderSaveButton()}

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {this.renderCourseRegistrationData()}
            {this.renderTeacherInvitations()}
            {this.renderDiskQuota()}
        </div>
    }
    private async OnAddInstitute(event: any) {
        if (!this.state.institute_site_id) {
            app.showError("Hiba", "Válasszon feladatellátási helyet");
        }
        else if (me) {
            try {
                let newRecord: IInstituteSiteTeacherRecord = {
                    sec_user_id: me.id,
                    institute_site_id: this.state.institute_site_id,
                    status_id: INSTITUTE_SITE_TEACHER_STATUS_ACTIVE_ID,
                }
                //Lekérjük, hogy létezik -e az adott intézménybe ez a meghívó
                const invitation = await ViewInstituteSiteTeacher.list({
                    filter: { sec_user_id: me!.id, institute_site_id: this.state.institute_site_id }
                });
                //Ha van meghívó, akkor AKTÍV-ra módosítjuk
                if (invitation.length != 0) {
                    const rec = await new InstituteSiteTeacherCrud({
                        id: invitation[0].id,
                        status_id: INSTITUTE_SITE_TEACHER_STATUS_ACTIVE_ID
                    }).put();
                }
                //Ha nincs akkor létrehozunk egy újat
                else await instituteSiteTeacherCrudClassProxy.put(newRecord);

                const invitations = await ViewInstituteSiteTeacher.list({
                    filter: { sec_user_id: me!.id },
                    order_by: "institute_site_name"
                });

                HeaderNotificationStore.changed();
                this.onTeacherInvitationsLoaded(invitations);
                app.showSuccess("Sikeres hozzárendelés", "");

            }
            catch (error) {
                app.showErrorFromJsonResult(error);
            };

        }
    }
}
