import * as ExerciseBaseTypes from "@src/component/exercise/models/ExerciseBaseClass";
import { AExerciseTypeConverter, ValidationResponse, StatisticsResponse } from "@src/component/exercise/models/AExerciseTypeConverter";
import { QuizData } from "@src/component/exercise/engine/EKEQuizExerciseEngine/EKEQuizExerciseEngine";
import { CheckableMultiTypeAnswerItem, AnswerTypes } from '@src/component/exercise/models/ExerciseBaseClass';
import * as React from "react";
import ExerciseFileSelect, { ExerciseFileTypes } from "@src/component/exercise/Editor/ExerciseFileSelect";
import { Panel, Card } from '@src/component/ui/Panel';
import { __ } from '@src/translation';

export type QuizAnswerElement = {
    multiple_answers: boolean,
    vertical_display: boolean,
    answers: CheckableMultiTypeAnswerItem[]
}

export class EKEQuizConverter extends AExerciseTypeConverter {

    public hasTextAnswer = false;
    public hasImageAnswer = true;
    public hasTextImageAnswer = false;
    static MAX_ANSWER_NUMBER: number = 20;
    
    render() {
        let curr_ex: QuizAnswerElement = this.state.exercise as QuizAnswerElement;
        return (
            <Panel header={__("Részletek")} headingLevel={4}>
                <div className="row">
                    <div className="large-12 columns">
                        <label ><input type="checkbox" name="multiple_answers" checked={curr_ex.multiple_answers || false}
                            onBlur={this.onBlurEvent.bind(this)}
                            onChange={this.handleInputChange.bind(this)} />
                            {__("Több helyes válasz")}</label>
                    </div>
                </div>
                <div className="row">
                    <div className="large-12 columns">
                        <label ><input type="checkbox" name="vertical_display" checked={curr_ex.vertical_display || false}
                            onBlur={this.onBlurEvent.bind(this)}
                            onChange={this.handleInputChange.bind(this)} />
                            {__("Vertikális megjelenítés")}</label>
                    </div>
                </div>
                <div className="row">
                    <div className="large-12 columns">
                        <label>A kérdés fent, az <i>Alapadatok</i> ablaknál, a <i>Feladat leírása</i> mezőben állítható!</label>
                    </div>
                </div>
                <Panel header={__("Válaszlehetőségek")} headingLevel={5} >
                    {
                        curr_ex.answers ?
                            curr_ex.answers.map((curr_task: any, index) => {
                                return (
                                    <Card header={
                                        <div>
                                            <label className="exe-editor-fieldlabel-1">{__("Válasz")} {index + 1}</label>
                                            <button className="button small alert exercise-series-small-btn" title={__("Törlés")} onClick={this.removeElement.bind(this, "answers", index)}><i className="fa fa-trash"></i></button>
                                            <button className="button small exercise-series-small-btn" title={__("Fel")} onClick={this.moveUp.bind(this, "answers", index)} ><i className="fa fa-arrow-up"></i></button>
                                            <button className="button small exercise-series-small-btn" title={__("Le")} onClick={this.moveDown.bind(this, "answers", index)}><i className="fa fa-arrow-down"></i></button>
                                        </div>
                                        }>
                                        <span className="exe-editor-validation-msg">{this.props.validationMessages.get("answers[" + index + "].type")}</span>
                                    <div className="row">
                                        <div className="large-12 small-12 columns">
                                            <label ><input type="checkbox" name={"answers#" + index + ".is_answer"} checked={curr_task.is_answer || false}
                                                onBlur={this.onBlurEvent.bind(this)}
                                                onChange={this.handleInputChange.bind(this)} />
                                                {__("Helyes?")}</label>
                                        </div>                                        
                                    </div>
                                    <div className="row">
                                        <div className="large-4 small-12 columns">
                                            <label> {__("Válasz típusa")}
                                            <select value={curr_task.type} name={"answers#" + index + ".type"} onChange={this.handleInputChange.bind(this)} onBlur={this.onBlurEvent.bind(this)}>
                                                    <option value={AnswerTypes.text}>{__("Szöveg")}</option>
                                                    <option value={AnswerTypes.image}>{__("Kép")}</option>
                                                    <option value={AnswerTypes.sound}>{__("Hang")}</option>
                                                </select></label>
                                        </div>
                                        <div className="large-8 small-12 columns">
                                            <label>{curr_task.type == AnswerTypes.text ? __("Válasz") : __("Leírás")}
                                                <input type="text" name={"answers#" + index + ".text"} data-parent-index={index} data-index={index} value={curr_task.text}
                                                    onBlur={this.onBlurEvent.bind(this)}
                                                    onChange={this.handleInputChange.bind(this)} />
                                            </label>

                                        </div>
                                    </div>
                                    <div className="row">
                                        {curr_task.type != AnswerTypes.text ? <div className="large-12 columns">
                                            <label className="exe-image-select-label">{curr_task.type == AnswerTypes.image ? __("Kép") : "Hang"}
                                                {<ExerciseFileSelect
                                                    imagebasepath={this.props.imagebasepath}
                                                    value={curr_task.url || ""}
                                                    onChange={this.handleImageChange.bind(this, "answers#" + index + ".url")}
                                                    getFolderId={this.getFolderId.bind(this)}
                                                    fileType={curr_task.type == AnswerTypes.image ? ExerciseFileTypes.Image : ExerciseFileTypes.Sound}
                                                />}
                                            </label>
                                        </div> : ""}                                       
                                    </div>
                                </Card>)
                            })
                            : ""
                    }
                    <div className="row">
                        <button className="button small columns" onClick={this.onAddNewQuestion.bind(this)}><i className="fa fa-plus"></i> {__("Válasz hozzáadása")}</button>
                        <label className="exe-editor-label-description columns">{__("Egy kérdéshez minimum {min}, maximum {max} válaszelem tartozhat!", {min: EKEQuizConverter.QUIZ_MIN_ANS_NUMBER, max: EKEQuizConverter.MAX_ANSWER_NUMBER})}</label>
                    </div>
                </Panel>
            </Panel>
        );
    }

    public convertToEditorAnswer(exercise: QuizData): QuizAnswerElement | undefined {
        if (exercise) {
            var answerList: CheckableMultiTypeAnswerItem[] = [];
            var cur_exercise = exercise;

            if (cur_exercise.options)
                for (var _i = 0; _i < cur_exercise.options.length; _i++) {
                    var curr_ans: CheckableMultiTypeAnswerItem = { is_answer: false, type: AnswerTypes.text };
                    let isAns: boolean = (cur_exercise.solution && Array.isArray(cur_exercise.solution)) ? cur_exercise.solution.indexOf(_i) >= 0 : false;

                    if (cur_exercise.options[_i].type == "image") {
                        curr_ans =
                            {
                                url: cur_exercise.options[_i].image,
                                text: cur_exercise.options[_i].text,
                                is_answer: isAns,
                                type: AnswerTypes.image
                            };
                    }

                    else if (cur_exercise.options[_i].type == "sound") {
                        curr_ans =
                            {
                                url: cur_exercise.options[_i].url,
                                text: cur_exercise.options[_i].text,
                                is_answer: isAns,
                                type: AnswerTypes.sound
                            };
                    }

                    else {
                        curr_ans =
                            {
                                text: cur_exercise.options[_i].text,
                                is_answer: isAns,
                                type: AnswerTypes.text
                            };
                    }
                    answerList.push(curr_ans)
                }
            var response: QuizAnswerElement = {
                answers: answerList,
                multiple_answers: Boolean(exercise.multiple_answers),
                vertical_display: exercise.vertical_display
            }
            return response;
        }
        else return undefined;
    }

    public convertToJson(answerList: QuizAnswerElement, baseData: ExerciseBaseTypes.ExerciseBaseClass, prevJSON?: QuizAnswerElement): QuizData | undefined {
        if (answerList) {
            var ans_list = [];
            var sol_list = [];
            if (answerList.answers) {
                var modified = false;
                if (prevJSON != undefined && prevJSON.multiple_answers && !answerList.multiple_answers) {
                    for (let index = 0; index < answerList.answers.length; index++) {
                        answerList.answers[index].is_answer = false;
                    }
                }

                for (var _i = 0; _i < answerList.answers.length; _i++) {
                    if (answerList.answers[_i].type == AnswerTypes.image) {
                        let curr_ans = {
                            type: "image",
                            text: answerList.answers[_i].text,
                            image: answerList.answers[_i].url
                        }
                        ans_list.push(curr_ans);
                    }

                    else if (answerList.answers[_i].type == AnswerTypes.sound) {
                        let curr_ans = {
                            type: "sound",
                            text: answerList.answers[_i].text,
                            url: answerList.answers[_i].url,
                        }
                        ans_list.push(curr_ans);
                    }

                    else {
                        let curr_ans = {
                            type: "text",
                            text: answerList.answers[_i].text,
                            image: ""
                        }
                        ans_list.push(curr_ans);
                    }
                    if (!answerList.multiple_answers && prevJSON != undefined && prevJSON.answers != undefined &&  //not multipleanswers, and previously it was not answer
                        prevJSON.answers[_i] != undefined && !prevJSON.answers[_i].is_answer &&
                        answerList.answers[_i].is_answer) {

                        sol_list = [];                //then deleting all the existing answers
                        modified = true;                    //remember that it was modified
                        sol_list.push(_i);            //and storing this from now
                    }
                    else if (!answerList.multiple_answers && !modified && answerList.answers[_i].is_answer) {
                        sol_list.push(_i)            //not multipleanswer, was not modified than this is the answer
                    }
                    else if (!answerList.multiple_answers && modified && answerList.answers[_i].is_answer) {
                        answerList.answers[_i].is_answer = false;            //if modification happened, delete the previously set answers' booleans
                    }
                    else if (answerList.multiple_answers && answerList.answers[_i].is_answer)
                        sol_list.push(_i);
                }
            }
            var cur_exercise = ExerciseBaseTypes.convertToBaseJson(baseData);
            cur_exercise = {
                ...cur_exercise,
                vertical_display: answerList.vertical_display,
                options: ans_list,
                solution: sol_list,
                multiple_answers: answerList.multiple_answers
            };
            return cur_exercise;
        }
        else return undefined;
    }

    public convertOldNKPToJson(data: any): any | undefined {

        var ans_list = [];
        var sol_list = [];
        let isImage = false;
        if (data.Answers) {
            for (var _i = 0; _i < data.Answers.length; _i++) {
                //ans_list.push(data.Answers[_i].AnswerText);

                if (data.Answers[_i].AssetId) {
                    // https://player.nkp.hu/play/32763/false/undefined
                    //  assetid: 32,778
                    let currAsset = data.ContentVersionReferences.find(function (element: any) {
                        return element.Id == data.Answers[_i].AssetId;
                    });
                    let curr_ans = {
                        type: "image",
                        text: data.Answers[_i].AnswerText,
                        image: currAsset.ThumbnailUrl
                    }
                    ans_list.push(curr_ans);
                    isImage = true;
                } else {
                    let curr_ans = {
                        type: "text",
                        text: data.Answers[_i].AnswerText,
                        // image: answerList.answers[_i].answer
                    }
                    ans_list.push(curr_ans);
                }


                if (data.Answers[_i].IsCorrect)
                    sol_list.push(_i);
            }
        }

        let newExercise = {
            title: data.Title,
            description: data.QuestionText,
            backgroundStyle: { is_custom_background: false, backgroundImage: "", backgroundColor: "" },
            illustration: (ExerciseBaseTypes.convertOldNKPToAnswerElement(data, data).image != "" && ExerciseBaseTypes.convertOldNKPToAnswerElement(data, data).image != undefined) ?
                ExerciseBaseTypes.convertOldNKPToAnswerElement(data, data).image : null,
            level: data.QuestionDifficulty == 1 ? 1 : data.QuestionDifficulty == 2 ? 3 : 5,
            demo_path: "",
            imageanswers: isImage,
            imagebasepath: "", //await EditorServer.getExerciseNewImageBasePath()
            comment: data.Description,
            options: ans_list,
            solution: sol_list,
            multiple_answers: (sol_list.length > 1)
        };
        return newExercise;
    }
    public validate(editorAnswer: QuizAnswerElement, baseData: any, validationMap?:Map<string,string>, is_accessible?:boolean|null): ValidationResponse {
        let superAnswer: ValidationResponse = super.validate(editorAnswer, baseData,validationMap, is_accessible);
        if (!superAnswer.valid) return superAnswer;
        let errorMsg="";
        let OFIErrors: string[] = [];
        if(editorAnswer.answers.length < EKEQuizConverter.QUIZ_MIN_ANS_NUMBER || (editorAnswer.answers.length > EKEQuizConverter.MAX_ANSWER_NUMBER)){
            errorMsg = __("Egy kérdéshez minimum {min}, maximum {max} válaszelem tartozhat!", {min: EKEQuizConverter.QUIZ_MIN_ANS_NUMBER, max: EKEQuizConverter.MAX_ANSWER_NUMBER});
            if(!OFIErrors.includes(errorMsg, 0)) OFIErrors.push(errorMsg);
            if(!AExerciseTypeConverter.isOfiEditor) return { valid: false, message: errorMsg };
        }
        if (AExerciseTypeConverter.isOfiEditor) return { valid: true, message: OFIErrors.join(' , ')}
        
        return { valid:true };
        
    }

    public makeValidBeforeSetState(oldState: QuizAnswerElement, newState: QuizAnswerElement): ValidationResponse {
        if (newState.answers && !newState.multiple_answers) {
            var modified = false;
            for (var i = 0; i < newState.answers.length; i++) {
                //if there is a previous state we check if the solution was there false and now it has changed to true then this is the new solution
                if (oldState != undefined && oldState.answers != undefined && oldState.answers[i] != undefined &&
                    !oldState.answers[i].is_answer && newState.answers[i].is_answer) {
                    for (let index = 0; index < newState.answers.length; index++) {
                        if (index != i) newState.answers[index].is_answer = false;
                    }
                    modified = true;
                    break;
                }
                if (modified) break;
            }
        }
        if (newState.answers && newState.answers.length > EKEQuizConverter.MAX_ANSWER_NUMBER){
            newState.answers.splice(EKEQuizConverter.MAX_ANSWER_NUMBER, newState.answers.length - EKEQuizConverter.MAX_ANSWER_NUMBER);
            return { valid: false, message: __("Egy kérdéshez minimum {min}, maximum {max} válaszelem tartozhat!", {min: EKEQuizConverter.QUIZ_MIN_ANS_NUMBER, max: EKEQuizConverter.MAX_ANSWER_NUMBER})};
        }
        return { valid: true }
    }

    public isSolutionAvailable(exerciseJson: ExerciseBaseTypes.ExerciseBaseClass): boolean {
        if (!super.isSolutionAvailable(exerciseJson))
            return false;

            if(exerciseJson.options.length<2) return false;   
            for(let i =0;i<exerciseJson.options.length;i++)
            {   
                let temp=(exerciseJson.options[i] as ExerciseBaseTypes.AnswerElement);
                if( (!temp.text || temp.text.length<1) && (!temp.image||temp.image.length<1)&&(!temp.url||temp.url.length<1)) return false;
            }   
        return true;
    }

    onAddNewQuestion() {
        let temp_exercise = this.state.exercise as QuizAnswerElement;
        if (!temp_exercise.answers) temp_exercise.answers = [];
        temp_exercise.answers.push(this.getNewQuestion());
        if(this.makeValidBeforeSetState(this.state.exercise, temp_exercise).valid)
            this.setState({ exercise: temp_exercise });
    }

    getNewQuestion(): ExerciseBaseTypes.CheckableMultiTypeAnswerItem {
        let newElement: ExerciseBaseTypes.CheckableMultiTypeAnswerItem = {
            is_answer: false,
            text: "",
            type: AnswerTypes.text
        };
        return newElement;
    }


    public getExerciseStatistics(exerciseList: any): StatisticsResponse[] {
        if (!exerciseList || exerciseList.length == 0) return [];
        let statisticsResponses: StatisticsResponse[] = [];
        let answerStat: StatisticsResponse;
        statisticsResponses.push({name:__("limit - válaszok min. száma: {n}", {n: EKEQuizConverter.QUIZ_MIN_ANS_NUMBER}), count:undefined});
        statisticsResponses.push({name:__("limit - válaszok max. száma: {n}", {n: EKEQuizConverter.MAX_ANSWER_NUMBER}), count:undefined});
        answerStat = { name: __("Válaszok száma"), count: new Map() }
        for (let i = 0; i < exerciseList.length; i++) {
            if (!exerciseList[i]) continue;
            const curr_ex = exerciseList[i];
            let answer_count = answerStat.count!.has(curr_ex.answers.length) ? answerStat.count!.get(curr_ex.answers.length) : 0;
            answerStat.count!.set(curr_ex.answers.length, answer_count! + 1);
        }
        statisticsResponses.push(answerStat);
        return statisticsResponses;
    }
}