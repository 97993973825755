import * as React from "react";
import { match } from "react-router";
import ViewInstituteGroupMember, { IViewInstituteGroupMemberRecord, viewInstituteGroupMemberClassProxy } from "@src/framework/view/sys/ViewInstituteGroupMember";
import { History } from 'history';
import { me } from "@src/framework/server/Auth";
import { BubbleLoader } from 'react-css-loaders';
import { __ } from "@src/translation";
import MemberCard, { MemberCardFromId } from "@src/framework/forms/MemberCard";
import ViewSharedFromMyGroup, { IViewSharedFromMyGroupRecord } from "@src/framework/view/usr/ViewSharedFromMyGroup";
import { TFilterDict } from "@src/framework/crud/Crud";
import { DateToString, DTSMode } from "@src/component/dashboard/eventcalendar/EventCalendarAPI";
import { addToDate, formatDate, roundToDecimals } from "@src/Util";
import { ContentDetails, getContentDetailsList } from "@src/component/filemanager/ContentUtils";
import { ExerciseEvaluationHelper, SubmissionDetails, ExerciseSubmissionStates } from "@src/component/exercise/ExerciseEvaluationHelper";
import { SHARE_MEGOSZTAS_ID } from "@src/Const";
import ReactTable, { Column } from "react-table";
import { Link } from "react-router-dom";
import { PATH_SHARED_CONTENT_BY_ME, PATH_SHARED_CONTENT_WITH_ME } from "@src/Routes";
import ViewSharedWithMe from "@src/framework/view/usr/ViewSharedWithMe";
import LookupEdit from "@src/framework/forms/lookupedit";
import SecUserCrud from "@src/framework/crud/sys/SecUserCrud";
import OpenEndedQuestionCrud from "@src/framework/crud/usr/OpenEndedQuestionCrud";
import { InstituteGroupMemberStates } from "@src/component/institute/InstituteGroupMemberList";
import ViewMemberName from "@src/framework/view/sys/ViewMemberName";



type ClassroomResultsPageProps = {
    match: match<{ instituteGroupId: string }>;
    history: History;
}

interface IViewShared {
    id?: number;
    shared_by_id?: number;
    table_info_id?: number;
    rec_id?: number;
    share_mode_id?: number;
    start_date?: string /*timestamp?*/;
    title?: string;
    table_display_name?: string | null;
    share_mode_title?: string | null;
    displayvalue?: string | null;
    max_points?: number | null;
    user_success_percent?: number | null;
    user_earned_points?: number | null;
}

type ClassroomResultsPageState = {
    loading: boolean,
    classRoomId: number,
    selectedMemberId?: number | null,
    selectedMemberName?: string | null,
    sharedRecords: IViewShared[],
    filteredSharedRecords: IViewShared[],
    fromDate?: string,
    toDate?: string,
    isAdmin?: boolean,
    summEarnedPoints: number,
    summTotalPoints: number
}

export class ClassroomResults extends React.Component<ClassroomResultsPageProps, ClassroomResultsPageState> {

    constructor(props: ClassroomResultsPageProps) {
        super(props);

        const classRoomId = Number(this.props.match.params.instituteGroupId);
        this.state = {
            sharedRecords: [],
            loading: false,
            classRoomId: classRoomId,
            filteredSharedRecords: [],
            fromDate: undefined,//DateToString(addToDate(new Date(), 0, -1, 0), DTSMode.dateFull, "-"), //By default we set fromDate last 30 days
            toDate: undefined,//DateToString(new Date(), DTSMode.dateFull, "-"),
            summEarnedPoints: 0,
            summTotalPoints: 0
        }
        this.asyncReload();
    }

    async asyncReload() {
        if (!me) return;
        let classRoomId = this.state.classRoomId;
        if (!classRoomId) classRoomId = Number(this.props.match.params.instituteGroupId);
        if (!classRoomId) return;

        this.setState({ loading: true });
        let isAdmin = false;
        const admins: IViewInstituteGroupMemberRecord[] = await ViewInstituteGroupMember.list({
            filter: {
                institute_group_id: classRoomId,
                is_active: true,
                is_admin: true,
                sec_user_id: me!.id
            }
        });
        isAdmin = admins.length > 0;

        let filter: TFilterDict = { is_active: true };
        let records: IViewShared[] = [];
        let details: any[] = [];
        let memberId = (isAdmin ? this.state.selectedMemberId! : me.id!);
        let summTotalPoints: number = 0;
        let summEarnedPoints: number = 0;

        if (this.state.fromDate) {
            let from = new Date(this.state.fromDate);
            from.setHours(0, 0, 0, 0);
            filter[">="] = { start_date: from.getTime() }
        }
        if (this.state.toDate) {
            let to = new Date(this.state.toDate);
            to.setHours(23, 59, 59, 999);
            filter["<="] = { start_date: to.getTime() }
        }
        filter.shared_from_institute_group_id = classRoomId;
        filter["$not"] = { share_mode_id: SHARE_MEGOSZTAS_ID };

        if (isAdmin) {
            let sharedByMe: IViewSharedFromMyGroupRecord[] = [];
            sharedByMe = await ViewSharedFromMyGroup.list({
                filter, limit: 1000, order_by: [{ name: "start_date", desc: true }]
            });
            records = sharedByMe;
        } else {
            let sharedWithMe = await ViewSharedWithMe.list({ filter, limit: 1000, order_by: [{ name: "start_date", desc: true }] });
            records = sharedWithMe;
        }
        // todo: get the shared with me in the same datastructure
        // itearate on sharedbymedetails         

        details = await getContentDetailsList(records.map(i => ({ tableOrViewId: i.table_info_id!, recordId: i.rec_id! })));

        if (memberId) {
            for (let index = 0; index < records.length; index++) {
                const element = records[index];
                let result = (await ExerciseEvaluationHelper.getUserSuccessPercent(memberId, element.id!));

                records[index].user_success_percent = result;
                if (element.max_points) {
                    summTotalPoints += element.max_points;
                    if (result > -1) {
                        records[index].user_earned_points = (result * element.max_points);
                        summEarnedPoints += records[index].user_earned_points!;
                    }
                }
            }
        }

        this.setState({
            loading: false,
            sharedRecords: records,
            isAdmin,
            filteredSharedRecords: records.map(i => {
                i.start_date = formatDate(i.start_date);
                return i;
            }),
            summTotalPoints:Math.round(summTotalPoints),
            summEarnedPoints:Math.round(summEarnedPoints)
        });

    }

    userSelected(userId: number | null) {
        this.getMemberNameFromId(userId);
        // this.setState({ selectedMemberId: userId }, this.asyncReload);
    }

    onChangeDateFilter(propName: string, ev: any) {
        this.setState({ ...this.state, [propName]: ev.target.value }, this.asyncReload);
    }

    async getMemberNameFromId(id: number | null) {
        try {
            const viewer_id = me!.id!;
            const member = await ViewMemberName.list({ filter: { id: id, viewer_id } });
            this.setState({selectedMemberName: member[0].fullname + " <" + member[0].email + ">" , selectedMemberId: id}, this.asyncReload);
        } catch (e) {
            console.log(e);
        }
        return "";
    }

    render() {
        if (!me && !this.state.classRoomId) return "";

        if (this.state.loading || this.state.isAdmin == undefined) return <BubbleLoader />;
        let urlBase = "";
        if (this.state.isAdmin) {
            urlBase = PATH_SHARED_CONTENT_BY_ME + "/";
        } else {
            urlBase = PATH_SHARED_CONTENT_WITH_ME + "/";
        }

        let columns: Column[] = [
            {
                Header: __("Feladat címe"), accessor: "title", filterable: false, sortable: true,
                Cell: (data: any, column: any) => {
                    const row: IViewShared = data.original;
                    let url = undefined;
                    if ((!this.state.isAdmin || row.shared_by_id == me!.id)) {
                        url = urlBase + row.id;
                    }
                    return (<p>
                        {url ? <Link to={url || ""}> <b>{row.title}</b> </Link> : <b>{row.title}</b>}<br />
                        <span style={{ fontFamily: "monospace" }}>{row.table_display_name}</span><br></br>
                    </p>);
                }, style: { justifyContent: "left" }
            },
            {
                Header: __("Kiadás ideje"),
                accessor: "start_date", filterable: false, sortable: true,
                Cell: (data: any, column: any) => {
                    const row: IViewShared = data.original;
                    return row.start_date;
                }
                , style: { justifyContent: "left" }
            },
            {
                Header: __("Eredmény (%)"), accessor: "due_date", filterable: false, sortable: false,
                Cell: (data: any, column: any) => {
                    const row: IViewShared = data.original;
                    let text = __("Nincs elkezdve");
                    if (row.user_success_percent != undefined) {
                        if ( row.user_success_percent > -1) {
                            text = (Math.round( row.user_success_percent * 100) + " %");
                        } else if ( row.user_success_percent == ExerciseSubmissionStates.NotEvaluated){
                            text = __("Nincs értékelés");
                        } else if( row.user_success_percent == ExerciseSubmissionStates.NotSubmitted){
                            text = __("Nincs beküldve");
                        }
                    }
                    return text;
                }
                , style: { justifyContent: "center" }
            },
            {
                Header: __("Össz pont"), accessor: "due_date", filterable: false, sortable: false,
                Cell: (data: any, column: any) => {
                    const row: IViewShared = data.original;
                    return row.max_points;
                }
                , style: { justifyContent: "center" }
            }, {
                Header: __("Elért pont"), accessor: "due_date", filterable: false, sortable: false,
                Cell: (data: any, column: any) => {
                    const row: IViewShared = data.original;
                    let text = 0;
                    if (row.user_earned_points != undefined && row.user_earned_points != null) {
                        text = roundToDecimals(row.user_earned_points, 1);
                    }
                    return text;
                }
                , style: { justifyContent: "center" }
            },
        ];

        let roundedSharedRecords = this.state.sharedRecords.map(sr => {
            sr.user_earned_points = sr.user_earned_points ?  Math.round(sr.user_earned_points) : undefined;
            return sr;
        })

        return (<>
            <div className="row expanded classroom-result">
                {
                    this.state.isAdmin ? <>
                        <div className="small-12 columns">
                            <label>{__("Diák") + ": "}
                                {/*<SecUserSelector clearable={false} groupId={this.state.classRoomId || undefined} onChange={(event) => this.userSelected(event)} value={null} />*/}
                                <LookupEdit
                                    key={"member_name_selector"}
                                    fk_table_info_id={SecUserCrud.TABLE_INFO_ID}
                                    emptyLoad={true}
                                    viewClassProxy={viewInstituteGroupMemberClassProxy}
                                    distinct={true}
                                    searchColumnNames={["member_name", "member_email"]}
                                    displayColumnNames={["member_name", "member_email"]}
                                    orderByColumnNames={[{ name: "member_name" }]}
                                    valueColumn={"sec_user_id"}
                                    filter={{
                                        is_active: true,
                                        institute_group_id: this.state.classRoomId,
                                        is_admin: false,
                                        status_id: InstituteGroupMemberStates.ACTIVE_ID
                                    }}
                                    clearable={true}
                                    // value={this.state.selectedMemberId}
                                    onChange={value => this.userSelected(value as number)}
                                    placeholder={this.state.selectedMemberName ? this.state.selectedMemberName : __("Kérem válasszon diákot...")} />
                            </label>
                        </div>
                        {this.state.selectedMemberId &&
                            <div className="small-12 columns">
                                <h3><MemberCardFromId memberId={this.state.selectedMemberId} short={true} />
                                    {" " + __("eredményei")}
                                </h3>
                            </div>
                        }
                    </>
                        :
                        <div className="small-12 columns">
                            <h3>
                                {__("Eredményeim")}
                            </h3>
                        </div>
                }

                <div className="small-12 columns">
                    <div className="row align-center">
                        <div className="large-5 medium-6 small-12 columns">
                            <label>{__("Szűrés kezdete")}
                                <input type="date" value={this.state.fromDate ? this.state.fromDate.substr(0, 10) : ""}
                                    onChange={this.onChangeDateFilter.bind(this, "fromDate")}
                                />
                            </label>
                        </div>
                        <div className="large-5 medium-6 small-12 columns">
                            <label>{__("Szűrés vége")}
                                <input type="date" min={this.state.fromDate ? this.state.fromDate : undefined} value={this.state.toDate ? this.state.toDate.substr(0, 10) : ""}
                                    onChange={this.onChangeDateFilter.bind(this, "toDate")}
                                />
                            </label>
                        </div>
                    </div>
                </div>
                {(this.state.selectedMemberId || !this.state.isAdmin) && this.state.sharedRecords && roundedSharedRecords &&
               
                    <div className="small-12 columns">
                        {
                            <ReactTable data={roundedSharedRecords}
                                columns={columns}
                                /*resizable={false}*/
                                pageSize={10}
                                loading={this.state.loading}
                                className="-striped -highlight"
                                showPageSizeOptions={false} />
                        }
                    </div>
                }
            </div>
            <div className="row" style={{ marginBottom: "20px" }}>
                <div className="small-12 medium-5 large-6 columns">
                    <span><strong>{__("Össz. elérhető pont: ")}</strong></span><span>{this.state.summTotalPoints}</span>
                </div>

                <div className="small-12 medium-5 large-6 columns">
                    <span><strong>{__("Össz. elért pont: ")}</strong></span><span>{this.state.summEarnedPoints}</span>
                </div>
            </div>

        </>
        );
    }
}