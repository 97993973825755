import * as React from 'react'
import { BubbleLoader } from 'react-css-loaders';
import "@src/component/exercise/ExerciseContainerComponent.css";
import ExerciseCrud from '@src/framework/crud/exc/ExerciseCrud';
import { IExerciseRecord } from '@src/framework/crud/exc_pub/ExerciseCrud';

import { app } from '@src/index';
import { searchExercise, SearchResult } from '@src/server/PublicServer';
import { MathJaxText } from '../../component/MathJaxText';
import { __ } from '@src/translation';

declare var MathJax: any;
const ITEMS_PER_PAGE: number = 10;

interface IExerciseBlock {
    exercise: IExerciseRecord;
    searchResults?: SearchResult[];
}

interface IPage {
    exerciseBlocks: IExerciseBlock[];
}

interface ExerciseSearchResultsState {
    pageIndex: number;
    pages: IPage[];
    loading: boolean;
}

export default class ExerciseSearchResults extends React.Component<any, ExerciseSearchResultsState> {

    constructor(prop: any) {
        super(prop);

        this.state = {
            pageIndex: 0,
            pages: [],
            loading: true,
        }
        MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
    }

    componentDidMount() {
        this.asyncReload();
    }

    private asyncReload = async () => {
        let pages: IPage[] = []; // Az összes lap
        let pageIndex = 0;
        let exerciseBlocks: IExerciseBlock[] = []; // Az aktuális lap
        try {
            let exercises: IExerciseRecord[] = await ExerciseCrud.list({ filter: { grade_id: 7200 }, order_by: "name" , limit: 10000});
            for (let i: number = 0; i < exercises.length; i++) {
                const exercise: IExerciseRecord = exercises[i];
                if (pageIndex == this.state.pageIndex) {
                    // Az akutális lapon beleteszük a feladatot és a search engine-t is meghívjuk
                    const searchResults: SearchResult[] = await searchExercise(exercise.id!, false);
                    exerciseBlocks.push({ exercise, searchResults });
                } else {
                    exerciseBlocks.push({ exercise }); // Belerakjuk a feladatot, de a search engine-t NEM hívjuk meg.
                }

                // Következő lapra ugrunk ha szükséges.
                if (exerciseBlocks.length >= ITEMS_PER_PAGE) {
                    pages.push({ exerciseBlocks });
                    exerciseBlocks = [];
                    pageIndex += 1;
                }
            }
            // Ha van a végén tört lap, hozzáadjuk.
            if (exerciseBlocks.length > 0) {
                pages.push({ exerciseBlocks });
            }
            this.setState({ pages, loading: false });
        } catch (error) {
            app.showErrorFromJsonResult(error);
        }
    }

    private goToPage = async (pageIndex: number) => {
        this.setState({loading: true});
        try {
            let page = this.state.pages[pageIndex];
            for (let blockIndex = 0; blockIndex < page.exerciseBlocks.length; blockIndex++ ) {
                let block = page.exerciseBlocks[blockIndex];
                if (block.searchResults===undefined) {
                    block.searchResults = await searchExercise(block.exercise.id!, false);
                }
            }
            this.setState({pageIndex, pages: this.state.pages, loading: false} );
        } catch(error) {
            app.showErrorFromJsonResult(error);
        }
    }

    render() {

        if (!this.state.pages || !this.state.pages[this.state.pageIndex] || this.state.loading) {
            return <BubbleLoader />;
        }
        const page: IPage = this.state.pages[this.state.pageIndex];

        if (page.exerciseBlocks.length == 0) {
            return <p className="callout warning">{__("Nincs találat.")}</p>;
        }

        let  minIndex = this.state.pageIndex - 5;
        if (minIndex<0) {
            minIndex = 0;
        }
        let maxIndex = minIndex + 10;
        if (maxIndex > this.state.pages.length-1) {
            maxIndex = this.state.pages.length-1;
        }
        let pageSelector = null;
        if (minIndex!=maxIndex) {
            let pageLinks : JSX.Element[] = [
                <a onClick={()=>{this.goToPage(0)}}> {__("ELSŐ LAPRA |")} </a>
            ];
            for (let index=minIndex ; index<=maxIndex; index++) {
                if (index==this.state.pageIndex) {
                    pageLinks.push(<span> Lap {index+1} | </span>);
                } else {
                    pageLinks.push(<a onClick={()=>{this.goToPage(index)}}> {__("Lap")} {index+1} | </a>);
                }
            }
            pageLinks.push(
                <a onClick={()=>{this.goToPage(this.state.pages.length -1)}}> {__("UTOLSÓ LAPRA")} </a>
            )
            pageSelector = <div className="row"><div className="small-12 column">
                    {pageLinks.map( (pageLink, index) => {return  <span key={index}>{pageLink}</span>})}
                    </div></div>;
        }

        return <div className="row">
            {pageSelector}
            {page.exerciseBlocks.map((exerciseBlock: IExerciseBlock, index : number) =>
                <div className="small-12 column" key={index}>
                    <div className="exe-controls-result exe-controls-result-wrong">
                        <p>{__("Feladat címe")}: <span>{exerciseBlock.exercise.name!}</span></p>
                        <p>{__("Feladat kulcsszavai")}: <span>{exerciseBlock.exercise.keywords}</span></p>
                        <p>{__("Feladat azonosítója")}: <span>{exerciseBlock.exercise.id}</span></p>
                        <p>{__("Javaslatok")}:</p>
                        <ul>
                            {exerciseBlock.searchResults?
                                exerciseBlock.searchResults!.map((searchResult) => {
                                    return <li key={searchResult._id}>
                                        <p title={searchResult.subject_name + " / " + searchResult.grade_name + " / " + searchResult.text}>
                                            <a target="_blank" className="exe-result-link" href={"/tankonyv/" + searchResult.book_uri_segment + "/" + searchResult.lesson_uri_segment + "#section-" + searchResult.section_id}>
                                                <i className="fa fa-arrow-circle-o-right" aria-hidden="true" />
                                                <MathJaxText>{searchResult.subject_name + " / " + searchResult.grade_name + " / " + searchResult.text}</MathJaxText>
                                            </a>
                                        </p>
                                    </li>
                                })
                            :<p className="callout secondary">{__("Nincs találat javaslatra.")}</p>
                            }
                        </ul>
                    </div>
                    <br />
                </div>
            )}
            {pageSelector}
        </div>;
    }
}