import * as React from "react";
import CourseContactDayCrud, { ICourseContactDayRecord } from "@src/framework/crud/kap/CourseContactDayCrud";
import { app } from "@src/index";
import { BubbleLoader } from 'react-css-loaders';
import { __ } from "@src/translation";
import CourseStudentList from "./CourseStudentList";
import ViewKapCourse, { IViewKapCourseRecord } from "@src/framework/view/kap/ViewKapCourse";
import { formatDateWithoutTime, formatDate } from "@src/Util";
import ViewKapCourseTrainerInvitation, { IViewKapCourseTrainerInvitationRecord } from "@src/framework/view/kap/ViewKapCourseTrainerInvitation";
import { me, Groups, hasAnyGroup, hasGroup } from "@src/framework/server/Auth";
import { Link } from "react-router-dom";
import ViewKapTrainingType, { IViewKapTrainingTypeRecord } from "@src/framework/view/kap/ViewKapTrainingType";
import { PATH_PUBLISHED_BOOK, PATH_COURSE_ORGANIZER_SURVEY } from "@src/Routes";
import ViewKapCourseUser, { IViewKapCourseUserRecord } from "@src/framework/view/kap/ViewKapCourseUser";
import AccessDeniedPage from "@src/framework/pages/AccessDeniedPage";
import CourseUserCrud, { ICourseUserRecord } from "@src/framework/crud/kap/CourseUserCrud";
import WfAPI, { IViewTransitionTypeRecordWithPerm } from "@src/framework/wf/WfAPI";
import obtainServer from "@src/framework/server/Server";
import { WF_TYPE_KAP_COURSE_USER_ID, LIBRARY_KSZR_BEADANDO_ID, WF_STATION_KSZR_COURSE_USER_STATION_KERELEM_BEADVA_ID, WF_STATION_KSZR_COURSE_USER_STATION_KEPZESBOL_TOROLVE_ID } from "@src/Const";
import { WfDeleteTransitionInfo, PermissionCache } from "@src/framework/security/PermissionCache";
import { IViewTransitionTypeRecord } from "@src/framework/view/wf/ViewTransitionType";
import { IViewWfWorkflowRecord } from "@src/framework/view/wf/ViewWfWorkflow";
import ButtonGroup, { ButtonSize, ButtonAlign } from "@src/component/ui/ButtonGroup";
import CourseDocumentComponent from "./CourseDocumentsComponent";
import MediaFileChooser, { ContentActionType } from "@src/component/filemanager/MediaFileChooser";
import { confirmDialog, Dialog } from "@src/component/Dialog";
import { LessonDraftEdit } from "../draft/LessonDraft";
import { KipDraftEdit } from "../draft/KipDraft";
import { OccupationDraftEdit } from "../draft/OccupationDraftEdit";
import { IViewKapCourseInstituteSiteRecord } from "@src/framework/view/kap/ViewKapCourseInstituteSite";
import { DownloadAsPdf } from '../draft/DownloadAdPdf';
import { Accordion, AccordionItem } from "@src/component/ui/Accordion";
import { WorkflowDialog } from "@src/component/wf/WorkflowDialog";
import CourseCrud from "@src/framework/crud/kap/CourseCrud";
import { transitionTypeCrudClassProxy } from "@src/framework/crud/wf/TransitionTypeCrud";
import { Breadcrumbs } from "@src/component/Breadcrumbs";
import ViewMediaOoFile, { IViewMediaOoFileRecord } from '@src/framework/view/media/ViewMediaOoFile';
import CourseStats from "./adminPages/CourseStats";
import NewMessagePopUp from "@src/module/messengerModule/components/NewMessagePopUp";
import { courseModule } from "./courseModule";
import { CourseChecklist } from "./adminPages/CourseChecklist";


interface ICourseDetailsComponentState {
    loading: boolean;
    courseRecord?: IViewKapCourseRecord;
    possibleCourseUserTransitions: IViewTransitionTypeRecordWithPerm[];
    workflowDialogOpen: boolean;
    courseUser: IViewKapCourseUserRecord | null;
    isTeacher: boolean;
    isInspector: boolean;
    workflowStateName?: string;
    lastInvitationDeadline?: string;
    contactDates: ICourseContactDayRecord[];
    sites: IViewKapCourseInstituteSiteRecord[];
    trainingType?: IViewKapTrainingTypeRecord;
    showLessonPlanEditor: boolean;
    showKipDraftEditor: boolean;
    showOccupationDraftEditor: boolean;
    trainingTypeFiles: IViewMediaOoFileRecord[];
    messageOpen:boolean;
    otherJsonB:any;
}


/*
select 'export const WF_COURSE_USER_STATION_??_ID = ' || id || '; // ' || name
from wf.station s where wf_type_id = 25406740433 order by no asc
*/
export const WF_COURSE_USER_STATION_APPLICATION_REQUESTED_ID = 25406740434; // Kérelem beadva
export const WF_COURSE_USER_STATION_APPLICATION_REJECTED_ID = 25406740437; // Kérelem visszautasítva
export const WF_COURSE_USER_STATION_ACTIVE_STUDENT_ID = 25406740438; // Képzés engedélyezve
export const WF_COURSE_USER_STATION_APPLICATION_CORRECTION_ID = 25503820779; // Beadandó javítása
export const WF_COURSE_USER_STATION_APPLICATION_UNDER_REVIEW_ID = 25406740439; // Beadandó feladat elbírálás alatt
export const WF_COURSE_USER_STATION_APPLICATION_SUCCESSFULLY_CLOSED_ID = 25406740440; // Beadandó feladat sikeresen lezárva
export const WF_COURSE_USER_STATION_APPLICATION_FAILED_ID = 25406740441; // Beadandó feladat sikertelenül lezárva
export const WF_COURSE_USER_STATION_APPLICATION_NOT_FULFILLED_ID = 25408617064; // Nem teljesítette
export const WF_COURSE_USER_STATION_STUDENT_REMOVED_ID = 25406740442; // Képzésből törölve

/*
select 'export const WF_COURSE_STATION_??_ID = ' || id || '; // ' || name
from wf.station s where wf_type_id = 25406740407 order by no asc
*/
export const WF_COURSE_STATION_INACTIVE_ID = 25406740408; // Inaktív
export const WF_COURSE_STATION_ACTIVE_ID = 25406740411; // Aktív (jelentkezés/oktatás)
export const WF_COURSE_STATION_CLOSABLE_ID = 25408438524; // Lezárható
export const WF_COURSE_STATION_CLOSED_ID = 25406740413; // Lezárt
export const WF_COURSE_STATION_DELETED_ID = 25406740414; // Törölt


const SURVEY_KSZR_PARTICIPANT_ID = 3;

export default class CourseDetails extends React.Component<any, ICourseDetailsComponentState> {
    private static wfAPI: WfAPI = new WfAPI(obtainServer());

    constructor(props: any) {
        super(props);

        this.state = {
            loading: true,
            courseRecord: undefined,
            courseUser: null,
            possibleCourseUserTransitions: [],
            workflowDialogOpen: false,
            workflowStateName: undefined,
            trainingType: undefined,
            lastInvitationDeadline: "",
            contactDates: [],
            sites: [],
            isTeacher: false,
            isInspector: false,
            showLessonPlanEditor: false,
            showKipDraftEditor: false,
            showOccupationDraftEditor: false,
            trainingTypeFiles: [],
            messageOpen:false,
            otherJsonB:{}
        };
    }

    componentDidMount() {

        this.asyncReload();
    }

    componentDidUpdate() {
        ($("#course_data_card") as any).foundation();
    }

    public asyncReload = async (redirectToBook?: boolean) => {
        this.setState({ loading: true, courseRecord: undefined });
        let courseRecordId = this.getMatchParam("courseId");
        let contactDates: ICourseContactDayRecord[] = [];
        let lastInvitationDeadline = "";
        let trainerInvitations: IViewKapCourseTrainerInvitationRecord[] = [];
        let trainingType = undefined;
        let courseUser = null;
        let isTeacher = false;
        let isInspector = false;
        let possibleCourseUserTransitions: IViewTransitionTypeRecordWithPerm[] = [];
        let trainingTypeFiles: IViewMediaOoFileRecord[] = [];
        let otherJsonB={};
        try {
            let courseRecord: IViewKapCourseRecord = {};
            if (courseRecordId && courseRecordId != "null") {
                courseRecord = (await ViewKapCourse.load(courseRecordId)).record;
                const tmp = (await CourseCrud.load(courseRecordId)).record;
                if(tmp) otherJsonB=tmp.other_jsonb;

                if (courseRecord && courseRecord.training_type_id) {
                    trainingType = (await ViewKapTrainingType.load(courseRecord.training_type_id)).record;
                    contactDates = (await CourseContactDayCrud.list({ filter: { course_id: Number(courseRecordId) } }));

                    trainingTypeFiles = await ViewMediaOoFile.list({ filter: { is_active: true, oo_folder_id: trainingType.oo_folder_id }, order_by: [{ name: "title" }] });

                    if (me && trainingType) {
                        if (me.id == courseRecord.teacher_id) {
                            isTeacher = true;
                        } else if (me.id == courseRecord.oh_inspector_id) {
                            isInspector = true;
                        } else {
                            let courseUsers: IViewKapCourseUserRecord[] = (await ViewKapCourseUser.list({ filter: { course_id: courseRecord.id, sec_user_id: me.id } }));
                            //let tempUser = courseUsers.find(courseUser => courseUser.sec_user_id == me!.id && courseUser.is_active);
                            if (courseUsers.length) {
                                courseUser = courseUsers[0];
                                const workflowId = courseUser.wf_workflow_id!;
                                possibleCourseUserTransitions = await CourseDetails.wfAPI.listPossibleTransitions(workflowId);
                            }
                        }

                        if (isTeacher) {
                            trainerInvitations = (await ViewKapCourseTrainerInvitation.list({ filter: { course_id: Number(courseRecordId) }, order_by: [{ name: "answer_deadline", desc: true }] }));
                            lastInvitationDeadline = trainerInvitations[0] && trainerInvitations[0].answer_deadline ? trainerInvitations[0].answer_deadline : "";
                        }
                    }

                }

            } else {
                return;
            }
            this.setState({
                loading: false,
                courseRecord,
                possibleCourseUserTransitions,
                trainingType,
                lastInvitationDeadline,
                contactDates,
                isInspector,
                courseUser,
                isTeacher,
                workflowStateName: courseRecord ? courseRecord.wf_station_name! : "",
                trainingTypeFiles,
                otherJsonB
            }, () => {
                if (redirectToBook && this.state.courseUser && this.state.trainingType && this.state.trainingType.book_id
                    && this.state.courseUser.wf_station_id == WF_COURSE_USER_STATION_ACTIVE_STUDENT_ID) {
                    window.open(PATH_PUBLISHED_BOOK + "/" + this.state.trainingType.book_uri_segment + "/", "_blank");
                }
            });

        } catch (error) {
            app.showErrorFromJsonResult(error);
        };
    }

    protected getMatchParam(name: string): any {
        const match: any = this.props.match;
        return (match && match.params) ? match.params[name] : null;
    }

    public async onApplyCourse(event: any) {

        try {
            if (me && this.state.courseRecord) {
                // Megnézzük, hogy van-e neki korábbi törölt jelentkezése.
                if (this.state.courseUser) {

                    let goodTransitonTypes = this.state.possibleCourseUserTransitions.filter((trans) => {
                        return trans.dst_station_id == WF_STATION_KSZR_COURSE_USER_STATION_KERELEM_BEADVA_ID;
                    });

                    if (!goodTransitonTypes.length) {
                        app.showError("Hiba", 'Ön törölve lett a képzésből, és ebben az állapotban nem jelentkezhet újra.');
                        return;
                    }
                    const reapplyTransitonTypeId = goodTransitonTypes[0].id!;
                    const workflow: IViewWfWorkflowRecord = await CourseDetails.wfAPI.makeTransition(
                        this.state.courseUser.wf_workflow_id!,
                        reapplyTransitonTypeId,
                        "Újra jelentkezés"
                    );
                    app.showWarning(
                        "Figyelmeztetés",
                        "Ön korábban már jelentkezett erre a képzésre, de a jelentkezését törölték. " +
                        "Az újbóli jelentkezését befogadtuk, egy képzésszervező fogja elbírálni. " +
                        "Az elbírálás eredményéről értesítést fog kapni."
                    );
                } else {
                    let newApplicant: ICourseUserRecord = {}
                    newApplicant.course_id = this.state.courseRecord.id;
                    newApplicant.sec_user_id = me.id
                    await (new CourseUserCrud(newApplicant).put());
                    app.showSuccess("Köszönjük", "Ön sikeresen jelentkezett a kurzusra");
                }
                this.asyncReload(true);
            }

        } catch (error) {
            app.showErrorFromJsonResult(error);
        };
    }
    private getDeleteTransitionTypesForStation = (stationId: number, deleteTransitionInfo: WfDeleteTransitionInfo): IViewTransitionTypeRecord[] => {
        let deleteTransitions = [];
        let tt = undefined;
        if (deleteTransitionInfo) {
            tt = deleteTransitionInfo.activeTransitionTypes;
        }

        if (tt) {
            for (let i = 0; i < tt.length; i++) {
                let transitionType = tt[i];
                if (transitionType.src_station_id == stationId) {
                    if (transitionType.dst_is_deleted!) {
                        deleteTransitions.push(transitionType);
                    }
                }
            }
        }
        return deleteTransitions;

    }

    public async onQuitFromCourse(event: any) {

        try {
            if (me) {

                let registeredUser = this.state.courseUser;
                if (registeredUser) {

                    if (registeredUser && registeredUser.id) {

                        const stationId = registeredUser.wf_station_id;
                        if (stationId) {
                            // Folyamatban részt vevő feladat, folyamat átmenettel töröljük.
                            let deleteTransitionInfo: WfDeleteTransitionInfo = await PermissionCache.loadWfDelTrans(WF_TYPE_KAP_COURSE_USER_ID);
                            let possibleTransitions = this.getDeleteTransitionTypesForStation(stationId, deleteTransitionInfo);
                            if (possibleTransitions.length > 1) {
                                // TODO: mi lenne ha itt egyből megnyitnánk ?
                                app.showError(
                                    __("Hiba"),
                                    __('Egynél több célállapot elérhető.') + " " +
                                    __('A törlés végrehajtásához nyissa meg a folyamat dialógus ablakot.')
                                );
                                return;
                            }
                            const transitionType: IViewTransitionTypeRecord = possibleTransitions[0];
                            let justification = prompt(__('Kérem írja be az indoklást'), '');
                            this.setState({ loading: true });
                            let wfApi = new WfAPI(obtainServer());
                            try {
                                let workflowAfter: IViewWfWorkflowRecord = await wfApi.makeTransition(
                                    registeredUser!.wf_workflow_id!,
                                    transitionType.id!,
                                    justification
                                );
                                this.asyncReload();
                                app.showSuccess(__("Lejelentkezés kurzusról"), __("Sikeres"))

                            } catch (error) {
                                app.showErrorFromJsonResult(error);
                                this.setState({ loading: false });
                            };

                        }
                    }
                }

            }
        }
        catch (error) {
            app.showErrorFromJsonResult(error);
        };
        // const newMenuItem = (await new MenuItemCrud(newApplicant).put()).record;
    }

    private showMessageBox(){
        if(!this.state.courseRecord ||!this.state.courseRecord.teacher_id ){
            return;
        }
        this.setState({messageOpen:true});
    }

    private async onSetLessonDraftId(draftId: number) {
        let currCourseUser = this.state.courseUser;
        if (!currCourseUser) return;

        try {
            await new CourseUserCrud({
                id: currCourseUser.id,
                lesson_draft_id: draftId
            }).put();
            this.asyncReload();
        } catch (e) {
            app.showErrorFromJsonResult(e);
        };
    }

    private async onSetKipDraftId(draftId: number) {
        let currCourseUser = this.state.courseUser;
        if (!currCourseUser) return;

        try {
            await new CourseUserCrud({
                id: currCourseUser.id,
                kip_draft_id: draftId
            }).put();
            this.asyncReload();
        } catch (e) {
            app.showErrorFromJsonResult(e);
        };
    }

    private async onSetOccupationDraftId(draftId: number) {
        let currCourseUser = this.state.courseUser;
        if (!currCourseUser) return;

        try {
            await new CourseUserCrud({
                id: currCourseUser.id,
                occupation_draft_id: draftId
            }).put();
            this.asyncReload();
        } catch (e) {
            app.showErrorFromJsonResult(e);
        };
    }

    private async submitAssignment() {
        if (!this.state.courseUser) return;
        //todo: check if there's any file or lesson plan to submit

        let message = __("Biztosan beküldi a beadandót elbírálásra?")

        if ((await confirmDialog(__("Megerősítés"), message))) {
            const wfApi: WfAPI = new WfAPI(obtainServer());
            try {
                const transitionTypes = await transitionTypeCrudClassProxy.list({
                    filter: {
                        is_active: true,
                        src_station_id: this.state.courseUser.wf_station_id,
                        dst_station_id: WF_COURSE_USER_STATION_APPLICATION_UNDER_REVIEW_ID
                    }
                });
                if (transitionTypes && transitionTypes.length == 1) {
                    const transitionTypeId: number = transitionTypes[0].id!;
                    (await (wfApi.makeTransition(this.state.courseUser.wf_workflow_id!, transitionTypeId)).then(
                        () => {
                            this.asyncReload().then(() => { app.showSuccess(__("Sikeres művelet"), __("Sikeresen benyújtotta a beadandóját.")) });
                        }));
                } else {
                    if (!transitionTypes || transitionTypes.length != 1) {
                        app.showError("Belső hiba", "Nincs ilyen átmenet.");
                        return;
                    }
                }
            } catch (ex) {
                app.showErrorFromJsonResult(ex);
            }
        }
    }

    render() {
        const course = this.state.courseRecord;

        if (!course) return <BubbleLoader />;
        if (!me || (!hasAnyGroup(me, [Groups.KapKszrCourseCoordinator, Groups.Developer, Groups.Admin, Groups.KapKszrCourseTeacher, Groups.KapKszrParticipantTeacher, Groups.KapKszrOhInspector]))) {
            return AccessDeniedPage();
        }

        let isOnline = this.state.trainingType != undefined && this.state.trainingType.is_online;
        console.log("isonline", isOnline, this.state.trainingType);
        let showAdminData = hasAnyGroup(me, [Groups.KapKszrCourseCoordinator, Groups.Developer]);
        let showTrainerData = !this.state.courseUser && hasAnyGroup(me, [Groups.KapKszrCourseCoordinator, Groups.KapKszrCourseTeacher, Groups.Developer]);
        let showStudentData = (this.state.courseUser != undefined
            && this.state.courseUser.wf_station_id != WF_COURSE_USER_STATION_APPLICATION_REQUESTED_ID
            && this.state.courseUser.wf_station_id != WF_COURSE_USER_STATION_APPLICATION_REJECTED_ID
            && this.state.courseUser.wf_station_id != WF_COURSE_USER_STATION_STUDENT_REMOVED_ID);
        let isStudentDataEditable = this.state.courseUser && (
            this.state.courseUser.wf_station_id == WF_COURSE_USER_STATION_ACTIVE_STUDENT_ID ||
            this.state.courseUser.wf_station_id == WF_COURSE_USER_STATION_APPLICATION_CORRECTION_ID);


        let filePermits: number[] = [ContentActionType.FILE_DOWNLOAD];
        if (isStudentDataEditable)
            filePermits = filePermits.concat([ContentActionType.FILE_MODIFY, ContentActionType.FILE_UPLOAD, ContentActionType.FILE_DELETE]);


        const reapplyTransitonTypes = this.state.possibleCourseUserTransitions.filter((trans) => {
            return trans.dst_station_id == WF_STATION_KSZR_COURSE_USER_STATION_KERELEM_BEADVA_ID;
        });

        const reapplyTransiton = reapplyTransitonTypes.length ? reapplyTransitonTypes[0] : null;
        let deleteTransitonTypes = this.state.possibleCourseUserTransitions.filter((trans) => {
            return trans.dst_station_id == WF_STATION_KSZR_COURSE_USER_STATION_KEPZESBOL_TOROLVE_ID;
        });

        const deleteTransiton = deleteTransitonTypes.length ? deleteTransitonTypes[0] : null;


        return <div className="small-12 column clearfix">
            {course.id && !this.state.courseUser ?
                <WorkflowDialog
                    open={this.state.workflowDialogOpen}
                    displayName={__("Kurzus")}
                    tableInfoId={CourseCrud.TABLE_INFO_ID}
                    recId={course.id!}
                    onClose={() => this.setState({ workflowDialogOpen: false })}
                    onTransition={this.asyncReload.bind(this)}
                    showHistory={isStudentDataEditable || undefined}
                    autoPublishOnClosedStation={true}
                /> : ""
            }
            {this.state.courseUser ?
                <WorkflowDialog
                    open={this.state.workflowDialogOpen}
                    displayName={__("Beadandó")}
                    tableInfoId={CourseUserCrud.TABLE_INFO_ID}
                    recId={this.state.courseUser.id!}
                    onClose={() => this.setState({ workflowDialogOpen: false })}
                    onTransition={this.asyncReload.bind(this)}
                    showHistory={true}
                    autoPublishOnClosedStation={true}
                /> : ""
            }
            <div className="medium-12 column">
                {this.state.showLessonPlanEditor &&
                    <Dialog title="Óravázlat" width={1100} height={800}
                        onClose={() => this.setState({ showLessonPlanEditor: false })}>
                        <LessonDraftEdit
                            id={this.state.courseUser!.lesson_draft_id || undefined}
                            onSave={this.onSetLessonDraftId.bind(this)}
                            defaultLibraryId={LIBRARY_KSZR_BEADANDO_ID}
                            hideBackLink
                        />
                    </Dialog>
                }
                {this.state.showKipDraftEditor &&
                    <Dialog title="KIP Óravázlat" width={1100} height={800}
                        onClose={() => this.setState({ showKipDraftEditor: false })}>
                        <KipDraftEdit
                            id={this.state.courseUser!.kip_draft_id || undefined}
                            onSave={this.onSetKipDraftId.bind(this)}
                            defaultLibraryId={LIBRARY_KSZR_BEADANDO_ID}
                            hideBackLink
                        />
                    </Dialog>
                }
                {this.state.showOccupationDraftEditor &&
                    <Dialog title="Óravázlat" width={1100} height={800}
                        onClose={() => this.setState({ showOccupationDraftEditor: false })}>
                        <OccupationDraftEdit
                            id={this.state.courseUser!.occupation_draft_id || undefined}
                            onSave={this.onSetOccupationDraftId.bind(this)}
                            defaultLibraryId={LIBRARY_KSZR_BEADANDO_ID}
                            hideBackLink
                        />
                    </Dialog>
                }
                <div className="small-12 column">
                    <Breadcrumbs links={[
                        { name: __("KSZR"), path: courseModule.PATH_COURSE_ORGANIZER },
                        { name: __("Minden kurzus"), path: courseModule.PATH_COURSE_ORGANIZER_COURSE_PUBLIC },
                        { name: __("Kurzus infó") },
                    ]} />

                    <div className="row">

                        <div className="small-12 column">

                            <h4>{__("Kurzus infó")}</h4>
                            {
                                this.state.courseUser &&
                                <label className="label" style={{ alignSelf: "center", marginRight: "0.5rem" }}>{__("Állapot")}: {this.state.courseUser.wf_station_name}</label>

                            }
                            <ButtonGroup size={ButtonSize.Normal} align={ButtonAlign.Expanded}>
                                {/* In case of the current user is the course teacher */}
                                {
                                    this.state.trainingType && this.state.trainingType.book_id && (this.state.isTeacher || showStudentData || this.state.isInspector)
                                        ?
                                        <Link className="button expanded eke-general-buttons" to={PATH_PUBLISHED_BOOK + "/" + this.state.trainingType.book_uri_segment + "/"}><i className="fa fa-book" /> {__("Tananyag")}</Link>
                                        : ""
                                }
                                {
                                    course.id && (this.state.isTeacher || hasAnyGroup(me, [Groups.Developer, Groups.KapKszrCourseCoordinator])) &&
                                    <button className="button" onClick={() => this.setState({ workflowDialogOpen: !this.state.workflowDialogOpen })}>
                                        <i className="fa fa-project-diagram" />&nbsp; {__("Folyamat")}  <label className="label" style={{ alignSelf: "center", marginLeft: "0.5rem" }}>{__("Állapot")}: {this.state.workflowStateName}</label>
                                    </button>

                                }
                                {/* In case of the current user is a student who hasn't been applied yet */}
                                {
                                    me && !this.state.isTeacher && hasAnyGroup(me, [Groups.KapKszrParticipantTeacher, Groups.KapKszrCourseTeacher]) &&
                                    <>
                                        {reapplyTransiton &&
                                            <button onClick={this.onApplyCourse.bind(this)} disabled={!reapplyTransiton.has_perm} className="button success expanded eke-general-buttons"><i className="fa fa-book" />
                                                {reapplyTransiton.description || __("Újra jelentkezés")}
                                            </button>
                                        }
                                        {(!this.state.courseUser && !isStudentDataEditable) &&
                                            <button onClick={this.onApplyCourse.bind(this)} className="button success expanded eke-general-buttons"><i className="fa fa-book" /> {__("Jelentkezés")}</button>
                                        }
                                        {/* In case of the current user is a student who is already registered  */}
                                        {(showStudentData && deleteTransiton) &&
                                            <button className="button expanded eke-general-buttons alert" disabled={!deleteTransiton.has_perm} onClick={this.onQuitFromCourse.bind(this)} style={{ float: "right" }} ><i className="fa fa-sign-out-alt" />
                                                {deleteTransiton.description || __("Leiratkozom")}
                                            </button>
                                        }
                                    </>
                                }

                            </ButtonGroup>

                            <div className="row" ref="course_data_card">

                                <Accordion allowAllClosed={true}>
                                    <AccordionItem defaultClosed={true} title={"Részletes leírás"}>
                                        <div dangerouslySetInnerHTML={{ __html: this.state.trainingType!.description_html! }}></div>
                                    </AccordionItem>
                                    {
                                        this.state.trainingType!.fulfillment_conditions_html &&
                                        <AccordionItem defaultClosed={true} title={"Kimeneti követelmények"}>
                                            <div dangerouslySetInnerHTML={{ __html: this.state.trainingType!.fulfillment_conditions_html }}></div>
                                        </AccordionItem>
                                    }
                                    {
                                        this.state.trainingTypeFiles.length > 0 &&
                                        <AccordionItem defaultClosed={true} title={"Prezentációk, segédletek"}>
                                            {
                                                this.state.trainingTypeFiles.map((file: IViewMediaOoFileRecord, index: number) => {
                                                    return <div key={index} className="small-12 large-12 column">
                                                        <a href={"/api/media/file/" + file.sha1} target="_blank" download={file.title}>
                                                            <i className="fa fa-download" /> &nbsp;
                                                {file.title ? file.title : file.displayname}
                                                        </a>
                                                    </div>
                                                })
                                            }
                                        </AccordionItem>
                                    }
                                    {me && (this.state.isTeacher || hasAnyGroup(me,[Groups.Developer, Groups.Admin, Groups.KapKszrCourseCoordinator])) &&
                                    <AccordionItem defaultClosed={true} title={<span><i className="far fa-calendar-check" /> {__("Mappa állapota")}</span>}>
                                        <CourseChecklist isReadOnly={!hasAnyGroup(me,[Groups.Developer, Groups.Admin, Groups.KapKszrCourseCoordinator])} courseRecord={{id:this.state.courseRecord!.id, other_jsonb:this.state.otherJsonB}} />
                                    </AccordionItem>
                                    }
                                </Accordion>

                                <div className="card">
                                    <div className="card-divider">
                                        <p><i className="fa fa-graduation-cap" /> {__("Kurzus alap adatok")}</p>
                                    </div>
                                    <div className="card-section">
                                        <div className="large-12 small-12 columns">
                                            <div className="row">
                                                <div className="large-12 columns">
                                                    <label><strong>{__("Azonosító")}:</strong> {course.regno}</label>
                                                </div>
                                                <div className="large-12 columns">
                                                    <label><strong>{__("Típus")}:</strong> {course.subprogram_title || course.training_type_code}</label>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="large-12 columns">
                                                    <label><strong>{__("Létszám")}: </strong> {course.applied_participants}/{course.max_participants}</label>
                                                </div>
                                            </div>
                                            {(me && hasAnyGroup(me, [Groups.KapKszrCourseTeacher, Groups.Developer, Groups.KapKszrCourseCoordinator]))
                                                ?
                                                <div className="row">
                                                    <div className="large-12 columns">
                                                        <label><strong>{__("Megjegyzés oktatóknak")}: </strong> <p dangerouslySetInnerHTML={{ __html: course.teacher_comment_html || "" }}></p></label>
                                                    </div>
                                                </div>
                                                : ""
                                            }
                                            <div className="row">
                                                <div className="large-12 columns">
                                                    <label><strong>{__("Megjegyzés résztvevőknek")}: </strong> <p dangerouslySetInnerHTML={{ __html: course.participants_comment_html || "" }}></p></label>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="large-12 columns">
                                                    <label className="profile_form_separator">{__("Feladatellátási helyek")}</label>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="large-12 columns">
                                                    <label><strong>{__("Fő feladatellátási hely")}:</strong> {course.institute_site_name}
                                                    </label>
                                                </div>
                                            </div>
                                            {!isOnline && <div className="row">
                                                <div className="large-12 columns">
                                                    <label><strong>{__("Helyszín címe")}: </strong> {course.address}
                                                    </label>
                                                </div>
                                            </div>}
                                            {this.state.sites && this.state.sites.length > 0 &&
                                                <div className="row">
                                                    <table className="table table-bordered">
                                                        <thead>
                                                            <tr>
                                                                <th>{__("Elsődleges")}</th>
                                                                <th>{__("Név")}</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                this.state.sites.map((element, index) => {
                                                                    const prefix = "" + index + (element.id || "_");
                                                                    return <tr id={"sites_" + prefix} key={prefix}>
                                                                        <td key={"curr_site_internal_" + prefix}>
                                                                            <span>{element.is_internal ? __("Elsődleges") : ""}</span>
                                                                        </td>
                                                                        <td key={"curr_site_" + prefix}>
                                                                            <span>{element.om_identifier}/{element.site_identifier} {element.site_name} </span>
                                                                        </td>
                                                                    </tr>
                                                                })
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            }

                                            <div className="row">
                                                <div className="large-12 columns">
                                                    <label className="profile_form_separator">{__("Határidők")}</label>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="large-6 medium-6 small-12 columns">

                                                    <label><strong>{__("Továbbképzés kezdete")}: </strong> {formatDateWithoutTime(course.training_from)}
                                                    </label>

                                                </div>
                                                <div className="large-6 medium-6 small-12 columns">
                                                    <label><strong>{__("Továbbképzés vége")}: </strong> {formatDateWithoutTime(course.training_to)}
                                                    </label>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="large-6 medium-6 small-12 columns">
                                                    <label><strong>{__("Intézményen belüli jelentkezés kezdete")}: </strong> {formatDateWithoutTime(course.apply_after_internal)}
                                                    </label>
                                                </div>
                                                <div className="large-6 medium-6 small-12 columns">
                                                    <label><strong>{__("Intézményen kívüli jelentkezés kezdete")}: </strong> {formatDateWithoutTime(course.apply_after_external)}
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="large-6 medium-6 small-12 columns">
                                                    <label><strong>{__("Jelentkezési határidő")}: </strong> {formatDateWithoutTime(course.application_deadline)}
                                                    </label>
                                                </div>
                                            </div>

                                            {!isOnline && <>
                                            <div className="row">
                                                <div className="large-6 medium-6 small-12 columns">
                                                    <label><strong>{__("Távoktatás kezdési dátum")}: </strong> {formatDateWithoutTime(course.distance_learning_start)}
                                                    </label>
                                                </div>
                                                <div className="large-6 medium-6 small-12 columns">
                                                    <label><strong>{__("Távoktatás befejezési dátum")}: </strong> {formatDateWithoutTime(course.distance_learning_end)}
                                                    </label>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="large-12 columns">
                                                    <label className="profile_form_separator">{__("Kontakt napok")}</label>
                                                </div>
                                            </div>


                                            <div> <table className="table table-bordered">
                                                <thead>
                                                    <tr>
                                                        {showTrainerData && <th>{__("Hivatalos időpont")}</th>}
                                                        <th>{__("Időpont")}</th>
                                                        <th>{__("Helyszín")}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>

                                                    {
                                                        this.state.contactDates.map((element, index) => {

                                                            const prefix = "" + index + (element.id || "_");
                                                            return <tr id={"contact_day_" + prefix} key={prefix}>
                                                                {showTrainerData && <td>
                                                                    {formatDate(element.official_start)} - {element.official_end && element.official_end.substr(11, 5)}
                                                                </td>}
                                                                <td>
                                                                    {formatDate(element.real_start)} - {element.real_end && element.real_end.substr(11, 5)}
                                                                </td>
                                                                <td key={"contact_day_address_" + prefix}>
                                                                    {element.address}
                                                                </td>
                                                            </tr>
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                            </div>
                                            </>}
                                            <div className="row">
                                                <div className="large-12 columns">
                                                    <label className="profile_form_separator">{__("Beadandó")}</label>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="large-6 medium-6 small-12 columns">
                                                    <label><strong>{__("Beadandó határidő")}: </strong> {formatDateWithoutTime(course.submission_deadline)}
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {this.state.courseRecord && this.state.courseRecord.id ? <>
                                <div className="row" ref="course_teacher_invitations_card">
                                    <div className="card">
                                        <div className="card-divider">
                                            <p><i className="fa-fw fas fa-chalkboard-teacher" /> {__("Képző(k)")}</p>
                                        </div>
                                        <div className="card-section">
                                            <div className="row">
                                                <div className="large-12 small-12 columns">
                                                    <label><strong>{__("Kiválasztott képző")}: </strong>
                                                        {course.teacher_fullname+" "}
                                                        {
                                                            !this.state.isTeacher && course.teacher_id && course.id && 
                                                            <>
                                                                <button className="primary" onClick={this.showMessageBox.bind(this)} title={__("Üzenet küldés")}><i className="fas fa-envelope"></i></button>
                                                                <NewMessagePopUp recipientIds={[course.teacher_id!]} isOpen={this.state.messageOpen} tableInfoId={CourseCrud.TABLE_INFO_ID} recordId={course!.id} onClose={()=>{this.setState({messageOpen:false})}} /> 
                                                            </>
                                                        }
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                                {showAdminData || this.state.isTeacher || this.state.isInspector ?
                                    <div className="row" ref="course_students_card">
                                        <div className="card">
                                            <div className="card-divider">
                                                <p><i className="fa fa-users" /> {__("Résztvevő hallgatók")}</p>
                                            </div>
                                            <div className="card-section">
                                                <CourseStudentList currentCourse={this.state.courseRecord} editable={false} isInspector={this.state.isInspector} contactDays={this.state.contactDates} />
                                            </div>
                                        </div>
                                    </div> : ""}
                                {showAdminData || this.state.isTeacher ?
                                    <div className="row" ref="course_files_card">
                                        <div className="card">
                                            <div className="card-divider">
                                                <p><i className="fas fa-folder-open" /> {__("Dokumentumok")}</p>
                                            </div>
                                            <div className="card-section">
                                                <CourseDocumentComponent currentCourse={this.state.courseRecord} contactDates={this.state.contactDates} />
                                            </div>
                                        </div>
                                    </div>
                                    : ""
                                }
                                {/*
                                    (hasGroup(me, Groups.Developer) || me.id == this.state.courseRecord.teacher_id) &&
                                    <div className="row">
                                        <Accordion>
                                            <AccordionItem defaultClosed={true} title={<span><i className="fa fa-chart-bar" /> {__("Kurzus statisztikák")}</span>}>
                                                <CourseStats courseRec={this.state.courseRecord} />
                                            </AccordionItem>
                                        </Accordion>
                                    </div>
                                */}
                                {(this.state.courseUser && showStudentData) &&
                                    <div className="row" ref="course_student_folder_card">
                                        <div className="card">
                                            <div className="card-divider">
                                                <p><i className="fa fa-upload" /> {__("Beadandó")}</p>
                                            </div>
                                            <div className="card-section">
                                                {isStudentDataEditable &&
                                                    <div>
                                                        <button className={"button small eke-general-buttons"}
                                                            onClick={() => this.setState({ showLessonPlanEditor: true })} >
                                                            <i className="fas fa-file-alt" /> {this.state.courseUser!.lesson_draft_id ? __("Óraterv szerkesztése") : __("Új óraterv")}
                                                        </button>
                                                        <button className={"button small eke-general-buttons"}
                                                            onClick={() => this.setState({ showKipDraftEditor: true })} >
                                                            <i className="fas fa-file-alt" /> {this.state.courseUser!.kip_draft_id ? __("KIP óraterv szerkesztése") : __("Új KIP óraterv")}
                                                        </button>
                                                        <button className={"button small eke-general-buttons"}
                                                            onClick={() => this.setState({ showOccupationDraftEditor: true })} >
                                                            <i className="fas fa-file-alt" /> {this.state.courseUser!.occupation_draft_id ? __("Foglalkozásterv szerkesztése") : __("Új foglalkozásterv")}
                                                        </button>
                                                    </div>
                                                }
                                                {
                                                    (this.state.isTeacher || showStudentData) && <div className="kap_course_download_div">
                                                        {this.state.courseUser!.lesson_draft_id && <DownloadAsPdf id={this.state.courseUser!.lesson_draft_id} type="lesson_draft" published={false}>{__("Óraterv")}</DownloadAsPdf>}
                                                        {this.state.courseUser!.kip_draft_id && <DownloadAsPdf id={this.state.courseUser!.kip_draft_id} type="kip_draft" published={false}>{__("KIP Óraterv")}</DownloadAsPdf>}
                                                        {this.state.courseUser!.occupation_draft_id && <DownloadAsPdf id={this.state.courseUser!.occupation_draft_id} type="occupation_draft" published={false}>{__("Foglalkozásterv")}</DownloadAsPdf>}
                                                    </div>
                                                }

                                                <MediaFileChooser
                                                    rootFolderId={this.state.courseUser.oo_folder_id!}
                                                    currentFolderId={this.state.courseUser.oo_folder_id!}
                                                    showHoverPreview={false}
                                                    showLocation={false}
                                                    showFilter={false}
                                                    enabledActions={filePermits}
                                                />

                                                {(this.state.courseUser.wf_station_id == WF_COURSE_USER_STATION_ACTIVE_STUDENT_ID
                                                    ||
                                                    this.state.courseUser.wf_station_id == WF_COURSE_USER_STATION_APPLICATION_CORRECTION_ID
                                                ) ?
                                                    <div>
                                                        <button className={"button small warning eke-general-buttons"}
                                                            onClick={this.submitAssignment.bind(this)} >
                                                            <i className="fa fa-paper-plane" /> {
                                                                this.state.courseUser.wf_station_id == WF_COURSE_USER_STATION_ACTIVE_STUDENT_ID
                                                                    ? __("Beadandó beküldése")
                                                                    : __("Javított beadandó visszaküldése")
                                                            }
                                                        </button></div> : ""
                                                }
                                                {
                                                    this.state.courseUser &&
                                                    <>
                                                        <button className="button" onClick={() => this.setState({ workflowDialogOpen: !this.state.workflowDialogOpen })}>
                                                            <i className="fa fa-project-diagram" />&nbsp; {__("Előzmények")}
                                                        </button>
                                                        <label className="label" style={{ alignSelf: "center", marginRight: "0.5rem" }}>{__("Állapot")}: {this.state.courseUser.wf_station_name}</label>

                                                    </>

                                                }
                                            </div>
                                        </div>
                                    </div>
                                }

                            </>
                                : ""}
                        </div>
                        {
                            this.state.courseRecord && this.state.courseRecord.wf_station_id == WF_COURSE_STATION_CLOSED_ID && me && hasAnyGroup(me, [Groups.Admin, Groups.Developer, Groups.KapKszrParticipantTeacher])
                                ?
                                <Link to={PATH_COURSE_ORGANIZER_SURVEY + "/?survey_id=" + SURVEY_KSZR_PARTICIPANT_ID + "&table_info_id=" + CourseCrud.TABLE_INFO_ID + "&recId=" + this.state.courseRecord.id}>
                                    <div className="button success">
                                        <i className="fa fa-question-circle" /> &nbsp;
                                        Elégedettségi kérdőív kitöltése
                                </div>
                                </Link>
                                : ""
                        }
                    </div>
                </div>
            </div>
        </div >;
    }
}

interface ICourseButtonGroupComponentProps {
    course: IViewKapCourseRecord,
    trainingType?: IViewKapTrainingTypeRecord;
    isApplied: boolean;
    isTeacher: boolean;
    onReload: () => Promise<void>;
}
