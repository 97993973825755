
import * as React from 'react';
import { app } from '@src/index';
import { match } from 'react-router';
import { __, getLanguage } from '@src/translation';

import { Dialog, confirmDialog } from '@src/component/Dialog';
import { BubbleLoader } from 'react-css-loaders';
import InstituteGroupNewsfeedCrud, { InstituteGroupNewsfeedCrudClassProxy, IInstituteGroupNewsfeedRecord } from '@src/framework/crud/usr/InstituteGroupNewsfeedCrud';
import { HTMLTextarea } from '@src/component/HtmlTextarea';

const SECTION_TEMPLATE_TEXT_ID = 1;


type FeedEditProps = {
    id?: number;
    instituteGroupId: number;
    rowIndex : number;
    onSave: (savedRecord: IInstituteGroupNewsfeedRecord, rowIndex: number) => void;
    onDelete: (savedRecord: IInstituteGroupNewsfeedRecord, rowIndex: number) => void;
    onCancel?: ()=>void;
}

type FeedEditState = {
    feed: IInstituteGroupNewsfeedRecord;
    loading: boolean;
}

export class FeedEdit extends React.Component<FeedEditProps, FeedEditState> {

    constructor(props: FeedEditProps) {
        super(props);

        this.state = {                    
            loading: true,
            feed: {
                institute_group_id: this.props.instituteGroupId
            }
        }
    }

    async componentDidMount() {

            this.reloadAsync();
    }

    private onMessageChanged(htmlBody: string) {
        this.state.feed.body_html = htmlBody;
        this.setState({
            feed: this.state.feed,
        })
    }

    private async reloadAsync() {

        try {
            if(this.props.id)
            {
                const feed= (await InstituteGroupNewsfeedCrud.load(this.props.id!)).record;
                this.setState({
                    feed,
                    loading: false
                })
            }
            else
            {
                this.setState({loading: false, feed: {institute_group_id: this.props.instituteGroupId}})
            }

        } catch (e) {
            app.showErrorFromJsonResult(e);
        }
    }

    private async onSave() {
        try {

            let feedRecord : Partial<IInstituteGroupNewsfeedRecord> = (await new InstituteGroupNewsfeedCrud(this.state.feed).put()).record;
            app.showSuccess(__("Sikeres bejegyzés"), __("Beküldve"));

            this.props.onSave(feedRecord, this.props.rowIndex);
            if (this.props.rowIndex==-1) {
                feedRecord = {institute_group_id: this.props.instituteGroupId};
            }
            this.setState({feed: feedRecord, loading: false});
        } catch (e) {
            app.showErrorFromJsonResult(e);
        }
    }

    private async onDelete() {

        if (!await confirmDialog(__("Törlés"), __("Biztosan törölni akarja a listából?"), __("Törlés"))) return;
        try {
            const feedRecord = await InstituteGroupNewsfeedCrud.deleteById(this.props.id!);
            app.showSuccess(__("Bejegyzés törlése"), __("Sikeres"))
            this.props.onDelete(feedRecord, this.props.rowIndex);
            this.setState({feed: feedRecord, loading: false});
        } catch (e) {
            app.showErrorFromJsonResult(e);
        }
    }

    render() {

        if (this.state.loading) {
            return <BubbleLoader />;
        }

        return <div className="post-edit callout">
            {
                this.state.feed.id ? 
                <div className="settings" onClick={this.props.onCancel}><i className="fas fa-times"></i></div> 
                : 
                <div className="header">
                    <h3>Bejegyzés küldése</h3>
                </div>
            }

            <HTMLTextarea autoFocus={true} height={100} value={this.state.feed.body_html || ""} onChange={(e:any) => this.onMessageChanged(e)}></HTMLTextarea>           
           
            <div className="button-group stacked-for-small">       
                    
                    {this.state.feed.id ?                    
                            <button onClick={this.onDelete.bind(this)} className="alert button small">{__("Törlés")}</button>
                    
                    : ""}     
                    <button onClick={this.onSave.bind(this)} className="button small">{__("Közzététel")}</button>         
            </div>
        </div>;
    }
}
