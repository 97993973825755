import { Server } from '../../server/Server';
import View , { registerViewClassProxy, ViewClassProxy } from '../View';
import { IListParams } from '@framework/crud/Crud';

export interface IViewSecUserCrudAuditByUserRecord {
    /**
     * Táblázat infó azonosító
     *
     * Táblázat infó azonosító
     */
    id?: number;
    /**
     * Séma név
     *
     * Séma név
     */
    schema_name?: string|null;
    /**
     * Táblázat név
     *
     * Táblázat név
     */
    table_name?: string|null;
    /**
     * Kijelzett név
     *
     * A táblázat neve magyar nyelven
     */
    table_display_name?: string|null;
    /**
     * Leírás
     *
     * A táblázat leírása magyar nyelven
     */
    table_description?: string|null;
    /**
     * Felhasználó azonosító
     *
     * Felhasználó azonosító
     */
    sec_user_id?: number|null;
    /**
     * Bejelentkezési név
     *
     * Bejelentkezési név
     */
    login_name?: string|null;
    /**
     * E-mail
     *
     * E-mail
     */
    email?: string|null;
    /**
     * Személy azonosító
     *
     * Személy azonosító
     */
    person_id?: number|null;
    /**
     * Teljes név
     *
     * Teljes név
     */
    fullname?: string|null;
    /**
     * C
     *
     * C
     */
    can_create?: boolean|null;
    /**
     * R
     *
     * R
     */
    can_read?: boolean|null;
    /**
     * U
     *
     * U
     */
    can_update?: boolean|null;
    /**
     * D
     *
     * D
     */
    can_delete?: boolean|null;
}

/**
 *  meta.view_sec_user_crud_audit_by_user - Felhasználó CRUD jog audit felhasználó szerint
 */

export default class ViewSecUserCrudAuditByUser extends View<IViewSecUserCrudAuditByUserRecord> {
    public static VIEW_INFO_ID = 2019101502;

    public static getSchemaNameForClass(): string { return 'meta'; }
    public static getViewNameForClass(): string { return 'view_sec_user_crud_audit_by_user'; }
    public static getTableNameForClass(): string|null { return 'null'; }

    public static load: (id: number, aServer ?: Server) => Promise<ViewSecUserCrudAuditByUser>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<IViewSecUserCrudAuditByUserRecord[]>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class ViewSecUserCrudAuditByUserClassProxy extends ViewClassProxy<IViewSecUserCrudAuditByUserRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const viewSecUserCrudAuditByUserClassProxy = new ViewSecUserCrudAuditByUserClassProxy(ViewSecUserCrudAuditByUser);
registerViewClassProxy(viewSecUserCrudAuditByUserClassProxy);

