import { Server } from '../../server/Server';
import Crud , { registerCrudClassProxy, IAsTextResult, IListParams, CrudClassProxy, ISearchTextParams, ISearchTextResult } from '../Crud';

export interface IExerciseSeriesTopicRecord {
    /**
     * Globálisan egyedi azonosító.
     * 
     * Ha új rekordot akarsz létrehozni put() metódussal, akkor hagyd üresen.
     * upsert() hívásnál figyelmen kívül van hagyva az értéke.
     *
     */
    id ?: number;
    /**
     * Sorrend
     */
    no?: number /*integer*/|null;
    /**
     * Rekord létrehozás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel létrehozásakor.
     */
    creation_time?: string /*timestamp?*/;
    /**
     * Ki hozta létre
     *
     * NOT NULL -> sys.sec_user ON DELETE noaction
     *
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozó felhasználóra hivatkozik.
     */
    creation_user_id?: number;
    /**
     * Melyik session-ből jött létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozáshoz használt munkamenetre hivatkozik.
     */
    creation_session_id?: number|null;
    /**
     * Utolsó módosítás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel módosításakor.
     */
    modification_time?: string /*timestamp?*/|null;
    /**
     * Ki módosította utoljára
     *
     * -> sys.sec_user ON DELETE noaction
     *
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosító felhasználóra hivatkozik.
     */
    modification_user_id?: number|null;
    /**
     * Melyik session-ből módosították utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosításhoz használt munkamenetre hivatkozik.
     */
    modification_session_id?: number|null;
    /**
     * Aktív
     *
     * Logikai törléshez használt mező.
     */
    is_active?: boolean;
    /**
     * Feladatlista
     *
     * NOT NULL -> exc.exercise_series ON DELETE cascade
     *
     */
    exercise_series_id?: number;
    /**
     * Cím
     */
    title?: string;
    /**
     * Súly
     */
    weight?: number /*integer*/;
    /**
     * Kérdések max. száma
     */
    max_questions?: number /*integer*/;
}

/**
 *  exc.exercise_series_topic - Adaptív feladatsor témakör
 *
 *  Idősoros táblázat, ami az összes tétel összes korábbi verzióját eltárolja.
 */

export default class ExerciseSeriesTopicCrud extends Crud<IExerciseSeriesTopicRecord> {
    public static TABLE_INFO_ID = 3089288801;
    public static getSchemaNameForClass() { return 'exc'; }
    public static getTableNameForClass() { return 'exercise_series_topic'; }

    public static load: (id: number, aServer ?: Server) => Promise<ExerciseSeriesTopicCrud>;
    public static asText: (id: number, includeRecord : boolean, aServer ?: Server) => Promise<IAsTextResult<IExerciseSeriesTopicRecord>>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<IExerciseSeriesTopicRecord[]>;
    public static searchText: (params: ISearchTextParams, aServer ?: Server) => Promise<ISearchTextResult<IExerciseSeriesTopicRecord>[]>;
    public static deleteById: (id: number, aServer ?: Server) => Promise<IExerciseSeriesTopicRecord>;
    public static unDeleteById: (id: number, aServer ?: Server) => Promise<IExerciseSeriesTopicRecord>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class ExerciseSeriesTopicCrudClassProxy extends CrudClassProxy<IExerciseSeriesTopicRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const exerciseSeriesTopicCrudClassProxy = new ExerciseSeriesTopicCrudClassProxy(ExerciseSeriesTopicCrud);
registerCrudClassProxy(exerciseSeriesTopicCrudClassProxy);
