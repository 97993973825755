
import * as React from 'react';
import { SurveyInputType, Question } from './SurveyConstants';

/**
 * ===========================
 * DO NOT TRANSLATE THIS FILE!
 * ===========================
 */


export const NewQuestionsForStudents: Question[] = [
    {
        id: 1,
        name: <>Nemed?</>,
        type: SurveyInputType.RADIO,

        answers: [
            {
                id: 1,
                name: "Lány",
            },
            {
                id: 2,
                name: "Fiú",
            }
        ],
    },
    {
        id: 2,
        name: <>Hány rendszerben kellett regisztrálnod az otthon tanulás miatt? (pl. LearningApps, Google Classroom, stb)</>,
        type: SurveyInputType.TEXT,
        answers: [
            {
                id: 1,
                name: "Válasz",
            }
        ],
    },
    {
        id: 3,
        name: <>Mely modulokat használod a leggyakrabban ezen a felületen (NKP)? (több is megjelölhető)</>,
        type: SurveyInputType.CHECKBOX,
        answers: [
            {
                id: 1,
                name: "Okostankönyv",
            },
            {
                id: 2,
                name: "Tartalomtár",
            },
            {
                id: 3,
                name: "Médiatár",
            },
            {
                id: 4,
                name: "Feladattár",
            },
            {
                id: 5,
                name: "Kereső",
            },
            {
                id: 6,
                name: "Tanulócsoport",
            }
        ],
    },
    {
        id: 4,
        name: <>Van olyan modul, ami nem volt felsorolva, de használod? Melyek ezek?</>,
        type: SurveyInputType.TEXT,
        optional: true,
        answers: [
            {
                id: 1,
                name: "Válasz",
            }
        ],
    },
    {
        id: 5,
        name: <>Milyen gyakran használtatok digitális oktatási eszközöket a vírushelyzet előtt?</>,
        type: SurveyInputType.RADIO,

        answers: [
            {
                id: 1,
                name: "Egyáltalán nem használtunk",
            },
            {
                id: 2,
                name: "Nagyon ritkán",
            },
            {
                id: 3,
                name: "Havonta egyszer-kétszer",
            },
            {
                id: 4,
                name: "Hetente többször",
            },
            {
                id: 5,
                name: "Szinte minden órán",
            }
        ],
    },
    {
        id: 6,
        name: <>Jobbnak találtad ezt a portált, mint a többi, általad ismert digitális eszközt?</>,
        type: SurveyInputType.RADIO,

        answers: [
            {
                id: 1,
                name: "Igen",
            },
            {
                id: 2,
                name: "Ugyanolyan",
            },
            {
                id: 3,
                name: "Nem",
            }
        ],
    },
    {
        id: 7,
        name: <>Az előző kérdésre adott válaszod indokolhatod!</>,
        type: SurveyInputType.TEXT,
        optional: true,
        answers: [
            {
                id: 1,
                name: "Válasz",
            }
        ],
    },
    {
        id: 8,
        name: <>Kérjük jelöld be, hogy az alábbi szópárok esetén melyik kifejezés a jellemzőbb és milyen mértékben az NKP portálra!</>,
        type: SurveyInputType.RANGE,
        rangeSize: 7,
        answers: [
            {
                id: 1,
                name: "akadályozó",
            },
            {
                id: 7,
                name: "támogató",
            }
        ],
    },
    {
        id: 9,
        type: SurveyInputType.RANGE,
        rangeSize: 7,
        answers: [
            {
                id: 1,
                name: "bonyolult",
            },
            {
                id: 7,
                name: "könnyű",
            }
        ],
    },
    {
        id: 10,
        type: SurveyInputType.RANGE,
        rangeSize: 7,
        answers: [
            {
                id: 1,
                name: "nem hatékony",
            },
            {
                id: 7,
                name: "hatékony",
            }
        ],
    },
    {
        id: 11,
        type: SurveyInputType.RANGE,
        rangeSize: 7,
        answers: [
            {
                id: 1,
                name: "átlátható",
            },
            {
                id: 7,
                name: "összezavaró",
            }
        ],
    },
    {
        id: 12,
        type: SurveyInputType.RANGE,
        rangeSize: 7,
        answers: [
            {
                id: 1,
                name: "unalmas",
            },
            {
                id: 7,
                name: "izgalmas",
            }
        ],
    },
    {
        id: 13,
        type: SurveyInputType.RANGE,
        rangeSize: 7,
        answers: [
            {
                id: 1,
                name: "nem érdekes",
            },
            {
                id: 7,
                name: "érdekes",
            }
        ],
    },
    {
        id: 14,
        type: SurveyInputType.RANGE,
        rangeSize: 7,
        answers: [
            {
                id: 1,
                name: "szokványos",
            },
            {
                id: 7,
                name: "formabontó",
            }
        ],
    },
    {
        id: 15,
        type: SurveyInputType.RANGE,
        rangeSize: 7,
        answers: [
            {
                id: 1,
                name: "újító",
            },
            {
                id: 7,
                name: "átlagos",
            }
        ],
    },
    {
        id: 16,
        type: SurveyInputType.RANGE,
        rangeSize: 7,
        answers: [
            {
                id: 1,
                name: "könnyen tanulható",
            },
            {
                id: 7,
                name: "nehezen tanulható",
            }
        ],
    },
    {
        id: 17,
        type: SurveyInputType.RANGE,
        rangeSize: 7,
        answers: [
            {
                id: 1,
                name: "motiváló",
            },
            {
                id: 7,
                name: "elkedvetlenítő",
            }
        ],
    },
    {
        id: 18,
        type: SurveyInputType.RANGE,
        rangeSize: 7,
        answers: [
            {
                id: 1,
                name: "barátságos",
            },
            {
                id: 7,
                name: "barátságtalan",
            }
        ],
    },
    {
        id: 19,
        type: SurveyInputType.RANGE,
        rangeSize: 7,
        answers: [
            {
                id: 1,
                name: "nem érthető",
            },
            {
                id: 7,
                name: "érthető",
            }
        ],
    },

]
