import './style.css';
import { ExerciseBaseClass, AnswerElement } from '@src/component/exercise/models/ExerciseBaseClass';
import { AExerciseEngine } from '../../models/AExerciseEngine';
import { EKEFillTableDnDServer } from './EKEFillTableDnDServer';


export interface FillTableData extends ExerciseBaseClass {
    answers: string[];
    options: AnswerElement[];
    headers: string[];
    rows: Row[];
    fix_elements: Row[];
    keywords: string;
    caseSensitive: boolean;
    first_row_header: boolean;
    first_column_header: boolean;
    infinite_elements: boolean;
};

type Row = {
    data: any[];
};

type FillTableUserSolution = {
    answer: number[];
    fullmatch: boolean;
}

export class EKEFillTableDnDExerciseEngine extends AExerciseEngine {

    private dropDivList: HTMLDivElement[];
    private answerList: string[];
    private answerDivList: HTMLElement[];
    private interaction: boolean;
    private infinite_elements: boolean = false;


    initExercise(params: ExerciseParams): void {
        super.initExercise(params);
        this.exercise = params.exercise;
        this.dropDivList = [];
        //this.answerList = exercise.answers;
        this.answerList = [];
        let exercise: FillTableData = this.exercise;

        if (!exercise || !exercise.options)
            return;



        exercise.options.forEach(element => {
            this.answerList.push(element.type == "image" ? element.image : element.text);
        });
        this.answerDivList = [];
        this.interaction = false;

        this.root.classList.add("eke-filltable-dnd");
        this.root.style.position = "relative";

        let gridDiv = this.root.appendChild(document.createElement("div"));
        gridDiv.classList.add("exercise-wrapper");

        var randomgroup = Math.floor(Math.random() * 1000);
        var td_counter = randomgroup;

        this.setIllustration(exercise, gridDiv);

        var tablediv = gridDiv.appendChild(document.createElement("div"));
        tablediv.classList.add("table-scroll");
        var table = tablediv.appendChild(document.createElement("table"));

        if (exercise.headers && exercise.headers.length > 0) {
            var headers = table.appendChild(document.createElement("tr"));
            for (var index = 0; index < exercise.headers.length; index++) {
                var header = headers.appendChild(document.createElement("th"));
                header.innerText = exercise.headers[index];
                header.classList.add("table-header");
            }
        }

        for (var indexR = 0; indexR < exercise.fix_elements.length; indexR++) {
            var tr = table.appendChild(document.createElement("tr"));
            for (var indexC = 0; indexC < exercise.fix_elements[indexR].data.length; indexC++) {
                var td = tr.appendChild(document.createElement("td"));

                if ((indexR == 0 && exercise.first_row_header)
                    || (indexC == 0 && exercise.first_column_header)) {
                    td.classList.add("table-header");
                } else {
                    td.classList.add("table-td");
                }

                td.setAttribute('id', "table-td" + td_counter);
                td.setAttribute('tabindex', '0');
                td_counter++;
                if (exercise.fix_elements[indexR].data[indexC].is_answer) {
                    //td.addEventListener('click', this.click, false);
                    td.classList.add("drp-area");
                    this.dropDivList.push(td);

                    ($(td) as any).droppable({
                        accept: ".answer-div",
                        classes: {
                            "ui-droppable-active": "ui-state-active",
                            "ui-droppable-hover": "ui-state-hover"
                        },
                        drop: function (event: any, ui: any) {
                            $(this)
                                .addClass("ui-state-highlight")
                        },
                        tolerance: "pointer"
                    });

                } else {
                    let curr_ans = exercise.fix_elements[indexR].data[indexC].answer;
                    if (curr_ans.type == "image") {
                        AExerciseEngine.displayAnswer(td, curr_ans, this.is_accessible, ["question-img"], this)
                    }
                    else {
                        AExerciseEngine.displayAnswer(td, curr_ans, this.is_accessible, [], this)
                    }
                }
            }
        }

        let answerWrapper = gridDiv.appendChild(document.createElement("div"));
        answerWrapper.classList.add('answer-wrap');

        var answerContainer = answerWrapper.appendChild(document.createElement("div"));
        //answerContainer.classList.add("row");
        answerContainer.classList.add("answer-container");
        //answerContainer.classList.add("image-answer-container");
        this.dropDivList.push(answerContainer);

        //for (var index = 0; index < exercise.answers.length; index++)
        for (var index = 0; index < exercise.options.length; index++) {
            //let answerdiv;
            let answerdiv: HTMLElement = answerContainer.appendChild(document.createElement("div"));
            answerdiv.classList.add("answer-div");
            let curr_option = exercise.options[index];
            if (curr_option.type == "image") {
                answerdiv.setAttribute('data-type','image');
                AExerciseEngine.displayAnswer(answerdiv, curr_option, this.is_accessible, ["answer-img", "answer-div-img-max-width"], this);
            }
            else if (exercise.options[index].type == "sound") {
                answerdiv.setAttribute('data-type','sound');
                answerdiv.classList.add("string-answer-div", "answer-div", "button", "clear", "cell");
                AExerciseEngine.displayAnswer(answerdiv, curr_option, this.is_accessible, [], this);
            }
            else {
                answerdiv.setAttribute('data-type','text');
                //answerdiv = answersdiv.appendChild(document.createElement("div"));
                AExerciseEngine.displayAnswer(answerdiv, curr_option, this.is_accessible, [], this);
            }

            answerdiv.setAttribute("draggable", "true");
            answerdiv.setAttribute("id", exercise.options[index].type == "image" ? exercise.options[index].image : exercise.options[index].text);
            answerdiv.setAttribute("data-index", index + "");

            //answerdiv.onmousedown = this.mouseDown.bind(this);
            //answerdiv.onmouseup = this.mouseUp.bind(this);
        
            this.answerDivList.push(answerdiv);

            /* Create draggable elements */
            if (!this.isReplay) {
                ($(answerdiv) as any).draggable({
                    start: this.dragstart.bind(this),
                    stop: this.drop.bind(this),
                    containment: this.root,
                    scrollSensitivity: 50,
                    helper: 'clone',
                    appendTo: this.root,
                    cursorAt: {left: 30, top: 15}
                });
            }

            if (exercise.options[index].type == "text") AExerciseEngine.shrinkAndGrow(answerdiv);
        }

        /* Click-to-click simulation */
        if (!this.isReplay) {
            AExerciseEngine.simulateDrag({
                draggableItems: this.answerDivList,
                clickArea: this.root,
                excludedItemClasses: ['fa-play', 'fa-stop', 'icon-shrinkgrow']
            });
        }


        let answerControls = answerWrapper.appendChild(document.createElement('div'));
        answerControls.classList.add("answer-controls");
        let answerLeftButton = answerControls.appendChild(document.createElement("button"));
        answerLeftButton.classList.add("control", "answer-control-left");
        let answerRightButton = answerControls.appendChild(document.createElement("button"));
        answerRightButton.classList.add("control", "answer-control-right");
        answerRightButton.addEventListener("click", AExerciseEngine.xMove.bind(this, answerContainer, 'right'), false);
        answerLeftButton.addEventListener("click", AExerciseEngine.xMove.bind(this, answerContainer, 'left'), false);

    }


    getUserSolution(): FillTableUserSolution {
        var result: any[] = [];
        // the last element of the dropDivList is the answersdiv
        for (var index = 0; index < this.dropDivList.length - 1; index++) {
            if (this.dropDivList[index].childNodes[0] != null) {
                var element = this.dropDivList[index].childNodes[0] as HTMLElement;
                /*var res = this.answerList.indexOf(element.innerText);
                if (this.imageanswers) {
                    res = this.answerList.indexOf(element.id);
                }*/
                var res = this.answerList.indexOf(element.id);
                result.push(res);
            } else result.push(null);
        }
        let solution = { answer: result, fullmatch: true };
        return solution;
    }

    receiveEvaluation(evaluated: Evaluated): void {
        if (evaluated.success) {
            for (var index = 0; index < this.dropDivList.length; index++) {
                let htmlelement = this.dropDivList[index].childNodes[0] as HTMLElement;
                AExerciseEngine.removeEvalStyle(htmlelement);
                if (htmlelement && !htmlelement.parentElement!.classList.contains("image-answer-container")) {
                    htmlelement.classList.add("exe-engine-check-correct");
                } else if (htmlelement && htmlelement.parentElement!.classList.contains("image-answer-container")) {
                    for (let y = 0; y < this.dropDivList[index].childNodes.length; y++) {
                        let element = this.dropDivList[index].childNodes[y] as HTMLElement;
                        AExerciseEngine.removeEvalStyle(element);
                        element.classList.add("exe-engine-check-correct");
                    }
                }
            }
        } else {
            if (evaluated.solution != null) {
                for (var index = 0; index < evaluated.solution.length; index++) {
                    if (this.dropDivList[index].childNodes.length > 0) {
                        let htmlElement: any = this.dropDivList[index].childNodes[0] as HTMLDivElement;
                        AExerciseEngine.removeEvalStyle(htmlElement);
                        let mathjaxmatch: boolean = false;
                        //Check if we have a latex mathematical expression
                        var splittedAnswer = this.answerList[evaluated.solution[index]];
                        if (splittedAnswer.charAt(0) == "$" && splittedAnswer.slice(-1) == "$") {
                            splittedAnswer = splittedAnswer.slice(1, -1);
                            var splittedContent = this.dropDivList[index].textContent;
                            if (splittedContent) splittedContent = splittedContent.slice(-splittedAnswer.length);
                            if (splittedAnswer == splittedContent) mathjaxmatch = true;
                        }

                        if ((this.answerList.indexOf(htmlElement.innerText) == (evaluated.solution[index]))
                            || (this.answerList.indexOf(htmlElement.id) === (evaluated.solution[index]))
                            || this.dropDivList[index].textContent == this.answerList[evaluated.solution[index]]
                            || htmlElement.id == this.answerList[evaluated.solution[index]]
                            || mathjaxmatch) {
                            htmlElement.classList.add("exe-engine-check-correct");
                        } else {
                            if (!this.isReplay) {
                                htmlElement.classList.remove("dropped");
                                this.dropDivList[index].innerText = "";
                                //this.dropDivList[this.dropDivList.length - 1].appendChild(htmlElement);
                                let ansCont:HTMLElement|null = this.root.querySelector(".answer-container");
                                if(ansCont) AExerciseEngine.moveElementBack(htmlElement, ansCont, this.root);
                            }
                            if (!this.isSNIexc)
                                htmlElement.classList.add('exe-engine-check-wrong');
                        }
                    }
                }
            } else {
                //not implemented
            }
        }
        //check for non dropped elements:
        if (evaluated.success)
        for (let index = 0; index < this.answerDivList.length; index++) {
            let htmlElement: any = this.answerDivList[index] as HTMLElement;
            if (!htmlElement.classList.contains("dropped")) {
                if (evaluated.solution.includes(this.answerList.indexOf(htmlElement.innerText))
                    || evaluated.solution.includes(this.answerList.indexOf(htmlElement.id))
                    || evaluated.solution.includes(htmlElement.id)) {
                    //non redundant element dropped, add wrong
                    if (!this.isSNIexc)
                        htmlElement.classList.add("exe-engine-check-wrong");
                }
                else {
                    //non dragged redundant element, add correct
                    htmlElement.classList.add("exe-engine-check-correct");
                }
            }
        }
    }

    showCorrectSolution(solution: any): void {
        this.removePlaceHolders(this.root);
        this.interaction = false;
        for (var index = 0; index < solution.length; index++) {
            if (solution[index] == null && this.isReplay)
                continue;
            let htmlElement = this.answerDivList[solution[index]];
            this.dropDivList[index].appendChild(htmlElement);
            AExerciseEngine.removeEvalStyle(htmlElement);
            htmlElement.classList.add("dropped");
            if (!this.isReplay)
                htmlElement.classList.add("eke-engine-show-correct-bg");

        }
        for (let index = 0; index < this.answerDivList.length; index++) {
            AExerciseEngine.removeEvalStyle(this.answerDivList[index]);
            if (!this.answerDivList[index].classList.contains('eke-engine-show-correct-bg') && !this.answerDivList[index].classList.contains('exe-engine-correct-bg') && !this.isReplay)
                this.answerDivList[index].classList.add("eke-engine-show-correct-bg");
        }
    }

    isUserReady(): boolean {
        return this.interaction;
    }

    showHelp(solution: any): void {
        this.showCorrectSolution(solution);
    }

    drop(ev: any) {
        ev.target.style.zIndex = "1";
        ev.target.classList.remove('draggable-original');
        AExerciseEngine.drop(ev, this.dropDivList, this.infinite_elements, this);

        if (ev.target.parentElement.classList.contains('eke-filltable-dnd'))
            $(ev.target).appendTo(this.root.getElementsByClassName('image-answer-container')[0]);

        this.interaction = true;
        if (this.isSNIexc) this.SNIEvaluation(EKEFillTableDnDServer);
    }

    mouseDown(ev: any) {
        if (ev.target && ev.target.tagName == 'I')
            return;

        if (ev.target && (ev.target.parentElement.classList.contains('table-td') || ev.target.parentElement.parentElement.classList.contains('table-td'))) {
            var target = ev.target.parentElement.classList.contains('table-td') ? ev.target : ev.target.parentElement;
            var container = $(ev.target.closest('div.eke-filltable-dnd'));

            var top = $(target).offset()!.top - container.offset()!.top;
            var left = $(target).offset()!.left - container.offset()!.left;

            target.style.position = "absolute";
            target.style.top = top + "px";
            target.style.left = left + "px";
        }
    }

    mouseUp(ev: any) {
        if (ev.target && (ev.target.classList.contains('answer-div') || ev.target.parentElement.classList.contains('answer-div'))) {
            var target = ev.target.classList.contains('answer-div') ? ev.target : ev.target.parentElement;
            target.style.position = "relative";
            target.style.top = "0px";
            target.style.left = "0px";
        }
    }

    dragstart(ev: any, ui:any) {
        if (ev.target) {
            AExerciseEngine.removeEvalStyle(ev.target);
            ev.target.classList.add('draggable-original');
            ev.target.style.zIndex = "10";

            let parentDiv: HTMLElement = (ev.target.parentElement as HTMLElement);
            if (parentDiv.classList.contains("answer-container")) {
                if (this.simple_style || this.isSNIexc) {
                    this.cloneAnswerElement(ev.target, parentDiv);
                }
            }

            /*
            if (ev.target.parentElement.classList.contains('table-td')) {
                $(ev.target).appendTo($(ev.target.closest('div.eke-filltable-dnd')));
            }
            */

        }
    }
}