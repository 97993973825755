import * as React from 'react';
import { Link, match } from 'react-router-dom';
import { app } from '@src/index';
import { BubbleLoader } from 'react-css-loaders';
import * as PublicServer from '@src/server/PublicServer'
import { PATH_EXERCISE_SERIES_PUB_VIEW, PATH_EXERCISE_PUB_VIEW } from '@src/Routes';
import * as _ from 'lodash';
import { Accordion, AccordionItem } from '@src/component/ui/Accordion';
import ExerciseCrud from '@src/framework/crud/exc/ExerciseCrud';
import { getContentDetails, ContentDetails } from '../filemanager/ContentUtils';
import ExerciseSeriesCrud from '@src/framework/crud/exc/ExerciseSeriesCrud';
import { __ } from '@src/translation';

var saved = {
  filters: [],
  sorted: [],
  pageSize: 10,
  page: 0,
}

declare var MathJax: any;
class ProgressionPageState {
  progressionStats?: PublicServer.ProgressionElements;
  exeAndSeriesItems: any[];
  filters: any[];
  pageSize: number;
  page: number;
  count: number;
  sorted: any[];
  loading: boolean;
}

export type ProgressionPageProps = {
  match?: match<{ kbItemId?: string }>;
  /**Ha csak egy adott könyvben lévő előrehaladást akarunk lekérni */
  bookId?: number;
}

export default class ProgressionPage extends React.Component<ProgressionPageProps, ProgressionPageState> {
  constructor(props: any) {
    super(props);
    this.state = {
      loading: false,
      exeAndSeriesItems: [],
      pageSize: saved.pageSize,
      page: saved.page,
      count: 0,
      sorted: saved.sorted,
      filters: saved.filters,
    };
  }


  componentDidUpdate() {
    try {
      MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
    } catch (e) {
      console.log(e);
    }
  }
  componentDidMount() {
    this.asyncReload();
    ($(document) as any).foundation();
  }
  private asyncReload = async () => {

    try {
      this.setState({
        loading: true
      })
      let progressionStats = await PublicServer.getProgressionStats(this.props.bookId);
      let exeAndSeriesItems = [];
      if (progressionStats) {
        exeAndSeriesItems = progressionStats.exercises.concat(progressionStats.exerciseSeries);
      }

      for (var exeItem of exeAndSeriesItems) {
        {/*TODO checking */ }
        const url = "/" + __("tankonyv") + "/" + exeItem.bookurisegment + "/" + exeItem.lessonurisegment + "#section-" + exeItem.sectionid;
        let typeName = ""
        let baseUrl = ""
        if (exeItem.exerciseid) {
          typeName = "[" + __("Feladat") + "]";
          baseUrl = PATH_EXERCISE_PUB_VIEW + "/" + exeItem.exerciseid + "/";

        }
        if (exeItem.exerciseseriesid) {
          typeName = "[" + __("Feladatsor") + "]";
          baseUrl = PATH_EXERCISE_SERIES_PUB_VIEW + "/" + exeItem.exerciseseriesid + "/";
        }
        exeItem["exName"] = exeItem.exname;
        exeItem["grade_id"] = exeItem.grade_id;
        exeItem["bookName"] = exeItem.bookname;
        exeItem["typeName"] = typeName;
        exeItem["baseUrl"] = baseUrl;
        exeItem["bookUrl"] = <Link to={url}>{exeItem.bookName + " / " + exeItem.chaptername + " / " + exeItem.lessonname + " / " + exeItem.exname}</Link>
      }

      this.setState({
        exeAndSeriesItems,
        count: progressionStats ? progressionStats.exerciseSeriesCount + progressionStats.exerciseCount : 0,
        loading: false
      });

    } catch (error) {
      app.showErrorFromJsonResult(error);
    }

  }

  render() {

    const groupedRecords = _.groupBy(this.state.exeAndSeriesItems, "bookname");

    if (this.state.exeAndSeriesItems && !this.state.loading) {
      return (<div className="exercise-list-page row exerciseList-maincomponent">
        <br />
        <h4>{__("Előrehaladás")}</h4>

        <div className="small-12 medium-12">
          {
            Object.keys(groupedRecords).length === 0 &&
            <div className="callout">
              <h5>{__("Ahhoz, hogy itt adatok jelenjenek meg, legalább egy feladatot meg kell oldani!")}</h5>
            </div>
          }
          <Accordion>
            {
              Object.keys(groupedRecords).map((bookName, index) => {
                const values = groupedRecords[bookName];
                const title = bookName + (values.length ? " " + __("( {n} feladat)", { n: values.length }) + " " : "");

                return <AccordionItem key={index} title={title}>
                  <div >
                    {
                      values.map(e => {
                        let recid;
                        let tableinfo: number;
                        if (e.exerciseid) { recid = e.exerciseid; tableinfo = ExerciseCrud.TABLE_INFO_ID }
                        if (e.exerciseseriesid) { recid = e.exerciseseriesid; tableinfo = ExerciseSeriesCrud.TABLE_INFO_ID }

                        return <div className="row" style={{marginBottom:"10px"}}>
                          <div className="column large-2 medium-3 small-3">
                            <DetailImage tableInfoId={tableinfo!} recId={recid} />
                          </div>                          
                          <div className="column large-4 medium-9 small-8">
                            {<Link to={e.baseUrl}>{e.typeName + " - " + e.exName}</Link>}
                          </div>
                          <div className="column large-4 medium-9 small-12">
                            {e.bookUrl}
                          </div>
                          <div className="column large-2 medium-3 small-12">
                            <p style={{ fontWeight: "bold" }}> {__("Eredmény:")}</p>
                            <div className="row">
                              <div className="column large-12 small-6">
                                <p style={{ fontSize: "1rem" }}> {e.best_total_points + "/" + (e.best_earned_points).toFixed(2) + " pont"}</p>
                              </div>
                              <div className="column large-12 small-6">
                                <span className="badge" style={{ fontSize: "1rem" }}> {((e.best_earned_points / e.best_total_points) * 100).toFixed(2) + "%"}</span>
                              </div>
                            </div>
                          </div>
                          <hr />
                        </div>
                      }
                      )
                    }
                  </div>
                </AccordionItem>
              })
            }
          </Accordion>

        </div>
      </div>
      );

    } else {
      return <div><BubbleLoader /></div>
    }
  }
};

export class DetailImage extends React.Component<{ tableInfoId: number, recId: number }, { details?: ContentDetails }> {

  async componentDidMount() {
    const details = await getContentDetails(this.props.tableInfoId, this.props.recId);
    this.setState({
      details
    })
  }

  render() {
    if (!this.state || !this.state.details) return null;
    const objectFit = this.state.details.thumbnailUrl && this.state.details.thumbnailUrl.endsWith(".svg") ? "contain" : "cover";

    return <div className="__list-drag-handle" style={{ height: "70px", width: "100px", alignSelf: "center", padding: "4px" }}>
      {
        this.state.details.thumbnailUrl &&
        <img style={{ height: "100%", width: "100%", objectFit: objectFit }} src={this.state.details.thumbnailUrl} onError={(e) => e.currentTarget.style.display = "none"} />
      }
    </div>;
  }
}
