import * as React from 'react';
import { __ } from '@src/translation';
import ButtonGroup, { ButtonSize, ButtonAlign } from '@src/component/ui/ButtonGroup';
import { me, hasAnyGroup, Groups } from '@src/framework/server/Auth';
import { IViewKapCourseUserRecord } from '@src/framework/view/kap/ViewKapCourseUser';
import { IViewKapCourseRecord } from "@src/framework/view/kap/ViewKapCourse";
import { IViewKapTrainingTypeRecord } from "@src/framework/view/kap/ViewKapTrainingType";
import { PATH_PUBLISHED_BOOK } from '@src/Routes';
import { Link } from 'react-router-dom';
import { WF_STATION_KSZR_COURSE_USER_STATION_KERELEM_BEADVA_ID, WF_STATION_KSZR_COURSE_USER_STATION_KEPZESBOL_TOROLVE_ID, WF_TYPE_KAP_COURSE_USER_ID } from '@src/Const';
import WfAPI, { IViewTransitionTypeRecordWithPerm } from '@src/framework/wf/WfAPI';
import { Accordion, AccordionItem } from '@src/component/ui/Accordion';
import { IViewMediaOoFileRecord } from '@src/framework/view/media/ViewMediaOoFile';
import { IViewKapCourseInstituteSiteRecord } from '@src/framework/view/kap/ViewKapCourseInstituteSite';
import { formatDateWithoutTime, formatDate } from "@src/Util";
import { IViewWfWorkflowRecord } from '@src/framework/view/wf/ViewWfWorkflow';
import { CourseDetailsMain } from './CourseDetailsMain';
import { app } from "@src/index";
import { ICourseContactDayRecord } from '@src/framework/crud/kap/CourseContactDayCrud';
import CourseUserCrud, { ICourseUserRecord } from '@src/framework/crud/kap/CourseUserCrud';
import { WorkflowDialog } from '@src/component/wf/WorkflowDialog';
import CourseCrud from '@src/framework/crud/kap/CourseCrud';
import { IViewTransitionTypeRecord } from '@src/framework/view/wf/ViewTransitionType';
import obtainServer from '@src/framework/server/Server';
import { PermissionCache, WfDeleteTransitionInfo } from '@src/framework/security/PermissionCache';
import NewMessagePopUp from '@src/module/messengerModule/components/NewMessagePopUp';
import { CourseChecklist } from '../../adminPages/CourseChecklist';

/*
select 'export const WF_COURSE_USER_STATION_??_ID = ' || id || '; // ' || name
from wf.station s where wf_type_id = 25406740433 order by no asc
*/
const WF_COURSE_USER_STATION_APPLICATION_REQUESTED_ID = 25406740434; // Kérelem beadva
const WF_COURSE_USER_STATION_APPLICATION_REJECTED_ID = 25406740437; // Kérelem visszautasítva
const WF_COURSE_USER_STATION_ACTIVE_STUDENT_ID = 25406740438; // Képzés engedélyezve
const WF_COURSE_USER_STATION_APPLICATION_CORRECTION_ID = 25503820779; // Beadandó javítása
const WF_COURSE_USER_STATION_APPLICATION_UNDER_REVIEW_ID = 25406740439; // Beadandó feladat elbírálás alatt
const WF_COURSE_USER_STATION_APPLICATION_SUCCESSFULLY_CLOSED_ID = 25406740440; // Beadandó feladat sikeresen lezárva
const WF_COURSE_USER_STATION_APPLICATION_FAILED_ID = 25406740441; // Beadandó feladat sikertelenül lezárva
const WF_COURSE_USER_STATION_APPLICATION_NOT_FULFILLED_ID = 25408617064; // Nem teljesítette
const WF_COURSE_USER_STATION_STUDENT_REMOVED_ID = 25406740442; // Képzésből törölve

type CourseDetailsDataPageProps = {
    courseRecord: IViewKapCourseRecord;
    trainingType: IViewKapTrainingTypeRecord;
    contactDates: ICourseContactDayRecord[];
    courseUser: IViewKapCourseUserRecord | null;
    isTeacher: boolean;
    isInspector: boolean;
    possibleCourseUserTransitions: IViewTransitionTypeRecordWithPerm[];
    trainingTypeFiles: IViewMediaOoFileRecord[];
    sites: IViewKapCourseInstituteSiteRecord[];
    otherJsonB: any,
    onReload: () => void
}

type CourseDetailsDataPageState = {
    workflowDialogOpen: boolean;
    messageOpen: boolean;
}

export class CourseDetailsDataPage extends React.Component<CourseDetailsDataPageProps, CourseDetailsDataPageState> {

    constructor(props: CourseDetailsDataPageProps) {
        super(props);

        this.state = {
            workflowDialogOpen: false,
            messageOpen: false,
        }
    }

    private async onApplyCourse(event: any) {

        try {
            if (me && this.props.courseRecord) {
                // Megnézzük, hogy van-e neki korábbi törölt jelentkezése.
                if (this.props.courseUser) {

                    let goodTransitonTypes = this.props.possibleCourseUserTransitions.filter((trans) => {
                        return trans.dst_station_id == WF_STATION_KSZR_COURSE_USER_STATION_KERELEM_BEADVA_ID;
                    });

                    if (!goodTransitonTypes.length) {
                        app.showError("Hiba", 'Ön törölve lett a képzésből, és ebben az állapotban nem jelentkezhet újra.');
                        return;
                    }
                    const reapplyTransitonTypeId = goodTransitonTypes[0].id!;
                    const workflow: IViewWfWorkflowRecord = await CourseDetailsMain.wfAPI.makeTransition(
                        this.props.courseUser.wf_workflow_id!,
                        reapplyTransitonTypeId,
                        "Újra jelentkezés"
                    );
                    app.showWarning(
                        "Figyelmeztetés",
                        "Ön korábban már jelentkezett erre a képzésre, de a jelentkezését törölték. " +
                        "Az újbóli jelentkezését befogadtuk, egy képzésszervező fogja elbírálni. " +
                        "Az elbírálás eredményéről értesítést fog kapni."
                    );
                } else {
                    let newApplicant: ICourseUserRecord = {}
                    newApplicant.course_id = this.props.courseRecord.id;
                    newApplicant.sec_user_id = me.id
                    await (new CourseUserCrud(newApplicant).put());
                    app.showSuccess("Köszönjük", "Ön sikeresen jelentkezett a kurzusra");
                }
                this.props.onReload();
            }

        } catch (error) {
            app.showErrorFromJsonResult(error);
        };
    }

    private async onQuitFromCourse(event: any) {
        try {
            if (me) {

                let registeredUser = this.props.courseUser;
                if (registeredUser) {

                    if (registeredUser && registeredUser.id) {

                        const stationId = registeredUser.wf_station_id;
                        if (stationId) {
                            // Folyamatban részt vevő feladat, folyamat átmenettel töröljük.
                            let deleteTransitionInfo: WfDeleteTransitionInfo = await PermissionCache.loadWfDelTrans(WF_TYPE_KAP_COURSE_USER_ID);
                            let possibleTransitions = this.getDeleteTransitionTypesForStation(stationId, deleteTransitionInfo);
                            if (possibleTransitions.length > 1) {
                                // TODO: mi lenne ha itt egyből megnyitnánk ?
                                app.showError(
                                    __("Hiba"),
                                    __('Egynél több célállapot elérhető.') + " " +
                                    __('A törlés végrehajtásához nyissa meg a folyamat dialógus ablakot.')
                                );
                                return;
                            }
                            const transitionType: IViewTransitionTypeRecord = possibleTransitions[0];
                            let justification = prompt(__('Kérem írja be az indoklást'), '');
                            let wfApi = new WfAPI(obtainServer());
                            try {
                                let workflowAfter: IViewWfWorkflowRecord = await wfApi.makeTransition(
                                    registeredUser!.wf_workflow_id!,
                                    transitionType.id!,
                                    justification
                                );
                                this.props.onReload();
                                app.showSuccess(__("Lejelentkezés kurzusról"), __("Sikeres"))

                            } catch (error) {
                                app.showErrorFromJsonResult(error);
                            };

                        }
                    }
                }

            }
        }
        catch (error) {
            app.showErrorFromJsonResult(error);
        };
        // const newMenuItem = (await new MenuItemCrud(newApplicant).put()).record;
    }

    private getDeleteTransitionTypesForStation = (stationId: number, deleteTransitionInfo: WfDeleteTransitionInfo): IViewTransitionTypeRecord[] => {
        let deleteTransitions = [];
        let tt = undefined;
        if (deleteTransitionInfo) {
            tt = deleteTransitionInfo.activeTransitionTypes;
        }

        if (tt) {
            for (let i = 0; i < tt.length; i++) {
                let transitionType = tt[i];
                if (transitionType.src_station_id == stationId) {
                    if (transitionType.dst_is_deleted!) {
                        deleteTransitions.push(transitionType);
                    }
                }
            }
        }
        return deleteTransitions;

    }

    render() {
        if (!me) return null;

        const course = this.props.courseRecord;
        let isOnline = this.props.trainingType != undefined && this.props.trainingType.is_online;

        let showStudentData = (this.props.courseUser != undefined
            && this.props.courseUser.wf_station_id != WF_COURSE_USER_STATION_APPLICATION_REQUESTED_ID
            && this.props.courseUser.wf_station_id != WF_COURSE_USER_STATION_APPLICATION_REJECTED_ID
            && this.props.courseUser.wf_station_id != WF_COURSE_USER_STATION_STUDENT_REMOVED_ID);

        const reapplyTransitonTypes = this.props.possibleCourseUserTransitions.filter((trans) => {
            return trans.dst_station_id == WF_STATION_KSZR_COURSE_USER_STATION_KERELEM_BEADVA_ID;
        });

        const reapplyTransiton = reapplyTransitonTypes.length ? reapplyTransitonTypes[0] : null;
        let deleteTransitonTypes = this.props.possibleCourseUserTransitions.filter((trans) => {
            return trans.dst_station_id == WF_STATION_KSZR_COURSE_USER_STATION_KEPZESBOL_TOROLVE_ID;
        });

        const deleteTransiton = deleteTransitonTypes.length ? deleteTransitonTypes[0] : null;

        let isStudentDataEditable = this.props.courseUser && (
            this.props.courseUser.wf_station_id == WF_COURSE_USER_STATION_ACTIVE_STUDENT_ID ||
            this.props.courseUser.wf_station_id == WF_COURSE_USER_STATION_APPLICATION_CORRECTION_ID);

        let showTrainerData = !this.props.courseUser && hasAnyGroup(me, [Groups.KapKszrCourseCoordinator, Groups.KapKszrCourseTeacher, Groups.Developer]);

        return <div className="row">

            <div className="small-12 column">

                <h4>{__("Kurzus infó")}</h4>
                {
                    this.props.courseUser &&
                    <label className="label" style={{ alignSelf: "center", marginRight: "0.5rem" }}>{__("Állapot")}: {this.props.courseUser.wf_station_name}</label>

                }
                <ButtonGroup size={ButtonSize.Normal} align={ButtonAlign.Expanded}>
                    {/* In case of the current user is the course teacher */}
                    {
                        this.props.trainingType && (this.props.isTeacher || showStudentData || this.props.isInspector)
                            ?
                            <Link className="button expanded eke-general-buttons" to={PATH_PUBLISHED_BOOK + "/" + this.props.trainingType.book_uri_segment + "/"}><i className="fa fa-book" /> {__("Tananyag")}</Link>
                            : ""
                    }
                    {
                        course.id && (this.props.isTeacher || hasAnyGroup(me, [Groups.Developer, Groups.KapKszrCourseCoordinator])) &&
                        <button className="button" onClick={() => this.setState({ workflowDialogOpen: !this.state.workflowDialogOpen })}>
                            <i className="fa fa-project-diagram" />&nbsp; {__("Folyamat")}  <label className="label" style={{ alignSelf: "center", marginLeft: "0.5rem" }}>{__("Állapot")}: {this.props.courseRecord.wf_station_name}</label>
                        </button>

                    }
                    {/* In case of the current user is a student who hasn't been applied yet */}
                    {
                        me && !this.props.isTeacher && hasAnyGroup(me, [Groups.KapKszrParticipantTeacher, Groups.KapKszrCourseTeacher]) &&
                        <>
                            {reapplyTransiton &&
                                <button onClick={this.onApplyCourse.bind(this)} disabled={!reapplyTransiton.has_perm} className="button success expanded eke-general-buttons"><i className="fa fa-book" />
                                    {reapplyTransiton.description || __("Újra jelentkezés")}
                                </button>
                            }
                            {(!this.props.courseUser && !isStudentDataEditable) &&
                                <button onClick={this.onApplyCourse.bind(this)} className="button success expanded eke-general-buttons"><i className="fa fa-book" /> {__("Jelentkezés")}</button>
                            }
                            {/* In case of the current user is a student who is already registered  */}
                            {(showStudentData && deleteTransiton) &&
                                <button className="button expanded eke-general-buttons alert" disabled={!deleteTransiton.has_perm} onClick={this.onQuitFromCourse.bind(this)} style={{ float: "right" }} ><i className="fa fa-sign-out-alt" />
                                    {deleteTransiton.description || __("Leiratkozom")}
                                </button>
                            }
                        </>
                    }

                </ButtonGroup>

                <div className="row" ref="course_data_card">

                    <Accordion allowAllClosed={true}>
                        <AccordionItem defaultClosed={true} title={"Részletes leírás"}>
                            <div dangerouslySetInnerHTML={{ __html: this.props.trainingType!.description_html! }}></div>
                        </AccordionItem>
                        {
                            this.props.trainingType!.fulfillment_conditions_html &&
                            <AccordionItem defaultClosed={true} title={"Kimeneti követelmények"}>
                                <div dangerouslySetInnerHTML={{ __html: this.props.trainingType!.fulfillment_conditions_html }}></div>
                            </AccordionItem>
                        }
                        {
                            this.props.trainingTypeFiles.length > 0 &&
                            <AccordionItem defaultClosed={true} title={"Prezentációk, segédletek"}>
                                {
                                    this.props.trainingTypeFiles.map((file: IViewMediaOoFileRecord, index: number) => {
                                        return <div key={index} className="small-12 large-12 column">
                                            <a href={"/api/media/file/" + file.sha1} target="_blank" download={file.title}>
                                                <i className="fa fa-download" /> &nbsp;
                                    {file.title ? file.title : file.displayname}
                                            </a>
                                        </div>
                                    })
                                }
                            </AccordionItem>
                        }
                        {me && (this.props.isTeacher || hasAnyGroup(me, [Groups.Developer, Groups.Admin, Groups.KapKszrCourseCoordinator])) &&
                            <AccordionItem defaultClosed={true} title={<span><i className="far fa-calendar-check" /> {__("Mappa állapota")}</span>}>
                                <CourseChecklist isReadOnly={!hasAnyGroup(me, [Groups.Developer, Groups.Admin, Groups.KapKszrCourseCoordinator])} courseRecord={{ id: this.props.courseRecord!.id, other_jsonb: this.props.otherJsonB }} />
                            </AccordionItem>
                        }
                    </Accordion>

                    <div className="card">
                        <div className="card-divider">
                            <p><i className="fa fa-graduation-cap" /> {__("Kurzus alap adatok")}</p>
                        </div>
                        <div className="card-section">
                            <div className="large-12 small-12 columns">
                                <div className="row">
                                    <div className="large-12 columns">
                                        <label><strong>{__("Azonosító")}:</strong> {course.regno}</label>
                                    </div>
                                    <div className="large-12 columns">
                                        <label><strong>{__("Típus")}:</strong> {course.subprogram_title || course.training_type_code}</label>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="large-12 columns">
                                        <label><strong>{__("Létszám")}: </strong> {course.applied_participants}/{course.max_participants}</label>
                                    </div>
                                </div>
                                {(me && hasAnyGroup(me, [Groups.KapKszrCourseTeacher, Groups.Developer, Groups.KapKszrCourseCoordinator]))
                                    ?
                                    <div className="row">
                                        <div className="large-12 columns">
                                            <label><strong>{__("Megjegyzés oktatóknak")}: </strong> <p dangerouslySetInnerHTML={{ __html: course.teacher_comment_html || "" }}></p></label>
                                        </div>
                                    </div>
                                    : ""
                                }
                                <div className="row">
                                    <div className="large-12 columns">
                                        <label><strong>{__("Megjegyzés résztvevőknek")}: </strong> <p dangerouslySetInnerHTML={{ __html: course.participants_comment_html || "" }}></p></label>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="large-12 columns">
                                        <label className="profile_form_separator">{__("Feladatellátási helyek")}</label>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="large-12 columns">
                                        <label><strong>{__("Fő feladatellátási hely")}:</strong> {course.institute_site_name}
                                        </label>
                                    </div>
                                </div>
                                {!isOnline && <div className="row">
                                    <div className="large-12 columns">
                                        <label><strong>{__("Helyszín címe")}: </strong> {course.address}
                                        </label>
                                    </div>
                                </div>}
                                {this.props.sites && this.props.sites.length > 0 &&
                                    <div className="row">
                                        <table className="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th>{__("Elsődleges")}</th>
                                                    <th>{__("Név")}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.props.sites.map((element, index) => {
                                                        const prefix = "" + index + (element.id || "_");
                                                        return <tr id={"sites_" + prefix} key={prefix}>
                                                            <td key={"curr_site_internal_" + prefix}>
                                                                <span>{element.is_internal ? __("Elsődleges") : ""}</span>
                                                            </td>
                                                            <td key={"curr_site_" + prefix}>
                                                                <span>{element.om_identifier}/{element.site_identifier} {element.site_name} </span>
                                                            </td>
                                                        </tr>
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                }

                                <div className="row">
                                    <div className="large-12 columns">
                                        <label className="profile_form_separator">{__("Határidők")}</label>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="large-6 medium-6 small-12 columns">

                                        <label><strong>{__("Továbbképzés kezdete")}: </strong> {formatDateWithoutTime(course.training_from)}
                                        </label>

                                    </div>
                                    <div className="large-6 medium-6 small-12 columns">
                                        <label><strong>{__("Továbbképzés vége")}: </strong> {formatDateWithoutTime(course.training_to)}
                                        </label>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="large-6 medium-6 small-12 columns">
                                        <label><strong>{__("Intézményen belüli jelentkezés kezdete")}: </strong> {formatDateWithoutTime(course.apply_after_internal)}
                                        </label>
                                    </div>
                                    <div className="large-6 medium-6 small-12 columns">
                                        <label><strong>{__("Intézményen kívüli jelentkezés kezdete")}: </strong> {formatDateWithoutTime(course.apply_after_external)}
                                        </label>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="large-6 medium-6 small-12 columns">
                                        <label><strong>{__("Jelentkezési határidő")}: </strong> {formatDateWithoutTime(course.application_deadline)}
                                        </label>
                                    </div>
                                </div>

                                {!isOnline && <>
                                    <div className="row">
                                        <div className="large-6 medium-6 small-12 columns">
                                            <label><strong>{__("Távoktatás kezdési dátum")}: </strong> {formatDateWithoutTime(course.distance_learning_start)}
                                            </label>
                                        </div>
                                        <div className="large-6 medium-6 small-12 columns">
                                            <label><strong>{__("Távoktatás befejezési dátum")}: </strong> {formatDateWithoutTime(course.distance_learning_end)}
                                            </label>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="large-12 columns">
                                            <label className="profile_form_separator">{__("Kontakt napok")}</label>
                                        </div>
                                    </div>


                                    <div> <table className="table table-bordered">
                                        <thead>
                                            <tr>
                                                {showTrainerData && <th>{__("Hivatalos időpont")}</th>}
                                                <th>{__("Időpont")}</th>
                                                <th>{__("Helyszín")}</th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            {
                                                this.props.contactDates.map((element, index) => {

                                                    const prefix = "" + index + (element.id || "_");
                                                    return <tr id={"contact_day_" + prefix} key={prefix}>
                                                        {showTrainerData && <td>
                                                            {formatDate(element.official_start)} - {element.official_end && element.official_end.substr(11, 5)}
                                                        </td>}
                                                        <td>
                                                            {formatDate(element.real_start)} - {element.real_end && element.real_end.substr(11, 5)}
                                                        </td>
                                                        <td key={"contact_day_address_" + prefix}>
                                                            {element.address}
                                                        </td>
                                                    </tr>
                                                })
                                            }
                                        </tbody>
                                    </table>
                                    </div>
                                </>}
                                <div className="row">
                                    <div className="large-12 columns">
                                        <label className="profile_form_separator">{__("Beadandó")}</label>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="large-6 medium-6 small-12 columns">
                                        <label><strong>{__("Beadandó határidő")}: </strong> {formatDateWithoutTime(course.submission_deadline)}
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {course.teacher_id &&
                    <div className="row" ref="course_data_card">
                        <div className="card">
                            <div className="card-divider">
                                <p><i className="fa-fw fas fa-chalkboard-teacher" /> {__("Képző")}</p>
                            </div>
                            <div className="card-section">
                                <div className="row">
                                    <div className="large-12 small-12 columns">
                                        <label><strong>{__("Képző")}: </strong>
                                            {course.teacher_fullname}
                                            {
                                                course.teacher_id && course.id &&
                                                <>
                                                    &nbsp;<button className="primary" onClick={() => this.setState({ messageOpen: true })} title={__("Üzenet küldés")}><i className="fas fa-envelope"></i></button>
                                                    <NewMessagePopUp recipientIds={[course.teacher_id]} isOpen={this.state.messageOpen} tableInfoId={CourseCrud.TABLE_INFO_ID} recordId={course.id} onClose={() => { this.setState({ messageOpen: false }) }} />
                                                </>
                                            }
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                }
            </div>

            <WorkflowDialog
                open={this.state.workflowDialogOpen}
                displayName={__("Kurzus")}
                tableInfoId={CourseCrud.TABLE_INFO_ID}
                recId={course.id!}
                onClose={() => this.setState({ workflowDialogOpen: false })}
                onTransition={this.props.onReload}
                showHistory={isStudentDataEditable || undefined}
                autoPublishOnClosedStation={true}
            />

        </div>
    }

}
