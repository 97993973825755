import * as React from 'react';
import { match } from 'react-router';
import OoFileCrud, { IOoFileRecord } from '@src/framework/crud/media/OoFileCrud';
//import { getCollaboraUrl, CollaboraUrl } from '@src/server/PublicServer';
import { BubbleLoader } from 'react-css-loaders';
import { app } from "@src/index";
import PermissionPage from '@src/framework/PermissionPage';
import { __ } from '@src/translation';

export class ContentPermissionPage extends React.Component<ContentPageProps, any> {
    render() {
        return <PermissionPage requirements={{}}>
            <ContentPage {...this.props}/>
        </PermissionPage>
    }
}

type ContentPageProps = {
    match: match<{fileId: string}>
}

type ContentPageState = {
    file?: IOoFileRecord;
    // collaboraUrl?: CollaboraUrl | null;
}

export class ContentPage extends React.Component<ContentPageProps, ContentPageState> {

    private oldForm: HTMLSpanElement;
    
    private resizeEventListener:any;

    constructor(props: ContentPageProps) {
        super(props);

        this.state = {

        }
    }

    componentDidMount() {
        this.reloadAsync();
    }

    componentDidUpdate(prevProps: ContentPageProps) {
        if (prevProps.match.params.fileId != this.props.match.params.fileId) {
            this.reloadAsync();
        }
        this.updateIframe();
    }

    componentWillUnmount() {
        if (this.resizeEventListener) {
            window.removeEventListener('resize', this.resizeEventListener);
        }
    }

    private updateIframe() {
        const officeForm = this.refs.officeForm as HTMLFormElement;

        if (!officeForm) {
            return;
        }
        if (officeForm == this.oldForm) {
            return;
        }
        this.oldForm = officeForm;

        officeForm.submit();

        this.setIframeHeight();

        this.resizeEventListener = window.addEventListener('resize', this.setIframeHeight.bind(this));
    }

    private setIframeHeight() {
        const iframe = this.refs.iframe as HTMLFormElement;
        if (!iframe) return;

        iframe.height = window.innerHeight - iframe.offsetTop - 5;
    }

    private async reloadAsync() {
        try {
            const file = (await OoFileCrud.load(Number(this.props.match.params.fileId))).record;
            this.setState({file });
        } catch(e) {
            app.showErrorFromJsonResult(e);
        }
    }

    render() {
        if (!this.state.file) {
            return <BubbleLoader />;
        }

        const ext = (this.state.file.ext ? this.state.file.ext.replace(".", "").toLowerCase() : "");
       return <div>{__("A kollaboratív szerkesztő jelenleg nem elérhető.")}</div>

    }
}
