import { Server } from '../../server/Server';
import View , { registerViewClassProxy, ViewClassProxy } from '../View';
import { IListParams } from '@framework/crud/Crud';

export interface IViewBookRecord {
    /**
     * Azonosító
     *
     * Azonosító (pótkulcs)
     */
    id?: number;
    /**
     * Név
     */
    name?: string;
    /**
     * Könyvtár
     */
    library_id?: number;
    /**
     * Évfolyam
     */
    grade_id?: number;
    /**
     * Tantárgy
     */
    subject_id?: number;
    /**
     * A könyv gyökér könyvtára.
     */
    oo_folder_id?: number|null;
    /**
     * URL rész
     *
     * URL path (útvonal) szegmense
     */
    uri_segment?: string;
    /**
     * Szlogen
     *
     * A könyv szlogenje
     */
    slogan?: string|null;
    /**
     * Műveltségi terület
     */
    literacy_field_id?: number|null;
    /**
     * SNI
     *
     * Sajátos nevelési igényűek számára készített könyv
     */
    is_sni?: boolean;
    /**
     * Nyelv
     *
     * A könyv nyelve
     */
    lang_id?: number;
    /**
     * Könyv megjelenítési mód
     */
    display_mode_id?: number;
    /**
     * Site
     */
    site_id?: number;
    /**
     * Intézmény típus
     */
    institute_type_id?: number|null;
    /**
     * Könyvtár
     *
     * Könyvtár
     */
    library_name?: string|null;
    /**
     * Évfolyam
     *
     * A feladatsort milyen évfolyamnak szánták
     */
    grade_name?: string|null;
    /**
     * Tantárgy
     *
     * A feladatsort milyen tantárgyhoz tartozik
     */
    subject_name?: string|null;
    /**
     * Tudományterület
     *
     * Tudományterület
     */
    literacy_field_name?: string|null;
    /**
     * Kijelzési mód neve
     *
     * Kijelzési mód neve
     */
    display_mode_title?: string|null;
    /**
     * Intézmény típus név
     *
     * Intézmény típus név
     */
    institute_type_title?: string|null;
}

/**
 *  doc_pub.view_book - Könyv
 */

export default class ViewBook extends View<IViewBookRecord> {
    public static VIEW_INFO_ID = 2020031701;

    public static getSchemaNameForClass(): string { return 'doc_pub'; }
    public static getViewNameForClass(): string { return 'view_book'; }
    public static getTableNameForClass(): string|null { return 'book'; }

    public static canCache(): boolean {
        return true;
    }

    public static load: (id: number, aServer ?: Server) => Promise<ViewBook>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<IViewBookRecord[]>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class ViewBookClassProxy extends ViewClassProxy<IViewBookRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const viewBookClassProxy = new ViewBookClassProxy(ViewBook);
registerViewClassProxy(viewBookClassProxy);

