import { Server } from '../../server/Server';
import View , { registerViewClassProxy, ViewClassProxy } from '../View';
import { IListParams } from '@framework/crud/Crud';

export interface IViewKapInstituteSiteTeacherRecord {
    /**
     * Azonosító
     *
     * Azonosító
     */
    id?: number;
    /**
     * Aktív
     *
     * Aktív
     */
    is_active?: boolean|null;
    /**
     * Tanár azonosító
     *
     * Tanár azonosító
     */
    sec_user_id?: number|null;
    /**
     * Feladatellátási hely azonossító
     *
     * Feladatellátási hely azonossító
     */
    institute_site_id?: number|null;
    /**
     * Tanár aktív
     *
     * Tanár aktív
     */
    teacher_is_active?: boolean|null;
    /**
     * Tanár bejelentkezési név
     *
     * Tanár bejelentkezési név
     */
    teacher_login_name?: string|null;
    /**
     * Tanár email
     *
     * Tanár email
     */
    teacher_email?: string|null;
    /**
     * Tanár teljes név
     *
     * Tanár teljes név
     */
    teacher_full_name?: string|null;
    /**
     * Van KAP tanúsítványa
     *
     * Van KAP tanúsítványa
     */
    teacher_has_kap_cert?: boolean|null;
    /**
     * Tanár-ellátási hely állapot azonosító
     *
     * Tanár-ellátási hely állapot azonosító
     */
    teacher_status_id?: number|null;
    /**
     * Tanár-ellátási hely állapot név
     *
     * Tanár-ellátási hely állapot név
     */
    teacher_status_title?: string|null;
    /**
     * Feladatellátási hely aktív
     *
     * Feladatellátási hely aktív
     */
    institute_site_is_active?: boolean|null;
    /**
     * Feladatellátási hely név
     *
     * Feladatellátási hely név
     */
    institute_site_name?: string|null;
    /**
     * Feladatellátási hely bevezetés dátuma
     *
     * Feladatellátási hely bevezetés dátuma
     */
    kap_introduction_date?: string /*date?*/|null;
    /**
     * Feladatellátási hely település azonosító
     *
     * Feladatellátási hely település azonosító
     */
    city_id?: number|null;
    /**
     * Feladatellátási hely település név
     *
     * Feladatellátási hely település név
     */
    city_name?: string|null;
    /**
     * Feladatellátási hely irányítószám
     *
     * Feladatellátási hely irányítószám
     */
    zipcode?: string|null;
    /**
     * Feladatellátási hely cím
     *
     * Feladatellátási hely cím
     */
    institute_site_address?: string|null;
    /**
     * Feladatellátási hely külföldi cím
     *
     * Feladatellátási hely külföldi cím
     */
    institute_site_foreign_address?: string|null;
    /**
     * Feladatellátási hely küldöldi
     *
     * Feladatellátási hely küldöldi
     */
    institute_site_is_foreign?: boolean|null;
    /**
     * Feladatellátási hely azonosító
     *
     * Feladatellátási hely azonosító
     */
    institute_site_identifier?: string|null;
    /**
     * Intézmény OM azonosító
     *
     * Intézmény OM azonosító
     */
    institute_om_identifier?: string|null;
    /**
     * Intézmény azonosító
     *
     * Intézmény azonosító
     */
    institute_id?: number|null;
    /**
     * Intézmény név
     *
     * Intézmény név
     */
    institute_name?: string|null;
}

/**
 *  sys.view_kap_institute_site_teacher - Feladatellátási helyek tanárai
 *
 *  Feladatellátási helyek tanárai
 */

export default class ViewKapInstituteSiteTeacher extends View<IViewKapInstituteSiteTeacherRecord> {
    public static VIEW_INFO_ID = 2019091002;

    public static getSchemaNameForClass(): string { return 'sys'; }
    public static getViewNameForClass(): string { return 'view_kap_institute_site_teacher'; }
    public static getTableNameForClass(): string|null { return 'null'; }

    public static load: (id: number, aServer ?: Server) => Promise<ViewKapInstituteSiteTeacher>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<IViewKapInstituteSiteTeacherRecord[]>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class ViewKapInstituteSiteTeacherClassProxy extends ViewClassProxy<IViewKapInstituteSiteTeacherRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const viewKapInstituteSiteTeacherClassProxy = new ViewKapInstituteSiteTeacherClassProxy(ViewKapInstituteSiteTeacher);
registerViewClassProxy(viewKapInstituteSiteTeacherClassProxy);

