import * as React from 'react';
import InstituteSiteCrud, { instituteSiteCrudClassProxy } from '@src/framework/crud/sys/InstituteSiteCrud';
import LookupEdit from '@src/framework/forms/lookupedit';
import { __ } from '@src/translation';
import { app } from '@src/index';
import InstituteGroupCrud from '@src/framework/crud/sys/InstituteGroupCrud';
import ViewInstituteGroupMember, { IViewInstituteGroupMemberRecord } from '@src/framework/view/sys/ViewInstituteGroupMember';
import { me } from '@src/framework/server/Auth';
import { IViewInstituteGroupRecord } from '@src/framework/view/sys/ViewInstituteGroup';
import { BubbleLoader } from 'react-css-loaders';
import { Link } from 'react-router-dom';
import { classroomModule } from './classroomModule';
import ViewInstituteSiteTeacher, { viewInstituteSiteTeacherClassProxy, IViewInstituteSiteTeacherRecord } from '@src/framework/view/sys/ViewInstituteSiteTeacher';
import { INSTITUTE_SITE_STATUS_INVITATION_SENT, INSTITUTE_SITE_STATUS_ACTIVE } from './ClassroomUsersPage';
import { PATH_PROFILE } from '@src/Routes';
import { hasGroup, Groups } from '@src/framework/server/Auth'
import { INSTITUTE_SITE_TEACHER_STATUS_ACTIVE_ID } from '@src/component/profile/ProfilePageComponent';
import { confirmDialog, Dialog, alertDialog } from '@src/component/Dialog';
import InstituteGroupMemberCrud from '@src/framework/crud/sys/InstituteGroupMemberCrud';
import InstituteSiteTeacherCrud from '@src/framework/crud/sys/InstituteSiteTeacherCrud';
import { InstituteGroupMemberStates } from '@src/component/institute/InstituteGroupMemberList';
import { Popover } from '@src/component/ui/Popover';
import { viewInstituteSiteClassProxy } from '@src/framework/view/sys/ViewInstituteSite';

type ClassroomListPageState = {
    instituteSiteId?: number;
    newGroupName: string;

    groups: IViewInstituteGroupMemberRecord[];
    loaded: boolean;

    sites: IViewInstituteSiteTeacherRecord[];

    showGroupMenu?: number;
    createGroupDialogOpen: boolean;
    saving: boolean;
}

export class ClassroomListPage extends React.Component<any, ClassroomListPageState> {

    private menuIcons: {} = {};

    constructor(props: any) {
        super(props);

        this.state = {
            newGroupName: "",
            groups: [],
            loaded: false,
            sites: [],
            createGroupDialogOpen: false,
            saving: false,
        }
    }

    componentDidMount() {
        this.reloadAsync();
    }

    private async reloadAsync() {
        if (!me) return;

        const isTeacher : boolean = hasGroup(me, Groups.Teacher);
        const max_age =  isTeacher?undefined:10;

        try {
            const groups = await ViewInstituteGroupMember.list({
                filter: {
                    sec_user_id: me.id,
                    is_active: true
                },
                order_by: [{name: "status_id"}, {name: "group_name"}],
                max_age
            });

            const sites = await ViewInstituteSiteTeacher.list({
                filter: {
                    sec_user_id: me.id,
                    status_id: INSTITUTE_SITE_TEACHER_STATUS_ACTIVE_ID
                },
                max_age
            });

            this.setState({
                groups,
                loaded: true,
                sites
            })
        } catch(e) {
            app.showErrorFromJsonResult(e);
        }
    }

    private async onCreateGroup() {
        try {
            const alreadyExists = this.state.groups.some(g => g.institute_site_id === this.state.instituteSiteId && g.group_name === this.state.newGroupName);

            this.setState({
                saving: true
            });

            if (alreadyExists) {
                alertDialog("Ilyen nevű csoport már létezik!");
                return;
            }

            await new InstituteGroupCrud({
                institute_site_id: this.state.instituteSiteId,
                name: this.state.newGroupName
            }).put();
            
            app.showSuccess("Tanulócsoport létrehozva", "Tanulócsoport létrehozva");
            this.reloadAsync();

            this.setState({
                instituteSiteId: undefined,
                newGroupName: "",
                createGroupDialogOpen: false,
            })

        } catch(e) {
            app.showErrorFromJsonResult(e);
        } finally {
            this.setState({
                saving: false
            });
        }
    }

    private async onAddInstitute() {
        try {
            if (!me) return;

            await new InstituteSiteTeacherCrud({
                institute_site_id: this.state.instituteSiteId,
                sec_user_id: me.id,
                status_id: INSTITUTE_SITE_TEACHER_STATUS_ACTIVE_ID
            }).put();
            
            app.showSuccess("Iskola hozzáadva", "Iskola hozzáadva");
            this.reloadAsync();

            this.setState({
                instituteSiteId: undefined
            })

        } catch(e) {
            app.showErrorFromJsonResult(e);
        }
    }

    private async onAcceptInvitation(group: IViewInstituteGroupMemberRecord) {
        if (!await confirmDialog("Megerősítés", "Biztos, hogy belép a csoportba?", "")) return;

        try {
            await new InstituteGroupMemberCrud({
                id: group.id,
                status_id: INSTITUTE_SITE_STATUS_ACTIVE
            }).put();

            this.reloadAsync();

        } catch(e) {
            app.showErrorFromJsonResult(e);
        }
    }

    private async setMemberStatus(group: IViewInstituteGroupMemberRecord, newStatusId: number, confirmTitle: string, confirmText: string) {
        this.setState({showGroupMenu: undefined});

        if (!await confirmDialog(confirmTitle, confirmText)) return;

        try {
            await new InstituteGroupMemberCrud({
                id: group.id, 
                status_id: newStatusId,
            }).put();

            this.reloadAsync();
            
        } catch(e) {
            app.showErrorFromJsonResult(e);
        }
    }
    
    private async deleteGroup(group: IViewInstituteGroupMemberRecord) {
        this.setState({showGroupMenu: undefined});

        if (!await confirmDialog(__("Törlés") ,__("Biztos benne, hogy törli a csoportot?"))) return;

        try {
            await new InstituteGroupCrud({
                id: group.institute_group_id, 
                is_active: false
            }).put();

            this.reloadAsync();
            
        } catch(e) {
            app.showErrorFromJsonResult(e);
        }
    }

    render() {
        if (!me) return null;

        if (!this.state.loaded) {
            return <BubbleLoader />;
        }

        return <div className="content-wrapper">
            
            <h5>Tanulócsoportjaim:</h5>
           
            <div className="column small-12" style={{marginTop: "1em"}}>
                <button className="button" onClick={() => this.setState({createGroupDialogOpen: true})}>
                    <i className="fa fa-plus" /> {__("Új tanulócsoport létrehozása")}
                </button>
            </div>

            {this.renderGroups("Új tanulócsoport meghívások", group => group.is_active! && group.status_id === InstituteGroupMemberStates.INVITATION_SENT_ID)}
            
            {this.renderGroups("Aktív tanulócsoportok", group => group.is_active! && group.status_id === InstituteGroupMemberStates.ACTIVE_ID)}
            
            {/* Béla: Ha ott marad, akkor örökre ott marad mondjuk egy téves meghívó? Nem kell, hogy ott maradjon, majd meghívnak újra.
                this.renderGroups("Egyéb tanulócsoportok", group => group.is_active! && (group.status_id !== InstituteGroupMemberStates.INVITATION_SENT_ID && group.status_id !== InstituteGroupMemberStates.ACTIVE_ID))
            */}

            {/*
                this.renderGroups("Törölt tanulócsoportok", group => !group.is_active)
            */}

            
            {
                this.state.groups.length === 0 &&
                <div className="column small-12">
                    <div className="callout">
                        <p>Még nincs tanulócsoportja.</p>
                    </div>
                </div>
            }

            
            
            
            {
                this.state.createGroupDialogOpen &&
                <Dialog title={this.state.sites.length > 0 ? __("Új tanulócsoport létrehozása") : __("Figyelmeztetés")} width={640} height={this.state.sites.length > 0 ? 400 : 160} maxHeight={ this.state.sites.length > 0 ? 600 : 180} maxWidth={700} onClose={() => this.setState({createGroupDialogOpen: false, instituteSiteId: undefined})}>

                    {
                        this.state.sites.length > 0
                        ?
                        <div className="row">
                        <div className="column small-12">
                            <label>
                                Iskola:
                                <LookupEdit
                                    key={""}
                                    fk_table_info_id={InstituteSiteCrud.TABLE_INFO_ID}
                                    clearable={false}
                                    emptyLoad={true}
                                    
                                    valueColumn={"institute_site_id"}
                                    searchColumnNames={["institute_site_name"]}
                                    selectColumnNames={["institute_site_id", "institute_site_name"]}
                                    displayColumnNames={["institute_site_name"]}
                                    orderByColumnNames={[{name:"institute_site_name"}]}
                                    distinct={true}
    
                                    viewClassProxy={viewInstituteSiteTeacherClassProxy}
                                    filter={{
                                        sec_user_id: me.id,
                                        status_id: INSTITUTE_SITE_TEACHER_STATUS_ACTIVE_ID
                                    }}
                                    
                                    value={this.state.instituteSiteId || null}
                                    onChange={e => this.setState({instituteSiteId: Number(e)})}
                                    /*
    
                                    placeholder="Kérem válasszon feladatellátási helyet..."
                                    styles={{singleValue: base => ({...base, whiteSpace:"pre-wrap"}), menuPortal: base => ({...base, zIndex: 19999}) }}
                                    noOptionsMessage={() => "Nincs választható feladatellátási hely"}
                                    */
                                />
                            </label>
    
                            <label>
                                Csoport neve:
                                <input type="text" value={this.state.newGroupName} onChange={e => this.setState({newGroupName: e.target.value})} />
                            </label>
    
                            <button 
                                className="button" 
                                onClick={this.onCreateGroup.bind(this)}
                                disabled={this.state.saving || !this.state.instituteSiteId || !this.state.newGroupName}
                                >
                                    <i className="fa fa-plus" /> {__("Létrehozás")}
                            </button>
                        </div>
        
                        <div className="column small-12">
                            <div className="callout">
                                <i className="fa fa-exclamation-circle"/> Ha nem találja az iskola listában az Ön intézményét, azt a <Link to={PATH_PROFILE}>profil</Link> lapon felveheti.
                            </div>
                        </div>
                        </div>
                        :
                        <div className="row">
                            <div className="column small-12">
                                Új tanulócsoport létrehozásához először adja meg saját intézményét! <br/>
                                {/* (Saját intézményt a profil lapon rögzíthet → <Link to={PATH_PROFILE}>ugrás a profil lapra</Link>) */}
                                <button className="button" onClick={()=> this.setState({createGroupDialogOpen: false})} style={{ margin: "10px 10px 10px 0px" }}>{__("Mégsem")}</button><Link to={PATH_PROFILE}>{__("Ugrás a profil lapra")}</Link>
        
                                {/* <LookupEdit 
                                    key={""}
                                    fk_table_info_id={InstituteSiteCrud.TABLE_INFO_ID}
                                    clearable={false}
                                    viewClassProxy={viewInstituteSiteClassProxy}
                                    noOptionsMessage={() => __("Nincs találat!")}
                                    loadingMessage={() => __("Betöltés...")}
                                    selectColumnNames={["id", "name"]}
                                    displayColumnNames={["name", "om_identifier", "site_identifier"]}
                                    searchColumnNames={["name", "om_identifier", "site_identifier"]}
                                    value={this.state.instituteSiteId || null}
                                    onChange={e => this.setState({instituteSiteId: Number(e)})}
                                />
                                <br />
                                <button 
                                    className="button" 
                                    onClick={this.onAddInstitute.bind(this)}
                                    disabled={!this.state.instituteSiteId}
                                    >
                                        <i className="fa fa-plus" /> {__("Hozzáadás")}
                                </button> */}
                            </div>
                        </div>
                    }
                </Dialog>
            }

        </div>;
    }

    private renderGroups(groupFilterTitle: string, filter: (group: IViewInstituteGroupMemberRecord) => boolean) {
        const groups = this.state.groups.filter(filter);
        if (groups.length === 0) return null;


        return <div className="row expanded classroom-groups">
            <div className="column small-12">
                <h6>{groupFilterTitle}:</h6>
            </div>
            
        {
            groups.map((group, index) => {

                let actions: {text: string, onAction: () => void}[] = [];

                if (me && group.is_active && group.status_id === InstituteGroupMemberStates.INVITATION_SENT_ID) {
                    actions.push({
                        text: "Meghívás visszautasítása",
                        onAction: () => this.setMemberStatus(group, InstituteGroupMemberStates.INVITATION_REFUSED_ID, "Megerősítés", "Biztos, hogy visszautasítja a meghívást?") 
                    })
                }
                if (me && group.is_active && group.status_id === InstituteGroupMemberStates.INVITATION_REFUSED_ID) {
                    actions.push({
                        text: "Csatlakozás a csoportba",
                        onAction: () => this.setMemberStatus(group, InstituteGroupMemberStates.ACTIVE_ID, "Megerősítés", "Biztos, hogy csatlakozik a csoporthoz?") 
                    })
                }
                if (me && group.is_active && group.status_id === InstituteGroupMemberStates.LEFT_ID) {
                    actions.push({
                        text: "Visszatérés a csoportba",
                        onAction: () => this.setMemberStatus(group, InstituteGroupMemberStates.ACTIVE_ID, "Megerősítés", "Biztos, hogy visszatér a csoportba?")
                    })
                }
                if (me && group.is_active && group.is_admin && group.status_id === InstituteGroupMemberStates.ACTIVE_ID) {
                    actions.push({
                        text: "Csoport törlése",
                        onAction: () => this.deleteGroup(group)
                    })
                }

                return <React.Fragment key={index}>
                    <div className="column large-4 medium-6 small-12">
                        <div className="classroom-group">

                        <div style={{flex: "1", marginBottom: "0.5rem"}}>
                            {
                                group.status_id === INSTITUTE_SITE_STATUS_ACTIVE && group.is_active
                                ?
                                <Link to={classroomModule.PATH_CLASSROOM_NEWS + "/" + group.institute_group_id}>
                                    {group.group_name}
                                </Link>
                                :
                                <span>{group.group_name} <small>({group.status_title})</small></span>
                            }
                            <br />
                            <small style={{color: "gray"}}>{group.institute_site_name}</small>
                        </div>
                        <div>
                        <div className="">
                        {
                            group.status_id === INSTITUTE_SITE_STATUS_INVITATION_SENT
                            ?
                            <div className="button-group tiny" style={{justifyContent: "center"}}>
                            <button className="button" style={{marginBottom: 0}} onClick={this.onAcceptInvitation.bind(this, group)}>
                                Tagság elfogadása
                            </button>
                            <button className="button alert" style={{marginBottom: 0}} onClick={() => this.setMemberStatus(group, InstituteGroupMemberStates.INVITATION_REFUSED_ID, "Megerősítés", "Biztos, hogy visszautasítja meghívást?")}>
                                Meghívás visszautasítása
                            </button>
                            </div>
                            : 
                            <>
                                <div className="status-container column" style={{ float: "left" }}>{group.is_admin ? "Tanár" : "Diák"}</div>
                                <div className="status-container column" style={{ float: "right" }}>{group.status_title}</div>
                                </>   
                        }

                       
                    </div>
                    </div>
                    {
                            actions.length > 0 &&
                            <span className="classroom-menu" ref={ref => this.menuIcons[group.id!] = ref}
                                    onClick={(e) => {this.setState({showGroupMenu: group.id}); e.stopPropagation(); }} 
                                    style={{cursor: "pointer"}} >
                                <i className="fa fa-fw fa-ellipsis-v" />
                            </span>
                        }
                         <Popover open={this.state.showGroupMenu === group.id} parentElement={() => this.menuIcons[group.id!] as HTMLElement} onClose={() => this.setState({showGroupMenu: undefined})} >
                            <div className="content-item-icon__menu-item">
                                {
                                    actions.map((action, index) => {
                                        return <div key={index} onClick={action.onAction}>{action.text}</div>;
                                    })
                                }
                            </div>
                        </Popover>
                    </div>
                    </div>
                    
                    </React.Fragment>
            })
        }
        </div>;

    }

}
