import { Server } from '../../server/Server';
import Crud , { registerCrudClassProxy, IAsTextResult, IListParams, CrudClassProxy, ISearchTextParams, ISearchTextResult } from '../Crud';

export interface IExerciseSeriesRecord {
    /**
     * Globálisan egyedi azonosító.
     * 
     * Ha új rekordot akarsz létrehozni put() metódussal, akkor hagyd üresen.
     * upsert() hívásnál figyelmen kívül van hagyva az értéke.
     *
     */
    id ?: number;
    /**
     * Sorrend
     */
    no?: number /*integer*/|null;
    /**
     * Rekord létrehozás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel létrehozásakor.
     */
    creation_time?: string /*timestamp?*/;
    /**
     * Ki hozta létre
     *
     * NOT NULL -> sys.sec_user ON DELETE noaction
     *
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozó felhasználóra hivatkozik.
     */
    creation_user_id?: number;
    /**
     * Melyik session-ből jött létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozáshoz használt munkamenetre hivatkozik.
     */
    creation_session_id?: number|null;
    /**
     * Utolsó módosítás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel módosításakor.
     */
    modification_time?: string /*timestamp?*/|null;
    /**
     * Ki módosította utoljára
     *
     * -> sys.sec_user ON DELETE noaction
     *
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosító felhasználóra hivatkozik.
     */
    modification_user_id?: number|null;
    /**
     * Melyik session-ből módosították utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosításhoz használt munkamenetre hivatkozik.
     */
    modification_session_id?: number|null;
    /**
     * Aktív
     *
     * Logikai törléshez használt mező.
     */
    is_active?: boolean;
    /**
     * Utolsó találat időpontja
     */
    search_popularity_last_hit_date?: string /*timestamp?*/|null;
    /**
     * Utolsó népszerűségi index
     */
    search_popularity_last_score?: number /* double precision */|null;
    /**
     * Cím
     */
    title?: string;
    /**
     * Bevezető szöveg
     */
    introduction?: string|null;
    /**
     * Évfolyam
     *
     * -> doc.grade ON DELETE cascade
     *
     */
    grade_id?: number|null;
    /**
     * Tantárgy
     *
     * -> doc.subject ON DELETE cascade
     *
     */
    subject_id?: number|null;
    /**
     * Könyvtár
     *
     * NOT NULL -> doc.library ON DELETE cascade
     *
     */
    library_id?: number;
    /**
     * Tulajdonos/felelős
     *
     * NOT NULL -> sys.sec_user ON DELETE cascade
     *
     */
    owner_id?: number;
    /**
     * Szint
     *
     * A feladatsor nehézségi foka, 1=könnyű 2=közepes 3=nehéz
     */
    level?: number /*integer*/;
    /**
     * Fix sorrend
     *
     * A feladatok pontosan a meghatározott sorrendben követik egymást.
     */
    fix_order?: boolean;
    /**
     * Feladat kiválasztás módja
     *
     * NOT NULL -> exc.exc_selection_mode ON DELETE cascade
     *
     */
    exc_selection_mode_id?: number;
    /**
     * Feladatok darabszáma
     *
     * Csak akkor, ha a kiválasztás módja: megadott darabszámú kérdés.
     */
    exc_count?: number /*integer*/|null;
    /**
     * Feladatok min. összpontszáma
     *
     * Csak akkor, ha a kiválasztás módja: fix összpontszámú feladatsor.
     */
    minpoints?: number /*integer*/|null;
    /**
     * Elérhető pontszám kijelzése
     */
    show_max_points?: boolean;
    /**
     * Feladat üzemmód
     *
     * NOT NULL -> exc.exc_display_mode ON DELETE cascade
     *
     */
    exc_display_mode_id?: number;
    /**
     * Max. megoldási idő
     *
     * A megoldásra használható maximális idő percben.
     */
    max_solution_time_min?: number /*integer*/|null;
    /**
     * Megszakítható
     *
     * A feladatsor megoldása megszakítható.
     */
    can_interrupt?: boolean;
    /**
     * Sikeresség határa (százalék)
     */
    success_limit_percent?: number /* double precision */;
    /**
     * Megjelenés helye
     *
     * -> media.oo_folder ON DELETE setnull
     *
     *
     * Mappa, ahol a feladatsor a virtuális állományrendszerben megjelenik.
     */
    show_in_folder_id?: number|null;
    /**
     * Azonosító NKP 1.0 rendszerben
     */
    nkp1_id?: number|null;
    /**
     * Adaptív
     *
     * Az adaptív feladatsorok témakörökre és nehézségi kategóriákra osztják a feladatokat.
     */
    is_adaptive?: boolean;
    /**
     * Név
     *
     * Kulcsszavak
     */
    keywords?: string|null;
    /**
     * Site
     *
     * NOT NULL -> cms.site ON DELETE noaction
     *
     */
    site_id?: number;
    /**
     * Nyelv
     *
     * NOT NULL -> sys.lang ON DELETE cascade
     *
     */
    lang_id?: number;
}

/**
 *  exc.exercise_series - Feladatlista
 *
 *  Feladványok listája
 */

export default class ExerciseSeriesCrud extends Crud<IExerciseSeriesRecord> {
    public static TABLE_INFO_ID = 3732533840;
    public static getSchemaNameForClass() { return 'exc'; }
    public static getTableNameForClass() { return 'exercise_series'; }

    public static load: (id: number, aServer ?: Server) => Promise<ExerciseSeriesCrud>;
    public static asText: (id: number, includeRecord : boolean, aServer ?: Server) => Promise<IAsTextResult<IExerciseSeriesRecord>>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<IExerciseSeriesRecord[]>;
    public static searchText: (params: ISearchTextParams, aServer ?: Server) => Promise<ISearchTextResult<IExerciseSeriesRecord>[]>;
    public static deleteById: (id: number, aServer ?: Server) => Promise<IExerciseSeriesRecord>;
    public static unDeleteById: (id: number, aServer ?: Server) => Promise<IExerciseSeriesRecord>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class ExerciseSeriesCrudClassProxy extends CrudClassProxy<IExerciseSeriesRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const exerciseSeriesCrudClassProxy = new ExerciseSeriesCrudClassProxy(ExerciseSeriesCrud);
registerCrudClassProxy(exerciseSeriesCrudClassProxy);
