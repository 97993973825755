import * as React from 'react';
import { FavoriteList } from '@src/component/filemanager/FavouriteComponent';

interface FavouritesTileProps {
    settings: any;
}

export default class FavouritesTile extends React.Component<FavouritesTileProps, {}> {

    render() {
        return <div className="favorites-tile"><FavoriteList limit={this.props.settings.limit} hideHeader={true}/></div>
    }

}
