import * as React from 'react';

import { Dialog } from '@src/component/Dialog';

import LessonEditor, { FileToUrlPath } from './LessonEditor';
import MediaFileChooser, { ALL_ACTIONS } from '@component/filemanager/MediaFileChooser';
import { Section } from '@src/server/EditorServer';
import OoFileCrud from '@src/framework/crud/media/OoFileCrud';
import { SectionOptionsEditor } from '@src/component/book/editor/SectionOptionsEditor';
import { string } from 'prop-types';
import { __ } from '@src/translation';

type SectionFileEditorProps = {
  lessonEditor: LessonEditor;
  section: Section;
  templateVariableId: string;
  rootFolderId: number;
  urlRoot: string;
  fileToUrlPath: FileToUrlPath;
}

type SectionFileEditorState = {
  dialogVisible: boolean;
  currentFolderId: number;
}

export default class SectionFileEditor extends React.Component<SectionFileEditorProps, SectionFileEditorState> {
  constructor(props: SectionFileEditorProps) {
    super(props);
    this.state = { dialogVisible: false, currentFolderId: props.rootFolderId };
  }

  private onShowFileSelectionDialog() {
    this.setState({
      dialogVisible: !this.state.dialogVisible
    })
  }

  async onFileSelected(fileId: number) {

    const selectedFile = (await OoFileCrud.load(fileId)).record;

    const path = this.props.fileToUrlPath(selectedFile);


    const chapterEditor = this.props.lessonEditor;
    chapterEditor.handleEditorChange(this.props.section.id, this.props.templateVariableId, path);

    this.setState({ dialogVisible: false });
  }
  componentDidMount()
  {
    if (this.props.section.values[this.props.templateVariableId] && typeof(this.props.section.values[this.props.templateVariableId])==='string' && this.props.section.values[this.props.templateVariableId].endsWith(".html"))
     {
        const chapterEditor = this.props.lessonEditor;
        const path = ""
        chapterEditor.handleEditorChange(this.props.section.id, this.props.templateVariableId, path);
    }
  }

  render() {
    const section = this.props.section;
    const chapterEditor = this.props.lessonEditor;

    var value: string | undefined = this.props.section.values[this.props.templateVariableId];

    if (value && !value.startsWith("http") && !value.startsWith("/")) {
      value = this.props.urlRoot + "/" + value;
    }

    return <div className="row expanded">
      {this.state.dialogVisible
        ?
        <Dialog title={__("Videó kiválasztása")} onClose={() => this.setState({ dialogVisible: false })}>
          <MediaFileChooser
            key={this.state.currentFolderId || 0}
            rootFolderId={this.props.rootFolderId}
            currentFolderId={this.state.currentFolderId}
            //selectableExtensions={["mp4"]}
            onFileSelected={this.onFileSelected.bind(this)}
            onFolderSelected={ (folderId:number) => { this.setState({currentFolderId: folderId})}}
            enabledActions={ALL_ACTIONS}
          />
        </Dialog>
        : ""
      }

      <div className="shrink column">
        <button className="button" onClick={this.onShowFileSelectionDialog.bind(this)}><i className="fa fa-folder" /> {__("Saját fájl választása")}</button>
      </div>
      <div className="shrink column">
        <label className="middle">URL:</label>
      </div>
      <div className="column">
        <input
          type="text"
          value={this.props.section.values[this.props.templateVariableId]}
          onChange={chapterEditor.handleEditorChange.bind(chapterEditor, section.id, this.props.templateVariableId)}

        />
      </div>

      {value ?
        <div className="medium-12 column">
          <br />
          <a href={value} target="_blank" >{value}</a>        
        </div>
        :
        ""
      }

      <SectionOptionsEditor
        lessonEditor={this.props.lessonEditor}
        section={this.props.section}
        templateVariableId={this.props.templateVariableId + "-options"}
        enableIcons={false}
        enableAspectRatio={false}
        enableExerciseCheckboxes={false}
      />

    </div>
      ;
  }
}
