import * as React from 'react';
import { match } from 'react-router';
import * as ExerciseContainerComponent from '@src/component/exercise/ExerciseContainerComponent';
import * as queryString from 'query-string';
import ExerciseSeriesCrud from '@src/framework/crud/exc/ExerciseSeriesCrud';
import * as PrivateViewExerciseSeries from '@src/framework/view/exc/ViewExcSeries';
import * as PublicViewExerciseSeries from '@src/framework/view/exc_pub/ViewExcSeries';
import { MetaKeywordImmediateAssignerPlugin } from '@component/meta/meta_keyword_assigner_plugin';
import { me, hasAnyGroup, Groups } from '@framework/server/Auth';
import { PermissionCache } from '@src/framework/security/PermissionCache';
import { PATH_EXERCISE_SERIES_VIEW, PATH_EXERCISE_SERIES_PUB_VIEW, PATH_EXERCISE_SERIES_EDIT, PATH_EXERCISE_SERIES_LIST, PATH_EXERCISE_PUB_LIST, } from '@src/Routes';
import { Link } from 'react-router-dom';
import * as ExerciseServer from '@src/component/exercise/ExerciseServer';
import * as Server from '@src/server/PublicServer';
import EngineCRUD, { IEngineRecord } from '@crud/exc/EngineCrud';
import ViewExerciseSeriesSection, { IViewExerciseSeriesSectionRecord } from '@src/framework/view/exc/ViewExerciseSeriesSection';
import { WF_TYPE_OKOSFELADATSOR_ID, LIBRARY_OFI_OFFICIAL_ID, LIBRARY_PERSONAL_ID } from '@src/Const';
import { app } from '@src/index';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { BubbleLoader } from 'react-css-loaders';
import { ExerciseTile } from '../Viewer/ExerciseTileComponent';
import { WorkflowDialog } from '@src/component/wf/WorkflowDialog';
import { config } from '@src/framework/server/Server';
import { __ } from '@src/translation';
import { whiteSpaceChecker } from '@src/component/WhiteSpaceChecker';
import { IExerciseSeriesView } from '../models/IExerciseSeriesView';
import ViewEditorExcSeries from '@src/framework/view/exc/ViewEditorExcSeries';
import ViewMyExcSeries from '@src/framework/view/exc/ViewMyExcSeries';

type IExerciseSeriesShowProps = {
    match: match<{ exerciseSeriesId: string }>
}

interface IExerciseSeriesShowState {
    sections: IViewExerciseSeriesSectionRecord[],
    canEdit: boolean,
    filters: any,
    nextExerciseId?: number,
    prevExerciseId?: number,
    isFirst: boolean,
    published: boolean,
    searchResults: Server.SearchResult[],
    engines: IEngineRecord[],

    workflowDialogOpen: boolean,
    workflowStateName?: string,
    loading: boolean,
}

export default class ExerciseSeriesShow extends React.Component<IExerciseSeriesShowProps, IExerciseSeriesShowState> {

    private exerciseServer: ExerciseServer.ExerciseServer;

    constructor(props: any) {
        super(props);

        let isPublished = this.props.match && this.props.match.path.startsWith(PATH_EXERCISE_SERIES_PUB_VIEW);

        this.state = {
            canEdit: false,
            sections: [],
            filters: {},
            isFirst: true,
            published: isPublished,
            searchResults: [],
            engines: [],
            workflowDialogOpen: false,
            loading: true
        };


        if (!me && !isPublished) {
            app.showError(__("Hiba"), __("Nincs jogosultsága a tartalom megtekintésére!"));
            return;
        }
        this.exerciseServer = new ExerciseServer.ExerciseServer(isPublished);
        this.exerciseServer.setExerciseSeriesId(Number(this.props.match.params.exerciseSeriesId));
        this.setNavigation();

    }

    async componentDidUpdate(prevProps: IExerciseSeriesShowProps) {
        if (prevProps.match.params.exerciseSeriesId != this.props.match.params.exerciseSeriesId) {
            this.exerciseServer.setExerciseSeriesId(Number(this.props.match.params.exerciseSeriesId));
            this.setNavigation();
        }

    }

    async setNavigation() {
        this.setState({ loading: true });

        let exercises:  IExerciseSeriesView[] = [];
        let currSeriesId = parseInt(this.props.match.params.exerciseSeriesId);


        let published_series: PublicViewExerciseSeries.IViewExcSeriesRecord | null = null;
        let curr_series: IExerciseSeriesView | null = null;        
        try {            
            if (this.state.published) {
                // Ha publikált, akkor betöltjük a publikált és a szerkesztőségi verziót is.
                // Workflow állapota csak a szerkesztőséginek van...
                published_series = (await PublicViewExerciseSeries.default.load(currSeriesId)).record;                
            }
            try {
                // Lehet hogy a szerkesztőségi/privát változtatot nincs joga betölteni.
                curr_series = (await PrivateViewExerciseSeries.default.load(currSeriesId)).record;
            } catch (e) {
                // Ha a nyilvánosat akartuk megjeleníteni, akkor ez nem baj.
                if (this.state.published) {                    
                    curr_series = null;
                } else {
                    app.showErrorFromJsonResult(e);
                    return;
                }
            }
            // Ha nincs bejelentkezve akkor nem szerkeszthet.
            let canEdit = false;
            if (me && curr_series) {
                // Hivatalos feladatnál akkor szerkeszthet, ha van joga szerkeszteni az aktuális állapotában.
                // Fontos, hogy ez a szerkesztés jog a szerkesztőségi verzióra vonatkozik. Akkor is,
                // ha most nem azt jelenítjük meg, hanem a publikáltat.
                //
                // Privát feladatnál akkor szerkeszthet, ha ő a tulajdonos.
                //
                // A többi library-hoz meg elvileg nincsenek feladatok.
                //
                if (curr_series.library_id == LIBRARY_OFI_OFFICIAL_ID) {
                    canEdit = await PermissionCache.hasWfEditPermission(WF_TYPE_OKOSFELADATSOR_ID, curr_series.wf_station_id, me, curr_series);
                } else if (curr_series.library_id == LIBRARY_PERSONAL_ID) {
                    canEdit = (curr_series.owner_id == me.id);
                }
            }


            let sections: IViewExerciseSeriesSectionRecord[] = await ViewExerciseSeriesSection.list({ filter: { id: currSeriesId, library_id: LIBRARY_OFI_OFFICIAL_ID } });
            let searchResults: Server.SearchResult[] = await Server.searchSimilar(currSeriesId, this.state.published, 10, "exercise_series");
            let engines = await EngineCRUD.list({ filter: { is_active: true } });

            let newState : Partial<IExerciseSeriesShowState> = {
                canEdit,
                searchResults,
                engines,
                sections: sections,
                workflowStateName: (curr_series && curr_series.wf_station_name) || undefined,
                loading: false
            };

            if (!whiteSpaceChecker(location.search)) {
                this.setState(newState as IExerciseSeriesShowState);
                return;
            }

            let filter = queryString.parse(location.search); 
            // checking if it comes from a last page, then we need to create links for the previous and next exercises. 
            // Therefore, we need to know the library id, to use the apropriate view.
            const libId = filter.library_id?Number(filter.library_id):undefined;
            var listParameters = {
                filter: JSON.parse(filter.filter),
                order_by: filter.order_by ? JSON.parse(filter.order_by) : undefined,
                limit: Number(filter.limit),
                offset: Number(filter.offset),
                library_id:undefined
            };

            if (filter.offset == this.state.filters.offset && filter.limit == this.state.filters.limit) {
                this.setState(newState as IExerciseSeriesShowState);
                return;
            }

            let isFirst = false;

            // if it's the first element of the list

            if (listParameters.offset < 0) {
                isFirst = true;
                listParameters.offset = 0;
                listParameters.limit = 2;
            }

            if(libId == 0 || this.state.published){
                exercises = await PublicViewExerciseSeries.default.list(listParameters);               
            }else if(libId == LIBRARY_OFI_OFFICIAL_ID){
                exercises = await ViewEditorExcSeries.list(listParameters);
            }else{
                exercises = await ViewMyExcSeries.list(listParameters);
            }
            // we don't send the library id as filter to the server, but keep it, to pass it to the next page.
            listParameters.library_id = filter.library_id;
            
            let prevExId;
            let nextExId;

            if (exercises.length <= 1) {
                //do nothing in case of one element
                this.setState(newState as IExerciseSeriesShowState);
                return;
            }

            // the first or the last element
            if (exercises.length == 2) {
                //if (this.state.isFirst) {
                if (isFirst) {
                    curr_series = exercises[0];
                    nextExId = exercises[1].id;
                }
                else {
                    isFirst = false; // last element
                    prevExId = exercises[0].id;
                    curr_series = exercises[1];
                }
            }
            else {
                prevExId = exercises[0].id;
                curr_series = exercises[1];
                nextExId = exercises[2].id;
            }


            if ((this.state.nextExerciseId != nextExId || !this.state.nextExerciseId) && (this.state.prevExerciseId != prevExId || !this.state.prevExerciseId)) {
                newState = { ...newState,
                    filters: listParameters,
                    nextExerciseId: nextExId,
                    prevExerciseId: prevExId,
                    isFirst,                
                }
                this.setState(newState as IExerciseSeriesShowState);
            }

        } catch (e) {
            app.showErrorFromJsonResult(e);
        }
    }

    getNavigationFilter(isNext: boolean): any {
        let curr_offset = 0;
        if (!this.state.isFirst) {
            curr_offset = (isNext ? (this.state.filters.offset + 1) : (this.state.filters.offset - 1));
        }

        return {
            filter: JSON.stringify(this.state.filters.filter),
            order_by: JSON.stringify(this.state.filters.order_by),
            limit: 3,
            offset: curr_offset,
            library_id: this.state.filters.library_id
        };
    }
    private copyPublishedExerciseSerieItem = async (exerciseId: number) => {
        try {
            const newExercise = await Server.copyPublishedExerciseSeries(exerciseId, LIBRARY_PERSONAL_ID);
            // TODO: nem kellene itt egy linket kitenni vagy valami?
            app.showSuccess(__("Feladat másolása"), __("Sikeres"))
        } catch (error) {
            app.showErrorFromJsonResult(error);
        }
    }

    render() {
        if (this.state.loading) return <BubbleLoader />;

        if (!this.state.published && !me) {
            app.showError(__("Hiba"), __("Nincs jogosultsága a tartalom megtekintésére!"));
            return "";
        }

        /*if (!this.state.published && me && !hasAnyGroup(me, [Groups.Admin, Groups.OFIEditor, Groups.Developer]) && !this.state.canEdit) {
            app.showError(__("Hiba"), __("Nincs jogosultsága a tartalom megtekintésére!"));
            return "";
        }*/

        if (this.refs.exercise_container) {
            (this.refs.exercise_container as ExerciseContainerComponent.default).restartFromBeginning();
        }
        return (
            <div className="row expanded exerciseList-maincomponent">
                <WorkflowDialog
                    open={this.state.workflowDialogOpen}
                    displayName={__("Feladatsor")}
                    tableInfoId={ExerciseSeriesCrud.TABLE_INFO_ID}
                    recId={Number(this.props.match.params.exerciseSeriesId)}
                    onClose={() => this.setState({ workflowDialogOpen: false })}
                    onTransition={this.setNavigation.bind(this)}
                    autoPublishOnClosedStation={true}
                />
                <div className="small-12 medium-8 medium-offset-2 column">

                    {
                        (this.state.prevExerciseId && !this.state.isFirst) ?
                            <Link className="button small eke-general-buttons float-left" to={PATH_EXERCISE_SERIES_VIEW + `/${this.state.prevExerciseId}/?${queryString.stringify(this.getNavigationFilter(false))}`}><i className="fa fa-chevron-left" /> {__("Előző feladatsor")}</Link>
                            : ""
                    }
                    {
                        (this.state.nextExerciseId) ?
                            <Link className="button small eke-general-buttons float-right" to={PATH_EXERCISE_SERIES_VIEW + `/${this.state.nextExerciseId}/?${queryString.stringify(this.getNavigationFilter(true))}`}><i className="fa fa-chevron-right" /> {__("Következő feladatsor")}</Link>
                            : ""
                    }
                </div>
                <div className="small-12 medium-offset-2 medium-8 column">
                    <ExerciseContainerComponent.default
                        ref="exercise_container"
                        key={this.props.match.params.exerciseSeriesId}
                        server={this.exerciseServer}
                    />
                    <div>
                        {!this.state.published ?
                            <div>
                                <MetaKeywordImmediateAssignerPlugin
                                    tableInfoId={ExerciseSeriesCrud.TABLE_INFO_ID}
                                    recordId={Number(this.props.match.params.exerciseSeriesId)}
                                    key={"keywords_" + this.props.match.params.exerciseSeriesId}
                                />
                            </div>
                            : ""
                        }
                        {
                            config.mainServer.showBooks && me && hasAnyGroup(me, [Groups.Admin, Groups.Developer, Groups.OFIEditor]) ?
                                this.state.sections && this.state.sections.length > 0 && this.state.sections[0].section_id != null
                                    ? <div className="eke-exerciseshow-tooltip">
                                        <h4>{__("Ez a feladatsor az alábbi leckékben szerepel")}:</h4>
                                        {this.state.sections.map((item: IViewExerciseSeriesSectionRecord) => {
                                            const url = "/tankonyv/" + item.book_uri_segment + "/" + item.lesson_uri_segment + "#section-" + item.section_id;
                                            return <Link to={url} className="eke-exerciseshow-graytitle">
                                                <span>{item.book_name + " / " + item.chapter_name + " / " + item.lesson_name} </span><br />
                                            </Link>
                                        })
                                        }
                                    </div>
                                    : ""
                                : ""
                        }

                    </div>
                    <div style={{ paddingTop: "3rem" }}>
                        {
                            this.state.published ?
                                <Link className="button success" title={__("Vissza a feladattárhoz")} to={PATH_EXERCISE_PUB_LIST}>
                                    <i className="fa fa-arrow-left"></i>&nbsp; {__("Vissza a feladattárhoz")}
                                </Link>
                                :
                                <Link className="button success" title={__("Vissza a feladatsorlistához")} to={PATH_EXERCISE_SERIES_LIST}>
                                    <i className="fa fa-arrow-left"></i>&nbsp; {__("Vissza a feladatsorlistához")}
                                </Link>

                        }
                        {this.state.canEdit &&
                            <Link to={PATH_EXERCISE_SERIES_EDIT + `/${this.props.match.params.exerciseSeriesId}`} className="button" >
                                <i className="fa fa-edit"></i>&nbsp; {__("Szerkesztés")}
                            </Link>
                        }
                        {
                            this.state.canEdit && !this.state.published && this.state.workflowStateName &&
                            <button className="button" onClick={() => this.setState({ workflowDialogOpen: !this.state.workflowDialogOpen })}>
                                <i className="fa fa-project-diagram" />&nbsp; {__("Folyamat...")}
                            </button>
                        }
                        {(this.state.published && me && (hasAnyGroup(me, [Groups.Developer, Groups.Admin, Groups.Teacher, Groups.OFIEditor])) ?
                            <button key="new_copy" className="button success" onClick={this.copyPublishedExerciseSerieItem.bind(this, parseInt(this.props.match.params.exerciseSeriesId!))}>
                                <i className="fa fa-copy" /> {__("Mentés másolatként")}
                            </button>
                            : ""
                        )}

                        <div>
                            <h4>{__("Hasonló feladatok")}</h4>
                            {this.state.searchResults.length ?
                                <OwlCarousel
                                    ref="owlcarousel"
                                    key="owlcarousel"
                                    className="owl-theme eke-exerciseshow-similar"
                                    nav
                                    dots={false}
                                    responsive={{
                                        0: {
                                            items: 1,
                                            autoWidth: true,
                                            center: true
                                        },
                                        /*
                                        400: {
                                            items: 2
                                        },
                                        460: {
                                            items: 3
                                        },
                                        600: {
                                            items: 4
                                        },
                                        */
                                        640: {
                                            items: 2
                                        },
                                        900: {
                                            items: 3
                                        },
                                        1280: {
                                            items: 4
                                        },
                                        1600: {
                                            items: 5
                                        }
                                    }}
                                >
                                    {
                                        this.state.searchResults.map((r: Server.SearchResult) => {
                                            let engineName = undefined;
                                            const engine = this.state.engines.find((engine) => engine.id == r.engine_id);
                                            //console.log("engine", engine)
                                            if (engine && engine.class_name) {
                                                engineName = engine.class_name;
                                            }
                                            return (<ExerciseTile className="item" key={"exetile" + r.id} ExerciseResult={r} published={this.state.published} engineName={engineName} />)
                                        })
                                    }
                                </OwlCarousel>
                                : null}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
