import { durationSecondsConverter } from '@src/component/media/DurationConverter';
import * as React from 'react';
import "./CountDown.css";
import { __ } from '@src/translation';

type CountDownProps ={
    startSeconds: number, 
    isInSharedContent:boolean
    onComplete: () => void, 
    timerIsPaused: () => void, 
    canInterrupt:boolean|undefined,
}

export default class CountDown extends React.Component<CountDownProps , { seconds: number, paused: boolean }> {

    private timeoutId: any;

    constructor(props: any) {
        super(props);
        this.state = {
            seconds: this.props.startSeconds,
            paused: false
        }
    }

    componentWillMount() {
        if (!this.state.paused) {
            this.startTimer();
        }
    }
    componentDidUpdate() {
        if (this.timeoutId) clearTimeout(this.timeoutId);

        if (!this.state.paused) {
            this.startTimer();
        }
    }
    componentWillUnmount() {
        if (this.timeoutId) clearTimeout(this.timeoutId);
    }   

    private startTimer() {
        this.timeoutId = setTimeout(this.timeout.bind(this), 1000);
    }

    private timeout() {
        if (this.state.paused) return;
        const seconds = this.state.seconds - 1;

        if(seconds < 0) {
            this.props.onComplete();
            return;
        }

        this.setState({
            seconds
        })
        if (seconds > 0) {
            this.startTimer();
        } 
    }

    private getOffset(){
        let value =this.state.seconds;
        let total = this.props.startSeconds;
        return - length - length * value / (total);
    }

    private onIconClick() {

        if(!this.props.canInterrupt) return;
        this.setState({ paused: !this.state.paused },
            () => this.props.timerIsPaused()
        );
    }

    public startCountDown() {
        this.setState({ paused: false });
        this.props.timerIsPaused();
    }

    render() {
        return (
            <div className={"circle-graph" + (this.props.canInterrupt ? " circle-graph--interrupt" : "") + ( ( 360 * this.state.seconds / this.props.startSeconds > 180 ) ? " gt-50" : "" )} onClick={(this.props.canInterrupt ? this.onIconClick.bind(this) : "")}>
                <div className="circle-graph-progress">
                    <div className="circle-graph-progress-fill"  style={{transform : `rotate(${360 * this.state.seconds / (this.props.startSeconds)}deg)`}}></div>
                </div>
                <div className="circle-graph-percent">
                    <div className="circle-graph-percent-wrapper">
                        <span className={this.props.isInSharedContent?"circle-graph-percent-number-shared":"circle-graph-percent-number"}>{durationSecondsConverter(this.state.seconds.toString())}</span>
                            {
                                this.props.canInterrupt ?
                                this.state.paused ?
                                    <button className="circle-graph-percent-button circle-graph-percent-button--play" >
                                        <i className="fa fa-play" aria-hidden={true}></i>
                                        <label>{__("Indítás")}</label>
                                    </button>
                                        :
                                    <button className="circle-graph-percent-button circle-graph-percent-button--pause" >
                                        <i className="fa fa-pause" aria-hidden={true}></i>
                                        <label>{__("Szünet")}</label>
                                    </button>
                                :""
                            }
                    </div>
                </div>
            </div>

        )
    }
}
