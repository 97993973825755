import { Server } from '../../server/Server';
import View , { registerViewClassProxy, ViewClassProxy } from '../View';
import { IListParams } from '@framework/crud/Crud';

export interface IViewMediaOoFolderRecord {
    /**
     * Azonosító
     *
     * Azonosító (pótkulcs)
     */
    id?: number;
    /**
     * Rekord létrehozás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel létrehozásakor.
     */
    creation_time?: string /*timestamp?*/;
    /**
     * Ki hozta létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozó felhasználóra hivatkozik.
     */
    creation_user_id?: number;
    /**
     * Melyik session-ből jött létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozáshoz használt munkamenetre hivatkozik.
     */
    creation_session_id?: number|null;
    /**
     * Utolsó módosítás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel módosításakor.
     */
    modification_time?: string /*timestamp?*/|null;
    /**
     * Ki módosította utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosító felhasználóra hivatkozik.
     */
    modification_user_id?: number|null;
    /**
     * Melyik session-ből módosították utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosításhoz használt munkamenetre hivatkozik.
     */
    modification_session_id?: number|null;
    /**
     * Aktív
     *
     * Logikai törléshez használt mező.
     */
    is_active?: boolean;
    /**
     * Név
     *
     * Az OnlyOffice rendszerében a mappa'title' mezőjének értéke. Vagyis a mappa neve.
     */
    title?: string;
    /**
     * Ennek a könyvnek a gyökér könyvtára
     */
    book_id?: number|null;
    /**
     * Relatív útvonal
     *
     * A mappa szerkezetben a gyökérhez képest.
     */
    relpath?: string;
    /**
     * Relatív útvonal JSON objektumként
     *
     * Egy lista ami (folder_id, folder_title) elemekből áll.
     */
    relpath_items?: any;
    /**
     * OnlyOffice mappa azonosító.
     *
     * https://api.onlyoffice.com/portals/method/files/get/api/2.0/files/%7bfolderid%7d
     */
    oo_folderid?: string|null;
    /**
     * Szülő mappa
     */
    parent_id?: number|null;
    /**
     * OnlyOffice listázó API hívás utolsó eredménye
     */
    oo_info?: any|null;
    /**
     * Azonosító NKP 1.0 rendszerben
     */
    nkp1_id?: number|null;
    /**
     * Tulajdonos
     */
    owner_id?: number;
    /**
     * Jogok öröklése
     *
     * A mappa a jogokat a tartalmazó mappától örökli. Az explicit módon beállított jogok hatástalanok.
     */
    inherit_perms?: boolean;
    /**
     * Közös használatú
     *
     * A mappában létrehozott új bejegyzések tulajdonosa nem a létrehozó, hanem a mappa tulajdonosa lesz.
     */
    suid?: boolean;
    /**
     * Létrehozta
     *
     * Létrehozta
     */
    creator?: string|null;
    /**
     * Utoljára módosította
     *
     * Utoljára módosította
     */
    modifier?: string|null;
    /**
     * Tulajdonos
     *
     * Tulajdonos
     */
    owner?: string|null;
}

/**
 *  media.view_media_oo_folder - OnlyOffice mappa
 *
 *  Idősoros táblázat, ami az összes tétel összes korábbi verzióját eltárolja.
 */

export default class ViewMediaOoFolder extends View<IViewMediaOoFolderRecord> {
    public static VIEW_INFO_ID = 2018062802;

    public static getSchemaNameForClass(): string { return 'media'; }
    public static getViewNameForClass(): string { return 'view_media_oo_folder'; }
    public static getTableNameForClass(): string|null { return 'oo_folder'; }

    public static load: (id: number, aServer ?: Server) => Promise<ViewMediaOoFolder>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<IViewMediaOoFolderRecord[]>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class ViewMediaOoFolderClassProxy extends ViewClassProxy<IViewMediaOoFolderRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const viewMediaOoFolderClassProxy = new ViewMediaOoFolderClassProxy(ViewMediaOoFolder);
registerViewClassProxy(viewMediaOoFolderClassProxy);

