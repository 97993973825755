import * as React from 'react';
import StyleCrud, { IStyleRecord } from '@src/framework/crud/doc/StyleCrud';
import { Link } from 'react-router-dom';
import { app } from '@src/index';
import { __ } from '@src/translation';

export class TemplateStylesPage extends React.Component<{}, {styles: IStyleRecord[], newStyle: IStyleRecord}> {

    constructor(props: any) {
        super(props);

        this.state = {
            styles: [],
            newStyle: {}
        }
    }

    componentDidMount() {
        this.reloadAsync();
    }

    async reloadAsync() {
        const styles = await StyleCrud.list({filter: {is_active: true}, order_by: "title"});

        this.setState({
            styles
        })
    }

    async onSaveNewStyle() {
        try {
            await new StyleCrud(this.state.newStyle).put();
            
            this.setState({
                newStyle: {}
            }, this.reloadAsync.bind(this));
        } catch(e) {
            app.showErrorFromJsonResult(e);
        }
    }

    render() {
        return <div className="row">
            <div className="column small-12">
                <table>
                    <thead>
                        <tr>
                            <th>{__("Sablon neve")}</th>
                            <th>{__("css helye")}</th>
                            <th>{__("Módosítás")}</th>
                            <th>{__("Előnézet")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            this.state.styles.map(s => {
                                return <tr>
                                    <td>{s.title}</td>
                                    <td>{s.css_path}</td>
                                    <td><Link to={"/templateUpdate/" + s.id}>{__("Módosítás")}</Link></td>
                                    <td><Link to={"/layout/" + s.id}>{__("Előnézet")}</Link></td>
                                </tr>
                            })
                        }

                        <tr>
                            <td><input type="text" value={this.state.newStyle.title || ""} onChange={(e) => this.setState({newStyle: {...this.state.newStyle, title: e.target.value}})} /></td>
                            <td><input type="text" value={this.state.newStyle.css_path || ""} onChange={(e) => this.setState({newStyle: {...this.state.newStyle, css_path: e.target.value}})} /></td>
                            <td>
                                <button className="button success" onClick={this.onSaveNewStyle.bind(this)}>
                                    <i className="fa fa-save" /> {__("Mentés")}
                                </button>
                            </td>
                        </tr>

                    </tbody>
                </table>
            </div>
        </div>;
    }

}
