
export enum SurveyInputType{
    CHECKBOX="checkbox",
    RADIO="radio",
    RADIO_SMILEY="radio-smiley",
    //RADIO_ELSE="radio-else",
    //CHECKBOX_ELSE = "checkbox-else",
    TEXT="text",
    RANGE="range",
}

export enum QuestionCondition {
    EXACT_MATCH,
    AT_LEAST_ONE
}

export const MULTI_ANSWER_TYPES = [SurveyInputType.CHECKBOX, SurveyInputType.RADIO];
export const ANSWERS_PREDEFINED_TYPES = [SurveyInputType.RADIO_SMILEY, SurveyInputType.TEXT];

export const NEW_STUDENT_SURVEY_ID = 4;
export const NEW_TEACHER_SURVEY_ID = 5;
export const SURVEY_SKIPPED="survey_skipped";
export const RANGE_MIN_VALUE = 3;
export const RANGE_MAX_VALUE = 10;
export const RANGE_DEF_VALUE = 5;

export const OTHER_TEXT_ANSWER_ID = 0;

export type Question = {
    id: number;
    name?: JSX.Element | string;
    type: SurveyInputType,
    optional?:boolean,
    rangeSize?:number,
    allowCustomAnswer?:boolean,
    answers: { id: number, name?: string }[];
    condition?: { questionId?: number, answerIds: number[], type?: QuestionCondition };
}