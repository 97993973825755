import * as React from 'react';
import PermissionPage from '@src/framework/PermissionPage';
import LessonCrud, { ILessonRecord } from '@src/framework/crud/doc/LessonCrud';
import { CrudPermissionType } from '@src/framework/security/PermissionCache';
import { match } from 'react-router';
import { LessonEditorSidebar } from '@src/component/book/editor/LessonEditorSidebar';
import { IBookRecord } from '@src/framework/crud/doc/BookCrud';
import { IChapterRecord } from '@src/framework/crud/doc/ChapterCrud';
import { BubbleLoader } from 'react-css-loaders';
import { BreadcrumbsLink, BookBreadcrumbs } from '@src/component/Breadcrumbs';
import BookCrud from '@src/framework/crud/doc/BookCrud';
import ChapterCrud from '@src/framework/crud/doc/ChapterCrud';
import { app } from '@src/index';
import LessonEditor from '@src/component/book/editor/LessonEditor';
import { PATH_EDIT_BOOK_TABLE_OF_CONTENTS } from '@src/Routes';
import { EditorPage, EditorPanel, EditorSidebar, EditorSplit } from '@src/component/ui/Panel';
import { __ } from '@src/translation';

export class LessonEditorPageProtected extends React.Component<LessonEditorPageProps, any> {
    render() {
        return <PermissionPage requirements={{
                        crud: {tableInfoId: LessonCrud.TABLE_INFO_ID, permissions: CrudPermissionType.U}
                    }}>
                    <LessonEditorPage {...this.props} />
                </PermissionPage>
    }
}

type LessonEditorPageProps = {
    match: match<{
      bookId: string,
      lessonId: string
    }>;
}

type LessonEditorPageState = {
    book?: IBookRecord;
    chapter?: IChapterRecord;
    lesson?: ILessonRecord;
}

class LessonEditorPage extends React.Component<LessonEditorPageProps, LessonEditorPageState> {

    private lessonEditor: LessonEditor | null = null;

    constructor(props: LessonEditorPageProps) {
        super(props);

        this.state = {

        }
    }

    componentDidMount() {
        this.reloadAsync();
    }
    
    componentDidUpdate(prevProps: LessonEditorPageProps) {
        if (prevProps.match.params.lessonId != this.props.match.params.lessonId) {
            this.reloadAsync();
        }
    }

    private async reloadAsync() {
         var lessonId = parseInt(this.props.match.params.lessonId);

        try {
            const lesson = (await LessonCrud.load(lessonId)).record;
            const chapter = (await ChapterCrud.load(lesson.chapter_id!)).record;
            const book = (await BookCrud.load(chapter.book_id!)).record;

            this.setState({
                book,chapter,lesson
            });

        } catch(e) {
            app.showErrorFromJsonResult(e);
        }

    }
    
    onTextSelected(text: string) {
        if (this.lessonEditor) {
            this.lessonEditor.onTextSelected(text);
        }
    }

    onFileSelected(fileId: number) {
        if (this.lessonEditor) {
            this.lessonEditor.onFileSelected(fileId);
        }
    }

    render() {
        if (!this.state.book || !this.state.chapter || !this.state.lesson) {
            return <BubbleLoader />
        }

        var breadCrumbs: JSX.Element | string = "";

        if (this.props.match.params.bookId) {
          const bookId = this.props.match.params.bookId;
    
          const links: BreadcrumbsLink[] = [
            { name: this.state.book.name, path: PATH_EDIT_BOOK_TABLE_OF_CONTENTS + '/' + bookId },
            { name: this.state.chapter.name },
            { name: this.state.lesson.name }
          ];
    
          breadCrumbs = <BookBreadcrumbs links={links} />;
        }
        
        return <>

        <EditorPage>
            <div>
            {breadCrumbs}
            </div>
            <EditorSplit>
                <EditorSidebar width={"350px"}>
                        <LessonEditorSidebar
                            rootFolderId={this.state.book.oo_folder_id || 0}
                            onTextSelected={this.onTextSelected.bind(this)}
                            onFileSelected={this.onFileSelected.bind(this)}
                        />
                </EditorSidebar>
                <EditorPanel>

                    <LessonEditor 
                        ref={(lessonEditor) => this.lessonEditor = lessonEditor}
                        book={this.state.book}
                        chapter={this.state.chapter}
                        lesson={this.state.lesson}
                    />
                </EditorPanel>
            </EditorSplit>
        </EditorPage>     
      </>
    }

    private onStickToTop(mainDiv: HTMLElement | null) {
        const sidebarDiv: HTMLElement = this.refs["div-sidebar"] as HTMLElement;
    
        if (mainDiv) {
    
          window.addEventListener("scroll", () => {
            var top = mainDiv.getBoundingClientRect().top;
            if (top < 75) top = 75;
            sidebarDiv.style.top = top.toFixed() + "px";
          });
    
          var top = mainDiv.getBoundingClientRect().top;
          if (top < 75) top = 75;
          sidebarDiv.style.top = top.toFixed() + "px";
        }
    }

}
