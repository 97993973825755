import * as React from 'react';
import { __ } from '@src/translation';

import { Accordion, FAccordion, AccordionItem, FAccordionItem, ActionAccordionItem, FActionAccordionItem, AccordionActionType, IAccordionAction } from '@src/component/ui/Accordion';


export class Accordions extends React.Component<{}, {}> {

    render() {

        {
            var actions : IAccordionAction[];
                actions = [
                    {
                        title: __("Törlés"), 
                        type : AccordionActionType.Trash,
                        action: ()=> {}                               
                    },
                    {
                        title: __("Fel"), 
                        type : AccordionActionType.Up,
                        action: ()=> {}                                   
                    },
                    {
                        title: __("Le"), 
                        type : AccordionActionType.Down,
                        action: ()=> {}                                   
                    }
                ];


                // Latin placeholder text, do not translate
                var content = <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas et eleifend dolor, et lacinia mi. Integer id commodo nisl. Nunc ex eros, ultrices eu convallis nec, mollis sit amet urna. Duis posuere ullamcorper metus, ut pulvinar sapien interdum tempus. Sed nec nisl nec ipsum tincidunt pretium. Nulla posuere augue augue, sed tincidunt odio posuere id. Sed fringilla dolor tristique, vulputate eros eu, accumsan nisi. Morbi rhoncus mi orci, id efficitur felis vulputate sed. Sed vel nisl eget ex vestibulum condimentum et at justo.
                    Pellentesque cursus luctus bibendum. Nullam ut erat vel orci lobortis pretium. Interdum et malesuada fames ac ante ipsum primis in faucibus. Etiam hendrerit congue ante, at maximus lectus facilisis non. Cras ut sollicitudin odio. Sed pellentesque neque quis nunc efficitur consectetur. Proin hendrerit, est vel pretium laoreet, mi ipsum posuere urna, quis pellentesque diam ante at diam. Proin ut tellus aliquam, sodales purus in, dignissim magna. Aliquam bibendum volutpat nisi, eu elementum odio feugiat eu. Sed imperdiet lorem eget massa fringilla, at posuere sapien vehicula. Curabitur nisl diam, molestie et semper iaculis, mollis eu nulla. Phasellus in augue tincidunt, tristique nisi eu, efficitur eros.
                </p>;
                                            
        }

        return <>                

                <h3>multiExpand=true allowAllClosed=true</h3>

                <Accordion multiExpand={true} allowAllClosed={true}>
                    <AccordionItem key={1} title={"Title"}>
                    {content}
                    </AccordionItem>
                    <AccordionItem key={2} defaultClosed title={"Title2"}>   
                    {content}                 
                    </AccordionItem>
                    <AccordionItem key={3} defaultClosed title={"Title3"}>
                    {content}
                    </AccordionItem>
                </Accordion>

                <h3> multiExpand=false allowAllClosed=false</h3>

                <Accordion multiExpand={false} allowAllClosed={false}>
                    <AccordionItem key={1} title={"Title"}>
                    {content}
                    </AccordionItem>
                    <AccordionItem key={2} defaultClosed title={"Title2"}>   
                    {content}                 
                    </AccordionItem>
                    <AccordionItem key={3} defaultClosed title={"Title3"}>
                    {content}
                    </AccordionItem>
                </Accordion>

                <h3>actions=actions</h3>

                <Accordion multiExpand={false} allowAllClosed={false}>
                    <ActionAccordionItem defaultClosed actions={actions} title={"Title"}>
                    {content}
                    </ActionAccordionItem>
                    <ActionAccordionItem defaultClosed actions={actions} title={"Title2"}>   
                    {content}                 
                    </ActionAccordionItem>
                    <ActionAccordionItem defaultClosed actions={actions} title={"Title3"}>
                    {content}
                    </ActionAccordionItem>
                </Accordion>

                <h3>Foundation accordion</h3>

                <FAccordion>
                    <FAccordionItem defaultClosed title={"Title"}>
                    {content}
                    </FAccordionItem>
                    <FAccordionItem defaultClosed title={"Title2"}>  
                    {content}                  
                    </FAccordionItem>
                    <FAccordionItem defaultClosed title={"Title3"}>
                    {content}
                    </FAccordionItem>
                </FAccordion>

                <FAccordion>
                    <FActionAccordionItem defaultClosed actions={actions} title={"Title"}>
                    {content}
                    </FActionAccordionItem>
                    <FActionAccordionItem defaultClosed actions={actions} title={"Title2"}>  
                    {content}                  
                    </FActionAccordionItem>
                    <FActionAccordionItem defaultClosed actions={actions} title={"Title3"}>
                    {content}
                    </FActionAccordionItem>
                </FAccordion>
            </>;

    }

}
