import * as React from 'react';

import AdminRoute from '@src/Routes'

import  { viewTaskClassProxy } from '@src/framework/view/data_imp/ViewTask';
import TaskListView from './data_imp_task_listview';
import TaskEditor from './data_imp_task_editor';



export function DataImpRoutes() {
    return [
        <AdminRoute path={viewTaskClassProxy.getDefaultListRoute()} component={TaskListView} key="TaskListView"/>,
        <AdminRoute path={viewTaskClassProxy.getDefaultEditRoute()} component={TaskEditor} key="TaskEditor"/>,
    ];
}