import * as React from 'react';
import BookCrud from '@src/framework/crud/doc/BookCrud';
import PubBookCrud from '@src/framework/crud/doc_pub/BookCrud';
import { Link } from 'react-router-dom';
import { showUnpublished } from '@src/component/book/viewer/PublishedSelector';
import { LIBRARY_OFI_OFFICIAL_ID } from '@src/Const';
import { url } from 'inspector';
import { PATH_BOOK_VIEW_EDITING } from '@src/Routes';
import { __ } from '@src/translation';
import { timeoutAsync } from '@src/Util';

type BookFooterProps = {
    suggestBooksInGradeId?: number; // Az adott évfolyamból adjon könyv linkeket
    uriSegment?: string;
    bookpath?: string;
}

type BookFooterState = {
    backgroundStyle: any;
    books: any[];
}

export default class BookFooter extends React.Component<BookFooterProps, BookFooterState> {

    constructor(props: BookFooterProps) {
        super(props);
        this.state = { backgroundStyle: {}, books: [] };
    }

    render() {
        return <div>
            <div className="footer-image"><div style={this.state.backgroundStyle} ></div></div>
            <div className="book-footer">
            </div>
            <nav className="row footer-navigation" title={__("Tankönyvválasztó")}>

                <div className="columns small-12 medium-12 large-9">
                    <ul className="book-list-wrapper">
                        {
                            // this.getColumns(this.state.books);
                            this.state.books.map((book: any, index: number) => {
                                if (!book.name) return;
                                return <li key={"f" + index}><Link to={"/tankonyv/" + book.uri_segment + "/"}>{book.name}</Link></li>
                            })
                        }
                    </ul>
                </div>
            </nav>
        </div>
    }

    async componentDidMount() {
        if (!this.props.suggestBooksInGradeId) {
            return;
        }

        var books;
        if (showUnpublished()) {
            books = await BookCrud.list({ filter: { grade_id: this.props.suggestBooksInGradeId, is_active: true, library_id: LIBRARY_OFI_OFFICIAL_ID }, order_by: "name" });
        } else {
            books = await PubBookCrud.list({ filter: { grade_id: this.props.suggestBooksInGradeId, library_id: LIBRARY_OFI_OFFICIAL_ID }, order_by: "name" });
        }
        this.preloadFooter();
        this.setState({ books: books });
    }

    getFooterImageUrl() {
        const footerFileName = this.props.uriSegment + ".png";
        var url = undefined;
        if (footerFileName) {
            url = this.props.bookpath + 'img/FOOTER/' + footerFileName;
        }
        return url;
    }

    componentDidUpdate(prevProps: BookFooterProps) {
        if(prevProps.uriSegment != this.props.uriSegment) {
            this.preloadFooter();
        }
    }

    private async preloadFooter() {
        const uriToLoad = this.props.uriSegment;
        const url = this.getFooterImageUrl();
        if (url) {
            const backgroundStyle = {
                backgroundImage: `url('${url}')`,
                backgroundPosition: 'center bottom',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'contain',
                marginRight: 'auto',
                marginLeft: 'auto',
                height: '13vw',
                maxWidth: '1170px'
            };

            var image = new Image();
            image.onload = async () => {
                if (uriToLoad == this.props.uriSegment) {
                    this.setState({ backgroundStyle });
                }
                image.remove();
            };
            image.src = url;

            if (!image.complete) {
                await timeoutAsync(300);
            }

            if (uriToLoad == this.props.uriSegment) {
                this.setState({ backgroundStyle });
            }
        } else {
            this.setState({
                backgroundStyle: { height: 'auto' }
            });
        }
    }

    getBookList(books: Array<any>) {
        return <div>
            <ul>
                {
                    books.map
                }
            </ul>
        </div>
    }

    getColumns(books: Array<any>) {
        if (books.length >= 6) {

            let sliceNum: number;

            let rem = books.length % 3;
            sliceNum = Math.floor(books.length / 3);
            if (rem > 0) {
                sliceNum++;
            }

            return <div className="row">
                <div className="columns small-12 medium-6 large-4">
                    <ul>
                        {
                            books.slice(0, sliceNum).map((book: any, index: number) => {
                                if (!book.name) return;
                                return <li key={index}><Link to={"/tankonyv/" + book.uri_segment + "/"}>{book.name}</Link></li>

                            })
                        }
                    </ul>
                </div>
                <div className="columns small-12 medium-6 large-4">
                    <ul>
                        {
                            books.slice(sliceNum, (sliceNum * 2)).map((book: any, index: number) => {
                                if (!book.name) return;
                                return <li key={index}><Link to={"/tankonyv/" + book.uri_segment + "/"}>{book.name}</Link></li>
                            })
                        }
                    </ul>
                </div>
                <div className="columns small-12 medium-6 large-4">
                    <ul>
                        {
                            books.slice((sliceNum * 2)).map((book: any, index: number) => {
                                if (!book.name) return;
                                return <li key={index}><Link to={"/tankonyv/" + book.uri_segment + "/"}>{book.name}</Link></li>
                            })
                        }
                    </ul>
                </div>
            </div>
        }
        else if (books.length > 3) {
            return <div className="row">
                <div className="columns small-12 medium-6 large-6">
                    <ul>
                        {
                            books.slice(0, (books.length / 2) + 1).map((book: any, index: number) => {
                                if (!book.name) return;
                                return <li key={index}><Link to={"/tankonyv/" + book.uri_segment + "/"}>{book.name}</Link></li>
                            })
                        }
                    </ul>
                </div>
                <div className="columns small-12 medium-6 large-6">
                    <ul>
                        {
                            books.slice((books.length / 2) + 1).map((book: any, index: number) => {
                                if (!book.name) return;
                                return <li key={index}><Link to={"/tankonyv/" + book.uri_segment + "/"}>{book.name}</Link></li>
                            })
                        }
                    </ul>
                </div>
            </div>
        }
        else {
            return <div className="row">
                <div className="columns small-12 large-12">
                    <ul>
                        {
                            this.state.books.map((book: any, index: number) => {
                                if (!book.name) return;
                                return <li key={index}><Link to={"/tankonyv/" + book.uri_segment + "/"}>{book.name}</Link></li>
                            })
                        }
                    </ul>
                </div>
            </div>
        }

    }

}


