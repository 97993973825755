import { Server } from '../../server/Server';
import Crud , { registerCrudClassProxy, IAsTextResult, IListParams, CrudClassProxy, ISearchTextParams, ISearchTextResult } from '../Crud';

export interface IHtrTicketRecord {
    /**
     * Globálisan egyedi azonosító.
     * 
     * Ha új rekordot akarsz létrehozni put() metódussal, akkor hagyd üresen.
     * upsert() hívásnál figyelmen kívül van hagyva az értéke.
     *
     */
    id ?: number;
    /**
     * Rekord létrehozás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel létrehozásakor.
     */
    creation_time?: string /*timestamp?*/;
    /**
     * Ki hozta létre
     *
     * NOT NULL -> sys.sec_user ON DELETE noaction
     *
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozó felhasználóra hivatkozik.
     */
    creation_user_id?: number;
    /**
     * Melyik session-ből jött létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozáshoz használt munkamenetre hivatkozik.
     */
    creation_session_id?: number|null;
    /**
     * Utolsó módosítás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel módosításakor.
     */
    modification_time?: string /*timestamp?*/|null;
    /**
     * Ki módosította utoljára
     *
     * -> sys.sec_user ON DELETE noaction
     *
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosító felhasználóra hivatkozik.
     */
    modification_user_id?: number|null;
    /**
     * Melyik session-ből módosították utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosításhoz használt munkamenetre hivatkozik.
     */
    modification_session_id?: number|null;
    /**
     * Aktív
     *
     * Logikai törléshez használt mező.
     */
    is_active?: boolean;
    /**
     * Ügyiratszám
     */
    no?: string;
    /**
     * HTR bejelentés kategória
     *
     * NOT NULL -> ticket.htr_ticket_category ON DELETE noaction
     *
     */
    htr_ticket_category_id?: number;
    /**
     * Beküldő/Hallgató
     *
     * NOT NULL -> sys.sec_user ON DELETE noaction
     *
     */
    sec_user_id?: number;
    /**
     * Tárgy
     */
    subject?: string;
    /**
     * Az bejelentés könyvtára
     *
     * -> media.oo_folder ON DELETE noaction
     *
     *
     * A bejelentés saját virtuális könyvtára, ahová a résztvevők tudnak feltölteni állományokat
     */
    oo_folder_id?: number|null;
}

/**
 *  ticket.htr_ticket - HTR bejelentés
 *
 *  Idősoros táblázat, ami az összes tétel összes korábbi verzióját eltárolja.
 */

export default class HtrTicketCrud extends Crud<IHtrTicketRecord> {
    public static TABLE_INFO_ID = 943760127;
    public static getSchemaNameForClass() { return 'ticket'; }
    public static getTableNameForClass() { return 'htr_ticket'; }

    public static load: (id: number, aServer ?: Server) => Promise<HtrTicketCrud>;
    public static asText: (id: number, includeRecord : boolean, aServer ?: Server) => Promise<IAsTextResult<IHtrTicketRecord>>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<IHtrTicketRecord[]>;
    public static searchText: (params: ISearchTextParams, aServer ?: Server) => Promise<ISearchTextResult<IHtrTicketRecord>[]>;
    public static deleteById: (id: number, aServer ?: Server) => Promise<IHtrTicketRecord>;
    public static unDeleteById: (id: number, aServer ?: Server) => Promise<IHtrTicketRecord>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class HtrTicketCrudClassProxy extends CrudClassProxy<IHtrTicketRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const htrTicketCrudClassProxy = new HtrTicketCrudClassProxy(HtrTicketCrud);
registerCrudClassProxy(htrTicketCrudClassProxy);
