import { Server } from '../../server/Server';
import Crud , { registerCrudClassProxy, IAsTextResult, IListParams, CrudClassProxy, ISearchTextParams, ISearchTextResult } from '../Crud';

export interface IKipDraftGroupTaskItemRecord {
    /**
     * Globálisan egyedi azonosító.
     * 
     * Ha új rekordot akarsz létrehozni put() metódussal, akkor hagyd üresen.
     * upsert() hívásnál figyelmen kívül van hagyva az értéke.
     *
     */
    id ?: number;
    /**
     * Rekord létrehozás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel létrehozásakor.
     */
    creation_time?: string /*timestamp?*/;
    /**
     * Ki hozta létre
     *
     * NOT NULL -> sys.sec_user ON DELETE noaction
     *
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozó felhasználóra hivatkozik.
     */
    creation_user_id?: number;
    /**
     * Melyik session-ből jött létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozáshoz használt munkamenetre hivatkozik.
     */
    creation_session_id?: number|null;
    /**
     * Utolsó módosítás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel módosításakor.
     */
    modification_time?: string /*timestamp?*/|null;
    /**
     * Ki módosította utoljára
     *
     * -> sys.sec_user ON DELETE noaction
     *
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosító felhasználóra hivatkozik.
     */
    modification_user_id?: number|null;
    /**
     * Melyik session-ből módosították utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosításhoz használt munkamenetre hivatkozik.
     */
    modification_session_id?: number|null;
    /**
     * Aktív
     *
     * Logikai törléshez használt mező.
     */
    is_active?: boolean;
    /**
     * Sorrend
     */
    no?: number /*integer*/|null;
    /**
     * DFHT KIP óraterv csoportfeladat
     *
     * NOT NULL -> kap.kip_draft_group_task ON DELETE cascade
     *
     */
    kip_draft_group_task_id?: number;
    /**
     * Tanuló neve
     */
    student_name?: string|null;
    /**
     * Egyéni feladat leírás
     */
    description?: string;
}

/**
 *  kap.kip_draft_group_task_item - DFHT KIP óraterv csoportfeladat elem
 *
 *  Idősoros táblázat, ami az összes tétel összes korábbi verzióját eltárolja.
 */

export default class KipDraftGroupTaskItemCrud extends Crud<IKipDraftGroupTaskItemRecord> {
    public static TABLE_INFO_ID = 1958973052;
    public static getSchemaNameForClass() { return 'kap'; }
    public static getTableNameForClass() { return 'kip_draft_group_task_item'; }

    public static load: (id: number, aServer ?: Server) => Promise<KipDraftGroupTaskItemCrud>;
    public static asText: (id: number, includeRecord : boolean, aServer ?: Server) => Promise<IAsTextResult<IKipDraftGroupTaskItemRecord>>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<IKipDraftGroupTaskItemRecord[]>;
    public static searchText: (params: ISearchTextParams, aServer ?: Server) => Promise<ISearchTextResult<IKipDraftGroupTaskItemRecord>[]>;
    public static deleteById: (id: number, aServer ?: Server) => Promise<IKipDraftGroupTaskItemRecord>;
    public static unDeleteById: (id: number, aServer ?: Server) => Promise<IKipDraftGroupTaskItemRecord>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class KipDraftGroupTaskItemCrudClassProxy extends CrudClassProxy<IKipDraftGroupTaskItemRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const kipDraftGroupTaskItemCrudClassProxy = new KipDraftGroupTaskItemCrudClassProxy(KipDraftGroupTaskItemCrud);
registerCrudClassProxy(kipDraftGroupTaskItemCrudClassProxy);
