import { Module, ModuleMenu, BaseMenu, ModuleRoute } from "../Module";
import { __ } from "@src/translation";

import Error404Page from "@src/component/Error404Page";
import SearchPage, { ElasticContentType } from "./component/SearchPage";
import DetailedSearch from "@src/component/search/DetailedSearch";
import { CrudClassProxy, IRecord } from "@src/framework/crud/Crud";
import { SearchResult, SearchParameters } from "@src/server/PublicServer";

export let searchModule: SearchModule<BaseMenu, SearchResult, SearchParameters>;

export type SearchFilterType = {
    elasticType: ElasticContentType | string,
    name: string,
    iconClassName: string,
}

export interface SearchApi<R,P>  {
    api: (params: P)=>Promise<R[]>,
    filterParams?: {
        filterParamName: string;
        crudClassProxy: CrudClassProxy<IRecord>;
        emptyTitle: string;
        displayFieldName: string;
        filterActionName: string;
        filterDependencyParams?: string[];
        //onFilter?: (selected: any, filterParams: {name:string, value: any}[]) => boolean,
    }[]
}

export interface SearchModuleConfig<R, P>  {

     enableSearchPage?: boolean;
     elasticSearchTypes?: ElasticContentType[];
     enableFilterButtons?: boolean;
     enableFilterSelects?: boolean;
     enableSpecialSearch?: boolean;
     filterTypes?: SearchFilterType[];
     searchApi?: SearchApi<R, P>;
     paths?: any;
     searchResultComponent?: any;
     
}

export class SearchModule<T extends BaseMenu, R extends SearchResult, P extends SearchParameters> extends Module<T> {

    private config: SearchModuleConfig<R, P>;
    public getConfig()
    {
        return this.config;
    }
    
    public PATH_SEARCH_PAGE = "/kereses";
    public PATH_SEARCH_SPECIAL = "/reszletes";
    
     

    constructor(config: SearchModuleConfig<R, P>) {
        super();

        this.config = config;
        this.PATH_SEARCH_PAGE = (config.paths && config.paths["PATH_SEARCH_PAGE"]) || "/kereses";
        this.PATH_SEARCH_SPECIAL = (config.paths && config.paths["PATH_SEARCH_SPECIAL"]) || "/reszletes";
        ///
    }

    public static initGlobal<T extends BaseMenu, R extends SearchResult, P extends SearchParameters>(config: SearchModuleConfig<R, P>) {
        searchModule = new SearchModule<T,R, P>(config);
    }

    public static initSearch()
    {

    }
    
    public async getRoutesAsync(): Promise<Record<string, ModuleRoute<T>>>
    {
        return this.getRoutes();
    }
 
    public getRoutes(): Record<string, ModuleRoute<T>> {

        let ret: Record<string, ModuleRoute<T>> = {
            'ROUTE_SEARCH_PAGE' : { path: this.PATH_SEARCH_PAGE + "/:keywords?", component: SearchPage, exact: false, hasSidebar: false},                        
        };

        if(this.config.enableSpecialSearch)
        {
            ret["ROUTE_SEARCH_SPECIAL"] = { path: this.PATH_SEARCH_SPECIAL + "/:keywords?", component: DetailedSearch, exact: false, hasSidebar: false};
        }

        return ret;
    }

    public getRecordRoute(tableInfoId: number, recordId: number, edit?:boolean): string|null {
        // TODO!
        return null;
    }

    public getMenus() {
        let ret: ModuleMenu<T>[] = [];

        ret = ret.concat(
        [
            {
                enabled: this.config.enableSearchPage,
                title: __("Keresés"),
                path: this.PATH_SEARCH_PAGE,
                //iconClass: "",
                code: "header-main"
            },
        ]);
  
        return ret;
    }

}
