import { Server } from '../../server/Server';
import View , { registerViewClassProxy, ViewClassProxy } from '../View';
import { IListParams } from '@framework/crud/Crud';

export interface IViewKapOccupationVisitReservationRecord {
    /**
     * Azonosító
     *
     * Azonosító (pótkulcs)
     */
    id?: number;
    /**
     * Rekord létrehozás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel létrehozásakor.
     */
    creation_time?: string /*timestamp?*/;
    /**
     * Ki hozta létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozó felhasználóra hivatkozik.
     */
    creation_user_id?: number;
    /**
     * Melyik session-ből jött létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozáshoz használt munkamenetre hivatkozik.
     */
    creation_session_id?: number|null;
    /**
     * Utolsó módosítás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel módosításakor.
     */
    modification_time?: string /*timestamp?*/|null;
    /**
     * Ki módosította utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosító felhasználóra hivatkozik.
     */
    modification_user_id?: number|null;
    /**
     * Melyik session-ből módosították utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosításhoz használt munkamenetre hivatkozik.
     */
    modification_session_id?: number|null;
    /**
     * Aktív
     *
     * Logikai törléshez használt mező.
     */
    is_active?: boolean;
    /**
     * Alprogrami csoport szintű foglalkozás látogatás
     */
    occupation_visit_id?: number;
    /**
     * Alkalmazás szintű felhasználó
     */
    sec_user_id?: number;
    /**
     * Bejelentkezési név
     *
     * Bejelentkezési név
     */
    login_name?: string|null;
    /**
     * E-mail
     *
     * E-mail
     */
    email?: string|null;
    /**
     * Teljes név
     *
     * Teljes név
     */
    fullname?: string|null;
    /**
     * Aktív felhasználó
     *
     * Aktív felhasználó
     */
    user_is_active?: boolean|null;
}

/**
 *  kap.view_kap_occupation_visit_reservation - Pedagógus jelentkezés alprogrami szintű foglalkozásra
 *
 *  Idősoros táblázat, ami az összes tétel összes korábbi verzióját eltárolja.
 */

export default class ViewKapOccupationVisitReservation extends View<IViewKapOccupationVisitReservationRecord> {
    public static VIEW_INFO_ID = 2019100202;

    public static getSchemaNameForClass(): string { return 'kap'; }
    public static getViewNameForClass(): string { return 'view_kap_occupation_visit_reservation'; }
    public static getTableNameForClass(): string|null { return 'occupation_visit_reservation'; }

    public static load: (id: number, aServer ?: Server) => Promise<ViewKapOccupationVisitReservation>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<IViewKapOccupationVisitReservationRecord[]>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class ViewKapOccupationVisitReservationClassProxy extends ViewClassProxy<IViewKapOccupationVisitReservationRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const viewKapOccupationVisitReservationClassProxy = new ViewKapOccupationVisitReservationClassProxy(ViewKapOccupationVisitReservation);
registerViewClassProxy(viewKapOccupationVisitReservationClassProxy);

