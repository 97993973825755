import { debounce } from 'lodash';
import { observable, ObservableMap, action, runInAction, computed, toJS, IObservableArray } from "mobx";
import * as uuidv4 from 'uuid/v4';

import ForumTopicCrud, { IForumTopicRecord } from "@src/framework/crud/usr/ForumTopicCrud";
import { app } from '@src/index';
import ForumCrud, { IForumRecord } from '@src/framework/crud/usr/ForumCrud';
import { forumAPI, IForumMessage, IForumMessageModification, IForumMessageModificationFlags } from './forumAPI';
import { me } from '@src/framework/server/Auth';
import { __ } from '@src/translation';
import ViewUsrForumMember, { IViewUsrForumMemberRecord } from '@src/framework/view/usr/ViewUsrForumMember';
import { ForumPermTypes } from './ForumConst';
import { parseJwt } from '../notifierModule/NotifierJWT';
import { notifierModule } from '../notifierModule/notifierModule';
import { NotifierCluster } from '../notifierModule/NotifierCluster';


// Belső, ne használd.
const modifyMessageInplace = (message: IForumMessage, modifications: IForumMessageModification): IForumMessage => {
    if (modifications.message !== undefined) {
        message.message = modifications.message;
    }
    if (modifications.data !== undefined) {
        message.data = modifications.data;
    }
    return message;
}

/*
    Ez egy olyan objektum, ami egy "fórum konténer" állapotát tudja tárolni.
    Ez nagyjából olyan mintha a ForumContainer nevű komponens state-je lenne,
    kivéve hogy nem a FordumContainer.state -ben van, hanem egy külön külső
    objektumban.

    Ez azért jó, mert a ForumContainer csak egy konténer, ami több különböző
    fórumos rész-komponenst tartalmaz (topic lista, tagok listája, üzenetek stb.)
    
    Ezek a rész-komponensek tetszőlegesen elhelyezhetők a felületen bárhová.
    Nem kell egy ForumContainer -en belül lenniük, mivel az adatokat nem
    az őket tartalmazó React Component tárolja, hanem ez a külső objektum.

    Mivel a megbeszéléseken nem tudtuk eldönteni, hogy mi hol legyen a felületen,
    ezért egy olyan megoldást készíttem, aminél az egyes felületi elemek tetszőlegesen
    áthelyezhetők (sőt akár több példány is lehet belőlük egy felületen).

*/
export class ForumContainerStore {
    @observable private _forumId: number | null; // Melyik fórumot jelenítjük meg

    @observable public loading: boolean = false; // Akkor igaz, ha az adatok betöltése folyamatban van.
    @observable public loaded: boolean = false; // Akkor igaz, ha az adatok betöltése befejeződött.
    @observable public forum: IForumRecord | null = null; // A kiválasztott fórum adatai

    @observable public topicId: number | null = null; // Melyik topic van kiválasztva
    @observable public replyToId: string | null = null; /* Melyik üzenetre akarunk válaszolni. bson ObjectId */
    @observable public message: string = ""; /* Az ÚJ üzenet szövege amit el akarunk küldeni. */
    @observable public data?: Object = undefined; /* Az ÚJ üzenet adata amit el akarunk küldeni. */



    private _debounced_reload: () => Promise<void>;

    constructor() {
        this._debounced_reload = debounce(this.asyncReload, 300);
    }

    @computed public get forumId() {
        return this._forumId;
    }

    /** 
        Fórum módosítása.

        A fórum konténerek általában nem fognak fórumok között váltogatni, ezért ez általában csak
        egyszer, a konténer állapot létrehozásakor lesz meghívva.
    */
    public set forumId(value: number | null) {
        if (this._forumId !== value) {
            runInAction('forumId', () => {
                this._forumId = value;
                this.reload();
            });
        }
    }

    /* Téma váltás */
    @action.bound public changeTopic(topicId: number | null) {
        this.topicId = topicId;
    }



    /**
     * Fórum adatok betöltése (elindítja a betöltést a háttérben).
     */
    @action.bound reload() {
        this.loaded = false;
        this.loading = true;
        this._debounced_reload();
    }


    private async asyncReload(): Promise<void> {
        try {
            const forums = await ForumCrud.list({
                filter: {
                    id: this._forumId,
                    is_active: true
                }, order_by: "title"
            });
            runInAction(() => {
                this.loading = false;
                this.loaded = true;
                this.forum = forums.length ? forums[0] : null;
                if (this.forum === null) {
                    this.topicId = null;
                } else {
                    this.topicId = this.forum.default_topic_id || null;
                }
                this.replyToId = null;
            })
        } catch (e) {
            app.showErrorFromJsonResult(e);
            runInAction(() => {
                this.loading = false;
                this.loaded = false;
                this.forum = null;
                this.topicId = null;
                this.replyToId = null;
            })
        }
    }
}

/*
    Egy adott fórum topic listáját reprezentálja.    
*/
export class TopicListStore {
    public forumId: number;

    @observable public loading: boolean = false;
    @observable public loaded: boolean = false;
    @observable public topics: IForumTopicRecord[] = [];

    private _debounced_reload: () => Promise<void>;

    constructor(forumId: number) {
        this.forumId = forumId;
        this._debounced_reload = debounce(this.asyncReload, 300);

    }

    @action.bound reload() {
        this.loaded = false;
        this.loading = true;
        this._debounced_reload();
    }

    private async asyncReload(): Promise<void> {
        try {
            const topics = await ForumTopicCrud.list({
                filter: {
                    forum_id: this.forumId,
                    is_active: true
                }, order_by: "title"
            });
            runInAction(() => {
                this.loading = false;
                this.loaded = true;
                this.topics = topics;
            })
        } catch (e) {
            app.showErrorFromJsonResult(e);
            runInAction(() => {
                this.loading = false;
                this.loaded = false;
                this.topics = [];
            })
        }
    }

}

/**
    Egy adott fórum tagjait reprezenálja.

    - a tagok nevét azonosító alapján gyorsan el tudod érni
    - ezen keresztül tudod lekérdezni, hogy mire van joga a tagoknak, illetve mire van jogod neked

*/
export class MemberListStore {
    public forumId: number;

    @observable public loading: boolean = false;
    @observable public loaded: boolean = false;
    @observable public members: IViewUsrForumMemberRecord[] = [];
    @observable public memberById: ObservableMap<number, IViewUsrForumMemberRecord> = observable.map({});

    /**
     * Egy adott member jogai az adott fórumon belül.
     * 
     * @param userId A felhasználó azonosítója. Ha nincs megadva, akkor az aktuálisan bejelentkezett
     *      felhasználót nézi.
     */
    public getMemberPermissions(userId?: number): ForumPermTypes[] {
        if (userId === undefined && me) {
            userId = me.id;
        }
        if (userId !== undefined && this.memberById.has(userId)) {
            return this.memberById.get(userId)!.perms!;
        } else {
            return [];
        }
    }

    /**
     * Megmondja, hogy egy adott member rendelkezik-e a megadott joggal az adott fórumon belül.
     * 
     * @param permType A jogosultság típusa
     * @param userId A felhasználó azonosítója. Ha nincs megadva, akkor az aktuálisan bejelentkezett
     *      felhasználót nézi.
     * 
     */
    public hasPermission(permType: ForumPermTypes, userId?: number): boolean {
        return this.getMemberPermissions(userId).includes(permType);
    }

    /** Tudok-e létrehozni új témát? */
    @computed public get canCreateTopic(): boolean {
        return this.hasPermission(ForumPermTypes.TOPIC_CREATE_ID);
    }

    /** Tudok-e módosítani létező témát? */
    @computed public get canModifyTopic(): boolean {
        return this.hasPermission(ForumPermTypes.TOPIC_MODIFY_ID);
    }

    /** Tudok-e törölni létező témát? */
    @computed public get canDeleteTopic(): boolean {
        return this.hasPermission(ForumPermTypes.TOPIC_DELETE_ID);
    }

    /** Tudok-e beküldeni új üzenetet? */
    @computed public get canSendMessage(): boolean {
        return this.hasPermission(ForumPermTypes.MESSAGE_SEND_ID);
    }

    /** Tudom-e módosítani a saját üzenetemet? */
    @computed public get canModifyMyMessage(): boolean {
        return this.hasPermission(ForumPermTypes.MESSAGE_MODIFY_MINE_ID);
    }

    /** Tudom-e törölni a saját üzenetemet? */
    @computed public get canRemoveMyMessage(): boolean {
        return this.hasPermission(ForumPermTypes.MESSAGE_REMOVE_MINE_ID);
    }

    /** Tudom-e módosítani valaki más üzenetét? */
    @computed public get canModifyOtherMessage(): boolean {
        return this.hasPermission(ForumPermTypes.MESSAGE_MODIFY_OTHER_ID);
    }

    /** Tudom-e törölni mások üzenetét? */
    @computed public get canRemoveOtherMessage(): boolean {
        return this.hasPermission(ForumPermTypes.MESSAGE_REMOVE_OTHER_ID);
    }

    /** Tudom-e fölvenni új tagot? */
    @computed public get canAddMember(): boolean {
        return this.hasPermission(ForumPermTypes.MEMBER_ADD_ID);
    }

    /** Tudok-e tagságot megszüntetni? */
    @computed public get canRemoveMember(): boolean {
        return this.hasPermission(ForumPermTypes.MEMBER_REMOVE_ID);
    }

    private _debounced_reload: () => Promise<void>;

    constructor(forumId: number) {
        this.forumId = forumId;
        this._debounced_reload = debounce(this.asyncReload, 300);

    }

    @action.bound reload() {
        this.loaded = false;
        this.loading = true;
        this._debounced_reload();
    }

    private async asyncReload(): Promise<void> {
        try {
            const members = await ViewUsrForumMember.list({
                filter: { forum_id: this.forumId }, order_by: "fullname"
            });
            const memberById = new Map<number, IViewUsrForumMemberRecord>();
            members.forEach(member => {
                memberById.set(member.sec_user_id!, member);
            })
            runInAction(() => {
                this.loading = false;
                this.loaded = true;
                this.members = members;
                this.memberById.clear();
                this.memberById.replace(memberById);
            })
        } catch (e) {
            app.showErrorFromJsonResult(e);
            runInAction(() => {
                this.loading = false;
                this.loaded = false;
                this.members = [];
                this.memberById.clear();
            })
        }
    }
}

/**
 * Egy adott topic üzeneteit reprezentálja.
 * 
 * Elsősorban ezen keresztül kell küldeni és lekérdezni az üzeneteket.
 * A ForumAPI-t erre ne használd, az alacsony szintű!
 * 
 */
export class MessageListStore {
    public forumId: number;
    public topicId: number;

    @observable public loading: boolean = false;
    @observable public loaded: boolean = false;
    /**
     * messages stores the forum messages order by "created desc".
     * E.g. the last (most recent) message is at messages[0].
     */
    @observable public messages: IObservableArray<IForumMessage> = observable.array([]);
    /** Messages can be accessed by their unique id. */
    @observable public messageById: ObservableMap<string, IForumMessage> = observable.map({});
    @observable public lastFetched: string | null = null; /* last query time on the server */

    @observable public pendingModifications: ObservableMap<string, IForumMessageModification> = observable.map({});

    /** Easy access to the members of this topic. */
    @computed public get memberStore(): MemberListStore {
        return forumStore.getMemberListStore(this.topicId)
    }

    private _debounced_reload: () => Promise<void>;

    constructor(forumId: number, topicId: number) {
        this.forumId = forumId;
        this.topicId = topicId;
        this._debounced_reload = debounce(this.asyncReload, 300);

    }

    // Betölti az üzeneteket (feltétel nélkül újratölti).
    @action.bound reload() {
        this.loaded = false;
        this.loading = true;
        this.pendingModifications.clear();
        this._debounced_reload();
    }

    // Betölti az üzeneteket, ha még nem voltak betöltve.
    @action.bound load() {
        if (!this.loaded && !this.loading) { this.reload(); }
    }

    private onMessage = (messageBody: Object) => {
        console.log("Forum message arrived!", messageBody);
    }

    private async asyncReload(): Promise<void> {
        try {
            if (this.forumId) {                
                const cluster = notifierModule.cluster;
                if (cluster) {
                    const channel = 'forum/'+this.forumId+"//";
                    if (!cluster.isSubscribed(channel)) {
                        const provider = (channel: string) => { return forumAPI.obtainToken(this.forumId); }
                        cluster.subscribe(channel, provider, this.onMessage);
                    }
                }
            }

            const result = await forumAPI.getMessages({ topicId: this.topicId });
            // getMessages returns the most recent messages by default, and limits the number to 1000.
            // We want to most recent to be on the bottom (not the top) of the screen.
            // We reverse the array so that the array and all if its slices can be map()-ed to JSX
            // comfortably.
            result.messages.reverse();
            const messageById = new Map();
            runInAction('asyncReload', () => {
                this.loading = false;
                this.loaded = true;
                this.lastFetched = result.now;
                this.messages.replace(result.messages);
                result.messages.forEach(message => {
                    messageById.set(message._id!, message);
                })
                this.messageById.replace(messageById);
                this.pendingModifications.clear();
            })
        } catch (e) {
            console.log(e);
            app.showErrorFromJsonResult(e);
            runInAction('asyncReloadFail', () => {
                this.loading = false;
                this.loaded = false;
                this.lastFetched = null;
                this.messages.clear();
                this.messageById.clear();
                this.pendingModifications.clear();
            })
        }
    }

    @action.bound public ensurePending(messageId: string, flags: Partial<IForumMessageModificationFlags>) {
        let messageModification;
        if (this.pendingModifications.has(messageId)) {
            messageModification = this.pendingModifications.get(messageId)!;
        } else {
            const message = this.messageById.get(messageId)!;
            messageModification = {
                id: messageId,
                message: message.message,
                data: message.data,
                editing: false,
                sending: false,
                deleting: false,
                failed : false
            }
        }
        if (flags.editing !== undefined) {
            messageModification.editing = flags.editing;
        }
        if (flags.sending !== undefined) {
            messageModification.sending = flags.sending;
        }
        if (flags.deleting !== undefined) {
            messageModification.deleting = flags.deleting;
        }
        if (flags.failed !== undefined) {
            messageModification.failed = flags.failed;
        }
        this.pendingModifications.set(messageId, messageModification);
    }


    @action.bound public startEditing(messageId: string) {
        this.ensurePending(messageId, { editing: true });
    }

    @action.bound public startSending(messageId: string) {
        this.ensurePending(messageId, { sending: true });
    }

    @action.bound public startDeleting(messageId: string) {
        this.ensurePending(messageId, { deleting: true });
    }

    @action.bound public failedPending(messageId: string) {
        this.ensurePending(messageId, { sending: false, deleting: false, failed: true });
    }

    @action.bound public cancelPending(messageId: string) {
        this.pendingModifications.delete(messageId);
    }

    /** Van-e folyamatban hálózati művelet egy adott üzenettel kapcsolatban. */
    public isOperationPending(messageId: string) : boolean {
        const modification = this.pendingModifications.get(messageId);
        if (modification) {
            return modification.sending || modification.deleting || false;
        } else {
            return false;
        }

    }

    /** Az adott művelethez nyitva van-e az üzenet szerkesztő.. */
    public isEditing(messageId: string) : boolean {
        const modification = this.pendingModifications.get(messageId);
        return (modification && modification.editing) || false;
    }    

    /** Ez majdnem ugyan az mint ami a forumApi -ban van, kivéve hogy ez a helyi adatbázisba is beteszi az üzenetet. */
    public async sendMessage(message: string, replyToId?: string | null, data?: Object | null): Promise<IForumMessage> {
        const tempMessageId: string = uuidv4().toString();
        // TODO: set sending flag???
        const forumMessage: IForumMessage = {
            _id: tempMessageId, // 
            forumId: this.forumId,
            topicId: this.topicId,
            replyToId: replyToId || null,
            message,
            data: data || null,
            userId: me!.id,
            created: __("Éppen most"),
            modified: null,
            modifiedById: null
        };
        let messageIndex: number;
        try {
            runInAction(() => {
                this.messages.push(forumMessage);
                messageIndex = this.messages.length - 1;
                this.messageById.set(tempMessageId, forumMessage);
                this.startSending(tempMessageId);
            });
            const toSave = { ...forumMessage };
            delete toSave._id;
            delete toSave.created;
            delete toSave.forumId;
            const savedMessage = await forumAPI.sendMessage(this.topicId, toSave);
            // Make sure we replace the temp. message that was previously added.
            runInAction(() => {
                const origMessage = this.messages[messageIndex];
                if (origMessage && origMessage._id == tempMessageId) {
                    this.messageById.delete(tempMessageId);
                    this.cancelPending(tempMessageId);
                    this.messageById.set(savedMessage._id, savedMessage);
                    this.messages[messageIndex] = savedMessage;
                } else {
                    this.reload();
                }
            })
            return Promise.resolve(savedMessage);
        } catch (e) {
            console.log(e);
            app.showErrorFromJsonResult(e);
            this.failedPending(tempMessageId);
            return Promise.reject(e);
        }
    }


    /**
     * Módosít egy üzenetet a store-on belül.
     * 
     * Ha azt akarod, hogy a változások látszódjanak a felületi elemeken, akkor ezt kell használnod! 
     * 
     */
    public async modifyMessage(modifications: IForumMessageModification): Promise<IForumMessage> {
        // Ez itt nem hatékony, ki tudunk rá találni valami mást?
        const messageIndex = this.messages.findIndex((message) => message._id == modifications.id);
        if (messageIndex < 0) {
            return Promise.reject("Nincs ilyen üzenet, id=" + modifications.id);
        }
        if (!this.messageById.has(modifications.id)) {
            return Promise.reject("Belső hiba, nincs ilyen üzenet, id=" + modifications.id);
        }
        try {
            runInAction(() => {
                const modifiedMessage = modifyMessageInplace(this.messages[messageIndex], modifications);
                this.messages[messageIndex] = modifiedMessage
                this.messageById.set(modifications.id, modifiedMessage);
                this.startSending(modifications.id);
            });
            const savedMessage = await forumAPI.modifyMessage(modifications);
            if (savedMessage===null) {
                app.showError(__("Hiba"), __("Az üzenet nem található."));
                this.cancelPending(modifications.id);
                return Promise.reject();
            }
            // Make sure we replace the temp. message that was previously added.
            runInAction(() => {
                this.cancelPending(modifications.id);
                if (this.messages[messageIndex]._id == modifications.id) {
                    this.messages[messageIndex] = savedMessage;
                    this.messageById.set(modifications.id, savedMessage);
                } else {
                    this.reload();
                }
            })
            return Promise.resolve(savedMessage);
        } catch (e) {
            console.log(e);
            this.failedPending(modifications.id);
            app.showErrorFromJsonResult(e);
            return Promise.reject(e);
        }
    }

    /** Eltávolít egy üzenetet */
    public async removeMessage(messageId: string): Promise<void> {
        // Ez itt nem hatékony, ki tudunk rá találni valami mást?
        const messageIndex = this.messages.findIndex((message) => message._id == messageId);
        if (messageIndex < 0) {
            return Promise.reject("Nincs ilyen üzenet, id=" + messageId);
        }
        if (!this.messageById.has(messageId)) {
            return Promise.reject("Belső hiba, nincs ilyen üzenet, id=" + messageId);
        }
        try {
            runInAction(() => {
                if (this.pendingModifications.has(messageId)) {
                    this.pendingModifications.delete(messageId);
                }
            })
            await forumAPI.deleteMessage(messageId);
            runInAction(() => {
                if (this.messages[messageIndex]._id != messageId) {
                    this.reload();
                } else {
                    const messageToRemove = this.messages[messageIndex];
                    this.messages.remove(messageToRemove);
                    this.messageById.delete(messageId);
                }
            })
            return Promise.resolve();
        } catch (e) {
            console.log(e);
            app.showErrorFromJsonResult(e);
            return Promise.reject(e);
        }
    }

}

/**
 * Fórum adatokat tároló tároló.
 */
export class ForumStore {
    @observable private containerStores: ObservableMap<string, ForumContainerStore> = observable.map({});
    @observable private topicListStores: ObservableMap<number, TopicListStore> = observable.map({});
    @observable private messageListStores: ObservableMap<number, MessageListStore> = observable.map({});
    @observable private memberListStores: ObservableMap<number, MemberListStore> = observable.map({});

    /*
        ForumContainer állapot lekérése.

        A fórum konténerek megőrzik az állapotukat render-elések között is.
    */
    public getContainerStore = (storeId: string): ForumContainerStore => {
        if (this.containerStores.has(storeId)) {
            return this.containerStores.get(storeId)!;
        } else {
            const result: ForumContainerStore = new ForumContainerStore();
            this.containerStores.set(storeId, result);            
            return result;
        }
    }

    /*
        Member lista lekérdezése adott fórumhoz.
    */
    public getMemberListStore = (forumId: number): MemberListStore => {
        if (this.memberListStores.has(forumId)) {
            return this.memberListStores.get(forumId)!;
        } else {
            const result: MemberListStore = new MemberListStore(forumId);
            this.memberListStores.set(forumId, result);
            result.reload();
            return result;
        }
    }

    /*
        Topic lista lekérdezése adott fórumhoz.
    */
    public getTopicListStore = (forumId: number): TopicListStore => {
        if (this.topicListStores.has(forumId)) {
            return this.topicListStores.get(forumId)!;
        } else {
            const result: TopicListStore = new TopicListStore(forumId);
            this.topicListStores.set(forumId, result);
            result.reload();
            return result;
        }
    }

    /*
        Üzenet lista lekérdezése adott fórumhoz.

    */
    public getMessageListStore = (forumId: number, topicId: number): MessageListStore => {
        if (typeof (forumId) !== "number" || typeof (topicId) !== "number") {
            throw new Error("getMessageListStore: must have number arguments.");
        }
        if (this.messageListStores.has(topicId)) {
            return this.messageListStores.get(topicId)!;
        } else {
            const result: MessageListStore = new MessageListStore(forumId, topicId);
            this.messageListStores.set(topicId, result);
            result.load();
            return result;
        }
    }

}

export const forumStore = new ForumStore();