import * as ExerciseBaseTypes from "@src/component/exercise/models/ExerciseBaseClass";
import { AExerciseTypeConverter, ValidationResponse, StatisticsResponse } from "@src/component/exercise/models/AExerciseTypeConverter";
import { WMDndImageToBarData } from "@src/component/exercise/engine/WMNumberLineExerciseEngine/WMNumberLineExerciseEngine";
import * as React from "react";
import ExerciseFileSelect, { ExerciseFileTypes } from "@src/component/exercise/Editor/ExerciseFileSelect";
import { NumberLineTypes } from "@src/component/exercise/engine/WMNumberLineExerciseEngine/WMNumberLineExerciseEngine";
import { AnswerTypes } from '@src/component/exercise/models/ExerciseBaseClass';
import { Panel } from '@src/component/ui/Panel';
import { __ } from '@src/translation';

export type WMNumberLineAnswerElement = {
    answer_type: NumberLineTypes,
    decimal_pointer: number,
    display_position: boolean,
    numberline: any,
    cards: any[],
    num_of_questions: number
}

export class WMNumberLineConverter extends AExerciseTypeConverter {
    public hasTextAnswer = false;
    public hasImageAnswer = true;
    public hasTextImageAnswer = false;
    static MAX_CARD_NUM = 20;

    render() {
        let curr_ex: WMNumberLineAnswerElement = this.state.exercise as WMNumberLineAnswerElement;
        return (
            <Panel header={__("Részletek")} headingLevel={4} >
            <div className="row">
                    <div className="large-12 columns">
                        <label>{__("Megjelenített kérdések száma (0 = összes)")}
                                    <input type="number" min="0" max="100" name="num_of_questions" value={curr_ex.num_of_questions || 0}
                                onBlur={this.onBlurEvent.bind(this)}
                                onChange={this.handleInputChange.bind(this)} />
                        </label>
                    </div>
                </div>
                <div className="row">
                    <div className="large-4 small-12 columns">
                        <label> {__("Válaszok típusa")}
                        <select value={curr_ex.answer_type || NumberLineTypes.number} name={"answer_type"} onChange={this.handleInputChange.bind(this)} onBlur={this.onBlurEvent.bind(this)}>
                                <option value={NumberLineTypes.number}>{__("Egész szám")}</option>
                                <option value={NumberLineTypes.float}>{__("Tizedes szám")}</option>
                            </select></label>
                    </div>
                    <div className="large-4 small-12 columns">
                        <label>{__("Tizedes jegyek száma")}
                                    <input type="number" min="1" max="3" name="decimal_pointer" value={curr_ex.answer_type != NumberLineTypes.number ? curr_ex.decimal_pointer || 0 : 0}
                                onBlur={this.onBlurEvent.bind(this)}
                                onChange={this.handleInputChange.bind(this)}
                                disabled={!(curr_ex.answer_type == NumberLineTypes.float)}
                            />
                        </label>
                    </div>
                </div>
                <div className="row">
                    <div className="large-12 small-12 columns">
                        <label ><input type="checkbox" name="display_position" checked={curr_ex.display_position || false}
                            onBlur={this.onBlurEvent.bind(this)}
                            onChange={this.handleInputChange.bind(this)} />
                            {__("Pontos pozíció kiírása")}</label>
                    </div>
                </div>

                <Panel header={__("Számegyenes adatai")} headingLevel={5}>
                    <div className="row">
                        <div className="large-6 small-12 columns">
                            <label>{" " + __("Alsó határ")}
                        <input type="number" name="numberline.lower" value={curr_ex.numberline.lower || 0}
                                    onBlur={this.onBlurEvent.bind(this)}
                                    onChange={this.handleInputChange.bind(this)} /></label>
                        </div>
                        <div className="large-6 small-12 columns">
                            <label>{" " + __("Felső határ")}
                        <input type="number" name="numberline.upper" value={curr_ex.numberline.upper || 100}
                                    onBlur={this.onBlurEvent.bind(this)}
                                    onChange={this.handleInputChange.bind(this)} /></label>
                        </div>
                    </div>
                    <div className="row">
                        <div className="large-6 small-12 columns">
                            <label>{__("Mozgatási skála (+ és - gombok értéke)")} 
                        <input type="text" name="numberline.scale" value={curr_ex.numberline.scale || "1"}
                                    onBlur={this.onBlurEvent.bind(this)}
                                    onChange={this.handleInputChange.bind(this)} /></label>
                        </div>
                        <div className="large-6 small-12 columns">
                            <label> {__("Megjelenítési skála (Egy számegyenes egység)")}
                        <input type="text" name="numberline.writescale" value={curr_ex.numberline.writescale || 10}
                                    onBlur={this.onBlurEvent.bind(this)}
                                    onChange={this.handleInputChange.bind(this)} /></label>
                        </div>
                    </div>
                </Panel>

                <Panel header={__("Kártyák")} headingLevel={5}>
                    {
                        curr_ex.cards ?
                            curr_ex.cards.map((curr_task: any, index) => {
                                return (<Panel>
                                    <legend>
                                        <label className="exe-editor-fieldlabel-1">{index + 1}. Elem</label>
                                        <button className="button small alert exercise-series-small-btn" title={__("Törlés")} onClick={this.removeElement.bind(this, "cards", index)}><i className="fa fa-trash"></i></button>
                                        <button className="button small exercise-series-small-btn" title={__("Fel")} onClick={this.moveUp.bind(this, "cards", index)} ><i className="fa fa-arrow-up"></i></button>
                                        <button className="button small exercise-series-small-btn" title={__("Le")} onClick={this.moveDown.bind(this, "cards", index)}><i className="fa fa-arrow-down"></i></button>
                                    </legend>
                                    <span className="exe-editor-validation-msg">{this.props.validationMessages.get("cards[" + index + "].type")}</span>
                                    <div className="row">

                                        <div className="large-6 small-12 columns">
                                            <label>{__("Kártya típusa")} 
                                            <select value={curr_task.type} name={"cards#" + index + ".type"} onChange={this.handleInputChange.bind(this)} onBlur={this.onBlurEvent.bind(this)}>
                                                    <option value={AnswerTypes.text}>{__("Szöveg")}</option>
                                                    <option value={AnswerTypes.image}>{__("Kép")}</option>
                                                    <option value={AnswerTypes.sound}>{__("Hang")}</option>
                                                </select></label>
                                        </div>

                                        <div className="large-6 small-12 columns">
                                            <label>
                                               {__("Megoldás (szám)")}  <span className="exe-editor-validation-msg">{this.props.validationMessages.get("cards[" + index + "].solution_value")}</span>
                                                <input type="text" name={"cards#" + index + ".solution_value"} data-parent-index={index} data-index={index} value={curr_task.solution_value}
                                                    onBlur={this.onBlurEvent.bind(this)}
                                                    onChange={this.handleInputChange.bind(this)} />
                                            </label>

                                        </div>
                                        <div className="large-12 small-12 columns">
                                            <label>
                                                {curr_task.type == AnswerTypes.text ? __("Kártya szövege") : __("Leírás (alt)")}
                                                <input type="text" name={"cards#" + index + ".text"} data-parent-index={index} data-index={index} value={curr_task.text}
                                                    onBlur={this.onBlurEvent.bind(this)}
                                                    onChange={this.handleInputChange.bind(this)} />
                                            </label>

                                        </div>
                                    </div>
                                    {curr_task.type != AnswerTypes.text ?
                                        <div className="large-12 small-12 columns">
                                            <label className="exe-image-select-label">{curr_task.type == AnswerTypes.image ? __("Kép") : __("Hang")}
                                                {<ExerciseFileSelect
                                                    imagebasepath={this.props.imagebasepath}
                                                    value={curr_task.url || ""}
                                                    onChange={this.handleImageChange.bind(this, "cards#" + index + ".url")}
                                                    getFolderId={this.getFolderId.bind(this)}
                                                    fileType={curr_task.type == AnswerTypes.image ? ExerciseFileTypes.Image : ExerciseFileTypes.Sound}
                                                />}
                                            </label>
                                        </div> : ""}
                                </Panel>)
                            })
                            : ""
                    }
                    <div className="row">
                        <button className="button small warning" onClick={this.onAddNewCard.bind(this)}><i className="fa fa-plus"></i>{__("Kártya hozzáadása")}</button>
                        <label className="exe-editor-label-description columns">{__("Maximum {max} elem vehető fel!", {max: WMNumberLineConverter.MAX_CARD_NUM})}</label>
                    </div>
                </Panel>
            </Panel>
        );
    }

    onAddNewCard() {
        let temp_exercise = this.state.exercise as WMNumberLineAnswerElement;
        if (!temp_exercise.cards) temp_exercise.cards = [];
        temp_exercise.cards.push(this.getNewCard());
        if (this.makeValidBeforeSetState(this.state.exercise, temp_exercise).valid)
            this.setState({ exercise: temp_exercise });
    }

    getNewCard(): any {
        let newElement: any = {
            type: AnswerTypes.text,
            text: "",
            solution_value: "",
        };
        return newElement;
    }

    public convertToEditorAnswer(exercise: WMDndImageToBarData): WMNumberLineAnswerElement | undefined {
        if (exercise) {
            var cardsList = [];
            if (!exercise.answer_type) {
                exercise.answer_type = NumberLineTypes.number;
                exercise.decimal_pointer = 0;
            }
            if (!exercise.numberline) {
                let newNumberLine = {
                    lower: 0,
                    upper: 100,
                    scale: 1,
                    writescale: 10
                }
                exercise.numberline = newNumberLine;
            }
            if (exercise.cards) {
                for (let i = 0; i < exercise.cards.length; i++) {
                    if (exercise.cards[i].type == "image") {
                        cardsList.push({
                            type: AnswerTypes.image,
                            url: exercise.cards[i].image,
                            text: exercise.cards[i].text,
                            solution_value: exercise.solution[i]
                        });
                    }
                    if (exercise.cards[i].type == "sound") {
                        cardsList.push({
                            type: AnswerTypes.sound,
                            url: exercise.cards[i].url,
                            text: exercise.cards[i].text,
                            solution_value: exercise.solution[i]
                        });
                    }
                    if (exercise.cards[i].type == "text") {
                        cardsList.push({
                            type: AnswerTypes.text,
                            text: exercise.cards[i].text,
                            solution_value: exercise.solution[i]
                        });
                    }
                }
            }
            var response: WMNumberLineAnswerElement = {
                numberline: exercise.numberline,
                answer_type: exercise.answer_type,
                decimal_pointer: exercise.decimal_pointer,
                display_position: exercise.display_position ? exercise.display_position : true,
                cards: cardsList,
                num_of_questions: exercise.num_of_questions
            };
            return response;
        }
        else return undefined;
    }

    public convertToJson(answerList: WMNumberLineAnswerElement, baseData: ExerciseBaseTypes.ExerciseBaseClass, prevJSON?: WMNumberLineAnswerElement): WMDndImageToBarData | undefined {
        if (answerList) {
            var cardsList = [];
            var solutionList = [];
            if (answerList.answer_type == NumberLineTypes.number) answerList.decimal_pointer = 0;
            if (answerList.cards) {
                for (let i = 0; i < answerList.cards.length; i++) {
                    if (answerList.cards[i].type == AnswerTypes.image) {
                        let curr_card = {
                            type: "image",
                            text: answerList.cards[i].text,
                            image: answerList.cards[i].url
                        }
                        cardsList.push(curr_card);
                    }
                    if (answerList.cards[i].type == AnswerTypes.sound) {
                        let curr_card = {
                            type: "sound",
                            text: answerList.cards[i].text,
                            url: answerList.cards[i].url
                        }
                        cardsList.push(curr_card);
                    }
                    if (answerList.cards[i].type == AnswerTypes.text) {
                        let curr_card = {
                            type: "text",
                            text: answerList.cards[i].text,
                        }
                        cardsList.push(curr_card);
                    }
                    solutionList.push(answerList.cards[i].solution_value);
                }
            }
            var cur_exercise = ExerciseBaseTypes.convertToBaseJson(baseData);
            cur_exercise = {
                ...cur_exercise,
                numberline: answerList.numberline,
                cards: cardsList,
                solution: solutionList,
                answer_type: answerList.answer_type,
                decimal_pointer: answerList.decimal_pointer,
                display_position: answerList.display_position,
                num_of_questions: answerList.num_of_questions
            };
            return cur_exercise;
        }
        else return undefined;
    }
    public validate(editorAnswer: WMNumberLineAnswerElement, baseData: any, validationMap?:Map<string,string>, is_accessible?:boolean|null): ValidationResponse {
        let superAnswer: ValidationResponse = super.validate(editorAnswer, baseData,validationMap, is_accessible);
        if (!superAnswer.valid) return superAnswer;

        let cards = editorAnswer.cards;
        let errorMsg = "";
        let OFIErrors: string[] = [];
        if (cards.length > WMNumberLineConverter.MAX_CARD_NUM) {
            errorMsg = __("Maximum {max} elem vehető fel!", {max: WMNumberLineConverter.MAX_CARD_NUM});
            if(!OFIErrors.includes(errorMsg, 0)) OFIErrors.push(errorMsg);
            if(!AExerciseTypeConverter.isOfiEditor ) return { valid: false, message: errorMsg}
        }
        if (editorAnswer.answer_type == NumberLineTypes.number) {
            if (editorAnswer.decimal_pointer != 0) return { valid: false, message: __("Egész szám válaszoknál a tizedesjegyek száma csak 0 lehet.") };
            if (!Number.isInteger(Number(editorAnswer.numberline.scale))) return { valid: false, message: __("Egész szám válaszoknál a mozgatási skála csak egész szám lehet.") };
        }
        else {
            if (editorAnswer.decimal_pointer < 1) return { valid: false, message: __("Tizedes számos választípusnál a tizedesjegy legalább 1.") };
            if (editorAnswer.numberline.scale >= 1) return { valid: false, message: __("Tizedes számos választípusnál a mozgatási skálának kisebbnek kell lenni, mint 1.") };
        }
        for (let i = 0; i < cards.length; i++) {
            if (cards[i].solution_value != "" && isNaN(cards[i].solution_value)) {
                errorMsg = __("Egy kártya megoldásának számnak vagy üresnek kell lennie!");
                if(!OFIErrors.includes(errorMsg, 0)) OFIErrors.push(errorMsg);
                validationMap!.set("cards[" + i + "].solution_value", errorMsg);
                return { valid: false, message: errorMsg };
            }
            if (cards[i].solution_value != "")
            {
                let numSolValue: number = Number(cards[i].solution_value);
                if (numSolValue > editorAnswer.numberline.upper || numSolValue < editorAnswer.numberline.lower){
                    errorMsg = __("A megoldásnak az alsó és felső határon belülinek kell lennie.")
                    if(!OFIErrors.includes(errorMsg, 0)) OFIErrors.push(errorMsg);
                    validationMap!.set("cards[" + i + "].solution_value", errorMsg);
                    return { valid: false, message: errorMsg };
                }
            }
        }

        let rowsValid = this.makeValidBeforeSetState(null, editorAnswer);
        if (!rowsValid.valid) return rowsValid;

        if (AExerciseTypeConverter.isOfiEditor){
            return { valid: true, message: OFIErrors.join(' , ') }
        }

        return { valid: true }
    }

    public makeValidBeforeSetState(oldState: WMNumberLineAnswerElement | null, newState: WMNumberLineAnswerElement): ValidationResponse {
        if(AExerciseTypeConverter.isOfiEditor ) return {valid:true};
        if (newState.cards.length > WMNumberLineConverter.MAX_CARD_NUM) {
            newState.cards.splice(WMNumberLineConverter.MAX_CARD_NUM, newState.cards.length - WMNumberLineConverter.MAX_CARD_NUM);
            return { valid: false, message: __("Maximum {max} elem vehető fel!", {max: WMNumberLineConverter.MAX_CARD_NUM}) }
        }
        return { valid: true }
    }

    public getExerciseStatistics(exerciseList: any): StatisticsResponse[] {
        if (!exerciseList || exerciseList.length == 0) return [];
        let statisticsResponses: StatisticsResponse[] = [];
        let elementStat: StatisticsResponse;
        statisticsResponses.push({ name: __("limit - max. kártyaszám: {n}", {n: WMNumberLineConverter.MAX_CARD_NUM}), count: undefined });
        elementStat = { name: __("Kártyák száma"), count: new Map() }
        for (let i = 0; i < exerciseList.length; i++) {
            if (!exerciseList[i]) continue;
            const cur_exc = exerciseList[i];
            let element_count = elementStat.count!.has(cur_exc.cards.length) ? elementStat.count!.get(cur_exc.cards.length) : 0;
            elementStat.count!.set(cur_exc.cards.length, element_count! + 1);
        }
        statisticsResponses.push(elementStat);
        return statisticsResponses;
    }
}
