import * as React from 'react';
import { LessonPlanEditState, LessonPlanEditProps, LessonPlanEdit, AsyncStatus, EMPTY_TECHICAL } from '@src/component/lessonplan/LessonPlanEdit';
import LessonDraftSubprogramCrud, { ILessonDraftSubprogramRecord, lessonDraftSubprogramCrudClassProxy } from '@src/framework/crud/kap/LessonDraftSubprogramCrud';
import LessonDraftCrud, { ILessonDraftRecord } from '@src/framework/crud/kap/LessonDraftCrud';
import LessonDraftBlockCrud, { ILessonDraftBlockRecord } from '@src/framework/crud/kap/LessonDraftBlockCrud';
import { LIBRARY_OFI_OFFICIAL_ID, LIBRARY_JTR_BEADANDO_ID } from '@src/Const';
import { HTMLTextarea } from '@src/component/HtmlTextarea';
import { __ } from '@src/translation';
import CrudSelect from '@framework/forms/crudselect';
import { lessonDraftTypeCrudClassProxy } from '@src/framework/crud/kap/LessonDraftTypeCrud';
import { literacyFieldCrudClassProxy } from '@src/framework/crud/doc/LiteracyFieldCrud';
import { subjectCrudClassProxy } from '@src/framework/crud/doc/SubjectCrud';
import { gradeCrudClassProxy } from '@src/framework/crud/doc/GradeCrud';
import { CrudMultiSelect } from '@src/framework/forms/crudmultiselect';
import LessonDraftBlockEduMethodCrud, { ILessonDraftBlockEduMethodRecord, lessonDraftBlockEduMethodCrudClassProxy } from '@src/framework/crud/kap/LessonDraftBlockEduMethodCrud';
import LessonDraftBlockEduWorkFormCrud, { ILessonDraftBlockEduWorkFormRecord, lessonDraftBlockEduWorkFormCrudClassProxy } from '@src/framework/crud/kap/LessonDraftBlockEduWorkFormCrud';
import LessonDraftBlockSubprogramCrud, { lessonDraftBlockSubprogramCrudClassProxy } from '@src/framework/crud/kap/LessonDraftBlockSubprogramCrud';
import { app, history } from '@src/index';
import { subprogramCrudClassProxy } from '@src/framework/crud/kap/SubprogramCrud';
import { eduMethodCrudClassProxy } from '@src/framework/crud/kap/EduMethodCrud';
import { eduWorkFormCrudClassProxy } from '@src/framework/crud/kap/EduWorkFormCrud';
import { getMissingFields } from '@src/Util';
import { AccordionItem, Accordion } from '@src/component/ui/Accordion';
import ViewKapDraft from '@src/framework/view/kap/ViewKapDraft';
import { fetchKeywords, setKeywords } from '@src/component/meta/meta_keyword_assigner_plugin';
import { groupTypeCrudClassProxy } from '@src/framework/crud/kap/GroupTypeCrud';
import { lessonTypeCrudClassProxy } from '@src/framework/crud/kap/LessonTypeCrud';
import { IOccupationDraftSubjectRecord } from '@src/framework/crud/kap/OccupationDraftSubjectCrud';
import LessonDraftSubjectCrud, { lessonDraftSubjectCrudClassProxy, ILessonDraftSubjectRecord } from '@src/framework/crud/kap/LessonDraftSubjectCrud';
import { IViewTransitionTypeRecordWithPerm } from '@src/framework/wf/WfAPI';
import { getMetaCategoryTree } from '@src/component/meta/meta_category_assigner_api';
import { KAP_META_KOMPETENCIA } from './DraftEditList';
import { getDraftComment, setDraftComment } from '@src/server/PublicServer';
import { Comment } from '@src/component/ui/Comment';
import { updateSubrecords } from '@src/component/filemanager/UpdateSubrecords';
import { DownloadAsPdf } from './DownloadAdPdf';
import { match } from 'react-router';
import { me } from '@src/framework/server/Auth';

export const PATH_LESSON_DRAFT_EDIT = "/oravazlat/dfht";

export const DRAFT_TYPE_DFHT = 1;
export const DRAFT_TYPE_KOMPLEX = 2;

export const DRAFT_CATEGORY_INTRO = 1;
export const DRAFT_CATEGORY_MAIN = 2;
export const DRAFT_CATEGORY_ENDING = 3;

export class LessonDraftEditPage extends React.Component<{ match: match<{ id?: string }> }> {

    render() {
        return <LessonDraftEdit
            id={this.props.match.params.id ? Number(this.props.match.params.id) : undefined}
            onSave={newId => history.push(PATH_LESSON_DRAFT_EDIT + "/" + newId)}
        />;
    }

}

type LessonDraftEditState = {
    subjects: IOccupationDraftSubjectRecord[];
    subprograms: ILessonDraftSubprogramRecord[];

    subjectIds: number[];
    subprogramIds: number[];

    comments: any;

    publicationConfirmed: string;
} & LessonPlanEditState<ILessonDraftRecord, ILessonDraftBlockRecord>;

export class LessonDraftEdit extends LessonPlanEdit<LessonPlanEditProps, LessonDraftEditState> {

    constructor(props: LessonPlanEditProps) {
        super(props);

        this.state = {
            lessonPlan: { library_id: this.props.defaultLibraryId || LIBRARY_OFI_OFFICIAL_ID },
            lessonPlanBlocks: [] as any,
            folders: [],
            deletedBlocks: [],
            status: AsyncStatus.LOADING,
            ownerName: "",

            categorization: {},
            subject_ids: {},

            workflowDialogOpen: false,

            subjects: [],
            subprograms: [],

            subjectIds: [],
            subprogramIds: [],

            keywords: [],
            isDataChanged: false,
            comments: undefined,
            publicationConfirmed: ""
        }
    }

    componentDidMount() {
        if (this.props.id) {
            this.reloadAsync();
        } else {
            this.setState({ status: AsyncStatus.LOADED });
        }
    }

    componentDidUpdate(prevProps: LessonPlanEditProps) {
        if (this.props.id && this.props.id !== prevProps.id) {
            this.reloadAsync();
        }

        $('.accordion-title input').click(function (e) {
            e.stopPropagation();
        });
    }

    getTitles() {
        return {
            mainTitle: __("DFHT vagy komplex óravázlat"),
            mainPanelTitle: __("Adatlap"),
            blockPanelTitle: __("Az óra menete"),
            secondPanelTitle: __("Mellékletek"),
            attachmentPanelTitle: __("Csatolt állományok"),
            blockTitle: __("Sor"),
            tableInfoId: LessonDraftCrud.TABLE_INFO_ID,
            disableCheckforNodesWithLeaf: true,
        }
    }

    async reloadAsync() {
        const id = this.props.id;
        if (!id) return;

        try {
            const lessonPlan = (await LessonDraftCrud.load(id)).record;

            const comments = await getDraftComment("lesson_draft_" + id);

            const listParams = {
                filter: {
                    is_active: true,
                    lesson_draft_id: lessonPlan.id,
                },
                order_by: "id"
            };

            const lessonPlanBlocks = await LessonDraftBlockCrud.list({
                filter: {
                    is_active: true,
                    lesson_draft_id: lessonPlan.id,
                },
                order_by: "no"
            });

            const subprograms = await LessonDraftSubprogramCrud.list(listParams);
            const subjects = await LessonDraftSubjectCrud.list(listParams);

            for (const block of lessonPlanBlocks) {
                const methods = await LessonDraftBlockEduMethodCrud.list({ filter: { is_active: true, lesson_draft_block_id: block.id } });
                block["eduMethods"] = methods;
                block["eduMethodIds"] = methods.map(m => m.edu_method_id);

                const workForms = await LessonDraftBlockEduWorkFormCrud.list({ filter: { is_active: true, lesson_draft_block_id: block.id } });
                block["eduWorkFroms"] = workForms;
                block["eduWorkFromIds"] = workForms.map(w => w.edu_work_form_id);

                const subprograms = await LessonDraftBlockSubprogramCrud.list({ filter: { is_active: true, lesson_draft_block_id: block.id } });
                block["subprograms"] = subprograms;
                block["subprogramIds"] = subprograms.map(w => w.subprogram_id);
            }

            const viewLessonPlan = (await ViewKapDraft.load(lessonPlan.id!)).record;

            const keywords = await fetchKeywords(LessonDraftCrud.TABLE_INFO_ID, lessonPlan.id!);
            let pub;
            if (lessonPlan.consent_publication && lessonPlan.consent_publication != null) {
                pub = "pub_confirm";
            } else if (lessonPlan.consent_publication == null) {
                pub = "pub_not_confirm";
            } else {
                pub = "";
            }
            this.setState({
                status: AsyncStatus.LOADED,
                lessonPlan: lessonPlan,
                lessonPlanBlocks,
                deletedBlocks: [],
                subprograms,
                subjects,
                subprogramIds: subprograms.map(s => s.subprogram_id!),
                subjectIds: subjects.map(s => s.subject_id!),
                workflowStateName: viewLessonPlan.wf_station_name || "",
                keywords,
                comments,
                publicationConfirmed: pub

            });
        } catch (e) {
            app.showErrorFromJsonResult(e);
        }
    }

    protected onBeforeTransition(transition: IViewTransitionTypeRecordWithPerm) {
        if (this.state.keywords.length == 0 && !transition.dst_is_deleted) {
            app.showError("Hiba", __("Kérem adjon meg kulcsszavakat!"));
            return false;
        }

        if (this.state.categorization[KAP_META_KOMPETENCIA] && this.state.lessonPlan.library_id == LIBRARY_JTR_BEADANDO_ID && this.state.categorization[KAP_META_KOMPETENCIA]!.length < 1 && !transition.dst_is_deleted) {
            app.showError("Hiba", __("Legalább egy metaadat megadása kötelező!"));
            return false;
        }

        if (this.state.publicationConfirmed == "" && !transition.dst_is_deleted) {
            app.showError("Hiba", __("Kérem jelezze, hogy hozzájárul-e az esetleges publikus megjelenéshez!"));
            return false;
        }

        return true;
    }


    async onSave() {
        try {

            let toomuchcatTitles: string[] = [];
            const treeRoot = await getMetaCategoryTree(KAP_META_KOMPETENCIA);

            const selectedIds = this.state.categorization[KAP_META_KOMPETENCIA];

            if (this.state.lessonPlan.library_id == LIBRARY_JTR_BEADANDO_ID && this.state.publicationConfirmed == "") {
                app.showError("Hiba", __("Kérem jelezze, hogy hozzájárul-e az esetleges publikus megjelenéshez!"));
                return;
            }

            if (selectedIds) {
                let count = 0;
                for (let i = 0; i < treeRoot.children.length; i++) {
                    count = 0;
                    for (let k = 0; k < treeRoot.children[i].children.length; k++) {
                        if (selectedIds.includes(treeRoot.children[i].children[k].id)) {
                            count++;
                        }
                    }
                    if (count > 4) {
                        toomuchcatTitles.push(treeRoot.children[i].title);
                    }
                }
            }
            if (toomuchcatTitles.length > 0) {
                app.showError(__("Sikertelen mentés"), __(`Egyszerre maximum 4 kompetencia adható meg! 4-nél több lett megadva a következőkben: `) + toomuchcatTitles.join(", "));
                return;
            }



            let missingFields = await getMissingFields(LessonDraftCrud as any, this.state.lessonPlan, ["oo_folder_id"]);

            for (const lessonPlanBlock of this.state.lessonPlanBlocks) {
                if (!lessonPlanBlock.require_time_min || lessonPlanBlock.require_time_min <= 0) {
                    app.showError(__("Hiba"), __("Az időkorlátnak nullánál nagyobb számnak kell lennie!"));
                    return;
                }

                missingFields = missingFields.concat(await getMissingFields(LessonDraftBlockCrud as any, lessonPlanBlock, ["lesson_draft_id"]));
                const subprogramIds = lessonPlanBlock["subprogramIds"] || [];
            }

            if (this.state.lessonPlan.lesson_draft_type_id === DRAFT_TYPE_KOMPLEX && !this.state.lessonPlan.subprogram_id) {
                missingFields.push("Alprogrami kapcsolat");
            }

            if (missingFields.length) {
                app.showError(__("Sikertelen mentés"), __("Töltse ki az összes kötelező mezőt: {details}", { details: missingFields.join(", ") }));
                return;
            }

            if (this.state.subprogramIds.length > 2) {
                app.showError(__("Hiba"), __("Maximum 2 további alprogrami kapcsolat választható!"));
                return;
            }

            if (this.state.lessonPlanBlocks.filter(b => b.lesson_draft_block_category_id == DRAFT_CATEGORY_INTRO).length > 4) {
                app.showError(__("Hiba"), __("Maximum 4 bevezető sor lehet!"));
                return;
            }
            if (this.state.lessonPlanBlocks.filter(b => b.lesson_draft_block_category_id == DRAFT_CATEGORY_MAIN).length > 10) {
                app.showError(__("Hiba"), __("Maximum 10 főrész sor lehet!"));
                return;
            }
            if (this.state.lessonPlanBlocks.filter(b => b.lesson_draft_block_category_id == DRAFT_CATEGORY_ENDING).length > 4) {
                app.showError(__("Hiba"), __("Maximum 4 befejezés sor lehet!"));
                return;
            }


            this.setState({ status: AsyncStatus.SAVING });

            const lp = this.state.lessonPlan;
            if (this.state.publicationConfirmed == "pub_confirm") {
                lp.consent_publication = "now";
            } else if (this.state.publicationConfirmed == "pub_not_confirm") {
                lp.consent_publication = null;
            } else {
                lp.consent_publication = undefined;
            }
            let toSave = { ...lp, ...EMPTY_TECHICAL };

            const lessonPlan = (await new LessonDraftCrud(toSave).put()).record;

            if (this.state.comments) {
                await setDraftComment("lesson_draft_" + lessonPlan.id, this.state.comments);
            }

            await setKeywords(
                LessonDraftCrud.TABLE_INFO_ID, lessonPlan.id!,
                this.state.keywords
            );

            await updateSubrecords<ILessonDraftSubjectRecord>(
                this.state.subjects, this.state.subjectIds, lessonDraftSubjectCrudClassProxy, "lesson_draft_id", lessonPlan.id!, "subject_id"
            );

            await updateSubrecords<ILessonDraftSubprogramRecord>(
                this.state.subprograms, this.state.subprogramIds, lessonDraftSubprogramCrudClassProxy, "lesson_draft_id", lessonPlan.id!, "subprogram_id"
            );

            var index = 1;
            for (const lessonPlanBlock of this.state.lessonPlanBlocks) {

                if (lessonPlanBlock.id) {
                    lessonPlanBlock.lesson_draft_id = undefined;
                } else {
                    lessonPlanBlock.lesson_draft_id = lessonPlan.id;
                }

                lessonPlanBlock.no = index;
                index++;
                const newBlock = (await new LessonDraftBlockCrud(lessonPlanBlock).put()).record;

                await updateSubrecords<ILessonDraftBlockEduMethodRecord>(
                    lessonPlanBlock["eduMethods"] || [], lessonPlanBlock["eduMethodIds"] || [], lessonDraftBlockEduMethodCrudClassProxy, "lesson_draft_block_id", newBlock.id!, "edu_method_id"
                );
                await updateSubrecords<ILessonDraftBlockEduWorkFormRecord>(
                    lessonPlanBlock["eduWorkFroms"] || [], lessonPlanBlock["eduWorkFromIds"] || [], lessonDraftBlockEduWorkFormCrudClassProxy, "lesson_draft_block_id", newBlock.id!, "edu_work_form_id"
                );
                await updateSubrecords<ILessonDraftBlockEduWorkFormRecord>(
                    lessonPlanBlock["subprograms"] || [], lessonPlanBlock["subprogramIds"] || [], lessonDraftBlockSubprogramCrudClassProxy, "lesson_draft_block_id", newBlock.id!, "subprogram_id"
                );


            }
            for (const deletedId of this.state.deletedBlocks) {
                await new LessonDraftBlockCrud({ id: deletedId }).del();
            }

            await this.saveCategories(lessonPlan.id!);

            app.showSuccess(__("Óravázlat lementve"), __("Óravázlat lementve"));

            if (lessonPlan.id != this.props.id) {
                this.props.onSave(lessonPlan.id!);
            } else if (lessonPlan.id) {
                this.reloadAsync();
            }
        } catch (e) {
            app.showErrorFromJsonResult(e);
        }

        this.setState({
            status: AsyncStatus.LOADED
        })
    }

    private onChangeComment(propName: string, value: any) {
        this.setState({ isDataChanged: true, comments: { ...this.state.comments, [propName]: value } });
    }

    protected getExtraButtons() {
        if (!this.state.lessonPlan.id) return null;

        return <>
            <br />
            <DownloadAsPdf
                id={this.state.lessonPlan.id}
                type="lesson_draft"
                published={false}
            />

        </>
    }

    changeConfirmation(e: any) {
        const target = e!.target;
        let value = target.value;
        this.setState({ publicationConfirmed: value, isDataChanged: true });
    }

    protected getConfirm() {
        return this.state.lessonPlan.library_id == LIBRARY_JTR_BEADANDO_ID ?
            <div className="row callout confirm-box">
                <div className="column">
                    <fieldset>
                        <label><p><input type="radio" checked={this.state.publicationConfirmed == "pub_confirm"} value="pub_confirm" name="confirm" onChange={this.changeConfirmation.bind(this)} disabled={!this.state.lessonPlan.owner_id || (me!.id == this.state.lessonPlan.owner_id && this.state.publicationConfirmed != "pub_confirm") ? false : true}></input> {__("Hozzájárulok, hogy az SZTR online felületére feltöltött, általam készített, az EFOP-3.1.216-2016-00001 azonosítószámú projekt keretében megbízott Szakmai Támogatás Munkacsoport szakemberei által kiválónak vélt óratervek a Komplex Alapprogram Tudástárába feltöltésre kerüljenek a nevem feltüntetése mellett. A feltöltés tényéről értesítést kapok.")} <strong>{__("(A hozzájárulást később nem lehet visszavonni!)")}</strong></p></label>
                        <label><p><input type="radio" checked={this.state.publicationConfirmed == "pub_not_confirm"} value="pub_not_confirm" name="confirm" onChange={this.changeConfirmation.bind(this)} disabled={!this.state.lessonPlan.owner_id || (me!.id == this.state.lessonPlan.owner_id && this.state.publicationConfirmed != "pub_confirm") ? false : true}></input>{__("Nem járulok hozzá.")}</p></label>
                    </fieldset>
                </div>

            </div> : <></>;
    }

    getFormBeforeBlocks() {
        return <div className="row">
            <div className="column small-12">
                <Comment
                    value={this.state.comments && this.state.comments.authors}
                    onChange={e => this.onChangeComment("authors", e)}
                />
                <label>
                    {__("Szerző(k)")}*:
                </label>
                <textarea rows={2} value={this.state.lessonPlan.authors || ""} onChange={e => this.onChange("authors", e.target.value)} />
            </div>
            <div className="column small-12">
                <Comment
                    value={this.state.comments && this.state.comments.lesson_draft_type_id}
                    onChange={e => this.onChangeComment("lesson_draft_type_id", e)}
                />
                <label>
                    {__("Típus")}*:
                </label>
                <CrudSelect
                    crudClassProxy={lessonDraftTypeCrudClassProxy}
                    value={this.state.lessonPlan.lesson_draft_type_id || null}
                    onSelect={(_, id) => this.onChange("lesson_draft_type_id", id)}
                    displayFieldName="title"
                />
            </div>
            <div className="column small-12">
                <Comment
                    value={this.state.comments && this.state.comments.literacy_field_id}
                    onChange={e => this.onChangeComment("literacy_field_id", e)}
                />
                <label>
                    {__("Műveltségi terület")}*:
                </label>
                <CrudSelect
                    crudClassProxy={literacyFieldCrudClassProxy}
                    value={this.state.lessonPlan.literacy_field_id || null}
                    filter={{ is_active: true }}
                    onSelect={(_, id) => this.onChange("literacy_field_id", id)}
                    displayFieldName="name"
                />
            </div>
            <div className="column small-12">
                <Comment
                    value={this.state.comments && this.state.comments.subject_id}
                    onChange={e => this.onChangeComment("subject_id", e)}
                />
                <label>
                    {__("Tantárgy")}*:
                </label>
                <CrudSelect
                    crudClassProxy={subjectCrudClassProxy}
                    value={this.state.lessonPlan.subject_id || null}
                    filter={{ is_active: true }}
                    onSelect={(_, id) => this.onChange("subject_id", id)}
                    displayFieldName="name"
                />
            </div>
            <div className="column small-12">
                <Comment
                    value={this.state.comments && this.state.comments.grade_id}
                    onChange={e => this.onChangeComment("grade_id", e)}
                />
                <label>
                    {__("Évfolyam")}*:
                </label>
                <CrudSelect
                    crudClassProxy={gradeCrudClassProxy}
                    value={this.state.lessonPlan.grade_id || null}
                    filter={{ is_active: true }}
                    onSelect={(_, id) => this.onChange("grade_id", id)}
                    displayFieldName="name"
                    emptyTitle={__("Minden osztály")}
                    orderByFieldName="id"
                    key="id"
                    sortFunc={(a, b) => a.name.localeCompare(b.name, undefined, { numeric: true, sensitivity: 'base' })}
                />
            </div>
            <div className="column small-6">
                <Comment
                    value={this.state.comments && this.state.comments.group_type_id}
                    onChange={e => this.onChangeComment("group_type_id", e)}
                />
                <label>
                    {__("Csoport / osztály típusa")}:
                </label>
                <CrudSelect
                    crudClassProxy={groupTypeCrudClassProxy}
                    value={this.state.lessonPlan.group_type_id || null}
                    onSelect={(_, id) => this.onChange("group_type_id", id)}
                    displayFieldName="title"
                    orderByFieldName="id"
                />
            </div>
            <div className="column small-6">
                <Comment
                    value={this.state.comments && this.state.comments.group_type_other}
                    onChange={e => this.onChangeComment("group_type_other", e)}
                />
                <label>
                    {__("Egyéb")}:
                </label>
                <input type="text" value={this.state.lessonPlan.group_type_other || ""} onChange={e => this.onChange("group_type_other", e.target.value)} />
            </div>
            <div className="column small-12">
                <Comment
                    value={this.state.comments && this.state.comments.topic}
                    onChange={e => this.onChangeComment("topic", e)}
                />
                <label>
                    {__("Az óra témája")}*:
                </label>
                <textarea rows={2} value={this.state.lessonPlan.topic || ""} onChange={e => this.onChange("topic", e.target.value)} />
            </div>
            <div className="column small-12">
                <Comment
                    value={this.state.comments && this.state.comments.goal}
                    onChange={e => this.onChangeComment("goal", e)}
                />
                <label>
                    {__("Az óra cél- és feladatrendszere")}*:
                </label>
                <textarea rows={2} value={this.state.lessonPlan.goal || ""} onChange={e => this.onChange("goal", e.target.value)} />
            </div>
            <div className="column small-12">
                <Comment
                    value={this.state.comments && this.state.comments.lesson_type_id}
                    onChange={e => this.onChangeComment("lesson_type_id", e)}
                />
                <label>
                    {__("Az óra típusa")}*:
                </label>
                <CrudSelect
                    crudClassProxy={lessonTypeCrudClassProxy}
                    value={this.state.lessonPlan.lesson_type_id || null}
                    onSelect={(_, id) => this.onChange("lesson_type_id", id)}
                    displayFieldName="title"
                    orderByFieldName="id"
                />
            </div>
            <div className="column small-12">
                <Comment
                    value={this.state.comments && this.state.comments.lesson_type_id}
                    onChange={e => this.onChangeComment("lesson_type_id", e)}
                />
                <label>
                    {__("Alprogrami kapcsolat")}{this.state.lessonPlan.lesson_draft_type_id === DRAFT_TYPE_DFHT ? "" : "*"}:
                </label>
                <CrudSelect
                    crudClassProxy={subprogramCrudClassProxy}
                    value={this.state.lessonPlan.subprogram_id || null}
                    filter={{ is_active: true }}
                    onSelect={(_, id) => this.onChange("subprogram_id", id)}
                    displayFieldName="title"
                />
            </div>
            <div className="column small-12">
                <Comment
                    value={this.state.comments && this.state.comments.subprogramIds}
                    onChange={e => this.onChangeComment("subprogramIds", e)}
                />
                <label>
                    {__("További alprogrami kapcsolat")}:
                </label>
                <CrudMultiSelect
                    crudClassProxy={subprogramCrudClassProxy}
                    displayFieldName="title"
                    orderByFieldName="title"
                    values={this.state.subprogramIds}
                    filter={{ is_active: true }}
                    onChange={values => this.setState({ isDataChanged: true, subprogramIds: values })}
                />
            </div>

            <div className="column small-12">
                <Comment
                    value={this.state.comments && this.state.comments.subprogram_comment}
                    onChange={e => this.onChangeComment("subprogram_comment", e)}
                />
                <label>
                    {__("Alprogrami kapcsolat indoklása")}:
                </label>
                <textarea rows={2} value={this.state.lessonPlan.subprogram_comment || ""} onChange={e => this.onChange("subprogram_comment", e.target.value)} />
            </div>

            <div className="column small-12">
                <Comment
                    value={this.state.comments && this.state.comments.subjectIds}
                    onChange={e => this.onChangeComment("subjectIds", e)}
                />
                <label>
                    {__("Tantárgyi kapcsolatok")}:
                </label>
                <CrudMultiSelect
                    crudClassProxy={subjectCrudClassProxy}
                    displayFieldName="name"
                    orderByFieldName="name"
                    values={this.state.subjectIds}
                    filter={{ is_active: true }}
                    onChange={values => this.setState({ subjectIds: values, isDataChanged: true })}
                />
            </div>

            <div className="column small-12">
                <Comment
                    value={this.state.comments && this.state.comments.tools}
                    onChange={e => this.onChangeComment("tools", e)}
                />
                <label>
                    {__("Taneszközök")}*:
                </label>
                <textarea rows={2} value={this.state.lessonPlan.tools || ""} onChange={e => this.onChange("tools", e.target.value)} />
            </div>
            <div className="column small-12">
                <Comment
                    value={this.state.comments && this.state.comments.used_sources}
                    onChange={e => this.onChangeComment("used_sources", e)}
                />
                <label>
                    {__("Felhasznált források")}:
                </label>
                <textarea rows={2} value={this.state.lessonPlan.used_sources || ""} onChange={e => this.onChange("used_sources", e.target.value)} />
            </div>
        </div>;
    }

    getFormAfterBlocks() {
        return <div className="row">
            <div className="column small-12">
                <Comment
                    value={this.state.comments && this.state.comments.html_body}
                    onChange={e => this.onChangeComment("html_body", e)}
                />
                <label>
                    {__("Mellékletek")}:
                    </label>
                <HTMLTextarea
                    key={this.state.lessonPlan.id || ""}
                    value={this.state.lessonPlan.html_body || ""}
                    onChange={(text) => this.onChange("html_body", text)}
                    onAddImage={this.state.lessonPlan.id ? this.onAddImage.bind(this) : undefined}
                />
            </div>
        </div>;
    }

    protected getExtraForm() {
        return null;
    }

    private onChangeBlock(index: number, propName: string, value: any) {
        const block = this.state.lessonPlanBlocks[index];
        block[propName] = value;
        this.setState({
            lessonPlanBlocks: this.state.lessonPlanBlocks, isDataChanged: true
        });
    }

    private getBlock(title: string, draftBlockCategoryId: number) {
        const blocks = this.state.lessonPlanBlocks.filter(b => b.lesson_draft_block_category_id == draftBlockCategoryId);

        const timeMinutes = blocks.map(l => l.require_time_min).reduce((a, b) => (a || 0) + (b || 0), 0);

        const titles = this.getTitles();

        let counter = 1;

        return <AccordionItem title={title + " " + (timeMinutes ? __(`(Összes idő eddig: {time} perc)`, { time: timeMinutes }) : "")}>
            <div className="row">
                <div className="column small-12">
                    <Accordion>
                        {
                            this.state.lessonPlanBlocks.map((lpb, index) => {
                                if (lpb.lesson_draft_block_category_id != draftBlockCategoryId) {
                                    return null;
                                }

                                const title = <div style={{ display: "flex", flex: 1, alignItems: "center" }}>
                                    <span>#{counter++}. {titles.blockTitle} </span>

                                    <span style={{ flex: 1, margin: "0 1em", display: "flex", alignItems: "center" }}>
                                        {__("Időkeret :")}
                                        <input
                                            type="number"
                                            style={{ margin: "0 2em", maxWidth: "4em", display: "inline-block" }}
                                            value={lpb.require_time_min || ""}
                                            onChange={(event) => {
                                                lpb.require_time_min = event.currentTarget.value ? Number(event.currentTarget.value) : undefined;
                                                this.setState({ lessonPlanBlocks: this.state.lessonPlanBlocks, isDataChanged: true });
                                            }}
                                        />
                                        {__("perc")}
                                    </span>

                                    <button className="button small primary exercise-series-small-btn" title="Fel" onClick={this.moveExeUp.bind(this, lpb)} ><i className="fa fa-arrow-up"></i></button>
                                    <button className="button small primary exercise-series-small-btn" title="Le" onClick={this.moveExeDown.bind(this, lpb)}><i className="fa fa-arrow-down"></i></button>
                                    <button className="button small alert" style={{ margin: "0" }} onClick={this.onRemoveBlock.bind(this, index)}><i className="fa fa-trash" /></button>


                                </div>;

                                return <AccordionItem key={index} title={title}>
                                    {this.getBlockForm(lpb, index)}
                                </AccordionItem>
                            })
                        }
                    </Accordion>
                    <button className="button" onClick={this.onAddBlockWithCategory.bind(this, draftBlockCategoryId)} style={{ marginTop: "1em" }}>
                        <i className="fa fa-plus" /> &nbsp;{titles.blockTitle + " " + __("hozzáadása")}
                    </button>
                </div>
            </div>
        </AccordionItem>;
    }

    protected getBlocks() {
        return <>
            {this.getBlock("Bevezetés / ráhangolás", DRAFT_CATEGORY_INTRO)}
            {this.getBlock("Főrész / jelentésteremtés", DRAFT_CATEGORY_MAIN)}
            {this.getBlock("Befejezés / reflektálás", DRAFT_CATEGORY_ENDING)}
        </>
    }

    protected onAddBlockWithCategory(categoryId: number) {
        this.state.lessonPlanBlocks.push({
            lesson_draft_block_category_id: categoryId
        });

        this.setState({
            lessonPlanBlocks: this.state.lessonPlanBlocks
        });
    }

    getBlockForm(block: ILessonDraftBlockRecord, index: number) {
        const eduMethodIds = block["eduMethodIds"] || [];
        const eduWorkFromIds = block["eduWorkFromIds"] || [];
        const subprogramIds = block["subprogramIds"] || [];

        return <div className="row">
            <div className="column small-12">
                <Comment
                    value={this.state.comments && this.state.comments["block_teacher_activity_" + index]}
                    onChange={e => this.onChangeComment("block_teacher_activity_" + index, e)}
                />
                <label>
                    {__("Az óra menete / A pedagógus tevékenysége")}*:
                </label>
                <textarea rows={2} value={block.teacher_activity || ""} onChange={e => this.onChangeBlock(index, "teacher_activity", e.target.value)} />
            </div>
            <div className="column small-12">
                <Comment
                    value={this.state.comments && this.state.comments["block_student_activity_" + index]}
                    onChange={e => this.onChangeComment("block_student_activity_" + index, e)}
                />
                <label>
                    {__("A tanulók tevékenysége")}*:
                </label>
                <textarea rows={2} value={block.student_activity || ""} onChange={e => this.onChangeBlock(index, "student_activity", e.target.value)} />
            </div>
            <div className="column small-6">
                <Comment
                    value={this.state.comments && this.state.comments["block_eduMethodIds_" + index]}
                    onChange={e => this.onChangeComment("block_eduMethodIds_" + index, e)}
                />
                <label>
                    {__("Módszer")}:
                </label>
                <CrudMultiSelect
                    crudClassProxy={eduMethodCrudClassProxy}
                    displayFieldName="title"
                    orderByFieldName="title"
                    values={eduMethodIds}
                    filter={{ is_active: true }}
                    onChange={values => this.onChangeBlock(index, "eduMethodIds", values)} />
            </div>
            <div className="column small-6">
                <Comment
                    value={this.state.comments && this.state.comments["block_dfht_method_other_" + index]}
                    onChange={e => this.onChangeComment("block_dfht_method_other_" + index, e)}
                />
                <label>
                    {__("Egyéb módszer")}:
                </label>
                <input type="text" value={block.dfht_method_other || ""} onChange={e => this.onChangeBlock(index, "dfht_method_other", e.target.value)} />
            </div>
            <div className="column small-6">
                <Comment
                    value={this.state.comments && this.state.comments["block_eduWorkFromIds_" + index]}
                    onChange={e => this.onChangeComment("block_eduWorkFromIds_" + index, e)}
                />
                <label>
                    {__("Munkaforma")}:
                </label>
                <CrudMultiSelect
                    crudClassProxy={eduWorkFormCrudClassProxy}
                    displayFieldName="title"
                    orderByFieldName="title"
                    values={eduWorkFromIds}
                    filter={{ is_active: true }}
                    onChange={values => this.onChangeBlock(index, "eduWorkFromIds", values)} />
            </div>
            <div className="column small-6">
                <Comment
                    value={this.state.comments && this.state.comments["block_dfht_work_form_other_" + index]}
                    onChange={e => this.onChangeComment("block_dfht_work_form_other_" + index, e)}
                />
                <label>
                    {__("Egyéb munkaforma")}:
                </label>
                <input type="text" value={block.dfht_work_form_other || ""} onChange={e => this.onChangeBlock(index, "dfht_work_form_other", e.target.value)} />
            </div>
            <div className="column small-12">
                <Comment
                    value={this.state.comments && this.state.comments["block_subprogramIds_" + index]}
                    onChange={e => this.onChangeComment("block_subprogramIds_" + index, e)}
                />
                <label>
                    {__("Kapcsolódás az alprogramokhoz")}:
                </label>
                <CrudMultiSelect
                    crudClassProxy={subprogramCrudClassProxy}
                    displayFieldName="title"
                    orderByFieldName="title"
                    values={subprogramIds}
                    filter={{ is_active: true }}
                    onChange={values => this.onChangeBlock(index, "subprogramIds", values)} />
            </div>
            <div className="column small-12">
                <Comment
                    value={this.state.comments && this.state.comments["block_tools_" + index]}
                    onChange={e => this.onChangeComment("block_tools_" + index, e)}
                />
                <label>
                    {__("Taneszközök")}:
                </label>
                <textarea rows={2} value={block.tools || ""} onChange={e => this.onChangeBlock(index, "tools", e.target.value)} />
            </div>

        </div>;
    }

}
