import { any } from "prop-types"
import * as React from "react";
import { me, hasAnyGroup, Groups } from "@src/framework/server/Auth";
import SurveyAnswerCrud from "@src/framework/crud/usr/SurveyAnswerCrud";
import { NEW_TEACHER_SURVEY_ID, NEW_STUDENT_SURVEY_ID, SURVEY_SKIPPED } from "./SurveyConstants";
import { __ } from "@src/translation";
import { Link } from "react-router-dom";
import { PATH_COURSE_ORGANIZER_SURVEY } from "@src/Routes";

import { history } from '@src/index';
import { differenceInDays } from "date-fns";

export class SurveyStartComponent extends React.Component<any, any>{

    constructor(props: any) {
        super(props);

        /* this.state = {
         }*/
    }

    static async showSurvey(): Promise<boolean> {

        if (!me) return false;
        try {
            // todo: show for the group everyone. Everyone, who is not oh worker or developer
            if (!hasAnyGroup(me, [Groups.Admin, Groups.OFIEditor, Groups.LessonplanEditor, Groups.LessonplanValidator, Groups.LessonPlanMentor, Groups.Admin, Groups.Developer])) {
                // if(hasAnyGroup(me,[Groups.TestTeacher, Groups.TestStudent]) ){
                const surveys = await SurveyAnswerCrud.list({ filter: { sec_user_id: me.id, survey_id: [NEW_TEACHER_SURVEY_ID, NEW_STUDENT_SURVEY_ID], is_active: true } });
                if (surveys && surveys.length > 0) {
                    return false;
                }

                let timeOfSkip = sessionStorage.getItem(SURVEY_SKIPPED + "/" + me!.id);
                if (!timeOfSkip) return true;

                let skipTime: number = JSON.parse(timeOfSkip).timestamp;
                let timeDiff = differenceInDays(skipTime, Date.now());
                if (timeDiff >= 1) return true; // if more than a day has passed
            }
        } catch (e) {
            return false; // sessionStorage does not exist
        }

        return false;
    }

    onSkip() {
        sessionStorage.setItem(SURVEY_SKIPPED + "/" + me!.id, JSON.stringify({
            timestamp: new Date().getTime()
        }));
        //history.push("/");
        location.reload();
    }

    clickOnSurvey(surveyId: number) {
        sessionStorage.setItem(SURVEY_SKIPPED + "/" + me!.id, JSON.stringify({
            timestamp: new Date().getTime()
        }));
        location.replace(PATH_COURSE_ORGANIZER_SURVEY + "/?survey_id=" + surveyId);
        //history.push(PATH_COURSE_ORGANIZER_SURVEY + "/?survey_id=" + surveyId);
    }

    render() {
        return <div className="row">
            <div className="column small-12" style={{ paddingTop: "1em" }}>

                <h4>{__("Elégedettségi kérdőív")}</h4>
                <h3>{__("Kedves")} {(me && me.person && me.person.full_name) || __("Felhasználó")}!</h3>
                <p>{__("Kíváncsiak vagyunk felhasználóink véleményére! ")}<br />
                    {__("Kérjük, töltse ki a maximum 10 percet igénybe vevő kérdőívünket, amellyel hozzájárulhat a portál jobbá tételéhez!")}<br />
                    {__("Minden visszajelzés fontos számunkra!")}
                </p>
                <div>
                    <button onClick={this.clickOnSurvey.bind(this, NEW_STUDENT_SURVEY_ID)}
                        className="button success">
                        <i className="fa fa-question-circle" /> &nbsp;
                    Kitöltöm diákként
                    </button>

                    <button onClick={this.clickOnSurvey.bind(this, NEW_TEACHER_SURVEY_ID)}
                        className="button success">
                        <i className="fa fa-question-circle" /> &nbsp;
                    Kitöltöm tanárként
                    </button>

                </div>
                <button onClick={this.onSkip.bind(this)} style={{ float: "right" }} className="button">{__("Kitöltöm később")}</button>

            </div>
        </div>;
    }

}