import * as React from 'react'
import * as Server from '@src/server/PublicServer'
import { Link } from 'react-router-dom';
import { ElasticContentType, getTypeFromItemId } from '@src/component/search/SearchComponent';
import { Dialog } from '@src/component/Dialog';
import { __ } from '@src/translation';
import { app } from '@src/index';
import { Pager } from '@src/component/Pager';

export type SearchStatus = {
    searchInProgess: boolean;
    response: Server.SearchResult[];
}

type SearchComponentProps = {
    subject_id: Number;
    grade_id: Number;
    bookPath: string;
    onResultCallback?: (searchStatus?: SearchStatus) => void;
}

export default class SearchComponent extends React.Component<SearchComponentProps, { keywords: string, result: any[], searchStatus?: SearchStatus, searchPageIndex?: number, resultNum: number }> {

    constructor(prop: SearchComponentProps) {
        super(prop);
        this.state = {
            keywords: '',
            result: [],
            searchPageIndex: 0,
            resultNum: 0
        }
    }

    onInputChange(e: any) {
        this.setState(
            { keywords: e.target.value }
        );
    }

    onKeyPress(e: any) {
        if (e.key === 'Enter') {
            this.onSearch();
        }
    }

    render() {
        var searchText: any = null;
        var searchResult: any = [];


        if (this.state.searchStatus) {
            if (this.state.searchStatus!.searchInProgess) {
                searchText = <div aria-hidden="true" className="search-info">{__("Keresés folyamatban...")}</div>;
            } else {

                let results = this.state.searchStatus!.response.filter(item => {
                    return getTypeFromItemId(item) == ElasticContentType.Section || getTypeFromItemId(item) == ElasticContentType.Exercise;
                });

                if (this.state.searchStatus!.response.length == 0) {
                    searchText = <div className="search-info">{__("Nincs találat")}</div>;
                } else {
                    searchResult = <>
                        <div className="search-info">

                            {this.state.resultNum} {__("találat a keresett szavakra")} ({this.state.keywords}):
                        </div>

                        <ul className="book-search-result">

                            {

                                results.map((r, i) => {
                                    var url = this.props.bookPath + r.lesson_uri_segment + "?w=" + this.state.keywords + "#section-" + r.section_id;

                                    if (getTypeFromItemId(r) == ElasticContentType.Section || getTypeFromItemId(r) == ElasticContentType.Lesson || getTypeFromItemId(r) == ElasticContentType.Chapter || getTypeFromItemId(r) == ElasticContentType.Book) {
                                        if (!r.text) return false;

                                        return <li key={i}>
                                            <div className="source">{r.chapter_name} / {r.lesson_name}</div>
                                            <Link to={url} onClick={() => this.setState({ searchStatus: undefined })}>
                                                <h3>{r.section_name}</h3>
                                                <span dangerouslySetInnerHTML={{ __html: (r.highlight && r.highlight!.length > 100 ? r.highlight!.slice(0, 100) + "..." : r.highlight!) }}></span>
                                            </Link>
                                        </li>
                                    }
                                    else if (getTypeFromItemId(r) == ElasticContentType.Exercise) {
                                        return <li key={i}>
                                             <div className="source">{r.engine_name}</div>
                                            <Link to={url} onClick={() => this.setState({ searchStatus: undefined })}>
                                                <h3>{r.exercise_name}</h3>
                                                <span dangerouslySetInnerHTML={{ __html: (r.highlight && r.highlight!.length > 100 ? r.highlight!.slice(0, 100) + "..." : r.highlight!) }}></span>
                                            </Link>
                                        </li>
                                    }
                                    else {
                                        return false;
                                    }
                                })
                            }
                        </ul>
                        <Pager resultNum={this.state.resultNum} elementPerPage={5} pageIndex={this.state.searchPageIndex! + 1} paging={this.onSearch.bind(this)} setPageIndex={(i: number) => { this.setState({ searchPageIndex: i - 1 }) }}></Pager>

                        <button className="back" onClick={() => this.setState({ searchStatus: undefined })}>{__("Vissza a könyvhöz")}</button>
                    </>
                }
            }
        }

        return <div>
            <div className="eke-booksearch">
                <input
                    tabIndex={0}
                    title={__("Keresés a könyvben")}
                    ref="input"
                    onChange={this.onInputChange.bind(this)}
                    onKeyPress={this.onKeyPress.bind(this)}
                    className="eke-booksearch__input"
                    placeholder={__("Keresés a könyvben")} />
                <i className="fas fa-search search-btn" onClick={this.onSearch.bind(this)}></i>
            </div>

            {
                this.state.searchStatus
                    ?
                    <Dialog key="searchDialog" title={__("Keresés a könyvben")} width={1170} onClose={() => this.setState({ searchStatus: undefined })} >
                        <div className="book-search">
                            {searchText}{searchResult}
                        </div>
                    </Dialog>
                    :
                    null
            }

        </div>
    }

    componentDidMount() {
        // A react-os onKeyDown event valamiért ezt nem oldotta meg (az input-ban enter-t nyomva bezáródott az egész menü)
        const input: any = this.refs.input;
        input.addEventListener('keydown', (e: any) => {
            e.stopPropagation();
            return false;
        })
    }

    async onSearch() {
        var result: any;
        this.setState({ searchStatus: { searchInProgess: false, response: [] } })

        this.props.onResultCallback ? this.props.onResultCallback({ searchInProgess: true, response: [] }) : null;

        try {
            let resultNumSearch = await Server.search({
                keyword: "*" + this.state.keywords + "*",
                subject_id: this.props.subject_id + "",
                grade_id: this.props.grade_id + "",
                exactmatch: "true",
                wildcard: "true",
                countonly: true,
                maxhits: "10000"

            });
            let resultNum = resultNumSearch[0].count;
            result = await Server.search({
                keyword: "*" + this.state.keywords + "*",
                subject_id: this.props.subject_id + "",
                grade_id: this.props.grade_id + "",
                exactmatch: "true",
                wildcard: "true",
                from: this.state.searchPageIndex! * 5,
                maxhits: "5"
            });
            this.setState({ searchStatus: { searchInProgess: false, response: result }, resultNum: resultNum! });

            this.props.onResultCallback ? this.props.onResultCallback({ searchInProgess: false, response: result }) : null;
        } catch (e) {
            app.showErrorFromJsonResult(e);
        }

    }

}
