import * as React from 'react';
import { BubbleLoader } from 'react-css-loaders';
import { app } from '@src/index';
import DcFieldTypeCrud, { IDcFieldTypeRecord } from '@framework/crud/meta/DcFieldTypeCrud';
import DcFieldValueCrud, { IDcFieldValueRecord } from '@framework/crud/meta/DcFieldValueCrud';
import { __ } from '@src/translation';

export interface DublinCoreStore {
    values: IDcFieldValueRecord[];
    to_remove: number[];
}

export type DublinCoreStoreEvent = (store: DublinCoreStore) => void;

export interface IDublinCoreEditorPluginProps {
    tableInfoId: number;
    recId: number | null;
    store: DublinCoreStore;
    onStoreLoaded : DublinCoreStoreEvent;
    onStoreChanged : DublinCoreStoreEvent;
}

export interface IDublinCoreEditorPluginState {
    loading: boolean;
    types?: IDcFieldTypeRecord[];
    type_by_id?: { [dc_type_id: number]: IDcFieldTypeRecord };
    current_type_id: number | null;    
}

type SelectValueChangedEvent = (value: number | null) => void;

export const saveDublinCoreStore = async (tableInfoId:number, recordId:number, store:DublinCoreStore) : Promise<boolean> => {
    try {
        for (let i=0; i<store.to_remove.length; i++) {
            await DcFieldValueCrud.deleteById(store.to_remove[i]);
        }
        for (let i=0; i<store.values.length; i++) {
            const item = store.values[i];
            await (new DcFieldValueCrud({
                id: item.id,
                table_info_id: tableInfoId,
                record_id: recordId,
                dc_field_type_id: item.dc_field_type_id,
                value: item.value!
            })).put();
        }
        return Promise.resolve(true);
    } catch (error) {
        app.showErrorFromJsonResult(error);
        return Promise.reject(false);
    }
}

export default class DublinCoreEditorPlugin extends React.Component<IDublinCoreEditorPluginProps, IDublinCoreEditorPluginState> {
    constructor(props: IDublinCoreEditorPluginProps) {
        super(props);
        this.state = { loading: true, current_type_id: null };
    }

    componentWillMount() {
        this.asyncReload();
    }

    private asyncReload = async () => {
        try {
            let newState = { loading: false };
            if (this.state.types === undefined) {
                let types = await DcFieldTypeCrud.list({
                    'order_by': 'title'
                });
                let type_by_id = {}
                types.forEach((type) => {
                    type_by_id[type.id!] = type;
                })


                newState['types'] = types;
                newState['type_by_id'] = type_by_id;

            }

            this.setState(newState, async () => {
                this.props.onStoreLoaded({
                    values: await DcFieldValueCrud.list({
                        filter: {
                            table_info_id: this.props.tableInfoId,
                            record_id: this.props.recId
                        },
                        order_by: [
                            { name: 'dc_field_type_id' },
                        ]
                    }),
                    to_remove: []
                });
            });
            
        } catch (error) {
            app.showErrorFromJsonResult(error);
        }
    }

    private typeSelector = (value: number | null, onChange: SelectValueChangedEvent) => {
        return <select
            onChange={(e: any) => {
                let value = e.target.value;
                onChange(value ? parseInt(value) : null);
            }}
            value={value || ""}
        >
            <option key="0" value="">{ __("Kérem válasszon...") }</option>
            {this.state.types!.map((type: IDcFieldTypeRecord) =>
                <option key={type.id!} value={type.id!} title={type.definition}>{type.title!}</option>
            )}
        </select>
    }

    private addNewValue = (dcTypeId: number | null) => {
        if (dcTypeId) {
            let values = this.props.store.values.concat([{
                table_info_id: this.props.tableInfoId,
                record_id: this.props.recId!,
                dc_field_type_id: dcTypeId,
                value: ''
            }]);
            let newStore = { ...this.props.store, values };
            this.props.onStoreChanged(newStore);
        }
    }

    private changeType = (index: number, dcTypeId: number | null) => {
        let values = this.props.store.values.concat();
        let to_remove = this.props.store.to_remove.concat();
        if (dcTypeId) {
            values[index].dc_field_type_id = dcTypeId;
        } else {
            const oldValue =  values[index];
            if (oldValue.id) {
                to_remove.push(oldValue.id);
            }
            values.splice(index, 1);
        }
        this.props.onStoreChanged({
            ...this.props.store,
            values, to_remove
        })
        this.setState({ current_type_id: dcTypeId });
    }

    private onValueChange = (index: number, value: string) => {
        let values = this.props.store.values.concat();
        values[index].value = value ? value : "";
        this.props.onStoreChanged({...this.props.store, values, });
    }

    private showHint = (index: number) => {
        this.setState({ current_type_id: this.props.store.values[index].dc_field_type_id! });
    }

    private hideHint = () => {
        this.setState({ current_type_id: null });
    }

    render() {
        if (this.state.loading) {
            return <BubbleLoader />;
        } else {
            let definition = null;
            let explanation = null;
            if (this.state.current_type_id) {
                const type = this.state.type_by_id![this.state.current_type_id];
                definition = type.definition;
                explanation = type.explanation;
            }

            const values = this.props.store.values;

            return <div className="row">
                <div className="column small-12">
                    <table>
                        <thead>
                            <tr>
                                <th>{ __("Mező típusa") }</th>
                                <th>{ __("Mező értéke") }</th>
                            </tr>
                        </thead>
                        <tbody>
                            {values.map((value: IDcFieldValueRecord, index: number) =>
                                <tr key={index}>
                                    <td>{this.typeSelector(value.dc_field_type_id!, (typeId) => this.changeType(index, typeId))}</td>
                                    <td>
                                        <input type="text"
                                            value={value.value || ""}
                                            onChange={(e: any) => {
                                                this.onValueChange(index, e.target.value)
                                            }}
                                            onFocus={() => this.showHint(index)}
                                            onBlur={() => this.hideHint()}
                                        />
                                    </td>
                                </tr>
                            )}
                            <tr key="new-value">
                                <td>{this.typeSelector(null, this.addNewValue)}</td>
                                <td></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                {definition
                    ? <div className="column small-12">
                        <p className="callout primary">
                            <b>{ __("Definíció") }:</b>&nbsp;
                            {definition}
                        </p>
                    </div>
                    : null}
                {explanation
                    ? <div className="column small-12">
                        <p className="callout secondary">
                            <b>{ __("Magyarázat") }:</b>&nbsp;
                            {explanation}
                        </p>
                    </div>
                    : null}
            </div>
        }
    }
}