import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Z_INDEX_DIALOG_ROOT } from '@src/Const';
import SplitPane from 'react-split-pane';
//import 'motion-ui/dist/motion-ui.min.js';
declare var MotionUI: any;

export class Card
    extends React.Component<{ header: string | JSX.Element, className?: string }, any> {

    render() {
        return <>

            <div className={"card " + (this.props.className ? this.props.className : "")}>
                <div className="card-divider">
                    {this.props.header}
                </div>
                <div className="card-section">
                    {this.props.children}
                </div>
            </div>

        </>
    }
}

export enum AnimationMode {
    toggleIn = "toggle-in",
    toggleOut = "toggle-out",
    slideInDown = "slide-in-down",
    slideInUp = "slide-in-up",
    slideInLeft = "slide-in-left",
    slideInRight = "slide-in-right",
    slideOutDown = "slide-out-down",
    slideOutUp = "slide-out-up",
    slideOutLeft = "slide-out-left",
    slideOutRight = "slide-out-right",
    fadeIn = "fade-in",
    fadeOut = "fade-out",
    hingeInFromTop = "hinge-in-from-top",
    hingeInFromBottom = "hinge-in-from-bottom",
    hingeInFromLeft = "hinge-in-from-left",
    hingeInFromRight = "hinge-in-from-right",
    hingeInFromMiddleX = "hinge-in-from-middle-x",
    hingeInFromMiddleY = "hinge-in-from-middle-y",
    hingeOutFromTop = "hinge-out-from-top",
    hingeOutFromBottom = "hinge-out-from-bottom",
    hingeOutFromLeft = "hinge-out-from-left",
    hingeInOutFromRight = "hinge-out-from-right",
    hingeOutFromMiddleX = "hinge-out-from-middle-x",
    hingeOutFromMiddleY = "hinge-out-from-middle-y",
    scaleInUp = "scale-in-up",
    scaleInDown = "scale-in-down",
    scaleOutUp = "scale-out-up",
    scaleOutDown = "scale-out-down",
    spinIn = "spin-in",
    spinOut = "spin-out",
    spinInCCW = "spin-in-ccw",
    spinOutCCW = "spin-out-ccw",
}

export interface IToggleState { isOpen: boolean, isToggle?: boolean }
export interface IToggleProps { header?: string | JSX.Element, id?: string, isToggle?: boolean, className?: string, animationIn?: AnimationMode, animationOut?: AnimationMode, onToggle?: (isOpen: boolean) => void, ariaHidden?: boolean, isOpen?: boolean, headingLevel?: number }

export class ToggleBase<P extends IToggleProps, S extends IToggleState>
    extends React.Component<P, S> {

    close() {
        if (this.state.isOpen) {
            var toggle = (this.refs.toggleContent as HTMLDivElement);
            var thisToggle = this;
            if (this.props.animationOut) {
                toggle.style.height = toggle.scrollHeight + 'px';
                window.setTimeout(function () {
                    toggle.style.height = "0";
                }, 50);
                MotionUI.animateOut(toggle, this.props.animationOut, function () {
                    thisToggle.setState({ isOpen: false })
                    toggle.style.height = "0";
                });
            }
            else {
                thisToggle.setState({ isOpen: !thisToggle.state.isOpen })
            }
        }
        if (this.props.onToggle)
            this.props.onToggle(false);
    }
    open() {
        if (!this.state.isOpen) {
            var toggle = (this.refs.toggleContent as HTMLDivElement);
            var thisToggle = this;
            if (this.props.animationIn) {
                window.setTimeout(function () {
                    var getHeight = function () {
                        toggle.style.display = 'block';
                        var height = toggle.scrollHeight + 'px';
                        toggle.style.display = '';

                        return height;
                    };

                    var height = getHeight();
                    toggle.style.height = height;
                }, 50);


                MotionUI.animateIn(toggle, this.props.animationIn, function () {
                    thisToggle.setState({ isOpen: true })
                    toggle.style.height = "";
                });

            }
            else {
                thisToggle.setState({ isOpen: !thisToggle.state.isOpen })
            }
        }
        if (this.props.onToggle)
            this.props.onToggle(true);
    }
    toggle() {
        if (this.state.isToggle) {
            if (this.state.isOpen) {
                this.close();
            }
            else {
                this.open();
            }
        }
    }

    render() {
        return <>

            <div aria-hidden={this.props.ariaHidden!} ref="togglePanel" id={this.props.id ? this.props.id : ""} className={"toggle " + (this.props.className ? this.props.className : "")}>
                {
                    this.props.header
                        ?
                        <a href="javascript:void(0)" tabIndex={0} aria-expanded={this.state.isOpen ? true : false} className={"panel-header" + (this.state.isToggle ? " is-toggle" : "")} onClick={this.toggle.bind(this)}>
                            {this.props.header}
                        </a>
                        : null
                }
                {
                    this.props.id
                        ?
                        <button id={this.props.id + "Btn"} onClick={this.toggle.bind(this)} style={{ display: "none" }}></button>
                        : null
                }
                <div style={{ display: this.state.isOpen ? "" : "none" }} ref="toggleContent" className="panel-content">
                    {this.props.children}
                </div>
            </div>

        </>
    }
}

export class ToggleControl
    extends React.Component<{ id: string, className?: string, ariaHidden?: boolean }, {}> {

    toggle() {
        var toggleBtn = document.getElementById(this.props.id + "Btn") as HTMLInputElement;
        toggleBtn.click();
    }

    render() {
        return <>

            <button aria-hidden={this.props.ariaHidden} ref="toggleControl" className={"toggle-control " + (this.props.className ? this.props.className : "")} onClick={this.toggle.bind(this)}>
                {this.props.children}
            </button>

        </>
    }
}

export class Toggle
    extends ToggleBase<IToggleProps, IToggleState> {

    constructor(props: any) {
        super(props);

        this.state = {
            isOpen: this.props.isOpen ? this.props.isOpen : false,
            isToggle: this.props.isToggle ? this.props.isToggle : true
        }
    }

    componentWillReceiveProps(nextProps: IToggleProps) {
        this.setState({ isOpen: nextProps.isOpen ? nextProps.isOpen : false });
    }

}
export class Panel
    extends ToggleBase<IToggleProps, IToggleState> {

    constructor(props: any) {
        super(props);

        this.state = {
            isOpen: true,
            isToggle: this.props.isToggle ? this.props.isToggle : false
        }
    }

    render() {

        let title: any;
        switch (this.props.headingLevel) {
            case 1: title = <h1 className="h1-title">{this.props.header}</h1>; break;
            case 2: title = <h2 className="h2-title">{this.props.header}</h2>; break;
            case 3: title = <h3 className="h3-title">{this.props.header}</h3>; break;
            case 4: title = <h4>{this.props.header}</h4>; break;
            case 5: title = <h5>{this.props.header}</h5>; break;
            case 6: title = <h6>{this.props.header}</h6>; break;
            default: title = <h2 className="h2-title">{this.props.header}</h2>; break;
        }
        return <>

            <div ref="togglePanel" id={this.props.id ? this.props.id : ""} className={"panel " + (this.props.className ? this.props.className : "")}>
                {
                    this.props.header
                        ?
                        (this.props.isToggle ?
                            <a href="javascript:void(0)" className={"panel-header" + (this.state.isToggle ? " is-toggle" : "")} onClick={this.toggle.bind(this)}>
                                {title}
                            </a>
                            :
                            <div className={"panel-header" + (this.state.isToggle ? " is-toggle" : "")}>
                                {title}
                            </div>
                        )
                        : null
                }
                {
                    this.props.id
                        ?
                        <button id={this.props.id + "Btn"} onClick={this.toggle.bind(this)} style={{ display: "none" }}></button>
                        : null
                }

                <div style={{ display: this.state.isOpen ? "" : "none" }} ref="toggleContent" className="panel-content">
                    {this.props.children}
                </div>
            </div>

        </>
    }
}

/* --- */
export class EditorPage
    extends React.Component<{ className?: string, ariaHidden?: boolean }, { panels?: any[] }> {

    constructor(props: any) {
        super(props);

        this.state = {
        }
    }

    initPanels(panel: any) {
        const panels = this.state.panels ? this.state.panels : [];

        if (!panels.includes(panel)) {
            panels.push(panel);
            this.setState({ panels: panels });
        }
    }

    update(panel: any, isHide: boolean) {
        const state = this.state;

        if (this.state.panels && isHide) {
            this.state.panels!.forEach(element => {
                if (element == panel) {
                    element.open();
                }
                else {

                }
            });
        }
        else if (this.state.panels && !isHide) {
            this.state.panels!.forEach(element => {
                if (element == panel) {
                    element.close();
                }
                else {
                    element.open(true);
                }
            });
        }


        this.setState(state);
    }

    render() {
        const childrenWithProps = React.Children.map(this.props.children, (child: any) =>

            (child.type == EditorSplit || child.type == EditorPanel || child.type == EditorSidebar) ?
                React.cloneElement(child, { panels: this.state.panels, update: this.update.bind(this), initPanel: this.initPanels.bind(this) })
                :
                child

        );
        return <>
            <div style={{ position: "relative", flex: "1" }}>
                <div aria-hidden={this.props.ariaHidden!} ref="page" className={"editor-page " + (this.props.className ? this.props.className : "")}>
                    {childrenWithProps}
                </div>
            </div>
        </>
    }
}

export class EditorSplit
    extends React.Component<{ className?: string, ariaHidden?: boolean, defaultSize?: number | string, panels?: any[], initPanel?: (panel: any) => void, update?: (panel: any, isHide: boolean) => void }, {}> {

    render() {
        const childrenWithProps = React.Children.map(this.props.children, (child: any) =>

            (child.type == EditorPanel || child.type == EditorSidebar) ?
                React.cloneElement(child, { panels: this.props.panels, update: this.props.update, initPanel: this.props.initPanel })
                :
                child

        );
        return <SplitPane aria-hidden={this.props.ariaHidden!} className={"editor-split " + (this.props.className ? this.props.className : "")} split="vertical" minSize={0} defaultSize={this.props.defaultSize ? this.props.defaultSize : "350px"} style={{ position: "relative" }}>
            {childrenWithProps}
        </SplitPane>
    }
}

export class EditorPanel
    extends React.Component<{ closable?: boolean, className?: string, ariaHidden?: boolean, panels?: any[], initPanel?: (panel: any) => void, update?: (panel: any, isHide: boolean) => void }, { isHide: boolean, sideWidth: string }> {

    constructor(props: any) {
        super(props);

        this.state = {
            isHide: false,
            sideWidth: "auto"
        }
    }

    componentDidMount() {
        if (this.props.initPanel)
            this.props.initPanel(this);
    }
    componentDidUpdate() {
        if (this.props.initPanel)
            this.props.initPanel(this);

    }

    close() {
        const thisPane = ((this.refs.panel as HTMLDivElement).closest('.Pane')! as HTMLElement);
        const nextPane = getNextSibling(thisPane, '.Pane');
        const prevPane = getPrevSibling(thisPane, '.Pane');
        const pane2 = nextPane ? nextPane : prevPane;

        if (prevPane) {
            thisPane.style.flex = "0 0 auto";
            thisPane.style.width = "auto";

            pane2.style.flex = "1 1 auto";
            if (pane2.querySelector('.close')) pane2.querySelector('.close')!.classList.remove("close");

            const prevResizer = getPrevSibling(thisPane, '.Resizer');
            if (prevResizer) prevResizer.style.display = "none";

            this.setState({ isHide: true, sideWidth: pane2.style.width != "auto" ? pane2.style.width! : this.state.sideWidth });
        }
        else if (nextPane) {
            const width = thisPane.style.width!.toString();
            thisPane.style.flex = "0 0 auto";
            thisPane.style.width = "auto";

            pane2.style.flex = "1 1 auto";
            if (pane2.querySelector('.close')) pane2.querySelector('.close')!.classList.remove("close");

            const nextResizer = getNextSibling(thisPane, '.Resizer');
            if (nextResizer) nextResizer.style.display = "none";

            this.setState({ isHide: true, sideWidth: width! });
        }

    }
    open(full?: boolean) {
        const thisPane = ((this.refs.panel as HTMLDivElement).closest('.Pane')! as HTMLElement);
        const nextPane = getNextSibling(thisPane, '.Pane');
        const prevPane = getPrevSibling(thisPane, '.Pane');
        const pane2 = nextPane ? nextPane : prevPane;

        if (prevPane) {
            const prevResizer = getPrevSibling(thisPane, '.Resizer');
            if (prevResizer) prevResizer.style.display = "";
            //open                   
            thisPane.style.flex = "1 1 auto";
            pane2.style.flex = "0 0 auto";

            pane2.style.width = this.state.sideWidth;


            this.setState({ isHide: false, sideWidth: pane2.style.width != "auto" ? pane2.style.width! : this.state.sideWidth });
        }
        else if (nextPane) {
            const nextResizer = getNextSibling(thisPane, '.Resizer');
            //open 
            if (full) {
                thisPane.style.flex = "1 1 auto";
            }
            else {
                thisPane.style.flex = "0 0 auto";
                thisPane.style.width = this.state.sideWidth;
            }
            if (nextResizer) nextResizer.style.display = "";
            this.setState({ isHide: false, sideWidth: pane2.style.width! });
        }
    }

    sizeClick() {

        if (this.props.update != undefined) {
            this.props.update(this, this.state.isHide);
        }
    }


    render() {
        return <>
            <div aria-hidden={this.props.ariaHidden!} ref="panel" className={"editor-panel " + (this.props.className ? this.props.className : "") + (this.state.isHide ? " close" : "")}>
                {this.props.closable
                    ?
                    <div className="tools">
                        <i onClick={this.sizeClick.bind(this)} className="far fa-caret-square-left"></i>
                    </div>
                    : null
                }

                <div className="content">
                    {this.props.children}
                </div>
            </div>

        </>
    }
}

export class EditorSidebar
    extends React.Component<{ width?: string, className?: string, ariaHidden?: boolean, panels?: any[], initPanel?: (panel: any) => void, update?: (panel: any, isHide: boolean) => void }, { isHide: boolean, width: string }> {

    constructor(props: any) {
        super(props);

        this.state = {
            isHide: false,
            width: !this.props.width ? "auto" : this.props.width
        }
    }

    sidebar: HTMLElement;
    componentDidMount() {
        if (this.props.initPanel)
            this.props.initPanel(this);

        this.sidebar = ((this.refs.sidebar as HTMLDivElement).closest('.Pane')! as HTMLElement);
        this.sidebar.style.width = this.state.width;
    }


    componentDidUpdate() {
        if (this.props.initPanel)
            this.props.initPanel(this);
    }

    close() {
        const nextPane = getNextSibling(this.sidebar, '.Pane');
        const prevPane = getPrevSibling(this.sidebar, '.Pane');
        const pane2 = nextPane ? nextPane : prevPane;
        const nextResizer = getNextSibling(this.sidebar, '.Resizer');
        const width = this.sidebar.style.width;

        //close
        this.sidebar.style.width = "auto";
        this.sidebar.style.flex = "0 0 auto";
        nextResizer.style.display = "none";
        pane2.style.flex = "1 1 auto";
        if (pane2.querySelector('.close')) pane2.querySelector('.close')!.classList.remove("close");

        this.setState({ isHide: true, width: width! });
    }
    open() {
        const nextPane = getNextSibling(this.sidebar, '.Pane');
        const prevPane = getPrevSibling(this.sidebar, '.Pane');
        const pane2 = nextPane ? nextPane : prevPane;
        const nextResizer = getNextSibling(this.sidebar, '.Resizer');

        //open         
        this.sidebar.style.width = this.state.width;
        this.sidebar.style.flex = "0 0 auto";
        nextResizer.style.display = "";
        pane2.style.flex = "1 1 auto";

        this.setState({ isHide: false });
    }

    sizeClick() {

        // const nextPane = getNextSibling(this.sidebar, '.Pane');
        // const prevPane = getPrevSibling(this.sidebar, '.Pane');
        // const pane2 = nextPane ? nextPane : prevPane;
        // const nextResizer = getNextSibling(this.sidebar, '.Resizer');
        // const width = this.sidebar.style.width;

        // if(this.state.isHide)
        // {   //open         
        //     this.sidebar.style.width = this.state.width;     
        //     nextResizer.style.display = "";  
        //     pane2.style.flex = "1 1 auto"; 
        // }
        // else
        // {
        //     //close
        //     this.sidebar.style.width = "auto";
        //     this.sidebar.style.flex = "0 0 auto";
        //     nextResizer.style.display = "none"; 
        //     pane2.style.flex = "1 1 auto";
        //     if(pane2.querySelector('.close')) pane2.querySelector('.close')!.classList.remove("close");  
        // }

        // this.setState({
        //     isHide: !this.state.isHide,
        //     width: width!
        // });

        if (this.props.update != undefined) {
            this.props.update(this, this.state.isHide);
        }
    }


    render() {
        return <>

            <div aria-hidden={this.props.ariaHidden!} ref="sidebar" className={"editor-sidebar " + (this.props.className ? this.props.className : "") + (this.state.isHide ? " close" : "")}>
                <div className="tools">
                    <i onClick={this.sizeClick.bind(this)} className="far fa-caret-square-left"></i>
                </div>
                <div className="content">
                    {this.props.children}
                </div>
            </div>

        </>
    }
}

var getPrevSibling = function (elem: any, selector: string) {
    var sibling = elem.previousElementSibling;
    if (!selector) return sibling;
    while (sibling) {
        if (sibling.matches(selector)) return sibling;
        sibling = sibling.previousElementSibling;
    }
};

var getNextSibling = function (elem: any, selector: string) {
    var sibling = elem.nextElementSibling;
    if (!selector) return sibling;
    while (sibling) {
        if (sibling.matches(selector)) return sibling;
        sibling = sibling.nextElementSibling
    }
};