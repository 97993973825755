import * as React from 'react';
import { match } from 'react-router';
import { app } from '@src/index';
import { BubbleLoader } from 'react-css-loaders';
import OoFileCrud, { IOoFileRecord } from '@src/framework/crud/media_pub/OoFileCrud';
import ViewDlMaterial, { IViewDlMaterialRecord } from '@src/framework/view/doc_pub/ViewDlMaterial';
import GradeCrud, { IGradeRecord } from '@src/framework/crud/doc/GradeCrud';
import SubjectCrud, { ISubjectRecord } from '@src/framework/crud/doc/SubjectCrud';
import DlMaterialGradeCrud from '@src/framework/crud/doc_pub/DlMaterialGradeCrud';
import { __ } from '@src/translation';
import { IDlSubjectRecord } from '@src/framework/crud/doc_pub/DlSubjectCrud';

type DownloadableMaterialPageProps = {
    match: match<{id: string}>
}

type DownloadableMaterialPageState = {
    loading: boolean;
    dlMaterial?: IViewDlMaterialRecord;
    files: IOoFileRecord[];
    //subject?: IDlSubjectRecord;
    grades: IGradeRecord[];
}

export class DownloadableMaterialPage extends React.Component<DownloadableMaterialPageProps, DownloadableMaterialPageState> {

    constructor(props: DownloadableMaterialPageProps) {
        super(props);

        this.state = {
            loading: true,
            grades: [],
            files: []
        }
    }

    componentDidMount() {
        this.reloadAsync();
    }

    componentDidUpdate(prevProps: DownloadableMaterialPageProps) {
        if (prevProps.match.params.id != this.props.match.params.id) {
            this.reloadAsync();
        }
    }

    private async reloadAsync() {
        try {

            const dlMaterial = (await ViewDlMaterial.load(Number(this.props.match.params.id))).record;

            const files = await OoFileCrud.list({filter: {oo_folder_id: dlMaterial.oo_folder_id}});
            
            //const subject = dlMaterial.subject_id !== undefined ? (await SubjectCrud.load(dlMaterial.subject_id!)).record : undefined;

            const dlGrades = await DlMaterialGradeCrud.list({filter: {dl_material_id: dlMaterial.id}});
            const grades = [];
            for(const dlGrade of dlGrades) {
                grades.push((await GradeCrud.load(dlGrade.grade_id!)).record);
            }
            
            this.setState({
                loading: false,
                dlMaterial,
                files,
                //subject,
                grades
            })

        } catch(e) {
            app.showErrorFromJsonResult(e);

            this.setState({
                loading: false
            })
        }
    }

    render() {
        
        if (this.state.loading) {
            return <BubbleLoader/>;
        }
        if (!this.state.dlMaterial) {
            return __("Nem sikerült betölteni a letölthető anyagot");
        }

        const altalanos = this.state.dlMaterial.props.altalanos || {};
        altalanos[__("Iskolai ár:") + " "] = undefined;

        const file = this.state.files.find(f => f.id === this.state.dlMaterial!.material_file_id);

        return <div className="row">
                <div className="column shrink" style={{marginTop: "1em"}}>
                    <img src={"/api/media/thumb/200/" + this.state.dlMaterial.thumbnail_file_sha1} /> <br/>
                </div>
                <div className="column" style={{marginTop: "1em"}}>
                    <h4>{this.state.dlMaterial.name}</h4>
                    <p>
                        {
                            altalanos &&
                            Object.keys(altalanos).sort().map(key => {
                                if (!altalanos[key]) return;
                                return <>
                                        <b>{key}</b> {altalanos[key]} <br/>
                                    </>
                            })
                        }
                    </p>
                </div>

                <div className="column small-12" style={{marginTop: "1em"}}>
                    <ul>
                        {
                            file &&
                            <li>
                                <a href={"/api/media/file/" + file.sha1} download={file.title}>{this.state.dlMaterial.name}</a>
                            </li>
                        }
                
                        {
                            this.state.files.filter(f => f.id !== this.state.dlMaterial!.material_file_id).map(f => {
                                if (f.id == this.state.dlMaterial!.thumbnail_file_id) return null;

                                return <li><a href={"/api/media/file/" + f.sha1} download={f.title}>{f.displayname || f.title}</a></li>;
                            })
                        }
                    </ul>
                </div>

            </div>;
    }
    
}
