import { Server } from '../../server/Server';
import Crud , { registerCrudClassProxy, IAsTextResult, IListParams, CrudClassProxy, ISearchTextParams, ISearchTextResult } from '../Crud';

export interface IWorkflowRecord {
    /**
     * Globálisan egyedi azonosító.
     * 
     * Ha új rekordot akarsz létrehozni put() metódussal, akkor hagyd üresen.
     * upsert() hívásnál figyelmen kívül van hagyva az értéke.
     *
     */
    id ?: number;
    /**
     * Rekord létrehozás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel létrehozásakor.
     */
    creation_time?: string /*timestamp?*/;
    /**
     * Ki hozta létre
     *
     * NOT NULL -> sys.sec_user ON DELETE noaction
     *
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozó felhasználóra hivatkozik.
     */
    creation_user_id?: number;
    /**
     * Melyik session-ből jött létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozáshoz használt munkamenetre hivatkozik.
     */
    creation_session_id?: number|null;
    /**
     * Utolsó módosítás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel módosításakor.
     */
    modification_time?: string /*timestamp?*/|null;
    /**
     * Ki módosította utoljára
     *
     * -> sys.sec_user ON DELETE noaction
     *
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosító felhasználóra hivatkozik.
     */
    modification_user_id?: number|null;
    /**
     * Melyik session-ből módosították utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosításhoz használt munkamenetre hivatkozik.
     */
    modification_session_id?: number|null;
    /**
     * Folyamat típus
     *
     * NOT NULL -> wf.wf_type ON DELETE noaction
     *
     */
    wf_type_id?: number;
    /**
     * Folyamat fej táblázat
     *
     * NOT NULL -> wf.head_table ON DELETE noaction
     *
     */
    head_table_id?: number;
    /**
     * Táblázat infó
     *
     * NOT NULL -> meta.table_info ON DELETE noaction
     *
     *
     * Ez mondja meg, hogy a folyamat melyik táblázat rekordjához tartozik.
     */
    table_info_id?: number;
    /**
     * Rekord azonosító
     *
     * Ez mondja meg, hogy a folyamat melyik rekordhoz tartozik.
     */
    rec_id?: number;
    /**
     * Állomás
     *
     * NOT NULL -> wf.station ON DELETE noaction
     *
     *
     * A folyamat aktuális állomása.
     */
    station_id?: number;
    /**
     * Utolsó átmenet
     *
     * -> wf.transition_log ON DELETE cascade
     *
     */
    last_transition_id?: number|null;
    /**
     * Utolsó feldolgozott átmenet
     *
     * -> wf.transition_log ON DELETE cascade
     *
     */
    proc_transition_id?: number|null;
    /**
     * Al-státusz
     *
     * -> wf.substation ON DELETE cascade
     *
     */
    substation_id?: number|null;
    /**
     * Szöveges állapot kiegészítés
     */
    status_text?: string|null;
    /**
     * Utolsó publikáló
     *
     * -> sys.sec_user ON DELETE noaction
     *
     */
    last_publisher_id?: number|null;
    /**
     * Utoljára publikálva
     */
    last_published?: string /*timestamp?*/|null;
}

/**
 *  wf.workflow - Folyamat
 *
 *  Idősoros táblázat, ami az összes tétel összes korábbi verzióját eltárolja.
 */

export default class WorkflowCrud extends Crud<IWorkflowRecord> {
    public static TABLE_INFO_ID = 716414181;
    public static getSchemaNameForClass() { return 'wf'; }
    public static getTableNameForClass() { return 'workflow'; }

    public static load: (id: number, aServer ?: Server) => Promise<WorkflowCrud>;
    public static asText: (id: number, includeRecord : boolean, aServer ?: Server) => Promise<IAsTextResult<IWorkflowRecord>>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<IWorkflowRecord[]>;
    public static searchText: (params: ISearchTextParams, aServer ?: Server) => Promise<ISearchTextResult<IWorkflowRecord>[]>;
    public static deleteById: (id: number, aServer ?: Server) => Promise<IWorkflowRecord>;
    public static unDeleteById: (id: number, aServer ?: Server) => Promise<IWorkflowRecord>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class WorkflowCrudClassProxy extends CrudClassProxy<IWorkflowRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const workflowCrudClassProxy = new WorkflowCrudClassProxy(WorkflowCrud);
registerCrudClassProxy(workflowCrudClassProxy);
