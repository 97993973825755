import { RouteComponentProps } from "react-router";

export type ModuleRoute<T> = {
    path: string;    
    component?: any;
    render?: ((props: RouteComponentProps<any>) => React.ReactNode);
    exact?: boolean| undefined;
    hasSidebar?: boolean| undefined;
    menuCode?: BaseMenu | T | undefined;
    layoutKey?: string;
    renderLayout?: (content:any)=>any;
    props?: any; 
    sidebarHeader?: string|JSX.Element;  
    sideBarSize?: number;  
    title?: string;
}

export type BaseMenu = "header-main" | "header-function" | "developer-sidebar" | "contentstore-sidebar" | "cms-sidebar" | "messages-sidebar" | "elearning-sidebar" | "course-sidebar" | "classroom-sidebar" | "graposit-sidebar";

export type ModuleMenu<T> = {
    enabled?: boolean | null;
    code?: T|BaseMenu;
    menuItemCode?: string;

    title?: string;
    iconClass?: string;
    path?: string;
    no?: number;

    subMenus?: ModuleMenu<T>[];
    separator?: boolean;

    matchName?: string;
}

export interface IRecordRoute {
    tableInfoId: number;
    recordId: number;
    route: string;
    module: any; // Module?
}

export abstract class Module<T> {

    private static modules: Module<any>[] = [];

    public static getModules() {
        return Module.modules;
    }

    constructor() {
        Module.modules.push(this);
    }

    public static async getAllRoutes(): Promise<Record<string, ModuleRoute<any>>> {
        let result = {};
        const modules = this.getModules();
        for (var i = 0; i< this.getModules().length; i++)
        {
            result = Object.assign(result, await modules[i].getRoutesAsync());
        }

        return result;
    }

    public abstract getRoutes(): Record<string, ModuleRoute<T>>;
    public abstract getRoutesAsync(): Promise<Record<string, ModuleRoute<T>>>;

    public abstract getMenus(): ModuleMenu<T>[];

    public abstract getRecordRoute(tableInfoId: number, recordId: number, edit?:boolean): string|null;

    public static getRecordRoutes(tableInfoId: number, recordId: number, edit?:boolean): IRecordRoute[] {
        const result : IRecordRoute[] = [];
        const modules = this.getModules();
        for (var i = 0; i< this.getModules().length; i++)
        {
            const module = modules[i];
            const route = module.getRecordRoute(tableInfoId, recordId, edit);
            if (route!==null) {
                result.push({tableInfoId, recordId, route, module});
            }
        }
        return result;
    }

    public static getDefaultRecordRoute(tableInfoId: number, recordId: number, edit?:boolean): IRecordRoute|null {
        const routes = this.getRecordRoutes(tableInfoId, recordId, edit);
        if (routes.length) {
            return routes[0];
        } else {
            return null;
        }
    }

}

export function getMenus<T>(code: T) {
    var menus : ModuleMenu<T>[] = [];
    Module.getModules().forEach(m => {       
        menus = menus.concat(m.getMenus().filter(m => (m.code === code && (m.enabled == undefined || m.enabled == true || m.separator))))
    });

    menus = menus.sort((a, b) => (a.no || 0) - (b.no || 0));

    return menus;
}

export function getRoutes<T>() {
    //var routes : any[] = [];
    var routes : Record<string, ModuleRoute<T>> = {};
    Module.getModules().forEach(async m => {
        const mRoutes = m.getRoutes();
        routes=Object.assign(routes,mRoutes)
    }); 
    return routes;
}
