import * as React from 'react';

import { ISecGroupRecord, SecGroupCrudClassProxy, secGroupCrudClassProxy } from '@src/framework/crud/sys/SecGroupCrud';
import RecordEditor from '@framework/forms/recordeditor';
import { SecUserGroupListViewForGroup } from '@src/component/sys/sys_sec_user_group_listview';
import { Groups } from '@src/framework/server/Auth';
import { Tabs, TabList, Tab, TabPanel } from 'react-tabs';
import { SecGroupLeaderListViewForGroup } from './sys_sec_group_leader_listview';
import { __ } from '@src/translation';


export default class SecGroupEditor extends RecordEditor<ISecGroupRecord> {

    public getCrudClassProxy(): SecGroupCrudClassProxy { return secGroupCrudClassProxy; }


    protected getDetailPanels(): any[] {
        let result = super.getDetailPanels();
        if (this.state.rec_id) {
            if (this.state.rec_id == Groups.Everyone) {
                result = result.concat(
                    <div className="row">
                        <div className="column small-12">
                            <div className="callout secondary">
                                {__("A mindenki csoportba az összes regisztrált aktív felhasználó")}
                                {__("automatikusan hozzá van rendelve. A mindenki csoportból csak")}
                                {__("a felhasználó inaktiválásával lehet eltávolítani tagot.")}
                            </div>
                        </div>
                    </div>
                );
            } else if (this.state.rec_id == Groups.Guest) {
                result = result.concat(
                    <div className="row">
                        <div className="column small-12">
                            <div className="callout secondary">
                                {__("A vendég csoport a nem regisztrált felhasználókat jelenti.")}
                                {__("Ezek a felhasználók anonimak, ezért nem lehet megadni")}
                                {__("a tagságot vagy a vezetőket.")}
                            </div>
                        </div>
                    </div>
                );

            } else {
                result = result.concat(
                    <div className="row">
                        <div className="column small-12">
                            <Tabs>
                                <TabList>
                                    <Tab>{__("A csoport tagjai")}</Tab>
                                    <Tab>{__("A csoport vezetői")}</Tab>
                                </TabList>
                                <TabPanel>
                                    <SecUserGroupListViewForGroup group_id={this.state.rec_id!} />
                                </TabPanel>
                                <TabPanel>
                                    <SecGroupLeaderListViewForGroup group_id={this.state.rec_id!} />
                                </TabPanel>
                            </Tabs>
                        </div>
                    </div>
                );
            }
        }
        return result;
    }

}
