import { Server } from '../../server/Server';
import Crud , { registerCrudClassProxy, IAsTextResult, IListParams, CrudClassProxy, ISearchTextParams, ISearchTextResult } from '../Crud';

export interface ISharedContentRunRecord {
    /**
     * Globálisan egyedi azonosító.
     * 
     * Ha új rekordot akarsz létrehozni put() metódussal, akkor hagyd üresen.
     * upsert() hívásnál figyelmen kívül van hagyva az értéke.
     *
     */
    id ?: number;
    /**
     * Rekord létrehozás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel létrehozásakor.
     */
    creation_time?: string /*timestamp?*/;
    /**
     * Ki hozta létre
     *
     * NOT NULL -> sys.sec_user ON DELETE noaction
     *
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozó felhasználóra hivatkozik.
     */
    creation_user_id?: number;
    /**
     * Melyik session-ből jött létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozáshoz használt munkamenetre hivatkozik.
     */
    creation_session_id?: number|null;
    /**
     * Utolsó módosítás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel módosításakor.
     */
    modification_time?: string /*timestamp?*/|null;
    /**
     * Ki módosította utoljára
     *
     * -> sys.sec_user ON DELETE noaction
     *
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosító felhasználóra hivatkozik.
     */
    modification_user_id?: number|null;
    /**
     * Melyik session-ből módosították utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosításhoz használt munkamenetre hivatkozik.
     */
    modification_session_id?: number|null;
    /**
     * Aktív
     *
     * Logikai törléshez használt mező.
     */
    is_active?: boolean;
    /**
     * Megosztott tartalom
     *
     * -> usr.shared_content ON DELETE noaction
     *
     */
    shared_content_id?: number|null;
    /**
     * Kurzus hallgatója
     *
     * -> elrn.course_student ON DELETE noaction
     *
     */
    course_student_id?: number|null;
    /**
     * Logikailag törölhető
     *
     * -> elrn.topic_item_task ON DELETE noaction
     *
     */
    topic_item_task_id?: number|null;
    /**
     * Futtatás ideje
     */
    run_time?: string /*timestamp?*/|null;
    /**
     * Ki futtatta
     *
     * NOT NULL -> sys.sec_user ON DELETE cascade
     *
     */
    run_by_id?: number;
    /**
     * Futtatás száma
     */
    run_no?: number /*integer*/;
    /**
     * Indítási paraméterek ÉS eredmények.
     *
     * A feladatsor futtató itt tudja eltárolni azt, hogy az adott futtatás során mely feladatok kerültek kiválasztásra. Az adaptív feladatsorok esetén itt egyéb más olyan adatok is szerepelhetnek, amik szükségesek a feladatsor futtatás folytatásához.
     */
    start_params?: any|null;
    /**
     * Tanári értékelés %
     */
    shared_by_success_percent?: number /* double precision */|null;
    /**
     * Szöveges tanári értékelés
     */
    shared_by_comments_html?: string|null;
    /**
     * Nyugtázva
     *
     * Amikor a felhasználó nyugtázta az értesítést.
     */
    acknowledged?: string /*timestamp?*/|null;
    /**
     * Befejezve
     *
     * Amikor a felhasználó befejezte a futtatást.
     */
    finished?: string /*timestamp?*/|null;
}

/**
 *  usr.shared_content_run - Megosztott tartalom futtatása
 *
 *  Idősoros táblázat, ami az összes tétel összes korábbi verzióját eltárolja.
 */

export default class SharedContentRunCrud extends Crud<ISharedContentRunRecord> {
    public static TABLE_INFO_ID = 1703541196;
    public static getSchemaNameForClass() { return 'usr'; }
    public static getTableNameForClass() { return 'shared_content_run'; }

    public static load: (id: number, aServer ?: Server) => Promise<SharedContentRunCrud>;
    public static asText: (id: number, includeRecord : boolean, aServer ?: Server) => Promise<IAsTextResult<ISharedContentRunRecord>>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<ISharedContentRunRecord[]>;
    public static searchText: (params: ISearchTextParams, aServer ?: Server) => Promise<ISearchTextResult<ISharedContentRunRecord>[]>;
    public static deleteById: (id: number, aServer ?: Server) => Promise<ISharedContentRunRecord>;
    public static unDeleteById: (id: number, aServer ?: Server) => Promise<ISharedContentRunRecord>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class SharedContentRunCrudClassProxy extends CrudClassProxy<ISharedContentRunRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const sharedContentRunCrudClassProxy = new SharedContentRunCrudClassProxy(SharedContentRunCrud);
registerCrudClassProxy(sharedContentRunCrudClassProxy);
