export class EKEOddOneOutServer{
    
    static evaluateOnServer(exercise: any, correctSolution: any[], userSolution: any[]): Evaluated {
        var success = true;
        var goodAnswers = 0;
        var numOfQuestions = exercise.exercise.number ? exercise.exercise.number : correctSolution.length;
        //sorting usersolution array by key
        for (let i = 0; i < userSolution.length; i++) {
            var min = userSolution[i].key;
            for (let j = i; j < userSolution.length; j++) {
                if (userSolution[j].key < min)
                    min = userSolution[j].key
            }
            if (min != userSolution[i].key) {
                var temp1Index = userSolution.indexOf(userSolution.find(function (x) { return x.key == min }));
                var temp2Index = userSolution.indexOf(userSolution[i]);
                var temp = userSolution[temp1Index];
                userSolution[temp1Index] = userSolution[temp2Index];
                userSolution[temp2Index] = temp;
            }
        }


        if (userSolution.length != numOfQuestions) success = false;

        for (var i = 0; i < userSolution.length; i++) {
            let sol_item = correctSolution[userSolution[i].key];
            if (userSolution[i].value != sol_item.value) {
                success = false;
            } else {
                goodAnswers++;
            }
        }

        let successPercent = Number(((goodAnswers) / numOfQuestions).toFixed(2));
        return { "success": success, "solution": correctSolution, "successPercent": success ? 1 : successPercent };
    }

    static filterExerciseOnServer(exercise: any, solution: any): any {
        if (!solution
            || !exercise.number || exercise.number < 1 || exercise.number >= solution.length) {
            return exercise;
        }

        var delIdx: any[] = [];
        let elementsToRemove = solution.length - exercise.number;

        while (delIdx.length != elementsToRemove) {             //generating deletable indexes 
            let j = Math.floor(Math.random() * (exercise.options.length));
            if (delIdx.indexOf(j) < 0)
                delIdx.push(j);
        }

        for (let index = 0; index < delIdx.length; index++) { //delete questions that are not needed because of limited question numbers
            exercise.options[delIdx[index]].key = "";
            exercise.options[delIdx[index]].values = "";
        }
        return exercise;
    }
}