import * as React from 'react';

import { viewGoodWordGlobalClassProxy } from '@src/framework/view/spell/ViewGoodWordGlobal';
import GoodWordGlobalListView from '@src/component/spell/good_word_global_listview';
import GoodWordGlobalEditor from '@src/component/spell/good_word_global_editor';

import AdminRoute from '@src/Routes'

export function SpellRoutes() {
   
    return [
        
        <AdminRoute path={viewGoodWordGlobalClassProxy.getDefaultListRoute()} component={GoodWordGlobalListView} key="GoodWordGlobalListView"/>,
        <AdminRoute path={viewGoodWordGlobalClassProxy.getDefaultEditRoute()} component={GoodWordGlobalEditor} key="GoodWordGlobalEditor"/>,



    ];
}

