import { History } from 'history';

export default class LocationUtil {
    /**
     * Eltárolja a jelenlegi helyet, hogy később vissza lehessen térni hozzá.
     * Ezt kb. akkor kell meghívnod, amikor a Router-ben megváltozik a location.
     */
    public static save() {
        try {
            localStorage.setItem(LocationUtil.LOCALSTORAGE_URL_PATH_KEY, window.location.pathname);
        } catch(e) {
            // QuotaExceededError DOM Exception 22-t ad ipad-en
        }
    }

    /**
     * Visszaállítja a legutóbb elmentett helyet.
     */
    public static restore(history: History): boolean {
        const oldPath = localStorage.getItem(LocationUtil.LOCALSTORAGE_URL_PATH_KEY);
        if (oldPath) {
            if (oldPath != window.location.pathname || window.location.href.indexOf("?code=") !== -1) {
                history.push(oldPath);
            }
            //history.replaceState(null, '', oldPath);
            return true;
        } else {
            return false;
        }
    }

    private static LOCALSTORAGE_URL_PATH_KEY = 'LOCATION_AFTER_REDIRECT';
}
