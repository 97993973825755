import { loadVimeoPlayerApiAsync } from "@src/Util";
import * as PublicServer from '@src/server/PublicServer';
import OoFileCrud from "@src/framework/crud/media_pub/OoFileCrud";

declare var Vimeo: any;

export async function saveVimeoUserEvents(iframe: HTMLIFrameElement, name: string, fileId: number, sha1: string,mediaTypeId?:number) {

    await loadVimeoPlayerApiAsync();
    var player = new Vimeo.Player(iframe);

    player.on('play', function(extra_data:any) {
        let data: PublicServer.IViewEventData = {
            eventTarget : PublicServer.UserEventTargets.VIMEO,
            eventType : PublicServer.UserEventTypes.PLAY,
            sha1 : sha1,
            mediaTypeId : mediaTypeId || undefined
        }
        /*data.event = "vimeo";
        data.vimeo_event = "play";
        data.sha1 = sha1;
        data.mediaTypeId=mediaTypeId;*/
        PublicServer.sendUserViewEvent({
            tableInfoId: OoFileCrud.TABLE_INFO_ID,
            recordId:fileId,
            name: name,
            data:data
         });
    });

    player.on('ended', function(extra_data: any) {
        let data: PublicServer.IViewEventData = {
            eventTarget : PublicServer.UserEventTargets.VIMEO,
            eventType : PublicServer.UserEventTypes.ENDED,
            sha1 : sha1,
            mediaTypeId : mediaTypeId || undefined
        }
        /*data.event = "vimeo";
        data.vimeo_event = "ended";
        data.sha1 = sha1;
        data.mediaTypeId=mediaTypeId;*/
        PublicServer.sendUserViewEvent({
            tableInfoId: OoFileCrud.TABLE_INFO_ID,
            recordId:fileId,
            name: name,
            data:data
          
        });
    });

    iframe.addEventListener("unload", () => player.destroy()); 
    
}
