import * as ExerciseBaseTypes from "@src/component/exercise/models/ExerciseBaseClass";
import { CheckableMultiTypeAnswerItem, AnswerTypes } from '@src/component/exercise/models/ExerciseBaseClass';
import { AExerciseTypeConverter, ShuffleResult, shuffle, ValidationResponse, StatisticsResponse } from "@src/component/exercise/models/AExerciseTypeConverter";
import { FillTableData } from "@src/component/exercise/engine/EKEFillTableDnDExerciseEngine/EKEFillTableDnDExerciseEngine";
import * as React from "react";
import { Accordion, AccordionItem } from '@src/component/ui/Accordion';
import ExerciseFileSelect, { ExerciseFileTypes } from '@src/component/exercise/Editor/ExerciseFileSelect';
import { Panel } from '@src/component/ui/Panel';
import { __ } from '@src/translation';

export type FillTableAnswerElement = {
    headers: any,
    rows: any[],
    first_column_header: boolean,
    first_row_header: boolean,
    odd_one_out: any
}

export class EKEFillTableAnswerConverter extends AExerciseTypeConverter {

    public hasTextAnswer = false;
    public hasImageAnswer = true;
    public hasTextImageAnswer = false;



    public convertToEditorAnswer(exercise: FillTableData): FillTableAnswerElement | undefined {
        if (exercise) {
            var rowList: any[] = [];
            var cur_exercise = exercise;
            let isFirstRowHeader = exercise.first_row_header;
            var solutionIdx = 0;
            // the old exercises have headears, we convert them to the new type
            if (exercise.headers && exercise.headers.length > 0) {
                isFirstRowHeader = true;
                let curr_row: CheckableMultiTypeAnswerItem[] = [];
                for (var _i = 0; _i < cur_exercise.headers.length; _i++) {
                    var ansObj: CheckableMultiTypeAnswerItem = {
                        type: AnswerTypes.text,
                        text: cur_exercise.headers[_i],
                        is_answer: false
                    };
                    curr_row.push(ansObj);
                }
                rowList.push(curr_row);
            }
            if (cur_exercise.fix_elements)
                for (var _i = 0; _i < cur_exercise.fix_elements.length; _i++) {
                    let curr_row: CheckableMultiTypeAnswerItem[] = [];
                    for (let _j = 0; _j < cur_exercise.fix_elements[_i].data.length; _j++) {
                        let element: CheckableMultiTypeAnswerItem = { type: AnswerTypes.text, is_answer: false };
                        let currCell = cur_exercise.fix_elements[_i].data[_j];
                        if (currCell.is_answer && cur_exercise.solution.length > solutionIdx) {
                            if (cur_exercise.options[cur_exercise.solution[solutionIdx]].type == "image") {
                                element = { is_answer: true, url: cur_exercise.options[cur_exercise.solution[solutionIdx]].image, text: cur_exercise.options[cur_exercise.solution[solutionIdx]].text, type: AnswerTypes.image };
                            } else if (cur_exercise.options[cur_exercise.solution[solutionIdx]].type == "text") {
                                element = { is_answer: true, url: "", text: cur_exercise.options[cur_exercise.solution[solutionIdx]].text, type: AnswerTypes.text };
                            } else if (cur_exercise.options[cur_exercise.solution[solutionIdx]].type == "sound") {
                                element = { is_answer: true, url: cur_exercise.options[cur_exercise.solution[solutionIdx]].url ? cur_exercise.options[cur_exercise.solution[solutionIdx]].url : "", text: cur_exercise.options[cur_exercise.solution[solutionIdx]].text, type: AnswerTypes.sound };
                            }
                            solutionIdx++;
                        } else {
                            if (currCell.answer.type == "image") {
                                element = { is_answer: false, url: currCell.answer.image, text: currCell.answer.text, type: AnswerTypes.image };
                            } else if (currCell.answer.type == "sound") {
                                element = { is_answer: false, text: currCell.answer.text, url: currCell.answer.url ? currCell.answer.url : "", type: AnswerTypes.sound };
                            } else {
                                element = { is_answer: false, text: currCell.answer.text, url: "", type: AnswerTypes.text };
                            }
                        }

                        curr_row.push(element);
                    }
                    rowList.push(curr_row);
                }

            if (exercise.options) {
                var oo_list = [];
                for (let i = 0; i < exercise.options.length; i++) {
                    if (exercise.options[i].type == "image" && exercise.solution.indexOf(i) == -1)
                        oo_list.push({ image: exercise.options[i].image, text: exercise.options[i].text, type: AnswerTypes.image });
                    else if (exercise.options[i].type == "text" && exercise.solution.indexOf(i) == -1)
                        oo_list.push({ image: "", text: exercise.options[i].text, type: AnswerTypes.text });
                    else if (exercise.options[i].type == "sound" && exercise.solution.indexOf(i) == -1)
                        oo_list.push({ image: "", text: exercise.options[i].text, url: exercise.options[i].url ? exercise.options[i].url : "", type: AnswerTypes.sound });
                }
            }

            var answerElement: FillTableAnswerElement = {
                headers: exercise.headers,
                rows: rowList,
                first_column_header: exercise.first_column_header,
                first_row_header: isFirstRowHeader,
                odd_one_out: oo_list
            }
            return answerElement;
        }
        else return undefined;
    }

    public convertToJson(exerciseDetails: FillTableAnswerElement, baseData: ExerciseBaseTypes.ExerciseBaseClass, prevJSON?: FillTableAnswerElement): FillTableData | undefined {
        var cur_exercise = ExerciseBaseTypes.convertToBaseJson(baseData);

        if (exerciseDetails) {
            var answerList = [];
            var rowList = [];

            if (exerciseDetails.rows) {
                for (var _i = 0; _i < exerciseDetails.rows.length; _i++) {
                    var curr_row = [];
                    var dataList = [];
                    for (var _j = 0; _j < exerciseDetails.rows[_i].length; _j++) {
                        var currCell: CheckableMultiTypeAnswerItem = (exerciseDetails.rows[_i][_j] as CheckableMultiTypeAnswerItem);
                        var type: any;
                        if (currCell.type == AnswerTypes.text)
                            type = "text";
                        else if (currCell.type == AnswerTypes.image)
                            type = "image";
                        else if (currCell.type == AnswerTypes.sound)
                            type = "sound";
                        let newElement = {
                            type: type,
                            image: type == "image" ? currCell.url : "",
                            text: currCell.text,
                            url: type == "sound" ? currCell.url : ""
                        };

                        if (currCell.is_answer) {
                            answerList.push(newElement);
                            dataList.push({ is_answer: true });
                        } else {
                            dataList.push({ is_answer: false, answer: newElement });
                        }
                    }
                    rowList.push({ data: dataList });
                }
            }

            var shuffleAnswers: ShuffleResult = shuffle(answerList, null);
            if (exerciseDetails.odd_one_out) {
                for (let index = 0; index < exerciseDetails.odd_one_out.length; index++) {
                    if (exerciseDetails.odd_one_out[index].type == AnswerTypes.image && shuffleAnswers.answers.map((x: any) => x.image).indexOf(exerciseDetails.odd_one_out[index].image) == -1)
                        shuffleAnswers.answers.push({
                            type: "image",
                            image: exerciseDetails.odd_one_out[index].image,
                            text: exerciseDetails.odd_one_out[index].text
                        });
                    else if (exerciseDetails.odd_one_out[index].type == AnswerTypes.text && shuffleAnswers.answers.map((x: any) => x.text).indexOf(exerciseDetails.odd_one_out[index].text) == -1)
                        shuffleAnswers.answers.push({
                            type: "text",
                            image: "",
                            text: exerciseDetails.odd_one_out[index].text
                        });
                    else if (exerciseDetails.odd_one_out[index].type == AnswerTypes.sound && shuffleAnswers.answers.map((x: any) => x.url).indexOf(exerciseDetails.odd_one_out[index].url) == -1)
                        shuffleAnswers.answers.push({
                            type: "sound",
                            image: "",
                            text: exerciseDetails.odd_one_out[index].text,
                            url: exerciseDetails.odd_one_out[index].url
                        });
                }
            }

            cur_exercise = {
                ...cur_exercise,
                //headers: exerciseDetails.headers,
                //rows: rowList,
                fix_elements: rowList,
                first_column_header: exerciseDetails.first_column_header,
                first_row_header: exerciseDetails.first_row_header,
                //answers: shuffleAnswers.answers,
                options: shuffleAnswers.answers,
                solution: shuffleAnswers.indexes
            };
            return cur_exercise;
        }
        return cur_exercise;
    }

    public convertOldNKPToJson(data: any): any | undefined {
        var row_list: any[][] = [];
        var data_list: any[][] = [];
        var answers = [];
        var odd_one_outs: any = [];
        var firstRowHeader = false;
        var firstColumnHeader = false;
        var imageAnswers = false;
        if (data.TableAnswerItems) {
            var itemsCopy = data.TableAnswerItems.slice(0);
            row_list[0] = [];
            var rowCounter = 0;
            data_list[0] = [];

            for (let index = 0; index < itemsCopy.length; index++) {
                if (itemsCopy[index].RowNumber == -1 && itemsCopy[index].ColumnNumber == -1) {                               //this one is an odd one out
                    let element = {
                        is_fix: false,
                        answer: ExerciseBaseTypes.convertOldNKPToAnswerElement(itemsCopy[index], data)
                    }
                    odd_one_outs.push({ data: element });
                    itemsCopy.splice(index, 1);
                    index--;
                }
            }

            while (itemsCopy.length > 0) {                                                                          //processing all items
                var foundItem = false;                                                                              //bool, whether we found an item in the ginve row
                for (let index = 0; index < itemsCopy.length; index++) {
                    if (itemsCopy[index].RowNumber == rowCounter) {                                                 //if the item is in the row that we are looking for, process
                        if (!itemsCopy[index].IsFix)                                                                //if not fix, means it's droppable, so add to answers
                            answers.push({ idx: ((itemsCopy[index].RowNumber + 1) * 10) + itemsCopy[index].ColumnNumber, item: ExerciseBaseTypes.convertOldNKPToAnswerElement(itemsCopy[index], data) });   //add to answers with an index for further sorting (rownumber has a weight 10)
                        else if (rowCounter == 0)                                                                   //if fix, and we are in the first row, then the first wor is header
                            firstRowHeader = true;
                        else if (itemsCopy[index].ColumnNumber == 0)                                                //if fix and we are in the first column, then first column is header
                            firstColumnHeader = true;

                        row_list[rowCounter][itemsCopy[index].ColumnNumber] = ExerciseBaseTypes.convertOldNKPToAnswerElement(itemsCopy[index], data);       //create old nkp elment, and insert it to the proer index
                        data_list[rowCounter][itemsCopy[index].ColumnNumber] = { is_answer: itemsCopy[index].IsCorrect, is_fix: itemsCopy[index].IsFix }    //paralell list, collectint meta about answer item
                        itemsCopy.splice(index, 1);                                                                                                         //after processing delete element from the list
                        foundItem = true;                                                                                                                   //set founditem bool true
                    }
                }
                if (!foundItem) {                                                                                   //have not found any element in the given row, then increment rowcounter
                    rowCounter++;
                    row_list[rowCounter] = [];
                    data_list[rowCounter] = [];
                }
            }

            answers.sort(function (obj1, obj2) { return obj1.idx - obj2.idx });                                     //sorting answer according to their index
            answers = answers.map(x => x.item);                                                                     //leave index property
        }


        var final_rowlist: any[] = [];                                                                              //create the final structure that the engine requires
        for (let index = 0; index < row_list.length; index++) {
            var temp = [];
            for (let y = 0; y < row_list[index].length; y++) {
                if (row_list[index][y]) {
                    temp.push({ is_answer: !data_list[index][y].is_fix, answer: row_list[index][y] });
                }
                else
                    temp.push({ is_answer: false, answer: { type: "", image: "", text: "" } })

                if (row_list[index][y] && !imageAnswers && row_list[index][y].type == "image")
                    imageAnswers = true;
            }
            final_rowlist.push({ data: temp });
        }

        var shuffleAnswers: ShuffleResult = shuffle(answers, null);
        shuffleAnswers.answers = shuffleAnswers.answers.concat(odd_one_outs.map((element: any) => element.data.answer));

        let newExercise = {
            title: data.Title,
            description: data.QuestionText,
            backgroundStyle: { is_custom_background: false, backgroundImage: "", backgroundColor: "" },
            illustration: (ExerciseBaseTypes.convertOldNKPToAnswerElement(data, data).image != "" && ExerciseBaseTypes.convertOldNKPToAnswerElement(data, data).image != undefined) ?
                ExerciseBaseTypes.convertOldNKPToAnswerElement(data, data).image : null,
            level: data.QuestionDifficulty == 1 ? 1 : data.QuestionDifficulty == 2 ? 3 : 5,
            demo_path: "",
            imagebasepath: "", //await EditorServer.getExerciseNewImageBasePath()
            comment: data.Description,
            fix_elements: final_rowlist,
            options: shuffleAnswers.answers,
            solution: shuffleAnswers.indexes,
            imageanswers: imageAnswers,
            first_row_header: firstRowHeader,
            first_column_header: firstColumnHeader
            //solution: sol_list,
        };
        return newExercise;
    }

    public validate(editorAnswer: FillTableAnswerElement, baseData: any, validationMap?: Map<string, string>, is_accessible?: boolean | null): ValidationResponse {
        let superAnswer: ValidationResponse = super.validate(editorAnswer, baseData, validationMap, is_accessible);
        if (!superAnswer.valid) return superAnswer;
        let errorMsg = "";
        let OFIErrors: string[] = [];
        let rowsValid = this.makeValidBeforeSetState(null, editorAnswer);
        if (!rowsValid.valid) return rowsValid;

        let elementsCount = 0;
        if (editorAnswer.rows)
            for (let i = 0; i < editorAnswer.rows.length; i++) {
                for (let j = 0; j < editorAnswer.rows[i].length; j++) {
                    elementsCount++;
                    let cell: CheckableMultiTypeAnswerItem | null = (editorAnswer.rows[i][j] as CheckableMultiTypeAnswerItem);
                    if ((cell.text == undefined || cell.text == "") && cell.is_answer) {
                        errorMsg = __("Üres cella nem lehet válasz elem!");
                        if (!OFIErrors.includes(errorMsg, 0)) OFIErrors.push(errorMsg);
                        if (!AExerciseTypeConverter.isOfiEditor) return { valid: false, message: errorMsg };
                    }
                    if (cell.text && cell.text.length > EKEFillTableAnswerConverter.MAX_ALT_TEXT_LENGTH) {
                        errorMsg = __("A cellák karakterszáma maximum {MAX_ALT_TEXT_LENGTH} lehet!", { MAX_ALT_TEXT_LENGTH: EKEFillTableAnswerConverter.MAX_ALT_TEXT_LENGTH });
                        if (!OFIErrors.includes(errorMsg, 0)) OFIErrors.push(errorMsg);
                        if (!AExerciseTypeConverter.isOfiEditor) return { valid: false, message: errorMsg };
                    }
                }
            }
        if (editorAnswer.odd_one_out && elementsCount < editorAnswer.odd_one_out.length) {
            errorMsg = __("Nem lehet több kakukktojás elem, mint normál elem.");
            if (!OFIErrors.includes(errorMsg, 0)) OFIErrors.push(errorMsg);
            return { valid: false, message: errorMsg };
        }
        if (AExerciseTypeConverter.isOfiEditor) return { valid: true, message: OFIErrors.join(' , ') }
        return { valid: true }
    }

    public makeValidBeforeSetState(oldState: FillTableAnswerElement | null, newState: FillTableAnswerElement): ValidationResponse {
        if (AExerciseTypeConverter.isOfiEditor) return { valid: true };
        if (newState.rows.length > EKEFillTableAnswerConverter.FILLTABLE_MAX_ROWS_NUM) {
            newState.rows.splice(EKEFillTableAnswerConverter.FILLTABLE_MAX_ROWS_NUM, newState.rows.length - EKEFillTableAnswerConverter.FILLTABLE_MAX_ROWS_NUM);
            return { valid: false, message: __("Oszlopok és sorok száma maximum {FILLTABLE_MAX_ROWS_NUM} lehet!", { FILLTABLE_MAX_ROWS_NUM: EKEFillTableAnswerConverter.FILLTABLE_MAX_ROWS_NUM }) }
        }
        if (newState.rows) {
            for (let i = 0; i < newState.rows.length; i++) {
                if (newState.rows[i].length > EKEFillTableAnswerConverter.FILLTABLE_MAX_COLUMN_NUM) {
                    newState.rows[i].splice(EKEFillTableAnswerConverter.FILLTABLE_MAX_COLUMN_NUM, newState.rows[i].length - EKEFillTableAnswerConverter.FILLTABLE_MAX_COLUMN_NUM);
                    return { valid: false, message: __("Oszlopok és sorok száma maximum {FILLTABLE_MAX_COLUMN_NUM} lehet!", { FILLTABLE_MAX_COLUMN_NUM: EKEFillTableAnswerConverter.FILLTABLE_MAX_COLUMN_NUM }) }
                }
            }
        }
        return { valid: true }
    }

    render() {
        let cur_ex: FillTableAnswerElement = this.state.exercise as FillTableAnswerElement;
        return (
            <Panel header={__("Részletek")} headingLevel={4}>
                <div className="row">
                    <div className="large-12 columns">
                        <label ><input type="checkbox" name="first_row_header" checked={cur_ex.first_row_header || false}
                            onBlur={this.onBlurEvent.bind(this)}
                            onChange={this.handleInputChange.bind(this)} />
                            {__("Első sor fejléc")}</label>
                    </div>
                </div>
                <div className="row">
                    <div className="large-12 columns">
                        <label ><input type="checkbox" name="first_column_header" checked={cur_ex.first_column_header || false}
                            onBlur={this.onBlurEvent.bind(this)}
                            onChange={this.handleInputChange.bind(this)} />
                            {__("Első oszlop fejléc")}</label>
                    </div>
                </div>
                <Panel  header={__("Sorok")} headingLevel={5} >
                    <div>
                        {cur_ex.rows ? cur_ex.rows.map((cur_row: any, i) => {
                            let accTitle = (<legend><label className="exe-editor-fieldlabel-1">{__("Sor")} {i + 1}</label>
                                <button className="button small alert exercise-series-small-btn" title={__("Törlés")} onClick={this.removeElement.bind(this, "rows", i)}><i className="fa fa-trash"></i></button>
                                <button className="button small exercise-series-small-btn" title={__("Fel")} onClick={this.moveUp.bind(this, "rows", i)} ><i className="fa fa-arrow-up"></i></button>
                                <button className="button small exercise-series-small-btn" title={__("Le")} onClick={this.moveDown.bind(this, "rows", i)}><i className="fa fa-arrow-down"></i></button>
                            </legend>);
                            return (
                                <Accordion key={"rows_" + i}>
                                    <AccordionItem defaultClosed={false} key={"rows_" + i} title={accTitle}>
                                        {cur_row ? cur_row.map((cur_column: any, j: any) => {
                                            return (
                                                <Panel key={"rows#" + i + j} >
                                                    <legend>
                                                        <label className="exe-editor-fieldlabel-1"> {"Cella " + (j + 1)}</label>
                                                        <button className="button small alert exercise-series-small-btn" title={__("Törlés")} onClick={this.removeElement.bind(this, "rows#" + String(i), j)}><i className="fa fa-trash"></i></button>
                                                        <button className="button small exercise-series-small-btn" title={__("Fel")} onClick={this.moveUp.bind(this, "rows#" + String(i), j)} ><i className="fa fa-arrow-up"></i></button>
                                                        <button className="button small exercise-series-small-btn" title={__("Le")} onClick={this.moveDown.bind(this, "rows#" + String(i), j)}><i className="fa fa-arrow-down"></i></button>
                                                    </legend>
                                                    <div className="row">
                                                        <div className="large-4 small-12 columns">
                                                            <label><input type="checkbox" name={"rows#" + i + "#" + j + ".is_answer"} data-parent-index={i} data-index={j} checked={cur_column.is_answer || false}
                                                                onBlur={this.onBlurEvent.bind(this)}
                                                                onChange={this.handleInputChange.bind(this)} />
                                                                {__("Kitöltendő")}
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="large-4 small-12 columns">
                                                            <label> {__("Válasz típusa")}
                                                                <select value={cur_column.type} name={"rows#" + i + "#" + j + ".type"} onChange={this.handleInputChange.bind(this)} onBlur={this.onBlurEvent.bind(this)}>
                                                                    <option value={AnswerTypes.text}>{__("Szöveg")}</option>
                                                                    <option value={AnswerTypes.image}>{__("Kép")}</option>
                                                                    <option value={AnswerTypes.sound}>{__("Hang")}</option>
                                                                </select></label>
                                                        </div>
                                                        <div className="large-8 small-12 columns">
                                                            <label>{cur_column.type == AnswerTypes.text ? __("Válasz") : __("Leírás")}
                                                                <input type="text" name={"rows#" + i + "#" + j + ".text"} data-parent-index={i} data-index={j} value={cur_column.text}
                                                                    onBlur={this.onBlurEvent.bind(this)}
                                                                    onChange={this.handleInputChange.bind(this)} />
                                                            </label>
                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                        {cur_column.type != AnswerTypes.text ? <div className="large-8 small-12 columns">
                                                            <label className="exe-image-select-label">{cur_column.type == AnswerTypes.image ? __("Kép") : __("Hang")}
                                                                {<ExerciseFileSelect
                                                                    imagebasepath={this.props.imagebasepath}
                                                                    value={cur_column.url || ""}
                                                                    onChange={this.handleImageChange.bind(this, "rows#" + i + "#" + j + ".url")}
                                                                    getFolderId={this.getFolderId.bind(this)}
                                                                    fileType={cur_column.type == AnswerTypes.image ? ExerciseFileTypes.Image : ExerciseFileTypes.Sound}
                                                                />}
                                                            </label>
                                                        </div> : ""}
                                                    </div>
                                                </Panel>
                                            )
                                        }) : ""
                                        }
                                        <div className="row">
                                            <button className="button small" name={"row#" + i + ".btn-add-cell"} onClick={this.onAddNewColumn.bind(this, i)}><i className="fa fa-plus"></i> {__("Új cella")}</button>
                                            <label className="exe-editor-label-description columns">{__("Az oszlopok száma maximum {FILLTABLE_MAX_COLUMN_NUM} lehet!", { FILLTABLE_MAX_COLUMN_NUM: EKEFillTableAnswerConverter.FILLTABLE_MAX_COLUMN_NUM })}</label>
                                        </div>
                                    </AccordionItem>
                                </Accordion>
                            )
                        }) : ""
                        }
                        <div className="row">
                            <button className="button small" onClick={this.onAddNewRow.bind(this)}><i className="fa fa-plus"></i>{__("Új sor")}</button>
                            <label className="exe-editor-label-description columns">{__("A sorok száma maximum {max} lehet!", { max: EKEFillTableAnswerConverter.FILLTABLE_MAX_ROWS_NUM })}</label>
                        </div>
                    </div>
                </Panel>
                <Panel  header={__("Kakukktojás elemek")} headingLevel={5}>
                    <div>
                        {
                            cur_ex.odd_one_out ?
                                cur_ex.odd_one_out.map((cur_odd_one_out: any, k: any) => {
                                    return (
                                        <Panel key={"Elem" + k}>
                                            <div>
                                                <label className="exe-editor-fieldlabel-1">{__("Elem")} {k + 1}</label>
                                                <button className="button small alert exercise-series-small-btn" title={__("Törlés")} onClick={this.removeElement.bind(this, "odd_one_out", k)}><i className="fa fa-trash"></i></button>
                                                <div className="large-8 small-12 columns">
                                                    <label> {__("Elem típusa")}
                                                        <select value={cur_odd_one_out.type} name={"odd_one_out#" + k + ".type"} onChange={this.handleInputChange.bind(this)} onBlur={this.onBlurEvent.bind(this)}>
                                                            <option value={AnswerTypes.text}>{__("Szöveg")}</option>
                                                            <option value={AnswerTypes.image}>{__("Kép")}</option>
                                                            <option value={AnswerTypes.sound}>{__("Hang")}</option>
                                                        </select></label>
                                                </div>
                                                <div className="row">
                                                    {cur_odd_one_out.type != AnswerTypes.text ? <div className="large-8 small-12 columns">
                                                        <label className="exe-image-select-label">{cur_odd_one_out.type == AnswerTypes.image ? __("Kép") : __("Hang")}
                                                            {<ExerciseFileSelect
                                                                imagebasepath={this.props.imagebasepath}
                                                                value={(cur_odd_one_out.type == AnswerTypes.image ? cur_odd_one_out.image : cur_odd_one_out.url) || ""}
                                                                onChange={this.handleImageChange.bind(this, "odd_one_out#" + k + (cur_odd_one_out.type == AnswerTypes.image ? ".image" : ".url"))}
                                                                getFolderId={this.getFolderId.bind(this)}
                                                                fileType={cur_odd_one_out.type == AnswerTypes.image ? ExerciseFileTypes.Image : ExerciseFileTypes.Sound}
                                                            />}
                                                        </label>
                                                    </div> : ""}
                                                    <div className="large-12 columns">
                                                        <label>{cur_odd_one_out.type == AnswerTypes.text ? __("Válasz") : __("Leírás")}
                                                            <input type="text" name={"odd_one_out#" + k + ".text"} data-index={k} value={cur_odd_one_out.text}
                                                                onBlur={this.onBlurEvent.bind(this)}
                                                                onChange={this.handleInputChange.bind(this)} />
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </Panel>
                                    );
                                })
                                : ""
                        }
                        <button className="button small" onClick={this.onAddNewOddOneOut.bind(this)}><i className="fa fa-plus"></i>{__("Új elem")}</button>
                    </div>
                </Panel>
            </Panel>
        )
    }
    onAddNewRow() {
        let temp_exercise = this.state.exercise as FillTableAnswerElement;
        if (!temp_exercise.rows)
            temp_exercise.rows = [];
        temp_exercise.rows.push(this.getNewRow());
        if (this.makeValidBeforeSetState(this.state.exercise, temp_exercise).valid)
            this.setState({ exercise: temp_exercise });

    }

    onAddNewColumn(index: number) {
        let temp_exercise = this.state.exercise as FillTableAnswerElement;
        if (!temp_exercise.rows[index])
            temp_exercise.rows[index] = [];
        temp_exercise.rows[index].push(this.getNewCheckableColumn());
        if (this.makeValidBeforeSetState(this.state.exercise, temp_exercise).valid)
            this.setState({ exercise: temp_exercise });
    }
    onAddNewOddOneOut() {
        let temp_exercise = this.state.exercise as FillTableAnswerElement;
        if (!temp_exercise.odd_one_out)
            temp_exercise.odd_one_out = [];
        temp_exercise.odd_one_out.push(this.getNewOddOneOut());
        if (this.makeValidBeforeSetState(this.state.exercise, temp_exercise).valid)
            this.setState({ exercise: temp_exercise });
    }
    getNewRow(): any {
        let newElement: [] = [];
        return newElement;
    }
    getNewOddOneOut(): any {
        let newColumn: any = {
            image: "",
            text: "",
            url: "",
            type: AnswerTypes.text
        };
        return newColumn;
    }
    getNewCheckableColumn(): any {
        let newColumn: any = {
            is_answer: false,
            text: "",
            image: "",
            url: "",
            type: AnswerTypes.text
        };
        return newColumn;
    }

    public getExerciseStatistics(exerciseList: any): StatisticsResponse[] {
        let statisticsResponses: StatisticsResponse[] = [];
        statisticsResponses.push({ name: __("limit - sorok száma: {n}", { n: AExerciseTypeConverter.FILLTABLE_MAX_ROWS_NUM }), count: undefined });
        statisticsResponses.push({ name: __("limit - oszlopok száma: {n}", { n: AExerciseTypeConverter.FILLTABLE_MAX_COLUMN_NUM }), count: undefined });

        let rowStat, elementStat, oddStat, totalElemStat: StatisticsResponse;
        rowStat = { name: __("Sorok száma"), count: new Map() }
        elementStat = { name: __("Cellák száma"), count: new Map() }
        totalElemStat = { name: __("Elemek száma összesen"), count: new Map() }
        oddStat = { name: __("A kakukktojás elemek száma"), count: new Map() }

        for (let i = 0; i < exerciseList.length; i++) {
            if (!exerciseList[i]) continue;
            const cur_ex = exerciseList[i];
            let row_count = rowStat.count.has(cur_ex.rows.length) ? rowStat.count.get(cur_ex.rows.length) : 0;
            rowStat.count.set(cur_ex.rows.length, row_count! + 1);
            let counter = 0;
            for (let j = 0; j < cur_ex.rows.length; j++) {
                if (!cur_ex.rows[j]) continue;
                const element = cur_ex.rows[j];
                counter += element.length;
                let element_count = elementStat.count.has(element.length) ? elementStat.count.get(element.length) : 0;
                elementStat.count.set(element.length, element_count! + 1);
            }
            let total_elem_count = totalElemStat.count!.has(counter) ? totalElemStat.count!.get(counter) : 0;
            totalElemStat.count!.set(counter, total_elem_count! + 1);

            let odd_count = oddStat.count.has(cur_ex.odd_one_out.length) ? oddStat.count.get(cur_ex.odd_one_out.length) : 0;
            oddStat.count.set(cur_ex.odd_one_out.length, odd_count! + 1);
        }
        statisticsResponses.push(rowStat, elementStat, oddStat, totalElemStat)
        return statisticsResponses;
    }
}