import obtainServer from '@framework/server/Server';
import { objectToQueryParams } from '@framework/util/UrlUtils';
import { __ } from '@src/translation';

const URL = 'userConfigStore';

export default class UserConfigStore {
    public static put = (key: string, value: Object) : Promise < any > => {
        if (value.hasOwnProperty("_userId")) {
            throw new Error(__("A '_userId' név foglalt, ne használd a UserConfigStore-ban!"))
        }
        if (value.hasOwnProperty("_modified")) {
            throw new Error(__("A '_modified' név foglalt, ne használd a UserConfigStore-ban!"))
        }
        return obtainServer().put(URL, { key, value });
    }

    public static get = <T>(key: string) : Promise <T|null> => {
        return obtainServer().get<T|null>(URL + '?' + objectToQueryParams({key:key}) );
    }

    public static remove = (key: string) : Promise <any> => {
        return obtainServer().del(URL + '?' + objectToQueryParams({key}) );
    }
}
