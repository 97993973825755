import { Server } from '../../server/Server';
import View , { registerViewClassProxy, ViewClassProxy } from '../View';
import { IListParams } from '@framework/crud/Crud';

export interface IViewPartnerRecord {
    /**
     * Azonosító
     *
     * Azonosító (pótkulcs)
     */
    id?: number;
    /**
     * Rekord létrehozás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel létrehozásakor.
     */
    creation_time?: string /*timestamp?*/;
    /**
     * Ki hozta létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozó felhasználóra hivatkozik.
     */
    creation_user_id?: number;
    /**
     * Melyik session-ből jött létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozáshoz használt munkamenetre hivatkozik.
     */
    creation_session_id?: number|null;
    /**
     * Utolsó módosítás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel módosításakor.
     */
    modification_time?: string /*timestamp?*/|null;
    /**
     * Ki módosította utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosító felhasználóra hivatkozik.
     */
    modification_user_id?: number|null;
    /**
     * Melyik session-ből módosították utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosításhoz használt munkamenetre hivatkozik.
     */
    modification_session_id?: number|null;
    /**
     * Aktív
     *
     * Logikai törléshez használt mező.
     */
    is_active?: boolean;
    /**
     * Név
     *
     * A rövidített elnevezése
     */
    name?: string;
    /**
     * Teljes név
     *
     * A partner teljes (hosszú) neve
     */
    fullname?: string;
    /**
     * Adószám
     */
    taxno?: string|null;
    /**
     * EU adószám
     */
    eutaxno?: string|null;
    /**
     * Cégjegyzékszám
     */
    compregno?: string|null;
    /**
     * Irányítószám
     */
    address_zip?: string|null;
    /**
     * Ország hívószám
     */
    phone_country_code?: string|null;
    /**
     * Leírás
     */
    description?: string|null;
    /**
     * Állapot
     */
    status_id?: number;
    /**
     * Partner típus
     */
    partner_type_id?: number;
    /**
     * Cím
     */
    address_address?: string|null;
    /**
     * Helyi szám
     */
    phone_number?: string|null;
    /**
     * Ország
     */
    address_country?: string|null;
    /**
     * Megye
     */
    address_county?: string|null;
    /**
     * Város
     */
    address_city?: string|null;
    /**
     * Létrehozta
     *
     * Felhasználó, aki létrehozta
     */
    creator?: string|null;
    /**
     * Módosító
     *
     * Felhasználó, aki utoljára módosította
     */
    modifier?: string|null;
    /**
     * Állapot
     *
     * Partner állapot név
     */
    status_name?: string|null;
    /**
     * Kijelzett név
     *
     * Egysoros szöveges reprezentáció
     */
    displayvalue?: string|null;
}

/**
 *  sys.view_partner - Partner/Szervezet/Cég
 *
 *  Idősoros táblázat, ami az összes tétel összes korábbi verzióját eltárolja.
 */

export default class ViewPartner extends View<IViewPartnerRecord> {
    public static VIEW_INFO_ID = 2018052902;

    public static getSchemaNameForClass(): string { return 'sys'; }
    public static getViewNameForClass(): string { return 'view_partner'; }
    public static getTableNameForClass(): string|null { return 'partner'; }

    public static load: (id: number, aServer ?: Server) => Promise<ViewPartner>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<IViewPartnerRecord[]>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class ViewPartnerClassProxy extends ViewClassProxy<IViewPartnerRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const viewPartnerClassProxy = new ViewPartnerClassProxy(ViewPartner);
registerViewClassProxy(viewPartnerClassProxy);

