import * as React from 'react';

/*
NKP időpont szerkesztő komponens.

Ezek alapján készült:

- https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/date
- https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/time

Az általa megjelenített érték egy string, ami pont olyan formátumban van,
mint amiben a szerver is visszaadja az időpontokat:

    yyyy-mm-dd HH:MM:SS

Felismeri a következő formátumokat is:

    yyyy-mm-dd HH:MM
    yy-mm-dd HH:MM:SS
    yy-mm-dd HH:MM

Ezeket üresnek veszi, és úgy is jeleníti meg mint üres/nem kitöltött érték:

    - null
    - undefined
    - üres sztring
    - bármilyen sztring ami trim-elve üres

*/

type DateTimePickerChangeEvent = (value: string | null, event: React.FormEvent<HTMLInputElement>) => void;

/** A modulon belül ezzel reprezentáljuk a dátum + idő párt. */
type DateParts = { date: null, time: null } | { date: string, time: string };

interface IDateTimePickerProps {
    id ?: string;
    value?: string | null; // "yyyy-mm-dd HH:MM:SS" - ahogyan a szervertől is jön. 
    onChange: DateTimePickerChangeEvent;
    label?: string;
    step?: number; // Ha ide 60-nál kisebb számot adsz meg, akkor a másodperceket is jelzi.
    disabled ?: boolean;
}

interface IDateTimePickerState {
}

export const padTwoDigits = (value: number): string => {
    if (value < 10) {
        return '0' + value;
    } else {
        return value.toString();
    }
}

/*
  A rendszeridő, DateParts formátumban.
*/
export const getNowParts = (showSeconds?: boolean): DateParts => {
    let now: Date = new Date();

    const year = now.getFullYear();
    const month = padTwoDigits(now.getMonth() + 1); //January is 0!
    const day = padTwoDigits(now.getDate());
    const date = `${year}-${month}-${day}`;

    let hours = padTwoDigits(now.getHours());
    let minutes = padTwoDigits(now.getMinutes());
    let seconds = padTwoDigits(now.getSeconds());
    const time = showSeconds
        ? `${hours}:${minutes}:${seconds}`
        : `${hours}:${minutes}`;

    return { date, time }
}

const _buildYear = (matches: any): string => {
    let year = parseInt(matches[1]);
    if (year < 30) {
        year += 2000;
    } else if (year < 100) {
        year += 1900;
    }
    return `${year}-${matches[2]}-${matches[3]}`;
}

/*
    Egy timestamp sztringet szétvág dátum és idő részre.

    CodePen: https://codepen.io/nagylzs/pen/wRaOoJ
*/
export function parseTimestampStr(value?: string|null, extractSeconds?: boolean): DateParts {
    if (value && value.trim()) {
        const matches = value.match(/^(\d{2,4})\-(\d{1,2})\-(\d{1,2})\s+(\d{2}):(\d{2})(:(\d{2})(.\d+){0,1}){0,1}/);
        if (matches) {
            const date = _buildYear(matches);
            let time = `${matches[4]}:${matches[5]}`
            if (extractSeconds) {
                if (matches[7]) {
                    time += ":" + matches[7];
                } else {
                    time += ":00";
                }
            }
            return {date, time};
        } else {
            const matches = value.match(/^(\d{2,4})\-(\d{1,2})\-(\d{1,2})/);
            if (matches) {
                const date = _buildYear(matches);
                const time = extractSeconds?"00:00:00":"00:00";
                return {date, time};
            }        
        }
    }
    return {date:null, time:null};
}

/*
   Megmondja, hogy két timestamp string egyenlő értéket reprezentál-e.
*/
export const strTimestampEquals = (a ?: string|null, b ?: string|null, compareSeconds ?: boolean) : boolean => {
    const aParts = parseTimestampStr(a, compareSeconds);
    const bParts = parseTimestampStr(b, compareSeconds);
    return (aParts.date===null && bParts.date===null) 
        || (aParts.date!==null && bParts.date!==null
            && aParts.date==bParts.date && aParts.time==bParts.time);
}


export default class DatePicker extends React.Component<IDateTimePickerProps, IDateTimePickerState> {
    constructor(props: IDateTimePickerProps) {
        super(props);
        this.state = {};
    }

    private showSeconds = () => {
        if (this.props.step === undefined) {
            return false
        } else {
            return this.props.step < 60;
        }
    }

    /*
      A sztring formájú reprezentáció nem egyértelmű.

      Ez a függvény csak akkor hívja meg az onChange-t, 
      ha valódi érték változásról van szó. Ha csak egy másik
      reprezentáció, akkor nem csinál semmit.
    */
    private _changeIfDifferent = (newValue: string|null, event: React.FormEvent<HTMLInputElement>) : boolean  => {
        const changed = !strTimestampEquals(newValue, this.props.value);
        if (changed) {
            this.props.onChange(newValue, event);
        }
        return changed;
    }

    private onDateChange = (event: React.FormEvent<HTMLInputElement>) => {
        const parts = parseTimestampStr(this.props.value, this.showSeconds())
        const dateStr = event.currentTarget.value;
        if (!dateStr) {
            // Most törölte ki a dátum részt
            this._changeIfDifferent(null, event);
        } else if (parts.time) {
            this._changeIfDifferent(dateStr + " " + parts.time, event);
        } else {
            const nowParts = getNowParts(this.showSeconds());
            this._changeIfDifferent(dateStr + " " + nowParts.time, event);
        }
    }

    private onTimeChange = (event: React.FormEvent<HTMLInputElement>) => {
        const parts = parseTimestampStr(this.props.value, this.showSeconds())
        const timeStr = event.currentTarget.value;
        if (!timeStr) {
            // Most törölte ki az idő részt
            this._changeIfDifferent(null, event);
        } else if (parts.date) {
            this._changeIfDifferent(parts.date + " " + timeStr, event);
        } else {
            const nowParts = getNowParts(this.showSeconds());
            this._changeIfDifferent(nowParts.date + " " + timeStr, event);
        }
    }

    render() {
        const parts = parseTimestampStr(this.props.value, this.showSeconds())
        const contents = <>
            {this.props.label ? <span className="input-group-label">{this.props.label}</span> : null}
            <div className="input-group-field medium-7 cell">
                <input type="date" value={parts.date || ''} 
                    onChange={this.onDateChange} 
                    disabled={this.props.disabled || false}
                />
            </div>
            <div className="input-group-field medium-5 cell">
                <input type="time"
                    value={parts.time || ''} 
                    step={this.props.step || 60} 
                    onChange={this.onTimeChange} 
                    disabled={this.props.disabled || false}
                />
            </div>
            {this.props.children}
        </>;

        return <div className="input-group" id={this.props.id}>{contents}</div>;
    }
}


