import { Server } from '../../server/Server';
import View , { registerViewClassProxy, ViewClassProxy } from '../View';
import { IListParams } from '@framework/crud/Crud';

export interface IViewOoFileHistoryRecord {
    /**
     * Azonosító
     *
     * Azonosító (pótkulcs)
     */
    id?: number;
    /**
     * OnlyOffice file
     */
    oo_file_id?: number|null;
    /**
     * SHA1 hash érték
     *
     * A file tartalmára számítva. Csak képeknél számítjuk!
     */
    sha1?: string;
    /**
     * Kérelem létrehozva
     */
    created?: string /*timestamp?*/;
    /**
     * Alkalmazás szintű felhasználó
     */
    sec_user_id?: number|null;
    /**
     * Létrehozta
     *
     * Felhasználó, aki létrehozta
     */
    creator?: string|null;
}

/**
 *  media.view_oo_file_history - Állomány verzió
 *
 *  Ez egy napló, ami a file bejegyzésekhez tartozó bináris file adatok korábbi verzióira való hivatkozásokat tárol.
 */

export default class ViewOoFileHistory extends View<IViewOoFileHistoryRecord> {
    public static VIEW_INFO_ID = 2018071301;

    public static getSchemaNameForClass(): string { return 'media'; }
    public static getViewNameForClass(): string { return 'view_oo_file_history'; }
    public static getTableNameForClass(): string|null { return 'oo_file_history'; }

    public static load: (id: number, aServer ?: Server) => Promise<ViewOoFileHistory>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<IViewOoFileHistoryRecord[]>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class ViewOoFileHistoryClassProxy extends ViewClassProxy<IViewOoFileHistoryRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const viewOoFileHistoryClassProxy = new ViewOoFileHistoryClassProxy(ViewOoFileHistory);
registerViewClassProxy(viewOoFileHistoryClassProxy);

