import * as React from 'react';
import * as ReactDOM from 'react-dom';
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
//import listPlugin from '@fullcalendar/list'
import visitListPlugin, { VisitListView } from './visitview/VisitListView';
import View from "@fullcalendar/core/View";
import {EventApi} from '@fullcalendar/core';
import {EventInput} from "@fullcalendar/core/structs/event";
import {EventSourceError} from "@fullcalendar/core/structs/event-source";
import {Duration} from "@fullcalendar/core/datelib/duration";
import {DateInput} from '@fullcalendar/core/datelib/env';
import Axios from 'axios';
import {GetScreenCordinates, DateToString, DateInputToString} from './EventCalendarAPI';
import {CoordinateType, DTSMode} from './EventCalendarAPI'
import {EventStyleType, EventTypeStyleType, CallbackFunctionType, EventIconType, TooltipParametersType, 
    nameType, MenuParametersType, EventConflictVerifyType, EventCalendarPropsType, EventConflictVerifyElemType,
    EventConflictVerifyEnum as ECVE, EventConflictOperationTypeEnum as ECO} from './EventCalendarAPI'
import {customDialog, DialogType} from '@src/component/Dialog';
import '@fullcalendar/core/main.css';
import '@fullcalendar/daygrid/main.css';
import '@fullcalendar/timegrid/main.css';
import './EventCalendar.css' 
import { __ } from '@src/translation';
import { app } from '@src/index';

export type EventCalendarProps = {
    Title?: string;
    View?: string;
    Weekends?: boolean;
    EventStyles?: EventStyleType;
    EventIcons?: EventIconType[];
    CallbackFunction?: CallbackFunctionType;
    TooltipParameters?: TooltipParametersType;
    EventConflictVerify?: EventConflictVerifyType;
    EventCalendarProps?: EventCalendarPropsType;
};

type EventCalendarState = {
    calendarTitle: string;
    defaultView: string;
    weekends: boolean;
    tooltipOpen: boolean;
    tooltipContent?: React.ReactElement;
    tooltipX: number,
    tooltipY: number,
    tooltipWidth: number,
    menuOpen: boolean,
    menuContent?: React.ReactElement,
    menuX: number,
    menuY: number,
    menuTitle: string;
    EventCalendarProps: EventCalendarPropsType;

};
const dialogRoot: HTMLElement = document.getElementById('dialog-root') as HTMLElement;
export class EventCalendar extends React.Component<EventCalendarProps, EventCalendarState> {
    calendarComponentRef = React.createRef<FullCalendar>();
    actualEvent?:EventApi;
    mouseEvent?:EventApi;
    actualDate?:Date;
    dayClickArg?:any;
    eventClickArg?:any;
    dayClick: boolean = false;
    constructor(props: EventCalendarProps) {
        super(props);
        this.state = {
            calendarTitle: (props.Title!==undefined ? props.Title : ''),
            defaultView: (props.View!==undefined ? props.View : 'dayGridMonth'),
            weekends: (props.Weekends!==undefined ? props.Weekends : true),
            tooltipOpen: false, tooltipContent: undefined, tooltipX: 0, tooltipY: 0,
            tooltipWidth: (props.TooltipParameters!==undefined && props.TooltipParameters.width!==undefined ? props.TooltipParameters.width : 400),
            menuOpen: false, menuContent: undefined, menuX: 0, menuY: 0, menuTitle: "",
            EventCalendarProps: {
                eventLimit: (props.EventCalendarProps!==undefined && props.EventCalendarProps.eventLimit!==undefined ? props.EventCalendarProps.eventLimit : false),
                eventLimitText: (props.EventCalendarProps!==undefined && props.EventCalendarProps.eventLimitText!==undefined ? props.EventCalendarProps.eventLimitText : __("további")),
                defaultDate: (props.EventCalendarProps!==undefined && props.EventCalendarProps.defaultDate!==undefined ? props.EventCalendarProps.defaultDate : DateToString(new Date(),DTSMode.dateFull,'-'))
            }
        };
        this.eventCalendarEscFunction = this.eventCalendarEscFunction.bind(this);
        this.eventCalendarScrollFunction = this.eventCalendarScrollFunction.bind(this);
    }
    
    private eventCalendarEscFunction = (event: KeyboardEvent) => {if (event.keyCode === 27) {this.setState({tooltipOpen:false, menuOpen:false});}}
    private eventCalendarScrollFunction = () => {this.setState({menuOpen: false, tooltipOpen: false})}

    componentDidMount(){
        document.addEventListener("keydown", this.eventCalendarEscFunction, false);
        document.addEventListener("scroll", this.eventCalendarScrollFunction, false);
    }
    componentWillUnmount(){
        document.removeEventListener("keydown", this.eventCalendarEscFunction, false);
        document.removeEventListener("scroll", this.eventCalendarScrollFunction, false);
    }
    
    static getDerivedStateFromProps(nextProps: EventCalendarProps, prevState: EventCalendarState) {
        return {
            calendarTitle: (nextProps.Title!==undefined ? nextProps.Title : ''),
            defaultView: (nextProps.View!==undefined ? nextProps.View : 'dayGridMonth'),
            weekends: (nextProps.Weekends!==undefined ? nextProps.Weekends : true),
            EventCalendarProps: {
                eventLimit: (nextProps.EventCalendarProps!==undefined && nextProps.EventCalendarProps.eventLimit!==undefined ? nextProps.EventCalendarProps.eventLimit : false),
                eventLimitText: (nextProps.EventCalendarProps!==undefined && nextProps.EventCalendarProps.eventLimitText!==undefined ? nextProps.EventCalendarProps.eventLimitText : __("további")),
                defaultDate: (nextProps.EventCalendarProps!==undefined && nextProps.EventCalendarProps.defaultDate!==undefined ? nextProps.EventCalendarProps.defaultDate : DateToString(new Date(),DTSMode.dateFull,'-'))
            }
        }
        
    }
    
    public reLoadCalendar = () => {
        if (this.calendarComponentRef.current!==null){
            const calendarApi = this.calendarComponentRef.current!.getApi();
            calendarApi.refetchEvents();
        }

    }

    private columnHeaderText = (date: DateInput) => {
        let result=DateInputToString(date,DTSMode.dayName,".");
        if (this.calendarComponentRef.current!==null){
            const calendarApi = this.calendarComponentRef.current!.getApi();
            if (calendarApi.view.type!=="dayGridMonth") result=DateInputToString(date,DTSMode.dayNameMonthDay,"-");
        } else {
            if (this.state.defaultView!=="dayGridMonth") result=DateInputToString(date,DTSMode.dayNameMonthDay,"-");
        }     
        return result;
    }

    private EventsLoad = async (arg:{start:Date; end:Date; timeZone:string;}, successCallback: (events: EventInput[]) => void, failureCallback: (error: EventSourceError) => void) => {
        //Komponens első használatakor kétszer hívódik meg, később már csak egyszer, de ezzel később foglalkozom
        const start=DateInputToString(arg.start,DTSMode.dateFull,"-")+' 00:00:00';
        const end=DateInputToString(arg.end,DTSMode.dateFull,"-")+' 00:00:00';
        try {

            if (this.props.CallbackFunction!==undefined && this.props.CallbackFunction.loadEvent!==undefined){
                const EventsData: EventInput[] = await this.props.CallbackFunction.loadEvent(start, end);
                successCallback(EventsData);
            }
        } catch(e) {
            app.showErrorFromJsonResult(e);
        }
    }

    private renderMenuItems = (menuItems: MenuParametersType[]) => {
        var renderedMenuItems: React.ReactElement[] = [];

        for(let index=0; index < menuItems.length; index++) {
            const item = menuItems[index];
            renderedMenuItems.push(
                <li key={index} className="eventCalendarMenuText" onClick={() => {this.setState({menuOpen: false}, item.onClick)} }>
                    <i className={item.icon} style={{marginRight:"22px"}} />{item.name}
                </li>);
        }
        return <ul className="eventCalendarMenuList">{renderedMenuItems}</ul>;
    }

    private DayClick = (arg:{date:Date; dateStr:string; allDay:boolean; resource:any; dayEl:HTMLElement; jsEvent:MouseEvent; view:View;}) => {
        this.actualDate=arg.date; this.actualEvent=undefined; 
        this.dayClickArg=arg; this.eventClickArg=undefined;
        this.dayClick=true;
        if (this.props.CallbackFunction && this.props.CallbackFunction.onDayClick) {
            
            const MenuElement = this.props.CallbackFunction.onDayClick(arg);
            if (MenuElement && MenuElement.length > 0) {
                this.setState({
                    menuContent:this.renderMenuItems(MenuElement), 
                    menuX:arg.jsEvent.clientX+10, 
                    menuY:arg.jsEvent.clientY+10, 
                    menuOpen:true, 
                    menuTitle:DateToString(arg.date,DTSMode.dateFull,"-"), 
                    tooltipOpen:false
                });
            } else {
                this.setState({
                    tooltipOpen:false, menuOpen: false
                });
            }
        }
    }

    private EventClick = (arg: {el: HTMLElement; event: EventApi; jsEvent: MouseEvent; view: View}) => {    
        this.actualEvent=arg.event; this.actualDate=undefined; 
        this.eventClickArg=arg; this.dayClickArg=undefined;
        this.dayClick=false;
        this.mouseEvent=arg.event;
        if (this.props.CallbackFunction && this.props.CallbackFunction.onEventClick) {
            const MenuElement = this.props.CallbackFunction.onEventClick(arg);
            if (MenuElement) this.setState({
                menuContent:this.renderMenuItems(MenuElement), 
                menuX:arg.jsEvent.clientX+10, 
                menuY:arg.jsEvent.clientY+10, 
                menuOpen:true, 
                menuTitle:arg.event.title, 
                tooltipOpen:false
            });    
        }
    }  

    private eventTypeNameResult = (type?: string) => {
        var result: string = "";
        if (this.props.TooltipParameters!==undefined && this.props.TooltipParameters.typeName!==undefined && type!==undefined){
            this.props.TooltipParameters.typeName.forEach(function (value: nameType) {
                if (value.id===type) result=value.name;
            });
        };
        return result;
    }

    private eventStatusNameResult = (status?: string) => {
        var result: string = "";
        if (this.props.TooltipParameters!==undefined && this.props.TooltipParameters.statusName!==undefined && status!==undefined){
            this.props.TooltipParameters.statusName.forEach(function (value: nameType) {
                if (value.id===status) result=value.name;
            });
        };
        return result;
    }

    async delay(ms: number) {
        await new Promise(resolve => setTimeout(()=>resolve(), ms));
    }

    private eventMouseEnter = (arg: {el: HTMLElement; event: EventApi; jsEvent: MouseEvent; view: View}) => {
        this.mouseEvent=arg.event;
        this.delay(1000).then(any=>{
            var Element = arg.el.parentElement;
            if (this.props.TooltipParameters===undefined || this.props.TooltipParameters.enabled===undefined || this.props.TooltipParameters.enabled===true){
                var ToolTip: React.ReactElement = <span/>;
                if (this.props.CallbackFunction===undefined || this.props.CallbackFunction.tooltipGeneral===undefined){    
                    ToolTip = (
                        <div>
                            <div>
                                <b>{arg.event.extendedProps.personName!==undefined ? arg.event.extendedProps.personName : arg.event.extendedProps.title}</b>
                                {'  '+DateToString(arg.event.start,DTSMode.dateFull,'.')+
                                    ' ('+DateToString(arg.event.start,DTSMode.hourMinute,'-')+
                                    '-'+DateToString(arg.event.end,DTSMode.hourMinute,'-')+')'}
                            </div>
                            <div>
                                {this.eventTypeNameResult(arg.event.extendedProps.type)} <b>{this.eventStatusNameResult(arg.event.extendedProps.status)}</b>
                            </div>
                            {arg.event.extendedProps.personComment!==undefined && arg.event.extendedProps.personComment.length>0 ? (
                                <div style={{marginTop:"15px"}}>{arg.event.extendedProps.personComment}</div>) : null}
                            {arg.event.extendedProps.placeName!==undefined && arg.event.extendedProps.placeName.length>0 ? (
                                <div style={{marginTop:"15px"}}>{arg.event.extendedProps.placeName}</div>) : null}
                            {arg.event.extendedProps.placeAddress!==undefined && arg.event.extendedProps.placeAddress.length>0 ? (
                                <div>{arg.event.extendedProps.placeAddress}</div>) : null}
                            {arg.event.extendedProps.placeComment!==undefined && arg.event.extendedProps.placeComment.length>0 ? (
                                <div style={{marginTop:"15px"}}>{arg.event.extendedProps.placeComment}</div>) : null}
                        </div>
                    );
                } else ToolTip = this.props.CallbackFunction.tooltipGeneral(arg);
                if (this.mouseEvent===undefined || this.mouseEvent!==arg.event) return;
                if (Element!==null) {
                    const Position: CoordinateType = GetScreenCordinates(arg.el);  
                    this.setState({tooltipContent:ToolTip, tooltipX:Position.X-Math.floor((this.state.tooltipWidth-Element.clientWidth)/2), tooltipY:Position.Y-10, tooltipOpen:true, menuOpen:false});
                } else this.setState({tooltipContent:ToolTip, tooltipX:arg.jsEvent.clientX-10, tooltipY:arg.jsEvent.clientY-22, tooltipOpen:true, menuOpen:false});        
            }
       });
    }
    private eventMouseLeave = (arg: {el: HTMLElement; event: EventApi; jsEvent: MouseEvent; view: View}) => {
        this.mouseEvent=arg.event;
        this.setState({tooltipContent:undefined, tooltipOpen:false});
    }

    private async EventConflictVerify(event:EventApi, revert:() => void) {
        if (this.props.CallbackFunction!==undefined && this.props.CallbackFunction.eventConflictVerify!==undefined) {
            this.props.CallbackFunction.eventConflictVerify(event, revert); return;
        } else {
            if (this.props.EventConflictVerify!==undefined){
                const calendarApi = this.calendarComponentRef.current!.getApi();
                const Events: EventApi[] = calendarApi.getEvents();
                var personIdName: string = (this.props.EventConflictVerify.personIdName!==undefined ? this.props.EventConflictVerify.personIdName : "personId");
                var placeIdName: string = (this.props.EventConflictVerify.placeIdName!==undefined ? this.props.EventConflictVerify.placeIdName : "placeId");
                var start1: string = "1900-01-01", timestart1: string = "1900-01-01 00:00:00";
                var personEmptyValue = this.props.EventConflictVerify.personIdEmptyValue;
                var placeEmptyValue = this.props.EventConflictVerify.placeIdEmptyValue;
                if (event.start!==undefined) {
                    start1=DateToString(event.start, DTSMode.dateFull,'-');
                    timestart1=DateToString(event.start, DTSMode.full,'-');
                } 
                var end1: string = "2999-12-31", timeend1: string = "2999-12-31 23:59:59";
                if (event.end!==undefined) {
                    end1=DateToString(event.end, DTSMode.dateFull,'-');
                    timeend1=DateToString(event.end, DTSMode.full,'-');
                } 
                var start2: string = "", timestart2: string = "", end2: string = "", timeend2: string = "";
                var conflict: boolean = false;
                for (const {eventitem} of Events.map((eventitem:EventApi) => ({eventitem}))) {
                    if (eventitem.id!==event.id) {
                        if (this.props.EventConflictVerify.conflictVerifyFunction!==undefined) {
                            if (!this.props.EventConflictVerify.conflictVerifyFunction(event, eventitem)) return false;                            
                        } else {
                            if (eventitem.start===undefined) {
                                start2="1900-01-01"; timestart2="1900-01-01 00:00:00";
                            } else {
                                start2 = DateToString(eventitem.start, DTSMode.dateFull,'-');
                                timestart2 = DateToString(eventitem.start, DTSMode.full,'-');
                            } 
                            if (eventitem.end===undefined) {
                                end2="2999-12-31"; timeend2="2999-12-31 23:59:59"
                            } else {
                                end2 = DateToString(eventitem.end, DTSMode.dateFull,'-');
                                timeend2 = DateToString(eventitem.end, DTSMode.full,'-');        
                            } 
                            for (const {item} of this.props.EventConflictVerify.Elem.map((item:EventConflictVerifyElemType) => ({item}))) {
                                conflict=false;
                                switch(item.name) { 
                                    case ECVE.personDay: { 
                                        if (event.extendedProps[personIdName]!==undefined 
                                                && eventitem.extendedProps[personIdName]!==undefined 
                                                && event.extendedProps[personIdName].length>0 
                                                && eventitem.extendedProps[personIdName].length>0
                                                && (personEmptyValue===undefined || eventitem.extendedProps[personIdName]!==personEmptyValue || event.extendedProps[personIdName]!==personEmptyValue)
                                                && event.extendedProps[personIdName]===eventitem.extendedProps[personIdName]) {
                                            conflict=(start1<=end2 && end1>=start2);
                                        }
                                        break; 
                                    } 
                                    case ECVE.personTimePeriod: { 
                                        if (event.extendedProps[personIdName]!==undefined 
                                                && eventitem.extendedProps[personIdName]!==undefined 
                                                && event.extendedProps[personIdName].length>0 
                                                && eventitem.extendedProps[personIdName].length>0
                                                && (personEmptyValue===undefined || eventitem.extendedProps[personIdName]!==personEmptyValue || event.extendedProps[personIdName]!==personEmptyValue)
                                                && event.extendedProps[personIdName]===eventitem.extendedProps[personIdName]) {
                                            conflict=(timestart1<=timeend2 && timeend1>=timestart2);
                                        } 
                                       break; 
                                    } 
                                    case ECVE.personPlaceDay: { 
                                         
                                        break; 
                                    } 
                                    case ECVE.placeDay: { 
                                        if (event.extendedProps[placeIdName]!==undefined 
                                                && eventitem.extendedProps[placeIdName]!==undefined 
                                                && event.extendedProps[placeIdName].length>0 
                                                && eventitem.extendedProps[placeIdName].length>0
                                                && (placeEmptyValue===undefined || eventitem.extendedProps[placeIdName]!==placeEmptyValue || event.extendedProps[placeIdName]!==placeEmptyValue)
                                                && event.extendedProps[placeIdName]===eventitem.extendedProps[placeIdName]) {
                                            conflict=(start1<=end2 && end1>=start2);  
                                        }                                    
                                        break; 
                                    } 
                                    case ECVE.placeTimePeriod: { 
                                        if (event.extendedProps[placeIdName]!==undefined 
                                                && eventitem.extendedProps[placeIdName]!==undefined 
                                                && event.extendedProps[placeIdName].length>0 
                                                && eventitem.extendedProps[placeIdName].length>0
                                                && (placeEmptyValue===undefined || eventitem.extendedProps[placeIdName]!==placeEmptyValue || event.extendedProps[placeIdName]!==placeEmptyValue)
                                                && event.extendedProps[placeIdName]===eventitem.extendedProps[placeIdName]) {
                                            conflict=(timestart1<=timeend2 && timeend1>=timestart2);     
                                        }                                
                                        break; 
                                    } 
                                    case ECVE.noMoving: {
                                        if (item.propertyName!==undefined && item.propertyValue!==undefined && event.extendedProps[item.propertyName]===item.propertyValue) conflict=true;
                                        break;
                                    }
                                } 
                                if (conflict===true) {
                                    switch(item.type) {
                                        case ECO.conflictAlert: {
                                            if (item.message.length>0) customDialog(__("ÜZENET"), item.message, DialogType.Info, false, true);
                                            break;
                                        }
                                        case ECO.conflictConfirm: {
                                            if (await customDialog(__("MEGERŐSÍTÉS"), item.message, DialogType.Confirm, false, true)===0) {
                                                revert(); return;
                                            }
                                            break;
                                        }
                                        case ECO.conflictError: {
                                            revert();
                                            if (item.message.length>0) customDialog(__("FIGYELEM"), item.message, DialogType.Error, false, true, "300px");
                                            return;
                                        }
                                    }
                                }
                            }
                        } 
                    }
                }
            }
            //Ha idáig eljutott, akkor lehet a változtatást az adatbázisban is véglegeíteni, ha van megadva URL.
            if (this.props.CallbackFunction!==undefined && this.props.CallbackFunction.eventDropResizeWrity!==undefined){ 
                if (!this.props.CallbackFunction.eventDropResizeWrity(event.id,event.start,event.end)) revert();
            }
        }
        return;
    }

    private eventDragStart = (arg: {event: EventApi; el: HTMLElement; jsEvent: MouseEvent; view: View;}) => {this.mouseEvent=undefined;}
    private eventDragStop = (arg: {event: EventApi; el: HTMLElement; jsEvent: MouseEvent; view: View;}) => {this.mouseEvent=arg.event;}
    private eventDrop = (arg: {el:HTMLElement; event:EventApi; oldEvent:EventApi; delta:Duration; revert:() => void; jsEvent:Event; view:View;}) => {this.EventConflictVerify(arg.event, arg.revert);}

    private eventResizeStart = (arg: {el: HTMLElement; event: EventApi; jsEvent: MouseEvent; view: View;}) => {this.mouseEvent=undefined;}
    private eventResizeStop = (arg: {el: HTMLElement; event: EventApi; jsEvent: MouseEvent; view: View;}) => {this.mouseEvent=arg.event;}
    private eventResize = (arg: {el:HTMLElement; startDelta:Duration; endDelta:Duration; prevEvent:EventApi; event:EventApi; revert:() => void; jsEvent:Event; view:View;}) => {this.EventConflictVerify(arg.event, arg.revert);}

    private EventStyleResult = (event: EventApi) => {
        var eventStyle = {textAlign:"left", paddingLeft:"5px", paddingTop:"5px", margin:"0px !important"} as React.CSSProperties;        
        if (this.props.EventStyles!==undefined && this.props.EventStyles.eventTypeStyle!==undefined){
            if (this.props.EventStyles.eventStyle!==undefined) {
                if (this.props.EventStyles.eventStyle.fontSize!==undefined) eventStyle.fontSize=this.props.EventStyles.eventStyle.fontSize;
                if (this.props.EventStyles.eventStyle.eventHeight!==undefined) eventStyle.height=this.props.EventStyles.eventStyle.eventHeight;
            }
            this.props.EventStyles.eventTypeStyle.forEach(function (value: EventTypeStyleType) {
                var typeName: string = "type";
                if (value.typeName!==undefined) typeName=value.typeName;
                if (value.typeValue===event.extendedProps[typeName]) {
                    eventStyle.backgroundColor=value.backgroundColor; 
                    eventStyle.color=value.textColor;
                    if (value.textBold===true) eventStyle.fontWeight="bold";       
                }
            });
        }
        return eventStyle;
    }

    private iconNameResult = (status: number) => {
        if (!status) return "";
        var result: string = "";
        if (this.props.EventIcons!==undefined){
            for (const {item} of this.props.EventIcons.map((item: EventIconType) => ({item}))) {
                if (item.statusValue==status) result='fa fa-'+item.iconName;
            }    
        }
        return result
    }
    private eventBackgroundColorResult = (event: EventApi) => {
        var result = "#fff";
        if (this.props.EventStyles!==undefined && this.props.EventStyles.eventTypeStyle!==undefined){
            this.props.EventStyles.eventTypeStyle.forEach(function (value: EventTypeStyleType) {
                var typeName: string = "type";
                if (value.typeName!==undefined) typeName=value.typeName;
                if (value.typeValue===event.extendedProps[typeName]) result=value.backgroundColor;
            });
        }
        return result;
    }

    private eventRender = (arg:{isMirror:boolean; isStart:boolean; isEnd:boolean; event:EventApi; el:HTMLElement; view:View}) => {
        const elem = arg.el;
        var content = null;
        //if (this.props.CallbackFunction && this.props.CallbackFunction.eventRender) {
        //    content=this.props.CallbackFunction.eventRender(arg);
        //    ReactDOM.render(content, elem);
        //}
        var visitContent : React.ReactElement<any>;
        //if (arg.view.viewSpec.class.name!=="VisitListView"){
        if (arg.view.viewSpec.class.name!==VisitListView.name){
            if (this.props.CallbackFunction && this.props.CallbackFunction.eventRender) {
                content=this.props.CallbackFunction.eventRender(arg);
                ReactDOM.render(content, elem);
            } 
        } else {
            const StatusIconName=this.iconNameResult(arg.event.extendedProps.status); 
            const BackgroundColor = this.eventBackgroundColorResult(arg.event);
            let iconName: string = '/img/IKON_SET/EVENTCALENDAR/dfht_image.svg';
            let isLeft: boolean = true, leftWidth: string = "100px", centerWidth: string = "350px", rightWidth: string = "200px"; 
            let left1Data: string = '';
            if (arg.event.extendedProps.startDate!==undefined && arg.event.extendedProps.startDate!==null){
                let startDate: Date = new Date(arg.event.extendedProps.startDate);
                left1Data = DateToString(startDate,DTSMode.hourMinute,'')
            }
            let left2Data: string = arg.event.extendedProps.groupTypeOther;
            let center1Data: string = arg.event.extendedProps.placeName;
            let center2Data: string = arg.event.extendedProps.personName;
            let left1Css: React.CSSProperties = {}, left2Css: React.CSSProperties = {};
            let center1Css: React.CSSProperties = {}, center2Css: React.CSSProperties = {};
            let right1Css: React.CSSProperties = {}, right2Css: React.CSSProperties = {}; 
            if (arg.event.extendedProps.type==='0') {
                //DFHT eseményről van szó
                iconName = '/img/IKON_SET/EVENTCALENDAR/dfht_image.svg';
                isLeft = false, centerWidth = "450px";  
            } else if (arg.event.extendedProps.type in ["1","2","3","4","5",""]) {
                //Alprogrami eseményről van szó
                isLeft = true, centerWidth = "350px";
                if (arg.event.extendedProps.type==='1') iconName='/img/IKON_SET/EVENTCALENDAR/da_icon.svg';
                else if (arg.event.extendedProps.type==='2') iconName='/img/IKON_SET/EVENTCALENDAR/ea_icon.svg';
                else if (arg.event.extendedProps.type==='3') iconName='/img/IKON_SET/EVENTCALENDAR/la_icon.svg';
                else if (arg.event.extendedProps.type==='4') iconName='/img/IKON_SET/EVENTCALENDAR/ma_icon.svg';
                else if (arg.event.extendedProps.type==='5') iconName='/img/IKON_SET/EVENTCALENDAR/ta_icon.svg';
                left1Css.fontSize="20px"; left1Css.fontWeight="bold";
            } else {
                //Más, később fel kerülő események lista nézetét itt lehet beállítani
                iconName = 'NONE';
            }
            visitContent = (
                <React.Fragment>
                    <div className="fc-visit-list-item-box">
                        <div className="fc-visit-list-item-image">
                            <img src={iconName} alt="" height="100%" width="100%" />
                        </div>
                        <div className="fc-visit-list-item-content" style={{backgroundColor: BackgroundColor}}>
                            {isLeft ?
                                <div className="fc-visit-list-item-content-left" style={{width: leftWidth}}>
                                    <div className="fc-visit-list-item-content-left-1" style={left1Css}>{left1Data}</div>
                                    <div className="fc-visit-list-item-content-left-2" style={left2Css}>{left2Data}</div>
                                </div>
                            : null}
                            <div className="fc-visit-list-item-content-center" style={{width: centerWidth}}>
                                <div className="fc-visit-list-item-content-center-1" style={center1Css}>{center1Data}</div>
                                <div className="fc-visit-list-item-content-center-2" style={center2Css}>{center2Data}</div>
                            </div>
                            <div className="fc-visit-list-item-content-right" style={{width: rightWidth}}>
                                <div className="fc-visit-list-item-content-right-1" style={right1Css}>{StatusIconName.length===0 ? null : <i className={StatusIconName}></i>}</div>
                                <div className="fc-visit-list-item-content-right-2" style={right2Css}>{arg.event.extendedProps.stationName}</div>
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            )    
            ReactDOM.render(visitContent, elem.getElementsByClassName("fc-visit-list-item-event").item(0));
        }
        this.setState({tooltipOpen:false, menuOpen: false});
        return elem;
    }

    render() {
        const eventMenu = ReactDOM.createPortal((
            <div>
                <div className="eventCalendarMenuContainer" style={{position: "absolute", left:this.state.menuX, top:this.state.menuY}}>
                    <div className="eventCalendarMenuTitle" onClick={()=>{this.setState({menuOpen:false})}}>{this.state.menuTitle}</div>
                    {this.state.menuContent}
                </div>
            </div>
        ), dialogRoot)
        const eventHint = ReactDOM.createPortal((
            <div>
                <div className="eventCalendarTooltipContainer" style={{position: "absolute", left:this.state.tooltipX, top:this.state.tooltipY}}>
                    <div className="eventCalendarTooltipText" style={{width:this.state.tooltipWidth}}>
                        {this.state.tooltipContent}
                    </div>
                </div>
            </div>
        ), dialogRoot)
        return (
            <div className="eventCalendar">
                {/*<div className="eventCalendarTitle">
                    {this.state.calendarTitle}
                </div>*/}
                <div className="eventCalendarContent">
                    <FullCalendar
                        defaultView={this.state.defaultView} 
                        eventLimit={this.state.EventCalendarProps.eventLimit}
                        eventLimitText={this.state.EventCalendarProps.eventLimitText}
                        locale={'hu'}                         
                        timeZone={'local'}
                        height={"auto"}

                        //buttonText={{today:__('MAI NAP'), month:__('hónap'), week:__('hét'), day:__('nap'), list:__('lista'), next:__('következő'), prev:__('előző'), prevYear: __("előző év"), nextYear: __("következő év")}}
                        //header={{left: 'prevYear prev today next nextYear', center: 'title', right: 'dayGridMonth timeGridWeek timeGridDay listWeek'}}
                        buttonText={{today:__('MAI NAP'), month:__('hónap'), week:__('hét'), day:__('nap'), visitlistMonth:__('lista (hónap)'), visitlistWeek:__('lista (hét)')}}
                        header={{left: 'prevYear prev today next nextYear', center: 'title', right: 'dayGridMonth timeGridWeek timeGridDay visitlistMonth visitlistWeek'}}

                        editable={true}

                        //plugins={[dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin]}
                        plugins={[dayGridPlugin, timeGridPlugin, visitListPlugin, interactionPlugin]}

                        firstDay={1}
                        titleFormat={{month:'long',  year:'numeric', day:'2-digit'}}
                        allDayText={__('egész nap')}
                        slotLabelFormat={{hour:'2-digit', minute:'2-digit'}}
                        columnHeaderFormat={{weekday:'short', month:'2-digit', day:'2-digit'}}
                        columnHeaderText={this.columnHeaderText}
                        noEventsMessage={__('Az adott időszakban nincs hospitálás megadva')}
                        displayEventTime={false}
                        selectable={true}
                        defaultDate={this.state.EventCalendarProps.defaultDate}
                        navLinks={true} 
                        businessHours={[{dow: [1,2,3,4,5], start: '07:00', end: '20:00'}]}
                        ref={this.calendarComponentRef}
                        weekends={this.state.weekends}
                        eventRender={this.eventRender}
                        events={this.EventsLoad}
                        dateClick={this.DayClick}
                        eventClick={this.EventClick}
                        eventMouseEnter={this.eventMouseEnter}
                        eventMouseLeave={this.eventMouseLeave }
                        eventStartEditable={this.props.CallbackFunction && this.props.CallbackFunction.eventDropResizeWrity ? true : false}
                        eventDrop={this.eventDrop}
                        eventDragStart={this.eventDragStart}
                        eventDragStop={this.eventDragStop}
                        eventResize={this.eventResize}
                        eventResizeStart={this.eventResizeStart}
                        eventResizeStop={this.eventResizeStop}                        
                    />
                </div>
                {this.state.tooltipOpen ? eventHint : null}
                {this.state.menuOpen ? eventMenu : null}
            </div>
            

        )
    }

}