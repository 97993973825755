import { Server } from '../../server/Server';
import Crud , { registerCrudClassProxy, IAsTextResult, IListParams, CrudClassProxy, ISearchTextParams, ISearchTextResult } from '../Crud';

export interface IOriginRecord {
    /**
     * Globálisan egyedi azonosító.
     * 
     * Ha új rekordot akarsz létrehozni put() metódussal, akkor hagyd üresen.
     * upsert() hívásnál figyelmen kívül van hagyva az értéke.
     *
     */
    id ?: number;
    /**
     * Rekord létrehozás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel létrehozásakor.
     */
    creation_time?: string /*timestamp?*/;
    /**
     * Ki hozta létre
     *
     * NOT NULL -> sys.sec_user ON DELETE noaction
     *
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozó felhasználóra hivatkozik.
     */
    creation_user_id?: number;
    /**
     * Melyik session-ből jött létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozáshoz használt munkamenetre hivatkozik.
     */
    creation_session_id?: number|null;
    /**
     * Utolsó módosítás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel módosításakor.
     */
    modification_time?: string /*timestamp?*/|null;
    /**
     * Ki módosította utoljára
     *
     * -> sys.sec_user ON DELETE noaction
     *
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosító felhasználóra hivatkozik.
     */
    modification_user_id?: number|null;
    /**
     * Melyik session-ből módosították utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosításhoz használt munkamenetre hivatkozik.
     */
    modification_session_id?: number|null;
    /**
     * Aktív
     *
     * Logikai törléshez használt mező.
     */
    is_active?: boolean;
    /**
     * Név
     */
    title?: string;
    /**
     * Leírás
     */
    description?: string|null;
}

/**
 *  media.origin - Forrás (származási hely)
 *
 *  Tartalom származási hely
 */

export default class OriginCrud extends Crud<IOriginRecord> {
    public static TABLE_INFO_ID = 680990559;
    public static getSchemaNameForClass() { return 'media'; }
    public static getTableNameForClass() { return 'origin'; }

    public static load: (id: number, aServer ?: Server) => Promise<OriginCrud>;
    public static asText: (id: number, includeRecord : boolean, aServer ?: Server) => Promise<IAsTextResult<IOriginRecord>>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<IOriginRecord[]>;
    public static searchText: (params: ISearchTextParams, aServer ?: Server) => Promise<ISearchTextResult<IOriginRecord>[]>;
    public static deleteById: (id: number, aServer ?: Server) => Promise<IOriginRecord>;
    public static unDeleteById: (id: number, aServer ?: Server) => Promise<IOriginRecord>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class OriginCrudClassProxy extends CrudClassProxy<IOriginRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const originCrudClassProxy = new OriginCrudClassProxy(OriginCrud);
registerCrudClassProxy(originCrudClassProxy);
