import SectionTemplate from "@src/template/SectionTemplate";

import BlockTemplateCrud, { IBlockTemplateRecord } from '@src/framework/crud/doc/BlockTemplateCrud';
import SectionCrud from "@src/framework/crud/doc/SectionCrud";
import { replaceAll } from "@src/framework/util/StringUtils";
import BlockTemplateTypeCrud from "@src/framework/crud/doc/BlockTemplateTypeCrud";

const SECTION_TEMPLATE_EXERCISE_ID = 1;
const SECTION_TEMPLATE_EXERCISE_SERIES_ID = 2;

export type BlockTemplate = {
    id: number,
    name: string,
    template: SectionTemplate
}

export async function getSectionTemplates(styleId: number): Promise<BlockTemplate[]> {
    const templateTypes = await BlockTemplateTypeCrud.list({});
    const sectionTemplates: any[] = await BlockTemplateCrud.list({filter: {is_active: true, style_id: styleId}});

    if (sectionTemplates.length > 0) {
        if (!sectionTemplates.find(x => x.name == "Okosfeladat")) {
            const exerciseTemplate = (await BlockTemplateCrud.load(SECTION_TEMPLATE_EXERCISE_ID)).record;
            sectionTemplates.push(exerciseTemplate);
        }
        if (!sectionTemplates.find(x => x.name == "Okosfeladatsor")) {
            const exerciseSeriesTemplate = (await BlockTemplateCrud.load(SECTION_TEMPLATE_EXERCISE_SERIES_ID)).record;
            sectionTemplates.push(exerciseSeriesTemplate);
        }
    }

    for(var sectionTemplate of sectionTemplates) {
        sectionTemplate.template = new SectionTemplate(getSectionTemplateBody(sectionTemplate, templateTypes) || "");
    }

    return Promise.resolve(sectionTemplates);
}

export function getSectionTemplateBody(blockTemplate: IBlockTemplateRecord, blockTemplateTypes: IBlockTemplateRecord[]) {
    var body = blockTemplate.body;

    if (blockTemplate.block_template_type_id) {
        const blockTemplateType = blockTemplateTypes.find(t => t.id == blockTemplate.block_template_type_id);
        
        if (blockTemplateType && blockTemplateType.body) {
            body = blockTemplateType.body;

            if (blockTemplate.variables) {
                for(const name in blockTemplate.variables) {
                    body = replaceAll(body, "[[" + name + "]]", blockTemplate.variables[name]);
                }
            }

            body = replaceAll(body, "[[name]]", blockTemplate.name || "");
        }
    }
    return body;
}

export type Section = {
    id: number;
    name: string;
    block_template_id?: number;
    values: string | object;
    no?: number;
    exercise_id?: number | null;
    exercise_series_id?: number | null;
    exercise_is_hidden_by_default: boolean;
    is_teachers_guide: boolean;
}



export async function getSections(lessonId: number): Promise<Section[]> {
    const ret = await SectionCrud.list({filter: {lesson_id: lessonId, is_active: true}, order_by: "no"});
    return ret as Section[];
}
