import * as React from "react";
import { TicketMessage } from "@src/component/ticket/htr_ticket_message";
import { match } from "react-router";

type TicketsProps = {
    match: match<{ticketCategoryId: string}>;
}
type TicketsState = {
    loading: boolean;
}

export default class Tickets extends React.Component<TicketsProps, TicketsState> {

    constructor(props: any) {
        super(props);
        this.state = { loading: true };
    }

    render() {
        return <TicketMessage category_id={Number(this.props.match.params.ticketCategoryId)}/>
    }
}