
import RecordEditor, { IFieldEditorProp } from '@framework/forms/recordeditor';
import { ILessonplanRecord, LessonplanCrudClassProxy, lessonplanCrudClassProxy } from '@src/framework/crud/doc/LessonplanCrud';


export default class InstituteLessonPlanEditor extends RecordEditor<ILessonplanRecord> {
    public getCrudClassProxy(): LessonplanCrudClassProxy { return lessonplanCrudClassProxy; }

    protected getFieldEditor(fProps: IFieldEditorProp): JSX.Element | null {
        if (fProps.fieldName == 'creation_time')
            return null;
        if (fProps.fieldName == 'creation_user_id')
            return null;
        if (fProps.fieldName == 'creation_session_id')
            return null;
        if (fProps.fieldName == 'modification_time')
            return null;
        if (fProps.fieldName == 'modification_user_id')
            return null;
        if (fProps.fieldName == 'modification_session_id')
            return null;

        if (fProps.fieldName == 'is_active')
            return null;
        if (fProps.fieldName == 'search_popularity_last_hit_date')
            return null;
        if (fProps.fieldName == 'search_popularity_last_score')
            return null;

        if (fProps.fieldName == 'institute_type_id') {
            return null;
        }
        if (fProps.fieldName == 'literacy_field_id')
            return null;
        if (fProps.fieldName == 'owner_id')
            return null;
        if (fProps.fieldName == 'topic')
            return null;
        if (fProps.fieldName == 'goal') {
            return null;
        }
        if (fProps.fieldName == 'cross_curriculum_link') {
            return null;
        }     
        if (fProps.fieldName == 'facility_to_develop') {
            return null;
        }

        if (fProps.fieldName == 'dpmk_methodology_package_id')
            return null;
        if (fProps.fieldName == 'dpmk_methodology_package_text')
            return null;
        if (fProps.fieldName == 'frame_study_conditions')
            return null;
        if (fProps.fieldName == 'frame_contributor_experts') {
            return null;
        }
        if (fProps.fieldName == 'concepts_to_pick') {
            return null;
        }

        if (fProps.fieldName == 'conclusions')
            return null;
        if (fProps.fieldName == 'suggestions') {
            return null;
        }
        if (fProps.fieldName == 'oo_folder_id') {
            return null;
        }

        return super.getFieldEditor(fProps);
    }
}