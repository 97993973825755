import { Server } from '../../server/Server';
import Crud , { registerCrudClassProxy, IAsTextResult, IListParams, CrudClassProxy, ISearchTextParams, ISearchTextResult } from '../Crud';

export interface ISystemCheckRecord {
    /**
     * Globálisan egyedi azonosító.
     * 
     * Ha új rekordot akarsz létrehozni put() metódussal, akkor hagyd üresen.
     * upsert() hívásnál figyelmen kívül van hagyva az értéke.
     *
     */
    id ?: number;
    /**
     * Kód
     */
    code?: string;
    /**
     * Adatközpont
     */
    cluster_name?: string;
    /**
     * Tomcat példány
     */
    instance_name?: string;
    /**
     * Név
     */
    name?: string;
    /**
     * Leírás
     */
    description?: string;
    /**
     * Lejárat
     */
    expires?: string /*timestamp?*/;
    /**
     * Utoljára ellenőrizve
     */
    checked?: string /*timestamp?*/;
    /**
     * Eredmény
     */
    result?: string;
    /**
     * Rendszer ellenőrzés szint
     *
     * NOT NULL -> sys.system_check_level ON DELETE noaction
     *
     */
    system_check_level_id?: number;
}

/**
 *  sys.system_check - Rendszer ellenőrzés
 */

export default class SystemCheckCrud extends Crud<ISystemCheckRecord> {
    public static TABLE_INFO_ID = 2788794688;
    public static getSchemaNameForClass() { return 'sys'; }
    public static getTableNameForClass() { return 'system_check'; }

    public static load: (id: number, aServer ?: Server) => Promise<SystemCheckCrud>;
    public static asText: (id: number, includeRecord : boolean, aServer ?: Server) => Promise<IAsTextResult<ISystemCheckRecord>>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<ISystemCheckRecord[]>;
    public static searchText: (params: ISearchTextParams, aServer ?: Server) => Promise<ISearchTextResult<ISystemCheckRecord>[]>;
    public static deleteById: (id: number, aServer ?: Server) => Promise<ISystemCheckRecord>;
    public static unDeleteById: (id: number, aServer ?: Server) => Promise<ISystemCheckRecord>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class SystemCheckCrudClassProxy extends CrudClassProxy<ISystemCheckRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const systemCheckCrudClassProxy = new SystemCheckCrudClassProxy(SystemCheckCrud);
registerCrudClassProxy(systemCheckCrudClassProxy);
