import * as React from 'react';

import { app } from '@src/index';

import obtainServer from '@src/framework/server/Server';
import { HeaderNotificationStore, IAnnounceMessages, getAnnounceMessages, IAnnounceMessage, setAnnounceMessage } from '@src/component/HeaderNotification';
import { __ } from '@src/translation';
import { me } from '@framework/server/Auth';

interface ISysAnnounceState {
    messages : IAnnounceMessages;
}

export default class SysAnnounce extends React.Component<any, ISysAnnounceState> {

    constructor(props: any) {
        super(props);


        this.state = {
            messages: {
                anonymMessage: null,
                registeredMessage: null
            }
        };

    }


    componentWillMount() {
        this.asyncReload();
    }

    private asyncReload = async () => {
        try {
            let messages: IAnnounceMessages = await getAnnounceMessages();
            // Csak hogy konzisztens legyen...
            HeaderNotificationStore.changed();
            this.setState({ messages });

        } catch (error) {
            app.showErrorFromJsonResult(error);
        }
    }

    private setRegisteredMessage = (message: string) => {
        const old = this.state.messages.registeredMessage;
        const registeredMessage : IAnnounceMessage = {
            message: message,
            when: old?old.when:null,
            creator: old?old.creator:null,
        }
        this.setState({
            messages: {
                registeredMessage,
                anonymMessage: this.state.messages.anonymMessage
            }
        })

    }

    private publishRegisteredMessage = async () => {
        try {
            const messages = await setAnnounceMessage("registered", this.state.messages.registeredMessage!.message);
            this.setState({messages});
            app.showInfo(__("Sikeres"), __("Hirdetmény beállítása sikeres."));
        } catch (e) {
            app.showErrorFromJsonResult(e);
        }
    }

    private setAnonymMessage = (message: string) => {
        const old = this.state.messages.anonymMessage;
        const anonymMessage : IAnnounceMessage = {
            message: message,
            when: old?old.when:null,
            creator: old?old.creator:null,
        }
        this.setState({
            messages: {
                anonymMessage,
                registeredMessage: this.state.messages.registeredMessage
            }
        })

    }

    private publishAnonymMessage = async () => {
        try {
            const messages = await setAnnounceMessage("anonym", this.state.messages.anonymMessage!.message);
            this.setState({messages});
            app.showInfo(__("Sikeres"), __("Hirdetmény beállítása sikeres."));
        } catch (e) {
            app.showErrorFromJsonResult(e);
        }
    }

    render() {
        const registeredMessage = this.state.messages.registeredMessage;
        const anonymMessage = this.state.messages.anonymMessage;

        return <div className="callout">
                            
                    <h3><i className="fa fa-bullhorn" />&nbsp; {__("Rendszer hirdetmény")}</h3>

                    <div className="row">
                        <div className="column small-12">
                            <p className="callout alert">
                                {__("Az itt megadott hirdetmény az összes bejelentkezett felhasználónak megjelenik a főlapon.")}
                                {__("Azoknak is, akik nincsenek bejelentkezve. Ide írhatsz pl. olyat, hogy")}
                                {__('"Tervezett frissítés, reggel 2:00 és 4:00 között nem lesz elérhető a weblap."')}
                                {__("A hirdetmény törléséhez töröld ki a szöveget és mentsd el.")}
                            </p>
                        </div>
                    </div>

                    <div className="row">
                        <div className="column small-12">
                            <h4>Regisztrált felhasználónak</h4>
                        </div>
                    </div>

                    <div className="row">
                        <div className="column small-8 large-10">
                            <input type="text" value={
                                (registeredMessage && registeredMessage.message) || ""}
                                onChange={(e: any) => {
                                    this.setRegisteredMessage(e.target.value);
                                }}
                            />
                        </div>
                        <div className="column small-4 large-2">
                            <button type="button" className="button primary"
                                onClick={this.publishRegisteredMessage}
                            >
                                <i className="fa fa-save" /> {__("Közzétesz")}
                            </button>
                        </div>
                    </div>
                    {registeredMessage?
                        <div className="row">
                                <div className="column small-6 large-6">
                                    <span><b>Utoljára beállította:</b>&nbsp;{registeredMessage.creator}</span>
                                </div>
                                <div className="column small-6 large-6">
                                    <span><b>Utoljára beállítva:</b>&nbsp;{registeredMessage.when}</span>
                                </div>
                        </div>
                    :null}


                    <div className="row">
                        <div className="column small-12">
                            <h4>Mindenkinek</h4>
                        </div>
                    </div>

                    <div className="row">
                        <div className="column small-8 large-10">
                            <input type="text" value={
                                (anonymMessage && anonymMessage.message) || ""}
                                onChange={(e: any) => {
                                    this.setAnonymMessage(e.target.value);
                                }}
                            />
                        </div>
                        <div className="column small-4 large-2">
                            <button type="button" className="button primary"
                                onClick={this.publishAnonymMessage}
                            >
                                <i className="fa fa-save" /> {__("Közzétesz")}
                            </button>
                        </div>
                    </div>
                    {anonymMessage?
                        <div className="row">
                                <div className="column small-6 large-6">
                                    <span><b>Utoljára beállította:</b>&nbsp;{anonymMessage.creator}</span>
                                </div>
                                <div className="column small-6 large-6">
                                    <span><b>Utoljára beállítva:</b>&nbsp;{anonymMessage.when}</span>
                                </div>
                        </div>
                    :null}

            </div>



    }
}
