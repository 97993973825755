import * as React from 'react';
import { match } from 'react-router';
import InstituteGroupCrud, { IInstituteGroupRecord } from '@src/framework/crud/sys/InstituteGroupCrud';
import ViewInstituteGroupMember from '@src/framework/view/sys/ViewInstituteGroupMember';
import { me } from '@src/framework/server/Auth';
import { app } from '@src/index';
import { BubbleLoader } from 'react-css-loaders';
import MediaFileChooser, { ALL_ACTIONS, ContentActionType } from '@src/component/filemanager/MediaFileChooser';
import { History } from 'history';
import { classroomModule } from './classroomModule';
import { ItemStyle } from '@src/component/filemanager/ContentItem';

type ClassroomFolderPageProps = {
    match: match<{instituteGroupId: string, folderId?: string}>;
    history: History
}

type ClassroomFolderPageState = {
    group?: IInstituteGroupRecord;
    isGroupAdmin: boolean;
}

export class ClassroomFolderPage extends React.Component<ClassroomFolderPageProps, ClassroomFolderPageState> {
    
    constructor(props: ClassroomFolderPageProps) {
        super(props);

        this.state = {
            isGroupAdmin: false
        }
    }

    componentDidMount() {
        this.reloadAsync();
    }

    private async reloadAsync() {
        try {
            const instituteGroupId = Number(this.props.match.params.instituteGroupId);

            const group = (await InstituteGroupCrud.load(instituteGroupId)).record;
            const members = await ViewInstituteGroupMember.list({
                filter: {
                    institute_group_id: instituteGroupId,
                    is_active: true,
                    is_admin: true
                }
            });
            
            this.setState({
                group,
                isGroupAdmin: members.some(m => me && m.sec_user_id === me.id)
            })

        } catch (e) {
            app.showErrorFromJsonResult(e);
        }
    }

    private onFolderSelected(newFolderId: number | null) {
        let url = classroomModule.PATH_CLASSROOM_FOLDER + "/" + this.props.match.params.instituteGroupId;
        if (newFolderId) url += "/" + newFolderId;
        this.props.history.push(url);
    }

    render() {
        if (!this.state.group) {
            return <BubbleLoader />;
        }

        return <div className="row expanded">
            <div className="column small-12">
                <MediaFileChooser
                    rootFolderId={this.state.group.oo_folder_id!}
                    currentFolderId={this.props.match.params.folderId ? Number(this.props.match.params.folderId) : this.state.group.oo_folder_id!}
                    onFolderSelected={this.onFolderSelected.bind(this)}
                    enabledActions={this.state.isGroupAdmin ? ALL_ACTIONS : [ContentActionType.FILE_DOWNLOAD]}
                    defaultStyle={ItemStyle.LIST}
                />
            </div>
        </div>;
    }

}
