import * as React from 'react';
import { BubbleLoader } from 'react-css-loaders';
import axios from 'axios';
import { app } from '@src/index';
import { config } from '@src/framework/server/Server';
import { __ } from '@src/translation';

export type InstitutionItem = {
    institution_name: string;
    instituion_id: number;
}

export type ImageItem = {
    preview: string;
    filename_loc: string;
    folder: string;
    name: string;
    type: string;
    subset: string;    
}

export type MuseumDigitalSearchResult = {
    object_institution: InstitutionItem;
    object_dimensions: string;
    object_description: string;
    object_id: number;
    object_images: ImageItem[];
    object_name: string;
    object_material_technique: string;
}

export type MuseumDigitalIdItem = {
    score: string;
    objekt_id: string;
}

interface MuseumDigitalSearchComponentProps {
    keyword: string;
}

type MuseumDigitalSearchComponentState = {
    results: MuseumDigitalSearchResult[] | null;
    loading: boolean;
}

export default class MuseumDigitalSearchComponent extends React.Component<MuseumDigitalSearchComponentProps, MuseumDigitalSearchComponentState> {

    constructor(props: MuseumDigitalSearchComponentProps) {
        super(props);
        this.state = { results: null, loading: true };

        this.onSearch();
    }

    componentDidUpdate(prevProps: MuseumDigitalSearchComponentProps, prevState: MuseumDigitalSearchComponentState) {
        if (prevProps.keyword != this.props.keyword) {
            this.onSearch();
        }
    }

    private async onSearch() {
        var url = config!.searchServer!.apiBaseUrl + "museumDigital?keyword=" + this.props.keyword;

        this.setState({loading: true});
        try {
            const idArray : MuseumDigitalIdItem[] = (await axios.get<MuseumDigitalIdItem[]>(url,{maxRedirects: 5})).data;
            this.addResults(idArray);
            this.setState({loading: false});
        } catch (error) {
            app.showErrorFromJsonResult(error);
        }
    }

    private async addResults(ids: MuseumDigitalIdItem[]) {        
        ids.forEach( async (element : MuseumDigitalIdItem, index : number) => {
            let urlString = "https://hu.museum-digital.org/index.php?t=objekt&oges=" + element.objekt_id + "&recordlang=hu&output=json";             
            const response : MuseumDigitalSearchResult = (await axios.get<MuseumDigitalSearchResult>(urlString
                ,{maxRedirects: 5}
            )).data;
            let results : MuseumDigitalSearchResult[]|null = this.state.results;
            if (results) {
                results.push(response);
            } else {
                results = [response];
            }
            this.setState({results});
        });
    }

    render() {
        
        if (!this.props.keyword) {
            return "";
        }

        if (this.state.loading) {
            return <BubbleLoader />;
        }        

        const results = this.state.results;    
            
        if ((results !== null) && (results.length > 0)) {
            return <div className="eke-searchresult">
                <h5>{__("MúzeumDigitár találatok")}</h5>

                    <div className="row small-up-1 medium-up-3 large-up-4">
                        {results.map((item) => {                       

                            /**
                            let target: string;
                            if (item.object_images[0].type == "image") {
                                target = "https://www.museum-digital.de/data/hu/" + item.object_images[0].folder + "/" + item.object_images[0].filename_loc;
                            } else {
                                target = item.object_images[0].filename_loc;
                            }
                            */

                            let target: string;
                            target = "https://hu.museum-digital.de/index.php?t=objekt&oges=" + item.object_id;

                            let meret;
                            if (item.object_dimensions) {
                                meret = <div>{__("Méret")}: {item.object_dimensions} <br/></div>
                            } else { 
                                meret = ""
                            }                            

                            return <div key={item.object_id} className="search-item column column-block">  
                                <a href={target} target="_new" key={item.object_id}>                                                                           
                                    <img src={"https://www.museum-digital.de/data/" + item.object_images[0].subset + "/" + item.object_images[0].folder + "/" + item.object_images[0].preview} title={item.object_description} /> 
                                    <small>
                                        {__("Név")}: {item.object_name} <br/>
                                        {__("Forrás")}: {item.object_institution.institution_name} <br/>
                                        {meret}
                                        {__("Anyag")}: {item.object_material_technique}
                                    </small>                                
                                </a>                                
                            </div>
                        }
                        )}
                    </div>    
                
            </div>;
        } else {
            return <div className="eke-searchresult">{__("Nincs MúzeumDigitár találat a következő kifejezésre")}: <b>{this.props.keyword}</b></div>;
        }        
    }

}    