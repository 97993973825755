import * as React from 'react';

import { IViewPartnerRecord, ViewPartnerClassProxy, viewPartnerClassProxy } from '@src/framework/view/sys/ViewPartner';
import ListView from '@framework/forms/listview';

import '@framework/crud/sys/PartnerCrud'; // Ezt ne távolítsd el mert akkor a hülye webpack kioptimizálja!
import { __ } from '@src/translation';

class PartnerInner extends ListView<IViewPartnerRecord> {
    
    public getViewClassProxy() : ViewPartnerClassProxy { return viewPartnerClassProxy; }

    protected getQuickFilterFieldNames(): string[] {
        return ['fullname'];
    }

}

export default function PartnerListView(props: any) {
    // TODO: hozzáadni a Partner-os oszlopokat a view-hoz hogy látszódjon a 
    // rendes nevük!
    return <PartnerInner 
        defaultPageSize={10}
        match={props.match}        
        viewColumns={[
            {accessor: "id", maxWidth: 100},
            {accessor: "fullname"},
            {accessor: "status_name"},
            /*
            {accessor: "creation_time"},
            {accessor: "creator"},
            {accessor: "modification_time"},
            {accessor: "modifier"},
            */
            {accessor: "link", Header:__("Műveletek"), maxWidth:200},
        ]}
    />
}
