import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { BubbleLoader } from 'react-css-loaders';
import { __ } from '@src/translation';

export class TimedBubbleLoader extends React.Component<{timesec?: number, text?: string | JSX.Element}, {timeout: boolean}> {

    constructor(props: any) {
        super(props);

        this.state = {
            timeout: false,
        };

    }

    componentDidMount()
    {
        setTimeout(()=>this.setState({timeout: true}), this.props.timesec ? (this.props.timesec*1000) : 5000);
    }

    render() {
        return <>
                    {
                        this.state.timeout
                        ?
                        this.props.text ? this.props.text : __("Az erőforrás nem található")
                        :
                        <BubbleLoader/>
                    }
                </>
 
    }
}
