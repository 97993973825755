import * as React from 'react';
import { app } from '@src/index';
import DfhtVisitCrud from '@src/framework/crud/kap/DfhtVisitCrud';
import { BubbleLoader } from 'react-css-loaders';
import LookupEdit from '@src/framework/forms/lookupedit';
import InstituteCrud from '@src/framework/crud/sys/InstituteCrud';
import { viewKapDfhtSupporterClassProxy } from '@src/framework/view/kap/ViewKapDfhtSupporter';
import { DialogContent, DialogActions, Dialog, alertDialog, confirmDialog } from '@src/component/Dialog';
import { DateTimePicker, DateTimeGeneralType } from '@src/component/dashboard/datetimepicker/DateTimePicker';
import SecUserCrud from '@src/framework/crud/sys/SecUserCrud';
import ClassVisitCrud, { IClassVisitRecord, } from '@src/framework/crud/kap/ClassVisitCrud';
import ReactTable from 'react-table';
import { WorkflowDialog } from '@src/component/wf/WorkflowDialog';
import { __ } from '@src/translation';
import { formatHourMinute, formatDateWithoutTime } from '@src/Util';
import ViewKapDfhtVisit, { IViewKapDfhtVisitRecord } from '@src/framework/view/kap/ViewKapDfhtVisit';
import ViewKapClassVisit, { IViewKapClassVisitRecord } from '@src/framework/view/kap/ViewKapClassVisit';
import CrudSelectComponent from '@src/framework/forms/crudselect';
import SubjectCrud, { subjectCrudClassProxy } from '@src/framework/crud/doc/SubjectCrud';
import InstituteSiteCrud, { instituteSiteCrudClassProxy } from '@src/framework/crud/sys/InstituteSiteCrud';
import ClassVisitTypeCrud, { classVisitTypeCrudClassProxy } from '@src/framework/crud/kap/ClassVisitTypeCrud';
import ViewKapInstituteSiteTeacher, { viewKapInstituteSiteTeacherClassProxy } from '@src/framework/view/sys/ViewKapInstituteSiteTeacher';
import { DfhtTimeBusy, DfhtDocumentUpload, DfhtWaitingAttendance, DfhtClosed, DfhtLessonPlanUpload, DfhtSupportTime } from './AttendanceCalendarProps';
import { hasAnyGroup, Groups, me, hasGroup } from '@src/framework/server/Auth';
import ClassVisitSubprogramCrud, { classVisitSubprogramCrudClassProxy } from '@src/framework/crud/kap/ClassVisitSubprogramCrud';
import SubprogramCrud, { ISubprogramRecord } from '@src/framework/crud/kap/SubprogramCrud';
import { updateSubrecords } from '@src/component/filemanager/UpdateSubrecords';
import MediaFileChooser, { FILE_ACTIONS,ContentActionType } from '@src/component/filemanager/MediaFileChooser';
import ComplexAspectCrud, { IComplexAspectRecord } from '@src/framework/crud/kap/ComplexAspectCrud';
import ComplexAspectAnswerCrud, { IComplexAspectAnswerRecord } from '@src/framework/crud/kap/ComplexAspectAnswerCrud';
import ComplexAspectValueCrud, { IComplexAspectValueRecord } from '@src/framework/crud/kap/ComplexAspectValueCrud';
import KipAspectCrud, { IKipAspectRecord } from '@src/framework/crud/kap/KipAspectCrud';
import KipAspectAnswerCrud, { IKipAspectAnswerRecord } from '@src/framework/crud/kap/KipAspectAnswerCrud';
import { KipDraftEdit } from '../draft/KipDraft';
import { LessonDraftEdit } from '../draft/LessonDraft';
import WfAPI, { IViewTransitionTypeRecordWithPerm } from '@src/framework/wf/WfAPI';
import obtainServer from '@src/framework/server/Server';
import { LIBRARY_JTR_BEADANDO_ID } from '@src/Const';

const CLASS_VISIT_TYPE_KOMPLEX_ID = 2;
const WORKFLOW_DFHT_VISIT_ID = 25346333724;

type DfhtVisitEditorProps = {
    id: number | null;
    onNewId: (newId: number) => void;
    onClose: () => void;

    minStartDateDefault?: string,
    maxEnddateDefault?: string;
}

type DfhtVisitEditorState = {
    visit?: IViewKapDfhtVisitRecord;
    classes: IViewKapClassVisitRecord[];
    subprograms: ISubprogramRecord[];
    workflowDialogOpen: boolean;
    editClassIndex?: number | null;
    uploadClassIndex?: number;
    aspectClassIndex?: number;
    draftClassIndex?: number;
    canSave: boolean;
    canTransition: boolean;
    isDataChanged: boolean;
}

export class DfhtVisitEditor extends React.Component<DfhtVisitEditorProps, DfhtVisitEditorState> {

    constructor(props: DfhtVisitEditorProps) {
        super(props);

        this.state = {
            classes: [],
            subprograms: [],
            workflowDialogOpen: false,
            canSave: true,
            canTransition: false,
            isDataChanged: false
        }
    }

    componentDidMount() {
        this.reloadAsync();
    }

    componentDidUpdate(prevProps: DfhtVisitEditorProps) {
        if (prevProps.id !== this.props.id) {
            this.reloadAsync();
        }
    }

    private async reloadAsync() {
        if (!this.props.id) {

            const fixedKapSupporterId = me && (!hasGroup(me, Groups.KapJtrCoordinator) && hasGroup(me, Groups.KapJtrSupport)) ? me.id : undefined;

            this.setState({
                visit: {
                    min_start_date: this.props.minStartDateDefault,
                    max_end_date: this.props.maxEnddateDefault,
                    kap_supporter_id: fixedKapSupporterId,
                }
            })
            return;
        }

        try {
            const subprograms = await SubprogramCrud.list({
                filter: { is_active: true },
                order_by: "code"
            });

            const visit = (await ViewKapDfhtVisit.load(this.props.id)).record;
            const classes = await ViewKapClassVisit.list({
                filter: {
                    dfht_visit_id: this.props.id,
                    is_active: true
                },
                order_by: "start_date"
            });

            for (const cls of classes) {
                const visitSubprograms = await ClassVisitSubprogramCrud.list({
                    filter: {
                        is_active: true,
                        class_visit_id: cls.id
                    }
                });
                cls["subprograms"] = visitSubprograms;
                cls["subprogramIds"] = visitSubprograms.filter(s => s.is_active).map(s => s.subprogram_id);
            }

            const wfApi = new WfAPI(obtainServer());
            const stations = await wfApi.getStationPermissions(WORKFLOW_DFHT_VISIT_ID);
            let canSave = ((visit.wf_station_id && stations[visit.wf_station_id] && stations[visit.wf_station_id].can_update_master) || (me && hasGroup(me, Groups.Admin))) ? true : false;

            if (me && !hasAnyGroup(me, [Groups.KapJtrCoordinator, Groups.KapJtrSupport]) && hasGroup(me, Groups.KapKszrCertifiedTeacher)) {
                canSave = false;
            }

            let canTransition = false;
            if (visit.wf_workflow_id) {
                const transitions = await wfApi.listPossibleTransitions(visit.wf_workflow_id);
                canTransition = transitions.find(t => t.has_perm) ? true : false;
            }

            this.setState({
                visit,
                classes,
                subprograms,
                canSave,
                canTransition,
                isDataChanged: false
            })
        } catch (e) {
            app.showErrorFromJsonResult(e);
        }
    }

    private onChange(propName: string, value: any, callback?: () => void) {
        this.setState({
            visit: { ...this.state.visit, [propName]: value },
            isDataChanged: true
        }, callback)
    }

    private onSetClassVisit(index: number | null, classVisit: IViewKapClassVisitRecord) {
        const classes = this.state.classes;
        if (index === null) {
            classes.push(classVisit);
        } else {
            classes[index] = classVisit;
        }
        this.setState({
            classes,
            isDataChanged: true
        });
    }

    private async onSave() {
        if (!this.state.visit) return;

        if (!this.state.visit.institute_id) {
            alertDialog("Intézmény hiányzik", "Az intézményi feladatellátási helyet kötelező kitölteni!");
            return;
        }

        try {

            const visit = this.state.visit;
            visit.is_active = undefined; // Szerver: Ehhez a rekordhoz van folyamat. Ilyenkor a rekord nem törölhető, és nem állítható vissza...

            const newDfhtVisitRecord = (await new DfhtVisitCrud(visit).put()).record;

            for (const classVisit of this.state.classes) {
                if (classVisit.id) {
                    classVisit.dfht_visit_id = undefined; // Szerver: tétel rekordok áthelyezése másik folyamat alá nem támogatott
                } else {
                    classVisit.dfht_visit_id = newDfhtVisitRecord.id;
                }

                const newClassVisit = (await new ClassVisitCrud(classVisit).put()).record;

                updateSubrecords(
                    classVisit["subprograms"] || [], classVisit["subprogramIds"] || [], classVisitSubprogramCrudClassProxy, "class_visit_id", newClassVisit.id!, "subprogram_id"
                );
            }

            app.showSuccess("Látogatás lementve", "Látogatás lementve");

            if (this.props.id !== newDfhtVisitRecord.id && newDfhtVisitRecord.id) {
                this.props.onNewId(newDfhtVisitRecord.id);
            } else {
                this.reloadAsync();
            }

        } catch (e) {
            app.showErrorFromJsonResult(e);
        }
    }

    private async onSetLessonDraftId(index: number, lessonDraftId: number) {
        try {
            const id = this.state.classes[index].id;
            if (!id) return;

            await new ClassVisitCrud({
                id,
                lesson_draft_id: lessonDraftId
            }).put();

            app.showSuccess("Óravázlat csatolva", "Óravázlat csatolva az óralátogatáshoz");

            this.reloadAsync();

        } catch (e) {
            app.showErrorFromJsonResult(e);
        }
    }

    private async onSetKipDraftId(index: number, kipDraftEdit: number) {
        try {

            const id = this.state.classes[index].id;
            if (!id) return;

            await new ClassVisitCrud({
                id,
                kip_draft_id: kipDraftEdit
            }).put();

            app.showSuccess("Óravázlat csatolva", "Óravázlat csatolva az óralátogatáshoz");

            this.reloadAsync();

        } catch (e) {
            app.showErrorFromJsonResult(e);
        }
    }

    private onBeforeTransition(transition: IViewTransitionTypeRecordWithPerm) {
        if (!this.state.visit) return false;

        if (transition.dst_station_id == 25346333727) {

            if (!this.state.visit.institute_id) {
                alertDialog("Intézmény hiányzik", "Az intézményi feladatellátási helyet kötelező kitölteni!");
                return false;
            }
            if (!this.state.visit.kap_supporter_id) {
                alertDialog("Szakmai támogató hiányzik", "A szakmai támogatót kötelező kitölteni!");
                return false;
            }

        }
        return true;
    }

    private removeCheck() {
        if (me && hasGroup(me, Groups.KapJtrCoordinator)) return;

        if (me && hasGroup(me, Groups.KapJtrSupport)) {
            this.onChange("site_approved", null);
        }
        if (me && hasGroup(me, Groups.KapJtrInstituteContact)) {
            this.onChange("supporter_approved", null);
        }
    }
    //Óra törlése
    private async onDeleteClass(row_id: number) {
        if (!await confirmDialog(__("Törlés"), __("Biztosan törölni akarja a listából?"), __("Törlés"))) return;
        try {
            let classVisit: IViewKapClassVisitRecord = this.state.classes[row_id];
            await ClassVisitCrud.deleteById(classVisit.id!);
            app.showSuccess(__("Bejegyzés törlése"), __("Sikeres"))
            await this.reloadAsync();
        } catch (e) {
            app.showErrorFromJsonResult(e);
        }
    }

    render() {
        if (!me) return null;

        if (!this.state.visit) {
            return <Dialog title="Betöltés" width={1200} onClose={this.props.onClose}><BubbleLoader /></Dialog>;
        }

        const stationId = this.state.visit.wf_station_id;


        const minMaxDateVisible = !stationId || stationId == DfhtSupportTime || stationId == DfhtTimeBusy;

        const minMaxDateEditable = !stationId || stationId == DfhtSupportTime || (hasAnyGroup(me, [Groups.Admin, Groups.Developer, Groups.KapJtrCoordinator, Groups.KapJtrSupport]) && stationId == DfhtTimeBusy);

        const instituteEditable = hasAnyGroup(me, [Groups.Admin, Groups.Developer, Groups.KapJtrCoordinator, Groups.KapJtrSupport]) && (!stationId || stationId == DfhtSupportTime || stationId == DfhtTimeBusy);
        const supporterEditable = hasAnyGroup(me, [Groups.Admin, Groups.Developer, Groups.KapJtrCoordinator, Groups.KapJtrSupport]) && (!stationId || stationId == DfhtSupportTime || stationId == DfhtTimeBusy);
        const classVisitEditable = hasAnyGroup(me, [Groups.Admin, Groups.Developer, Groups.KapJtrCoordinator, Groups.KapJtrSupport]) && (stationId == DfhtSupportTime || stationId == DfhtTimeBusy);
        const classVisitFileEditable = hasAnyGroup(me, [Groups.Admin, Groups.Developer, Groups.KapJtrCoordinator, Groups.KapJtrSupport]) && (stationId == DfhtDocumentUpload || stationId == DfhtWaitingAttendance || stationId == DfhtClosed);
        const classVisitAspectEditable = classVisitFileEditable || (stationId == DfhtWaitingAttendance && this.state.classes.find(c => c.teacher_id == me!.id));
        const isMediaVisible = hasAnyGroup(me, [Groups.Admin, Groups.Developer, Groups.KapJtrCoordinator]) && (stationId == DfhtWaitingAttendance || stationId == DfhtClosed);

        const fixedKapSupporterId = (!hasGroup(me, Groups.KapJtrCoordinator) && hasGroup(me, Groups.KapJtrSupport)) ? me.id : undefined;

        const supporterClearable = (me && (!hasGroup(me, Groups.KapJtrCoordinator) && hasGroup(me, Groups.KapJtrSupport))) ? false : true;

        const dateString = formatDateWithoutTime(this.state.visit.min_start_date);

        return <>
            <Dialog title={dateString + " - DFHT / Komplex óralátogatás"} width={1200} onClose={this.props.onClose}>
                <DialogContent>
                    <div className="row expanded">
                        <div className="column small-6">
                            {
                                minMaxDateVisible
                                    ?
                                    <>
                                        <label>
                                            Szakmai támogató által megadott időpont
                            </label>
                                        {
                                            minMaxDateEditable
                                                ?
                                                <div style={{ display: "flex", alignItems: "center" }}>
                                                    <DateTimePicker
                                                        value={this.state.visit.min_start_date ? this.state.visit.min_start_date.substring(0, 16) : ""}
                                                        onChange={(date, time) => this.onChange("min_start_date", date + " " + time, this.removeCheck.bind(this))}
                                                        visibled={DateTimeGeneralType.all} minTime="07:00"
                                                    />
                                                    <span style={{ margin: "0 0.25em" }}>-</span>
                                                    <DateTimePicker
                                                        value={this.state.visit.max_end_date ? this.state.visit.max_end_date.substring(0, 16) : ""}
                                                        onChange={(date, time) => this.onChange("max_end_date", date + " " + time, this.removeCheck.bind(this))}
                                                        visibled={DateTimeGeneralType.time} className="end" maxTime="18:00"
                                                    />
                                                </div>
                                                :
                                                formatHourMinute(this.state.visit.min_start_date) + "-" + formatHourMinute(this.state.visit.max_end_date)
                                        }
                                    </>
                                    :
                                    <>
                                        <label>
                                            Időpont
                            </label>
                                        {this.state.visit.min_start_date && this.state.visit.min_start_date.substring(0, 10)}
                                    </>
                            }
                        </div>
                        <div className="column small-6">
                            <label>
                                Státusz
                    </label>
                            <div style={{ ...this.state.visit.wf_station_style, padding: "0.5em" }}>
                                {this.state.visit.wf_station_name || "Új létrehozása"}
                            </div>
                        </div>
                        <div className="column small-12">
                            <label>
                                Intézmény *
                    </label>
                            <LookupEdit
                                key={this.state.visit.institute_id || ""}
                                fk_table_info_id={InstituteCrud.TABLE_INFO_ID}
                                emptyLoad={true}
                                viewClassProxy={viewKapDfhtSupporterClassProxy}
                                distinct={true}
                                isDisabled={!instituteEditable}
                                selectColumnNames={["institute_id", "institute_name"]}
                                searchColumnNames={["institute_name"]}
                                displayColumnNames={["institute_name"]}
                                orderByColumnNames={"institute_name"}
                                valueColumn={"institute_id"}
                                filter={{
                                    kap_supporter_id: this.state.visit.kap_supporter_id || fixedKapSupporterId,
                                    "$notnull": "kap_introduction_date"
                                }}

                                clearable={false}
                                value={this.state.visit.institute_id}
                                onChange={value => this.onChange("institute_id", value)}
                                placeholder="Kérem válasszon intézményt..."
                                styles={{ singleValue: base => ({ ...base, whiteSpace: "pre-wrap" }), menu: base => ({ ...base, zIndex: 19999 }) }}
                                noOptionsMessage={() => "Nincs megfelelő intézmény"}
                            />
                        </div>

                        <div className="column small-12">
                            <label>
                                Szakmai támogató
                    </label>
                            <LookupEdit
                                key={this.state.visit.kap_supporter_id || ""}
                                fk_table_info_id={SecUserCrud.TABLE_INFO_ID}
                                emptyLoad={true}
                                isDisabled={!supporterEditable}
                                viewClassProxy={viewKapDfhtSupporterClassProxy}
                                searchColumnNames={["fullname", "email"]}
                                selectColumnNames={["kap_supporter_id", "fullname", "email"]}
                                displayColumnNames={["fullname", "email"]}
                                orderByColumnNames={[{ name: "email" }]}
                                valueColumn={"kap_supporter_id"}
                                distinct={true}
                                filter={{
                                    kap_supporter_id: this.state.visit.kap_supporter_id || fixedKapSupporterId,
                                    institute_id: this.state.visit.institute_id || undefined,
                                    "$notnull": "kap_introduction_date"
                                }}
                                /*
                                asTextViewClassProxy={viewKapJtrSupporterClassProxy}
                                asTextColumnNames={["fullname", "email"]}
                                */

                                clearable={supporterClearable}
                                value={this.state.visit.kap_supporter_id}
                                onChange={value => this.onChange("kap_supporter_id", value)}

                                placeholder="Kérem válasszon szakmai támogatót..."
                                styles={{ singleValue: base => ({ ...base, whiteSpace: "pre-wrap" }), menu: base => ({ ...base, zIndex: 19999 }) }}
                                noOptionsMessage={() => "Nincs választható szakmai támogató"}
                            />
                        </div>

                        <div className="column small-12">
                            <label>
                                Órák
                    </label>
                            <ReactTable
                                columns={
                                    [
                                        { Header: "Óra", accessor: "lesson_no", width: 40 },
                                        {
                                            Header: "Időpont", accessor: "start_date", Cell: row => {
                                                return formatHourMinute(row.original.start_date) + "-" + formatHourMinute(row.original.end_date)
                                            }
                                        },
                                        {
                                            Header: "AP", accessor: "room_no", width: 40, Cell: row => {
                                                const subprogramIds = row.original.subprogramIds;
                                                return this.state.subprograms.filter(s => subprogramIds.includes(s.id)).map(s => s.code).join(", ");
                                            }
                                        },
                                        { Header: "Terem", accessor: "room_no", width: 60 },
                                        { Header: "Típus", accessor: "class_visit_type_title", width: 80 },

                                        { Header: "Feladatellátási hely", accessor: "institute_site_name" },
                                        { Header: "Pedagógus", accessor: "teacher_full_name" },
                                        { Header: "Tantárgy", accessor: "subject_name" },
                                        { Header: "Osztály", accessor: "class_name", width: 60 },
                                        { Header: "Téma", accessor: "topic" },

                                        {
                                            Header: "Művelet", className: "action-cell",minWidth:120, Cell: (row: { original: IClassVisitRecord, index: number }) => {
                                                if (this.state.visit && !this.state.visit.institute_id) return null;

                                                const classVisitDraftEditable = me && hasAnyGroup(me, [Groups.Admin, Groups.Developer, Groups.KapKszrCertifiedTeacher]) && (stationId == DfhtLessonPlanUpload) && me.id == row.original.teacher_id;

                                                const classVisitDraftDownloadable = me && hasAnyGroup(me, [Groups.Admin, Groups.Developer, Groups.KapKszrCertifiedTeacher, Groups.KapJtrSupport, Groups.KapJtrCoordinator]);

                                                let downloadLink = null;
                                                if (this.state.classes[row.index].kip_draft_id) {
                                                    downloadLink = <a className="button tiny" target="_blank" download="Óravázlat.pdf" href={`/api/kap/draft?id=${this.state.classes[row.index].kip_draft_id}&format=pdf&type=kip_draft&published=false`}>
                                                        <i className="fa fa-download" />&nbsp; Óravázlat
                                        </a>;
                                                }
                                                if (this.state.classes[row.index].lesson_draft_id) {
                                                    downloadLink = <a className="button tiny" target="_blank" download="Óravázlat.pdf" href={`/api/kap/draft?id=${this.state.classes[row.index].lesson_draft_id}&format=pdf&type=lesson_draft&published=false`}>
                                                        <i className="fa fa-download" />&nbsp; Óravázlat
                                        </a>;
                                                }

                                                return <div>
                                                    {
                                                        classVisitEditable &&
                                                        <button className="button tiny" title="Szerkesztés" onClick={() => this.setState({ editClassIndex: row.index })}>
                                                            <i className="fa fa-edit" />
                                                        </button>
                                                    }
                                                    {
                                                        classVisitEditable &&
                                                        <button className="alert button tiny" title="Törlés" onClick={this.onDeleteClass.bind(this, row.index)}>
                                                            <i className="fa fa-trash" />
                                                        </button>
                                                    }
                                                    {
                                                        classVisitFileEditable &&
                                                        <button className="button tiny" title="Fotók" onClick={() => this.setState({ uploadClassIndex: row.index })}>
                                                            <i className="fa fa-file" />
                                                        </button>
                                                    }
                                                    {
                                                        classVisitAspectEditable &&
                                                        <button className="button tiny" title="Szempontsor" onClick={() => this.setState({ aspectClassIndex: row.index })}>
                                                            <i className="fa fa-check-square" />
                                                        </button>
                                                    }
                                                    {
                                                        classVisitDraftEditable
                                                            ?
                                                            <button className="button tiny" title="Óravázlat" onClick={() => this.setState({ draftClassIndex: row.index })}>
                                                                <i className="fa fa-file-alt" /> Óravázlat
                                            </button>
                                                            :
                                                            (
                                                                classVisitDraftDownloadable
                                                                    ?
                                                                    downloadLink
                                                                    :
                                                                    downloadLink && "Óravázlat feltöltve"
                                                            )

                                                    }
                                                </div>;
                                            }
                                        },
                                    ]
                                }
                                sortable={false}
                                data={this.state.classes}
                                defaultPageSize={5}
                                showPagination={this.state.classes.length > 5}
                                className="-striped -highlight"

                                NoDataComponent={() => null}
                            />
                            {
                                classVisitEditable && this.state.visit.institute_id &&
                                <button className="button small" onClick={() => this.setState({ editClassIndex: null })}>
                                    <i className="fa fa-plus" /> Óra hozzáadása
                                </button>
                            }
                        </div>
                        <div className="column small-12">
                            {this.state.visit.oo_folder_id &&isMediaVisible
                            ?<>
                            <p>Jelenléti ív:</p>
                                    <MediaFileChooser
                                    rootFolderId={this.state.visit.oo_folder_id!}
                                    currentFolderId={this.state.visit.oo_folder_id}
                                    enabledActions={FILE_ACTIONS}
                                    showFilter={false}
                                    showLocation={false}
                                />
                                </>
                                : ""
                            }

                        </div>

                    </div>

                </DialogContent>
                <DialogActions>
                    {
                        this.state.canTransition && this.props.id &&
                        <>
                            <button disabled={this.state.isDataChanged} className="button" onClick={() => this.setState({ workflowDialogOpen: !this.state.workflowDialogOpen })}>
                                <i className="fa fa-project-diagram" />&nbsp; {__("Folyamat...")}
                            </button>
                        </>
                    }
                    {
                        this.state.canSave &&
                        <button className="button" onClick={this.onSave.bind(this)}>
                            <i className="fa fa-save" /> Mentés
                </button>
                    }
                </DialogActions>
            </Dialog>
            <WorkflowDialog
                open={this.state.workflowDialogOpen}
                displayName={__("Látogatás")}
                tableInfoId={DfhtVisitCrud.TABLE_INFO_ID}
                recId={this.props.id!}
                onClose={() => this.setState({ workflowDialogOpen: false })}
                onBeforeTransition={this.onBeforeTransition.bind(this)}
                onTransition={this.reloadAsync.bind(this)}
                autoPublishOnClosedStation={true}
            />

            {
                this.state.editClassIndex !== undefined && this.state.visit.institute_id && this.state.visit.min_start_date && this.state.visit.max_end_date &&
                <ClassVisitDialog
                    classVisit={this.state.editClassIndex !== null ? this.state.classes[this.state.editClassIndex] : {
                        subprograms: [],
                        subprogramIds: []
                    } as any}
                    otherVisits={this.state.classes.filter((c, index) => index !== this.state.editClassIndex)}
                    onChange={classVisit => this.onSetClassVisit(this.state.editClassIndex!, classVisit)}
                    onClose={() => this.setState({ editClassIndex: undefined })}
                    onSave={() => this.onSave()}
                    insituteId={this.state.visit.institute_id}
                    subprograms={this.state.subprograms}
                    date={this.state.visit.min_start_date ? this.state.visit.min_start_date.substring(0, 10) : ""}

                    minStartDate={this.state.visit.min_start_date}
                    maxEndDate={this.state.visit.max_end_date}
                />
            }

            {
                this.state.uploadClassIndex !== undefined &&
                <ClassVisitFileDialog
                    rootFolderId={this.state.classes[this.state.uploadClassIndex].oo_folder_id!}
                    stationId={stationId}
                    onClose={() => this.setState({ uploadClassIndex: undefined })}
                />
            }

            {
                this.state.aspectClassIndex !== undefined &&
                (
                    this.state.classes[this.state.aspectClassIndex].class_visit_type_id === CLASS_VISIT_TYPE_KOMPLEX_ID
                        ?
                        <ClassVisitKomplexAspectDialog
                            classVisitId={this.state.classes[this.state.aspectClassIndex].id!}
                            stationId={this.state.visit.wf_station_id}
                            subprograms={this.state.subprograms}
                            subprogramIds={this.state.classes[this.state.aspectClassIndex]["subprogramIds"]}
                            onClose={() => this.setState({ aspectClassIndex: undefined })}
                        />
                        :
                        <ClassVisitDfhtAspectDialog
                            stationId={this.state.visit.wf_station_id}
                            classVisitId={this.state.classes[this.state.aspectClassIndex].id!}
                            onClose={() => this.setState({ aspectClassIndex: undefined })}
                        />
                )
            }

            {
                this.state.draftClassIndex !== undefined &&
                (
                    this.state.classes[this.state.draftClassIndex].class_visit_type_id === CLASS_VISIT_TYPE_KOMPLEX_ID
                        ?
                        <Dialog title="Óravázlat" width={1100} height={800} onClose={() => this.setState({ draftClassIndex: undefined })}>
                            <LessonDraftEdit
                                id={this.state.classes[this.state.draftClassIndex!].lesson_draft_id || undefined}
                                onSave={this.onSetLessonDraftId.bind(this, this.state.draftClassIndex)}
                                defaultLibraryId={LIBRARY_JTR_BEADANDO_ID}
                                hideBackLink
                            />
                        </Dialog>
                        :
                        <Dialog title="Óravázlat" width={1100} height={800} onClose={() => this.setState({ draftClassIndex: undefined })}>
                            <KipDraftEdit
                                id={this.state.classes[this.state.draftClassIndex!].kip_draft_id || undefined}
                                onSave={this.onSetKipDraftId.bind(this, this.state.draftClassIndex)}
                                defaultLibraryId={LIBRARY_JTR_BEADANDO_ID}
                                hideBackLink
                            />
                        </Dialog>
                )
            }
        </>
            ;
    }

}

type ClassVisitDialogProps = {
    classVisit: IViewKapClassVisitRecord;
    otherVisits: IViewKapClassVisitRecord[];
    onChange: (classVisit: IViewKapClassVisitRecord) => void;
    onClose: () => void;
    onSave: () => void;
    insituteId: number;
    date: string;
    subprograms: ISubprogramRecord[];
    minStartDate: string;
    maxEndDate: string;
}

type ClassVisitDialogState = {
    classVisit: IViewKapClassVisitRecord;
}


class ClassVisitDialog extends React.Component<ClassVisitDialogProps, ClassVisitDialogState> {

    constructor(props: ClassVisitDialogProps) {
        super(props);

        this.state = {
            classVisit: this.props.classVisit
        }
    }

    componentDidUpdate(prevProps: ClassVisitDialogProps) {
        if (prevProps.classVisit !== this.props.classVisit) {
            this.setState({ classVisit: this.props.classVisit });
        }
    }

    private onChange(propName: string, value: any) {
        this.setState({
            classVisit: { ...this.state.classVisit, [propName]: value }
        })
    }

    private async onSetInstituteSite(id: number | null) {
        const classVisit = this.state.classVisit!;

        let name = "";

        if (id) {
            const site = (await InstituteSiteCrud.load(id)).record;
            if (site && site.name) name = site.name;
        }

        classVisit.institute_site_id = id;
        classVisit.institute_site_name = name;

        this.setState({ classVisit });
    }

    private async onSetClassVisitType(id: number | null) {
        const classVisit = this.state.classVisit!;

        let title = "";

        if (id) {
            const visitType = (await ClassVisitTypeCrud.load(id)).record;
            if (visitType && visitType.title) title = visitType.title;
        }

        classVisit.class_visit_type_id = id!;
        classVisit.class_visit_type_title = title;

        this.setState({ classVisit });
    }

    private async onSetTeacher(id: number | null) {
        const classVisit = this.state.classVisit!;

        let name = "";

        if (id) {
            const users = await ViewKapInstituteSiteTeacher.list({
                filter: { sec_user_id: id }
            });
            if (users.length > 0 && users[0].teacher_full_name) name = users[0].teacher_full_name;
        }

        classVisit.teacher_id = id!;
        classVisit.teacher_full_name = name;

        this.setState({ classVisit });
    }

    private async onSetSubject(id: number | null) {
        const classVisit = this.state.classVisit!;

        let title = "";

        if (id) {
            const subject = (await SubjectCrud.load(id)).record;
            if (subject && subject.name) title = subject.name;
        }

        classVisit.subject_id = id!;
        classVisit.subject_name = title;

        this.setState({ classVisit });
    }

    private onSetSubprogram(id: number, checked: boolean) {
        const subprogramIds = this.state.classVisit["subprogramIds"];
        if (checked) {
            subprogramIds.push(id)
        } else {
            subprogramIds.splice(subprogramIds.indexOf(id), 1);
        }

        this.setState({ classVisit: this.state.classVisit });
    }

    private async onSave() {
        const required: string[] = [];

        if (!this.state.classVisit.class_visit_type_id) required.push("típus");
        if (!this.state.classVisit.start_date || !this.state.classVisit.end_date) required.push("időpont");
        if (!this.state.classVisit.teacher_id) required.push("pedagógus");
        if (!this.state.classVisit.institute_site_id) required.push("intézmény feladatellátási hely");
        if (!this.state.classVisit.lesson_no) required.push("óra");
        if (!this.state.classVisit.room_no) required.push("terem szám");
        if (!this.state.classVisit.subject_id) required.push("tantárgy");
        if (!this.state.classVisit.class_name) required.push("osztály");

        if (this.state.classVisit.class_visit_type_id === CLASS_VISIT_TYPE_KOMPLEX_ID && this.state.classVisit["subprogramIds"].length === 0) required.push("legalább egy alprogram");

        if (required.length > 0) {
            await alertDialog("Hiányzó adat", "Az adatok megadása kötelező: " + required.join(", "));
            return;
        }

        if (!this.state.classVisit.end_date || !this.state.classVisit.start_date) return;

        if (this.state.classVisit.end_date < this.state.classVisit.start_date) {
            await alertDialog("Hibás", "Az óra vég időpontjának az óra kezdő időpontja után kell lennie!");
            return;
        }

        if (new Date(this.state.classVisit.start_date) < new Date(this.props.minStartDate)) {
            await alertDialog("Hibás adat", `Az óra a szakmai támogató által megadott időpont előtt kezdődik (${formatHourMinute(this.props.minStartDate)} - ${formatHourMinute(this.props.maxEndDate)})! `);
            return;
        }

        if (new Date(this.state.classVisit.end_date) > new Date(this.props.maxEndDate)) {
            await alertDialog("Hibás adat", `Az óra a szakmai támogató által megadott időpont után végződik (${formatHourMinute(this.props.minStartDate)} - ${formatHourMinute(this.props.maxEndDate)})! `);
            return;
        }

        for (const otherVisit of this.props.otherVisits) {
            if (this.state.classVisit !== undefined && otherVisit.lesson_no === this.state.classVisit.lesson_no) {
                await alertDialog("Foglalt", `Az ${this.state.classVisit.lesson_no}. óra már foglalt!`);
                return;
            }

            if (otherVisit.start_date && otherVisit.end_date && this.state.classVisit.start_date <= otherVisit.end_date && this.state.classVisit.end_date >= otherVisit.start_date) {
                await alertDialog("Foglalt", `Az óra ütközik a ${otherVisit.lesson_no}. órával (${formatHourMinute(otherVisit.start_date)} - ${formatHourMinute(otherVisit.end_date)})`);
                return;
            }
        }

        this.props.onChange(this.state.classVisit);
        this.props.onSave();
        this.props.onClose();
    }

    render() {
        return <Dialog title="Óra" width={800} onClose={this.props.onClose}>
            <DialogContent>
                <div className="row">

                    <div className="column small-6">
                        <label>
                            Típus *
                        </label>
                        <CrudSelectComponent
                            crudClassProxy={classVisitTypeCrudClassProxy}
                            value={this.state.classVisit.class_visit_type_id || null}
                            filter={{ is_active: true }}
                            onSelect={(_, id) => this.onSetClassVisitType(id)}
                            displayFieldName="title"
                            orderByFieldName="title"
                        />
                    </div>
                    <div className="column small-6">
                        <label>
                            Időpont *
                        </label>

                        <div style={{ display: "flex", alignItems: "center" }}>
                            <DateTimePicker
                                value={this.state.classVisit.start_date ? this.state.classVisit.start_date.substring(0, 16) : ""}
                                onChange={(date, time) => this.onChange("start_date", (date || this.props.date) + " " + time)}
                                visibled={DateTimeGeneralType.time} className="end" maxTime="18:00"
                            />
                            -
                            <DateTimePicker
                                value={this.state.classVisit.end_date ? this.state.classVisit.end_date.substring(0, 16) : ""}
                                onChange={(date, time) => this.onChange("end_date", (date || this.props.date) + " " + time)}
                                visibled={DateTimeGeneralType.time} className="end" maxTime="18:00"
                            />
                        </div>
                    </div>
                    <div className="column small-12">
                        <label>
                            Pedagógus *
                        </label>
                        <LookupEdit
                            key={this.state.classVisit.teacher_id || ""}
                            fk_table_info_id={SecUserCrud.TABLE_INFO_ID}
                            emptyLoad={true}
                            viewClassProxy={viewKapInstituteSiteTeacherClassProxy}
                            distinct={true}
                            searchColumnNames={["teacher_full_name", "teacher_email"]}
                            displayColumnNames={["teacher_full_name", "teacher_email"]}
                            orderByColumnNames={"teacher_full_name"}
                            valueColumn={"sec_user_id"}

                            /*
                            asTextViewClassProxy={viewKapInstituteSiteTeacherClassProxy}
                            asTextKeyColumn={"sec_user_id"}
                            asTextColumnNames={["teacher_full_name", "teacher_email"]}
                            */

                            filter={{
                                is_active: true,
                                teacher_status_id: 2,
                                institute_id: this.props.insituteId
                            }}
                            clearable={true}
                            value={this.state.classVisit.teacher_id}
                            onChange={value => this.onSetTeacher(value as number)}
                            placeholder="Kérem válasszon pedagógust..."
                            styles={{ singleValue: base => ({ ...base, whiteSpace: "pre-wrap" }), menuPortal: base => ({ ...base, zIndex: 19999 }) }}
                            noOptionsMessage={() => "Nincs megfelelő pedagógus"}
                        />
                    </div>
                    <div className="column small-12">
                        <label>
                            Intézmény feladat ellátási hely *
                        </label>

                        <CrudSelectComponent
                            crudClassProxy={instituteSiteCrudClassProxy}
                            value={this.state.classVisit.institute_site_id || null}
                            filter={{ is_active: true, institute_id: this.props.insituteId }}
                            onSelect={(_, id) => this.onSetInstituteSite(id)}
                            autoSelectSingle
                            displayFieldName="name"
                            orderByFieldName="name"
                        />

                    </div>
                    {
                        this.state.classVisit.class_visit_type_id === CLASS_VISIT_TYPE_KOMPLEX_ID &&
                        <div className="column small-12">
                            <label>
                                Alprogram *
                            </label>
                            {
                                this.props.subprograms.map(s => {
                                    const checked = this.state.classVisit["subprogramIds"].includes(s.id);

                                    return <label style={{ display: "inline-block", margin: "0 0.5em" }}>
                                        <input type="checkbox" checked={checked} onChange={e => this.onSetSubprogram(s.id!, e.target.checked)} /> {s.code}
                                    </label>
                                })
                            }
                        </div>
                    }

                    <div className="column small-6">
                        <label>
                            Óra *
                        </label>
                        <input type="number" value={this.state.classVisit.lesson_no || ""} onChange={e => this.onChange("lesson_no", Number(e.target.value))} />
                    </div>
                    <div className="column small-6">
                        <label>
                            Terem szám *
                        </label>
                        <input type="text" value={this.state.classVisit.room_no || ""} onChange={e => this.onChange("room_no", e.target.value)} />
                    </div>
                    <div className="column small-6">
                        <label>
                            Tantárgy *
                        </label>
                        <CrudSelectComponent
                            crudClassProxy={subjectCrudClassProxy}
                            value={this.state.classVisit.subject_id || null}
                            filter={{ is_active: true }}
                            onSelect={(_, id) => this.onSetSubject(id)}
                            displayFieldName="name"
                            orderByFieldName="name"
                        />
                    </div>
                    <div className="column small-6">
                        <label>
                            Osztály *
                        </label>
                        <input type="text" value={this.state.classVisit.class_name || ""} onChange={e => this.onChange("class_name", e.target.value)} />
                    </div>
                    <div className="column small-12">
                        <label>
                            Téma
                        </label>
                        <input type="text" value={this.state.classVisit.topic || ""} onChange={e => this.onChange("topic", e.target.value)} />
                    </div>

                </div>
            </DialogContent>
            <DialogActions>
                <button className="button small" onClick={this.onSave.bind(this)}>
                    <i className="fa fa-save" /> Mentés
                </button>
            </DialogActions>
        </Dialog>;
    }

}

type ClassVisitFileDialogProps = {
    rootFolderId: number;
    stationId?:number |null;
    onClose: () => void;
}

class ClassVisitFileDialog extends React.Component<ClassVisitFileDialogProps> {

    render() {
        return <Dialog title="Fotók " onClose={this.props.onClose}>
            <DialogContent>
                <MediaFileChooser
                    rootFolderId={this.props.rootFolderId}
                    currentFolderId={this.props.rootFolderId}
                    enabledActions={this.props.stationId &&this.props.stationId==DfhtDocumentUpload?FILE_ACTIONS:[ContentActionType.FILE_DOWNLOAD]}
                    showFilter={false}
                    showLocation={false}
                />
            </DialogContent>
            {this.props.stationId &&this.props.stationId==DfhtDocumentUpload?
            <DialogActions>
               
                <button className="button small" onClick={this.props.onClose}>
                    <i className="fa fa-save" /> Mentés
                </button>
            </DialogActions>
            :""
            }
        </Dialog>
    }

}

type ClassVisitKomplexAspectDialogProps = {
    classVisitId: number;
    stationId?: number | null;
    subprograms: ISubprogramRecord[];
    subprogramIds: number[];
    onClose: () => void;
}

type ClassVisitKomplexAspectDialogState = {
    aspects: IComplexAspectRecord[],
    values: IComplexAspectValueRecord[],
    answers: IComplexAspectAnswerRecord[],
}

class ClassVisitKomplexAspectDialog extends React.Component<ClassVisitKomplexAspectDialogProps, ClassVisitKomplexAspectDialogState> {

    constructor(props: ClassVisitKomplexAspectDialogProps) {
        super(props);

        this.state = {
            aspects: [],
            values: [],
            answers: []
        }
    }

    componentDidMount() {
        this.reloadAsync();
    }

    private async reloadAsync() {
        try {

            const aspects = await ComplexAspectCrud.list({
                filter: { is_active: true },
                order_by: "no"
            });

            const values = await ComplexAspectValueCrud.list({
                filter: { is_active: true },
                order_by: "id"
            });

            const answers = await ComplexAspectAnswerCrud.list({
                filter: {
                    is_active: true,
                    class_visit_id: this.props.classVisitId
                }
            })

            this.setState({
                aspects,
                values,
                answers
            })

        } catch (e) {
            app.showErrorFromJsonResult(e);
        }
    }

    private onSetValue(aspectId: number, property: string, value: string | number) {
        const answer = this.state.answers.find(ans => aspectId == ans.complex_aspect_id);
        if (answer) {
            answer[property] = value;
        } else {
            this.state.answers.push({
                class_visit_id: this.props.classVisitId,
                complex_aspect_id: aspectId,
                [property]: value,

            });
        }

        this.setState({ answers: this.state.answers });
    }

    private async onSave() {
        try {

            for (const answer of this.state.answers) {
                await new ComplexAspectAnswerCrud(answer).put();
            }

            app.showSuccess("Szempontsor lementve", "Szempontsor lementve");

            this.props.onClose();

        } catch (e) {
            app.showErrorFromJsonResult(e);
        }
    }

    render() {
        const canEdit = me && hasAnyGroup(me, [Groups.Admin, Groups.KapJtrSupport]) && (this.props.stationId == DfhtDocumentUpload);

        return <Dialog title="Szempontsor" width={1000} onClose={this.props.onClose}>
            <DialogContent>
                <div className="row">
                    <div className="column small-12">
                        <h5>Általános</h5>
                    </div>
                    {this.getAspectPart(null, !!canEdit)}
                    {
                        this.props.subprograms.filter(s => this.props.subprogramIds.includes(s.id!)).map((s, i) => {
                            return <React.Fragment key={i}>
                                <div className="column small-12">
                                    <h5>{s.title}</h5>
                                </div>
                                {this.getAspectPart(s.id!, !!canEdit)}
                            </React.Fragment>
                        })
                    }
                </div>
            </DialogContent>
            <DialogActions>
                {
                    canEdit &&
                    <button className="button" onClick={this.onSave.bind(this)}>
                        <i className="fa fa-save" /> Mentés
                    </button>
                }
            </DialogActions>
        </Dialog>;
    }

    private getAspectPart(subprogramId: number | null, canEdit: boolean) {
        const aspects = this.state.aspects.filter(a => a.complex_aspect_type_id == 2 && a.subprogram_id === subprogramId);

        return aspects.map((aspect, index) => {
            const answer = this.state.answers.find(ans => aspect.id == ans.complex_aspect_id);

            return <React.Fragment key={index}>
                <div className="column small-12">
                    {aspect.title}
                </div>
                <div className="column small-7" style={{ display: "flex", alignItems: "center" }}>
                    {
                        this.state.values.map((v, i) => {
                            return <label key={i} style={{ margin: "0 0.5em" }}>
                                <input disabled={!canEdit} type="radio" checked={answer && answer.complex_aspect_value_id === v.id} onChange={() => this.onSetValue(aspect.id!, "complex_aspect_value_id", v.id!)} /> {v.title}
                            </label>;
                        })
                    }
                </div>
                <div className="column small-5">
                    <input
                        type="text"
                        disabled={!answer || !canEdit}
                        value={answer && answer.comments ? answer.comments : ""}
                        onChange={e => this.onSetValue(aspect.id!, "comments", e.target.value)} />
                </div>
            </React.Fragment>;
        })
    }

}

const KIP_ASPECT_TYPE_BOOLEAN_ID = 1;
const KIP_ASPECT_TYPE_TEXT_ID = 2;

type ClassVisitDfhtAspectDialogProps = {
    classVisitId: number;
    stationId?: number | null;
    onClose: () => void;
}

type ClassVisitDfhtAspectDialogState = {
    aspects: IKipAspectRecord[],
    answers: IKipAspectAnswerRecord[],
}

class ClassVisitDfhtAspectDialog extends React.Component<ClassVisitDfhtAspectDialogProps, ClassVisitDfhtAspectDialogState> {

    constructor(props: ClassVisitKomplexAspectDialogProps) {
        super(props);

        this.state = {
            aspects: [],
            answers: []
        }
    }

    componentDidMount() {
        this.reloadAsync();
    }

    private async reloadAsync() {
        try {

            const aspects = await KipAspectCrud.list({
                filter: { is_active: true },
                order_by: "no"
            });

            const answers = await KipAspectAnswerCrud.list({
                filter: {
                    is_active: true,
                    class_visit_id: this.props.classVisitId
                }
            })

            this.setState({
                aspects,
                answers
            })

        } catch (e) {
            app.showErrorFromJsonResult(e);
        }
    }

    private onSetValue(aspectId: number, property: string, value: string | boolean) {
        const answer = this.state.answers.find(ans => aspectId == ans.kip_aspect_id);
        if (answer) {
            answer[property] = value;
        } else {
            this.state.answers.push({
                class_visit_id: this.props.classVisitId,
                kip_aspect_id: aspectId,
                [property]: value,

            });
        }

        this.setState({ answers: this.state.answers });
    }

    private async onSave() {
        try {

            for (const answer of this.state.answers) {
                await new KipAspectAnswerCrud(answer).put();
            }

            app.showSuccess("Szempontsor lementve", "Szempontsor lementve");

            this.props.onClose();

        } catch (e) {
            app.showErrorFromJsonResult(e);
        }
    }

    render() {
        const canEdit = me && hasAnyGroup(me, [Groups.Admin, Groups.KapJtrSupport]) && (this.props.stationId == DfhtDocumentUpload);

        return <Dialog title="Szempontsor" width={1000} onClose={this.props.onClose}>
            <DialogContent>
                <div className="row">
                    {
                        this.state.aspects.map((aspect, index) => {
                            const answer = this.state.answers.find(ans => aspect.id == ans.kip_aspect_id);

                            return aspect.kip_aspect_type_id === KIP_ASPECT_TYPE_BOOLEAN_ID
                                ?
                                <React.Fragment key={index}>
                                    <div className="column small-8" style={{ borderBottom: "1px solid gray" }}>
                                        {aspect.title}
                                    </div>
                                    <div className="column small-4" style={{ display: "flex", alignItems: "center", borderBottom: "1px solid gray" }}>
                                        <label style={{ margin: "0 0.5em" }}>
                                            <input disabled={!canEdit} type="radio" checked={answer && answer.boolean_value === true} onChange={() => this.onSetValue(aspect.id!, "boolean_value", true)} /> Igen
                                        </label>
                                        <label style={{ margin: "0 0.5em" }}>
                                            <input disabled={!canEdit} type="radio" checked={answer && answer.boolean_value === false} onChange={() => this.onSetValue(aspect.id!, "boolean_value", false)} /> Nem
                                        </label>
                                    </div>
                                </React.Fragment>
                                :
                                <React.Fragment key={index}>
                                    <div className="column small-12">
                                        {aspect.title}
                                    </div>
                                    <div className="column small-12" style={{ borderBottom: "1px solid gray" }}>
                                        <textarea
                                            disabled={!canEdit}
                                            value={answer && answer.text_value ? answer.text_value : ""}
                                            onChange={e => this.onSetValue(aspect.id!, "text_value", e.target.value)} />
                                    </div>
                                </React.Fragment>

                        })
                    }
                </div>
            </DialogContent>
            <DialogActions>
                {
                    canEdit &&
                    <button className="button" onClick={this.onSave.bind(this)}>
                        <i className="fa fa-save" /> Mentés
                    </button>
                }
            </DialogActions>
        </Dialog>;
    }

}
