import * as React from 'react';
import { IExerciseRecord } from '@src/framework/crud/exc/ExerciseCrud';

import { Dialog } from '@src/component/Dialog';
import ExerciseList from '@src/component/exercise/Viewer/ExerciseList';
import { Section } from '@src/server/EditorServer';
import LessonEditor from './LessonEditor';
import { app } from '@src/index';
import * as ExerciseContainerComponent from '@src/component/exercise/ExerciseContainerComponent';
import { ExerciseServer } from '@src/component/exercise/ExerciseServer';
import ViewExcExercise, { IViewExcExerciseRecord } from '@src/framework/view/exc/ViewExcExercise';
import { PATH_EXERCISE_EDIT, PATH_EXERCISE_VIEW } from '@src/Routes';
import { Link } from 'react-router-dom';
import { SectionOptionsEditor } from '@src/component/book/editor/SectionOptionsEditor';
import { LIBRARY_OFI_OFFICIAL_ID } from '@src/Const';
import { __ } from '@src/translation';

type SectionExerciseEditorProps = {
  lessonEditor: LessonEditor;
  templateVariableId: string;
  section: Section;
  gradeId?: number;
  subjectId?: number;
}

type SectionExerciseEditorState = {
  dialogOpen: boolean;
  exerciseRecord?: IViewExcExerciseRecord;
}

type SectionExerciseEditorConfig = {
  disableExerciseListFilter?: boolean
}

let pageConfig: SectionExerciseEditorConfig = {};

export function configureSectionExerciseEditor(newConfig: SectionExerciseEditorConfig) {
  pageConfig = newConfig;
}

export default class SectionExerciseEditor extends React.Component<SectionExerciseEditorProps, SectionExerciseEditorState> {

    constructor(props: SectionExerciseEditorProps) {
      super(props);
  
      this.state = {
        dialogOpen: false
      };

    }

    componentDidMount() {
      this.reloadAsync();
    }

    componentDidUpdate() {
      this.reloadAsync();
    }

    async reloadAsync() {
      const exerciseSeriesId = this.props.section.values[this.props.templateVariableId];

      if (exerciseSeriesId && (!this.state.exerciseRecord || this.state.exerciseRecord.id != exerciseSeriesId)) {
        
        const exerciseRecord = (await ViewExcExercise.load(exerciseSeriesId)).record;

        this.setState({exerciseRecord});
      }
    }
  
    onOpenExerciseDialog() {
      this.setState({dialogOpen: !this.state.dialogOpen});
    }
  
    async onExerciseSelected(exerciseRecord: IExerciseRecord) {
      if (exerciseRecord.id && exerciseRecord.engine_id) {
        this.props.lessonEditor.handleEditorChange(this.props.section.id, this.props.templateVariableId, exerciseRecord.id.toString(), exerciseRecord.id);
      } else {
        app.showError(__("Hiba!"), __("A rekordnak nincs azonosítója!"));
        return;
      }

      this.setState({dialogOpen: false}, this.reloadAsync);
    }

    render() {
      const exerciseId = Number(this.props.section.values[this.props.templateVariableId]);
      const exercise = this.state.exerciseRecord;

      const server = new ExerciseServer(false);
      server.setExerciseId(exerciseId);

      return <div className="row">
          <div className="column" >
            
            <button className="button expanded" onClick={this.onOpenExerciseDialog.bind(this)}><i className="fa fa-check-square-o"/> {__("Feladat kiválasztása")}</button>
            { this.state.dialogOpen ?
              <Dialog title={__("Feladat kiválasztása")} onClose={this.onOpenExerciseDialog.bind(this)}>
                <ExerciseList 
                  onExerciseSelected={this.onExerciseSelected.bind(this)} 
                  grade_id={ pageConfig.disableExerciseListFilter ? undefined : this.props.gradeId } 
                  subject_id={ pageConfig.disableExerciseListFilter ? undefined : this.props.subjectId } 
                  library_id={ LIBRARY_OFI_OFFICIAL_ID }
                />
              </Dialog>
              : ""
            }
            {
              exercise
              ? 
              <div>
                  {
                    exercise
                    ?
                    <div>
                      <span> #{exercise.id}
                          &nbsp;
                          <Link title={__("Feladat szerkesztő megnyitása (szerkesztőségi verzió)")}  to={PATH_EXERCISE_EDIT + "/" + exercise.id}><i className="fa fa-edit"/></Link> 
                          &nbsp;
                          <Link title={__("Feladat megnyitása (szerkesztőségi verzió)")}  to={PATH_EXERCISE_VIEW + "/" + exercise.id}><i className="fa fa-eye"/></Link> 
                          &nbsp;
                      </span>
                      / <span> {exercise.name} </span>
                      / <span> {exercise.grade_name} </span>
                      / <span> {exercise.subject_name} </span>
                      / <span> {exercise.owner_person_fullname} </span>
                      / <span> {exercise.creation_time} </span>
                    </div>
                    :
                    ""
                  }
                  <ExerciseContainerComponent.default ref="container" key={exercise.id} server={server} />
                  
                </div>
              : 
              <div className="callout alert">{__("Kérem válasszon feladatot")} </div>
            }
          </div>

          
          <SectionOptionsEditor
            lessonEditor={this.props.lessonEditor}
            section={this.props.section}
            templateVariableId={this.props.templateVariableId + "-options"}
            enableIcons={false}
            enableAspectRatio={false}
            enableExerciseCheckboxes={true}
          />
          
        </div>
    }
}

