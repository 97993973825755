import * as React from 'react';
import { debounce } from 'lodash';
import { app } from '@src/index';
import 'react-table/react-table.css'
import TableInfoCrud from '@src/framework/crud/meta/TableInfoCrud';
import LookupEdit from '@src/framework/forms/lookupedit';
import PermissionAuditViewByView from './sys_perm_audit_view_by_view';
import ViewInfoCrud from '@src/framework/crud/meta/ViewInfoCrud';
import { __ } from '@src/translation';

interface IPermissionAuditByViewProps {

}

interface IPermissionAuditByViewState {
    loading: boolean,
    viewInfoId : number|null;
}

export default class PermissionAuditByView extends React.Component<IPermissionAuditByViewProps, IPermissionAuditByViewState> {
    private debouncedAsyncReload: () => void;

    constructor(props: IPermissionAuditByViewProps) {
        super(props);
        this.state = {
            loading: true,
            viewInfoId: null
        };
        this.debouncedAsyncReload = debounce(() => this.asyncReload(), 300);
    }

    componentDidMount() {
        this.debouncedAsyncReload();
    }

    private asyncReload = async () => {
        try {
            this.setState({ loading: true });
            // ???
            this.setState({ loading: false });
        } catch (error) {
            app.showErrorFromJsonResult(error);
        }
    }

    private onViewInfoIdChange = (viewInfoId: number | null) => {
        this.setState({ viewInfoId: viewInfoId }, this.debouncedAsyncReload);
    }
    
    private getReport = () => {
        return <PermissionAuditViewByView viewInfoId={this.state.viewInfoId!} />;
    }

    render() {
        return <div className="row expanded">
            <div className="column small-12 large-12">
                <p className="callout primary">
                    {__("Ezen a lapon egy adott nézet táblázatra jogosult felhasználókat")}
                    {__("tudja elemezni.") + " "}<b>{__("Ez nem tartalmazza a vendég (Guest) csoportnak")}
                        {__("megadott jogokat!")}</b>
                </p>
            </div>
            <div className="column small-12 large-12">

                <label>{__("Nézet táblázat (view):")}
                            <LookupEdit
                                key={"viewInfoId_" + (this.state.viewInfoId || 'null')}
                                name="viewInfoId"
                                fk_table_info_id={ViewInfoCrud.TABLE_INFO_ID}
                                clearable={false}
                                value={this.state.viewInfoId!}
                                onChange={(newValue) => this.onViewInfoIdChange(Number(newValue))}
                            />
                </label>
            </div>
            <div className="column small-12 large-12">
                {this.state.viewInfoId
                    ?this.getReport()
                    :<p className="callout alert">
                        {__("Válasszon ki egy nézet táblázatot az adatok elemzéséhez.")}
                    </p>
                }
            </div>
        </div>
    }

}