import * as React from 'react'
import { BubbleLoader } from 'react-css-loaders';
import { SearchResult } from '@src/server/PublicServer';
import * as Server from '@src/server/PublicServer'
import { getOfferDivs } from '@src/component/media/GetOffers';
import * as queryString from 'query-string';
import { __ } from '@src/translation';
import { History, Location } from 'history';

type PopularMediaState = {
    loading: boolean;
    popularVideos: SearchResult[];
    popularVideosBlock:any;
    popularImages: SearchResult[];
    popularImagesBlock:any;
    popularMaps: SearchResult[];
    popularMapsBlock:any;
    soundOffers:SearchResult[];
    soundOffersBlock:any;
    popularIntExercises: SearchResult[];
    popularIntExercisesBlock:any
}
type MediaPopularsProps = {
    location: Location;
    history: History;
}
export class MediaPopulars extends React.Component<MediaPopularsProps, PopularMediaState> {

    constructor(prop: any) {
        super(prop);

        this.state = {
            loading: false,
            popularVideos: [],
            soundOffers:[],
            popularImages: [],
            popularMaps: [],
            popularIntExercises: [],
            popularVideosBlock:undefined,
            popularImagesBlock:undefined,
            popularIntExercisesBlock:undefined,
            popularMapsBlock:undefined,
            soundOffersBlock:undefined
        };
    }

    componentDidMount() {
        this.reloadOffersAsync();
    }

    async reloadOffersAsync() {
        this.setState({ loading: true });    

            let searchParams: Server.SearchParameters = {};

            const params = queryString.parse(this.props.location.search);

            if(params.grade_id)
            {
                searchParams.grade_id=params.grade_id;
            }
            if(params.subject_id)
            {
                searchParams.subject_id=params.subject_id;
            }

            try {
                searchParams.type = "video";
                var videoOffers = await Server.search(searchParams);
                videoOffers = videoOffers.filter(e => e.displayname);
                this.setState({ popularVideos: videoOffers.slice(0, 8) });
            } catch (e) {/*Nincs elasticsearch index*/ }

            try {

                searchParams.type = "image";
                var imageOffers = await Server.search(searchParams);
                // imageOffers = imageOffers.filter(e => e.displayname);
                this.setState({ popularImages: imageOffers.slice(0, 8) });
            } catch (e) {/*Nincs elasticsearch index*/ }

            try {

                searchParams.type = "map";
                var mapOffers = await Server.search(searchParams);
                mapOffers = mapOffers.filter(e => e.displayname);
                this.setState({ popularMaps: mapOffers.slice(0, 8) });
            } catch (e) {/*Nincs elasticsearch index*/ }
            try {
                searchParams.type ="audio";
                var soundOffers = await Server.search(searchParams);
                soundOffers = soundOffers.filter(e => e.displayname);           
                this.setState({soundOffers: soundOffers.slice(0, 8)});
            } catch(e) {/*Nincs elasticsearch index*/}

            try {

                searchParams.type = "interactive_exercise";
                var interactiveExerciseOffers = await Server.search(searchParams);
                interactiveExerciseOffers = interactiveExerciseOffers.filter(e => e.displayname);
                this.setState({ popularIntExercises: interactiveExerciseOffers.slice(0, 7) })
            } catch (e) {/*Nincs elasticsearch index*/ }  

            let popularVideosBlock = (!params.type || params.type == "media" || params.type == "video") &&await getOfferDivs(this.props.history, this.props.location, __("Ajánlott videók"), "video", this.state.popularVideos);
            //let popularImagesBlock = (!params.type || params.type == "media" || params.type == "image") &&await getOfferDivs(this.props.history, this.props.location, __("Ajánlott képek"), "image", this.state.popularImages);
            let popularMapsBlock= (!params.type || params.type == "media" || params.type == "map") &&await getOfferDivs(this.props.history, this.props.location, __("Ajánlott térképek"), "video", this.state.popularMaps)
            let popularIntExercisesBlock = (!params.type || params.type == "media" || params.type == "interactive_exercise") &&await getOfferDivs(this.props.history, this.props.location, __("Ajánlott interaktív feladatok"), "video", this.state.popularIntExercises);
            let soundOffersBlock= (!params.type || params.type == "media" || params.type == "audio") &&await getOfferDivs(this.props.history, this.props.location, __("Ajánlott hanganyagok"), "audio", this.state.soundOffers);
             
        this.setState({

            loading: false,
            popularVideosBlock,
            //popularImagesBlock,
            popularMapsBlock,
            popularIntExercisesBlock,
            soundOffersBlock,
        });
    }

    render() {
        if (this.state.loading) {
            return <div className="row"><BubbleLoader /></div>;
        }
        const params = queryString.parse(this.props.location.search);
        return <div className="row">
            <div className="column small-12">
                <h3 className="eke-video-videoplayer-graytitle">{__("Legnépszerűbb tartalmak")}</h3>
                {(!params.type || params.type == "media" || params.type == "video") &&this.state.popularVideosBlock}
                {/* {(!params.type || params.type == "media" || params.type == "image") &&this.state.popularImagesBlock} */}
                {(!params.type || params.type == "media" || params.type == "map") && this.state.popularMapsBlock}
                {(!params.type || params.type == "media" || params.type == "audio") &&this.state.soundOffersBlock}
            </div>
        </div>;
    }
}
