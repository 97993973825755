import { Server } from '../../server/Server';
import Crud , { registerCrudClassProxy, IAsTextResult, IListParams, CrudClassProxy, ISearchTextParams, ISearchTextResult } from '../Crud';

export interface ITrainingPermitRecord {
    /**
     * Globálisan egyedi azonosító.
     * 
     * Ha új rekordot akarsz létrehozni put() metódussal, akkor hagyd üresen.
     * upsert() hívásnál figyelmen kívül van hagyva az értéke.
     *
     */
    id ?: number;
    /**
     * Rekord létrehozás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel létrehozásakor.
     */
    creation_time?: string /*timestamp?*/;
    /**
     * Ki hozta létre
     *
     * NOT NULL -> sys.sec_user ON DELETE noaction
     *
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozó felhasználóra hivatkozik.
     */
    creation_user_id?: number;
    /**
     * Melyik session-ből jött létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozáshoz használt munkamenetre hivatkozik.
     */
    creation_session_id?: number|null;
    /**
     * Utolsó módosítás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel módosításakor.
     */
    modification_time?: string /*timestamp?*/|null;
    /**
     * Ki módosította utoljára
     *
     * -> sys.sec_user ON DELETE noaction
     *
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosító felhasználóra hivatkozik.
     */
    modification_user_id?: number|null;
    /**
     * Melyik session-ből módosították utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosításhoz használt munkamenetre hivatkozik.
     */
    modification_session_id?: number|null;
    /**
     * Aktív
     *
     * Logikai törléshez használt mező.
     */
    is_active?: boolean;
    /**
     * Hallgató
     *
     * NOT NULL -> sys.sec_user ON DELETE noaction
     *
     *
     * KAP Képzési Rendszer hallgatója
     */
    sec_user_id?: number;
    /**
     * A képzési engedélyhez szükséges dokumentumok gyökér könyvtára.
     *
     * NOT NULL -> media.oo_folder ON DELETE noaction
     *
     */
    oo_folder_id?: number;
}

/**
 *  kap.training_permit - KAP Képzési engedély
 *
 *  Idősoros táblázat, ami az összes tétel összes korábbi verzióját eltárolja.
 */

export default class TrainingPermitCrud extends Crud<ITrainingPermitRecord> {
    public static TABLE_INFO_ID = 763375635;
    public static getSchemaNameForClass() { return 'kap'; }
    public static getTableNameForClass() { return 'training_permit'; }

    public static load: (id: number, aServer ?: Server) => Promise<TrainingPermitCrud>;
    public static asText: (id: number, includeRecord : boolean, aServer ?: Server) => Promise<IAsTextResult<ITrainingPermitRecord>>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<ITrainingPermitRecord[]>;
    public static searchText: (params: ISearchTextParams, aServer ?: Server) => Promise<ISearchTextResult<ITrainingPermitRecord>[]>;
    public static deleteById: (id: number, aServer ?: Server) => Promise<ITrainingPermitRecord>;
    public static unDeleteById: (id: number, aServer ?: Server) => Promise<ITrainingPermitRecord>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class TrainingPermitCrudClassProxy extends CrudClassProxy<ITrainingPermitRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const trainingPermitCrudClassProxy = new TrainingPermitCrudClassProxy(TrainingPermitCrud);
registerCrudClassProxy(trainingPermitCrudClassProxy);
