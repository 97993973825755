import * as React from 'react';

import { Link, match } from 'react-router-dom';
import { app } from '@src/index';
import { BubbleLoader } from 'react-css-loaders';
import * as PublicServer from '@src/server/PublicServer'
import * as queryString from 'query-string';
import { TFilterDict } from '@src/framework/crud/Crud';
import { PATH_EXERCISE_SERIES_PUB_VIEW, PATH_EXERCISE_PUB_VIEW } from '@src/Routes';
import * as _ from 'lodash';
import ExerciseCrud from '@src/framework/crud/exc_pub/ExerciseCrud';
import { getContentDetails, ContentDetails } from '../filemanager/ContentUtils';
import ExerciseSeriesCrud from '@src/framework/crud/exc_pub/ExerciseSeriesCrud';
import { DetailImage } from'@src/component/progression/ProgressionPage';
import { __ } from '@src/translation';

declare var MathJax:any;

var saved = {
    filters: [],
    sorted: [],
    pageSize: 10,
    page: 0,
}

interface ProgressionTileProps {
    settings: any;
}

class ProgressionPageState {
    progressionStats?: PublicServer.ProgressionElements;
    exeAndSeriesItems: any[];
    filters: any[];
    pageSize: number;
    page: number;
    count: number;
    sorted: any[];
    loading:boolean;
  }

export default class ProgressionTile extends React.Component<ProgressionTileProps, ProgressionPageState> {

    constructor(props: any) {
        super(props);
        this.state = {
         loading:false,
         exeAndSeriesItems:[],
         pageSize: saved.pageSize,
         page: saved.page,
         count: 0,
         sorted: saved.sorted,
         filters: saved.filters,
        };
    }

    componentDidUpdate()
    {
      try {
        MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
    } catch (e) {
        console.log(e);
    }
    }
    componentDidMount() {
      this.asyncReload();
      ($(document) as any).foundation();
    }
    private asyncReload = async () => {
  
      try {
        this.setState({
          loading: true
      })
     
        let progressionStats = await PublicServer.getProgressionStats();
           let exeAndSeriesItems=[];
        if(progressionStats)
        {
           exeAndSeriesItems = progressionStats.exercises.concat(progressionStats.exerciseSeries);
        }
       
        for (var exeItem of exeAndSeriesItems) {
  
          const url = "/tankonyv/" + exeItem.bookurisegment + "/" + exeItem.lessonurisegment + "#section-" + exeItem.sectionid;
          let typeName=""
          let baseUrl=""
          if(exeItem.exerciseid)
          {
            typeName="[Feladat]";
            baseUrl=PATH_EXERCISE_PUB_VIEW+"/"+exeItem.exerciseid+"/";
  
          }
          if(exeItem.exerciseseriesid)
          {
            typeName="[Feladatsor]";
            baseUrl=PATH_EXERCISE_SERIES_PUB_VIEW+"/"+exeItem.exerciseseriesid+"/";
          }
          exeItem["exName"] = exeItem.exname;
          exeItem["grade_id"] = exeItem.grade_id;
          exeItem["bookName"] = exeItem.bookname;
          exeItem["typeName"] =typeName;
          exeItem["baseUrl"] = baseUrl;
          exeItem["bookUrl"] = <Link to={url} title={exeItem.bookName+" / "+exeItem.chaptername+" / "+exeItem.lessonname+" / "+exeItem.exname}>{exeItem.bookName+" / "+exeItem.chaptername+" / "+exeItem.lessonname+" / "+exeItem.exname}</Link>
        }
  
        this.setState({
            exeAndSeriesItems,
            count:progressionStats?progressionStats.exerciseSeriesCount+progressionStats.exerciseCount:0,
            loading: false
        });
  
      } catch (error) {
        app.showErrorFromJsonResult(error);
      }
  
    }

    render() {
        const groupedRecords =_.groupBy(this.state.exeAndSeriesItems,"bookname");
        return <div className="progression-tile">

                    {
                        (this.state.exeAndSeriesItems &&!this.state.loading) 
                        ?
                        <>
                        {
                            Object.keys(groupedRecords).length === 0 &&
                            <div className="callout">
                            <h5>{__("Eddig még egy feladatot sem oldottál meg az okostankönyvekben.")}</h5>
                            <p>{__("Ahhoz, hogy itt adatok jelenjenek meg, legalább egy feladatot szükséges megoldanod.")}</p>
                            </div>
                        }
                        {                
                                Object.keys(groupedRecords).map((bookName, index) => {
                                const values = groupedRecords[bookName];
                                const title = bookName + (values.length ? " " + __("( {n} feladat )", {n: values.length}):"");
                                
                                return <div key={index}>
                                        {
                                            values.map(e => 
                                            { let recid;
                                            let tableinfo:number;                                                 
                                            if(e.exerciseid){recid=e.exerciseid; tableinfo=ExerciseCrud.TABLE_INFO_ID}
                                            if(e.exerciseseriesid){recid=e.exerciseseriesid;tableinfo = ExerciseSeriesCrud.TABLE_INFO_ID}
                                                
                                            return <div className="row expanded">
                                                    {/* <div className="column small-1">
                                                        <DetailImage tableInfoId={tableinfo!} recId={recid}/>
                                                    </div> */}
                                                    <div className="column book-source">
                                                        {e.bookUrl}                                                          
                                                    </div>
                                                    <div className="column">                                 
                                                        {<Link to={e.baseUrl}>{e.exName}</Link>}
                                                    </div>
                                                    <div className="column">   
                                                        {/* <p style={{fontWeight:"bold"}}> Eredmény:</p> */}
                                                        <div className="row expanded">                                                                                         
                                                            <div className="column">  
                                                                <p style={{fontSize:"1rem"}}> {e.best_total_points +"/"+ (e.best_earned_points).toFixed(2) +" pont"}</p>
                                                            </div>
                                                            <div className="column">                                                            
                                                                <span className="badge"style={{fontSize:"1rem"}}> {((e.best_earned_points/e.best_total_points)*100).toFixed(2)+"%"}</span>
                                                            </div>
                                                        </div>  
                                                    </div>
                                                    <hr/>                                                     
                                                </div>       
                                            }                                            
                                            )
                                        }                                      
                                    </div>
                            })
                        }
                        </>
                        
                        :

                        <div><BubbleLoader /></div>
                    }

            </div>
        }

}
