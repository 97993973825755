import { Server } from '../../server/Server';
import Crud , { registerCrudClassProxy, IAsTextResult, IListParams, CrudClassProxy, ISearchTextParams, ISearchTextResult } from '../Crud';

export interface ITrainingTypeRecord {
    /**
     * Globálisan egyedi azonosító.
     * 
     * Ha új rekordot akarsz létrehozni put() metódussal, akkor hagyd üresen.
     * upsert() hívásnál figyelmen kívül van hagyva az értéke.
     *
     */
    id ?: number;
    /**
     * Rekord létrehozás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel létrehozásakor.
     */
    creation_time?: string /*timestamp?*/;
    /**
     * Ki hozta létre
     *
     * NOT NULL -> sys.sec_user ON DELETE noaction
     *
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozó felhasználóra hivatkozik.
     */
    creation_user_id?: number;
    /**
     * Melyik session-ből jött létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozáshoz használt munkamenetre hivatkozik.
     */
    creation_session_id?: number|null;
    /**
     * Utolsó módosítás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel módosításakor.
     */
    modification_time?: string /*timestamp?*/|null;
    /**
     * Ki módosította utoljára
     *
     * -> sys.sec_user ON DELETE noaction
     *
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosító felhasználóra hivatkozik.
     */
    modification_user_id?: number|null;
    /**
     * Melyik session-ből módosították utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosításhoz használt munkamenetre hivatkozik.
     */
    modification_session_id?: number|null;
    /**
     * Aktív
     *
     * Logikai törléshez használt mező.
     */
    is_active?: boolean;
    /**
     * Kód
     */
    code?: string;
    /**
     * Cím
     */
    title?: string;
    /**
     * Engedélyzám
     *
     * Akkreditációs engedély száma
     */
    license_number?: string|null;
    /**
     * A képzés elvégzése melyik csoport tagjává tesz
     *
     * -> sys.sec_group ON DELETE cascade
     *
     */
    sec_group_id?: number|null;
    /**
     * Melyik alprogramhoz tartozik a képzés
     *
     * -> kap.subprogram ON DELETE cascade
     *
     */
    subprogram_id?: number|null;
    /**
     * Leírás
     */
    description_html?: string|null;
    /**
     * Könyv
     *
     * -> doc.book ON DELETE noaction
     *
     */
    book_id?: number|null;
    /**
     * Melyik csoport jogosít föl ilyen típusú képzésre
     *
     * -> sys.sec_group ON DELETE cascade
     *
     */
    trainer_group_id?: number|null;
    /**
     * Tanúsítványszám prefix
     */
    certno_prefix?: string;
    /**
     * Igazolás sorszám prefix
     */
    verno_prefix?: string;
    /**
     * Kimeneti követelmények
     */
    fulfillment_conditions_html?: string|null;
    /**
     * Mappa
     *
     * NOT NULL -> media.oo_folder ON DELETE noaction
     *
     *
     * A képzés típussal kapcsolatos dokumentumok vannak itt tárolva.
     */
    oo_folder_id?: number;
    /**
     * Online képzés
     */
    is_online?: boolean;
    /**
     * Sablon kód
     *
     * PDF generálásokhoz.
     */
    template_code?: string;
    /**
     * Sorrend
     */
    no?: number /*integer*/;
    /**
     * Site
     *
     * NOT NULL -> cms.site ON DELETE noaction
     *
     */
    site_id?: number;
}

/**
 *  kap.training_type - Képzés típus
 *
 *  Idősoros táblázat, ami az összes tétel összes korábbi verzióját eltárolja.
 */

export default class TrainingTypeCrud extends Crud<ITrainingTypeRecord> {
    public static TABLE_INFO_ID = 339349411;
    public static getSchemaNameForClass() { return 'kap'; }
    public static getTableNameForClass() { return 'training_type'; }

    public static load: (id: number, aServer ?: Server) => Promise<TrainingTypeCrud>;
    public static asText: (id: number, includeRecord : boolean, aServer ?: Server) => Promise<IAsTextResult<ITrainingTypeRecord>>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<ITrainingTypeRecord[]>;
    public static searchText: (params: ISearchTextParams, aServer ?: Server) => Promise<ISearchTextResult<ITrainingTypeRecord>[]>;
    public static deleteById: (id: number, aServer ?: Server) => Promise<ITrainingTypeRecord>;
    public static unDeleteById: (id: number, aServer ?: Server) => Promise<ITrainingTypeRecord>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class TrainingTypeCrudClassProxy extends CrudClassProxy<ITrainingTypeRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const trainingTypeCrudClassProxy = new TrainingTypeCrudClassProxy(TrainingTypeCrud);
registerCrudClassProxy(trainingTypeCrudClassProxy);
