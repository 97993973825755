import * as React from "react";
import { IViewExcExerciseRecord } from "@src/framework/view/exc/ViewExcExercise";
import { PATH_EXERCISE_EDIT } from "@src/Routes";
import { Link } from "react-router-dom";
import { Groups, me, hasAnyGroup } from "@src/framework/server/Auth";
import EngineCRUD, { IEngineRecord, engineCrudClassProxy } from '@crud/exc/EngineCrud';
import * as ExerciseTypeConverter from "../models/ExerciseTypeConverter";
import { AExerciseTypeConverter, StatisticsResponse } from '@src/component/exercise/models/AExerciseTypeConverter';
import { any } from "prop-types";
import { BubbleLoader } from 'react-css-loaders';
import ExerciseCrud, { IExerciseRecord } from "@src/framework/crud/exc/ExerciseCrud";
import CrudSelect from '@framework/forms//crudselect';
import ReactTable from 'react-table';
import ViewExcExercise from "@src/framework/view/exc/ViewExcExercise";
import { __ } from '@src/translation';


type OFIInvalidExercisesListState = {
    wrong_exercises: IViewExcExerciseRecord[];
    total_exc_number: number;
    isLoading: boolean;
    statistics: StatisticsResponse[];
    engineId?: number;
}

export class OFIInvalidExercisesList extends React.Component<any, OFIInvalidExercisesListState> {

    private TypeConverter: AExerciseTypeConverter;
    //static wf_station_id: number = 6004788335;

    constructor(props: any) {
        super(props);
        this.state = {
            wrong_exercises: [],
            isLoading: false,
            total_exc_number: 0,
            statistics: []
        }
    }

    componentDidMount() {
        this.reloadAsync();
    }

    async reloadAsync() {

    }

    private onEngineSelect = (sender: CrudSelect, newEngine: number | null): void => {
        const engine: any = newEngine;
        this.setState({ engineId: engine });
    }

    async onGetExerciseErrors() {
        this.setState({ isLoading: true });
        if (!this.state.engineId) return;
        let curr_engine = (await EngineCRUD.list({})).find((eng) => { return eng.id == this.state.engineId });
        var convertedExercises: any[] = [];
        let exerciseList: IExerciseRecord[] = await ExerciseCrud.list({ filter: { engine_id: curr_engine!.id, is_active: true }, limit: 10000 });
        // let listCopy: any[] = [];
        let exerciseViewList: any[] = [];
        let jsonArray:any[] = [];
        if (curr_engine) {
            let engineName = curr_engine.class_name;
            let curr_converter = ExerciseTypeConverter.TypeConverterAssinment.Converter.find((element: ExerciseTypeConverter.ExerciseEngineInfo) => element.EngineName == engineName);
            if (curr_converter) {
                this.TypeConverter = curr_converter.TypeConverter;
                let curr_exercise: IExerciseRecord;

                for (let i = 0; i < exerciseList.length; i++) {
                    let customListItem = {
                        exercise: {},
                        message: "",
                    }
                    curr_exercise = exerciseList[i];
                    if (curr_exercise && curr_exercise.exercise) {
                        curr_exercise.exercise.solution = curr_exercise.solution.solution;
                        try {
                            let new_ex_data = this.TypeConverter.convertToEditorAnswer(curr_exercise.exercise);
                            convertedExercises.push(new_ex_data);
                            let exerciseValidResult = this.TypeConverter.validate(new_ex_data, curr_exercise.exercise, undefined);
                            if (!exerciseValidResult.valid) {
                                customListItem.exercise = curr_exercise;
                                customListItem.message = exerciseValidResult.message ? exerciseValidResult.message : "";
                                let currViewList = await ViewExcExercise.list({ filter: { id: curr_exercise.id } });
                                exerciseViewList.push({ exercise: currViewList[0], message: customListItem.message });
                                jsonArray.push({id:currViewList[0].id, title:currViewList[0].name, owner:currViewList[0].owner_person_fullname, message:customListItem.message});
                            }
                        } catch (error) {
                            console.log("Error: ", error, "exercise: ", curr_exercise, "index: ", i);
                            //exerciseViewList.push({ exercise: curr_exercise, message: "Sikertelen konvertálás: " + error.message })
                            let currViewList = await ViewExcExercise.list({ filter: { id: curr_exercise.id } });
                            exerciseViewList.push({ exercise: currViewList[0], message:  __("Sikertelen konvertálás: {details}", {details: error.message}) });
                            jsonArray.push({id:currViewList[0].id, title:currViewList[0].name, owner:currViewList[0].owner_person_fullname, message:__("Sikertelen konvertálás: {details}", {details: error.message})});
                        }                        
                    }
                }
            }
        }

        console.log("ex list:", JSON.stringify(jsonArray));
        let stats: StatisticsResponse[] = [];
        if (convertedExercises && convertedExercises.length != 0) stats = this.TypeConverter.getExerciseStatistics(convertedExercises);


        /*for (let index = 0; index < listCopy.length; index++) {
                let currViewList = await ViewExcExercise.list({ filter: { id: listCopy[index].exercise.id } });
                exerciseViewList.push({exercise:currViewList[0], message:listCopy[index].message});
        }*/

        this.setState({ wrong_exercises: exerciseViewList, isLoading: false, total_exc_number: exerciseList.length, statistics: stats });
    }

    render() {

        const tableProps = {
            Header: __('Cím'),
            columns: [
                {
                    Header: __('Szám'),
                    accessor: "key",
                    filterable: false
                },
                {
                    Header: __('Darab'),
                    accessor: "value",
                    filterable: false
                }
            ],
            showPagination: false
        }

        const exeTableProps = {
            columns: [
                {
                    Header: __('Feladat'),
                    accessor: "exercise.name",
                    filterable: false,
                    Cell: (data: any, column: any) => {
                        const row: any = data.original;
                        return <Link to={PATH_EXERCISE_EDIT + "/" + row.exercise.id}>{row.exercise.name}</Link>
                    }
                },
                {
                    Header: __('Létrehozó'),
                    accessor: "exercise.owner_person_fullname",
                    filterable: true
                },
                {
                    Header: __('Hiba leírása'),
                    accessor: "message",
                    filterable: false
                }
            ],
            showPagination: false
        }

        return <div>
            {
                (me && hasAnyGroup(me, [Groups.Developer, Groups.OFIEditor])) ?
                    <div className="row">
                        <div className="column">
                            <CrudSelect
                                value={this.state.engineId || null}
                                onSelect={this.onEngineSelect}
                                displayFieldName="name"
                                orderByFieldName="name"
                                key="id"
                                emptyTitle={__("Válasszon feladattípust!")}
                                clearable={true}
                                crudClassProxy={engineCrudClassProxy}
                                filter={{ is_active: true }}
                                sortFunc={(a, b) => a.name.localeCompare(b.name, undefined, { numeric: true, sensitivity: 'base' })}
                            />
                        </div>
                        <div className="column">
                            <button className="button success" onClick={this.onGetExerciseErrors.bind(this)}>{__("Lekérdezés")}</button>
                        </div>
                    </div>
                    : ""
            }
            <div className="column small-12">
                {this.state.total_exc_number != 0 && this.state.wrong_exercises.length != 0 ?
                    <label>{this.state.total_exc_number} {__("db. feladatból")} {this.state.wrong_exercises.length} {__("db. nem felel meg a jelenlegi validációnknak.")}</label>
                    : ""}
                {
                    this.state.isLoading ? <BubbleLoader /> :

                        this.state.wrong_exercises.length == 0 ? __("Nincs hibásan felvett feladat.") :
                            <ReactTable data={this.state.wrong_exercises} {...exeTableProps} defaultPageSize={this.state.wrong_exercises.length} />
                }

                <div className="row">

                    {this.state.statistics.length != 0 ?
                        this.state.statistics.map((curr_stat: any, i: number) => {
                            if (curr_stat.count) {
                                let keys = Array.from(curr_stat.count.keys());
                                let tableData: any[] = [];
                                keys.forEach(el => {
                                    tableData.push({ key: el, value: curr_stat.count.get(el) });
                                });
                                tableData.sort((a, b) => { return b.key - a.key; })
                                return <div key={i} className="column small-12 medium-4 large-3">
                                    <label style={{ fontWeight: 'bold' }}>{curr_stat.name}</label>
                                    <ReactTable data={tableData} {...tableProps} defaultPageSize={tableData.length} />
                                </div>
                            }
                            else {
                                return <div className="columns large-12"> <span>{curr_stat.name}</span></div>
                            }
                        })
                        : ""}
                </div>
            </div>
        </div>
    }

}