import * as ExerciseBaseTypes from "@src/component/exercise/models/ExerciseBaseClass";
import { AExerciseTypeConverter, StatisticsResponse, ValidationResponse } from "@src/component/exercise/models/AExerciseTypeConverter";
import * as React from "react";
import ExerciseFileSelect, { ExerciseFileTypes } from "@src/component/exercise/Editor/ExerciseFileSelect";
import { Accordion, ActionAccordionItem, AccordionActionType, IAccordionAction } from '@src/component/ui/Accordion';
import { Panel } from '@src/component/ui/Panel';
import { hasGroup, me, Groups } from "@src/framework/server/Auth";
import { __ } from '@src/translation';
import { Label } from "recharts";

export type ColoringEditorData = {
    imageToFill: string,
}

export class ColoringGameConverter extends AExerciseTypeConverter {
    
    public isGame = true;
    public convertToEditorAnswer(exercise: any) {
        //todo
        //console.log(exercise);
        if (exercise){
            let cur_exercise: ColoringEditorData = {
                imageToFill: exercise.imageToFill
            }
            return cur_exercise;
        }
        return undefined;
    }    
    
    public convertToJson(answerList: any, baseData: ExerciseBaseTypes.ExerciseBaseClass, prevJSON?: any) {

       var cur_exercise = ExerciseBaseTypes.convertToBaseJson(baseData);
       cur_exercise = {
        ...cur_exercise,
        imageToFill:answerList.imageToFill
    
    }
    return cur_exercise;  
    }

    public validate(editorAnswer: any, baseData: any, validationMap?:Map<string,string>, is_accessible?:boolean|null): ValidationResponse {
        let superAnswer: ValidationResponse = super.validate(editorAnswer, baseData,validationMap, is_accessible);
        if (!superAnswer.valid) return superAnswer;    
        //add if condition here, if there is an image(imageToFill) to fill if true is valid is true 
        return { valid: true }
    }

    render() {
        let curr_ex: ColoringEditorData = this.state.exercise as ColoringEditorData;
        return (
            <Panel>
                <h3>{__("Részletek")}</h3>
                {<ExerciseFileSelect
                    imagebasepath={this.props.imagebasepath}
                    value={curr_ex.imageToFill || ""}
                    onChange={this.handleImageChange.bind(this, "imageToFill")}
                    getFolderId={this.getFolderId.bind(this)}
                    fileType={ExerciseFileTypes.Image}
                />}
            </Panel>
        )
    }
}