import * as React from 'react';

import TaskCrud, { ITaskRecord, TaskCrudClassProxy, taskCrudClassProxy } from '@src/framework/crud/data_imp/TaskCrud';
import RecordEditor, { IFieldEditorProp } from '@framework/forms/recordeditor';
// Ezt ne töröld ki mert akkor kioptimizálja a Crud Class Proxy regisztrációt.
import '@src/framework/crud/data_imp/TaskTypeCrud';
import '@src/framework/crud/data_imp/TaskStatusCrud';
import { IFieldInfo } from '@src/framework/crud/Meta';
import MediaFileChooser, { ContentActionType } from '../filemanager/MediaFileChooser';
import { ItemStyle } from '../filemanager/ContentItem';
import { app } from '@src/index';
import { Tabs, TabList, Tab, TabPanel } from 'react-tabs';
import TaskLogListView from './data_imp_task_log_listview';
import { __, getLanguage } from '@src/translation';

/*
nkp=> select id,title from data_imp.task_status  order by 1;
 id  |              title               
-----+----------------------------------
   1 | Előkészítés
   2 | Sorban áll
   3 | Feldolgozás alatt
   4 | Sikeresen feldolgozva
   5 | Feldolgozva, figyelmeztetésekkel
 100 | Sikertelen
(6 rows)
*/
const TASK_STATUS_ELOKESZITES_ID = 1;
const TASK_STATUS_SORBAN_ALL_ID = 2;
const TASK_STATUS_FELDOLGOZAS_ALATT_ID = 3;
const TASK_STATUS_FELDOLGOZVA_ID = 4;
const TASK_STATUS_FIGYELMEZTETES_ID = 5;
const TASK_STATUS_SIKERTELEN_ID = 100;

export default class TaskEditor extends RecordEditor<ITaskRecord> {
    public getCrudClassProxy(): TaskCrudClassProxy { return taskCrudClassProxy; }

    protected getFieldEditor(fProps: IFieldEditorProp): JSX.Element | null {
        if (fProps.fieldName == "task_type_id") {
            return super.getFieldEditor({
                ...fProps,
                forceCrudSelect: true,
                displayFieldName: "title"
            });
        } else if (fProps.fieldName == "task_status_id") {
            return super.getFieldEditor({
                ...fProps,
                forceCrudSelect: true,
                displayFieldName: "title",
                disabled: true
            });
        } else if (fProps.fieldName == "oo_folder_id") {
            return null;
        } else {
            return super.getFieldEditor({ ...fProps, disabled: fProps.disabled || fProps.fieldName != "title" });
        }
    }

    protected getNewRecordDefaults(): ITaskRecord {
        console.log("getNewRecordDefaults");
        return {
            task_status_id: TASK_STATUS_ELOKESZITES_ID, // előkészítés
            task_type_id: 1, // most csak ez az egy van...
        }
    }

    protected ignoreRequired(fieldInfo: IFieldInfo): boolean {
        if (fieldInfo.field_name == "oo_folder_id") {
            return true;
        } else {
            return super.ignoreRequired(fieldInfo);
        }
    }

    protected getDetailPanels(): any[] {
        const rec_id = (this.state.rec && this.state.rec.id) || null;
        return [<Tabs key="details">
            <TabList>
                <Tab>{__("Importálandó állományok")}</Tab>
                <Tab>{__("Importálási napló")}</Tab>
            </TabList>
            <TabPanel>
                {this.getFolderTab()}
            </TabPanel>
            <TabPanel>
                <TaskLogListView taskId={rec_id}/>
            </TabPanel>
        </Tabs>];
        //return this.getFolderTab();
        /* .concat([ <TaskLogListView key="log" taskId={rec_id}/> ]); */
    }


    protected getFolderTab(): any {
        let result: any[] = [];
        let rec = this.state.rec;
        if (rec && rec.oo_folder_id) {
            if (
                rec.task_status_id == TASK_STATUS_SORBAN_ALL_ID
                ||
                rec.task_status_id == TASK_STATUS_FELDOLGOZAS_ALATT_ID
            ) {
                result.push(<p key="folder_disabled_desc" className="callout secondary">
                    {__("Feldolgozás közben nem lehet állományokat feltölteni. Várja meg az importálási folyamat végét.")}
                    
                </p>)
                result.push(
                    <MediaFileChooser
                        key="file_chooser"
                        rootFolderId={rec.oo_folder_id}
                        currentFolderId={rec.oo_folder_id}
                        showLocation={false}
                        showFilter={false}
                        showFolders={false}
                        defaultStyle={ItemStyle.LIST}
                        enabledActions={[
                            ContentActionType.FILE_DOWNLOAD,
                        ]}
                    />
                )
            } else {
                result.push(<p key="folder_desc" className="callout primary">
                    {__("Húzza be az importálandó állományokat az alábbi mappába.")}
                </p>)
                result.push(
                    <MediaFileChooser
                        key="file_chooser"
                        rootFolderId={rec.oo_folder_id}
                        currentFolderId={rec.oo_folder_id}
                        showLocation={false}
                        showFilter={false}
                        showFolders={false}
                        defaultStyle={ItemStyle.LIST}
                        enabledActions={[
                            ContentActionType.FILE_DELETE,
                            ContentActionType.FILE_DOWNLOAD,
                            ContentActionType.FILE_MODIFY,
                            ContentActionType.FILE_UPLOAD,
                            ContentActionType.FILE_RENAME
                        ]}
                    />
                )
            }
        }
        return result;
    }

    private onStartImport = async () => {
        if (confirm(__('Biztos benne, hogy el akarja indítani az importálást?'))) {
            const rec = this.state.rec!;
            try {
                await (new TaskCrud({ id: rec.id!, task_status_id: TASK_STATUS_SORBAN_ALL_ID })).put();
                this.asyncReload();
            } catch (error) {
                app.showErrorFromJsonResult(error);
            }

        }
    }

    protected getBottomButtons(): JSX.Element[] {
        let result = super.getBottomButtons();
        const rec = this.state.rec;
        if (rec) {
            const canImport = rec.oo_folder_id &&
                rec.task_status_id == TASK_STATUS_ELOKESZITES_ID;
            const canReimport = rec.oo_folder_id && (
                rec.task_status_id == TASK_STATUS_SIKERTELEN_ID
                ||
                rec.task_status_id == TASK_STATUS_FELDOLGOZVA_ID
                ||
                rec.task_status_id == TASK_STATUS_FIGYELMEZTETES_ID
            );
            if (canImport) {
                result.push(
                    <div className="small-4 medium-4 column"
                        key="recedit_import">
                        <button className="button"
                            onClick={this.onStartImport}>{__("Importálás indítása")}</button>
                    </div>
                )
            }
            if (canReimport) {
                result.push(
                    <div className="small-4 medium-4 column"
                        key="recedit_reimport">
                        <button className="button"
                            onClick={this.onStartImport}>{__("Importálás újraindítása")}</button>
                    </div>
                )
            }
        }
        return result;
    }

}