import * as React from 'react';
import ViewSharedWithMe, { IViewSharedWithMeRecord } from '@src/framework/view/usr/ViewSharedWithMe';
import { app } from '@src/index';
import { IContentRowProps, ContentList } from '../filemanager/ContentList';
import { PATH_SHARED_CONTENT_WITH_ME } from '@src/Routes';
import MemberCard from '@src/framework/forms/MemberCard';
import { SHARE_MEGOSZTAS_ID } from '@src/Const';
import { __ } from '@src/translation';

interface ISharedWithMeTileState {
    loading : boolean;
    items : IViewSharedWithMeRecord[];
}
export default class SharedWithMeTile extends React.Component<any, ISharedWithMeTileState> {

    constructor(props: any) {
        super(props);
        this.state = { items : [], loading: true };
    }
  
    async componentDidMount() {
        this.reloadAsync();
    }
    async reloadAsync() {
        this.setState({loading: true});
        try {
            const items = await ViewSharedWithMe.list({filter: {is_active: true}, limit:10, order_by: [{name: "creation_time", desc: true}]});
            this.setState({items, loading: false});
        } catch (e) {            
            app.showErrorFromJsonResult(e);
        }
    }
   
    render() {
        const items : IContentRowProps[] = this.state.items.map( (item : IViewSharedWithMeRecord ) => {
            var url = (item.share_mode_id == SHARE_MEGOSZTAS_ID) ? undefined : PATH_SHARED_CONTENT_WITH_ME + "/" + item.id;
            var name = (item.share_mode_id == SHARE_MEGOSZTAS_ID) ? __("Megosztó") : __("Kiosztó");

            return {
                table_info_id: item.table_info_id!,
                record_id: item.rec_id!,
                creation_time: item.creation_time,
                start_date: item.start_date!,
                end_date: item.end_date,
                due_date: item.due_date,
                share_mode_title: item.share_mode_title!,
                name : item.displayvalue,
                description: <>
                        {/* {item.title}<br /> */}
                        <div className="member">
                            <span>{name}: </span>
                            <MemberCard short={true} {...item} memberFieldNamePrefix="shared_by_" />
                        </div>
                    </>,
                url: url
            }
        });
        return<div className="shared-with-me-tile">
         <ContentList loading={this.state.loading} items={items}/>
         </div>
    }
}

