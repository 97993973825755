import * as ExerciseBaseTypes from "@src/component/exercise/models/ExerciseBaseClass";
import { AExerciseTypeConverter, ValidationResponse, StatisticsResponse } from "@src/component/exercise/models/AExerciseTypeConverter";
import { FillTableData } from "@src/component/exercise/engine/WMFillTableTextExerciseEngine/WMFillTableTextExerciseEngine";
import * as React from "react";
import { AnswerObject } from '@src/component/exercise/models/ExerciseBaseClass';
import { Accordion, ActionAccordionItem, AccordionActionType, IAccordionAction } from '@src/component/ui/Accordion';
import { Panel } from '@src/component/ui/Panel';
import { __ } from '@src/translation';

export type WMFillTableAnswerElement = {
    headers: any,
    rows: any[],
    first_column_header: boolean,
    first_row_header: boolean
}
export class WMFillTableTextConverter extends AExerciseTypeConverter {

    public hasTextAnswer = true;
    public hasImageAnswer = false;
    public hasTextImageAnswer = false;


    public convertToEditorAnswer(exercise: FillTableData): WMFillTableAnswerElement | undefined {
        if (exercise) {
            var rowList = [];
            var cur_exercise = exercise;
            let isFirstRowHeader = exercise.first_row_header;
            var solutionIdx = 0;
            // the old exercises have headears, we convert them to the new type
            if (exercise.headers && exercise.headers.length > 0) {
                isFirstRowHeader = true;
                var curr_row = [];
                for (var _i = 0; _i < cur_exercise.headers.length; _i++) {
                    var ansObj: AnswerObject = {
                        answer: cur_exercise.headers[_i],
                        is_answer: false
                    };
                    curr_row.push(ansObj);
                }
                rowList.push(curr_row);
            }
            if (cur_exercise.fix_elements)
                for (var _i = 0; _i < cur_exercise.fix_elements.length; _i++) {
                    var curr_row = [];
                    for (var _j = 0; _j < cur_exercise.fix_elements[_i].data.length; _j++) {
                        var element = null;
                        var isAnswer = false;
                        var currCell = cur_exercise.fix_elements[_i].data[_j];
                        if (currCell.is_answer) {
                            element = { text: cur_exercise.solution[solutionIdx].text };

                            isAnswer = true;
                            solutionIdx++;
                        } else {
                            element = currCell.answer;
                            isAnswer = false;
                        }
                        let ansObj = {
                            answer: element.text,
                            is_answer: isAnswer
                        };
                        curr_row.push(ansObj);

                    }
                    rowList.push(curr_row);
                }

            var answerElement: WMFillTableAnswerElement = {
                headers: exercise.headers,
                rows: rowList,
                first_column_header: exercise.first_column_header,
                first_row_header: isFirstRowHeader,
            }
            return answerElement;
        }
        else return undefined;
    }

    public convertToJson(exerciseDetails: WMFillTableAnswerElement, baseData: ExerciseBaseTypes.ExerciseBaseClass, prevJSON?: WMFillTableAnswerElement): FillTableData | undefined {
        var cur_exercise = ExerciseBaseTypes.convertToBaseJson(baseData);

        if (exerciseDetails) {
            var answerList = [];
            var rowList = [];

            if (exerciseDetails.rows) {
                for (var _i = 0; _i < exerciseDetails.rows.length; _i++) {
                    var curr_row = [];
                    var dataList = [];
                    for (var _j = 0; _j < exerciseDetails.rows[_i].length; _j++) {
                        var currCell = exerciseDetails.rows[_i][_j];
                        let newElement = {
                            text: currCell.answer
                        };

                        if (currCell.is_answer) {
                            answerList.push(newElement);
                            dataList.push({ is_answer: true });
                        } else {
                            dataList.push({ is_answer: false, answer: newElement });
                        }
                    }
                    rowList.push({ data: dataList });
                }
            }

            cur_exercise = {
                ...cur_exercise,
                //headers: exerciseDetails.headers,
                //rows: rowList,
                fix_elements: rowList,
                first_column_header: exerciseDetails.first_column_header,
                first_row_header: exerciseDetails.first_row_header,
                //answers: shuffleAnswers.answers,
                solution: answerList
            };
            return cur_exercise;
        }
        return cur_exercise;
    }
    public validate(editorAnswer: WMFillTableAnswerElement, baseData: any, validationMap?: Map<string, string>, is_accessible?: boolean | null): ValidationResponse {
        let superAnswer: ValidationResponse = super.validate(editorAnswer, baseData, validationMap, is_accessible);
        if (!superAnswer.valid) return superAnswer;

        let rowsValid = this.makeValidBeforeSetState(null, editorAnswer);
        if (!rowsValid.valid) return rowsValid;

        let errorMsg = "";
        let OFIErrors: string[] = [];
        if (editorAnswer.rows)
            for (let i = 0; i < editorAnswer.rows.length; i++) {
                for (let j = 0; j < editorAnswer.rows[i].length; j++) {
                    let cell = editorAnswer.rows[i][j];
                    if (cell.answer.length > WMFillTableTextConverter.TEXT_ANSWER_LENGTH) {
                        errorMsg = __("A válasz hossza nem lehet több mint {n} karakter!", {n: WMFillTableTextConverter.TEXT_ANSWER_LENGTH});
                        if(!OFIErrors.includes(errorMsg, 0)) OFIErrors.push(errorMsg);
                        validationMap!.set("rows[" + i + "][" + j + "].answer", errorMsg); 
                        if (!AExerciseTypeConverter.isOfiEditor ) return { valid: false, message: errorMsg };
                    }
                }
            }
        if (AExerciseTypeConverter.isOfiEditor){
            return { valid: true, message: OFIErrors.join(' , ') }
        }
        return { valid: true }
    }

    public makeValidBeforeSetState(oldState: WMFillTableAnswerElement | null, newState: WMFillTableAnswerElement): ValidationResponse {

        if(AExerciseTypeConverter.isOfiEditor ) return {valid:true};
        if (newState.rows) {
            if (newState.rows.length > WMFillTableTextConverter.FILLTABLE_MAX_ROWS_NUM) {
                newState.rows.splice(WMFillTableTextConverter.FILLTABLE_MAX_ROWS_NUM, newState.rows.length - WMFillTableTextConverter.FILLTABLE_MAX_ROWS_NUM);
                return { valid: false, message: __("Oszlopok és sorok száma maximum {n} lehet!", {n: WMFillTableTextConverter.FILLTABLE_MAX_ROWS_NUM}) }
            }

            for (let i = 0; i < newState.rows.length; i++) {
                if (newState.rows[i].length > WMFillTableTextConverter.FILLTABLE_MAX_COLUMN_NUM) {
                    newState.rows[i].splice(WMFillTableTextConverter.FILLTABLE_MAX_COLUMN_NUM, newState.rows[i].length - WMFillTableTextConverter.FILLTABLE_MAX_COLUMN_NUM);
                    return { valid: false, message: __("Oszlopok és sorok száma maximum {n} lehet!", {n: WMFillTableTextConverter.FILLTABLE_MAX_COLUMN_NUM}) }
                }
            }
        }
        return { valid: true }
    }

    render() {
        let cur_ex: WMFillTableAnswerElement = this.state.exercise as WMFillTableAnswerElement;
        return (
            <Panel header={__("Részletek")} headingLevel={4}>
                <div className="row">
                    <div className="large-12 columns">
                        <label ><input type="checkbox" name="first_row_header" checked={cur_ex.first_row_header || false}
                            onBlur={this.onBlurEvent.bind(this)}
                            onChange={this.handleInputChange.bind(this)} />
                            {__("Első sor fejléc")}</label>
                    </div>
                </div>
                <div className="row">
                    <div className="large-12 columns">
                        <label ><input type="checkbox" name="first_column_header" checked={cur_ex.first_column_header || false}
                            onBlur={this.onBlurEvent.bind(this)}
                            onChange={this.handleInputChange.bind(this)} />
                            {__("Első oszlop fejléc")}</label>
                    </div>
                </div>
                <Panel header={__("Sorok")} headingLevel={5}>
                    <div>
                        {cur_ex.rows ? cur_ex.rows.map((cur_row: any, i) => {

                            let actions: IAccordionAction[];
                            actions = [
                                {
                                    title: __("Törlés"),
                                    type: AccordionActionType.Trash,
                                    action: this.removeElement.bind(this, "rows", i)
                                },
                                {
                                    title: __("Fel"),
                                    type: AccordionActionType.Up,
                                    action: this.moveUp.bind(this, "rows", i)
                                },
                                {
                                    title: __("Le"),
                                    type: AccordionActionType.Down,
                                    action: this.moveDown.bind(this, "rows", i)
                                }
                            ];
                            return (
                                <Accordion key={"rows_" + i}>
                                    <ActionAccordionItem defaultClosed={false} key={"rows_" + i} actions={actions} title={"Sor " + (i + 1)}>
                                        {cur_row ? cur_row.map((cur_column: any, j: any) => {
                                            return (
                                                <Panel key={"rows#" + i + j}>
                                                    <legend>
                                                        <label className="exe-editor-fieldlabel-1"> {__("{n}. Cella", { n: j + 1 })}</label>
                                                        <button className="button small alert exercise-series-small-btn" title={__("Törlés")} onClick={this.removeElement.bind(this, "rows#" + String(i), j)}><i className="fa fa-trash"></i></button>
                                                        <button className="button small exercise-series-small-btn" title={__("Fel")} onClick={this.moveUp.bind(this, "rows#" + String(i), j)} ><i className="fa fa-arrow-up"></i></button>
                                                        <button className="button small exercise-series-small-btn" title={__("Le")} onClick={this.moveDown.bind(this, "rows#" + String(i), j)}><i className="fa fa-arrow-down"></i></button>
                                                    </legend>
                                                    <div className="large-12 columns">
                                                        <div>
                                                            <label><input type="checkbox" name={"rows#" + i + "#" + j + ".is_answer"} data-parent-index={i} data-index={j} checked={cur_column.is_answer || false}
                                                                onBlur={this.onBlurEvent.bind(this)}
                                                                onChange={this.handleInputChange.bind(this)} />
                                                                {__("Kitöltendő")}
                                                            </label>
                                                            <span className="exe-editor-validation-msg">{this.props.validationMessages.get("rows[" + i + "][" + j + "].answer")}</span>
                                                        </div>
                                                        <div>
                                                            <label>{__("Válasz")}
                                                            <input type="text" name={"rows#" + i + "#" + j + ".answer"} data-parent-index={i} data-index={j} value={cur_column.answer}
                                                                    onBlur={this.onBlurEvent.bind(this)}
                                                                    onChange={this.handleInputChange.bind(this)} />
                                                            </label>
                                                        </div>
                                                    </div>
                                                </Panel>
                                            )
                                        }) : ""

                                        }
                                        <div className="row">
                                            <button className="button small" name={"row#" + i + ".btn-add-cell"} onClick={this.onAddNewColumn.bind(this, i)}><i className="fa fa-plus"></i> {__("Új cella")}</button>
                                            <label className="exe-editor-label-description columns">{__("Az oszlopok száma maximum {max} lehet!",{max:WMFillTableTextConverter.FILLTABLE_MAX_COLUMN_NUM})} </label>
                                        </div>
                                    </ActionAccordionItem>
                                </Accordion>
                            )
                        }) : ""
                        }
                        <div className="row">
                            <button className="button small" onClick={this.onAddNewRow.bind(this)}><i className="fa fa-plus"></i>{__("Új sor")}</button>
                            <label className="exe-editor-label-description columns">{__("A sorok száma maximum {max} lehet!",{max: WMFillTableTextConverter.FILLTABLE_MAX_ROWS_NUM}) }</label>
                        </div>
                    </div>
                </Panel>
            </Panel>
        )
    }
    onAddNewRow() {
        let temp_exercise = this.state.exercise as WMFillTableAnswerElement;
        if (!temp_exercise.rows)
            temp_exercise.rows = [];
        temp_exercise.rows.push(this.getNewRow());
        if (this.makeValidBeforeSetState(this.state.exercise, temp_exercise).valid)
            this.setState({ exercise: temp_exercise });
    }

    onAddNewColumn(index: number) {
        let temp_exercise = this.state.exercise as WMFillTableAnswerElement;
        if (!temp_exercise.rows[index])
            temp_exercise.rows[index] = [];
        temp_exercise.rows[index].push(this.getNewCheckableColumn());
        if (this.makeValidBeforeSetState(this.state.exercise, temp_exercise).valid)
            this.setState({ exercise: temp_exercise });
    }
    getNewRow(): any {
        let newElement: [] = [];
        return newElement;
    }

    getNewCheckableColumn(): ExerciseBaseTypes.AnswerObject {
        let newColumn: ExerciseBaseTypes.AnswerObject = {
            is_answer: false,
            answer: ""
        };
        return newColumn;
    }
    public getExerciseStatistics(exerciseList: any): StatisticsResponse[] {
        let statisticsResponses: StatisticsResponse[] = [];
        let rowStat, elementStat, oddStat, totalElemStat: StatisticsResponse;
        statisticsResponses.push({ name: __("limit - min. sor: {n}", {n: WMFillTableTextConverter.FILLTABLE_MIN_ROWS_NUM}), count: undefined });
        statisticsResponses.push({ name: __("limit - max. sor: {n}", {n: WMFillTableTextConverter.FILLTABLE_MAX_ROWS_NUM}), count: undefined });
        statisticsResponses.push({ name: __("limit - min. cella: {n}", {n: WMFillTableTextConverter.FILLTABLE_MIN_COLUMN_NUM}), count: undefined });
        statisticsResponses.push({ name: __("limit - max. cella: {n}", {n: WMFillTableTextConverter.FILLTABLE_MAX_COLUMN_NUM}), count: undefined });

        rowStat = { name: __("Sorok száma"), count: new Map() }
        elementStat = { name: __("Cellák száma"), count: new Map() }
        totalElemStat = { name: __("Elemek száma összesen"), count: new Map() }

        for (let i = 0; i < exerciseList.length; i++) {
            if (!exerciseList[i]) continue;
            const cur_ex = exerciseList[i];
            let row_count = rowStat.count.has(cur_ex.rows.length) ? rowStat.count.get(cur_ex.rows.length) : 0;
            rowStat.count.set(cur_ex.rows.length, row_count! + 1);
            let counter = 0;
            for (let j = 0; j < cur_ex.rows.length; j++) {
                if (!cur_ex.rows[j]) continue;
                const element = cur_ex.rows[j];
                counter += element.length;
                let element_count = elementStat.count.has(element.length) ? elementStat.count.get(element.length) : 0;
                elementStat.count.set(element.length, element_count! + 1);
            }
            let total_elem_count = totalElemStat.count!.has(counter) ? totalElemStat.count!.get(counter) : 0;
            totalElemStat.count!.set(counter, total_elem_count! + 1);
        }
        statisticsResponses.push(rowStat, elementStat, totalElemStat)
        return statisticsResponses;
    }
}