import * as React from 'react';
import HtrTicketCategoryCrud, { htrTicketCategoryCrudClassProxy, IHtrTicketCategoryRecord } from '@src/framework/crud/ticket/HtrTicketCategoryCrud';
import Tree, { TreeNode } from 'rc-tree';
import { __ } from '@src/translation';
import { Link } from 'react-router-dom';
import { PATH_TICKETING_MESSAGE } from "@src/Routes";

type TicketCategoryTreeListState={
    categories: IHtrTicketCategoryRecord[];
}

type TicketCategoryTreeListProps={
    dialogClose?: () => void;
}

export default class TicketCategoryTreeList extends React.Component<TicketCategoryTreeListProps, TicketCategoryTreeListState> {

    constructor(props:any) {
        super(props);
        this.state={
            categories: []
        }
    }

    componentDidMount() {
        this.reloadCategories();
    }

    onCategorySelect() {
        if(this.props.dialogClose) this.props.dialogClose();
    }
    
    async reloadCategories() {
        const cats = await HtrTicketCategoryCrud.list({filter: {is_active: true}});
        this.setState({categories: cats});
    }

    render()
    {

        const loop = (parentId?: number) => {
            return this.state.categories.filter(i => i.parent_id == parentId).map(i => {
                const node_title = i.parent_id ? <p>{i.title}</p> : <h5>{i.title}</h5>;
                //If the actual category is selectable, we wrap a link around it, otherwise we just display it
                const node_content = i.selectable ? <Link onClick={this.onCategorySelect.bind(this)} to={PATH_TICKETING_MESSAGE + "/" + i.id}>{node_title}</Link> : node_title;
                return <TreeNode key={i.id} title={node_content}>
                    {loop(i.id)}
                </TreeNode>
            })
        };

        return <>
            <h4>{__("Új üzenet indítása")}</h4>
            <h5>{__("Üzenet kategóriák")}</h5>
            <Tree showIcon={false} >
                {loop()}
            </Tree>
        </>
    }

}