

export class ExerciseEngineHelper {   

    /**
     * Converts polygons percentage coordinate values to pixel depending the size of an image
     * @param ratioCoords The coordinates in string format (Example: 25,50 12,50, 88,55)
     * @param imgH Height of the image
     * @param imgW Width of the image
     */
    static getPolyCoordsImagePixels(ratioCoords: string, imgH: number, imgW: number): string {
        let numberPattern = /\d+/g;
        let coords = ratioCoords.match(numberPattern);
        let calcCoords: string = "";
        if (coords != null) {
            for (let i = 0; i < coords.length; i++) {
                var cord;
                //X koordináta
                if (i % 2 == 0) {
                    cord = Math.round(((Number(coords[i])) / 100) * imgW);
                    calcCoords = calcCoords + String(cord) + ",";
                }
                //Y koordináta
                else {
                    cord = Math.round(((Number(coords[i])) / 100) * imgH);
                    calcCoords = calcCoords + String(cord) + " ";
                }
            }
        }
        return calcCoords;
    }

    static getPolyPercentageToPixel(coords: any[], w:number, h:number) :any[]
    {
        let pixelCoords = [];
        for (let i = 0; i < coords.length; i++) {
            let xypair = [];
            xypair.push((coords[i][0] / 100) * w);
            xypair.push((coords[i][1] / 100) * h);
            pixelCoords.push(xypair);
        }
        return pixelCoords;
    }

    /**
    * Converts string polygon coordinate format into array, which contains x,y pairs
    * @param polycoords The coordinates in string format (Example: "25,50 12,50, 88,55")
    */
    static getPolyCoordinatePairs(polycoords: string): any[] {
        let numberPattern = /\d+/g;
        let coords = polycoords.match(numberPattern);
        let coordPairs: any[] = [];
        if (coords != null) {
            for (let i = 0; i < coords.length; i += 2) {
                coordPairs.push([Number(coords[i]), Number(coords[i + 1])]);
            }
        }
        return coordPairs;
    }

    /**
    * Converts string polygon coordinate format into scaled string format
    * @param points The original string values (Example: "25,50 12,50, 88,55")
    * @param scale_x The x scale for example 0.5
    * @param scale_y The y scale for example 0.8
    */
    static scalePolygonPoints(points: string, scale_x: number, scale_y: number): string {
        let point_list = points.split(" ");
        let newS = "";
        for (let i = 0; i < point_list.length-1; i++) {
            let x: number = Number(point_list[i].split(",")[0]) * scale_x;
            let y: number = Number(point_list[i].split(",")[1]) * scale_y;
            newS += x + "," + y + " ";
        }
        return newS;
    }

    /**
    * Returns all the 4 points of a rectangle polygon in string format
    * @param polycoords The top left, and bottom right points of a rectangle in string format (Example: "5,10 50,60")
    */
    static getRectangleCoordinates(polycoords: string | null): string {
        if (polycoords == null) return "error";
        let numberPattern = /\d+/g;
        let coords = polycoords.match(numberPattern);

        if (coords != null && coords.length >= 4) {
            let p1x = coords[coords.length - 4];
            let p1y = coords[coords.length - 3];
            let p2x = coords[coords.length - 2];
            let p2y = coords[coords.length - 1];
            return String(p1x) + "," + String(p1y) + " " + String(p1x) + "," + String(p2y) + " " + String(p2x) + "," + String(p2y) + " " + String(p2x) + "," + String(p1y);
        }
        return "error";
    }
    /**
    * This method tells if a given point is inside a polygon
    * @param point The coordinate pair of the point [x,y]
    * @param polygon The polygon HTMLElement
    */
    static isPointInsidePolygon(point: any, polygonPoints: string): boolean {
        let polycoords: any = this.getPolyCoordinatePairs(polygonPoints);
        var x = point[0], y = point[1];
        var inside = false;
        for (var i = 0, j = polycoords.length - 1; i < polycoords.length; j = i++) {
            var xi = polycoords[i][0], yi = polycoords[i][1];
            var xj = polycoords[j][0], yj = polycoords[j][1];

            var intersect = ((yi >= y) != (yj >= y))
                && (x <= (xj - xi) * (y - yi) / (yj - yi) + xi);
            if (intersect) inside = !inside;
        }
        return inside;
    }


    /**
     * This method tells if a given points array is inside a polygon in percentage
     * @param point The coordinate pair of the point [x,y]
     * @param polygon The polygon HTMLElement
     */
    static isPointsInsidePolygon(points: any, polygonPoints: string): number {
        let polycoords: any = this.getPolyCoordinatePairs(polygonPoints);
        let pointsNumber = points.length;
        let pointsInside = 0;

        for (let p = 0;p < points.length;p++) {
            var x = points[p].x, y = points[p].y;
            var inside = false;
            for (var i = 0, j = polycoords.length - 1; i < polycoords.length; j = i++) {
                var xi = polycoords[i][0], yi = polycoords[i][1];
                var xj = polycoords[j][0], yj = polycoords[j][1];

                var intersect = ((yi >= y) != (yj >= y))
                    && (x <= (xj - xi) * (y - yi) / (yj - yi) + xi);
                if (intersect) inside = !inside;
            }

            if (inside) pointsInside++;
        }

        return pointsInside / pointsNumber;

    }


    /**
    * This method converts a circle into polygon (with 21 points)
    * @param cx The x coordinate of the middle point of the circle
    * @param cy The y coordinate of the middle point of the circle
    * @param r The radius of the circle
    * @param polygon The polygon HTMLElement
    */
    static convertCircleToPolygon(cx: number, cy: number, r: number): any {
        let circleCoords: any[] = [];
        let stringPolyCoords: string = "";
        for (let a = 0; a <= 360; a += 18) {
            let polyc: number[] = [];
            polyc.push(Math.round(cx + r * Math.cos(a * Math.PI / 180)));
            polyc.push(Math.round(cy + r * Math.sin(a * Math.PI / 180)));
            stringPolyCoords = stringPolyCoords + String(polyc[0]) + "," + String(polyc[1]) + " ";
            circleCoords.push(polyc);
        }
        return { pairs: circleCoords, string: stringPolyCoords };
    }

    /**
    * Returns a function, that, as long as it continues to be invoked, will not
    * be triggered. The function will be called after it stops being called for
    * N milliseconds. If `immediate` is passed, trigger the function on the
    * leading edge, instead of the trailing.
     */
    static debounce(func: () => void, wait = 50) {
        let h: any;
        return () => {
            clearTimeout(h);
            h = setTimeout(() => func(), wait);
        }
    }

    /**
     * This method give back the max width version of media url
     * @param url The url of the image
     */
    static getMediaMaxWidthUrl(url: string): any {
        return url.replace("api/media/file/", "api/media/max_width/2048/");
    }

}

