import * as uuidv4 from 'uuid/v4';
import axios from 'axios';
import Cookies from '@src/framework/server/Cookies';
import * as queryString from 'query-string';

export const ACCESS_TOKEN_COOKIE_NAME = "access_token";

export const OAUTH_SERVER_AUTHORIZATION_PATH = '/oauth/auth';
export const OAUTH_SERVER_TOKEN_PATH = '/oauth/token';
export const OAUTH_SERVER_REVOKE_PATH = '/oauth/revoke';

export interface IAuthConfig {
    client_id: string;
    redirect_uri: string;
    oauth_server_root_url: string;
    scope: string;
}

export interface IAccessToken {
    access_token: string;
    expires_in: number;
    refresh_token: string;
    token_type: string;
    scope: string;
}

export interface IPersonInfo {
    id: number;
    first_name: string;
    last_name: string;
    full_name: string;
    email: string;
}

export interface IUserInfoGroupList {
    id: number;
    title: string;
}

export interface IUserInfo {
    id: number;
    login_name: string;
    teacher_has_kap_site ?: boolean;
    email?: string;
    email_confirmed ?: string; /* timestamp */
    person?: IPersonInfo;
    oo_folder_id?: number; // A saját személyes mappa azonosítója.
    groups?: IUserInfoGroupList[];
}

// select id,title from sys.sec_group order by 1 asc;
export enum Groups {
    Everyone = 1, // Mindenki, minden regisztrált felhasználó aki be van jelentkezve   
    Admin = 2,    // Rendszer adminisztrátor, bármit megtehet
    EditorCandidate = 3, // Szerkesztő jelölt
    OFIEditor = 4, // Összes OFI-ban lévő szerkesztő
    //TestStudent = 5, // Első tesztben résztvevő diákok
    TeacherTraining = 6, // Tanári képzésben résztvevők
    Teacher = 7, // Az összes tanár
    Student = 8, // Az összes diák
    Lector = 9, // Lektor, tud szöveghez megjegyzést írni
    Guest = 10,    // Mindenki, ide értve a nem bejelentkezett anonim felhasználókat is   
    Developer = 11,
    NkpAdmin = 12, // NKP adminisztrátor, aki az intézményeket kezeli és az intézményi adminokat fölveszi
    LessonplanEditor = 13, // DPMK Óravázlat szerkesztő
    LessonplanValidator = 16, // DPMK Óravázlat validátor
    InstituteSiteAdmin = 14, // Intézményi adminisztrátor, aki egy feladatellátási hely intézményi csoportjait kezeli
    ForcedPublisherOFI = 15, // OFI kényszerített publikáló

    NeedToAcceptTermsOfUse = 20, // (DEMO) jogi nyilatkozatot el kell fogadnia az ebbe a csoportba tartozóknak
    LessonPlanMentor =21,
    KapKszrCourseCoordinator = 22, // KAP KSZR kézpés szervező
    NeptunUser = 23, // Olyan felhasználó aki már legalább egyszer belépett neptun kóddal
    KapKszrCourseTeacher = 24, // KAP KSZR képző

    KapKszrReporter = 25, // KAP KSZR Jelentés készítő
    KapJtrReporter = 26, // KAP JTR Jelentés készítő

    // ExerciseAdmin = 16602551185, // Okosfeladat adminisztrátor (OFI), aki mások feladatát is tudja szerkeszteni


    TestStudent = 16358708700,
    TestTeacher = 16358708900,

    MunyiWorker = 25196085965, //	MUNYI Kitöltők (OFI)
    MunyiApprover = 25195921155, //	MUNYI Jóváhagyók (OFI)
    MunyiValidator = 25195921172, //	MUNYI Validálók (OFI)


    KapEsztrCategoryValidator = 25346155124,
    KapEsztrCategoryResponsible = 25346155123,
    KapEsztrSupporter = 25346155122,
    KapEsztrAdmin = 25346155121,


    KapDraftEditor = 25346118900, // KAP óravázlat szerkesztő
    KapDraftLector = 25346155116, // KAP óravázlat szakmai lektor
    KapDraftReferens = 25346155117, // KAP óravázlat szerzői jogi referens
    KapDraftValidator = 25346155118, // KAP óravázlat validátor
    KapDraftLanguageLector = 25346155119, // KAP óravázlat nyelvi lektor
    KapDraftAdmin = 25346155120, // KAP óravázlat admin
    KapResponsibleAssigner = 25353931696, // KAP felelős kiosztó


    KapContentStoreKiadvany = 25346333769,
    KapContentStoreVideo = 25346333772,

    KapKszrParticipantTeacher = 25347475389, // KAP KSZR résztvevő pedagógus (elfogadták a KSZR jelentkezését)
    KapKszrCertifiedTeacher = 25347475390, // KAP KSZR diplomás pedagógus ( sikeresen elvégezte a KSZR képzést)

    // Kap képzést elvégzők
    KapKszrCerttifiedDA = 1001,
    KapKszrCerttifiedEA = 1002,
    KapKszrCerttifiedLA = 1003,
    KapKszrCerttifiedMA = 1004,
    KapKszrCerttifiedTA = 1005,
    KapKszrCerttifiedKAK = 2001,
    KapKszrCerttifiedDFHT = 2002,

    KapJtrCoordinator = 25347475391, // KAP JTR Koordinátor
    KapJtrInstituteContact = InstituteSiteAdmin, // KAP JTR Intézményi kapcsolattartó
    KapJtrSupport = 25347475393, // KAP JTR szakmai támogató

    KapArticleEditor = 25347475394, // KAP hírek / cikkek szerkesztő / holnap tartalomfeltöltő

    KapELearningEditor = 25354234305, // KAP e-learning tananyag szerkesztő
    KapExerciseEditor = KapELearningEditor,

    KapNyiregyhaziAdmin = 25347475395, // KAP Nyíregyházi Egyetem admin
    KapNyiregyhaziTeacher = 25347475396, // KAP Nyíregyházi Egyetem pedagógus
    KapSzegediAdmin = 25347475397, // KAP Szegedi Egyetem admin
    KapSzegediTeacher = 25347475398, // KAP Szegedi Egyetem pedagógus
    
    EventOrganizer = 25355073226, // Rendezvényszervező

    KapCommonFolderVisible = 25393870679, // Látja a közös mappát

    KapKszrTrainerDA = 1101,	// Digitális alapú alprogram (DA) képző
    KapKszrTrainerEA =  1102,	// Életgyakorlat-alapú alprogram (ÉA) képző
    KapKszrTrainerLA =  1103,	// Logikaalapú alprogram (LA) képző
    KapKszrTrainerMA =  1104,	// Művészetalapú alprogram (MA) képző
    KapKszrTrainerTA =  1105,	// Testmozgásalapú alprogram (TA) képző
    KapKszrTrainerKAK =  2101, //	KAK képesített képző, VOLT  KapKAKKSZR
    KapKszrTrainerDFHT = 2102, //	DFHT képző

    KapKszrOhInspector = 2201, // KAP KSZR OH ellenőr

    SyllabusEditor = 25578631561, // (KAP) Tanmenet szerkesztő
}

/**
 * KAP munkatársak, akik a főbb közös rendszereket elérik
 */
export const GROUPS_KAP_WORKERS = [
    // e-SZTR munkatársak
    Groups.KapEsztrCategoryValidator, Groups.KapEsztrCategoryResponsible, Groups.KapEsztrSupporter, Groups.KapEsztrAdmin,

    // Óravázlat munkatársak
    Groups.KapDraftEditor, Groups.KapDraftLector, Groups.KapDraftReferens, Groups.KapDraftValidator, Groups.KapDraftLanguageLector, Groups.KapDraftAdmin,

    // Tartalomtár - tudástár nyilvános tartalom
    Groups.KapContentStoreKiadvany, Groups.KapContentStoreVideo,

    // JTR munkatársak
    Groups.KapJtrCoordinator, Groups.KapJtrSupport,

    // Cikk szerkesztők
    Groups.KapArticleEditor,

    // Tananyag szerkesztők
    Groups.KapELearningEditor,
]

/**
 * Külső egyetemi pedagógusok
 */
export const GROUPS_KAP_EXTERNAL_UNIVERSITY_TEACHERS = [
    Groups.KapNyiregyhaziAdmin, Groups.KapNyiregyhaziTeacher, Groups.KapSzegediAdmin, Groups.KapSzegediTeacher
]

export let api: Auth | null; // TODO: remove this, nasty!
export let me: IUserInfo | null; // TODO: remove this, nasty!

/**
 * Megmondja, hogy a megadott felhasználó benne van-e a megadott csoportban.
 * 
 * @param user Ahogyan azt az api.getMe() visszaadta. Jelenleg a 'me' globális
 *      változót adjuk át ide, de ez nem túl szép.
 * @param groupId A kérdéses csoport. Ide használhatod a fenti Groups nevű enum
 *      értékeit.
 */
export function hasGroup(user: IUserInfo, groupId: number): boolean {
    if (groupId == Groups.Guest) {
        return true;
    }
    if (user && user.groups) {
        for (let i = 0; i < user.groups.length; i++) {
            const group = user.groups[i];
            if (group.id == groupId)
                return true;
        }
    }
    return false;
}


/**
 * Akkor ad vissza igazat, ha a megadott felhasználó benne van legalább egy csoportban
 * a megadottak közül.
 * 
 * @param user Ahogyan azt az api.getMe() visszaadta. Jelenleg a 'me' globális
 *      változót adjuk át ide, de ez nem túl szép.
 * @param groupId A lehetséges csoportok tömbje. Ide használhatod a fenti
 *      Groups nevű enum értékeit.
 */
export function hasAnyGroup(user: IUserInfo, groupIds: number[]): boolean {
    if (groupIds.includes(Groups.Guest)) {
        return true;
    }
    if (user && user.groups) {
        for (let i = 0; i < user.groups.length; i++) {
            const group = user.groups[i];
            if (groupIds.includes(group.id))
                return true;
        }
    }
    return false;
}


export default class Auth {
    /**
     * Felhasználó bejelentkeztetés.
     *
     * Ezt használd a weblap megnyitásakor a felhasználó bejelentkeztetéséhez.
     *
     * - ha sikertelen, akkor egyből át is irányítja a felhasználót a bejelentkező képernyőre.
     *   Amíg az meg nem nyílik, addig kiírhatsz neki egy üzenetet arról hogy "átirányítunk a bejelentkezéshez"
     * - Ha sikeres, akkor egy Auth object-et ad vissza, ami tartalmaz egy AccessToken-t, amivel
     *   el lehet érni a szerver API funkcióit.
     *
     * @param authConfig: A bejelentkezéshez szükséges konfiguráció
     *
     */
    public static async loginOrRedirect(authConfig: IAuthConfig, allowLocalStorage: boolean = true): Promise<Auth> {
        const auth = await Auth.create(authConfig, allowLocalStorage);
        if (auth) {
            return Promise.resolve(auth);
        } else {
            const fullAuthUrl = Auth.getRedirectURL(authConfig);
            window.location.replace(fullAuthUrl.toString());
            return Promise.reject('Nincs bejelentkezve.');
        }
    }

    /**
     * Felhasználó kijelentkeztetése.
     * 
     * @param authConfig: Az auth szerver amiről a kijelentkezés megtörténik.
     * @param aGlobal: Ha ez igazra van állítva, akkor a token az auth szerveren
     *      visszavonásra kerül, így a kijelentkezés az összes alkalmazásból
     *      megtörténik (single sign out). Ha Ez a flag hamisra van állítva,
     *      akkor csak a helyi alkalmazásból távolítja el az access token-t.
     * 
     * Figyelem! Erre mindenképp await-tezz mielőtt átirányítod a user-t egy másik
     * URL-re, máskülönben lehet, hogy a token nem távolítódik el.
     * 
     */
    public async logout(authConfig: IAuthConfig, aGlobal: boolean = true): Promise<any> {
        const params = {
            'token': this.accessToken.access_token,
            'token_type_hint': 'access_token',
            'global': aGlobal
        };
        const postParams = Object.keys(params).map((key) => {
            return encodeURIComponent(key) + '=' + encodeURIComponent(params[key]);
        }).join('&');
        const response = await fetch(
            authConfig.oauth_server_root_url + OAUTH_SERVER_REVOKE_PATH, {
                method: 'POST',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                body: postParams
            }
        );
        Auth.cleanSession(authConfig);
        return Promise.resolve(response);
    }

    public static cleanSession(authConfig: IAuthConfig) {
        const accessTokenStorageKey = Auth._getAccessTokenStorageKey(authConfig);
        localStorage.removeItem(accessTokenStorageKey);
        Cookies.erase(ACCESS_TOKEN_COOKIE_NAME);
        api = null;
        me = null;
        
    }

    /**
     * Nagyobb biztonságot igénylő scope esetén letiltja a local alapú access token tárolást.
     */
    private static _safeAllowLocalStorage(authConfig: IAuthConfig, allowLocalStorage: boolean) {
        if (authConfig.scope.includes("2fa")) {
            return false;
        } else {
            return allowLocalStorage;
        }
    }

    private static _getAccessTokenStorageKey(config: IAuthConfig) {
        return "access_token_" + config.client_id;
    }

    /**
     * 
     * Megmondja, hogy a felhasználó be van-e jelentkezve éppen.
     * 
     * A bejelentkezést localStorage-ben tároljuk, minden client_id-hez külön.
     * 
     * @param config: A bejelentkezéshez szükséges konfiguráció
     * @param allowLocalStorage: Ha ez igazra van állítva, akkor a bejelentkezés során kapott
     *      access token-t eltárolja localStorage-be, és onnan vissza is tudja tölteni. Alapértelmezett
     *      értéke igaz, de ha a config.scope tartalmazza a "2fa" értékeket, akkor felül
     *      bírálja, és nem használja (sőt törli) a localStorage-ben levő access token-t.
     * 
     */

    public static async create(config: IAuthConfig, allowLocalStorage: boolean = true): Promise<Auth | null> {
        const accessTokenStorageKey = Auth._getAccessTokenStorageKey(config);
        allowLocalStorage = Auth._safeAllowLocalStorage(config, allowLocalStorage);
        let accessToken: IAccessToken | null = null;        
        let accessTokenStr : string | null = null;
        if (allowLocalStorage) {
            accessTokenStr = localStorage.getItem(accessTokenStorageKey);
            accessToken = (accessTokenStr) ? JSON.parse(accessTokenStr) : null;    
        }
        if (!accessToken) {
            const {code, state} = queryString.parse(location.search);
            if (!code || !state) {
                return Promise.resolve(null); // Nincs access token-ünk.
            } else {
                const goodState = localStorage.getItem('auth_state');
                localStorage.removeItem('auth_state');
                if (state !== goodState) {
                    return Promise.resolve(null); // Valaki próbálkozott...
                }
                const response = await fetch(
                    config.oauth_server_root_url + OAUTH_SERVER_TOKEN_PATH + '?'
                    + 'grant_type=authorization_code'
                    + '&redirect_uri=' + encodeURIComponent(config.redirect_uri)
                    + '&client_id=' + encodeURIComponent(config.client_id)
                    + '&code=' + encodeURIComponent(code),
                );
                accessToken = await response.json();
                if (allowLocalStorage) {
                    localStorage.setItem(accessTokenStorageKey, JSON.stringify(accessToken));
                }
                if (accessToken==null) {
                    Cookies.erase(ACCESS_TOKEN_COOKIE_NAME);
                } else {
                    Cookies.set(ACCESS_TOKEN_COOKIE_NAME, accessToken.access_token, accessToken.expires_in/3600.0/24.0 );
                }
                
            }
        }
        if (accessToken) {
            // TODO: remove global variable, this is nasty!
            api = new Auth(config, accessToken as IAccessToken);
            try {
                me = await api.getMe();
            } catch {
                me = null;
            }
            if (!me) {
                Auth.cleanSession(config);
                return Promise.resolve(null);
            } else {
                return Promise.resolve(api);
            }
        } else {
            me = null;
            return Promise.resolve(null);
        }
    }


    /* Csak és kizárólag admin használatja, felhasználó megszemélyesítésre. */
    public static async impersonate(config: IAuthConfig, accessToken: IAccessToken, allowLocalStorage: boolean = true): Promise<Auth | null> {
        const accessTokenStorageKey = Auth._getAccessTokenStorageKey(config);
        allowLocalStorage = Auth._safeAllowLocalStorage(config, allowLocalStorage);
        if (allowLocalStorage) {
            localStorage.setItem(accessTokenStorageKey, JSON.stringify(accessToken));
        }
        Cookies.set(ACCESS_TOKEN_COOKIE_NAME, accessToken.access_token, accessToken.expires_in/3600.0/24.0 );
        api = new Auth(config, accessToken);
        try {
            me = await api.getMe();
        } catch {
            me = null;
        }
        if (!me) {
            Auth.cleanSession(config);
            return Promise.resolve(null);
        } else {
            return Promise.resolve(api);
        }
    }


    private static getRedirectURL(config: IAuthConfig) {
        const state = uuidv4();
        localStorage.setItem('auth_state', state);
        return new URL(
            config.oauth_server_root_url + OAUTH_SERVER_AUTHORIZATION_PATH
            + '?response_type=code'
            + '&client_id=' + encodeURIComponent(config.client_id)
            + '&redirect_uri=' + encodeURIComponent(config.redirect_uri)
            + '&state=' + encodeURIComponent(state)
            + '&scope=' + encodeURIComponent(config.scope));

    }

    private config: IAuthConfig;
    public accessToken: IAccessToken; // TODO: this should not

    constructor(config: IAuthConfig, accessToken: IAccessToken) {
        this.config = config;
        this.accessToken = accessToken;
    }

    public getAuthorizationHeader(): object {
        return { Authorization: 'Bearer ' + this.accessToken.access_token };
    }

    public async getMe(): Promise<IUserInfo|null> {
        const headers = {'Authorization' : 'Bearer ' + this.accessToken.access_token }
        try {
            let me = (await axios.get<IUserInfo>(
                this.config.oauth_server_root_url + '/api/me', { headers })).data;
            return Promise.resolve(me);
        } catch (error) {
            return Promise.resolve(null);
        }
    }

    public getCurrentUser(): IUserInfo | null {
        return me;
    }

}
