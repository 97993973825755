import * as React from 'react';
import * as ReactDOM from 'react-dom';

const DRAG_HANDLE_CLASS = "__list-drag-handle";

const listContext: React.Context<{list?: List, dragIndex?: number, mouseX: number, mouseY: number}> = React.createContext({list: undefined, dragIndex: undefined, mouseX: undefined, mouseY: undefined}) as any;

type ListProps = {
    onDrop?: (fromIndex: number, toIndex: number) => void;
}

type ListState = {
    dragIndex?: number;
    mouseX: number;
    mouseY: number;
}

export class List extends React.Component<ListProps, ListState> {

    private dropIndex: number | undefined;

    constructor(props: ListProps) {
        super(props);
        
        this.state = {
            mouseX: 0,
            mouseY: 0
        }
    }
    
    onDrag(dragIndex: number, event: React.MouseEvent<HTMLDivElement>) {
        this.setState({
            dragIndex: dragIndex,
            mouseX: event.clientX,
            mouseY: event.clientY
        });
        this.dropIndex = undefined;
    }

    onDrop() {
        if (this.props.onDrop && this.state.dragIndex !== undefined && this.dropIndex !== undefined) {
            this.props.onDrop(this.state.dragIndex, this.dropIndex);
        }
        this.setState({
            dragIndex: undefined
        })
    }

    setDropIndex(dropIndex?: number) {
        this.dropIndex = dropIndex;
    }

    render() {
        return <div ref="root" style={{position: "relative"}}>
            <listContext.Provider value={{list: this, dragIndex: this.state.dragIndex, mouseX: this.state.mouseX, mouseY: this.state.mouseY}}>
                {this.props.children}
            </listContext.Provider>
        </div>;
    }
}

export class ListItem extends React.Component<{list?: List, dragIndex?: number}, {drag: boolean, xStart: number, yStart: number, x: number, y: number}> {

    private element: HTMLElement;

    state = {
        drag: false,
        xStart: 0,
        yStart: 0,
        x: 0,
        y: 0
    }

    private onDragStart(event: React.MouseEvent<HTMLDivElement>) {
        if (this.props.dragIndex !== null && (event.target as HTMLElement).classList.contains(DRAG_HANDLE_CLASS)) {
            this.setState({
                drag: true,
                xStart: event.clientX,
                yStart: event.clientY,
                x: 0,
                y: 0
            })
        }
    }

    private onDrag(list: List, event: React.MouseEvent<HTMLDivElement>) {
        if (this.state.drag && this.props.dragIndex !== undefined) {
            this.setState({
                x: event.clientX - this.state.xStart,
                y: event.clientY - this.state.yStart,
            });
            list.onDrag(this.props.dragIndex, event);
        }
    }
    
    private onDragEnd(list: List, event: React.MouseEvent<HTMLDivElement>) {
        if (this.state.drag) {
            this.setState({
                drag: false
            });
            list.onDrop();
        }
    }

    componentDidMount() {
        this.element = ReactDOM.findDOMNode(this) as HTMLElement;
    }

    render() {
        
        return <listContext.Consumer>
            {({list, dragIndex, mouseX, mouseY}) => {

                var dropBelow = false;
                var dropAbove = false;

                if (list && this.element && dragIndex !== this.props.dragIndex && dragIndex !== undefined && this.props.dragIndex !== undefined) {
                    const bounds = this.element.getBoundingClientRect();
                    if (mouseX > bounds.left && mouseX < bounds.right) {
                        if (mouseY > bounds.top && mouseY < bounds.top + bounds.height / 2) {
                            dropBelow = true;
                            list.setDropIndex(dragIndex > this.props.dragIndex ? this.props.dragIndex : this.props.dragIndex - 1);
                        }

                        if (mouseY > bounds.top + bounds.height / 2 && mouseY < bounds.bottom) {
                            dropAbove = true;
                            list.setDropIndex(dragIndex > this.props.dragIndex ? this.props.dragIndex + 1 : this.props.dragIndex);
                        }
                    }
                }
                return <div onMouseDown={this.onDragStart.bind(this)}
                            onMouseUp={this.onDragEnd.bind(this, list)}
                            onMouseMove={this.onDrag.bind(this, list)}
							className="content-item-list"
                            style={{display: "flex", 
                                cursor: this.state.drag ? "grabbing" : (this.props.dragIndex !== undefined ? "grab" : undefined),
                                flexDirection: "row", 
                                minHeight: "88px",
                                userSelect: this.state.drag ? "none" : undefined,
                                borderTop: dropBelow ? "1px solid red" : "", 
                                borderBottom: dropAbove ? "1px solid red" : undefined,
                                position: this.state.drag ? "relative" : undefined,
                                top: this.state.y + "px",
                                left: this.state.x + "px",
                                background: this.state.drag ? "white" : undefined
                                }}>
                        {this.props.children}
                    </div>
                }
            }
            </listContext.Consumer>
        ;
    }
}

export class ListItemImage extends React.Component<{src?: string}, {}> {

    render() {
        const objectFit = this.props.src && this.props.src.endsWith(".svg") ? "contain" : "cover";

        return <div className={"content-item-list__img "+DRAG_HANDLE_CLASS}>
            {
                this.props.src &&
                <img style={{height: "100%", width: "100%", objectFit: objectFit}} src={this.props.src} onError={(e) => e.currentTarget.style.display="none"} />
            }
        </div>;
    }

}

export class ListItemText extends React.Component<{}, {}> {
    render() {
        return <div className={"content-item-list__text " + DRAG_HANDLE_CLASS} style={{flex: 1, whiteSpace: "nowrap", overflow: "hidden", padding: "4px"}}>
            {this.props.children}
        </div>;
    }

}
export class ListItemInfo extends React.Component<{}, {}> {
    render() {
        return <div className={"content-item-list__info " + DRAG_HANDLE_CLASS} style={{whiteSpace: "nowrap", overflow: "hidden", padding: "4px"}}>
            {this.props.children}
        </div>;
    }

}

export type ListItemMenuItem = {
    icon: JSX.Element;
    type?: "primary" | "secondary" | "alert";
    name?: string;
    primary?: boolean;
    onClick: () => void;
}

type ListItemMenuProps = {
    mainItems?: ListItemMenuItem[];
    secondaryItems?: ListItemMenuItem[];
}

export class ListItemMenu extends React.Component<ListItemMenuProps, {showMenu: boolean}> {
    state = {
        showMenu: false
    }
    

    componentDidUpdate() {
        if(this.state.showMenu && this.refs.menuRoot) {
            (this.refs.menuRoot as HTMLDivElement).focus();
        }
    }

    render() {
        return <div className={DRAG_HANDLE_CLASS} style={{alignSelf: "center", display: "flex", flexDirection: "row-reverse", alignItems: "center"}}>
            <div>
                {
                    this.props.mainItems && this.props.mainItems.map((i, index) => 
                        <button key={index} className={"button small " + (i.type || "clear")} style={{margin: 0, marginLeft: "8px"}} onClick={i.onClick} title={i.name}>
                            {i.icon}
                        </button>
                    )
                }
                {
                    this.props.secondaryItems && this.props.secondaryItems.length > 0 &&
                    <button className="button small clear" style={{margin: 0, marginLeft: "8px"}} onClick={() => this.setState({showMenu: !this.state.showMenu})}>
                        <i className="fa fa-2x fa-ellipsis-v" />
                    </button>
                }
                {
                    this.props.secondaryItems && this.state.showMenu &&
                    <div ref="menuRoot" className="content-item-list__menu-item" tabIndex={1}  onBlur={(e) => {if(!e.currentTarget.contains(e.relatedTarget as any)){this.setState({showMenu: false})}}}>
                        {
                            this.props.secondaryItems.map((i, index) => 
                                <div>
                                    <button key={index} className={"button small " + (i.type || "clear")} style={{margin: 0, marginLeft: "8px"}} onClick={i.onClick}>
                                        {i.icon}&nbsp; {i.name}
                                    </button>
                                </div>
                            )
                        }
                    </div>
                }
                
            </div>
            {this.props.children}
        </div>;
    }
}
