import * as React from "react";
import { match } from "react-router";
import TrainingTypeCrud, { ITrainingTypeRecord } from "@src/framework/crud/kap/TrainingTypeCrud";
import { BubbleLoader } from 'react-css-loaders';
import PermissionPage from "@src/framework/PermissionPage";
import { CrudPermissionType } from "@src/framework/security/PermissionCache";
import { Breadcrumbs } from "@src/component/Breadcrumbs";
import { courseModule } from "../courseModule";
import { __ } from "@src/translation";
import ButtonGroup, { ButtonSize, ButtonAlign } from "@src/component/ui/ButtonGroup";
import { app } from "@src/index";
import { HTMLTextarea } from "@src/component/HtmlTextarea";
import { whiteSpaceChecker } from "@src/component/WhiteSpaceChecker";
import MediaFileChooser, { ContentActionType } from "@src/component/filemanager/MediaFileChooser";
import LookupEdit from "@src/framework/forms/lookupedit";
import BookCrud from "@src/framework/crud/doc/BookCrud";
import { config } from '@src/framework/server/Server';

interface TrainingTypeEditProps {
    match: match<{ recId: string }>;
    history: History;
}

interface TrainingTypeEditState {
    loading: boolean;
    trainingRecord?: ITrainingTypeRecord;
    isDataChanged: boolean;
    validationMessages: Map<string, string>
}

export default class TrainingTypeEdit extends React.Component<TrainingTypeEditProps, TrainingTypeEditState> {

    protected getMatchParam(name: string): any {
        const match: any = this.props.match;
        return (match && match.params) ? match.params[name] : null;
    }

    constructor(props: TrainingTypeEditProps) {
        super(props);

        let validationMap: Map<string, string> = new Map<string, string>();
        this.state = {
            loading: true,
            trainingRecord: undefined,
            isDataChanged: false,
            validationMessages: validationMap,
        };
    }

    componentDidMount() {
        this.asyncReload();
    }

    componentDidUpdate(prevProps: TrainingTypeEditProps) {
        if (this.props.match.params.recId != prevProps.match.params.recId) {
            this.asyncReload();
        }
    }

    private asyncReload = async () => {
        this.setState({ loading: true, trainingRecord: undefined });
        let recId = this.getMatchParam("recId");

        let trainingRecord: ITrainingTypeRecord = { title: "" };
        if (this.state.trainingRecord && this.state.trainingRecord.id)
            trainingRecord = (await TrainingTypeCrud.load(this.state.trainingRecord.id)).record;
        if (recId && recId != "null") {
            trainingRecord = (await TrainingTypeCrud.load(Number(recId))).record;
        }

        this.setState({
            trainingRecord,
            loading: false
        });


        try {

        } catch (error) {
            app.showErrorFromJsonResult(error);
        }


    }

    private onFieldChange(e: any, fieldName: string) {
        let trainingRecord = Object.assign({}, this.state.trainingRecord);
        if (e.type != undefined) {
            if (e.target.getAttribute("data-type") == "number") {
                trainingRecord[fieldName] = Number(e.target.value);
            } else if (e.target.type === 'checkbox') {
                trainingRecord[fieldName] = e.target.checked;
            } else {
                trainingRecord[fieldName] = e.target.value;
            }
        } else {
            trainingRecord[fieldName] = e.target.value;
        }
        this.setState({ trainingRecord, isDataChanged: true });
    }

    private onFieldChangedWithValue(value:any, fieldName:string){
        let trainingRecord = Object.assign({}, this.state.trainingRecord);
        trainingRecord[fieldName] = value;
        this.setState({ trainingRecord, isDataChanged: true });
    }

    private handleDescriptionChange(fieldName: any, text: string) {
        let trainingRecord = Object.assign({}, this.state.trainingRecord);
        trainingRecord[fieldName] = text;
        this.setState({ trainingRecord, isDataChanged: true });
    }

    private async onSave(saveAsCopy: boolean) {
        let trainingRecord = this.state.trainingRecord;
        let validationMessages = new Map<string, string>();
        if (!trainingRecord) { app.showError(__("Hiba"), __("Nem található a képzés")); return; }

        let errorMsg = "";
        if (!whiteSpaceChecker(trainingRecord.title) || trainingRecord.title!.length < 2) {
            errorMsg = __("A képzés nevét kötelező kitölteni!");
            app.showError(__("Hiba"), errorMsg);
            validationMessages.set("title", errorMsg);
        }
        if (!whiteSpaceChecker(trainingRecord.code) || trainingRecord.code!.length < 2) {
            errorMsg = __("A képzés kódját kötelező kitölteni!");
            app.showError(__("Hiba"), errorMsg);
            validationMessages.set("code", errorMsg);
        }
        if (!whiteSpaceChecker(trainingRecord.certno_prefix)) {
            errorMsg = __("A tanúsítványszám prefixet kötelező megadni!");
            app.showError(__("Hiba"), errorMsg);
            validationMessages.set("certno_prefix", errorMsg);
        }
        if (!whiteSpaceChecker(trainingRecord.verno_prefix)) {
            errorMsg = __("A tanúsítványszám prefixet kötelező megadni!");
            app.showError(__("Hiba"), errorMsg);
            validationMessages.set("verno_prefix", errorMsg);
        }

        if (validationMessages.size > 0) {
            this.setState({ loading: false, validationMessages });
            return;
        }

        if (saveAsCopy) trainingRecord.id = undefined;
        try {
            trainingRecord = (await (new TrainingTypeCrud(trainingRecord).put())).record;
            app.showSuccess(__("Mentés"), __("Sikeres mentés!"));
            this.asyncReload();            
        } catch (error) {
            app.showErrorFromJsonResult(error);            
        }       
    }

    render() {
        const trainingRecord = this.state.trainingRecord;

        if (this.state.loading || !trainingRecord) return <BubbleLoader />;

        return (<PermissionPage requirements={{
            crud: { tableInfoId: TrainingTypeCrud.TABLE_INFO_ID, permissions: CrudPermissionType.U }
        }}>
            <div className="row expanded">
                <div className="column small-12">
                    <Breadcrumbs links={[
                        { name: __("KSZR"), path: courseModule.PATH_COURSE_ORGANIZER },
                        { name: __("Képzés típusok"), path: courseModule.PATH_COURSE_ORGANIZER_TRAINING_ADMIN },
                        { name: __("Szerkesztés") },
                    ]} />
                </div>
                <div className="column" style={{ alignSelf: "center" }}>
                    <ButtonGroup size={ButtonSize.Normal} align={ButtonAlign.Expanded}>

                        <button className="button success expanded eke-general-buttons" onClick={this.onSave.bind(this, false)} disabled={(!this.state.isDataChanged)}>
                            <i className="fa fa-save" /> {trainingRecord.id ? __("Módosítások mentése") : __("Létrehozás")}
                        </button>
                        {
                            trainingRecord.id &&
                            <button className="button success expanded eke-general-buttons" onClick={this.onSave.bind(this, true)}>
                                <i className="fa fa-copy" /> {__("Másolat készítése")}
                            </button>
                        }
                    </ButtonGroup>
                </div>
                <div className="small-12 large-12 column clearfix">
                    <div className="row">
                        <div className="column large-12 medium-12 small-12">
                            <h4>{__("Képzés típus szerkesztése")}</h4>
                        </div>
                    </div>
                    <div className="row">
                        <div className="large-12 small-12 columns">
                            <label>{__("Képzés típus neve")}<span style={{ color: "#b30000" }}>*</span>
                                <span className="exe-editor-validation-msg">{this.state.validationMessages.get("title")}</span>
                                <input type="text"
                                    value={trainingRecord.title || undefined}
                                    onChange={(e) => this.onFieldChange(e, "title")} />
                            </label>
                        </div>
                        <div className="large-6 small-12 columns">
                            <label>{__("Képzés típus kódja")}<span style={{ color: "#b30000" }}>*</span>
                                <span className="exe-editor-validation-msg">{this.state.validationMessages.get("code")}</span>
                                <input type="text"
                                    value={trainingRecord.code || undefined}
                                    onChange={(e) => this.onFieldChange(e, "code")} />
                            </label>
                        </div>
                        <div className="large-6 small-12 columns">
                            <label>{__("Akreditációs engedély szám")}
                                <input type="text"
                                    value={trainingRecord.license_number || undefined}
                                    onChange={(e) => this.onFieldChange(e, "license_number")} />
                            </label>
                        </div>

                        <div className="large-6 small-12 columns">
                            <label>{__("Tanúsítványszám prefix")}<span style={{ color: "#b30000" }}>*</span>
                                <span className="exe-editor-validation-msg">{this.state.validationMessages.get("certno_prefix")}</span>
                                <input type="text"
                                    value={trainingRecord.certno_prefix || undefined}
                                    onChange={(e) => this.onFieldChange(e, "certno_prefix")} />
                            </label>
                        </div>
                        <div className="large-6 small-12 columns">
                            <label>{__("KAP specifikus Igazolás sorszám prefix")}<span style={{ color: "#b30000" }}>*</span>
                                <span className="exe-editor-validation-msg">{this.state.validationMessages.get("verno_prefix")}</span>
                                <input type="text"
                                    value={trainingRecord.verno_prefix || undefined}
                                    onChange={(e) => this.onFieldChange(e, "verno_prefix")} />
                            </label>
                        </div>
                        <div className="large-12 small-12 columns">
                            <label>{__("Tananyag")}
                                <span className="exe-editor-validation-msg">{this.state.validationMessages.get("verno_prefix")}</span>
                                <LookupEdit
                                    key={"book_" + (trainingRecord.book_id || 'null')}
                                    fk_table_info_id={BookCrud.TABLE_INFO_ID}
                                    clearable={true}
                                    valueColumn="id"
                                    value={trainingRecord.book_id || null}
                                    selectColumnNames={["id", "title"]}
                                    displayColumnNames={["title"]}
                                    searchColumnNames={["title"]}
                                    orderByColumnNames={"title"}
                                    emptyLoad={true}
                                    filter={{site_id:config.mainServer.siteId}}
                                    onChange={(newValue) => this.onFieldChangedWithValue(Number(newValue), "book_id")}
                                />
                            </label>
                        </div>

                        <div className="large-6 small-12 columns">
                            <label>
                                <input type="checkbox"
                                    checked={trainingRecord.is_online === true}
                                    onChange={(e) => this.onFieldChange(e, "is_online")}
                                />{__("Távoktatás")}
                            </label>
                        </div>
                        
                        <div className="large-12 small-12 columns">
                            <label>{__("Leírás")} </label>
                            <HTMLTextarea
                                value={trainingRecord.description_html || ""}
                                onChange={this.handleDescriptionChange.bind(this, "description_html")}
                            />
                        </div>
                        <div className="large-12 small-12 columns">
                            <label>{__("Kimeneti követelmények")} </label>
                            <HTMLTextarea
                                value={trainingRecord.fulfillment_conditions_html || ""}
                                onChange={this.handleDescriptionChange.bind(this, "fulfillment_conditions_html")}
                            />
                        </div>

                        <div className="large-12 small-12 columns">
                            <h5>{__("Kurzus dokumentumai")} </h5>
                            {!trainingRecord.oo_folder_id?
                            <div><span  style={{ color: "#b30000" }}>{__("Fájlok feltöltéséhez először mentse el a képzést!")}</span></div>
                            :
                            <MediaFileChooser
                            rootFolderId={trainingRecord.oo_folder_id!}
                            currentFolderId={trainingRecord.oo_folder_id!}
                            showHoverPreview={false}
                            showLocation={false}
                            showFilter={false}
                            enabledActions={[ContentActionType.FILE_DOWNLOAD, ContentActionType.FILE_MODIFY, ContentActionType.FILE_UPLOAD, ContentActionType.FILE_DELETE]}
                        />

                            }
                            </div>
                    </div>
                </div>
            </div>
        </PermissionPage>);
    }
}