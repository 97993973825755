import * as React from 'react';
import ReactTable, { TableProps, Column, RowInfo } from 'react-table';
import 'react-table/react-table.css';
import { getReactTableLabels } from '@src/framework/i18n';
import { Link, match } from 'react-router-dom';
import { subjectCrudClassProxy } from '@crud/doc/SubjectCrud'
import CrudSelect from '@framework/forms//crudselect';
import { debounce } from 'lodash';
import { app } from '@src/index';
import { IUserInfo} from '@framework/server/Auth';
import ViewLessonplan, { IViewLessonplanRecord } from '@src/framework/view/doc_pub/ViewLessonplan';
import { instituteTypeCrudClassProxy } from '@src/framework/crud/sys/InstituteTypeCrud';
import { gradeCrudClassProxy } from '@src/framework/crud/doc/GradeCrud';
import { IListParams, TFilterDict, StringSearchKind, IListOrderBy, TSpecFilterDict } from '@src/framework/crud/Crud';
import * as queryString from 'query-string';
import { replaceAll } from '@src/framework/util/StringUtils';
import { literacyFieldCrudClassProxy } from '@src/framework/crud/doc/LiteracyFieldCrud';
import { __ } from '@src/translation';
import { PATH_LESSONPLAN_PUB_VIEW } from './lessonplan_routes';

var saved = {
    instituteTypeId: undefined,
    literacyFieldId:undefined,
    owner_id: undefined,
    instituteId: undefined,
    grade_id: undefined,
    subject_id: undefined,
    engineId: undefined,
    stationId: undefined,
    dpmk_project_id: undefined,
    filters: [],
    sorted: [],
    pageSize: 10,
    page: 0,
}

type LessonPlanPubListProps = {
    match?: match<any>,
    onLessonPlanSelected?: (LessonPlanRecord: IViewLessonplanRecord) => void,
    instituteId?: any,
    institute_type_id?: any,
    literacyFieldId?:any,
    owner_id?: any,
    dpmk_project_id?: any
}
interface ILessonPlanPubListState {

    search: string,
    keyword: string | null,
    institute_type_id: any,
    instituteId: any,
    literacyFieldId:any,
    dpmk_project_id: any,
    grade_id: number | null;
    subject_id: number | null;
    owner_id: any,
    stationId: any,
    nokeywords: boolean,
    noresult: boolean,

    lesssonPlanRecords: IViewLessonplanRecord[],
    pageSize: number,
    page: number,
    count: number,
    sorted: any[],
    filters: any[],
    loading: boolean,

    currentUser?: IUserInfo | null,
    class?: any,
}

export default class LessonPlanPubList extends React.Component<LessonPlanPubListProps, ILessonPlanPubListState> {

    constructor(props: any) {
        super(props);

        this.state = {
            search: '',
            keyword: '',
            institute_type_id: props.instituteTypeId || saved.instituteTypeId,
            literacyFieldId:props.literacyFieldId|| saved.literacyFieldId,
            instituteId: props.instituteId || saved.instituteId,
            grade_id: props.grade_id || saved.grade_id,
            subject_id: props.subject_id || saved.subject_id,
            owner_id: props.owner_id || saved.owner_id,
            stationId: props.stationId || saved.stationId,
            dpmk_project_id: props.dpmk_project_id || saved.dpmk_project_id,
            nokeywords: false,
            noresult: false,

            lesssonPlanRecords: [],
            pageSize: saved.pageSize,
            page: saved.page,
            count: 0,
            sorted: saved.sorted,
            filters: saved.filters,
            loading: true,
        };
    }

    componentDidMount() {
        this.reloadAsync();
        ($(document) as any).foundation();
    }

    componentDidUpdate(prevProps: LessonPlanPubListProps) {
        if (prevProps.match != this.props.match) {
            this.reloadAsync();
        }
    }

    componentWillUnmount() {
        try {
            // TODO az alábbi sor ezt a hibát dobja tankönyvben választáskor: ReferenceError: We're sorry, '_destroy' is not an available method for this element.
            ($(this.refs.deleteConfirmationModal) as any).foundation('_destroy');
            $(this.refs.deleteConfirmationModal).remove();
        } catch (e) {
            console.error(e);
        }
    }

    onLessonPlanSelect(LessonPlanRecord: IViewLessonplanRecord) {
        if (this.props.onLessonPlanSelected) {
            this.props.onLessonPlanSelected(LessonPlanRecord);
        } else {
            app.showError(__("Hiba!"), __("Nem lehet óravázlatot választani!"));
        }
    }

    async reloadAsync() {

        try {
            this.setState({
                loading: true
            })

            var filter: TFilterDict = {};

            if (this.state.literacyFieldId) {
                filter.literacy_field_id = Number(this.state.literacyFieldId);
            }
            if (this.state.institute_type_id) {
                filter.institute_type_id = Number(this.state.institute_type_id);
            }
            if (this.state.instituteId) {
                filter.institute_id = Number(this.state.instituteId);
            }
      
            if (this.state.dpmk_project_id) {
                filter.dpmk_project_id = Number(this.state.dpmk_project_id);
            }
            if (this.state.search) {
                filter.name = { kind: StringSearchKind.Contains, case_sensitive: false, expr: this.state.search };
            }
            if (this.state.keyword && this.state.keyword.length > 0 && this.state.keyword != "") {
                filter.keywords = { kind: StringSearchKind.Contains, case_sensitive: false, expr: this.state.keyword };
            }

            for (const userFilter of this.state.filters) {
                filter[userFilter.id] = { kind: StringSearchKind.Contains, case_sensitive: false, expr: userFilter.value }
            }

            let orderBy: undefined | string | IListOrderBy[] = undefined;
            if (this.state.sorted.length > 0) {
                const userOrder = this.state.sorted[0];

                if (userOrder.desc) {
                    orderBy = [{ name: userOrder.id, desc: true }];
                } else {
                    orderBy = userOrder.id
                }
            }

            if (!orderBy) { orderBy = [{ name: "project_title", desc: true }]; }

            let spec: TSpecFilterDict = {};
            if (this.state.grade_id) {
                spec.grade_id = this.state.grade_id;
            }
            if (this.state.subject_id) {
                spec.subject_id = this.state.subject_id;
            }


            let listParameters: IListParams = {
                filter: filter,
                spec: spec,
                order_by: orderBy,
                limit: this.state.pageSize,
                offset: this.state.page * this.state.pageSize
            };

            let lesssonPlanRecords = await ViewLessonplan.list(listParameters);

            for (var lessonPlanRecord of lesssonPlanRecords) {

                const topic = document.createElement("div");
                topic.innerHTML = lessonPlanRecord.topic || "";

                lessonPlanRecord["topic"] = topic.innerText;
                lessonPlanRecord["institute_name"] = lessonPlanRecord.institute_name;
                lessonPlanRecord["institute_site"] = lessonPlanRecord.institute_site_name;
                lessonPlanRecord["owner_name"] = lessonPlanRecord.owner_name;
                lessonPlanRecord["literacy_field_name"] = lessonPlanRecord.literacy_field_name;
                lessonPlanRecord["institute_type_title"] = lessonPlanRecord.institute_type_title;
                lessonPlanRecord["institute_om_identifier"] = lessonPlanRecord.institute_om_identifier;
            }

            this.setState({
                lesssonPlanRecords,
                loading: false,
            });

            var count = await ViewLessonplan.count(listParameters);
            this.setState({ count: count });
        }
        catch (error) {
            app.showErrorFromJsonResult(error);
        }
    }

    onFetchData(tablestate: any) {
        if (this.state.filters != tablestate.filtered) {
            this.debouncedSetTableState(tablestate);
        } else {
            this.setTableState(tablestate);
        }
    }

    debouncedSetTableState = debounce((tablestate) => this.setTableState(tablestate), 300);

    private setTableState(tablestate: any) {

        saved.page = tablestate.page;
        saved.pageSize = tablestate.pageSize;
        saved.sorted = tablestate.sorted;
        saved.filters = tablestate.filtered;

        this.setState({
            page: tablestate.page,
            pageSize: tablestate.pageSize,
            sorted: tablestate.sorted,
            filters: tablestate.filtered,
        }, this.reloadAsync);
    }

    private onInstituteTypeSelect = (sender: CrudSelect, institute_type_id: number | null): void => {
        saved.instituteTypeId = institute_type_id as any;
        this.setState({ institute_type_id }, this.reloadAsync);
    }
    
    private onliteracyFieldIdSelect = (sender: CrudSelect, literacy_id: number | null): void => {
        saved.literacyFieldId = literacy_id as any;
        this.setState({ literacyFieldId:literacy_id }, this.reloadAsync);
    }

    private onGradeSelect = (sender: CrudSelect, grade_id: number | null): void => {
        if (grade_id) {
            grade_id = parseInt(grade_id.toString());
        }
        saved.grade_id = grade_id as any;
        this.setState({ grade_id }, this.reloadAsync);
    }

    private onSubjectSelect = (sender: CrudSelect, subject_id: number | null): void => {
        if (subject_id) {
            subject_id = parseInt(subject_id.toString());
        }
        saved.subject_id = subject_id as any;
        this.setState({ subject_id }, this.reloadAsync);
    }


    showConfirmModal(lessonplan: IViewLessonplanRecord) {
        ($(this.refs.deleteConfirmationModal) as any).foundation('open');
        if (lessonplan && lessonplan.id) {
            $('#currentLessonplanId').val(lessonplan.id);
        }
    }

    closeConfirmModal() {
        ($(this.refs.deleteConfirmationModal) as any).foundation('close');
    }

    render() {
        var filteredRecords = this.state.lesssonPlanRecords;

        const getTdProps = (finalState: any, rowInfo: RowInfo, column?: Column, instance?: any) => {
            let props: Partial<TableProps> = {};
            if (column && rowInfo && rowInfo.row) {
                let row = rowInfo.row._original;
                if (row && column.id == "wf_station_name") {
                    props.style = row.wf_station_style || {};
                }
                if (row && column.id == "grades") {
                    let repstring:string=row.grades;
                    row.grades=replaceAll(repstring,",",", ");
                }
            }
            return props;
        }
        //here
        const tableProps = {
            columns: [
                { Header: __("Téma"), accessor: "topic", minWidth: 300, 
                 Cell: (data: any, column: any) => {
                    const row: IViewLessonplanRecord = data.original;
                    return <Link to={PATH_LESSONPLAN_PUB_VIEW + `/${row.id}/?${queryString.stringify(data.original.filter_params)}`} style={{ width: "100%" }}>
                      <b>{row.topic}</b><br />
                    
                    </Link>;
                  }
            },
            //here
                // { Header: "Intézmény neve", accessor: "institute_name", minWidth: 120 },           
                { Header: __("Iskolatípus"), accessor: "institute_type_title", filterable: true, minWidth: 300, sortable: false},
                { Header: __("Műveltségterület"), accessor: "literacy_field_name", filterable: true, minWidth: 200, sortable: false},
                { Header: __("Évfolyamok"), accessor: "grades", filterable: false, minWidth: 150 },
                { Header: __("Tantárgyak"), accessor: "subjects", filterable: false, minWidth: 200},
            ],

            data: filteredRecords,
            defaultPageSize: this.state.pageSize,
            defaultFiltered: this.state.filters,
            defaultSorted: this.state.sorted,
            pages: Math.ceil(this.state.count / this.state.pageSize),
            filterable: true,
            className: "-striped -highlight",
            ...getReactTableLabels(),
            onFetchData: this.onFetchData.bind(this),
            loading: this.state.loading,
            getTdProps,
            manual: true
        };

        return (
            <div className="exercise-list-page row exerciseList-maincomponent align-center">
                <h3>{__("Óravázlattár")}</h3>

                    {
                        this.props.onLessonPlanSelected ? ""
                            :
                            <div className="small-12 medium-12 column">
                            </div>
                    }

                   
                    <div className="medium-12 columns row exerciselist-filterContainer">                  
                    <div className="small-12 medium-6 column">
                            <label className="lms-pages-typeLabel">{__("Műveltségterület")}</label>
                            <CrudSelect
                                value={this.state.literacyFieldId}
                                onSelect={this.onliteracyFieldIdSelect}
                                displayFieldName="name"
                                key="id"
                                emptyTitle={__("Minden terület")}
                                crudClassProxy={literacyFieldCrudClassProxy}
                            />
                        </div>           
                        <div className="small-12 medium-6 column">
                            <label className="lms-pages-typeLabel">{__("Iskolatípus")}</label>
                            <CrudSelect
                                value={this.state.institute_type_id}
                                onSelect={this.onInstituteTypeSelect}
                                displayFieldName="title"
                                key="id"
                                emptyTitle={__("Minden iskola")}
                                crudClassProxy={instituteTypeCrudClassProxy}
                            />
                        </div>
                        <div className="small-12 medium-6 column">
                            <label className="lms-pages-typeLabel">{__("Évfolyam")}</label>
                            <CrudSelect
                                value={this.state.grade_id}
                                onSelect={this.onGradeSelect}
                                displayFieldName="name"
                                key="id"
                                emptyTitle={__("Minden évfolyam")}
                                crudClassProxy={gradeCrudClassProxy}
                                filter={{ is_active: true }}
                                sortFunc={(a, b) => a.name.localeCompare(b.name, undefined, { numeric: true, sensitivity: 'base' })}
                            />
                        </div>
                        <div className="small-12 medium-6 column">
                            <label className="lms-pages-typeLabel">{__("Tantárgy")}</label>
                            <CrudSelect
                                value={this.state.subject_id}
                                onSelect={this.onSubjectSelect}
                                displayFieldName="name"
                                key="id"
                                emptyTitle={__("Minden tantárgy")}
                                crudClassProxy={subjectCrudClassProxy}
                            />
                        </div>                                                    
                    </div>
                    <div className="small-12 medium-12">
                        <label className="result-count">&nbsp;{this.state.count ? __("{n} találat", {n: this.state.count}) : __("0 találat")}</label>

                        <ReactTable {...tableProps} />
                    </div>
              
            </div>
        );
    }
}
