import * as React from 'react';
import { Tabs, TabList, Tab, TabPanel } from 'react-tabs';


import  { IInquiryCategoryRecord, InquiryCategoryCrudClassProxy, inquiryCategoryCrudClassProxy } from '@src/framework/crud/ticket/InquiryCategoryCrud';
import RecordEditor from '@framework/forms/recordeditor';
import { InquiryCategoryUserListViewForInquiryCategory } from './inquiry_category_user_listview';
import { __ } from '@src/translation';

export default class InquiryCategoryEditor extends RecordEditor<IInquiryCategoryRecord> {

    public getCrudClassProxy() : InquiryCategoryCrudClassProxy { return inquiryCategoryCrudClassProxy;  }  


    protected getDetailPanels(): any[] {
        let result = super.getDetailPanels();
        if (this.state.rec_id) {
            result = result.concat(
                <div className="row">
                    <div className="column small-12">
                        <Tabs>
                            <TabList>
                                <Tab>{__("E-SZTR felelősök")}</Tab>
                            </TabList>
                            <TabPanel>
                                    <InquiryCategoryUserListViewForInquiryCategory
                                        key="users_editor" inquiry_category_id={this.state.rec_id!}
                                    />
                            </TabPanel>
                        </Tabs>
                    </div>
                </div>
            );
        }
        return result;
    }    
}