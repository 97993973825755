import * as React from "react";
import { IViewInstituteGroupMemberRecord } from "@src/framework/view/sys/ViewInstituteGroupMember";
import { IViewSharedWithMeRecord } from "@src/framework/view/usr/ViewSharedWithMe";
import { IViewSharedFromMyGroupRecord } from "@src/framework/view/usr/ViewSharedFromMyGroup";
import SharedWithCrud from "@src/framework/crud/usr/SharedWithCrud";
import { ExerciseEvaluationHelper, ExerciseSubmissionStates } from "@src/component/exercise/ExerciseEvaluationHelper";
import { me } from "@src/framework/server/Auth";
import { ListItem, ListItemImage, ListItemText, ListItemInfo, ListItemMenu } from "@src/component/ui/List";
import { ContentDetails } from "@src/component/filemanager/ContentUtils";
import { __ } from "@src/translation";
import { SHARE_MEGOSZTAS_ID } from "@src/Const";
import { PATH_SHARED_CONTENT_BY_ME, PATH_SHARED_CONTENT_WITH_ME } from "@src/Routes";
import { Link } from "react-router-dom";

type SubmissionDetails = {
    id: number,
    subbmitted: number,
    total: number
}

interface ISharedContentRecord {
    id?: number,
    title?: string,
    /**
     * Ki osztotta meg
     */
    shared_by_id?: number;
    /**
     * Mit osztott meg- Táblázat infó
     *
     * Ez mondja meg, hogy a megosztott tartalom melyik táblázat rekordjához tartozik.
     */
    table_info_id?: number;
    /**
     * Mit osztott meg - rekord azonosító
     *
     * Ez mondja meg, hogy a megosztott tartalom melyik rekordhoz tartozik.
     */
    rec_id?: number;
    /**
     * Megosztás módja
     */
    share_mode_id?: number;
    /**
     * Megosztás kezdő időpontja
     */
    start_date?: string /*timestamp?*/;
    /**
     * Megosztás befejező időpontja
     */
    end_date?: string /*timestamp?*/ | null;
    /**
     * Leadás határideje
     *
     * A leadás határideje csak határidős kiosztásoknál van értelmezve.
     */
    due_date?: string /*timestamp?*/ | null;
    /**
     * Max. pontszám
     */
    max_points?: number /*int4*/;
    /**
    * Megosztás módja
    *
    * Megosztás módja
    */
    share_mode_title?: string | null;
    /**
     * Saját legjobb eredményem %
     *
     * Saját legjobb eredményem %
     */
    success_percent?: number /* float8 */ | null;
}

type ClassroomTasksListItemState = {
    sharedByMeDetail?: SubmissionDetails,
    earnedPoints: number,
    successPercent: number
}

type ClassroomTasksListItemProps = {
    isGroupAdmin: boolean,
    studentCount?: number,
    members: IViewInstituteGroupMemberRecord[],
    sharedWithMe?: IViewSharedWithMeRecord,
    sharedByMe?: IViewSharedFromMyGroupRecord,
    itemDetail?: ContentDetails,
    shareDialogOpenCallBack?: (numOfSubmissions:number) => void,
    deleteSharedContentCallBack?: (numOfSubmissions:number) => void,
    filterNotStarted:boolean
}

export class ClassroomTasksListItem extends React.Component<ClassroomTasksListItemProps, ClassroomTasksListItemState> {

    constructor(props: any) {
        super(props);

        this.state = {
            earnedPoints: 0,
            successPercent: 0
        }
    }

    componentDidMount() {
        this.asyncReload();
    }

    async asyncReload() {
        if (!me) {
            return;
        }

        try {

            if (this.props.isGroupAdmin && this.props.sharedByMe) {
                const elementId = this.props.sharedByMe.id!;
                // getting the individually shared submissions
                let swList = (await SharedWithCrud.list({ filter: { shared_content_id: elementId, institute_group_id: null } }));
                let count = await ExerciseEvaluationHelper.getNumberOfSubmissions(this.props.members, this.props.sharedByMe);
                let subD: SubmissionDetails = { id: elementId, subbmitted: count, total: swList.length > 0 ? swList.length : (this.props.studentCount || 0) };

                this.setState({
                    sharedByMeDetail: subD
                })

            } else if (this.props.sharedWithMe) {
                const elementId = this.props.sharedWithMe.id!;
                const succPercent = (await ExerciseEvaluationHelper.getUserSuccessPercent(me!.id, elementId));
                const maxPoints = this.props.sharedWithMe.max_points || 1;
                const earnedPoints = (Math.round(maxPoints * (this.props.sharedWithMe.success_percent || 0)));

                this.setState({
                    successPercent: succPercent,
                    earnedPoints
                })
            }
        } catch (error) {
            console.log("error", error);
        }

    }

    render() {

        if (!this.props.itemDetail) return __("Nincs adat");
        let item: ISharedContentRecord = {};
        let url = "";
        let labelClassName = "not-started";
        let resultText = __("Még nem kezdtél hozzá");

        if (this.props.isGroupAdmin) {
            item = (this.props.sharedByMe as ISharedContentRecord);
            if (item.shared_by_id == me!.id) {
                url = (item.share_mode_id == SHARE_MEGOSZTAS_ID) ? (this.props.itemDetail.viewUrl) : PATH_SHARED_CONTENT_BY_ME + "/" + item.id;
            }
        } else {
            if (this.props.filterNotStarted && this.state.successPercent && this.state.successPercent != ExerciseSubmissionStates.NotStarted) {
                return null;
            }
            item = (this.props.sharedWithMe as ISharedContentRecord);
            url = (item.share_mode_id == SHARE_MEGOSZTAS_ID) ? (this.props.itemDetail.viewUrl) : PATH_SHARED_CONTENT_WITH_ME + "/" + item.id;
            if (this.state.successPercent != undefined) {
                if (this.state.successPercent > -1) {
                    resultText = __("Eredmény") + ": " + (Math.round(this.state.successPercent * 100)) + "%";
                    labelClassName = "evaluated";
                } else if (this.state.successPercent == ExerciseSubmissionStates.NotEvaluated) {
                    resultText = __("Beküldve");
                    labelClassName = "submitted orange";
                } else if ((this.state.successPercent == ExerciseSubmissionStates.NotSubmitted)) {
                    resultText = __("Elkezdve");
                    labelClassName = "submitted";
                }
            }
        }

        let title = "";
        if(this.props.itemDetail.type){
            title = (this.props.itemDetail.type == "Nyílt végű feladat" ? "Szöveges feladat " : (this.props.itemDetail.type + " "));
        }
        if(item.share_mode_id){
            title += "(" + item.share_mode_title!.toLowerCase() + ")";
        }
        title += ": ";
        // title = "" + ((this.props.itemDetail.type ? (this.props.itemDetail.type == "Nyílt végű feladat" ? "Szöveges feladat " : (this.props.itemDetail.type + " ")) : "") + (this.props.itemDetail.type ? ("(" + item.share_mode_title!.toLowerCase() + ")") : item.share_mode_title!) + ": ");
        //var title = "" + ((this.state.itemDetail.type ? (this.state.details[index]!.type == "Nyílt végű feladat" ? "Szöveges feladat " : (this.state.details[index]!.type + " ")) : "") + ( this.state.details[index]!.type ? ("(" + item.share_mode_title!.toLowerCase() + ")") : item.share_mode_title!) + ": ");
        var exeTitle = item.title;


        return <ListItem key={item.id}>
            <ListItemImage src={this.props.itemDetail.thumbnailUrl} />

            <ListItemText>
                {url ?
                    <Link to={url || ""} title={title + item.title}>
                        {title}<strong>{exeTitle}</strong>
                    </Link>
                    :
                    <label title={title}>{item.share_mode_title}: <strong>{item.title}</strong></label>
                }
                <div>
                    {this.props.itemDetail.displayName}
                </div>
                <small>{__("Elérhető") + ": "} {item.start_date} {item.end_date && " - " + item.end_date}</small>
            </ListItemText>

            <ListItemInfo>
                {
                    item.due_date || (item.shared_by_id != me!.id)
                        ?
                        <div className="classroom-task-info-div">
                            {
                                item.due_date &&
                                <div className="info-cell">
                                    <span className="hint" style={{ fontSize: "0.8rem", color: "#4c7484" }}>{__("Leadás határideje")}</span>
                                    <label className="deadline float-right"><i className="far fa-clock"></i>&nbsp;{item.due_date}</label>
                                </div>
                            }
                            {
                                ( this.props.isGroupAdmin && item.shared_by_id != me!.id) &&
                                <div className="info-cell">
                                    <label className="deadline float-right"><i className="fas fa-user-lock"></i>&nbsp;{__("Nem saját")}</label>
                                </div>
                            }
                        </div>
                        : null
                }


                {this.props.isGroupAdmin ?
                    <div className="classroom-task-info-div">
                        <div>
                            {this.state.sharedByMeDetail && item.share_mode_id != SHARE_MEGOSZTAS_ID && <label className="label warning">{__("Leadva")}: {this.state.sharedByMeDetail.subbmitted} / {this.state.sharedByMeDetail.total}</label>}
                        </div>
                    </div>
                    :
                    <div className="classroom-task-info-div">
                        <div>
                            {item.share_mode_id != SHARE_MEGOSZTAS_ID && <label className={"label float-right " + labelClassName}>{resultText}</label>}
                        </div>
                    </div>
                }
            </ListItemInfo>

            {item.shared_by_id == me!.id &&
                <ListItemMenu
                    mainItems={[
                        {
                            icon: <i className="fas fa-fw fa-cogs"></i>,
                            type: "primary",
                            onClick: () => { this.props.shareDialogOpenCallBack && this.state.sharedByMeDetail ? this.props.shareDialogOpenCallBack(this.state.sharedByMeDetail!.subbmitted) : {} }
                        },
                        {
                            icon: <i className="fa fa-fw fa-trash" />,
                            type: "alert",
                            onClick: () => { this.props.deleteSharedContentCallBack && this.state.sharedByMeDetail ? this.props.deleteSharedContentCallBack(this.state.sharedByMeDetail!.subbmitted) : {} }
                        }
                    ]}
                />
            }

        </ListItem>


    }
}