import * as React from 'react';
import { match } from 'react-router';
import ViewOoFileInBook, { IViewOoFileInBookRecord } from '@src/framework/view/doc/ViewOoFileInBook';
import BookCrud, { IBookRecord } from '@src/framework/crud/doc/BookCrud';
import { LIBRARY_OFI_OFFICIAL_ID } from '@src/Const';
import { Link } from 'react-router-dom';
import { PATH_CONTENT_STORE, PATH_PUBLISHED_BOOK, PATH_PUBLISHED_MEDIA_VIEW } from '@src/Routes';
import { app } from '@src/index';
import ViewDocSectionFileRef, { IViewDocSectionFileRefRecord } from '@src/framework/view/doc/ViewDocSectionFileRef';
import { BubbleLoader } from 'react-css-loaders';
import { IOoFileRecord } from '@src/framework/crud/media/OoFileCrud';
import OoFolderCrud from '@src/framework/crud/media/OoFolderCrud';
import OoFileCrud from '@src/framework/crud/media/OoFileCrud';
import { OriginTable } from './OFIOriginTablePage';
import OriginCrud, { IOriginRecord } from '@src/framework/crud/media/OriginCrud';
import { __ } from '@src/translation';
import { MetaKeywordAssignerPlugin, fetchKeywords, setKeywords } from '@src/component/meta/meta_keyword_assigner_plugin';
import { IMediaTypeRecord, mediaTypeCrudClassProxy } from '@src/framework/crud/media/MediaTypeCrud';
import CrudSelectComponent from '@src/framework/forms/crudselect';
import obtainServer from '@src/framework/server/Server';
import ReactTable from 'react-table';
import { getReactTableLabels } from '@src/framework/i18n';

type OFIScormListPageProps = {
    match: match<{ index?: string }>;
}

type OFIScormListPageState = {
    files: IOoFileRecord[];
}

export class OFIScormListPage extends React.Component<OFIScormListPageProps, OFIScormListPageState> {

    constructor(props: OFIScormListPageProps) {
        super(props);

        this.state = {
            files: [],
        }
    }

    componentDidMount() {
        this.reloadAsync();
    }

    async reloadAsync() {

        try {

            const response = await obtainServer().get<{scormList: IOoFileRecord[]}>("get_old_scorm_assets");
            
            this.setState({
                files: response.scormList
            })
        } catch (e) {
            app.showErrorFromJsonResult(e);
        }
    }

    render() {
        if (this.state.files.length === 0) {
            return <BubbleLoader />;
        }
        
        return <ReactTable
                    columns={[
                        {Header: "Név", accessor: "displayname", width: 300,
                            Cell: (data) => {
                                console.log("data.row", data.row);
                                return <a href={PATH_PUBLISHED_MEDIA_VIEW + "/" + data.row["_original"]["id"]} target="_new">
                                    {data.row["displayname"]}
                                </a>
                            }
                        },

                        {Header: "Tartalomtár mappa", accessor: "relpath",
                            Cell: (data) => {
                                return <a href={PATH_CONTENT_STORE + "/kozos/" + data.row["_original"]["oo_folder_id"]} target="_new">
                                    {data.row["relpath"]}
                                </a>
                            }
                        },
                    ]}
                    data={this.state.files}
                    filterable={true}
                    className="-striped -highlight"
                    {...getReactTableLabels()}

                    defaultFilterMethod={(filter, row, column) => row[filter.id].toLowerCase().indexOf(filter.value.toLowerCase()) != -1}
                    
                />
/*
        return this.state.files.map((f, index) => {
            return <div key={index}>
                <Link to={>{f.displayname}</Link>
            </div>
        });*/

    }

}
