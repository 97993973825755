import * as React from 'react';
import { BubbleLoader } from 'react-css-loaders';
import { IViewKapCourseRecord } from '@src/framework/view/kap/ViewKapCourse';
import { IViewKapTrainingTypeRecord } from '@src/framework/view/kap/ViewKapTrainingType';
import ForumCrud, { IForumRecord } from '@src/framework/crud/usr/ForumCrud';
import { app } from '@src/index';
import { __ } from '@src/translation';
import CourseCrud from '@src/framework/crud/kap/CourseCrud';
import ForumContainer from '@src/module/forumModule/component/ForumContainer';

interface ICourseDetailsForumPageProps {
    courseRecord: IViewKapCourseRecord;
    trainingType: IViewKapTrainingTypeRecord;
}

interface ICourseDetailsForumPageState {
    forum : IForumRecord|null;
    loading : boolean;
}

export class CourseDetailsForumPage extends React.Component<ICourseDetailsForumPageProps, ICourseDetailsForumPageState> {

    constructor(props: ICourseDetailsForumPageProps) {
        super(props);
        this.state = {forum:null, loading:true};
    }

    public componentDidMount() {
        this.asyncReload();
    }

    public componentDidUpdate(prevProps: ICourseDetailsForumPageProps) {
        if (prevProps.courseRecord.id !== this.props.courseRecord.id
            || prevProps.trainingType.id !== this.props.trainingType.id
        ) {
            this.asyncReload();
        }
    }

    private async asyncReload() {
        try {
            const forums = await ForumCrud.list({
                filter: {
                    table_info_id: CourseCrud.TABLE_INFO_ID,
                    rec_id: this.props.courseRecord.id!,
                    is_active: true
                }
            });
            if (forums && forums.length) {
                this.setState({forum: forums[0], loading:false});
            } else {
                this.setState({forum: null, loading:false});
            }

        } catch (e) {
            app.showErrorFromJsonResult(e);
        }
    }

    render() {
        if (this.state.loading) {
            return <BubbleLoader />
        }
        if (this.state.forum === null) {
            return <p className="callout secondary">{__("Ehhez a kurzushoz nincs indítva fórum.")}</p>
        }
        return <ForumContainer 
            forumId={this.state.forum.id!} 
            storeId={"ksz_course/"+this.state.forum.id!}
            showTopics={true /* ezt csak a példa kedvéért, egyébként ha csak default topic van akkor nem mutatja */ }
            showMembers={true}
        />
    }

}
