import obtainServer from "@src/framework/server/Server";

type ApiResult<RESULT> = {
    success: true, 
    result: RESULT
} | {
    success: false,
    errorCode: string,
    error: string,
}

export async function callApiThrowOnError<PARAMS, RESULT>(path: string, parameters: PARAMS): Promise<RESULT> {
    if (path.startsWith("/")) path = path.substr(1);
    const response = await obtainServer().post<ApiResult<RESULT>>(path, parameters);
    if (response.success) {
        return response.result;
    } else {
        throw new Error(response.error);
    }
}
