import * as React from 'react';
import ReactTable, { RowInfo, Column, TableProps } from 'react-table'
import 'react-table/react-table.css'
import { getReactTableLabels } from '@framework/i18n';

import { Link, match } from 'react-router-dom';
import * as queryString from 'query-string';
import SubjectCrud, { ISubjectRecord } from '@crud/doc/SubjectCrud'
import GradeCrud, { IGradeRecord } from '@src/framework/crud/doc/GradeCrud';
import CrudSelect from '@framework/forms//crudselect';
import { subjectCrudClassProxy } from '@framework/crud/doc/SubjectCrud';
import { gradeCrudClassProxy } from '@framework/crud/doc/GradeCrud';
import * as PrivExerciseSeriesCrud from '@src/framework/crud/exc/ExerciseSeriesCrud';
import * as PubExerciseSeriesCrud from '@src/framework/crud/exc_pub/ExerciseSeriesCrud';
import { IViewExcSeriesRecord } from '@src/framework/view/exc/ViewExcSeries';
import * as PrivateViewExerciseSerie from '@src/framework/view/exc/ViewExcSeries';
import * as PublicViewExerciseSerie from '@src/framework/view/exc_pub/ViewExcSeries';
import { debounce } from 'lodash'
import { app } from '@src/index';
import { hasGroup, Groups, me, hasAnyGroup } from '@framework/server/Auth';

import { WF_TYPE_OKOSFELADATSOR_ID, LIBRARY_PERSONAL_ID } from '@src/Const';

import PermissionPage from '@framework/PermissionPage';
import { ViewPermissionType, PermissionCache, WfDeleteTransitionInfo } from '@src/framework/security/PermissionCache';
import {
  PATH_EXERCISE_SERIES_CREATE,
  PATH_EXERCISE_SERIES_EDIT,
  PATH_EXERCISE_SERIES_VIEW,
  PATH_EXERCISE_SERIES_PUB_VIEW,
  PATH_ADAPTIVE_EXERCISE_SERIES_EDIT,
  PATH_ADAPTIVE_EXERCISE_SERIES_CREATE,
  PATH_GUIDE_EDITOR
}
  from '@src/Routes';

import { getPlayData, copyPublishedExerciseSeries } from '@src/server/PublicServer';
import { IViewTransitionTypeRecord } from '@src/framework/view/wf/ViewTransitionType';
import WfAPI from '@src/framework/wf/WfAPI';
import obtainServer, { config } from '@src/framework/server/Server';
import { IViewWfWorkflowRecord } from '@src/framework/view/wf/ViewWfWorkflow';
import LevelSelectComponent from '@src/framework/forms/level_select';
import { stationCrudClassProxy } from '@src/framework/crud/wf/StationCrud';
import { LIBRARY_OFI_OFFICIAL_ID } from '@src/Const';
import { getWfStateCell } from '@src/component/wf/wf_util';
import { formatDate } from '@src/Util';
import { Accordion, AccordionItem } from '@src/component/ui/Accordion';

import UsrShareDialog from '@src/component/usr/UsrShareDialog';
import { ISharedContentRecord } from '@src/framework/crud/usr/SharedContentCrud';
import { Dialog, confirmDialog, promptDialog } from '@src/component/Dialog';
import { StringSearchKind, TFilterDict } from '@src/framework/crud/Crud';

import { RadioTab, RadioTabs } from '@src/component/ui/RadioTab';
import ViewExerciseSeriesItem from '@src/framework/view/exc/ViewExerciseSeriesItem';
import { __, getLanguageId } from '@src/translation';
import ViewMyExcSeries, { IViewMyExcSeriesRecord } from '@src/framework/view/exc/ViewMyExcSeries';
import ViewEditorExcSeries, { IViewEditorExcSeriesRecord } from '@src/framework/view/exc/ViewEditorExcSeries';
import { IExerciseSeriesView } from '../models/IExerciseSeriesView';
import { whiteSpaceChecker } from '@src/component/WhiteSpaceChecker';
import CollectionItemCrud from '@src/framework/crud/usr/CollectionItemCrud';
import FavouriteCrud from '@src/framework/crud/usr/FavouriteCrud';
import { exerciseModule } from '@src/module/exerciseModule/exerciseModule';
import { langTranslationCrudClassProxy } from '@src/framework/crud/sys/LangTranslationCrud';

type ExerciseSeriesSearchParams = {
  exerciseSeriesType?: string,
  internal_code?: string,
  search?: string,
  nokeywords?: boolean,
  notinbook?: boolean,
  keywords?: string,
  description?: string,
  subjectId?: number,
  gradeId?: number,
  stationId?: number,
  selectedExeLevel?: number | null,
  filters: any[],
  sorted: any[],
  pageSize: number,
  page: number,
  libraryId?: number,
  isSNI?: boolean,
  accessibility?: number | null,
  origin?:ExerciseSeriesOriginTypes,
  lang_id?: number;
}

enum ExerciseSeriesOriginTypes{
  OWN = 1,
  OFFICIAL = 2,
  COLLECTION_PUBLISHED = 3,
  FAVORITS_PUBLISHED = 4,
  ALL_PUBLISHED = 5,
}

var saved: {
  search?: string,
  keywords?: string,
  notinbook?: boolean,

  exerciseSeriesType?: string,
  subjectId?: number,
  gradeId?: number,
  stationId?: number,
  selectedExeLevel: number | null,
  filters: [],
  sorted: [],
  pageSize: number,
  page: number,
  libraryId?: number,
  origin?:ExerciseSeriesOriginTypes,
  lang_id?: number;

} = {
  subjectId: undefined,
  notinbook: undefined,
  keywords: undefined,
  search: undefined,
  exerciseSeriesType: undefined,
  gradeId: undefined,
  selectedExeLevel: null,
  libraryId: undefined,
  stationId: undefined,
  filters: [],
  sorted: [],
  pageSize: 10,
  page: 0,
  origin: ExerciseSeriesOriginTypes.OWN,
  lang_id: undefined
}

type ExerciseSeriesListProps = {
  match?: match<any>,
  onExerciseSeriesSelected?: (exerciseSeriesRecord: PrivateViewExerciseSerie.IViewExcSeriesRecord, tableInfoId?: number) => void,
  grade_id?: any,
  subject_id?: any,
  canChangeLibraryId?: boolean,
  isPublicDisabled?: boolean;
  openInNewTab?: boolean;
  onlyPublished?: boolean;
}

interface IExerciseSeriesListState {  
  searchParams: ExerciseSeriesSearchParams,  
  exerciseSeriesRecords: IExerciseSeriesView[];  
  count: number,  
  loading: boolean,
  subjects: ISubjectRecord[];
  grades: IGradeRecord[];
  saving: boolean;  
  exerciseActiveDeleteTransitonTypes?: TransitionTypeMap;
  canDeleteFromStationIds: Set<number>;
  deleteTransitionInfo?: WfDeleteTransitionInfo;
  shareExerciseSeriesId: number | null;
  shareExerciseListName: string | undefined;
  sharedContentId: number | null | undefined;

  checked: Set<number>;
  showForcePublishingDialog: boolean;
  publishPercent: number;

  showReopenDialog: boolean;
}

type TransitionTypeMap = { [stationId: number]: IViewTransitionTypeRecord[] };

export default class ExerciseSeriesList extends React.Component<ExerciseSeriesListProps, IExerciseSeriesListState> {

  constructor(props: any) {
    super(props);
    
    let originValue = ExerciseSeriesOriginTypes.OWN;
    let libId = saved.libraryId || LIBRARY_PERSONAL_ID;
    if(libId == LIBRARY_OFI_OFFICIAL_ID){
      originValue = ExerciseSeriesOriginTypes.OFFICIAL;
    } else if(libId == 0){
      originValue = ExerciseSeriesOriginTypes.ALL_PUBLISHED;
    }

    this.state = {
      searchParams: {
        search: saved.search || '',
        keywords: saved.keywords || '',
        //description: saved.description || '',
        //internal_code: saved.internal_code || '',
        subjectId: props.subject_id || saved.subjectId,
        gradeId: props.grade_id || saved.gradeId,
        exerciseSeriesType: saved.exerciseSeriesType || '',
        stationId: saved.stationId || props.stationId || undefined,
        selectedExeLevel: props.selectedExeLevel || saved.selectedExeLevel,
        notinbook: saved.notinbook || false,
        libraryId: libId,
        pageSize: saved.pageSize,
        page: saved.page,
        sorted: saved.sorted,
        filters: saved.filters,
        accessibility: null,
        origin: originValue,
        lang_id: saved.lang_id
      },
      
      exerciseSeriesRecords: [],
      count: 0,
      loading: true,
      subjects: [],
      grades: [],
      saving: false,
      exerciseActiveDeleteTransitonTypes: undefined,
      canDeleteFromStationIds: new Set([]),
      shareExerciseSeriesId: null, sharedContentId: undefined,
      shareExerciseListName: "",

      checked: new Set([]),
      showForcePublishingDialog: false,
      publishPercent: 0,
      showReopenDialog: false
    };

  }

  componentDidMount() {
    this.reloadAsync();
  }

  async componentDidUpdate(prevProps: ExerciseSeriesListProps) {
    if (prevProps.match != this.props.match) {
      this.reloadAsync();
    }
  }


  async tempMigrateOldNkpSeries() {

    let currSerie = (await PrivExerciseSeriesCrud.default.load(977158577)).record;
    let curr_serie = await getPlayData(String(currSerie.nkp1_id));
    console.log("curr_serie", curr_serie);
    //27180349500
    //976458897
    //975605953
    //977158577

    /*
    var all_serie_ex = await PrivExerciseSeriesCrud.default.list({ filter: { is_active: false, title: "nkp_old" }, limit: 1 });

    var curr_serie;

    for (var i = 0; i < all_serie_ex.length; i++) {
      try {
        curr_serie = await getPlayData(String(all_serie_ex[i].nkp1_id));
      }
      catch (e) {
        console.log("something went wrong")
      }

      var response = await new PrivExerciseSeriesCrud.default({
        id: all_serie_ex[i].id,
        can_interrupt: curr_serie.Interruptible,
        title: curr_serie.Title,
        success_limit_percent: curr_serie.SuccessfullTestPercentage,
        is_active: true,
        introduction: "<p>" + curr_serie.Description + "</p>",
        show_max_points: curr_serie.TotalScoreVisible
      }).put();

    }*/

    alert("Migration Finished!");
  }

  onExerciseSelect(exerciseRecord: IViewExcSeriesRecord) {
    if (this.props.onExerciseSeriesSelected) {
      //this.props.onExerciseSeriesSelected(exerciseRecord);      
      if (this.props.isPublicDisabled && this.state.searchParams.libraryId == 0) return;
      if (this.state.searchParams.libraryId == LIBRARY_PERSONAL_ID) {
        this.props.onExerciseSeriesSelected(exerciseRecord, PrivExerciseSeriesCrud.default.TABLE_INFO_ID);
      } else if (this.state.searchParams.libraryId != LIBRARY_OFI_OFFICIAL_ID) {
        this.props.onExerciseSeriesSelected(exerciseRecord, PubExerciseSeriesCrud.default.TABLE_INFO_ID);
      } else {
        this.props.onExerciseSeriesSelected(exerciseRecord, PrivExerciseSeriesCrud.default.TABLE_INFO_ID);
      }
    } else {
      app.showError(__("Hiba!"), __("Nem lehet feladatot választani!"));
    }
  }


  private copyPublishedExerciseSeries = async (exerciseSeriesId: number, exerciseCount: number, exerciseSeriesName:string) => {
    try {
      //A másolni kívánt feladat szerepel -e már a saját feladatsoaim között (title alapján, mert nem tároljuk az eredeti feladatsor id másolásnál)
      let count = await ViewMyExcSeries.count({filter: {title: exerciseSeriesName}});
      
      if(count > 0) {
        if (!await confirmDialog(__(`Feladatsor másolása: ${exerciseSeriesName}`), 
        __(`Ilyen című feladatsor már szerepel a Saját feladatsoraim között. Biztosan kér újabb másolatot?`), 
        __("Igen"))) return;
      }
      
      if (!await confirmDialog(__("Feladatsor másolása"), __(`A kiválasztott feladatsorhoz tartozó ${exerciseCount} feladat másolásra kerül a 'Saját feladataim' listába.`), __("Ok"))) return;

      const newExerciseSeries = await copyPublishedExerciseSeries(exerciseSeriesId, LIBRARY_PERSONAL_ID); 
      this.reloadAsync();
      app.showSuccess(__("Feladatsor másolása"), __("Sikeres"))
    } catch (error) {
      app.showErrorFromJsonResult(error);
    }
  }

  async reloadAsync() {
    try {
      this.setState({
        loading: true
      })
      let deleteTransitionInfo: WfDeleteTransitionInfo = await PermissionCache.loadWfDelTrans(WF_TYPE_OKOSFELADATSOR_ID);
      let exerciseActiveDeleteTransitonTypes: TransitionTypeMap | undefined = this.state.exerciseActiveDeleteTransitonTypes;
      let canDeleteFromStationIds = await PermissionCache.getWfDeletableStationIds(WF_TYPE_OKOSFELADATSOR_ID, undefined, exerciseActiveDeleteTransitonTypes);


      var subjects: ISubjectRecord[];
      try {
        subjects = await SubjectCrud.list({ filter: { is_active: true }, order_by: "name" });
      } catch (error) {
        app.showErrorFromJsonResult(error);
        console.log(error);
        return;
      }

      const grades = await GradeCrud.list({});
      const sortedGrades = grades.sort((a: IGradeRecord, b: IGradeRecord) => a.name!.localeCompare(b.name!, undefined, { numeric: true, sensitivity: 'base' }));
      var filter: TFilterDict = {
        site_id: config.mainServer.siteId
      };
      let countCrudFilter = true;

      if (this.state.searchParams.libraryId == LIBRARY_OFI_OFFICIAL_ID || this.state.searchParams.libraryId == LIBRARY_PERSONAL_ID ) filter.is_active = true;

      if (this.state.searchParams.gradeId) {
        filter.grade_id = Number(this.state.searchParams.gradeId);
      }
      if (this.state.searchParams.subjectId) {
        filter.subject_id = Number(this.state.searchParams.subjectId);
      }

      if (this.state.searchParams.lang_id) {
        filter.lang_id = this.state.searchParams.lang_id;
      }

      if (this.state.searchParams.selectedExeLevel) {
        filter.level = Number(this.state.searchParams.selectedExeLevel);
        countCrudFilter = false;
      }
      if (this.state.searchParams.stationId) {
        filter.wf_station_id = Number(this.state.searchParams.stationId);
        countCrudFilter = false;
      }
      if (this.state.searchParams.keywords && this.state.searchParams.keywords.length > 0 && this.state.searchParams.keywords != "") {
        filter.keywords = { kind: StringSearchKind.WordSearch, case_sensitive: false, expr: this.state.searchParams.keywords };
        countCrudFilter = false;
      }

      for (let userFilter of this.state.searchParams.filters) {
        if (userFilter.id == "title") {
          filter.$text = {
            fieldNames: ["title", "grade_name", "subject_name"],
            expression: {
              kind: StringSearchKind.WordSearch,
              case_sensitive: false,
              expr: userFilter.value
            }
          }
        } else {
          filter[userFilter.id] = { kind: StringSearchKind.WordSearch, case_sensitive: false, expr: userFilter.value }
        }
        countCrudFilter = false;
      }
      if (this.state.searchParams.exerciseSeriesType) {
        let tempseriesType = undefined;

        if (this.state.searchParams.exerciseSeriesType == "simple") { tempseriesType = false; }
        else if (this.state.searchParams.exerciseSeriesType == "adaptive") { tempseriesType = true; }

        filter.is_adaptive = tempseriesType;
      }

      // filtering for collections
      if(me && this.state.searchParams.origin == ExerciseSeriesOriginTypes.COLLECTION_PUBLISHED){
        let collectionItems = (await CollectionItemCrud.list({filter:{table_info_id:PubExerciseSeriesCrud.default.TABLE_INFO_ID, creation_user_id:me.id, is_active:true }}) );
        let recordIds = collectionItems.map(el => el.rec_id!);
        filter.id=recordIds;
      }
      //filtering for favourits
      if(me && this.state.searchParams.origin == ExerciseSeriesOriginTypes.FAVORITS_PUBLISHED){
        let collectionItems = (await FavouriteCrud.list({filter:{table_info_id:PubExerciseSeriesCrud.default.TABLE_INFO_ID, sec_user_id:me.id }}) );
        let recordIds = collectionItems.map(el => el.rec_id!);
        filter.id=recordIds;
      }

      var orderBy: any = undefined;
      if (this.state.searchParams.sorted.length > 0) {
        const userOrder = this.state.searchParams.sorted[0];

        if (userOrder.desc) {
          orderBy = [{ name: userOrder.id, desc: true }];
        } else {
          orderBy = userOrder.id
        }
      }

      if (!orderBy && this.state.searchParams.libraryId != 0) { orderBy = [{ name: "creation_time", desc: true }]; }


      let spec = {}
      if (this.state.searchParams.notinbook) {
        spec["notinbook"] = true;
      }
      var listParameters = {
        filter, spec,
        order_by: orderBy,
        limit: this.state.searchParams.pageSize,
        offset: this.state.searchParams.page * this.state.searchParams.pageSize
      };
      var countParams = { filter, spec };

      var filterParams = {
        filter: JSON.stringify(filter),
        order_by: JSON.stringify(orderBy),
        limit: 3,
        offset: 0,
        library_id: this.state.searchParams.libraryId
      }

      var exerciseSeriesRecords: IExerciseSeriesView[];
      var count: number;

      if (this.state.searchParams.libraryId == LIBRARY_PERSONAL_ID) {
        exerciseSeriesRecords = await ViewMyExcSeries.list(listParameters);
        count = await ViewMyExcSeries.count(countParams);
      } else if (this.state.searchParams.libraryId == LIBRARY_OFI_OFFICIAL_ID) {
        exerciseSeriesRecords = await ViewEditorExcSeries.list(listParameters);
        count = await ViewEditorExcSeries.count(countParams);
      } else {
        exerciseSeriesRecords = await PublicViewExerciseSerie.default.list(listParameters);
        if (countCrudFilter)
          count = await PubExerciseSeriesCrud.default.count(countParams);
        else
          count = await PublicViewExerciseSerie.default.count(countParams);
      }

      /* if (this.state.searchParams.libraryId == 0) {
         exerciseSeriesRecords = await PublicViewExerciseSerie.default.list(listParameters);
         if (countCrudFilter)
           count = await PubExerciseSeriesCrud.default.count(countParams);
         else
           count = await PublicViewExerciseSerie.default.count(countParams);
       } else {
         exerciseSeriesRecords = await PrivateViewExerciseSerie.default.list(listParameters);
         if (countCrudFilter)
           count = await PrivExerciseSeriesCrud.default.count(countParams);
         else
           count = await PrivateViewExerciseSerie.default.count(countParams);
       }*/
      this.setState({ count: count });
      if (whiteSpaceChecker(this.state.searchParams.search)) {
        exerciseSeriesRecords = exerciseSeriesRecords.filter((record: PrivExerciseSeriesCrud.IExerciseSeriesRecord) => String(record.introduction).indexOf(this.state.searchParams.search!) > -1);
      }

      let ex_index = listParameters.offset - 1;

      for (var exerciseSeriesRecord of exerciseSeriesRecords) {
        let editBtn = null;
        let delBtn = null;
        let copyBtn = null;
        let shareBtn = null;
        var station_name = null;
        station_name = exerciseSeriesRecord.wf_station_name != null && exerciseSeriesRecord.wf_station_name.length > 0 ? exerciseSeriesRecord.wf_station_name : __("Saját feladat");
        filterParams.offset = ex_index;
        exerciseSeriesRecord["filter_params"] = JSON.parse(JSON.stringify(filterParams));
        exerciseSeriesRecord["formated_creation_date"] = formatDate(exerciseSeriesRecord.creation_time);
        exerciseSeriesRecord["grade_name"] = (exerciseSeriesRecord.grade_name ? String(exerciseSeriesRecord.grade_name).replace(__("osztály"), "") : "NA");

        if (this.state.searchParams.libraryId != 0) {
          station_name = exerciseSeriesRecord.wf_station_name != null && exerciseSeriesRecord.wf_station_name.length > 0 ? exerciseSeriesRecord.wf_station_name : __("Saját feladat");
          exerciseSeriesRecord["level"] = exerciseSeriesRecord.level;
          exerciseSeriesRecord["wf_station_name"] = station_name;
        }

        ex_index++;
        let canEdit: boolean;
        let canDel: boolean;
        let canCopy: boolean;
        let canShare: boolean;

        if (this.props.onExerciseSeriesSelected) {
          editBtn = <button title="Kiválaszt" className="button small" onClick={this.onExerciseSelect.bind(this, exerciseSeriesRecord)}><i className="fa fa-plus"></i></button>;
          canEdit = false;
          canDel = false;
          canShare = false;
          canCopy = false;
        } else if (this.state.searchParams.libraryId == 0) {
          canEdit = false;
          canDel = false;
          canShare = false;
          canCopy = true;
          copyBtn = <button className="button small primary" title={__("Másolás")} onClick={this.copyPublishedExerciseSeries.bind(this, exerciseSeriesRecord.id!, exerciseSeriesRecord.item_count, exerciseSeriesRecord.title)}><i className="fa fa-clone"></i></button>;
        } else {
          canEdit = await PermissionCache.hasWfEditPermission(WF_TYPE_OKOSFELADATSOR_ID, exerciseSeriesRecord.wf_station_id, me, exerciseSeriesRecord);
          canDel = await PermissionCache.hasWfDeletePermission(canDeleteFromStationIds, me, exerciseSeriesRecord);
          canCopy = this.state.searchParams.libraryId == 0 || (exerciseSeriesRecord.is_published != undefined && exerciseSeriesRecord.is_published);
          canShare = ((exerciseSeriesRecord.library_id == LIBRARY_PERSONAL_ID) && me && (exerciseSeriesRecord.owner_id == me.id)) || false;

          let editLink = exerciseSeriesRecord.is_adaptive ? PATH_ADAPTIVE_EXERCISE_SERIES_EDIT : PATH_EXERCISE_SERIES_EDIT;

          canEdit = await PermissionCache.hasWfEditPermission(WF_TYPE_OKOSFELADATSOR_ID, exerciseSeriesRecord.wf_station_id, me, exerciseSeriesRecord);
          canDel = await PermissionCache.hasWfDeletePermission(canDeleteFromStationIds, me, exerciseSeriesRecord);
          canCopy = exerciseSeriesRecord.is_published || false;
          canShare = ((exerciseSeriesRecord.library_id == LIBRARY_PERSONAL_ID) && me && (exerciseSeriesRecord.owner_id == me.id)) || false;

          if (canEdit) {
            editBtn = <Link className="button small" title={__("Szerkesztés")} to={editLink + `/${exerciseSeriesRecord.id}/`}><i className="fa fa-edit"></i></Link>;
          }
          if (canDel) {
            delBtn = <button className="button small alert" title={__("Törlés")} onClick={this.onExeSeriesDelete.bind(this, exerciseSeriesRecord)}><i className="fa fa-trash"></i></button>;
          }
          if (canCopy) {
            copyBtn = <button className="button small primary" title={__("Másolás")} onClick={this.copyPublishedExerciseSeries.bind(this, exerciseSeriesRecord.id!, exerciseSeriesRecord.item_count, exerciseSeriesRecord.title)}><i className="fa fa-copy"></i></button>;
          }
          if (canShare) {
            shareBtn = <button className="button small success" title={__("Megosztás")}
              onClick={this.showShareDialog.bind(this, exerciseSeriesRecord)}><i className="fa fa-share-alt"></i></button>;
          }

        }
        exerciseSeriesRecord["link"] = <div className="exercise-list-page-button-container">{editBtn} {shareBtn} {delBtn} {copyBtn}</div>;

      }

      this.setState({
        subjects,
        grades: sortedGrades,
        exerciseSeriesRecords,
        loading: false,
        exerciseActiveDeleteTransitonTypes,
        canDeleteFromStationIds,
        deleteTransitionInfo,
      });



    } catch (error) {
      app.showErrorFromJsonResult(error);
    }
  }

  onFetchData(tablestate: any) {
    if (this.state.searchParams.filters != tablestate.filtered) {
      this.debouncedSetTableState(tablestate);
    } else {
      this.setTableState(tablestate);
    }
  }

  debouncedSetTableState = debounce((tablestate) => this.setTableState(tablestate), 300);

  private setTableState(tablestate: any) {

    saved.page = tablestate.page;
    saved.pageSize = tablestate.pageSize;
    saved.sorted = tablestate.sorted;
    saved.filters = tablestate.filtered;

    this.setState({ searchParams:{...this.state.searchParams,
      page: tablestate.page,
      pageSize: tablestate.pageSize,
      sorted: tablestate.sorted,
      filters: tablestate.filtered,
    }}, this.reloadAsync);
  }

  onKeyWordsChange(event: any) {
    const keywords = event.target.value;
    saved.keywords = keywords;
    this.setState({  searchParams:{...this.state.searchParams, keywords: keywords} }), this.reloadAsync();

  }

  /*private onToggleLibraryId = (value: any) => {

    saved.libraryId = 0;
    if (value == "my-exercise-series") {
      saved.libraryId = LIBRARY_PERSONAL_ID;
    } else if (value == "ofi-official-series") {
      saved.libraryId = LIBRARY_OFI_OFFICIAL_ID;
    } else {
      saved.libraryId = 0;
    }
    if (this.state.searchParams.libraryId != saved.libraryId)
      this.setState({ libraryId: saved.libraryId, sorted: [], stationId: saved.libraryId == 0 ? undefined : saved.stationId }, this.reloadAsync)
  }*/

  private onToggleLibraryId = (value: any) => {
    saved.libraryId = 0;
    saved.origin = value;
    console.log("value",value);
    // todo: use the enum

    if (value == ExerciseSeriesOriginTypes.OWN) {
      saved.libraryId = LIBRARY_PERSONAL_ID;
    } else if (value == ExerciseSeriesOriginTypes.OFFICIAL) {
      saved.libraryId = LIBRARY_OFI_OFFICIAL_ID;
    } else {
      saved.libraryId = 0;      
    }


    if (this.state.searchParams.origin != saved.origin) {
      this.setState({ searchParams: 
        { ...this.state.searchParams, 
          libraryId: saved.libraryId!,
          origin: saved.origin, 
          stationId: saved.libraryId == 0 ? undefined : saved.stationId, 
          sorted: []} },() => {this.reloadAsync()});      
    }
  }

  private onGradeSelect = (sender: CrudSelect, newGrade: number | null): void => {
    const gradeId: any = newGrade;
    saved.gradeId = gradeId;
    this.setState({ searchParams:{...this.state.searchParams, gradeId: gradeId }});
    this.reloadAsync();
  }
  private onSubjectSelect = (sender: CrudSelect, newSubject: number | null): void => {
    const subjectId: any = newSubject;
    saved.subjectId = subjectId;
    this.setState({  searchParams:{...this.state.searchParams,subjectId: subjectId} });
    this.reloadAsync();
  }
  private onExeLevelSelect = (event: React.FormEvent<HTMLSelectElement>): void => {
    let tempexe = event.currentTarget.value ? Number(event.currentTarget.value) : null;
    saved.selectedExeLevel = tempexe;
    this.setState({  searchParams:{...this.state.searchParams,selectedExeLevel: tempexe} });
    this.reloadAsync();
  }
  private onExeSeriesTypechanged(event: any) {
    var exerciseSeriesType: string = event.target.value;
    saved.exerciseSeriesType = exerciseSeriesType;
    this.setState({  searchParams:{...this.state.searchParams,exerciseSeriesType} }, this.reloadAsync)
  }
  private onStationSelect = (sender: CrudSelect, newStation: number | null): void => {
    const station: any = newStation;
    saved.stationId = station;
    this.setState({  searchParams:{...this.state.searchParams,stationId: station} });
    this.reloadAsync();
  }

  private onSelectLang = (sender: CrudSelect, newLang: number): void => {
    saved.lang_id = newLang;
    this.setState({ searchParams: { ...this.state.searchParams, lang_id: newLang } });
    this.reloadAsync();
  }

  onNotInBookChanged(event: any) {
    const notinbook = event.target.checked;
    saved.notinbook = notinbook;
    this.setState({  searchParams:{...this.state.searchParams, notinbook }});
    this.reloadAsync();
  }

  private getDeleteTransitionTypesForStation(stationId: number): IViewTransitionTypeRecord[] {
    let deleteTransitions = [];

    if (this.state.deleteTransitionInfo) {
      let tt: IViewTransitionTypeRecord[] = this.state.deleteTransitionInfo.activeTransitionTypes;

      for (let i = 0; i < tt.length; i++) {
        let transitionType = tt[i];
        if (transitionType.src_station_id == stationId) {
          if (transitionType.dst_is_deleted!) {
            deleteTransitions.push(transitionType);
          }
        }
      }
    }
    return deleteTransitions;
  }

  private async onExeSeriesDelete(selectedSeries: PrivateViewExerciseSerie.IViewExcSeriesRecord) {

    let canDelete = false;

    if (!selectedSeries) {
      alert(__('Nem található az adott elem.'));
      return;
    }

    if (!await confirmDialog(__("Megerősítés"), __("Biztos, hogy törölni szeretné ezt a feladatsort: {title} ?", { title: selectedSeries.title }))) return;

    // Kikeressük a törlő transition type-okat.
    const stationId = selectedSeries.wf_station_id;
    if (stationId) {
      // Folyamatban részt vevő feladat, folyamat átmenettel töröljük.
      let possibleTransitions = this.getDeleteTransitionTypesForStation(stationId);
      if (possibleTransitions.length > 1) {
        // TODO: mi lenne ha itt egyből megnyitnánk ?
        app.showError(
          __("Hiba"),
          __('Egynél több célállapot elérhető.') + " " +
          __('A törlés végrehajtásához nyissa meg a folyamat dialógus ablakot.')
        );
        return;
      }
      const transitionType: IViewTransitionTypeRecord = possibleTransitions[0];
      let justification = prompt(__('Kérem írja be az indoklást'), '');
      this.setState({ loading: true });
      let wfApi = new WfAPI(obtainServer());

      try {
        let workflowAfter: IViewWfWorkflowRecord = await wfApi.makeTransition(
          selectedSeries!.wf_workflow_id!,
          transitionType.id!,
          justification
        );
        this.reloadAsync();
        app.showSuccess(__("Feladatsor törlése"), __("Sikeres"))

      } catch (error) {
        app.showErrorFromJsonResult(error);
        this.setState({ saving: false });
      };

    } else {
      try {
        await PrivExerciseSeriesCrud.default.deleteById(selectedSeries.id!);
        this.reloadAsync();
        app.showSuccess(__("Feladatsor törlése"), __("Sikeres"))
      } catch (error) {
        app.showErrorFromJsonResult(error);
        this.setState({ loading: false });
      }
    }
  }

  private showShareDialog = (record: PrivateViewExerciseSerie.IViewExcSeriesRecord) => {
    this.setState({ shareExerciseSeriesId: record.id!, sharedContentId: undefined, shareExerciseListName: record.title })
  }

  private toggleChecked = (workflowId: number): void => {
    let checked = this.state.checked;
    if (checked.has(workflowId)) {
      checked.delete(workflowId);
    } else {
      checked.add(workflowId);
    }
    this.setState({ checked });
  }

  private checkAll = () => {
    if (this.state.checked.size < this.state.exerciseSeriesRecords!.length) {
      const allIds = this.state.exerciseSeriesRecords
        .filter(rec => (rec.wf_workflow_id ? true : false))
        .map(rec => rec.wf_workflow_id!);
      this.setState({ checked: new Set(allIds) });
    } else {
      this.setState({ checked: new Set([]) });
    }
  }

  private forcePublishChecked = () => {
    if (!confirm(
      __('Az összes kijelölt feladatsor publikálható állapotba kerül, a folyamat kezelési ') +
      __('szabályok megkerülésével, ezután publikálásra kerülnek. Biztos benne, hogy ezt akarja?'))) {
      return;
    }
    this.setState({ showForcePublishingDialog: true }, async () => {
      const wfApi: WfAPI = new WfAPI(obtainServer());
      try {
        const checked = this.state.checked;
        const it = checked.values();
        let item = it.next();
        let processed = 0;
        while (!item.done && this.state.showForcePublishingDialog) {
          const workflowId = item.value;
          const publishPercent = Math.round(1000.0 * processed / checked.size) / 10.0;
          this.setState({ publishPercent });
          await wfApi.forcePublishExerciseSeries(workflowId);
          processed += 1;
          item = it.next();
        }
        if (this.state.showForcePublishingDialog) {
          app.showSuccess(__("Sikeres műveletek"), __("A tömeges kényszerített publikálás sikeresen befejeződött."));
        } else {
          app.showWarning(__("Megszakítva"), __("A tömeges kényszerített publikálást a felhasználó megszakította."));
        }
        this.setState({ showForcePublishingDialog: false, checked: new Set([]) }, this.reloadAsync);
      } catch (error) {
        app.showErrorFromJsonResult(error);
        this.setState({ showForcePublishingDialog: false, checked: new Set([]) }, this.reloadAsync);
      }
    });
  }

  private async onTransitionToEdit() {
    const justification = await promptDialog(__("Visszanyitás szerkesztésre"), __("Kérem adja meg a visszanyitás okát!"), undefined, __("Visszanyitás"));
    if (!justification) {
      return;
    }

    let count = 0;

    this.setState({ showReopenDialog: true });
    try {
      const wfApi: WfAPI = new WfAPI(obtainServer());

      for (const workflowId of Array.from(this.state.checked)) {

        const series = this.state.exerciseSeriesRecords.find(t => t.wf_workflow_id === workflowId);

        if (series) {
          const transitions = await wfApi.listPossibleTransitions(workflowId);
          const transition = transitions.find(t => t.dst_is_start!);

          if (transition) {
            await wfApi.makeTransition(workflowId, transition.id!, justification!);
            count++;
          }

          const exerciseItems = await ViewExerciseSeriesItem.list({ filter: { is_active: true, exercise_series_id: series.id } });

          for (const exerciseItem of exerciseItems) {
            if (exerciseItem.wf_workflow_id) {
              const transitions = await wfApi.listPossibleTransitions(exerciseItem.wf_workflow_id);
              const transition = transitions.find(t => t.dst_is_start!);

              if (transition) {
                await wfApi.makeTransition(exerciseItem.wf_workflow_id, transition.id!, justification!);
              }
            }
          }
        }
      }

      if (count > 0) app.showSuccess(__("Visszanyitás"), __(`{c} feladatsor visszanyitása sikeres.`, { c: count }));

      this.reloadAsync();
    } catch (e) {
      app.showErrorFromJsonResult(e);
    }
    this.setState({ showReopenDialog: false, checked: new Set([]) });
  }

  render() {

    const getTdProps = (finalState: any, rowInfo: RowInfo, column?: Column, instance?: any) => {
      let props: Partial<TableProps> = {};

      if (column && rowInfo && rowInfo.row) {
        let row = rowInfo.row._original;
        if (row && column.id == "wf_station_name") {
          props.style = row.wf_station_style || {};
        }
      }
      return props;
    }

    const enableLang = exerciseModule.getConfig().enableLangSelector;

    const checkboxCount = this.state.exerciseSeriesRecords.filter(e => e.wf_workflow_id).length;

    const tableProps = {
      columns: [
        {
          Header: <div>
            <button
              title={__("Mindent kijelöl")}
              className="button secondary"
              onClick={this.checkAll}
              disabled={!this.state.exerciseSeriesRecords || !(this.state.exerciseSeriesRecords!.length)}
              style={{
                padding: 3,
                margin: 0
              }}
            >
              <i className="fa fa-check-double" />
            </button>
          </div>,
          accessor: "name",
          filterable: false,
          sortable: false,
          minWidth: 30,
          show: this.state.searchParams.libraryId != 0 && !this.props.onExerciseSeriesSelected,

          Cell: (data: any, column: any) => {
            const row: PrivateViewExerciseSerie.IViewExcSeriesRecord = data.original;
            return row.wf_workflow_id
              ? <input type="checkbox"
                value="1"
                checked={this.state.checked.has(row.wf_workflow_id!)}
                onChange={() => this.toggleChecked(row.wf_workflow_id!)}
              />
              : null;
          }
        },

        {
          Header: <div>
            <b>{__("Feladatsor neve")}</b><br />
            <small>{__("Évfolyam, tantárgy")}</small>
          </div>,
          accessor: "title",
          minWidth: 200,
          Cell: (data: any, column: any) => {
            const row: PrivateViewExerciseSerie.IViewExcSeriesRecord = data.original;
            const baseUrl = this.state.searchParams.libraryId == 0 ? PATH_EXERCISE_SERIES_PUB_VIEW : PATH_EXERCISE_SERIES_VIEW;
            return (this.props.openInNewTab ?
              <a target="_blank" href={baseUrl + `/${row.id}/?${queryString.stringify(data.original.filter_params)}`} style={{ width: "100%" }}>
                <b>{row.title}</b><br />
                <small >{row.grade_name} {row.subject_name}
                </small>
              </a> :
              <Link to={baseUrl + `/${row.id}/?${queryString.stringify(data.original.filter_params)}`} style={{ width: "100%" }}>
                <b>{row.title}</b><br />
                <small >{row.grade_name} {row.subject_name}
                </small>
                {/* Csilla és Dani nem engedte :'( */}
                {/* <small dangerouslySetInnerHTML={{__html:row.introduction ||""}}/> */}
              </Link>)
          }
        },
        { Header: <small title={__("Nehézségi szint")}>{__("Szint")}</small>, accessor: "level", maxWidth: 70, filterable: false, sortable: false, style: { justifyContent: "center" } },
        {
          Header: __("Típus"), accessor: "is_adaptive", filterable: false, style: { justifyContent: "center" },
          Cell: (data: any, column: any) => {
            const row: PrivateViewExerciseSerie.IViewExcSeriesRecord = data.original;
            return <small>{row.is_adaptive ? __("Adaptív") : __("Egyszerű")}</small>;
          }
        },
        { Header: <small title={__("Feladatok száma")}>Db.</small>, accessor: "item_count", maxWidth: 60, filterable: false, sortable: false, style: { justifyContent: "center" } },
        {
          Header: <small>{__("Tulajdonos")}<br />{__("Létrehozva")}</small>,
          accessor: "owner_person_fullname", filterable: true, maxWidth: 170,
          show: this.state.searchParams.libraryId != 0,
          Cell: (data: any, column: any) => {
            const row: PrivateViewExerciseSerie.IViewExcSeriesRecord = data.original;
            return row.owner_person_fullname ? <small><b>{row.owner_person_fullname}</b><br />{formatDate(row.creation_time)}</small> : ""
          }
        },
        {
          Header: "Állapot",
          accessor: "wf_station_name",
          filterable: false,
          show: this.state.searchParams.libraryId != 0,
          Cell: (data: any, column: any) => {
            const row: PrivateViewExerciseSerie.IViewExcSeriesRecord = data.original;
            return this.state.searchParams.libraryId != 0 ? getWfStateCell(data, column, row.is_published ?
              <>
                <br />
                <span className="label primary"
                  title={formatDate(row.last_published) + " " + row.last_publisher}
                >
                  <small>{__("Publikálva")}
                  </small>
                </span>
              </>
              : null
            ) : ""
          },
          maxWidth: 100, style: { justifyContent: "center" }
        },
        { Header: __("Művelet"), accessor: "link", filterable: false, sortable: false, minWidth: 180, maxWidth: 180, className: "action-cell" },
      ],

      data: this.state.exerciseSeriesRecords,
      defaultPageSize: this.state.searchParams.pageSize,
      defaultFiltered: this.state.searchParams.filters,
      defaultSorted: this.state.searchParams.sorted,
      pages: Math.ceil(this.state.count / this.state.searchParams.pageSize),
      filterable: true,
      className: "-striped -highlight",
      ...getReactTableLabels(),
      onFetchData: this.onFetchData.bind(this),
      loading: this.state.loading,
      getTdProps,
      manual: true,
    };
    const canChangeLibraryId = (this.props.canChangeLibraryId === undefined) ? true : this.props.canChangeLibraryId;
    return (
      <PermissionPage
        requirements={{
          view: [{
            viewInfoId: PrivateViewExerciseSerie.default.VIEW_INFO_ID,
            permissions: [ViewPermissionType.R]
          }]
        }}
      >

        {
          this.state.shareExerciseSeriesId
            ?
            <Dialog title={__("Feladatsor kiosztása")} onClose={() => this.setState({ shareExerciseSeriesId: null, sharedContentId: undefined })} width={1000} height={700}>

              <UsrShareDialog
                sharedContentId={this.state.sharedContentId}
                fileName={this.state.shareExerciseListName ? this.state.shareExerciseListName : ""}
                tableInfoId={PrivExerciseSeriesCrud.default.TABLE_INFO_ID}
                recordId={this.state.shareExerciseSeriesId}
                onCreated={(record: ISharedContentRecord) => {
                  this.setState({ shareExerciseSeriesId: null, sharedContentId: this.state.sharedContentId });
                  app.showSuccess(
                    __("Sikeres művelet"),
                    __("A tartalom megosztása sikeresen befejeződött."));
                }}
                onSaved={(record: ISharedContentRecord) => {
                  this.setState({ shareExerciseSeriesId: null, sharedContentId: this.state.sharedContentId });
                  app.showSuccess(
                    __("Sikeres művelet"),
                    __("Megosztás módosítva."));
                }}
                onSharedContentSelected={(sharedContentId: number | null) => {
                  this.setState({ sharedContentId });
                }}
                onSharedContentDeleted={(sharedContentId: number | null) => {
                  this.setState({ shareExerciseSeriesId: null });
                  app.showWarning(
                    __("Sikeres művelet"),
                    __("Megosztás törölve."));
                }}
                onCancel={() => this.setState({ shareExerciseSeriesId: null })}
              />
            </Dialog>
            :
            null
        }

        {this.state.showForcePublishingDialog ?
          <Dialog width={600} height={200}
            title={__("Kényszerített publikálás")}
            onClose={() => {
              this.setState({ showForcePublishingDialog: false, checked: new Set([]) });
            }}
          >
            <div className="progress" role="progressbar">
              <span className="progress-meter" style={{ width: "" + this.state.publishPercent + "%" }}>
                <p className="progress-meter-text">{this.state.publishPercent}%</p>
              </span>
            </div>
            <br />
            <button className="button alert"
              onClick={() => this.setState({ showForcePublishingDialog: false, checked: new Set([]) })}
            >
              <i className="fa fa-abort" /> {__("Megszakít")}
            </button>
          </Dialog> : null
        }

        {
          this.state.showReopenDialog &&
          <Dialog title={__("Visszanyitás szerkesztésre")} closable={false} onClose={() => { }}>
            {__("Visszanyitás szerkesztésre folyamatban...")}
          </Dialog>
        }

        <div className="exercise-list-page row expanded exerciseList-maincomponent">
          {
            this.props.onExerciseSeriesSelected ? ""
              :
              <div className="small-12 medium-12 column">
              </div>
          }

          <div className="small-12 medium-12 column clearfix">
            {
              (me && !this.props.onExerciseSeriesSelected) ?

                <>
                  <Link className="button small" to={PATH_EXERCISE_SERIES_CREATE}><i className="fa fa-plus" /> {__("Új feladatsor hozzáadása")}</Link>
                  <Link className="button small" to={PATH_ADAPTIVE_EXERCISE_SERIES_CREATE} style={{ marginLeft: "5px" }}><i className="fa fa-plus" /> {__("Új adaptív feladatsor hozzáadása")}</Link>
                </>
                : ""
            }
            {
              checkboxCount > 0 ?
                <button
                  className="button small"
                  onClick={this.onTransitionToEdit.bind(this)}
                  disabled={this.state.checked.size == 0}
                >
                  <i className="fa fa-edit" /> {__("Kijelöltek visszanyitása szerkesztésre")} ({this.state.checked.size})
                </button>
                : ""
            }
            {
              (me && this.state.checked.size && hasAnyGroup(me, [Groups.Admin, Groups.NkpAdmin, Groups.ForcedPublisherOFI])) ?
                <button
                  className="button small alert"
                  onClick={this.forcePublishChecked}
                >
                  <i className="fa fa-eye" /> {__("Kényszerített publikálás")} ({this.state.checked.size})
              </button>
                : ""
            }

          </div>

          <RadioTabs onChange={this.onToggleLibraryId.bind(this)} tabGroup="exeSeriesTabs">           
              
            <RadioTab className={this.state.loading ? "disabled-input" : ""} selected={this.state.searchParams.origin == ExerciseSeriesOriginTypes.OWN} value={ExerciseSeriesOriginTypes.OWN}>
              {__("Saját feladatsoraim")}
            </RadioTab>
            
            {(exerciseModule.getConfig().enableOfficialExercises && (me && hasGroup(me, Groups.OFIEditor))) ?
              <RadioTab className={this.state.loading ? "disabled-input" : ""} selected={this.state.searchParams.origin == ExerciseSeriesOriginTypes.OFFICIAL} value={ExerciseSeriesOriginTypes.OFFICIAL}>
                {__("Hivatalos feladatsorok")}
              </RadioTab>
              : ""
            }
            {(me && !this.props.isPublicDisabled) && 
              <RadioTab className={this.state.loading ? "disabled-input" : ""} selected={this.state.searchParams.origin == ExerciseSeriesOriginTypes.ALL_PUBLISHED} value={ExerciseSeriesOriginTypes.ALL_PUBLISHED}>
                {__("Akkreditált feladatsorok")}
              </RadioTab>}
              {(exerciseModule.getConfig().enableExercisesColleciton && (me && !this.props.isPublicDisabled)) && 
              <RadioTab className={this.state.loading ? "disabled-input" : ""} selected={this.state.searchParams.origin == ExerciseSeriesOriginTypes.COLLECTION_PUBLISHED} value={ExerciseSeriesOriginTypes.COLLECTION_PUBLISHED}>
                {__("Gyüjteményeim feladatsorai")}
              </RadioTab>
            }
             {(exerciseModule.getConfig().enableFavouriteExercises && (me && !this.props.isPublicDisabled)) && 
              <RadioTab className={this.state.loading ? "disabled-input" : ""} selected={this.state.searchParams.origin == ExerciseSeriesOriginTypes.FAVORITS_PUBLISHED} value={ExerciseSeriesOriginTypes.FAVORITS_PUBLISHED}>
                {__("Kedvenc feladatsoraim")}
              </RadioTab>
            }

          </RadioTabs>

          <div className="small-12 medium-12 filters">
            <Accordion>
              <AccordionItem defaultClosed key={"filter_accordion"} title={__("Szűrők")}>
                <div className="medium-12 row">
                  <div className="small-12 medium-6 large-4 column ">
                    <label className="lms-pages-typeLabel">{__("Évfolyam")}</label>
                    <CrudSelect
                      value={this.state.searchParams.gradeId || null}
                      onSelect={this.onGradeSelect}
                      displayFieldName="name"
                      orderByFieldName="id"
                      key="id"
                      emptyTitle={__("Minden osztály")}
                      clearable={true}
                      crudClassProxy={gradeCrudClassProxy}
                      filter={{ is_active: true }}
                      sortFunc={(a, b) => a.name.localeCompare(b.name, undefined, { numeric: true, sensitivity: 'base' })}
                    />
                  </div>
                  <div className="small-12 medium-6 large-4 column">
                    <label className="lms-pages-typeLabel">{__("Tantárgy")}</label>
                    <CrudSelect
                      value={this.state.searchParams.subjectId || null}
                      onSelect={this.onSubjectSelect}
                      displayFieldName="name"
                      key="id"
                      emptyTitle={__("Minden tantárgy")}
                      clearable={true}
                      crudClassProxy={subjectCrudClassProxy}
                      filter={{ is_active: true }}
                    />
                  </div>
                  <div className="small-12 medium-6 large-4 column">
                    <label className="lms-pages-typeLabel">{__("Kulcsszó")}</label>
                    <input className="exerciseList-option" type="text" value={this.state.searchParams.keywords} onChange={this.onKeyWordsChange.bind(this)} />
                  </div>
                  <div className="small-12 medium-6 large-4 column">
                    <label className="lms-pages-typeLabel">{__("Típus")}</label>
                    <select name="exerciseSeriesType" id="exerciseSeriesType" onChange={this.onExeSeriesTypechanged.bind(this)} >
                      <option value="all">{__("Minden típus")}</option>
                      <option value="simple">{__("Egyszerű")}</option>
                      <option value="adaptive">{__("Adaptív")} </option>
                    </select>
                  </div>
                  <div className="small-12 medium-6 large-4 column">
                    <label className="lms-pages-typeLabel">{__("Nehézségi szint")}</label>
                    <LevelSelectComponent
                      value={this.state.searchParams.selectedExeLevel || null}
                      allowEmpty={true}
                      onSelect={this.onExeLevelSelect}
                      name={""}
                    />
                  </div>
                  {me && hasGroup(me, Groups.OFIEditor) && this.state.searchParams.libraryId != 0 ?
                    <div className="small-12 medium-6 large-4 column">
                      <label className="lms-pages-typeLabel">{__("Állapot")}</label>
                      <CrudSelect
                        value={this.state.searchParams.stationId || null}
                        onSelect={this.onStationSelect}
                        displayFieldName="name"
                        orderByFieldName="name"
                        key="id"
                        emptyTitle={__("Minden állapot")}
                        clearable={true}
                        crudClassProxy={stationCrudClassProxy}
                        filter={{ is_active: true, wf_type_id: WF_TYPE_OKOSFELADATSOR_ID }}
                        sortFunc={(a, b) => a.name.localeCompare(b.name, undefined, { numeric: true, sensitivity: 'base' })}
                      />
                    </div>
                    : ""
                  }
                  {enableLang && <div className="small-12 medium-6 large-4 column">
                    <label className="lms-pages-typeLabel">{__("Feladatsor nyelve")}</label>
                    <CrudSelect
                      value={this.state.searchParams.lang_id || null}
                      displayFieldName="name"
                      onSelect={this.onSelectLang}
                      emptyTitle={__("Minden nyelv")}
                      clearable={true}
                      valueFieldName="lang_id"
                      filter={{ translated_to_id: getLanguageId() }}
                      crudClassProxy={langTranslationCrudClassProxy}
                    />
                  </div>}

                  <div className="small-6 medium-12 large-6 column">
                    <input type="checkbox" name="notinbook" id="notinbook" checked={this.state.searchParams.notinbook} onChange={this.onNotInBookChanged.bind(this)} />
                    <label htmlFor="notinbook">{__("Nem szerepel könyvben")}</label>
                  </div>
                </div>
              </AccordionItem>
            </Accordion>
          </div>
          <div className="small-12 medium-12">

            <label className="result-count">{this.state.count ? this.state.count : "0"} {__("találat")}</label>
            <ReactTable {...tableProps} />

            {/*me && hasGroup(me, Groups.Developer) && <div className="small-12 medium-12 column clearfix">
              
                <div className="row">
                  <button className="button secondary small eke-general-buttons" onClick={this.tempMigrateOldNkpSeries} ><i className="fa fa-plus" /> Régi nkp feladatsorok migrálása </button>
                </div>
              
            </div>
                */}

          </div>

        </div>
      </PermissionPage >
    );
  }

}
