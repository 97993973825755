import * as React from 'react';
import UserViewHistoryAPI, { IUserViewEvent } from '@src/component/usr/usr_history_api';
import {app} from '@src/index';
import { getUserEventName, ContentDetails, getContentDetailsList } from '@src/component/filemanager/ContentUtils';
import { Link } from 'react-router-dom';
import { formatDate } from '@src/Util';
import { List, ListItem, ListItemImage, ListItemText } from '../ui/List';
import { BubbleLoader } from 'react-css-loaders';
import TypeIcon from "@src/component/ui/TypeIcon";
import { __ } from '@src/translation';
import FileManagerPager from './ofi/FileManagerPager';


type ContentHistoryPageState = {
    viewEvents: IUserViewEvent[];
    details: (ContentDetails | undefined)[];
    loading: boolean;
    limit: number;
    offset: number;
    //pageSize: number;
}

export class ContentHistoryPage extends React.Component<{hideHeader?: boolean, limit?: number}, ContentHistoryPageState> {
    constructor(props: any) {
        super(props);
        this.state = {
            viewEvents: [],
            details: [],
            loading: true,
            limit: 25,
            offset: 0,
            //pageSize: 25,
        };
    }

    componentDidMount() {
        this.reloadAsync();
    }

    componentDidUpdate() {
        // this.reloadAsync();
    }

    async reloadAsync() {

        try {
            this.setState({loading:true});
            var viewEvents = await UserViewHistoryAPI.getViewEventLog(undefined,this.props.limit?this.props.limit:this.state.limit,this.state.offset);
            const details = await getContentDetailsList(viewEvents.map(e => ({tableOrViewId: e.table_info_id, recordId: e.record_id})));

            this.setState({
                viewEvents,
                details,
                loading: false
            });
        } catch(e) {
            app.showErrorFromJsonResult(e);
        }
    }
    private onChangeLimit = (limit: number) => {
        this.setState({limit}, this.reloadAsync);
    }    

    private onChangeOffset = (offset: number) => {
        this.setState({offset, viewEvents:[]}, this.reloadAsync);
    } 

    render() {
        if (this.state.loading) {
            return <BubbleLoader />
        }

        return <>
            {!this.props.hideHeader && <h4>{__("Előzmények:")}</h4>}
            <FileManagerPager
                        disabled={this.state.loading}
                        limit={this.state.limit}
                        offset={this.state.offset}
                        onChangeLimit={this.onChangeLimit}
                        onChangeOffset={this.onChangeOffset}
                        pageItemCount={this.state.viewEvents.length}
                    />            
                <List>
                {
                    this.state.viewEvents.map((event, index) => {
                        const detail = this.state.details[index];
                        if (!detail) return;

                        return <ListItem>
                            <ListItemImage src={detail.thumbnailUrl} />
                            <ListItemText>
                                <Link to={detail.viewUrl}>                                   
                                    {detail.displayName}
                                </Link>
                                <div>
                                    <TypeIcon contentType={detail.type}/>
                                    <small>{getUserEventName(event)}</small>
                                    <small className="date">{formatDate(event.event_date)}</small>
                                </div>
                                
                            </ListItemText>
                        </ListItem>;
                    })
                }
                
            </List>
            <FileManagerPager
                disabled={this.state.loading}
                limit={this.state.limit}
                offset={this.state.offset}
                onChangeLimit={this.onChangeLimit}
                onChangeOffset={this.onChangeOffset}
                pageItemCount={this.state.viewEvents.length}
            />
            </>
    }

}
