
import * as React from 'react';
import { ExerciseServerSimulator } from '@src/component/exercise/ExerciseServerSimulator';
import GradeCrud, { IGradeRecord } from '@src/framework/crud/doc/GradeCrud';
import { IExerciseSeriesRecord } from '@src/framework/crud/exc/ExerciseSeriesCrud';
import { ExerciseWithMeta, SearchResult } from '@src/server/PublicServer';
import { timeoutAsync, roundToDecimals } from '@src/Util';
import UsrLikePlugin from '@src/component/usr/usr_like_plugin';
import ChapterCrud from '@src/framework/crud/doc/ChapterCrud';
import { History, Location } from 'history';
import "./ExerciseContainerComponent.css";
import { Z_INDEX_EXERCISE_POPUP_BACKGROUND } from '@src/Const';
import { exerciseEngineClasses, NavigationDirections } from '@src/component/exercise/models/ExerciseBaseClass';
import * as PublicServer from '@src/server/PublicServer';

import ExerciseSeriesCrud from '@src/framework/crud/exc/ExerciseSeriesCrud';
import ExerciseSeriesCruPub from '@src/framework/crud/exc_pub/ExerciseSeriesCrud';
import ExerciseCrudPub from '@src/framework/crud/exc_pub/ExerciseCrud';
import ExerciseCrud, { IExerciseRecord } from '@src/framework/crud/exc/ExerciseCrud';

import CountDown from '@src/component/exercise/ExerciseSeries/CountDown';
import { IChapterRecord } from '@src/framework/crud/doc/ChapterCrud';
import { tableScrollSideBlur } from '@src/Util';
import { PATH_EXERCISE_MORE_HELP_PUB, PATH_EXERCISE_MORE_HELP } from '@src/Routes';
import { ToolTipDiv } from '../ToolTipDiv';
import { ExerciseEngineTypeCheck, getIconOfEngine, getDisplayNameOfEngine } from './models/ExerciseTypeConverter';
import { app } from '../../index';
import { BubbleLoader } from 'react-css-loaders';
import { Evaluated } from './Server';
import { me } from '@src/framework/server/Auth';
import * as OoFileCrud from '@src/framework/crud/media/OoFileCrud';
import * as OoFileCrudPub from '@src/framework/crud/media_pub/OoFileCrud';
import { TweenMax, Back, Power1 } from 'gsap';
import { ExerciseEngineSubSeries } from './models/AExerciseEngine';
import { ExerciseServer } from './ExerciseServer';
import { __ } from '@src/translation';
import { ILpPlayStateRecord } from '@src/framework/crud/exc/LpPlayStateCrud';
import { LpPlayStateNode } from '../learningpath/LearningPathContainer';

declare var MathJax: any;

export type ExerciseSeriesInfo = {
    currentExerciseCount: number, // A visszadott feladatok száma, a mostanival együtt
    totalExerciseCount?: number, // Az összes megoldandó feladat, ha tudjuk előre, hogy hány darab lesz
    successCount: number, // Sikeresen megoldott feladatok száma
    totalPoints: number, // Összesen megszerezhető pontok száma
    earnedPoints: number, // Megszerzett pontok száma
    isFinished: boolean, // A feladatsor véget ért
}

export type DataToEvaluate = {
    answer: any;
    fullmatch?: boolean;
}

export type MessageBoxContent = {
    title?: string;
    content?: any;
    type?: ModalTypes;
}

// new scripts loaded into script tags will have their classes declared on the global Windows Object
export interface ExerciseServerInterface {

    // Elkezdi a feladatsort (simát és adaptívat is) az elejéről.
    startSeries(): Promise<IExerciseSeriesRecord | undefined>;

    // Lekérdezi a feladatsor eddigi állapotát
    getSeriesInfo(): ExerciseSeriesInfo;

    // Lekérdezi az index-edit feladatot
    getExercisebyIndex(index: number): Promise<ExerciseWithMeta | null>;

    // Lekérdezi a jelenlegi feladatot
    getCurrentExercise(): Promise<ExerciseWithMeta | null>;

    // Skip-eli a jelenlegi feladatot, és visszaadja a következőt    
    skipCurrentExercise(): Promise<ExerciseWithMeta | null>;

    evaluate(exercise: IExerciseRecord, dataToEvaluate: DataToEvaluate): Promise<Evaluated>;

    getCorrectSolution(exerciseId: number): Promise<any[]>;

    searchExercise(exercise_id: number): Promise<any>;

    isPublished(): boolean;

    getSharedContentRunId?: () => number | undefined;

}

const imageBaseURL = window.location.hostname == "" ? "" : "/";

export enum ExerciseSolutionType {
    CorrectSolution = "CorrectSolution",
    WrongSolution = "WrongSolution",
    NotStarted = "NotStarted"
};

export enum ModalTypes {
    Task,
    Correct,
    Wrong,
    SeriesIntro,
    SeriesSummary
};

type EvaluateOnServerFuncType = (exercise: IExerciseRecord, correctSolution: any, userSolution: any) => Evaluated | boolean;

export enum ContainerState {
    RequestingExercise,
    RequestingReplay,
    WaitingToPlay,
    Paused,
    Active,
    Evaluate,
    Successful,
    UnSuccessful,
    ShowCorrectSolution,
    FinalEvaluation,
    ReplayUserSolution,
    ReplayFinalEvaluation
}


export type ExerciseContainerState = {
    containerState: ContainerState,

    // Current exercise
    exerciseEngine?: ExerciseEngine10,
    engineName?: string | null | undefined,
    engineDisplayName: string,
    exercise?: IExerciseRecord,
    backgroundStyle?: any,
    isSubSeries: boolean,
    subSeriesTotalQuestions?: number,
    subSeriesActualQuestionIndex?: number,
    is_accessible: boolean | null,
    simple_style: boolean,

    // Current exercise evaluation
    solutionResponseType: ExerciseSolutionType | undefined,
    searchResults?: SearchResult[],
    searchSimilarExe?: SearchResult[],
    examSearchResults: SearchResult[],
    showHelperLinks: boolean,
    showSuggestedExercises: boolean,

    // Current exercise series
    exerciseSeries?: IExerciseSeriesRecord,
    currentExerciseIndex: number,
    remainingTime: number | null,
    seriesExamMode: boolean | undefined,
    evaluatedAlready: boolean;

    // Current exercise series evaluation
    //exerciseSeriesSuccessCount?: number, // we don't need to count the number of correct exercises, we have point for them!
    totalEarnedPoints?: number,
    currentEarnedPoints?: number,

    // Extra
    isFullScreen: boolean,
    //needCheckButton: boolean, we don't show the result at all in exam mode
    needEvaluateButton: boolean,
    needNextButton: boolean,
    messageBoxContent?: MessageBoxContent,
    //specialBookTimePause: boolean,
    mins: string,
    secs: string,
    show: boolean
    windowsizeState: number,
    successPercent: number,
    needRestartButton: boolean
}

type ExerciseContainerProps = {
    exerciseRecord?: IExerciseRecord,  // not required
    server?: ExerciseServerInterface, // not required
    engineName?: string | null | undefined, // required
    parentElement?: HTMLElement, // required
    randomExercise?: boolean,
    isInBook?: boolean, //required,
    chapterId?: number,
    history?: History,
    location?: Location,
    enableToggle?: boolean,
    defaultHidden?: boolean,
    evaluationCallBack?: () => void,
    //** This parameter is required if we run the exercise in share mode. This will be called, when the exercise solving is finished. */
    finished?: () => void,
    examMode?: boolean,
    exerciseInPopup?: boolean,
    sectionId?: string,
    replay?: boolean // Feladatok visszajátszása
    learningPathPlayState?: LpPlayStateNode //in case when exercise is in learning path
    onLpGetSuccessPercent?: (evaluation:Evaluated) => void,  //we also need to get time 
}

var idCounter = 1;
export default class ExerciseContainerComponent extends React.Component<ExerciseContainerProps, ExerciseContainerState, CountDown> {

    private exerciseServer: ExerciseServerInterface = new ExerciseServerSimulator();
    static noCountDownStates: ContainerState[] = [ContainerState.WaitingToPlay, ContainerState.FinalEvaluation, ContainerState.ReplayUserSolution, ContainerState.ReplayFinalEvaluation];
    static showControllButtonStates: ContainerState[] = [ContainerState.Active, ContainerState.Evaluate, ContainerState.RequestingExercise, ContainerState.ShowCorrectSolution, ContainerState.Successful, ContainerState.UnSuccessful];
    static backdropStates: ContainerState[] = [ContainerState.WaitingToPlay, /*ContainerState.FinalEvaluation,*/ ContainerState.Paused, ContainerState.Successful, ContainerState.UnSuccessful];

    private idPostfix = idCounter++;
    private countdownkey = 0;
    private userFinishedSeries = false;

    constructor(props: any) {
        super(props);

        if (this.props.server) this.exerciseServer = this.props.server as ExerciseServerSimulator;
        let currentState = this.props.replay ? ContainerState.ReplayUserSolution : ContainerState.WaitingToPlay;
        //: this.props.isInBook ? ContainerState.WaitingToPlay : ContainerState.RequestingExercise;

        this.state = {
            containerState: currentState,
            engineDisplayName: "",
            isFullScreen: false,
            needEvaluateButton: true,
            needNextButton: true,
            solutionResponseType: undefined,
            isSubSeries: false,
            currentExerciseIndex: 0,
            //specialBookTimePause: this.props.isInBook ? true : false,
            mins: "",
            secs: "",
            remainingTime: null,
            showHelperLinks: false,
            showSuggestedExercises: false,
            show: !this.props.defaultHidden,
            seriesExamMode: undefined,
            examSearchResults: [],
            windowsizeState: 1,
            evaluatedAlready: false,
            is_accessible: null,
            successPercent: 0,
            needRestartButton: true,
            simple_style: false
        };
    }

    containerStateChange(newState: ContainerState, params?: any) {
        switch (newState) {
            case ContainerState.WaitingToPlay:
                this.setState({ containerState: newState });
                break;
            case ContainerState.RequestingExercise:
                if (this.props.replay) {
                    this.replayNextExercise(0);
                } else {
                    if (this.state.containerState != ContainerState.WaitingToPlay)
                        this.setState({ containerState: newState });
                    this.reset(params);
                }
                break;
            case ContainerState.Active:
                if (this.state.solutionResponseType == ExerciseSolutionType.NotStarted && this.state.containerState == ContainerState.Evaluate) {
                    this.setState({ containerState: newState });
                } else if (this.state.containerState != ContainerState.ReplayUserSolution) {
                    this.setState({ containerState: newState, solutionResponseType: undefined });
                }
                break;
            case ContainerState.Evaluate:
                this.setState({ containerState: newState });
                if (this.state.containerState != ContainerState.Paused)
                    this.onEvaluateUserSolution();
                break;
            case ContainerState.Successful:
                this.setState({ containerState: newState });
                this.successfulEvaluation();
                break;
            case ContainerState.UnSuccessful:
                this.setState({ containerState: newState });
                this.unsuccessfulEvaluation();
                break;
            case ContainerState.ShowCorrectSolution:
                this.setState({ containerState: newState });
                this.showSolution();
                break;
            case ContainerState.FinalEvaluation:
                this.setState({ containerState: newState });
                this.showFinalEvaluation();
                break;
            case ContainerState.Paused:
                this.setState({ containerState: newState });
                break;
            case ContainerState.RequestingReplay:
                this.setState({ containerState: newState });
                break;
            case ContainerState.ReplayUserSolution:
                this.setState({ containerState: newState });
                break;
            case ContainerState.ReplayUserSolution:
                this.setState({ containerState: newState });
                break;
        }
    }


    render() {

        var isSubseries = this.state.isSubSeries;
        var toggleState = ""

        var showCountDown: boolean = (this.state.exerciseSeries && ExerciseContainerComponent.noCountDownStates.indexOf(this.state.containerState) == -1
            && this.state.exerciseSeries.max_solution_time_min && this.state.remainingTime) ? true : false;

        // var showControllButtons: boolean = (!this.state.exerciseSeries || ExerciseContainerComponent.showControllButtonStates.indexOf(this.state.containerState) > -1) ? true : false;
        var showControllButtons: boolean = (ExerciseContainerComponent.showControllButtonStates.indexOf(this.state.containerState) > -1) ? true : false;

        const seriesInfo = this.exerciseServer.getSeriesInfo();

        let endOfSeriesReached = !isSubseries && this.state.exerciseSeries && (seriesInfo.isFinished || (seriesInfo.totalExerciseCount && this.state.currentExerciseIndex == seriesInfo.totalExerciseCount - 1));

        this.state.show ? toggleState = "exe-container-toggletoShown" : toggleState = "exe-container-toggletoHidden"

        let progressPercent = 0;
        if (this.state.exerciseSeries && seriesInfo.totalExerciseCount) {
            progressPercent = ((this.state.currentExerciseIndex + 1) / seriesInfo.totalExerciseCount) * 100;
        }
        let isSuccesfull = false;
        let success_percent = 0;
        if (!this.state.exercise) {
            return <div className="row expanded">
                <div className="small-12 medium-4 medium-offset-4 column">
                    <BubbleLoader />
                </div>
            </div>
        }

        if (this.state.exerciseSeries) {
            if (seriesInfo.earnedPoints != undefined) {
                //const failedExerciseCount = seriesInfo.currentExerciseCount - this.state.exerciseSeriesSuccessCount;
                success_percent = roundToDecimals((seriesInfo.earnedPoints / seriesInfo.totalPoints) * 100, 2);

                if (this.state.exerciseSeries.success_limit_percent
                    && success_percent >= this.state.exerciseSeries.success_limit_percent) {
                    isSuccesfull = true;
                } else if (this.state.exerciseSeries.success_limit_percent == 0) {
                    isSuccesfull = true;
                }
            }
        }

        let currContainerStyle = "exe-container";
        let is_sni = this.state.exercise.is_sni && ExerciseEngineTypeCheck.isSNI(this.state.engineName!)
        let is_simple_style = this.state.simple_style;

        if (is_simple_style) {
            currContainerStyle = this.state.containerState == ContainerState.WaitingToPlay ? "exe-container-simple waiting-to-play" : "exe-container-simple";
        }

        const published = this.exerciseServer.isPublished();

        return <section ref="root" className={"exe-wrapper" + (this.state.isFullScreen ? " exe-fullscreen" : "")}>

            <div className={((this.state.isFullScreen) ? "exe-container-fullscreen-div " : "") + currContainerStyle}>
                <div className="exe-header">


                    {/* <div className={"exe-container-level-div float-right " + this.getLevelClass()}  ><div className="c1 exe-level-circle"></div><div className="c2 exe-level-circle"></div><div className="c3 exe-level-circle"></div><div className="c4 exe-level-circle"></div><div className="c5 exe-level-circle"></div></div> */}

                    <div className="exe-header-info">

                        {
                            !this.state.exercise.subject_id
                                ?
                                <span><img src={imageBaseURL + "img/IKON_SET/FELADATMOTOR/KONTENER_GOMBOK/interaktiv_tantargykozi.svg"} className="eke-controls-image" alt={__("Tantárgyközi okosfeladat")} /></span>
                                :
                                <span><img src={imageBaseURL + "img/IKON_SET/FELADATMOTOR/KONTENER_GOMBOK/interaktiv.svg"} className="eke-controls-image" alt={__("Okosfeladat")} title={__("Okosfeladat")} /></span>
                        }
                        <span><img src={getIconOfEngine(this.state.engineName!, false, true)} className="eke-controls-image" alt={this.state.engineDisplayName} title={__("Feladat típusa: {name}", { name: this.state.engineDisplayName })} /></span>
                        <span style={{ marginRight: "0.5em", marginLeft: "0.2em" }}><img src={imageBaseURL + "img/IKON_SET/FELADATMOTOR/KONTENER_GOMBOK/vegleges/feladat_nehezseg0" + this.getLevelClass() + ".svg"} className="eke-controls-image" alt={__("Nehézségi szint {level}", { level: this.getLevelClass() })} title={__("Nehézségi szint: {level}", { level: this.getLevelClass() })} /></span>

                        <div className="container-exercise-title">
                            {this.state.exerciseSeries ? <h3 className="exercise-title" title={this.state.exerciseSeries!.title}>{this.state.exerciseSeries!.title}</h3> : ""}
                            <div style={{ display: "flex" }}>
                                {this.state.exercise.exercise ? <h3 title={this.state.exercise.exercise.title} aria-label={__("Okosfeladat: {title}", { title: this.state.exercise.exercise.title })}>{this.state.exercise.exercise.title}</h3>
                                    : ""
                                }
                            </div>

                        </div>



                    </div>
                    <div className="exe-header-tools">
                        {!isSubseries ? "" :
                            <div className="sub-number"><h3> {this.state.subSeriesActualQuestionIndex != undefined ? this.state.subSeriesActualQuestionIndex + 1 : ""}/{this.state.subSeriesTotalQuestions}</h3></div>
                        }
                        <div className="tools-button tools-button-minimize" tabIndex={0} onClick={this.minimalizeContainer.bind(this)}>
                            <img src={"/img/IKON_SET/FELADATMOTOR/KONTENER_GOMBOK/vegleges/kisablak" + (this.state.simple_style ? "_dark.svg" : ".svg")} alt={this.state.show ? __("Összecsukás") : __("Kinyitás")} title={this.state.show ? __("Összecsukás") : __("Kinyitás")} style={{ height: "1em" }} />

                        </div>
                        {
                            (this.state.isFullScreen)
                                ?
                                <div className="tools-button tools-button-medium" tabIndex={0} onClick={this.closeFullScreen.bind(this)}>
                                    <img src={"/img/IKON_SET/FELADATMOTOR/KONTENER_GOMBOK/vegleges/kozepesablak" + (this.state.simple_style ? "_dark.svg" : ".svg")} alt={__("Normál méretre állítás")} title={__("Normál méret")} style={{ height: "1em" }} />
                                </div>
                                :
                                <div className="tools-button tools-button-maximize" tabIndex={0} onClick={this.openInFullScreen.bind(this)}>
                                    <img src={"/img/IKON_SET/FELADATMOTOR/KONTENER_GOMBOK/vegleges/teljeskepernyo" + (this.state.simple_style ? "_dark.svg" : ".svg")} alt={__("Teljes képernyő")} title={__("Teljes képernyő")} style={{ height: "1em" }} />
                                </div>

                        }
                        {this.props.exerciseInPopup
                            ? <div className="tools-button tools-button-close" tabIndex={0} onClick={() => { ($("#" + this.props.sectionId) as any).foundation('close'); $('.is-reveal-open').removeClass('is-reveal-open'); document.querySelector("html")!.style.overflow = "auto"; }}>
                                ×
                            </div>
                            : ""}

                    </div>
                </div>

                {
                    this.state.exerciseSeries && !this.state.exerciseSeries.is_adaptive ?
                        <div className="exe-progress progress" role="progressbar" aria-valuenow={success_percent} aria-valuemin={0} aria-valuemax={100} >
                            <div className="progress-meter" style={{ width: progressPercent + "%" }}></div>
                        </div>
                        : ""
                }
                <div className={toggleState}>
                    <div id={"curr-ex-div-" + this.idPostfix} className="exe-modal-parent">
                        {

                            ExerciseContainerComponent.backdropStates.indexOf(this.state.containerState) == -1 && this.state.solutionResponseType != ExerciseSolutionType.NotStarted
                                ?
                                null
                                :
                                (is_simple_style) /*&& this.state.containerState == ContainerState.WaitingToPlay*/
                                    ?
                                    <div className="exe-start-mask" onClick={(this.closePopup.bind(this))} style={{ zIndex: Z_INDEX_EXERCISE_POPUP_BACKGROUND + 2 }}>
                                    </div>
                                    :
                                    !this.state.evaluatedAlready ? <div onClick={(this.closePopup.bind(this))} key={1} className="exe-start-mask" style={{ zIndex: Z_INDEX_EXERCISE_POPUP_BACKGROUND + 3 }}>
                                        {
                                            this.state.containerState == ContainerState.WaitingToPlay
                                                ?
                                                <div>
                                                    <button onClick={this.specialBookstart.bind(this)} tabIndex={0} title={__("Kattints ide a kezdéshez")} className="exe-start-button">
                                                        <i className="fa fa-play fa-4x" aria-hidden="true"></i>
                                                        <label className="show-for-sr">{__("Kezdés")}</label>
                                                    </button>
                                                </div>
                                                : ""
                                        }
                                    </div> : <div className="exe-start-mask"></div>
                        }
                        {
                            (this.state.messageBoxContent) ?
                                <div>
                                    <div key={"messagebox_mask_1"} className="exe-container-messagebox-mask" style={{ zIndex: Z_INDEX_EXERCISE_POPUP_BACKGROUND }} area-label={__("Felugró ablak háttér")}>
                                    </div>

                                    <div className="exe-container-messagebox exe-container-messagebox-small">
                                        <div className="exe-container-messagebox-header">

                                            {
                                                (this.state.messageBoxContent.type != ModalTypes.SeriesSummary) ?
                                                    <button className="close-button exe-container-messagebox-close" type="button" onClick={this.closeModal.bind(this)} ><span>×</span></button>
                                                    : ""
                                            }
                                            <h3 className="exe-container-messagebox-title">{this.state.messageBoxContent.title}</h3>
                                        </div>
                                        {
                                            (this.state.messageBoxContent.content) ?
                                                <div className="exe-container-messagebox-content" dangerouslySetInnerHTML={{ __html: this.state.messageBoxContent.content ? this.state.messageBoxContent.content : "" }}></div>
                                                :
                                                <>

                                                    <div className="exe-container-messagebox-content">
                                                        <table>
                                                            <tbody>
                                                                <tr>
                                                                    <td>{__("Összes feladat")}:</td>
                                                                    <td>{seriesInfo.currentExerciseCount + " db"}</td>
                                                                </tr>
                                                                {/*<tr>
                                                                    <td>Sikeresen megoldott feladat:</td>
                                                                    <td>{this.state.exerciseSeriesSuccessCount} darab</td>
                                                                </tr>*/
                                                                }
                                                                <tr>
                                                                    <td>{__("Szerzett pontok")}:</td>
                                                                    <td>{roundToDecimals(seriesInfo.earnedPoints, 2)}/{seriesInfo.totalPoints}</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                        {this.state.exerciseSeries && this.state.exerciseSeries.show_max_points ?
                                                            <div className="exe-container-messagebox-result">
                                                                {isSuccesfull ? <h5 className="message">{__("Sikeres megoldás!")}</h5> : <h5>{__("Sikertelen megoldás!")}</h5>}
                                                                <div className="score">
                                                                    <span className="score-title"> {__("Elért teljesítmény")}:</span>
                                                                    <span style={{ fontSize: "1.25rem" }} className="badge"> {success_percent.toFixed() + " %"} </span>
                                                                </div>

                                                            </div>
                                                            : ""
                                                        }

                                                        {this.state.seriesExamMode
                                                            ?
                                                            <div className="exe-container-messagebox-content">
                                                                <h5>{__("Itt ellenőrizheted a tudásod")}:</h5>
                                                                {this.state.examSearchResults.map(function (x) {
                                                                    return (
                                                                        <p title={x.subject_name + " / " + x.grade_name + " / " + x.text}>
                                                                            <a target="_blank" className="exe-exam-result-link" href={"/tankonyv/" + x.book_uri_segment + "/" + x.lesson_uri_segment + "#section-" + x.section_id}>
                                                                                <i className="fa fa-arrow-circle-o-right" aria-hidden="true"></i> <MathJaxText>{x.subject_name + " / " + x.grade_name + " / " + x.text}</MathJaxText>
                                                                            </a>
                                                                        </p>
                                                                    );
                                                                })}
                                                                <hr />
                                                            </div>
                                                            : ""
                                                        }
                                                    </div>

                                                </>
                                        }


                                    </div>
                                </div>
                                : ""
                        }

                        {/*
                            this.state.containerState == ContainerState.ReplayUserSolution || this.state.containerState == ContainerState.ReplayFinalEvaluation ?
                                <div className="exe-container-replay-mask" style={{ zIndex: Z_INDEX_EXERCISE_POPUP_BACKGROUND + 1 }}>
                                </div>
                                : ""
                        */}

                        {
                            this.state.exerciseSeries && this.state.exerciseSeries.show_max_points ?
                                <div className="exe-earned-points" aria-hidden={this.state.containerState == ContainerState.WaitingToPlay}>
                                    {seriesInfo ? ExerciseContainerComponent.roundNumber(seriesInfo.earnedPoints) : 0}
                                    {this.state.exerciseSeries.is_adaptive ? "" : "/ " + seriesInfo.totalPoints}
                                </div>
                                : ""
                        }

                        <div ref="exerciseDiv" aria-hidden={this.state.containerState == ContainerState.WaitingToPlay} className="exe-engine" style={this.state.backgroundStyle}>
                        </div>

                        {
                            (this.state.containerState == ContainerState.Successful || this.state.containerState == ContainerState.UnSuccessful || this.state.containerState == ContainerState.Active) ?
                                this.renderEvaluation() : ""
                        }

                    </div>
                    {this.props.replay && (this.state.isSubSeries || this.state.exerciseSeries) ?
                        <div className="exe-controls">
                            <div className="exe-controls-header">
                                <button onClick={this.replayNextExercise.bind(this, -1)} className="exe-controls-button exe-controls-prew-question-button" id="backBtn" title={__("Előző feladat")}>
                                    <i aria-hidden={true}></i>
                                    <label className="show-for-large eke-controls-label" > {__("Előző feladat")}</label>
                                </button>
                                <button onClick={this.replayNextExercise.bind(this, 1)} className="exe-controls-button exe-controls-new-question-button" id="forwardBtn" title={__("Következő feladat")}>
                                    <i aria-hidden={true}></i>
                                    <label className="show-for-large eke-controls-label" > {__("Következő feladat")}</label>
                                </button>
                            </div>
                        </div>
                        :
                        <div className="exe-controls">
                            <div className={"exe-controls-header" + (showCountDown ? " exe-controls-countdown-active" : "")} >
                                <div className="exe-controls-cell exe-reload-button-cell">
                                    {
                                        this.state.containerState == ContainerState.WaitingToPlay
                                            ?
                                            is_simple_style
                                                ?
                                                <button onClick={this.specialBookstart.bind(this)} className={"exe-controls-button exe-controls-reload-button exe-controls-start-button simple-style"} title={__("Kezdés")} >
                                                    <i aria-hidden="true" className="fa fa-play"></i>
                                                </button>
                                                : ""
                                            :
                                            this.state.needRestartButton
                                                ?
                                                <button onClick={this.restartFromBeginning.bind(this)} className={"exe-controls-button exe-controls-reload-button " + (is_simple_style ? "simple-style" : "")} disabled={this.state.containerState == ContainerState.Paused} title={__("Újrakezdés")} >
                                                    <i aria-hidden="true"></i>
                                                    <label className="show-for-large eke-controls-label">{__("Újrakezdés")}</label>
                                                </button>
                                                : ""
                                    }

                                </div>

                                {
                                    showCountDown
                                        ?
                                        <div className="exe-controls-cell">
                                            <div className="exe-controls-countdown">
                                                <CountDown startSeconds={this.state.remainingTime!}
                                                    key={this.countdownkey}
                                                    isInSharedContent={false}
                                                    onComplete={this.timeIsOver.bind(this)}
                                                    timerIsPaused={this.pauseCounter.bind(this)}
                                                    ref="countDownChild"
                                                    canInterrupt={this.state.exerciseSeries ? this.state.exerciseSeries.can_interrupt : undefined}
                                                />
                                            </div>
                                        </div>
                                        :
                                        null
                                }

                                <div className="exe-controls-cell">
                                    {
                                        this.state.exercise.task_html ?

                                            <button onClick={this.showModal.bind(this, ModalTypes.Task)} className={"exe-controls-button exe-control-task-button" + (is_simple_style ? " simple-style" : "")} id="taskHtmlBtn" title={__("Feladat leírása")}>
                                                <i aria-hidden={true}></i>
                                                <label className="show-for-large eke-controls-label" > {__("Feladat")}</label>
                                            </button>
                                            : ""
                                    }
                                    {
                                        showControllButtons
                                            ?
                                            <>
                                                {
                                                    this.state.needEvaluateButton && !is_sni && ExerciseContainerComponent.backdropStates.indexOf(this.state.containerState) == -1 ?
                                                        <button onClick={this.containerStateChange.bind(this, ContainerState.Evaluate)}
                                                            className={"exe-controls-button exe-controls-check-button" + (is_simple_style ? " simple-style" : "")} title={__("Ellenőrzés")}>
                                                            <i aria-hidden={true}></i>
                                                            <label className="show-for-large eke-controls-label" > {__("Ellenőrzés")}</label>
                                                        </button>
                                                        : ""
                                                }

                                                {
                                                    (this.state.needNextButton) ?
                                                        <button onClick={() => this.containerStateChange((endOfSeriesReached ? ContainerState.FinalEvaluation : ContainerState.RequestingExercise), !this.state.evaluatedAlready)}
                                                            className={"exe-controls-button exe-controls-new-question-button" + (is_simple_style ? " simple-style" : "")} title={endOfSeriesReached ? __("Kiértékelés") : __("Következő kérdés")}>
                                                            <i aria-hidden={true}></i>
                                                            <label className="show-for-large eke-controls-label" >
                                                                {
                                                                    endOfSeriesReached
                                                                        ?
                                                                        __("Kiértékelés")
                                                                        :
                                                                        <>{__("Következő")}<br />{__("kérdés")}</>
                                                                }
                                                            </label>
                                                        </button>

                                                        : ""
                                                }
                                            </>
                                            :
                                            null
                                    }
                                </div>
                            </div>
                        </div>}
                    {me ?
                        <div className="exe-socials">
                            <div className="exe-socials-buttons">
                                {
                                    <UsrLikePlugin
                                        tableInfoId={published
                                            ? (this.state.exerciseSeries ? ExerciseSeriesCruPub.TABLE_INFO_ID : ExerciseCrudPub.TABLE_INFO_ID)
                                            : (this.state.exerciseSeries ? ExerciseSeriesCrud.TABLE_INFO_ID : ExerciseCrud.TABLE_INFO_ID)
                                        }
                                        recId={(this.state.exerciseSeries && this.state.exerciseSeries.id) ? this.state.exerciseSeries.id : this.state.exercise.id || null}
                                    />
                                }
                            </div>
                        </div>
                        : ""}


                </div>
            </div>
            <span className="show-for-sr">{this.state.exerciseSeries ? this.state.exerciseSeries.title : this.state.exercise.name} {__("okosfeladat vége")}</span>
        </section>;

    }

    animateText() {
        let title = document.getElementById('congrats-title');
        if (title) {
            TweenMax.from(title, 2, {
                scale: 0.4,
                opacity: 0,
                rotation: 15,
                ease: Back.easeOut.config(5)
            });
        }

    }

    animateElements() {
        let stars = document.getElementsByClassName('star');
        let blobs = document.getElementsByClassName('blob')
        var xSeed = Math.floor(Math.random() * 30) + 350;
        var ySeed = Math.floor(Math.random() * 50) + 120;

        if (stars) {

            [].forEach.call(stars, function (star: any) {
                var speed = Math.floor(Math.random() * 3) + 1;
                var rotation = Math.floor(Math.random() * 80) + 20;
                var scale = Number(((Math.random() * 0.7) + 0.8).toFixed(1));

                var x = Math.floor(Math.random() * 2 * xSeed) - xSeed;
                var y = Math.floor(Math.random() * 2 * ySeed) - ySeed;


                TweenMax.to(star, speed, {
                    x: x,
                    y: y,
                    ease: Power1.easeOut,
                    opacity: 0,
                    rotation: rotation,
                    scale: scale,
                    onStartParams: [star],
                    onStart: function (element: any) {
                        (element as any).style.display = "block";
                    },
                    onCompleteParams: [star],
                    onComplete: function (element: any) {
                        (element as any).style.display = "none";
                    }
                });


            });
        }

        if (blobs) {

            [].forEach.call(blobs, function (blob: any) {
                var speed = Math.floor(Math.random() * 3) + 1;
                var rotation = Math.floor(Math.random() * 80) + 20;
                var scale = Number(((Math.random() * 0.7) + 0.8).toFixed(1));

                var x = Math.floor(Math.random() * 2 * xSeed) - xSeed;
                var y = Math.floor(Math.random() * 2 * ySeed) - ySeed;


                TweenMax.to(blob, speed, {
                    x: x,
                    y: y,
                    ease: Power1.easeOut,
                    opacity: 0,
                    rotation: rotation,
                    scale: scale,
                    onStartParams: [blob],
                    onStart: function (element: any) {
                        (element as any).style.display = "block";
                    },
                    onCompleteParams: [blob],
                    onComplete: function (element: any) {
                        (element as any).style.display = "none";
                    }
                });


            });
        }
    }

    renderEvaluation() {
        if (this.state.exercise && (this.state.simple_style || this.state.exercise.is_sni)) {
            if (this.state.exercise.correct_answer_feedback_html && this.state.containerState == ContainerState.Successful)
                return;
            else if (this.state.exercise.wrong_answer_feedback_html && this.state.containerState == ContainerState.UnSuccessful)
                return;
        }

        const MoreHelpLink = this.exerciseServer.isPublished() ? PATH_EXERCISE_MORE_HELP_PUB : PATH_EXERCISE_MORE_HELP;
        let IsInBook = this.props.isInBook;
        let pointDisplay = null;

        let stars = [];
        for (let i = 0; i < 30; i++) {
            stars.push(<div className={"particle star fa fa-star " + i} ></div>);
            stars.push(<div className={'particle blob ' + i}></div>);
        }


        if (this.state.exerciseSeries && this.state.exerciseSeries.show_max_points) {
            pointDisplay = (<div className="exe-result-points">
                <p> {__("Szerzett pontok")}:</p>
                <span className="badge"> {this.state.currentEarnedPoints}</span>
            </div>);
        }

        if (!this.state.solutionResponseType) return "";

        if (this.state.solutionResponseType == ExerciseSolutionType.NotStarted) {
            return (<div className="exe-controls-result-wrapper"><div ref="suggestion" data-closable="" className="exe-controls-result exe-controls-result-correct">
                <div className="exe-control-container">
                    <span className="exe-control-label" >
                        <img className="exe-image" src={imageBaseURL + "img/IKON_SET/FELADATMOTOR/FELADATMOTOR_BELSO_ALAPOTJELZES/kerdojel_ikon.svg"} />
                        {__("Nem kezdtél hozzá a feladathoz!")}
                    </span>
                    <div className="exe-result-buttons">
                        <button onClick={(this.closePopup.bind(this))} className="exe-result-button exe-result-show-solution-button" title={__("Folytatás")}>
                            <div>
                                <i className="fa fa-angle-right" aria-hidden={true}></i>
                                <label> {__("Folytatás")} </label>
                            </div>
                        </button>
                    </div>
                </div>
            </div></div>);
        }

        if (this.state.solutionResponseType == ExerciseSolutionType.CorrectSolution) {

            if (this.state.exercise!.is_sni) {
                return (<div className="exe-sni-result-wrapper">
                    <div id='congrats' className="sni-congrats" data-closable="" style={{ zIndex: Z_INDEX_EXERCISE_POPUP_BACKGROUND + 5 }}>
                        <img className="sni-congrats-image" src={imageBaseURL + "img/IKON_SET/true.png"} alt={__("Ügyes vagy!")} />
                        {stars}
                    </div>
                </div>
                );


            }
            else {

                return (
                    <div className="exe-controls-result-wrapper"><div ref="suggestion" data-closable="" className="exe-controls-result exe-controls-result-correct">

                        {/* <img className="exe-image" src={imageBaseURL + "img/IKON_SET/FELADATMOTOR/KONTENER_GOMBOK/vegleges/megoldas_helyes_1.svg"} /> */}
                        <div className="exe-control-container">
                            <span className="exe-control-label green-icon" >{__("A megoldásod tökéletes!")}</span>
                            {pointDisplay}
                        </div>

                        {((this.state.subSeriesTotalQuestions && (this.state.subSeriesTotalQuestions - 1 == this.state.subSeriesActualQuestionIndex)) || this.state.subSeriesTotalQuestions == undefined) && IsInBook ?

                            <div className="exe-result-buttons exe-result-buttons-correct">
                                { /* <button onClick={(this.closePopup.bind(this))} className="close-button" title={__("Bezár")}><i className="fa fa-angle-right"></i>{__("Bezárás")}</button> */}
                                {this.state.showSuggestedExercises && this.state.searchSimilarExe && this.state.searchSimilarExe.length > 0 ?
                                    <button onClick={() => this.setState({ showSuggestedExercises: !this.state.showSuggestedExercises })} className="exe-result-button exe-result-button-show-exe exe-result-show-solution-button--hide" title={__("Bezárás")}>
                                        <i className="fa fa-chevron-down" aria-hidden="true"></i>
                                        <label>{__("Bezárás")}</label>
                                    </button>
                                    :
                                    <button onClick={() => this.setState({ showSuggestedExercises: !this.state.showSuggestedExercises })} className="exe-result-button exe-result-button-show-exe exe-result-show-solution-button--show" title={__("Mutass hasonló feladatokat a gyakorláshoz!")}>
                                        <i className="fa fa-chevron-left" aria-hidden="true"></i>
                                        <label>{__("Mutass hasonló feladatokat a gyakorláshoz!")}</label>
                                    </button>
                                }
                            </div> : ""}
                        {
                            this.state.exercise && (this.state.exercise.exercise.show_search_results || this.state.exercise.exercise.show_search_results == undefined) ?
                                this.state.showSuggestedExercises && this.state.searchSimilarExe && this.state.searchSimilarExe.length > 0
                                    ?
                                    <div className="exe-result-links">
                                        {this.state.searchSimilarExe.map(function (x) {
                                            return (
                                                <ToolTipDiv key={x._id} className="exe-result-links-item" title={x.subject_name + " / " + x.grade_name + " / " + x.exercise_title}>
                                                    <h3><a target="_blank" className="exe-result-link" href={"/feladat/megjelenites/" + x.id}>
                                                        <i className="fa fa-arrow-circle-right" aria-hidden="true"></i>
                                                        <MathJaxText><span className="link__class">{x.subject_name + " / " + x.grade_name + " / "}</span>  {x.exercise_title}</MathJaxText>
                                                    </a></h3>
                                                </ToolTipDiv>
                                            );
                                        })}
                                    </div>
                                    : ""
                                : ""
                        }
                    </div></div>);
            }
        }
        // if not correct solution

        return (<div className="exe-controls-result-wrapper"><div ref="suggestion" data-closable="" className="exe-controls-result exe-controls-result-wrong">
            <div className="exe-control-container">
                <span className="exe-control-label blue-icon" >{this.state.successPercent > 0.4 ? __('A megoldásod nem tökéletes!') : __('Rossz megoldás')}</span>
                {pointDisplay}
            </div>
            <div className="exe-result-buttons">
                {!this.state.seriesExamMode
                    ? <>
                        {
                            this.state.exercise && (this.state.exercise.exercise.show_search_results || this.state.exercise.exercise.show_search_results == undefined) ?
                                //!this.state.isSubSeries || (this.state.isSubSeries && this.userFinishedSeries) ? //if not subseries show the button, but if it is, then check that the user has already finished with all the exercises
                                this.state.showHelperLinks && this.state.searchResults && this.state.searchResults.length > 0
                                    ?
                                    <button onClick={() => this.setState({ showHelperLinks: !this.state.showHelperLinks })} className="exe-result-button exe-result-help-button--hide" title={__("Segítség elrejtése")}>

                                        <i className="fa fa-chevron-down" aria-hidden="true"></i>
                                        <label>{__("Segítség elrejtése")}</label>
                                    </button>
                                    :
                                    <button onClick={() => this.setState({ showHelperLinks: !this.state.showHelperLinks })} className="exe-result-button exe-result-help-button--show" title={__("Hol nézhetek utána?")}>
                                        <i className="fa fa-chevron-left" aria-hidden="true"></i>
                                        <label>{__("Hol tudok utánanézni?")}</label>
                                    </button>
                                : ""
                            // : ""
                        }


                        <button onClick={this.containerStateChange.bind(this, ContainerState.ShowCorrectSolution)} className="exe-result-button exe-result-show-solution-button" title={__("Kérem a választ!")}>
                            <div>
                                <i className="fa fa-question" aria-hidden={true}></i>
                                <label> {__("Kérem a választ!")} </label>
                            </div>
                        </button>
                    </>
                    : ""
                }
                {!this.props.finished && <button onClick={(this.closePopup.bind(this))} className="exe-result-button exe-result-show-solution-button" title={__("Folytatás")}>
                    <div>
                        <i className="fa fa-angle-right" aria-hidden={true}></i>
                        <label> {__("Folytatás")} </label>
                    </div>
                </button>}
            </div>
            {
                this.state.exercise && (this.state.exercise.exercise.show_search_results || this.state.exercise.exercise.show_search_results == undefined) ?
                    //!this.state.isSubSeries || (this.state.isSubSeries && this.userFinishedSeries) ?   //if not subseries show the button, but if it is, then check that the user has already finished with all the exercises
                    this.state.showHelperLinks && this.state.searchResults && this.state.searchResults.length > 0
                        ?
                        <div className="exe-result-links">
                            {this.state.searchResults.map(function (x) {
                                return (
                                    <ToolTipDiv key={x._id} className="exe-result-links-item" title={x.subject_name + " / " + x.grade_name + " / " + x.text}>
                                        <h3 className="show-for-sr"><a target="_blank" href={"/tankonyv/" + x.book_uri_segment + "/" + x.lesson_uri_segment + "#section-" + x.section_id}>{x.subject_name + " / " + x.grade_name + " / " + x.lesson_name}</a></h3>
                                        <span className="show-for-sr">{x.text}</span>
                                        <h3 aria-hidden="true"><a target="_blank" className="exe-result-link" href={"/tankonyv/" + x.book_uri_segment + "/" + x.lesson_uri_segment + "#section-" + x.section_id}>
                                            <i className="fa fa-arrow-circle-right" aria-hidden="true"></i>  <MathJaxText><span aria-hidden="true" className="link__class">{x.subject_name + " / " + x.grade_name + " / "}</span>  {x.text}</MathJaxText>
                                        </a></h3>
                                    </ToolTipDiv>
                                );
                            })}

                            {(!this.exerciseServer || !this.state.exercise || !this.state.exercise.id)
                                ? ""
                                : <a href={MoreHelpLink + `/${this.state.exercise.id}`} target="_new" key={this.state.exercise.id}>
                                    <button className="exe-result-button exe-result-buttons" title={__("Az összes link megnyitása új lapon")}>
                                        <div>
                                            <i className="fa fa-list" aria-hidden={true}></i>
                                            <label> {__("Mutass többet")} </label>
                                        </div>
                                    </button>
                                </a>
                            }
                        </div>
                        : ""
                    : ""
                //: ""
            }
        </div></div>);

        // return (<div className="exe-controls-result-wrapper"><div ref="suggestion" data-closable="" className="exe-controls-result exe-controls-result-wrong">
        //     <button onClick={(this.closePopup.bind(this))} className="close-button" title="Bezár">&times;</button>
        //     {<span className="exe-control-label" >A megoldás nem tökéletes!</span>}
        //     <div className="exe-result-buttons">
        //         <button onClick={this.containerStateChange.bind(this, ContainerState.ShowCorrectSolution)} className="exe-result-button exe-result-show-solution-button" title="Kérem a választ">
        //             <div>
        //                 <i className="fa fa-question" aria-hidden={true}></i>
        //                 <label> Kérem a választ </label>
        //             </div>
        //         </button>
        //     </div>
        //     <br />
        //     {pointDisplay}
        // </div>
        // </div>);
    }

    private resetting = false;
    async specialBookstart() {
        this.containerStateChange(ContainerState.Active);

        if (this.state.exerciseSeries) {
            PublicServer.sendUserViewEvent({
                tableInfoId: ExerciseSeriesCrud.TABLE_INFO_ID,
                recordId: this.state.exerciseSeries.id!,
                name: this.state.exerciseSeries.title || "",
                data: {
                    //event: "exercise_series",
                    //exercise_series_event: "start",
                    eventTarget: PublicServer.UserEventTargets.EXERCISE_SERIES,
                    eventType:PublicServer.UserEventTypes.START,
                    exerciseRecord: this.state.exerciseSeries,
                }
            });
        }

        if (this.state.exercise && this.state.exercise.id) {
            PublicServer.sendUserViewEvent({
                tableInfoId: ExerciseCrud.TABLE_INFO_ID,
                recordId: this.state.exercise.id,
                name: this.state.exercise.name || "",
                data: {
                    //event: "exercise",
                    //exercise_series_event: "start",
                    eventTarget: PublicServer.UserEventTargets.EXERCISE,
                    eventType:PublicServer.UserEventTypes.START,
                    exerciseRecord: this.state.exercise,
                }
            });
        }

        /* Force recalculate the size of exercise */
        /*
        if (this.state && this.state.exerciseEngine) {
            this.state.exerciseEngine.containerResized();
        }
        */
    }


    async reset(getNextExercise: boolean) {
        if (!this.props.engineName && !this.exerciseServer) {
            //app.showErrorFromJsonResult(error);
            app.showError(__("Nem sikerült betölteni a feladatmotort"), __("Error: no engine name or server {name}", { name: this.state.engineName }));
            return;
        }

        if (this.state.isSubSeries) {// && this.state.exerciseEngine && this.state.engineName && ExerciseContainerComponent.subseriesEngines.indexOf(this.state.engineName)>-1){
            var subQuestInfo = (this.state.exerciseEngine as ExerciseEngineSubSeries).getNextSubQuestion(NavigationDirections.Next);
            if (subQuestInfo.is_next_available) {
                this.setState({
                    solutionResponseType: undefined,
                    needEvaluateButton: true,
                    needNextButton: false,
                    evaluatedAlready: false,
                    subSeriesTotalQuestions: subQuestInfo.number_of_questions,
                    subSeriesActualQuestionIndex: subQuestInfo.current_question_index,
                });
                return;
            } else { // end of the subseries
            }
        }

        //When we reach the end of the series
        if (this.props.server) {
            const seriesInfo = this.props.server.getSeriesInfo();
            if (getNextExercise
                && ((seriesInfo.totalExerciseCount && this.state.currentExerciseIndex == seriesInfo.totalExerciseCount - 1) || seriesInfo.isFinished)) {
                if (this.state.isSubSeries) this.setState({ isSubSeries: false });
                this.containerStateChange(ContainerState.FinalEvaluation);
                return;
            }
        }

        // Ez azért van, ha az editor nagyon gyorsan hívja meg a reset-et többször egymás után, akkor sorban fusson le ez (sok await)
        var counter = 0;
        while (this.resetting && counter++ < 100) {
            await timeoutAsync(100);
        }
        this.resetting = true;

        this.userFinishedSeries = false;
        try {

            if (this.props.exerciseRecord && this.props.engineName && this.props.exerciseRecord != this.state.exercise) {
                (this.exerciseServer as ExerciseServerSimulator).setExerciseRecord(this.props.engineName, this.props.exerciseRecord);
            }

            var response: ExerciseWithMeta | null = null;
            if (getNextExercise) {
                response = await this.exerciseServer.skipCurrentExercise();
            } else {
                response = await this.exerciseServer.getCurrentExercise();
            }

            if (!response) {
                // if no exercise
                if (this.props.server) {
                    const seriesInfo = await this.props.server!.getSeriesInfo();
                    this.setState({
                        totalEarnedPoints: ExerciseContainerComponent.roundNumber(seriesInfo.earnedPoints),
                        solutionResponseType: undefined,
                        needEvaluateButton: false,
                        needNextButton: this.state.exerciseSeries ? true : false,
                        evaluatedAlready: false,
                    });
                }
            }// if exercise
            else if (response.exercise && response.exercise != this.state.exercise) {
                await this.displayExercise(response);
            }

        } finally {
            this.resetting = false;
        }
    }

    async displayExercise(response: any) {
        var exEngine = instantiateExerciseEngine(response.exerciseEngineName);

        if (exEngine) {

            let is_sni = response.exercise.is_sni && ExerciseEngineTypeCheck.isSNI(response.exerciseEngineName);
            let is_accessible = response.exercise.is_accessible;
           // let isLowgrade = await this.isLowerGrade(this.state.exerciseSeries ? this.state.exerciseSeries.grade_id : response.exercise.grade_id);
            let is_simple_style = (response.exercise.simple_style ? response.exercise.simple_style : false);
            let isSharedContent: boolean = false;
            let shareContentRunId: any;
            if (this.exerciseServer.getSharedContentRunId)
                shareContentRunId = (this.exerciseServer as ExerciseServer).getSharedContentRunId();

            if (shareContentRunId) {
                isSharedContent = true;
            }
            var series = false;
            if (ExerciseEngineTypeCheck.isSubSeries(response.exerciseEngineName)) {
                series = true;
            }
            let neednextbtn = false || (is_sni && series);//(series || (this.state.exerciseSeries && !this.state.exerciseSeries.is_adaptive) ? true : false);

            let totalEarnedPoints = 0;
            let seriesInfo = undefined;
            if (this.props.server) {
                seriesInfo = await this.props.server!.getSeriesInfo();
                totalEarnedPoints = ExerciseContainerComponent.roundNumber(seriesInfo.earnedPoints);
            }
            let engineDisplayName = await getDisplayNameOfEngine(response.exerciseEngineName!);

            this.setState({
                solutionResponseType: undefined,
                exercise: response.exercise,
                exerciseEngine: exEngine,
                engineDisplayName: engineDisplayName,
                currentExerciseIndex: response.currentExerciseIndex,
                backgroundStyle: await this.getBackGround(response.exercise, is_sni, is_simple_style),
                needEvaluateButton: !is_sni,
                isSubSeries: series,
                engineName: response.exerciseEngineName,
                currentEarnedPoints: 0,
                needNextButton: neednextbtn,
                evaluatedAlready: false,
                is_accessible: is_accessible,
                totalEarnedPoints,
                needRestartButton: !isSharedContent,
                simple_style: is_simple_style,
            });
            let new_ex = { ...response.exercise.exercise, backgroundStyle: await this.getBackGround(response.exercise, is_sni, is_simple_style), isExam: this.state.seriesExamMode }

            // todo: do it correctly! to test SNI    
            let tempSol = {};
            if (is_sni) {
                tempSol = response.exercise.id
                    ? (await this.exerciseServer.getCorrectSolution(response.exercise.id))
                    : (await this.exerciseServer.evaluate(response.exercise, { answer: {} }) as Evaluated).solution;
            }


            let exeParams: ExerciseParams = {
                element: this.refs.exerciseDiv as HTMLElement,
                exercise: new_ex,
                reloadResources: this.reloadResources.bind(this),
                userReadyWithSubSeries: this.userReadyWithSubSeries.bind(this),
                SNIUserReady: this.userReadyWithSNI.bind(this), // todo: replace with correct method
                SNISolution: tempSol,
                isReplay: this.props.replay ? this.props.replay : false,
                is_accessible: is_accessible,
                simple_style: is_simple_style,
                isExamMode:this.state.seriesExamMode || false
            }
            exeParams.element.innerHTML = "";

            exEngine.initExercise(exeParams);
            if (this.state.containerState != ContainerState.WaitingToPlay) {
                this.containerStateChange(ContainerState.Active);
            }

            if (this.state.isSubSeries && this.state.exerciseEngine) {
                let subQuestInfo = (this.state.exerciseEngine as ExerciseEngineSubSeries).getNextSubQuestion(NavigationDirections.Current);
                this.setState({
                    subSeriesActualQuestionIndex: subQuestInfo.current_question_index,
                    subSeriesTotalQuestions: subQuestInfo.number_of_questions,
                })
            }

            $('.exe-wrapper').on('open.zf.reveal', function () {
                $('body').removeClass('is-reveal-open')
            });

            if (this.state.exerciseSeries && seriesInfo && seriesInfo.currentExerciseCount == 1) {
                if (this.state.exerciseSeries.introduction)
                    this.showModal(ModalTypes.SeriesIntro);
            }
            if (this.state.exercise!.task_html) {
                this.showModal(ModalTypes.Task);
            }
            try {
                this.reloadResources();
            } catch (e) {
                app.showErrorFromJsonResult(e);
            }
        }

    }

    public async restartFromBeginning() {
        if (this.props.server) {

            this.userFinishedSeries = false;

            const series = await this.props.server.startSeries();
            this.setState({
                exerciseSeries: series,
                totalEarnedPoints: 0,
                evaluatedAlready: false
            });

            if (!this.props.replay && this.state.exerciseSeries && this.state.exerciseSeries.max_solution_time_min) {
                this.countdownkey++;
                this.setState({ remainingTime: 60 * this.state.exerciseSeries.max_solution_time_min, currentExerciseIndex: 0 });
            }
            this.closeModal();
        }

        this.setState({ messageBoxContent: undefined, currentExerciseIndex: 1, isSubSeries: false }, () => this.containerStateChange(ContainerState.RequestingExercise, false));
    }


    //This method is called from the QuizSeries and ParingQuiz engines
    userReadyWithSubSeries(succes: boolean): void {
        this.userFinishedSeries = true;
        if (!this.state.exercise) return;
        if (succes && this.state.exercise.correct_answer_feedback_html
            /*&& this.state.exercise.correct_answer_feedback_html.length > 200*/) {     //feedback htmls character number restriction just uncommented
            this.showModal(ModalTypes.Correct)
            }
        else if (this.state.exercise.wrong_answer_feedback_html
            /*&& this.state.exercise.wrong_answer_feedback_html.length > 200*/) {
            this.showModal(ModalTypes.Wrong);
            }

        if(!this.state.exerciseSeries && this.props.finished) this.props.finished();
    }

    userReadyWithSNI(): void {
        let evaluationState = ExerciseSolutionType.CorrectSolution;
        this.props.evaluationCallBack ? this.props.evaluationCallBack() : void (0);

        this.setState({
            solutionResponseType: evaluationState,
            searchResults: undefined,
        });
        this.containerStateChange(ContainerState.Successful);
    }


    async replayNextExercise(direction: NavigationDirections) {

        if (!this.props.replay || !this.exerciseServer) return;

        let exeIdx = this.state.currentExerciseIndex + direction;
        if (this.state.isSubSeries && this.state.subSeriesActualQuestionIndex && this.state.subSeriesActualQuestionIndex >= 0) {
            exeIdx = this.state.subSeriesActualQuestionIndex + direction;
        }

        if (exeIdx >= 0) { // && this.state.exerciseSeries!.exc_count && exeIdx < this.state.exerciseSeries!.exc_count!) {

            let relIdx = this.state.currentExerciseIndex + direction;
            let isMoreQuestion = false;
            if (this.state.isSubSeries) {
                let subQuestInfo = (this.state.exerciseEngine as ExerciseEngineSubSeries).getNextSubQuestion(direction);
                this.setState({
                    subSeriesActualQuestionIndex: subQuestInfo.current_question_index,
                    subSeriesTotalQuestions: subQuestInfo.number_of_questions,
                })
                isMoreQuestion = subQuestInfo.is_next_available;
                relIdx = isMoreQuestion ? this.state.currentExerciseIndex : this.state.currentExerciseIndex + direction;
            }

            //let relIdx = this.state.isSubSeries ? this.state.currentExerciseIndex : this.state.currentExerciseIndex + direction;
            let response = await this.exerciseServer.getExercisebyIndex(relIdx);
            if (!response) return;

            if (this.state.isSubSeries) {// && this.state.exerciseEngine && this.state.engineName && ExerciseContainerComponent.subseriesEngines.indexOf(this.state.engineName)>-1){
                //let isTheremoreQuestion = (this.state.exerciseEngine as ExerciseEngineSubSeries).getNextSubQuestion(direction);
                if (isMoreQuestion) {
                    this.replayCurrrentSolution(response);
                    return;
                }
            }
            await this.displayExercise(response);
            this.replayCurrrentSolution(response);
        }

    }

    async replayCurrrentSolution(exercise: ExerciseWithMeta) {
        let showCorrectSolution = (await (this.state.exerciseEngine as ExerciseEngine10).showCorrectSolution(exercise.userSolution));
        let correctSolution = (await this.exerciseServer.getCorrectSolution(exercise.exercise.id));
        const temp_eval: Evaluated = { success: false, solution: correctSolution };
        (this.state.exerciseEngine as ExerciseEngine10).receiveEvaluation(temp_eval);
    }

    async componentDidMount() {
        if (this.props.server) {

            const exerciseSeries = await this.props.server.startSeries();
            let tempexamMode = this.props.examMode;
            if (tempexamMode === undefined && exerciseSeries) {
                tempexamMode = exerciseSeries.exc_display_mode_id == 1 ? true : false
            }
            this.setState({
                exerciseSeries: exerciseSeries,
                seriesExamMode: tempexamMode
            });
            if (!this.props.replay && this.state.exerciseSeries && this.state.exerciseSeries.max_solution_time_min) {
                this.setState({ remainingTime: 60 * this.state.exerciseSeries.max_solution_time_min })
            }
        }
        this.containerStateChange(ContainerState.RequestingExercise, this.props.randomExercise ? this.props.randomExercise : false);


    }

    private prevFoundationRoot: HTMLElement;

    async componentDidUpdate(prevProps: any, prevState: ExerciseContainerState) {

        // this.animateText();
        this.animateElements();
        // TODO ez kell egyáltalán? Elvileg nincs is foundation használva már itt.
        if (this.refs.root && this.refs.root != this.prevFoundationRoot) {
            ($(this.refs.root) as any).foundation();
            this.prevFoundationRoot = this.refs.root as HTMLElement;
        }

        if (!this.refs.suggestion) return;

        let parentElement = (this.refs.suggestion as any).parentElement;


        // Ha van éppen eredmény jelzés
        //if (this.refs.suggestion) {
        if (this.state.containerState == ContainerState.Successful || this.state.containerState == ContainerState.UnSuccessful) {

            //if (this.state.engineName == "EKEQuizSeriesExerciseEngine" || this.state.engineName == "EKEPairingExerciseEngine") {
            parentElement.style.bottom = "-10px";
            (this.refs.suggestion as HTMLDivElement).style.display = "";

            if (($(parentElement) as any).position().top < 0) {
                parentElement.style.top = "0";
                parentElement.style.bottom = "auto";
            }

            ($(parentElement) as any).draggable({
                cancel: ".button, .close-button, .exe-result-button",
                containment: "parent",
                axis: "y",
                drag: function (event: any, ui: any) {
                    $(this).css({
                        "bottom": "auto"
                    });
                },
                stop: function (event: any, ui: any) {
                    var elem = $(this)[0];
                    var eBottom = elem.getBoundingClientRect().bottom;
                    var pBottom = elem.parentElement.getBoundingClientRect().bottom;
                    $(elem).css({ bottom: pBottom - eBottom, top: "auto" });
                }
            });
        }

        if (prevState.isFullScreen != this.state.isFullScreen) {
            $(document).resize();
        }
    }




    /*-------------------------------------------*/
    /* ---------- Evaluation methods ------------*/
    /*-------------------------------------------*/

    private async onEvaluateUserSolution() {

        if (!this.state.exerciseEngine || !this.exerciseServer || !this.state.exercise)
            return;

        if (!this.state.exerciseEngine.isUserReady()) {
            this.setState({ solutionResponseType: ExerciseSolutionType.NotStarted }, () => (this.containerStateChange(ContainerState.Active)));
            return;
        }

        if (this.state.exerciseSeries) {
            PublicServer.sendUserViewEvent({
                tableInfoId: ExerciseSeriesCrud.TABLE_INFO_ID,
                recordId: this.state.exerciseSeries.id!,
                name: this.state.exerciseSeries.title || "",
                data: {
                    //event: "exercise_series",
                    //exercise_series_event: "evaluate",
                    eventType:PublicServer.UserEventTypes.EVALUATE,
                    eventTarget: PublicServer.UserEventTargets.EXERCISE_SERIES,
                    exerciseRecord: this.state.exerciseSeries,
                }
            });
        }
        else if (!this.state.exerciseSeries && !this.state.isSubSeries) {
            PublicServer.sendUserViewEvent({
                tableInfoId: ExerciseSeriesCrud.TABLE_INFO_ID,
                recordId: this.state.exercise.id!,
                name: this.state.exercise.name || "",
                data: {
                    //event: "exercise",
                    //exercise_series_event: "evaluate",
                    eventType:PublicServer.UserEventTypes.EVALUATE,
                    eventTarget: PublicServer.UserEventTargets.EXERCISE,
                    exerciseRecord: this.state.exercise,
                }
            });
        }


        try {
            var userSolution = this.state.exerciseEngine.getUserSolution();
            var evaluation: Evaluated = await this.exerciseServer.evaluate(this.state.exercise, userSolution);

            if (this.props.learningPathPlayState && this.props.onLpGetSuccessPercent) {
                this.props.onLpGetSuccessPercent(evaluation);
            }
            let succesPercent: number = 0;
            if (evaluation.successPercent)
                succesPercent = evaluation.successPercent;
            this.setState({ evaluatedAlready: true, successPercent: succesPercent });
            // If the exercise is finished we call back the finished method. In this case, we need the sourca page to navigate away.
            if (this.props.finished) {

                if (!this.state.exerciseSeries) {
                    if (!this.state.isSubSeries || this.state.subSeriesTotalQuestions == this.state.subSeriesActualQuestionIndex) {
                        this.props.finished();
                    }
                } else {
                    const seriesInfo = this.exerciseServer.getSeriesInfo();
                    if (seriesInfo.isFinished || (seriesInfo.totalExerciseCount && this.state.currentExerciseIndex == seriesInfo.totalExerciseCount)) {
                        this.props.finished();
                    }
                }
            }

            if (this.state.seriesExamMode) {
                this.containerStateChange(ContainerState.RequestingExercise, false);

            } else {
                this.state.exerciseEngine.receiveEvaluation(evaluation);
                if (evaluation.success) {
                    this.containerStateChange(ContainerState.Successful);
                } else {
                    this.containerStateChange(ContainerState.UnSuccessful);
                }
            }
        } catch (error) {
            app.showErrorFromJsonResult(error);
        }

        /* if (this.props.server) {
             const seriesInfo = await this.props.server!.getSeriesInfo();
             this.setState({
                 earnedPoints: Math.round(seriesInfo.earnedPoints * 100) / 100
             });
         }*/
    }

    private async successfulEvaluation() {
        let evaluationState = ExerciseSolutionType.CorrectSolution;
        let totalEarnedPoints = await this.getTotalEarnedPoints();
        let searchSimilar;
        //getting similar exercises
        if (this.state.exercise && this.state.exercise.id) {
            if (this.state.exerciseSeries)
                searchSimilar = await PublicServer.searchSimilar(this.state.exercise!.id, true, 5, "exercise_series");
            else
                searchSimilar = await PublicServer.searchSimilar(this.state.exercise!.id, true, 5, "exercise");
        }
        this.setState({
            solutionResponseType: evaluationState,
            searchResults: undefined,
            searchSimilarExe: searchSimilar,
            //totalEarnedPoints,
            currentEarnedPoints: this.getCurrentEarnedPoints(totalEarnedPoints)
        }, () => this.setButtonVisibility(true));

        if (!this.state.seriesExamMode) {

            if (this.state.exercise && this.state.exercise.correct_answer_feedback_html
            /*&& this.state.exercise.wrong_answer_feedback_html.length > 200*/) {
                if (!this.state.isSubSeries) this.showModal(ModalTypes.Correct);
            }
        }
    }

    private async unsuccessfulEvaluation() {

        let evaluationState = ExerciseSolutionType.WrongSolution;
        let totalEarnedPoints = await this.getTotalEarnedPoints();

        this.setState({
            solutionResponseType: evaluationState,
            searchResults: undefined,
            //totalEarnedPoints,
            currentEarnedPoints: this.getCurrentEarnedPoints(totalEarnedPoints)
        }, () => this.setButtonVisibility(false));

        if (!this.state.seriesExamMode) {

            if (this.state.exercise && this.state.exercise.wrong_answer_feedback_html
                    /*&& this.state.exercise.correct_answer_feedback_html.length > 200*/) {              //feedback htmls character number restriction just uncommented
                if (!this.state.isSubSeries) this.showModal(ModalTypes.Wrong);
            }
            var searchResults = await this.loadFiveSearchResults();
            this.setState({
                searchResults
            });
        }
    }

    private async getTotalEarnedPoints(): Promise<number> {
        let earnedPoints = 0;
        if (this.props.server) {
            const seriesInfo = await this.props.server!.getSeriesInfo();
            earnedPoints = ExerciseContainerComponent.roundNumber(seriesInfo.earnedPoints);
        }
        return earnedPoints;
    }

    private getCurrentEarnedPoints(totalEarnedPoints: number): number {
        let currTP = this.state.totalEarnedPoints ? this.state.totalEarnedPoints : 0;
        return ExerciseContainerComponent.roundNumber(totalEarnedPoints - currTP);
    }

    private async showFinalEvaluation() {
        this.showModal(ModalTypes.SeriesSummary);
    }

    private async showSolution() {
        // Amennyiben kérte a választ, már nem kell a gomb, de nem ellenőrizhet megint csak ha kvízsor/párosítás sorban vagyunk
        this.setState({
            needEvaluateButton: false,
            solutionResponseType: undefined,
        });

        if (!this.exerciseServer || !this.state.exercise || !this.state.exercise.id) {
            return;
        }
        var solution = await this.exerciseServer.getCorrectSolution(this.state.exercise.id);

        if (this.state.exerciseEngine) {
            this.state.exerciseEngine.showCorrectSolution(solution);
        }
        this.reloadResources();
    }

    /*-------------------------------------------*/
    /* -------------- Helper methods ------------*/
    /*-------------------------------------------*/

    private setButtonVisibility(isSucessfull: boolean) {

        var needEvaluateB: boolean = !isSucessfull;

        this.props.evaluationCallBack ? this.props.evaluationCallBack() : void (0);

        let needNextBtnAdaptive = this.state.exerciseSeries && (this.state.evaluatedAlready || this.state.exercise!.is_sni) ? true : false;
        let endOfSubSeries: boolean = false;
        if (this.state.isSubSeries && this.state.subSeriesActualQuestionIndex && this.state.subSeriesTotalQuestions) {
            endOfSubSeries = this.state.subSeriesActualQuestionIndex! + 1 == this.state.subSeriesTotalQuestions;
        }
        if (!needEvaluateB) {
            if (this.state.exerciseSeries)
                needEvaluateB = !(this.state.seriesExamMode || this.state.evaluatedAlready);
        }

        this.setState({
            needEvaluateButton: needEvaluateB,
            needNextButton: needNextBtnAdaptive || (this.state.isSubSeries && !endOfSubSeries),
        });

    }


    async reloadResources() {
        MathJax.Hub.Config({ CommonHTML: { linebreaks: true } });
        MathJax.Hub.Config({ "HTML-CSS": { linebreaks: true } });
        MathJax.Hub.Config({ SVG: { linebreaks: true } });
        MathJax.Hub.Queue(["Typeset", MathJax.Hub]);

        let imgList = (this.refs.exerciseDiv as HTMLElement).querySelectorAll('img');

        const published = this.exerciseServer.isPublished();

        if (this.state.exercise && imgList) {
            let lighboxId = "feladat-keptar-" + this.state.exercise.exercise.title;

            let currImgFiles: OoFileCrud.IOoFileRecord[];

            if (published) {
                currImgFiles = await OoFileCrudPub.default.list({ filter: { oo_folder_id: this.state.exercise.oo_folder_id } });
            } else {
                currImgFiles = await OoFileCrud.default.list({ filter: { is_active: true, oo_folder_id: this.state.exercise.oo_folder_id } });
            }


            for (let i = 0; i < imgList.length; i++) {
                var $img: any = $(imgList[i]);
                let isWrapped = $img.parent() ? $img.parent().hasClass("exe-image-wrap") : false;
                let originalSrc: string = $img.attr('src');
                if (!$img.hasClass("exe-zoom-icon") && !isWrapped && originalSrc) {
                    let imgSha1: string = originalSrc.replace('/api/media/file/', '');

                    /* Previous "no-zoom class - media replace" function was moved into the individual engines */
                    if (!$img.hasClass("exe-img-no-zoom")) {
                        $img.addClass("exe-image-core");

                        if ($img.hasClass("exe-engine-illustration") || $img.hasClass("exe-large-img") || ($img.parent() && $img.parent().hasClass("exe-large-img"))) {
                            $img.attr('src', originalSrc.replace("/api/media/file/", "/api/media/thumb/500/"));
                        } else {
                            $img.attr('src', originalSrc.replace("/api/media/file/", "/api/media/thumb/200/"));
                        }

                        const frstImageElement = currImgFiles.find(f => f.sha1 == imgSha1);
                        if (!frstImageElement) continue;
                        let lightboxTitle: string = $img.attr('alt');
                        if (!lightboxTitle || lightboxTitle.length < 1 || lightboxTitle == "undefined") { lightboxTitle = ""; }
                        if (frstImageElement && frstImageElement.origin_display) lightboxTitle += "</br> " + __("Forrás:") + " " + frstImageElement.origin_display;
                        var $imgWrap = $('<div class="exe-image-wrap"></div>');
                        var $icon = $('<a aria-hidden="true" data-title="' + lightboxTitle + '" data-lightbox="' + lighboxId + '"><img aria-hidden="true" class="exe-zoom-icon" src="/img/IKON_SET/kep_ikon.svg"/></a>');

                        $icon.attr('href', originalSrc.replace("/api/media/file/", "/api/media/max_width/2048/"));
                        $img.wrap($imgWrap);
                        $img.after($icon);
                    }
                }
            }
        }
        $('.exe-image-wrap img').dblclick(function () {
            $(this).next('a').trigger('click');
        });


        // exe-content
        let exeDiv = (this.refs.exerciseDiv as HTMLElement).querySelector('.exe-content');
        if (exeDiv) {
            tableScrollSideBlur(exeDiv as HTMLElement);

            /* Force attributes to exercisediv */
            if (this.state && this.state.exercise) {
                /* If SNI or not */
                if (this.state.exercise.is_sni) {
                    exeDiv.setAttribute('data-snimode', 'true');
                } else {
                    exeDiv.setAttribute('data-snimode', 'false');
                }

                /* Need simple style or not */
                let grade_id = Number(this.state.exerciseSeries ? !this.state.exerciseSeries.grade_id : this.state.exercise.grade_id);
                //let isLowgrade = await this.isLowerGrade(grade_id);
                let is_simple_style = this.state.exercise.simple_style; //isLowgrade || this.state.exercise.simple_style;

                if (is_simple_style) {
                    exeDiv.setAttribute('data-simplemode', 'true');
                } else {
                    exeDiv.setAttribute('data-simplemode', 'false');
                }

            }


        }


    }


    private async loadFiveSearchResults(exe_id?: any): Promise<SearchResult[] | undefined> {
        if (!this.exerciseServer || !this.state.exercise) {
            app.showError(__("Hiba a keresési eredmények betöltése közben"), __("Hiányzó feladat"));
            return;
        }

        var result: SearchResult[] = [];

        if (!this.state.exercise || !this.state.exercise.id)
            return;

        try {
            if (exe_id)
                result = await this.exerciseServer.searchExercise(exe_id);
            else
                result = await this.exerciseServer.searchExercise(this.state.exercise.id);

            if (result.length == 0) {
                return;
            }
            result = result.slice(0, 5);
        } catch (ex) {
            app.showErrorFromJsonResult(ex);
        }
        return result;
    }

    async pauseCounter() {
        let oldState = this.state.containerState == ContainerState.Paused;
        this.containerStateChange(!oldState ? ContainerState.Paused : ContainerState.Active);
    }
    async timeIsOver() {
        this.containerStateChange(ContainerState.FinalEvaluation);
    }

    /*-------------------------------------------*/
    /* ------------- Display methods ------------*/
    /*-------------------------------------------*/

    private async openInFullScreen(ev: any) {
        document.querySelector("html")!.style.overflow = "hidden";
        this.setState({ isFullScreen: true, show: true }, () => { if (this.state.exerciseEngine) { this.state.exerciseEngine.containerResized(); } });

        let event = new Event('exerciseOnFullscreen', { bubbles: true });
        document.dispatchEvent(event);
    }

    private async minimalizeContainer(ev: any) {

        if (this.state.isFullScreen) {
            document.querySelector("html")!.style.overflow = "auto";
        }

        this.setState({ isFullScreen: false, show: !this.state.show }, () => { if (this.state.exerciseEngine) { this.state.exerciseEngine.containerResized(); } });
    }

    private async closeFullScreen(ev: any) {
        document.querySelector("html")!.style.overflow = "auto";
        this.setState({ isFullScreen: false }, () => { if (this.state.exerciseEngine) { this.state.exerciseEngine.containerResized(); } });
    }

    private showModal(modalName: ModalTypes) {
        let msgContent: MessageBoxContent = {};
        switch (modalName) {
            case ModalTypes.Task: {
                msgContent = { title: __("Feladat"), content: this.state.exercise!.task_html, type: ModalTypes.Task };
                break;
            }
            case ModalTypes.Correct: {
                msgContent = { title: __("A megoldásod tökéletes"), content: this.state.exercise!.correct_answer_feedback_html, type: ModalTypes.Correct };
                break;
            }
            case ModalTypes.Wrong: {
                msgContent = { title: __("A megoldásod nem tökéletes"), content: this.state.exercise!.wrong_answer_feedback_html, type: ModalTypes.Wrong };
                break;
            }
            case ModalTypes.SeriesIntro: {
                msgContent = { title: __("Bevezető"), content: this.state.exerciseSeries!.introduction, type: ModalTypes.SeriesIntro };
                break;
            }
            case ModalTypes.SeriesSummary: {
                msgContent = { title: __("Eredmény"), content: undefined, type: ModalTypes.SeriesSummary };
            }
        }
        this.setState({ messageBoxContent: msgContent });
    }

    private closeModal() {
        (!this.props.replay && this.state.exerciseSeries && this.state.exerciseSeries.max_solution_time_min)
            ?
            this.setState({ messageBoxContent: undefined, remainingTime: 60 * this.state.exerciseSeries.max_solution_time_min })
            :
            this.setState({ messageBoxContent: undefined })
        if (this.state.exercise && (this.state.simple_style || this.state.exercise.is_sni)) {
            if (this.state.exercise.correct_answer_feedback_html && this.state.containerState == ContainerState.Successful)
                this.closePopup();
            else if (this.state.exercise.wrong_answer_feedback_html && this.state.containerState == ContainerState.UnSuccessful)
                this.closePopup();
        }
    }

    private closePopup() {
        if (this.state.containerState == ContainerState.FinalEvaluation) return;
        if (this.state.containerState == ContainerState.Paused) return;
        if (this.state.containerState == ContainerState.WaitingToPlay && this.state.simple_style) return;
        this.containerStateChange(ContainerState.Active);
    }

    private static cachedChapter: IChapterRecord;

    private async getBackGround(exercise: IExerciseRecord, is_sni: boolean, is_simple_style: boolean): Promise<any> {
        if (!exercise) return;

        let new_style = {
            backgroundImage: "",
            backgroundColor: "white"
        };

        if (is_simple_style) {
            return new_style;
        }

        new_style.backgroundColor = "#caf5b9";

        if (this.props.chapterId) {
            let curr_chapter = ExerciseContainerComponent.cachedChapter;

            if (!curr_chapter || curr_chapter.id !== this.props.chapterId) {
                curr_chapter = (await ChapterCrud.load(this.props.chapterId)).record;
                ExerciseContainerComponent.cachedChapter = curr_chapter;
            }

            if (curr_chapter && curr_chapter.exercise_background_color != null) new_style.backgroundColor = curr_chapter.exercise_background_color;
        }
        if (new_style.backgroundColor) {
            return new_style;
        }

        return null;
    }

    getLevelClass() {

        let level = 1;
        if (this.state.exerciseSeries && this.state.exerciseSeries.level) {
            level = this.state.exerciseSeries.level;
        } else if (this.state.exercise && this.state.exercise.exercise && this.state.exercise.exercise.level) {
            level = this.state.exercise.exercise.level;
        }
        return level;
    }

    private static gradeCache: IGradeRecord[];

    public async isLowerGrade(grade_id: number): Promise<boolean> {
        if (!ExerciseContainerComponent.gradeCache) {
            ExerciseContainerComponent.gradeCache = await GradeCrud.list({ filter: { is_active: true } });
        }

        if (grade_id) {
            const grade = ExerciseContainerComponent.gradeCache.find(g => g.id == grade_id);
            if (grade) {
                return grade.is_low_grade!;
            }
        }
        return false;
    }

    static roundNumber(num2round: number): number {
        return Math.round(num2round * 100) / 100;
    }

}

function instantiateExerciseEngine(exerciseEngineName: string): ExerciseEngine10 | null {
    return new exerciseEngineClasses[exerciseEngineName]();
    /*
    const module = exerciseEngineModules[exerciseEngineName]; // global window or engine
    const exerciseEngineClass: any = module[exerciseEngineName];
    if (exerciseEngineClass) return new exerciseEngineClass();
    return null;
    */
}
class MathJaxText extends React.Component {
    componentDidMount() {
        MathJax.Hub.Queue(["Typeset", MathJax.Hub, this.refs.element]);
    }
    componentDidUpdate() {
        MathJax.Hub.Queue(["Typeset", MathJax.Hub, this.refs.element]);
    }
    render() {
        return <span ref="element">{this.props.children}</span>;
    }
}
