import * as React from 'react';
import OriginalTextSelect from '@src/component/book/editor/OriginalTextSelect';
import { Dialog } from '@src/component/Dialog';
import MediaFileChooser, { ALL_LESSON_EDITOR_ACTIONS } from '@src/component/filemanager/MediaFileChooser';
import OoFolderCrud from '@src/framework/crud/media/OoFolderCrud';
import { INDESIGN_FOLDER_NAME } from '@src/Const';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { __ } from '@src/translation';

type LessonEditorSidebarProps = {
    rootFolderId: number;
    onTextSelected: (text: string) => void;
    onFileSelected: (fileId: number) => void;
}

type LessonEditorSidebarState = {
    showSelectDialog: boolean;
    currentFolderId: number;
    indesignExists?: boolean;
}

export class LessonEditorSidebar extends React.Component<LessonEditorSidebarProps, LessonEditorSidebarState> {

    constructor(props: LessonEditorSidebarProps) {
        super(props);
        
        this.state = {
            showSelectDialog: false,
            currentFolderId: this.props.rootFolderId
        }
    }

    async componentDidMount() {
        const folders = await OoFolderCrud.list({filter: {is_active: true, parent_id: this.props.rootFolderId, title: INDESIGN_FOLDER_NAME}});
        const indesignExists = folders.length > 0;

        this.setState({indesignExists});
    }

    onTextSelected(text: string) {
        this.props.onTextSelected(text);
    }

    onFileSelected(fileId: number) {
        this.props.onFileSelected(fileId);
    }

    render() {
        if (typeof this.state.indesignExists === 'undefined') {
            return null;
        }
        
        return <>
            <Tabs forceRenderTabPanel defaultIndex={this.state.indesignExists ? 1 : 0} className="react-tabs stretched">
                <TabList>
                    {this.state.indesignExists && <Tab>{__("Nyers szöveg")}</Tab>}
                    <Tab>{__("Képek")}</Tab>
                </TabList>

                { this.state.indesignExists &&
                    <TabPanel className="react-tabs__tab-panel stretched">
                        <div style={{ backgroundColor: "#eee", height: "100%", overflow: "auto" }}>
                            <OriginalTextSelect onTextSelected={this.onTextSelected.bind(this)} folderId={this.props.rootFolderId} />
                        </div>
                    </TabPanel>
                }

                <TabPanel className="react-tabs__tab-panel stretched">
                    <div style={{ backgroundColor: "#eee", height: "100%", overflow: "auto" }}>
                        <button className="button small" onClick={() => this.setState({ showSelectDialog: true })}>
                            <i className="fa fa-external-link" /> {__("Kép választása felugró ablakban")}
                        </button>

                        {this.state.showSelectDialog &&
                            <Dialog title={__("Kép választása")} width={1050} onClose={() => this.setState({ showSelectDialog: false })} >
                                <MediaFileChooser
                                    rootFolderId={this.props.rootFolderId}
                                    currentFolderId={this.state.currentFolderId}
                                    selectableExtensions={["jpg", "jpeg", "png", "svg", "mp3"]}
                                    onFileSelected={(fileId) => { this.setState({ showSelectDialog: false }); this.onFileSelected(fileId); }}
                                    onFolderSelected={(folderId: number) => { this.setState({ currentFolderId: folderId }) }}
                                    enabledActions={ALL_LESSON_EDITOR_ACTIONS}
                                />
                            </Dialog>
                        }

                        <MediaFileChooser
                            rootFolderId={this.props.rootFolderId}
                            currentFolderId={this.state.currentFolderId}
                            selectableExtensions={["jpg", "jpeg", "png", "svg", "mp3"]}
                            onFileSelected={this.onFileSelected.bind(this)}
                            onFolderSelected={(folderId: number) => { this.setState({ currentFolderId: folderId }) }}
                            enabledActions={ALL_LESSON_EDITOR_ACTIONS}
                        />
                    </div>
                </TabPanel>

            </Tabs>
        </>;
    }
}
