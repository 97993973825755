import * as React from 'react';
import BlockTemplateTypeCrud, { IBlockTemplateTypeRecord } from '@src/framework/crud/doc/BlockTemplateTypeCrud';
import { BubbleLoader } from 'react-css-loaders';
import { __ } from '@src/translation';
import { app } from '@src/index';
import { alertDialog } from '@src/component/Dialog';

type TemplateTypeEditPageProps = {

}

type TemplateTypeEditPageState = {
    loading: boolean;
    errorMessage?: string;
    blockTemplateTypes: IBlockTemplateTypeRecord[];
}

export class TemplateTypeEditPage extends React.Component<TemplateTypeEditPageProps, TemplateTypeEditPageState> {

    constructor(props: TemplateTypeEditPageProps) {
        super(props);

        this.state = {
            loading: true,
            blockTemplateTypes: []
        }
    }

    componentDidMount() {
        this.reloadAsync();
    }

    private async reloadAsync() {
        this.setState({loading: false});

        try {

            const blockTemplateTypes = await BlockTemplateTypeCrud.list({
                filter: {is_active: true},
                order_by: "name"
            })

            blockTemplateTypes.push({
                name: "",
                body: ""
            })

            this.setState({
                blockTemplateTypes,
                loading: false
            });
        } catch(e) {
            app.showErrorFromJsonResult(e);
        }
    }

    private onNameChange(index: number, event: React.FormEvent<HTMLInputElement>) {
        this.state.blockTemplateTypes[index].name = event.currentTarget.value;

        this.setState({
            blockTemplateTypes: this.state.blockTemplateTypes
        })
    }

    private onBodyChange(index: number, event: React.FormEvent<HTMLTextAreaElement>) {
        this.state.blockTemplateTypes[index].body = event.currentTarget.value;

        this.setState({
            blockTemplateTypes: this.state.blockTemplateTypes
        })
    }

    private async onSave(index: number) {
        try {
            const blockTemplateType = this.state.blockTemplateTypes[index];

            if (!blockTemplateType.name || !blockTemplateType.body) {
                await alertDialog(__("Név és html kitöltése kötelező"));
                return;
            }

            await new BlockTemplateTypeCrud(blockTemplateType).put();
            await this.reloadAsync();
            //app.showSuccess(__("Sikeres mentés"), `${blockTemplateType.name} lementve`);
            app.showSuccess(__("Sikeres mentés"), __(`{name} lementve`, {name: blockTemplateType.name}));
        } catch(e) {
            app.showErrorFromJsonResult(e);
        }
    }

    render() {
        if (this.state.loading) {
            return <BubbleLoader />
        }

        return <div>
            <div className="row expanded">
                <div className="small-12 column">
                    <h4>{__("Sablon típusok")}</h4>
                    <table>
                        <tbody>
                            {
                                this.state.blockTemplateTypes.map((bt, index) => {
                                    return <tr key={index}>
                                        <td style={{verticalAlign: "top"}}>
                                            <input type="text" value={bt.name} onChange={this.onNameChange.bind(this, index)} />
                                        </td>
                                        <td>
                                            <textarea value={bt.body} rows={12} onChange={this.onBodyChange.bind(this, index)} />
                                        </td>
                                        <td style={{verticalAlign: "top"}}>
                                            <button className="button" onClick={this.onSave.bind(this, index)}>{__("Mentés")}</button>
                                        </td>
                                    </tr>
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        ;
    }

}
