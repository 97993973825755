import * as React from 'react';
import { EventCalendar } from '@src/component/dashboard/eventcalendar/EventCalendar';
import { CallbackFunctionType, nameType, EventIconType } from '@src/component/dashboard/eventcalendar/EventCalendarAPI'
import { EventApi, EventInput } from '@fullcalendar/core';
import View from "@fullcalendar/core/View";
import { DateToString } from '@src/component/dashboard/eventcalendar/EventCalendarAPI';
import { DTSMode } from '@src/component/dashboard/eventcalendar/EventCalendarAPI'
import { attendanceUserType as aUT, attendanceCalendarType } from "./AttendanceAPI"
import { EventStyles, EventIcons, TooltipParameters, EventConflictVerify, EventCalendarProps } from "./AttendanceCalendarProps"
import ViewKapDfhtVisit, { IViewKapDfhtVisitRecord } from '@src/framework/view/kap/ViewKapDfhtVisit';
import ViewKapOccupationVisit, { IViewKapOccupationVisitRecord } from '@src/framework/view/kap/ViewKapOccupationVisit';
import { TFilterDict } from '@src/framework/crud/Crud';
import './AttendanceCalendar.css'
import '@src/component/dashboard/datetimepicker/DateTimePicker.css'
import { DfhtVisitEditor } from './DfhtVisitEditor';
import { OccupationVisitEditor } from './OccupationVisitEditor';
import { hasAnyGroup, Groups, me } from '@src/framework/server/Auth';
import SplitPane from 'react-split-pane';
import LookupEdit from '@src/framework/forms/lookupedit';
import InstituteCrud from '@src/framework/crud/sys/InstituteCrud';
import { viewInstituteSiteClassProxy } from '@src/framework/view/sys/ViewInstituteSite';
import WfAPI from '@src/framework/wf/WfAPI';
import StationCrud, { IStationRecord } from '@src/framework/crud/wf/StationCrud';

export type AttendanceFilter = {
    calendarTypes: attendanceCalendarType[];
    instituteId?: number;
    onlyOwnEvents: boolean;
}

type AttendanceCalendarProps = {

}

type AttendanceCalendarState = {
    eventDay: string;

    filter: AttendanceFilter;

    editEventId?: number | null;
    eventType?: attendanceCalendarType;

    sidebarIsOpen: boolean;

    occupationStations: IStationRecord[];
    dfhtStations: IStationRecord[];
}

export class AttendanceCalendar extends React.Component<AttendanceCalendarProps, AttendanceCalendarState> {
    attandanceCalendarComponentRef = React.createRef<EventCalendar>();

    constructor(props: AttendanceCalendarProps) {
        super(props);

        this.state = {
            sidebarIsOpen: true,
            eventDay: "",
            filter: {
                calendarTypes: [
                    attendanceCalendarType.Alprogram,
                    attendanceCalendarType.DFHT
                ],
                onlyOwnEvents: false
            },
            occupationStations: [],
            dfhtStations: []
        }
    }

    componentDidMount() {
        this.reloadAsync();
    }

    private async reloadAsync() {

        const occupationStations = await StationCrud.list({
            filter: { is_active: true, wf_type_id: 25346333725 },
            order_by: "no"
        });
        const dfhtStations = await StationCrud.list({
            filter: { is_active: true, wf_type_id: 25346333724 },
            order_by: "no"
        });
        this.setState({
            occupationStations,
            dfhtStations
        })

    }


    onSidebarToggle(isOpen: boolean) {
        this.setState({ sidebarIsOpen: isOpen });
    }

    componentDidUpdate() {
        const calendar = this.attandanceCalendarComponentRef.current;
        if (calendar !== null) calendar.reLoadCalendar();
    }

    private attendanceEventsLoad = async (start: string, end: string) => {
        let Esemenyek: EventInput[] = [];
        let DfhtVisitData: IViewKapDfhtVisitRecord[];
        let OccupationVisitData: IViewKapOccupationVisitRecord[];
        const startdate = new Date(start);
        const enddate = new Date(end);
        const filter: TFilterDict = {
            is_active: true, ">=": { min_start_date: startdate.getTime() }, "<=": { max_end_date: enddate.getTime() },
            institute_id: this.state.filter.instituteId || undefined,
        };

        let spec = undefined;
        if (this.state.filter.onlyOwnEvents) {
            spec = { mine: true };
        }

        if (this.state.filter.calendarTypes.length === 0 || this.state.filter.calendarTypes.includes(attendanceCalendarType.Alprogram)) {
            OccupationVisitData = await ViewKapOccupationVisit.list({
                filter: filter,
                columns: ['id', 'min_start_date', 'max_end_date', 'subprogram_id', 'wf_station_id', 'kap_supporter_id',
                    'kap_supporter_fullname', 'institute_site_id', 'institute_site_name', 'institute_om_identifier',
                    'start_date', 'end_date', 'group_type_other', 'wf_station_name'
                ], spec: spec
            });
            for (const item of OccupationVisitData) {
                Esemenyek.push({
                    id: item.id, start: item.min_start_date, end: item.max_end_date, title: item.kap_supporter_fullname || "[Szakmai támogató nincs megadva]",
                    type: "" + item.subprogram_id, status: item.wf_station_id, personId: item.kap_supporter_id, personName: item.kap_supporter_fullname,
                    personComment: "", placeId: item.institute_site_id, placeName: item.institute_site_name, placeAddress: "", placeComment: "",
                    eventType: attendanceCalendarType.Alprogram, startDate: item.start_date, groupTypeOther: item.group_type_other, stationName: item.wf_station_name
                });
            }
        }

        if (this.state.filter.calendarTypes.length === 0 || this.state.filter.calendarTypes.includes(attendanceCalendarType.DFHT)) {
            DfhtVisitData = await ViewKapDfhtVisit.list({
                filter: filter,
                columns: ['id', 'min_start_date', 'max_end_date', 'wf_station_id', 'kap_supporter_id',
                    'kap_supporter_fullname', 'institute_id', 'institute_name', 'institute_om_identifier', 'wf_station_name'], spec: spec
            });
            for (const { item } of DfhtVisitData.map((item: IViewKapDfhtVisitRecord) => ({ item }))) {
                Esemenyek.push({
                    id: item.id, start: item.min_start_date, end: item.max_end_date, title: item.kap_supporter_fullname || "[Szakmai támogató nincs megadva]",
                    type: "0", status: item.wf_station_id, personId: item.kap_supporter_id, personName: item.kap_supporter_fullname,
                    personComment: "", placeId: item.institute_id, placeName: item.institute_name, placeAddress: "", placeComment: "",
                    eventType: attendanceCalendarType.DFHT, stationName: item.wf_station_name
                });
            }
        }
        return Esemenyek;
    }

    private typeNameResult = (type: string) => {
        var result: string = "";
        for (const { item } of TooltipParameters.typeName.map((item: nameType) => ({ item }))) {
            if (item.id === type.toString()) result = item.name;
        }
        return result
    }
    private statusNameResult = (status: string) => {
        var result: string = "";
        for (const { item } of TooltipParameters.statusName.map((item: nameType) => ({ item }))) {
            if (item.id === status.toString()) result = item.name;
        }
        return result
    }
    private iconNameResult = (status: number) => {
        if (!status) return "";

        var result: string = "";
        for (const { item } of EventIcons.map((item: EventIconType) => ({ item }))) {
            if (item.statusValue == status) result = 'fa fa-' + item.iconName;
        }
        return result
    }

    private attendanceTooltipGeneral = (arg: { el: HTMLElement; event: EventApi; jsEvent: MouseEvent; view: View }) => {
        const e = arg.event;
        const Idopont = DateToString(arg.event.start, DTSMode.dateFull, '.') +
            ' (' + DateToString(arg.event.start, DTSMode.hourMinute, '-') +
            '-' + DateToString(arg.event.end, DTSMode.hourMinute, '-') + ')';
        const StatuszNev = this.statusNameResult(e.extendedProps.status);
        const TipusNev = this.typeNameResult(e.extendedProps.type);
        const SztNev = (e.extendedProps.personName !== undefined ? e.extendedProps.personName : '');
        const SztMegjegyzes = (e.extendedProps.personComment !== undefined ? e.extendedProps.personComment : '');
        const IntNev = (e.extendedProps.placeName !== undefined ? e.extendedProps.placeName : '');
        const IntCim = (e.extendedProps.placeAddress !== undefined ? e.extendedProps.placeAddress : '');
        const IntMegjegyzes = (e.extendedProps.placeComment !== undefined ? e.extendedProps.placeComment : '');
        return (
            <div style={{ paddingLeft: "10px", paddingRight: "10px", fontSize: "16px" }}>
                <div style={{ textAlign: "center" }}><b>Időpont: </b>{Idopont}</div>
                <div style={{ textAlign: "left" }}><b>Hospitálás típusa: </b>{TipusNev}</div>
                <div style={{ textAlign: "left" }}><b>Státusz megnevezése: </b>{StatuszNev}</div>
                <div style={{ marginTop: "10px", textAlign: "left" }}><b>Szakmai támogató neve: </b> {SztNev}</div>
                <div style={{ textAlign: "center" }}>{SztMegjegyzes}</div>
                <div style={{ marginTop: "20px", textAlign: "left" }}><b>Inézmény adatai:</b></div>
                <div style={{ textAlign: "center" }}>{IntNev}</div>
                <div style={{ textAlign: "center" }}>{IntCim}</div>
                <div style={{ textAlign: "center" }}>{IntMegjegyzes}</div>
            </div>
        )
    }

    private attendanceEventRendel = (arg: { isMirror: boolean; isStart: boolean; isEnd: boolean; event: EventApi; el: HTMLElement; view: View }) => {
        const eventStyle = { textAlign: "left", height: "24px", paddingLeft: "5px", paddingTop: "5px", margin: "0px !important", maxHeight: "100%", minHeight: "100%" } as React.CSSProperties;
        if (EventStyles.eventTypeStyle !== undefined) {
            eventStyle.backgroundColor = EventStyles.eventTypeStyle[arg.event.extendedProps.type].backgroundColor;
            eventStyle.color = EventStyles.eventTypeStyle[arg.event.extendedProps.type].textColor;
        }
        if (EventStyles.eventStyle !== undefined) {
            if (EventStyles.eventStyle.fontSize !== undefined) eventStyle.fontSize = EventStyles.eventStyle.fontSize;
            if (EventStyles.eventStyle.eventHeight !== undefined) eventStyle.height = EventStyles.eventStyle.eventHeight;
        }
        if (arg.event.extendedProps.type.textBold === true) eventStyle.fontWeight = "bold";
        const IconName = this.iconNameResult(arg.event.extendedProps.status);
        console.log(this.statusNameResult(arg.event.extendedProps.status));

        return (
            <React.Fragment>
                <div className="fc-content" style={eventStyle}>
                    {IconName.length === 0 ? null : <i className={IconName} aria-hidden={true} style={{ marginRight: "5px" }}></i>}
                    {arg.event.title}
                    <div className="show-for-sr">
                        <div>Időpont: {DateToString(arg.event.start, DTSMode.dateFull, '.') +
                            ' (' + DateToString(arg.event.start, DTSMode.hourMinute, '-') +
                            '-' + DateToString(arg.event.end, DTSMode.hourMinute, '-') + ')'}</div>
                        <div>Hospitálás típusa: {this.typeNameResult(arg.event.extendedProps.type)}</div>
                        <div>Státusz megnevezése: {this.statusNameResult(arg.event.extendedProps.status)}</div>
                        <div >Szakmai támogató neve: {(arg.event.extendedProps.personName !== undefined ? arg.event.extendedProps.personName : '')}</div>
                        <div>{(arg.event.extendedProps.personComment !== undefined ? arg.event.extendedProps.personComment : '')}</div>
                        <div>Inézmény adatai:</div>
                        <div>{(arg.event.extendedProps.placeName !== undefined ? arg.event.extendedProps.placeName : '')}</div>
                        <div>{(arg.event.extendedProps.placeAddress !== undefined ? arg.event.extendedProps.placeAddress : '')}</div>
                        <div>{(arg.event.extendedProps.placeComment !== undefined ? arg.event.extendedProps.placeComment : '')}</div>
                    </div>
                </div>
                <div className="fc-resizer fc-end-resizer" />
            </React.Fragment>
        )
    }

    private onEditEvent(event: EventApi) {
        this.setState({
            editEventId: Number(event.id),
            eventType: event.extendedProps.eventType
        })
    }

    private attendanceDayClickMenuVisibled = (date: Date, id: string, index: number) => {
        var Datum: Date = new Date();
        if (DateToString(date, DTSMode.dateFull, '-') !== DateToString(Datum, DTSMode.dateFull, '-')) return true
        else return false;
    }

    private onDayClick = (arg: { date: Date; dateStr: string; allDay: boolean; resource: any; dayEl: HTMLElement; jsEvent: MouseEvent; view: View; }) => {
        const dateInPast = arg.date < new Date();

        let menuItems = [];

        if (!dateInPast && (this.state.filter.calendarTypes.length === 0 || this.state.filter.calendarTypes.includes(attendanceCalendarType.Alprogram))
            && me && hasAnyGroup(me, [Groups.Admin, Groups.KapJtrCoordinator, Groups.KapJtrSupport, Groups.KapJtrInstituteContact])) {

            menuItems.push({ id: "mFelvisz", name: "Új Csoportszintű időpont", icon: "fa fa-calendar-plus", onClick: () => this.setState({ editEventId: null, eventType: attendanceCalendarType.Alprogram, eventDay: arg.dateStr }) });
        }

        if (!dateInPast && (this.state.filter.calendarTypes.length === 0 || this.state.filter.calendarTypes.includes(attendanceCalendarType.DFHT))
            && me && hasAnyGroup(me, [Groups.Admin, Groups.KapJtrCoordinator, Groups.KapJtrSupport])) {
            menuItems.push({ id: "mFelvisz", name: "Új DFHT-KIP időpont", icon: "fa fa-calendar-plus", onClick: () => this.setState({ editEventId: null, eventType: attendanceCalendarType.DFHT, eventDay: arg.dateStr }) });
        }

        return menuItems;
    }

    private onEventClick = (arg: { el: HTMLElement; event: EventApi; jsEvent: MouseEvent; view: View }) => {
        this.onEditEvent(arg.event);
        return undefined;
        /*
        return [
            {id:"mEdit",name:"Megnyitás",icon:"fa fa-edit", onClick: () => this.onEditEvent(arg.event)}
        ]*/
    }

    private onCheckSubprogram(type: attendanceCalendarType, checked: boolean) {
        const filter = this.state.filter;

        if (checked) {
            filter.calendarTypes.push(type);
        } else {
            filter.calendarTypes.splice(filter.calendarTypes.indexOf(type), 1);
        }

        this.setState({ filter });
    }

    private onCheckOnlyOwnEvent(checked: boolean) {
        const filter = this.state.filter;

        filter.onlyOwnEvents = checked;

        this.setState({ filter });
    }

    private onSetInstituteId(instituteId?: number) {
        const filter = this.state.filter;
        filter.instituteId = instituteId;
        this.setState({ filter });
    }

    render() {
        const CallbackFunction: CallbackFunctionType = {
            loadEvent: this.attendanceEventsLoad,
            tooltipGeneral: this.attendanceTooltipGeneral,
            eventRender: this.attendanceEventRendel,
            dayClickMenuVisibled: this.attendanceDayClickMenuVisibled,
            onDayClick: this.onDayClick,
            onEventClick: this.onEventClick,
        }

        const canShowLegend = me && hasAnyGroup(me, [Groups.Admin, Groups.KapJtrCoordinator, Groups.KapJtrInstituteContact, Groups.KapJtrSupport]);

        return (<>
            <SplitPane
                pane1Style={{ background: "#00638a", color: "white" }}
                ref="splitPane" split="vertical" allowResize={this.state.sidebarIsOpen} maxSize={-150} size={this.state.sidebarIsOpen ? 350 : 50} defaultSize={350} style={{ position: "relative", minHeight: "100vh" }}>

                <nav title="Szűrés" style={{ margin: "1em" }}>
                    <h5 style={{ borderBottom: "2px solid #239ac5" }}>Szűrés</h5>
                    <label style={{ padding: "0.5em 1em", color: "white" }}>
                        <input type="checkbox" checked={this.state.filter.onlyOwnEvents} onChange={e => this.onCheckOnlyOwnEvent(e.target.checked)} />
                        &nbsp; Csak a saját eseményeim
                    </label>

                    <h6>Esemény típus</h6>
                    <label style={{ padding: "0.5em 1em", color: "white" }}>
                        <input type="checkbox" checked={this.state.filter.calendarTypes.includes(attendanceCalendarType.DFHT)} onChange={e => this.onCheckSubprogram(attendanceCalendarType.DFHT, e.target.checked)} />
                        &nbsp; DFHT-KIP óra hospitálások
                    </label>
                    <label style={{ padding: "0.5em 1em", color: "white" }}>
                        <input type="checkbox" checked={this.state.filter.calendarTypes.includes(attendanceCalendarType.Alprogram)} onChange={e => this.onCheckSubprogram(attendanceCalendarType.Alprogram, e.target.checked)} />
                        &nbsp; Csoportszintű alprogrami hospitálás
                    </label>

                    <h6>Intézmény</h6>
                    <div style={{ color: "black" }}>
                        <LookupEdit
                            fk_table_info_id={InstituteCrud.TABLE_INFO_ID}
                            clearable={true}
                            viewClassProxy={viewInstituteSiteClassProxy}
                            emptyLoad={true}
                            selectColumnNames={["institute_id", "institute_name"]}
                            searchColumnNames={["institute_name"]}
                            displayColumnNames={["institute_name"]}
                            valueColumn={"institute_id"}
                            distinct={true}
                            filter={{
                                is_active: true,
                                "$notnull": "kap_introduction_date"
                            }}
                            placeholder="Kérem válasszon intézményt..."
                            value={this.state.filter.instituteId}
                            onChange={id => this.onSetInstituteId(id as number)}

                        />
                    </div>
                    <br />
                    <br />
                    <br />
                    {
                        canShowLegend && this.state.dfhtStations.length > 0 &&
                        <div>
                            <p>DFHT-KIP hospitálás státuszok:</p>
                            <ul>
                                {
                                    this.state.dfhtStations.filter(s => !s.is_deleted).map(s => {
                                        const icon = EventIcons.find(e => e.statusValue === s.id);
                                        return <li>
                                            {icon ? <i className={"fa fa-fw fa-" + icon.iconName} /> : <i className={"fa fa-fw"} />} {s.name}
                                        </li>
                                    })
                                }
                            </ul>
                        </div>
                    }

                    {
                        canShowLegend && this.state.occupationStations.length > 0 &&
                        <div>
                            <p>Csoportszintű hospitálás státuszok:</p>
                            <ul>
                                {
                                    this.state.occupationStations.filter(s => !s.is_deleted).map(s => {
                                        const icon = EventIcons.find(e => e.statusValue === s.id);
                                        return <li>
                                            {icon ? <i className={"fa fa-fw fa-" + icon.iconName} /> : <i className={"fa fa-fw"} />} {s.name}
                                        </li>
                                    })
                                }
                            </ul>
                        </div>
                    }

                </nav>


                <div className="attendanceContent" style={{ margin: "10px" }}>
                    <EventCalendar
                        EventStyles={EventStyles}
                        EventIcons={EventIcons}
                        CallbackFunction={CallbackFunction}
                        TooltipParameters={TooltipParameters}
                        EventConflictVerify={EventConflictVerify}
                        EventCalendarProps={EventCalendarProps}
                        ref={this.attandanceCalendarComponentRef}
                    />


                </div>

            </SplitPane>
            {
                this.state.editEventId !== undefined && this.state.eventType &&
                (
                    this.state.eventType === attendanceCalendarType.DFHT
                        ?
                        <DfhtVisitEditor
                            id={this.state.editEventId}
                            onNewId={id => this.setState({ editEventId: id })}
                            minStartDateDefault={this.state.eventDay + " 08:00"}
                            maxEnddateDefault={this.state.eventDay + " 16:00"}
                            onClose={() => this.setState({ editEventId: undefined })}
                        />
                        :
                        <OccupationVisitEditor
                            id={this.state.editEventId}
                            onNewId={id => this.setState({ editEventId: id })}
                            eventDay={this.state.eventDay}
                            onClose={() => this.setState({ editEventId: undefined })}
                        />
                )
            }
        </>
        )
    }

}

