import obtainServer, { Server } from '@framework/server/Server';

/*
    Java-ban ForumMessageDTO felel meg neki, és MongoDb-ben is majdnem pont így van tárolva.
    Kivéve a "sending" mezőt.
*/
export interface IForumMessage {
    _id : string; /* Bson ObjectId, egyedi üzenet azonosító. Amíg az üzenet nincs elmentve, addig változhat az értéke. */
    forumId : number;
    topicId : number;
    userId : number;
    created : string; /* timestamp */
    message : string; /* html */
    replyToId : string|null; /* Bson ObjectId */
    data : Object|null;
    modified : string|null; /* timestamp */
    modifiedById : number|null;
}

export interface IForumMessageModificationFlags {
    editing : boolean; /* éppen szerkesztés alatt van */
    sending : boolean; /* Az üzenet mentése folyamatban van. */
    deleting : boolean; /* Az üzenet törlése folyamatban van. */
    failed : boolean; /* Ha az üzenettel kapcsolatos utolsó művelet sikertelen lett. */
}

export interface IForumMessageModificationData {
    id : string; /* Bson ObjectId, egyedi üzenet azonosító. Amíg az üzenet nincs elmentve, addig változhat az értéke. */
    message ?: string; /* html, ha nem akarod módosítani akkor undefined legyen */
    data ?: Object|null; /* ha nem akarod módosítani akkor undefined legyen */
}

export type IForumMessageModification  = IForumMessageModificationData & IForumMessageModificationFlags;

/* getMessages paraméter, fórum üzenetek lekérdezéséhez. */
export interface IForumGetMessagesParams {
    forumId : number|null; // Melyik fórumból
    topicId : number|null; // Melyik topic-ból
    userId : number|null; // Melyik felhasználó üzeneteit
    minDate : Date|null|"now"; // Legkorábbi időpont
    maxDate : Date|null|"now"; // Legkésőbbi időpont
    searchText : string|null; // Szöveges keresés
    limit : number; // Visszaadott üzenetek max. száma
}

export interface IGetMessagesResult {
    now: string; /* timestamp on the server */
    messages : IForumMessage[];
}

/**
 * Low level forum API.
 * 
 * Please use ForumModule instead!
 */
export class ForumAPI {
    server: Server;

    constructor(server ?: Server) {
        this.server = obtainServer(server);
    }


    /*
     * Get forum messages.
     *
     * Messages are always returned ordered by "created desc". In other words, the very first
     * message in the list will be the most recent.
     *
     */
    public async getMessages(params: Partial<IForumGetMessagesParams>) : Promise<IGetMessagesResult> {
        return this.server.post<IGetMessagesResult>('forum', {
            ...params,
            operation: 'get_messages'
        });        
    }

    /**
     * Üzenet küldése.
     * 
     * NE EZT HASZNÁLD, hanem a forumStore -ban levő sendMessage-t!
     * 
     * @param topicId melyik topic-hoz. Ha a fórumnak csak egyetlen default topic-ja van, akkor itt azt kell megadni.
     * @param message a kimenő üzenet (szöveg)
     * @param data extra adat az üzenethez (bármilyen object lehet, vagy null)
     * @returns Az az új bejövő üzenet, amit a kimenő üzenet mentése után a getMessages() visszaadna ehhez a küldéshez.
     * 
     */
    public async sendMessage(topicId: number, forumMessage: Partial<IForumMessage> ): Promise<IForumMessage> {
        return this.server.post<IForumMessage>(
            'forum',
            {
                operation: 'send_message',
                ...forumMessage
            }
        );
    }

    /**
     * Üzenet módosítása.
     * 
     * NE EZT HASZNÁLD, hanem a forumStore -ban levő modifyMessage-t!
     * 
     * @param message a módosított üzenet (szöveg)
     * @returns Az új módosított üzenet, amit a kimenő üzenet mentése után a getMessages() visszaadna ehhez az üzenethez.
     *      Ha a módosítás nem sikerült (mert az üzenet nem található), akkor null-t ad vissza.
     * 
     * 
     */
    public async modifyMessage(modifications: IForumMessageModificationData ): Promise<IForumMessage|null> {
        return this.server.post<IForumMessage|null>(
            'forum',
            {
                operation: 'modify_message',
                id: modifications.id,
                message: modifications.message,
                data: modifications.data
            }
        );
    }    

    public async deleteMessage(messageId: string ): Promise<void> {
        return this.server.post<void>(
            'forum',
            {
                operation: 'delete_message',
                messageId
            }
        );
    }    
    
    /**
     * notifier token igénylése egy adott fórumhoz
     */ 
    public obtainToken = async (forumId:number) : Promise<string> => {
        return obtainServer().post('forum', {operation: "obtain_token", forumId });
    }

}

export const forumAPI = new ForumAPI();
