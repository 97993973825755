import * as React from "react";
import { formatDate } from "@src/Util";
import { __ } from "@src/translation";
import { getContentDetails } from "@src/component/filemanager/ContentUtils";
import { ListItem, ListItemImage, ListItemText } from "@src/component/ui/List";
import { IGeneralItem, IGeneralItemDetails } from "@src/component/collection/CollectionItemComponent";

type LpNodeCardProps = {
    item: IGeneralItem;
    onNodeSelected: () => void;
}

export class LpNodeCard extends React.Component<LpNodeCardProps, {details:IGeneralItemDetails}> {

    constructor(props: any) {
        super(props);
        this.state = {
            details:{}
        }
    }
    componentDidMount() {
        this.reloadAsync();
    }
    componentDidUpdate(prevProps: LpNodeCardProps) {
        if (this.props.item.id != prevProps.item.id) {
            this.reloadAsync();
        }
    }

    private async reloadAsync() {
        if(!this.props.item || !this.props.item.rec_id) return;
        const details = await getContentDetails(this.props.item.table_info_id!, this.props.item.rec_id!);
        if(details)
        this.setState({
            details
        })
    }
  
    render() {
        return <div onClick={this.props.onNodeSelected.bind(this, this.props.item.no)}>
         <ListItem>
            <ListItemImage src={this.state.details && this.state.details.thumbnailUrl} />
            <ListItemText>
                <strong>
                    {this.state.details!.type? "["+this.state.details!.type+"] - " : ""}
                    {this.state.details!.displayName? this.state.details!.displayName : ""}               
                    <br/>
                </strong>
                <span className="content-item-list__small">{__("Hozzáadva")}: {formatDate(this.props.item.creation_time)}</span>
            </ListItemText>
        </ListItem>
        </div>
    }

}
