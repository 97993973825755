import { Server } from '../../server/Server';
import View , { registerViewClassProxy, ViewClassProxy } from '../View';
import { IListParams } from '@framework/crud/Crud';

export interface IViewKapSubprogramSupporterSiteRecord {
    /**
     * Azonosító
     *
     * Azonosító (pótkulcs)
     */
    id?: number;
    /**
     * Rekord létrehozás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel létrehozásakor.
     */
    creation_time?: string /*timestamp?*/;
    /**
     * Ki hozta létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozó felhasználóra hivatkozik.
     */
    creation_user_id?: number;
    /**
     * Melyik session-ből jött létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozáshoz használt munkamenetre hivatkozik.
     */
    creation_session_id?: number|null;
    /**
     * Utolsó módosítás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel módosításakor.
     */
    modification_time?: string /*timestamp?*/|null;
    /**
     * Ki módosította utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosító felhasználóra hivatkozik.
     */
    modification_user_id?: number|null;
    /**
     * Melyik session-ből módosították utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosításhoz használt munkamenetre hivatkozik.
     */
    modification_session_id?: number|null;
    /**
     * Aktív
     *
     * Logikai törléshez használt mező.
     */
    is_active?: boolean;
    /**
     * KAP szakmai támogató
     */
    kap_supporter_id?: number|null;
    /**
     * Intézmény feladat ellátási hely
     */
    institute_site_id?: number|null;
    /**
     * Támogató aktív
     *
     * Támogató aktív
     */
    supporter_is_active?: boolean|null;
    /**
     * Támogató bejelentkezési név
     *
     * Támogató bejelentkezési név
     */
    supporter_login_name?: string|null;
    /**
     * Támogató email
     *
     * Támogató email
     */
    supporter_email?: string|null;
    /**
     * Támogató teljes név
     *
     * Támogató teljes név
     */
    supporter_full_name?: string|null;
    /**
     * Feladatellátási hely aktív
     *
     * Feladatellátási hely aktív
     */
    institute_site_is_active?: boolean|null;
    /**
     * Feladatellátási hely név
     *
     * Feladatellátási hely név
     */
    institute_site_name?: string|null;
    /**
     * Feladatellátási hely bevezetés dátuma
     *
     * Feladatellátási hely bevezetés dátuma
     */
    kap_introduction_date?: string /*date?*/|null;
    /**
     * Feladatellátási hely település azonosító
     *
     * Feladatellátási hely település azonosító
     */
    city_id?: number|null;
    /**
     * Feladatellátási hely település név
     *
     * Feladatellátási hely település név
     */
    city_name?: string|null;
    /**
     * Feladatellátási hely irányítószám
     *
     * Feladatellátási hely irányítószám
     */
    zipcode?: string|null;
    /**
     * Feladatellátási hely cím
     *
     * Feladatellátási hely cím
     */
    institute_site_address?: string|null;
    /**
     * Feladatellátási hely külföldi cím
     *
     * Feladatellátási hely külföldi cím
     */
    institute_site_foreign_address?: string|null;
    /**
     * Feladatellátási hely küldöldi
     *
     * Feladatellátási hely küldöldi
     */
    institute_site_is_foreign?: boolean|null;
    /**
     * Feladatellátási hely azonosító
     *
     * Feladatellátási hely azonosító
     */
    institute_site_identifier?: string|null;
    /**
     * Intézmény OM azonosító
     *
     * Intézmény OM azonosító
     */
    institute_om_identifier?: string|null;
    /**
     * Intézmény azonosító
     *
     * Intézmény azonosító
     */
    institute_id?: number|null;
    /**
     * Intézmény név
     *
     * Intézmény név
     */
    institute_name?: string|null;
}

/**
 *  kap.view_kap_subprogram_supporter_site - Alprogrami támogató által vállalt feladatellátási hely
 *
 *  Azt mondja meg, hogy melyik alprogrami szakmai támogató mely feladatellátási helyeken vállal oktatást.
 */

export default class ViewKapSubprogramSupporterSite extends View<IViewKapSubprogramSupporterSiteRecord> {
    public static VIEW_INFO_ID = 2019091601;

    public static getSchemaNameForClass(): string { return 'kap'; }
    public static getViewNameForClass(): string { return 'view_kap_subprogram_supporter_site'; }
    public static getTableNameForClass(): string|null { return 'subprogram_supporter_site'; }

    public static load: (id: number, aServer ?: Server) => Promise<ViewKapSubprogramSupporterSite>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<IViewKapSubprogramSupporterSiteRecord[]>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class ViewKapSubprogramSupporterSiteClassProxy extends ViewClassProxy<IViewKapSubprogramSupporterSiteRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const viewKapSubprogramSupporterSiteClassProxy = new ViewKapSubprogramSupporterSiteClassProxy(ViewKapSubprogramSupporterSite);
registerViewClassProxy(viewKapSubprogramSupporterSiteClassProxy);

