import * as React from 'react';
import { BubbleLoader } from 'react-css-loaders';
import UserSessionsAPI, { IServerAccessToken, ISessionsResult, ISchoolSummary } from '@src/component/sys/sys_user_sessions_api';
import { formatDateWithSeconds } from '@src/Util';
import { app } from '@src/index';
import Auth, { api } from '@src/framework/server/Auth';

import SecUserCrud, { ISecUserRecord, secUserCrudClassProxy } from '@src/framework/crud/sys/SecUserCrud';
import { Link } from 'react-router-dom';
import './sys_sec_user_view_sessions.css';
import { PATH_SYS_USER_VIEW_SESSIONS } from '@src/component/sys/sys_routes';
import { authConfig } from '@src/framework/server/Server';
import SecUserSelector from '@src/framework/forms/SecUserSelector';
import ExamineUserLogAPI from '../usr/usr_log_examine_api';
import { IUserLoginEvent } from '../usr/usr_profile_api';
import ReactTable from 'react-table';
import { getReactTableLabels } from '@src/framework/i18n';
import { __ } from '@src/translation';

interface ISecUserViewSessionsProps {
    limit: number;
    offset: number;
    pageSize: number;
    count: number;
}

interface ISecUserViewSessionsState {
    userId: number;
    loading: boolean;
    user?: ISecUserRecord | null;
    sessions?: ISessionsResult;
    loginEvents: IUserLoginEvent[];
    cardView: boolean;
}

function add(a: any, b: any): number {
    return 0 + (a || 0) + (b || 0);
}

export default class SecUserViewSessions extends React.Component<ISecUserViewSessionsProps, ISecUserViewSessionsState> {
    constructor(props: ISecUserViewSessionsProps) {
        super(props);

        let userId = this.getMatchParam("userId");
        if (userId) {
            userId = parseInt(userId);
        } else {
            userId = 0;
        }
        this.state = {
            userId,
            loading: true,
            cardView: false,
            loginEvents: []
        };
    }

    protected getMatchParam(name: string): any {
        const match: any = this.props["match"];
        return (match && match.params) ? match.params[name] : null;
    }

    componentDidMount() {
        this.asyncReload();
    }

    async asyncReload() {
        try {
            let sessions;
            let user;
            if (this.state.userId > 0) {
                user = (await SecUserCrud.load(this.state.userId)).record;
                sessions = await UserSessionsAPI.getUserSessions(this.state.userId);
            } else {
                user = null;
                sessions = await UserSessionsAPI.getLastSeenSessions();
            }
            let loginEvents: IUserLoginEvent[] = [];
            if (user) {
                loginEvents = await ExamineUserLogAPI.getLoginEventLog(user.id!);
            }

            this.setState({ user, sessions, loading: false, loginEvents });
        } catch (error) {
            app.showErrorFromJsonResult(error);
        }
    }

    private kickOffLocal = async (session: IServerAccessToken, index: number) => {
        if (confirm(__("Biztos benne, hogy lokálisan érvényteleníti ezt a bejelentkezést?"))) {
            try {
                await UserSessionsAPI.revokeTokenLocal(session.access_token);
                let tokens = this.state.sessions!.tokens.concat();
                tokens.splice(index, 1);
                this.setState({
                    sessions: {
                        tokens: tokens,
                        users: this.state.sessions!.users,
                        schoolSummary: this.state.sessions!.schoolSummary
                    }
                });
            } catch (error) {
                app.showErrorFromJsonResult(error);
            }

        }
    }

    private kickOffGlobal = async (session: IServerAccessToken, index: number) => {
        if (confirm(__("Biztos benne, hogy globálisan érvényteleníti ezt a bejelentkezést?"))) {
            try {
                await UserSessionsAPI.revokeTokenGlobal(
                    session.access_token,
                    authConfig
                );
                let tokens = this.state.sessions!.tokens.concat();
                tokens.splice(index, 1);
                this.setState({
                    sessions: {
                        tokens: tokens,
                        users: this.state.sessions!.users,
                        schoolSummary: this.state.sessions!.schoolSummary
                    }
                });
            } catch (error) {
                app.showErrorFromJsonResult(error);
            }

        }
    }

    private impersonate = async (session: IServerAccessToken) => {
        if (confirm(__("Biztos benne, hogy fölveszi ezt a bejelentkezést?"))) {
            let accessToken = {
                access_token: session.access_token,
                expires_in: 365,
                refresh_token: session.refresh_token,
                token_type: session.token_type,
                scope: session.scopes.join(" ")
            }
            await Auth.impersonate(authConfig, accessToken, true);
            window.location.replace('/');
        }
    }

    private onUserIdChange(userId: any) {
        this.setState({ userId }, this.asyncReload);
    }

    private cardView = () => {
        const sessions = this.state.sessions!;
        const users = sessions.users;
        return sessions.tokens.map((token: IServerAccessToken, index: number) => {
            const user = users[token.user_id!];
            return <div className="column small-6 large-4 sec-user-session-item">
                <ul>
                    <li><b>User ID=</b>{token.user_id}
                        &nbsp;
                        <Link to={PATH_SYS_USER_VIEW_SESSIONS + "/" + token.user_id}
                            title={__("{loginname} összes bejelentkezése...", {loginname: user.login_name})}
                        >
                            <i className="fa fa-eye" />
                        </Link>
                    </li>
                    <li><b>Login=</b>{user.login_name}
                        &nbsp;
                        <Link to={secUserCrudClassProxy.getEditUrl(token.user_id)}
                            title={__("{loginname} szerkesztése...", {loginname: user.login_name})}
                        >
                            <i className="fa fa-edit" />
                        </Link>
                    </li>
                    <li><b>E-mail=</b>{user.email}</li>
                    <li><b>{__("Név=")}</b>{user.fullname}</li>
                    <li><b>SID=</b>{token.sid}</li>
                    <li><b>Access Token=</b>{token.access_token}</li>
                    <li><b>Remote IP=</b>
                        <a href={`https://www.maxmind.com/geoip/v2.1/city/${token.remote_ip}?use-downloadable-db=1&demo=1`} target="_blank">
                            {token.remote_ip}
                        </a>
                    </li>
                    <li><b>User agent=</b>{token.user_agent}</li>
                    <li><b>{__("Létrehozva=")}</b>{token.created_at}</li>
                    <li><b>{__("Lejár=")}</b>{token.expires_at}</li>
                    <li><b>{__("Utoljára látva=")}</b>{formatDateWithSeconds(token.last_seen)}</li>
                    <li><b>{__("Hatókör=")}</b>[{token.scopes.join(",")}]</li>
                    <li><b>Only office=</b>{
                        token.only_office_access_token
                            ? <label className="label success">{__("Igen")}</label>
                            : <label className="label secondary">{__("Nem")}</label>
                    }</li>
                </ul>
                {token.access_token != api!.accessToken.access_token ?
                    <div className="row">
                        <div className="column small-4">
                            <button type="button" className="button alert"
                                onClick={() => { this.kickOffLocal(token, index) }}
                                title={__("Kijelentkezteti a felhasználót az okostankönyv honlapról")}
                            ><i className="fa fa-sign-out" /> {__("Kidob")}
                            </button>
                        </div>
                        <div className="column small-4">
                            <button type="button" className="button alert"
                                onClick={() => { this.kickOffGlobal(token, index) }}
                                title={__("Kijelentkezteti a felhasználót mindenhonnan")}
                            ><i className="fa fa-bomb" /> {__("Eltávolít")}
                            </button>
                        </div>
                        <div className="column small-4">
                            <button type="button" className="button primary"
                                onClick={() => { this.impersonate(token) }}
                                title={__("Csatlakozik ehhez a bejelentkezéshez, ezen a honlapon")}
                                disabled={token.access_token == api!.accessToken.access_token}
                            >
                                <i className="fa fa-sign-in" /> {__("Fölvesz")}
                            </button>
                        </div>
                    </div>
                    : <p className="callout secondary">{__("Ez az Ön saját bejelentkezése")}</p>
                }
            </div>
        });
    }

    private tableView = () => {
        const sessions = this.state.sessions!;
        const users = sessions.users;
        for (const sessiontoken of sessions.tokens) {
            const user = users[sessiontoken.user_id!];
            sessiontoken["login_name"] = user.login_name;
            sessiontoken["fullname"] = user.fullname;
            sessiontoken["school_title"] = user.school_title;
            sessiontoken["email"] = user.email;
            sessiontoken["created_at"] = formatDateWithSeconds(sessiontoken.created_at);
            sessiontoken["last_seen"] = formatDateWithSeconds(sessiontoken.last_seen);
        }
        return <div className="column small-12 large-12">
            <h4>{__("Bejelentkezések")}</h4>
            <ReactTable
                columns={[
                 { Header: __("Login"), accessor: "login_name", style: { overflow: "auto" },show:false },
                    { Header: __("Név"), accessor: "fullname" },
                    { Header: __("E-mail"), accessor: "email" },             
                    { Header: __("Iskola"), accessor: "school_title"},
                    { Header: __("Létrehozva"), accessor: "created_at" },
                    { Header: __("Utoljára látva"), accessor: "last_seen" },

                ]}
                data={sessions.tokens}
                filterable={true}
                className="-striped -highlight "
                {...getReactTableLabels()}
                defaultFilterMethod={(filter, row, column) => row[filter.id].toLowerCase().indexOf(filter.value.toLowerCase()) != -1}
                onFetchData={this.asyncReload.bind(this)}
                loading={this.state.loading}
            />
           </div>;
    }

    private schoolSummary = () => {
        const sessions = this.state.sessions!;
        let diak = 0; let tanar = 0; let total = 0;
        sessions.schoolSummary.forEach(school => {
            diak += school.diak;
            tanar += school.tanar;
            total += school.total;
        });

        return <div className="column small-12 large-12">
            <h4>{__("Összesítés iskolánként")}</h4>

            <table>
                <thead>
                    <th>{__("Iskola (id)")}</th>
                    <th>{__("Diák")}</th>
                    <th>{__("Tanár")}</th>
                    <th>{__("Egyéb")}</th>
                    <th>{__("Mindenki")}</th>
                </thead>
                <tbody>
                    {sessions.schoolSummary.map((school: ISchoolSummary, index: number) => {
                        return <tr>
                            <td>{
                                school.id
                                    ? `${school.title} (${school.id})`
                                    : '<Iskolán kívül>'
                            }</td>
                            <td className="user-view-sessions-school">{school.diak}</td>
                            <td className="user-view-sessions-school">{school.tanar}</td>
                            <td className="user-view-sessions-school">{school.total - school.diak - school.tanar}</td>
                            <td className="user-view-sessions-school">{school.total}</td>
                        </tr>;
                    }
                    )}
                    <tr>
                        <td><b>{__("Összesen")}</b></td>
                        <td className="user-view-sessions-school"><b>{diak}</b></td>
                        <td className="user-view-sessions-school"><b>{tanar}</b></td>
                        <td className="user-view-sessions-school"><b>{total - diak - tanar}</b></td>
                        <td className="user-view-sessions-school"><b>{total}</b></td>
                    </tr>
                </tbody>
            </table>
        </div>
    }

    private loginHistory = () => {
        const user = this.state.user;
        if (!user) { return null };
        return <div className="column small-12">
        <h4>{__("Ki és bejelentkezési események")}</h4>
        <ReactTable
        columns={[
         
            { Header: __("Dátum"), accessor: "event_date", 
            Cell: (data: any, column: any) => {
                const row = data.original;
                return formatDateWithSeconds(row.event_date);
            }},
            { Header: __("Esemény"), accessor: "action" },             
            { Header: __("Kliens ip"), accessor: "client_ip"},
            { Header: __("Üzenet"), accessor: "message" },
            { Header: __("Agent"), accessor: "user_agent" },

        ]}
        data={this.state.loginEvents}
        filterable={true}
        className="-striped -highlight "
        {...getReactTableLabels()}
        defaultFilterMethod={(filter, row, column) => row[filter.id].toLowerCase().indexOf(filter.value.toLowerCase()) != -1}
        onFetchData={this.asyncReload.bind(this)}
        loading={this.state.loading}
    />
    </div>
    }

    render() {
        if (!this.state.sessions) {
            return <BubbleLoader />;
        } else {
            const user = this.state.user;
            return <div className="row">
                {user ?
                    <div className="column small-12">
                        <h4>{__("Felhasználó bejelentkezései") + " "}({this.state.sessions!.tokens.length})</h4>
                        <b>{__("Bejelentkezési név:")}</b> {user.login_name}<br />
                        <b>E-mail:</b> {user.email}<br />
                        <Link to={secUserCrudClassProxy.getEditUrl(user.id!)}>
                            {__("A felhasználó szerkesztéshez kattintson ide")}
                    </Link>
                    </div>
                    :
                    <div className="column small-12">
                        <h4>{__("Aktív bejelentkezések") + " "}({this.state.sessions!.tokens.length})</h4>
                    </div>
                }
                <hr />
                <div className="column small-12 large-12">
                    <label>
                        {__("Szűrés felhasználóra")}
                        <SecUserSelector
                            clearable={true}
                            value={this.state.userId}
                            onChange={(id) => this.onUserIdChange(id)}
                        />
                    </label>
                </div>
                <div className="column small-12 large-12">
                    <label>
                        <input type="checkbox" checked={this.state.cardView}
                            onClick={() => this.setState({ cardView: !this.state.cardView })}
                        />
                        {__("Kártyás nézet (részletes adatok)")}
                    </label>
                </div>
                {this.schoolSummary()}
                {this.state.cardView ? this.cardView() : this.tableView()}
                {this.loginHistory()}


            </div>
        }
    }
}