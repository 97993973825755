import { Server } from '../../server/Server';
import View , { registerViewClassProxy, ViewClassProxy } from '../View';
import { IListParams } from '@framework/crud/Crud';

export interface IViewPersonRecord {
    /**
     * Azonosító
     *
     * Azonosító (pótkulcs)
     */
    id?: number;
    /**
     * Rekord létrehozás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel létrehozásakor.
     */
    creation_time?: string /*timestamp?*/;
    /**
     * Ki hozta létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozó felhasználóra hivatkozik.
     */
    creation_user_id?: number;
    /**
     * Melyik session-ből jött létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozáshoz használt munkamenetre hivatkozik.
     */
    creation_session_id?: number|null;
    /**
     * Utolsó módosítás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel módosításakor.
     */
    modification_time?: string /*timestamp?*/|null;
    /**
     * Ki módosította utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosító felhasználóra hivatkozik.
     */
    modification_user_id?: number|null;
    /**
     * Melyik session-ből módosították utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosításhoz használt munkamenetre hivatkozik.
     */
    modification_session_id?: number|null;
    /**
     * Aktív
     *
     * Logikai törléshez használt mező.
     */
    is_active?: boolean;
    /**
     * Keresztnév
     */
    firstname?: string;
    /**
     * Vezetéknév
     */
    lastname?: string;
    /**
     * Teljes név
     */
    fullname?: string|null;
    /**
     * Előnév
     */
    name_prefix?: string|null;
    /**
     * Becenév
     */
    nickname?: string|null;
    /**
     * Neme
     */
    gender_id?: number|null;
    /**
     * Anyanyelv
     */
    mother_lang_id?: number|null;
    /**
     * Időzóna
     */
    time_zone_name?: string|null;
    /**
     * E-mail cím
     */
    email?: string;
    /**
     * Leírás
     */
    description?: string|null;
    /**
     * Beosztás
     */
    position?: string|null;
    /**
     * Állapot
     */
    status_id?: number;
    /**
     * Partner/Szervezet/Cég
     */
    partner_id?: number|null;
    /**
     * Születésnap
     */
    birthday?: string /*date?*/|null;
    /**
     * Adóazonosító jel
     */
    taxid?: string|null;
    /**
     * Tudományos fokozat
     */
    scientific_degree_id?: number|null;
    /**
     * Születési név
     */
    birthname?: string|null;
    /**
     * Anyja neve
     */
    mothers_name?: string|null;
    /**
     * Személyi igazolvány száma
     */
    id_number?: string|null;
    /**
     * Irányítószám
     */
    birth_zip?: string|null;
    /**
     * Cím
     */
    birth_address?: string|null;
    /**
     * Irányítószám
     */
    residence_zip?: string|null;
    /**
     * Cím
     */
    residence_address?: string|null;
    /**
     * Irányítószám
     */
    postal_zip?: string|null;
    /**
     * Cím
     */
    postal_address?: string|null;
    /**
     * Ország hívószám
     */
    mobile_country_code?: string|null;
    /**
     * Helyi szám
     */
    mobile_number?: string|null;
    /**
     * Ország hívószám
     */
    landline_country_code?: string|null;
    /**
     * Helyi szám
     */
    landline_number?: string|null;
    /**
     * Legmagasabb iskolai végzettség
     */
    diploma_qualification?: string|null;
    /**
     * Diplomát/oklevelet kiadó intézmény megnevezése
     */
    diploma_inst?: string|null;
    /**
     * Diploma/oklevél kiállítás dátuma
     */
    diploma_created?: string /*date?*/|null;
    /**
     * Diploma/oklevél sorszáma
     */
    diploma_serial?: string|null;
    /**
     * Munkahelyi telefonszám
     */
    workplace_tel?: string|null;
    /**
     * Ország
     */
    birth_country?: string|null;
    /**
     * Megye
     */
    birth_county?: string|null;
    /**
     * Város
     */
    birth_city?: string|null;
    /**
     * Ország
     */
    residence_country?: string|null;
    /**
     * Megye
     */
    residence_county?: string|null;
    /**
     * Város
     */
    residence_city?: string|null;
    /**
     * Ország
     */
    postal_country?: string|null;
    /**
     * Megye
     */
    postal_county?: string|null;
    /**
     * Város
     */
    postal_city?: string|null;
    /**
     * Állampolgárság
     */
    citizenship?: string|null;
    /**
     * TAJ
     */
    taj?: string|null;
    /**
     * Normalizált teljes név
     *
     * Normalizált teljes név
     */
    norm_fullname?: string|null;
    /**
     * Létrehozta
     *
     * Felhasználó
     */
    creator?: string|null;
    /**
     * Módosító
     *
     * Felhasználó, aki utoljára módosította
     */
    modifier?: string|null;
    /**
     * Állapot
     *
     * Személy állapot név
     */
    status_name?: string|null;
    /**
     * Kijelzett név
     *
     * Egysoros szöveges reprezentáció
     */
    displayvalue?: string|null;
}

/**
 *  sys.view_person - Természetes személy
 *
 *  Természetes személyek akik a szoftverrel kapcsolatba kerülhetnek. Pl. vevők, adminisztátorok, külső partnerek kapcsolattartói stb.
 */

export default class ViewPerson extends View<IViewPersonRecord> {
    public static VIEW_INFO_ID = 2018052901;

    public static getSchemaNameForClass(): string { return 'sys'; }
    public static getViewNameForClass(): string { return 'view_person'; }
    public static getTableNameForClass(): string|null { return 'person'; }

    public static load: (id: number, aServer ?: Server) => Promise<ViewPerson>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<IViewPersonRecord[]>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class ViewPersonClassProxy extends ViewClassProxy<IViewPersonRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const viewPersonClassProxy = new ViewPersonClassProxy(ViewPerson);
registerViewClassProxy(viewPersonClassProxy);

