import { Server } from '../../server/Server';
import Crud , { registerCrudClassProxy, IAsTextResult, IListParams, CrudClassProxy, ISearchTextParams, ISearchTextResult } from '../Crud';

export interface IOauthClientRecord {
    /**
     * Globálisan egyedi azonosító.
     * 
     * Ha új rekordot akarsz létrehozni put() metódussal, akkor hagyd üresen.
     * upsert() hívásnál figyelmen kívül van hagyva az értéke.
     *
     */
    id ?: number;
    /**
     * Rekord létrehozás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel létrehozásakor.
     */
    creation_time?: string /*timestamp?*/;
    /**
     * Ki hozta létre
     *
     * NOT NULL -> sys.sec_user ON DELETE noaction
     *
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozó felhasználóra hivatkozik.
     */
    creation_user_id?: number;
    /**
     * Melyik session-ből jött létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozáshoz használt munkamenetre hivatkozik.
     */
    creation_session_id?: number|null;
    /**
     * Utolsó módosítás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel módosításakor.
     */
    modification_time?: string /*timestamp?*/|null;
    /**
     * Ki módosította utoljára
     *
     * -> sys.sec_user ON DELETE noaction
     *
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosító felhasználóra hivatkozik.
     */
    modification_user_id?: number|null;
    /**
     * Melyik session-ből módosították utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosításhoz használt munkamenetre hivatkozik.
     */
    modification_session_id?: number|null;
    /**
     * Aktív
     *
     * Logikai törléshez használt mező.
     */
    is_active?: boolean;
    /**
     * Név
     */
    name?: string;
    /**
     * Leírás
     */
    description?: string|null;
    /**
     * Client id
     */
    client_id?: string;
    /**
     * Titkos kulcs
     */
    secret?: string;
    /**
     * A kliens root URL-je, záró perjel nélkül.
     *
     * Ez az ami általában úgy kezdődik hogy https:// és a context root-ra mutat.
     */
    root_url?: string;
    /**
     * Megbízható
     *
     * A megbízható kliensek közvetlenül hozzáférnek az adatbázishoz, ezért nem kell scope-ra engedélyt osztani.
     */
    is_trusted?: boolean;
    /**
     * Nyilvános
     */
    is_public?: boolean;
    /**
     * 2FA
     *
     * Két faktoros azonosítást igényel.
     */
    need_2fa?: boolean;
    /**
     * Elvárt jogosultság csoport
     *
     * -> sys.sec_group ON DELETE setnull
     *
     *
     * Ha meg van adva, akkor a felhasználó csak úgy tud bejelentkezni, ha a megadott csoport aktív tagja.
     */
    sec_group_id?: number|null;
    /**
     * Site
     *
     * -> cms.site ON DELETE noaction
     *
     *
     * Az auth szerver ebből tudja meghatározni, hogy a kliens melyik site-hoz tartozik.
     */
    site_id?: number|null;
    /**
     * State kötelező
     */
    need_state?: boolean;
    /**
     * Scope kötelező
     */
    need_scope?: boolean;
}

/**
 *  sys.oauth_client - Kliens (oauth2)
 *
 *  Azok a külső vagy belső weblapok, amik az nkp oauth2 szervert használják.
 */

export default class OauthClientCrud extends Crud<IOauthClientRecord> {
    public static TABLE_INFO_ID = 1661710724;
    public static getSchemaNameForClass() { return 'sys'; }
    public static getTableNameForClass() { return 'oauth_client'; }

    public static load: (id: number, aServer ?: Server) => Promise<OauthClientCrud>;
    public static asText: (id: number, includeRecord : boolean, aServer ?: Server) => Promise<IAsTextResult<IOauthClientRecord>>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<IOauthClientRecord[]>;
    public static searchText: (params: ISearchTextParams, aServer ?: Server) => Promise<ISearchTextResult<IOauthClientRecord>[]>;
    public static deleteById: (id: number, aServer ?: Server) => Promise<IOauthClientRecord>;
    public static unDeleteById: (id: number, aServer ?: Server) => Promise<IOauthClientRecord>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class OauthClientCrudClassProxy extends CrudClassProxy<IOauthClientRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const oauthClientCrudClassProxy = new OauthClientCrudClassProxy(OauthClientCrud);
registerCrudClassProxy(oauthClientCrudClassProxy);
