import * as React from 'react';
import { app } from '@src/index';
import { me } from '@framework/server/Auth';
import { permissionCache, CrudPermissionType } from '@src/framework/security/PermissionCache'; 
import SystemCheckCrud, { ISystemCheckRecord } from '@src/framework/crud/sys/SystemCheckCrud';
import SystemCheckLevelCrud, { ISystemCheckLevelRecord } from '@src/framework/crud/sys/SystemCheckLevelCrud';
import { __ } from '@src/translation';

export interface SystemCheckerProps {
        settings: any
}

export interface SystemCheckerState {
    hasPermission?: boolean;
    systemChecks?: ISystemCheckRecord[];
}

export default class SystemCheckerComponent extends React.Component<SystemCheckerProps, SystemCheckerState> {

    private systemCheckLevels : ISystemCheckLevelRecord[];
    constructor(props: SystemCheckerProps) {
        super(props);        

        this.state = { };
        this.asyncReload(this.props)     
    }

    private asyncReload = async (props:any) => {

        const hasPermission = permissionCache.hasPermission({
            crud: {
            tableInfoId: SystemCheckCrud.TABLE_INFO_ID,
            permissions: CrudPermissionType.R
            }
        }) 

        if(hasPermission)
        {        
            this.systemCheckLevels = await SystemCheckLevelCrud.list({});

            let newState: Partial<SystemCheckerState> = {};
            if (me && me.id) {
                try {
                    newState.systemChecks = await SystemCheckCrud.list({
                        order_by: [
                            { name: "system_check_level_id", desc: true }
                        ]
                        //, limit: props.settings.limit? Number(props.settings.limit) : undefined
                    });

                } catch (error) {
                    app.showErrorFromJsonResult(error);
                }
            } else {
                newState.systemChecks = [];
            }
            this.setState(newState as SystemCheckerState);
        }
    }

    componentWillReceiveProps(newProps: any) {

        this.asyncReload(newProps);
    }

    render() {
        if (!this.state.systemChecks) {
                return <div/>; 
        } else {
            return <div className="list-table">
                <table className="SystemCheckList">
                    <thead>
                        <tr>
                            <th>{__("Név")}</th>
                            <th>{__("Lejárat")}</th>
                            <th>{__("Állapot")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            this.state.systemChecks.map((row: ISystemCheckRecord, i: number) => {
                                return <tr key={row.id} className={"systemcheck_level_"+row.system_check_level_id}>
                                        <td>
                                            {row.name}<br />
                                            <small>{row.description}</small>
                                        </td>
                                        {(new Date(row.expires!) < new Date())?
                                            <td className="">
                                                {__("RÉGI")}
                                            </td>
                                            :<td>
                                            {
                                                new Date(row.expires!).toLocaleDateString() + " " + new Date(row.expires!).toLocaleTimeString()
                                            }
                                            </td>
                                        }                                        
                                        <td>
                                            <span className={"systemcheck_level_"+row.system_check_level_id}>
                                                {
                                                   
                                                    this.systemCheckLevels ? this.systemCheckLevels.find((item)=>{
                                                        return item.id == row.system_check_level_id
                                                    })!.title : ""
                                                    }
                                            </span><br />
                                            <small>{row.result}</small>
                                        </td>
                                    </tr>
                            })

                        }
                    </tbody>
                </table>
                </div>
        }
    }
}