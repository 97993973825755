import * as React from 'react';

import { IViewBadWordGlobalRecord, ViewBadWordGlobalClassProxy, viewBadWordGlobalClassProxy } from '@src/framework/view/argo/ViewBadWordGlobal';

import ListView from '@src/framework/forms/listview';

import '@framework/crud/argo/BadWordGlobalCrud'; // Ezt ne távolítsd el mert akkor a hülye webpack kioptimizálja!

import { __ } from '@src/translation';

class BadWordGlobalListViewInner extends ListView<IViewBadWordGlobalRecord> {
    //protected getHeader = () : JSX.Element|null => { return <SideMenu /> }
    public getViewClassProxy() : ViewBadWordGlobalClassProxy { return viewBadWordGlobalClassProxy; }
}

export default function BadWordGlobalListView(props: any) {
    // TODO: hozzáadni a person-os oszlopokat a view-hoz hogy látszódjon a 
    // rendes nevük!
    return <BadWordGlobalListViewInner 
        defaultPageSize={10}
        match={props.match}        
        viewColumns={[
            {accessor: "id", maxWidth: 100},
            {accessor: "word"},
            {accessor: "creation_time"},
            {accessor: "creator"},
            /*
            {accessor: "modification_time"},
            {accessor: "modifier"},
            */
            {accessor: "link", Header: __("Műveletek"), maxWidth:200},
        ]}
    />
}
