import { Server } from '../../server/Server';
import View , { registerViewClassProxy, ViewClassProxy } from '../View';
import { IListParams } from '@framework/crud/Crud';

export interface IViewSupplementaryMaterialLinkRecord {
    /**
     * Azonosító
     *
     * Azonosító (pótkulcs)
     */
    id?: number;
    /**
     * Link
     */
    link_id?: number;
    /**
     * Kiegészítő anyag
     */
    supplementary_material_id?: number;
    /**
     * Könyv azonosító
     *
     * Könyv azonosító
     */
    book_id?: number|null;
    /**
     * Könyv név
     *
     * A könyv neve amiben a link van
     */
    book_name?: string|null;
    /**
     * Fejzet azonosító
     *
     * Fejzet azonosító
     */
    chapter_id?: number|null;
    /**
     * Fejzet név
     *
     * A fejezet neve amiben a link van
     */
    chapter_name?: string|null;
    /**
     * Lecke azonosító
     *
     * Lecke azonosító
     */
    lesson_id?: number|null;
    /**
     * Lecke név
     *
     * A lecke neve amiben a link van
     */
    lesson_name?: string|null;
    /**
     * Kiegésztítő tananyag neve
     *
     * Kiegésztítő tananyag neve
     */
    supplementary_material_name?: string|null;
    /**
     * Szerkesztő link
     *
     * A szekciót szerkesztő lapra mutató relatív hivatkozás
     */
    lesson_edit_url?: string|null;
}

/**
 *  doc.view_supplementary_material_link - Kiegészítő ynag link
 */

export default class ViewSupplementaryMaterialLink extends View<IViewSupplementaryMaterialLinkRecord> {
    public static VIEW_INFO_ID = 2018090703;

    public static getSchemaNameForClass(): string { return 'doc'; }
    public static getViewNameForClass(): string { return 'view_supplementary_material_link'; }
    public static getTableNameForClass(): string|null { return 'supplementary_material_link'; }

    public static load: (id: number, aServer ?: Server) => Promise<ViewSupplementaryMaterialLink>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<IViewSupplementaryMaterialLinkRecord[]>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class ViewSupplementaryMaterialLinkClassProxy extends ViewClassProxy<IViewSupplementaryMaterialLinkRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const viewSupplementaryMaterialLinkClassProxy = new ViewSupplementaryMaterialLinkClassProxy(ViewSupplementaryMaterialLink);
registerViewClassProxy(viewSupplementaryMaterialLinkClassProxy);

